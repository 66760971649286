// eslint is detecting the extra functions as multiple React components
/* eslint react/no-multi-comp: "off" */

import React from 'react'
import PropTypes from 'prop-types'
import 'react-widgets/styles.css'
import CircularProgress from '@mui/material/CircularProgress'
import DropdownList from 'react-widgets/DropdownList'
import { billAccountConstants } from '../../../constants/bill-account-constants';

let waterStyle = {
    width: '58px',
    height: '22px',
    backgroundColor: 'rgba(0,151,167,1)',
    borderRadius: '4px',
    fontFamily: '"HelveticaNeue-Bold", "Helvetica Neue Bold", "Helvetica Neue"',
    fontSize: '11px',
    color: '#FFFFFF',
    padding: '4px 8px 4px 8px',
    fontWeight: '700'
}

let powerStyle = {
    width: '58px',
    height: '22px',
    backgroundColor: '#B1621C',
    borderRadius: '4px',
    fontFamily: '"HelveticaNeue-Bold", "Helvetica Neue Bold", "Helvetica Neue"',
    fontSize: '11px',
    color: '#FFFFFF',
    padding: '4px 8px 4px 8px',
    fontWeight: '700'
}

function filterNickNameAndAccountNumber(account, value) {

    let nickNameSearch = (account.accountName).toLowerCase()
    let accountSearch = account.accountWithLeadingZeros
    let accountWithDashesSearch = account.accountText
    let searchTerm = value.toLowerCase()

    let searchResult = nickNameSearch.includes(searchTerm) === true
                     || accountSearch.includes(searchTerm) === true
                     || accountWithDashesSearch.includes(searchTerm) == true

    return searchResult
}


const AccountSelector = ({ billAccountList, selectedBillAccount, changeAccount, disableSelector, accountChangeInProgress, t }) => {
    const accounts = billAccountList
    let lengthOfAccountsList = accounts.length

    let [ dropDownOpen, setDropDownOpen ] = React.useState(false)
    let [ searchTerm, setSearchTerm ] = React.useState('')

    function listItem(accountChangeInProgress, skipRenderIfOpen) {
        return function createItem(itemBase) {
            if (!itemBase) return null // null to tell React not generate any DOM element
            if (skipRenderIfOpen && dropDownOpen) return null // don't render value if drop down is open

            if (accountChangeInProgress) {
                return <div className="d-flex align-self-center"><CircularProgress size={20} thickness={5} /></div>
            }

            let item = itemBase.item

            let itemText = (accountName, accountText) => {
                let text = ''

                if (accountName) {
                    text += accountName + ', '
                }

                text += accountText

                return text
            }

            if (item.accountType === billAccountConstants.POWER_ACCOUNT) {
                return (
                    <span>
                        <span style={powerStyle}>{t("POWER")}</span>&nbsp;&nbsp;{itemText(item.accountName, item.accountText)}
                    </span>
                )
            } else if (item.accountType === billAccountConstants.WATER_ACCOUNT) {
                return (
                    <span>
                        <span style={waterStyle}>{t("WATER")}</span>&nbsp;&nbsp;{itemText(item.accountName, item.accountText)}
                    </span>
                )
            } else {
                return null
            }
        }
    }

    return (
        <div>
            <label className="mb-0 d-none d-lg-block font-weight-bold">{t("Select account")}</label>
            <label className="mb-0 d-block d-lg-none text-muted">{t("Select account")}</label>
            <DropdownList
                filter={lengthOfAccountsList > 1 ? filterNickNameAndAccountNumber : false}
                disabled={disableSelector || accountChangeInProgress}
                data={accounts}
                dataKey="account"
                textField="accountText"
                renderListItem={listItem(accountChangeInProgress)}
                renderValue={listItem(accountChangeInProgress, true)}
                onToggle={setDropDownOpen}
                onSearch={setSearchTerm}
                searchTerm={dropDownOpen ? searchTerm : ''}
                value={selectedBillAccount}
                onChange={(selectedBillAccount) => changeAccount(selectedBillAccount)}
            />
        </div>
    )
}

AccountSelector.propTypes = {
    billAccountList: PropTypes.arrayOf(PropTypes.object),
    selectedBillAccount: PropTypes.number.isRequired,
    changeAccount: PropTypes.func.isRequired,
    item: PropTypes.object,
    disableSelector: PropTypes.bool,
    accountChangeInProgress: PropTypes.bool,
    t: PropTypes.func.isRequired
}

export default AccountSelector