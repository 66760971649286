import React from 'react'
import PropTypes from 'prop-types'
import TextField from '@mui/material/TextField'

const EmailInput = props => {
    let isError = props.touched[props.id]
        && Object.prototype.hasOwnProperty.call(props.errors, props.id)
    let errMsg = isError ? props.errors[props.id] : ''
    let meta = {
        id: props.id,
        name: props.id,
        helperText: errMsg==="" ? "" : props.t(errMsg),
        error: isError
    }

    return (
        <div>
            <TextField
                {...meta}
                value={props.value}
                label={props.t(props.label)}
                type="email"
                fullWidth={true}
                inputProps={props.inputProps}
                onChange={props.onChange}
                onBlur={props.onBlur}
            />
        </div>
    )
}

EmailInput.propTypes = {
    t: PropTypes.func.isRequired,
    touched: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    inputProps: PropTypes.object,
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired
}

export default EmailInput