import React from 'react'
import PropTypes from 'prop-types'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { connect } from 'react-redux'

import EditAutopayForm from './edit-autopay-form'

import { createValidationRulesFromeChexBankInfoValidationErrors } from '../gift_payment_page/input_page/single-payment-formik-container'

import { autopayConstants } from '../../constants/autopay-constants'
import { autopayFormDataCancel, autopayFormDataChange, validateAutopayBankAccount } from '../../actions/auth/payment/autopay-actions'
import { withRouter } from '../../srp_modules/with-router'

class EditAutopayFormik extends React.Component {
    constructor(props) {
        super(props)

        this.validationSchema = Yup.object().shape({
            shareAmount: Yup.number()
                .test('must be 0 or 1-99',
                    'Please enter an amount between $1 and $99',
                shareAmount =>  shareAmount === 0 || (shareAmount >= 1 && shareAmount <= 99)),
            bankFirstName: Yup.string().ensure().trim()
                .required('First name is required.')
                .test('not all spaces', 'First name is required.', bankFirstName => typeof bankFirstName === "string" && bankFirstName.trim().length > 0),
            bankLastName: Yup.string().ensure().trim()
                .required('Last name is required.')
                .test('not all spaces',
                    'Last name is required.',
                    bankLastName => typeof bankLastName === "string" && bankLastName.trim().length > 0)
                .test('at least 2 characters',
                    'Last name must have at least two characters.',
                    bankLastName => typeof bankLastName === "string" && bankLastName.trim().length > 1),
            bankPhoneNumber: Yup.string().ensure().trim()
                .required('Phone number is required.')
                .test('not all spaces',
                    'Phone number is required.',
                    bankPhoneNumber => typeof bankPhoneNumber === "string" && bankPhoneNumber.trim().length > 0)
                .test('must have 10 digits',
                    'Phone number must have 10 digits.',
                    bankPhoneNumber => typeof bankPhoneNumber === "string" && /^\d{10}$/.test(bankPhoneNumber.replace(/[() -]/g, ''))),
            bankEmailAddress: Yup.string().ensure().trim()
                .required('Email address is required.')
                .test('not all spaces',
                    'Email address is required.',
                    bankEmailAddress => typeof bankEmailAddress === "string" && bankEmailAddress.trim().length > 0)
                .email('Invalid email address.'),
            bankRoutingNumber: Yup.string().ensure().trim()
                .required('Routing number is required.')
                .test('not all spaces',
                    'Routing number is required.',
                    bankRoutingNumber => typeof bankRoutingNumber === "string" && bankRoutingNumber.trim().length > 0)
                .test('must have 9 digits',
                    'This is a 9 digit number located at the bottom left corner of your check.',
                    bankRoutingNumber => typeof bankRoutingNumber === "string" && /^\d{9}$/.test(bankRoutingNumber.trim())),
            bankAccountNumberLeadingZeroesAreSignificant: Yup.string().ensure().trim()
                .required('Bank account number is required.')
                .test('not all spaces',
                    'Bank account number is required.',
                    bankAccountNumber => typeof bankAccountNumber === "string" && bankAccountNumber.trim().length > 0)
                .test('17 digits max',
                    'Your bank account number must be 1-17 digits long.',
                    bankAccountNumber => typeof bankAccountNumber === "string" && /^\d{1,17}$/.test(bankAccountNumber.trim())),
        })

        this.initialValues = {}
    }

    transformValues(values) {
        return {
            ...values,
            bankFirstName: values.bankFirstName.trim(),
            bankLastName: values.bankLastName.trim(),
            bankPhoneNumber: values.bankPhoneNumber.replace(/[() -]/g, ''),
            bankEmailAddress: values.bankEmailAddress.trim(),
            bankRoutingNumber: values.bankRoutingNumber.trim(),
            bankAccountNumberLeadingZeroesAreSignificant: values.bankAccountNumberLeadingZeroesAreSignificant.trim()
        }
    }

    render() {
        let isPendingSubmit = this.props.autopayFormData && this.props.autopayFormData.isPendingSubmit
        let firstName = isPendingSubmit ? this.props.autopayFormData && this.props.autopayFormData.firstName || '' :
            this.props.autopayInfo.isResidential ?
                this.props.autopayInfo.customerInfo && this.props.autopayInfo.customerInfo.firstName || '' :
                ''
        let lastName = isPendingSubmit ? this.props.autopayFormData && this.props.autopayFormData.lastName || '' :
            this.props.autopayInfo.isResidential ?
                this.props.autopayInfo.customerInfo && this.props.autopayInfo.customerInfo.lastName || '' :
                this.props.autopayInfo.businessInfo && this.props.autopayInfo.businessInfo.businessName || ''
        let phoneNumber = isPendingSubmit ? this.props.autopayFormData && this.props.autopayFormData.phoneNumber || '' :
            this.props.autopayInfo && this.props.autopayInfo.phoneNumber || ''
        let emailAddress = isPendingSubmit ? this.props.autopayFormData && this.props.autopayFormData.emailAddress || '' :
            this.props.autopayInfo && this.props.autopayInfo.emailAddress || this.props.loginEmail || ''
        let bankAccountType = isPendingSubmit ? this.props.autopayFormData && this.props.autopayFormData.bankAccountType || autopayConstants.BANK_ACCOUNT_CHECKING : autopayConstants.BANK_ACCOUNT_CHECKING
        let bankRoutingNumber = isPendingSubmit ? this.props.autopayFormData && this.props.autopayFormData.routingNumber || '' :
            this.props.autopayInfo && this.props.autopayInfo.bankAccount && this.props.autopayInfo.bankAccount.routingNumber || ''
        let bankAccountNumber = isPendingSubmit ? this.props.autopayFormData && this.props.autopayFormData.bankAccountNumber || '' : ''

        return (
            <Formik
                initialValues={{
                    srpAccountNumber: this.props.autopayInfo && this.props.autopayInfo.billAccount || 0,
                    bankFirstName: firstName,
                    bankLastName: this.props.autopayInfo.isResidential ? lastName : "",
                    bankPhoneNumber: phoneNumber,
                    bankEmailAddress: emailAddress,
                    bankAccountType: bankAccountType,
                    bankRoutingNumber: bankRoutingNumber,
                    bankAccountNumberLeadingZeroesAreSignificant: bankAccountNumber,
                    shareAmount: 0
                }}
                validationSchema={this.validationSchema}
                onSubmit={(values, formikProps) => {
                    let _values = this.transformValues(values)

                    let autopayFormData = {
                        billAccount: this.props.autopayInfo.billAccount,
                        isResidential: this.props.autopayInfo.isResidential,
                        firstName: _values.bankFirstName,
                        lastName: _values.bankLastName,
                        routingNumber: _values.bankRoutingNumber,
                        bankAccountNumber: _values.bankAccountNumberLeadingZeroesAreSignificant,
                        bankAccountType: _values.bankAccountType,
                        phoneNumber: _values.bankPhoneNumber,
                        emailAddress: _values.bankEmailAddress,
                        shareAmount: _values.shareAmount,
                        isPendingSubmit: true
                    }

                    this.props.actions.verifyAutopay(autopayFormData, this.validationSchema, formikProps)
                }}>
                {formikProps => (<EditAutopayForm {...formikProps} {...this.props} {...this.state} />)}
            </Formik>
        )
    }
}

EditAutopayFormik.propTypes = {
    actions: PropTypes.object.isRequired,
    autopayInfo: PropTypes.object.isRequired,
    autopayFormData: PropTypes.object.isRequired,
    bankDataStatus: PropTypes.string.isRequired,
    showSpanish: PropTypes.bool,
    router: PropTypes.shape({
        navigate: PropTypes.func
    }),
    loginEmail: PropTypes.string.isRequired
}

const mapStateToProps = (state) => {
    return {
        loginEmail: state.login.loginEmail,
        bankDataStatus: state.accountInfo.autopay.bankDataStatus,
        autopayFormData: state.accountInfo.autopay.autopayFormData
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        actions: {
            cancelClick: () => {
                dispatch(autopayFormDataCancel())
                ownProps.router.navigate(-1)
            },
            verifyAutopay: async (autopayFormData, validationSchema, formikProps) => {
                await dispatch(autopayFormDataChange(autopayFormData))
                let bankInquiryResult = await dispatch(validateAutopayBankAccount(autopayFormData))

                if (bankInquiryResult.error) {
                    formikProps.setStatus({hasUnhandledBankError: true})
                    document.getElementById("topOfEditSurepayForm").scrollIntoView()
                    return false
                }

                if (!bankInquiryResult.payload.success) {
                    const bankInputValues = {
                        bankFirstName: autopayFormData.firstName,
                        bankLastName: autopayFormData.lastName,
                        bankRoutingNumber: autopayFormData.routingNumber,
                        bankAccountNumber: autopayFormData.bankAccountNumber
                    }

                    if (!bankInquiryResult.payload.autopayBankErrors || (Object.keys(bankInquiryResult.payload.autopayBankErrors).length === 0 && bankInquiryResult.payload.autopayBankErrors.constructor === Object)) {
                        formikProps.setStatus({ hasUnhandledBankError: true })
                        formikProps.validateForm()
                    } else {
                        const eChexAutopayBankAccountErrors = bankInquiryResult.payload.autopayBankErrors
                        const numErrorsHandled = createValidationRulesFromeChexBankInfoValidationErrors(validationSchema.fields, eChexAutopayBankAccountErrors, bankInputValues)
                        const hasUnhandledeChexValidationError = (Object.keys(eChexAutopayBankAccountErrors).length - numErrorsHandled) > 0
                        if (hasUnhandledeChexValidationError) {
                            if (eChexAutopayBankAccountErrors.errorBankInfoMatchesExisting) {
                                formikProps.setStatus({ matchesExistingAutopayError: true })
                            }
                            if (eChexAutopayBankAccountErrors.errorRequestMatchesPending) {
                                formikProps.setStatus({ hasPendingAutopayError: true })
                            }
                            if(!eChexAutopayBankAccountErrors.errorBankInfoMatchesExisting
                                && !eChexAutopayBankAccountErrors.errorRequestMatchesPending) {
                                    formikProps.setStatus({ hasUnhandledBankError: true })
                                }
                        }

                        formikProps.validateForm()

                        if (eChexAutopayBankAccountErrors.errorBankAccountSetupLocked)
                            ownProps.router.navigate('/myaccount/profile/banks/locked')
                    }

                    document.getElementById("topOfEditSurepayForm").scrollIntoView()
                    return false
                } else {
                    ownProps.router.navigate('/myaccount/payment/autopay/verify')
                }
            }
        }
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EditAutopayFormik))
