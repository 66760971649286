import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import { multiPaymentConstants } from '../../constants/payment/multi-payment-constants'
import { withRouter } from '../../srp_modules/with-router'

class MultiPaymentAccountTypeSelector extends React.Component {
    constructor(props) {
        super(props)

        this.switchTab = this.switchTab.bind(this)

        this.state = {
            currentTab: this.getCurrentTab()
        }
    }

    getCurrentTab() {
        let currentTab = multiPaymentConstants.MULTI_TABLE_VIEW.CREDIT
        if (this.props.router.location.pathname.toLowerCase().includes('mpower')) {
            currentTab = multiPaymentConstants.MULTI_TABLE_VIEW.MPOWER
        }

        return currentTab
    }

    switchTab(event, clickedTab) {
        if (clickedTab === this.state.currentTab) {
            return
        } else {
            this.props.switchTab(clickedTab)
        }
    }
    render() {
        if (this.props.paymentInfoList.length > 0 && this.props.mpowerPaymentInfoList.length > 0) {
            return (
                <div style={{ width: "100%", borderBottom: "1px solid rgb(224,224,224)"}}>
                    <Tabs
                        value={this.state.currentTab}
                        onChange={this.switchTab}
                        indicatorColor="primary"
                        textColor="primary"
                        className="srp-icon-color"
                        style={{ color: 'black' }}
                        variant="fullWidth"
                    >
                        <Tab
                            className="mui_tab"
                            label="Monthly billed accounts"
                            style={{ outline: "none", boxShadow: "none", textTransform: "none", paddingTop: "20px" }}
                        />
                        <Tab
                            className="mui_tab"
                            label="M-Power accounts"
                            style={{ outline: "none", boxShadow: "none", textTransform: "none", paddingTop: "20px" }}  />
                    </Tabs>
                </div>
        )}
        else {
            return null
        }
    }
}

const mapStateToProps = (state) => {
    return {
        paymentInfoList: state.multiPayment.paymentInfoList,
        mpowerPaymentInfoList: state.multiPaymentNgp.mpowerPaymentInfoList
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        switchTab: (nextTab) => {
            if (nextTab === multiPaymentConstants.MULTI_TABLE_VIEW.CREDIT) {
                ownProps.router.navigate('/myaccount/payment/multi')
            } else {
                ownProps.router.navigate('/myaccount/payment/multi/mpower')
            }
        }
    }
}

MultiPaymentAccountTypeSelector.propTypes = {
    paymentInfoList: PropTypes.arrayOf(PropTypes.object),
    mpowerPaymentInfoList: PropTypes.arrayOf(PropTypes.object),
    router: PropTypes.shape({
        location: PropTypes.object,
        navigate: PropTypes.func
    }).isRequired,
    switchTab: PropTypes.func.isRequired

}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MultiPaymentAccountTypeSelector))