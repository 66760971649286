import { RSAA } from 'redux-api-middleware'
import cookies from 'js-cookie'
import { DateTime } from 'luxon'

import { loginConstants } from '../../../constants/login-constants'
import { SKIP_XSRF } from '../../../srp_modules/middleware/xsrf-middleware'
import { SKIP_BILL_ACCOUNT_CHECK } from '../../../srp_modules/middleware/bill-account-middleware'
import utils from '../../../srp_modules/utils'

export const setRememberMeValue = (rememberUser) => {
    if (!rememberUser && utils.isLocalStorageAvailable()) {
        localStorage.removeItem('rememberUser')
        localStorage.removeItem('rememberUserLogin')
    }

    return {
        type: loginConstants.TOGGLE_REMEMBER_MY_LOGIN_INFO,
        rememberUser
    }
}

export const setSiteOffline = (response) => ({
    type: loginConstants.SET_SITE_OFFLINE,
    siteOfflineInfo: {
        ...response,
        startDate: DateTime.fromISO(response.startDate).setZone('MST'),
        endDate: DateTime.fromISO(response.endDate).setZone('MST')
    }
})


export const loginProfile = () => async dispatch => {
    let myAccountTypeOverride = cookies.get('myAccountType')

    let profileResult = await dispatch({
        [RSAA]: {
            endpoint: utils.createUrl('login/profile'),
            method: 'GET',
            credentials: 'include',
            types: [
                loginConstants.LOGIN_PROFILE_REQUEST,
                {
                    type: loginConstants.LOGIN_PROFILE_SUCCESS,
                    meta: { myAccountTypeOverride }
                },
                loginConstants.LOGIN_PROFILE_FAILURE
            ]
        },
        [SKIP_XSRF]: true
    })

    if(profileResult === undefined)
        return { error: true }

    if(profileResult.error === true || !profileResult.payload)
        return profileResult

    return profileResult
}

const cleanupLoginSession = () => {
    cookies.remove('xsrf-token')
}

export const beginLogout = () => dispatch => {
    cleanupLoginSession()

    return dispatch({
        [RSAA]: {
            endpoint: utils.createUrl('logout'),
            method: 'GET',
            credentials: 'include',
            types: [
                loginConstants.LOGOUT_REQUEST,
                loginConstants.LOGOUT_SUCCESS,
                loginConstants.LOGOUT_FAILURE
            ]
        },
        [SKIP_BILL_ACCOUNT_CHECK]: true,
        [SKIP_XSRF]: true
    })
}

export const submitLoginForm = (username, password, rememberUser) => async (dispatch) => {
    cleanupLoginSession()

    let qs = new URLSearchParams(location.search)
    let redirectUrl = qs.get('r') || qs.get('returnUrl') || qs.get('ReturnUrl') || ''
    let parameters = {
        username: username,
        password: password,
        redirectUrl: redirectUrl
    }

    let loginResult = await dispatch({
        [RSAA]: {
            endpoint: utils.createUrl('login/authorize'),
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body: utils.toQueryString(parameters),
            credentials: 'include',
            types: [
                loginConstants.LOGIN_REQUEST,
                loginConstants.LOGIN_SUCCESS,
                loginConstants.LOGIN_FAILURE
            ]
        },
        [SKIP_XSRF]: true,
        [SKIP_BILL_ACCOUNT_CHECK]: true
    })

    if (rememberUser && loginResult && !loginResult.error && loginResult.payload
        && loginResult.payload.username && utils.isLocalStorageAvailable()) {
        localStorage.setItem('rememberUser', true)
        localStorage.setItem('rememberUserLogin', username)
    }

    return loginResult
}

//password reset
export const sendPasswordResetEmail = email => dispatch => {
    return dispatch({
        [RSAA]: {
            endpoint: utils.createUrl('passwordreset/emailWeb', { email }),
            method: 'POST',
            types: [
                loginConstants.PASSWORD_RESET_EMAIL_REQUEST,
                {
                    type: loginConstants.PASSWORD_RESET_EMAIL_SUCCESS,
                    meta: { email }
                },
                loginConstants.PASSWORD_RESET_EMAIL_FAILURE
            ]
        },
        meta: {
            debounce: {
                key: loginConstants.PASSWORD_RESET_EMAIL_REQUEST,
                time: 1000,
                leading: false,
                trailing: true
            }
        }
    })
}

export const refreshPasswordReset = (username) => ({
    type: loginConstants.REFRESH_PASSWORD_RESET,
    username
})

export const confirmPasswordReset = (e, t) => dispatch => {
    let parameters = {
        email: e,
        token: t
    }

    return dispatch({
        [RSAA]: {
            endpoint: utils.createUrl('passwordreset/confirm', parameters),
            method: 'POST',
            types: [
                loginConstants.CONFIRM_PASSWORD_RESET_REQUEST,
                loginConstants.CONFIRM_PASSWORD_RESET_SUCCESS,
                loginConstants.CONFIRM_PASSWORD_RESET_FAILURE
            ]
        },
        [SKIP_XSRF]: true
    })
}

export const completePasswordReset = (email, token, newPassword) => dispatch => {
    let parameters = { email, authToken: token, password: newPassword, confirmPassword: newPassword }

    return dispatch({
        [RSAA]: {
            endpoint: utils.createUrl('passwordreset/complete'),
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(parameters),
            types: [
                loginConstants.COMPLETE_PASSWORD_RESET_REQUEST,
                loginConstants.COMPLETE_PASSWORD_RESET_SUCCESS,
                loginConstants.COMPLETE_PASSWORD_RESET_FAILURE
            ]
        },
        [SKIP_XSRF]: true
    })
}

export const closePasswordResetSuccessMessage = () => ({
    type: loginConstants.CLOSE_PASSWORD_RESET_SUCCESS_MESSAGE
})

//create new account
export const registerNewLogin = (username, password, accountNumber, phoneNumber) => (dispatch) => {
    let parameters = {
        emailAddress: username,
        password: password,
        confirmPassword: password,
        accountNumber: accountNumber,
        phoneNumber: phoneNumber,
        newUserRegistrationType: 'Web'
    }

    return dispatch({
        [RSAA]: {
            endpoint: utils.createUrl('account/createandregisternewlogin'),
            method: 'POST',
            body: JSON.stringify(parameters),
            headers: { 'Content-Type': 'application/json' },
            types: [
                loginConstants.REGISTER_NEW_LOGIN_REQUEST,
                loginConstants.REGISTER_NEW_LOGIN_SUCCESS,
                loginConstants.REGISTER_NEW_LOGIN_FAILURE
            ]
        },
        meta: { //this turns off the debouncer
            debounce: {
                leading: false,
                trailing: false
            }
        }
    })
}


export const completeCreateAccount = (e, t) => dispatch => {
    let parameters = {
        email: e,
        token: t
    }
    return dispatch({
        [RSAA]: {
            endpoint: utils.createUrl('account/completeaccess', parameters),
            method: 'POST',
            types: [
                loginConstants.COMPLETE_CREATE_ACCOUNT_REQUEST,
                loginConstants.COMPLETE_CREATE_ACCOUNT_SUCCESS,
                loginConstants.COMPLETE_CREATE_ACCOUNT_FAILURE
            ]
        }
    })
}

export const refreshAccountSetup = () => ({
    type: loginConstants.REFRESH_ACCOUNT_SETUP
})

export const getResponsiveLoginTabValue = (event, value) => ({
    type: loginConstants.LOGIN_RESP_TAB_VALUE,
    value
})

export const requestLoginEmailUpdate = (newEmail, password) => dispatch => {
    return dispatch({
        [RSAA]: {
            endpoint: utils.createUrl('login/email/requestupdate', { newEmail, password }),
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            types: [
                loginConstants.REQUEST_LOGIN_EMAIL_UPDATE_REQUEST,
                loginConstants.REQUEST_LOGIN_EMAIL_UPDATE_SUCCESS,
                loginConstants.REQUEST_LOGIN_EMAIL_UPDATE_FAILURE
            ]
        },
        meta: {
            debounce: {
                key: `${loginConstants.REQUEST_LOGIN_EMAIL_UPDATE_REQUEST}_${newEmail}`,
                time: 100,
                leading: true,
                trailing: false
            }
        }
    })
}

export const completeLoginEmailUpdate = (oldEmail, newEmail, token) => dispatch => {
    return dispatch({
        [RSAA]: {
            endpoint: utils.createUrl('login/email/completeupdate', { oldEmail, newEmail, token }),
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            types: [
                loginConstants.COMPLETE_LOGIN_EMAIL_UPDATE_REQUEST,
                loginConstants.COMPLETE_LOGIN_EMAIL_UPDATE_SUCCESS,
                loginConstants.COMPLETE_LOGIN_EMAIL_UPDATE_FAILURE
            ]
        }
    })
}

export const changePassword = (currentPassword, newPassword) => dispatch => {
    let parameters = {
        currentPassword: currentPassword,
        newPassword: newPassword
    }

    return dispatch({
        [RSAA]: {
            endpoint: utils.createUrl('login/changepassword'),
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body: utils.toQueryString(parameters),
            credentials: 'include',
            types: [
                loginConstants.CHANGE_PASSWORD_REQUEST,
                loginConstants.CHANGE_PASSWORD_SUCCESS,
                loginConstants.CHANGE_PASSWORD_FAILURE
            ]
        },
        meta: {
            debounce: {
                key: `${loginConstants.CHANGE_PASSWORD_REQUEST}`,
                time: 100,
                leading: true,
                trailing: false
            }
        }
    })
}

export const loginWasDeleted = () => ({
    type: loginConstants.ACCOUNT_LOGIN_DELETED
})

export const clearLoginWasDeleted = () => ({
    type: loginConstants.CLEAR_LOGIN_DELETED
})

export const deleteLogin = (password) => dispatch => {
    let parameters = {
        password: password
    }

    return dispatch({
        [RSAA]: {
            endpoint: utils.createUrl('account/deleteuserlogin'),
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body: utils.toQueryString(parameters),
            credentials: 'include',
            types: [
                loginConstants.DELETE_LOGIN_REQUEST,
                loginConstants.DELETE_LOGIN_SUCCESS,
                loginConstants.DELETE_LOGIN_FAILURE
            ]
        },
        meta: {
            debounce: {
                key: `${loginConstants.DELETE_LOGIN_REQUEST}`,
                time: 100,
                leading: true,
                trailing: false
            }
        }
    })
}
