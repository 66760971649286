import { billAccountConstants } from '../constants/bill-account-constants'


const evCommunityStatusModule = {
    getEVCommunitySignupEligibility,
}

function getEVCommunitySignupEligibility(selectedBillAccountDetails, evcommunityProgram, customerNames) {
    let eligible = true

    if (selectedBillAccountDetails.isCommercial) {
        eligible = false
    } else if (selectedBillAccountDetails.closedState !== billAccountConstants.CLOSED_STATE.OPEN) {
        eligible = false
    } else if (!customerNames.firstName) {
        eligible = false
    }

    return eligible
}

export default evCommunityStatusModule