import {accountHistoryConstants} from '../../../constants/account-history-constants'
import {RSAA} from 'redux-api-middleware'
import config from 'my-account-config'

export const getAccountHistory = (billAccount) => {
    return dispatch => {
        return dispatch({
            [RSAA]: {
                endpoint: config.apiBaseUrl 
                    + 'api/accounts/getaccounthistory?billaccount='
                    + encodeURIComponent(billAccount),
                method: 'GET',
                credentials: 'include',
                types: [
                    accountHistoryConstants.GET_ACCOUNT_HISTORY_REQUEST,
                    accountHistoryConstants.GET_ACCOUNT_HISTORY_SUCCESS,
                    accountHistoryConstants.GET_ACCOUNT_HISRORY_FAILURE
                ]
            }
        })
    }
}