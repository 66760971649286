import React from 'react'
import PropTypes from 'prop-types'
import { DocumentTitle } from '../../../srp_modules/document-title'

import Card from "@mui/material/Card"

import { Trans } from 'react-i18next'
import utils from '../../../srp_modules/utils'

const CustomDueDateHelp = ({ isCommercial, t, i18n }) => {
    const isSpanish = (i18n.language === "es")

    const supportNumber = utils.GetCustomerServicePhoneNumber(isCommercial, isSpanish)
    const supportNumberFmt = utils.GetCustomerServicePhoneNumberFmt(isCommercial, isSpanish)

    return (
        <DocumentTitle title="Custom Due Date Help | SRP Power">
            <div>
                <div className="srp-card-header">
                    <div>{t("Need help?")}</div>
                </div>
                <Card>
                    <div className="container" style={{ backgroundColor: "#F7DFD0" }}>
                        <div className="srp-card-details">
                            <div className="d-lg-block d-none">
                                {isCommercial
                                ?
                                t("For_questions_please_contact_SRP_commercial_desktop", {supportNumberFmt})
                                :
                                t("For_questions_please_contact_SRP_residential_desktop", {supportNumberFmt})
                                }
                            </div>
                            <div className="d-lg-none d-block">
                                {isCommercial
                                ?
                                <Trans i18nKey="For_questions_please_contact_SRP_commercial_mobile" t={t}>
                                For questions please contact SRP Business Contact Center at <a href={"tel:"+supportNumber}>{{supportNumberFmt}}</a>, Monday through Friday, 7 a.m.-5 p.m.
                                </Trans>
                                :
                                <Trans i18nKey="For_questions_please_contact_SRP_residential_mobile" t={t}>
                                For questions please contact SRP Residential Customer Services at <a href={"tel:"+supportNumber}>{{supportNumberFmt}}</a>, available to serve you 24 hours a day, seven days a week.
                                </Trans>
                                }
                            </div>
                        </div>
                    </div>
                </Card>
            </div>
        </DocumentTitle>
    )
}

CustomDueDateHelp.propTypes = {
    isCommercial: PropTypes.bool.isRequired,

    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired,
}

export default CustomDueDateHelp