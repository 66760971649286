import React from 'react'
import PropTypes from 'prop-types'
import { Formik } from 'formik'
import * as Yup from 'yup'

import SRPAccountNumberPaymentAmountForm from './srp-account-number-payment-amount-form'

class SRPAccountNumberPaymentAmountFormik extends React.Component {
    constructor(props) {
        super(props)

        this.validationSchema = Yup.object().shape({
            srpAccountNumber: Yup.string().ensure().
                transform(function (srpAccountNumber) {
                    return this.isType(srpAccountNumber) && srpAccountNumber !== null ? srpAccountNumber.replace(/[- ]/g, '') : srpAccountNumber
                }).
                required("SRP account number is required.").
                test('nnn-nnn-nnn format', "Invalid SRP account number.", srpAccountNumber => /^\d{9}$/.test(srpAccountNumber)).
                test("bill account number doesn't already exist in the payment list", "You are already making a payment for this account number.", (function (newSrpAccountNumber) { return this.props.paymentList.findIndex(e => e.srpAccountNumber === newSrpAccountNumber) === -1 }).bind(this)).
                test("50 payments max", "Max number of accounts to pay (50) reached.", (function () { return this.props.paymentList.length < 50 }).bind(this)),
            paymentAmount: Yup.string().ensure().trim().
                required("A payment amount is required.").
                test('payment amount is valid', "Invalid payment amount.", paymentAmount => /^(\$?\d+(,\d{3})*\.?[0-9]?[0-9]?|\$?\.\d{1,2})$/.test(paymentAmount)). // regex from https://stackoverflow.com/questions/8829765/regular-expression-for-dollar-amount-in-javascript
                test('payment amount is a penny or more', "Invalid payment amount.", paymentAmount => parseFloat(paymentAmount.replace(/[$,]/g, '')) >= 0.01)
        })
    }

    transformValues(values) {
        return {
            ...values,
            srpAccountNumber: values.srpAccountNumber.replace(/[- ]/g, ''),
            paymentAmount: parseFloat(values.paymentAmount.trim().replace(/[$,]/g, ''))
        }
    }

    render() {
        return (
            <Formik
                initialValues={{
                    srpAccountNumber: '',
                    paymentAmount: ''
                }}
                validationSchema={this.validationSchema}
                validateOnBlur={false}
                validateOnChange={false}
                onSubmit={(srpAccountNumberPaymentAmount, goodies) => {
                    const _srpAccountNumberPaymentAmount = this.transformValues(srpAccountNumberPaymentAmount)
                    this.props.handleAddPayment(_srpAccountNumberPaymentAmount)
                    goodies.resetForm()
                    this.srpAccountNumberField.focus()
                }}>
                {formikProps => (<SRPAccountNumberPaymentAmountForm
                    {...formikProps}
                    {...this.props}
                    {...this.state}
                    bindSrpAccountNumberField={field => this.srpAccountNumberField = field} />)}
            </Formik>
        )
    }
}

SRPAccountNumberPaymentAmountFormik.propTypes = {
    paymentList: PropTypes.arrayOf(PropTypes.shape({
        srpAccountNumber: PropTypes.string.isRequired,
        paymentAmount: PropTypes.number.isRequired
    })).isRequired,
    verifyingPaymentList: PropTypes.bool.isRequired,
    handleAddPayment: PropTypes.func.isRequired,
    handleRemovePayment: PropTypes.func.isRequired,
    formDataChanged: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
}

export default SRPAccountNumberPaymentAmountFormik