/* eslint react/no-multi-comp: "off" */

import React from 'react'
import { Navigate } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import CircularProgress from '@mui/material/CircularProgress'

import { LOADING_STATUS_INIT, LOADING_STATUS_IN_PROGRESS, LOADING_STATUS_SUCCESS } from '../../../constants/loading-status-constants'

import PayableAccountsTable from './payable-accounts-table'

import { getMultiPaymentInfoFromBillAccountList, setPaymentBillAccountListCount } from '../../../actions/auth/payment/multi-payment-actions'

import { verifyPurchases,
    getSelectedMpowerAccount,
    selectAllAccounts,
    selectFirst100Accounts,
    clearPurchaseList,
    setFormStartEventEmitted,
    getPurchaseAmount,
    resetMultipleMpowerInfo,
    getPayAmountOwedToSrp,
    filterAccountsText } from '../../../actions/auth/payment/multi-payment-ngp-actions'
import { selectBillAccountOnChange, getBillAccounts } from '../../../actions/auth/bill_account/bill-account-actions'
import { getLandlordAccounts } from '../../../actions/auth/landlord/landlord-actions'
import { getBankAccountList } from '../../../actions/auth/payment/bank-account-actions'
import { myAccountConstants } from '../../../constants/myaccount-constants'
import multiPaymentUtils from '../../common_payment/multi-payment-utils'

import { billAccountConstants } from '../../../constants/bill-account-constants';

import { formStartEvent } from '../../../srp_modules/analytics-events'
import { purchaseConstants } from '../../common_payment/purchase-constants'
import { withRouter } from '../../../srp_modules/with-router'

const loadingStyle = {
    marginLeft: "45%",
    marginTop: "20%",
    marginBottom: "20%"
}

const loadingStyleResp = {
    marginLeft: "40%",
    marginTop: "20%",
    marginBottom: "20%"
}

let hasLoaded = (props) => {
    let loaded = true

    if (props.billAccountListStatus === LOADING_STATUS_INIT ||
        props.billAccountListStatus === LOADING_STATUS_IN_PROGRESS ||
        props.paymentInfoCompleteStatus === LOADING_STATUS_INIT ||
        props.paymentInfoCompleteStatus === LOADING_STATUS_IN_PROGRESS ||
        props.bankAccountListStatus === LOADING_STATUS_INIT ||
        props.bankAccountListStatus === LOADING_STATUS_IN_PROGRESS ||
        props.landlordAccountsStatus === LOADING_STATUS_INIT ||
        props.landlordAccountsStatus === LOADING_STATUS_IN_PROGRESS) {
            loaded = false
    }

    return loaded
}

let updateDataIfNeeded = (props) => {
    if (props.bankAccountListStatus === LOADING_STATUS_INIT) {
        props.getBankAccountList()
    }

    if (props.billAccountListStatus === LOADING_STATUS_INIT) {
        props.getBillAccounts()
    }

    if (props.landlordAccountsStatus === LOADING_STATUS_INIT) {
        props.getLandlordAccounts()
    }
}

let updatePaymentInfoListIfNeeded = async (props) => {
    if(props.billAccountListStatus !== LOADING_STATUS_SUCCESS ||
        props.landlordAccountsStatus !== LOADING_STATUS_SUCCESS){
            return
    }

    props.getMultiPaymentInfo(getPaymentAccountList(props))
}

let hasPaymentAccountListChanged = (prevProps, props) => {
    if(props.billAccountListStatus === LOADING_STATUS_SUCCESS &&
        props.landlordAccountsStatus === LOADING_STATUS_SUCCESS) {

        // get set of distinct billAccounts associated to current props
        let currentList = getPaymentAccountList(props)

        if(prevProps == null) {
            // the multi-payment redcuer will reset the paymentBillAccountListCount value
            // to the initial state value 0 in the event of any of the following actions:
            //  - multiPaymentConstants.SUBMIT_MULTIPLE_SUCCESS
            //  - multiPaymentNgpConstants.SUBMIT_MULTIPLE_NGP_SUCCESS
            // so if the currentList.size is greater than zero
            // AND props.paymentBillAccountListCount is equal to the currentList.size
            // then paymentBillAccountList has not changed since last load
            // and can use the current paymentInfoList from state
            if(currentList.size > 0 && props.paymentBillAccountListCount === currentList.size) {
                return false
            }

            return true
        }

        if(prevProps.billAccountListStatus !== props.billAccountListStatus ||
            prevProps.landlordAccountsStatus !== props.landlordAccountsStatus){
            return true
        }

        // get set of distinct billAccounts associated to previous props
        let prevList = getPaymentAccountList(prevProps)

        // create new set of distinct billAccounts that is the intersect of the currentList & prevList
        let intersect = new Set([...currentList].filter(billAccount => prevList.has(billAccount)))

        // if current list size equals previous list size and
        // the intersect list size equals the current list size
        // then prev list and current list contain the same billAccounts
        // which indicates the Payment Account List has not changed
        if(currentList.size === prevList.size &&
            currentList.size === intersect.size ) {
            return false
        }

        return true
    }
    return false
}

let getPaymentAccountList = (props) => {
    const powerAccountList = props.billAccountList.filter(o => o.accountType === billAccountConstants.POWER_ACCOUNT)
    let userBillAccountList = multiPaymentUtils.getUserBillAccountList(powerAccountList)
    let landlordBillAccountList = multiPaymentUtils.getLandlordBillAccountList(props.landlordAccounts)
    let summaryBillingSubAccounts = multiPaymentUtils.getSummaryBillingSubAccountList(props.summaryBillAccounts)

    return new Set([...userBillAccountList, ...landlordBillAccountList, ...summaryBillingSubAccounts])
}

class PayableAccountsTableContainerPure extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            showGeneralWarning: false,
        }

        this.getLoadingCard = this.getLoadingCard.bind(this)
        this.verifyPurchasesOnClick = this.verifyPurchasesOnClick.bind(this)
        this.hasError = this.hasError.bind(this)

        this.refreshData = this.refreshData.bind(this)

        this.formDataChanged = this.formDataChanged.bind(this)
        this.MPowerAccountSelectedOnClickAndTrack = this.MPowerAccountSelectedOnClickAndTrack.bind(this)
        this.payAmountOwedToSrpOnClickAndTrack = this.payAmountOwedToSrpOnClickAndTrack.bind(this)
        this.purchaseAmountOnChangeAndTrack = this.purchaseAmountOnChangeAndTrack.bind(this)
        this.selectAllAccountsOnClickAndTrack = this.selectAllAccountsOnClickAndTrack.bind(this)
        this.selectFirst100AccountsOnClickAndTrack = this.selectFirst100AccountsOnClickAndTrack.bind(this)
        this.clearPurchaseListOnClickAndTrack = this.clearPurchaseListOnClickAndTrack.bind(this)
    }

    componentDidMount() {
        if(!hasLoaded(this.props)){
            updateDataIfNeeded(this.props)
        }

        if(hasPaymentAccountListChanged(null, this.props)) {
            updatePaymentInfoListIfNeeded(this.props)
        }
    }

    componentDidUpdate(prevProps) {
        if(!hasLoaded(this.props)){
            updateDataIfNeeded(this.props)
        }

        if(hasPaymentAccountListChanged(prevProps, this.props)) {
            updatePaymentInfoListIfNeeded(this.props)
        }
    }

    refreshData() {
        updateDataIfNeeded(this.props)
        updatePaymentInfoListIfNeeded(this.props)
    }

    formDataChanged() {
        if (this.props.formStartEventEmitted === false) {
            const formName = purchaseConstants.ADOBE_ANALYTICS_FORM_NAME
            const stepName = purchaseConstants.PURCHASE_STEPS[0]
            formStartEvent(formName, stepName)

            this.props.setFormStartEventEmitted()
        }
    }

    MPowerAccountSelectedOnClickAndTrack(billAccount, paymentInfo) {
        this.props.getSelectedMpowerAccountOnClick(billAccount, paymentInfo)
        this.formDataChanged()
    }

    payAmountOwedToSrpOnClickAndTrack(billAccount) {
        this.props.payAmountOwedToSrpClick(billAccount)
        this.formDataChanged()
    }

    purchaseAmountOnChangeAndTrack(billAccount, purchaseAmount) {
        this.props.getPurchaseAmountOnChange(billAccount, purchaseAmount)
        this.formDataChanged()
    }

    selectAllAccountsOnClickAndTrack() {
        this.props.selectAllAccountsOnClick()
        this.formDataChanged()
    }

    selectFirst100AccountsOnClickAndTrack() {
        this.props.selectFirst100AccountsOnClick()
        this.formDataChanged()
    }

    clearPurchaseListOnClickAndTrack() {
        this.props.clearPurchaseListOnClick()
        this.formDataChanged()
    }

    hasError(purchaseList) {
        let error = false
        if (purchaseList.length === 0) {
            error = true
        }

        for (let i = 0; i < purchaseList.length; i++) {
            if (purchaseList[i].purchaseAmountError.errorLevel === myAccountConstants.ERROR_LEVEL.ERROR) {
                error = true
            }
            if (!purchaseList[i].purchaseAmount) {
                // purchaseAmount will be "" when a bill account is checked
                // and then the Confirm purchase button is clicked
                error = true
            }
        }

        this.setState({
            ...this.state,
            showGeneralWarning: error
        })

        return error
    }

    getLoadingCard() {
        return (
            <div>
                <div className="d-none d-md-block">
                    <CircularProgress size={80} thickness={5} style={loadingStyle} />
                </div>
                <div className="d-block d-md-none">
                    <CircularProgress size={80} thickness={5} style={loadingStyleResp} />
                </div>
            </div>
        )
    }

    verifyPurchasesOnClick() {
        if (!this.hasError(this.props.purchaseList)) {
            this.props.verifyPurchasesOnClick()
        }
    }

    render() {
        let renderedCard = {}
        let isSubmitting = this.props.verifyMultiPurchaseResultStatus === LOADING_STATUS_IN_PROGRESS
        if (!hasLoaded(this.props)) {
            renderedCard = this.getLoadingCard()
        }
        else if (this.props.paymentInfoListStatus === LOADING_STATUS_SUCCESS) {
            let hasBankAccounts = this.props.bankAccountList.length > 0
            renderedCard = this.props.mpowerPaymentInfoList.length > 0 ? (
                <PayableAccountsTable
                    purchaseList={this.props.purchaseList}
                    showMaxPaymentWarning={this.props.showMaxPaymentWarning}
                    paymentInfoList={this.props.mpowerPaymentInfoList}
                    selectAll={this.props.selectAll}
                    selectAllAccountsOnClick={this.selectAllAccountsOnClickAndTrack}
                    selectFirst100AccountsOnClick={this.selectFirst100AccountsOnClickAndTrack}
                    clearPurchaseListOnClick={this.clearPurchaseListOnClickAndTrack}
                    getSelectedMpowerAccountOnClick={this.MPowerAccountSelectedOnClickAndTrack}
                    getPurchaseAmountOnChange={this.purchaseAmountOnChangeAndTrack}
                    verifyPurchasesOnClick={this.verifyPurchasesOnClick}
                    viewBillClick={this.props.viewBillClick}
                    payAmountOwedToSrpClick={this.payAmountOwedToSrpOnClickAndTrack}
                    isSubmitting={isSubmitting}
                    hasBankAccounts={hasBankAccounts}
                    showGeneralWarning={this.state.showGeneralWarning}
                    selectableCount={this.props.selectableCount}
                    displayedCount={this.props.displayedCount}
                    filterAccountsText={this.props.filterAccountsText}
                    supportPhoneNumber={this.props.supportPhoneNumber}
                    errorVerifyingPurchases={this.props.errorVerifyingPurchases}
                />
            ) : <Navigate to="/myaccount/payment/multi" replace={true} />
        }
        else {
            renderedCard = (
                <div className="srp-card-details">
                    <div className="text-center">
                        <div className="mb-1" style={{color: "#9E2A2B"}}><i className="material-icons" style={{fontSize:"50px"}}>error_outline</i></div>
                        <div className="mb-1" style={{color: "#9E2A2B"}}><strong>{this.props.t("Something went wrong")}</strong></div>
                        <div className="mb-4">{this.props.t("We were unable to retrieve payment data.")}</div>
                    </div>
                    <div className="d-flex justify-content-end">
                        <a onClick={()=>this.refreshData()} className="btn srp-btn btn-lightblue text-white mr-2">{this.props.t("Try again")}</a>
                    </div>
                </div>
            )
        }
        return renderedCard
    }
}

const mapStateToProps = state => {
    let billAccountList = state.accountInfo.billAccount.billAccountList
    let purchaseList = state.multiPaymentNgp.purchaseList
    let allCommercial = false
    if (purchaseList && billAccountList && purchaseList.length > 0) {
        allCommercial = purchaseList.every(p => billAccountList.find(b => b.account === p.billAccount)?.isCommercial ?? false)
    }

    let supportPhoneNumber = allCommercial
        ? myAccountConstants.COMMERCIAL_CUSTOMER_SERVICE_PHONE_NUMBER_FMTTD
        : myAccountConstants.RESIDENTIAL_CUSTOMER_SERVICE_PHONE_NUMBER_FMTTD

    return {  ...state.bankAccounts, ...state.multiPaymentNgp,
                paymentInfoListStatus: state.multiPayment.paymentInfoListStatus,
                paymentInfoCompleteStatus: state.multiPayment.paymentInfoCompleteStatus,
                paymentBillAccountListCount: state.multiPayment.paymentBillAccountListCount,
                billAccountList,
                billAccountListStatus: state.accountInfo.billAccount.billAccountListStatus,
                landlordAccounts: state.landlord.landlordAccounts,
                landlordAccountsStatus: state.landlord.landlordAccountsStatus,
                supportPhoneNumber,
                summaryBillAccounts: state.accountInfo.billAccount.summaryBillAccounts }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getSelectedMpowerAccountOnClick: (billAccount, paymentInfo) => {
            dispatch(getSelectedMpowerAccount(billAccount, paymentInfo))
        },
        selectAllAccountsOnClick: () => {
            dispatch(selectAllAccounts())
        },
        selectFirst100AccountsOnClick: () => {
            dispatch(selectFirst100Accounts())
        },
        clearPurchaseListOnClick: () => {
            dispatch(clearPurchaseList())
        },
        setFormStartEventEmitted: () => {
            dispatch(setFormStartEventEmitted())
        },
        resetMultipleMpowerInfo: () => {
            dispatch(resetMultipleMpowerInfo())
        },
        getPurchaseAmountOnChange: (billAccount, purchaseAmount) => {
            dispatch(getPurchaseAmount(billAccount, purchaseAmount))
        },
        payAmountOwedToSrpClick: (billAccount) => {
            dispatch(getPayAmountOwedToSrp(billAccount))
        },
        getBillAccounts: () => {
            dispatch(getBillAccounts())
        },
        getBankAccountList: () => {
            dispatch(getBankAccountList())
        },
        getMultiPaymentInfo: async (paymentAccountList) => {
            //await dispatch(resetPaymentInfoList())
            await dispatch(resetMultipleMpowerInfo())
            await dispatch(setPaymentBillAccountListCount(paymentAccountList.size))
            await dispatch(getMultiPaymentInfoFromBillAccountList(paymentAccountList))
        },
        verifyPurchasesOnClick: async () => {
            let verifyPurchaseResult = await dispatch(verifyPurchases())

            if (typeof verifyPurchaseResult.payload !== 'undefined') {
                const referenceNumber = verifyPurchaseResult.payload.referenceNumberWhenNoError
                const eChexValidationErrors = verifyPurchaseResult.payload.eChexValidationErrors

                if (referenceNumber > 0 && (Object.keys(eChexValidationErrors).length === 0 && eChexValidationErrors.constructor === Object)) {
                    ownProps.router.navigate('/myaccount/payment/multi/mpower/verify')
                }
            }
        },
        viewBillClick: async (billAccount) => {
            await dispatch(selectBillAccountOnChange(billAccount))
            ownProps.router.navigate('/myaccount/bill')
        },
        getLandlordAccounts: () => {
            dispatch(getLandlordAccounts())
        },
        filterAccountsText: (enabled, filterText) => {
            dispatch(filterAccountsText(enabled, filterText))
        }
    }
}

PayableAccountsTableContainerPure.propTypes = {
    bankAccountListStatus: PropTypes.string.isRequired,
    bankAccountList: PropTypes.arrayOf(PropTypes.object).isRequired,
    billAccountListStatus: PropTypes.string,
    billAccountList: PropTypes.arrayOf(PropTypes.object),
    summaryBillAccounts: PropTypes.arrayOf(PropTypes.object),
    mpowerPaymentInfoList: PropTypes.arrayOf(PropTypes.object).isRequired,
    paymentInfoListStatus: PropTypes.string.isRequired,
    getSelectedMpowerAccountOnClick: PropTypes.func.isRequired,
    selectAllAccountsOnClick: PropTypes.func.isRequired,
    selectFirst100AccountsOnClick: PropTypes.func.isRequired,
    clearPurchaseListOnClick: PropTypes.func.isRequired,
    setFormStartEventEmitted: PropTypes.func.isRequired,
    formStartEventEmitted: PropTypes.bool.isRequired,
    selectAll: PropTypes.bool.isRequired,
    getPurchaseAmountOnChange: PropTypes.func.isRequired,
    getMultiPaymentInfo: PropTypes.func.isRequired,
    purchaseList: PropTypes.arrayOf(PropTypes.object),
    showMaxPaymentWarning: PropTypes.bool.isRequired,
    verifyPurchasesOnClick: PropTypes.func.isRequired,
    verifyMultiPurchaseResultStatus: PropTypes.string.isRequired,
    errorVerifyingPurchases: PropTypes.bool.isRequired,
    viewBillClick: PropTypes.func.isRequired,
    payAmountOwedToSrpClick: PropTypes.func.isRequired,
    resetMultipleMpowerInfo: PropTypes.func.isRequired,
    getLandlordAccounts: PropTypes.func.isRequired,
    paymentInfoCompleteStatus: PropTypes.string.isRequired,
    getBankAccountList: PropTypes.func.isRequired,
    paymentBillAccountListCount: PropTypes.number.isRequired,
    selectableCount: PropTypes.number.isRequired,
    displayedCount: PropTypes.number.isRequired,
    supportPhoneNumber: PropTypes.string.isRequired,
    filterAccountsText: PropTypes.func.isRequired,
    router: PropTypes.shape({
        navigate: PropTypes.func
    }),
    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired
}

const PayableAccountsTableContainer = withTranslation("multiPurchasePage")(withRouter(connect(mapStateToProps, mapDispatchToProps)(PayableAccountsTableContainerPure)))
export { PayableAccountsTableContainer as default, PayableAccountsTableContainerPure }