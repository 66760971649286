import { billAccountConstants } from '../../../constants/bill-account-constants'
import { loginConstants } from '../../../constants'
import { myAccountConstants } from '../../../constants/myaccount-constants'
import { paymentConstants } from '../../../constants/payment-constants'
import { multiPaymentConstants } from '../../../constants/payment/multi-payment-constants'
import { giftPaymentMultiConstants } from '../../../constants/payment/gift-payment-multi-constants'
import { pricePlanConstants } from '../../../constants/price-plan-constants'
import { rateMetaDataConstants } from '../../../constants/rate-meta-data-constants'
import { primaryContactInfoReminderConstants } from '../../../constants/primary-contact-info-reminder-constants'
import * as loadingStatus from '../../../constants/loading-status-constants'
import format from '../../../srp_modules/format'

const initialState = {
    selectedBillAccount: 0,
    selectedWaterAccount: 0,
    selectedBillAccountDetails: {
        customerKey: 0,
        pricePlan: 0,
        isPrePay: false,
        isFinalAccount: false,
        isCommercial: false,
        account: 0,
        accountWithLeadingZeros: "",
        accountText: "",
        accountType: 0,
        accountName: "",
        accountSelected: false,
        isLinked: false,
        closedState: 0,
        closedDate: "",
        isPendingTurnOn: false,
        billingStatusCode: "",
        isBarcodeEligible: false,
        summaryBillingStatus: billAccountConstants.SUMMARY_BILLING_STATUS.NONE
    },
    selectBillAccountStatus: loadingStatus.LOADING_STATUS_INIT,

    selectedBlockFromTurnOffOn: {
        start: false,
        stop: false,
        transfer: false
    },
    blockFromTurnOffOnStatus: loadingStatus.LOADING_STATUS_INIT,

    billAccountList: [],
    waterAccountList: [],
    fullAccountList: [],
    billAccountSet: new Set(),
    billAccountTokens: {},
    pendingDelete: {},
    billAccountListStatus: loadingStatus.LOADING_STATUS_INIT,

    summaryBillAccountCount: 0,
    summaryChildAccountCount: 0,
    summaryBillAccounts: [],
    summaryBillAccountStatus: loadingStatus.LOADING_STATUS_INIT,

    myAccountType: 'anon',

    unlinkedBillAccountList: [],
    unlinkedBillAccountListStatus: loadingStatus.LOADING_STATUS_INIT,
    selectedUnlinkedBillAccounts: {},

    compareMyPricePlan: {
        fromCmpp: false,
        selectedBusinessOffering: 0,
        isEligible: false,
        currentPlan: {
        },
        evPlan: {
        },
        comparisonPlans: [
        ]
    },
    // todo: change to init once API interface is complete
    compareMyPricePlanStatus: loadingStatus.LOADING_STATUS_INIT,

    customerNames: {
        displayName: "",
        firstName: "",
        lastName: "",
        customerName: "",
        middleName: "",
        authorizedSpouseFirstName: ""
    },
    customerNamesStatus: loadingStatus.LOADING_STATUS_INIT,

    currentRateCode: {
        description: "",
        rateCode: 0,
        isPrePay: false,
        isTimeOfUse: false,
        displayUrl: "",
        shouldDisplayOnHomePayment: true,
        shouldDisplayOnPaymentsNextSched: true,
        shouldDisplayOnMakePayments: true,
        shouldDisplayOnBankAccount: true,
        shouldDisplayCharts: true,
        shouldDisplayHourlyChart: true,
        shouldDisplayDailyChart: true,
        shouldDisplayMonthlyChart: true,
        shouldDisplayAllUsage: true,
        shouldDisplayCost: true,
        isSolar: false
    },
    currentRateCodeStatus: loadingStatus.LOADING_STATUS_INIT,

    changePricePlanStatus: loadingStatus.LOADING_STATUS_INIT,

    serviceAddress: {
        houseNumber: 0,
        addressDirection: "",
        streetName: "",
        cityName: "",
        state: "",
        zipCode: "",
        serviceAddressTypeCode: "",
        dwellingTypeCode: "",
        unitNumber: "",
        buildingNumber: "",
        uniqueCode: "",
        addressDirectionSuffix: "",
        uspsAddressTypeAbbreviation: "",
        fullStreetAddress: "",
        streetAddress: "",
        cityStateZip: ""
    },
    serviceAddressStatus: loadingStatus.LOADING_STATUS_INIT,

    billingAddress: {
        streetAddress: '',
        streetAddressLineTwo: '',
        state: '',
        city: '',
        zipcode: '',
        addressType: 0,
        country: '',
        dbaLineOne: '',
        dbaLineTwo: ''
    },
    singleLineBillingAddress: '',
    billingAddressStatus: loadingStatus.LOADING_STATUS_INIT,

    updateBillingAddressResult: {
        success: false,
        errorMessage: ''
    },
    updatebillingAddressStatus: loadingStatus.LOADING_STATUS_INIT,

    phoneNumber: {
        bestPhone: "",
        alternatePhone: ""
    },
    phoneNumberStatus: loadingStatus.LOADING_STATUS_INIT,

    accountHistory: [],
    accountHistoryStatus: loadingStatus.LOADING_STATUS_INIT,

    accountManager: {
        hasAccountManager: false,
        accountManagerCode: '',
        accountManagerTitle: '',
        firstName: '',
        middleName: '',
        lastName: '',
        fullName: '',
        emailAddress: '',
        phoneNumber: '',
        phoneExtension: ''
    },
    accountManagerStatus: loadingStatus.LOADING_STATUS_INIT,
    defaultAccountManager: {
        hasAccountManager: true,
        accountManagerTitle: 'Business Customer Center',
        fullName: 'Business Customer Center',
        phoneNumber: myAccountConstants.COMMERCIAL_CUSTOMER_SERVICE_PHONE_NUMBER_FMTTD,
        emailAddress: 'srpbiz@srpnet.com'
    },

    currentBillInfo: {
        billedAmount: 0,
        billingStatusCode: "",
        analyzeCode: "",
        accountBalanceAmount: 0,
        currentBudgetBillPaymentDue: 0,
        budgetBillBalanceAmount: 0,
        budgetBillInstallmentAmount: 0,
        paymentPlanCode: paymentConstants.PAYMENT_PLAN_CODE.NONE,
        dueDate: new Date(0),
        creditRating: ""
    },
    currentBillInfoStatus: loadingStatus.LOADING_STATUS_INIT,

    mPowerAcctDetails: {
        billAccount: 0,
        balanceRemaining: 0,
        balanceUsedYesterday: 0,
        daysRemaining: 0,
        lastReadDate: new Date(0),
        status: 0,
        dailyReadCount: 0
    },
    mPowerAcctDetailsStatus: loadingStatus.LOADING_STATUS_INIT,

    businessInfo: {
        businessName: '',
        dbaName: '',
        contactName: '',
        contactPosition: '',
        emailAddress: '',
        phoneNumber: '',
        lastFourSSN: '',
    },
    businessInfoStatus: loadingStatus.LOADING_STATUS_INIT,

    countyAssessorInfo: {
        hasCountyAssesorInfo: false,
        countyAssessor: {
            squareFeet: 0,
            stories: 0,
            poolArea: 0,
            heating: "",
            cooling: "",
            constructionYear: ""
        }
    },
    countyAssessorStatus: loadingStatus.LOADING_STATUS_INIT,

    scannableCodeBase64: "",
    scannableCodeStatus: loadingStatus.LOADING_STATUS_INIT,

    primaryContactInfoReminder: {
        needsReminder: false
    },
    primaryContactInfoReminderStatus: loadingStatus.LOADING_STATUS_INIT,

    hashBillAccountStatus: loadingStatus.LOADING_STATUS_INIT,
    hashedBillAccount: '',

    transferredAccountStatus: {
        isTransferredAccount: false,
        closedBillAccount: 0,
        newBillAccount: 0,
        newServiceAddress: {}
    },
    transferredAccountAlreadyAdded: false,
    transferredAccountStatusStatus: loadingStatus.LOADING_STATUS_INIT,
    transferredAccountSupressDisplay: false
}

const sortBillAccountList = billAccountList => {
    return billAccountList.sort((a, b) => {
        let accountTypeDifference = a.accountType - b.accountType
        if (accountTypeDifference !== 0)
            return accountTypeDifference

        if (!a.accountName && !b.accountName)
            return a.account - b.account

        if (!a.accountName)
            return 1

        if (!b.accountName)
            return -1

        return a.accountName.localeCompare(b.accountName, 'en', { numeric: true })
    })
}

const sortSummaryBillingSubAccountList = subAccountList => {
    return subAccountList.slice().sort((a, b) => {
        if (!a.accountName && !b.accountName)
            return a.billAccount - b.billAccount

        if (!a.accountName)
            return 1

        if (!b.accountName)
            return -1

        return a.accountName.localeCompare(b.accountName, 'en', { numeric: true })
    })
}

const getSelectedBillAccount = billAccountList => {
    for (let index in billAccountList) {
        let billAccountItem = billAccountList[index]
        if (billAccountItem.accountSelected === true) {
            return billAccountItem
        }
    }

    return (billAccountList.length > 0) ? billAccountList[0] : null
}

const getMyAccountType = billAccountItem => {
    if (billAccountItem === null)
        return 'power'

    if (billAccountItem.accountType === billAccountConstants.POWER_ACCOUNT)
        return 'power'
    if (billAccountItem.accountType === billAccountConstants.WATER_ACCOUNT)
        return 'water'

    return 'anon'
}

const getBillAccountDetails = (selectedAccount, billAccountList) => {
    for (let index in billAccountList) {
        let billAccountItem = billAccountList[index]
        if (billAccountItem.account === selectedAccount) {
            return billAccountItem;
        }
    }

    return (billAccountList.length > 0) ? billAccountList[0] : initialState.selectedBillAccountDetails
}

const getSingleLineBillingAddress = (billingAddress) => {
    return (billingAddress === null || billingAddress.streetAddress.length === 0)
        ? '' : `${billingAddress.streetAddress} ${billingAddress.city}, ${billingAddress.state} ${billingAddress.zipcode}`
}

const combineAccounts = (billAccountList, waterAccountList) => {
    let fullAccountList = [...billAccountList]

    waterAccountList.forEach(waterAccount => {
        fullAccountList.push(waterAccount)
    })

    return fullAccountList
}

const getSummaryBillAccounts = (billAccountList, billAccountSet, summaryBillingAccountList) => {
    let nextSummaryBillAccounts = []

    summaryBillingAccountList.forEach(parentAccount => {
        let newParent = { parentBillAccount: parentAccount.parentBillAccount, subAccounts: [] }
        parentAccount.subAccounts.forEach(subAccount => {
            let subAccountInfo = {
                ...subAccount,
                accountName: '',
                accountText: format.formatBillAccountNumber(subAccount.billAccount),
                alreadyAdded: false
            }

            if (billAccountSet.has(subAccount.billAccount)) {
                let billAccountItem = billAccountList.find(a => a.account === subAccount.billAccount)
                subAccountInfo.alreadyAdded = true
                subAccountInfo.accountName = billAccountItem.accountName
            }
            newParent.subAccounts.push(subAccountInfo)
        })
        newParent.subAccounts = sortSummaryBillingSubAccountList(newParent.subAccounts)
        nextSummaryBillAccounts.push(newParent)
    })

    return nextSummaryBillAccounts
}

const getBillAccountState = (state, billAccountList, waterAccountList, fullAccountList) => {

    const billAccountItem = getSelectedBillAccount(fullAccountList)
    const selectedBillAccount = billAccountItem !== null && billAccountItem.accountType === billAccountConstants.POWER_ACCOUNT
        ? billAccountItem.account
        : state.selectedBillAccount
    const selectedWaterAccount = billAccountItem !== null && billAccountItem.accountType === billAccountConstants.WATER_ACCOUNT
        ? billAccountItem.account
        : state.selectedWaterAccount

    let billAccountSet = new Set(billAccountList.map(baitem => baitem.account))

    let summaryBillAccountCount = 0;
    let summaryChildAccountCount = 0;

    billAccountList.forEach(account => {
        if (account.summaryBillingStatus === billAccountConstants.SUMMARY_BILLING_STATUS.PARENT) {
            summaryBillAccountCount++
        } else if (account.summaryBillingStatus === billAccountConstants.SUMMARY_BILLING_STATUS.CHILD) {
            summaryChildAccountCount++
        }
    });

    return {
        billAccountList,
        waterAccountList,
        fullAccountList,
        summaryBillAccountCount: summaryBillAccountCount,
        summaryChildAccountCount: summaryChildAccountCount,
        billAccountSet: billAccountSet,
        myAccountType: getMyAccountType(billAccountItem),
        selectBillAccountStatus: loadingStatus.LOADING_STATUS_SUCCESS,
        billAccountListStatus: loadingStatus.LOADING_STATUS_SUCCESS,
        selectedBillAccount,
        selectedWaterAccount,
        selectedBillAccountDetails: billAccountItem || initialState.selectedBillAccountDetails
    }
}

const getBillAccountTokens = (billAccountList, summaryBillingList) => {
    let billAccountTokens = {}

    billAccountList.forEach(billAccountItem => {
        billAccountTokens[`${billAccountItem.account}`] = billAccountItem.billAccountToken
    })

    summaryBillingList.forEach(summaryAccount => {
        summaryAccount.subAccounts.forEach(subAccount => {
            billAccountTokens[`${subAccount.billAccount}`] = subAccount.billAccountToken
        })
    })

    return billAccountTokens
}

export default function billAccountReducer(state = initialState, action) {
    switch (action.type) {
        case billAccountConstants.ADD_MULTIPLE_BILL_ACCOUNTS_SUCCESS: {
            return {
                ...state,
                selectedUnlinkedBillAccounts: initialState.selectedUnlinkedBillAccounts,
                unlinkedBillAccountList: initialState.unlinkedBillAccountList,
                unlinkedBillAccountListStatus: initialState.unlinkedBillAccountListStatus
            }
        }
        case billAccountConstants.ADD_BILL_ACCOUNT_SUCCESS: {
            return {
                ...state,
                selectedUnlinkedBillAccounts: initialState.selectedUnlinkedBillAccounts,
                unlinkedBillAccountList: initialState.unlinkedBillAccountList,
                unlinkedBillAccountListStatus: initialState.unlinkedBillAccountListStatus
            }
        }
        case billAccountConstants.UPDDATE_BILL_ACCOUNT_NICKNAME_SUCCESS: {
            if (action.payload.success !== true)
                return state

            let updatedBillAccountList = sortBillAccountList(state.billAccountList.map(item => {
                if (item.account !== action.meta.billAccount)
                    return item

                return { ...item, accountName: action.meta.nickname }
            }))

            let summaryBillAccounts = getSummaryBillAccounts(updatedBillAccountList,
                state.billAccountSet,
                state.summaryBillAccounts)

            return { ...state, billAccountList: updatedBillAccountList, summaryBillAccounts }
        }

        case billAccountConstants.DELETE_BILL_ACCOUNT_REQUEST: {
            if (!action.payload || !action.payload.billAccount)
                return state

            return {
                ...state, pendingDelete: {
                    ...state.pendingDelete,
                    [`${action.payload.billAccount}`]: true
                }
            }
        }
        case billAccountConstants.DELETE_BILL_ACCOUNT_SUCCESS:
        case billAccountConstants.DELETE_BILL_ACCOUNT_FAILURE: {
            let pendingDelete = { ...state.pendingDelete }
            delete pendingDelete[`${action.payload.billAccount}`]

            let nextState = { ...state, pendingDelete }
            if (action.type === billAccountConstants.DELETE_BILL_ACCOUNT_SUCCESS) {
                nextState.billAccountList = state.billAccountList.filter(item => item.account !== action.payload.billAccount)
                nextState.billAccountSet = new Set(nextState.billAccountList.map(item => item.account))
                nextState.selectedUnlinkedBillAccounts = initialState.selectedUnlinkedBillAccounts
                nextState.unlinkedBillAccountList = initialState.unlinkedBillAccountList
                nextState.unlinkedBillAccountListStatus = initialState.unlinkedBillAccountListStatus

                nextState.summaryBillAccounts = getSummaryBillAccounts(nextState.billAccountList,
                    nextState.billAccountSet,
                    state.summaryBillAccounts)
            }

            return nextState
        }
        case billAccountConstants.GET_LIST_REQUEST:
            return { ...state, billAccountListStatus: loadingStatus.LOADING_STATUS_IN_PROGRESS }
        case billAccountConstants.GET_LIST_SUCCESS: {
            let fullAccountList = combineAccounts(action.payload.billAccountList, action.payload.waterAccountList)
            fullAccountList = sortBillAccountList(fullAccountList)

            let sortedBillAccountList = sortBillAccountList(action.payload.billAccountList)
            let billAccountState = getBillAccountState(state, sortedBillAccountList, action.payload.waterAccountList, fullAccountList)
            let summaryBillAccounts = getSummaryBillAccounts(billAccountState.billAccountList,
                billAccountState.billAccountSet,
                action.payload.summaryBillingAccountList)
            let billAccountTokens = getBillAccountTokens(billAccountState.billAccountList, summaryBillAccounts)

            return { ...state, ...billAccountState, billAccountTokens, summaryBillAccounts }
        }
        case billAccountConstants.GET_LIST_FAILURE:
            return { ...state, billAccountList: [], waterAccountList: [], fullAccountList: [], billAccountSet: {}, selectedBillAccount: 0, selectedBillAccountDetails: {}, billAccountListStatus: loadingStatus.LOADING_STATUS_FAILURE }

        case billAccountConstants.GET_UNLINKED_ACCOUNTS_REQUEST:
            return { ...state, unlinkedBillAccountListStatus: loadingStatus.LOADING_STATUS_IN_PROGRESS }
        case billAccountConstants.GET_UNLINKED_ACCOUNTS_SUCCESS:
            return { ...state, unlinkedBillAccountList: action.payload.unlinkedAccounts, unlinkedBillAccountListStatus: loadingStatus.LOADING_STATUS_SUCCESS }
        case billAccountConstants.GET_UNLINKED_ACCOUNTS_FAILURE:
            return { ...state, unlinkedBillAccountList: [], unlinkedBillAccountListStatus: loadingStatus.LOADING_STATUS_FAILURE }
        case billAccountConstants.UPDATE_SELCTED_UNLINKED_ACCOUNTS:
            return { ...state, selectedUnlinkedBillAccounts: action.selectedUnlinkedBillAccounts }

        case billAccountConstants.GET_RATE_CODE_REQUEST:
            return { ...state, currentRateCodeStatus: loadingStatus.LOADING_STATUS_IN_PROGRESS }
        case billAccountConstants.GET_RATE_CODE_SUCCESS:
            return { ...state, currentRateCode: action.payload, currentRateCodeStatus: loadingStatus.LOADING_STATUS_SUCCESS }
        case billAccountConstants.GET_RATE_CODE_FAILURE:
            return { ...state, currentRateCode: { ...initialState.currentRateCode }, currentRateCodeStatus: loadingStatus.LOADING_STATUS_FAILURE }

        case billAccountConstants.GET_ADDRESS_REQUEST:
            return { ...state, serviceAddressStatus: loadingStatus.LOADING_STATUS_IN_PROGRESS }
        case billAccountConstants.GET_ADDRESS_SUCCESS:
            return { ...state, serviceAddress: action.payload, serviceAddressStatus: loadingStatus.LOADING_STATUS_SUCCESS }
        case billAccountConstants.GET_ADDRESS_FAILURE:
            return { ...state, serviceAddress: { ...initialState.serviceAddress }, serviceAddressStatus: loadingStatus.LOADING_STATUS_FAILURE }

        case billAccountConstants.GET_BILLING_ADDRESS_REQUEST:
            return { ...state, billingAddressStatus: loadingStatus.LOADING_STATUS_IN_PROGRESS }
        case billAccountConstants.GET_BILLING_ADDRESS_SUCCESS:
            return {
                ...state,
                billingAddress: action.payload,
                singleLineBillingAddress: getSingleLineBillingAddress(action.payload),
                billingAddressStatus: loadingStatus.LOADING_STATUS_SUCCESS
            }
        case billAccountConstants.GET_BILLING_ADDRESS_FAILURE:
            return {
                ...state,
                billingAddress: initialState.billingAddress,
                singleLineBillingAddress: initialState.singleLineBillingAddress,
                billingAddressStatus: loadingStatus.LOADING_STATUS_FAILURE
            }

        case billAccountConstants.UPDATE_BILLING_ADDRESS_REQUEST:
            return { ...state, updatebillingAddressStatus: loadingStatus.LOADING_STATUS_IN_PROGRESS }
        case billAccountConstants.UPDATE_BILLING_ADDRESS_SUCCESS:
            return {
                ...state,
                updateBillingAddressResult: action.payload,
                updatebillingAddressStatus: loadingStatus.LOADING_STATUS_SUCCESS,
                billingAddress: initialState.billingAddress,
                singleLineBillingAddress: initialState.singleLineBillingAddress,
                billingAddressStatus: initialState.billingAddressStatus
            }
        case billAccountConstants.UPDATE_BILLING_ADDRESS_FAILURE:
            return { ...state, updateBillingAddressResult: initialState.updateBillingAddressResult, updatebillingAddressStatus: loadingStatus.LOADING_STATUS_FAILURE }

        case billAccountConstants.GET_PHONE_REQUEST:
            return { ...state, phoneNumberStatus: loadingStatus.LOADING_STATUS_IN_PROGRESS }
        case billAccountConstants.GET_PHONE_SUCCESS:
            return { ...state, phoneNumber: action.payload, phoneNumberStatus: loadingStatus.LOADING_STATUS_SUCCESS }
        case billAccountConstants.GET_PHONE_FAILURE:
            return { ...state, phoneNumber: {}, phoneNumberStatus: loadingStatus.LOADING_STATUS_FAILURE }

        case billAccountConstants.SELECT_BILL_ACCOUNT_REQUEST:
            return { ...state, selectBillAccountStatus: loadingStatus.LOADING_STATUS_IN_PROGRESS }
        case billAccountConstants.SELECT_BILL_ACCOUNT_SUCCESS: {
            const newState = {
                ...initialState,
                pendingDelete: state.pendingDelete,
                billAccountList: state.billAccountList,
                waterAccountList: state.waterAccountList,
                fullAccountList: state.fullAccountList,
                billAccountSet: state.billAccountSet,
                billAccountTokens: state.billAccountTokens,
                billAccountListStatus: state.billAccountListStatus,
                selectBillAccountStatus: loadingStatus.LOADING_STATUS_SUCCESS,
                unlinkedBillAccountList: state.unlinkedBillAccountList,
                unlinkedBillAccountListStatus: state.unlinkedBillAccountListStatus,
                selectedUnlinkedBillAccounts: state.selectedUnlinkedBillAccounts,
                summaryBillAccounts: state.summaryBillAccounts
            }

            switch (action.payload.accountType) {
                case billAccountConstants.POWER_ACCOUNT:
                    newState.selectedBillAccount = action.payload.billAccount
                    newState.selectedBillAccountDetails = getBillAccountDetails(action.payload.billAccount, state.billAccountList)
                    newState.myAccountType = 'power'
                    break;
                case billAccountConstants.WATER_ACCOUNT:
                    newState.selectedWaterAccount = action.payload.billAccount
                    newState.myAccountType = 'water'
                    newState.selectedBillAccountDetails = getBillAccountDetails(action.payload.billAccount, state.billAccountList)
                    break;
            }

            return newState
        }
        case billAccountConstants.SELECT_BILL_ACCOUNT_FAILURE:
            return { ...state, selectBillAccountStatus: loadingStatus.LOADING_STATUS_FAILURE }
        case billAccountConstants.GET_ACCOUNT_HISTORY_REQUEST:
            return { ...state, accountHistoryStatus: loadingStatus.LOADING_STATUS_IN_PROGRESS }
        case billAccountConstants.GET_ACCOUNT_HISTORY_SUCCESS:
            return { ...state, accountHistory: action.payload.accountHistoryList, accountHistoryStatus: loadingStatus.LOADING_STATUS_SUCCESS }
        case billAccountConstants.GET_ACCOUNT_HISTORY_FAILURE:
            return { ...state, accountHistory: initialState.accountHistory, accountHistoryStatus: loadingStatus.LOADING_STATUS_FAILURE }

        case rateMetaDataConstants.GET_RATE_META_DATA_REQUEST:
            return { ...state, customerNamesStatus: loadingStatus.LOADING_STATUS_IN_PROGRESS }
        case rateMetaDataConstants.GET_RATE_META_DATA_SUCCESS:
            return {
                ...state,
                customerNames: {
                    displayName: action.payload.displayName,
                    firstName: action.payload.firstName,
                    lastName: action.payload.lastName,
                    customerName: action.payload.customerName,
                    middleName: action.payload.middleName,
                    authorizedSpouseFirstName: action.payload.authorizedSpouseFirstName
                },
                customerNamesStatus: loadingStatus.LOADING_STATUS_SUCCESS
            }
        case rateMetaDataConstants.GET_RATE_META_DATA_FAILURE:
            return { ...state, customerNames: initialState.customerNames, customerNamesStatus: loadingStatus.LOADING_STATUS_FAILURE }


        case billAccountConstants.GET_BUSINESS_INFO_REQUEST:
            return { ...state, businessInfoStatus: loadingStatus.LOADING_STATUS_IN_PROGRESS }
        case billAccountConstants.GET_BUSINESS_INFO_SUCCESS:
            return { ...state, businessInfo: action.payload, businessInfoStatus: loadingStatus.LOADING_STATUS_SUCCESS }
        case billAccountConstants.GET_BUSINESS_INFO_FAILURE:
            return { ...state, businessInfo: initialState.businessInfo, businessInfoStatus: loadingStatus.LOADING_STATUS_FAILURE }

        case billAccountConstants.GET_ACCOUNT_MANAGER_REQUEST:
            return { ...state, accountManagerStatus: loadingStatus.LOADING_STATUS_IN_PROGRESS }
        case billAccountConstants.GET_ACCOUNT_MANAGER_SUCCESS:
            return { ...state, accountManager: action.payload.hasAccountManager ? action.payload : initialState.accountManager, accountManagerStatus: loadingStatus.LOADING_STATUS_SUCCESS }
        case billAccountConstants.GET_ACCOUNT_MANAGER_FAILURE:
            return { ...state, accountManager: initialState.accountManager, accountManagerStatus: loadingStatus.LOADING_STATUS_FAILURE }

        case billAccountConstants.GET_CURRENT_BILL_INFO_REQUEST:
            return { ...state, currentBillInfoStatus: loadingStatus.LOADING_STATUS_IN_PROGRESS }
        case billAccountConstants.GET_CURRENT_BILL_INFO_SUCCESS:
            return { ...state, currentBillInfo: action.payload, currentBillInfoStatus: loadingStatus.LOADING_STATUS_SUCCESS }
        case billAccountConstants.GET_CURRENT_BILL_INFO_FAILURE:
            return { ...state, currentBillInfo: initialState.currentBillInfo, currentBillInfoStatus: loadingStatus.LOADING_STATUS_FAILURE }

        case billAccountConstants.GET_MPOWER_DETAILS_REQUEST:
            return { ...state, mPowerAcctDetailsStatus: loadingStatus.LOADING_STATUS_IN_PROGRESS }
        case billAccountConstants.GET_MPOWER_DETAILS_SUCCESS:
            return { ...state, mPowerAcctDetails: action.payload, mPowerAcctDetailsStatus: loadingStatus.LOADING_STATUS_SUCCESS }
        case billAccountConstants.GET_MPOWER_DETAILS_FAILURE:
            return { ...state, mPowerAcctDetails: initialState.mPowerAcctDetails, mPowerAcctDetailsStatus: loadingStatus.LOADING_STATUS_FAILURE }

        case billAccountConstants.GET_COUNTRY_ASSESSOR_INFO_REQUEST:
            return { ...state, countyAssessorStatus: loadingStatus.LOADING_STATUS_IN_PROGRESS }
        case billAccountConstants.GET_COUNTRY_ASSESSOR_INFO_SUCCESS:
            return { ...state, countyAssessorInfo: action.payload, countyAssessorStatus: loadingStatus.LOADING_STATUS_SUCCESS }
        case billAccountConstants.GET_COUNTRY_ASSESSOR_INFO_FAILURE:
            return { ...state, countyAssessorInfo: initialState.countyAssessorInfo, countyAssessorStatus: loadingStatus.LOADING_STATUS_FAILURE }

        case pricePlanConstants.SUBMIT_CHANGE_PRICE_PLAN_REQUEST:
            return { ...state, changePricePlanStatus: loadingStatus.LOADING_STATUS_IN_PROGRESS }
        case pricePlanConstants.SUBMIT_CHANGE_PRICE_PLAN_SUCCESS:
            return (action.payload === true)
                ? {
                    ...state,
                    changePricePlanStatus: loadingStatus.LOADING_STATUS_SUCCESS,
                    compareMyPricePlan: {
                        ...state.compareMyPricePlan,
                        fromCmpp: false,
                        selectedBusinessOffering: 0
                    }
                }
                : { ...state, changePricePlanStatus: loadingStatus.LOADING_STATUS_FAILURE }
        case pricePlanConstants.SUBMIT_CHANGE_PRICE_PLAN_FAILURE:
            return { ...state, changePricePlanStatus: loadingStatus.LOADING_STATUS_FAILURE }

        case paymentConstants.SUBMIT_PAYMENT_SUCCESS:
            return { ...state, currentBillInfoStatus: loadingStatus.LOADING_STATUS_INIT }
        case multiPaymentConstants.SUBMIT_MULTIPLE_SUCCESS:
            return { ...state, currentBillInfoStatus: loadingStatus.LOADING_STATUS_INIT }
        case giftPaymentMultiConstants.SUBMIT_GIFT_MULTIPLE_SUCCESS:
            return { ...state, currentBillInfoStatus: loadingStatus.LOADING_STATUS_INIT }

        case loginConstants.LOGIN_PROFILE_SUCCESS:
            return {
                ...state,
                myAccountType: action.meta.myAccountTypeOverride !== undefined
                    ? action.meta.myAccountTypeOverride
                    : state.myAccountType
            }
        case loginConstants.LOGOUT_SUCCESS:
            return { ...state, myAccountType: 'anon' }


        case billAccountConstants.GET_FORM_STATUS_REQUEST:
            return { ...state, blockFromTurnOffOnStatus: loadingStatus.LOADING_STATUS_IN_PROGRESS }
        case billAccountConstants.GET_FORM_STATUS_SUCCESS:
            return {
                ...state,
                selectedBlockFromTurnOffOn: action.payload,
                blockFromTurnOffOnStatus: loadingStatus.LOADING_STATUS_SUCCESS
            }
        case billAccountConstants.GET_FORM_STATUS_FAILURE:
            return {
                ...state,
                selectedBlockFromTurnOffOn: initialState.selectedBlockFromTurnOffOn,
                blockFromTurnOffOnStatus: loadingStatus.LOADING_STATUS_FAILURE
            }

        case billAccountConstants.GET_SCANNABLE_CODE_REQUEST:
            return { ...state, scannableCodeStatus: loadingStatus.LOADING_STATUS_IN_PROGRESS }
        case billAccountConstants.GET_SCANNABLE_CODE_SUCCESS:
            return { ...state, scannableCodeStatus: loadingStatus.LOADING_STATUS_SUCCESS, scannableCodeBase64: action.payload.image64 }
        case billAccountConstants.GET_SCANNABLE_CODE_FAILURE:
            return { ...state, scannableCodeStatus: loadingStatus.LOADING_STATUS_FAILURE, scannableCodeBase64: initialState.scannableCodeBase64 }

        case billAccountConstants.GET_COMPARE_MY_PRICE_PLAN_REQUEST:
            return { ...state, compareMyPricePlanStatus: loadingStatus.LOADING_STATUS_IN_PROGRESS }
        case billAccountConstants.GET_COMPARE_MY_PRICE_PLAN_SUCCESS:
            return { ...state, compareMyPricePlanStatus: loadingStatus.LOADING_STATUS_SUCCESS, compareMyPricePlan: action.payload }
        case billAccountConstants.GET_COMPARE_MY_PRICE_PLAN_FAILURE:
            return { ...state, compareMyPricePlanStatus: loadingStatus.LOADING_STATUS_FAILURE, compareMyPricePlan: initialState.compareMyPricePlan }

        case billAccountConstants.SELECT_COMPARE_MY_PRICE_PLAN_BUSINESSOFFERING:
            return {
                ...state, compareMyPricePlan: {
                    ...state.compareMyPricePlan,
                    selectedBusinessOffering: action.selectedBusinessOffering
                }
            }
        case billAccountConstants.UPDATE_CHANGE_PRICE_PLAN_FROM_PAGE:
            return {
                ...state, compareMyPricePlan: {
                    ...state.compareMyPricePlan,
                    fromCmpp: action.fromCmpp
                }
            }
        case billAccountConstants.RESET_CHANGE_MY_PRICE_PLAN:
            return {
                ...state, compareMyPricePlan: {
                    ...state.compareMyPricePlan,
                    fromCmpp: false,
                    selectedBusinessOffering: 0
                }
            }

        case primaryContactInfoReminderConstants.GET_PRIMARY_CONTACT_INFO_STATUS_REQUEST:
            return { ...state, primaryContactInfoReminderStatus: loadingStatus.LOADING_STATUS_IN_PROGRESS }
        case primaryContactInfoReminderConstants.GET_PRIMARY_CONTACT_INFO_STATUS_SUCCESS:
            return { ...state, primaryContactInfoReminder: action.payload.primaryContactInfoReminder, primaryContactInfoReminderStatus: loadingStatus.LOADING_STATUS_SUCCESS}
        case primaryContactInfoReminderConstants.GET_PRIMARY_CONTACT_INFO_STATUS_FAILURE:
            return { ...state, primaryContactInfoReminder: initialState.primaryContactInfoReminder, primaryContactInfoReminderStatus: loadingStatus.LOADING_STATUS_FAILURE}

        case billAccountConstants.GET_TRANSFERRED_ACCOUNT_STATUS_REQUEST:
            return { ...state, transferredAccountStatusStatus: loadingStatus.LOADING_STATUS_IN_PROGRESS }
        case billAccountConstants.GET_TRANSFERRED_ACCOUNT_STATUS_SUCCESS:
            return { ...state, transferredAccountStatus: action.payload,
                transferredAccountAlreadyAdded: state.fullAccountList.find(e => e.account === action.payload.newBillAccount) === undefined ? false : true,
                transferredAccountStatusStatus: loadingStatus.LOADING_STATUS_SUCCESS }
        case billAccountConstants.GET_TRANSFERRED_ACCOUNT_STATUS_FAILURE:
            return { ...state, transferredAccountStatus: initialState.transferredAccountStatus, transferredAccountStatusStatus: loadingStatus.LOADING_STATUS_FAILURE }
        case billAccountConstants.DISMISS_TRANSFERRED_ACCOUNT_PROMPT:
            return { ...state, transferredAccountSupressDisplay: true }

        case billAccountConstants.HASH_BILL_ACCOUNT_REQUEST:
            return { ...state, hashBillAccountStatus: loadingStatus.LOADING_STATUS_IN_PROGRESS, hashedBillAccount: '', }
        case billAccountConstants.HASH_BILL_ACCOUNT_SUCCESS: {
            const newState = {
                ...state,
                hashBillAccountStatus: loadingStatus.LOADING_STATUS_SUCCESS,
                hashedBillAccount: action.payload,
            }
            return newState
        }
        case billAccountConstants.HASH_BILL_ACCOUNT_FAILURE:
            return { ...state, hashBillAccountStatus: loadingStatus.LOADING_STATUS_FAILURE, hashedBillAccount: '', }
    
        default:
            return state
    }
}