import { RSAA } from 'redux-api-middleware'
import { primaryContactInfoReminderConstants } from '../../../constants/primary-contact-info-reminder-constants'
import utils from '../../../srp_modules/utils'

export const primaryContactInfoReminderCreate = billAccount => dispatch => {
    return dispatch({
        [RSAA]: {
            endpoint: utils.createUrl('primarycontacts/primarycontactinfostatuscreate', { billAccount: billAccount }),
            method: 'POST',
            credentials: 'include',
            types: [
                primaryContactInfoReminderConstants.PRIMARY_CONTACT_INFO_STATUS_CREATE_REQUEST,
                primaryContactInfoReminderConstants.PRIMARY_CONTACT_INFO_STATUS_CREATE_SUCCESS,
                primaryContactInfoReminderConstants.PRIMARY_CONTACT_INFO_STATUS_CREATE_FAILURE
            ]
        }
    })
}

export const getPrimaryContactInfoReminder = billAccount => dispatch => {
    return dispatch({
        [RSAA]: {
            endpoint: utils.createUrl('primarycontacts/getprimarycontactinfostatus', { billAccount: billAccount }),
            method: 'GET',
            credentials: 'include',
            types: [
                primaryContactInfoReminderConstants.GET_PRIMARY_CONTACT_INFO_STATUS_REQUEST,
                primaryContactInfoReminderConstants.GET_PRIMARY_CONTACT_INFO_STATUS_SUCCESS,
                primaryContactInfoReminderConstants.GET_PRIMARY_CONTACT_INFO_STATUS_FAILURE
            ]
        }
    })
}

export const setPrimaryContactInfoReminderHasUpdated = billAccount => dispatch => {
    return dispatch({
        [RSAA]: {
            endpoint: utils.createUrl('primarycontacts/setprimarycontactinfohasupdated', { billAccount: billAccount }),
            method: 'POST',
            credentials: 'include',
            types: [
                primaryContactInfoReminderConstants.SET_PRIMARY_CONTACT_INFO_STATUS_HAS_UPDATED_REQUEST,
                primaryContactInfoReminderConstants.SET_PRIMARY_CONTACT_INFO_STATUS_HAS_UPDATED_SUCCESS,
                primaryContactInfoReminderConstants.SET_PRIMARY_CONTACT_INFO_STATUS_HAS_UPDATED_FAILURE
            ]
        }
    })
}

export const setPrimaryContactInfoReminderWasReminded = billAccount => dispatch => {
    return dispatch({
        [RSAA]: {
            endpoint: utils.createUrl('primarycontacts/setprimarycontactinfowasreminded', { billAccount: billAccount }),
            method: 'POST',
            credentials: 'include',
            types: [
                primaryContactInfoReminderConstants.SET_PRIMARY_CONTACT_INFO_STATUS_WAS_REMINDED_REQUEST,
                primaryContactInfoReminderConstants.SET_PRIMARY_CONTACT_INFO_STATUS_WAS_REMINDED_SUCCESS,
                primaryContactInfoReminderConstants.SET_PRIMARY_CONTACT_INFO_STATUS_WAS_REMINDED_FAILURE
            ]
        }
    })
}