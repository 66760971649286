import React from 'react'
import { withTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import { PurchaseDetailsStyledDiv, classes } from '../common/purchase-details-styles'

import PurchaseDetailsLine from '../common/purchase-details-line'
import PurchaseDetailsPanel from '../common/purchase-details-panel'

const SinglePurchaseDetailsTablePure = ({ purchaseList, bankName, truncatedBankAccountNumber, referenceNumber, amountOwedToSRP, payDownFraction, t }) => (
    <PurchaseDetailsStyledDiv>
        <div>
            <h5 className="text-muted srp-olive-green mb-0 pl-3 pt-3 d-lg-none">{t("Reference number")} {referenceNumber}</h5>
            <h4 className="text-muted srp-olive-green mb-0 pl-3 pt-3 d-none d-lg-block">{t("Reference number")} {referenceNumber}</h4>
        </div>

        <div className="px-3 pt-4">
            <h4 className="text-secondary">{t("Bank details")}</h4>
            <div className="d-none d-lg-block">
                <div className="d-flex flex-row align-items-center mt-4">
                    <h6 className={`${classes.column} ${classes.secondaryText} mb-0`}>{t("Bank account")}</h6>
                    <h6 className={`${classes.twoColumns} mb-0`}>{`${bankName} (****${truncatedBankAccountNumber})`}</h6>
                </div>
            </div>
            <div className="d-lg-none d-flex justify-content-between mt-4">
                <h6 className={`${classes.secondaryText} mb-0`}>{t("Bank account")}</h6>
                <h6 className="mb-0 text-right">{`${bankName} (****${truncatedBankAccountNumber})`}</h6>
            </div>
        </div>
        <hr />

        <div className="px-3">
            <h4 className="text-secondary">{t("Purchase details")}</h4>
            {amountOwedToSRP <= 0 ?
                <PurchaseDetailsLine srpAccountNumber={purchaseList[0].srpAccountNumber} purchaseAmount={purchaseList[0].purchaseAmount} purchaseDate={new Date()} /> :
                <PurchaseDetailsPanel srpAccountNumber={purchaseList[0].srpAccountNumber} purchaseAmount={purchaseList[0].purchaseAmount} applyWholePurchaseTowardsArrears={purchaseList[0].payAmountOwedToSRP} purchaseDate={new Date()} amountOwedToSRP={amountOwedToSRP} payDownFraction={payDownFraction} defaultExpanded={false} />
            }
        </div>
        <hr />
    </PurchaseDetailsStyledDiv>
)

SinglePurchaseDetailsTablePure.propTypes = {
    purchaseList: PropTypes.arrayOf(PropTypes.shape({
        srpAccountNumber: PropTypes.string.isRequired,
        purchaseAmount: PropTypes.number.isRequired,
        payAmountOwedToSRP: PropTypes.bool.isRequired
    })),
    bankName: PropTypes.string.isRequired,
    truncatedBankAccountNumber: PropTypes.string.isRequired,
    referenceNumber: PropTypes.number.isRequired,
    amountOwedToSRP: PropTypes.number.isRequired,
    payDownFraction: PropTypes.number.isRequired,

    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired
}

const SinglePurchaseDetailsTable = withTranslation("singlePurchasePage")(SinglePurchaseDetailsTablePure)
export { SinglePurchaseDetailsTable as default, SinglePurchaseDetailsTablePure }