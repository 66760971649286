import React from 'react'
import { withTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import '../../../styles/dashboard-page.css'

import CallAnytime from '../../myaccount_header/call-anytime'

import ConfirmPurchaseDetailsCardContainer from './confirm-purchase-details-card-container'

const SinglePurchaseConfirmationPagePure = ({t}) => (
    <div className="container py-3">
        <CallAnytime />

        <h3 className="mt-3 mb-5">{t("Make a purchase")}</h3>

        <div className="row">
            <div className="col-lg-6">
                <ConfirmPurchaseDetailsCardContainer />
            </div>
        </div>
    </div>
)

SinglePurchaseConfirmationPagePure.propTypes = {
    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired
}

const SinglePurchaseConfirmationPage = withTranslation("singlePurchasePage")(SinglePurchaseConfirmationPagePure)
export { SinglePurchaseConfirmationPage as default, SinglePurchaseConfirmationPagePure }