import {accountHistoryConstants} from '../../../constants/account-history-constants'
import {billAccountConstants} from '../../../constants/bill-account-constants'
import * as loadingStatus from '../../../constants/loading-status-constants'

const initialState = {
    accountHistoryList: [],
    budgetBillingEstimate: 0,
    accountHistoryStatus: loadingStatus.LOADING_STATUS_INIT
}

export default function accountHistoryReducer(state = initialState, action){
    switch(action.type){
        case accountHistoryConstants.GET_ACCOUNT_HISTORY_REQUEST:
            return {
                    ...state,
                    accountHistoryStatus: loadingStatus.LOADING_STATUS_IN_PROGRESS
                }
        case accountHistoryConstants.GET_ACCOUNT_HISTORY_SUCCESS:
            return{
                ...state,
                accountHistoryList: action.payload.accountHistoryList,
                budgetBillingEstimate: action.payload.bbpEstimate,
                accountHistoryStatus: loadingStatus.LOADING_STATUS_SUCCESS
            }
        case accountHistoryConstants.GET_ACCOUNT_HISRORY_FAILURE:
            return{
                ...state,
                accountHistoryList: [],
                budgetBillingEstimate: initialState.budgetBillingEstimate,
                accountHistoryStatus: loadingStatus.LOADING_STATUS_FAILURE
            }
        case billAccountConstants.SELECT_BILL_ACCOUNT_SUCCESS:
            return initialState
        default:
            return state 
    }
}