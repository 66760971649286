import { giftPaymentBankInfoConstants } from '../../../constants/gift/gift-payment-bank-info-constants'
import { giftPaymentApiConstants } from '../../../constants/gift/gift-payment-api-constants'

const initialState = {
    bankFormOpen: false,

    bankFirstName: '',
    bankLastName: '',
    bankPhoneNumber: '',
    bankEmailAddress: '',
    bankRoutingNumber: '',
    bankNickname: '',
    bankAccountNumberLeadingZeroesAreSignificant: '',

    selectedBankId: 0
}

export default function giftPaymentBankInfoReducer(state = initialState, action) {
    switch (action.type) {
        case giftPaymentBankInfoConstants.GIFT_PAYMENT_STORE_BANK_INFO_COLLECTED:
            return {
                ...state,
                bankFirstName: action.bankFirstName,
                bankLastName: action.bankLastName,
                bankPhoneNumber: action.bankPhoneNumber,
                bankEmailAddress: action.bankEmailAddress,
                bankRoutingNumber: action.bankRoutingNumber,
                bankAccountNumberLeadingZeroesAreSignificant: action.bankAccountNumberLeadingZeroesAreSignificant,
                bankNickname: action.bankNickname
            }
        case giftPaymentBankInfoConstants.GIFT_PAYMENT_CLEAR_BANK_INFO:
            return { ...state, ...initialState }

        case giftPaymentBankInfoConstants.SET_BANK_ID_FOR_GIFT_PAYMENT:
            return { ...state, selectedBankId: action.selectedBankId }

        case giftPaymentBankInfoConstants.GIFT_PAYMENT_SET_BANK_FORM_OPEN:
            return { ...state, bankFormOpen: true }
        case giftPaymentBankInfoConstants.GIFT_PAYMENT_CLEAR_BANK_FORM_OPEN:
            return { ...state, bankFormOpen: false }
        case giftPaymentApiConstants.VERIFY_GIFT_PAYMENT_LIST_SUCCESS:
            return {
                ...state,
                bankFormOpen: false,
                bankRoutingNumber: '',
                bankAccountNumberLeadingZeroesAreSignificant: '',
                bankNickname: '',
            }

        default:
            return state
    }
}