import {formConstants} from '../../constants/form-constants'
import {pricePlanConstants} from '../../constants/price-plan-constants'
import format from '../../srp_modules/format'
import { DateTime } from 'luxon'
import { formatDayWithOrdinal } from '../../srp_modules/utils'

export const getPlanDescription = (selectedPlan) => {
  switch (selectedPlan) {
    case formConstants.EZ_3:
    case pricePlanConstants.PRICE_PLAN_RATE_CODE_MAP.EZ3:
      return "EZ-3 (3-6 pm)"
    case formConstants.EZ_34:
    case pricePlanConstants.PRICE_PLAN_RATE_CODE_MAP.EZ3_4_7:
      return "EZ-3 (4-7 pm)"
    case formConstants.TIME_OF_USE:
    case pricePlanConstants.PRICE_PLAN_RATE_CODE_MAP.TOU:
      return "Time-of-Use"
    case formConstants.ELECTRIC_VEHICLE:
    case pricePlanConstants.PRICE_PLAN_RATE_CODE_MAP.EV:
      return "Electric Vehicle"
    case formConstants.BASIC:
    case pricePlanConstants.PRICE_PLAN_RATE_CODE_MAP.BASIC:
      return "Basic"
    case formConstants.CUSTOMER_GENERATION:
    case pricePlanConstants.PRICE_PLAN_RATE_CODE_MAP.CUSTOMER_GENERATION:
      return "Customer Generation"
    case formConstants.MPOWER:
    case pricePlanConstants.PRICE_PLAN_RATE_CODE_MAP.MPOWER:
      return "M-Power"
    default:
      return selectedPlan
  }
}

export const getSolarChoiceDescription = (solarChoice) => {
  switch (solarChoice) {
    case formConstants.SOLARCHOICE_100:
      return "100%"
    case formConstants.SOLARCHOICE_50:
      return "50%"
    case formConstants.SOLARCHOICE_NONE:
      return "0%"
    default:
      return solarChoice
  }
}

export const getCustomDueDate = (dueDate, selectedPlan) => (
  (dueDate === 0 || selectedPlan === formConstants.MPOWER)
    ? "N/A"
    : formatDayWithOrdinal(dueDate,"d")
)

export const getCustomDueDateXfer = (currentlyEnrolledInCustomDueDate, maintainCustomDueDate, dueDate) => {
  if (!currentlyEnrolledInCustomDueDate)
    return "N/A"

  if (maintainCustomDueDate)
    return "N/A"

  return (dueDate === 0)
    ? ""
    : formatDayWithOrdinal(dueDate,"d")
}

export const getOwnershipType = (ownershipType) => {
  switch (ownershipType) {
    case formConstants.VEHICLE_OWN:
      return "own"
    case formConstants.VEHICLE_LEASE:
      return "lease"
    default:
      return ownershipType
  }
}

export const getLanguageDescription = (selectedLang) => {
  switch (selectedLang) {
    case formConstants.ENGLISH:
      return "English"
    case formConstants.SPANISH:
      return "Spanish"
    default:
      return selectedLang
  }
}

export const mapPlanToServerEnum = (selectedPlan) => {
  switch (selectedPlan) {
    case formConstants.EZ_3:
    case pricePlanConstants.PRICE_PLAN_RATE_CODE_MAP.EZ3:
      return 1
    case formConstants.EZ_34:
    case pricePlanConstants.PRICE_PLAN_RATE_CODE_MAP.EZ3_4_7:
      return 2
    case formConstants.TIME_OF_USE:
    case pricePlanConstants.PRICE_PLAN_RATE_CODE_MAP.TOU:
      return 3
    case formConstants.BASIC:
    case pricePlanConstants.PRICE_PLAN_RATE_CODE_MAP.BASIC:
      return 4
    case formConstants.MPOWER:
    case pricePlanConstants.PRICE_PLAN_RATE_CODE_MAP.MPOWER:
      return 5
    case formConstants.ELECTRIC_VEHICLE:
    case pricePlanConstants.PRICE_PLAN_RATE_CODE_MAP.EV:
      return 6
    case formConstants.CUSTOMER_GENERATION:
    case pricePlanConstants.PRICE_PLAN_RATE_CODE_MAP.CUSTOMER_GENERATION:
      return 7
    case pricePlanConstants.PRICE_PLAN_RATE_CODE_MAP.EZ3_2_5:
      return 8
  }
}

export const mapLanguage = (selectedLang) => {
  switch (selectedLang) {
    case formConstants.ENGLISH:
      return 0
    case formConstants.SPANISH:
      return 1
  }
}

export const mapChargingSupplyType = (supplyConst) => {
  switch (supplyConst){
    case formConstants.SUPPLY_TYPE_1:
      return "Type 1"
    case formConstants.SUPPLY_TYPE_2:
      return "Type 2"
    case formConstants.SUPPLY_DONT_KNOW:
      return "I don't know"
  }
}

export const mapChargingVoltage = (voltageConst) => {
  switch (voltageConst){
    case formConstants.INPUT_VOLTAGE_120:
      return "120v"
    case formConstants.INPUT_VOLTAGE_220:
      return "220v"
    case formConstants.VOLTAGE_DONT_KNOW:
      return "I don't know"
  }
}

export const mapChargingAmps = (ampsConst) => {
  switch (ampsConst) {
    case formConstants.AMPS_15:
      return "15 amps"
    case formConstants.AMPS_20:
      return "20 amps"
    case formConstants.AMPS_DONT_KNOW:
      return "I don't know"
  }
}

export const mapIdentificationType = (idType) => {
  switch (idType) {
    case formConstants.SOCIAL:
      return 1
    case formConstants.DRIVERS_LICENSE:
      return 2
    case formConstants.STATE_ID:
      return 3
    case formConstants.GOVERNMENT_ID:
      return 4
    case formConstants.MILITARY_ID:
      return 5
    case formConstants.RESIDENCY_ID:
      return 6
    case formConstants.NATURALIZATION:
      return 7
    case formConstants.PASSPORT:
      return 8
    case formConstants.MEXICAN_VOTER:
      return 9
    case formConstants.TEMP_WORK_AUTH:
      return 10
    case formConstants.ITIN:
      return 11
  }
}

export const mapToServerStruct = (formState, isLoggedIn, currentAccountInfo) => {
  // Change the appropiate property based on the selected identification type
  return {
    customerInfoModel:{
      firstName: formState.firstName,
      middleName: formState.middleName,
      lastName: formState.lastName,
      emailAddress: formState.email,
      phoneNumber: format.formatPhoneNumber(formState.phoneNumber)
    },
    currentServiceAddress: {
      streetAddress: currentAccountInfo.serviceAddress.streetAddress,
      city: currentAccountInfo.serviceAddress.cityName
    },
    webTurnOnRequestModel: {
      serviceAddress1: formState.address1,
      serviceAddress2: formState.address2,
      serviceCity: formState.city,
      mailingBillingAddressSame: formState.isMailingAddressSame,
      mailingAddress1: formState.isMailingAddressSame ? "" : formState.mailingAddress1,
      MailingAddress2: formState.isMailingAddressSame ? "" : formState.mailingAddress2,
      mailingState: formState.isMailingAddressSame ? "" : formState.mailingState,
      mailingCity: formState.isMailingAddressSame ? "" : formState.mailingCity,
      mailingCountry: formState.isMailingAddressSame ? "" : formState.mailingCountry,
      mailingZipCode: formState.isMailingAddressSame ? "" : formState.mailingZipCode,

      identificationType: mapIdentificationType(formState.identificationType),

      electricVehicleMake: formState.carMake,
      electricVehicleModel: formState.carModel,
      electricVehicleYear: formState.carYear,
      electricVehicleOwnOrLease: formState.ownershipType,
      electricVehicleChargingSupplyType: mapChargingSupplyType(formState.supplyEquipment),
      electricVehicleChargingInputVoltage: mapChargingVoltage(formState.inputVoltage),
      electricVehicleChargingAmps: mapChargingAmps(formState.inputCurrent),

      identificationIsNotSsn: formConstants.SOCIAL !== formState.identificationType,
      identificationId: formState.identificationNumber,
      callNextTwoHours: true,
      callBackWindow: "",
      authorizeSpouse: formState.authorizeSpouse,
      spouseFirstName: formState.authorizeSpouse ? formState.spouseFirstName : "",
      spouseLastName: formState.authorizeSpouse ? formState.spouseLastName : "",
      startDate: DateTime.fromJSDate(formState.startDate).toFormat('M/d/yyyy'),
      customBillDueDate: formatDayWithOrdinal(formState.dayOfMonth),
      eBill: formState.isPaperlessBilling
    },
    languageModel: {
      language: mapLanguage(formState.preferredLanguage)
    },
    serviceRequestTypeModel: {
      serviceType: isLoggedIn ? formConstants.ADDITIONAL_SERVICE : formConstants.START_SERVICE
    },
    srpPlanOfferingModel: {
      planOffering: mapPlanToServerEnum(formState.selectedPlan),
      solarChoicePlan: formState.selectedSolarChoicePlan
    },
    billAccountSelectorModel: {
      selectedBillAccount: currentAccountInfo.selectedBillAccount
    },
  }
}