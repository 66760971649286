import { guestCreditCardLinkConstants } from '../../../constants/guest/guest-credit-card-link-constants'
import { RSAA } from 'redux-api-middleware'
import config from 'my-account-config'

export const getGuestCreditCardPaymentUrl = () => {
    return (dispatch, getState) => {
        const paymentUrl = getState().guestCreditCardLink.guestCreditCardPaymentUrl
        if (paymentUrl !== '' && paymentUrl !== '#')
            return

        return dispatch({
            [RSAA]: {
                endpoint: config.apiBaseUrl + 'api/payments/getguestcreditcardpaymenturl',
                method: 'GET',
                types: [
                    guestCreditCardLinkConstants.GET_GUEST_CREDIT_CARD_PAYMENT_URL_REQUEST,
                    guestCreditCardLinkConstants.GET_GUEST_CREDIT_CARD_PAYMENT_URL_SUCCESS,
                    guestCreditCardLinkConstants.GET_GUEST_CREDIT_CARD_PAYMENT_URL_FAILURE
                ]
            }            
        })
    }
}