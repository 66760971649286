import React from 'react'
import PropTypes from 'prop-types'
import { Link, useNavigate } from 'react-router-dom'

import { Trans } from 'react-i18next'

import AccountInfoBarContainer from '../../myaccount_header/info_bar/account-info-bar-container'
import CallAnytime from '../../myaccount_header/call-anytime'

import SuccessCheck from '../../../images/common/SuccessCheck.png'

import utils from '../../../srp_modules/utils'

import * as loadingStatus from '../../../constants/loading-status-constants'

const BudgetBillingConfirmation = ({ submitBudgetBillingStatus, primaryEmail, isCommercial, t, i18n }) => {
    let navigate = useNavigate()
    let goBack = () => {
        navigate(-1)
    }

    const isSpanish = (i18n.language === "es")
    const supportNumber = utils.GetCustomerServicePhoneNumber(isCommercial, isSpanish)
    const supportNumberFmt = utils.GetCustomerServicePhoneNumberFmt(isCommercial, isSpanish)

    return (
        <div>
            <div className="container py-3">
                <CallAnytime />
                <h3 className="d-lg-block d-none mt-3 mb-4 pb-4">{t("Sign up for Budget Billing")}</h3>
                <h3 className="d-lg-none d-block mt-3 mb-4">{t("Sign up for Budget Billing")}</h3>

                <AccountInfoBarContainer disableSelector={false} />

                {submitBudgetBillingStatus !== loadingStatus.LOADING_STATUS_FAILURE
                    ? <div className="row">
                        <div className="col-lg-7 col-12">
                            <div className="srp-card-header">{t("Budget Billing plan request submitted")}</div>
                            <div className="srp-card-body text-muted">
                                <div className="srp-card-details">
                                    <div className="d-lg-block d-none">
                                        <div className="mb-3 srp-olive-green d-flex align-items-center">
                                            <img className="img-fluid mr-3" src={SuccessCheck} style={{ width: '50px' }} />
                                            <span style={{ fontSize: '1.75rem', fontWeight: '500' }}>{t("You're all set!")}</span>
                                        </div>
                                    </div>
                                    <div className="d-lg-none d-block">
                                        <div className="mb-3 srp-olive-green text-center">
                                            <img className="img-fluid mr-3" src={SuccessCheck} style={{ width: '50px' }} />
                                            <div style={{ fontSize: '1.75rem', fontWeight: '500' }}>{t("You're all set!")}</div>
                                        </div>
                                    </div>

                                    <div className="mb-3">
                                        <Trans i18nKey="Your_request_has_been_submitted" t={t}>
                                            Your request has been submitted. A copy of the request has been sent to <span className="font-weight-bold">{{email: primaryEmail.toLowerCase()}}</span>
                                        </Trans>
                                    </div>
                                    <div className="mb-4">{t("SRP_will_process_within_1_business_day")}</div>

                                    <div className="d-flex flex-wrap justify-content-end">
                                        <Link to="/myaccount/dashboard" className="btn srp-btn btn-lightblue">{t("Go to dashboard")}</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    : <div className="row">
                        <div className="col-lg-7 col-12">
                            <div className="srp-card-header">{t("Unable to sign up for Budget Billing")}</div>
                            <div className="srp-card-body text-muted">
                                <div className="srp-card-details">
                                    <div className="mb-3">{t("unable_to_submit_bbp_application")}</div>
                                    <p className="d-lg-block d-none">
                                        {t("try_again_or_call_SRP_desktop", {phoneNumber: supportNumberFmt})}
                                    </p>
                                    <p className="d-lg-none d-block">
                                        <Trans i18nKey="try_again_or_call_SRP_mobile" t={t}>
                                        Please try again later or call SRP Customer Service at
                                        <a href={"tel:" + supportNumber}>{{phoneNumber: supportNumberFmt}}</a>,
                                        available to serve you 24 hours a day, seven days a week.
                                        </Trans>
                                    </p>

                                    <div className="d-flex flex-wrap justify-content-end">
                                        <button className="btn srp-btn btn-lightblue" onClick={goBack}>{t("Go back")}</button>
                                        <Link to="/myaccount/dashboard" className="ml-2 btn srp-btn btn-lightblue">{t("Go to dashboard")}</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}

BudgetBillingConfirmation.propTypes = {
    primaryEmail: PropTypes.string.isRequired,
    submitBudgetBillingStatus: PropTypes.string,
    isCommercial: PropTypes.bool,

    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired,
}

export default BudgetBillingConfirmation