import { dailyUsageChartTypeConstants } from '../../constants/daily-usage-chart-type-constants'

const formatUsageModule = {
    formatNumberByUsageType
}

function formatNumberByUsageType(usageType, usageNumber) {
    if(usageType === dailyUsageChartTypeConstants.COST)
        return `$${((usageNumber).toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
    else
        return usageNumber.toLocaleString('US-EN')
}

export default formatUsageModule