import React from 'react'
import PropTypes from 'prop-types'

import Card from '@mui/material/Card'
import CheckDiagram from '../../../images/payment/check-diagram.png'
import CheckDiagramMobile from '../../../images/payment/check-diagram-mobile.png'
import CheckDiagramSpanish from '../../../images/payment/check-diagram-es.png'
import CheckDiagramMobileSpanish from '../../../images/payment/check-diagram-mobile-es.png'

const CheckDiagramCard = ({t, i18n}) => (
    <div>
        <label className="h5">{t("Check diagram")}</label>

        <Card>
            <img src={i18n.language==='es' ? CheckDiagramMobileSpanish : CheckDiagramMobile} className="mx-auto d-block img-fluid d-xl-none" />
            <img src={i18n.language==='es' ? CheckDiagramSpanish : CheckDiagram} className="d-none d-xl-block" />
        </Card>
    </div>
)

CheckDiagramCard.propTypes = {
    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired,
}

export default CheckDiagramCard