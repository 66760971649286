/* eslint react/no-multi-comp: "off" */

import React from 'react'
import { NumericFormat } from 'react-number-format'

const format = {
    zipcode: zipcode,
    bankAccount: bankAccount,
    maskBankAccountNumber: maskBankAccountNumber,
    formatBillAccountNumber: formatBillAccountNumber,
    srpAccountNumber: srpAccountNumber,
    formatUsdAmount: formatUsdAmount,
    formatDollarAmount: formatDollarAmount,
    formatPhoneNumber: formatPhoneNumber,
    formatNumber: formatNumber,
    formatRoutingNumber: formatRoutingNumber
}

function zipcode(zipcode) {
    let zip = ''+zipcode

    if (zip.length < 5)
        zip = ('0').repeat(5-zip.length) + zip

    if (zip.length === 5) {
        return zip
    } else {
        return zip.substring(0,5)+"-"+zip.substring(5)
    }
}

function bankAccount(bankAccount) {
    if (bankAccount.nickName) {
        return bankAccount.nickName + ' (****' + bankAccount.accountNumber + ')'
    } else {
        return bankAccount.bankName + ' (****' + bankAccount.accountNumber + ')'
    }
}

function maskBankAccountNumber(bankAccountNumber) {
    // Only string allowed because leading zeros are significant.
    if (typeof bankAccountNumber !== "string")
        return bankAccountNumber

    const _bankAccountNumber = bankAccountNumber.trim()

    if (/^\d+$/.test(_bankAccountNumber) === false)
        return bankAccountNumber


    // Mask the bank account number (BAN) so that only the last four digits show.
    // Cases:
    //   If BAN is only 1 digit long, hide it.
    //   If BAN is 2 or 3 digits long, show the last digit.
    //   If BAN is 4 or 5 digits long, show the last 2 digits.
    //   If BAN is 6 or 7 digits long, show the last 3 digits.
    //   If BAN is 8 digits or longer, show only the last 4 digits.
    // Then left-pad the digits with *'s so that the total length is always 8.

    const numDigitsToShow = Math.min(4, Math.floor(_bankAccountNumber.length / 2))
    const numXsToShow = 8 - numDigitsToShow
    const maskedBankAccountNumber = '*'.repeat(numXsToShow) + _bankAccountNumber.substring(_bankAccountNumber.length-numDigitsToShow)
    return maskedBankAccountNumber
}

function formatBillAccountNumber(srpAccountNumber)
{
    if (Number.isInteger(srpAccountNumber)) {
        if (srpAccountNumber < 0)
            return srpAccountNumber
        const _srpAccountNumber = '' + srpAccountNumber
        if (_srpAccountNumber.length > 9)
            return srpAccountNumber
        srpAccountNumber = _srpAccountNumber
    }

    if (typeof srpAccountNumber !== "string")
        return srpAccountNumber

    let _srpAccountNumber = srpAccountNumber.trim()

    if (_srpAccountNumber.length === 0 || _srpAccountNumber.length > 9)
        return srpAccountNumber

    if (_srpAccountNumber.length < 9)
        _srpAccountNumber = '0'.repeat(9-_srpAccountNumber.length) + _srpAccountNumber

    if (/^\d{9}$/.test(_srpAccountNumber) === false)
        return srpAccountNumber

    const srpAccountNumberDisplay = _srpAccountNumber.substring(0, 3) + "-" + _srpAccountNumber.substring(3, 6) + "-" + _srpAccountNumber.substring(6)
    return srpAccountNumberDisplay
}

function srpAccountNumber(accountNumber) {
    let accountNumberString = '0000000' + accountNumber

    return accountNumberString.substring(accountNumberString.length - 9)
}

function formatDollarAmount(dollarAmount, decimalScale = 2) {
    return (<NumericFormat
        value={dollarAmount}
        valueIsNumericString={true}
        displayType="text"
        thousandSeparator={true}
        prefix={'$'}
        decimalScale={decimalScale}
        fixedDecimalScale={true}
        />)
}

function formatUsdAmount(dollarAmount) {
    const currencyFormatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2
    })

    return currencyFormatter.format(Number(dollarAmount))
}

function formatNumber(num, decimalScale = 0) {
    return (<NumericFormat
        value={num}
        valueIsNumericString={true}
        displayType="text"
        thousandSeparator={true}
        decimalScale={decimalScale}
        fixedDecimalScale={true}
        />)
}

function formatPhoneNumber(phoneNumber) {
    let cleanNumber = ('' + phoneNumber).replace(/\D/g, '')
    let match = cleanNumber.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
    if (match) {
      let intlCode = (match[1] ? '+1 ' : '')
      let formattedNumber = intlCode + '(' + match[2] + ') ' + match[3] + '-' + match[4]
      return formattedNumber
    }
    return phoneNumber
  }

function formatRoutingNumber(routingNumber) {
    let rountingNumAsString = routingNumber.toString()
    let formattedRoutingNumber = rountingNumAsString
    if (rountingNumAsString.length < 9)
        formattedRoutingNumber = '0'.repeat(9-rountingNumAsString.length) + rountingNumAsString

    return formattedRoutingNumber
}

export default format