import React from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import TextField from '@mui/material/TextField'
import IconButton from '@mui/material/IconButton'
import Icon from '@mui/material/Icon'

import validate from '../../srp_modules/validate'

import '../../styles/dashboard-page.css'
import '../../styles/login-page.css'

const PasswordValidation = ({ showPassword, passwordLabel, password, passwordError, passwordErrorText, passwordOnChange, passwordOnBlur, togglePasswordVisibilityOnClick,
    confirmPasswordLabel, confirmPassword, confirmPasswordError, confirmPasswordErrorText, confirmPasswordOnChange, confirmPasswordOnBlur, t }) => {

    let [ passwordTouched, updatePasswordTouched ] = React.useState(false)
    let updatePasswordTouchedWithCallback = callback => event => {
        updatePasswordTouched(true)
        callback(event)
    }

    let requirementUnmet = {
        icon: 'cancel',
        iconStyle: 'material-icons srp-red-color',
        textStyle: 'ml-2 mr-4 srp-red-color',
        styling: {}
    }
    let requirementMet = {
        icon: 'check_circle',
        iconStyle: 'material-icons srp-olive-green',
        textStyle: 'ml-2 mr-4 srp-olive-green',
        styling: {}
    }
    let skipRequirementCheck = {
        icon: 'info',
        iconStyle: 'material-icons text-muted',
        textStyle: 'ml-2 mr-4 text-muted',
        styling: {}
    }

    let lengthStyle = passwordTouched ? (validate.passwordLength(password) ? requirementMet : requirementUnmet) : skipRequirementCheck
    let letterStyle = passwordTouched ? (validate.passwordLetter(password) ? requirementMet : requirementUnmet) : skipRequirementCheck
    let numberStyle = passwordTouched ? (validate.passwordNumber(password) ? requirementMet : requirementUnmet) : skipRequirementCheck

    return (
        <>
            <TextField
                fullWidth
                type={showPassword ? 'text' : 'password'}
                label={passwordLabel || t("Password")}
                value={password}
                error={passwordError}
                helperText={passwordError ? passwordErrorText : ''}
                onChange={updatePasswordTouchedWithCallback(passwordOnChange)}
                onBlur={updatePasswordTouchedWithCallback(passwordOnBlur)}
                id="password"
                name="password"
                autoComplete="new-password"
                InputProps={{
                    endAdornment:
                        <IconButton
                            color="primary"
                            className="button-focus"
                            aria-label={showPassword ? 'hide password' : 'show password'}
                            alt={showPassword ? 'hide password button' : 'show password button'}
                            onClick={() => togglePasswordVisibilityOnClick()}
                            size="large">
                            <Icon>{showPassword ? 'visibility_off' : 'visibility'}</Icon>
                        </IconButton>
                }}
            />
            <div className="d-none d-lg-block">
                <div className="d-flex justify-content-start mt-1">
                    <i className={lengthStyle.iconStyle} style={lengthStyle.styling} name="minChars">{lengthStyle.icon}</i>
                    <div className={lengthStyle.textStyle} name="minChars">{t("Minimum 8 characters")}</div>
                    <i className={letterStyle.iconStyle} style={letterStyle.styling} name="oneLetter">{letterStyle.icon}</i>
                    <div className={letterStyle.textStyle} name="oneLetter">{t("One letter")}</div>
                    <i className={numberStyle.iconStyle} style={numberStyle.styling} name="oneNum">{numberStyle.icon}</i>
                    <div className={numberStyle.textStyle} name="oneNum">{t("One number")}</div>
                </div>
            </div>

            <div className="d-lg-none d-block">
                <div className="d-flex justify-content-start mt-1">
                    <i className={lengthStyle.iconStyle} style={lengthStyle.styling} name="minChars">{lengthStyle.icon}</i>
                    <div className={lengthStyle.textStyle} name="minChars">{t("Minimum 8 characters")}</div>
                </div>
                <div className="d-flex justify-content-start mt-1">
                    <i className={letterStyle.iconStyle} style={letterStyle.styling} name="oneLetter">{letterStyle.icon}</i>
                    <div className={letterStyle.textStyle} name="oneLetter">{t("One letter")}</div>
                </div>
                <div className="d-flex justify-content-start mt-1">
                    <i className={numberStyle.iconStyle} style={numberStyle.styling} name="oneNum">{numberStyle.icon}</i>
                    <div className={numberStyle.textStyle} name="oneNum">{t("One number")}</div>
                </div>
            </div>

            <TextField
                fullWidth={true}
                type={showPassword ? 'text' : 'password'}
                value={confirmPassword}
                label={confirmPasswordLabel || t("Confirm password")}
                error={confirmPasswordError}
                helperText={confirmPasswordError ? confirmPasswordErrorText : ''}
                onChange={confirmPasswordOnChange}
                onBlur={confirmPasswordOnBlur}
                onFocus={confirmPasswordOnBlur}
                autoComplete="new-password"
                id="confirmPassword"
                name="confirmPassword" />
        </>
    )
}

PasswordValidation.propTypes = {
    showPassword: PropTypes.bool,
    password: PropTypes.string,
    passwordLabel: PropTypes.string,
    passwordError: PropTypes.bool,
    passwordErrorText: PropTypes.string,
    passwordOnChange: PropTypes.func.isRequired,
    passwordOnBlur: PropTypes.func.isRequired,

    confirmPassword: PropTypes.string,
    confirmPasswordLabel: PropTypes.string,
    confirmPasswordError: PropTypes.bool,
    confirmPasswordErrorText: PropTypes.string,
    confirmPasswordOnChange: PropTypes.func.isRequired,
    confirmPasswordOnBlur: PropTypes.func.isRequired,

    togglePasswordVisibilityOnClick: PropTypes.func.isRequired,

    t: PropTypes.func.isRequired
}

export default withTranslation('forgotPassword')(PasswordValidation)