/* eslint react/no-multi-comp: "off" */

import React from 'react'
import PropTypes from 'prop-types'

import Checkbox from '@mui/material/Checkbox'
import { PatternFormat } from 'react-number-format'
import format from '../../../srp_modules/format'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'


function getFullStreetAddress(address) {
    let unitNum = ""
    if (address.unitNumber) {
        unitNum = "#" + address.unitNumber
    }

    let fullStreetAddress = address.houseNumber + " " + address.addressDirection + " " + address.streetName + " " + unitNum
    return fullStreetAddress
}

function getRowTitle(subAccount, viewBillClick) {
    if (subAccount.accountName && subAccount.accountName.trim() !== '') {
        return (
            <div style={{fontSize: '18px', fontWeight: '800'}}>
                <span>
                    <button className="displayAsLink text-truncate" style={{maxWidth: '70%'}} type="button" onClick={() => viewBillClick(subAccount.billAccount)}>
                        {subAccount.accountName}
                    </button>
                </span>
                &nbsp;
                <span style={{color: '#333333', fontWeight: '500'}}>
                    <PatternFormat
                        value={format.srpAccountNumber(subAccount.billAccount)}
                        valueIsNumericString={true}
                        format="###-###-###"
                        displayType="text"
                    />
                </span>
            </div>
        )
    } else {
        return (
            <div>
                <PatternFormat
                    value={format.srpAccountNumber(subAccount.billAccount)}
                    valueIsNumericString={true}
                    format="###-###-###"
                    displayType="text"
                    style={{ fontSize: '18px', fontWeight: '800', color: '#087ed8'}}
                />
            </div>
        )
    }
}

const SummaryBillingSubAccount = ({subAccount, viewBillClick}) => {
    let tdClassName = 'pat-tr-disabled'

    return (
        <React.Fragment>
            <TableRow style={{height: '70px'}}>
                <TableCell padding="none" className={tdClassName}>
                    <Checkbox
                        style={{marginLeft: '10px'}}
                        disabled={true}
                        color="primary"
                    />
                </TableCell>
                <TableCell colSpan="2" padding="none" className={tdClassName} style={{ paddingLeft: '20px'}}>
                    {getRowTitle(subAccount, viewBillClick)}
                    <div style={{color: '#333333', fontSize: '15px', fontWeight:'500'}}>
                        {subAccount.serviceAddress.streetName.length &&
                        getFullStreetAddress(subAccount.serviceAddress)}
                    </div>
                </TableCell>
                <TableCell padding="none" className={tdClassName} style={{ textAlign: "right" }}>
                    - -
                </TableCell>
                <TableCell padding="none" className={tdClassName} style={{ paddingLeft: "4px" }} />
                <TableCell padding="none" className={tdClassName} style={{ textAlign: "right" }}>
                    - -
                </TableCell>
                <TableCell padding="none" className={tdClassName} style={{ paddingLeft: "4px" }} />
                <TableCell padding="none" className={tdClassName} style={{ paddingLeft: "12px" }}>
                    - -
                </TableCell>
                <TableCell padding="none" className={tdClassName} />
            </TableRow>
        </React.Fragment>
    )
}

SummaryBillingSubAccount.propTypes = {
    subAccount: PropTypes.object.isRequired,
    viewBillClick: PropTypes.func.isRequired
}

export default SummaryBillingSubAccount