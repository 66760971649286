import React from 'react'
import { connect } from 'react-redux'
import CircularProgress from '@mui/material/CircularProgress'
import Dialog from '@mui/material/Dialog'
import { Trans } from 'react-i18next'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { getAlertsTab } from '../../../actions/auth/notification/notification-actions'

import alertsModule from '../../../srp_modules/alerts-module'

class OutageAlertDialog extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            displayEnrollDialog: false,
            displayUnenrollDialog: false,
            disableOutageToggle: false,
            subscribedContacts: alertsModule.createEBillContactsString(this.props.outageAlert.email, this.props.outageAlert.phone)
        }
    }
    
    showEnrollDialog() {
        this.setState({displayEnrollDialog: true})
    }

    closeEnrollDialog() {
        this.setState({displayEnrollDialog: false})
    }

    showUnenrollDialog() {
        this.setState({displayUnenrollDialog: true})
    }

    closeUnenrollDialog() {
        this.setState({displayUnenrollDialog: false})
    }

    navigateToAlerts() {
        this.setState({ displayEnrollDialog: false, displayUnenrollDialog: false})
        this.props.getAlertsTabOnClick(2)
    }

    async enrollOutageAlert() {
        this.setState({disableOutageToggle: true})

        let success = await this.props.toggleOutageAlert(this.props.selectedBillAccount, true)
        if (success) {
            this.showEnrollDialog()
        }

        this.setState({disableOutageToggle: false})
    }

    async unenrollOutageAlert() {
        this.setState({disableOutageToggle: true})

        let success = await this.props.toggleOutageAlert(this.props.selectedBillAccount, false)
        if (success) {
            this.showUnenrollDialog()
        }

        this.setState({disableOutageToggle: false})
    }

    render() {
        const t = this.props.t

        let hasEmail = false
        if (this.props.primaryEmail) {
            hasEmail = true
        }
        return (
            <div>
                {this.props.outageAlertState && 
                    <div className="srp-alert-success px-3 pt-3 d-flex justify-content-between">
                        <h6 className="mb-0">
                            <Trans i18nKey="Outage_alerts_are_ON" t={t}>
                                Outage alerts are <b>ON</b>
                            </Trans>
                        </h6>
                        <button type="button" className="displayAsLink py-0" onClick={() => this.unenrollOutageAlert()}>
                            {this.state.disableOutageToggle ? <CircularProgress size={20} thickness={5} /> : <h6><b>{t("Turn off")}</b></h6>}
                        </button>
                    </div>
                }
                {!this.props.outageAlertState && 
                    <div className="d-flex flex-column srp-alert-notice p-3">
                        <h4 className="srp-dark-blue-color"><b>{t("Enroll in Outage alerts")}</b></h4>

                        <small>{t("Get_notified_about_outages")}</small>
                        {hasEmail &&
                            <button className="btn srp-btn btn-blue align-self-end mt-3" type="button" onClick={() => this.enrollOutageAlert()}>
                                {this.state.disableOutageToggle ? <CircularProgress size={20} thickness={5} style={{color:'white'}} /> : <small><b>{t("Turn alerts on")}</b></small>}
                            </button>
                        }
                        {!hasEmail && 
                            <Link
                            to="/myaccount/profile/alerts"
                            className="btn srp-btn btn-blue align-self-end mt-3"
                            onClick={() => this.navigateToAlerts()}>
                            {t("Turn alerts on")}
                        </Link>}
                    </div>
                }

                <Dialog
                    modal="true"
                    open={this.state.displayEnrollDialog}
                    PaperProps={{ className: "srp-modal" }}
                >
                    <div className="srp-modal-body">
                        <div className="srp-modal-close" onClick={() => this.closeEnrollDialog()} />
                        <div>
                            <div className="srp-modal-header">
                                <h4 className="srp-modal-title">{t("Outage alerts")}</h4>
                            </div>
                            <p>
                                {t("You_have_turned_on_Outage_alerts", {email: this.props.primaryEmail})}
                            </p>
                            <div className="srp-modal-footer mb-0">
                                <Link
                                    to="/myaccount/profile/alerts"
                                    className="btn srp-btn btn-lightblue mr-2"
                                    onClick={() => this.navigateToAlerts()}>
                                    {t("Manage account")}
                                </Link>
                                <button
                                    className="btn srp-btn btn-lightblue"
                                    onClick={() => this.closeEnrollDialog()}>
                                    {t("Close")}
                                </button>
                            </div>
                        </div>
                    </div>
                </Dialog>

                <Dialog
                    modal="true"
                    open={this.state.displayUnenrollDialog}
                    PaperProps={{ className: "srp-modal" }}
                >
                    <div className="srp-modal-body">
                        <div className="srp-modal-close" onClick={() => this.closeUnenrollDialog()} />
                        <div>
                            <div className="srp-modal-header">
                                <h4 className="srp-modal-title">{t("Outage alerts")}</h4>
                            </div>
                            <div>
                                <div>
                                    {t("You_have_turned_off_Outage_alerts", {contact: this.state.subscribedContacts})}
                                </div>
                            </div>
                            <div className="srp-modal-footer mb-0">
                                <Link
                                    to="/myaccount/profile/alerts"
                                    className="btn srp-btn btn-lightblue mr-2"
                                    onClick={() => this.navigateToAlerts()}>
                                    {t("Manage account")}
                                </Link>
                                <button
                                    className="btn srp-btn btn-lightblue"
                                    onClick={() => this.closeUnenrollDialog()}>
                                    {t("Close")}
                                </button>
                            </div>
                        </div>
                    </div>
                </Dialog>
            </div>)
    }
}

OutageAlertDialog.propTypes = {
    t: PropTypes.func.isRequired,
    selectedBillAccount: PropTypes.number.isRequired,
    primaryEmail: PropTypes.string.isRequired,
    outageAlertState: PropTypes.bool.isRequired,
    outageAlert: PropTypes.object.isRequired,
    toggleOutageAlert: PropTypes.func.isRequired,
    getAlertsTabOnClick: PropTypes.func.isRequired
}

const mapStateToProps = () => {
    return {}
}

const mapDispatchToProps = (dispatch) => {
    return {
        getAlertsTabOnClick: (tab) => {
            dispatch(getAlertsTab(tab))
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(OutageAlertDialog)
