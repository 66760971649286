import { loginConstants } from '../../../constants'
import { mapScriptConstants } from '../../../constants/map-script-constants'
import { XSRF_TOKEN_SUCCESS, XSRF_TOKEN_FAILURE, XSRF_TOKEN_REQUEST } from '../../../srp_modules/middleware/xsrf-middleware'
import * as loadingStatus from '../../../constants/loading-status-constants'
import config from 'my-account-config'

const initialState = {
    siteOfflineInfo: {
        siteOffline: false,
        startDate: null,
        endDate: null,
        message: ''
    },

    loginEmail: '', // Don't default this to the username, because it may not be the same as the login email
    username: '',
    userId: '',
    requestedUrl: '',
    isLoggedIn: false,
    loginResponsiveTab: 0,
    isIrrigator: false,
    loginProfileStatus: loadingStatus.LOADING_STATUS_INIT,
    loginSubmitStatus: loadingStatus.LOADING_STATUS_INIT,
    logoutStatus: loadingStatus.LOADING_STATUS_INIT,
    xsrfStatus: loadingStatus.LOADING_STATUS_INIT,
    xsrfToken: '',
    referrer: document ? document.referrer : '',

    apiKey: config.googleMapsApiKey,
    mapScriptLoadedStatus: loadingStatus.LOADING_STATUS_INIT,

    //reset password
    emailToSendReset: '',
    passwordResetEmailSent: false,
    passwordResetEmailSentError: false,
    passwordResetEmailSentErrorText: '',
    isSendingResetEmail: false,
    passwordResetComplete: false,

    // update login email
    completeLoginUpdateStatus: loadingStatus.LOADING_STATUS_INIT,
    completeCreateAccountStatus: loadingStatus.LOADING_STATUS_INIT,
    completeCreateAccountResult: {},
    loginEmailDeleted: false,

    // isProduction flag
    isProduction: config.isProduction
};

export default function loginReducer(state = initialState, action) {
    switch (action.type) {
        case loginConstants.TOGGLE_REMEMBER_MY_LOGIN_INFO: {
            return { ...state, rememberUser: action.rememberUser, username: action.rememberUser ? state.username : '' }
        }
        case loginConstants.SET_SITE_OFFLINE: {
            return { ...state, siteOfflineInfo: action.siteOfflineInfo }
        }
        case loginConstants.LOGIN_PROFILE_REQUEST:
                return {
                    ...state,
                    isLoggedIn: false,
                    xsrfToken: initialState.xsrfToken,
                    loginProfileStatus: loadingStatus.LOADING_STATUS_IN_PROGRESS
                }
        case loginConstants.LOGIN_REQUEST:
        case loginConstants.LOGOUT_REQUEST: {
            return {
                    ...state,
                    isLoggedIn: false,
                    referrer: '', // clear the referrer from state if the user submits a login request
                    xsrfToken: initialState.xsrfToken,
                    loginSubmitStatus: loadingStatus.LOADING_STATUS_IN_PROGRESS,
                    logoutStatus: (action.type === loginConstants.LOGOUT_REQUEST) ? loadingStatus.LOADING_STATUS_IN_PROGRESS : state.logoutStatus,
                }
        }
        case loginConstants.LOGIN_PROFILE_SUCCESS:
        case loginConstants.LOGIN_SUCCESS: {
                const newState = {
                    ...state,
                    isLoggedIn: true,
                    loginEmail: action.payload.email,
                    username: action.payload.username,
                    userId: (action.payload.id || state.userId).toLowerCase(),
                    isIrrigator: action.payload.isIrrigator === true,
                    loginProfileStatus: loadingStatus.LOADING_STATUS_SUCCESS,
                    loginSubmitStatus: loadingStatus.LOADING_STATUS_SUCCESS,
                    logoutStatus: loadingStatus.LOADING_STATUS_INIT
                }

                return newState
            }
        case loginConstants.LOGIN_PROFILE_FAILURE:
        case loginConstants.LOGIN_FAILURE:
            return {
                ...state,
                isLoggedIn: false,
                loginEmail: initialState.loginEmail,
                username: initialState.username,
                userId: initialState.userId,
                loginProfileStatus: loadingStatus.LOADING_STATUS_FAILURE,
                loginSubmitStatus: loadingStatus.LOADING_STATUS_FAILURE,
                xsrfStatus: loadingStatus.LOADING_STATUS_INIT,
                xsrfToken: initialState.xsrfToken
            }

        case loginConstants.LOGOUT_SUCCESS:
            return { ...initialState,
                mapScriptLoadedStatus: state.mapScriptLoadedStatus,
                loginProfileStatus: state.loginProfileStatus,
                loginSubmitStatus: loadingStatus.LOADING_STATUS_SUCCESS,
                logoutStatus: loadingStatus.LOADING_STATUS_SUCCESS
            }
        case loginConstants.LOGOUT_FAILURE:
            return { ...state,
                logoutStatus: loadingStatus.LOADING_STATUS_FAILURE
            }

        //password reset
        case loginConstants.PASSWORD_RESET_EMAIL_REQUEST:
            return { ...state, isSendingResetEmail: action.error !== true, passwordResetEmailSent: false, passwordResetEmailSentError: false }
        case loginConstants.PASSWORD_RESET_EMAIL_SUCCESS:
            return (action.payload.success === true) ? { ...state, emailToSendReset: action.meta.email } : state
        case loginConstants.REFRESH_PASSWORD_RESET:
            return { ...state, emailToSendReset: (action.username).trim() }
        case loginConstants.GET_CONFIRM_PASSWORD:
            if (state.newPassword !== action.confirmPassword) {
                return { ...state, confirmPassword: action.confirmPassword, confirmPasswordError: true, confirmPasswordErrorText: 'The password and confirmation password do not match.' }
            }
            else {
                return { ...state, confirmPassword: action.confirmPassword, confirmPasswordError: false }
            }

        case loginConstants.LOGIN_RESP_TAB_VALUE:
            return { ...state, loginResponsiveTab: action.value }

        case mapScriptConstants.MAP_SCRIPT_REQUEST:
            return { ...state, mapScriptLoadedStatus: loadingStatus.LOADING_STATUS_IN_PROGRESS }
        case mapScriptConstants.MAP_SCRIPT_SUCCESS:
            return { ...state, mapScriptLoadedStatus: loadingStatus.LOADING_STATUS_SUCCESS }
        case mapScriptConstants.MAP_SCRIPT_FAILURE:
            return { ...state, mapScriptLoadedStatus: loadingStatus.LOADING_STATUS_FAILURE }

        case loginConstants.COMPLETE_LOGIN_EMAIL_UPDATE_SUCCESS:
            return { ...state, completeLoginUpdateStatus: action.payload.success === true
                                                          ? loadingStatus.LOADING_STATUS_SUCCESS
                                                          : loadingStatus.LOADING_STATUS_FAILURE }
        case loginConstants.COMPLETE_LOGIN_EMAIL_UPDATE_FAILURE:
            return { ...state, completeLoginUpdateStatus: loadingStatus.LOADING_STATUS_FAILURE }
        case loginConstants.COMPLETE_CREATE_ACCOUNT_SUCCESS:
            return { ...state, completeCreateAccountStatus: action.payload.success === true ? loadingStatus.LOADING_STATUS_SUCCESS : loadingStatus.LOADING_STATUS_FAILURE, completeCreateAccountResult: action.payload }
        case loginConstants.COMPLETE_CREATE_ACCOUNT_FAILURE:
            return { ...state, completeCreateAccountStatus: loadingStatus.LOADING_STATUS_FAILURE, completeCreateAccountResult: action.payload ? action.payload.response : initialState.completeCreateAccountResult }

        case loginConstants.COMPLETE_PASSWORD_RESET_SUCCESS:
            return { ...state, passwordResetComplete: true }
        case loginConstants.CLOSE_PASSWORD_RESET_SUCCESS_MESSAGE:
            return { ...state, passwordResetComplete: false }

        case loginConstants.ACCOUNT_LOGIN_DELETED:
            return { ...state, loginEmailDeleted: true }
        case loginConstants.CLEAR_LOGIN_DELETED:
            return { ...state, loginEmailDeleted: false }

        case XSRF_TOKEN_REQUEST:
            return { ...state, xsrfStatus: loadingStatus.LOADING_STATUS_IN_PROGRESS, xsrfToken: '' }
        case XSRF_TOKEN_SUCCESS:
            return { ...state, xsrfStatus: loadingStatus.LOADING_STATUS_SUCCESS, xsrfToken: action.payload.xsrfToken }
        case XSRF_TOKEN_FAILURE:
            return { ...state, xsrfStatus: loadingStatus.LOADING_STATUS_FAILURE, xsrfToken: '' }

        default:
            return state;
    }
}