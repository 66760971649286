export const guestPaymentApiConstants = {
    VERIFY_GUEST_PAYMENT_LIST_REQUEST: 'VERIFY_GUEST_PAYMENT_LIST_REQUEST',
    VERIFY_GUEST_PAYMENT_LIST_SUCCESS: 'VERIFY_GUEST_PAYMENT_LIST_SUCCESS',
    VERIFY_GUEST_PAYMENT_LIST_FAILURE: 'VERIFY_GUEST_PAYMENT_LIST_FAILURE',

    SUBMIT_GUEST_PAYMENT_LIST_REQUEST: 'SUBMIT_GUEST_PAYMENT_LIST_REQUEST',
    SUBMIT_GUEST_PAYMENT_LIST_SUCCESS: 'SUBMIT_GUEST_PAYMENT_LIST_SUCCESS',
    SUBMIT_GUEST_PAYMENT_LIST_FAILURE: 'SUBMIT_GUEST_PAYMENT_LIST_FAILURE',

    CLEAR_GUEST_PAYMENT_SUBMITTED: 'CLEAR_GUEST_PAYMENT_SUBMITTED'
}