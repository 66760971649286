/* eslint react/no-did-mount-set-state: "off" */

import React from 'react'
import { connect } from 'react-redux'
import { PropTypes } from 'prop-types'
import CircularProgress from '@mui/material/CircularProgress'
import IconButton from '@mui/material/IconButton'
import TextField from '@mui/material/TextField'
import { Formik } from 'formik'
import { updateBillAccountNickname } from '../../../actions/auth/bill_account/bill-account-actions'
import SrpTooltip from '../../common_tooltip/srp-tooltip'

let getNickName = (accountName, alreadyAdded, fullStreetAddress) => {
    if (accountName != null && accountName.length > 0) {
        return accountName
    } else if (!alreadyAdded) {
        return fullStreetAddress
    } else {
        return ''
    }
}

class SummaryBillingSubAccount extends React.Component {
    constructor(props) {
        super(props)

        this.accountName = React.createRef()
        this.accountNumber = React.createRef()
        this.serviceAddress = React.createRef()

        this.state = {
            showEditNickname: false,
            disableAddAccount: false,
            accountNameTooltip: false,
            accountNumberTooltip: false,
            serviceAddressTooltip: false
        }

        this.updateNickname = this.updateNickname.bind(this)
        this.addAccountToProfile = this.addAccountToProfile.bind(this)
        this.toggleEditNickname = this.toggleEditNickname.bind(this)
        this.handleResize = this.handleResize.bind(this)
        window.addEventListener('resize', this.handleResize)
    }

    componentDidMount() {
        if (this.accountName.current && this.accountNumber.current && this.serviceAddress.current)
        {
            this.setState({ ...this.state,
                accountNameTooltip: this.accountName.current.scrollWidth > this.accountName.current.clientWidth,
                accountNumberTooltip: this.accountNumber.current.scrollWidth > this.accountNumber.current.clientWidth,
                serviceAddressTooltip: this.serviceAddress.current.scrollWidth > this.serviceAddress.current.clientWidth })
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize)
    }

    handleResize() {
        if (this.accountName.current && this.accountNumber.current && this.serviceAddress.current)
        {
            let nextAccountNameTooltip = this.accountName.current.scrollWidth > this.accountName.current.clientWidth
            let nextAccountNumberTooltip = this.accountNumber.current.scrollWidth > this.accountNumber.current.clientWidth
            let nextServiceAddressTooltip = this.serviceAddress.current.scrollWidth > this.serviceAddress.current.clientWidth

            if (nextAccountNameTooltip !== this.state.accountNameTooltip ||
                nextAccountNumberTooltip !== this.state.accountNumberTooltip ||
                nextServiceAddressTooltip !== this.state.serviceAddressTooltip) {
                    this.setState({ ...this.state,
                        accountNameTooltip: nextAccountNameTooltip,
                        accountNumberTooltip: nextAccountNumberTooltip,
                        serviceAddressTooltip: nextServiceAddressTooltip })
                }
        }
    }

    toggleEditNickname(show) {
        this.setState({  ...this.state, showEditNickname: show})
    }

    async updateNickname(values, formikGoodies) {
        let updateResponse = await this.props.updateNickname(values)
        formikGoodies.setSubmitting(false)

        if (!updateResponse || updateResponse.error === true || updateResponse.payload.success === false) {
            formikGoodies.setStatus({ error: "We're sorry, there was an error. Please try again later." })
        }
        else {
            this.setState({ ...this.state, showEditNickname: false })
        }
    }

    async addAccountToProfile(billAccount, nickname) {
        if (this.state.disableAddAccount) {
            return
        } else {
            this.setState({ ...this.state, disableAddAccount: true})
            await this.props.addAccountToProfile(billAccount, nickname)
            this.setState({ ...this.state, disableAddAccount: false})
        }
    }

    render() {
        let { billAccount, accountName, accountText, isFinalAccount, alreadyAdded, serviceAddress } = this.props.subAccount
        let disableEditAndDelete = this.props.disableEditAndDelete
        let rowClass = alreadyAdded ? 'summary-billing-subaccount-row' : 'summary-billing-subaccount-row-not-added'
        let nickName = getNickName(accountName, alreadyAdded, serviceAddress.fullStreetAddress)

        return (this.state.showEditNickname
            ? <Formik
                onSubmit={this.updateNickname}
                initialValues={{
                    billAccount: this.props.subAccount.billAccount,
                    nickname: this.props.subAccount.accountName || ''
                }}>
                {({ values, status, handleBlur, handleChange, handleSubmit, isSubmitting }) => {
                    let formStatus = status || {}

                    return (
                        <form className="border-bottom p-4" onSubmit={handleSubmit}>
                            {formStatus.error ?
                                <div className="row mt-2 mb-2">
                                    <div className="col">
                                        <span className="srp-alert-error">{formStatus.error}</span>
                                    </div>
                                </div> : null}
                            <div className="row">
                                <div className="col">
                                    <TextField value={this.props.subAccount.accountText} fullWidth={true} disabled={true} label="SRP account number"/>
                                    <TextField
                                        autoFocus={true}
                                        name="nickname"
                                        value={values.nickname}
                                        fullWidth={true}
                                        onFocus={e => e.target.select()}
                                        onBlur={handleBlur}
                                        onChange={handleChange} 
                                        label="Nickname"
                                    />
                                </div>
                                <div className="col align-self-end text-right text-nowrap pt-2">
                                    <button type="button" className="btn srp-btn btn-lightblue"
                                        disabled={isSubmitting}
                                        onClick={() => this.toggleEditNickname(false)}>
                                        Cancel
                                    </button>
                                    <button type="submit" className="btn srp-btn btn-green ml-2" disabled={isSubmitting || values.nickname === this.props.subAccount.accountName}>
                                        {isSubmitting ? <CircularProgress size={20} thickness={5} color="secondary" /> : "Update"}
                                    </button>
                                </div>
                            </div>
                        </form>
                    )
                }}
            </Formik>
            : <div className={'pt-1 ' + rowClass}>
                <div className="col-3 d-flex align-self-center justify-content-end" style={{ color: isFinalAccount ? 'rgb(158,42,43)' : 'inherit' }}>
                    {
                        nickName !== ''
                            ? <div className="text-truncate" style={{ width: '90%' }}>
                            {this.state.accountNameTooltip ?
                                <SrpTooltip
                                    content={<span>{nickName}</span>}
                                >
                                    <div className="text-truncate" ref={this.accountName}>
                                        {nickName}
                                    </div>
                                </SrpTooltip> :
                                <div className="text-truncate" ref={this.accountName}>{nickName}</div>}
                        </div>
                        : <div ref={this.accountName} style={{ width: '90%' }}>&nbsp;</div>
                    }
                </div>
                <div className="d-flex col-9 pr-2">
                    <div className="d-flex col-3 px-0 align-self-center">
                        {this.state.accountNumberTooltip ?
                            <SrpTooltip
                                content={<span>{accountText}</span>}
                            >
                                <div ref={this.accountNumber}>
                                    {accountText}
                                </div>
                            </SrpTooltip> :
                            <div className="text-truncate" style={{ overflow: 'unset'}} ref={this.accountNumber}>{accountText}</div>
                        }
                    </div>
                    <div className="col-6 d-flex align-self-center">
                        <div className="text-truncate">
                            {this.state.serviceAddressTooltip ?
                                <SrpTooltip
                                    content={<span>{serviceAddress.fullStreetAddress}</span>}
                                >
                                    <div className="text-truncate" ref={this.serviceAddress}>
                                        {serviceAddress.fullStreetAddress}
                                    </div>
                                </SrpTooltip> :
                                <div className="text-truncate" ref={this.serviceAddress}>
                                    {serviceAddress.fullStreetAddress}
                                </div>
                            }
                        </div>
                    </div>
                    <div className="ml-3 d-flex flex-grow-1">
                    {alreadyAdded ?
                        <div className="text-nowrap associated-account-row-action-buttons">
                            <IconButton color="primary" size="large"
                                onClick={() => this.toggleEditNickname(true)}
                                disabled={disableEditAndDelete} >
                                <i className="material-icons">create</i>
                            </IconButton>
                            <IconButton disabled={disableEditAndDelete} color="primary" size="large"
                                onClick={this.props.deleteBillAccountOnClick(billAccount, accountName, false)}>
                                {disableEditAndDelete ? <CircularProgress className="mr-1" size={20} thickness={5} /> : <i className="material-icons">delete</i>}
                            </IconButton>
                        </div> :
                        <div className="ml-1 pl-2 d-flex text-nowrap align-items-center profile-summary-subaccount-addaccount"
                            style={{ height: '48px', color: 'rgb(8,126,216)' }}
                            onClick={() => this.addAccountToProfile(billAccount, serviceAddress.fullStreetAddress)}
                        >
                            {this.state.disableAddAccount ? <CircularProgress size={20} thickness={5} /> : 'Add account'}
                        </div>}
                    </div>
                </div>
            </div>
        )
    }
}

SummaryBillingSubAccount.propTypes = {
    subAccount: PropTypes.object.isRequired,
    disableEditAndDelete: PropTypes.bool.isRequired,
    deleteBillAccountOnClick: PropTypes.func.isRequired,
    updateNickname: PropTypes.func.isRequired,
    addAccountToProfile: PropTypes.func.isRequired
}

const mapDispatchToProps = dispatch => {
    return {
        updateNickname: async (values) => {
            return await dispatch(updateBillAccountNickname(values.billAccount, values.nickname))
        }
    }
}

export default connect(null, mapDispatchToProps)(SummaryBillingSubAccount)