import React from 'react'
import Card from '@mui/material/Card'

class CleanPowerRedirect extends React.Component {
    render() {
        return (
            <div className="container">
                <div className="col-12 col-lg-9">
                    <div className="srp-card-header">Your data is being calculated</div>
                    <Card className="srp-card-details">
                        <p>You are leaving SRP and automatically being redirected to Clean Power Research LLC (CPR).</p>
                        <p>Having second thoughts? <button className="displayAsLink" onClick={window.close}>Go back to SRP.</button></p>
                    </Card>
                </div>
            </div>
        )
    }
}

export default CleanPowerRedirect