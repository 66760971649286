import React from 'react'
import PropTypes from 'prop-types'

import SrpTooltip from '../../../common_tooltip/srp-tooltip'

import format from '../../../../srp_modules/format'

const PaymentListMobile = ({paymentList, paymentListErrors, removePayment, verifyingPaymentList, t}) => (
    <div>
        <label className="h4 text-muted">{t("Payment list")}</label>
        <table className="table mb-0">
            <thead>
                <tr>
                    <th className="border-0 text-muted pl-0 pr-1 pb-1 srp-fit " style={{fontWeight: 'normal'}}>{t("Account number")}</th>
                    <th className="border-0 text-muted pl-1 pr-0 pb-1 text-right" style={{fontWeight: 'normal'}}>
                        <span className="align-middle">{t("Amount")}</span>
                        <i className="material-icons align-middle" style={{opacity: 0}}>delete</i>
                    </th>
                </tr>
            </thead>
            <tbody style={{fontSize: '0.875rem'}}>
                {paymentList.map((p, paymentIndex) => (
                    <tr key={paymentIndex}>
                        <td className="pt-2 pb-2 pl-0 pr-1 border-top-0 border-bottom align-middle">
                            <span className="align-middle">{format.formatBillAccountNumber(p.srpAccountNumber)}</span>
                            {paymentListErrors && paymentIndex < paymentListErrors.length && paymentListErrors[paymentIndex].srpAccountNumber &&
                                <SrpTooltip content={
                                    <span>
                                    {
                                    typeof paymentListErrors[paymentIndex].srpAccountNumber.getLocalizedErrMsg === 'function'
                                        ? paymentListErrors[paymentIndex].srpAccountNumber.getLocalizedErrMsg()
                                        : (paymentListErrors[paymentIndex].srpAccountNumber !== "" ? t(paymentListErrors[paymentIndex].srpAccountNumber) : "")
                                    }
                                    </span>
                                }>
                                    <i className="material-icons align-middle srp-icon-red-color">error_outline</i>
                                </SrpTooltip>
                            }
                        </td>
                        <td className="pt-2 pb-2 pl-1 pr-0 border-top-0 border-bottom align-middle text-right">
                            {paymentListErrors && paymentIndex < paymentListErrors.length && paymentListErrors[paymentIndex].paymentAmount &&
                                <SrpTooltip content={
                                    <span>
                                    {
                                    typeof paymentListErrors[paymentIndex].paymentAmount.getLocalizedErrMsg === 'function'
                                        ? paymentListErrors[paymentIndex].paymentAmount.getLocalizedErrMsg()
                                        : (paymentListErrors[paymentIndex].paymentAmount !== "" ? t(paymentListErrors[paymentIndex].paymentAmount) : "")
                                    }
                                    </span>
                                }>
                                    <i className="material-icons align-middle srp-icon-red-color">error_outline</i>
                                </SrpTooltip>
                            }
                            <span className="align-middle">{format.formatDollarAmount(p.paymentAmount)}</span>
                            <a disabled={verifyingPaymentList} onClick={() => removePayment(paymentIndex)}>
                                <i className="material-icons srp-icon-color align-middle">delete</i>
                            </a>
                        </td>
                    </tr>
                ))}
            </tbody>
            <tfoot style={{fontSize: '0.875rem'}}>
                <tr>
                    <td className="font-weight-bold pl-0 pr-1 srp-fit">{t("Total payment")}</td>
                    <td className="font-weight-bold pl-1 pr-0 text-right">
                        <span className="align-middle">{format.formatDollarAmount(paymentList.reduce((total, p) => total+p.paymentAmount, 0))}</span>
                        <i className="material-icons align-middle" style={{opacity: 0}}>delete</i>
                    </td>
                </tr>
            </tfoot>
        </table>
    </div>
)

PaymentListMobile.propTypes = {
    t: PropTypes.func.isRequired,
    paymentList: PropTypes.arrayOf(PropTypes.shape({
        srpAccountNumber: PropTypes.string.isRequired,
        paymentAmount: PropTypes.number.isRequired
    })),
    paymentListErrors: PropTypes.arrayOf(PropTypes.shape({
        srpAccountNumber: PropTypes.oneOfType([
            PropTypes.string.isRequired,
            PropTypes.shape({
                getLocalizedErrMsg: PropTypes.func.isRequired,
            }),
        ]),
        paymentAmount: PropTypes.oneOfType([
            PropTypes.string.isRequired,
            PropTypes.shape({
                getLocalizedErrMsg: PropTypes.func.isRequired,
            }),
        ]),
    })),
    removePayment: PropTypes.func.isRequired,
    verifyingPaymentList: PropTypes.bool.isRequired
}

export default PaymentListMobile