import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import '../../../styles/dashboard-page.css'

import format from '../../../srp_modules/format'
import { withRouter } from '../../../srp_modules/with-router'

import { getBankAccountToEdit } from '../../../actions/auth/payment/bank-account-actions'

import SubmittedMultiPaymentPage from './submitted-multi-payment-page'

let getSelectedBank = (bankAccountList, selectedBankAccountId) => {
    const selectedBank = bankAccountList.find((bankAccount) => bankAccount.bankId === selectedBankAccountId)
    return selectedBank
}

let getTotalPayment = (submitMultiPaymentResult) => {
    let total = 0
    submitMultiPaymentResult.submitPaymentResults.forEach(paymentResult => {
        paymentResult.payments.forEach(payment => {
            total += payment.paymentAmount
        })
    })

    return total
}

class SubmittedMultiPaymentPageContainer extends React.Component {
    constructor(props) {
        super(props)

        if (this.isStateEmpty())
            this.props.router.navigate('/myaccount/payment/multi', { replace: true })
    }

    isStateEmpty() {
        return this.props.submitMultiPaymentResult.submitPaymentResults === undefined
            || this.props.submitMultiPaymentResult.submitPaymentResults.length === 0
    }

    render() {
        return (
            !this.isStateEmpty() &&
            <div>
                <SubmittedMultiPaymentPage
                    submitMultiPaymentResult={this.props.submitMultiPaymentResult}
                    selectedBankAccountDescription={this.props.selectedBankAccountDescription}
                    totalPayment={getTotalPayment(this.props.submitMultiPaymentResult)}
                    selectedBank={this.props.selectedBank}
                    updateBankEmailAddressOnClick={this.props.updateBankEmailAddressOnClick}
                 />
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const selectedBank = getSelectedBank(state.bankAccounts.bankAccountList, state.multiPayment.selectedBankAccountId)
    return {
        submitMultiPaymentResult: state.multiPayment.submitMultiPaymentResult,
        selectedBankAccountDescription: selectedBank === undefined ? '' : format.bankAccount(selectedBank),
        selectedBank
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        updateBankEmailAddressOnClick: (selectedBank) => {
            if (selectedBank === undefined) {
                ownProps.router.navigate('/myaccount/profile/banks')
            } else {
                dispatch(getBankAccountToEdit(selectedBank))
                ownProps.router.navigate('/myaccount/profile/banks/editbankaccount')
            }
        }
    }
}

SubmittedMultiPaymentPageContainer.propTypes = {
    totalPayment: PropTypes.number,
    submitMultiPaymentResult: PropTypes.object,
    selectedBankAccountDescription: PropTypes.string.isRequired,
    router: PropTypes.shape({
        navigate: PropTypes.func
    }),
    selectedBank: PropTypes.object,
    updateBankEmailAddressOnClick: PropTypes.func.isRequired
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SubmittedMultiPaymentPageContainer))