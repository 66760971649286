import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import utils from '../../srp_modules/utils'
import callUsPhone from '../../images/common/CallUsPhoneMobile.png'
import { withTranslation } from 'react-i18next'

class CallAnytime extends React.Component {
    render() {
        return (
            <div className="d-block d-md-none">
                <div className="col-12 p-2 callMeAnyTimeLink">
                    <a href={"tel:" + utils.getCSPhoneNumber(this.props.selectedBillAccountDetails.isCommercial)} className="w-100">
                        <div className="text-center">
                            <img className="img-fluid" src={callUsPhone} alt="Call SRP any time" />
                            <span>{this.props.t("Call SRP anytime")} {utils.getFormattedCSPhoneNumber(this.props.selectedBillAccountDetails.isCommercial)}</span>
                        </div>
                    </a>
                </div>
            </div>
        )
    }
}

CallAnytime.propTypes = {
    selectedBillAccountDetails: PropTypes.object,
    t: PropTypes.func.isRequired
}

const mapStateToProps = state => {
    return { ...state.accountInfo.billAccount }
}

export default withTranslation("callAnyTime")(connect(mapStateToProps)(CallAnytime))