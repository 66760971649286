import React from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import CircularProgress from '@mui/material/CircularProgress'

import * as loadingStatus from '../../constants/loading-status-constants'
import format from '../../srp_modules/format'
import changePricePlanModule from '../../srp_modules/forms/change-price-plan-module'

const Review = ({ activeStep, setActiveStep, accountDetails, isCommercial, currentPlanIsTouOrSolar, selectedPricePlan,
                  createAndSubmitChangePricePlanForm, changePricePlanStatus, fromCmpp, backButtonClick, t }) => {

    return (
        <div>
            <div className="mb-2 section-header">
                {`${t("Your new price plan")}: ${changePricePlanModule.getPricePlanTitle(isCommercial, selectedPricePlan)}`}
            </div>
            <div className="text-muted mb-4">
                {isCommercial
                    ? changePricePlanModule.mapRateSavingsMessageForCommercial(selectedPricePlan, currentPlanIsTouOrSolar, t)
                    : changePricePlanModule.mapRateSavingsMessageForResidential(selectedPricePlan, t)
                }

                <p>{t("can_only_switch_one_time_in_12_months")}</p>
            </div>



            <div className="mb-2 section-header">{isCommercial ? t("Business information") : t("Customer information")}</div>

            <div className="d-lg-block d-none mb-4">
                {isCommercial
                    ? <div>
                        <div className="text-muted">
                            <span className="font-weight-bold mr-2">{t("Business name")}</span>
                            <span>{`${accountDetails.businessName}`}</span>
                        </div>
                        {(accountDetails.dbaName || '').length !== 0
                            ? <div className="text-muted">
                                <span className="font-weight-bold mr-2">{t("DBA name")}</span>
                                <span>{`${accountDetails.dbaName}`}</span>
                            </div>
                            : null
                        }
                        {(accountDetails.contactName || '').length !== 0
                            ? <div className="text-muted">
                                <span className="font-weight-bold mr-2">{t("Contact name")}</span>
                                <span>{`${accountDetails.contactName}`}</span>
                            </div>
                            : null
                        }
                        {(accountDetails.contactPosition || '').length !== 0
                            ? <div className="text-muted">
                                <span className="font-weight-bold mr-2">{t("Contact position")}</span>
                                <span>{`${accountDetails.contactPosition}`}</span>
                            </div>
                            : null
                        }
                    </div>
                    : <div className="text-muted">
                        <span className="font-weight-bold mr-2">{t("Name")}</span>
                        <span>{`${accountDetails.firstName} ${accountDetails.lastName}`}</span>
                    </div>
                }

                <div className="text-muted">
                    <span className="font-weight-bold mr-2">{t("Account number")}</span>
                    <span>{`${format.formatBillAccountNumber(accountDetails.billAccount)}`}</span>
                </div>
                <div className="text-muted">
                    <span className="font-weight-bold mr-2">{t("Email address")}</span>
                    <span>{`${accountDetails.email}`}</span>
                </div>
                <div className="text-muted">
                    <span className="font-weight-bold mr-2">{t("Phone number")}</span>
                    <span>{`${format.formatPhoneNumber(accountDetails.phone)}`}</span>
                </div>
                <div className="text-muted">
                    <span className="font-weight-bold mr-2">{t("Service address")}</span>
                    <span>
                        {`${accountDetails.serviceAddressLineOne}`}
                        {(accountDetails.serviceAddressLineTwo || '').length !== 0
                            ? ` ${accountDetails.serviceAddressLineTwo}`
                            : null
                        }
                        {` ${accountDetails.city}, AZ ${format.zipcode(accountDetails.zipCode)}`}
                    </span>
                </div>
            </div>

            <div className="d-lg-none d-block mb-4">
                <div className="row mt-3 d-flex align-items-center">
                    <div className="col text-muted font-weight-bold align-middle">{isCommercial ? t("Business name") : t("Name")}</div>
                    <div className="col align-middle text-right">
                        {isCommercial ? accountDetails.businessName : `${accountDetails.firstName} ${accountDetails.lastName}`}
                    </div>
                </div>
                <hr className="mt-2" />
                {isCommercial && (accountDetails.dbaName || '').length !== 0
                    ? <div>
                        <div className="row mt-3 d-flex align-items-center">
                            <div className="col text-muted font-weight-bold align-middle">{t("DBA name")}</div>
                            <div className="col align-middle text-right">
                                {accountDetails.dbaName}
                            </div>
                        </div>
                        <hr className="mt-2" />
                    </div>
                    : null
                }
                {isCommercial && (accountDetails.contactName || '').length !== 0
                    ? <div>
                        <div className="row mt-3 d-flex align-items-center">
                            <div className="col text-muted font-weight-bold align-middle">{t("Contact name")}</div>
                            <div className="col align-middle text-right">
                                {accountDetails.contactName}
                            </div>
                        </div>
                        <hr className="mt-2" />
                    </div>
                    : null
                }
                {isCommercial && (accountDetails.contactPosition || '').length !== 0
                    ? <div>
                        <div className="row mt-3 d-flex align-items-center">
                            <div className="col text-muted font-weight-bold align-middle">{t("Contact position")}</div>
                            <div className="col align-middle text-right">
                                {accountDetails.contactPosition}
                            </div>
                        </div>
                        <hr className="mt-2" />
                    </div>
                    : null
                }
                <div className="row mt-2 d-flex align-items-center">
                    <div className="col text-muted font-weight-bold align-middle">{t("Account number")}</div>
                    <div className="col align-middle text-right">
                        {format.formatBillAccountNumber(accountDetails.billAccount)}
                    </div>
                </div>
                <hr className="mt-2" />
                <div className="row mt-2 d-flex align-items-center">
                    <div className="col text-muted font-weight-bold align-middle">{t("Email address")}</div>
                    <div className="col align-middle text-right">
                        {accountDetails.email}
                    </div>
                </div>
                <hr className="mt-2" />
                <div className="row mt-2 d-flex align-items-center">
                    <div className="col text-muted font-weight-bold align-middle">{t("Phone number")}</div>
                    <div className="col align-middle text-right">
                        {format.formatPhoneNumber(accountDetails.phone)}
                    </div>
                </div>
                <hr className="mt-2" />
                <div className="row mt-2 d-flex align-items-center">
                    <div className="col-5 text-muted font-weight-bold align-middle">{t("Service address")}</div>
                    <div className="col-7 align-middle text-right">
                        <div>
                            {`${accountDetails.serviceAddressLineOne}`}
                            {(accountDetails.serviceAddressLineTwo || '').length !== 0
                                ? ` ${accountDetails.serviceAddressLineTwo}`
                                : null
                            }
                        </div>
                        <div>{`${accountDetails.city}, AZ ${format.zipcode(accountDetails.zipCode)}`}</div>
                    </div>
                </div>
                <hr className="mt-2" />
            </div>

            <div className="d-flex flex-wrap justify-content-end">
                <button className="btn srp-btn btn-lightblue ml-2" onClick={fromCmpp ? backButtonClick : () => setActiveStep(activeStep - 1)}>{t("Previous")}</button>
                <button
                    type="submit"
                    className="btn srp-btn btn-green ml-2"
                    onClick={createAndSubmitChangePricePlanForm}
                    disabled={changePricePlanStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS}>
                    {changePricePlanStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS
                        ? <CircularProgress size={20} thickness={5} style={{ color: 'white' }} />
                        : t("Submit")
                    }
                </button>
            </div>
        </div>
    )

}

Review.propTypes = {
    activeStep: PropTypes.number,
    setActiveStep: PropTypes.func,

    accountDetails: PropTypes.object,
    isCommercial: PropTypes.bool,
    currentPlanIsTouOrSolar: PropTypes.bool,
    selectedPricePlan: PropTypes.number,

    createAndSubmitChangePricePlanForm: PropTypes.func,
    changePricePlanStatus: PropTypes.string,

    fromCmpp: PropTypes.bool,
    backButtonClick: PropTypes.func,

    t: PropTypes.func
}

export default withTranslation('changePricePlan')(Review)
