import * as loadingStatus from '../../../../constants/loading-status-constants'
import { multiPaymentNgpConstants } from '../../../../constants/payment/multi-payment-ngp-constants'
import { multiPaymentConstants } from '../../../../constants/payment/multi-payment-constants'
import { myAccountConstants } from '../../../../constants/myaccount-constants'
import { bankAccountConstants } from '../../../../constants/bank-account-constants'
import { billAccountConstants } from '../../../../constants/bill-account-constants'
import utils from '../../../../srp_modules/utils'

import { DateTime } from 'luxon'

let hasScheduledPayment = (paymentInfo) => {
    let surePayDate = DateTime.fromISO(paymentInfo.paymentInfo.surePayDate)
    let hasPendingSurePay = surePayDate.isValid && surePayDate !== DateTime.fromISO(myAccountConstants.MINIMUM_DATE)

    let scheduledPayment = paymentInfo.scheduledPayments.length > 0

    return (paymentInfo.paymentInfo.isSurePay) ? hasPendingSurePay : scheduledPayment
}

let isDisabled = (paymentInfo) => {
    return (!paymentInfo.paymentInfo.canMakeEChexPayment || hasScheduledPayment(paymentInfo)  || paymentInfo.paymentInfo.isSurePay)
}

let sortPaymentInfo = (a, b) => {
    return a.billAccount - b.billAccount
}

let clonePurchaseListAndAddToListIfNeeded = (mpowerPaymentInfoList, purchaseList, billAccount) => {
    let newPurchaseList = purchaseList.map((purchase) => {return {...purchase}})

    const found = purchaseList.some(purchase => purchase.billAccount === billAccount)
    if (!found) {
        let newPurchaseAmount = ''
        newPurchaseList = newPurchaseList.concat({
            billAccount: billAccount,
            purchaseAmount: newPurchaseAmount,
            purchaseAmountError: getPurchaseAmountError(mpowerPaymentInfoList, billAccount, newPurchaseAmount),
            payAmountOwedToSRP: false
        })
    }

    return newPurchaseList
}

let getPurchaseAmountError = (mpowerPaymentInfoList, billAccount, amount) => {
    let paymentInfo = mpowerPaymentInfoList.find(info => info.billAccount === billAccount)
    let paymentAmount = utils.paymentToNumber(amount)
    let paymentAmountError = {
        hasError: false,
        errorLevel: myAccountConstants.ERROR_LEVEL.NONE,
        errorType: multiPaymentConstants.PAYMENT_AMOUNT_ERRORS.NO_ERROR
    }
    if (amount === "") {
        paymentAmountError = {
            hasError: true,
            errorLevel: myAccountConstants.ERROR_LEVEL.ERROR,
            errorType: multiPaymentConstants.PAYMENT_AMOUNT_ERRORS.REQUIRED
        }
    } else if (isNaN(paymentAmount) || paymentAmount===0.0) {
        paymentAmountError = {
            hasError: true,
            errorLevel: myAccountConstants.ERROR_LEVEL.ERROR,
            errorType: multiPaymentConstants.PAYMENT_AMOUNT_ERRORS.INVALID_AMOUNT
        }
    } else if(Number(paymentAmount) > paymentInfo.paymentInfo.hardLimit) {
        paymentAmountError = {
            hasError: true,
            errorLevel: myAccountConstants.ERROR_LEVEL.ERROR,
            errorType: multiPaymentConstants.PAYMENT_AMOUNT_ERRORS.PAYMENT_LIMIT,
            paymentHardLimit: paymentInfo.paymentInfo.hardLimit
        }
    }

    return paymentAmountError
}

let resetPaymentInfoListDisplay = (mpowerPaymentInfoList) => {
    let newMpowerPaymentInfoList = []

    mpowerPaymentInfoList.forEach(paymentInfo => {
        let newPaymentInfo = {
            ...paymentInfo,
            display: true
        }

        newMpowerPaymentInfoList.push(newPaymentInfo)
    })

    return newMpowerPaymentInfoList
}

let getFullStreetAddress = (address) => {
    let unitNum = ""
    if (address.unitNumber) {
        unitNum = "#" + address.unitNumber
    }

    let fullStreetAddress = address.houseNumber + " " + address.addressDirection + " " + address.streetName + " " + unitNum
    return fullStreetAddress
}

let accountToString = (account) => {
    let billAccountString = String(account.billAccount)
    billAccountString = billAccountString.padStart(9, '0')

    let billAccountDashed = billAccountString.replace(/^([0-9]{3})([0-9]{3})([0-9]{3})$/, '$1-$2-$3')

    let searchableString = billAccountString + ' ' + billAccountDashed + ' ' + account.accountName.toLowerCase() + ' ' + getFullStreetAddress(account.serviceAddress).toLowerCase()
    return searchableString
}

let isTextFilterMatch = (filterArray, paymentInfo) => {
    let isMatch = true
    let billAccountString = accountToString(paymentInfo)
    filterArray.forEach(filter => {
        if (!billAccountString.includes(filter)) {
            isMatch = false
        }
    })

    return isMatch
}

let applyFilter = (accountFilter, mpowerPaymentInfoList) => {
    if (!accountFilter.enabled) {
        return resetPaymentInfoListDisplay(mpowerPaymentInfoList)
    }

    let newMpowerPaymentInfoList = []

    let filterArray = accountFilter.text.toLowerCase().trim().split(' ')
    mpowerPaymentInfoList.forEach(paymentInfo => {
        let newDisplay = false
        if (isTextFilterMatch(filterArray, paymentInfo)) {
            newDisplay = true
        }

        newMpowerPaymentInfoList.push({ ...paymentInfo, display: newDisplay })
    })

    return newMpowerPaymentInfoList
}

let applyFilterToPurchaseList = (purchaseList, mpowerPaymentInfoList) => {
    let nextPurchaseList = []

    let displayedAccounts = []
    mpowerPaymentInfoList.forEach(paymentInfo => {
        if (paymentInfo.display) {
            displayedAccounts.push(paymentInfo.billAccount)
        }
    })

    purchaseList.forEach(purchase => {
        if (displayedAccounts.includes(purchase.billAccount)) {
            nextPurchaseList.push(purchase)
        }
    })

    return nextPurchaseList
}

let getFilterCounts = (mpowerPaymentInfoList) => {
    let selectableCount = 0
    let displayedCount = 0
    mpowerPaymentInfoList.forEach(paymentInfo => {
        if (paymentInfo.display) {
            displayedCount++

            if (!paymentInfo.disabled) {
                selectableCount++
            }
        }
    })

    return { 
        selectableCount,
        displayedCount
    }
}

const initialState = {
    selectedBankAccountId: 0,
    mpowerPaymentInfoList: [],
    purchaseList: [],
    selectableCount: 0,
    displayedCount: 0,

    formStartEventEmitted: false,
    errorVerifyingPurchases: false,

    verifyMultiPurchaseResultStatus: loadingStatus.LOADING_STATUS_INIT,
    verifyMultiPurchaseResult: {
        referenceNumberWhenNoError: 0,
        eChexValidationErrors: {},
    },

    showMaxPaymentWarning: false,

    errorSubmittingPurchases: false,

    submitMultiPurchaseResultStatus: loadingStatus.LOADING_STATUS_INIT,
    submitMultiPurchaseResult: {},

    selectAll: false,

    accountFilter: {
        enabled: false,
        text: ''
    }
}

export default function multiPaymentNgpReducer (state = initialState, action) {
    switch (action.type) {
        case bankAccountConstants.GET_BANK_ACCOUNT_LIST_SUCCESS: {
            const defaultBankAccount = action.payload.find((bankAccount) => bankAccount.isDefault)
            let defaultBankAccountId = defaultBankAccount
                                     ? defaultBankAccount.bankId
                                     : (action.payload.length > 0 ? action.payload[0].bankId : initialState.selectedBankAccountId)
            return { ...state, selectedBankAccountId: defaultBankAccountId }
        }

        case multiPaymentConstants.LOAD_MULTI_PAYMENT_INFO_LIST_SUCCESS: {
            let newMPowerInfoList = []

            for (let key in action.multiPaymentInfoList) {
                let payload = action.multiPaymentInfoList[key]
                if (!payload.paymentInfo.supportsRemotePrepay)
                    continue

                let billAccountInfo = action.billAccountList.find(b => b.account === payload.billAccount)
                newMPowerInfoList.push({ ...payload, display: true, disabled: isDisabled(payload), accountName: billAccountInfo.accountName})
            }

            let newBillAccounts = new Set(action.multiPaymentInfoList.map(billAccount => billAccount))
            let existingBillAccounts = new Set()

            let nextStateMPowerPaymentInfoList = state.mpowerPaymentInfoList.map(paymentInfo => {
                if (!newBillAccounts.has(paymentInfo.billAccount)) {
                    existingBillAccounts.add(paymentInfo.billAccount)
                    return paymentInfo
                }
            }).concat(newMPowerInfoList)

            let nextCounts = getFilterCounts(nextStateMPowerPaymentInfoList)

            nextStateMPowerPaymentInfoList.sort((a, b) => { return sortPaymentInfo(a, b) })
            return { ...state, mpowerPaymentInfoList: nextStateMPowerPaymentInfoList, selectableCount: nextCounts.selectableCount, displayedCount: nextCounts.displayedCount }
        }

        case billAccountConstants.ADD_BILL_ACCOUNT_SUCCESS:
        case billAccountConstants.DELETE_BILL_ACCOUNT_SUCCESS:
            return { ...state, mpowerPaymentInfoList: initialState.mpowerPaymentInfoList,
                purchaseList: initialState.purchaseList,
                verifyMultiPurchaseResultStatus: initialState.verifyMultiPurchaseResultStatus,
                verifyMultiPurchaseResult: initialState.verifyMultiPurchaseResult,
                submitMultiPurchaseResultStatus: initialState.submitMultiPurchaseResultStatus,
                submitMultiPurchaseResult: initialState.submitMultiPurchaseResult
            }

        case multiPaymentConstants.RESET_PAYMENT_INFO_LIST:
            return { ...state, mpowerPaymentInfoList: initialState.mpowerPaymentInfoList }

        case multiPaymentNgpConstants.CLEAR_MULTIPLE_NGP_LIST:
            return { ...state, purchaseList: initialState.purchaseList, selectAll: false, showMaxPaymentWarning: false }

        case multiPaymentNgpConstants.RESET_MULTIPLE_NGP_INFO:
            return { ...state, verifyMultiPurchaseResultStatus: initialState.verifyMultiPurchaseResultStatus, verifyMultiPurchaseResult: initialState.verifyMultiPurchaseResult, submitMultiPurchaseResultStatus: initialState.submitMultiPurchaseResultStatus, submitMultiPurchaseResult: initialState.submitMultiPurchaseResult }

        case multiPaymentNgpConstants.GET_SELECTED_BANK_ACCOUNT_MULTI_NGP:
            return { ...state, selectedBankAccountId: action.bankAccountId }

        case multiPaymentNgpConstants.VERIFY_MULTIPLE_NGP_REQUEST:
            return { ...state, verifyMultiPurchaseResultStatus: loadingStatus.LOADING_STATUS_IN_PROGRESS, errorVerifyingPurchases: false }
        case multiPaymentNgpConstants.VERIFY_MULTIPLE_NGP_SUCCESS: {
            const referenceNumber = action.payload.referenceNumberWhenNoError
            const eChexValidationErrors = action.payload.eChexValidationErrors
            const errorVerifyingPurchases = (referenceNumber <= 0 || (eChexValidationErrors.constructor !== Object || Object.keys(eChexValidationErrors).length > 0))

            return { ...state, verifyMultiPurchaseResult: action.payload,
                verifyMultiPurchaseResultStatus: loadingStatus.LOADING_STATUS_SUCCESS,
                errorVerifyingPurchases,
            }
        }
        case multiPaymentNgpConstants.VERIFY_MULTIPLE_NGP_FAILURE:
            return { ...state, verifyMultiPurchaseResult: initialState.verifyMultiPurchaseResult,
                verifyMultiPurchaseResultStatus: loadingStatus.LOADING_STATUS_FAILURE,
                errorVerifyingPurchases: true,
            }

        case multiPaymentNgpConstants.SUBMIT_MULTIPLE_NGP_REQUEST:
            return { ...state, submitMultiPurchaseResultStatus: loadingStatus.LOADING_STATUS_IN_PROGRESS, errorSubmittingPurchases: false }
        case multiPaymentNgpConstants.SUBMIT_MULTIPLE_NGP_SUCCESS:
            return { ...state, submitMultiPurchaseResult: action.payload,
                submitMultiPurchaseResultStatus: loadingStatus.LOADING_STATUS_SUCCESS,
                errorSubmittingPurchases: action.payload.referenceNumber <= 0
            }
        case multiPaymentNgpConstants.SUBMIT_MULTIPLE_NGP_FAILURE:
            return { ...state, submitMultiPurchaseResult: initialState.submitMultiPurchaseResult,
                submitMultiPurchaseResultStatus: loadingStatus.LOADING_STATUS_FAILURE,
                errorSubmittingPurchases: true,
            }

        case multiPaymentNgpConstants.CLEAR_SUBMIT_PURCHASE_ERROR:
            return { ...state, errorSubmittingPurchases: false, }

        case multiPaymentNgpConstants.SELECT_MPOWER_ACCOUNT: {
            let selected = state.purchaseList

            let selectedIndex = -1
            for (let i = 0; i < selected.length; i++) {
                if (selected[i].billAccount === action.billAccount) {
                    selectedIndex = i
                }
            }

            let newSelected = []

            if(selectedIndex === -1)
            {
                newSelected = newSelected.concat(selected, {
                    billAccount: action.billAccount,
                    purchaseAmount: "",
                    purchaseAmountError: getPurchaseAmountError(state.mpowerPaymentInfoList, action.billAccount, ""),
                    payAmountOwedToSRP: false
                })
            }
            else if(selectedIndex === 0)
            {
                newSelected = newSelected.concat(selected.slice(1))
            }
            else if(selectedIndex === selected.length -1)
            {
                newSelected = newSelected.concat(selected.slice(0, -1))
            }
            else if(selectedIndex > 0)
            {
                newSelected = newSelected.concat(
                    selected.slice(0, selectedIndex),
                    selected.slice(selectedIndex + 1)
                )
            }

            return { ...state, purchaseList: newSelected, showMaxPaymentWarning: newSelected.length > multiPaymentConstants.MAX_SIMULTANEOUS_PAYMENTS, selectAll: newSelected.length >= multiPaymentConstants.MAX_SIMULTANEOUS_PAYMENTS }
        }

        case multiPaymentNgpConstants.GET_PAY_AMOUNT_OWED_TO_SRP: {
            let newPurchaseList = clonePurchaseListAndAddToListIfNeeded(state.mpowerPaymentInfoList, state.purchaseList, action.billAccount)

            for (let i = 0; i < newPurchaseList.length; i++) {
                if (newPurchaseList[i].billAccount === action.billAccount) {
                    newPurchaseList[i].payAmountOwedToSRP = !newPurchaseList[i].payAmountOwedToSRP
                }
            }

            return { ...state, purchaseList: newPurchaseList}
        }

        case multiPaymentNgpConstants.GET_MULTIPLE_PURCHASE_AMOUNT: {
            let newPurchaseList = clonePurchaseListAndAddToListIfNeeded(state.mpowerPaymentInfoList, state.purchaseList, action.billAccount)

            for (let i = 0; i < newPurchaseList.length; i++) {
                if (newPurchaseList[i].billAccount === action.billAccount) {
                    newPurchaseList[i].purchaseAmount = action.purchaseAmount,
                    newPurchaseList[i].purchaseAmountError = getPurchaseAmountError(state.mpowerPaymentInfoList, action.billAccount, action.purchaseAmount)
                }
            }

            return { ...state, purchaseList: newPurchaseList}
        }

        case multiPaymentNgpConstants.SELECT_ALL_MPOWER_ACCOUNTS: {
            let newPurchaseList = []

            for (let j = 0; j < state.mpowerPaymentInfoList.length; j++) {
                if (!state.mpowerPaymentInfoList[j].paymentInfo.hasError && !state.mpowerPaymentInfoList[j].disabled) {
                    newPurchaseList.push({
                        billAccount: state.mpowerPaymentInfoList[j].billAccount,
                        purchaseAmount: state.mpowerPaymentInfoList[j].paymentInfo.amountDue <= 0 ? "" : state.mpowerPaymentInfoList[j].paymentInfo.amountDue.toFixed(2),
                        purchaseAmountError: getPurchaseAmountError(state.mpowerPaymentInfoList, state.mpowerPaymentInfoList[j].billAccount,
                            state.mpowerPaymentInfoList[j].paymentInfo.amountDue <= 0 ? "" : state.mpowerPaymentInfoList[j].paymentInfo.amountDue),
                        payAmountOwedToSRP: state.mpowerPaymentInfoList[j].paymentInfo.amountDue <= 0 ? false : true
                    })
                }
            }

            return { ...state, purchaseList: newPurchaseList, selectAll: true, showMaxPaymentWarning: newPurchaseList.length > multiPaymentConstants.MAX_SIMULTANEOUS_PAYMENTS }
        }

        case multiPaymentNgpConstants.SELECT_FIRST_100_MPOWER_ACCOUNTS: {
            let newPurchaseList = []

            for (let j = 0; j < state.mpowerPaymentInfoList.length; j++) {
                if (!state.mpowerPaymentInfoList[j].paymentInfo.hasError && !state.mpowerPaymentInfoList[j].disabled) {
                    newPurchaseList.push({
                        billAccount: state.mpowerPaymentInfoList[j].billAccount,
                        purchaseAmount: state.mpowerPaymentInfoList[j].paymentInfo.amountDue <= 0 ? "" : state.mpowerPaymentInfoList[j].paymentInfo.amountDue.toFixed(2),
                        purchaseAmountError: getPurchaseAmountError(state.mpowerPaymentInfoList, state.mpowerPaymentInfoList[j].billAccount,
                            state.mpowerPaymentInfoList[j].paymentInfo.amountDue <= 0 ? "" : state.mpowerPaymentInfoList[j].paymentInfo.amountDue),
                        payAmountOwedToSRP: state.mpowerPaymentInfoList[j].paymentInfo.amountDue <= 0 ? false : true
                    })

                    if (newPurchaseList.length >= multiPaymentConstants.MAX_SIMULTANEOUS_PAYMENTS) {
                        break
                    }
                }
            }

            return { ...state, purchaseList: newPurchaseList, selectAll: true, showMaxPaymentWarning: newPurchaseList.length > multiPaymentConstants.MAX_SIMULTANEOUS_PAYMENTS }
        }

        case bankAccountConstants.ADD_BANK_EARLY_WARNING_SUCCESS: {
            if (action.payload.isSuccess === false)
                return state
            if (state.selectedBankAccountId)
                return state

            const savedBankAccountId = action.payload.savedBankAccount.bankAccountId
            return { ...state, selectedBankAccountId: savedBankAccountId }
        }

        case multiPaymentNgpConstants.FILTER_ACCOUNT_TEXT_NGP: {
            let nextAccountFilter = { enabled: action.enabled, text: action.filterText }
            let nextMpowerPaymentInfoList = applyFilter(nextAccountFilter, state.mpowerPaymentInfoList)
            let nextPurchaseList = applyFilterToPurchaseList(state.purchaseList, state.mpowerPaymentInfoList)
            let nextCounts = getFilterCounts(nextMpowerPaymentInfoList)

            return { ...state, accountFilter: nextAccountFilter, mpowerPaymentInfoList: nextMpowerPaymentInfoList, purchaseList: nextPurchaseList, selectableCount: nextCounts.selectableCount, displayedCount: nextCounts.displayedCount }
        }

        case multiPaymentNgpConstants.SET_FORM_START_EVENT_EMITTED:
            return { ...state,
                formStartEventEmitted: true
            }
        case multiPaymentNgpConstants.CLEAR_FORM_START_EVENT_EMITTED:
            return { ...state,
                formStartEventEmitted: false
            }
                    
        default:
            return state
    }
}