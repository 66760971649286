import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'

import Card from '@mui/material/Card'

import * as loadingStatus from '../../../constants/loading-status-constants'
import { getBankAccountList } from '../../../actions/auth/payment/bank-account-actions'
import { getEnrolledProgramsDetails, getPayment } from '../../../actions/auth/payment/payment-actions'
import { getCustomerNames, getPhoneNumber } from '../../../actions/auth/bill_account/bill-account-actions'
import { withRouter } from '../../../srp_modules/with-router'

import CashOnlyPaymentCard from '../../common_payment/cash-only-payment-card'

import FetchErrorCardContent from '../common/fetch-error-card-content'
import FetchInProgressCardContent from '../common/fetch-in-progress-card-content'
import SinglePurchaseFormikConnectedContainer from './single-purchase-formik-container'

class SinglePurchaseInputCardPure extends React.Component {
    constructor(props) {
        super(props)

        this.refetchBankAccountOrEnrolledProgramStatus = this.refetchBankAccountOrEnrolledProgramStatus.bind(this)
    }

    componentDidMount() {
        if (this.props.paymentStatus === loadingStatus.LOADING_STATUS_SUCCESS && this.props.supportsRemotePrepay === false)
            this.props.router.navigate('/myaccount/payment/single', { replace: true })

        if (this.props.enrolledProgramsDetailsStatus === loadingStatus.LOADING_STATUS_SUCCESS && this.props.isCashOnly)
            return

        if (this.props.bankAccountListStatus === loadingStatus.LOADING_STATUS_INIT)
            this.props.fetchBankAccountList()

        if (this.props.enrolledProgramsDetailsStatus === loadingStatus.LOADING_STATUS_INIT && this.props.selectedBillAccount !== 0)
            this.props.getEnrolledProgramsDetails(this.props.selectedBillAccount)

        if (this.props.customerNamesStatus === loadingStatus.LOADING_STATUS_INIT && this.props.selectedBillAccount !== 0)
            this.props.getCustomerName(this.props.selectedBillAccount)

        if (this.props.phoneNumberStatus === loadingStatus.LOADING_STATUS_INIT && this.props.selectedBillAccount !== 0)
            this.props.getPhoneNumber(this.props.selectedBillAccount)

        if (this.props.paymentStatus === loadingStatus.LOADING_STATUS_INIT && this.props.selectedBillAccount !== 0) {
            this.props.getMPowerInfo(this.props.selectedBillAccount)
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.paymentStatus === loadingStatus.LOADING_STATUS_SUCCESS && prevProps.paymentStatus !== loadingStatus.LOADING_STATUS_SUCCESS) {
            if (this.props.supportsRemotePrepay === false)
                this.props.router.navigate('/myaccount/payment/single', { replace: true })
        }

        if (this.props.selectedBillAccount === prevProps.selectedBillAccount || this.props.selectedBillAccount === 0)
            return

        this.props.getEnrolledProgramsDetails(this.props.selectedBillAccount)
        this.props.getCustomerName(this.props.selectedBillAccount)
        this.props.getPhoneNumber(this.props.selectedBillAccount)
        this.props.getMPowerInfo(this.props.selectedBillAccount)
    }

    refetchBankAccountOrEnrolledProgramStatus() {
        if (this.props.bankAccountListStatus === loadingStatus.LOADING_STATUS_FAILURE)
            this.props.fetchBankAccountList()

        if (this.props.enrolledProgramsDetailsStatus === loadingStatus.LOADING_STATUS_FAILURE && this.props.selectedBillAccount !== 0)
            this.props.getEnrolledProgramsDetails(this.props.selectedBillAccount)

        if (this.props.customerNamesStatus === loadingStatus.LOADING_STATUS_FAILURE && this.props.selectedBillAccount !== 0)
            this.props.getCustomerName(this.props.selectedBillAccount)

        if (this.props.phoneNumberStatus === loadingStatus.LOADING_STATUS_FAILURE && this.props.selectedBillAccount !== 0)
            this.props.getPhoneNumber(this.props.selectedBillAccount)

        if (this.props.paymentStatus === loadingStatus.LOADING_STATUS_FAILURE && this.props.selectedBillAccount !== 0)
            this.props.getMPowerInfo(this.props.selectedBillAccount)
    }

    render() {
        let cardContent
        if (this.props.bankAccountListStatus === loadingStatus.LOADING_STATUS_INIT ||
            this.props.bankAccountListStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS ||
            this.props.enrolledProgramsDetailsStatus === loadingStatus.LOADING_STATUS_INIT ||
            this.props.enrolledProgramsDetailsStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS ||
            this.props.customerNamesStatus === loadingStatus.LOADING_STATUS_INIT ||
            this.props.customerNamesStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS ||
            this.props.phoneNumberStatus === loadingStatus.LOADING_STATUS_INIT ||
            this.props.phoneNumberStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS ||
            this.props.paymentStatus === loadingStatus.LOADING_STATUS_INIT ||
            this.props.paymentStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS)
            cardContent = <FetchInProgressCardContent />
        else if (this.props.enrolledProgramsDetailsStatus === loadingStatus.LOADING_STATUS_SUCCESS && this.props.isCashOnly)
            return <CashOnlyPaymentCard />
        else if (this.props.bankAccountListStatus === loadingStatus.LOADING_STATUS_SUCCESS)
            cardContent = <SinglePurchaseFormikConnectedContainer selectedBillAccount={this.props.selectedBillAccount} />
        else
            cardContent = <FetchErrorCardContent dataDescription={this.props.t("bank account or program status")} fetchAgainCallback={this.refetchBankAccountOrEnrolledProgramStatus} />

        return (
            <div>
                <label className="h5">{this.props.t("Purchase information")}</label>
                <Card className="p-4">
                    {cardContent}
                </Card>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    selectedBillAccount: state.accountInfo.billAccount.selectedBillAccount,
    bankAccountListStatus: state.bankAccounts.bankAccountListStatus,
    enrolledProgramsDetailsStatus: state.accountInfo.payment.enrolledProgramsDetailsStatus,
    customerNamesStatus: state.accountInfo.billAccount.customerNamesStatus,
    phoneNumberStatus: state.accountInfo.billAccount.phoneNumberStatus,
    paymentStatus: state.accountInfo.payment.paymentStatus,
    isCashOnly: state.accountInfo.payment.isCashOnly,
    supportsRemotePrepay: state.accountInfo.payment.payment.supportsRemotePrepay,
})

const mapDispatchToProps = (dispatch) => ({
    fetchBankAccountList: () => dispatch(getBankAccountList()),
    getEnrolledProgramsDetails: (billAccount) => dispatch(getEnrolledProgramsDetails(billAccount)),
    getCustomerName: (billAccount) => dispatch(getCustomerNames(billAccount)),
    getPhoneNumber: (billAccount) => dispatch(getPhoneNumber(billAccount)),
    getMPowerInfo: (billAccount) => dispatch(getPayment(billAccount))
})

SinglePurchaseInputCardPure.propTypes = {
    selectedBillAccount: PropTypes.number.isRequired,
    bankAccountListStatus: PropTypes.string.isRequired,
    enrolledProgramsDetailsStatus: PropTypes.string.isRequired,
    customerNamesStatus: PropTypes.string.isRequired,
    phoneNumberStatus: PropTypes.string.isRequired,
    paymentStatus: PropTypes.string.isRequired,
    isCashOnly: PropTypes.bool.isRequired,
    supportsRemotePrepay: PropTypes.bool.isRequired,

    fetchBankAccountList: PropTypes.func.isRequired,
    getEnrolledProgramsDetails: PropTypes.func.isRequired,
    getCustomerName: PropTypes.func.isRequired,
    getPhoneNumber: PropTypes.func.isRequired,
    getMPowerInfo: PropTypes.func.isRequired,

    router: PropTypes.shape({
        navigate: PropTypes.func
    }).isRequired,

    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired
}

const ConnectedSinglePurchaseInputCard = withTranslation("singlePurchasePage")(withRouter(connect(mapStateToProps, mapDispatchToProps)(SinglePurchaseInputCardPure)))
export { ConnectedSinglePurchaseInputCard as default, SinglePurchaseInputCardPure}