import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'

import BankAccountSelectField from './bank-account-select-field'
import { getBankAccountList, changeSelectedBankAccountId } from '../../actions/auth/payment/bank-account-actions'
import * as loadingConstants from '../../constants/loading-status-constants'

let updateDataIfNeeded = (props) => {
    if (props.bankAccountListStatus === loadingConstants.LOADING_STATUS_INIT) {
        props.getBankAccountList()
    }
}

class BankAccountSelectFieldContainer extends React.Component {
    componentDidMount() {
        updateDataIfNeeded(this.props)
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        updateDataIfNeeded(nextProps)
    }

    render() {
        return (
            <BankAccountSelectField
                selectedBankAccountId={this.props.selectedBankAccountId}
                bankAccountList={this.props.bankAccountList}
                bankAccountOnChange={(bankAccountId) => {this.props.bankAccountOnChange(bankAccountId); this.props.onBankAccountChanged(bankAccountId)}}
                t={this.props.t}
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        bankAccountList: state.bankAccounts.bankAccountList,
        bankAccountListStatus: state.bankAccounts.bankAccountListStatus,
        selectedBankAccountId: state.bankAccounts.selectedBankAccountId
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getBankAccountList: () => {
            dispatch(getBankAccountList())
        },
        bankAccountOnChange: (bankAccountId) => {
            dispatch(changeSelectedBankAccountId(bankAccountId))
        }
    }
}

BankAccountSelectFieldContainer.propTypes = {
    bankAccountList: PropTypes.arrayOf(PropTypes.object),
    bankAccountListStatus: PropTypes.string,
    selectedBankAccountId: PropTypes.number,
    getBankAccountList: PropTypes.func,
    bankAccountOnChange: PropTypes.func,
    onBankAccountChanged: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    i18n: PropTypes.object.isRequired
}

export default withTranslation("bankAccountSelectField")(connect(mapStateToProps, mapDispatchToProps)(BankAccountSelectFieldContainer))