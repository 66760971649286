import React from 'react'
import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Checkbox, ListItem, ListItemText } from '@mui/material'

import { updateSelectedUnlinkedBillAccounts } from '../../../../actions/auth/bill_account/bill-account-actions'

const PREFIX = 'unlinked-bill-account-row'

const classes = {
    finalPrimary: `${PREFIX}-finalPrimary`,
    finalSecondary: `${PREFIX}-finalSecondary`
}

const StyledListItem = styled(ListItem)({
    [`& .${classes.finalPrimary}`]: {
        color: '#9E2A2B'
    },
    [`& .${classes.finalSecondary}`]: {
        color: '#C47F7F'
    }
})

class UnlinkedBillAccountRow extends React.Component {
    constructor(props) {
        super(props)

        this.toggleChecked = this.toggleChecked.bind(this)
    }

    shouldComponentUpdate(nextProps) {
        return this.props.selectedUnlinkedBillAccounts[`${this.props.billAccountItem.account}`]
               !== nextProps.selectedUnlinkedBillAccounts[`${nextProps.billAccountItem.account}`]
    }

    toggleChecked() {
        let isChecked = this.props.selectedUnlinkedBillAccounts[`${this.props.billAccountItem.account}`] !== undefined

        let newSelectedList = { ...this.props.selectedUnlinkedBillAccounts }
        if (isChecked)
            delete newSelectedList[`${this.props.billAccountItem.account}`]
        else
            newSelectedList[`${this.props.billAccountItem.account}`] = this.props.billAccountItem

        this.props.updateSelectedUnlinkedBillAccounts(newSelectedList)
    }

    render() {
        let isChecked = this.props.selectedUnlinkedBillAccounts[`${this.props.billAccountItem.account}`] !== undefined

        return (
            <StyledListItem dense={true} button={true} value={this.props.billAccountItem.account} onClick={this.toggleChecked} className="p-0">
                <Checkbox className="pl-2 pr-2" checked={isChecked} id={`${this.props.billAccountItem.account}_checkbox`} color="primary" />
                <ListItemText className="text-truncate pl-0 pr-0"
                              classes={this.props.billAccountItem.isFinalAccount
                                     ? { primary: classes.finalPrimary, secondary: classes.finalSecondary }
                                     : null}
                              primary={this.props.billAccountItem.accountName}
                              secondary={this.props.billAccountItem.accountText} />
            </StyledListItem>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        selectedUnlinkedBillAccounts: state.accountInfo.billAccount.selectedUnlinkedBillAccounts
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateSelectedUnlinkedBillAccounts: selectedUnlinkedBillAccounts => {
            dispatch(updateSelectedUnlinkedBillAccounts(selectedUnlinkedBillAccounts))
        }
    }
}

UnlinkedBillAccountRow.propTypes = {
    billAccountItem: PropTypes.object.isRequired,
    selectedUnlinkedBillAccounts: PropTypes.object.isRequired,

    updateSelectedUnlinkedBillAccounts: PropTypes.func.isRequired,

    classes: PropTypes.object
}

export default connect(mapStateToProps, mapDispatchToProps)(UnlinkedBillAccountRow)