import React from "react"
import PropTypes from 'prop-types'

import TextSignUpAlertMessage from './text-signup-alert-message'
import CommonAlert from "./common-alert"

const UsageAlerts = ({ allAlerts, phoneContacts, emailContacts, addNotificationOnClick, deleteNotificationOnClick, deleteAllNotificationsOnClick, t }) => {
    return (
        <div className="srp-card-details">
            <TextSignUpAlertMessage t={t}/>

            <div className="d-lg-block d-none">
                <div className="row mb-3">
                    <div className="col-lg-8" />
                    <div className="col-lg-2 text-muted">{t("Email")}</div>
                    <div className="col-lg-2 text-muted">{t("Text")}</div>
                </div>
            </div>

            {allAlerts.highUsageAlert.isAvailable
                ? <CommonAlert
                    alert={allAlerts.highUsageAlert}
                    alertTitle={t("High - usage alert")}
                    alertDescription={t("Alert me when my energy usage is higher than usual.")}
                    phoneContacts={phoneContacts}
                    emailContacts={emailContacts}
                    addNotification={addNotificationOnClick}
                    deleteNotification={deleteNotificationOnClick}
                    deleteAllNotificationsOnClick={deleteAllNotificationsOnClick}
                    allowEmailSubscription={true}
                    allowTextSubscription={false}
                    t={t}
                />
                : null}

            {allAlerts.timeOfDayAlert.isAvailable
                ? <CommonAlert
                    alert={allAlerts.timeOfDayAlert}
                    alertTitle={t("Time of day")}
                    alertDescription={allAlerts.timeOfDayAlert.notificationInfo.description}
                    phoneContacts={phoneContacts}
                    emailContacts={emailContacts}
                    addNotification={addNotificationOnClick}
                    deleteNotification={deleteNotificationOnClick}
                    deleteAllNotificationsOnClick={deleteAllNotificationsOnClick}
                    allowEmailSubscription={true}
                    allowTextSubscription={true}
                    t={t}
                />
                : null}

            {allAlerts.poolTimerAlert.isAvailable
                ? <CommonAlert
                    alert={allAlerts.poolTimerAlert}
                    alertTitle={t("Pool timer")}
                    alertDescription={allAlerts.poolTimerAlert.notificationInfo.description}
                    phoneContacts={phoneContacts}
                    emailContacts={emailContacts}
                    addNotification={addNotificationOnClick}
                    deleteNotification={deleteNotificationOnClick}
                    deleteAllNotificationsOnClick={deleteAllNotificationsOnClick}
                    allowEmailSubscription={true}
                    allowTextSubscription={true}
                    t={t}
                />
                : null}
        </div>
    )
}

UsageAlerts.propTypes = {
    allAlerts: PropTypes.object,
    phoneContacts: PropTypes.arrayOf(PropTypes.object),
    emailContacts: PropTypes.arrayOf(PropTypes.object),

    addNotificationOnClick: PropTypes.func,
    deleteNotificationOnClick: PropTypes.func,
    deleteAllNotificationsOnClick: PropTypes.func,
}

export default UsageAlerts