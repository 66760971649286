import React from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import Collapse from '@mui/material/Collapse'
import IconButton from '@mui/material/IconButton'
import PropTypes from 'prop-types'
import SummaryBillingSubAccountResponsive from './summary-billing-subaccount-responsive'

class SummaryBillingSubAccountsResponsive extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            showSubAccounts: false
        }
    }

    render() {
        return (
            <React.Fragment>
                <TableRow>
                    <TableCell colSpan="2" className="pat-summary-account-parent-control-responsive">
                        <Collapse
                            in={this.state.showSubAccounts}
                            collapsedSize="49px"
                            classes={{
                                root: "pat-summary-account-container-responsive"
                            }}
                        >
                            {!this.state.showSubAccounts &&
                                <div className="summary-account-expand-multipayment">
                                    <IconButton
                                        color="primary"
                                        onClick={() => this.setState({ ...this.state, showSubAccounts: !this.state.showSubAccounts})}
                                        size="large">
                                            <i className="material-icons">keyboard_arrow_down</i>
                                    </IconButton>
                                    <span className="align-self-center">Show sub accounts&nbsp;({this.props.subAccounts.length})</span>
                                </div>
                            }
                            
                            <Table style={{ fontSize: '15px' }}>
                                <TableBody>
                                    {this.props.subAccounts.map((subAccount, index) => {
                                        return (
                                            <SummaryBillingSubAccountResponsive
                                                subAccount={subAccount} 
                                                viewBillClick={this.props.viewBillClick}
                                                showSubAccounts={this.state.showSubAccounts}
                                                key={`${subAccount.billAccount}_${index}_subaccount`} 
                                            />
                                        )
                                    })}
                                </TableBody>
                            </Table>
                            
                            {this.state.showSubAccounts &&
                                <div className="summary-account-expand-multipayment">
                                    <IconButton
                                        color="primary"
                                        onClick={() => this.setState({ ...this.state, showSubAccounts: !this.state.showSubAccounts})}
                                        size="large">
                                            <i className="material-icons">keyboard_arrow_up</i>
                                    </IconButton>
                                    <span className="align-self-center">Hide sub accounts&nbsp;({this.props.subAccounts.length})</span>
                                </div>
                            }
                        </Collapse>
                    </TableCell>
                </TableRow>
                <TableRow style={{height: "10px", maxWidth: "300px"}} />
            </React.Fragment>
        )
    }
}

SummaryBillingSubAccountsResponsive.propTypes = {
    subAccounts: PropTypes.arrayOf(PropTypes.object),
    viewBillClick: PropTypes.func.isRequired
}

export default SummaryBillingSubAccountsResponsive