import React from 'react'
import { withTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import TextField from '@mui/material/TextField'
import { PatternFormat } from 'react-number-format'

const BankPhoneNumberFieldPure = props => {
    let isError = props.touched['bankPhoneNumber']
        && Object.prototype.hasOwnProperty.call(props.errors, 'bankPhoneNumber')
    let errMsg = isError ? props.errors['bankPhoneNumber'] : ''
    let meta = {
        id: 'bankPhoneNumber',
        name: 'bankPhoneNumber',
        label: props.t("Phone number"),
        helperText: errMsg==="" ? "" : props.t(errMsg),
        error: isError
    }

    let textFieldProps = {
        value: !props.value ? '' : props.value,
        onChange: props.onChange,
        onBlur: props.onBlur,
        errors: props.errors,
        touched: props.touched,
        fullWidth: props.fullWidth
    }

    return (
        <PatternFormat
            {...meta}
            {...textFieldProps}
            valueIsNumericString={false}
            format="(###) ###-####"
            type="tel"
            customInput={TextField}
        />
    )
}

BankPhoneNumberFieldPure.propTypes = {
    errors: PropTypes.shape({
        bankPhoneNumber: PropTypes.string
    }).isRequired,
    fullWidth: PropTypes.bool,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired,
    onBlur: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    touched: PropTypes.shape({
        bankPhoneNumber: PropTypes.bool
    }).isRequired,
    value: PropTypes.string.isRequired
}

const BankPhoneNumberField = withTranslation("singlePurchasePage")(BankPhoneNumberFieldPure)
export { BankPhoneNumberField as default, BankPhoneNumberFieldPure }