import React from 'react'
import PropTypes from 'prop-types'

import { withTranslation } from 'react-i18next'

import Dialog from '@mui/material/Dialog'

class SrpModal extends React.Component {
    render() {
        return (
            <Dialog
                modal="true"
                open={this.props.open}
                PaperProps={{className:"srp-modal"}}
            >   
                <div className="srp-modal-body">
                    <button className="srp-modal-close srp-modal-close-btn" onClick={this.props.closeClick} aria-label="close dialog" alt="" />
                    <div>
                        <div className="srp-modal-header">
                            <h4 className="srp-modal-title">{this.props.title}</h4>
                        </div>
                        <div>
                            {this.props.content}
                            <div className="srp-modal-footer">
                                {this.props.buttons || <button className="btn srp-btn btn-blue ml-2" onClick={this.props.okClick}>{this.props.t("Close")}</button>}
                            </div>
                        </div>
                    </div>
                </div>
                
            </Dialog>
        )
    }
}

SrpModal.propTypes = {
    t: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    content: PropTypes.element.isRequired,
    title: PropTypes.string.isRequired,
    okClick: PropTypes.func,
    closeClick: PropTypes.func.isRequired,
    buttons: PropTypes.arrayOf(PropTypes.element)
}

export default withTranslation("common")(SrpModal)