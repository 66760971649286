import React from 'react'
import { withTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import SrpModal from '../../common_modal/srp-modal'
import SafetyStatement from './credit_advance/safety-statement'

class ReconnectPowerSafetyStatementPure extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            displaySafetyStatement: false
        }

        this.toggleSafetyStatement = this.toggleSafetyStatement.bind(this)
    }

    toggleSafetyStatement(display) {
        this.setState({
            displaySafetyStatement: display
        })
    }

    render() {
        return (
            <span>
                <button
                    className="displayAsLink"
                    onClick={() => this.toggleSafetyStatement(true)}>
                    <span className="d-flex align-items-center">
                        {this.props.t("safety statement")}
                    </span>
                </button>
                <SrpModal
                    open={this.state.displaySafetyStatement}
                    title={this.props.t("Safety statement")}
                    content={<SafetyStatement t={this.props.t} />}
                    okClick={() => this.toggleSafetyStatement(false)}
                    closeClick={() => this.toggleSafetyStatement(false)}
                />
            </span>
        )
    }
}

ReconnectPowerSafetyStatementPure.propTypes = {
    t: PropTypes.func.isRequired,
}

const ReconnectPowerSafetyStatement = withTranslation("dashboardMyBillCard")(ReconnectPowerSafetyStatementPure)
export { ReconnectPowerSafetyStatement as default, ReconnectPowerSafetyStatementPure }