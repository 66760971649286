import React from "react"
import PropTypes from 'prop-types'

import config from 'my-account-config'

import powerIconGrey from '../../../images/payment/guest-payment-power-grey.png'
import powerIconWhite from '../../../images/payment/guest-payment-power-white.png'

import waterIconGrey from '../../../images/payment/guest-payment-water-grey.png'
import waterIconWhite from '../../../images/payment/guest-payment-water-white.png'

const iconSize = {
    height: "32px", width:"32px"
}

const PowerWaterTabs = ({t, i18n}) => {
    let waterGuestPaymentUrl = config.waterBaseUrl + 'guest/payment'
    if (i18n.language === 'es')
        waterGuestPaymentUrl = waterGuestPaymentUrl + "/es"

    return (
        <div className="payment-nav nav nav-justified">
            <div className="nav-item">
                <a href="#" className="nav-link d-flex flex-column align-items-center active">
                    <img src={powerIconGrey} style={iconSize} className="icon" />
                    <img src={powerIconWhite} style={iconSize} className="iconSelected" />
                    {t("Power")}
                </a>
            </div>
            <div className="nav-item">
                <a href={waterGuestPaymentUrl} className="nav-link d-flex flex-column align-items-center">
                    <img src={waterIconGrey} style={iconSize} className="icon" />
                    <img src={waterIconWhite} style={iconSize} className="iconSelected" />
                    <span style={{color: "#707070"}}>{t("Water")}</span>
                </a>
            </div>
        </div>                
    )
}

PowerWaterTabs.propTypes = {
    t:  PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired,
}

export default PowerWaterTabs