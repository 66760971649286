import React from 'react'
import { withTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import Card from '@mui/material/Card'
import CheckDiagram from '../../../images/payment/check-diagram.png'
import CheckDiagramMobile from '../../../images/payment/check-diagram-mobile.png'

const CheckDiagramCardPure = ({t}) => (
    <div>
        <label className="h5">{t("Check diagram")}</label>

        <Card>
            <img src={CheckDiagramMobile} className="mx-auto d-block img-fluid d-xl-none" />
            <img src={CheckDiagram} className="d-none d-xl-block" />
        </Card>
    </div>
)

CheckDiagramCardPure.propTypes = {
    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired
}

const CheckDiagramCard = withTranslation("singlePurchasePage")(CheckDiagramCardPure)
export { CheckDiagramCard as default, CheckDiagramCardPure }