import { purchaseHistoryTableConstants } from '../../../constants/purchase-history-table-constants'

export const getPurchaseHistStartMonth = (month) => ({
    type: purchaseHistoryTableConstants.GET_PURCHASE_START_MONTH,
    month
})

export const getPurchaseHistEndMonth = (month) => ({
    type: purchaseHistoryTableConstants.GET_PURCHASE_END_MONTH,
    month
})

export const getPurchaseHistoryByDate = (startMonth, endMonth) => ({
    type: purchaseHistoryTableConstants.GET_PURCHASE_HIST_BY_DATE,
    startMonth, endMonth
})

export const getPrevPurchaseHistory = () => ({
    type: purchaseHistoryTableConstants.GET_PREV_PURCHASE_HISTORY
})

export const getNextPurchaseHistory = (lengthOfList) => ({
    type: purchaseHistoryTableConstants.GET_NEXT_PURCHASE_HISTORY,
    lengthOfList
})