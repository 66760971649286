import React from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import { withTranslation } from 'react-i18next' 

const ProfileMenu = ({t}) => {
    return (
        <>

        <div className="d-none d-md-block">
            <div className="clearfix d-flex mt-3 mb-3">
                <NavLink to="/myaccount/profile/account" className="no-text-decoration myaccount-profile-tab">
                    <i className="material-icons mr-2">account_circle</i>
                    <div>{t("Account information")}</div>
                </NavLink>
                <NavLink to="/myaccount/profile/alerts" className="myaccount-profile-tab">
                    <i className="material-icons mr-2">notifications</i>
                    <div>{t("Contact info & alerts")}</div>
                </NavLink>
                <NavLink to="/myaccount/profile/banks" className="myaccount-profile-tab">
                    <i className="material-icons mr-2">account_balance</i>
                    <div>{t("Bank information")}</div>
                </NavLink>
                <NavLink to="/myaccount/profile/documents" className="myaccount-profile-tab">
                    <i className="material-icons mr-2">description</i>
                    <div>{t("Document center")}</div>
                </NavLink>
            </div>
        </div>

        <div className="d-md-none d-block">
            <div className="row">
                <ul className="myaccount-nav nav nav-filled align-items-end w-25">
                    <li className="nav-item">
                        <NavLink to="/myaccount/profile/account" className="nav-link d-flex flex-column align-items-center">
                            <i className="material-icons">account_circle</i>
                            <div className="nav-description">{t("Account")}</div>
                        </NavLink>
                    </li>
                </ul>
                <ul className="myaccount-nav nav nav-filled align-items-end w-25">
                    <li className="nav-item">
                        <NavLink to="/myaccount/profile/alerts" className="nav-link d-flex flex-column align-items-center">
                            <i className="material-icons">notifications</i>
                            <div className="nav-description">{t("Alerts")}</div>
                        </NavLink>
                    </li>
                </ul>
                <ul className="myaccount-nav nav nav-filled align-items-end w-25">
                    <li className="nav-item">
                        <NavLink to="/myaccount/profile/banks" className="nav-link d-flex flex-column align-items-center">
                            <i className="material-icons">account_balance</i>
                            <div className="nav-description">{t("Banks")}</div>
                        </NavLink>
                    </li>
                </ul>
                <ul className="myaccount-nav nav nav-filled align-items-end w-25">
                    <li className="nav-item">
                        <NavLink to="/myaccount/profile/documents" className="nav-link d-flex flex-column align-items-center">
                            <i className="material-icons">description</i>
                            <div className="nav-description">{t("Documents")}</div>
                        </NavLink>
                    </li>
                </ul>
            </div>
        </div>

        </>
    )
}

ProfileMenu.propTypes = {
    router: PropTypes.shape({
        location: PropTypes.object,
        navigate: PropTypes.func
    }),
}

export default withTranslation("profileSettings")(ProfileMenu)