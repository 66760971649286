import React from "react"
import PropTypes from 'prop-types'
import Card from "@mui/material/Card"

import ScheduledPaymentContainer from './scheduled-payment-container'
import RecentPaymentsContainer from './recent-payments-container'

const PaymentHistory = ({
        scheduledPaymentList,
        recentPaymentsList,
        bankAccountList,
        makeAnotherPayment,
        scheduledBy,
        supportPhoneNumber,
        t,
        i18n }) =>
{
    const hasScheduledPayment = scheduledPaymentList.length > 0
    const hasRecentPayments = recentPaymentsList.length > 0

    return (
    <div className="mt-3">
        <h5>{t("My payment history")}</h5>
        <Card className="p-4">
            {hasScheduledPayment &&
            <ScheduledPaymentContainer scheduledPayment={scheduledPaymentList[0]}
                bankAccountList={bankAccountList}
                scheduledBy={scheduledBy}
                showMakeAnotherPaymentButton={!hasRecentPayments}
                makeAnotherPayment={makeAnotherPayment}
                supportPhoneNumber={supportPhoneNumber}
                t={t}
                i18n={i18n}
            />
            }

            {hasRecentPayments &&
            <div className={hasScheduledPayment ? "mt-5" : ""}>
            <RecentPaymentsContainer
                recentPaymentsList={recentPaymentsList}
                bankAccountList={bankAccountList}
                makeAnotherPayment={makeAnotherPayment}
                scheduledBy={scheduledBy}
            />
            </div>
            }
        </Card>
    </div>
    )
}

PaymentHistory.propTypes = {
    scheduledPaymentList: PropTypes.arrayOf(PropTypes.object).isRequired,
    recentPaymentsList: PropTypes.arrayOf(PropTypes.object).isRequired,
    bankAccountList: PropTypes.arrayOf(PropTypes.object).isRequired,
    makeAnotherPayment: PropTypes.func.isRequired,
    scheduledBy: PropTypes.string.isRequired,
    supportPhoneNumber: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired,
    i18n: PropTypes.object.isRequired
}

export default PaymentHistory