import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

class BlankPageTest extends React.Component {
    render() {
        return (
            <React.Fragment>
                Is logged in: {this.props.isLoggedIn.toString()}
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        isLoggedIn: state.login.isLoggedIn
    }
}

BlankPageTest.propTypes = {
    isLoggedIn: PropTypes.bool
}

export default connect(mapStateToProps)(BlankPageTest)