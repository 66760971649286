/* eslint react/no-multi-comp: "off" */

import React from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

import config from 'my-account-config'
import Checkbox from '@mui/material/Checkbox'
import { Button, Menu, MenuItem } from '@mui/material'
import styled from '@emotion/styled'
import Icon from '@mui/material/Icon'
import IconButton from '@mui/material/IconButton'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableHead from '@mui/material/TableHead'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import TableFooter from '@mui/material/TableFooter'
import CircularProgress from '@mui/material/CircularProgress'
import TextField from '@mui/material/TextField'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import { NumericFormat } from 'react-number-format'

import MultiPaymentAccountTypeSelector from '../multi-payment-account-type-selector'
import { multiPaymentConstants } from '../../../constants/payment/multi-payment-constants'
import SrpModal from '../../common_modal/srp-modal'
import PayableAccountRow from './payable-account-row'
import PayableAccountRowResponsive from './payable-account-row-responsive'
import PayableAccountRowOneTimePaymentResponsive from './payable-account-row-one-time-payment-responsive'
import { billAccountConstants } from '../../../constants/bill-account-constants'
import SummaryBillingSubAccounts from './summary-billing-subaccounts'
import SummaryBillingSubAccountsResponsive from './summary-billing-subaccounts-responsive'

let filterButtonStyleClearAll = {
    textTransform: 'none',
    borderRadius: '30px',
    marginRight: '10px',
    fontWeight: '800',
    paddingTop: '4px',
    paddingBottom: '4px',
    marginBottom: '5px',
    color: 'rgba(0, 0, 0, 0.87)',
    borderColor: 'rgba(0, 0, 0, 0.23)',
    fontSize: '16px'
}

let filterButtonStyle = {
    ...filterButtonStyleClearAll,
    paddingRight: '5px'
}

let filterButtonIconStyle = {
    color: 'rgb(158,42,43)',
    fontSize: '22px',
    marginLeft: '5px'
}

let TableFooterNoBottomBorder = styled(TableFooter)`
    & .MuiTableCell-footer {
        border-bottom: none;
    }
`

function getAmountDueDisplay(amountDue) {
    const displayAmountDue = Math.abs(amountDue).toLocaleString("en-US", { style: "currency", currency: "USD" })

    return amountDue >= 0 ?
        displayAmountDue :
        displayAmountDue + " CR"
}

function getTotalAmountDisplay(data) {
    const totalAmountDue = data.reduce((accumulator, p) => accumulator + (p.display ? p.paymentInfo.amountDueWithShare : 0), 0)
    const totalAmountDueDisplay = getAmountDueDisplay(totalAmountDue)
    return totalAmountDueDisplay
}

function getTotalPayment(paymentList) {
    let totalPayments = 0
    paymentList.forEach(function (item) {
        let paymentAmountInput = item.paymentAmount
        if (typeof paymentAmountInput === 'string' || paymentAmountInput instanceof String)
            paymentAmountInput = paymentAmountInput.trim().replace(/[$,]/g, '')
        const paymentAmount = Number(paymentAmountInput)
        if (!isNaN(paymentAmount)) {
            totalPayments += paymentAmount
        }
    })

    return totalPayments.toFixed(2)
}

function getSelectedInfo(billAccount, paymentList) {
    let selectedInfo = { isSelected: false }
    for (let i = 0; i < paymentList.length; i++) {
        if (paymentList[i].billAccount === billAccount) {
            selectedInfo = {
                ...paymentList[i],
                isSelected: true
            }
        }
    }

    return selectedInfo
}

function getDisabled(paymentInfo, isSelected, paymentListCount) {
    if (!isSelected && paymentListCount >= multiPaymentConstants.MAX_SIMULTANEOUS_PAYMENTS){
        return true
    } else if (paymentInfo.isAdditionalPayment) {
        return false
    } else {
        return !paymentInfo.selectable
    }
}

function getDeleteDialogButtons(deleteDialogRemoveClick, closeDialogClick, t) {
    let buttons = []
    buttons.push(<div key="1" className="btn srp-btn btn-lightblue ml-2" onClick={() => { closeDialogClick() }}>{t("Cancel")}</div>)
    buttons.push(<div key="2" className="btn srp-btn btn-green ml-2" onClick={() => { deleteDialogRemoveClick() }}>{t("Remove")}</div>)
    return buttons
}

function hasSummaryBillingAccount(paymentInfoList) {
    let hasSummaryBillingAccount = false

    for (let i = 0; i < paymentInfoList.length; i++) {
        if (paymentInfoList[i].summaryBillingStatus === billAccountConstants.SUMMARY_BILLING_STATUS.PARENT) {
            hasSummaryBillingAccount = true
            break
        }
    }

    return hasSummaryBillingAccount
}
class PayableAccountsTable extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            showDeleteDialog: false,
            deletePaymentInfo: {},
            menuAnchor: null,
            filterMenuAnchor: null,
            isTextFiltering: false,
            allowTextFiltering: false,
            hasSummaryBillingAccount: hasSummaryBillingAccount(props.paymentInfoList)
        }

        this.showDeletePaymentDialogClick = this.showDeletePaymentDialogClick.bind(this)
        this.dismissDeletePaymentDialogClick = this.dismissDeletePaymentDialogClick.bind(this)
        this.deleteDialogRemoveClick = this.deleteDialogRemoveClick.bind(this)
        this.openMenu = this.openMenu.bind(this)
        this.handleClose = this.handleClose.bind(this)
        this.handleCheckChanged = this.handleCheckChanged.bind(this)
        this.toggleSelected = this.toggleSelected.bind(this)
        this.openFilterMenu = this.openFilterMenu.bind(this)
        this.closeFilterMenu = this.closeFilterMenu.bind(this)
        this.toggleTextFiltering = this.toggleTextFiltering.bind(this)
        this.handleFilterKeysUp = this.handleFilterKeysUp.bind(this)
        this.clearAllFilters = this.clearAllFilters.bind(this)
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if ((nextProps.showGeneralWarning !== this.props.showGeneralWarning && nextProps.showGeneralWarning) ||
            (nextProps.showMaxPaymentWarning !== this.props.showMaxPaymentWarning && nextProps.showMaxPaymentWarning) ||
            (nextProps.errorVerifyingPayments !== this.props.errorVerifyingPayments && nextProps.errorVerifyingPayments)) {
            document.getElementById("payable-accounts-table-header").scrollIntoView({ behavior: 'smooth' })
        }
    }

    openMenu(event) {
        this.setState({ ...this.state, menuAnchor: event.currentTarget })
    }

    handleClose() {
        this.setState({ ...this.state, menuAnchor: null })
    }

    openFilterMenu(event) {
        this.setState({ ...this.state, filterMenuAnchor: event.currentTarget })
    }

    closeFilterMenu() {
        this.setState({ ...this.state, filterMenuAnchor: null })
    }

    handleCheckChanged(event) {
        event.stopPropagation()
        this.toggleSelected()
    }

    handleChange(value) {
        return () => {
            this.handleClose()

            switch (value) {
                case 'all':
                    return this.props.selectAllPayableAccountsOnClick()
                case 'unpaid100':
                    return this.props.selectFirst100UnpaidAccountsOnClick()
                case 'unpaid':
                    return this.props.selectUnpaidAccountsOnClick()
                case 'none':
                    return this.props.selectNoneAccountsOnClick()
            }
        }
    }

    toggleSelected() {
        if (this.props.paymentList.length > 0) {
            return this.props.selectNoneAccountsOnClick()
        } else {
            return this.props.selectAllPayableAccountsOnClick()
        }
    }

    showDeletePaymentDialogClick(scheduledPayment) {
        this.setState({
            ...this.state,
            showDeleteDialog: true,
            deletePaymentInfo: {
                scheduledPayment: scheduledPayment
            }
        })
    }

    dismissDeletePaymentDialogClick() {
        this.setState({
            ...this.state,
            showDeleteDialog: false,
            deletePaymentInfo: {}
        })
    }

    deleteDialogRemoveClick() {
        // get reference number to cancel
        let referenceNumber = this.state.deletePaymentInfo.scheduledPayment.referenceNumber

        // get all the affected payments
        let paymentsDeleted = []
        this.props.paymentInfoList.forEach(paymentInfo => {
            if (paymentInfo.scheduledPayments.length > 0
                && paymentInfo.scheduledPayments[0].referenceNumber === referenceNumber) {
                paymentsDeleted.push(paymentInfo.scheduledPayments[0])
            }
        })

        // delete payment
        this.props.deletePaymentClick(this.state.deletePaymentInfo.scheduledPayment.referenceNumber, paymentsDeleted)

        // dismiss the dialog
        this.setState({
            ...this.state,
            showDeleteDialog: false,
            undoPaymentsInfo: paymentsDeleted
        })
    }

    toggleTextFiltering() {
        let nextIsTextFiltering = !this.state.isTextFiltering
        if (!nextIsTextFiltering) {
            this.props.filterAccountsText(false, '')
        }

        this.setState({ ...this.state, isTextFiltering: nextIsTextFiltering})
    }

    handleFilterKeysUp(event) {
        if (event.target.value.length >= 3
            || this.state.allowTextFiltering
            || event.key === ' '
            || event.key === 'Enter') {
                this.props.filterAccountsText(true, event.target.value)
        } else if (event.target.value.length === 0) {
            this.props.filterAccountsText(false, event.target.value)
        }

        if (event.target.value.length >=3 && !this.state.allowTextFiltering) {
            this.setState({ ...this.state, allowTextFiltering: true})
        }

        return
    }

    clearAllFilters() {
        this.props.enableAccountFilter(false)
        this.setState({ ...this.state, isTextFiltering: false})
    }

    render() {
        let t = this.props.t
        let maxPaymentsWarningText = `${t("Max number of accounts to pay")} (${multiPaymentConstants.MAX_SIMULTANEOUS_PAYMENTS}) ${t("exceeded")}.`

        let searchIcon = this.state.isTextFiltering
            ? (<IconButton color="primary" size="small"
                onClick={() => this.toggleTextFiltering()} >
                <Icon>clear</Icon>
            </IconButton>)
            : (<IconButton color="primary" size="small"
                onClick={() => { this.toggleTextFiltering() }} >
                <Icon>search</Icon>
            </IconButton>)

        let pageHeader = this.state.isTextFiltering
            ? (<TextField
                fullWidth
                inputRef={(input) => (input && input.focus())}
                onKeyUp={this.handleFilterKeysUp}
                onChange={() => {}}
                InputProps={{
                    disableUnderline: true,
                    style: {
                        fontWeight: 'bold',
                        fontSize: "24px",
                        color: '#004B87',
                        boxShadow: '0px 0px 8px rgb(8 126 216 / 99%)',
                        borderRadius: '5px'
                    }
                }}
            />)
            : <span className="align-middle" style={{ marginRight: "0.2rem", fontSize: "24px", color: "#004B87" }}><strong>{t("Payable accounts")}</strong></span>

        return (
            <div className="srp-card-body">
                <div className="srp-card-details">
                    <div className="d-none d-lg-block srp-card-header clearfix">
                        <div className="col-9" style={{float: 'left', paddingLeft: '0px', paddingRight: '0px'}}>{pageHeader}</div>
                        <div className="col-3" style={{float: 'right', paddingRight: '0px', textAlign: 'right'}}>
                            {searchIcon}
                            <Button
                                style={{color: 'rgb(8,126,216)'}}
                                id="filter-menu-button"
                                onClick={this.openFilterMenu}>
                                <Icon>filter_list</Icon>
                            </Button>
                        </div>
                    </div>
                    <div className="d-block d-lg-none srp-card-header clearfix">
                        <div className="col-7" style={{float: 'left', paddingLeft: '0px', paddingRight: '0px'}}>{pageHeader}</div>
                        <div className="col-5 clearfix" style={{float: 'right', textAlign: 'right'}}>
                            {searchIcon}
                            <Button
                                style={{color: 'rgb(8,126,216)'}}
                                id="filter-menu-button-responsive"
                                onClick={this.openFilterMenu}>
                                <Icon>filter_list</Icon>
                            </Button>
                        </div>
                    </div>
                    <Menu id="select-filter-accounts-menu" open={this.state.filterMenuAnchor !== null}
                            anchorEl={this.state.filterMenuAnchor} onClose={this.closeFilterMenu} variant="menu">
                            <MenuItem
                                onClick={() => {}}
                                classes={{root: 'multipayment-filter-menu-header'}}
                                style={{position: 'relative'}}
                            >
                                <div className="clearFix" style={{width: '100%'}}>
                                    <div style={{float: 'left', fontWeight: '700', color: 'rgba(0,0,0,0.5)'}}>{t("Filters")}</div>
                                    <div
                                        className="multipayment-filter-menu-header-clear-all"
                                        style={{float: 'right', color: 'rgb(8,126,216)', fontSize: '16px'}}
                                        onClick={() => {this.props.enableAccountFilter(false)}}
                                    >
                                        {t("Reset all")}
                                    </div>
                                </div>
                            </MenuItem>
                            {this.state.hasSummaryBillingAccount &&
                                <MenuItem
                                    onClick={() => {this.props.enableAccountFilter(!this.props.accountFilter.enabled)}}
                                    classes={{root: 'multipayment-filter-menu-header'}}
                                    style={{position: 'relative'}}
                                    disabled={true}
                                >
                                    <span className="mr-5" style={{fontWeight: '700', fontSize: '16px'}}>{t("Account Type")}</span>
                                </MenuItem>
                            }
                            {this.state.hasSummaryBillingAccount &&
                                <MenuItem onClick={() => {this.props.filterAccountsSummaryMaster(!this.props.accountFilter.summaryMaster)}} style={{position: 'relative'}}>
                                    <div className="multipayment-menu-clickable-div" />
                                    <span className="ml-2 mr-3">{t("Summary master")}</span>
                                    <div className="clearfix" style={{width: '100%'}}>
                                        <label className="switch align-middle mb-0" style={{float: 'right'}}>
                                            <input
                                                type="checkbox"
                                                checked={this.props.accountFilter.summaryMaster}
                                                onChange={() => {this.props.filterAccountsSummaryMaster(!this.props.accountFilter.summaryMaster)}}
                                            />
                                            <span className="slider"/>
                                        </label>
                                    </div>
                                </MenuItem>
                            }
                            <MenuItem
                                onClick={() => {this.props.enableAccountFilter(!this.props.accountFilter.enabled)}}
                                classes={{root: 'multipayment-filter-menu-header'}}
                                style={{position: 'relative'}}
                                disabled={true}
                            >
                                <span className="mr-5" style={{fontWeight: '700', fontSize: '16px'}}>{t("Payment status")}</span>
                            </MenuItem>
                            <MenuItem onClick={() => {this.props.filterAccountsDue(!this.props.accountFilter.due)}} style={{position: 'relative'}}>
                                <div className="multipayment-menu-clickable-div" />
                                <span className="ml-2 mr-3">{t("Due")}</span>
                                <div className="clearfix" style={{width: '100%'}}>
                                    <label className="switch align-middle mb-0" style={{float: 'right'}}>
                                        <input
                                            type="checkbox"
                                            checked={this.props.accountFilter.due}
                                            onChange={() => {this.props.filterAccountsDue(!this.props.accountFilter.due)}}
                                        />
                                        <span className="slider"/>
                                    </label>
                                </div>
                            </MenuItem>
                            <MenuItem onClick={() => {this.props.filterAccountsPastDue(!this.props.accountFilter.pastDue)}} style={{position: 'relative'}}>
                                <div className="multipayment-menu-clickable-div" />
                                <span className="ml-2 mr-3">{t("Past due")}</span>
                                <div className="clearfix" style={{width: '100%'}}>
                                    <label className="switch align-middle mb-0" style={{float: 'right'}}>
                                        <input
                                            type="checkbox"
                                            checked={this.props.accountFilter.pastDue}
                                            onChange={() => {this.props.filterAccountsPastDue(!this.props.accountFilter.pastDue)}}
                                        />
                                        <span className="slider"/>
                                    </label>
                                </div>
                            </MenuItem>
                            <MenuItem onClick={() => {this.props.filterAccountsScheduled(!this.props.accountFilter.scheduled)}} style={{position: 'relative'}}>
                                <div className="multipayment-menu-clickable-div" />
                                <span className="ml-2 mr-3">{t("Scheduled")}</span>
                                <div className="clearfix" style={{width: '100%'}}>
                                    <label className="switch align-middle mb-0" style={{float: 'right'}}>
                                        <input
                                            type="checkbox"
                                            checked={this.props.accountFilter.scheduled}
                                            onChange={() => {this.props.filterAccountsScheduled(!this.props.accountFilter.scheduled)}}
                                        />
                                        <span className="slider"/>
                                    </label>
                                </div>
                            </MenuItem>
                            <MenuItem onClick={() => {this.props.filterAccountsSurePay(!this.props.accountFilter.surePay)}} style={{position: 'relative'}}>
                                <div className="multipayment-menu-clickable-div" />
                                <span className="ml-2 mr-3">{t("SurePay")}</span>
                                <div className="clearfix" style={{width: '100%'}}>
                                    <label className="switch align-middle mb-0" style={{float: 'right'}}>
                                        <input
                                            type="checkbox"
                                            checked={this.props.accountFilter.surePay}
                                            onChange={() => {this.props.filterAccountsSurePay(!this.props.accountFilter.surePay)}}
                                        />
                                        <span className="slider"/>
                                    </label>
                                </div>
                            </MenuItem>
                        </Menu>
                    {this.props.accountFilter.enabled &&
                        <div>
                            {this.props.accountFilter.summaryMaster &&
                                <Button
                                    style={filterButtonStyle}
                                    variant="outlined"
                                    onClick={() => {this.props.filterAccountsSummaryMaster(false)}}>
                                        {t("Summary master")}
                                        <Icon style={filterButtonIconStyle}>cancel</Icon>
                                </Button>
                            }
                            {this.props.accountFilter.due &&
                                <Button
                                    style={filterButtonStyle}
                                    variant="outlined"
                                    onClick={() => {this.props.filterAccountsDue(false)}}>
                                        {t("Due")}
                                        <Icon style={filterButtonIconStyle}>cancel</Icon>
                                </Button>
                            }
                            {this.props.accountFilter.pastDue &&
                                <Button
                                    style={filterButtonStyle}
                                    variant="outlined"
                                    onClick={() => {this.props.filterAccountsPastDue(false)}}>
                                        {t("Past due")}
                                        <Icon style={filterButtonIconStyle}>cancel</Icon>
                                </Button>
                            }
                            {this.props.accountFilter.scheduled &&
                                <Button
                                    style={filterButtonStyle}
                                    variant="outlined"
                                    onClick={() => {this.props.filterAccountsScheduled(false)}}>
                                        {t("Scheduled")}
                                        <Icon style={filterButtonIconStyle}>cancel</Icon>
                                </Button>
                            }
                            {this.props.accountFilter.surePay &&
                                <Button
                                    style={filterButtonStyle}
                                    variant="outlined"
                                    onClick={() => {this.props.filterAccountsSurePay(false)}}>
                                        {t("SurePay")}
                                        <Icon style={filterButtonIconStyle}>cancel</Icon>
                                </Button>
                            }
                            <Button
                                style={filterButtonStyleClearAll}
                                variant="outlined"
                                onClick={() => {this.clearAllFilters()}}>
                                    {t("Clear all")}
                            </Button>
                        </div>
                    }
                    <MultiPaymentAccountTypeSelector />
                    {/* desktop view */}
                    <div className="d-none d-lg-block mb-4" style={{ overflowX: 'auto', marginLeft: '-20px', marginRight: '-20px' }}>
                        {this.props.showMaxPaymentWarning &&
                            <div className="srp-alert-warn mt-3 pt-2 mb-1 w-100">
                                {maxPaymentsWarningText}
                            </div>
                        }
                        {this.props.showGeneralWarning &&
                            <div className="srp-alert-error mt-3 pt-2 mb-1 w-100">
                                {t("error_confirming_payment")}
                            </div>
                        }
                        {this.props.errorVerifyingPayments &&
                        <div className="srp-alert-error mt-3 pt-2 mb-1 w-100">
                            {`${t("unable_complete_payment")} ${this.props.supportPhoneNumber}.`}
                        </div>
                        }

                        <div className="multipayment-large-header">
                            <div style={{float: 'right', fontSize: '15px', fontWeight: '500'}}>{`${this.props.totalCreditAccounts} ${t("Accounts")} (${this.props.paymentList.length} ${t("Selected")})`}</div>
                            <div className="clearfix" />
                        </div>
                        <Table style={{ fontSize: '15px', tableLayout: 'fixed' }}>
                            <colgroup>
                                <col width="8%" />
                                <col width="30%" />
                                <col width="14%" />
                                <col width="9%" />
                                <col width="3%" />
                                <col width="13%" />
                                <col width="3%" />
                                <col width="17%" />
                                <col width="3%" />
                            </colgroup>
                            <TableHead>
                                <TableRow style={{fontSize: '18px', backgroundColor: 'rgba(229,237,243,1)'}}>
                                    <TableCell padding="none">
                                        <Button id="unlinked-checkbox" ref="unlinkedCheckbox" aria-haspopup="true"
                                            onClick={this.openMenu} style={{ textTransform: 'none' }} endIcon={<ArrowDropDownIcon />}>
                                            <Checkbox color="primary" onClick={this.handleCheckChanged}
                                                indeterminate={!this.props.selectAll && this.props.paymentList.length > 0}
                                                checked={this.props.selectAll} />
                                        </Button>
                                        <Menu id="select-unlinked-accounts-menu" open={this.state.menuAnchor !== null}
                                            anchorEl={this.state.menuAnchor} onClose={this.handleClose}>
                                            {this.props.unpaidBillAccountsCount < multiPaymentConstants.MAX_SIMULTANEOUS_PAYMENTS ?
                                                <MenuItem onClick={this.handleChange('unpaid')}>{t("Unpaid")}</MenuItem> :
                                                <MenuItem onClick={this.handleChange('unpaid100')}>{t("First 100 unpaid accounts")}</MenuItem>
                                            }
                                            <MenuItem onClick={this.handleChange('none')}>{t("None")}</MenuItem>
                                        </Menu>
                                    </TableCell>
                                    <TableCell padding="none">{t("Account")}</TableCell>
                                    <TableCell padding="none">{t("Status")}</TableCell>
                                    <TableCell padding="none" style={{ textAlign: "right" }}>{t("Amount due")}</TableCell>
                                    <TableCell padding="none" />
                                    <TableCell padding="none" style={{ textAlign: "right" }}>{t("Payment amount")}</TableCell>
                                    <TableCell padding="none" />
                                    <TableCell padding="none">{t("Payment date")}</TableCell>
                                    <TableCell />
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.props.paymentInfoList.map((n, index) => {
                                    let selectedInfo = getSelectedInfo(n.billAccount, this.props.paymentList)
                                    let disabled = getDisabled(n, selectedInfo.isSelected, this.props.paymentList.length)
                                    let displayborder = n.summaryBillingStatus === billAccountConstants.SUMMARY_BILLING_STATUS.PARENT && n.summaryBillingSubAccounts.length > 0 ? false : true

                                    if (!n.display) {
                                        return null
                                    } else if (n.isAdditionalPayment) {
                                        return (
                                            <React.Fragment key={`${n.billAccount}_${index}`}>
                                                <PayableAccountRow
                                                    displayBorder={false}
                                                    paymentInfo={n.scheduledPaymentInfo}
                                                    index={index}
                                                    selectedInfo={{isSelected: false}}
                                                    disabled={true}
                                                    paymentListLength={this.props.paymentList.length}
                                                    selectableCount={this.props.selectableCount}
                                                    getSelectedPayableAccountsOnClick={this.props.getSelectedPayableAccountsOnClick}
                                                    getPaymentAmountOnChange={this.props.getPaymentAmountOnChange}
                                                    getPaymentDateOnChange={this.props.getPaymentDateOnChange}
                                                    viewBillClick={this.props.viewBillClick}
                                                    deletePaymentClick={this.showDeletePaymentDialogClick}
                                                    cixClick={this.props.cixClick}
                                                />
                                                <PayableAccountRow
                                                    displayBorder={displayborder}
                                                    paymentInfo={n}
                                                    index={index}
                                                    selectedInfo={selectedInfo}
                                                    disabled={disabled}
                                                    paymentListLength={this.props.paymentList.length}
                                                    selectableCount={this.props.selectableCount}
                                                    getSelectedPayableAccountsOnClick={this.props.getSelectedPayableAccountsOnClick}
                                                    getPaymentAmountOnChange={this.props.getPaymentAmountOnChange}
                                                    getPaymentDateOnChange={this.props.getPaymentDateOnChange}
                                                    viewBillClick={this.props.viewBillClick}
                                                    deletePaymentClick={this.showDeletePaymentDialogClick}
                                                    cixClick={this.props.cixClick}
                                                />
                                                {n.scheduledPaymentInfo.summaryBillingStatus === billAccountConstants.SUMMARY_BILLING_STATUS.PARENT &&
                                                    n.scheduledPaymentInfo.summaryBillingSubAccounts.length > 0 &&
                                                    <SummaryBillingSubAccounts
                                                        subAccounts={n.scheduledPaymentInfo.summaryBillingSubAccounts}
                                                        viewBillClick={this.props.viewBillClick}
                                                    />
                                                }
                                            </React.Fragment>
                                        )
                                    }
                                    else {
                                        return (
                                            <React.Fragment key={`${n.billAccount}_${index}`}>
                                                <PayableAccountRow
                                                    displayBorder={displayborder}
                                                    paymentInfo={n}
                                                    index={index}
                                                    selectedInfo={selectedInfo}
                                                    disabled={disabled}
                                                    paymentListLength={this.props.paymentList.length}
                                                    selectableCount={this.props.selectableCount}
                                                    getSelectedPayableAccountsOnClick={this.props.getSelectedPayableAccountsOnClick}
                                                    getPaymentAmountOnChange={this.props.getPaymentAmountOnChange}
                                                    getPaymentDateOnChange={this.props.getPaymentDateOnChange}
                                                    viewBillClick={this.props.viewBillClick}
                                                    deletePaymentClick={this.showDeletePaymentDialogClick}
                                                    cixClick={this.props.cixClick}
                                                />
                                                {n.summaryBillingStatus === billAccountConstants.SUMMARY_BILLING_STATUS.PARENT &&
                                                    n.summaryBillingSubAccounts.length > 0 &&
                                                    <SummaryBillingSubAccounts
                                                        subAccounts={n.summaryBillingSubAccounts}
                                                        viewBillClick={this.props.viewBillClick}
                                                    />
                                                }
                                            </React.Fragment>
                                        )
                                    }
                                })}
                            </TableBody>
                            <TableFooterNoBottomBorder>
                                <TableRow>
                                    <TableCell padding="none" />
                                    <TableCell padding="none" />
                                    <TableCell colSpan="7" sx={{ textAlign: 'right', fontSize: '18px' }} className="py-2 pr-5">
                                        <span style={{ color: 'rgba(33, 33, 33, 0.537254901960784)'}}>
                                            {t("Total amount due:")}&nbsp;
                                        </span>
                                        <span>
                                            <strong>
                                                {getTotalAmountDisplay(this.props.paymentInfoList)}
                                            </strong>
                                        </span>
                                        <span style={{ color: 'rgba(33, 33, 33, 0.537254901960784)'}}>
                                            &nbsp;&nbsp;&nbsp;&nbsp;{t("Total payment:")}&nbsp;
                                        </span>
                                        <span>
                                            <strong>
                                                <NumericFormat
                                                    value={getTotalPayment(this.props.paymentList)}
                                                    valueIsNumericString={true}
                                                    decimalScale={2}
                                                    thousandSeparator={true}
                                                    prefix={'$'}
                                                    displayType="text"
                                                />
                                            </strong>
                                        </span>
                                    </TableCell>
                                </TableRow>
                            </TableFooterNoBottomBorder>
                        </Table>
                    </div>

                    {/* responsive view */}
                    <div className="d-block d-lg-none mb-4">
                        {this.props.showMaxPaymentWarning &&
                        <div className="srp-alert-warn mt-1 pt-2 mb-1 w-100">
                            {maxPaymentsWarningText}
                        </div>
                        }
                        {this.props.showGeneralWarning &&
                        <div className="srp-alert-error mt-1 pt-2 mb-1 w-100">
                            {t("error_confirming_payment")}
                        </div>
                        }
                        {this.props.errorVerifyingPayments &&
                        <div className="srp-alert-error mt-1 pt-2 mb-1 w-100">
                            {t("unable_complete_payment")} <a href={"tel:" + this.props.supportPhoneNumber}>{this.props.supportPhoneNumber}</a>.
                        </div>
                        }
                        <div className="pat-header-responsive clearfix">
                            <Button id="unlinked-checkbox"
                                ref="unlinkedCheckbox"
                                aria-haspopup="true"
                                onClick={this.openMenu}
                                style={{ textTransform: 'none' }}
                                endIcon={<ArrowDropDownIcon />}
                                size="small">
                                <Checkbox
                                    color="primary"
                                    onClick={this.handleCheckChanged}
                                    indeterminate={!this.props.selectAll && this.props.paymentList.length > 0}
                                    checked={this.props.selectAll}
                                />
                            </Button>
                            <Menu id="select-unlinked-accounts-menu" open={this.state.menuAnchor !== null}
                                anchorEl={this.state.menuAnchor} onClose={this.handleClose}>
                                {this.props.unpaidBillAccountsCount < multiPaymentConstants.MAX_SIMULTANEOUS_PAYMENTS ?
                                    <MenuItem onClick={this.handleChange('unpaid')}>{t("Unpaid")}</MenuItem> :
                                    <MenuItem onClick={this.handleChange('unpaid100')}>{t("First 100 unpaid accounts")}</MenuItem>
                                }
                                <MenuItem onClick={this.handleChange('none')}>{t("None")}</MenuItem>
                            </Menu>
                            <div className="mt-2" style={{float: "right"}}>
                                <div style={{fontSize: '15px', fontWeight: '500'}}>{`${this.props.totalCreditAccounts} ${"Accounts"} (${this.props.paymentList.length} ${t("Selected")})`}</div>
                                <div className="clearfix" />
                            </div>
                        </div>
                        <Table style={{ fontSize: '15px', borderCollapse: 'separate' }}>
                            <TableBody>
                                <TableRow style={{height: "10px", maxWidth: "300px"}} />
                                {this.props.paymentInfoList.map((paymentInfo, index) => {
                                    let selectedInfo = getSelectedInfo(paymentInfo.billAccount, this.props.paymentList)
                                    let disabled = getDisabled(paymentInfo, selectedInfo.isSelected, this.props.paymentList.length)

                                    if (!paymentInfo.display) {
                                        return null
                                    } else if (paymentInfo.isAdditionalPayment) {
                                        return (
                                            <React.Fragment key={`${paymentInfo.billAccount}_${index}_responsive`}>
                                                <PayableAccountRowOneTimePaymentResponsive
                                                    paymentInfo={paymentInfo}
                                                    disabled={disabled}
                                                    selectedInfo={selectedInfo}
                                                    viewBillClick={this.props.viewBillClick}
                                                    getSelectedPayableAccountsOnClick={this.props.getSelectedPayableAccountsOnClick}
                                                    getPaymentAmountOnChange={this.props.getPaymentAmountOnChange}
                                                    getPaymentDateOnChange={this.props.getPaymentDateOnChange}
                                                    deletePaymentClick={this.showDeletePaymentDialogClick}
                                                    cixClick={this.props.cixClick}
                                                    index={index}
                                                />
                                                {paymentInfo.scheduledPaymentInfo.summaryBillingStatus === billAccountConstants.SUMMARY_BILLING_STATUS.PARENT &&
                                                    paymentInfo.scheduledPaymentInfo.summaryBillingSubAccounts.length > 0 &&
                                                    <SummaryBillingSubAccountsResponsive
                                                        subAccounts={paymentInfo.scheduledPaymentInfo.summaryBillingSubAccounts}
                                                        viewBillClick={this.props.viewBillClick}
                                                    />
                                                }
                                             </React.Fragment>
                                    )} else {
                                        return (
                                            <React.Fragment key={`${paymentInfo.billAccount}_${index}_responsive`}>
                                                <PayableAccountRowResponsive
                                                    paymentInfo={paymentInfo}
                                                    disabled={disabled}
                                                    selectedInfo={selectedInfo}
                                                    viewBillClick={this.props.viewBillClick}
                                                    getSelectedPayableAccountsOnClick={this.props.getSelectedPayableAccountsOnClick}
                                                    getPaymentAmountOnChange={this.props.getPaymentAmountOnChange}
                                                    getPaymentDateOnChange={this.props.getPaymentDateOnChange}
                                                    cixClick={this.props.cixClick}
                                                    index={index}
                                                />
                                                {paymentInfo.summaryBillingStatus === billAccountConstants.SUMMARY_BILLING_STATUS.PARENT &&
                                                    paymentInfo.summaryBillingSubAccounts.length > 0 &&
                                                    <SummaryBillingSubAccountsResponsive
                                                        subAccounts={paymentInfo.summaryBillingSubAccounts}
                                                        viewBillClick={this.props.viewBillClick}
                                                    />
                                                }
                                             </React.Fragment>
                                    )}
                                })}
                            </TableBody>
                            <TableFooterNoBottomBorder>
                                <TableRow>
                                    <TableCell colSpan="2" style={{ color: 'rgb(0,0,0)' }}>
                                        <div className="clearfix" style={{position: "relative"}}>
                                            <div style={{ fontSize: '15px', fontWeight: '600', color: 'rgba(33, 33, 33, 0.537254901960784)', width: '50%', textAlign: 'right', float: 'left', paddingTop: '3px' }}>
                                                {t("Total amount due:")}
                                            </div>
                                            <div style={{ fontSize: '18px', fontWeight: '600', color: '#212121', width: '50%', textAlign: 'right', float: 'left'}}>
                                                {getTotalAmountDisplay(this.props.paymentInfoList)}
                                            </div>
                                        </div>
                                        <div className="clearfix" style={{position: "relative"}}>
                                            <div style={{ fontSize: '15px', fontWeight: '600', color: 'rgba(33, 33, 33, 0.537254901960784)', width: '50%', textAlign: 'right', float: 'left', paddingTop: '3px' }}>
                                                {t("Total payment:")}
                                            </div>
                                            <div style={{ fontSize: '18px', fontWeight: '600', color: '#212121',  width: '50%', textAlign: 'right', float: 'left'}}>
                                                <NumericFormat
                                                    value={getTotalPayment(this.props.paymentList)}
                                                    valueIsNumericString={true}
                                                    decimalScale={2}
                                                    thousandSeparator={true}
                                                    prefix={'$'}
                                                    displayType="text"
                                                />
                                            </div>
                                        </div>
                                    </TableCell>
                                </TableRow>
                            </TableFooterNoBottomBorder>
                        </Table>
                        {this.props.paymentList.length > 0 && this.props.selectableCount >= multiPaymentConstants.MAX_SIMULTANEOUS_PAYMENTS &&
                            <div className="multi-payment-responsive-sticky-counter">
                                {this.props.paymentList.length > multiPaymentConstants.MAX_SIMULTANEOUS_PAYMENTS ?
                                    <div>{t("Payable account limit exceeded")}</div> :
                                    <div>{this.props.paymentList.length}/{multiPaymentConstants.MAX_SIMULTANEOUS_PAYMENTS} {t("accounts selected")}</div>
                                }
                            </div>
                        }
                    </div>
                    <div className="text-muted mb-4">
                        <span>
                            {t("Want to help fellow Arizonans through")}&nbsp;
                            <a className="displayAsLink" href={`${config.srpnetBaseUrl}account/donate/help-neighbors`} target="_blank">{t("SHARE")}</a>
                            {t("share_donation_text")}
                        </span>
                    </div>
                    <div className="d-flex justify-content-end" style={{ display: 'flex', flexWrap: 'wrap' }}>
                        <button disabled={this.props.isSubmitting || !this.props.hasBankAccounts} onClick={() => this.props.verifyMultiplePaymentsOnClick()} className="btn srp-btn btn-blue text-white ml-2 mb-2">{this.props.isSubmitting ? <CircularProgress size={20} thickness={5} style={{ color: 'white' }} /> : this.props.showMaxPaymentWarning ? maxPaymentsWarningText : t("Confirm payment")}</button>
                    </div>
                    <SrpModal
                        open={this.state.showDeleteDialog}
                        title={t("Remove scheduled payment")}
                        content={<span>{t("remove_payment_warning")}</span>}
                        okClick={this.dismissDeletePaymentDialogClick}
                        closeClick={this.dismissDeletePaymentDialogClick}
                        buttons={getDeleteDialogButtons(this.deleteDialogRemoveClick, this.dismissDeletePaymentDialogClick, this.props.t)}
                    />
                </div>
            </div>
        )
    }
}

PayableAccountsTable.propTypes = {
    paymentList: PropTypes.arrayOf(PropTypes.object),
    showMaxPaymentWarning: PropTypes.bool.isRequired,
    paymentInfoList: PropTypes.arrayOf(PropTypes.object),
    selectAllPayableAccountsOnClick: PropTypes.func.isRequired,
    selectAll: PropTypes.bool.isRequired,
    selectUnpaidAccountsOnClick: PropTypes.func.isRequired,
    selectFirst100UnpaidAccountsOnClick: PropTypes.func.isRequired,
    selectNoneAccountsOnClick: PropTypes.func.isRequired,
    getSelectedPayableAccountsOnClick: PropTypes.func.isRequired,
    getPaymentAmountOnChange: PropTypes.func.isRequired,
    getPaymentDateOnChange: PropTypes.func.isRequired,
    deletePaymentClick: PropTypes.func.isRequired,
    viewBillClick: PropTypes.func.isRequired,
    verifyMultiplePaymentsOnClick: PropTypes.func,
    isSubmitting: PropTypes.bool.isRequired,
    hasBankAccounts: PropTypes.bool.isRequired,
    showGeneralWarning: PropTypes.bool.isRequired,
    cixClick: PropTypes.func.isRequired,
    unpaidBillAccountsCount: PropTypes.number.isRequired,
    selectableCount: PropTypes.number.isRequired,
    accountFilter: PropTypes.object.isRequired,
    enableAccountFilter: PropTypes.func.isRequired,
    filterAccountsDue: PropTypes.func.isRequired,
    filterAccountsPastDue: PropTypes.func.isRequired,
    filterAccountsScheduled: PropTypes.func.isRequired,
    filterAccountsSurePay: PropTypes.func.isRequired,
    filterAccountsSummaryMaster: PropTypes.func.isRequired,
    filterAccountsText: PropTypes.func.isRequired,
    supportPhoneNumber: PropTypes.string.isRequired,
    errorVerifyingPayments: PropTypes.bool.isRequired,
    totalCreditAccounts: PropTypes.number.isRequired,
    t: PropTypes.func.isRequired,
    i18n: PropTypes.object.isRequired
}

export default withTranslation("multiPayment")(PayableAccountsTable)