import { multiPaymentConstants } from '../../../constants/payment/multi-payment-constants'
import { RSAA } from 'redux-api-middleware'
import utils from '../../../srp_modules/utils'
import { DateTime } from 'luxon'
import asyncify from 'async-es/asyncify'
import mapLimit from 'async-es/mapLimit'

let getMultiPaymentInfoCommon = (billAccount, type_request, type_success, type_failure) => async dispatch => {
    let url = utils.createUrl('payments/accountpaymentwithaddress', { billAccount: billAccount})

    let response = await dispatch({
        [RSAA]: {
            endpoint: url,
            method: 'GET',
            credentials: 'include',
            types: [
                { type: type_request, meta: { billAccount } },
                { type: type_success, meta: { billAccount } },
                { type: type_failure, meta: { billAccount } }
            ]
        }
    })

    return response
}

let verifyPayments = (paymentInfo) => (dispatch, getState) => {
    const state = getState()

    let selectedBank = state.bankAccounts.bankAccountList.find(ba => ba.bankId === paymentInfo.bankAccountId)
    const bankAccountIdToken = (selectedBank === undefined ? '' : selectedBank.bankIdToken)

    let parameters = {
        paymentList: paymentInfo.payments.map((p) => {
            return {
                billAccountNumber: Number(p.billAccount),
                paymentAmount: utils.paymentToNumber(p.paymentAmount),
                scheduledPaymentDate: DateTime.fromISO(p.paymentDate).toFormat('MM/dd/yyyy')
            }
        }),
        bankAccountIdToken: bankAccountIdToken
    }

    return dispatch({
        [RSAA]: {
            endpoint: utils.createUrl('payments/verifypaymentlist_transition'),
            method: 'POST',
            body: JSON.stringify(parameters),
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include',
            types: [
                multiPaymentConstants.VERIFY_MULTIPLE_REQUEST,
                multiPaymentConstants.VERIFY_MULTIPLE_SUCCESS,
                multiPaymentConstants.VERIFY_MULTIPLE_FAILURE
            ]
        },
        meta: {
            debounce: {
                leading: false,
                trailing: false
            }
        }
    })
}

let submitPayments = (paymentInfo, referenceNumber) => (dispatch, getState) => {
    const state = getState()

    const selectedBankId = paymentInfo.bankAccountId
    let selectedBank = state.bankAccounts.bankAccountList.find(ba => ba.bankId === selectedBankId)
    if (selectedBank === undefined) {
        selectedBank = {
            bankIdToken: '',
            firstName: '',
            lastName: '',
            email: '',
            accountNumber: ''
        }
    }

    let paymentList = paymentInfo.payments.map((pi) => {
        return {
            billAccountNumber: Number(pi.billAccount),
            paymentAmount: utils.paymentToNumber(pi.paymentAmount),
            scheduledPaymentDate: DateTime.fromISO(pi.paymentDate).toFormat('MM/dd/yyyy')
        }
    })

    let billAccountList = state.accountInfo.billAccount.billAccountList
    let allCommercial = false
    if (paymentList && billAccountList && paymentList.length > 0) {
        allCommercial = paymentList.every(p => billAccountList.find(b => b.account === p.billAccountNumber)?.isCommercial ?? false)
    }

    let parameters = {
        paymentList: paymentList,
        bankAccountIdToken: selectedBank.bankIdToken,
        sendCommercialConfirmationEmail: allCommercial,
        bankSignerFirstNameForConfirmationEmail: selectedBank.firstName,
        bankSignerLastNameForConfirmationEmail: selectedBank.lastName,
        bankSignerEmailAddressForConfirmationEmail: selectedBank.email,
        bankAccountNumberTruncatedForConfirmationEmail: selectedBank.accountNumber,
        referenceNumberFromValidatePayment: referenceNumber
    }

    return dispatch({
        [RSAA]: {
            endpoint: utils.createUrl('payments/submitpaymentlist_emailconfirmation_transition'),
            method: 'POST',
            body: JSON.stringify(parameters),
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include',
            types: [
                multiPaymentConstants.SUBMIT_MULTIPLE_REQUEST,
                multiPaymentConstants.SUBMIT_MULTIPLE_SUCCESS,
                multiPaymentConstants.SUBMIT_MULTIPLE_FAILURE
            ]
        }
    })
}

export const verifyPaymentsNoState = (paymentInfo) => {
    return verifyPayments(paymentInfo)
}

export const submitPaymentsNoState = (paymentInfo, referenceNumber) => {
    return submitPayments(paymentInfo, referenceNumber)
}

export const verifyMultiplePayments = () => (dispatch, getState) => {
    let state = getState()
    let payments = state.multiPayment.paymentList.map(payment => {
         return {
            billAccount: payment.billAccount,
            paymentDate: payment.paymentDate,
            paymentAmount: payment.paymentAmount
            }
        })
    let paymentInfo = {
        bankAccountId: state.multiPayment.selectedBankAccountId,
        payments: payments
    }

    return dispatch(verifyPayments(paymentInfo))
}

export const clearMultiPaymentInfo = () => ({
    type: multiPaymentConstants.CLEAR_MULTI_PAYMENT_INFO
})

export const submitMultiplePayments = () => (dispatch, getState) => {
    let state = getState()
    let payments = state.multiPayment.paymentList.map(payment => {
         return {
            billAccount: payment.billAccount,
            paymentDate: payment.paymentDate,
            paymentAmount: payment.paymentAmount
            }
        })
    let paymentInfo = {
        bankAccountId: state.multiPayment.selectedBankAccountId,
        payments: payments
    }

    return dispatch(submitPayments(paymentInfo, state.multiPayment.verifyMultiPaymentResult.paymentsReferenceNumber))
}

export const getSelectedBankAccountForMultiPayment = (bankAccount) => ({
    type: multiPaymentConstants.GET_SELECTED_BANK_ACCOUNT_MULTI,
    bankAccount
})

export const getSelectedPayableAccounts = (billAccount, paymentInfo) => ({
    type: multiPaymentConstants.GET_SELECTED_PAYABLE_ACCOUNTS,
    billAccount, paymentInfo
})

export const selectAllPayableAccounts = () => ({
    type: multiPaymentConstants.SELECT_ALL_PAYABLE_ACCOUNTS,
})

export const selectFirst100UnpaidAccounts = () => ({
    type: multiPaymentConstants.SELECT_FIRST_100_UNPAID_ACCOUNTS
})

export const selectUnpaidAccounts = () => ({
    type: multiPaymentConstants.SELECT_UNPAID_ACCOUNTS,
})

export const selectNoneAccounts = () => ({
    type: multiPaymentConstants.SELECT_NONE_ACCOUNTS
})

export const getPaymentDate = (billAccount, selectedDate, paymentAmount) => ({
    type: multiPaymentConstants.GET_MULTI_PAYMENT_DATE,
    billAccount, selectedDate, paymentAmount
})

export const getPaymentAmount = (billAccount, paymentAmount) => ({
    type: multiPaymentConstants.GET_MULTI_PAYMENT_AMOUNT,
    billAccount, paymentAmount
})

export const resetPaymentInfoList = () => ({
    type: multiPaymentConstants.RESET_PAYMENT_INFO_LIST
})

export const sortPaymentList = () => ({
    type: multiPaymentConstants.SORT_MULTI_PAYMENT_LIST
})


export const getMultiPaymentInfo = (billAccount) => {
    return getMultiPaymentInfoCommon(billAccount, multiPaymentConstants.GET_MULTI_PAYMENT_REQUEST, multiPaymentConstants.GET_MULTI_PAYMENT_SUCCESS, multiPaymentConstants.GET_MULTI_PAYMENT_FAILURE)
}

export const reloadMultiPaymentInfo = (billAccount) => {
    return getMultiPaymentInfoCommon(billAccount, multiPaymentConstants.RELOAD_MULTI_PAYMENT_REQUEST, multiPaymentConstants.RELOAD_MULTI_PAYMENT_SUCCESS, multiPaymentConstants.RELOAD_MULTI_PAYMENT_FAILURE)
}

export const setPaymentBillAccountListCount = (listCount) => ({
    type: multiPaymentConstants.SET_PAYMENT_BILL_ACCOUNT_LIST_COUNT,
    listCount
})

export const enableAccountFilter = (enabled) => ({
    type: multiPaymentConstants.FILTER_ACCOUNT_ENABLED,
    enabled
})
export const filterAccountsDue = (enabled) => ({
    type: multiPaymentConstants.FILTER_ACCOUNT_DUE,
    enabled
})

export const filterAccountsPastDue = (enabled) => ({
    type: multiPaymentConstants.FILTER_ACCOUNT_PAST_DUE,
    enabled
})

export const filterAccountsScheduled = (enabled) => ({
    type: multiPaymentConstants.FILTER_ACCOUNT_SCHEDULED,
    enabled
})

export const filterAccountsSurePay = (enabled) => ({
    type: multiPaymentConstants.FILTER_ACCOUNT_SUREPAY,
    enabled
})

export const filterAccountsSummaryMaster = (enabled) => ({
    type: multiPaymentConstants.FILTER_ACCOUNT_SUMMARYMASTER,
    enabled
})

export const filterAccountsText = (enabled, filterText) => ({
    type: multiPaymentConstants.FILTER_ACCOUNT_TEXT,
    enabled,
    filterText
})

export const clearSubmitPaymentError = () => ({
    type: multiPaymentConstants.CLEAR_SUBMIT_PAYMENT_ERROR
})

export const getMultiPaymentInfoFromBillAccountList = (paymentAccountList) => async (dispatch, getStore) => {
    let store = getStore()
    let existingBillAccounts = store.multiPayment.paymentInfoListLoadedBillAccounts
    let billAccountList = store.accountInfo.billAccount.billAccountList
    let summaryBillAccounts = store.accountInfo.billAccount.summaryBillAccounts
    let landlordAccounts = store.landlord.landlordAccounts

    dispatch({ type: multiPaymentConstants.LOAD_MULTI_PAYMENT_INFO_LIST_START })

    let billAccountsNeeded = new Set()
    paymentAccountList.forEach(billAccount => {
        if (!existingBillAccounts.has(billAccount)) {
            billAccountsNeeded.add(billAccount)
        }
    })

    let multiPaymentInfoList = await new Promise(resolve => mapLimit(billAccountsNeeded, 10,
        asyncify(async billAccount => {
            let result = await dispatch(getMultiPaymentInfo(billAccount))
            if (result === undefined || result === null || result.error === true) {
                throw { error: true, message: 'Payment info request error', result }
            }
            return result.payload
        }),
        (err, results) => {
            (err !== null) ? resolve(err) : resolve({ results })
        }))

    if (multiPaymentInfoList.error === true) {
        return dispatch({
            type: multiPaymentConstants.LOAD_MULTI_PAYMENT_INFO_LIST_FAILURE,
            ...multiPaymentInfoList
        })
    }

    return dispatch({
        type: multiPaymentConstants.LOAD_MULTI_PAYMENT_INFO_LIST_SUCCESS,
        multiPaymentInfoList: multiPaymentInfoList.results,
        billAccountList,
        summaryBillAccounts,
        landlordAccounts
    })
}

export const reloadMultiPaymentInfoFromBillAccountList = (paymentAccountList) => async (dispatch, getStore) => {
    let multiPaymentInfoList = []

    let store = getStore()
    let billAccountList = store.accountInfo.billAccount.billAccountList
    let summaryBillAccounts = store.accountInfo.billAccount.summaryBillAccounts
    let landlordAccounts = store.landlord.landlordAccounts

    dispatch({ type: multiPaymentConstants.LOAD_MULTI_PAYMENT_INFO_LIST_START })

    let results = []
    for (let key in paymentAccountList) {
        let billAccount = paymentAccountList[key]
        results.push(dispatch(reloadMultiPaymentInfo(billAccount)))
    }

    for (let key in results) {
        let result = await results[key]

        if (result !== undefined && result !== null && result.error !== true) {
            multiPaymentInfoList.push(result.payload)
        }
    }

    return dispatch({
        type: multiPaymentConstants.LOAD_MULTI_PAYMENT_INFO_LIST_SUCCESS,
        multiPaymentInfoList,
        billAccountList,
        summaryBillAccounts,
        landlordAccounts
    })
}
