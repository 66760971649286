import utils from '../../../srp_modules/utils'
import {
    itemizedStatementConstants,
    creditHistoryConstants,
    usageHistoryConstants,
    energyScoreCardConstants,
    energyScoreCardFAQConstants,
    daytimeSaverPilotConstants,
    communitySolarConstants
} from '../../../constants/document-center-constants'
import { RSAA } from 'redux-api-middleware'


export const refreshItemizedStatementLink = () => ({
    type: itemizedStatementConstants.REFRESH_ITEMIZED_STATEMENT_LINK_REQUEST
})

export const getItemizedStatementLink = (billAccount) => dispatch => {
    return dispatch({
        [RSAA]: {
            endpoint: utils.createUrl('documents/itemizedstatementlink', { billAccount: billAccount }),
            method: 'GET',
            credentials: 'include',
            types: [
                itemizedStatementConstants.GET_ITEMIZED_STATEMENT_LINK_REQUEST,
                itemizedStatementConstants.GET_ITEMIZED_STATEMENT_LINK_SUCCESS,
                itemizedStatementConstants.GET_ITEMIZED_STATEMENT_LINK_FAILURE
            ]
        }
    })
}
export const requestItemizedHistory = (url) => dispatch => {
    return dispatch({
        [RSAA] :{
            endpoint: utils.createUrl(url),
            method: 'GET',
            credentials: 'include',
            types: [
                itemizedStatementConstants.REQUEST_ITEMIZED_STMT_REQUEST,
                itemizedStatementConstants.REQUEST_ITEMIZED_STMT_SUCCESS,
                itemizedStatementConstants.REQUEST_ITEMIZED_STMT_FAILURE
            ] 
        }
    })
}

export const refreshCreditHistoryLink = () => ({
    type: creditHistoryConstants.REFRESH_CREDIT_HISTORY_LINK_REQUEST
})

export const getCreditHistoryLink = (billAccount) => dispatch => {
    return dispatch({
        [RSAA]: {
            endpoint: utils.createUrl('documents/credithistorylink', { billAccount: billAccount }),
            method: 'GET',
            credentials: 'include',
            types: [
                creditHistoryConstants.GET_CREDIT_HISTORY_LINK_REQUEST,
                creditHistoryConstants.GET_CREDIT_HISTORY_LINK_SUCCESS,
                creditHistoryConstants.GET_CREDIT_HISTORY_LINK_FAILURE
            ]
        }
    })
}

export const refreshUsageHistoryLink = () => ({
    type: usageHistoryConstants.REFRESH_USAGE_HIST_LINK_REQUEST
})

export const getUsageHistoryLink = (billAccount, isMPower) => dispatch => {
    return dispatch({
        [RSAA]: {
            endpoint: utils.createUrl('documents/usagehistorylink', { billAccount: billAccount, isMPower: isMPower }),
            method: 'GET',
            credentials: 'include',
            types: [
                usageHistoryConstants.GET_USAGE_HIST_LINK_REQUEST,
                usageHistoryConstants.GET_USAGE_HIST_LINK_SUCCESS,
                usageHistoryConstants.GET_USAGE_HIST_LINK_FAILURE
            ]
        }
    })
}

export const getEnergyScoreCardLinks = (billAccount) => dispatch => {
    return dispatch({
        [RSAA]: {
            endpoint: utils.createUrl('documents/energyscorecardlinks', { billAccount: billAccount }),
            method: 'GET',
            credentials: 'include',
            types: [
                energyScoreCardConstants.GET_SCORECARD_LINKS_REQUEST,
                energyScoreCardConstants.GET_SCORECARD_LINKS_SUCCESS,
                energyScoreCardConstants.GET_SCORECARD_LINKS_FAILURE
            ]
        }
    })
}

export const getEnergyScoreCardFAQLink = () => dispatch => {
    return dispatch({
        [RSAA]: {
            endpoint: utils.createUrl('documents/energyscorecardfaqlink'),
            method: 'GET',
            credentials: 'include',
            types: [
                energyScoreCardFAQConstants.GET_SCORECARD_FAQ_LINK_REQUEST,
                energyScoreCardFAQConstants.GET_SCORECARD_FAQ_LINK_SUCCESS,
                energyScoreCardFAQConstants.GET_SCORECARD_FAQ_LINK_FAILURE
            ]
        }
    })
}

export const refreshDaytimeSaverPilotLinks = () => ({
    type: daytimeSaverPilotConstants.REFRESH_DAYTIME_SAVER_LINKS_REQUEST
})

export const getDaytimeSaverPilotLinks = (billAccount) => dispatch => {
    return dispatch({
        [RSAA]: {
            endpoint: utils.createUrl('documents/daytimesaverpilotpdflinks', { billAccount: billAccount }),
            method: 'GET',
            credentials: 'include',
            types: [
                daytimeSaverPilotConstants.GET_DAYTIME_SAVER_LINKS_REQUEST,
                daytimeSaverPilotConstants.GET_DAYTIME_SAVER_LINKS_SUCCESS,
                daytimeSaverPilotConstants.GET_DAYTIME_SAVER_LINKS_FAILURE
            ]
        }
    })
}

export const refreshCommunitySolarLink = () => ({
    type: communitySolarConstants.REFRESH_COMMUNITY_SOLAR_LINK_REQUEST
})

export const getCommunitySolarLink = (billAccount) => dispatch => {
    return dispatch({
        [RSAA]: {
            endpoint: utils.createUrl('documents/communitysolarlink', { billAccount: billAccount }),
            method: 'GET',
            credentials: 'include',
            types: [
                communitySolarConstants.GET_COMMUNITY_SOLAR_LINK_REQUEST,
                communitySolarConstants.GET_COMMUNITY_SOLAR_LINK_SUCCESS,
                communitySolarConstants.GET_COMMUNITY_SOLAR_LINK_FAILURE
            ]
        }
    })
}