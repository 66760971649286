import React from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import InputLabel from '@mui/material/InputLabel'

import AccountDetailsCommercial from './acct-details-commercial'
import AccountDetailsResidential from './acct-details-residential'

import ResidentialIconBlue from '../../images/forms/home-blue.svg'
import ResidentialIconGrey from '../../images/forms/home-grey.svg'
import CommercialIconBlue from '../../images/forms/business-blue.svg'
import CommercialIconGrey from '../../images/forms/business-grey.svg'

import { pricePlanConstants } from '../../constants/price-plan-constants'

const accountTypeBtn = {
    borderRadius: '10px',
    border: '2px solid #DADADB',
    backgroundColor: 'white',
    color: '#087ED8',
    fontWeight: '400',
    width: '100%'
}

const accountTypeBtnSelected = {
    borderRadius: '10px',
    border: '3px solid #087ED8',
    backgroundColor: 'white',
    color: '#087ED8',
    fontWeight: '700',
    width: '100%'
}

const iconStyle = {
    width: '40px',
    height: '100%'
}

const AccountDetailsContainer = ({ activeStep, setActiveStep, accountType, setAccountType, accountDetails, setAccountDetails,
    accountDetailsChanged, setAccountDetailsChanged, t }) => {

    return (
        <div className="mb-2 text-muted">
            <InputLabel>{t("What type of account is this?")}</InputLabel>
            <div className="row">
                <div className="col-6">
                    <button
                        className="button-focus pointer-btns"
                        type="button"
                        name="accountType"
                        onClick={() => setAccountType(pricePlanConstants.ACOUNT_TYPE.RESIDENTIAL)}
                        style={accountType === pricePlanConstants.ACOUNT_TYPE.RESIDENTIAL ? accountTypeBtnSelected : accountTypeBtn}>
                        <img
                            className="mt-2 mb-1 img-fluid"
                            src={accountType === pricePlanConstants.ACOUNT_TYPE.RESIDENTIAL ? ResidentialIconBlue : ResidentialIconGrey}
                            style={iconStyle} />
                        <div className="mb-2">{t("Residential")}</div>
                    </button>
                </div>
                <div className="col-6">
                    <button
                        className="button-focus pointer-btns"
                        type="button"
                        name="accountType"
                        onClick={() => setAccountType(pricePlanConstants.ACOUNT_TYPE.COMMERCIAL)}
                        style={accountType === pricePlanConstants.ACOUNT_TYPE.COMMERCIAL ? accountTypeBtnSelected : accountTypeBtn}>
                        <img
                            className="mt-2 mb-1 img-fluid"
                            src={accountType === pricePlanConstants.ACOUNT_TYPE.COMMERCIAL ? CommercialIconBlue : CommercialIconGrey}
                            style={iconStyle} />
                        <div className="mb-2">{t("Business")}</div>
                    </button>
                </div>
            </div>
            {accountType === pricePlanConstants.ACOUNT_TYPE.RESIDENTIAL
                ? <AccountDetailsResidential
                    activeStep={activeStep}
                    setActiveStep={setActiveStep}
                    accountDetails={accountDetails}
                    setAccountDetails={setAccountDetails}
                    accountDetailsChanged={accountDetailsChanged}
                    setAccountDetailsChanged={setAccountDetailsChanged} />
                : accountType === pricePlanConstants.ACOUNT_TYPE.COMMERCIAL
                    ? <AccountDetailsCommercial
                        activeStep={activeStep}
                        setActiveStep={setActiveStep}
                        accountDetails={accountDetails}
                        setAccountDetails={setAccountDetails}
                        accountDetailsChanged={accountDetailsChanged}
                        setAccountDetailsChanged={setAccountDetailsChanged} />
                    : null
            }
        </div>
    )
}

AccountDetailsContainer.propTypes = {
    activeStep: PropTypes.number,
    setActiveStep: PropTypes.func,

    accountType: PropTypes.number,
    setAccountType: PropTypes.func,
    accountDetails: PropTypes.object,
    setAccountDetails: PropTypes.func,

    accountDetailsChanged: PropTypes.bool,
    setAccountDetailsChanged: PropTypes.func,

    t: PropTypes.func
}

export default withTranslation('changePricePlan')(AccountDetailsContainer)