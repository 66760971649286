import React from "react"

let baseStyle = {
    borderStyle: 'solid',
    borderRadius: '10px',
    borderWidth: '1px',
    height: '285px',
    paddingTop: '10px',
    paddingBottom: '10px',
    textAlign: 'center',
    marginRight: '10px'
}

let regularBoxStyle={
    ...baseStyle, 
    borderColor: 'rgba(215,215,215,1)'
}

let centerStyle = {
    left: '0',
    right: '0',
    margin: 'auto'
}

let lightGrayStyle = {
    ...centerStyle,
    backgroundColor: "rgb(242,242,242)",
    borderRadius: "20px"
}

const MyPricingLoadingCard = () => {
    return (
        <div style={{position:"relative"}}>
            <div style={regularBoxStyle}>
                <div style={{marginTop:"8px"}}>
                    <div className="cmpp-shine" style={{...centerStyle, backgroundColor:"rgb(204,219,231)", borderRadius:"20px", width:"90%", height:"20px"}}/>
                </div>
                <div style={{marginRight:"5px", marginTop:"30px"}}>
                    <div className="cmpp-shine" style={{...lightGrayStyle, width:"60px", height:"36px"}}/>
                </div>
                <div style={{marginTop:"7px"}}>
                    <div className="cmpp-shine" style={{...lightGrayStyle, width:"80px", height:"14px"}}/>
                </div>
                <div style={{marginRight: "3px", marginTop: "12px"}}>
                    <div className="cmpp-shine" style={{...lightGrayStyle, width:"60px", height:"36px"}}/>
                </div>
                <div style={{marginTop:"5px"}}>
                    <div className="cmpp-shine" style={{...lightGrayStyle, width:"80px", height:"14px"}}/>
                </div>
                <div className="srp-blue-color" style={{marginTop: "17px"}}>
                    <div className="cmpp-shine" style={{...centerStyle, backgroundColor:"rgb(205,229,247)", borderRadius:"20px", width:"80px", height:"14px"}}/>
                </div>
                <div style={{marginTop: "10px"}}>
                    <div className="cmpp-shine" style={{...centerStyle, backgroundColor:"rgb(205,229,247)", borderRadius:"4px", width:"90%", height:"32px"}}/>
                </div>
            </div>
        </div>
    )
}

export default MyPricingLoadingCard