import React from 'react'
import { withTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import SrpModal from '../../common_modal/srp-modal'
import SafetyStatement from '../../dashboard_page/bill/credit_advance/safety-statement'

class MpowerPurchaseAcknowledgementPure extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            displaySafetyStatement: false
        }

        this.toggleSafetyStatement = this.toggleSafetyStatement.bind(this)
    }

    toggleSafetyStatement(display) {
        this.setState({
            displaySafetyStatement: display
        })
    }

    render() {
        return (
            <div className="px-3">
                <h6 className="font-14 text-muted">
                    {this.props.t("This_purchase_will_immediately_add_credit_submit")}
                    <button
                        className="displayAsLink font-weight-bold"
                        onClick={() => this.toggleSafetyStatement(true)}>
                        <span className="d-flex align-items-center">
                            {this.props.t("safety statement.")}
                        </span>
                    </button>
                </h6>
                <SrpModal
                    open={this.state.displaySafetyStatement}
                    title={this.props.t("Safety statement")}
                    content={<SafetyStatement t={this.props.t} />}
                    okClick={() => this.toggleSafetyStatement(false)}
                    closeClick={() => this.toggleSafetyStatement(false)}
                />
            </div>
        )
    }
}

MpowerPurchaseAcknowledgementPure.propTypes = {
    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired
}

const MpowerPurchaseAcknowledgement = withTranslation("singlePurchasePage")(MpowerPurchaseAcknowledgementPure)
export { MpowerPurchaseAcknowledgement as default, MpowerPurchaseAcknowledgementPure }