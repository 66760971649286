/*eslint no-unused-vars: "warn"*/
/* eslint-disable react/no-did-update-set-state */
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { withTranslation } from 'react-i18next'

import * as loadingStatus from '../../constants/loading-status-constants'
import { getEnrolledProgramsDetails, getPayment, getPaymentsByAcct, getCreditExtension, clearPaymentInfo } from '../../actions/auth/payment/payment-actions'
import { getNotifications } from '../../actions/auth/notification/notification-actions'
import { clearBankInfo, showScheduledAndRecentPayments, clearFormStartEventEmitted, setFormStartEventEmitted } from '../../actions/auth/payment/single-payment-actions'
import { getBankAccountList } from '../../actions/auth/payment/bank-account-actions'
import { getCustomerNames, getPhoneNumber } from '../../actions/auth/bill_account/bill-account-actions'
import { getContacts } from '../../actions/auth/contact/contact-actions'
import { withRouter } from '../../srp_modules/with-router'

import SinglePaymentForm from './single-payment-form'
import SinglePaymentLoadingProgress from './contents/single-payment-loading-progress'

import AccountInfoBarContainer from '../myaccount_header/info_bar/account-info-bar-container'
import Breadcrumb from '../common_payment/breadcrumb'
import CallAnytime from '../myaccount_header/call-anytime'
import CheckDiagramCard from './common/check-diagram-card'

import MakePaymentByCard from './common/make-payment-by-card'
import OtherPaymentOptions from '../common_payment/other-payment-options'
import PaymentHistoryContainerWithRouter from './contents/payment_history/payment-history-container'
import HelpFindPayLocationCard from '../cash_card_page/common_payment_page/help-find-pay-location-card'
import HowToCashPayCardContainer from '../cash_card_page/common_payment_page/how-to-cash-pay-card-container'
import CashOnlyPaymentContainer from './common/cash-only-payment-container'

class SinglePaymentPageContainer extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            bankFormOpen: this.shouldBankFormOpen()
        }

        this.shouldBankFormOpen = this.shouldBankFormOpen.bind(this)

        this.onAddBankAccountClick = this.onAddBankAccountClick.bind(this)
        this.onAddBankAccountCancelClick = this.onAddBankAccountCancelClick.bind(this)
    }

    componentDidMount() {
        if (this.isPaymentSubmitted()) {
            this.props.clearState()
        }

        if (this.props.paymentStatus === loadingStatus.LOADING_STATUS_SUCCESS && this.props.payment.supportsRemotePrepay === true)
            this.props.router.navigate('/myaccount/payment/single/mpower', { replace: true })

        this.updateDataIfNeeded(this.props)
    }

    componentDidUpdate(prevProps) {
        if (this.props.paymentStatus === loadingStatus.LOADING_STATUS_SUCCESS && prevProps.paymentStatus !== loadingStatus.LOADING_STATUS_SUCCESS) {
            if (this.props.payment.supportsRemotePrepay === true)
                this.props.router.navigate('/myaccount/payment/single/mpower', { replace: true })
        }

        if (this.props.bankAccountListStatus !== prevProps.bankAccountListStatus
            && (this.props.bankAccountListStatus === loadingStatus.LOADING_STATUS_SUCCESS
                || this.props.bankAccountListStatus === loadingStatus.LOADING_STATUS_FAILURE)) {
            this.setState({ bankFormOpen: this.shouldBankFormOpen() })
        }

        if (this.props.selectedBillAccount === 0)
            return

        const differentBillAccountSelected = (this.props.selectedBillAccount !== prevProps.selectedBillAccount)

        if (differentBillAccountSelected || this.props.creditExtensionStatus === loadingStatus.LOADING_STATUS_INIT)
            this.props.actions.getCreditExtension(this.props.selectedBillAccount)

        if (differentBillAccountSelected || this.props.customerNamesStatus === loadingStatus.LOADING_STATUS_INIT)
            this.props.actions.getCustomerNames(this.props.selectedBillAccount)

        if (differentBillAccountSelected || this.props.enrolledProgramsDetailsStatus === loadingStatus.LOADING_STATUS_INIT)
            this.props.actions.getEnrolledProgramsDetails(this.props.selectedBillAccount)

        if (differentBillAccountSelected || this.props.paymentStatus === loadingStatus.LOADING_STATUS_INIT)
            this.props.actions.getPayment(this.props.selectedBillAccount)

        if (differentBillAccountSelected || this.props.paymentsByAcctStatus === loadingStatus.LOADING_STATUS_INIT)
            this.props.actions.getPaymentsByAcct(this.props.selectedBillAccount)

        if (differentBillAccountSelected || this.props.phoneNumberStatus === loadingStatus.LOADING_STATUS_INIT)
            this.props.actions.getPhoneNumber(this.props.selectedBillAccount)

        if (differentBillAccountSelected || this.props.notificationsStatus === loadingStatus.LOADING_STATUS_INIT) {
            this.props.actions.getNotificationsInfo(this.props.selectedBillAccount)
        }

        if (differentBillAccountSelected)
            this.props.getContactsInfo(this.props.selectedBillAccount)
    }

    shouldBankFormOpen = () => (
        this.props.bankAccountListStatus === loadingStatus.LOADING_STATUS_SUCCESS
        && this.props.bankAccountList
        && this.props.bankAccountList.length === 0
    )

    isPaymentSubmitted() {
        return this.props.makePaymentResult.referenceNumbers !== undefined
            && this.props.makePaymentResult.referenceNumbers.length > 0
    }

    updateDataIfNeeded(props) {
        if (props.bankAccountListStatus === loadingStatus.LOADING_STATUS_INIT) {
            props.actions.getBankAccountList()
        }

        if (props.selectedBillAccount === 0) {
            return
        }

        if (props.creditExtensionStatus === loadingStatus.LOADING_STATUS_INIT) {
            props.actions.getCreditExtension(props.selectedBillAccount)
        }

        if (props.customerNamesStatus === loadingStatus.LOADING_STATUS_INIT) {
            props.actions.getCustomerNames(props.selectedBillAccount)
        }

        if (props.enrolledProgramsDetailsStatus === loadingStatus.LOADING_STATUS_INIT) {
            props.actions.getEnrolledProgramsDetails(props.selectedBillAccount)
        }

        if (props.paymentStatus === loadingStatus.LOADING_STATUS_INIT) {
            props.actions.getPayment(props.selectedBillAccount)
        }

        if (props.paymentsByAcctStatus === loadingStatus.LOADING_STATUS_INIT) {
            props.actions.getPaymentsByAcct(props.selectedBillAccount)
        }

        if (props.phoneNumberStatus === loadingStatus.LOADING_STATUS_INIT) {
            props.actions.getPhoneNumber(props.selectedBillAccount)
        }

        if (props.notificationsStatus === loadingStatus.LOADING_STATUS_INIT) {
            props.actions.getNotificationsInfo(props.selectedBillAccount)
        }
    }

    hasLoaded(props) {
        let statusList = [loadingStatus.LOADING_STATUS_INIT, loadingStatus.LOADING_STATUS_IN_PROGRESS]

        return (statusList.indexOf(props.bankAccountListStatus) === -1 &&
            statusList.indexOf(props.creditExtensionStatus) === -1 &&
            statusList.indexOf(props.customerNamesStatus) === -1 &&
            statusList.indexOf(props.enrolledProgramsDetailsStatus) === -1 &&
            statusList.indexOf(props.paymentsByAcctStatus) === -1 &&
            statusList.indexOf(props.paymentStatus) === -1 &&
            statusList.indexOf(props.phoneNumberStatus) === -1 &&
            statusList.indexOf(props.notificationsStatus) === -1)
    }

    hasPendingPayments() {
        return this.props.pendingPaymentsByAcct && this.props.pendingPaymentsByAcct.length > 0
    }

    hasPostedPayments() {
        return this.props.postedPaymentsByAcct && this.props.postedPaymentsByAcct.length > 0
    }

    hasScheduledPayment() {
        return this.props.scheduledPaymentsByAcct && this.props.scheduledPaymentsByAcct.length > 0
    }

    showScheduledAndRecentPayments() {
        return (this.hasScheduledPayment() || this.hasPostedPayments() || this.hasPendingPayments())
            && !this.props.ignoreScheduledAndRecentPayments
    }

    onAddBankAccountClick() {
        this.setState({ bankFormOpen: true })
    }

    onAddBankAccountCancelClick() {
        this.setState({ bankFormOpen: false })
    }

    render() {
        let pageComponent, includeMakePaymentByCard = false, includeCheckDiagram = false
        let hasLoaded = this.hasLoaded(this.props)
        if (!hasLoaded)
            pageComponent = <SinglePaymentLoadingProgress />
        else if (this.props.isCashOnly) {
            return (
                <div className="container py-3">
                    {/* CallAnytime not shown when showing CashOnlyPaymentCard */}
                    <CallAnytime />

                    <h3 className="mt-3">{this.props.t("Make a payment")}</h3>

                    <AccountInfoBarContainer />

                    {/* BreadCrumb not in SinglePaymentLoadingProgress */}
                    <Breadcrumb pageTitle={this.props.t("Make a payment")} />
                    <div className="pl-lg-2">
                        <CashOnlyPaymentContainer
                            isBarcodeEligible={this.props.isBarcodeEligible}
                        />
                    </div>

                </div>
            )
        }
        else if (this.showScheduledAndRecentPayments()) {
            pageComponent = (<PaymentHistoryContainerWithRouter
                scheduledPaymentsByAcct={this.props.scheduledPaymentsByAcct}
                pendingPaymentsByAcct={this.props.pendingPaymentsByAcct}
                postedPaymentsByAcct={this.props.postedPaymentsByAcct}
                bankAccountList={this.props.bankAccountList}
                isResidential={this.props.isResidential}
                t={this.props.t}
                i18n={this.props.i18n}
            />)
            includeMakePaymentByCard = true
        }
        else if (this.props.router.location.pathname === "/myaccount/payment/single/cash") {
            pageComponent = <HowToCashPayCardContainer isBarcodeEligible={this.props.isBarcodeEligible} isMpower={this.props.isMpower} />
            includeMakePaymentByCard = true
        }
        else {
            pageComponent = (<SinglePaymentForm
                bankAccountList={this.props.bankAccountList}
                billAccount={this.props.selectedBillAccount}
                hasScheduledPayment={this.hasScheduledPayment()}
                isPaymentInfoValid={this.props.isPaymentInfoValid}
                payment={this.props.payment}
                phoneNumber={this.props.phoneNumber}
                customerNames={this.props.customerNames}
                makePaymentInfo={this.props.makePaymentInfo}
                creditExtension={this.props.creditExtension}
                summaryBillingStatus={this.props.summaryBillingStatus}
                formStartEventEmitted={this.props.formStartEventEmitted}
                setFormStartEventEmitted={this.props.actions.setFormStartEventEmitted}
                bankFormOpen={this.state.bankFormOpen}
                onAddBankAccountClick={this.onAddBankAccountClick}
                onAddBankAccountCancelClick={this.onAddBankAccountCancelClick}
                t={this.props.t}
                i18n={this.props.i18n}
            />)
            includeMakePaymentByCard = true
            includeCheckDiagram = true
        }

        return (
            <div className="container py-3">
                {/* CallAnytime not shown when showing CashOnlyPaymentCard */}
                <CallAnytime />

                <h3 className="mt-3">{this.props.t("Make a payment")}</h3>

                <AccountInfoBarContainer />

                {/* BreadCrumb not in SinglePaymentLoadingProgress */}
                <Breadcrumb pageTitle={this.props.t("Make a payment")} />

                <div className="row">
                    <div className="col-lg-6">
                        {includeMakePaymentByCard &&
                            <MakePaymentByCard
                                isEnrolledInAutopay={this.props.enrolledProgramsDetails.autopay.isEnrolled}
                                t={this.props.t}
                                i18n={this.props.i18n}
                            />
                        }
                    </div>
                    <div className="col-lg-6 mt-3 mt-lg-0 pl-lg-3 d-none d-lg-block">
                        {(this.props.enrolledProgramsDetailsStatus === loadingStatus.LOADING_STATUS_SUCCESS || this.props.enrolledProgramsDetailsStatus === loadingStatus.LOADING_STATUS_FAILURE) && !this.props.isCashOnly &&
                            <OtherPaymentOptions
                                showAutoPay={false}
                                showCreditDebit={false}
                                showGiftPayment={this.props.isCashOnly ? false : true}
                                showPayCenterLink={true}
                            />}
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-lg-6">
                        {!this.props.isCashOnly
                            ? pageComponent
                            : null
                        }
                    </div>
                    <div className="col-lg-6 mt-lg-0">
                        {includeCheckDiagram && this.state.bankFormOpen &&
                            <div className="mt-3 d-none d-lg-block">
                                <CheckDiagramCard />
                            </div>
                        }

                        {(this.props.router.location.pathname === "/myaccount/payment/single/cash" || this.props.isCashOnly) && hasLoaded &&
                            <div><HelpFindPayLocationCard /></div>
                        }
                    </div>
                    <div className="col-lg-6 mt-3 d-block d-lg-none">
                        {(this.props.enrolledProgramsDetailsStatus === loadingStatus.LOADING_STATUS_SUCCESS || this.props.enrolledProgramsDetailsStatus === loadingStatus.LOADING_STATUS_FAILURE) && !this.props.isCashOnly &&
                            <OtherPaymentOptions
                                showAutoPay={false}
                                showCreditDebit={false}
                                showGiftPayment={this.props.isCashOnly ? false : true}
                                showPayCenterLink={true}
                            />}
                    </div>
                </div>
            </div>
        )
    }
}

SinglePaymentPageContainer.propTypes = {
    actions: PropTypes.object.isRequired,
    bankAccountList: PropTypes.arrayOf(PropTypes.object).isRequired,
    bankAccountListStatus: PropTypes.string.isRequired,
    enrolledProgramsDetails: PropTypes.object.isRequired,
    enrolledProgramsDetailsStatus: PropTypes.string.isRequired,
    isPaymentInfoValid: PropTypes.bool.isRequired,
    payment: PropTypes.object.isRequired,
    paymentsByAcctStatus: PropTypes.string,
    paymentStatus: PropTypes.string.isRequired,
    pendingPaymentsByAcct: PropTypes.arrayOf(PropTypes.object).isRequired,
    postedPaymentsByAcct: PropTypes.arrayOf(PropTypes.object).isRequired,
    scheduledPaymentsByAcct: PropTypes.arrayOf(PropTypes.object).isRequired,
    selectedBillAccount: PropTypes.number.isRequired,
    isCashOnly: PropTypes.bool.isRequired,
    customerNames: PropTypes.object,
    customerNamesStatus: PropTypes.string.isRequired,
    phoneNumber: PropTypes.object,
    phoneNumberStatus: PropTypes.string.isRequired,
    makePaymentInfo: PropTypes.object.isRequired,
    ignoreScheduledAndRecentPayments: PropTypes.bool.isRequired,
    formStartEventEmitted: PropTypes.bool.isRequired,
    creditExtension: PropTypes.object,
    creditExtensionStatus: PropTypes.string.isRequired,
    clearState: PropTypes.func.isRequired,
    makePaymentResult: PropTypes.object.isRequired,
    primaryEmailStatus: PropTypes.string.isRequired,
    getContactsInfo: PropTypes.func.isRequired,
    isResidential: PropTypes.bool.isRequired,
    router: PropTypes.shape({
        location: PropTypes.object,
        navigate: PropTypes.func
    }),
    notificationsStatus: PropTypes.string.isRequired,
    summaryBillingStatus: PropTypes.number.isRequired,
    isBarcodeEligible: PropTypes.bool.isRequired,
    isMpower: PropTypes.bool.isRequired,
    t: PropTypes.func.isRequired,
    i18n: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
    bankAccountList: state.bankAccounts.bankAccountList,
    bankAccountListStatus: state.bankAccounts.bankAccountListStatus,
    enrolledProgramsDetails: state.accountInfo.payment.enrolledProgramsDetails,
    enrolledProgramsDetailsStatus: state.accountInfo.payment.enrolledProgramsDetailsStatus,
    isPaymentInfoValid: state.accountInfo.payment.isPaymentInfoValid,
    payment: state.accountInfo.payment.payment,
    paymentsByAcctStatus: state.accountInfo.payment.paymentsByAcctStatus,
    paymentStatus: state.accountInfo.payment.paymentStatus,
    pendingPaymentsByAcct: state.accountInfo.payment.pendingPaymentsByAcct,
    postedPaymentsByAcct: state.accountInfo.payment.postedPaymentsByAcct,
    scheduledPaymentsByAcct: state.accountInfo.payment.scheduledPaymentsByAcct,
    selectedBillAccount: state.accountInfo.billAccount.selectedBillAccount,
    isCashOnly: state.accountInfo.payment.isCashOnly,
    customerNames: state.accountInfo.billAccount.customerNames,
    customerNamesStatus: state.accountInfo.billAccount.customerNamesStatus,
    phoneNumber: state.accountInfo.billAccount.phoneNumber,
    phoneNumberStatus: state.accountInfo.billAccount.phoneNumberStatus,
    makePaymentInfo: state.accountInfo.payment.makePaymentInfo,
    ignoreScheduledAndRecentPayments: state.singlePaymentInfo.ignoreScheduledAndRecentPayments,
    formStartEventEmitted: state.singlePaymentInfo.formStartEventEmitted,
    creditExtension: state.accountInfo.payment.creditExtension,
    creditExtensionStatus: state.accountInfo.payment.creditExtensionStatus,
    makePaymentResult: state.accountInfo.payment.makePaymentResult,
    primaryEmailStatus: state.accountInfo.contact.primaryEmailStatus,
    isResidential: !state.accountInfo.billAccount.selectedBillAccountDetails.isCommercial,
    notificationsStatus: state.accountInfo.notification.notificationsStatus,
    summaryBillingStatus: state.accountInfo.billAccount.selectedBillAccountDetails.summaryBillingStatus,
    isBarcodeEligible: state.accountInfo.billAccount.selectedBillAccountDetails.isBarcodeEligible,
    isMpower: state.accountInfo.billAccount.selectedBillAccountDetails.isPrePay
})

const mapDispatchToProps = (dispatch) => ({
    actions: {
        getBankAccountList: async () => {
            await dispatch(getBankAccountList())
        },
        getCustomerNames: async (billAccount) => {
            await dispatch(getCustomerNames(billAccount))
        },
        getEnrolledProgramsDetails: async (billAccount) => {
            await dispatch(getEnrolledProgramsDetails(billAccount))
        },
        getPayment: async (billAccount) => {
            await dispatch(getPayment(billAccount))
        },
        getPaymentsByAcct: async (billAccount) => {
            await dispatch(getPaymentsByAcct(billAccount))
        },
        getPhoneNumber: async (billAccount) => {
            await dispatch(getPhoneNumber(billAccount))
        },
        getCreditExtension: async (billAccount) => {
            await dispatch(getCreditExtension(billAccount))
        },
        getNotificationsInfo: (billAccount) => {
            dispatch(getNotifications(billAccount))
        },
        setFormStartEventEmitted: () => {
            dispatch(setFormStartEventEmitted())
        },
    },
    clearState: () => {
        dispatch(clearPaymentInfo())
        dispatch(clearBankInfo())
        dispatch(showScheduledAndRecentPayments())
        dispatch(clearFormStartEventEmitted())
    },
    getContactsInfo: (billAccount) => {
        dispatch(getContacts(billAccount))
    }
})

export default withTranslation("singlePayment")(withRouter(connect(mapStateToProps, mapDispatchToProps)(SinglePaymentPageContainer)))