import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import queryString from 'query-string'
import AccountSetupPage from './account-setup-page'
import { registerNewLogin } from '../../../actions/auth/login/login-actions'
import { withRouter } from '../../../srp_modules/with-router'

function deriveAccountInfoFromQueryString() {
    let initialAccountInfoState = {
        accountNumber: '',
        email: ''
    }

    let params = queryString.parse(location.search)

    if (params === undefined)
        return initialAccountInfoState

    if (params.srpaccountnumber !== undefined && params.email !== undefined) {
        return {
            ...initialAccountInfoState,
            accountNumber: params.srpaccountnumber,
            email: params.email
        }
    }

    return initialAccountInfoState
}

class AccountSetupPageContainer extends React.Component {
    constructor(props) {
        super(props);

        let newAccountDetails = deriveAccountInfoFromQueryString()

        this.state = {
            newAccountDetails
        }
    }

    render() {
        return (
            <AccountSetupPage
                registerNewLoginOnClick={this.props.registerNewLoginOnClick}
                newAccountDetails={this.state.newAccountDetails} />
        )
    }
}

const mapStateToProps = state => {
    return { ...state.accountSetup }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        registerNewLoginOnClick: async (values, goodies) => {
            let { username, password, accountNumber, phoneNumber } = values
            let response = await dispatch(registerNewLogin(username, password, accountNumber, phoneNumber))
            goodies.setSubmitting(false)

            if (response.error === true || response.payload.success === false) {
                switch (response.payload.validationErrorDesc) {
                    case 'AccountNotLocated': {
                        goodies.setStatus({ invalidAccount: true })
                        return
                    }
                    case 'EmailExists': {
                        goodies.setStatus({ emailExists: true })
                        return
                    }
                    default: {
                        goodies.setStatus({ generalError: true, errorMsg: response.payload.error })
                        return
                    }
                }
            }

            if (response.payload.success === true) {
                ownProps.router.navigate('/confirmSetup')
            }
        }
    }
}

AccountSetupPageContainer.propTypes = {
    generalError: PropTypes.bool,
    router: PropTypes.shape({
        navigate: PropTypes.func
    }),

    registerNewLoginOnClick: PropTypes.func.isRequired,
    newAccountDetails: PropTypes.object
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AccountSetupPageContainer))