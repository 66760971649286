import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import Button from '@mui/material/Button'
import Icon from '@mui/material/Icon'

import { withTranslation } from 'react-i18next'

import CallAnytime from '../../myaccount_header/call-anytime'
import { myAccountConstants } from '../../../constants/myaccount-constants'

const BankLockedErrorCard = ({t, i18n}) => {
    const isSpanish = i18n.language === "es"
    const residentialSupportNumber = isSpanish ? myAccountConstants.SPANISH_CUSTOMER_SERVICE_PHONE_NUMBER : myAccountConstants.RESIDENTIAL_CUSTOMER_SERVICE_PHONE_NUMBER
    const residentialSupportNumberFmt = isSpanish ? myAccountConstants.SPANISH_CUSTOMER_SERVICE_PHONE_NUMBER_FMTTD : myAccountConstants.RESIDENTIAL_CUSTOMER_SERVICE_PHONE_NUMBER_FMTTD

    return (
        <div>
            <div className="d-block d-lg-none">
                <Button
                    variant="contained"
                    fullWidth
                    className="justify-content-start nav-button-responsive"
                    component={Link} to="/myaccount/dashboard"
                    disableRipple>
                    <Icon className="material-icons mr-2" style={{ color: 'rgb(33,108,224' }}>keyboard_backspace</Icon>
                    {t("Back to dashboard")}
                </Button>
            </div>
            <div className="container py-3">
                <CallAnytime />
                <h3 className="mt-3">{t("Banking features locked")}</h3>

                <div className="row">
                    <div className="col-lg-7 col-12 mt-4">
                        <div className="srp-card-header">{t("Some features have been disabled")}</div>
                        <div className="srp-card-body">
                            <div className="srp-card-details">
                                <div className="mb-3">{t("disabled_features")}</div>
                                <li>{t("Adding bank accounts")}</li>
                                <li className="mb-3">{t("Editing bank accounts")}</li>
                                <div className="mb-3">
                                    {t("questions_call_srp")}
                                </div>
                                <div className="d-lg-block d-none mb-3">
                                    <li>{t("Residential:")} {residentialSupportNumberFmt}</li>
                                    <li>{t("Commercial:")} {myAccountConstants.COMMERCIAL_CUSTOMER_SERVICE_PHONE_NUMBER_FMTTD}</li>
                                </div>
                                <div className="d-lg-none d-block mb-3">
                                    <li>
                                        {t("Residential:")} <a href={"tel:" + residentialSupportNumber}>
                                            {residentialSupportNumberFmt}
                                        </a>
                                    </li>
                                    <li>
                                        {t("Commercial:")} <a href={"tel:" + myAccountConstants.COMMERCIAL_CUSTOMER_SERVICE_PHONE_NUMBER}>
                                            {myAccountConstants.COMMERCIAL_CUSTOMER_SERVICE_PHONE_NUMBER_FMTTD}
                                        </a>
                                    </li>
                                </div>
                                <div className="d-flex justify-content-end">
                                    <Link to="/myaccount/dashboard" className="btn srp-btn btn-lightblue mr-2">{t("Back to dashboard")}</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

BankLockedErrorCard.propTypes = {
    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired,
}

export default withTranslation("bankLocked")(BankLockedErrorCard)