export const billPageConstants = {
    GET_NUM_BILLS_TO_DISPLAY: 'GET_NUM_BILLS_TO_DISPLAY',
    GET_ACCOUNT_HISTORY_TYPE: 'GET_ACCOUNT_HISTORY_TYPE',
    TOGGLE_DETAILED_TRANSACTION_LIST: 'TOGGLE_DETAILED_TRANSACTION_LIST',

    HISTORY_TYPE_BILLING: 'bill',
    HISTORY_TYPE_USAGE: 'usage',

    GET_PREV_ACCT_HISTORY: 'GET_PREV_ACCT_HISTORY',
    GET_NEXT_ACCT_HISTORY: 'GET_NEXT_ACCT_HISTORY',

    ADD_BILL_TO_COMPARE: 'ADD_BILL_TO_COMPARE',
    REMOVE_BILL_FROM_COMPARE: 'REMOVE_BILL_FROM_COMPARE',

    GET_SELECTED_BILL_REQUEST: 'GET_SELECTED_BILL_REQUEST',
    GET_SELECTED_BILL_SUCCESS: 'GET_SELECTED_BILL_SUCCESS',
    GET_SELECTED_BILL_FAILURE: 'GET_SELECTED_BILL_FAILURE',

    GET_VIEW_BILL_TAB: 'GET_VIEW_BILL_TAB'
}