import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { DocumentTitle } from '../../../srp_modules/document-title'
import { withTranslation } from 'react-i18next'

import CircularProgress from '@mui/material/CircularProgress'
import Button from '@mui/material/Button'
import Icon from '@mui/material/Icon'

import SafetyNetSignupFormik from './safety-net-signup-formik'
import SafetyNetNotEligibleCard from './safety-net-not-eligible-card'

import AccountInfoBarContainer from '../../myaccount_header/info_bar/account-info-bar-container'
import CallAnytime from '../../myaccount_header/call-anytime'

import { getContacts } from '../../../actions/auth/contact/contact-actions'
import { getCustomerNames, getServiceAddress, getPhoneNumber } from '../../../actions/auth/bill_account/bill-account-actions'
import { submitSafetyNetForm, updateSafetyNet, resetSafetyNetForm } from '../../../actions/auth/forms/safety-net-actions'
import { getEnrolledProgramsDetails } from '../../../actions/auth/payment/payment-actions'

import { myAccountConstants } from '../../../constants/myaccount-constants'
import { safetyNetConstants } from '../../../constants/safety-net-constants'
import * as loadingStatus from '../../../constants/loading-status-constants'

import format from '../../../srp_modules/format'
import { withRouter } from '../../../srp_modules/with-router'

let progressCirclestyle = {
    marginTop: "10%",
    marginBottom: "20%"
}

class SafetyNetSigupContainer extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            initialChangedState: false,
            formType: this.props.router.location.pathname === "/forms/safetynet/change" ? safetyNetConstants.SAFETYNET_ACTION.CHANGE : safetyNetConstants.SAFETYNET_ACTION.SIGNUP
        }

        this.getSafetyNetDetails = this.getSafetyNetDetails.bind(this)
        this.onStateChanged = this.onStateChanged.bind(this)
        this.setFormLanguage = this.setFormLanguage.bind(this)
    }

    componentDidMount() {
        if (this.props.selectedBillAccount === 0) {
            return
        }

        if (this.props.customerNamesStatus === loadingStatus.LOADING_STATUS_INIT
            || this.props.customerNamesStatus === loadingStatus.LOADING_STATUS_FAILURE) {
            this.props.getCustomerNamesInfo(this.props.selectedBillAccount)
        }
        if (this.props.contactsStatus === loadingStatus.LOADING_STATUS_INIT
            || this.props.contactsStatus === loadingStatus.LOADING_STATUS_FAILURE) {
            this.props.getContactInfo(this.props.selectedBillAccount)
        }
        if (this.props.serviceAddressStatus === loadingStatus.LOADING_STATUS_INIT
            || this.props.serviceAddressStatus === loadingStatus.LOADING_STATUS_FAILURE) {
            this.props.getServiceAddressInfo(this.props.selectedBillAccount)
        }

        if (this.props.phoneNumberStatus === loadingStatus.LOADING_STATUS_INIT
            || this.props.phoneNumberStatus === loadingStatus.LOADING_STATUS_FAILURE) {
            this.props.getBestPhone(this.props.selectedBillAccount)
        }

        if (this.props.enrolledProgramsDetailsStatus === loadingStatus.LOADING_STATUS_INIT
            || this.props.enrolledProgramsDetailsStatus === loadingStatus.LOADING_STATUS_FAILURE) {
            this.props.getEnrolledProgramsDetails(this.props.selectedBillAccount)
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.selectedBillAccount === prevProps.selectedBillAccount || this.props.selectedBillAccount === 0)
            return

        if (this.props.customerNamesStatus !== loadingStatus.LOADING_STATUS_IN_PROGRESS) {
            this.props.getCustomerNamesInfo(this.props.selectedBillAccount)
        }

        if (this.props.contactsStatus !== loadingStatus.LOADING_STATUS_IN_PROGRESS) {
            this.props.getContactInfo(this.props.selectedBillAccount)
        }

        if (this.props.serviceAddressStatus !== loadingStatus.LOADING_STATUS_IN_PROGRESS) {
            this.props.getServiceAddressInfo(this.props.selectedBillAccount)
        }

        if (this.props.phoneNumberStatus !== loadingStatus.LOADING_STATUS_IN_PROGRESS) {
            this.props.getBestPhone(this.props.selectedBillAccount)
        }

        if (this.props.enrolledProgramsDetailsStatus !== loadingStatus.LOADING_STATUS_IN_PROGRESS) {
            this.props.getEnrolledProgramsDetails(this.props.selectedBillAccount)
        }
    }

    componentWillUnmount() {
        this.props.resetSafetyNetForm()
    }

    getSafetyNetDetails(formValues, formActions) {
        let safetyNetDetails = {
            billAccount: formValues.billAccount,
            emailAddress: formValues.emailAddress,
            firstName: formValues.firstName,
            safetyNetAddress: {
                streetAddress: formValues.streetAddress,
                state: formValues.state,
                city: formValues.city,
                zipCode: formValues.zipCode,
                mailNameLineOne: `${formValues.firstName} ${formValues.lastName}`,
                mailNameLineTwo: formValues.partnerName
            },
            preferredLanguage: this.props.i18n.language
        }

        this.props.submitSafetyNetForm(safetyNetDetails, this.state.formType, formActions)
    }

    onStateChanged() {
        this.setState({
            initialChangedState: true
        })
    }

    setFormLanguage() {
        let newLang = this.props.i18n.language == "es" ? "en" : "es"
        this.props.i18n.changeLanguage(newLang)
    }

    getDetails() {
        let customerServicePhone = this.props.i18n.language === "es"
            ? myAccountConstants.SPANISH_CUSTOMER_SERVICE_PHONE_NUMBER
            : myAccountConstants.RESIDENTIAL_CUSTOMER_SERVICE_PHONE_NUMBER
        let fmttdcustomerServicePhone = this.props.i18n.language === "es"
            ? myAccountConstants.SPANISH_CUSTOMER_SERVICE_PHONE_NUMBER_FMTTD
            : myAccountConstants.RESIDENTIAL_CUSTOMER_SERVICE_PHONE_NUMBER_FMTTD

        if (this.props.customerNamesStatus === loadingStatus.LOADING_STATUS_INIT || this.props.customerNamesStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS
            || this.props.contactsStatus === loadingStatus.LOADING_STATUS_INIT || this.props.contactsStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS
            || this.props.serviceAddressStatus === loadingStatus.LOADING_STATUS_INIT || this.props.serviceAddressStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS
            || this.props.phoneNumberStatus === loadingStatus.LOADING_STATUS_INIT || this.props.phoneNumberStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS
            || this.props.enrolledProgramsDetailsStatus === loadingStatus.LOADING_STATUS_INIT || this.props.enrolledProgramsDetailsStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS
            && this.props.submitSafetyNetFormStatus === loadingStatus.LOADING_STATUS_INIT) {
            return <div className="d-flex justify-content-center"><CircularProgress size={80} thickness={5} style={progressCirclestyle} /></div>
        }

        return (<div>
            {!this.props.isSafetyNetEligible && !this.props.isSafetyNetEnrolled
            || this.props.customerNames.firstName.length === 0
                ? <div className="row">
                    <div className="col-lg-6 col-12">
                        <div className="srp-card-header" id="topOfSafetyNetForm">
                            {this.props.t("Not Eligible")}
                        </div>
                        <SafetyNetNotEligibleCard
                            phone={customerServicePhone}
                            fmttdPhone={fmttdcustomerServicePhone} />
                    </div>
                </div>
                : <div className="row">
                    <div className="col-lg-6 col-12">
                        <div className="srp-card-header" id="topOfSafetyNetForm">
                            {this.props.submitSafetyNetFormStatus !== loadingStatus.LOADING_STATUS_SUCCESS
                                ? this.props.t("If a bill becomes past due, we'll let someone know")
                                : this.props.t("SRP Safety Net Sign-Up request submitted")
                            }
                        </div>
                        <SafetyNetSignupFormik
                            customerName={this.props.customerNames}
                            billAccount={this.props.selectedBillAccount}
                            serviceAddress={this.props.serviceAddress}
                            formattedPhoneNumber={format.formatPhoneNumber(this.props.phoneNumber)}
                            emailAddress={this.props.customerEmailAddress}
                            safetyNetProgramInfo={this.props.safetyNetProgramInfo}
                            getSafetyNetDetails={this.getSafetyNetDetails}
                            submitSafetyNetFormStatus={this.props.submitSafetyNetFormStatus}
                            initialChangedState={this.state.initialChangedState}
                            onStateChanged={this.onStateChanged}
                            formLanguage={this.props.i18n.language}
                        />
                    </div>
                    <div className="col-lg-6 col-12 mt-lg-0 mt-4">
                        <div className="srp-card-header">{this.props.t("Need help?")}</div>
                        <div className="srp-card-body srp-orange-background">
                            <div className="srp-card-details">
                                <p className="d-lg-block d-none mb-4">
                                    {`${this.props.t("For questions please contact SRP Residential Customer Services at")}
                                    ${fmttdcustomerServicePhone},
                                    ${this.props.t("available to serve you 24 hours a day, seven days a week")}.`}
                                </p>
                                <p className="d-lg-none d-block mb-4">
                                    {`${this.props.t("For questions please contact SRP Residential Customer Services at")} `}
                                    <a href={"tel:" + customerServicePhone}>
                                        {fmttdcustomerServicePhone}
                                    </a>
                                    {`, ${this.props.t("available to serve you 24 hours a day, seven days a week")}.`}
                                </p>
                                <div>
                                    {`${this.props.t("This form is also available in Spanish")} `}
                                    <button
                                        className="displayAsLink"
                                        onClick={() => this.setFormLanguage()}>{this.props.t("Availbe in different langauge")}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>)
    }

    render() {
        return (
            <DocumentTitle title={`${this.props.t("SRP Safety Net Sign-Up")} | SRP Power}`}>
                <div>
                    <div className="d-block d-md-none">
                        <Button
                            variant="contained"
                            fullWidth
                            className="justify-content-start nav-button-responsive"
                            onClick={() => this.props.router.navigate(-1)}
                            disableRipple>
                            <Icon className="material-icons mr-2" style={{ color: 'rgb(33,108,224' }}>keyboard_backspace</Icon>
                            {this.props.t("Previous page")}
                        </Button>
                    </div>
                    <div className="container py-3">
                        <CallAnytime />
                        <h3 className="d-lg-block d-none mt-3 mb-4 pb-4">{this.props.t("SRP Safety Net Sign-Up")}</h3>
                        <h4 className="d-lg-none d-block mt-3 mb-4">{this.props.t("SRP Safety Net Sign-Up")}</h4>

                        <AccountInfoBarContainer disableSelector={true} />

                        <div className="d-none d-md-block mb-4">
                            <h5 className="d-flex justify-content-start align-items-center">
                                <button className="displayAsLink" onClick={() => this.props.router.navigate(-1)}>{this.props.t("Previous page")} </button>
                                <i className="material-icons" style={{ color: 'rgba(72, 72, 74, 0.376)' }} >keyboard_arrow_right</i>
                                <span style={{ color: '#707070' }}>{this.props.t("SRP Safety Net Sign-Up")}</span>
                            </h5>
                        </div>

                        {this.getDetails()}

                    </div>
                </div>
            </DocumentTitle>
        )
    }
}

const mapStateToProps = state => {
    return {
        selectedBillAccount: state.accountInfo.billAccount.selectedBillAccount,
        isPrePay: state.accountInfo.billAccount.selectedBillAccountDetails.isPrePay,
        customerNames: state.accountInfo.billAccount.customerNames,
        customerNamesStatus: state.accountInfo.billAccount.customerNamesStatus,
        serviceAddress: state.accountInfo.billAccount.serviceAddress,
        serviceAddressStatus: state.accountInfo.billAccount.serviceAddressStatus,
        contactsStatus: state.accountInfo.contact.contactsStatus,
        customerEmailAddress: state.accountInfo.contact.primaryEmail,
        phoneNumber: state.accountInfo.billAccount.phoneNumber.bestPhone,
        phoneNumberStatus: state.accountInfo.billAccount.phoneNumberStatus,
        enrolledProgramsDetailsStatus: state.accountInfo.payment.enrolledProgramsDetailsStatus,
        isSafetyNetEligible: state.accountInfo.payment.enrolledProgramsDetails.safetyNet.isAvailable,
        isSafetyNetEnrolled: state.accountInfo.payment.enrolledProgramsDetails.safetyNet.isEnrolled,
        safetyNetProgramInfo: state.accountInfo.payment.enrolledProgramsDetails.safetyNet,
        ...state.safetyNet
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getContactInfo: (billAccount) => {
            dispatch(getContacts(billAccount))
        },
        getCustomerNamesInfo: (billAccount) => {
            dispatch(getCustomerNames(billAccount))
        },
        getServiceAddressInfo: (billAccount) => {
            dispatch(getServiceAddress(billAccount))
        },
        getBestPhone: (billAccount) => {
            dispatch(getPhoneNumber(billAccount))
        },
        getEnrolledProgramsDetails: (billAccount) => {
            dispatch(getEnrolledProgramsDetails(billAccount))
        },
        submitSafetyNetForm: async (safetyNetDetails, formType, formActions) => {
            let res = {}
            if(formType === safetyNetConstants.SAFETYNET_ACTION.CHANGE) {
                res = await dispatch(updateSafetyNet(safetyNetDetails))
            }
            else {
                res = await dispatch(submitSafetyNetForm(safetyNetDetails))
            }

            const submitSuccessfully = (res.payload?.success === true)

            formActions.setStatus({ submitError: !submitSuccessfully })
            formActions.setSubmitting(false)

            if (!submitSuccessfully && document.getElementById("topOfSafetyNetForm")) {
                document.getElementById("topOfSafetyNetForm").scrollIntoView()
                return
            }

            dispatch(getEnrolledProgramsDetails(safetyNetDetails.billAccount))
        },
        resetSafetyNetForm: () => {
            dispatch(resetSafetyNetForm())
        }
    }
}

SafetyNetSigupContainer.propTypes = {
    router: PropTypes.shape({
        location: PropTypes.object,
        navigate: PropTypes.func
    }),
    selectedBillAccount: PropTypes.number,
    isPrePay: PropTypes.bool,
    myAccountPreferredLanguage: PropTypes.string,

    customerNames: PropTypes.object,
    customerNamesStatus: PropTypes.string,
    getCustomerNamesInfo: PropTypes.func,

    contactsStatus: PropTypes.string,
    getContactInfo: PropTypes.func,
    customerEmailAddress: PropTypes.string,

    serviceAddressStatus: PropTypes.string,
    serviceAddress: PropTypes.object,
    getServiceAddressInfo: PropTypes.func,

    phoneNumberStatus: PropTypes.string,
    phoneNumber: PropTypes.string,
    getBestPhone: PropTypes.func,

    t: PropTypes.func,
    i18n: PropTypes.object,

    enrolledProgramsDetailsStatus: PropTypes.string,
    getEnrolledProgramsDetails: PropTypes.func,
    isSafetyNetEligible: PropTypes.bool,
    isSafetyNetEnrolled: PropTypes.bool,
    safetyNetProgramInfo: PropTypes.object,

    submitSafetyNetFormStatus: PropTypes.string,
    submitSafetyNetForm: PropTypes.func,
    resetSafetyNetForm: PropTypes.func
}

export default withTranslation('safetyNetSignupForm')(withRouter(connect(mapStateToProps, mapDispatchToProps)(SafetyNetSigupContainer)))