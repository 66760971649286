import React from 'react'
import PropTypes from 'prop-types'
import { useLocation, useNavigate } from 'react-router-dom'

import { withTranslation, Trans } from 'react-i18next'

const LoginPrefillFormBanner = ({t}) => {
    let location = useLocation()
    let navigate = useNavigate()

    let redirectToLogin = () => {
        navigate('/login', { replace: true, state: { from: location } })
    }

    return (
        <div className="srp-alert-notice pt-1 pb-1 mb-4">
            <Trans i18nKey="login_to_prefill_form" t={t}>
                <button className="displayAsLink" onClick={redirectToLogin}>Log into My Account</button> to prefill this form.
            </Trans>
        </div>
    )
}

LoginPrefillFormBanner.propTypes = {
    t: PropTypes.func.isRequired,
}

export default withTranslation("common")(LoginPrefillFormBanner)