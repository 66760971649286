// eslint is detecting the extra functions as multiple React components
/* eslint react/no-multi-comp: "off" */

import React from 'react'
import PropTypes from 'prop-types'
import FormControlLabel from '@mui/material/FormControlLabel'

const toggleMarginStyle = {
    marginTop: "0",
    marginBottom: "0",
    marginRight: "12px"
}

const YesNoToggle = ({id, isChecked, isSpanish, onChange}) => {
    return (
        <label className="switch" style={toggleMarginStyle}>
            <input 
                id={id}
                name={id}
                type="checkbox"
                checked={isChecked}
                onChange={onChange}
            />
            <span className={"yesNoToggle" + (isSpanish ? " es" : "")}/>
        </label>
    )
}

YesNoToggle.propTypes = {
    id: PropTypes.string.isRequired,
    isChecked: PropTypes.bool.isRequired,
    isSpanish: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired
}

const YesNoToggleInput = props => {
    const label = typeof props.label==="string" ? props.t(props.label) : props.label

    return (
        <div>
            <FormControlLabel
                control={
                    <YesNoToggle
                        id={props.id}
                        isChecked={props.value}
                        onChange={props.onChange}
                        isSpanish={props.i18n.language==="es"}
                    />
                }
                label={label}
            />
        </div>
    )
}

YesNoToggleInput.propTypes = {
    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired,
    id: PropTypes.string.isRequired,
    label: PropTypes.oneOfType([
        PropTypes.string.isRequired,
        PropTypes.object.isRequired
    ]),
    value: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired
}

export default YesNoToggleInput
