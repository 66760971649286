import * as React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import * as loadingStatus from '../../constants/loading-status-constants'
import drawHorizontalChart from  '../../srp_modules/charting/monthly-usage-horizontal'
import drawVerticalChart from  '../../srp_modules/charting/monthly-usage-vertical'
import { convertMonthlyDataForCharting } from '../../srp_modules/charting/monthly-data-dto'
import { DateTime } from 'luxon'
import { initDailyUsagePage } from '../../actions/auth/usage/usage-actions'
import { dailyUsageChartTypeConstants } from '../../constants/daily-usage-chart-type-constants'

let initializeChart = (usageList,
    highDemandInfoList,
    currentChart,
    startDate,
    endDate,
    hasOnOffShoulder,
    barClickCallback,
    hasCost,
    isSolar,
    hasMonthlyDemandOnly,
    isIDRRate,
    t
    ) => {

    if(usageList.length > 0) {
        let usage =  sliceUsageData(usageList,startDate,endDate).reverse()
        let monthlyClickCallback = (startDate, endDate, chartType, hasCost, isSolar) =>{
            let clickChartType = chartType === dailyUsageChartTypeConstants.COST
                && hasCost === false
                    ? isSolar && !isIDRRate
                        ? dailyUsageChartTypeConstants.NET_ENERGY
                        : dailyUsageChartTypeConstants.USAGE
                    : chartType === dailyUsageChartTypeConstants.DEMAND
                        && hasMonthlyDemandOnly
                        ? isSolar
                            ? dailyUsageChartTypeConstants.NET_ENERGY
                            : dailyUsageChartTypeConstants.USAGE
                        : chartType

            barClickCallback(startDate, endDate, clickChartType, hasCost, isSolar)
        }

        window.innerWidth < 1024
            ? drawHorizontalChart(usage, currentChart, hasOnOffShoulder, monthlyClickCallback, hasCost, isSolar, t)
            : drawVerticalChart(usage, currentChart, highDemandInfoList, hasOnOffShoulder, monthlyClickCallback, hasCost, isSolar, t)
    }
}

class MonthlyUsageChartContainer extends React.Component{
    constructor(props) {
        super(props)
        this.state = {
            resizeListener: this.updateChart.bind(this)
        }
    }
    componentDidMount() {
        window.addEventListener("resize", this.state.resizeListener)
        if (this.props.accountHistoryStatus === loadingStatus.LOADING_STATUS_SUCCESS)
            this.updateChart()
    }

    shouldComponentUpdate(nextProps) {
        return (this.props.accountHistoryStatus !== nextProps.accountHistoryStatus
                || this.props.rateMetaData.rateMetaDataStatus !== nextProps.rateMetaData.rateMetaDataStatus
                || this.props.monthlyStartDate !== nextProps.monthlyStartDate
                || this.props.monthlyEndDate !== nextProps.monthlyEndDate
                || this.props.currentMonthlyChart !== nextProps.currentMonthlyChart
                || this.props.t !== nextProps.t
            )
        }

    componentDidUpdate() {
        if (this.props.accountHistoryStatus === loadingStatus.LOADING_STATUS_SUCCESS
            && this.props.rateMetaData.rateMetaDataStatus === loadingStatus.LOADING_STATUS_SUCCESS
        )
            this.updateChart()
    }

    componentWillUnmount(){
        window.removeEventListener("resize", this.state.resizeListener)
    }

    updateChart() {
        let usageData = [];
        usageData = convertMonthlyDataForCharting(this.props.accountHistoryList,this.props.currentMonthlyChart)

        initializeChart(
            usageData,
            this.props.highDemandInfoList,
            this.props.currentMonthlyChart,
            this.props.monthlyStartDate,
            this.props.monthlyEndDate,
            this.props.rateMetaData.rateMetaData.hasOnOffShoulder,
            this.props.initDailyUsagePage,
            this.props.rateMetaData.rateMetaData.hasCost,
            this.props.rateMetaData.rateMetaData.isSolar,
            this.props.rateMetaData.rateMetaData.hasMonthlyDemandOnly,
            this.props.rateMetaData.rateMetaData.isIDRRate,
            this.props.t
        )
    }

    render() {

        return(
            <div>
                <div id="usagePageChartContainer">
                    <div id="dailyUsageLegendContainer" className="chart-legend-container">
                        <div id="superOffLegendItem" className="chart-legend-item-hidden">
                            <span>{this.props.t("Super off-peak")}</span>
                            <div className="usage-chart-legend-circle viz-SuperOffPeak" />
                        </div>
                        <div id="offPeakLegendItem" className="chart-legend-item-hidden">
                            <span>{this.props.t("Off-peak")}</span>
                            <div className="usage-chart-legend-circle viz-OffPeak" />
                        </div>
                        <div id="shoulderLegendItem" className="chart-legend-item-hidden">
                            <span>{this.props.t("Shoulder")}</span>
                            <div className="usage-chart-legend-circle viz-Shoulder" />
                        </div>
                        <div id="onPeakLegendItem" className="chart-legend-item-hidden">
                            <span>{this.props.t("On-peak")}</span>
                            <div className="usage-chart-legend-circle viz-OnPeak" />
                        </div>
                        <div id="totalLegendItem" className="chart-legend-item-hidden">
                            <span>{this.props.t("Total")}</span>
                            <div className="usage-chart-legend-circle viz-Total" />
                        </div>
                        <div id="totalLegendItemCost" className="chart-legend-item-hidden">
                            <span>{this.props.t("Cost")}</span>
                            <div className="usage-chart-legend-circle viz-Total" />
                        </div>
                    </div>
                    <div className=".viz-tooltip-arrow-main" />
                </div>
            </div>
        )
    }

}

MonthlyUsageChartContainer.propTypes = {
    highDemandInfoList: PropTypes.array,
    selectedBillAccount: PropTypes.number,
    selectedUsagePageChartTab: PropTypes.number,
    rateMetaData: PropTypes.object,
    getRateMetaDataForAccount: PropTypes.func,
    showDailyCostChart: PropTypes.bool,
    accountHistoryStatus: PropTypes.string,
    monthlyStartDate: PropTypes.string,
    monthlyEndDate: PropTypes.string,
    currentMonthlyChart: PropTypes.string,
    initDailyUsagePage: PropTypes.func,
    accountHistoryList: PropTypes.array,
    t: PropTypes.func
}

const mapStateToProps = (state) => {
    return {
        selectedBillAccount: state.accountInfo.billAccount.selectedBillAccount,
        isCost: PropTypes.bool,
        selectedUsagePageChartTab: state.accountInfo.usagePageUI.selectedUsagePageChartTab,
        rateMetaData: state.rateMetaData,
        ...state.accountInfo.usagePageUI,
        ...state.accountHistory
    }
}

function sliceUsageData(data,startDate,endDate){
    let startIndex = data.findIndex(getIndexOfUsageDate, startDate)
    let endIndex = data.findIndex(getIndexOfUsageDate, endDate)

    return (startIndex >= 0 && endIndex >= 0)
        ?  data.slice(endIndex, startIndex + 1)
        :  []
}

function getIndexOfUsageDate(element){
    let elementDate = DateTime.fromISO(element.date)
    return elementDate.hasSame(DateTime.fromISO(this), 'day')
}

const mapDispatchToProps = (dispatch) => {
    return {
       initDailyUsagePage: (startDate, endDate, chartType, hasCost, isSolar) => {
           dispatch(
            initDailyUsagePage(startDate, endDate, chartType, hasCost, isSolar))
       }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MonthlyUsageChartContainer)

