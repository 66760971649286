/* eslint-disable react/no-multi-comp */
/* OK to have two components in one file in this case. */
/* They need to share getErrorMessageStyle, and they are small. */

import React from 'react'
import PropTypes from 'prop-types'

import parse from 'html-react-parser'

let getErrorMessageStyle = (open) => {
    return {
        color: "rgb(66,66,66)",
        backgroundColor: "rgba(235,212,212,1)",
        borderRadius: "4px",
        fontSize: "14px",
        padding: "5px 10px 5px 10px",
        display: open ? "block" : "none"
    }
}

const CardError = ({open, message}) => (
    <div style={getErrorMessageStyle(open)}>
        {parse(message)}
    </div>
)

export const CardErrorJsx = ({open, jsxMessage}) => (
    <div style={getErrorMessageStyle(open)}>
        {jsxMessage}
    </div>
)

CardError.propTypes = {
    open: PropTypes.bool.isRequired,
    message: PropTypes.string.isRequired
}

CardErrorJsx.propTypes = {
    open: PropTypes.bool.isRequired,
    jsxMessage: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.string,
    ]).isRequired,
}

export default CardError