/* eslint react/no-danger: 0 */
import PropTypes from 'prop-types';
import React from 'react'
import { connect } from 'react-redux'

import Footer from './footer'
import { getFooter } from '../../actions/guest/header-footer-actions'

class FooterContainer extends React.Component {
    componentDidMount() {
        this.props.getFooter(this.props.displayedLanguage)
    }

    componentDidUpdate(prevProps) {
        if (prevProps.displayedLanguage !== this.props.displayedLanguage) {
            this.props.getFooter(this.props.displayedLanguage)
        }
    }

    insertDigicertScript() {
        let footer = document.getElementById('footer')
        if (footer) {
            const script = document.createElement('script')
            script.src = `/common/scripts/digicert_seal.js`
            footer.appendChild(script)
        }
    }

    render() {
        return (
            <Footer footerHtml={this.props.footerHtml} />
        )
    }
}

const mapPropsToState = (state) => {
    return {
        displayedLanguage: state.myAccount.displayedLanguage,
        footerHtml: state.navigation.footerHtml
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getFooter: (displayedLanguage) => {
            dispatch(getFooter(displayedLanguage))
        }
    }
}

FooterContainer.propTypes = {
    displayedLanguage: PropTypes.string,
    getFooter: PropTypes.func,
    footerHtml: PropTypes.string,
}

export default connect(mapPropsToState, mapDispatchToProps)(FooterContainer);