import { eraProgramConstants } from '../../../constants/era-program-constants'
import { billAccountConstants } from '../../../constants/bill-account-constants'
import * as loadingStatus from '../../../constants/loading-status-constants'

const initialState = {
    submitErapFormResult:
    {
        success: false,
        emailSuccess: false,
        submitDateTime: "00:00",
        submitDateDate: "01/01/0001"
    },
    submitErapFormStatus: loadingStatus.LOADING_STATUS_INIT
}

export default function eraProgramReducer(state = initialState, action) {
    switch (action.type) {
        case eraProgramConstants.SUBMIT_ERAP_FORM_REQUEST:
            return { ...state, submitErapFormStatus: loadingStatus.LOADING_STATUS_IN_PROGRESS }
        case eraProgramConstants.SUBMIT_ERAP_FORM_SUCCESS:
            return {
                ...state,
                submitErapFormResult: action.payload,
                submitErapFormStatus: action.payload.success ? loadingStatus.LOADING_STATUS_SUCCESS : loadingStatus.LOADING_STATUS_FAILURE
            }
        case eraProgramConstants.SUBMIT_ERAP_FORM_FAILURE:
            return {
                ...state,
                submitErapFormResult: initialState.submitErapFormResult,
                submitErapFormStatus: loadingStatus.LOADING_STATUS_FAILURE 
            }
        case eraProgramConstants.REFRESH_ERAP_FORM:
            return { ...state, submitErapFormStatus: loadingStatus.LOADING_STATUS_INIT }
        case billAccountConstants.SELECT_BILL_ACCOUNT_SUCCESS:
            return { ...initialState }
        default:
            return state
    }
}