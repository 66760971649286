import React from 'react'
import PropTypes from 'prop-types'
import TextField from '@mui/material/TextField'

const PaymentDateField = ({t, ...props}) => {

    let isError = props.touched['paymentDate']
        && Object.prototype.hasOwnProperty.call(props.errors, 'paymentDate')
    let errMsg = isError ? props.errors['paymentDate'] : ''
    let meta = {
        id: 'paymentDate',
        name: 'paymentDate',
        label: t("Payment date"),
        helperText: errMsg==="" ? "" : t(errMsg),
        error: isError
    }
    return (
        <TextField {...meta} {...props} disabled={true} />
    )
}

PaymentDateField.propTypes = {
    touched: PropTypes.shape({
        paymentDate: PropTypes.bool
    }).isRequired,
    errors: PropTypes.shape({
        paymentDate: PropTypes.string
    }).isRequired,
    t: PropTypes.func.isRequired
}

export default PaymentDateField