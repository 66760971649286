import React from 'react'
import PropTypes from 'prop-types'
import { Formik } from 'formik'
import { withTranslation } from 'react-i18next'
import TextField from '@mui/material/TextField'
import { PatternFormat } from 'react-number-format'

import validate from '../../srp_modules/validate'

const AccountDetailsResidential = ({ activeStep, setActiveStep, accountDetails, setAccountDetails,
    accountDetailsChanged, setAccountDetailsChanged, t }) => {

    return (
        <Formik
            initialValues={{
                billAccount: accountDetails.billAccount,
                firstName: accountDetails.firstName || '',
                middleInitial: accountDetails.middleInitial || '',
                lastName: accountDetails.lastName || '',
                businessName: accountDetails.businessName || '',
                dbaName: accountDetails.dbaName || '',
                contactName: accountDetails.contactName || '',
                contactPosition: accountDetails.contactPosition || '',
                serviceAddressLineOne: accountDetails.serviceAddressLineOne || '',
                serviceAddressLineTwo: accountDetails.serviceAddressLineTwo || '',
                city: accountDetails.city || '',
                zipCode: accountDetails.zipCode || '',
                email: accountDetails.email || '',
                phone: accountDetails.phone || ''
            }}
            validate={(values) => validateForm(values, t)}
            onSubmit={(values) => {
                let transformedValues = transformValues(values)
                setAccountDetails(transformedValues)
                setActiveStep(activeStep + 1)
            }}>
            {({ initialValues, values, errors, touched, dirty, handleChange, handleBlur, handleSubmit, setFieldValue, setFieldTouched, submitCount }) => {
                let billAccountError = (dirty || submitCount > 0) && touched.billAccount && errors.billAccount !== undefined
                let invalidBillAccountError = (dirty || submitCount > 0) && touched.billAccount && errors.invalidBillAccount !== undefined
                let firstNameError = (dirty || submitCount > 0) && touched.firstName && errors.firstName !== undefined
                let lastNameError = (dirty || submitCount > 0) && touched.lastName && errors.lastName !== undefined
                let serviceAddressError = (dirty || submitCount > 0) && touched.serviceAddressLineOne && errors.serviceAddress !== undefined
                let cityError = (dirty || submitCount > 0) && touched.city && errors.city !== undefined
                let zipCodeError = (dirty || submitCount > 0) && touched.zipCode && errors.zipCode !== undefined
                let emailError = (dirty || submitCount > 0) && touched.email && errors.email !== undefined
                let invalidEmailError = (dirty || submitCount > 0) && touched.email && errors.invalidEmail !== undefined
                let phoneError = (dirty || submitCount > 0) && touched.phone && errors.phone !== undefined
                let invalidPhoneError = (dirty || submitCount > 0) && touched.phone && errors.invalidPhone !== undefined

                let setCustomerNames = (value, fieldName) => {
                    if (value.match("^[ A-Za-z'-]*$")) {
                        setFieldValue(fieldName, value, true)
                    }
                }

                let setMiddleInitial = (middleInitial) => {
                    if (middleInitial.match("^[a-zA-Z]*$")) {
                        setFieldValue('middleInitial', middleInitial, true)
                    }
                }

                let setZipCodeOnChange = zipCode => {
                    if (zipCode.match("^[a-zA-Z0-9]*$")) {
                        setFieldValue('zipCode', zipCode, true)
                    }
                }

                if (values != initialValues && !accountDetailsChanged) {
                    setAccountDetailsChanged()
                }

                return (
                    <form className="mb-2 mt-4 text-muted" onSubmit={handleSubmit}>
                        <PatternFormat
                            id="billAccount"
                            name="billAccount"
                            className="mb-3"
                            value={values.billAccount}
                            valueIsNumericString={true}
                            fullWidth={true}
                            type="tel"
                            onValueChange={(values) => setFieldValue('billAccount', values.value, true)}
                            onBlur={() => setFieldTouched("billAccount", true)}
                            format="###-###-###"
                            customInput={TextField}
                            helperText={billAccountError
                                ? errors.billAccount
                                : invalidBillAccountError
                                    ? errors.invalidBillAccount
                                    : ''}
                            error={billAccountError || invalidBillAccountError}
                            label={t("SRP account number")}
                        />
                        <TextField
                            fullWidth
                            className="mb-3"
                            name="firstName"
                            inputProps={{ maxLength: 50 }}
                            value={values.firstName}
                            onChange={(e) => setCustomerNames(e.target.value, 'firstName')}
                            onBlur={handleBlur}
                            helperText={firstNameError ? errors.firstName : ''}
                            error={firstNameError}
                            label={t("First name")}
                        />
                        <TextField
                            fullWidth
                            className="mb-3"
                            name="middleInitial"
                            inputProps={{ maxLength: 1 }}
                            value={values.middleInitial}
                            onChange={(e) => setMiddleInitial(e.target.value)}
                            onBlur={handleBlur}
                            label={t("Middle initial")}
                        />
                        <TextField
                            fullWidth
                            className="mb-3"
                            name="lastName"
                            inputProps={{ maxLength: 50 }}
                            value={values.lastName}
                            onChange={(e) => setCustomerNames(e.target.value, 'lastName')}
                            onBlur={handleBlur}
                            helperText={lastNameError ? errors.lastName : ''}
                            error={lastNameError}
                            label={t("Last name")}
                        />
                        <TextField
                            fullWidth
                            className="mb-2"
                            name="serviceAddressLineOne"
                            inputProps={{ maxLength: 39 }}
                            value={values.serviceAddressLineOne}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={serviceAddressError}
                            label={t("Street address")}
                        />
                        <TextField
                            fullWidth
                            className="mb-3"
                            name="serviceAddressLineTwo"
                            inputProps={{ maxLength: 39 }}
                            value={values.serviceAddressLineTwo}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            helperText={serviceAddressError ? errors.serviceAddress : ''}
                            error={serviceAddressError}
                            label={t("Apt, suite, etc (optional)")}
                        />
                        <TextField
                            fullWidth
                            className="mb-3"
                            name="city"
                            inputProps={{ maxLength: 30 }}
                            value={values.city}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            helperText={cityError ? errors.city : ''}
                            error={cityError}
                            label={t("City")}
                        />
                        <TextField
                            id="zipCode"
                            name="zipCode"
                            className="mb-4"
                            inputProps={{ maxLength: 9 }}
                            value={values.zipCode}
                            fullWidth={true}
                            onChange={(e) => setZipCodeOnChange(e.target.value)}
                            onBlur={handleBlur}
                            helperText={zipCodeError ? errors.zipCode : ''}
                            error={zipCodeError}
                            label={t("Zip code")}
                        />
                        <TextField
                            fullWidth
                            className="mb-3"
                            name="email"
                            inputProps={{ maxLength: 100 }}
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            helperText={
                                emailError
                                    ? errors.email
                                    : invalidEmailError
                                        ? errors.invalidEmail
                                        : ''}
                            error={emailError || invalidEmailError}
                            label={t("Email address")}
                        />
                        <PatternFormat
                            id="phone"
                            name="phone"
                            className="mb-4"
                            value={values.phone}
                            valueIsNumericString={true}
                            fullWidth={true}
                            type="tel"
                            onValueChange={(values) => setFieldValue('phone', values.value, true)}
                            onBlur={() => setFieldTouched("phone", true)}
                            format="(###) ###-####"
                            customInput={TextField}
                            helperText={
                                phoneError
                                    ? errors.phone
                                    : invalidPhoneError
                                        ? errors.invalidPhone
                                        : ''}
                            error={phoneError || invalidPhoneError}
                            label={t("Phone number")}
                        />
                        <div className="d-flex flex-wrap justify-content-end">
                            <button className="btn srp-btn btn-blue" type="submit">{t("Next")}</button>
                        </div>
                    </form>
                )
            }}
        </Formik>
    )
}

AccountDetailsResidential.propTypes = {
    activeStep: PropTypes.number,
    setActiveStep: PropTypes.func,

    accountDetails: PropTypes.object,
    setAccountDetails: PropTypes.func,

    accountDetailsChanged: PropTypes.bool,
    setAccountDetailsChanged: PropTypes.func,

    t: PropTypes.func
}

export default withTranslation('changePricePlan')(AccountDetailsResidential)

function transformValues(values) {
    return {
        ...values,
        billAccount: values.billAccount.trim(),
        firstName: values.firstName.trim(),
        middleInitial: values.middleInitial.trim(),
        lastName: values.lastName.trim(),
        serviceAddressLineOne: values.serviceAddressLineOne.trim(),
        serviceAddressLineTwo: values.serviceAddressLineTwo.trim(),
        city: values.city.trim(),
        zipCode: values.zipCode.trim(),
        email: values.email.trim(),
        phone: values.phone.replace(/[() -]/g, '')
    }
}

function validateForm(values, t) {
    let errors = {}

    if (values.billAccount === undefined || values.billAccount.trim().length === 0)
        errors.billAccount = t("SRP account number is required")
    if (values.billAccount.length < 9)
        errors.invalidBillAccount = t("Invalid SRP account number")

    if (values.firstName === undefined || values.firstName.trim().length === 0)
        errors.firstName = t("First name is required")

    if (values.lastName === undefined || values.lastName.trim().length === 0)
        errors.lastName = t("Last name is required")

    if (values.serviceAddressLineOne === undefined || values.serviceAddressLineOne.trim().length === 0)
        errors.serviceAddress = t("Service address is required")

    if (values.city === undefined || values.city.trim().length === 0)
        errors.city = t("City is required")

    if (values.zipCode === undefined || values.zipCode.trim().length === 0)
        errors.zipCode = t("Zip code is required")

    if (values.email === undefined || values.email.trim().length === 0)
        errors.email = t("Email address is required")
    if (!validate.emailAddress(values.email))
        errors.invalidEmail = t("Please enter a valid email address")

    if (values.phone === undefined || values.phone.trim().length === 0)
        errors.phone = t("Phone number is required")
    if (values.phone.length != 10)
        errors.invalidPhone = t("Please enter a valid phone number")

    return errors
}