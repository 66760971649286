const addressAutocompleteModule = {
    initializeGoogleAutocomplete,
    removeAutocompleteListener,
    getAddressComponents
}

function initializeGoogleAutocomplete(fieldId, value, callbackAddressFunc, isDomesticAddress) {
    let google = window.google
    let autocompleteSearch = {}
    let addressElement = document.getElementById(fieldId)
    if (value.length > 2) {
        autocompleteSearch = new google.maps.places.Autocomplete(addressElement, { types: ["geocode"] })
        autocompleteSearch.setFields(["address_component"])
        if(isDomesticAddress){
            autocompleteSearch.setComponentRestrictions({country: 'us'})
            autocompleteSearch.setBounds(
                new google.maps.LatLngBounds(new google.maps.LatLng(32.599615, -113.141349),
                new google.maps.LatLng(34.161393, -111.141838)))
        }
        autocompleteSearch.addListener("place_changed", function(){
            let place = autocompleteSearch.getPlace()
            let address = getAddressComponents(place)
            callbackAddressFunc(address)
        })
    }
}

function removeAutocompleteListener(fieldId) {
    let google = window.google
    let addressElement = document.getElementById(fieldId)
    google.maps.event.clearInstanceListeners(addressElement);
}

function getAddressComponents(place) {
    let addressComponents = {
        streetNumber: "",
        street: "",
        city: "",
        state: "",
        zipCode: "",
        country: ""
    }

    for (let i = 0; i < place.address_components.length; i++) {
        let addressType = place.address_components[i].types[0];
        switch (addressType) {
            case "street_number":
                addressComponents = {
                    ...addressComponents,
                    streetNumber: place.address_components[i].short_name
                }
                break
            case "route":
                addressComponents = {
                    ...addressComponents,
                    street: place.address_components[i].short_name
                }
                break
            case "locality":
                addressComponents = {
                    ...addressComponents,
                    city: place.address_components[i].long_name
                }
                break
            case "administrative_area_level_1":
                addressComponents = {
                    ...addressComponents,
                    state: place.address_components[i].short_name
                }
                break
            case "postal_code":
                addressComponents = {
                    ...addressComponents,
                    zipCode: place.address_components[i].short_name
                }
                break
            case "country":
                addressComponents = {
                    ...addressComponents,
                    country: place.address_components[i].long_name
                }
                break
            default:
                break
        }
    }

    return addressComponents
}

export default addressAutocompleteModule