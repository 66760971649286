import React from 'react'
import PropTypes from 'prop-types'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { DateTime } from 'luxon'

import SinglePaymentForm from './single-payment-form'

class SinglePaymentFormik extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            payMultipleAccountsButtonClicked: false
        }

        this.setPayMultipleAccountsButtonClicked = this.setPayMultipleAccountsButtonClicked.bind(this)
        this.clearPayMultipleAccountsButtonClicked = this.clearPayMultipleAccountsButtonClicked.bind(this)

        this.validationSchema = Yup.object().shape({
            srpAccountNumber: Yup.string().ensure().
                transform(function (srpAccountNumber) {
                    return this.isType(srpAccountNumber) && srpAccountNumber !== null ? srpAccountNumber.replace(/[- ]/g, '') : srpAccountNumber
                }).
                required("SRP account number is required.").
                test('nnn-nnn-nnn format', "Invalid SRP account number.", srpAccountNumber => /^\d{9}$/.test(srpAccountNumber)),
            bankFirstName: Yup.string().ensure().trim().
                required("First name is required."),
            bankLastName: Yup.string().ensure().trim().
                required("Last name is required.")
                .test('not all spaces', "Last name is required.", bankLastName => typeof bankLastName === "string" && bankLastName.trim().length > 0)
                .test('at least 2 characters', "Last name must have at least two characters.", bankLastName => typeof bankLastName === "string" && bankLastName.trim().length > 1),
            bankPhoneNumber: Yup.string().ensure().
                transform(function (bankPhoneNumber) {
                    return this.isType(bankPhoneNumber) && bankPhoneNumber !== null ? bankPhoneNumber.replace(/[() -]/g, '') : bankPhoneNumber
                }).
                required("Phone number is required.").
                test('must have 10 digits', "Phone number must have 10 digits.", bankPhoneNumber => /^\d{10}$/.test(bankPhoneNumber)),
            bankEmailAddress: Yup.string().ensure().trim().
                required("Email address is required.").
                email("Invalid email address."),
            bankRoutingNumber: Yup.string().ensure().trim().
                required("Routing number is required.").
                test('must have 9 digits', "Enter a valid 9-digit routing number.", bankRoutingNumber => /^\d{9}$/.test(bankRoutingNumber)),
            bankAccountNumberLeadingZeroesAreSignificant: Yup.string().ensure().trim().
                required("Bank account number is required.").
                test('17 digits max', "Your account number must be 1-17 digits.", bankAccountNumber => /^\d{1,17}$/.test(bankAccountNumber)),
            paymentAmount: Yup.string().ensure().trim().
                required("A payment amount is required.").
                test('payment amount is valid', "Invalid payment amount.", paymentAmount => /^(\$?\d+(,\d{3})*\.?[0-9]?[0-9]?|\$?\.\d{1,2})$/.test(paymentAmount)). // regex from https://stackoverflow.com/questions/8829765/regular-expression-for-dollar-amount-in-javascript
                test('payment amount is a penny or more', "Invalid payment amount.", paymentAmount => parseFloat(paymentAmount.replace(/[$,]/g, '')) >= 0.01),
            paymentDate: Yup.date().
                required("A payment date is required.").
                min(DateTime.now().toFormat('M/d/yyyy'), "Payment date must be today.").
                max(DateTime.now().toFormat('M/d/yyyy'), "Payment date must be today.")
        })
    }

    setPayMultipleAccountsButtonClicked() {
        this.setState({ ...this.state, payMultipleAccountsButtonClicked: true })
    }
    clearPayMultipleAccountsButtonClicked() {
        this.setState({ ...this.state, payMultipleAccountsButtonClicked: false })
    }

    transformValues(values) {
        return {
            ...values,
            srpAccountNumber: values.srpAccountNumber.replace(/[- ]/g, ''),
            bankFirstName: values.bankFirstName.trim(),
            bankLastName: values.bankLastName.trim(),
            bankPhoneNumber: values.bankPhoneNumber.replace(/[() -]/g, ''),
            bankEmailAddress: values.bankEmailAddress.trim(),
            bankRoutingNumber: values.bankRoutingNumber.trim(),
            bankAccountNumberLeadingZeroesAreSignificant: values.bankAccountNumberLeadingZeroesAreSignificant.trim(),
            paymentAmount: parseFloat(values.paymentAmount.trim().replace(/[$,]/g, ''))
        }
    }

    render() {
        return (
            <Formik
                initialValues={{
                    srpAccountNumber: this.props.paymentList.length > 0 ? this.props.paymentList[0].srpAccountNumber : '',
                    bankFirstName: this.props.bankFirstName,
                    bankLastName: this.props.bankLastName,
                    bankPhoneNumber: this.props.bankPhoneNumber,
                    bankEmailAddress: this.props.bankEmailAddress,
                    bankRoutingNumber: this.props.bankRoutingNumber,
                    bankAccountNumberLeadingZeroesAreSignificant: this.props.bankAccountNumberLeadingZeroesAreSignificant,
                    paymentAmount: this.props.paymentList.length > 0 ? String(this.props.paymentList[0].paymentAmount) : '',
                    paymentDate: DateTime.now().toFormat('M/d/yyyy')
                }}
                validationSchema={this.validationSchema}
                onSubmit={(paymentAndBankValues, goodies) => {
                    const _paymentAndBankValues = this.transformValues(paymentAndBankValues)
                    this.props.storeBankInfoAndPayment(_paymentAndBankValues);
                    if (this.state.payMultipleAccountsButtonClicked)
                        this.props.switchToMultiplePaymentForm()
                    else
                        this.props.validatePayment(goodies, this.validationSchema, _paymentAndBankValues)
                }}>
                {formikProps => (<SinglePaymentForm
                    {...formikProps}
                    {...this.props}
                    {...this.state}
                    setPayMultipleAccountsButtonClicked={this.setPayMultipleAccountsButtonClicked}
                    clearPayMultipleAccountsButtonClicked={this.clearPayMultipleAccountsButtonClicked} />)}
            </Formik>
        )
    }
}

SinglePaymentFormik.propTypes = {
    paymentList: PropTypes.arrayOf(PropTypes.shape({
        srpAccountNumber: PropTypes.string.isRequired,
        paymentAmount: PropTypes.number.isRequired,
    })),
    bankFirstName: PropTypes.string.isRequired,
    bankLastName: PropTypes.string.isRequired,
    bankPhoneNumber: PropTypes.string.isRequired,
    bankEmailAddress: PropTypes.string.isRequired,
    bankRoutingNumber: PropTypes.string.isRequired,
    bankAccountNumberLeadingZeroesAreSignificant: PropTypes.string.isRequired,

    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired,
    formDataChanged: PropTypes.func.isRequired,

    validatePayment: PropTypes.func.isRequired,
    switchToMultiplePaymentForm: PropTypes.func.isRequired,
    storeBankInfoAndPayment: PropTypes.func.isRequired
}

export default SinglePaymentFormik