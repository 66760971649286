import React from 'react'
import { connect } from 'react-redux'
import { PropTypes } from 'prop-types'
import CircularProgress from '@mui/material/CircularProgress'
import IconButton from '@mui/material/IconButton'
import TextField from '@mui/material/TextField'
import Collapse from '@mui/material/Collapse'
import { Formik } from 'formik'
import { updateBillAccountNickname } from '../../../../actions/auth/bill_account/bill-account-actions'
import SummaryBillingSubAccountResponsive from './summary-billing-sub-account-responsive'
import SrpTooltip from '../../../common_tooltip/srp-tooltip'
import SrpPopupMenu from '../../../common_popup_menu/srp-popup-menu'


class SummaryBillingRowResponsive extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            showEditNickname: false,
            showSubAccounts: false,
        }

        this.updateNickname = this.updateNickname.bind(this)
    }

    async updateNickname(values, formikGoodies) {
        let updateResponse = await this.props.updateNickname(values)
        formikGoodies.setSubmitting(false)

        if (!updateResponse || updateResponse.error === true || updateResponse.payload.success === false) {
            formikGoodies.setStatus({ error: "We're sorry, there was an error. Please try again later." })
        }
        else {
            this.setState({ ...this.state, showEditNickname: false })
        }
    }

    render() {
        let { account, accountName, accountText, isFinalAccount } = this.props.billAccountItem
        let disableEditAndDelete = this.props.disableEditAndDelete
        let hasNickName = accountName !== null && accountName.length > 0
        let accountNameDisplay = (accountName !== null && accountName.length > 0) ? accountName : accountText
        if (this.props.summaryBillAccount.subAccounts.length === 0)
            return (null)
        else
            return (
                <div>
                    <div className="mt-3 summary-billing-row-responsive-container">
                        <div>
                            {this.state.showEditNickname
                                ? <Formik
                                    onSubmit={this.updateNickname}
                                    initialValues={{
                                        billAccount: account,
                                        nickname: accountName || ''
                                    }}>
                                    {({ values, status, handleBlur, handleChange, handleSubmit, isSubmitting }) => {
                                        let formStatus = status || {}

                                        return (
                                            <form className="border-bottom p-2" onSubmit={handleSubmit}>
                                                {formStatus.error ?
                                                    <div className="row mt-2 mb-2">
                                                        <div className="col">
                                                            <span className="srp-alert-error">{formStatus.error}</span>
                                                        </div>
                                                    </div> : null}
                                                <div className="row">
                                                    <div className="col">
                                                        <TextField value={accountText} fullWidth={true} disabled={true} label={this.props.t("SRP account number")}/>
                                                        <TextField
                                                            autoFocus={true}
                                                            name="nickname"
                                                            value={values.nickname}
                                                            fullWidth={true}
                                                            onFocus={e => e.target.select()}
                                                            onBlur={handleBlur}
                                                            onChange={handleChange} 
                                                            label={this.props.t("Nickname")}    
                                                        />
                                                    </div>
                                                    <div className="col align-self-end text-right text-nowrap pt-2">
                                                        <button type="button" className="btn srp-btn btn-lightblue"
                                                            disabled={isSubmitting}
                                                            onClick={() => this.setState({ ...this.state, showEditNickname: false })}>
                                                            {this.props.t("Cancel")}
                                                        </button>
                                                        <button type="submit" className="btn srp-btn btn-green ml-2" disabled={isSubmitting || values.nickname === accountName}>
                                                            {isSubmitting ? <CircularProgress size={20} thickness={5} color="secondary" /> : this.props.t("Update")}
                                                        </button>
                                                    </div>
                                                </div>
                                            </form>
                                        )
                                    }}
                                </Formik>
                                : <div className="p-2 pb-4">
                                    <div className="row pt-1 associated-account-row-responsive-account-name">
                                        <div className="col-10 d-flex align-self-center" style={{ color: isFinalAccount ? 'rgb(158,42,43)' : 'inherit' }}>
                                            <div className="text-truncate">
                                                <SrpTooltip
                                                    content={<span>{accountNameDisplay}</span>}
                                                >
                                                    <div className="text-truncate">
                                                        <span>{accountNameDisplay}</span>
                                                    </div>
                                                </SrpTooltip>
                                            </div>
                                        </div>
                                        <div className="col-2 d-flex align-self-center">
                                            <SrpPopupMenu
                                                content={
                                                    <div>
                                                        <IconButton color="primary" size="small"
                                                            onClick={() => this.setState({ ...this.state, showEditNickname: true })}
                                                            disabled={disableEditAndDelete} >
                                                            <i className="material-icons">create</i>
                                                        </IconButton>
                                                        {
                                                            this.props.hideDelete ? null :
                                                                <span>
                                                                    <span style={{ borderLeft: '1px solid #888888', fontSize: '20px' }} />
                                                                    <IconButton
                                                                        disabled={disableEditAndDelete}
                                                                        color="primary"
                                                                        size="small"
                                                                        onClick={this.props.deleteBillAccountOnClick(account, accountName)}>
                                                                        {disableEditAndDelete ? <CircularProgress className="mr-1" size={20} thickness={5} /> : <i className="material-icons">delete</i>}
                                                                    </IconButton>
                                                                </span>
                                                        }
                                                    </div>
                                                }
                                            >
                                                <IconButton
                                                    color="primary"
                                                    classes={{
                                                        root: "associated-account-more-button-responsive"
                                                    }}
                                                    size="large">
                                                    <i className="material-icons">more_horiz</i>
                                                </IconButton>
                                            </SrpPopupMenu>
                                        </div>
                                    </div>
                                    {hasNickName &&
                                        <div className="row pt-1 associated-account-row-responsive-account-name">
                                            <div className="col-12 align-self-center">
                                                {accountText}
                                            </div>
                                        </div>
                                    }
                                    <div className="row pt-1">
                                        <div className="col-12 align-self-center text-truncate">
                                            <div className="text-nowrap summary-account-parent-chip">
                                                <div style={{ marginLeft: 'auto', marginRight: 'auto' }}>Master</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            {this.props.summaryBillAccount.subAccounts.length > 0 &&
                                <Collapse
                                    in={this.state.showSubAccounts}
                                    collapsedSize="49px"
                                >
                                    {!this.state.showSubAccounts &&
                                        <div className="summary-account-expand-responsive"
                                             onClick={() => this.setState({ ...this.state, showSubAccounts: !this.state.showSubAccounts })}
                                        >
                                            <IconButton
                                                color="primary"
                                                size="large">
                                                <i className="material-icons">keyboard_arrow_down</i>
                                            </IconButton>
                                            <span className="align-self-center">
                                                {this.props.t("Show sub accounts")} {this.props.summaryBillAccount.subAccounts.length}
                                            </span>
                                        </div>
                                    }
                                    {this.props.summaryBillAccount.subAccounts.map((subAccount) => {
                                        return (
                                            <SummaryBillingSubAccountResponsive
                                                key={subAccount.billAccount}
                                                subAccount={subAccount}
                                                disableEditAndDelete={this.props.pendingDelete[`${subAccount.billAccount}`] ? true : false}
                                                deleteBillAccountOnClick={this.props.deleteBillAccountOnClick} />)
                                    })}
                                    {this.state.showSubAccounts &&
                                        <div className="summary-account-expand-responsive"
                                             onClick={() => this.setState({ ...this.state, showSubAccounts: !this.state.showSubAccounts })}
                                        >
                                            <IconButton
                                                color="primary"
                                                size="large">
                                                <i className="material-icons">keyboard_arrow_up</i>
                                            </IconButton>
                                            <span className="align-self-center">
                                                {this.props.t("Hide sub accounts")} {this.props.summaryBillAccount.subAccounts.length}
                                            </span>
                                        </div>
                                    }
                                </Collapse>
                            }
                        </div>
                    </div>
                </div>
            )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        updateNickname: async (values) => {
            return await dispatch(updateBillAccountNickname(values.billAccount, values.nickname))
        }
    }
}

SummaryBillingRowResponsive.propTypes = {
    billAccountItem: PropTypes.object.isRequired,
    summaryBillAccount: PropTypes.object.isRequired,
    deleteBillAccountOnClick: PropTypes.func.isRequired,
    updateNickname: PropTypes.func.isRequired,
    disableEditAndDelete: PropTypes.bool.isRequired,
    hideDelete: PropTypes.bool.isRequired,
    pendingDelete: PropTypes.object.isRequired
}

export default connect(null, mapDispatchToProps)(SummaryBillingRowResponsive)