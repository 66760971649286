import { billPageConstants } from '../../../constants/bill-page-constants'

export const addBillToCompare = (bill, chip) => ({
    type: billPageConstants.ADD_BILL_TO_COMPARE,
    bill, chip
})

export const removeBillFromCompare = (billToDelete) => ({
    type: billPageConstants.REMOVE_BILL_FROM_COMPARE,
    billToDelete
})