import React from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'

import PaymentConfirmationDetailsTable from './payment-confirmation-details-table'

const PaymentConfirmationDetailsTableContainer = (props) => (
    <PaymentConfirmationDetailsTable
        bankName={props.bankName}
        truncatedBankAccountNumber={props.truncatedBankAccountNumber}
        paymentList={props.paymentList}
        paymentAmountWarningText={props.paymentAmountWarningText}
        paymentDateWarningText={props.paymentDateWarningText}
        t={props.t}
        i18n={props.i18n}
    />
)

const mapStateToProps = (state) => {
    const makePaymentInfo = state.accountInfo.payment.makePaymentInfo
    const selectedBank = state.bankAccounts.bankAccountList.find((bankAccount) => bankAccount.bankId === makePaymentInfo.bankAccountId)

    const noPayment = (makePaymentInfo.accountNumber === "" || makePaymentInfo.amount === "")

    return {
        bankName: selectedBank ? (selectedBank.nickName ? selectedBank.nickName : selectedBank.bankName) : "",
        truncatedBankAccountNumber: selectedBank ? selectedBank.accountNumber : "",
        paymentList: noPayment ? [] : [{
            srpAccountNumber: makePaymentInfo.accountNumber,
            paymentAmount: +makePaymentInfo.amount,
            shareAmount: +makePaymentInfo.shareAmount,
            paymentDate: makePaymentInfo.paymentDate
        }],
        paymentAmountWarningText: makePaymentInfo.paymentAmountWarningText,
        paymentDateWarningText: makePaymentInfo.paymentDateWarningText
    }
}

PaymentConfirmationDetailsTableContainer.propTypes = {
    bankName: PropTypes.string.isRequired,
    truncatedBankAccountNumber: PropTypes.string.isRequired,
    paymentList: PropTypes.arrayOf(PropTypes.shape({
        srpAccountNumber: PropTypes.number.isRequired,
        paymentAmount: PropTypes.number.isRequired,
        shareAmount: PropTypes.number,
        paymentDate: PropTypes.string.isRequired
    })),
    paymentAmountWarningText: PropTypes.string.isRequired,
    paymentDateWarningText: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired,
    i18n: PropTypes.object.isRequired
}

export default withTranslation("singlePayment")(connect(mapStateToProps)(PaymentConfirmationDetailsTableContainer))