import { eraProgramConstants } from '../../constants/era-program-constants'

const eraProgramMapper = {
    mapEraProgramStatusText,
    createEraProgramFormModel,
    mapStepperDetails
}

function mapEraProgramStatusText(eraProgramStatus, t) {
    switch (eraProgramStatus) {
        case eraProgramConstants.STATUS.NOT_ELIGIBLE: return t("Not Eligible")
        case eraProgramConstants.STATUS.NEW_ELIGIBLE: return t("Eligible")
        case eraProgramConstants.STATUS.APPLIED_UNDER_REVIEW: return t("Under Review")
        case eraProgramConstants.STATUS.APPROVED: return t("Approved")
        case eraProgramConstants.STATUS.DENIED: return t("Not Eligible")
        case eraProgramConstants.STATUS.SELF_DECLARED_INELIGIBLE: return t("Self-Declared Ineligible")
        case eraProgramConstants.STATUS.RECERTIFY: return t("Recertification Required")
        default: return t("Not Eligible")
    }
}

function createEraProgramFormModel(formValues, isPrePay, language) {

    let model = {
        firstName: formValues.firstName,
        lastName: formValues.lastName,
        emailAddress: formValues.emailAddress,
        billAccount: formValues.billAccount,
        meterType: isPrePay ? "PREPAY" : "CREDIT",
        formSubmissionNum: formValues.formSubmissionNum,
        isRenter: formValues.rentOrOwn === eraProgramConstants.NONE ? null : formValues.rentOrOwn === eraProgramConstants.HOUSING.RENT,
        isCovidImpact: formValues.submissionType === eraProgramConstants.SUBMISSION_TYPE.SIGNUP
            ? formValues.impactedByCovid === eraProgramConstants.NONE ? null : formValues.impactedByCovid === eraProgramConstants.YES
            : formValues.isDifficultPayingBill === eraProgramConstants.NONE ? null : formValues.isDifficultPayingBill === eraProgramConstants.YES,
        requestFutureAssistance: formValues.wantAdditionalThreeMonth === eraProgramConstants.NONE
            ? null
            : formValues.wantAdditionalThreeMonth === eraProgramConstants.YES,
        monthsOfPastAssist: formValues.submissionType === eraProgramConstants.SUBMISSION_TYPE.RECERTIFY
            ? null
            : formValues.receivedEraFundsBefore === eraProgramConstants.YES
                ? parseInt(formValues.numMonthsEraCovered)
                : 0,
        hasDifficultyPayingBill: formValues.submissionType === eraProgramConstants.SUBMISSION_TYPE.SIGNUP
            ? formValues.impactedByCovid === eraProgramConstants.NONE ? null : formValues.impactedByCovid === eraProgramConstants.YES
            : formValues.isDifficultPayingBill === eraProgramConstants.NONE ? null : formValues.isDifficultPayingBill === eraProgramConstants.YES,
        numInHousehold: parseInt(formValues.numPeopleInHousehold),
        race: formValues.race,
        ethnicity: formValues.ethnicity,
        gender: formValues.gender,
        recdAuthSigned: formValues.signature.length > 0,
        language: language === "es" ? "Spanish" : "English"
    }

    return model
}

function mapStepperDetails(t) {
    return (
        [
            {
                step: 0,
                label: t("Housing Details")
            },
            {
                step: 1,
                label: t("Background, Release Authorization")
            }
        ]
    )
}

export default eraProgramMapper