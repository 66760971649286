import React from 'react'
import PropTypes from 'prop-types'

import { myAccountConstants } from '../../../../constants/myaccount-constants'

import * as Yup from 'yup'
import * as loadingStatus from '../../../../constants/loading-status-constants'

import { createValidationRulesFromeChexBankInfoValidationErrors, createValidationRulesFromeChexSRPAccountNumberAndPaymentAmountValidationErrors } from '../single-payment-formik-container'

import Card from '@mui/material/Card'

import CircularProgress from '@mui/material/CircularProgress'

import CashOnlyPaymentCard from '../../../common_payment/cash-only-payment-card'
import FetchInProgressCardContent from '../../common/fetch-in-progress-card-content'

import BankInfoSection from './bank-info-section'
import SRPAccountNumberPaymentAmountFormik from './srp-account-number-payment-amount-formik'
import { withRouter } from '../../../../srp_modules/with-router'

export class MultiplePaymentInputCard extends React.Component {
    constructor (props) {
        super(props)

        this.state = {
            confirmingPayment: false,
            hasUnhandledeChexValidationError: false,
            paymentList: [...this.props.paymentList],
            paymentListErrors: [],
            paymentListValidationSchema: Yup.object().shape({
                srpAccountNumber: Yup.string().
                    transform(function(srpAccountNumber) {
                        return this.isType(srpAccountNumber) && srpAccountNumber !== null ? srpAccountNumber.replace(/[- ]/g, '') : srpAccountNumber
                    }),
                paymentAmount: Yup.number()
            })
        }

        this.handleConfirmPaymentClick = this.handleConfirmPaymentClick.bind(this)

        this.addPaymentIfNotOnList = this.addPaymentIfNotOnList.bind(this)

        this.addPayment = this.addPayment.bind(this)
        this.removePayment = this.removePayment.bind(this)

        this.paymentListHasErrors = this.paymentListHasErrors.bind(this)

        this.handleAddBankErrors = this.handleAddBankErrors.bind(this)
        this.handleValidatePaymentListErrors = this.handleValidatePaymentListErrors.bind(this)

        this.updatePaymentListErrors = this.updatePaymentListErrors.bind(this)

        this.validateBankInfoForm = this.validateBankInfoForm.bind(this)
        this.setBankInfoFormRefs = this.setBankInfoFormRefs.bind(this)
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.paymentList.length !== prevState.paymentList.length) {
            const paymentListErrors = this.updatePaymentListErrors(this.state.paymentList, this.state.paymentListValidationSchema)

            /* eslint react/no-did-update-set-state: "off" */
            /* It's ok to call setState in componentDidUpdate. See https://reactjs.org/docs/react-component.html . */
            this.setState({ paymentListErrors })
        }

        if (this.props.selectedBillAccount === prevProps.selectedBillAccount || this.props.selectedBillAccount === 0)
            return

        this.props.getEnrolledProgramsDetails(this.props.selectedBillAccount)
        this.props.getCustomerName(this.props.selectedBillAccount)
        this.props.getPhoneNumber(this.props.selectedBillAccount)
    }

    componentWillUnmount() {
        this.props.syncPaymentList(this.state.paymentList)
    }

    updatePaymentListErrors(paymentList, paymentListValidationSchema) {
        let paymentListErrors = paymentList.map(function(p) {
            let error = { srpAccountNumber: '', paymentAmount: '' }
            try {
                const options = { abortEarly: false }
                paymentListValidationSchema.validateSync(p, options)
                return error
            }
            catch (e) {
                if (e instanceof Yup.ValidationError) {
                    e.inner.forEach(function(ve) {
                        if (ve.path === 'srpAccountNumber')
                            error.srpAccountNumber = ve.errors[0]
                        else if (ve.path === 'paymentAmount')
                            error.paymentAmount = ve.errors[0]
                    })
                    return error
                }
                else
                    throw e
            }
        })

        return paymentListErrors
    }

    async validateBankInfoForm() {
        if (this.validateBankInfoFormAsync === undefined)
            return true
        const valid = await this.validateBankInfoFormAsync()
        return valid
    }

    setBankInfoFormRefs(validateBankInfoFormAsync, bankInfoValidationSchema, storeBankInfoAndSelectedBankId) {
        this.validateBankInfoFormAsync = validateBankInfoFormAsync

        this.bankInfoValidationSchema = bankInfoValidationSchema
        this.storeBankInfoAndSelectedBankId = storeBankInfoAndSelectedBankId
    }

    paymentListHasErrors() {
        const firstPaymentError = this.state.paymentListErrors.find(e => e.srpAccountNumber || e.paymentAmount)
        return firstPaymentError !== undefined
    }

    addPaymentIfNotOnList({srpAccountNumber, paymentAmount}) {
        if (this.state.paymentList.some(p => p.srpAccountNumber === srpAccountNumber))
            return false

        this.setState(prevState => ({
            paymentList: [...prevState.paymentList, {srpAccountNumber, paymentAmount}]
        }))
        return true
    }

    addPayment({srpAccountNumber, paymentAmount}) {
        this.setState(prevState => ({
            paymentList: [...prevState.paymentList, {srpAccountNumber, paymentAmount}]
        }))
    }

    removePayment(paymentIndex) {
        if (paymentIndex < 0 || paymentIndex >= this.state.paymentList.length)
            return

        const deletedPayment = this.state.paymentList[paymentIndex]
        let paymentListCopy = [...this.state.paymentList]
        paymentListCopy.splice(paymentIndex, 1)
        this.setState({ paymentList: paymentListCopy })

        this.props.showUndoRemovePaymentSnackbar(deletedPayment, this.addPaymentIfNotOnList, this.props.t)
    }

    handleAddBankErrors(errors, bankValues) {
        if (errors.errorBankAccountSetupLocked)
            this.props.router.navigate('/myaccount/profile/banks/locked')

        this.setState({ confirmingPayment: false})

        if (errors.networkError || errors.unexpectedError) {
            this.setState({ hasUnhandledeChexValidationError: true })
            return
        }

        const bankInputValues = {
            bankFirstName: bankValues.bankFirstName,
            bankLastName: bankValues.bankLastName,
            bankRoutingNumber: bankValues.bankRoutingNumber,
            bankAccountNumber: bankValues.bankAccountNumberLeadingZeroesAreSignificant
        }

        const eChexAddBankAccountErrors = errors
        const numErrorsHandled = createValidationRulesFromeChexBankInfoValidationErrors(this.bankInfoValidationSchema.fields, eChexAddBankAccountErrors, bankInputValues)
        const hasUnhandledeChexValidationError = (Object.keys(errors).length - numErrorsHandled) > 0 && errors.constructor === Object

        this.setState({ hasUnhandledeChexValidationError })
        this.validateBankInfoForm()
    }

    handleValidatePaymentListErrors(errors) {
        this.setState({ confirmingPayment: false})

        if (errors.networkError) {
            this.setState({ hasUnhandledeChexValidationError: true })
            return
        }

        const numErrorsHandled = createValidationRulesFromeChexSRPAccountNumberAndPaymentAmountValidationErrors(this.state.paymentListValidationSchema.fields, errors,
            this.props.t, this.props.i18n)
        const hasUnhandledeChexValidationError = (Object.keys(errors).length - numErrorsHandled) > 0 && errors.constructor === Object

        this.setState({ hasUnhandledeChexValidationError })
        if (numErrorsHandled > 0) {
            const paymentListErrors = this.updatePaymentListErrors(this.state.paymentList, this.state.paymentListValidationSchema)
            this.setState({ paymentListErrors })
        }
    }

    handleConfirmPaymentClick() {
        this.setState({ confirmingPayment: true, hasUnhandledeChexValidationError: false })

        this.validateBankInfoForm().then(bankInfoValid => {
            if (bankInfoValid === false) {
                this.setState({ confirmingPayment: false })
                return false
            }

            const _bankValues = this.storeBankInfoAndSelectedBankId()
            if (this.props.bankFormOpen) {
                const bankValues = {
                    bankFirstName: _bankValues.bankFirstName,
                    bankLastName: _bankValues.bankLastName,
                    bankRoutingNumber: _bankValues.bankRoutingNumber,
                    bankAccountNumberLeadingZeroesAreSignificant: _bankValues.bankAccountNumberLeadingZeroesAreSignificant
                }

                this.props.addBank(this.handleAddBankErrors, bankValues).then(bankAdded => {
                    if (bankAdded === false)
                        return false

                    this.props.validatePaymentList(this.state.paymentList, this.handleValidatePaymentListErrors, this.props.router.navigate)
                })
            } else
                this.props.validatePaymentList(this.state.paymentList, this.handleValidatePaymentListErrors, this.props.router.navigate)
        })

        return false
    }

    render () {
        let cardContent
        if (this.props.enrolledProgramsDetailsStatus === loadingStatus.LOADING_STATUS_INIT ||
            this.props.enrolledProgramsDetailsStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS ||
            this.props.customerNamesStatus === loadingStatus.LOADING_STATUS_INIT ||
            this.props.customerNamesStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS ||
            this.props.phoneNumberStatus === loadingStatus.LOADING_STATUS_INIT ||
            this.props.phoneNumberStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS)
            cardContent = <FetchInProgressCardContent />
        else if (this.props.enrolledProgramsDetailsStatus === loadingStatus.LOADING_STATUS_SUCCESS && this.props.isCashOnly)
            return <CashOnlyPaymentCard />
        else
            cardContent = (
                <div>
                {this.state.hasUnhandledeChexValidationError &&
                    <div className="srp-alert-error mb-3">
                    {this.props.t('We_are_unable_to_complete', {phone: this.props.i18n.language==='es' ? myAccountConstants.SPANISH_CUSTOMER_SERVICE_PHONE_NUMBER_FMTTD : myAccountConstants.RESIDENTIAL_CUSTOMER_SERVICE_PHONE_NUMBER_FMTTD})}
                    </div>
                }

                <BankInfoSection {...this.props} verifyingPaymentList={this.state.confirmingPayment} bankInfoFormRefs={this.setBankInfoFormRefs} />

                <SRPAccountNumberPaymentAmountFormik
                    paymentList={this.state.paymentList} paymentListErrors={this.state.paymentListErrors}
                    verifyingPaymentList={this.state.confirmingPayment} handleAddPayment={this.addPayment}
                    handleRemovePayment={this.removePayment} formDataChanged={this.props.formDataChanged}
                    t={this.props.t}
                />

                <button className="btn srp-btn btn-blue mt-4 float-right" type="button" onClick={this.handleConfirmPaymentClick} disabled={this.state.paymentList.length === 0 || this.paymentListHasErrors() || this.state.confirmingPayment}>
                    {this.state.confirmingPayment ? <CircularProgress size={20} thickness={5} style={{color:'white'}} /> : this.props.t('Confirm payment')}
                </button>
                </div>
            )

        return (
            <div>
            <label className="h5">{this.props.t('Payment Information')}</label>
            <Card className="p-4">
                {cardContent}
            </Card>
            </div>
        )
    }
}

MultiplePaymentInputCard.propTypes = {
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired,
    t: PropTypes.func.isRequired,

    addBank: PropTypes.func,
    bankAccountList: PropTypes.arrayOf(PropTypes.shape({
        bankId: PropTypes.number.isRequired,
        bankName: PropTypes.string.isRequired,
        nickName: PropTypes.string.isRequired,
        accountNumber: PropTypes.string.isRequired
    })).isRequired,

    bankFormOpen: PropTypes.bool.isRequired,
    bankFirstName: PropTypes.string.isRequired,
    bankLastName: PropTypes.string.isRequired,
    bankPhoneNumber: PropTypes.string.isRequired,
    bankEmailAddress: PropTypes.string.isRequired,
    bankRoutingNumber: PropTypes.string.isRequired,
    bankAccountNumberLeadingZeroesAreSignificant: PropTypes.string.isRequired,
    bankNickname: PropTypes.string.isRequired,

    paymentList: PropTypes.arrayOf(PropTypes.shape({
        srpAccountNumber: PropTypes.string.isRequired,
        paymentAmount: PropTypes.number.isRequired
    })).isRequired,

    syncPaymentList: PropTypes.func.isRequired,
    validatePaymentList: PropTypes.func.isRequired,
    showUndoRemovePaymentSnackbar: PropTypes.func.isRequired,

    selectedBillAccount: PropTypes.number.isRequired,
    getEnrolledProgramsDetails: PropTypes.func.isRequired,
    getCustomerName: PropTypes.func.isRequired,
    getPhoneNumber: PropTypes.func.isRequired,
    enrolledProgramsDetailsStatus: PropTypes.string.isRequired,
    customerNamesStatus: PropTypes.string.isRequired,
    phoneNumberStatus: PropTypes.string.isRequired,
    isCashOnly: PropTypes.bool.isRequired,

    formDataChanged: PropTypes.func.isRequired,

    router: PropTypes.shape({
        navigate: PropTypes.func
    })
}

const MultiplePaymentInputCardWithRouter = withRouter(MultiplePaymentInputCard)
export default MultiplePaymentInputCardWithRouter