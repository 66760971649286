import React from 'react'
import PropTypes from 'prop-types'

import BankFirstNameField from '../input-fields/bank-first-name-field'
import BankLastNameField from '../input-fields/bank-last-name-field'
import BankPhoneNumberField from '../input-fields/bank-phone-number-field'
import BankEmailAddressField from '../input-fields/bank-email-address-field'
import BankRoutingNumberField from '../input-fields/bank-routing-number-field'
import BankAccountNumberField from '../input-fields/bank-account-number-field'

import BankRoutingNumberAccountNumberInfoIcon from '../../../common_payment/bank-routing-number-account-number-info-icon'

class BankInfoForm extends React.Component {
    constructor (props) {
        super(props)

        // Highlight validation errors when the bank form opens.
        props.validateForm().then((errors) => {
            if (Object.keys(errors).length > 0 && errors.constructor === Object)
                props.submitForm()
        })
    }

    render() {
        let { values, errors, touched, handleChange,
            handleBlur, handleSubmit, handleBankFormClose,
            verifyingPaymentList, formDataChanged,
            t, i18n } = this.props
        let commonFormikProps = {
            onChange: (e) => {handleChange(e); formDataChanged(e);},
            onBlur: handleBlur,
            errors,
            touched
        }

        let commonTextFieldProps = {
            fullWidth: true,
            InputLabelProps: {shrink: true, className: "h5 mb-5 text-muted"}
        }

        return (
            <form method="POST" onSubmit={handleSubmit}>
            <div className="border border-secondary p-3 mb-4">
            <div className="mt-1"><BankFirstNameField t={t} value={values.bankFirstName} {...commonFormikProps} {...commonTextFieldProps} /></div>
            <div className="mt-3"><BankLastNameField t={t} value={values.bankLastName} {...commonFormikProps} {...commonTextFieldProps} /></div>
            <div className="mt-3"><BankPhoneNumberField t={t} value={values.bankPhoneNumber} {...commonFormikProps} {...commonTextFieldProps} /></div>
            <div className="mt-3"><BankEmailAddressField t={t} value={values.bankEmailAddress} {...commonFormikProps} {...commonTextFieldProps} /></div>
            
            <div className="mt-3 position-relative">
                <BankRoutingNumberField t={t} value={values.bankRoutingNumber} {...commonFormikProps} {...commonTextFieldProps}
                    endAdornment={
                        <BankRoutingNumberAccountNumberInfoIcon
                            showRoutingNumberDescription={true}
                            showBankAccountNumberDescription={false}
                            className="d-lg-none" />
                    }
                />
            </div>

            <div className="mt-3 position-relative">
                <BankAccountNumberField t={t} value={values.bankAccountNumberLeadingZeroesAreSignificant} {...commonFormikProps} {...commonTextFieldProps}
                    endAdornment={
                        <BankRoutingNumberAccountNumberInfoIcon
                            showRoutingNumberDescription={false}
                            showBankAccountNumberDescription={true}
                            className="d-lg-none" />
                    }
                />
            </div>

            <div className="d-flex justify-content-end flex-wrap mt-4">
                <button className="btn srp-btn btn-lightblue mr-2" type="button" onClick={() => handleBankFormClose()} disabled={verifyingPaymentList}>{t("Cancel")}</button>
                <button className="btn srp-btn btn-blue" type="submit" disabled={verifyingPaymentList}>{t("Update")}</button>
            </div>
            </div>
            </form>
        )
    }
}

BankInfoForm.propTypes = {
    values: PropTypes.shape({
        bankFirstName: PropTypes.string.isRequired,
        bankLastName: PropTypes.string.isRequired,
        bankPhoneNumber: PropTypes.string.isRequired,
        bankEmailAddress: PropTypes.string.isRequired,
        bankRoutingNumber: PropTypes.string.isRequired,
        bankAccountNumberLeadingZeroesAreSignificant: PropTypes.string.isRequired
    }).isRequired,
    errors: PropTypes.shape({
        bankFirstName: PropTypes.string,
        bankLastName: PropTypes.string,
        bankPhoneNumber: PropTypes.string,
        bankEmailAddress: PropTypes.string,
        bankRoutingNumber: PropTypes.string,
        bankAccountNumberLeadingZeroesAreSignificant: PropTypes.string
    }).isRequired,
    touched: PropTypes.shape({
        bankFirstName: PropTypes.bool,
        bankLastName: PropTypes.bool,
        bankPhoneNumber: PropTypes.bool,
        bankEmailAddress: PropTypes.bool,
        bankRoutingNumber: PropTypes.bool,
        bankAccountNumberLeadingZeroesAreSignificant: PropTypes.bool
    }).isRequired,
    handleChange: PropTypes.func.isRequired,
    handleBlur: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    validateForm: PropTypes.func.isRequired,
    submitForm: PropTypes.func.isRequired,
    handleBankFormClose: PropTypes.func.isRequired,
    verifyingPaymentList: PropTypes.bool.isRequired,
    formDataChanged: PropTypes.func.isRequired,
    t:  PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired,
}

export default BankInfoForm