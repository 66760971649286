import React from "react"
import PropTypes from 'prop-types'

import Card from "@mui/material/Card"

import config from 'my-account-config'

const OtherPaymentOptions = ({t, i18n}) => {
    const isSpanish = (i18n.language === 'es')
    const appPathWithoutTrailingSlash = config.appPath.endsWith('/') ? config.appPath.slice(0, -1) : config.appPath
    const setupAutoPayUrl = isSpanish ? `${config.srpnetBaseUrl}es/account/electric/manage-bill/pay-electric-bill`
                                      : `${appPathWithoutTrailingSlash}/myaccount/payment/autopay`
    const morePaymentMethodsUrl = isSpanish ? `${config.srpnetBaseUrl}es/account/electric/manage-bill/pay-electric-bill`
                                            : `${config.srpnetBaseUrl}account/electric/manage-bill/pay-electric-bill`

    return (
        <div>
            <label className="h5">{t("Other payment options")}</label>
            <Card className="px-1 py-4">
                <ul className="mb-0">
                    <li>
                        <a href={setupAutoPayUrl}>{t("Set up automatic payment with SurePay")}</a>
                    </li>
                    <li>
                        <a href={appPathWithoutTrailingSlash + "/myaccount/paymentlocations"}>{t("Find an In-person payment location")}</a>
                    </li>
                    <li>
                        <a href={morePaymentMethodsUrl}>{t("More payment methods")}</a>
                    </li>
                </ul>
            </Card>
        </div>
    )
}

OtherPaymentOptions.propTypes = {
    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired,
}

export default OtherPaymentOptions