export const guestPaymentListConstants = {
    ADD_GUEST_PAYMENT_TO_LIST: 'ADD_GUEST_PAYMENT_TO_LIST',
    STORE_GUEST_PAYMENT_LIST: 'STORE_GUEST_PAYMENT_LIST',
    CLEAR_GUEST_PAYMENT_LIST: 'CLEAR_GUEST_PAYMENT_LIST',

    SET_FORM_START_EVENT_EMITTED: 'SET_GUEST_FORM_START_EVENT_EMITTED',
    CLEAR_FORM_START_EVENT_EMITTED: 'CLEAR_GUEST_FORM_START_EVENT_EMITTED',

    ENABLE_MULTIPLE_GUEST_PAYMENTS: 'ENABLE_MULTIPLE_GUEST_PAYMENTS',
    DISABLE_MULTIPLE_GUEST_PAYMENTS: 'DISABLE_MULTIPLE_GUEST_PAYMENTS'
}