import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import DocumentCenterCard from './document_center/document-center-card'
import CardLoading from '../../components/dashboard_page/card-loading'
import * as loadingStatus from '../../constants/loading-status-constants'
import {
    getRateCode
} from '../../actions/auth/bill_account/bill-account-actions'
import {
    getUsageHistoryLink,
    refreshUsageHistoryLink,
    getEnergyScoreCardLinks,
    getEnergyScoreCardFAQLink,
    getDaytimeSaverPilotLinks,
    refreshDaytimeSaverPilotLinks,
    getCommunitySolarLink,
    refreshCommunitySolarLink,
    getItemizedStatementLink,
    refreshItemizedStatementLink,
    requestItemizedHistory,
    getCreditHistoryLink,
    refreshCreditHistoryLink
} from '../../actions/auth/document/document-center-actions'
import { getContacts } from '../../actions/auth/contact/contact-actions'
import { withTranslation } from 'react-i18next'

let isRefreshing = false

const loaded = (props) => {
    let loaded = true;

    let loadedCommSolar = (props.communitySolarLinkResultStatus === loadingStatus.LOADING_STATUS_FAILURE ||
        props.communitySolarLinkResultStatus === loadingStatus.LOADING_STATUS_SUCCESS)

    let loadedScroreCard = (props.energyScoreCardLinksResultStatus === loadingStatus.LOADING_STATUS_FAILURE ||
        props.energyScoreCardLinksResultStatus === loadingStatus.LOADING_STATUS_SUCCESS)

    let loadedScroreCardFaq = (props.energyScoreCardFAQLinkResultStatus === loadingStatus.LOADING_STATUS_FAILURE ||
        props.energyScoreCardFAQLinkResultStatus === loadingStatus.LOADING_STATUS_SUCCESS)

    let loadedDaytimeSaverPilot = false
    if (props.rateCode !== 0 && props.rateCode !== 8) {
        loadedDaytimeSaverPilot = true
    }
    else {
        loadedDaytimeSaverPilot = (props.daytimeSaverPilotResultStatus === loadingStatus.LOADING_STATUS_FAILURE ||
            props.daytimeSaverPilotResultStatus === loadingStatus.LOADING_STATUS_SUCCESS)
    }

    let loadedService = (props.creditHistoryLinkResultStatus === loadingStatus.LOADING_STATUS_FAILURE ||
        props.creditHistoryLinkResultStatus === loadingStatus.LOADING_STATUS_SUCCESS)

    let loadedUsageHistory = (props.usageHistoryLinkResultStatus === loadingStatus.LOADING_STATUS_FAILURE ||
        props.usageHistoryLinkResultStatus === loadingStatus.LOADING_STATUS_SUCCESS)

    if (props.isMPower === true) {
        let loadedItemized = (props.itemizedStatementLinkResultStatus === loadingStatus.LOADING_STATUS_SUCCESS ||
            props.itemizedStatementLinkResultStatus === loadingStatus.LOADING_STATUS_FAILURE)

        loaded = loaded && loadedItemized
    }

    loaded = loaded && loadedCommSolar && loadedScroreCard && loadedScroreCardFaq
        && loadedDaytimeSaverPilot && loadedService && loadedUsageHistory

    return loaded
}


let updateDocumentDataIfNeeded = (props) => {
    if (props.selectedBillAccount === 0) {
        return
    }
    if (props.currentRateCodeStatus === loadingStatus.LOADING_STATUS_INIT) {
        props.getCurrentRateCode(props.selectedBillAccount)
        return
    }

    if (props.energyScoreCardLinksResultStatus === loadingStatus.LOADING_STATUS_INIT) {
        props.getEnergyScoreCardLinksRequest(props.selectedBillAccount)
    }

    if (props.energyScoreCardFAQLinkResultStatus === loadingStatus.LOADING_STATUS_INIT) {
        props.getEnergyScoreCardFAQLinksRequest()
    }

    if (props.rateCode === 8 && props.daytimeSaverPilotResultStatus === loadingStatus.LOADING_STATUS_INIT) {
        props.getDaytimeSaverPilotLinksRequest(props.selectedBillAccount)
    }

    if (props.communitySolarLinkResultStatus === loadingStatus.LOADING_STATUS_INIT) {
        props.getCommunitySolarLinksRequest(props.selectedBillAccount)
    }

    if (props.usageHistoryLinkResultStatus === loadingStatus.LOADING_STATUS_INIT) {
        props.getUsageHistoryLinkRequest(props.selectedBillAccount, props.isMPower)
    }

    if (props.creditHistoryLinkResultStatus === loadingStatus.LOADING_STATUS_INIT) {
        props.getCreditHistoryLinkRequest(props.selectedBillAccount)
    }

    if (props.isMPower === true) {
        if (props.purchaseHistoryLinkResultStatus === loadingStatus.LOADING_STATUS_INIT) {
            props.getPurchaseHistoryLinkRequest(props.selectedBillAccount)
        }

        if (props.itemizedStatementLinkResultStatus === loadingStatus.LOADING_STATUS_INIT) {
            props.getItemizedStatementLinkRequest(props.selectedBillAccount)
        }
    }

    if (props.primaryEmailStatus === loadingStatus.LOADING_STATUS_INIT) {
        props.getContactsInfo(props.selectedBillAccount)
    }
}


class DocumentCenterContainer extends React.Component {

    constructor(props) {
        super(props)
        this.refreshFailedLinks = this.refreshFailedLinks.bind(this)
    }

    componentDidMount() {
        updateDocumentDataIfNeeded(this.props)
    }

    componentDidUpdate(prevProps) {
        if (!isRefreshing && (prevProps.selectedBillAccount === this.props.selectedBillAccount
            && prevProps.rateCode === this.props.rateCode))
            return

        updateDocumentDataIfNeeded(this.props)
    }

    refreshFailedLinks() {
        isRefreshing = true

        if (this.props.daytimeSaverPilotResultStatus === loadingStatus.LOADING_STATUS_FAILURE) {
            this.props.refreshDaytimeSaverPilotLinksRequest()
        }

        if (this.props.communitySolarLinkResultStatus === loadingStatus.LOADING_STATUS_FAILURE) {
            this.props.refreshCommunitySolarLinksRequest()
        }

        if (this.props.usageHistoryLinkResultStatus === loadingStatus.LOADING_STATUS_FAILURE) {
            this.props.refreshUsageHistoryLinkRequest()
        }

        if (this.props.creditHistoryLinkResultStatus === loadingStatus.LOADING_STATUS_FAILURE) {
            this.props.refreshCreditHistoryLinkRequest()
        }

        if (this.props.isMPower === true) {
            if (this.props.itemizedStatementLinkResultStatus === loadingStatus.LOADING_STATUS_FAILURE) {
                this.props.refreshItemizedStatementLinkRequest()
            }
        }
    }

    render() {
        if (loaded(this.props)
            && (this.props.rateMetaDataStatus === loadingStatus.LOADING_STATUS_SUCCESS
                || this.props.rateMetaDataStatus === loadingStatus.LOADING_STATUS_FAILURE)) {
            return (
                <div>
                    <DocumentCenterCard
                        isMPower={this.props.isMPower}
                        isCommercial={this.props.isCommercial}

                        itemizedStatementLink={this.props.itemizedStatementLink}
                        itemizedStatementLinkResultStatus={this.props.itemizedStatementLinkResultStatus}
                        requestItemizedHistoryOnClick={this.props.requestItemizedHistoryOnClick}
                        itemizedHistoryRequestResultStatus={this.props.itemizedHistoryRequestResultStatus}

                        creditHistoryLink={this.props.creditHistoryLink}
                        creditHistoryLinkResultStatus={this.props.creditHistoryLinkResultStatus}

                        usageHistoryLink={this.props.usageHistoryLink}
                        usageHistoryLinkResultStatus={this.props.usageHistoryLinkResultStatus}

                        energyScoreCardFAQLink={this.props.energyScoreCardFAQLink}
                        energyScoreCardLinks={this.props.energyScoreCardLinks}

                        daytimeSaverPilotLinks={this.props.daytimeSaverPilotLinks}
                        daytimeSaverPilotResultStatus={this.props.daytimeSaverPilotResultStatus}

                        communitySolarLink={this.props.communitySolarLink}

                        refreshFailedLinks={this.refreshFailedLinks}

                        primaryEmail={this.props.customerEmailAddress}
                        t={this.props.t}
                    />
                </div>)
        }
        else {
            return (
                <div className="col-lg-6 col-12">
                    <CardLoading title={this.props.t("Document Center")} />
                </div>)
        }
    }
}

DocumentCenterContainer.propTypes = {
    rateCode: PropTypes.number,
    currentRateCodeStatus: PropTypes.string,
    selectedBillAccount: PropTypes.number.isRequired,
    isMPower: PropTypes.bool.isRequired,
    isCommercial: PropTypes.bool.isRequired,

    usageHistoryLink: PropTypes.object,
    usageHistoryLinkResultStatus: PropTypes.string,
    refreshUsageHistoryLinkRequest: PropTypes.func,

    creditHistoryLink: PropTypes.object,
    creditHistoryLinkResultStatus: PropTypes.string,
    refreshCreditHistoryLinkRequest: PropTypes.func,

    itemizedStatementLink: PropTypes.object,
    itemizedStatementLinkResultStatus: PropTypes.string,
    requestItemizedHistoryOnClick: PropTypes.func,
    itemizedHistoryRequestResultStatus: PropTypes.string,
    refreshItemizedStatementLinkRequest: PropTypes.func,

    energyScoreCardLinks: PropTypes.array,
    energyScoreCardLinksResultStatus: PropTypes.string,

    energyScoreCardFAQLink: PropTypes.object,
    energyScoreCardFAQLinkResultStatus: PropTypes.string,

    daytimeSaverPilotLinks: PropTypes.object,
    daytimeSaverPilotResultStatus: PropTypes.string,
    refreshDaytimeSaverPilotLinksRequest: PropTypes.func,

    communitySolarLink: PropTypes.object,
    communitySolarLinkResultStatus: PropTypes.string,
    refreshCommunitySolarLinksRequest: PropTypes.func,

    rateMetaDataStatus: PropTypes.string,
    primaryEmail: PropTypes.string,

    customerEmailAddress: PropTypes.string,
    primaryEmailStatus: PropTypes.string,
    t: PropTypes.func
}

const mapStateToProps = state => {
    return {
        selectedBillAccount: state.accountInfo.billAccount.selectedBillAccount,
        rateCode: state.accountInfo.billAccount.currentRateCode.rateCode,
        currentRateCodeStatus: state.accountInfo.billAccount.currentRateCodeStatus,
        isMPower: state.accountInfo.billAccount.selectedBillAccountDetails.isPrePay,
        isCommercial: state.accountInfo.billAccount.selectedBillAccountDetails.isCommercial,
        ...state.accountInfo.documentCenter,
        ...state.rateMetaData,
        customerEmailAddress: state.accountInfo.contact.primaryEmail,
        primaryEmailStatus: state.accountInfo.contact.primaryEmailStatus,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getUsageHistoryLinkRequest: (billAccount, isMPower) => {
            dispatch(getUsageHistoryLink(billAccount, isMPower))
        },
        refreshUsageHistoryLinkRequest: () => {
            dispatch(refreshUsageHistoryLink())
        },

        getCreditHistoryLinkRequest: (billAccount) => {
            dispatch(getCreditHistoryLink(billAccount))
        },
        refreshCreditHistoryLinkRequest: () => {
            dispatch(refreshCreditHistoryLink())
        },

        getItemizedStatementLinkRequest: (billAccount) => {
            dispatch(getItemizedStatementLink(billAccount))
        },
        refreshItemizedStatementLinkRequest: () => {
            dispatch(refreshItemizedStatementLink())
        },
        requestItemizedHistoryOnClick: (url) => {
            dispatch(requestItemizedHistory(url))
        },

        getEnergyScoreCardLinksRequest: (billAccount) => {
            dispatch(getEnergyScoreCardLinks(billAccount))
        },

        getCurrentRateCode: (billAccount) => {
            dispatch(getRateCode(billAccount))
        },
        getDaytimeSaverPilotLinksRequest: (billAccount) => {
            dispatch(getDaytimeSaverPilotLinks(billAccount))
        },
        refreshDaytimeSaverPilotLinksRequest: () => {
            dispatch(refreshDaytimeSaverPilotLinks())
        },

        getEnergyScoreCardFAQLinksRequest: () => {
            dispatch(getEnergyScoreCardFAQLink())
        },

        getCommunitySolarLinksRequest: (billAccount) => {
            dispatch(getCommunitySolarLink(billAccount))
        },
        refreshCommunitySolarLinksRequest: () => {
            dispatch(refreshCommunitySolarLink())
        },
        getContactsInfo: (billAccount) => {
            dispatch(getContacts(billAccount))
        },
    }
}

export default withTranslation("documentCenter")(connect(mapStateToProps, mapDispatchToProps)(DocumentCenterContainer))