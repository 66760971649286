import React from 'react'
import PropTypes from 'prop-types'
import TextField from '@mui/material/TextField'
import { PatternFormat } from 'react-number-format'

const BankPhoneNumberField = ({t, ...props}) => {
    let isError = props.touched['bankPhoneNumber']
        && Object.prototype.hasOwnProperty.call(props.errors, 'bankPhoneNumber')
    let errMsg = isError ? props.errors['bankPhoneNumber'] : ''
    let meta = {
        id: 'bankPhoneNumber',
        name: 'bankPhoneNumber',
        label: t("Phone number"),
        helperText: errMsg==="" ? "" : t(errMsg),
        error: isError
    }

    let textFieldProps = {
        value: !props.value ? '' : props.value,
        onChange: props.onChange,
        onBlur: props.onBlur,
        errors: props.errors,
        touched: props.touched,
        fullWidth: props.fullWidth
    }

    return (
        <PatternFormat
            {...meta}
            {...textFieldProps}
            valueIsNumericString={false}
            format="(###) ###-####"
            type="tel"
            customInput={TextField}
        />
    )
}

BankPhoneNumberField.propTypes = {
    errors: PropTypes.shape({
        bankPhoneNumber: PropTypes.string
    }).isRequired,
    fullWidth: PropTypes.bool,
    onBlur: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    touched: PropTypes.shape({
        bankPhoneNumber: PropTypes.bool
    }).isRequired,
    value: PropTypes.object.isRequired
}

export default BankPhoneNumberField