/*eslint no-unused-vars: "warn"*/
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import * as loadingStatus from '../../constants/loading-status-constants'
import { getAutopayInfo } from '../../actions/auth/payment/autopay-actions'
import { getServiceAddress } from '../../actions/auth/bill_account/bill-account-actions'
import { addSnackbarMessage } from '../../actions/common/snackbar'
import EditAutopayPage from './edit-autopay-page'
import AutopayLoadingPage from '../common_autopay/autopay-loading-page'

class EditAutopayPageContainer extends React.Component {
    constructor(props) {
        super(props)
    }

    componentDidMount(){
        this.updateDataIfNeeded(this.props)
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.updateDataIfNeeded(nextProps)
    }

    hasLoaded(props){
        let statusList = [loadingStatus.LOADING_STATUS_INIT, loadingStatus.LOADING_STATUS_IN_PROGRESS]

        return (statusList.indexOf(props.autopayInfoStatus) === -1 &&
            statusList.indexOf(props.serviceAddressStatus) === -1)
    }

    updateDataIfNeeded(props){
        if (props.selectedBillAccount === 0) {
            return
        }

        if (props.autopayInfoStatus === loadingStatus.LOADING_STATUS_INIT) {
            props.actions.getAutopayInfo(props.selectedBillAccount)
        }

        if (props.serviceAddressStatus === loadingStatus.LOADING_STATUS_INIT) {
            props.actions.getServiceAddress(props.selectedBillAccount)
        }
    }

    render() {
        if(!this.hasLoaded(this.props)) {
            return (
                <AutopayLoadingPage
                    billAccount={this.props.selectedBillAccount}
                    isResidential={this.props.isResidential}
                />)
        }

        return (
            <EditAutopayPage
                autopayInfo={this.props.autopayInfo}
                autopayInfoStatus={this.props.autopayInfoStatus}
                billAccount={this.props.selectedBillAccount}
                selectBillAccountStatus={this.props.selectBillAccountStatus}
                customerName={this.props.autopayInfo.isResidential ?
                    this.props.autopayInfo.customerInfo.firstName + " " + this.props.autopayInfo.customerInfo.lastName :
                    this.props.autopayInfo.businessInfo.businessName}
                serviceAddress={{
                    streetAddress: this.props.serviceAddress.streetAddress,
                    cityStateZip: this.props.serviceAddress.cityStateZip
                }}
                isResidential={this.props.autopayInfo.isResidential}
                isPendingRelease={this.props.autopayInfo.isPendingRelease}
                ignorePendingRelease={this.props.ignorePendingRelease}
            />)
    }
}

EditAutopayPageContainer.propTypes = {
    actions: PropTypes.object.isRequired,
    autopayInfo: PropTypes.object.isRequired,
    autopayInfoStatus: PropTypes.string.isRequired,
    selectedBillAccount: PropTypes.number.isRequired,
    serviceAddress: PropTypes.object.isRequired,
    selectBillAccountStatus: PropTypes.string,
    serviceAddressStatus: PropTypes.string.isRequired,
    ignorePendingRelease: PropTypes.bool.isRequired,
    isResidential: PropTypes.bool.isRequired
}

const mapStateToProps = (state) => {
    return {
        autopayInfo: state.accountInfo.autopay.autopayInfo,
        autopayInfoStatus: state.accountInfo.autopay.autopayInfoStatus,
        selectedBillAccount: state.accountInfo.billAccount.selectedBillAccount,
        selectBillAccountStatus: state.accountInfo.billAccount.selectBillAccountStatus,
        serviceAddress: state.accountInfo.billAccount.serviceAddress,
        serviceAddressStatus: state.accountInfo.billAccount.serviceAddressStatus,
        ignorePendingRelease: state.accountInfo.autopay.ignorePendingRelease,
        isResidential: !state.accountInfo.billAccount.selectedBillAccountDetails.isCommercial
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: {
            getAutopayInfo: async (billAccount) => {
                await dispatch(getAutopayInfo(billAccount))
            },
            getServiceAddress: async (billAccount) => {
                await dispatch(getServiceAddress(billAccount))
            },
            addSnackbarMessageOnDelete: (message) => {
                dispatch(addSnackbarMessage(message))
            }
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditAutopayPageContainer)
