import React from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'

import { clearPaymentList, clearBankInfo, disableMultiplePayments, setFormStartEventEmitted, clearFormStartEventEmitted, clearGuestPaymentSubmitted } from '../../../actions/guest/payment/guest-payment-actions'
import '../../../styles/payment-page.css'
import GuestPaymentPage from './guest-payment-page'

class GuestPaymentPageContainer extends React.Component {
    constructor(props) {
        super(props)
    }

    componentDidMount() {
        if (this.props.purchaseSubmitted)
            this.props.clearState()
    }

    render() {
        return (
            !this.props.purchaseSubmitted &&
            <GuestPaymentPage multiplePaymentsEnabled={this.props.multiplePaymentsEnabled}
                formStartEventEmitted={this.props.formStartEventEmitted}
                setFormStartEventEmitted={this.props.setFormStartEventEmitted} />
        )
    }
}

const mapStateToProps = state => ({
    multiplePaymentsEnabled: state.guestPaymentList.multiplePaymentsEnabled,
    formStartEventEmitted: state.guestPaymentList.formStartEventEmitted,
    purchaseSubmitted: state.guestPaymentApiResponse.paymentSubmitted
})

const mapDispatchToProps = (dispatch) => ({
    clearState: () => {
        dispatch(clearPaymentList())
        dispatch(clearBankInfo())
        dispatch(disableMultiplePayments())
        dispatch(clearFormStartEventEmitted())
        dispatch(clearGuestPaymentSubmitted())
    },
    setFormStartEventEmitted: () => {
        dispatch(setFormStartEventEmitted())
    }
})

GuestPaymentPageContainer.propTypes = {
    multiplePaymentsEnabled: PropTypes.bool.isRequired,
    formStartEventEmitted: PropTypes.bool.isRequired,
    setFormStartEventEmitted: PropTypes.func.isRequired,
    purchaseSubmitted: PropTypes.bool.isRequired,
    clearState: PropTypes.func.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(GuestPaymentPageContainer)