/* eslint react/no-did-mount-set-state: "off" */

import React from 'react'
import { connect } from 'react-redux'
import { PropTypes } from 'prop-types'
import CircularProgress from '@mui/material/CircularProgress'
import IconButton from '@mui/material/IconButton'
import TextField from '@mui/material/TextField'
import Collapse from '@mui/material/Collapse'
import { Formik } from 'formik'
import { updateBillAccountNickname } from '../../../actions/auth/bill_account/bill-account-actions'
import SummaryBillingSubAccount from './summary-billing-sub-account'
import SrpTooltip from '../../common_tooltip/srp-tooltip'

class SummaryBillingRow extends React.Component {
    constructor(props) {
        super(props)

        this.accountName = React.createRef()
        this.accountNumber = React.createRef()

        this.state = {
            showEditNickname: false,
            showSubAccounts: false,
            accountNameTooltip: false,
            accountNumberTooltip: false,
        }

        this.updateNickname = this.updateNickname.bind(this)
        this.toggleEditNickname = this.toggleEditNickname.bind(this)
        this.handleResize = this.handleResize.bind(this)
        window.addEventListener('resize', this.handleResize)
    }

    componentDidMount() {
        this.decideWhetherTooltipsShouldDisplay()
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize)
    }

    decideWhetherTooltipsShouldDisplay() {
        if (!this.accountName.current || !this.accountNumber.current)
            return

        let accountNameTooltip = this.accountName.current.scrollWidth > this.accountName.current.clientWidth
        let accountNumberTooltip = this.accountNumber.current.scrollWidth > this.accountNumber.current.clientWidth

        if (accountNameTooltip === this.state.accountNameTooltip && accountNumberTooltip === this.state.accountNumberTooltip)
            return

        this.setState({
            accountNameTooltip: accountNameTooltip,
            accountNumberTooltip: accountNumberTooltip
        })
    }

    handleResize() {
        this.decideWhetherTooltipsShouldDisplay()
    }

    toggleEditNickname(show) {
        this.setState({ showEditNickname: show })
    }

    async updateNickname(values, formikGoodies) {
        let updateResponse = await this.props.updateNickname(values)
        formikGoodies.setSubmitting(false)

        if (!updateResponse || updateResponse.error === true || updateResponse.payload.success === false) {
            formikGoodies.setStatus({ error: "We're sorry, there was an error. Please try again later." })
        }
        else {
            this.setState({ showEditNickname: false })
        }
    }

    render() {
        let { account, accountName, accountText, isFinalAccount } = this.props.billAccountItem
        let disableEditAndDelete = this.props.pendingDelete[`${account}`] ? true : false
        let headerClassName = "row d-flex pt-1 " + (this.state.showSubAccounts ? "summary-billing-parent-expanded" : "summary-billing-parent-collapsed")
        let accountNameDisplay = (accountName != null && accountName.length > 0) ? accountName : ''

        return (this.state.showEditNickname
            ? <Formik
                onSubmit={this.updateNickname}
                initialValues={{
                    billAccount: account,
                    nickname: accountNameDisplay
                }}>
                {({ values, status, handleBlur, handleChange, handleSubmit, isSubmitting }) => {
                    let formStatus = status || {}

                    return (
                        <form className="border-bottom p-2" onSubmit={handleSubmit}>
                            {formStatus.error ?
                                <div className="row mt-2 mb-2">
                                    <div className="col">
                                        <span className="srp-alert-error">{formStatus.error}</span>
                                    </div>
                                </div> : null}
                            <div className="row">
                                <div className="col">
                                    <TextField value={accountText} fullWidth={true} disabled={true} label={this.props.t("SRP account number")}/>
                                    <TextField
                                        autoFocus={true}
                                        name="nickname"
                                        value={values.nickname}
                                        fullWidth={true}
                                        onFocus={e => e.target.select()}
                                        onBlur={handleBlur}
                                        onChange={handleChange} 
                                        label={this.props.t("Nickname")}
                                    />
                                </div>
                                <div className="col align-self-end text-right text-nowrap pt-2">
                                    <button type="button" className="btn srp-btn btn-lightblue"
                                        disabled={isSubmitting}
                                        onClick={() => this.toggleEditNickname(false)}>
                                        {this.props.t("Cancel")}
                                    </button>
                                    <button type="submit" className="btn srp-btn btn-green ml-2" disabled={isSubmitting || values.nickname === accountNameDisplay}>
                                        {isSubmitting ? <CircularProgress size={20} thickness={5} color="secondary" /> : this.props.t("Update")}
                                    </button>
                                </div>
                            </div>
                        </form>
                    )
                }}
            </Formik>
            : <div className={headerClassName}>
                <div className="d-flex col-3 pr-0">
                    <div className="d-flex align-self-center text-truncate" style={{ color: isFinalAccount ? 'rgb(158,42,43)' : 'inherit' }}>
                        {
                            accountNameDisplay !== null && accountNameDisplay.length > 0
                                ? <div className="text-truncate">
                                    {this.state.accountNameTooltip ?
                                        <SrpTooltip
                                            content={<span>{accountNameDisplay}</span>}
                                        >
                                            <div className="text-truncate" ref={this.accountNameDisplay}>
                                                {accountNameDisplay}
                                            </div>
                                        </SrpTooltip> :
                                        <div className="text-truncate" ref={this.accountName}>{accountName}</div>}
                                </div>
                                : <div ref={this.accountName}>-&nbsp;-</div>
                        }
                    </div>
                    <div className="d-flex pl-2 align-self-center">
                        <div className="text-nowrap summary-account-parent-chip">
                            <div>{this.props.t("Master")}</div>
                        </div>
                    </div>
                </div>
                <div className="d-flex col-9 pr-2">
                    <div className="d-flex col-3 px-0 align-self-center text-overflow">
                        {this.state.accountNumberTooltip ?
                            <SrpTooltip
                                content={<span>{accountText}</span>}
                            >
                                <div ref={this.accountNumber}>
                                    {accountText}
                                </div>
                            </SrpTooltip> :
                            <div ref={this.accountNumber}>{accountText}</div>
                        }
                    </div>
                    <div className="d-flex col-6 px-0 align-self-center text-nowrap ml-3">
                        - -
                    </div>
                    <div className="d-flex text-right text-overflow associated-account-row-action-buttons">
                        <IconButton color="primary" size="large"
                            onClick={() => this.toggleEditNickname(true)}
                            disabled={disableEditAndDelete} >
                            <i className="material-icons">create</i>
                        </IconButton>
                        {
                            this.props.hideDelete ? null :
                                <IconButton disabled={disableEditAndDelete} color="primary" size="large"
                                    onClick={this.props.deleteBillAccountOnClick(account, accountName, true)}>
                                    {disableEditAndDelete ? <CircularProgress className="mr-1" size={20} thickness={5} /> : <i className="material-icons">delete</i>}
                                </IconButton>
                        }
                    </div>
                </div>
                {this.props.summaryBillAccount.subAccounts.length > 0 &&
                <div className="container">
                <Collapse
                    in={this.state.showSubAccounts}
                    collapsedSize="32px"
                    classes={{
                        root: "row"
                    }}
                >
                    {!this.state.showSubAccounts &&
                        <div className="ml-2 summary-account-expand"
                             onClick={() => this.setState({ showSubAccounts: !this.state.showSubAccounts })}
                        >
                            <IconButton
                                color="primary"
                                style={{padding: 0}}
                                size="large">
                            <i className="material-icons">keyboard_arrow_down</i>
                            </IconButton>
                            <span>{this.props.t("Show sub accounts")} ({this.props.summaryBillAccount.subAccounts.length})</span>
                        </div>
                    }
                    <div>
                    {this.props.summaryBillAccount.subAccounts.map((subAccount) => {
                        return (
                                <SummaryBillingSubAccount
                                    key={subAccount.billAccount}
                                    subAccount={subAccount}
                                    disableEditAndDelete={this.props.pendingDelete[`${subAccount.billAccount}`] ? true : false}
                                    deleteBillAccountOnClick={this.props.deleteBillAccountOnClick}
                                    addAccountToProfile={this.props.addAccountToProfile} />)
                        })}
                    </div>
                    {this.state.showSubAccounts &&
                        <div className="summary-account-expand expanded ml-2 pb-2"
                             onClick={() => this.setState({ showSubAccounts: !this.state.showSubAccounts })}
                        >
                            <IconButton
                                color="primary"
                                style={{padding: 0}}
                                size="large">
                            <i className="material-icons">keyboard_arrow_up</i>
                            </IconButton>
                            <span>{this.props.t("Hide sub accounts")} ({this.props.summaryBillAccount.subAccounts.length})</span>
                        </div>
                    }
                </Collapse>
                </div>
                }
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        updateNickname: async (values) => {
            return await dispatch(updateBillAccountNickname(values.billAccount, values.nickname))
        }
    }
}

SummaryBillingRow.propTypes = {
    billAccountItem: PropTypes.object.isRequired,
    summaryBillAccount: PropTypes.object.isRequired,
    deleteBillAccountOnClick: PropTypes.func.isRequired,
    updateNickname: PropTypes.func.isRequired,
    addAccountToProfile: PropTypes.func.isRequired,
    disableEditAndDelete: PropTypes.bool.isRequired,
    hideDelete: PropTypes.bool.isRequired,
    pendingDelete: PropTypes.object.isRequired
}

export default connect(null, mapDispatchToProps)(SummaryBillingRow)