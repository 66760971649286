import React from 'react'
import PropTypes from 'prop-types'

import format from '../../../../srp_modules/format'

const BankRoutingNumberandAccountNumber = ({bankRoutingNumber, bankAccountNumber, t}) => (
    <div>
    <label className="h4 text-muted">{t("Bank information")}</label>
    <table className="table mb-0">
        <tbody>
            <tr>
                <td className="border-top-0 pl-0 srp-fit">{t("Routing number")}</td>
                <td className="border-top-0">{bankRoutingNumber}</td>
            </tr>
            <tr>
                <td className="border-top-0 pt-0 pl-0">{t("Bank account")}</td>
                <td className="border-top-0 pt-0">{format.maskBankAccountNumber(bankAccountNumber)}</td>
            </tr>
        </tbody>
    </table>
    </div>
)

BankRoutingNumberandAccountNumber.propTypes = {
    bankRoutingNumber: PropTypes.string.isRequired,
    bankAccountNumber: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired,
}

export default BankRoutingNumberandAccountNumber