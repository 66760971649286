import React from 'react'
import PropTypes from 'prop-types'
import CircularProgress from '@mui/material/CircularProgress'

import { withTranslation, Trans } from 'react-i18next'

import TextInput from '../common_formik/text-input'
import BankAccountNumberInput from '../common_formik/bank-account-number-input'
import BankRoutingNumberAccountNumberInfoIcon from '../common_payment/bank-routing-number-account-number-info-icon'
import EmailInput from '../common_formik/email-input'
import PhoneNumberInput from './payment_fields/phone-number-input'
import RoutingNumberInput from '../common_formik/routing-number-input'
import SelectorInput from '../common_formik/selector-input'

import { myAccountConstants } from '../../constants/myaccount-constants'
import { autopayConstants } from '../../constants/autopay-constants'
import * as loadingStatus from '../../constants/loading-status-constants'

class EditAutopayForm extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {
        const t = this.props.t
        const i18n = this.props.i18n

        let commonFormikProps = {
            onChange: this.props.handleChange,
            onBlur: this.props.handleBlur,
            errors: this.props.errors,
            touched: this.props.touched
        }

        let alternateFormikProps = {
            errors: this.props.errors,
            touched: this.props.touched
        }

        let verifyStatus = this.props.status || {}

        let onBankPhoneNumberChange = values => {
            let value = values.value
            this.props.setFieldValue('bankPhoneNumber', value)
        }

        let onBankAccountTypeChange = values => {
            let value = Math.trunc(values.target.value)
            this.props.setFieldValue('bankAccountType', value, true)
        }

        let servicePhoneNumber = myAccountConstants.RESIDENTIAL_CUSTOMER_SERVICE_PHONE_NUMBER
        let supportPhoneNumberFormatted = myAccountConstants.RESIDENTIAL_CUSTOMER_SERVICE_PHONE_NUMBER_FMTTD
        if (!this.props.autopayInfo.isResidential) {
            servicePhoneNumber = myAccountConstants.COMMERCIAL_CUSTOMER_SERVICE_PHONE_NUMBER
            supportPhoneNumberFormatted = myAccountConstants.COMMERCIAL_CUSTOMER_SERVICE_PHONE_NUMBER_FMTTD
        } else  if (this.props.i18n.language === "es") {
            servicePhoneNumber = myAccountConstants.SPANISH_CUSTOMER_SERVICE_PHONE_NUMBER
            supportPhoneNumberFormatted = myAccountConstants.SPANISH_CUSTOMER_SERVICE_PHONE_NUMBER_FMTTD
        }

        return (
            <form onSubmit={this.props.handleSubmit} id="topOfEditSurepayForm">
                {verifyStatus.hasUnhandledBankError &&
                    <div className="srp-alert-error mt-3 ">
                        <span className="d-lg-block d-none">
                            {t("unable_to_enroll", {servicePhoneNumber: supportPhoneNumberFormatted})}
                        </span>
                        <span className="d-lg-none d-block">
                            <Trans i18nKey="unable_to_enroll_phone_number_link" t={t}>
                            We are unable to enroll your account in SurePay at this time. Please try again or contact us at
                            <a href={"tel:" + servicePhoneNumber}>{{servicePhoneNumber: supportPhoneNumberFormatted}}</a>.
                            </Trans>
                        </span>
                    </div>
                }
                {verifyStatus.matchesExistingAutopayError &&
                    <div className="srp-alert-error mt-3">{t("already_enrolled_in_SurePay")}</div>
                }
                {verifyStatus.hasPendingAutopayError &&
                    <div className="srp-alert-error mt-3">
                        <span className="d-lg-block d-none">
                            {t("pending_SurePay_enrollment", {servicePhoneNumber: supportPhoneNumberFormatted})}
                        </span>
                        <span className="d-lg-none d-block">
                            <Trans i18nKey="pending_SurePay_enrollment_phone_number_link" t={t}>
                            An account with the details you provided is currently pending SurePay enrollment.
                            This process usually takes 2 business days to complete. If you have questions,
                            please call SRP Customer Service at
                            <a href={"tel:" + servicePhoneNumber}>{{servicePhoneNumber: supportPhoneNumberFormatted}}</a>.
                            </Trans>
                        </span>
                    </div>
                }
                <label className="h4 text-muted pt-2">{t("Bank information")}</label>
                <div className="mt-1"><TextInput id="bankFirstName" label="First name" value={this.props.values.bankFirstName} {...commonFormikProps} t={t} /></div>
                <div className="mt-3"><TextInput id="bankLastName" label="Last name" value={this.props.values.bankLastName} {...commonFormikProps} t={t} /></div>
                <div className="mt-3">
                    <PhoneNumberInput
                        id="bankPhoneNumber"
                        label="Phone number"
                        value={this.props.values.bankPhoneNumber}
                        onValueChange={onBankPhoneNumberChange}
                        onBlur={() => this.props.setFieldTouched("bankPhoneNumber", true)}
                        t={t}
                        {...alternateFormikProps}
                    />
                </div>
                <div className="mt-3"><EmailInput id="bankEmailAddress" label="Email address" value={this.props.values.bankEmailAddress} t={t} {...commonFormikProps} /></div>
                <div className="mt-3">
                    <SelectorInput
                        id="bankAccountType"
                        label={t("Account type")}
                        value={this.props.values.bankAccountType.toString()}
                        optionList={[
                            {
                                value: autopayConstants.BANK_ACCOUNT_CHECKING.toString(),
                                text: t("Checking")
                            },
                            {
                                value: autopayConstants.BANK_ACCOUNT_SAVINGS.toString(),
                                text: t("Savings")
                            }
                        ]}
                        onChange={onBankAccountTypeChange}
                        onBlur={() => this.props.setFieldTouched("bankAccountType", true)}
                        displayEmpty={false}
                        {...alternateFormikProps}
                    />
                </div>
                <div className="mt-3 position-relative">
                    <RoutingNumberInput
                        id="bankRoutingNumber"
                        label="9 digit routing number"
                        value={this.props.values.bankRoutingNumber}
                        endAdornment={
                            <BankRoutingNumberAccountNumberInfoIcon
                                showRoutingNumberDescription={true}
                                showBankAccountNumberDescription={false}
                                className="d-lg-none" />
                        }
                        t={t}
                        {...commonFormikProps} />
                </div>
                <div className="mt-3 position-relative">
                    <BankAccountNumberInput
                        id="bankAccountNumberLeadingZeroesAreSignificant"
                        label="Bank account number"
                        value={this.props.values.bankAccountNumberLeadingZeroesAreSignificant}
                        endAdornment={
                            <BankRoutingNumberAccountNumberInfoIcon
                                showRoutingNumberDescription={false}
                                showBankAccountNumberDescription={true}
                                className="d-lg-none" />
                        }
                        t={t}
                        {...commonFormikProps} />
                </div>
                <input id="srpAccountNumber" name="srpAccountNumber" type="hidden" value={this.props.values.srpAccountNumber} {...alternateFormikProps} />
                <div className="d-flex flex-wrap justify-content-end mt-4">
                    <a className="btn srp-btn btn-lightblue" onClick={() => this.props.actions.cancelClick()}>{t("Cancel")}</a>
                    <button className="btn srp-btn btn-blue ml-2" type="submit" disabled={this.props.bankDataStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS}>
                        {this.props.bankDataStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS
                            ? <CircularProgress size={20} thickness={5} style={{ color: 'white' }} />
                            : t("Next")
                        }
                    </button>
                </div>
            </form>
        )
    }
}

EditAutopayForm.propTypes = {
    actions: PropTypes.object.isRequired,
    autopayInfo: PropTypes.object.isRequired,
    autopayFormData: PropTypes.object.isRequired,
    bankDataStatus: PropTypes.string.isRequired,
    showSpanish: PropTypes.bool,
    loginEmail: PropTypes.string.isRequired,

    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired,

    // Formik props
    errors: PropTypes.object,
    handleBlur: PropTypes.func,
    handleChange: PropTypes.func,
    handleSubmit: PropTypes.func,
    setFieldTouched: PropTypes.func,
    setFieldValue: PropTypes.func,
    status: PropTypes.object,
    touched: PropTypes.object,
    values: PropTypes.object
}

export default withTranslation("addUpdateSurePay")(EditAutopayForm)
