import React from 'react'
import PropTypes from 'prop-types'

import format from '../../../srp_modules/format'

import { DateTime } from 'luxon'

const SinglePaymentDetailsTable = ({paymentList, bankName, truncatedBankAccountNumber, referenceNumber, t}) => (
    <div>
        {(referenceNumber || referenceNumber===0) &&
        <div>
        <h5 className="text-muted srp-olive-green mb-0 pl-3 pt-3 d-lg-none">{t("Reference number")}: {referenceNumber}</h5>
        <h4 className="text-muted srp-olive-green mb-0 pl-3 pt-3 d-none d-lg-block">{t("Reference number")}: {referenceNumber}</h4>
        </div>
        }

        <table className="table mb-0">
            <thead>
                <tr>
                    <th className="border-0 srp-fit" />
                    <th className="border-0" />
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td className="border-top-0 pl-3 srp-fit">{t("SRP account")}</td>
                    <td className="border-top-0">{format.formatBillAccountNumber(paymentList[0].srpAccountNumber)}</td>
                </tr>
                <tr>
                    <td className="pl-3 srp-fit">{t("Bank account")}</td>
                    <td>{`${bankName} (****${truncatedBankAccountNumber})`}</td>
                </tr>
                <tr>
                    <td className="pl-3 srp-fit">{t("Payment amount")}</td>
                    <td>{'$'+paymentList[0].paymentAmount.toFixed(2)}</td>
                </tr>
                <tr>
                    <td className="pl-3 srp-fit">{t("Payment date")}</td>
                    <td>{DateTime.now().toFormat('M/d/yyyy')}</td>
                </tr>
                <tr>
                    <td />
                    <td />
                </tr>
            </tbody>
        </table>
    </div>
)

SinglePaymentDetailsTable.propTypes = {
    t: PropTypes.func.isRequired,
    paymentList: PropTypes.arrayOf(PropTypes.shape({
        srpAccountNumber: PropTypes.string.isRequired,
        paymentAmount: PropTypes.number.isRequired,
    })),
    bankName: PropTypes.string.isRequired,
    truncatedBankAccountNumber: PropTypes.string.isRequired,
    referenceNumber: PropTypes.number
}

export default SinglePaymentDetailsTable