import React from 'react'
import PropTypes from 'prop-types'

import Icon from '@mui/material/Icon'
import { DateTime } from 'luxon'

import format from '../../../srp_modules/format'

const PaymentConfirmationDetailsTable = ({paymentList, bankName, truncatedBankAccountNumber, paymentAmountWarningText, paymentDateWarningText, t}) => (
    <table className="table">
        <tbody>
            <tr>
                <td className="border-top-0 pl-3 srp-fit">{t("SRP account")}</td>
                <td className="border-top-0">{format.formatBillAccountNumber(paymentList[0].srpAccountNumber)}</td>
            </tr>
            <tr>
                <td className="pl-3 srp-fit">{t("Bank account")}</td>
                <td>{`${bankName} (****${truncatedBankAccountNumber})`}</td>
            </tr>
            <tr>
                <td className="pl-3 srp-fit">{t("Payment amount")}</td>
                <td>
                    <span className="align-middle">{'$'+paymentList[0].paymentAmount.toFixed(2)}</span>
                    {paymentAmountWarningText && <Icon className="ml-3 align-middle payment-warning-color">error_outline</Icon>}
                    {paymentAmountWarningText && <div className="mt-2"><small className="payment-warning-color">{paymentAmountWarningText}</small></div>}
                </td>
            </tr>
            
            <tr>
                <td className="border-bottom pl-3 srp-fit">{t("Payment date")}</td>
                <td className="border-bottom">
                    <span className="align-middle">{DateTime.fromISO(paymentList[0].paymentDate).toFormat('M/d/yyyy')}</span>
                    {paymentDateWarningText && <Icon className="ml-3 align-middle payment-warning-color">error_outline</Icon>}
                    {paymentDateWarningText && <div className="mt-2"><small className="payment-warning-color">{paymentDateWarningText}</small></div>}
                </td>
            </tr>
        </tbody>
    </table>
)

PaymentConfirmationDetailsTable.propTypes = {
    paymentList: PropTypes.arrayOf(PropTypes.shape({
        srpAccountNumber: PropTypes.number.isRequired,
        paymentAmount: PropTypes.number.isRequired,
        shareAmount: PropTypes.number,
        paymentDate: PropTypes.string.isRequired
    })),
    bankName: PropTypes.string.isRequired,
    truncatedBankAccountNumber: PropTypes.string.isRequired,
    paymentAmountWarningText: PropTypes.string.isRequired,
    paymentDateWarningText: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired
}

export default PaymentConfirmationDetailsTable