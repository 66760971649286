import CircularProgress from '@mui/material/CircularProgress'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import * as loadingStatus from '../../../constants/loading-status-constants'
import { rateMetaDataConstants } from '../../../constants/rate-meta-data-constants'
import config from 'my-account-config'
class PricePlanContainer extends React.Component {
    render() {
        const isSpanish = (this.props.i18n.language === "es")

        let cashCardUrl = config.appPath !== '/' ? '..' + config.appPath + '/myaccount/cashpaymentcard' : '/myaccount/cashpaymentcard'
        if (this.props.myAccountType === 'water')
            return null

        if (this.props.currentRateCodeStatus === loadingStatus.LOADING_STATUS_FAILURE || this.props.currentRateCode.description === null
            || this.props.rateMetaDataStatus === loadingStatus.LOADING_STATUS_FAILURE) {
            return (
                <div>
                    <label className="mb-0 font-weight-bold">{this.props.t("Price plan")}</label>
                    <p className="form-control-static pt-0">{this.props.t("Current price plan is not available")}</p>
                </div>
            )
        }

        if (this.props.currentRateCodeStatus === loadingStatus.LOADING_STATUS_SUCCESS && this.props.currentRateCode.description.length > 0
            && this.props.rateMetaDataStatus === loadingStatus.LOADING_STATUS_SUCCESS) {

            let ratePlanUrl = ""
            if (isSpanish) {
                ratePlanUrl = this.props.currentRateCode.displayUrlEs.length !== 0
                    ? this.props.currentRateCode.displayUrlEs
                    : this.props.selectedBillAccountDetails.isCommercial
                        ? `${config.srpnetBaseUrl}price-plans/business-electric/overview` // no Spanish version, as of 10/24/2023
                        : `${config.srpnetBaseUrlEs}planes-de-precio/residencial-electrico/comparar-planes`
            } else {
                ratePlanUrl = this.props.currentRateCode.displayUrl.length !== 0
                    ? this.props.currentRateCode.displayUrl
                    : this.props.selectedBillAccountDetails.isCommercial
                        ? `${config.srpnetBaseUrl}price-plans/business-electric/overview`
                        : `${config.srpnetBaseUrl}price-plans/residential-electric/compare-plans`
            }

            return (
                this.props.rateMetaData.summaryBilling === rateMetaDataConstants.SUMMARY_BILLING_PARENT
                    ? null
                    : <div>
                        <div>
                            <label className="mb-0 font-weight-bold">{this.props.t("Price plan")}</label>
                            <p className="form-control-static pt-0">
                                <a
                                    className="text-primary-ada"
                                    target="_blank"
                                    href={ratePlanUrl}>
                                    {isSpanish ? this.props.currentRateCode.descriptionEs : this.props.currentRateCode.description}
                                </a>
                            </p>
                            {this.props.selectedBillAccountDetails.isCommercial && this.props.selectedBillAccountDetails.isBarcodeEligible
                            ? <div className="no-wrap">
                                <div style={{display:"inline-block"}}>
                                    <label className="mb-0 font-weight-bold">{this.props.t("In-person payments")}</label>
                                    <p className="form-control-static pt-0">
                                        <a className="text-primary-ada" target="_blank" href={cashCardUrl}>
                                            {this.props.t("My cash payment card")}
                                        </a>
                                    </p>
                                </div>
                                <div id="u101" className="srp-new-tag" style={{display:"inline-block"}}>
                                    <div id="u101_div" className="srp-new-tag-div">
                                    <div id="u101_text" className="srp-new-tag-text ">
                                        <p><span>{this.props.t("New")}</span></p>
                                    </div>
                                    </div>
                                </div>
                            </div>
                            : null
                            }
                        </div>
                    </div>
            )
        }

        return (
            this.props.rateMetaDataStatus === loadingStatus.LOADING_STATUS_INIT || this.props.rateMetaDataStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS
                ? null
                : <div>
                    <label className="mb-0 font-weight-bold">{this.props.t("Price plan")}</label>
                    <div><CircularProgress size={20} thickness={5} /></div>
                </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        ...state.accountInfo.billAccount,
        ...state.rateMetaData
    }
}

PricePlanContainer.propTypes = {
    selectedBillAccountDetails: PropTypes.object.isRequired,
    currentRateCode: PropTypes.object.isRequired,
    currentRateCodeStatus: PropTypes.string.isRequired,
    rateMetaData: PropTypes.object.isRequired,
    rateMetaDataStatus: PropTypes.string.isRequired,
    myAccountType: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired,
}

export default connect(mapStateToProps)(PricePlanContainer)