import {cleanPowerConstants} from '../../../constants/clean-power-constants'
import {RSAA} from 'redux-api-middleware'

import utils from '../../../srp_modules/utils'


export const getCalculatorURL = () => {
    let url = utils.createUrl('wattplan/calculatorurl')

    return dispatch => {
        return dispatch({
            [RSAA]: {
                endpoint: url,
                method: 'GET',
                types: [
                    cleanPowerConstants.CALCULATOR_URL_REQUEST,
                    cleanPowerConstants.CALCULATOR_URL_SUCCESS,
                    cleanPowerConstants.CALCULATOR_URL_FAILURE,
                ]
            }
        })
    }
}

export const getHas12MonthsBillingHistory = (billAccount) => {
    let url = utils.createUrl('wattplan/hasenoughhistory', { billAccount: billAccount })

    return dispatch => {
        return dispatch({
            [RSAA]: {
                endpoint: url,
                method: 'GET',
                credentials: 'include',
                types: [
                    cleanPowerConstants.HAS_ENOUGH_HISTORY_REQUEST,
                    cleanPowerConstants.HAS_ENOUGH_HISTORY_SUCCESS,
                    cleanPowerConstants.HAS_ENOUGH_HISTORY_FAILURE,
                ]
            }
        })
    }
}

export const sendUsageData = (wattPlanInfo) => dispatch => {
    let url = utils.createUrl('wattplan/sendusagedata')

    return dispatch({
        [RSAA]: {
            endpoint: url,
            body: JSON.stringify(wattPlanInfo),
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include',
            types: [
                cleanPowerConstants.SEND_USAGE_DATA_REQUEST,
                cleanPowerConstants.SEND_USAGE_DATA_SUCCESS,
                cleanPowerConstants.SEND_USAGE_DATA_FAILURE,
            ]
        }
    })
}