export const notificationConstants = {
    GET_NOTIFICATIONS_REQUEST: 'GET_NOTIFICATIONS_REQUEST',
    GET_NOTIFICATIONS_SUCCESS: 'GET_NOTIFICATIONS_SUCCESS',
    GET_NOTIFICATIONS_FAILURE: 'GET_NOTIFICATIONS_FAILURE',

    REFRESH_NOTIFICATIONS_REQUEST: 'REFRESH_NOTIFICATIONS_REQUEST',
    REFRESH_NOTIFICATIONS_SUCCESS: 'REFRESH_NOTIFICATIONS_SUCCESS',
    REFRESH_NOTIFICATIONS_FAILURE: 'REFRESH_NOTIFICATIONS_FAILURE',

    ADD_NOTIFICATION_REQUEST: 'ADD_NOTIFICATION_REQUEST',
    ADD_NOTIFICATION_SUCCESS: 'ADD_NOTIFICATION_SUCCESS',
    ADD_NOTIFICATION_FAILURE: 'ADD_NOTIFICATION_FAILURE',

    UPDATE_NOTIFICATION_REQUEST: 'UPDATE_NOTIFICATION_REQUEST',
    UPDATE_NOTIFICATION_SUCCESS: 'UPDATE_NOTIFICATION_SUCCESS',
    UPDATE_NOTIFICATION_FAILURE: 'UPDATE_NOTIFICATION_FAILURE',

    DELETE_NOTIFICATION_REQUEST: 'DELETE_NOTIFICATION_REQUEST',
    DELETE_NOTIFICATION_SUCCESS: 'DELETE_NOTIFICATION_SUCCESS',
    DELETE_NOTIFICATION_FAILURE: 'DELETE_NOTIFICATION_FAILURE',

    COPY_SUBSCRIPTIONS_TO_NEW_CONTACT_REQUEST: 'COPY_SUBSCRIPTIONS_TO_NEW_CONTACT_REQUEST',
    COPY_SUBSCRIPTIONS_TO_NEW_CONTACT_SUCCESS: 'COPY_SUBSCRIPTIONS_TO_NEW_CONTACT_SUCCESS',
    COPY_SUBSCRIPTIONS_TO_NEW_CONTACT_FAILURE: 'COPY_SUBSCRIPTIONS_TO_NEW_CONTACT_FAILURE',    

    GET_ALERTS_TAB: 'GET_ALERTS_TAB',

    NOTIFICATION_TYPE: {
        UNKNOWN: 0,
        BILLING_PAYMENT: 1,
        DISCONNECT: 6,
        BILL_PROJECTION: 16,
        MONTHLY_BILL_THRESHOLD: 17,
        DAILY_USAGE_THRESHOLD: 18,
        TIME_OF_USE: 8,
        POOL_TIMER: 9,
        OUTAGE: 41,
        HIGH_USAGE: 132,
        //mpower notifcation
        MPOWER_PURCHASE: 42,
        HEAT_MORATORIUM: 23,
        HEAT_MORATORIUM_ACCUMULATED_DEBT: 26,
        HEAT_MORATORIUM_END_WITH_ACCUMULATED_DEBT: 67,
        LOW_BALANCE: 21,
        // landlord turn on/turn off
        LANDLORD_ALERT: 38
    },

    ALERT_TYPE_GROUP: {
        UNKNOWN: 0,
        BILLING: 1,
        OUTAGES: 2,
        USAGE: 3,
        WATER: 4 
    },

    TOGGLE_EBILL_REQUEST: 'TOGGLE_EBILL_REQUEST',
    TOGGLE_EBILL_SUCCESS: 'TOGGLE_EBILL_SUCCESS',
    TOGGLE_EBILL_FAILURE: 'TOGGLE_EBILL_FAILURE',

    TOGGLE_OUTAGE_REQUEST: 'TOGGLE_OUTAGE_REQUEST',
    TOGGLE_OUTAGE_SUCCESS: 'TOGGLE_OUTAGE_SUCCESS',
    TOGGLE_OUTAGE_FAILURE: 'TOGGLE_OUTAGE_FAILURE',

    PAPERLESS_BILLLING_NOT_ENROLLED: 'PAPERLESS_BILLLING_NOT_ENROLLED',
    PAPERLESS_BILLLING_ENROLLING: 'PAPERLESS_BILLLING_ENROLLING',
    PAPERLESS_BILLLING_JUST_ENROLLED: 'PAPERLESS_BILLLING_JUST_ENROLLED',
}