import React from "react"
import PropTypes from 'prop-types'
import utils from '../../../srp_modules/utils'
import Icon from '@mui/material/Icon'

let baseStyle = {
    borderStyle: 'solid',
    borderRadius: '10px',
    borderWidth: '1px',
    height: '250px',
    paddingTop: '10px',
    paddingBottom: '10px',
    textAlign: 'center'
}

let currentBoxStyle = {
    ...baseStyle,
    borderColor: 'rgb(103,135,183)'
}

let regularBoxStyle={
    ...baseStyle,
    borderColor: 'rgba(215,215,215,1)'
}

let subTextStyle= {
    fontSize: '16px',
    color: 'rgb(112, 112, 112)'
}

let bubbleStyle= {
    color: '#ffffff',
    position: 'absolute',
    borderRadius: '16px',
    backgroundColor: 'rgb(103,135,183)',
    width: '82px',
    textAlign: 'center',
    left: '0',
    right: '0',
    margin: 'auto',
    top: '-15px',
    fontSize: '14px',
    lineHeight: '30px',
    display: 'inline-block'
}

class MyPricingRateCardResponsive extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {
        const t = this.props.t

        return (
            <div style={{position:"relative"}}>
                {
                    this.props.isCurrent &&
                    <div style={bubbleStyle}>
                        <b>{t("Current")}</b>
                    </div>
                }
                <div style={this.props.isCurrent ? currentBoxStyle : regularBoxStyle}>
                    <div className="clearfix" style={{marginLeft:"15px", marginRight:"15px", marginTop:"15px"}}>
                        <span style={{fontSize: "18px", fontWeight: "600", color: "rgb(0, 75, 135)", float: "left"}}>
                            {this.props.shortName.toUpperCase()}
                        </span>
                        <span className="srp-blue-color" style={{fontSize:"30px", fontWeight:"800", float: "right"}}>
                            {this.props.infoLink ? <a href={this.props.infoLink} target="_new">
                                <Icon className="rotate-180">error_outline</Icon>
                            </a> : <span>&nbsp;</span>}
                        </span>
                    </div>

                    <div className="clearfix" style={{marginLeft:"15px", marginRight:"15px", marginTop:"20px"}}>
                        <div style={{fontWeight:"600", float: "left"}}>
                            <span style={{fontSize:"22px"}}>$</span>
                            <span style={{fontSize:"22px"}}>{utils.delimitNumbers(this.props.monthlyAverage)}</span>
                        </div>
                        <div style={{...subTextStyle, float: "right"}}>
                            {t("monthly average")}
                        </div>
                    </div>

                    <div className="clearfix" style={{marginLeft:"15px", marginRight:"15px", marginTop:"20px"}}>
                        <div style={{float: "left"}}>
                            <b>
                                <span style={{fontSize:"18px"}}>$</span>
                                <span style={{fontSize:"18px"}}>{utils.delimitNumbers(this.props.annualProjection)}</span>
                            </b>
                        </div>
                        <div style={{...subTextStyle, float: "right"}}>
                            {t("annual projection")}
                        </div>
                    </div>

                    {
                        !this.props.isCurrent &&
                        <div style={{marginTop:"20px"}}>
                            <button name={`changePricePlan_${this.props.index}`} className="btn srp-btn btn-blue" style={{width: '90%'}} onClick={() => { this.props.selectPricePlanClick(this.props.businessOffering)}}>
                                {t("Change price plan")}
                            </button>
                        </div>
                    }
                </div>
            </div>
        )
    }
}

MyPricingRateCardResponsive.propTypes = {
    index: PropTypes.number.isRequired,
    businessOffering: PropTypes.number.isRequired,
    monthlyAverage: PropTypes.number.isRequired,
    annualProjection: PropTypes.number.isRequired,
    isCurrent: PropTypes.bool.isRequired,
    isRecommended: PropTypes.bool.isRequired,
    shortName: PropTypes.string.isRequired,
    infoLink: PropTypes.string.isRequired,
    selectPricePlanClick: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
}

export default MyPricingRateCardResponsive