export const autopayConstants = {
    // autopay_form_data_change
    AUTOPAY_FORM_DATA_CANCEL: 'AUTOPAY_FORM_DATA_CANCEL',
    AUTOPAY_FORM_DATA_CHANGE: 'AUTOPAY_FORM_DATA_CHANGE',

    BANK_ACCOUNT_CHECKING: 1,
    BANK_ACCOUNT_SAVINGS: 2,

    // cancel autopay
    CANCEL_AUTOPAY_REQUEST: 'CANCEL_AUTOPAY_REQUEST',
    CANCEL_AUTOPAY_SUCCESS: 'CANCEL_AUTOPAY_SUCCESS',
    CANCEL_AUTOPAY_FAILURE: 'CANCEL_AUTOPAY_FAILURE',
    SHOW_CACNEL_AUTOPAY_MODULE: 'SHOW_CACNEL_AUTOPAY_MODULE',
    CLOSE_CACNEL_AUTOPAY_MODULE: 'CLOSE_CACNEL_AUTOPAY_MODULE',

    // enroll autopay
    ENROLL_AUTOPAY_REQUEST: 'ENROLL_AUTOPAY_REQUEST',
    ENROLL_AUTOPAY_SUCCESS: 'ENROLL_AUTOPAY_SUCCESS',
    ENROLL_AUTOPAY_FAILURE: 'ENROLL_AUTOPAY_FAILURE',

    // get autopay info
    GET_AUTOPAY_INFO_REQUEST: 'GET_AUTOPAY_INFO_REQUEST',
    GET_AUTOPAY_INFO_SUCCESS: 'GET_AUTOPAY_INFO_SUCCESS',
    GET_AUTOPAY_INFO_FAILURE: 'GET_AUTOPAY_INFO_FAILURE',

    GET_AUTOPAY_INFO_LIST_REQUEST: 'GET_AUTOPAY_INFO_LIST_REQUEST',
    GET_AUTOPAY_INFO_LIST_SUCCESS: 'GET_AUTOPAY_INFO_LIST_SUCCESS',
    GET_AUTOPAY_INFO_LIST_FAILURE: 'GET_AUTOPAY_INFO_LIST_FAILURE',

    // validate bank account
    VALIDATE_AUTOPAY_BANK_REQUEST: 'VALIDATE_AUTOPAY_BANK_REQUEST',
    VALIDATE_AUTOPAY_BANK_SUCCESS: 'VALIDATE_AUTOPAY_BANK_SUCCESS',
    VALIDATE_AUTOPAY_BANK_FAILURE: 'VALIDATE_AUTOPAY_BANK_FAILURE',

    // ignore pending release
    IGNORE_PENDING_RELEASE: 'IGNORE_PENDING_RELEASE',

    // enroll autopay
    UPDATE_AUTOPAY_REQUEST: 'UPDATE_AUTOPAY_REQUEST',
    UPDATE_AUTOPAY_SUCCESS: 'UPDATE_AUTOPAY_SUCCESS',
    UPDATE_AUTOPAY_FAILURE: 'UPDATE_AUTOPAY_FAILURE',

    // autopay eligibility
    AUTOPAY_ELIGIBLE_REQUEST: 'AUTOPAY_ELIGIBLE_REQUEST',
    AUTOPAY_ELIGIBLE_SUCCESS: 'AUTOPAY_ELIGIBLE_SUCCESS',
    AUTOPAY_ELIGIBLE_FAILURE: 'AUTOPAY_ELIGIBLE_FAILURE',

    // autopay enrollment
    AUTOPAY_ENROLLED_REQUEST: 'AUTOPAY_ENROLLED_REQUEST',
    AUTOPAY_ENROLLED_SUCCESS: 'AUTOPAY_ENROLLED_SUCCESS',
    AUTOPAY_ENROLLED_FAILURE: 'AUTOPAY_ENROLLED_FAILURE',

    GET_PREV_AUTOPAY: 'GET_PREV_AUTOPAY',
    GET_NEXT_AUTOPAY: 'GET_NEXT_AUTOPAY'
};
