import React, { Component } from 'react'
import { formConstants } from '../../../constants/form-constants'
import PropTypes from 'prop-types'
import { withTranslation, Trans } from 'react-i18next'

import { DateTime } from 'luxon'

import CircularProgress from '@mui/material/CircularProgress'

import StartServiceStepper from './start-stepper'
import CardBlue from '../../common_error/card-blue'

import SafetyStatement from './safety-statement'
import AboutNewAccountDesktop from './about-new-account-desktop'
import AboutNewAccountMobile from './about-new-account-mobile'
import AboutYouDesktop from './about-you-desktop'
import AboutYouMobile from './about-you-mobile'

import LearnAboutMPowerCosts from './learn-about-mpower-costs'
import LowBalanceNotifications from './low-balance-notifications'

const displayIdentificationType = (key, t) => {
  switch (key) {
    case formConstants.SOCIAL: return t("social_security")
    case formConstants.DRIVERS_LICENSE: return t("drivers_license")
    case formConstants.STATE_ID: return t("state_id")
    case formConstants.GOVERNMENT_ID: return t("government_id")
    case formConstants.MILITARY_ID: return t("military_id")
    case formConstants.RESIDENCY_ID: return t("us_residency_id")
    case formConstants.NATURALIZATION: return t("certificate_of_naturalization")
    case formConstants.PASSPORT: return t("passport")
    case formConstants.MEXICAN_VOTER: return t("mexico_voter_id")
    case formConstants.TEMP_WORK_AUTH: return t("temporary_work_auth")
    case formConstants.ITIN: return t("itin_id")
  }
}

// TODO: Set the default time after the 2 hours from the current time
class Safety extends Component {
  constructor(props) {
    super(props)

    this.onSubmitButtonClick = this.onSubmitButtonClick.bind(this)
  }

  onSubmitButtonClick() {
    let safetyValid = this.props.validateSafety(this.props.hasAgreedToSafety)
    if (!safetyValid)
      return
    
    this.props.submitStartServiceForm()
  }

  render() {
    const serviceStartDate = DateTime.fromJSDate(this.props.startDate).toFormat('M/d/yyyy')
    const serviceAddress = `${this.props.address1} ${this.props.address2}`.trim()

    const isMPower = (this.props.selectedPlan === formConstants.MPOWER)

    return (
      <div>
        <StartServiceStepper
          currentStep={this.props.currentStep}
        />

        <div className="text-muted">
          <Trans i18nKey="You_have_requested_to_start_service_at" t={this.props.t}>
          You have requested to start service at <span className="font-weight-bold">{{serviceAddress}}</span> on <span className="font-weight-bold">{{serviceStartDate}}</span>.
          Your power may be turned on at any time during the day.
          </Trans>

          {isMPower &&
          <div className="mt-3 pb-1">
          <LearnAboutMPowerCosts {...this.props} />
          </div>
          }

          <div className="mt-3 mb-3">
            <CardBlue
              open={true}
              message={this.props.t("contact_24_hours")}
            />
          </div>
          <div className="mb-2 section-header">
            {this.props.t("about_your_new_account")}
          </div>
          <AboutNewAccountDesktop {...this.props} />
          <AboutNewAccountMobile {...this.props} />

          <div className="mb-2 section-header">
            {this.props.t("about_you")}
          </div>
          <AboutYouDesktop displayIdentificationType={displayIdentificationType} {...this.props} />
          <AboutYouMobile displayIdentificationType={displayIdentificationType} {...this.props} />

          {/* Force hide low balance notification message during Central Prepay pilot phase. */}
          {isMPower && false &&
          <LowBalanceNotifications cssClass="mb-3" />
          }

          <SafetyStatement {...this.props} />
        </div>

        <div className="d-flex justify-content-end pt-2">
          <button className="btn srp-btn btn-lightblue mr-3" onClick={() => this.props.goToAboutYou(this.props.currentStep - 1)}>
            {this.props.t("previous")}
          </button>
          <button type="submit"
            className="btn srp-btn btn-green"
            onClick={this.onSubmitButtonClick}>
            {this.props.hasSubmitted ? <CircularProgress size={20} thickness={5} style={{ color: 'white' }} /> : this.props.t("submit")}
          </button>
        </div>

      </div>)
  }
}

Safety.propTypes = {
  address1: PropTypes.string,
  address2: PropTypes.string,
  agreementError: PropTypes.bool,
  agreeToSafety: PropTypes.func,
  authorizeSpouse: PropTypes.bool,
  city: PropTypes.string,
  currentStep: PropTypes.number,
  dayOfMonth: PropTypes.number,
  email: PropTypes.string,
  firstName: PropTypes.string,
  hasAgreedToSafety: PropTypes.bool,
  identificationNumber: PropTypes.string,
  identificationType: PropTypes.string,
  isLoggedIn: PropTypes.bool,
  isMailingAddressSame: PropTypes.bool,
  isMpower: PropTypes.bool,
  isPaperlessBilling: PropTypes.bool,
  lastName: PropTypes.string,
  mailingAddress1: PropTypes.string,
  mailingAddress2: PropTypes.string,
  mailingCity: PropTypes.string,
  mailingCountry: PropTypes.string,
  mailingState: PropTypes.string,
  mailingZipCode: PropTypes.string,
  middleName: PropTypes.string,
  phoneNumber: PropTypes.string,
  preferredLanguage: PropTypes.string,
  selectedSolarChoicePlan: PropTypes.string,
  selectedPlan: PropTypes.string,
  shouldRunValidation: PropTypes.bool,
  spouseFirstName: PropTypes.string,
  spouseLastName: PropTypes.string,
  startDate: PropTypes.object,
  validateSafety: PropTypes.func.isRequired,

  hasSubmitted: PropTypes.bool.isRequired,
  goToAboutYou: PropTypes.func.isRequired,
  submitStartServiceForm: PropTypes.func.isRequired,

  t: PropTypes.func
}

export default withTranslation('startService')(Safety)