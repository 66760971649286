import { contactConstants } from '../../../constants/contact-constants'
import { RSAA } from 'redux-api-middleware'
import utils from '../../../srp_modules/utils'

export const getContacts = (billAccount) => async (dispatch) => {
    let getContactsResponse = await dispatch({
        [RSAA]: {
            endpoint: utils.createUrl('contact/getcontacts', { billAccount: billAccount }),
            method: 'GET',
            credentials: 'include',
            types: [
                contactConstants.GET_CONTACTS_REQUEST,
                contactConstants.GET_CONTACTS_SUCCESS,
                contactConstants.GET_CONTACTS_FAILURE
            ]
        }
    })

    return getContactsResponse
}

export const refreshContacts = (billAccount) => {
    return dispatch => {
        return dispatch({
            [RSAA]: {
                endpoint: utils.createUrl('contact/getcontacts', { billAccount: billAccount }),
                method: 'GET',
                credentials: 'include',
                types: [
                    contactConstants.REFRESH_CONTACTS_REQUEST,
                    contactConstants.REFRESH_CONTACTS_SUCCESS,
                    contactConstants.REFRESH_CONTACTS_FAILURE
                ]
            }
        })
    }
}

export const addContacts = (billAccount, contact) => {
    let contactToAdd = {
        contactId: 0,
        contactAddress: contact.contactAddress,
        contactType: contact.contactType,
        displaySequenceNumber: 0,
        contactLabel: contact.contactLabel,
        accountNumber: billAccount,
        isLoginContact: false,
        isTextAuthorized: false,
        contactAddressToDisplay: ''
    }

    return dispatch => {
        return dispatch({
            [RSAA]: {
                endpoint: utils.createUrl('contact/addcontacts', { billAccount: billAccount }),
                body: JSON.stringify(contactToAdd),
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                credentials: 'include',
                types: [
                    contactConstants.ADD_CONTACTS_REQUEST,
                    contactConstants.ADD_CONTACTS_SUCCESS,
                    contactConstants.ADD_CONTACTS_FAILURE
                ]
            },
            meta: { //this turns off debouncer
                debounce: {
                    leading: false,
                    trailing: false
                }
            }
        })
    }
}

export const deleteContacts = (billAccount, contactToDelete) => {
    return dispatch => {
        return dispatch({
            [RSAA]: {
                endpoint: utils.createUrl('contact/deletecontacts', { billAccount: billAccount }),
                body: JSON.stringify(contactToDelete),
                method: 'DELETE',
                headers: { 'Content-Type': 'application/json' },
                credentials: 'include',
                types: [
                    contactConstants.DELETE_CONTACTS_REQUEST,
                    contactConstants.DELETE_CONTACTS_SUCCESS,
                    contactConstants.DELETE_CONTACTS_FAILURE
                ]
            },
            meta: {
                debounce: {
                    key: `${contactConstants.DELETE_CONTACTS_REQUEST}_${billAccount}`,
                    time: 100,
                    leading: true,
                    trailing: false
                }
            }
        })
    }
}

export const updateContacts = (billAccount, oldContact, newContact) => {
    let model = {
        oldContact: oldContact,
        newContact: newContact
    }

    return dispatch => {
        return dispatch({
            [RSAA]: {
                endpoint: utils.createUrl('contact/updatecontacts', { billAccount: billAccount }),
                body: JSON.stringify(model),
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                credentials: 'include',
                types: [
                    contactConstants.UPDATE_CONTACTS_REQUEST,
                    contactConstants.UPDATE_CONTACTS_SUCCESS,
                    contactConstants.UPDATE_CONTACTS_FAILURE
                ]
            },
            meta: { //this turns off debouncer
                debounce: {
                    leading: false,
                    trailing: false
                }
            }
        })
    }
}

export const moveSubscriptionsAndDeleteContact = (billAccount, sourceContact, targetContact) => {
    let model = {
        oldContact: sourceContact,
        newContact: targetContact
    }

    return dispatch => {
        return dispatch({
            [RSAA]: {
                endpoint: utils.createUrl('contact/updatecontacts', { billAccount: billAccount }),
                body: JSON.stringify(model),
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                credentials: 'include',
                types: [
                    contactConstants.UPDATE_CONTACTS_REQUEST,
                    contactConstants.UPDATE_CONTACTS_SUCCESS,
                    contactConstants.UPDATE_CONTACTS_FAILURE
                ]
            },
            meta: { //this turns off debouncer
                debounce: {
                    leading: false,
                    trailing: false
                }
            }
        })
    }
}

export const reorderContacts = (billAccount, contacts) => {
    return dispatch => {
        return dispatch({
            [RSAA]: {
                endpoint: utils.createUrl('contact/reordercontacts', { billAccount: billAccount }),
                body: JSON.stringify(contacts),
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                credentials: 'include',
                types: [
                    contactConstants.REORDER_CONTACTS_REQUEST,
                    contactConstants.REORDER_CONTACTS_SUCCESS,
                    contactConstants.REORDER_CONTACTS_FAILURE
                ]
            },
            meta: { //this turns off debouncer
                debounce: {
                    leading: false,
                    trailing: false
                }
            }
        })
    }
}