import React from 'react'
import PropTypes from 'prop-types'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableHead from '@mui/material/TableHead'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import IconButton from '@mui/material/IconButton'
import Icon from '@mui/material/Icon'
import Dialog from '@mui/material/Dialog'
import CircularProgress from '@mui/material/CircularProgress'
import SrpTooltip from '../../common_tooltip/srp-tooltip'

import { bankAccountConstants } from '../../../constants/bank-account-constants'
import { myAccountConstants } from '../../../constants/myaccount-constants'
import * as loadingStatus from '../../../constants/loading-status-constants'

import '../../../styles/tables.css'

const BankInformationTable = ({ bankAccountList, scheduledPayments, pendingPayments, isCommercial, showDeleteBankModule, bankAccountToDelete,
    showDeleteBankModuleOnClick, closeDeleteBankModuleOnClick, showDeleteBankErrorModule, closeDeleteBankErrorModuleOnClick, deleteBankAccountOnClick,
    deleteBankAccountError, deleteBankAccountStatus, getBankAccountToEditOnClick, allBillAccountsAreCommercial, t}) => {

    const deleteBankErrorMessage = t("delete_bank_error_message", {phoneNumber : isCommercial ? myAccountConstants.COMMERCIAL_CUSTOMER_SERVICE_PHONE_NUMBER_FMTTD : myAccountConstants.RESIDENTIAL_CUSTOMER_SERVICE_PHONE_NUMBER_FMTTD})
    const bankAccountLockedMessage = t("bank_account_locked", {phoneNumber : isCommercial ? myAccountConstants.COMMERCIAL_CUSTOMER_SERVICE_PHONE_NUMBER_FMTTD : myAccountConstants.RESIDENTIAL_CUSTOMER_SERVICE_PHONE_NUMBER_FMTTD})

    return (
        <div>

            <div className="d-lg-block d-none" style={{ overflow: 'auto' }}>
                <Table className="mb-4" id="bank-info-table">
                    <TableHead className="srp-table-head">
                        <TableRow>
                            <TableCell className="border-0" padding="none">{t("Bank name")}</TableCell>
                            <TableCell className="border-0" padding="none">{t("Account number")}</TableCell>
                            <TableCell className="border-0" padding="none" style={{ width: '119px' }}>{t("Account name")}</TableCell>
                            <TableCell className="border-0" padding="none" style={{ width: '85px' }}>{t("Default")}</TableCell>
                            <TableCell className="border-0" padding="none">{t("Payment eligibility")}</TableCell>
                            <TableCell className="border-0" style={{ maxWidth: '41px' }} />
                            <TableCell className="border-0" style={{ width: '41px' }} />
                        </TableRow>
                    </TableHead>
                    <TableBody className="srp-table-body">
                        {bankAccountList.map((row, index) => {
                            let hasPendingPayments = getBankError(row.bankId, pendingPayments)
                            let hasScheduledPayments = getBankError(row.bankId, scheduledPayments)
                            let hasDeleteBankError = getDeleteBankError(row.bankId, deleteBankAccountError)
                            let canDeleteBank = !(hasScheduledPayments || hasPendingPayments || hasDeleteBankError)
                            let isLocked = row.isLocked
                            return (
                                <TableRow key={index} style={{ border: 'none' }}>
                                    <TableCell padding="none">
                                        <div className="d-flex justify-content-start align-items-center">
                                            <div className="mr-1 align-middle">
                                                {!canDeleteBank || isLocked
                                                    ? <SrpTooltip
                                                        content={
                                                            <span>
                                                                {hasPendingPayments
                                                                    ? isCommercial ? t(bankAccountConstants.HAS_PENDING_PMT_COMM) : t(bankAccountConstants.HAS_PENDING_PMT_RES)
                                                                    : hasScheduledPayments
                                                                        ? t(bankAccountConstants.HAS_SCHEDULED_PMT_MSG)
                                                                        : isLocked
                                                                            ? bankAccountLockedMessage
                                                                            : deleteBankErrorMessage
                                                                }
                                                            </span>
                                                        }>
                                                        <Icon className="align-middle" style={{ color: '#9E2A2B' }}>info_outline</Icon>
                                                    </SrpTooltip>
                                                    : <span />}
                                            </div>
                                            <div className="align-middle">
                                                <button className="displayAsLink text-left" onClick={() => getBankAccountToEditOnClick(row, canDeleteBank)}>{row.bankName}</button>
                                            </div>
                                        </div>
                                    </TableCell>
                                    <TableCell padding="none">****{row.bankAccountNumber}</TableCell>
                                    <TableCell padding="none" style={{ width: '119px' }}>{row.nickName === "" ? "- -" : row.nickName}</TableCell>
                                    <TableCell padding="none" style={{ width: '85px' }}>
                                        {row.isDefault
                                            ? <Icon style={{ color: "#737B4C" }}>checked</Icon>
                                            : <span />
                                        }
                                    </TableCell>
                                    <TableCell padding="none">
                                        <Icon style={{ color: row.isPhoneEligible ? "#737B4C" : "rgb(72, 72, 74, 0.376)" }}>local_phone</Icon>
                                    </TableCell>
                                    <TableCell className="text-right" padding="none" style={{ width: '41px' }}>
                                        <IconButton
                                            onClick={() => getBankAccountToEditOnClick(row, canDeleteBank)}
                                            color="primary"
                                            className="button-focus srp-table-button"
                                            style={{ textDecoration: 'none' }}
                                            size="large">
                                            <Icon>edit</Icon>
                                        </IconButton>
                                    </TableCell>
                                    <TableCell padding="none" style={{ width: '41px' }}>
                                        <IconButton
                                            onClick={() => showDeleteBankModuleOnClick(row)}
                                            color="primary"
                                            className="button-focus srp-table-button"
                                            style={{ textDecoration: 'none' }}
                                            disabled={!canDeleteBank}
                                            size="large">
                                            <Icon>delete</Icon>
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </div>

            <div className="d-lg-none d-block">
                {bankAccountList.map((row, index) => {
                    let hasPendingPaymentsMobile = getBankError(row.bankId, pendingPayments)
                    let hasScheduledPaymentsMobile = getBankError(row.bankId, scheduledPayments)
                    let hasDeleteBankErrorMobile = getDeleteBankError(row.bankId, deleteBankAccountError)
                    let canDeleteBank = !(hasPendingPaymentsMobile || hasScheduledPaymentsMobile || hasDeleteBankErrorMobile)
                    let isLockedMobile = row.isLocked
                    return (
                        <div key={index}>
                            <div className="row d-flex align-items-center">
                                <div className="col text-muted align-middle">{t("Bank name")}</div>
                                <div className="col align-middle d-flex justify-content-end">
                                    <div className="d-flex align-items-center mr-1">
                                        {!canDeleteBank || isLockedMobile
                                            ? <SrpTooltip
                                                content={
                                                    <span>
                                                        {hasPendingPaymentsMobile
                                                            ? isCommercial ? bankAccountConstants.HAS_PENDING_PMT_COMM : bankAccountConstants.HAS_PENDING_PMT_RES
                                                            : hasScheduledPaymentsMobile
                                                                ? bankAccountConstants.HAS_SCHEDULED_PMT_MSG
                                                                : isLockedMobile
                                                                    ? bankAccountLockedMessage
                                                                    : deleteBankErrorMessage
                                                        }
                                                    </span>
                                                }>
                                                <Icon className="align-middle" style={{ color: '#9E2A2B' }}>info_outline</Icon>
                                            </SrpTooltip>
                                            : <span />}
                                    </div>
                                    <div><button className="displayAsLink align-middle text-right" onClick={() => getBankAccountToEditOnClick(row, canDeleteBank)}>{row.bankName}</button></div>
                                </div>
                            </div>
                            <hr className="mt-2" />
                            <div className="row d-flex align-items-center">
                                <div className="col text-muted align-middle">
                                    {t("Account number")}
                                </div>
                                <div className="col align-middle text-right">
                                    ****{row.bankAccountNumber}
                                </div>
                            </div>
                            <hr className="mt-2" />
                            <div className="row d-flex align-items-center">
                                <div className="col text-muted align-middle">
                                    {t("Account name")}
                                </div>
                                <div className="col align-middle text-right">
                                    {row.nickName === "" ? "- -" : row.nickName}
                                </div>
                            </div>
                            <hr className="mt-2" />
                            <div className="row d-flex align-items-center">
                                <div className="col text-muted align-middle">
                                    {t("Default")}
                                </div>
                                <div className="col align-middle text-right">
                                    {row.isDefault
                                        ? <Icon style={{ color: "#737B4C" }}>checked</Icon>
                                        : <span />
                                    }
                                </div>
                            </div>
                            <hr className="mt-2" />
                            <div className="row d-flex align-items-center">
                                <div className="col text-muted align-middle">
                                    {t("Payment eligibility")}
                                </div>
                                <div className="col text-right">
                                    <Icon style={{ color: row.isPhoneEligible ? "#737B4C" : "rgb(72, 72, 74, 0.376)" }}>
                                        local_phone
                                    </Icon>
                                </div>
                            </div>
                            <hr className="mt-2 mb-0" />
                            <div className="row d-flex align-items-center">
                                <div className="col pr-0 text-right">
                                    <IconButton
                                        onClick={() => getBankAccountToEditOnClick(row, canDeleteBank)}
                                        color="primary"
                                        className="button-focus srp-table-button"
                                        style={{ textDecoration: 'none' }}
                                        size="large">
                                        <Icon>edit</Icon>
                                    </IconButton>
                                    <IconButton
                                        onClick={() => showDeleteBankModuleOnClick(row)}
                                        color="primary"
                                        className="button-focus srp-table-button"
                                        style={{ textDecoration: 'none' }}
                                        disabled={!canDeleteBank}
                                        size="large">
                                        <Icon>delete</Icon>
                                    </IconButton>
                                </div>
                            </div>
                            <hr className="mt-0" style={{ border: '2px solid lightgray' }} />
                        </div>
                    )
                })}
            </div>

            <Dialog
                modal="true"
                open={showDeleteBankModule}
                PaperProps={{ className: "srp-modal" }}
            >
                <div className="srp-modal-body">
                    <div className="srp-modal-close" onClick={() => closeDeleteBankModuleOnClick()} />
                    <div>
                        <div className="srp-modal-header">
                            <h4 className="srp-modal-title">{t("Remove bank account?")}</h4>
                        </div>
                        <div>
                            {bankAccountToDelete.nickName === ""
                                ? <div>{t("remove_account", {bankName: bankAccountToDelete.bankName, bankAccountNumber : bankAccountToDelete.bankAccountNumber})}</div>
                                : <div>{t("remove_account_nickname", {nickName : bankAccountToDelete.nickName, bankAccountNumber : bankAccountToDelete.bankAccountNumber})}</div>
                            }
                            <div className="srp-modal-footer mb-0">
                                <button className="btn srp-btn btn-lightblue mr-2" onClick={() => closeDeleteBankModuleOnClick()}>{t("No")}</button>
                                <button className="btn srp-btn btn-blue" disabled={deleteBankAccountStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS} onClick={() => deleteBankAccountOnClick(bankAccountToDelete, allBillAccountsAreCommercial)}>
                                    {deleteBankAccountStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS
                                        ? <CircularProgress size={20} thickness={5} style={{ color: 'white' }} />
                                        : t("Yes")
                                    }
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>
            <Dialog
                modal="true"
                open={showDeleteBankErrorModule}
                PaperProps={{ className: "srp-modal" }}
            >
                <div className="srp-modal-body">
                    <div className="srp-modal-close" onClick={() => closeDeleteBankErrorModuleOnClick()} />
                    <div>
                        <div className="srp-modal-header">
                            <h4 className="srp-modal-title">{t("Something went wrong")}</h4>
                        </div>
                        <div>
                            {deleteBankErrorMessage}
                            <div className="srp-modal-footer">
                                <button className="btn srp-btn btn-blue" onClick={() => closeDeleteBankErrorModuleOnClick()}>{t("OK")}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>
        </div>
    )
}

BankInformationTable.propTypes = {
    bankAccountList: PropTypes.arrayOf(PropTypes.object),
    scheduledPayments: PropTypes.arrayOf(PropTypes.object),
    pendingPayments: PropTypes.arrayOf(PropTypes.object),
    isCommercial: PropTypes.bool,
    allBillAccountsAreCommercial: PropTypes.bool.isRequired,

    showDeleteBankModule: PropTypes.bool,
    showDeleteBankModuleOnClick: PropTypes.func,
    closeDeleteBankModuleOnClick: PropTypes.func,
    showDeleteBankErrorModule: PropTypes.bool,
    closeDeleteBankErrorModuleOnClick: PropTypes.func,

    bankAccountToDelete: PropTypes.object,
    deleteBankAccountStatus: PropTypes.string,
    deleteBankAccountError: PropTypes.arrayOf(PropTypes.object),
    deleteBankAccountOnClick: PropTypes.func,

    getBankAccountToEditOnClick: PropTypes.func,
    t: PropTypes.func
}

export default BankInformationTable

function getBankError(bankId, paymentsList) {
    let error = false
    if (paymentsList.length === 0)
        return error

    for (let i = 0; i < paymentsList.length; i++) {
        if (paymentsList[i].fundingSourceId === bankId)
            error = true
        return error
    }
}

function getDeleteBankError(bankId, bankError) {
    let hasError = false
    for (let i = 0; i < bankError.length; i++) {
        if (bankError[i].error && bankError[i].bankId === bankId) {
            hasError = true
        }
    }

    return hasError
}