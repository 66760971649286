import React from 'react'
import PropTypes from 'prop-types'
import config from 'my-account-config'
const headerStyle = {
    background: 0,
    border: 0,
    outline: 0,
    cursor: 'pointer',
    color: '#087ED8',
    fontSize: '18px',
    fontWeight: '400',
    width: '100%',
    alignItems: 'center'
}

const MyAccountPageHeaderResponsive = ({ serviceAddress, currentRateCode, addressPricePlaceStyle, arrowIcon, toggleServicePricePlanButtonOnClick,
    selectedBillAccountDetails, accountManager, defaultAccountManager, isSummaryMaster, t, i18n }) => {
        const isSpanish = (i18n.language === "es")
        let cashCardUrl = config.appPath !== '/' ? '..' + config.appPath + '/myaccount/cashpaymentcard' : '/myaccount/cashpaymentcard'
        return (
        <div className="d-block d-lg-none d-print-none">
            <div className="d-flex mt-3">
                <button className="d-flex justify-content-between p-0" onClick={() => toggleServicePricePlanButtonOnClick()} style={headerStyle}>
                    {selectedBillAccountDetails.isCommercial
                        ? <div>{t("Account details")}</div>
                        : <div>{t("Service address & price plan")}</div>
                    }
                    <i className="material-icons">{arrowIcon}</i>
                </button>
            </div>

            <div id="addr-pp" style={addressPricePlaceStyle}>
                {selectedBillAccountDetails.isCommercial
                    ? accountManager.hasAccountManager
                        ? <div className="row mt-2" style={{ fontSize: '0.8125rem' }}>
                            <div className="col-5">
                                <strong>{t(accountManager.accountManagerTitle)}</strong>
                            </div>
                            <div className="col-7" style={{ whitespace: 'normal', textAlign: 'right' }}>
                                <div>{accountManager.fullName}</div>
                                <div>
                                    <a href={"tel:" + (accountManager.phoneNumber)}>{accountManager.phoneNumber}</a>
                                </div>
                                <div>
                                    <a href={"mailto:" + (accountManager.emailAddress)}>{accountManager.emailAddress}</a>
                                </div>
                            </div>
                        </div>
                        : <div className="row mt-2" style={{ fontSize: '0.8125rem' }}>
                            <div className="col-5">
                                <strong>{t(defaultAccountManager.accountManagerTitle)}</strong>
                            </div>
                            <div className="col-7" style={{ whitespace: 'normal', textAlign: 'right' }}>
                                <div>
                                    <a href={"tel:" + (defaultAccountManager.phoneNumber)}>{defaultAccountManager.phoneNumber}</a>
                                </div>
                            </div>
                        </div>
                    : null
                }
                <div className="row mt-2" style={{ fontSize: '0.8125rem' }}>
                    <div className="col-4">
                        <strong>{t("Service address")}</strong>
                    </div>
                    <div className="col-8" style={{ whitespace: 'normal', textAlign: 'right' }}>
                        {serviceAddress.streetAddress !== "0"
                            ? <span>{serviceAddress.streetAddress}<br />{serviceAddress.cityStateZip}</span>
                            : serviceAddress.cityStateZip
                        }
                    </div>
                </div>
                {!isSummaryMaster
                    ? <div className="row mt-2" style={{ fontSize: '0.8125rem' }}>
                        <div className="col-4">
                            <strong>{t("Price plan")}</strong>
                        </div>
                        <div className="col-8" style={{ whitespace: 'normal', textAlign: 'right' }}>
                            <a className="text-primary-ada" href={isSpanish ? currentRateCode.displayUrlEs : currentRateCode.displayUrl}>{isSpanish ? currentRateCode.descriptionEs : currentRateCode.description}</a>
                        </div>
                    </div>
                    : null
                }
            </div>
            {selectedBillAccountDetails.isBarcodeEligible
            ?<div>
                <div className="row mt-2">
                    <div className="col-6">
                        <strong>{t("In-person payments")}</strong>
                    </div>
                        <a className="text-primary-ada" target="_blank"
                           href={cashCardUrl}>
                            {t("My cash payment card")}
                        </a> 
                </div>
                <div className="srp-new-tag row" style={{display:"inline-block"}}>
                        <div className="srp-new-tag-div srp-new-tag-arrow-top">
                        <div className="srp-new-tag-text ">
                            <p><span>{t("New")}</span></p>
                        </div>
                        </div>
                    </div>
            </div>
            :null
            }
        </div>
    )
}

MyAccountPageHeaderResponsive.propTypes = {
    serviceAddress: PropTypes.object,
    currentRateCode: PropTypes.object,
    addressPricePlaceStyle: PropTypes.object,
    arrowIcon: PropTypes.string,
    toggleServicePricePlanButtonOnClick: PropTypes.func,
    selectedBillAccountDetails: PropTypes.object,
    accountManager: PropTypes.object,
    defaultAccountManager: PropTypes.object,
    isSummaryMaster: PropTypes.bool,
    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired,
}

export default MyAccountPageHeaderResponsive