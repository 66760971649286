import React from 'react'
import PropTypes from 'prop-types'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Icon from '@mui/material/Icon'
import TextField from '@mui/material/TextField'

class AssociatedAccountRowsHeaderResponsive extends React.Component {
    constructor(props) {
        super(props)

        this.handleSortChange = this.handleSortChange.bind(this)
    }

    handleSortChange(event) {
        this.props.setSortBy(event.target.value)
    }

    render() {
        return (
            <div className="container">
                <div className="row pt-3 pb-3 associated-account-row-header-info-box">
                    <div className="col-7 col-sm-5 pr-3">
                        <TextField
                            select
                            fullWidth
                            label={this.props.t("Sort By")}
                            value={this.props.sortedBy}
                            onChange={this.handleSortChange}
                            className="mt-0"
                        >
                            <MenuItem value="Nickname Asc"><Icon style={{ fontSize: 14 }}>arrow_upward</Icon> {this.props.t("Nickname")}</MenuItem>
                            <MenuItem value="Nickname Desc"><Icon style={{ fontSize: 14 }}>arrow_downward</Icon> {this.props.t("Nickname")}</MenuItem>
                            <MenuItem value="Account number Asc"><Icon style={{ fontSize: 14 }}>arrow_upward</Icon> {this.props.t("Account number")}</MenuItem>
                            <MenuItem value="Account number Desc"><Icon style={{ fontSize: 14 }}>arrow_downward</Icon> {this.props.t("Account number")}</MenuItem>
                            <MenuItem value="Service address Asc"><Icon style={{ fontSize: 14 }}>arrow_upward</Icon> {this.props.t("Service address")}</MenuItem>
                            <MenuItem value="Service address Desc"><Icon style={{ fontSize: 14 }}>arrow_downward</Icon> {this.props.t("Service address")}</MenuItem>
                        </TextField>
                    </div>
                    <div className="col-5 col-sm-7 pl-0">
                        <TextField
                            fullWidth
                            label="Search"
                            value={this.props.filterText}
                            className="mt-0"
                            onKeyUp={this.props.searchKeysUp}
                            onChange={this.props.searchOnChange}
                        />
                    </div>
                </div>
                <div className="row pt-3 pb-3 associated-account-row-header-column-header">
                    <div className="col-5 mt-1">
                        {(this.props.currentPage - 1) * this.props.pageSize + 1} - {Math.min(this.props.currentPage * this.props.pageSize, this.props.totalAccounts)} of {this.props.totalAccounts}
                    </div>
                    <div className="col-7">
                        <span className="mr-2">{this.props.t("Show")}:</span>
                        <Select
                            value={this.props.pageSize}
                            onChange={event => this.props.changePageSize(event.target.value)}
                        >
                            <MenuItem value={20}>20</MenuItem>
                            <MenuItem value={40}>40</MenuItem>
                            <MenuItem value={80}>80</MenuItem>
                            <MenuItem value={this.props.totalAccounts}>{this.props.t("all")}</MenuItem>
                        </Select>
                    </div>
                </div>
            </div>
        )
    }
}

AssociatedAccountRowsHeaderResponsive.propTypes = {
    currentPage: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired,
    totalAccounts: PropTypes.number.isRequired,
    sortedBy: PropTypes.string.isRequired,
    setSortBy: PropTypes.func.isRequired,
    changePageSize: PropTypes.func.isRequired,
    filterText: PropTypes.string.isRequired,
    searchKeysUp: PropTypes.func.isRequired,
    searchOnChange: PropTypes.func.isRequired
}

export default AssociatedAccountRowsHeaderResponsive