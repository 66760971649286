export const eppConstants = {
    AUDIT_STATUS: {
        NONE: 0,
        INITIAL: 1,
        RECEIVED: 2,
        APPROVED: 3,
        MISSING_INFO: 4,
        REMOVED_OVER_INCOME_LIMIT: 5,
        REMOVED_CUST_REQUEST: 6,
        LAST_CHANCE: 7,
        REMOVED_NO_RESPONSE: 8,
        DEFER: 9,
        TURNED_OFF: 10,
        REMOVAL_LETTER: 11,
        APPROVED_ASSISTANCE: 12,
        MISSING_PREV_AUDIT: 13,
        TOFF_TRANSFER_TO: 14,
        REMOVED_MISSING_DNR: 15
    }
}