import React from 'react'
import PropTypes from 'prop-types'
import { Formik } from 'formik'
import TextField from '@mui/material/TextField'
import { PatternFormat } from 'react-number-format'

import validate from '../../../srp_modules/validate'

const AccountDetailsCommercial = ({ activeStep, setActiveStepOnClick, accountDetails, setAccountDetailsOnChange, t, i18n }) => {

    return (
        <Formik
            initialValues={{
                accountType: accountDetails.accountType,
                billAccount: accountDetails.billAccount,
                firstName: accountDetails.firstName || '',
                middleInitial: accountDetails.middleInitial || '',
                lastName: accountDetails.lastName || '',
                businessName: accountDetails.businessName || '',
                dbaName: accountDetails.dbaName || '',
                contactName: accountDetails.contactName || '',
                contactPosition: accountDetails.contactPosition || '',
                serviceAddressOne: accountDetails.serviceAddressOne || '',
                serviceAddressTwo: accountDetails.serviceAddressTwo || '',
                city: accountDetails.city || '',
                zipCode: accountDetails.zipCode,
                emailAddress: accountDetails.emailAddress || '',
                phoneNumber: accountDetails.phoneNumber
            }}
            validate={(values) => validateForm(values, setAccountDetailsOnChange)}
            onSubmit={(values) => {
                let transformedValues = transformValues(values)
                setAccountDetailsOnChange(transformedValues)
                setActiveStepOnClick(activeStep + 1)
            }}>
            {({ values, errors, touched, dirty, handleChange, handleBlur, handleSubmit, setFieldValue, setFieldTouched, submitCount }) => {
                let billAccountError = (dirty || submitCount > 0) && touched.billAccount && errors.billAccount !== undefined
                let invalidBillAccountError = (dirty || submitCount > 0) && touched.billAccount && errors.invalidBillAccount !== undefined
                let businessNameError = (dirty || submitCount > 0) && touched.businessName && errors.businessName !== undefined
                let serviceAddressError = (dirty || submitCount > 0) && touched.serviceAddressOne && errors.serviceAddress !== undefined
                let cityError = (dirty || submitCount > 0) && touched.city && errors.city !== undefined
                let zipCodeError = (dirty || submitCount > 0) && touched.zipCode && errors.zipCode !== undefined
                let emailError = (dirty || submitCount > 0) && touched.emailAddress && errors.email !== undefined
                let invalidEmailError = (dirty || submitCount > 0) && touched.emailAddress && errors.invalidEmail !== undefined
                let phoneError = (dirty || submitCount > 0) && touched.phoneNumber && errors.phone !== undefined
                let invalidPhoneError = (dirty || submitCount > 0) && touched.phoneNumber && errors.invalidPhone !== undefined

                return (
                    <form className="mt-4 text-muted" onSubmit={handleSubmit}>
                        
                        <PatternFormat
                            id="billAccount"
                            name="billAccount"
                            className="mb-3"
                            value={values.billAccount}
                            valueIsNumericString={true}
                            fullWidth={true}
                            type="tel"
                            onValueChange={(values) => setFieldValue('billAccount', values.value, true)}
                            onBlur={() => setFieldTouched("billAccount", true)}
                            format="###-###-###"
                            customInput={TextField}
                            helperText={billAccountError
                                ? t(errors.billAccount)
                                : invalidBillAccountError
                                    ? t(errors.invalidBillAccount)
                                    : ''}
                            error={billAccountError || invalidBillAccountError}
                            label={t("SRP account number")}
                        />

                        <TextField
                            fullWidth
                            className="mb-3"
                            name="businessName"
                            value={values.businessName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            helperText={businessNameError ? t(errors.businessName) : ''}
                            error={businessNameError}
                            label={t("Business name")}
                        />
                        
                        <TextField
                            fullWidth
                            className="mb-3"
                            name="dbaName"
                            value={values.dbaName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            label={t("DBA name")}
                        />
                        
                        <TextField
                            fullWidth
                            className="mb-3"
                            name="contactName"
                            value={values.contactName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            label={t("Contact name")}
                        />
                        
                        <TextField
                            fullWidth
                            className="mb-3"
                            name="contactPosition"
                            value={values.contactPosition}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            label={t("Contact position")}
                        />
                        
                        <TextField
                            fullWidth
                            className="mb-2"
                            name="serviceAddressOne"
                            value={values.serviceAddressOne}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={serviceAddressError}
                            label={t("Service address")}
                        />
                        <TextField
                            fullWidth
                            className="mb-3"
                            name="serviceAddressTwo"
                            value={values.serviceAddressTwo}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            helperText={serviceAddressError ? t(errors.serviceAddress) : ''}
                            error={serviceAddressError}
                            label={t("Apt, suite, etc (optional)")}
                        />
                        
                        <TextField
                            fullWidth
                            className="mb-3"
                            name="city"
                            value={values.city}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            helperText={cityError ? t(errors.city) : ''}
                            error={cityError}
                            label={t("City")}
                        />
                        
                        <PatternFormat
                            id="zipCode"
                            name="zipCode"
                            className="mb-4"
                            value={values.zipCode}
                            valueIsNumericString={true}
                            fullWidth={true}
                            type="tel"
                            onValueChange={(values) => setFieldValue('zipCode', values.value, true)}
                            onBlur={() => setFieldTouched("zipCode", true)}
                            format="#####"
                            customInput={TextField}
                            helperText={zipCodeError ? t(errors.zipCode) : ''}
                            error={zipCodeError}
                            label={t("Zip code")}
                        />
                        
                        <TextField
                            fullWidth
                            className="mb-3"
                            name="emailAddress"
                            value={values.emailAddress}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            helperText={
                                emailError
                                    ? t(errors.email)
                                    : invalidEmailError
                                        ? t(errors.invalidEmail)
                                        : ''}
                            error={emailError || invalidEmailError}
                            label={t("Email address")}
                        />

                        <PatternFormat
                            id="phoneNumber"
                            name="phoneNumber"
                            className="mb-4"
                            value={values.phoneNumber}
                            valueIsNumericString={true}
                            fullWidth={true}
                            type="tel"
                            onValueChange={(values) => setFieldValue('phoneNumber', values.value, true)}
                            onBlur={() => setFieldTouched("phoneNumber", true)}
                            format="(###) ###-####"
                            customInput={TextField}
                            helperText={
                                phoneError
                                    ? t(errors.phone)
                                    : invalidPhoneError
                                        ? t(errors.invalidPhone)
                                        : ''}
                            error={phoneError || invalidPhoneError}
                            label={t("Phone number")}
                        />
                        <div className="d-flex flex-wrap justify-content-end">
                            <button className="btn srp-btn btn-blue" type="submit">{t("Next")}</button>
                        </div>
                    </form>
                )
            }}
        </Formik>
    )
}

AccountDetailsCommercial.propTypes = {
    activeStep: PropTypes.number,
    setActiveStepOnClick: PropTypes.func,
    accountDetails: PropTypes.object,
    setAccountDetailsOnChange: PropTypes.func,

    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired,
}

export default AccountDetailsCommercial

function transformValues(values) {
    return {
        ...values,
        billAccount: values.billAccount.trim(),
        businessName: values.businessName.trim(),
        dbaName: values.dbaName.trim(),
        contactName: values.contactName.trim(),
        contactPosition: values.contactPosition.trim(),
        serviceAddressOne: values.serviceAddressOne.trim(),
        serviceAddressTwo: values.serviceAddressTwo.trim(),
        city: values.city.trim(),
        zipCode: values.zipCode.trim(),
        emailAddress: values.emailAddress.trim(),
        phoneNumber: values.phoneNumber.replace(/[() -]/g, '')
    }
}

function validateForm(values, setValues) {
    let errors = {}
    if (values.billAccount === undefined || values.billAccount.trim().length === 0)
        errors.billAccount = 'SRP account number is required'
    if (values.billAccount.length < 9)
        errors.invalidBillAccount = 'Invalid SRP account number'

    if (values.businessName === undefined || values.businessName.trim().length === 0)
        errors.businessName = 'Business name is required'

    if (values.serviceAddressOne === undefined || values.serviceAddressOne.trim().length === 0)
        errors.serviceAddress = 'Service address is required'

    if (values.city === undefined || values.city.trim().length === 0)
        errors.city = 'City is required'

    if (values.zipCode === undefined || values.zipCode.trim().length === 0)
        errors.zipCode = 'Zip code is required'

    if (values.emailAddress === undefined || values.emailAddress.trim().length === 0)
        errors.email = 'Email address is required'
    if (!validate.emailAddress(values.emailAddress))
        errors.invalidEmail = 'Please enter a valid email address'

    if (values.phoneNumber === undefined || values.phoneNumber.trim().length === 0)
        errors.phone = 'Phone number is required'
    if (values.phoneNumber.length != 10)
        errors.invalidPhone = 'Please enter a valid phone number'

    let updateAccountDetails = {
        ...values,
        billAccount: values.billAccount,
        businessName: values.businessName,
        dbaName: values.dbaName,
        contactName: values.contactName,
        contactPosition: values.contactPosition,
        serviceAddressOne: values.serviceAddressOne,
        serviceAddressTwo: values.serviceAddressTwo,
        city: values.city,
        zipCode: values.zipCode,
        emailAddress: values.emailAddress,
        phoneNumber: values.phoneNumber
    }
    setValues(updateAccountDetails)

    return errors
}