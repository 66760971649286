import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Formik } from 'formik'
import * as Yup from 'yup'

import AnalyticsFormTracking from '../../common_formik/analytics-form-tracking'

import BankInfoLoadingCard from './bank-info-loading-card'

import { createValidationRulesFromeChexBankInfoValidationErrors } from '../../gift_payment_page/input_page/single-payment-formik-container'

import { addBankAccountFullDetails, addBankAccountInfoChange } from '../../../actions/auth/payment/bank-account-actions'
import { getCustomerNames, getPhoneNumber } from '../../../actions/auth/bill_account/bill-account-actions'
import { getContacts } from '../../../actions/auth/contact/contact-actions'
import { withRouter } from '../../../srp_modules/with-router'

import * as loadingStatus from '../../../constants/loading-status-constants'

import AddBankForm from './add-bank-form'

class AddBankFormik extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            initialChangedState: false,
        }
        this.onStateChanged = this.onStateChanged.bind(this)

        this.validationSchema = Yup.object().shape({
            isDefaultOnMyAcct: Yup.boolean(),
            isDefaultWithIVR: Yup.boolean(),
            shareAmount: Yup.number(),
            bankFirstName: Yup.string().ensure().trim()
                .required('First name is required.')
                .test('not all spaces', 'First name is required.', bankFirstName => typeof bankFirstName === "string" && bankFirstName.trim().length > 0),
            bankLastName: Yup.string().ensure().trim()
                .required('Last name is required.')
                .test('not all spaces',
                    'Last name is required.',
                    bankLastName => typeof bankLastName === "string" && bankLastName.trim().length > 0)
                .test('at least 2 characters',
                    'Last name must have at least two characters.',
                    bankLastName => typeof bankLastName === "string" && bankLastName.trim().length > 1),
            bankPhoneNumber: Yup.string().ensure().trim()
                .required('Phone number is required.')
                .test('not all spaces', 'Phone number is required.',
                    bankPhoneNumber => typeof bankPhoneNumber === "string" && bankPhoneNumber.trim().length > 0)
                .test('must have 10 digits',
                    'Phone number must have 10 digits.',
                    bankPhoneNumber => typeof bankPhoneNumber === "string" && /^\d{10}$/.test(bankPhoneNumber.replace(/[() -]/g, ''))),
            bankEmailAddress: Yup.string().ensure().trim()
                .required('Email address is required.')
                .test('not all spaces',
                    'Email address is required.',
                    bankEmailAddress => typeof bankEmailAddress === "string" && bankEmailAddress.trim().length > 0)
                .email('Invalid email address.'),
            bankRoutingNumber: Yup.string().ensure().trim()
                .required('Routing number is required.')
                .test('not all spaces',
                    'Routing number is required.',
                    bankRoutingNumber => typeof bankRoutingNumber === "string" && bankRoutingNumber.trim().length > 0)
                .test('must have 9 digits',
                    'Enter a valid 9-digit routing number.',
                    bankRoutingNumber => typeof bankRoutingNumber === "string" && /^\d{9}$/.test(bankRoutingNumber.trim())),
            bankAccountNumberLeadingZeroesAreSignificant: Yup.string().ensure().trim()
                .required('Bank account number is required.')
                .test('not all spaces',
                    'Bank account number is required.',
                    bankAccountNumber => typeof bankAccountNumber === "string" && bankAccountNumber.trim().length > 0)
                .test('17 digits max',
                    'Your account number must be 1-17 digits.',
                    bankAccountNumber => typeof bankAccountNumber === "string" && /^\d{1,17}$/.test(bankAccountNumber.trim())),
            bankAccountNickname: Yup.string().ensure().trim()
                .test('35 characters max',
                    'Your bank account nickname cannot have more than 35 characters.',
                    nickname => nickname === undefined || (typeof nickname === "string" && nickname.trim().length <= 35)),
        })
        this.initialValues = {}
    }

    componentDidMount() {
        if (this.props.selectedBillAccount === 0)
            return

        if (this.props.customerNamesStatus === loadingStatus.LOADING_STATUS_INIT) {
            this.props.getCustomerNamesInfo(this.props.selectedBillAccount)
        }

        if (this.props.phoneNumberStatus === loadingStatus.LOADING_STATUS_INIT) {
            this.props.getPhoneNumberInfo(this.props.selectedBillAccount)
        }

        if (this.props.primaryEmailStatus === loadingStatus.LOADING_STATUS_INIT)
            this.props.getContactsInfo(this.props.selectedBillAccount)
    }

    componentDidUpdate(prevProps) {
        if (this.props.selectedBillAccount === prevProps.selectedBillAccount || this.props.selectedBillAccount === 0)
            return

        if (this.props.customerNamesStatus !== loadingStatus.LOADING_STATUS_IN_PROGRESS)
            this.props.getCustomerNamesInfo(this.props.selectedBillAccount)

        if (this.props.phoneNumberStatus !== loadingStatus.LOADING_STATUS_IN_PROGRESS)
            this.props.getPhoneNumberInfo(this.props.selectedBillAccount)

        if (this.props.primaryEmailStatus !== loadingStatus.LOADING_STATUS_IN_PROGRESS)
            this.props.getContactsInfo(this.props.selectedBillAccount)
    }

    onStateChanged() {
        this.setState({
            initialChangedState: true
        })
    }

    transformValues(values) {
        return {
            ...values,
            bankFirstName: values.bankFirstName.trim(),
            bankLastName: values.bankLastName.trim(),
            bankPhoneNumber: values.bankPhoneNumber.replace(/[() -]/g, ''),
            bankEmailAddress: values.bankEmailAddress.trim(),
            bankRoutingNumber: values.bankRoutingNumber.trim(),
            bankAccountNumberLeadingZeroesAreSignificant: values.bankAccountNumberLeadingZeroesAreSignificant.trim(),
            bankAccountNickname: values.bankAccountNickname.trim(),
        }
    }

    render() {
        if (this.props.customerNamesStatus === loadingStatus.LOADING_STATUS_INIT || this.props.customerNamesStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS
            || this.props.phoneNumberStatus === loadingStatus.LOADING_STATUS_INIT || this.props.phoneNumberStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS
            || this.props.primaryEmailStatus === loadingStatus.LOADING_STATUS_INIT || this.props.primaryEmailStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS) {
            return <BankInfoLoadingCard />
        }
        else {
            return (
                <Formik
                    initialValues={{
                        bankFirstName: '',
                        bankLastName: '',
                        bankPhoneNumber: this.props.phoneNumber.bestPhone || '',
                        bankEmailAddress: this.props.primaryEmail || this.props.loginEmail || '',
                        bankRoutingNumber: '',
                        bankAccountNumberLeadingZeroesAreSignificant: '',
                        bankAccountNickname: '',
                        isDefaultOnMyAcct: false,
                        isDefaultWithIVR: false,
                    }}
                    validationSchema={this.validationSchema}
                    onSubmit={(values, formikProps) => {
                        let _values = this.transformValues(values)
                        let bankAccountInfo = {
                            bankAccountNumber: _values.bankAccountNumberLeadingZeroesAreSignificant,
                            nickName: _values.bankAccountNickname,
                            routingNumber: _values.bankRoutingNumber,
                            firstName: _values.bankFirstName,
                            lastName: _values.bankLastName,
                            phoneNumber: _values.bankPhoneNumber,
                            isDefaultAccount: _values.isDefaultOnMyAcct,
                            emailAddress: _values.bankEmailAddress,
                            isDefaultWithIVR: _values.isDefaultWithIVR,
                        }

                        this.props.submitAddBankForm(bankAccountInfo, formikProps, this.validationSchema)
                    }}>
                    {formikProps => {
                        return (<React.Fragment>
                            <AnalyticsFormTracking name="Add Bank" formStep="bank details" meta={{}}
                                onStateChanged={this.onStateChanged} initialChangedState={this.state.initialChangedState} />
                            <AddBankForm {...formikProps} {...this.props} {...this.state} />
                        </React.Fragment>)
                    }}
                </Formik>
            )
        }
    }
}

const mapStateToProps = state => {
    return {
        ...state.accountInfo.billAccount,
        ...state.bankAccounts,
        ...state.login,
        primaryEmail: state.accountInfo.contact.primaryEmail,
        primaryEmailStatus: state.accountInfo.contact.primaryEmailStatus,
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getCustomerNamesInfo: (billAccount) => {
            dispatch(getCustomerNames(billAccount))
        },
        getPhoneNumberInfo: (billAccount) => {
            dispatch(getPhoneNumber(billAccount))
        },
        submitAddBankForm: async (bankInfo, formikProps, validationSchema) => {
            await dispatch(addBankAccountInfoChange(bankInfo))
            let response = await dispatch(addBankAccountFullDetails())

            if (response.error) {
                formikProps.setStatus({hasUnhandledError: true, submitError: true})
                document.getElementById("topOfAddBankForm").scrollIntoView()
                formikProps.setSubmitting(false)
                return false
            }

            if (!response.payload.isSuccess) {
                const bankInputValues = {
                    bankFirstName: bankInfo.firstName,
                    bankLastName: bankInfo.lastName,
                    bankRoutingNumber: bankInfo.routingNumber,
                    bankAccountNumber: bankInfo.bankAccountNumber
                }

                const eChexAddBankAccountErrors = response.payload.saveBankAccountErrors
                const numErrorsHandled = createValidationRulesFromeChexBankInfoValidationErrors(validationSchema.fields, eChexAddBankAccountErrors, bankInputValues)
                const hasUnhandledeChexValidationError = (Object.keys(eChexAddBankAccountErrors).length - numErrorsHandled) > 0
                formikProps.setStatus({hasUnhandledError: hasUnhandledeChexValidationError, submitError: true})
                formikProps.validateForm()

                formikProps.setSubmitting(false)

                if (eChexAddBankAccountErrors.errorBankAccountSetupLocked)
                    ownProps.router.navigate('/myaccount/profile/banks/locked')

                document.getElementById("topOfAddBankForm").scrollIntoView()
                return false
            }
            else {
                formikProps.setStatus({submitError: false})
                formikProps.setSubmitting(false)
                ownProps.router.navigate(-1)
            }
        },
        getContactsInfo: (billAccount) => {
            dispatch(getContacts(billAccount))
        }
    }
}

AddBankFormik.propTypes = {
    router: PropTypes.shape({
        navigate: PropTypes.func
    }),
    selectedBillAccount: PropTypes.number,
    loginEmail: PropTypes.string,
    primaryEmail: PropTypes.string,
    primaryEmailStatus: PropTypes.string,

    customerNames: PropTypes.object,
    customerNamesStatus: PropTypes.string,
    getCustomerNamesInfo: PropTypes.func,

    phoneNumber: PropTypes.object,
    phoneNumberStatus: PropTypes.string,
    getContactsInfo: PropTypes.func,
    getPhoneNumberInfo: PropTypes.func,

    isDefaultBankAccount: PropTypes.bool,
    forUseWithIVR: PropTypes.bool,

    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired,

    submitAddBankForm: PropTypes.func
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddBankFormik))