import * as React from 'react'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import * as loadingStatus from '../../constants/loading-status-constants'
import drawHorizontalChart from  '../../srp_modules/charting/monthly-net-horizontal'
import drawVerticalChart from  '../../srp_modules/charting/monthly-net-vertical'
import {convertMonthlyDataForCharting} from '../../srp_modules/charting/monthly-data-dto'
import {dailyUsageChartTypeConstants} from '../../constants/daily-usage-chart-type-constants'
import {initDailyUsagePage} from '../../actions/auth/usage/usage-actions'
let initializeChart = (monthlyUsageList,
    isCost,
    startDate,
    endDate,
    barClickCallback,
    t
    ) => {

    if(monthlyUsageList.length > 0){
        let usage =  convertMonthlyDataForCharting(sliceUsageData(monthlyUsageList,startDate,endDate),dailyUsageChartTypeConstants.NET_ENERGY).reverse()

        window.innerWidth < 1024
            ? drawHorizontalChart(usage, isCost, barClickCallback, t)
            : drawVerticalChart(usage, isCost, barClickCallback, t)
    }
}

class MonthlyNetChartContainer extends React.Component{
    constructor(props) {
        super(props)
      this.state = {
          resizeListener: this.updateChart.bind(this)
      }
    }
    componentDidMount() {
        window.addEventListener("resize", this.state.resizeListener)
        if (this.props.accountHistoryStatus === loadingStatus.LOADING_STATUS_SUCCESS)
        this.updateChart()
    }



    shouldComponentUpdate(nextProps) {
        return (this.props.accountHistoryStatus !== nextProps.accountHistoryStatus
            || this.props.rateMetaData.rateMetaDataStatus !== nextProps.rateMetaData.rateMetaDataStatus
            || this.props.monthlyStartDate !== nextProps.monthlyStartDate
            || this.props.monthlyEndDate !== nextProps.monthlyEndDate
            || this.props.currentMonthlyChart !== nextProps.currentMonthlyChart
            || this.props.t !== nextProps.t
        )
    }

    componentDidUpdate() {
        if (this.props.accountHistoryStatus === loadingStatus.LOADING_STATUS_SUCCESS
            && this.props.rateMetaData.rateMetaDataStatus === loadingStatus.LOADING_STATUS_SUCCESS
        )
            this.updateChart()
    }

    componentWillUnmount(){
        window.removeEventListener("resize", this.state.resizeListener)
    }

    updateChart() {
        let usageData = [];
        usageData = this.props.accountHistoryList

        initializeChart(
            usageData,
            this.props.currentMonthlyChart === dailyUsageChartTypeConstants.COST,
            this.props.monthlyStartDate,
            this.props.monthlyEndDate,
            this.props.initDailyUsagePage,
            this.props.t
        )
    }

    render() {

        return(
            <div>
                <div id="usagePageChartContainer">
                    <div id="dailyUsageLegendContainer" className="chart-legend-container">
                        <div id="superOffLegendItem" className="chart-legend-item-hidden">
                            <span>{this.props.t("Super off-peak")}</span>
                            <div className="usage-chart-legend-circle viz-SuperOffPeak" />
                        </div>
                        <div id="offPeakLegendItem" className="chart-legend-item-hidden">
                            <span>{this.props.t("Off-peak")}</span>
                            <div className="usage-chart-legend-circle viz-OffPeak" />
                        </div>
                        <div id="shoulderLegendItem" className="chart-legend-item-hidden">
                            <span>{this.props.t("Shoulder")}</span>
                            <div className="usage-chart-legend-circle viz-Shoulder" />
                        </div>
                        <div id="onPeakLegendItem" className="chart-legend-item-hidden">
                            <span>{this.props.t("On-peak")}</span>
                            <div className="usage-chart-legend-circle viz-OnPeak" />
                        </div>
                        <div id="totalLegendItem" className="chart-legend-item-hidden">
                            <span>{this.props.t("Total")}</span>
                            <div className="usage-chart-legend-circle viz-Total" />
                        </div>
                        <div id="totalLegendItemCost" className="chart-legend-item-hidden">
                            <span>{this.props.t("Cost")}</span>
                            <div className="usage-chart-legend-circle viz-Total" />
                        </div>

                    </div>
                    <div className=".viz-tooltip-arrow-main" />
                </div>
            </div>
        )
    }

}

MonthlyNetChartContainer.propTypes = {
    highDemandInfoList: PropTypes.array,
    selectedBillAccount: PropTypes.number,
    selectedUsagePageChartTab: PropTypes.number,
    rateMetaData: PropTypes.object,
    getRateMetaDataForAccount: PropTypes.func,
    showDailyCostChart: PropTypes.bool,
    accountHistoryStatus: PropTypes.string,
    monthlyStartDate: PropTypes.string,
    monthlyEndDate: PropTypes.string,
    currentMonthlyChart: PropTypes.string,
    initDailyUsagePage: PropTypes.func,
    accountHistoryList: PropTypes.array,
    t: PropTypes.func
}

const mapStateToProps = (state) => {
    return {
        selectedBillAccount: state.accountInfo.billAccount.selectedBillAccount,
        isCost: PropTypes.bool,
        selectedUsagePageChartTab: state.accountInfo.usagePageUI.selectedUsagePageChartTab,
        rateMetaData: state.rateMetaData,
        ...state.accountInfo.usagePageUI,
        ...state.accountHistory
    }
}

function sliceUsageData(data,startDate,endDate){
    let startIndex = data.findIndex(getIndexOfUsageDate, startDate)
    let endIndex = data.findIndex(getIndexOfUsageDate, endDate)

    return (startIndex >= 0 && endIndex >= 0)
        ?
        data.slice(endIndex, startIndex + 1)
        :
        []
}

const mapDispatchToProps = (dispatch) => {
    return {
       initDailyUsagePage: (startDate, endDate, chartType, hasCost, isSolar) => {
           dispatch(
            initDailyUsagePage(startDate, endDate, chartType, hasCost, isSolar))
       }
    }
}
function getIndexOfUsageDate(element){
    return element.billEndDate === this
}
export default connect(mapStateToProps,mapDispatchToProps)(MonthlyNetChartContainer)

