import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import CircularProgress from '@mui/material/CircularProgress'

import { withTranslation } from 'react-i18next'

import MyPricing from './my-pricing-card'
import PricingPropgramsError from '../pricing-programs-error'

import {
    getRateCode,
    selectCompareMyPricePlanBusinessOffering,
    updateChangeMyPricePlanFromPage,
    resetChangeMyPricePlan,
    getCompareMyPricePlan
} from '../../../actions/auth/bill_account/bill-account-actions'
import { withRouter } from '../../../srp_modules/with-router'

import { rateMetaDataConstants } from '../../../constants/rate-meta-data-constants'
import { billAccountConstants } from '../../../constants/bill-account-constants'
import * as loadingStatus from '../../../constants/loading-status-constants'

let progressCirclestyle = {
    marginTop: "10%",
    marginBottom: "10%"
}

let hasLoaded = (props) => {
    return props.currentRateCodeStatus === loadingStatus.LOADING_STATUS_SUCCESS
    && props.compareMyPricePlanStatus === loadingStatus.LOADING_STATUS_SUCCESS
    && (props.rateMetaDataStatus === loadingStatus.LOADING_STATUS_SUCCESS || props.rateMetaDataStatus === loadingStatus.LOADING_STATUS_FAILURE)
}
class MyPricingContainer extends React.Component {
    constructor(props) {
        super(props)

        this.refreshMyPricingCard = this.refreshMyPricingCard.bind(this)

        this.props.resetChangeMyPricePlan()
    }

    componentDidMount() {
        if (this.props.selectedBillAccount === 0)
            return

        if (!hasLoaded(this.props)) {
            this.props.getCurrentRateCode(this.props.selectedBillAccount)
            this.props.getCompareMyPricePlan(this.props.selectedBillAccount)
        }

    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.selectedBillAccount === nextProps.selectedBillAccount || nextProps.selectedBillAccount === 0)
            return

        if (nextProps.currentRateCodeStatus !== loadingStatus.LOADING_STATUS_IN_PROGRESS
            || nextProps.compareMyPricePlanStatus !== loadingStatus.LOADING_STATUS_IN_PROGRESS) {
            this.props.getCurrentRateCode(nextProps.selectedBillAccount)
            this.props.getCompareMyPricePlan(nextProps.selectedBillAccount)
        }
    }

    refreshMyPricingCard(){
        if(this.props.currentRateCodeStatus === loadingStatus.LOADING_STATUS_FAILURE
            || this.props.compareMyPricePlanStatus === loadingStatus.LOADING_STATUS_FAILURE) {
            this.props.getCurrentRateCode(this.props.selectedBillAccount)
            this.props.getCompareMyPricePlan(this.props.selectedBillAccount)
        }
    }

    render() {
        const t = this.props.t
        const i18n = this.props.i18n

        let renderedCard = {}
        if (this.props.currentRateCodeStatus === loadingStatus.LOADING_STATUS_FAILURE
            || this.props.compareMyPricePlanStatus === loadingStatus.LOADING_STATUS_FAILURE) {
            renderedCard = <PricingPropgramsError errorMsgEnOrEs={t("We were unable to retrieve price plan data.")} refreshData={this.refreshMyPricingCard} t={t} />
        }
        else if (!hasLoaded(this.props)) {
            renderedCard =
                (<div className="srp-card-body">
                    <div className="srp-card-details">
                        <div className="d-flex justify-content-center">
                            <CircularProgress size={80} thickness={5} style={progressCirclestyle} />
                        </div>
                    </div>
                </div>
                )
        }
        else if (hasLoaded(this.props)) {
                    renderedCard = (<MyPricing
                                        currentRateCode={this.props.currentRateCode}
                                        isSummaryMaster={this.props.rateMetaData.summaryBilling === rateMetaDataConstants.SUMMARY_BILLING_PARENT}
                                        isClosed={this.props.selectedBillAccountDetails.closedState === billAccountConstants.CLOSED_STATE.CLOSED}
                                        isCommercial={this.props.selectedBillAccountDetails.isCommercial}
                                        compareMyPricePlan={this.props.compareMyPricePlan}
                                        selectPricePlanClick={this.props.selectPricePlanClick}
                                        t={t} i18n={i18n}
                                        />
                                    )
        }

        return renderedCard

    }
}

const mapStateToProps = state => {
    return { ...state.accountInfo.billAccount, ...state.rateMetaData }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getCurrentRateCode: (billAccount) => {
            dispatch(getRateCode(billAccount))
        },
        selectPricePlanClick: async (selectedBusinessOffering) => {
            await dispatch(selectCompareMyPricePlanBusinessOffering(selectedBusinessOffering))
            await dispatch(updateChangeMyPricePlanFromPage(true))
            ownProps.router.navigate('/changepriceplan')
        },
        selectCompareMyPricePlanBusinessOffering: async (businessOffering) => {
            dispatch(selectCompareMyPricePlanBusinessOffering(businessOffering))
        },
        updateChangeMyPricePlanFromPage: () => {
            dispatch(updateChangeMyPricePlanFromPage(true))
        },
        resetChangeMyPricePlan: () => {
            dispatch(resetChangeMyPricePlan())
        },
        getCompareMyPricePlan: async (billAccount) => {
            await dispatch(getCompareMyPricePlan(billAccount))
        }
    }
}

MyPricingContainer.propTypes = {
    selectedBillAccount: PropTypes.number,
    selectedBillAccountDetails: PropTypes.object,

    currentRateCode: PropTypes.object,
    currentRateCodeStatus: PropTypes.string,
    getCurrentRateCode: PropTypes.func,

    rateMetaData: PropTypes.object,
    rateMetaDataStatus: PropTypes.string,

    selectPricePlanClick: PropTypes.func,
    selectCompareMyPricePlanBusinessOffering: PropTypes.func,
    updateChangeMyPricePlanFromPage: PropTypes.func,
    resetChangeMyPricePlan: PropTypes.func,
    getCompareMyPricePlan: PropTypes.func,
    compareMyPricePlanStatus: PropTypes.string,
    compareMyPricePlan: PropTypes.object,

    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired,

    router: PropTypes.shape({
        navigate: PropTypes.func
    })
}

export default withTranslation('pricePlanAndPrograms')(withRouter(connect(mapStateToProps, mapDispatchToProps)(MyPricingContainer)))
