import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import * as loadingStatus from '../../constants/loading-status-constants'
import MonthlyDateRange from './monthly-date-range'
import MonthlyUsageContainer from './monthly-usage-container'
import MonthlyNetContainer from './monthly-net-container'
import UsagePageLoading from './usage-page-loading'
import {getRateMetaData} from '../../actions/auth/usage/rate-meta-data-actions'
import {getAccountHistory} from '../../actions/auth/payment/account-history-actions'
import {getDailyUsage} from '../../actions/auth/usage/daily-usage-actions'
import {rateMetaDataConstants} from '../../constants/rate-meta-data-constants'
import NonTokenlessMessage from './non-tokenless-message'
import NoMonthlyUsageMessage from './no-monthly-usage-message'
import UnmeteredMessage from './unmetered-message'
import SummaryMasterMessage from './summary-master-message'
import LookingForUsageMonthly from './looking_for_usage_monthly'
import { withTranslation } from 'react-i18next'

class MonthlyUsageFormContainer extends React.Component {
    constructor(props){
        super(props)
    }

    componentDidMount(){
        if (this.props.rateMetaData.rateMetaDataStatus === loadingStatus.LOADING_STATUS_INIT 
            && this.props.selectedBillAccount > 0)
                this.props.getRateMetaDataForAccount(this.props.selectedBillAccount) 
        if (this.props.accountHistoryStatus === loadingStatus.LOADING_STATUS_INIT && this.props.selectedBillAccount > 0){
            this.props.getAccountHistoryForAccount(this.props.selectedBillAccount)
        }
        if (this.props.dailyUsageStatus === loadingStatus.LOADING_STATUS_INIT && this.props.selectedBillAccount > 0){
            this.props.getDailyUsageForAccount(this.props.selectedBillAccount)
        }
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.accountHistoryStatus === loadingStatus.LOADING_STATUS_INIT && nextProps.selectedBillAccount > 0)
            this.props.getAccountHistoryForAccount(nextProps.selectedBillAccount)
        if (nextProps.rateMetaData.rateMetaDataStatus === loadingStatus.LOADING_STATUS_INIT && nextProps.selectedBillAccount > 0)
            this.props.getRateMetaDataForAccount(nextProps.selectedBillAccount)
        if (nextProps.dailyUsageStatus === loadingStatus.LOADING_STATUS_INIT && nextProps.selectedBillAccount > 0)
            this.props.getDailyUsageForAccount(nextProps.selectedBillAccount)
    }
   
    render(){
        if(this.props.rateMetaData.rateMetaData.isBasicSolar)
        return(
            <NoMonthlyUsageMessage t={this.props.t}/>
        )
        if((this.props.accountHistoryStatus === loadingStatus.LOADING_STATUS_SUCCESS
            && this.props.accountHistoryList.length <1)
            || this.props.isPendingTurnOn)
            return( 
                <div>
                    <LookingForUsageMonthly t={this.props.t}/>
                </div>
            )
        if (this.props.rateMetaData.rateMetaDataStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS
            || this.props.rateMetaData.rateMetaDataStatus === loadingStatus.LOADING_STATUS_INIT
            || this.props.accountHistoryStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS
            || this.props.accountHistoryStatus === loadingStatus.LOADING_STATUS_INIT
           
        )
        return(
            <div>
                <UsagePageLoading/>
            </div>
        )
        if(this.props.rateMetaData.rateMetaDataStatus === loadingStatus.LOADING_STATUS_SUCCESS 
            && this.props.rateMetaData.rateMetaData.summaryBilling === rateMetaDataConstants.SUMMARY_BILLING_PARENT)  
            return(
                <SummaryMasterMessage isCommercial={this.props.isCommercial} t={this.props.t}/>
            )
        if(this.props.rateMetaData.rateMetaDataStatus === loadingStatus.LOADING_STATUS_SUCCESS 
            && !this.props.rateMetaData.rateMetaData.isMetered)  
            return(
                <div><UnmeteredMessage isCommercial={this.props.isCommercial} t={this.props.t}/></div>
            )
        if(this.props.rateMetaData.rateMetaData.isMPower
            && !this.props.isTokenless)
            return(<NonTokenlessMessage t={this.props.t}/>)
        if(this.props.accountHistoryList.length < 1 || this.props.rateMetaData.rateMetaData.isMPower)
            return(
                <div>
                    <MonthlyDateRange t={this.props.t}/>
                    <NoMonthlyUsageMessage t={this.props.t}/>
                </div>
            )
            if(this.props.rateMetaData.rateMetaData.isNetBilled 
                && (!this.props.rateMetaData.rateMetaData.isCommercial))
            return(
                <div>
                    <MonthlyDateRange t={this.props.t}/>
                    <MonthlyNetContainer t={this.props.t}/>
                </div>)
            return(
                <div>
                    <MonthlyDateRange t={this.props.t}/>
                    <MonthlyUsageContainer t={this.props.t} i18n={this.props.i18n}/>
                </div>)
        

    }
}

const mapStateToProps = state => {
    return { 
        ...state.accountHistory, 
        ...state.accountInfo.usagePageUI,
        ...state.dailyUsage,
        ...state.accountInfo.payment,
        ...state.accountHistory,
        isTokenless: state.accountInfo.payment.payment.supportsRemotePrepay,
        selectedBillAccount: state.accountInfo.billAccount.selectedBillAccount,
        isCommercial: state.accountInfo.billAccount.selectedBillAccountDetails.isCommercial,
        rateMetaData: state.rateMetaData,
        isPendingTurnOn: state.accountInfo.billAccount.selectedBillAccountDetails.isPendingTurnOn
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getRateMetaDataForAccount: (billAccount) => {
            dispatch(getRateMetaData(billAccount))
        },
        getAccountHistoryForAccount: (billAccount) =>{
            dispatch(
                getAccountHistory(billAccount)
            )
        },
        getDailyUsageForAccount: (billAccount) => {
            dispatch(getDailyUsage(billAccount))
        }
    }
}

MonthlyUsageFormContainer.propTypes = {
    rateMetaData: PropTypes.object,
    selectedBillAccount: PropTypes.number,
    getRateMetaDataForAccount: PropTypes.func,
    dailyUsageStatus: PropTypes.string,
    paymentStatus: PropTypes.string,
    isTokenless: PropTypes.bool,
    dailyUsageList: PropTypes.array,
    getDailyUsageForAccount: PropTypes.func,
    accountHistoryStatus: PropTypes.string,
    getAccountHistoryForAccount: PropTypes.func,
    accountHistoryList: PropTypes.array,
    isCommercial: PropTypes.bool,
    isPendingTurnOn: PropTypes.bool
}

export default withTranslation("usageForms")(connect(mapStateToProps, mapDispatchToProps)(MonthlyUsageFormContainer));