import React from 'react'
import CircularProgress from '@mui/material/CircularProgress'

const ClosestPaymentLocationsLoading = () => {

    let loadingBoxes = []
    for (let i = 0; i < 5; i++) {
        loadingBoxes.push(<CircularProgress size={50} thickness={3} />)
    }

    return (
        <div className="row mb-2">
            {loadingBoxes.map((spinner, i) => (
                <div className="d-flex" key={i}>
                    <div className="col m-0 pl-2 pr-2 pb-2">
                        <div className="d-none d-lg-block payment-location-closest-box">
                            <div className="text-muted payment-location-closest-box-style d-flex align-items-center justify-content-center">
                                {spinner}
                            </div>
                        </div>
                        <div className="d-lg-none d-block mb-2">
                            <div className="text-muted payment-location-closest-box-style-small d-flex align-items-center justify-content-center">
                                {spinner}
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    )
}

export default ClosestPaymentLocationsLoading