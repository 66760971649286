import React from "react"
import Icon from '@mui/material/Icon'
import IconButton from '@mui/material/IconButton'
import config from 'my-account-config'
import { Trans } from 'react-i18next'
import PropTypes from 'prop-types'

class TextSignUpAlertMessage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            showTextAlertMessage: false,
        }

        this.showTextAlertMessageOnClick = this.showTextAlertMessageOnClick.bind(this)
    }

    showTextAlertMessageOnClick(showMessage){
        this.setState({
            showTextAlertMessage: showMessage
        })
    }

    render() {
        return (
            <div className="srp-alert-notice mb-4" style={{ fontSize: "0.875rem" }}>
                <div className="d-flex align-items-center">
                    <IconButton
                        onClick={()=>this.showTextAlertMessageOnClick(!this.state.showTextAlertMessage)}
                        className="button-focus"
                        style={{ textDecoration: 'none' }}
                        size="large">
                        <Icon>{this.state.showTextAlertMessage ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}</Icon>
                    </IconButton>
                    <span className="font-weight-bold">{this.props.t("Not receiving your SRP Service Alerts?")}</span>
                </div>
                <ul className="ml-4" style={this.state.showTextAlertMessage ? {display: 'block'} : {display: 'none'}}>
                    <li>
                        <Trans i18nKey="send_text_to" t={this.props.t}>
                            For Billing and Usage alerts, send texts to <span className="font-weight-bold">777797</span>. For Outage and Disconnect alerts, send texts to <span className="font-weight-bold">74722</span>
                        </Trans> 
                    </li>
                    
                    <li>
                        <ul>
                            <li>
                                <Trans i18nKey="start_text" t={this.props.t}>
                                    text <span className="font-weight-bold">START</span> to enable text alerts
                                </Trans>
                            </li>    
                            <li>
                                <Trans i18nKey="stop_text" t={this.props.t}>
                                    text <span className="font-weight-bold">STOP</span> to remove text alerts
                                </Trans>    
                            </li>
                            <li>
                                <Trans i18nKey="help_text" t={this.props.t}>
                                    text <span className="font-weight-bold">HELP</span> for more information
                                </Trans>
                            </li>
                        </ul>
                    </li>
                    <li>{this.props.t("Text message verification statuses")}</li>
                    <li>
                        <ul>
                        <li><span className="materialCheck"> </span><span> {this.props.t("signifies an enrolled phone number")}</span></li>
                        <li><span className="materialDots"> </span><span> {this.props.t("signifies a START message is needed to verify the phone number")}</span></li>
                        </ul>
                    </li>
                    <li>{this.props.t("Only US mobile numbers are eligible to receive SRP text alerts")}</li>
                    <li>{this.props.t("Message and data rates may apply")}</li>
                    <li>{this.props.t("Message frequency is driven by service impacting scenarios and could be three to five texts per scenario")}</li>
                    <li>
                    <Trans i18nKey="please_visit" t={this.props.t}>
                        Please visit <span className="font-weight-bold">
                            <a target="_new" href={`${config.srpnetBaseUrl}account/electric/manage-bill/billing-alerts-reminders`}>SRP Service Alerts</a>
                        </span> to learn more about the program and view the terms and conditions
                    </Trans>
                    </li>
                </ul>
            </div>
        )
    }
}

TextSignUpAlertMessage.propTypes = {
    t: PropTypes.func
}

export default TextSignUpAlertMessage