import PropTypes from 'prop-types'
import React from "react"
import { connect } from 'react-redux'
import PayByCreditCardLink from './pay-by-credit-card-link'

import { getCreditCardMultiPaymentUrl } from '../../../actions/auth/payment/payment-actions'
import { getBillingAddress } from '../../../actions/auth/bill_account/bill-account-actions'
import { getContacts } from '../../../actions/auth/contact/contact-actions'
import { LOADING_STATUS_INIT, LOADING_STATUS_IN_PROGRESS } from '../../../constants/loading-status-constants'

// export for unit testing
export class PayByCreditCardLinkContainer extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isMpowerPurchaseAckModalOpen: false,
            displaySafetyStatement: false
        }

        this.mpowerPurchaseAckModalOnClick = this.mpowerPurchaseAckModalOnClick.bind(this)
        this.toggleSafetyStatement = this.toggleSafetyStatement.bind(this)
    }

    componentDidMount() {
        if (this.props.selectedBillAccount !== 0)
            this.updateDataIfNeeded(this.props)
    }

    componentDidUpdate(prevProps) {
        if (this.props.selectedBillAccount !== 0 && this.props.selectedBillAccount !== prevProps.selectedBillAccount)
            this.updateDataIfNeeded(this.props)
    }

    mpowerPurchaseAckModalOnClick(isOpen) {
        this.setState({
            isMpowerPurchaseAckModalOpen: isOpen
        })
    }

    hasLoaded(props) {
        let loaded = true

        if (props.billingAddressStatus === LOADING_STATUS_INIT ||
            props.billingAddressStatus === LOADING_STATUS_IN_PROGRESS ||
            props.rateMetaDataStatus === LOADING_STATUS_INIT ||
            props.rateMetaDataStatus === LOADING_STATUS_IN_PROGRESS ||
            props.contactsStatus === LOADING_STATUS_INIT ||
            props.contactsStatus === LOADING_STATUS_IN_PROGRESS)
        {
            loaded = false
        }

        return loaded
    }

    hasLoadedCreditCardPaymentUrl(props) {
        let loaded = true

        if (props.creditCardPaymentUrlStatus === LOADING_STATUS_INIT ||
            props.creditCardPaymentUrlStatus === LOADING_STATUS_IN_PROGRESS) {
                loaded = false
            }

        return loaded
    }

    toggleSafetyStatement(display) {
        this.setState({
            displaySafetyStatement: display
        })
    }

    updateDataIfNeeded(props) {
        if (props.selectedBillAccount === 0)
            return

        if (props.contactsStatus === LOADING_STATUS_INIT) {
            props.getContacts(props.selectedBillAccount)
        }

        if (props.billingAddressStatus === LOADING_STATUS_INIT) {
            props.getMailingAddress(props.selectedBillAccount)
        }

        if (this.hasLoaded(props) && !this.hasLoadedCreditCardPaymentUrl(props)) {
            this.props.dispatch(getCreditCardMultiPaymentUrl())
        }
    }

    render() {
        return (<PayByCreditCardLink
                    {...this.props}
                    isMpowerPurchaseAckModalOpen={this.state.isMpowerPurchaseAckModalOpen}
                    mpowerPurchaseAckModalOnClick={this.mpowerPurchaseAckModalOnClick}
                    displaySafetyStatement={this.state.displaySafetyStatement}
                    toggleSafetyStatement={this.toggleSafetyStatement} />)
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getContacts: (billAccount) => {
            dispatch(getContacts(billAccount))
        },
        getMailingAddress: (billAccount) => {
            dispatch(getBillingAddress(billAccount))
        }
    }
}
const mapStateToProps = state => {
    return {
        billingAddressStatus: state.accountInfo.billAccount.billingAddressStatus,
        contactsStatus: state.accountInfo.contact.contactsStatus,
        creditCardPurchaseUrl: state.accountInfo.payment.creditCardPaymentUrl,
        rateMetaDataStatus: state.rateMetaData.rateMetaDataStatus,
        selectedBillAccount: state.accountInfo.billAccount.selectedBillAccount
    }
}

PayByCreditCardLinkContainer.propTypes = {
    billingAddressStatus: PropTypes.string,
    contactsStatus: PropTypes.string,
    rateMetaDataStatus: PropTypes.string,
    selectedBillAccount: PropTypes.number.isRequired,
    getContacts: PropTypes.func,
    getMailingAddress: PropTypes.func,
    dispatch: PropTypes.func
}

const PayByCreditCardLinkConnectedContainer = connect(mapStateToProps, mapDispatchToProps)(PayByCreditCardLinkContainer)
export default PayByCreditCardLinkConnectedContainer