import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import hereToHelp from '../../images/ads/hereToHelp.jpg'
import hereToHelpEs from '../../images/ads/hereToHelpEs.jpg'

const HereToHelpAd = ({t, isSpanish}) => (
    <Link to="#">
        <img alt={t("Here to help")} className="img-fluid w-100" src={isSpanish ? hereToHelpEs : hereToHelp}/>
    </Link>
)

HereToHelpAd.propTypes = {
    t: PropTypes.func.isRequired,
    isSpanish: PropTypes.bool.isRequired,
}

export default HereToHelpAd