import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import CircularProgress from '@mui/material/CircularProgress'

import * as loadingStatus from '../../../../constants/loading-status-constants'
import { myAccountConstants } from '../../../../constants/myaccount-constants'
import { getUnlinkedBillAccounts } from '../../../../actions/auth/bill_account/bill-account-actions'
import RestoreHiddenAccountsButtons from './restore-hidden-accounts-buttons'
import RestoreHiddenAccountsCheckboxes from './restore-hidden-accounts-checkboxes'
import UnlinkedBillAccountRows from './unlinked-bill-account-rows'
import { Trans } from 'react-i18next'

class RestoreHiddenAccountForm extends React.Component {
    constructor(props) {
        super(props)

        this.refreshData = this.refreshData.bind(this)
    }

    componentDidMount() {
        if (this.props.unlinkedBillAccountListStatus === loadingStatus.LOADING_STATUS_INIT
            || this.props.unlinkedBillAccountListStatus === loadingStatus.LOADING_STATUS_FAILURE) {
            this.props.getUnlinkedBillAccounts()
        }
    }

    componentDidUpdate() {
        if (this.props.unlinkedBillAccountListStatus === loadingStatus.LOADING_STATUS_INIT
            && Object.keys(this.props.pendingDelete).length === 0) {
            this.props.getUnlinkedBillAccounts()
        }
    }

    refreshData() {
        this.props.getUnlinkedBillAccounts()
    }

    render() {
        let supportPhoneNumber = this.props.selectedBillAccountDetails.isCommercial
            ? myAccountConstants.COMMERCIAL_CUSTOMER_SERVICE_PHONE_NUMBER_FMTTD
            : myAccountConstants.RESIDENTIAL_CUSTOMER_SERVICE_PHONE_NUMBER_FMTTD

        if (this.props.unlinkedBillAccountListStatus === loadingStatus.LOADING_STATUS_FAILURE) {
            return (
                <div className="p-2">
                    <div className="text-center">
                        <div className="mb-1" style={{color: "#9E2A2B"}}><i className="material-icons" style={{fontSize:"50px"}}>error_outline</i></div>
                        <div className="mb-1" style={{color: "#9E2A2B"}}><strong>{this.props.t("Something went wrong")}</strong></div>
                        <div className="mb-4">{this.props.t("We were unable to retrieve other bill accounts in your name.")}</div>
                    </div>
                    <div className="d-flex justify-content-end">
                        <a onClick={this.refreshData} className="btn srp-btn btn-lightblue text-white mr-2">{this.props.t("Try again")}</a>
                    </div> 
                </div>
            )
        }
        if (this.props.unlinkedBillAccountListStatus === loadingStatus.LOADING_STATUS_INIT
            || this.props.unlinkedBillAccountListStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS) {
            return (
                <div className="p-2">
                    <div className="mb-2 text-muted">{this.props.t("Please wait while we look for additional bill accounts in your name.")}</div>
                    <div className="d-flex justify-content-center">
                        <CircularProgress size={40} thickness={5} />
                    </div>
                </div>
            )
        }

        return this.props.unlinkedBillAccountList.length === 0
            ? (
                <div className="p-2">
                    <div className="mb-2 text-muted">
                        <Trans i18nKey="all_accounts_added" t={this.props.t}>
                            It appears all of your bill accounts have already been added. If you feel an account is missing,
                            please either add manually or call us at <span className="text-nowrap">{{phoneNumber : supportPhoneNumber}}</span>.
                        </Trans>
                    </div>
                </div>
            )
            : (
            <div className="p-2">
                <div className="mb-2 text-muted">
                    <Trans i18nKey="add_existing_account" t={this.props.t}>
                        To add an existing account, select one from the list below. If you feel an account is missing,
                        please either add manually or call us at <span className="text-nowrap">{{phoneNumber : supportPhoneNumber}}</span>.
                    </Trans>
                </div>
                <div className="row align-items-end">
                    <RestoreHiddenAccountsCheckboxes t={this.props.t}/>
                    <div className="col-12">
                        <UnlinkedBillAccountRows unlinkedBillAccountList={this.props.unlinkedBillAccountList} />
                    </div>
                    <div className="col-12 text-right">
                        <RestoreHiddenAccountsButtons hideForm={this.props.hideForm} t={this.props.t}/>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        pendingDelete: state.accountInfo.billAccount.pendingDelete,
        selectedBillAccountDetails: state.accountInfo.billAccount.selectedBillAccountDetails,
        unlinkedBillAccountList: state.accountInfo.billAccount.unlinkedBillAccountList,
        unlinkedBillAccountListStatus: state.accountInfo.billAccount.unlinkedBillAccountListStatus
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getUnlinkedBillAccounts: () => {
            dispatch(getUnlinkedBillAccounts())
        }
    }
}

RestoreHiddenAccountForm.propTypes = {
    selectedBillAccountDetails: PropTypes.object.isRequired,
    unlinkedBillAccountList: PropTypes.array.isRequired,
    unlinkedBillAccountListStatus: PropTypes.string.isRequired,
    pendingDelete: PropTypes.object.isRequired,

    getUnlinkedBillAccounts: PropTypes.func.isRequired,
    hideForm: PropTypes.func.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(RestoreHiddenAccountForm)