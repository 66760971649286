import React from 'react'
import { safetyNetConstants } from '../../../constants/safety-net-constants'
import { RSAA } from 'redux-api-middleware'

import { addSnackbarMessage, dismissSnackbarMessage } from '../../common/snackbar'
import utils from '../../../srp_modules/utils'

export const submitSafetyNetForm = (safetyNetDetails) => {

    let url = utils.createUrl('accounts/safetynetaddress/add')

    return dispatch => {
        return dispatch({
            [RSAA]: {
                endpoint: url,
                method: 'POST',
                body: JSON.stringify(safetyNetDetails),
                credentials: 'include',
                headers: { 'Content-Type': 'application/json' },
                types: [
                    safetyNetConstants.SUBMIT_SAFETYNET_FORM_REQUEST,
                    safetyNetConstants.SUBMIT_SAFETYNET_FORM_SUCCESS,
                    safetyNetConstants.SUBMIT_SAFETYNET_FORM_FAILURE,
                ]
            },
            meta: {
                debounce: {
                    leading: false,
                    trailing: false
                }
            }
        })
    }
}

export const updateSafetyNet = (safetyNetDetails) => {

    let url = utils.createUrl('accounts/safetynetaddress/update')

    return dispatch => {
        return dispatch({
            [RSAA]: {
                endpoint: url,
                method: 'POST',
                body: JSON.stringify(safetyNetDetails),
                credentials: 'include',
                headers: { 'Content-Type': 'application/json' },
                types: [
                    safetyNetConstants.UPDATE_SAFETYNET_REQUEST,
                    safetyNetConstants.UPDATE_SAFETYNET_SUCCESS,
                    safetyNetConstants.UPDATE_SAFETYNET_FAILURE,
                ]
            },
            meta: {
                debounce: {
                    leading: false,
                    trailing: false
                }
            }
        })
    }
}

export const removeSafetyNet = (safetyNetDetails) => async dispatch => {

    let url = utils.createUrl('accounts/safetynetaddress/delete')

    let deleteResult = await dispatch({
        [RSAA]: {
            endpoint: url,
            method: 'POST',
            body: JSON.stringify(safetyNetDetails),
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            types: [
                safetyNetConstants.DELETE_SAFETYNET_REQUEST,
                safetyNetConstants.DELETE_SAFETYNET_SUCCESS,
                safetyNetConstants.DELETE_SAFETYNET_FAILURE,
            ]
        },
        meta: {
            debounce: {
                leading: false,
                trailing: false
            }
        }
    })


    if (!deleteResult || deleteResult.error === true || !deleteResult.payload.success) {
        dispatch(addSnackbarMessage(<span>Remove Safety Net partner failed</span>))
        return deleteResult
    }

    dispatch(addSnackbarMessage(
        <span>Safety Net partner removed</span>,
        <button className="displayAsBlueText text-uppercase" onClick={() => {
            dispatch(dismissSnackbarMessage())
            dispatch(undoDeleteSafetyNet(safetyNetDetails))
        }}>Undo</button>))

    return deleteResult
}

export const undoDeleteSafetyNet = (safetyNetDetails) => async dispatch => {

    let undoResult = await dispatch({
        [RSAA]: {
            endpoint: utils.createUrl('accounts/safetynetaddress/add'),
            method: 'POST',
            body: JSON.stringify(safetyNetDetails),
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            types: [
                safetyNetConstants.UNDO_SAFETYNET_FORM_REQUEST,
                {
                    type: safetyNetConstants.UNDO_SAFETYNET_FORM_SUCCESS,
                    payload: safetyNetDetails
                },
                safetyNetConstants.UNDO_SAFETYNET_FORM_FAILURE,
            ]
        }
    })

    if (undoResult.error) {
        dispatch(addSnackbarMessage(<span>Undo remove Safety Net partner failed</span>))
    }
    else {
        dispatch(addSnackbarMessage(<span>Undo remove Safety Net partner successful</span>))
    }

    return undoResult
}

export const resetSafetyNetForm = () => ({
    type: safetyNetConstants.RESET_SAFETYNET_FORM
})
