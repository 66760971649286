import { isRSAA, RSAA } from 'redux-api-middleware'
import cookies from 'js-cookie'
import config from 'my-account-config'

const myAccountHeaderMiddleware = ({ getState }) => next => action => {
    if (!isRSAA(action))
        return next(action)

    let rsaa = action[RSAA]
    // NOTE: We shouldn't add custom MyAccount headers to API requests that are not our own
    if (!rsaa.endpoint.startsWith(config.apiBaseUrl))
        return next(action)

    let srpClient = cookies.get('maintenancetest') === undefined ? 'redesign' : 'maintenancetest'
    let billAccount = getBillAccountFromRequest(rsaa)

    let billAccountToken = (billAccount != null) ? getState().accountInfo.billAccount.billAccountTokens[`${billAccount}`] : null
    if (billAccountToken)
        rsaa.headers = { ...rsaa.headers, 'srp-client': srpClient, 'srp-account-token': billAccountToken }
    else
        rsaa.headers = { ...rsaa.headers, 'srp-client': srpClient }

    return next(action)
}

let getBillAccountFromRequest = (rsaa) => {
    let queryStringRegex = /(billaccount|accountnumber)=(\d+)/i

    let queryStringMatch = queryStringRegex.exec(rsaa.endpoint)
    if (queryStringMatch != null) {
        return queryStringMatch[2]
    }

    let contentBodyRegex = /(billaccount|accountnumber)":"?(\d+)/i
    let contentBodyMatch = contentBodyRegex.exec(rsaa.body)
    return (contentBodyMatch != null) ? contentBodyMatch[2] : null
}

export { myAccountHeaderMiddleware }