
export const OPEN_RESONSIVE_NAV = 'OPEN_RESONSIVE_NAV'
export const openResponsiveNav = () => ({
    type: OPEN_RESONSIVE_NAV
})

export const CLOSE_RESONSIVE_NAV = 'CLOSE_RESONSIVE_NAV'
export const closeResponsiveNav = () => ({
    type: CLOSE_RESONSIVE_NAV
})
