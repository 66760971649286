import { connect } from 'react-redux'

import GuestPaymentSubmittedCard from './guest-payment-submitted-card'

const mapStateToProps = state => ({
    referenceNumber: state.guestPaymentApiResponse.referenceNumber,
    confirmationEmailAddress: state.guestPaymentBankInfo.bankEmailAddress,
    confirmationEmailSent: state.guestPaymentApiResponse.confirmationEmailSent
})

export default connect(mapStateToProps)(GuestPaymentSubmittedCard)