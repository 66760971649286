import React from 'react'
import PropTypes from 'prop-types'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableHead from '@mui/material/TableHead'
import TableFooter from '@mui/material/TableFooter'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import { DateTime } from 'luxon'

import '../../styles/tables.css'
import { convertMonthlyDataForCharting } from '../../srp_modules/charting/monthly-data-dto'
import exportUsageModule from '../../srp_modules/charting/export'
import formatUsageModule from '../../srp_modules/charting/format'
import { dailyUsageChartTypeConstants } from '../../constants/daily-usage-chart-type-constants'

const MonthlyTableView = ({ usageData, currentUsageType, t }) => {
    let dtoUsageData = convertMonthlyDataForCharting(usageData, currentUsageType)
    let columns = exportUsageModule.getColumnsToShow(dtoUsageData)

    let superOffPeakStyle = columns.superOffPeakCol ? { display: 'table-cell' } : { display: 'none' }
    let superOffPeakStyleResp = columns.superOffPeakCol

    let offPeakStyle = columns.offPeakCol ? { display: 'table-cell' } : { display: 'none' }
    let offPeakStyleResp = columns.offPeakCol

    let shoulderStyle = columns.shoulderCol ? { display: 'table-cell' } : { display: 'none' }
    let shoulderStyleResp = columns.shoulderCol

    let onPeakStyle = columns.onPeakCol ? { display: 'table-cell' } : { display: 'none' }
    let onPeakStyleResp = columns.onPeakCol

    let totalStyle = columns.totalCol ? { display: 'table-cell' } : { display: 'none' }
    let totalStyleResp = columns.totalCol

    let unitOfMesaurement = currentUsageType === dailyUsageChartTypeConstants.COST
        ? t("cost")
        : currentUsageType === dailyUsageChartTypeConstants.DEMAND
            ? "kW"
            : "kWh"

    let showTotalsRow = currentUsageType !== dailyUsageChartTypeConstants.DEMAND
    let totals = showTotalsRow ? exportUsageModule.getUsageTotals(dtoUsageData, columns) : {}
    let totalLabel = exportUsageModule.getTotalLabel(currentUsageType)

    return (
        <div id="usageDataTable">
            <div className="d-lg-block d-none" style={{ overflow: 'auto' }}>
                <Table>
                    <TableHead >
                        <TableRow>
                            <TableCell className="border-0" padding="none">{t("Bill start date")}</TableCell>
                            <TableCell className="border-0" padding="none">{t("Bill end date")}</TableCell>
                            <TableCell className="border-0 text-right" padding="none" style={superOffPeakStyle}>{t("Super_off_peak_display", {unit : unitOfMesaurement})}</TableCell>
                            <TableCell className="border-0 text-right" padding="none" style={offPeakStyle}>{t("Off_peak_display", {unit : unitOfMesaurement})}</TableCell>
                            <TableCell className="border-0 text-right" padding="none" style={shoulderStyle}>{t("Shoulder_display", {unit : unitOfMesaurement})}</TableCell>
                            <TableCell className="border-0 text-right" padding="none" style={onPeakStyle}>{t("On_peak_display", {unit : unitOfMesaurement})}</TableCell>
                            <TableCell className="border-0 text-right" padding="none" style={totalStyle}>{t("Total_display", {unit : unitOfMesaurement})}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody className="srp-table-body">
                        {dtoUsageData.map((row, index) => {
                            return (
                                <TableRow key={index} style={{ border: 'none' }}>
                                    <TableCell padding="none">{DateTime.fromISO(row.billStartDate).toFormat('M/d/yyyy')}</TableCell>
                                    <TableCell padding="none">{DateTime.fromISO(row.billEndDate).toFormat('M/d/yyyy')}</TableCell>
                                    <TableCell className="text-right" padding="none" style={superOffPeakStyle}>
                                        {formatUsageModule.formatNumberByUsageType(currentUsageType, row.superOffPeak)}
                                    </TableCell>
                                    <TableCell className="text-right" padding="none" style={offPeakStyle}>
                                        {formatUsageModule.formatNumberByUsageType(currentUsageType, row.offPeak)}
                                    </TableCell>
                                    <TableCell className="text-right" padding="none" style={shoulderStyle}>
                                        {formatUsageModule.formatNumberByUsageType(currentUsageType, row.shoulder)}
                                    </TableCell>
                                    <TableCell className="text-right" padding="none" style={onPeakStyle}>
                                        {formatUsageModule.formatNumberByUsageType(currentUsageType, row.onPeak)}
                                    </TableCell>
                                    <TableCell className="text-right" padding="none" style={totalStyle}>
                                        {formatUsageModule.formatNumberByUsageType(currentUsageType, row.total)}
                                    </TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                    {showTotalsRow
                        ? <TableFooter className="srp-table-footer">
                            <TableRow>
                                <TableCell className="border-0" padding="none">{t("Combined_total_display", {unit : totalLabel})}</TableCell>
                                <TableCell className="border-0" padding="none" />
                                <TableCell className="border-0 text-right" padding="none" style={superOffPeakStyle}>
                                    {formatUsageModule.formatNumberByUsageType(currentUsageType, totals.superOffPeak)}
                                </TableCell>
                                <TableCell className="border-0 text-right" padding="none" style={offPeakStyle}>
                                    {formatUsageModule.formatNumberByUsageType(currentUsageType, totals.offPeak)}
                                </TableCell>
                                <TableCell className="border-0 text-right" padding="none" style={shoulderStyle}>
                                    {formatUsageModule.formatNumberByUsageType(currentUsageType, totals.shoulder)}
                                </TableCell>
                                <TableCell className="border-0 text-right" padding="none" style={onPeakStyle}>
                                    {formatUsageModule.formatNumberByUsageType(currentUsageType, totals.onPeak)}
                                </TableCell>
                                <TableCell className="border-0 text-right" padding="none" style={totalStyle}>
                                    {formatUsageModule.formatNumberByUsageType(currentUsageType, totals.usageTotal)}
                                </TableCell>
                                <TableCell className="border-0" padding="none" />
                                <TableCell className="border-0" padding="none" />
                            </TableRow>
                        </TableFooter>
                        : null
                    }
                </Table>
            </div>

            <div className="d-lg-none d-block">
                {dtoUsageData.map((row, index) => {
                    return (
                        <div key={"usageTableMobile" + index}>
                            <div className="row d-flex align-items-center">
                                <div className="col text-muted align-middle">{t("Bill start date")}</div>
                                <div className="col align-middle text-right">{DateTime.fromISO(row.billStartDate).toFormat('M/d/yyyy')}</div>
                            </div>

                            <hr className="mt-2" />
                            <div className="row d-flex align-items-center">
                                <div className="col text-muted align-middle">{t("Bill end date")}</div>
                                <div className="col align-middle text-right">{DateTime.fromISO(row.billEndDate).toFormat('M/d/yyyy')}</div>
                            </div>

                            {superOffPeakStyleResp
                                ? <div>
                                    <hr className="mt-2" />
                                    <div className="row">
                                        <div className="col text-muted align-middle">{t("Super_off_peak_display", {unit : unitOfMesaurement})}</div>
                                        <div className="col align-middle text-right">
                                            {formatUsageModule.formatNumberByUsageType(currentUsageType, row.superOffPeak)}
                                        </div>
                                    </div>
                                </div>
                                : null
                            }
                            {offPeakStyleResp
                                ? <div>
                                    <hr className="mt-2" />
                                    <div className="row">
                                        <div className="col text-muted align-middle">{t("Off_peak_display", {unit : unitOfMesaurement})}</div>
                                        <div className="col align-middle text-right">
                                            {formatUsageModule.formatNumberByUsageType(currentUsageType, row.offPeak)}
                                        </div>
                                    </div>
                                </div>
                                : null
                            }
                            {shoulderStyleResp
                                ? <div>
                                    <hr className="mt-2" />
                                    <div className="row">
                                        <div className="col text-muted align-middle">{t("Shoulder_display", {unit : unitOfMesaurement})}</div>
                                        <div className="col align-middle text-right">
                                            {formatUsageModule.formatNumberByUsageType(currentUsageType, row.shoulder)}
                                        </div>
                                    </div>
                                </div>
                                : null
                            }
                            {onPeakStyleResp
                                ? <div>
                                    <hr className="mt-2" />
                                    <div className="row">
                                        <div className="col text-muted align-middle">{t("On_peak_display", {unit : unitOfMesaurement})}</div>
                                        <div className="col align-middle text-right">
                                            {formatUsageModule.formatNumberByUsageType(currentUsageType, row.onPeak)}
                                        </div>
                                    </div>
                                </div>
                                : null
                            }
                            {totalStyleResp
                                ? <div>
                                    <hr className="mt-2" />
                                    <div className="row">
                                        <div className="col text-muted align-middle">{t("Total_display", {unit : unitOfMesaurement})}</div>
                                        <div className="col align-middle text-right">
                                            {formatUsageModule.formatNumberByUsageType(currentUsageType, row.total)}
                                        </div>
                                    </div>
                                </div>
                                : null
                            }

                            <hr className="mt-3" style={{ border: '2px solid lightgray' }} />
                        </div>
                    )
                })}
                {showTotalsRow
                    ? <div>
                        {superOffPeakStyleResp
                            ? <div className="row">
                                <div className="col text-muted align-middle">{t("Total_super_off_peak_display", {unit : unitOfMesaurement})}</div>
                                <div className="col align-middle text-right">
                                    {formatUsageModule.formatNumberByUsageType(currentUsageType, totals.superOffPeak)}
                                </div>
                            </div>
                            : null
                        }

                        {superOffPeakStyleResp && offPeakStyleResp ? <hr className="mt-2" /> : null}
                        {offPeakStyleResp
                            ? <div className="row">
                                <div className="col text-muted align-middle">{t("Total_off_peak_display", {unit : unitOfMesaurement})}</div>
                                <div className="col align-middle text-right">
                                    {formatUsageModule.formatNumberByUsageType(currentUsageType, totals.offPeak)}
                                </div>
                            </div>
                            : null
                        }

                        {(superOffPeakStyleResp || offPeakStyleResp) && shoulderStyleResp ? <hr className="mt-2" /> : null}
                        {shoulderStyleResp
                            ? <div className="row">
                                <div className="col text-muted align-middle">{t("Total_shoulder_display", {unit : unitOfMesaurement})}</div>
                                <div className="col align-middle text-right">
                                    {formatUsageModule.formatNumberByUsageType(currentUsageType, totals.shoulder)}
                                </div>
                            </div>
                            : null
                        }

                        {(superOffPeakStyleResp || offPeakStyleResp || shoulderStyleResp) && onPeakStyleResp ? <hr className="mt-2" /> : null}
                        {onPeakStyleResp
                            ? <div className="row">
                                <div className="col text-muted align-middle">{t("Total_on_peak_display", {unit : unitOfMesaurement})}</div>
                                <div className="col align-middle text-right">
                                    {formatUsageModule.formatNumberByUsageType(currentUsageType, totals.onPeak)}
                                </div>
                            </div>
                            : null
                        }

                        {(superOffPeakStyleResp || offPeakStyleResp || shoulderStyleResp || onPeakStyleResp) && totalStyleResp ? <hr className="mt-2" /> : null}
                        {totalStyleResp
                            ? <div className="row">
                                <div className="col text-muted align-middle">{t("Combined_total_display", {unit : unitOfMesaurement})}</div>
                                <div className="col align-middle text-right">
                                    {formatUsageModule.formatNumberByUsageType(currentUsageType, totals.usageTotal)}
                                </div>
                            </div>
                            : null
                        }
                        <hr className="mt-3" style={{ border: '2px solid lightgray' }} />
                    </div>
                    : null
                }

                <div className="d-flex justify-content-end mb-2">
                    <button
                        className="btn srp-btn btn-lightblue" onClick={() => backToTop()}>
                        {t("Back to top")}
                    </button>
                </div>
            </div>
        </div>
    )
}

MonthlyTableView.propTypes = {
    usageData: PropTypes.arrayOf(PropTypes.object),
    currentUsageType: PropTypes.string,
    t: PropTypes.func
}

export default MonthlyTableView

function backToTop() {
    document.getElementById("top-of-monthly-chart").scrollIntoView()
}