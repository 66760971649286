import { RSAA } from 'redux-api-middleware'
import { transferServiceConstants } from '../../../constants/transfer-service-constants'
import utils from '../../../srp_modules/utils'

export const setActiveStep = (activeStep) => ({
    type: transferServiceConstants.SET_ACTIVE_STEP,
    activeStep
})

export const setEndServiceDate = (endDate) => ({
    type: transferServiceConstants.SET_END_SERVICE_DATE,
    endDate
})

export const setEndServiceDateTouched = () => ({
    type: transferServiceConstants.SET_END_SERVICE_DATE_TOUCHED,
})

export const setStartServiceDate = (startDate) => ({
    type: transferServiceConstants.SET_START_SERVICE_DATE,
    startDate
})

export const setStartServiceDateTouched = () => ({
    type: transferServiceConstants.SET_START_SERVICE_DATE_TOUCHED,
})

export const selectPricePlan = (pricePlan) => ({
    type: transferServiceConstants.SELECT_PRICE_PLAN,
    pricePlan
})

export const selectSolarChoice = (solarChoice) => ({
    type: transferServiceConstants.SELECT_SOLAR_CHOICE,
    solarChoice
})

export const setElectricVehicleMake = (make) => ({
    type: transferServiceConstants.SET_EV_MAKE,
    make
})

export const setElectricVehicleModel = (model) => ({
    type: transferServiceConstants.SET_EV_MODEL,
    model
})

export const setElectricVehicleYear = (year) => ({
    type: transferServiceConstants.SET_EV_YEAR,
    year
})

export const validateElectricVehicleYear = (year) => ({
    type: transferServiceConstants.VALIDATE_EV_YEAR,
    year
})

export const selectElectricVehicleOwnership = (ownership) => ({
    type: transferServiceConstants.SELECT_EV_OWNERSHIP,
    ownership
})

export const selectElectricVehicleEquipmentType = (equipmentType) => ({
    type: transferServiceConstants.SELECT_EV_EQUIPMENT_TYPE,
    equipmentType
})

export const selectElectricVehicleInputVoltage = (voltage) => ({
    type: transferServiceConstants.SELECT_EV_VOLTAGE,
    voltage
})

export const selectElectricVehicleInputCurrent = (current) => ({
    type: transferServiceConstants.SELECT_EV_CURRENT,
    current
})

export const setServiceAndBillingInfo = (serviceBillingInfo) => ({
    type: transferServiceConstants.SET_SERVICE_AND_BILL_INFO,
    serviceBillingInfo
})

export const setCustomerInfo = (customerInfo) => ({
    type: transferServiceConstants.SET_CUSTOMER_INFO,
    customerInfo
})

export const setSafetyStmtAgreement = (doesAgree) => ({
    type: transferServiceConstants.SET_SAFETY_STMT_AGREE,
    doesAgree
})

export const sendMPowerBox = (sendBox) => ({
    type: transferServiceConstants.SEND_MPOWER_BOX,
    sendBox
})

export const submitTransferServiceForm = trasnferServiceForm => dispatch => {
    return dispatch({
        [RSAA]: {
            endpoint: utils.createUrl('forms/submitwebtransfer'),
            body: JSON.stringify(trasnferServiceForm),
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            types: [
                transferServiceConstants.SUBMIT_TRANSFER_SERVICE_REQUEST,
                transferServiceConstants.SUBMIT_TRANSFER_SERVICE_SUCCESS,
                transferServiceConstants.SUBMIT_TRANSFER_SERVICE_FAILURE
            ]
        }
    })
}