import { rateMetaDataConstants } from '../../../constants/rate-meta-data-constants'
import { billAccountConstants } from '../../../constants/bill-account-constants'
import * as loadingStatus from '../../../constants/loading-status-constants'

const initialState = {
    rateMetaData:{
        hasCost: true,
        isMetered: true,
        isMPower: false,
        isDemand: false,
        hasOnPeakDemand: false,
        isSolar: false,
        hasMontlhyUsageOnly: false,
        isNetBilled: false,
        isIDRRate: false,
        isNonSmartMeterRate: false,
        summaryBilling: rateMetaDataConstants.SUMMARY_BILLING_NONE,
        isCommercial: false,
        isBusinessOnResidentialPlan: false,
        displayName: "",
        firstName: "",
        middleName: "",
        lastName: "",
        customerName: "",
        authorizedSpouseFirstName: ""
    },
    rateMetaDataStatus: loadingStatus.LOADING_STATUS_INIT
}

export default function rateMetaDataReducer(state = initialState, action){
    switch(action.type){
        case rateMetaDataConstants.GET_RATE_META_DATA_REQUEST:
            return { ...state, rateMetaDataStatus: loadingStatus.LOADING_STATUS_IN_PROGRESS }
        case rateMetaDataConstants.GET_RATE_META_DATA_SUCCESS:
            return {
                ...state, 
                rateMetaData: action.payload,
                rateMetaDataStatus: loadingStatus.LOADING_STATUS_SUCCESS
            }
        case rateMetaDataConstants.GET_RATE_META_DATA_FAILURE:
            return { ...state, rateMetaData: { ...initialState.rateMetaData }, rateMetaDataStatus: loadingStatus.LOADING_STATUS_FAILURE }
        case billAccountConstants.SELECT_BILL_ACCOUNT_SUCCESS:
            return initialState
        default:
            return state
    }
}