import i18n from '../../i18n'
export const getGenerationData = function(data,isCost){
    const t = i18n.t
    let usageArray = isCost ? setCost(data) : setKwh(data, t); 
    let range = getRange(json2array(usageArray,["superOffPeak", "offPeak", "shoulder", "onPeak","total"]));
    let mins = getMinSeries(json2array(usageArray,["superOffPeak", "offPeak", "shoulder", "onPeak","total"]),range.minY);										
    let maxs = getMaxSeries(json2array(usageArray,["superOffPeak", "offPeak", "shoulder", "onPeak","total"]), range.maxY);
    usageArray = addMinsAndMaxes(usageArray, mins.mins,maxs.maxs);
    let consumptionArray = [];
    let generationArray = [];
    let min = range.maxY > Math.abs(range.minY) ? -range.maxY : range.minY
    let max = range.maxY > Math.abs(range.minY) ? range.maxY : - range.minY
    usageArray.forEach(function(e) {
        consumptionArray.push(
            {
                "day": e.day,
                "Date": e.day,
                "date": e.date,
                "billStartDate": e.billStartDate,
                "superOffPeak": e.superOffPeak > 0 ? e.superOffPeak : 0,
                "shoulder": e.shoulder > 0 ?  e.shoulder : 0,
                "offPeak": e.offPeak > 0 ? e.offPeak : 0,
                "onPeak": e.onPeak > 0 ? e.onPeak : 0,
                "total": e.total > 0 ? e.total : 0,
                "totalCost" : e.total,
                "dailyCost": e.dailyCost,
                "min": 0,
                "max": e.max === 0 ? max : e.max,
                "tooltipSuperOffPeak": e.tooltipSuperOffPeak,
                "tooltipOffPeak": e.tooltipOffPeak,
                "tooltipShoulder": e.tooltipShoulder,
                "tooltipOnPeak": e.tooltipOnPeak,
                "tooltipTotal": e.tooltipTotal    
            }
        )
        generationArray.push(
            {
                "day": e.day,
                "Date": e.day,
                "date": e.date,
                "billStartDate": e.billStartDate,
                "superOffPeak": e.superOffPeak < 0 ? e.superOffPeak : 0,
                "shoulder": e.shoulder < 0 ?  e.shoulder : 0,
                "offPeak": e.offPeak < 0 ? e.offPeak : 0,
                "onPeak": e.onPeak < 0 ? e.onPeak : 0,
                "total": e.total < 0 ? e.total : 0,
                "dailyCost": e.dailyCost,
                "totalCost" : e.total,
                "min": e.min === 0 ? min : e.min,
                "max": 0,
                "tooltipSuperOffPeak": e.tooltipSuperOffPeak,
                "tooltipOffPeak": e.tooltipOffPeak,
                "tooltipShoulder": e.tooltipShoulder,
                "tooltipOnPeak": e.tooltipOnPeak,
                "tooltipTotal": e.tooltipTotal
            }
        )
    }, this);

    return {
        "consumptionArray": consumptionArray, 
        "generationArray": generationArray,
        "min": min,
        "max": max
    }
}

export const getTooltipYCoordinates = function(consumptionArray,maxY,y){
    let coordinates = [];
    consumptionArray.forEach(function(e){
        coordinates.push(y(maxY-e.max));
    })
    return coordinates;
}
function setCost(data){
    let costData = [];
    data.forEach(function(e){
        costData.push(
            {
                "day": e.day,
                "date": e.date,
                "superOffPeak": e.superOffPeak,
                "offPeak": e.offPeak,
                "shoulder": e.shoulder,
                "onPeak": e.onPeak,
                "total": e.total,
                "dailyCost": e.dailyCost,
                "totalKwh": e.total,
                "tooltipSuperOffPeak": e.superOffPeak,
                "tooltipOffPeak": e.offPeak,
                "tooltipShoulder": e.shoulder,
                "tooltipOnPeak": e.onPeak,
                "tooltipTotal": e.total
            }
        )
    });
    return costData;
}
function setKwh(data, t){
    let kwhData = [];
    data.forEach(function(e){
        kwhData.push(
            {
                "day": e.day,
                "date": e.date,
                "billStartDate": e.billStartDate,
                "dailyCost": e.dailyCost,
                "superOffPeak": e.superOffPeak,
                "offPeak": e.offPeak,
                "shoulder": e.shoulder,
                "onPeak": e.onPeak,
                "total": e.total,
                "totalKwh": e.total,
                "tooltipSuperOffPeak": t("usagePage::Super off-peak") + " kWh: " + e.superOffPeak,
                "tooltipOffPeak": t("usagePage::Off-peak") + " kWh: " + e.offPeak,
                "tooltipShoulder": t("usagePage::Shoulder") + " kWh: " + e.shoulder,
                "tooltipOnPeak": t("usagePage::On-peak") + " kWh: " + e.onPeak,
                "tooltipTotal": t("usagePage::Total") + " kWh: " + e.total
            }
        )
    });
    return kwhData;
}

function getRange(){
    let args = Array.prototype.slice.call(arguments)[0];
            if (args.length === 0)
                return new Array();
    let seriesMin = [];
    let seriesMax = [];
    for(let i=0; i<args.length; i++){
        let itemMax = 0;
        let itemMin = 0;
        for( let y=0; y<args[i].length; y++)
        {
            if(args[i][y] < 0)
            {
                itemMin += args[i][y];
            }
            if(args[i][y] > 0)
            {
                itemMax += args[i][y];
            }
        }
        seriesMin.push(itemMin);
        seriesMax.push(itemMax);
    }
    if (Math.min(...seriesMin) == 0){
        return {minY: 0, maxY: Math.max(...seriesMax) * 1.1};
    }
    let minMax = Math.max(-1 * Math.min(...seriesMin), Math.max(...seriesMax))
    return {minY: -1 * (minMax * 1.1), maxY:  minMax * 1.1};

}

function getMinSeries(series, minY){
    if (series.length === 0)
        return new Array();
    let mins = [];
    series.forEach(function(item){
        let currentTotalMin = 0;
        item.forEach(function(seriesItem){
            if(seriesItem < 0){
                currentTotalMin += seriesItem;
            }

        })
        mins.push(minY - currentTotalMin);
    }); 
    return {mins: mins};
}

function getMaxSeries(series, maxY){
    if (series.length === 0)
        return new Array();
    let maxs = [];
    series.forEach(function(item){
        let currentTotalMax = 0;
        item.forEach(function(seriesItem){
        if(seriesItem > 0){
            currentTotalMax += seriesItem;
        }

    })
        maxs.push(maxY - currentTotalMax);
    }); 
return {maxs: maxs}; 
}


function addMinsAndMaxes(data, mins, maxs){
    for(let i = 0; i < data.length; i++){
        data[i].min = mins[i]
        data[i].max = maxs[i]
    }
    return data;
}

function json2array(json, usageKeys){
    let result = [];
    json.forEach(function(obj){
        let keys = Object.keys(obj);
        let currentObject = [];
        keys.forEach(function(key){
            if(usageKeys.indexOf(key) > -1){
                currentObject.push(obj[key]);
            }
        });
    result.push(currentObject);
    });
    return result;
}