import React from 'react'
import PropTypes from 'prop-types'
import TextField from '@mui/material/TextField'
import MenuItem from '@mui/material/MenuItem'
import Icon from '@mui/material/Icon'

class AssociatedAccountRowsHeader extends React.Component {
    constructor(props) {
        super(props)

        this.handleSortChange = this.handleSortChange.bind(this)
    }

    handleSortChange(event) {
        this.props.setSortBy(event.target.value)
    }

    render() {
        return (
            <div className="container">
                <div className="row pt-3 pb-3 associated-account-row-header-info-box">
                    <div className="col" style={{ color: '#087ED8' }} />
                    <div className="col-4 pr-3">
                        <TextField
                            select
                            fullWidth
                            label={this.props.t("Sort By")}
                            value={this.props.sortedBy}
                            onChange={this.handleSortChange}
                            className="mt-0"
                        >
                            <MenuItem value="Nickname Asc"><Icon style={{ fontSize: 14 }}>arrow_upward</Icon> {this.props.t("Nickname")}</MenuItem>
                            <MenuItem value="Nickname Desc"><Icon style={{ fontSize: 14 }}>arrow_downward</Icon> {this.props.t("Nickname")}</MenuItem>
                            <MenuItem value="Account number Asc"><Icon style={{ fontSize: 14 }}>arrow_upward</Icon> {this.props.t("Account number")}</MenuItem>
                            <MenuItem value="Account number Desc"><Icon style={{ fontSize: 14 }}>arrow_downward</Icon> {this.props.t("Account number")}</MenuItem>
                            <MenuItem value="Service address Asc"><Icon style={{ fontSize: 14 }}>arrow_upward</Icon> {this.props.t("Service address")}</MenuItem>
                            <MenuItem value="Service address Desc"><Icon style={{ fontSize: 14 }}>arrow_downward</Icon> {this.props.t("Service address")}</MenuItem>
                        </TextField>
                    </div>
                    <div className="col-5 pl-0">
                        <TextField
                            fullWidth
                            label={this.props.t("Search")}
                            value={this.props.filterText}
                            className="mt-0"
                            onKeyUp={this.props.searchKeysUp}
                            onChange={this.props.searchOnChange}
                        />
                    </div>
                </div>
                <div className="row py-3 associated-account-row-header-column-header">
                    <div className="d-flex col-3 pr-0">
                        {this.props.t("Nickname")}
                    </div>
                    <div className="d-flex col-9 pr-2">
                        <div className="col-3 d-flex px-0">
                            {this.props.t("Account Number")}
                        </div>
                        <div className="col-6 d-flex px-0 ml-3">
                            {this.props.t("Service Address")}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

AssociatedAccountRowsHeader.propTypes = {
    setSortBy: PropTypes.func.isRequired,
    sortedBy: PropTypes.string.isRequired,

    filterText: PropTypes.string.isRequired,
    searchKeysUp: PropTypes.func.isRequired,
    searchOnChange: PropTypes.func.isRequired
}
export default AssociatedAccountRowsHeader