import React from "react"
import PropTypes from 'prop-types'
import { Formik } from 'formik'
import * as Yup from 'yup'
import validateFormik from '../../../../srp_modules/validate-formik'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import { contactConstants } from '../../../../constants/contact-constants'
import utils from '../../../../srp_modules/utils'
import format from '../../../../srp_modules/format'
import { myAccountConstants } from '../../../../constants/myaccount-constants'
import PhoneAddForm from './phone-add-form'

let addStep = {
    addButton: 0,
    addContact: 1,
    copySubscriptions: 2
}

export function getContactArrayFromSubscribed(subscribedContacts) {
    let contactArray = []
    for (let property in subscribedContacts) {
        if (Object.prototype.hasOwnProperty.call(subscribedContacts, property)) {
            contactArray.push(subscribedContacts[property].contact)
        }
    }

    return contactArray
}

class PhoneAdd extends React.Component {
    constructor(props) {
        super(props)

        this.validationSchema = Yup.object().shape({
            contactAddress: validateFormik.phoneNumberRequired,
            contactLabel: validateFormik.nickNameMaxCharacter
        })

        this.state = {
            step: addStep.addButton,
            copyContactAddress: 'none',
            newContact: {
                contactAddress: '',
                contactType: contactConstants.CONTACT_TYPE.PHONE,
                contactLabel: ''
            },
            makeNewContactPrimary: false
        }

        this.setStep = this.setStep.bind(this)
        this.saveContactInfo = this.saveContactInfo.bind(this)
        this.saveCopyContactAddress = this.saveCopyContactAddress.bind(this)
        this.addContact = this.addContact.bind(this)
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.openItem !== 'addPhone') {
            this.setState({
                step: addStep.addButton
            })
        }
    }

    setStep(step) {
        this.setState({
            step: step
        })
    }

    saveContactInfo(values, callback) {
        this.setState(prevState => ({
            newContact: {
                ...prevState.newContact,
                contactAddress: values.contactAddress,
                contactLabel: values.contactLabel
            },
            copyContactAddress: "none",
            makeNewContactPrimary: values.makePrimary
        }), callback)
    }

    saveCopyContactAddress(contactAddress) {
        this.setState({
            copyContactAddress: contactAddress
        })
    }

    addContact() {
        this.props.addContactsClick(this.state.newContact, this.state.makeNewContactPrimary)
    }

    addContactsAndCopySubscriptionsClick() {
        if (this.state.copyContactAddress === "none") {
            this.props.addContactsClick(this.state.newContact, this.state.makeNewContactPrimary)
        } else {
            this.props.addContactsAndCopySubscriptionsClick(this.state.newContact, this.state.copyContactAddress, this.state.makeNewContactPrimary)
        }
    }

    render() {
        return (
            <div>
                {this.state.step === addStep.addButton &&
                    <div style={{textAlign: "right", paddingTop: "10px"}}>
                        <button className="btn srp-btn btn-lightblue ml-2" disabled={this.props.alertInfo.status === myAccountConstants.CUSTOMER_ACCOUNT_DATA_STATUS.MAXCONTACTLIMITREACHED && this.props.alertInfo.contactType === contactConstants.CONTACT_TYPE.PHONE} onClick={() => {
                            this.props.hideAlert()
                            this.props.setOpenItem('addPhone')
                            this.setStep(addStep.addContact)
                        }}>{this.props.t("Add")}</button>
                    </div>
                }
                {this.state.step === addStep.addContact &&
                    <div style={{paddingTop: "10px"}}>
                        <div style={{position: "absolute", borderBottom: "1px solid rgba(204,204,204,1)", left: "14px", width: "93%"}} />
                        <Formik
                            initialValues={{
                                contactAddress: '',
                                contactLabel: '',
                                makePrimary: false
                            }}
                            validationSchema={this.validationSchema}
                            onSubmit={(values, others) => {
                                this.saveContactInfo(values, () => {
                                others.resetForm()
                                if (utils.isEmptyObject(this.props.subscribedContacts.phones)) {
                                    this.addContact()
                                    this.setStep(addStep.addButton)
                                    this.props.setOpenItem('')
                                } else {
                                    this.setStep(addStep.copySubscriptions)
                                }
                                })
                            }}>
                            {formikProps => (<PhoneAddForm
                                goToAddButtonStep={() => this.setStep(addStep.addButton)}
                                noSubscribedPhoneContacts={utils.isEmptyObject(this.props.subscribedContacts.phones)}
                                {...formikProps} 
                                t={this.props.t}/>)}
                        </Formik>
                    </div>
                }
                {this.state.step === addStep.copySubscriptions &&
                    <div style={{paddingTop:"10px"}}>
                        <div>You're adding <b>{format.formatPhoneNumber(this.state.newContact.contactAddress)}</b>. Would you like to have the same alerts as one of your other phone numbers?</div>
                        <div style={{paddingTop:"20px"}}>
                            <Select value={this.state.copyContactAddress} fullWidth={true} onChange={(event) => { this.saveCopyContactAddress(event.target.value)}}>
                                <MenuItem key="none" value="none">No thanks</MenuItem>
                                {getContactArrayFromSubscribed(this.props.subscribedContacts.phones).map((phone) =>
                                    <MenuItem key={phone.contactAddress} value={phone.contactAddress}>{`Add ${phone.contactAddressDisplay}'s alerts`}</MenuItem>
                                )}
                            </Select>
                        </div>
                        <div className="d-flex flex-row-reverse" style={{paddingTop:"15px"}}>
                        <button className="btn srp-btn btn-green ml-2" onClick={() => {
                            this.addContactsAndCopySubscriptionsClick()
                            this.setStep(addStep.addButton)
                        }}>Add</button><button className="btn srp-btn btn-lightblue" onClick={() => this.setStep(addStep.addButton)}>Cancel</button>
                        </div>
                    </div>
                }

            </div>
        )
    }
}

PhoneAdd.propTypes = {
    subscribedContacts: PropTypes.object.isRequired,
    addContactsClick: PropTypes.func.isRequired,
    addContactsAndCopySubscriptionsClick: PropTypes.func.isRequired,
    hideAlert: PropTypes.func.isRequired,
    openItem: PropTypes.string,
    setOpenItem: PropTypes.func.isRequired,
    alertInfo: PropTypes.object.isRequired
}

export default PhoneAdd