import React from 'react'
import PropTypes from 'prop-types'

import { myAccountConstants } from '../../../constants/myaccount-constants'

const SummaryMasterCard = ({isCommercial, t}) => (
    <div className="srp-card">
        <div className="srp-card-header">{t("My usage")}</div>
        <div className="srp-card-body">
            <div className="srp-card-summary d-flex justify-content-around" style={{ backgroundColor: '#747474' }}>
                <div className="d-flex flex-column align-items-center">
                    <div className="h4 text-white">{t("N/A")}</div>
                    {t("No usage information")}
                </div>
            </div>
            <div className="srp-card-details">
                <span style={{ color: '#707070' }}>
                    {t("unable to display summary billing account", {phoneNumber : isCommercial ? myAccountConstants.COMMERCIAL_CUSTOMER_SERVICE_PHONE_NUMBER_FMTTD : myAccountConstants.RESIDENTIAL_CUSTOMER_SERVICE_PHONE_NUMBER_FMTTD})}
                </span>
            </div>
        </div>
    </div>
)

SummaryMasterCard.propTypes = {
    isCommercial: PropTypes.bool,
    t: PropTypes.func.isRequired
}

export default SummaryMasterCard
