import React from 'react'
import PropTypes from 'prop-types'
import { Formik } from 'formik'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Radio from '@mui/material/Radio'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import CircularProgress from '@mui/material/CircularProgress'
import TextField from '@mui/material/TextField'

import { Trans } from 'react-i18next'

import PoolIconBlue from '../../../images/budget_billing/pool-blue.svg'
import PoolIconGrey from '../../../images/budget_billing/pool-grey.svg'
import EvapCoolerIconBlue from '../../../images/budget_billing/evap-cooler-blue.svg'
import EvapCoolerIconGrey from '../../../images/budget_billing/evap-cooler-grey.svg'

import { budgetBillingConstants } from '../../../constants/budget-billing-constants'
import { withRouter } from '../../../srp_modules/with-router'
import * as loadingStatus from '../../../constants/loading-status-constants'

import utils from '../../../srp_modules/utils'
import validate from '../../../srp_modules/validate'

const budgetBillingBtn = {
    borderRadius: '10px',
    border: '2px solid #DADADB',
    backgroundColor: 'white',
    color: '#087ED8',
    fontWeight: '400',
    width: '100%'
}

const budgetBillingBtnSelected = {
    borderRadius: '10px',
    border: '3px solid #087ED8',
    backgroundColor: 'white',
    color: '#087ED8',
    fontWeight: '700',
    width: '100%'
}

const iconStyle = {
    width: '40px',
    height: '100%'
}

const amountDueBoxStyle = {
    backgroundColor: 'rgba(224, 231, 240, 1)',
    color: '#195D93',
    height: '60px',
    borderRadius: '4px'
}

const BudgetBillingForm = ({ isCommercial, customerName, primaryEmail, serviceAddress, budgetBillingEstimate,
    squareFootage, getSquareFootage, electricType, geElectricType, selectedPool, selectPoolOnClick, selectedEvapCooler, selectEvapCoolerOnClick,
    t, i18n,
    createAndSubmitBudgetBillingForm, submitBudgetBillingStatus, router }) => {

    return (
        <Formik
            initialValues={{
                emailAddress: primaryEmail
            }}
            validate={(values) => validateForm(values)}
            onSubmit={(values) => {
                createAndSubmitBudgetBillingForm(electricType, squareFootage, selectedEvapCooler, selectedPool, values.emailAddress)
            }}>
            {({ values, errors, touched, handleBlur, handleChange, handleSubmit }) => {
                let emailError = touched.emailAddress && errors.email !== undefined
                let invalidEmailError = touched.emailAddress && errors.invalidEmail !== undefined

                const isSpanish = (i18n.language === "es")
                const supportNumber = utils.GetCustomerServicePhoneNumber(isCommercial, isSpanish)
                const supportNumberFmt = utils.GetCustomerServicePhoneNumberFmt(isCommercial, isSpanish)
            
                return (
                    <form onSubmit={handleSubmit}>
                        <div className="row">
                            <div className="col-lg-6 col-12 mt-lg-4">
                                <div className="srp-card-header">{t("Account & home information")}</div>
                                <div className="srp-card-body text-muted">
                                    <div className="srp-card-details">
                                        <h4 className="mb-3" style={{ color: 'rgba(51, 51, 51, 0.537)' }}>{t("SRP account information")}</h4>
                                        <div className="d-lg-block d-none">
                                            <div>
                                                <span className="font-weight-bold mr-2">{t("Name")}</span>
                                                <span>{customerName}</span>
                                            </div>
                                            <div className="mb-3">
                                                <span className="font-weight-bold mr-2">{t("Service address")}</span>
                                                <span style={{ textTransform: 'capitalize' }}>{(serviceAddress.streetAddress).toLowerCase()}</span>
                                                <span style={{ textTransform: 'capitalize' }}>{` ${(serviceAddress.cityName).toLowerCase()}, `}</span>
                                                <span>AZ</span>
                                                <span>{` ${serviceAddress.zipCode}`}</span>
                                            </div>
                                        </div>
                                        <div className="d-lg-none d-block mb-3">
                                            <div className="row">
                                                <div className="col-5 font-weight-bold">{t("Name")}</div>
                                                <div className="col-7 text-right">{customerName}</div>
                                            </div>
                                            <div className="row">
                                                <div className="col-6 font-weight-bold">{t("Service address")}</div>
                                                <div className="col-6 text-right">
                                                    <div style={{ textTransform: 'capitalize' }}>{(serviceAddress.streetAddress).toLowerCase()}</div>
                                                    <div>
                                                        <span style={{ textTransform: 'capitalize' }}>{` ${(serviceAddress.cityName).toLowerCase()}, `}</span>
                                                        <span>AZ</span>
                                                        <span>{` ${serviceAddress.zipCode}`}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {budgetBillingEstimate
                                            ? <div className="col-lg-7 col-12 d-flex align-items-center mb-3" style={amountDueBoxStyle}>
                                                <div className="col-7 text-left p-0">{t("Estimated monthly payment amount")}</div>
                                                <div className="col-5 font-weight-bold text-right" style={{ fontSize: '1.25rem' }}>
                                                    ${budgetBillingEstimate.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                </div>
                                            </div>
                                            : null
                                        }

                                        <div className="mb-4">
                                            <span>{t("A confirmation will be sent to")} </span>
                                            {primaryEmail.length > 0
                                                ? <span className="font-weight-bold">{(primaryEmail).toLowerCase()}</span>
                                                : <div>
                                                    <TextField
                                                        fullWidth
                                                        name="emailAddress"
                                                        value={values.emailAddress}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        helperText={
                                                            emailError
                                                                ? t(errors.email)
                                                                : invalidEmailError
                                                                    ? t(errors.invalidEmail)
                                                                    : ''}
                                                        error={emailError || invalidEmailError} />
                                                </div>
                                            }
                                        </div>

                                        {!budgetBillingEstimate
                                            ? <div>
                                                <h4 className="mb-3" style={{ color: 'rgba(51, 51, 51, 0.537)' }}>{t("Home information")}</h4>
                                                <div className="mb-3">
                                                    <label className="mb-0">{t("Square footage")}</label>
                                                    <Select
                                                        value={squareFootage}
                                                        onChange={event => getSquareFootage(event.target.value)}
                                                        fullWidth>
                                                        <MenuItem value={"0-800"}><span className="ml-2">0-800</span></MenuItem>
                                                        <MenuItem value={"801-1200"}><span className="ml-2">801-1200</span></MenuItem>
                                                        <MenuItem value={"1201-1400"}><span className="ml-2">1201-1400</span></MenuItem>
                                                        <MenuItem value={"1401-1600"}><span className="ml-2">1401-1600</span></MenuItem>
                                                        <MenuItem value={"1601-1800"}><span className="ml-2">1601-1800</span></MenuItem>
                                                        <MenuItem value={"1801-2000"}><span className="ml-2">1801-2000</span></MenuItem>
                                                        <MenuItem value={"2001-2500"}><span className="ml-2">2001-2500</span></MenuItem>
                                                        <MenuItem value={"2501-3000"}><span className="ml-2">2501-3000</span></MenuItem>
                                                        <MenuItem value={"3001-3500"}><span className="ml-2">3001-3500</span></MenuItem>
                                                        <MenuItem value={"3501-4000"}><span className="ml-2">3501-4000</span></MenuItem>
                                                        <MenuItem value={"4001-4500"}><span className="ml-2">4001-4500</span></MenuItem>
                                                        <MenuItem value={"4501-5000"}><span className="ml-2">4501-5000</span></MenuItem>
                                                        <MenuItem value={"5000+"}><span className="ml-2">5000+</span></MenuItem>
                                                    </Select>
                                                </div>
                                                <div>
                                                    <label className="mb-0">{t("Home's electric type")}</label>
                                                    <div className="d-lg-block d-none">
                                                        <FormGroup row className="ml-2">
                                                            <FormControlLabel
                                                                control={<Radio
                                                                    checked={electricType === budgetBillingConstants.ELECTRIC_TYPE.ALL_ELECTRIC}
                                                                    onChange={event => geElectricType(event.target.value)}
                                                                    value={budgetBillingConstants.ELECTRIC_TYPE.ALL_ELECTRIC}
                                                                    color="primary"
                                                                />}
                                                                label={<span style={{ color: '#087ED8' }}>{t("All electric")}</span>}
                                                            />
                                                            <FormControlLabel
                                                                control={<Radio
                                                                    checked={electricType === budgetBillingConstants.ELECTRIC_TYPE.DUAL}
                                                                    onChange={event => geElectricType(event.target.value)}
                                                                    value={budgetBillingConstants.ELECTRIC_TYPE.DUAL}
                                                                    color="primary"
                                                                />}
                                                                label={<span style={{ color: '#087ED8' }}>{t("Dual system")}</span>}
                                                            />
                                                            <FormControlLabel
                                                                control={<Radio
                                                                    checked={electricType === budgetBillingConstants.ELECTRIC_TYPE.UNKNOWN}
                                                                    onChange={event => geElectricType(event.target.value)}
                                                                    value={budgetBillingConstants.ELECTRIC_TYPE.UNKNOWN}
                                                                    color="primary"
                                                                />}
                                                                label={<span style={{ color: '#087ED8' }}>{t("Don't know")}</span>}
                                                            />
                                                        </FormGroup>
                                                    </div>
                                                    <div className="d-lg-none d-block">
                                                        <FormGroup className="ml-2" row={false}>
                                                            <FormControlLabel
                                                                control={<Radio
                                                                    checked={electricType === budgetBillingConstants.ELECTRIC_TYPE.ALL_ELECTRIC}
                                                                    onChange={event => geElectricType(event.target.value)}
                                                                    value={budgetBillingConstants.ELECTRIC_TYPE.ALL_ELECTRIC}
                                                                    color="primary"
                                                                />}
                                                                label={<span style={{ color: '#087ED8' }}>{t("All electric")}</span>}
                                                                className="mb-0 mr-0"
                                                            />
                                                            <FormControlLabel
                                                                control={<Radio
                                                                    checked={electricType === budgetBillingConstants.ELECTRIC_TYPE.DUAL}
                                                                    onChange={event => geElectricType(event.target.value)}
                                                                    value={budgetBillingConstants.ELECTRIC_TYPE.DUAL}
                                                                    color="primary"
                                                                />}
                                                                label={<span style={{ color: '#087ED8' }}>{t("Dual system")}</span>}
                                                                className="mb-0 mr-0"
                                                            />
                                                            <FormControlLabel
                                                                control={<Radio
                                                                    checked={electricType === budgetBillingConstants.ELECTRIC_TYPE.UNKNOWN}
                                                                    onChange={event => geElectricType(event.target.value)}
                                                                    value={budgetBillingConstants.ELECTRIC_TYPE.UNKNOWN}
                                                                    color="primary"
                                                                />}
                                                                label={<span style={{ color: '#087ED8' }}>{t("Don't know")}</span>}
                                                                className="mr-0"
                                                            />
                                                        </FormGroup>
                                                    </div>
                                                </div>
                                                <div className="mb-4">
                                                    <label>{t("Select options if applicable")}</label>
                                                    <div className="row">
                                                        <div className="col-6">
                                                            <button
                                                                className="button-focus pointer-btns"
                                                                type="button"
                                                                onClick={() => selectPoolOnClick(!selectedPool)}
                                                                style={selectedPool ? budgetBillingBtnSelected : budgetBillingBtn}>
                                                                <img className="mt-2" src={selectedPool ? PoolIconBlue : PoolIconGrey} style={iconStyle} />
                                                                <div className="mb-2">{t("I have a pool")}</div>
                                                            </button>
                                                        </div>
                                                        <div className="col-6">
                                                            <button
                                                                className="button-focus pointer-btns"
                                                                type="button"
                                                                onClick={() => selectEvapCoolerOnClick(!selectedEvapCooler)}
                                                                style={selectedEvapCooler ? budgetBillingBtnSelected : budgetBillingBtn}>
                                                                <img className="mt-2" src={selectedEvapCooler ? EvapCoolerIconBlue : EvapCoolerIconGrey} style={iconStyle} />
                                                                <div className="mb-2">{t("I have an evaporative cooler")}</div>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            : null}
                                        <div className="d-flex flex-wrap justify-content-end">
                                            <button className="btn srp-btn btn-lightblue" type="button" onClick={() => router.navigate(-1)}>{t("Cancel")}</button>
                                            <button
                                                className="btn srp-btn btn-green ml-2"
                                                type="submit"
                                                disabled={submitBudgetBillingStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS}>
                                                {submitBudgetBillingStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS
                                                    ? <CircularProgress size={20} thickness={5} style={{ color: 'white' }} />
                                                    : t("Submit")
                                                }
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-6 col-12 mt-4">
                                <div className="srp-card-header">{t("Need help?")}</div>
                                <div className="srp-card-body" style={{ backgroundColor: '#f7dfd0' }}>
                                    {isCommercial
                                        ? <div className="srp-card-details">
                                            <p className="d-lg-block d-none mb-0">
                                                {t("Should_you_have_questions_commercial_desktop", {supportNumber: supportNumberFmt})}
                                            </p>
                                            <p className="d-lg-none d-block mb-0">
                                                <Trans i18nKey="Should_you_have_questions_commercial_mobile" t={t}>
                                                    Should you have questions, please contact the SRP Business Contact Center at
                                                    <a href={"tel:" + supportNumber}>
                                                        {{supportNumber: supportNumberFmt}}
                                                    </a>
                                                    , Monday through Friday, 7 a.m.-5 p.m.
                                                </Trans>
                                            </p>
                                        </div>
                                        : <div className="srp-card-details">
                                            <p className="d-lg-block d-none mb-0">
                                                {t("Should_you_have_questions_residential_desktop", {supportNumber: supportNumberFmt})}
                                            </p>
                                            <p className="d-lg-none d-block mb-0">
                                                <Trans i18nKey="Should_you_have_questions_residential_mobile" t={t}>
                                                    For questions please contact SRP Residential Customer Services at
                                                    <a href={"tel:" + supportNumber}>
                                                        {{supportNumber: supportNumberFmt}}
                                                    </a>
                                                    , available to serve you 24 hours a day, seven days a week.
                                                </Trans>
                                            </p>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </form>
                )
            }}
        </Formik>
    )

}

BudgetBillingForm.propTypes = {
    isCommercial: PropTypes.bool,
    customerName: PropTypes.string,
    primaryEmail: PropTypes.string,
    serviceAddress: PropTypes.object,
    budgetBillingEstimate: PropTypes.number,

    squareFootage: PropTypes.string,
    getSquareFootage: PropTypes.func,

    electricType: PropTypes.string,
    geElectricType: PropTypes.func,

    selectedPool: PropTypes.bool,
    selectPoolOnClick: PropTypes.func,

    selectedEvapCooler: PropTypes.bool,
    selectEvapCoolerOnClick: PropTypes.func,

    submitBudgetBillingStatus: PropTypes.string,
    createAndSubmitBudgetBillingForm: PropTypes.func,

    router: PropTypes.shape({
        navigate: PropTypes.func
    }),

    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired,
}

export default withRouter(BudgetBillingForm)

function validateForm(values) {
    let errors = {}

    if (values.emailAddress === undefined || values.emailAddress.length === 0)
        errors.email = 'Email address is required'
    if (!validate.emailAddress(values.emailAddress))
        errors.invalidEmail = 'Please enter a valid email address'

    return errors
}