import PropTypes from 'prop-types'
import React from "react"
import { connect } from 'react-redux'
import PayByCreditCardLink from './pay-by-credit-card-link'
import { getGuestCreditCardPaymentUrl as getGuestCreditCardPaymentUrlActionCreator } from '../../../actions/guest/payment/guest-credit-card-link-actions'

// export for unit testing
export class PayByCreditCardLinkContainer extends React.Component {
    constructor(props) {
        super(props)
    }

    componentDidMount(){
        this.props.dispatch(getGuestCreditCardPaymentUrlActionCreator())
    }

    render() {
        return <PayByCreditCardLink {...this.props} />
    }
}

const mapStateToProps = state => {
    return { guestCreditCardPaymentUrl: state.guestCreditCardLink.guestCreditCardPaymentUrl }
}

PayByCreditCardLinkContainer.propTypes = {
    dispatch: PropTypes.func.isRequired,
    guestCreditCardPaymentUrl: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired,
}

const PayByCreditCardLinkConnectedContainer = connect(mapStateToProps)(PayByCreditCardLinkContainer)
export default PayByCreditCardLinkConnectedContainer