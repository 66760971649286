import cookies from 'js-cookie'
import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

import AccountSelector from './account-selector'
import config from 'my-account-config'
import { selectBillAccountOnChange } from '../../../actions/auth/bill_account/bill-account-actions'
import { LOADING_STATUS_SUCCESS, LOADING_STATUS_IN_PROGRESS } from '../../../constants/loading-status-constants'
import { billAccountConstants } from '../../../constants/bill-account-constants'
import { withRouter } from '../../../srp_modules/with-router'

const waterDashboard = config.waterBaseUrl + 'dashboard'
const waterUrls = {
    '/myaccount/dashboard': { pathname: waterDashboard, redirect: true },
    '/myaccount/bill': { pathname: config.waterBaseUrl + 'bill', redirect: true },
    '/myaccount/profile/account': { pathname: config.waterBaseUrl + 'profile/account', redirect: true },
    '/myaccount/profile/alerts': { pathname: config.waterBaseUrl + 'profile/contact', redirect: true },
    '/myaccount/profile/banks': { pathname: config.waterBaseUrl + 'profile/bank', redirect: true },
    '/myaccount/profile/documents': { pathname: config.waterBaseUrl + 'profile/documents', redirect: true },

    // Add any pages that water accounts are allowed to view below with redirect property set to false
    '/myaccount/water': { redirect: false }
}

class AccountSelectorContainer extends React.Component {
    constructor(props) {
        super(props)
    }

    componentDidMount() {
        if (this.props.billAccountListStatus !== LOADING_STATUS_SUCCESS)
            return

        if (this.props.fullAccountList.length === 0) {
            this.props.router.navigate('/myaccount/addAccount', { replace: true })
            return
        }

        // NOTE: IntComm needs this cookie to know which navigation menu to display
        this.setMyAccountCookie(this.props.myAccountType)
        this.redirectIfWaterAccountSelected(this.props, false)
    }

    componentDidUpdate(prevProps) {
        if (this.props.billAccountListStatus !== LOADING_STATUS_SUCCESS)
            return

        if (this.props.fullAccountList.length === 0) {
            this.props.router.navigate('/myaccount/addAccount', { replace: true })
            return
        }

        if (prevProps.myAccountType !== this.props.myAccountType) {
            this.setMyAccountCookie(this.props.myAccountType)
        }

        if (prevProps.selectedWaterAccount === 0 && this.props.selectedWaterAccount > 0) {
            // If the account was changed as a result of the bill account list loading, then we need to check the referrer url
            let checkReferrer = prevProps.billAccountListStatus !== LOADING_STATUS_SUCCESS && this.props.billAccountListStatus === LOADING_STATUS_SUCCESS
            this.redirectIfWaterAccountSelected(this.props, checkReferrer)
        }
    }

    redirectIfWaterAccountSelected(props, checkReferrer) {
        if (props.selectedWaterAccount === 0)
            return false

        let waterUrl = waterUrls[props.router.location.pathname] || { redirect: true, pathname: waterDashboard }
        if (waterUrl.redirect === false)
            return false

        // Don't redirect back to the water application if we just came from water
        if (checkReferrer === true && this.props.referrer !== '' && ~config.waterBaseUrl.indexOf(this.props.referrer)) {
            let firstBillAccountItem = props.fullAccountList[0]
            if (firstBillAccountItem.accountType !== billAccountConstants.WATER_ACCOUNT) {
                this.props.changeAccount(firstBillAccountItem)
            }
            else {
                this.props.router.navigate('/myaccount/water')
            }

            return false
        }

        window.location = waterUrl.pathname
        return true
    }

    setMyAccountCookie(myAccountType) {
        let domain
        if (window.location.hostname.endsWith('srpnet.com')) {
            domain = 'srpnet.com'
        }
        else if (window.location.hostname.endsWith('srp.gov')) {
            domain = 'srp.gov'
        }

        cookies.set('myAccountType', myAccountType, (domain === undefined) ? null : { domain, secure: true })
    }

    render() {
        let selectedBillAccount = this.props.myAccountType === 'power'
                                ? this.props.selectedBillAccount
                                : this.props.selectedWaterAccount

        let accountChangeInProgress = this.props.selectBillAccountStatus === LOADING_STATUS_IN_PROGRESS
                                    || this.props.fullAccountList.length === 0
                                    || this.props.pendingDelete[`${this.props.selectedBillAccount}`] === true

        let disableSelector = this.props.disableSelector || Object.keys(this.props.pendingDelete).length > 0

        return (<AccountSelector
                billAccountList={this.props.fullAccountList}
                selectedBillAccount={selectedBillAccount}
                changeAccount={this.props.changeAccount}
                disableSelector={disableSelector}
                accountChangeInProgress={accountChangeInProgress}
                t={this.props.t}
            />)
    }
}

AccountSelectorContainer.propTypes = {
    myAccountType: PropTypes.string.isRequired,
    referrer: PropTypes.string.isRequired,

    fullAccountList: PropTypes.arrayOf(PropTypes.object),
    billAccountListStatus: PropTypes.string.isRequired,
    pendingDelete: PropTypes.object.isRequired,
    selectedBillAccount: PropTypes.number.isRequired,
    selectedWaterAccount: PropTypes.number,
    selectBillAccountStatus: PropTypes.string.isRequired,
    changeAccount: PropTypes.func.isRequired,
    disableSelector: PropTypes.bool,

    router: PropTypes.shape({
        location: PropTypes.object,
        navigate: PropTypes.func
    }),
    t: PropTypes.func.isRequired
}

const mapStateToProps = (state) => {
    return { ...state.accountInfo.billAccount, referrer: state.login.referrer }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeAccount: (billAccountItem) => {
            return dispatch(selectBillAccountOnChange(billAccountItem.account))
        }
    }
}

export default withTranslation("accountInfoBar")(withRouter(connect(mapStateToProps, mapDispatchToProps)(AccountSelectorContainer)))