import React from 'react'
import PropTypes from 'prop-types'
import TextField from '@mui/material/TextField'
import { PatternFormat } from 'react-number-format'

const BankAccountNumberField = ({t, ...props}) => {

    let isError = props.touched['bankAccountNumberLeadingZeroesAreSignificant']
        && Object.prototype.hasOwnProperty.call(props.errors, 'bankAccountNumberLeadingZeroesAreSignificant')
    let errMsg = isError ? props.errors['bankAccountNumberLeadingZeroesAreSignificant'] : ''
    let meta = {
        id: 'bankAccountNumberLeadingZeroesAreSignificant',
        name: 'bankAccountNumberLeadingZeroesAreSignificant',
        label: t("Bank account number"),
        helperText: errMsg==="" ? "" : t(errMsg),
        error: isError
    }

    let textFieldProps = {
        value: !props.value ? '' : props.value,
        onChange: props.onChange,
        onBlur: props.onBlur,
        errors: props.errors,
        touched: props.touched,
        fullWidth: props.fullWidth
    }

    return (
        <PatternFormat
            {...meta}
            {...textFieldProps}
            valueIsNumericString={true}
            format="#################"
            type="tel"
            customInput={TextField}
            InputProps={{ endAdornment: props.endAdornment }}
        />
    )
}

BankAccountNumberField.propTypes = {
    endAdornment: PropTypes.object.isRequired,
    errors: PropTypes.shape({
        bankAccountNumberLeadingZeroesAreSignificant: PropTypes.string
    }).isRequired,
    fullWidth: PropTypes.bool,
    onBlur: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    touched: PropTypes.shape({
        bankAccountNumberLeadingZeroesAreSignificant: PropTypes.bool
    }).isRequired,
    value: PropTypes.object.isRequired
}

export default BankAccountNumberField