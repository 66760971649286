import React from 'react'
import PropTypes from 'prop-types'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { connect } from 'react-redux'
import { DateTime } from 'luxon'

import { createValidationRulesFromeChexBankInfoValidationErrors } from '../gift_payment_page/input_page/single-payment-formik-container'
import { createValidationRulesFromeChexPaymentAmountValidationErrors } from './single-payment-select-bank-formik'

import { addBankAccountInfoChange, addBankAccountFullDetails } from '../../actions/auth/payment/bank-account-actions'
import { storeBankInfoCollected } from '../../actions/auth/payment/single-payment-actions'
import { makePaymentInfoChange, verifySinglePayment } from '../../actions/auth/payment/payment-actions'
import { startCIXFlow } from '../../actions/auth/payment/cix-actions'
import { withRouter } from '../../srp_modules/with-router'

import paymentUtils from '../common_payment/payment-utils'

import SinglePaymentAddBankForm from './single-payment-add-bank-form'

class SinglePaymentAddBankFormik extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            dateFieldProps: paymentUtils.getDateFieldProps(props.hasScheduledPayment),
            hasSurePayWarning: this.props.payment.isSurePay && (paymentUtils.getPaymentAmount(this.props.billAccount, this.props.payment, this.props.makePaymentInfo) > 0),
            hasPaymentOverAmountWarning: paymentUtils.isPaymentOverAmountDue(paymentUtils.getPaymentAmount(this.props.billAccount, this.props.payment, this.props.makePaymentInfo), this.props.payment.amountDueWithShare),
            hasPaymentUnderAmountWarning: paymentUtils.isPaymentUnderAmountDue(paymentUtils.getPaymentAmount(this.props.billAccount, this.props.payment, this.props.makePaymentInfo), this.props.payment.amountDueWithShare),
            hasPaymentDateWarning: paymentUtils.isPaymentDateAfterDueDate(paymentUtils.getPaymentDate(this.props.billAccount, DateTime.now().startOf('day').toFormat('yyyy-MM-dd'), this.props.makePaymentInfo), this.props.payment.dueDate) && this.props.payment.amountDueWithShare > 0
        }

        this.validationSchema = Yup.object().shape({
            isDefaultOnMyAcct: Yup.boolean(),
            isDefaultWithIVR: Yup.boolean(),
            shareAmount: Yup.number(),
            bankFirstName: Yup.string().ensure().trim()
                .required("First name is required.")
                .test('not all spaces', "First name is required.", bankFirstName => typeof bankFirstName === "string" && bankFirstName.trim().length > 0),
            bankLastName: Yup.string().ensure().trim()
                .required("Last name is required.")
                .test('not all spaces',
                    "Last name is required.",
                    bankLastName => typeof bankLastName === "string" && bankLastName.trim().length > 0)
                .test('at least 2 characters',
                    "Last name must have at least two characters.",
                    bankLastName => typeof bankLastName === "string" && bankLastName.trim().length > 1),
            bankPhoneNumber: Yup.string().ensure().trim()
                .required("Phone number is required.")
                .test('not all spaces', "Phone number is required.",
                    bankPhoneNumber => typeof bankPhoneNumber === "string" && bankPhoneNumber.trim().length > 0)
                .test('must have 10 digits',
                    "Phone number must have 10 digits.",
                    bankPhoneNumber => typeof bankPhoneNumber === "string" && /^\d{10}$/.test(bankPhoneNumber.replace(/[() -]/g, ''))),
            bankEmailAddress: Yup.string().ensure().trim()
                .required("Email address is required.")
                .test('not all spaces',
                    "Email address is required.",
                    bankEmailAddress => typeof bankEmailAddress === "string" && bankEmailAddress.trim().length > 0)
                .email("Invalid email address."),
            bankRoutingNumber: Yup.string().ensure().trim()
                .required("Routing number is required.")
                .test('not all spaces',
                    "Routing number is required.",
                    bankRoutingNumber => typeof bankRoutingNumber === "string" && bankRoutingNumber.trim().length > 0)
                .test('must have 9 digits',
                    "Enter a valid 9-digit routing number.",
                    bankRoutingNumber => typeof bankRoutingNumber === "string" && /^\d{9}$/.test(bankRoutingNumber.trim())),
            bankAccountNumberLeadingZeroesAreSignificant: Yup.string().ensure().trim()
                .required("Bank account number is required.")
                .test('not all spaces',
                    "Bank account number is required.",
                    bankAccountNumber => typeof bankAccountNumber === "string" && bankAccountNumber.trim().length > 0)
                .test('17 digits max',
                    "Your account number must be 1-17 digits.",
                    bankAccountNumber => typeof bankAccountNumber === "string" && /^\d{1,17}$/.test(bankAccountNumber.trim())),
            bankAccountNickname: Yup.string().ensure().trim()
                .test('35 characters max',
                    "bank_account_nickname_35_max",
                    nickname => nickname === undefined || (typeof nickname === "string" && nickname.trim().length <= 35)),
            paymentAmount: Yup.string()
                .required("A payment amount is required.")
                .test('not all spaces',
                    "A payment amount is required.",
                    paymentAmount => typeof paymentAmount === "string" && paymentAmount.trim().length > 0)
                .test('payment amount is valid',
                    "Invalid payment amount.",
                    paymentAmount => typeof paymentAmount === "string" && /^(\$?\d+(,\d{3})*\.?[0-9]?[0-9]?|\$?\.\d{1,2})$/.test(paymentAmount.trim())) // regex from https://stackoverflow.com/questions/8829765/regular-expression-for-dollar-amount-in-javascript
                .test('payment amount is a penny or more',
                    "Invalid payment amount.",
                    paymentAmount => typeof paymentAmount === "string" && parseFloat(paymentAmount.trim().replace(/[$,]/g, '')) >= 0.01),
            paymentDate: Yup.date()
                .required("A payment date is required.")
                .min(this.state.dateFieldProps.minDate, this.state.dateFieldProps.minDateMessage)
                .max(this.state.dateFieldProps.maxDate, this.state.dateFieldProps.maxDateMessage)
        })
    }

    transformValues(values) {
        return {
            ...values,
            bankFirstName: values.bankFirstName.trim(),
            bankLastName: values.bankLastName.trim(),
            bankPhoneNumber: values.bankPhoneNumber.replace(/[() -]/g, ''),
            bankEmailAddress: values.bankEmailAddress.trim(),
            bankRoutingNumber: values.bankRoutingNumber.trim(),
            bankAccountNumberLeadingZeroesAreSignificant: values.bankAccountNumberLeadingZeroesAreSignificant.trim(),
            bankAccountNickname: values.bankAccountNickname.trim(),
            paymentAmount: parseFloat(values.paymentAmount.trim().replace(/[$,]/g, '')),
        }
    }

    render() {
        let dueDate = DateTime.fromISO(this.props.payment.dueDate).startOf('day').toJSDate()

        return (
            <Formik
                initialValues={{
                    srpAccountNumber: this.props.billAccount,
                    bankFirstName: '',
                    bankLastName: '',
                    bankPhoneNumber: this.props.phoneNumber && this.props.phoneNumber.bestPhone || '',
                    bankEmailAddress: this.props.primaryEmail || this.props.loginEmail || '',
                    bankRoutingNumber: '',
                    bankAccountNumberLeadingZeroesAreSignificant: '',
                    bankAccountNickname: '',
                    paymentAmount: paymentUtils.getPaymentAmount(this.props.billAccount, this.props.payment, this.props.makePaymentInfo),
                    paymentDate: paymentUtils.getPaymentDate(this.props.billAccount, this.state.dateFieldProps.minDate, this.props.makePaymentInfo),
                    isDefaultOnMyAcct: this.props.isDefaultBankAccount,
                    isDefaultWithIVR: this.props.forUseWithIVR,
                    shareAmount: paymentUtils.getShareAmount(this.props.makePaymentInfo),
                }}
                validationSchema={this.validationSchema}
                onSubmit={(values, formikProps) => {

                    let _values = this.transformValues(values)

                    let bankAccountInfo = {
                        bankAccountNumber: _values.bankAccountNumberLeadingZeroesAreSignificant,
                        nickName: _values.bankAccountNickname,
                        routingNumber: _values.bankRoutingNumber,
                        firstName: _values.bankFirstName,
                        lastName: _values.bankLastName,
                        phoneNumber: _values.bankPhoneNumber,
                        isDefaultAccount: _values.isDefaultOnMyAcct,
                        emailAddress: _values.bankEmailAddress,
                        isDefaultWithIVR: _values.isDefaultWithIVR,
                    }

                    let paymentInfo = {
                        accountNumber: _values.srpAccountNumber,
                        amount: (Number(_values.paymentAmount) + Number(_values.shareAmount)).toFixed(2),
                        billAccount: _values.srpAccountNumber,
                        paymentAmount: (Number(_values.paymentAmount) + Number(_values.shareAmount)).toFixed(2),
                        paymentDate: _values.paymentDate,
                        paymentAmountWarningText: this.props.payment.isSurePay ? this.props.t("Surepay_may_be_canceled") :
                            ((Number(_values.paymentAmount) + Number(_values.shareAmount)) < this.props.payment.amountDueWithShare
                                ? this.props.t("Paying_less_warning")
                                : Number(_values.paymentAmount) > this.props.payment.amountDueWithShare
                                    ? this.props.t("You are making a payment that is more than the amount due.")
                                    : ""),
                        paymentDateWarningText: DateTime.fromISO(_values.paymentDate) > DateTime.fromISO(dueDate) && this.props.payment.amountDueWithShare > 0 ?
                            this.props.t("Paying_after_due_date"): "",
                        shareAmount: Number(_values.shareAmount).toFixed(2)
                    }

                    this.props.actions.submitPayment(bankAccountInfo, paymentInfo, this.validationSchema, formikProps)
                }}>
                {formikProps => (<SinglePaymentAddBankForm
                    {...formikProps}
                    {...this.props}
                    {...this.state} />)}
            </Formik>
        )
    }
}

SinglePaymentAddBankFormik.propTypes = {
    actions: PropTypes.object.isRequired,
    billAccount: PropTypes.number.isRequired,
    creditExtension: PropTypes.object.isRequired,
    customerNames: PropTypes.object.isRequired,
    eChexValidationErrors: PropTypes.shape({
        ErrOnSurePay: PropTypes.bool,
        ErrCashOnly: PropTypes.bool,
        ErrBankruptcy: PropTypes.bool,
        ErrClosedSrpAcct: PropTypes.bool,
        ErrInvalidBillStatus: PropTypes.bool,
        ErrInvalidCustomerAcctLen: PropTypes.bool,
        ErrInvalidSrpAcct: PropTypes.bool,
        ErrMissingSrpAccount: PropTypes.bool,
        ErrNoSrpAcct: PropTypes.bool,
        ErrNoBank: PropTypes.bool,
        ErrLockedBank: PropTypes.bool,
        ErrInvalidBankAcctLen: PropTypes.bool,
        ErrLockedBankAcct: PropTypes.bool,
        ErrExceedHardLimit: PropTypes.number,
        ErrMYAInternational: PropTypes.bool,
        ErrMYANonInternational: PropTypes.bool // ErrMYA*
    }).isRequired,
    forUseWithIVR: PropTypes.bool.isRequired,
    hasScheduledPayment: PropTypes.bool.isRequired,
    isDefaultBankAccount: PropTypes.bool.isRequired,
    isPaymentInfoValid: PropTypes.bool.isRequired,
    isResidential: PropTypes.bool.isRequired,
    summaryBillingStatus: PropTypes.number.isRequired,
    loginEmail: PropTypes.string.isRequired,
    makePaymentInfo: PropTypes.object.isRequired,
    onAddBankAccountCancelClick: PropTypes.func.isRequired,
    payment: PropTypes.object.isRequired,
    phoneNumber: PropTypes.object.isRequired,
    primaryEmail: PropTypes.string.isRequired,
    router: PropTypes.shape({
        navigate: PropTypes.func
    }).isRequired,
    showAddBankAccountCancelButton: PropTypes.bool.isRequired,
    showSpanish: PropTypes.bool.isRequired,
    supportPhoneNumbers: PropTypes.shape({
        residentialCustomerServicePhoneNumber: PropTypes.string.isRequired,
        residentialCustomerServicePhoneNumberSpanish: PropTypes.string.isRequired,
        commercialCustomerServicePhoneNumber: PropTypes.string.isRequired
    }).isRequired,
    formDataChanged: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    i18n: PropTypes.object.isRequired
}

const mapStateToProps = (state) => {
    return {
        loginEmail: state.login.loginEmail,
        primaryEmail: state.accountInfo.contact.primaryEmail,
        primaryEmailStatus: state.accountInfo.contact.primaryEmailStatus,
        isResidential: !state.accountInfo.billAccount.selectedBillAccountDetails.isCommercial,
        summaryBillingStatus: state.accountInfo.billAccount.selectedBillAccountDetails.summaryBillingStatus,
        isDefaultBankAccount: state.singlePaymentBankInfo.isDefaultBankAccount,
        forUseWithIVR: state.singlePaymentBankInfo.forUseWithIVR
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        actions: {
            submitPayment: async (bankInfo, paymentInfo, validationSchema, formikProps) => {
                dispatch(storeBankInfoCollected({
                    isDefaultBankAccount: bankInfo.isDefaultAccount,
                    forUseWithIVR: bankInfo.isDefaultWithIVR
                }))

                await dispatch(addBankAccountInfoChange(bankInfo))
                let addBankAccountResult = await dispatch(addBankAccountFullDetails())
                formikProps.setSubmitting(false)

                const bankInputValues = {
                    bankFirstName: bankInfo.firstName,
                    bankLastName: bankInfo.lastName,
                    bankRoutingNumber: bankInfo.routingNumber,
                    bankAccountNumber: bankInfo.bankAccountNumber
                }

                if (addBankAccountResult.error) {
                    formikProps.setStatus({ hasUnhandledBankError: true })
                    document.getElementById("topOfAddBankForPayment").scrollIntoView()
                    return false
                }

                if (!addBankAccountResult.payload.isSuccess) {
                    const eChexAddBankAccountErrors = addBankAccountResult.payload.saveBankAccountErrors
                    const numErrorsHandled = createValidationRulesFromeChexBankInfoValidationErrors(validationSchema.fields, eChexAddBankAccountErrors, bankInputValues)
                    const hasUnhandledeChexValidationError = (Object.keys(eChexAddBankAccountErrors).length - numErrorsHandled) > 0
                    formikProps.setStatus({ hasUnhandledBankError: hasUnhandledeChexValidationError })
                    formikProps.validateForm()

                    if (eChexAddBankAccountErrors.errorBankAccountSetupLocked)
                        ownProps.router.navigate("/myaccount/profile/banks/locked")

                    document.getElementById("topOfAddBankForPayment").scrollIntoView()
                    return false
                }
                else {
                    let savedBankAccount = addBankAccountResult.payload.savedBankAccount
                    let bankAcctId = savedBankAccount.bankAccountId
                    let newPaymentInfo = { ...paymentInfo, bankAccountId: bankAcctId }

                    await dispatch(makePaymentInfoChange(newPaymentInfo))
                    let verifyPaymentResult = await dispatch(verifySinglePayment(bankAcctId))

                    if (verifyPaymentResult.error) {
                        formikProps.setStatus({ hasUnandledPaymentError: true })
                        document.getElementById("topOfAddBankForPayment").scrollIntoView()
                        return false
                    }
                    else {
                        let referenceNumber = verifyPaymentResult.payload.referenceNumberWhenNoError
                        let eChexValidationErrors = verifyPaymentResult.payload.eChexValidationErrors
                        if (referenceNumber !== 0 && (Object.keys(eChexValidationErrors).length === 0 && eChexValidationErrors.constructor === Object))
                            ownProps.router.navigate('/myaccount/payment/single/verify')
                        else {

                            let numErrorsHandled = createValidationRulesFromeChexBankInfoValidationErrors(validationSchema.fields, eChexValidationErrors, bankInputValues)
                            numErrorsHandled += createValidationRulesFromeChexPaymentAmountValidationErrors(validationSchema.fields, eChexValidationErrors, ownProps.t)
                            const hasUnhandledeChexValidationError = (Object.keys(eChexValidationErrors).length - numErrorsHandled) > 0 && eChexValidationErrors.constructor === Object
                            formikProps.setStatus({ hasUnandledPaymentError: hasUnhandledeChexValidationError })
                            formikProps.validateForm()
                        }
                    }
                }
            },
            startCIXFlowOnClick: async () => {
                await dispatch(startCIXFlow())
            }
        }
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SinglePaymentAddBankFormik))