import { RSAA } from 'redux-api-middleware'
import { SKIP_BILL_ACCOUNT_CHECK } from '../../srp_modules/middleware/bill-account-middleware'
import { SKIP_XSRF } from '../../srp_modules/middleware/xsrf-middleware'

export const GET_NAV_MENU_REQUEST = 'GET_NAV_MENU_REQUEST'
export const GET_NAV_MENU_SUCCESS = 'GET_NAV_MENU_SUCCESS'
export const GET_NAV_MENU_FAILURE = 'GET_NAV_MENU_FAILURE'

export const GET_FOOTER_REQUEST = 'GET_FOOTER_REQUEST'
export const GET_FOOTER_SUCCESS = 'GET_FOOTER_SUCCESS'
export const GET_FOOTER_FAILURE = 'GET_FOOTER_FAILURE'

import config from 'my-account-config'

const appPathWithoutTrailingSlash = config.appPath.endsWith('/')
                                  ? config.appPath.substring(0, config.appPath.length - 1)
                                  : config.appPath

function replaceNavUrls(text) {
    return text
        .replaceAll(' href="#"', '')
        .replaceAll(' class="en-only"', '')
        .replaceAll(/https:\/\/myaccount(reg)?\.srpnet\.com\/power/ig, appPathWithoutTrailingSlash)
        .replaceAll(/https:\/\/myaccount(reg)?\.srpnet\.com/ig, '')
        .replaceAll(/https:\/\/(water|myaccountwater-qa)(\.srpnet\.com)?\/?/ig, config.waterBaseUrl)
}

export const getNavMenu = (myAccountType, language) => dispatch => {
    switch (myAccountType) {
        case "power":
        case "water":
        case "anon":
            break
        case "mpower": {
            myAccountType = "power"
            break
        }
        default:
            myAccountType = "anon"
    }

    let navUrl = language === 'es' ? config.navBaseUrlEs : config.navBaseUrl

    let endpoint = `${navUrl}?useAbsoluteUrls=true${myAccountType == 'anon' ? '' : `&myAccountType=${myAccountType}`}`

    return dispatch({
        [RSAA]: {
            endpoint,
            method: 'GET',
            types: [
                {
                    type: GET_NAV_MENU_REQUEST,
                    meta: { myAccountType, language }
                },
                {
                    type: GET_NAV_MENU_SUCCESS,
                    payload: (action, state, res) => {
                        const contentType = res.headers.get('Content-Type')
                        if (contentType && ~contentType.indexOf('text')) {
                            return res.text().then(replaceNavUrls);
                        }
                    }
                },
                GET_NAV_MENU_FAILURE
            ]
        },
        [SKIP_BILL_ACCOUNT_CHECK]: true,
        [SKIP_XSRF]: true,
        meta: {
            debounce: {
                key: GET_NAV_MENU_REQUEST,
                time: 100,
                leading: false,
                trailing: true // trailing is needed to make sure we pull the most recent header
            }
        }
    })
}

export const getFooter = language => dispatch => {
    let footerUrl = language === 'es' ? config.footerBaseUrlEs : config.footerBaseUrl

    return dispatch({
        [RSAA]: {
            endpoint: `${footerUrl}?useAbsoluteUrls=true`,
            method: 'GET',
            types: [
                GET_FOOTER_REQUEST,
                {
                    type: GET_FOOTER_SUCCESS,
                    payload: (action, state, res) => {
                        const contentType = res.headers.get('Content-Type')
                        if (contentType && ~contentType.indexOf('text')) {
                            return res.text().then(replaceNavUrls);
                        }
                    }
                },
                GET_FOOTER_FAILURE
            ]
        },
        [SKIP_BILL_ACCOUNT_CHECK]: true,
        [SKIP_XSRF]: true,
        meta: {
            debounce: {
                key: GET_FOOTER_REQUEST,
                time: 100,
                leading: false,
                trailing: true // trailing is needed to make sure we pull the most recent footer
            }
        }
    })
}