import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import CircularProgress from '@mui/material/CircularProgress'

import MyBillPage from './bill-page'
import NoBillNewCustomer from './no-bill-new-cust'
import MyPurchasesPageContainer from './purchase_history/purchase-history-container'
import MyAccountMainContainer from '../../containers/my-account-main-container'

import { getAccountHistory } from '../../actions/auth/payment/account-history-actions'
import { getEBillList } from '../../actions/auth/document/ebill-actions'
import { getRateMetaData } from '../../actions/auth/usage/rate-meta-data-actions'
import { rateMetaDataConstants } from '../../constants/rate-meta-data-constants'
import { withRouter } from '../../srp_modules/with-router'
import * as loadingStatus from '../../constants/loading-status-constants'
import { withTranslation } from 'react-i18next'

let style = {
    marginTop: "10%",
    marginBottom: "10%"
}

class MyBillPageContainer extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        if (this.props.selectedBillAccount === 0)
            return

        if (this.props.selectedBillAccountDetails.isPrePay)
            return

        if (this.props.accountHistoryStatus === loadingStatus.LOADING_STATUS_INIT) {
            this.props.getAccountHistory(this.props.selectedBillAccount)
        }

        if (this.props.eBillStatus === loadingStatus.LOADING_STATUS_INIT) {
            this.props.getEBillListInfo(this.props.selectedBillAccount)
        }

        if (this.props.rateMetaDataStatus === loadingStatus.LOADING_STATUS_INIT && !this.props.selectedBillAccountDetails.isPendingTurnOn) {
            this.props.getRateMetaDataInfo(this.props.selectedBillAccount)
        }

    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.selectedBillAccount === nextProps.selectedBillAccount || nextProps.selectedBillAccount === 0)
            return

        if (nextProps.selectedBillAccountDetails.isPrePay)
            return

        if (nextProps.accountHistoryStatus !== loadingStatus.LOADING_STATUS_IN_PROGRESS) {
            this.props.getAccountHistory(nextProps.selectedBillAccount)
        }

        if (nextProps.eBillStatus !== loadingStatus.LOADING_STATUS_IN_PROGRESS) {
            this.props.getEBillListInfo(nextProps.selectedBillAccount)
        }

        if (nextProps.rateMetaDataStatus !== loadingStatus.LOADING_STATUS_IN_PROGRESS && !nextProps.selectedBillAccountDetails.isPendingTurnOn) {
            this.props.getRateMetaDataInfo(nextProps.selectedBillAccount)
        }
    }

    render() {
        let renderedCard = {}
        let pageTitle = "My bill"
        if (this.props.billAccountListStatus === loadingStatus.LOADING_STATUS_SUCCESS
            && this.props.rateMetaDataStatus === loadingStatus.LOADING_STATUS_SUCCESS) {
            if (this.props.selectedBillAccountDetails.isPrePay) {
                pageTitle = "My purchases"
                renderedCard = <MyPurchasesPageContainer navLocation={this.props.router.location.pathname} />
            }
            else {
                if (this.props.accountHistoryStatus === loadingStatus.LOADING_STATUS_SUCCESS && Object.keys(this.props.accountHistoryList).length === 0) {
                    renderedCard = (
                        <div className="row">
                            <div className="col-12 mt-3">
                                <div className="srp-card-header">{this.props.t("Stay tuned for your first bill")}</div>
                                <NoBillNewCustomer isMpower={this.props.selectedBillAccountDetails.isPrePay} />
                            </div>
                        </div>
                    )
                }
                else {
                    renderedCard = (<MyBillPage
                        navLocation={this.props.router.location.pathname}
                        getAccountHistory={this.props.getAccountHistory}
                        getEBillListInfo={this.props.getEBillListInfo}
                        getRateMetaDataInfo={this.props.getRateMetaDataInfo}
                        isSummaryMaster={this.props.rateMetaData.summaryBilling === rateMetaDataConstants.SUMMARY_BILLING_PARENT} />)
                }
            }
        }
        else {
            renderedCard = (
                <div className="srp-card-details d-flex justify-content-center">
                    <CircularProgress size={80} thickness={5} style={style} />
                </div>
            )
        }

        return (
            <MyAccountMainContainer pageTitle={pageTitle}>
                {renderedCard}
            </MyAccountMainContainer>
        )
    }
}

const mapStateToProps = state => {
    return { ...state.accountHistory, ...state.eBill, ...state.accountInfo.billAccount, ...state.rateMetaData, ...state.purchaseHistoryTable }
}

const mapDispatchToProps = dispatch => {
    return {
        getAccountHistory: (billAccount) => {
            dispatch(getAccountHistory(billAccount))
        },
        getEBillListInfo: (billAccount) => {
            dispatch(getEBillList(billAccount))
        },
        getRateMetaDataInfo: (billAccount) => {
            dispatch(getRateMetaData(billAccount))
        }
    }
}

MyBillPageContainer.propTypes = {
    selectedBillAccount: PropTypes.number,
    selectedBillAccountDetails: PropTypes.object,
    billAccountListStatus: PropTypes.string,

    accountHistoryList: PropTypes.arrayOf(PropTypes.object),
    accountHistoryStatus: PropTypes.string,
    getAccountHistory: PropTypes.func,

    eBillStatus: PropTypes.string,
    getEBillListInfo: PropTypes.func,
    eBillDocumentList: PropTypes.arrayOf(PropTypes.object),

    rateMetaDataStatus: PropTypes.string,
    getRateMetaDataInfo: PropTypes.func,
    rateMetaData: PropTypes.object,

    router: PropTypes.shape({
        location: PropTypes.object,
        navigate: PropTypes.func
    }),
    t: PropTypes.func.isRequired
}

export default withTranslation("billPage")(withRouter(connect(mapStateToProps, mapDispatchToProps)(MyBillPageContainer)))