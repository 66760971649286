import { authorizedPartyConstants } from '../../../constants/authorized-party-constants'
import { RSAA } from 'redux-api-middleware'

import utils from '../../../srp_modules/utils'

export const submitAuthorizedPartyForm = (authorizedPartyDetails) => {

    let url = utils.createUrl('forms/authorizedparty/submit')

    return dispatch => {
        return dispatch({
            [RSAA]: {
                endpoint: url,
                method: 'POST',
                body: JSON.stringify(authorizedPartyDetails),
                credentials: 'include',
                headers: { 'Content-Type': 'application/json' },
                types: [
                    authorizedPartyConstants.SUBMIT_AUTHORIZED_PARTY_FORM_REQUEST,
                    authorizedPartyConstants.SUBMIT_AUTHORIZED_PARTY_FORM_SUCCESS,
                    authorizedPartyConstants.SUBMIT_AUTHORIZED_PARTY_FORM_FAILURE
                ]
            },
            meta: {
                debounce: {
                    leading: false,
                    trailing: false
                }
            }
        })
    }
}

export const resetAuthorizedPartyForm = () => ({
    type: authorizedPartyConstants.RESET_AUTHORIZED_PARTY_FORM
})