import { connect } from 'react-redux'

import SinglePaymentFormik from './single-payment-formik'

import { myAccountConstants } from '../../../constants/myaccount-constants'
import {
        addBankAccount,
        setBankIdForGiftPayment,
        storeBankInfoCollected,
        addPaymentToList,
        clearPaymentList,
        verifyPaymentList,
        enableMultiplePayments,
        setBankFormOpen,
        clearBankFormOpen
    } from '../../../actions/auth/payment/gift-payment-actions'
import { withRouter } from '../../../srp_modules/with-router'

const mapStateToProps = state => {
    const phoneNumber = state.accountInfo.billAccount.phoneNumber
    const loginEmail = state.login.loginEmail
    const primaryEmail = state.accountInfo.contact.primaryEmail

    return { ...state.giftPaymentBankInfo, ...state.giftPaymentList, bankAccountList: state.bankAccounts.bankAccountList,
        bankFirstName: state.giftPaymentBankInfo.bankFirstName,
        bankLastName: state.giftPaymentBankInfo.bankLastName,
        bankPhoneNumber: state.giftPaymentBankInfo.bankPhoneNumber || (phoneNumber && phoneNumber.bestPhone || ''),
        bankEmailAddress: state.giftPaymentBankInfo.bankEmailAddress || primaryEmail || loginEmail || '',
        primaryEmailStatus: state.accountInfo.contact.primaryEmailStatus,
        primaryEmail
    }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
    switchToMultiplePaymentForm: () => {
        dispatch(enableMultiplePayments())
    },
    storeBankInfoAndPayment: (paymentAndBankValues) => {
        dispatch(storeBankInfoCollected(paymentAndBankValues))
        dispatch(setBankIdForGiftPayment({selectedBankId: paymentAndBankValues.bankAccountIdSelected}))
        dispatch(clearPaymentList())
        dispatch(addPaymentToList(paymentAndBankValues))
    },
    addBank: async (goodies, validationSchema, paymentAndBankValues) => {
        let response = await dispatch(addBankAccount())
        goodies.setSubmitting(false)
        if (response.error === true) {
            goodies.setStatus({hasUnhandledeChexValidationError: true})
            return false
        }

        if (response.payload.isSuccess === false) {
            const bankInputValues = {
                bankFirstName: paymentAndBankValues.bankFirstName,
                bankLastName: paymentAndBankValues.bankLastName,
                bankRoutingNumber: paymentAndBankValues.bankRoutingNumber,
                bankAccountNumber: paymentAndBankValues.bankAccountNumberLeadingZeroesAreSignificant
            }

            const eChexAddBankAccountErrors = response.payload.saveBankAccountErrors
            const numErrorsHandled = createValidationRulesFromeChexBankInfoValidationErrors(validationSchema.fields, eChexAddBankAccountErrors, bankInputValues)
            const hasUnhandledeChexValidationError = (Object.keys(eChexAddBankAccountErrors).length - numErrorsHandled) > 0
            goodies.setStatus({hasUnhandledeChexValidationError})
            goodies.validateForm()

            if (eChexAddBankAccountErrors.errorBankAccountSetupLocked)
                ownProps.router.navigate('/myaccount/profile/banks/locked')

            return false
        }

        const savedBankAccount = response.payload.savedBankAccount
        if (savedBankAccount.bankAccountId === 0) {
            goodies.setStatus({hasUnhandledeChexValidationError: true})
            return false
        }

        dispatch(setBankIdForGiftPayment({selectedBankId: savedBankAccount.bankAccountId}))
        return true
    },
    validatePayment: async (goodies, validationSchema, paymentAndBankValues) => {
        let response = await dispatch(verifyPaymentList())
        goodies.setSubmitting(false)

        if (response.error === true)
            goodies.setStatus({hasUnhandledeChexValidationError: true})
        else {
            const referenceNumber = response.payload.referenceNumberWhenNoError
            const eChexValidationErrors = response.payload.eChexValidationErrors
            if (referenceNumber !== 0 && (Object.keys(eChexValidationErrors).length === 0 && eChexValidationErrors.constructor === Object))
                ownProps.router.navigate('/myaccount/payment/gift/confirmation')
            else {
                const bankInputValues = {
                    bankFirstName: paymentAndBankValues.bankFirstName,
                    bankLastName: paymentAndBankValues.bankLastName,
                    bankRoutingNumber: paymentAndBankValues.bankRoutingNumber,
                    bankAccountNumber: paymentAndBankValues.bankAccountNumberLeadingZeroesAreSignificant
                }

                let numErrorsHandled = createValidationRulesFromeChexBankInfoValidationErrors(validationSchema.fields, eChexValidationErrors, bankInputValues)
                numErrorsHandled += createValidationRulesFromeChexSRPAccountNumberAndPaymentAmountValidationErrors(
                    validationSchema.fields, eChexValidationErrors, ownProps.t, ownProps.i18n)
                const hasUnhandledeChexValidationError = (Object.keys(eChexValidationErrors).length - numErrorsHandled) > 0 && eChexValidationErrors.constructor === Object
                goodies.setStatus({hasUnhandledeChexValidationError})
                goodies.validateForm()
            }
        }
    },
    setBankFormOpen: () => {
        dispatch(setBankFormOpen())
    },
    clearBankFormOpen: () => {
        dispatch(clearBankFormOpen())
    }
})

// export for unit testing and multi-account gift payment form
export function createValidationRulesFromeChexBankInfoValidationErrors(validationRules, eChexValidationErrors, bankInputValues) {
    let numErrorsHandled = 0
    const earlyWarningNs = 'earlyWarning'

    for (let errorCode in eChexValidationErrors)
        switch (errorCode) {
            case 'errorInvalidCharacters': {
                const badBankFirstName = bankInputValues.bankFirstName
                const badBankLastName = bankInputValues.bankLastName

                validationRules.bankFirstName = validationRules.bankFirstName.test(errorCode,
                    earlyWarningNs + '::' + errorCode,
                    function (bankFirstName) {
                        const { bankLastName } = this.parent
                        return bankFirstName !== badBankFirstName
                            || bankLastName !== badBankLastName
                    })

                validationRules.bankLastName = validationRules.bankLastName.test(errorCode,
                    earlyWarningNs + '::' + errorCode,
                    function (bankLastName) {
                        const { bankFirstName } = this.parent
                        return bankFirstName !== badBankFirstName
                            || bankLastName !== badBankLastName
                    })

                ++numErrorsHandled
                }
                break
            case 'errorBankAccountInfoMismatch': {
                const badBankFirstName = bankInputValues.bankFirstName
                const badBankLastName = bankInputValues.bankLastName
                const pairedBankRoutingNumber = bankInputValues.bankRoutingNumber
                const pairedBankAccountNumber = bankInputValues.bankAccountNumber

                validationRules.bankFirstName = validationRules.bankFirstName.test(errorCode,
                    earlyWarningNs + '::' + errorCode,
                    function (bankFirstName) {
                        const { bankLastName, bankRoutingNumber, bankAccountNumberLeadingZeroesAreSignificant } = this.parent
                        return bankFirstName !== badBankFirstName
                            || bankLastName !== badBankLastName
                            || bankRoutingNumber !== pairedBankRoutingNumber
                            || bankAccountNumberLeadingZeroesAreSignificant !== pairedBankAccountNumber
                    })

                validationRules.bankLastName = validationRules.bankLastName.test(errorCode,
                    earlyWarningNs + '::' + errorCode,
                    function (bankLastName) {
                        const { bankFirstName, bankRoutingNumber, bankAccountNumberLeadingZeroesAreSignificant } = this.parent
                        return bankFirstName !== badBankFirstName
                            || bankLastName !== badBankLastName
                            || bankRoutingNumber !== pairedBankRoutingNumber
                            || bankAccountNumberLeadingZeroesAreSignificant !== pairedBankAccountNumber
                    })

                ++numErrorsHandled
                }
                break
            case 'errorRoutingNumberInvalid': {
                const badBankRoutingNumber = bankInputValues.bankRoutingNumber
                validationRules.bankRoutingNumber = validationRules.bankRoutingNumber.test(errorCode,
                    earlyWarningNs + '::' + errorCode,
                    bankRoutingNumber => bankRoutingNumber !== badBankRoutingNumber)
                ++numErrorsHandled
                }
                break
            case 'errorPaymentTypeInvalid':
            case 'errorBankAccountInfoInvalid': {
                const badBankAccountNumber = bankInputValues.bankAccountNumber
                const pairedBankRoutingNumber = bankInputValues.bankRoutingNumber
                validationRules.bankAccountNumberLeadingZeroesAreSignificant = validationRules.bankAccountNumberLeadingZeroesAreSignificant.test(errorCode,
                    earlyWarningNs + '::' + errorCode,
                    function (bankAccountNumber) {
                        const { bankRoutingNumber } = this.parent
                        return bankAccountNumber !== badBankAccountNumber
                            || bankRoutingNumber !== pairedBankRoutingNumber
                    })
                ++numErrorsHandled
                }
                break
        }

    return numErrorsHandled
}

// export for unit testing and multi-account gift payment form
export function createValidationRulesFromeChexSRPAccountNumberAndPaymentAmountValidationErrors(validationRules, eChexValidationErrors, t, i18n)
{
    let numErrorsHandled = 0

    for (let errorCode in eChexValidationErrors)
        switch(errorCode)
        {
            case 'errLockedBankAcct':
                validationRules.bankAccountIdSelected = validationRules.bankAccountIdSelected.test(errorCode, {
                    getLocalizedErrMsg: () => (t("Issue_with_recent_payment",
                        {phone: i18n.language === 'es' ? myAccountConstants.SPANISH_CUSTOMER_SERVICE_PHONE_NUMBER_FMTTD : myAccountConstants.RESIDENTIAL_CUSTOMER_SERVICE_PHONE_NUMBER_FMTTD}
                    ))},
                    bankAccountIdSelected => bankAccountIdSelected !== eChexValidationErrors[errorCode].bankAccountId)
                ++numErrorsHandled
                break
            case 'errorIneligibleBillAccount':
                validationRules.srpAccountNumber = validationRules.srpAccountNumber.test(errorCode, {
                    getLocalizedErrMsg: () => (t("Online_payments_cannot_be_made_for_this_account",
                        {phone: i18n.language === 'es' ? myAccountConstants.SPANISH_CUSTOMER_SERVICE_PHONE_NUMBER_FMTTD : myAccountConstants.RESIDENTIAL_CUSTOMER_SERVICE_PHONE_NUMBER_FMTTD}
                    ))},
                    srpAccountNumber => srpAccountNumber !== eChexValidationErrors[errorCode].srpAccountNumber)
                ++numErrorsHandled
                break
            case 'errExceedHardLimit': {
                const errorInfo = eChexValidationErrors[errorCode]
                validationRules.paymentAmount = validationRules.paymentAmount.test(errorCode, {
                        getLocalizedErrMsg: () => (t("Payments_of_over_x_are_not_allowed", {amount: errorInfo.maxPaymentAmountAllowed}))
                    },
                    function(paymentAmount) {
                        const { srpAccountNumber } = this.parent
                        if (typeof paymentAmount === "string")
                            paymentAmount = parseFloat(paymentAmount.replace(/[$,]/g, ''))
                        return paymentAmount <= errorInfo.maxPaymentAmountAllowed || srpAccountNumber !== errorInfo.srpAccountNumber
                    })
                }
                ++numErrorsHandled
                break
        }

    return numErrorsHandled
}

const SinglePaymentFormikConnectedContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(SinglePaymentFormik))
export default SinglePaymentFormikConnectedContainer