import React from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'

import { getBillAccounts } from '../../actions/auth/bill_account/bill-account-actions'
import { getPayment } from '../../actions/auth/payment/payment-actions'
import { getLandlordAccounts } from '../../actions/auth/landlord/landlord-actions'

import * as loadingStatus from '../../constants/loading-status-constants'
import { Navigate } from 'react-router-dom'

import multiPaymentUtils from '../common_payment/multi-payment-utils'

let hasLoaded = (props) => {
    let loaded = true
    if (props.billAccountListStatus === loadingStatus.LOADING_STATUS_INIT ||
        props.paymentStatus === loadingStatus.LOADING_STATUS_INIT ||
        props.landlordAccountsStatus === loadingStatus.LOADING_STATUS_INIT ||
        props.billAccountListStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS ||
        props.paymentStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS ||
        props.landlordAccountsStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS) {
        loaded = false
    }

    return loaded
}

let updateDataIfNeeded = (props) => {
    if (props.billAccountListStatus === loadingStatus.LOADING_STATUS_INIT) {
        props.getBillAccountList()
    }

    if (props.landlordAccountsStatus === loadingStatus.LOADING_STATUS_INIT) {
        props.getLandlordAccounts()
    }

    if (props.selectedBillAccount === 0)
        return

    if (props.paymentStatus === loadingStatus.LOADING_STATUS_INIT) {
        props.getPayment(props.selectedBillAccount)
    }
}

class SingleOrMultiPayment extends React.Component {
    constructor(props) {
        super(props)
    }

    componentDidMount() {
        updateDataIfNeeded(this.props)
    }

    componentDidUpdate() {
        updateDataIfNeeded(this.props)
    }

    getNavigateUrl() {
        if (this.props.billAccountListStatus === loadingStatus.LOADING_STATUS_SUCCESS && this.props.billAccountList.length === 0) {
            return '/myaccount/addAccount'
        }

        if (this.props.billAccountListStatus === loadingStatus.LOADING_STATUS_FAILURE) {
            return '/myaccount/error'
        }

        let userBillAccountList = multiPaymentUtils.getUserBillAccountList(this.props.billAccountList)
        let landlordBillAccountList = multiPaymentUtils.getLandlordBillAccountList(this.props.landlordAccounts)

        let paymentAccountList = new Set([...userBillAccountList, ...landlordBillAccountList])

        let basePaymentUrl = paymentAccountList.size === 1 ? '/myaccount/payment/single' : '/myaccount/payment/multi'
        let navigateUrl = `${basePaymentUrl}${this.props.payment.supportsRemotePrepay ? '/mpower' : ''}`

        return navigateUrl
    }

    render() {
        if (!hasLoaded(this.props))
            return null

        return <Navigate to={this.getNavigateUrl()} replace={true} />
    }
}

const mapStateToProps = state => {
    return {
        billAccountList: state.accountInfo.billAccount.billAccountList,
        billAccountListStatus: state.accountInfo.billAccount.billAccountListStatus,
        selectedBillAccount: state.accountInfo.billAccount.selectedBillAccount,
        selectedBillAccountDetails: state.accountInfo.billAccount.selectedBillAccountDetails,
        payment: state.accountInfo.payment.payment,
        paymentStatus: state.accountInfo.payment.paymentStatus,
        landlordAccounts: state.landlord.landlordAccounts,
        landlordAccountsStatus: state.landlord.landlordAccountsStatus
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getBillAccountList: () => {
            dispatch(getBillAccounts())
        },
        getPayment: async (billAccount) => {
            await dispatch(getPayment(billAccount))
        },
        getLandlordAccounts: async () => {
            await dispatch(getLandlordAccounts())
        }
    }
}

SingleOrMultiPayment.propTypes = {
    billAccountList: PropTypes.arrayOf(PropTypes.object).isRequired,
    getBillAccountList: PropTypes.func.isRequired,
    getPayment: PropTypes.func.isRequired,
    billAccountListStatus: PropTypes.string.isRequired,
    selectedBillAccountDetails: PropTypes.object.isRequired,
    payment: PropTypes.object.isRequired,
    paymentStatus: PropTypes.string.isRequired,
    landlordAccounts: PropTypes.object.isRequired,
    landlordAccountsStatus: PropTypes.string.isRequired,
    getLandlordAccounts: PropTypes.func.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(SingleOrMultiPayment)