import React from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

const NoBillNewCustomer = ({ isMpower, t }) => {
    return (
        <div className="srp-card-body col-12 col-lg-6">
            <div className="srp-card-details">
                <div className="text-center">
                    <div style={{ color: "#004B87" }}><i className="material-icons" style={{ fontSize: "3rem" }}>attach_money</i></div>
                    <div className="mb-1" style={{ color: "#004B87", fontSize: "1.125rem" }}><strong>{t(`Looking for your ${isMpower ? "purchases" : "bill"}?`)}</strong></div>
                    <div className="text-muted">
                        {isMpower ? t("No_purchase") : t("No_bill")}
                        {/* You're in the right place, but ${isMpower ? "you have yet to make a purchase" : "your first bill has yet to be processed"}. SRP is delighted to have you as a customer, we look forward to serving you. */}
                    </div>
                </div>
            </div>
        </div>
    )
}

NoBillNewCustomer.propTypes = {
    isMpower: PropTypes.bool,
    t: PropTypes.func.isRequired
}

export default withTranslation("billPage")(NoBillNewCustomer)