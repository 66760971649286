import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'

import NavigationMenu from './navigation-menu'

import { closeResponsiveNav } from '../../../actions/auth/navigation/navigation-actions'

const NavigationMenuContainer = (props) => {
    return(
        <NavigationMenu
            selectedBillAccountDetails={props.selectedBillAccountDetails}
            responsiveNavStyle={props.responsiveNavStyle}
            responsiveNavOpen={props.responsiveNavOpen}
            closeResponsiveNavOnClick={props.closeResponsiveNavOnClick}
            t={props.t}
        />
    )
}

const mapStateToProps = (state) => {
    return { ...state.navigation, ...state.accountInfo.billAccount }
}

const mapDispatchToProps = dispatch => {
    return {
        closeResponsiveNavOnClick: () => {
            dispatch(closeResponsiveNav())
        }
    }
}

NavigationMenuContainer.propTypes = {
    selectedBillAccountDetails: PropTypes.object.isRequired,
    responsiveNavStyle: PropTypes.object.isRequired,
    responsiveNavOpen: PropTypes.bool.isRequired,
    closeResponsiveNavOnClick: PropTypes.func.isRequired,
    t: PropTypes.func
}

export default withTranslation("navigationBar")(connect(mapStateToProps, mapDispatchToProps)(NavigationMenuContainer))