import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import { programConstants } from '../../../constants/program-constants'

const MyProgramDetails = ({ enrolled, first, last, program, t }) => {
    let headerText = t(enrolled ? 'Enrolled Programs' : 'Available Programs')
    let headerClassName = enrolled ? 'col-3 text-muted pt-2 pb-2' : 'col-3 pt-2 pb-2'

    let borderStyle = last ? { borderBottom: 'none' } : { borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }
    if (program.program === programConstants.ECONOMIC_PRICE_PLAN && enrolled) {
        return (
            <div className="row" style={{ display: 'flex' }}>
                <div className={headerClassName} style={enrolled ? {} : { color: 'rgb(128, 34, 95)' }}>{first ? headerText : ''}</div>
                <div className="col-3 pt-2 pb-2" style={borderStyle}>
                    <a
                        href={program.programLink}
                        target="_blank">
                        {program.programText}
                    </a>
                </div>
                <div className="col-3 pt-2 pb-2" style={borderStyle}>
                    {program.descriptionText}
                </div>
                <div className="col-3 pt-2 pb-2 text-right" style={borderStyle}>
                    {program.docusign.showReapplyLink &&
                    <Link to="/myaccount/epp/application" target="_blank" className="displayAsLink srp-blue-color">{t("Reapply")}</Link>
                    }
                </div>
            </div>
        )
    } else {
        return (
            <div className="row" style={{ display: 'flex' }}>
                <div className={headerClassName} style={enrolled ? {} : { color: 'rgb(128, 34, 95)' }}>{first ? headerText : ''}</div>
                <div className="col-3 pt-2 pb-2" style={borderStyle}>
                    <a
                        href={program.programLink}
                        target="_blank">
                        {program.programText}
                    </a>
                </div>
                <div className="col-3 pt-2 pb-2" style={borderStyle}>
                    {program.descriptionText}
                </div>
                <div className="col-3 pt-2 pb-2 text-right" style={borderStyle}>
                    {program.actionText2.length === 0
                        ? program.actionLink.startsWith('http')
                            ? <a href={program.actionLink} target="_blank">{program.actionText}</a>
                            : <Link to={program.actionLink}>{program.actionText}</Link>

                        : <div className="row">
                            <div className="col">
                                {program.actionLink2.startsWith('http')
                                    ? <a href={program.actionLink2} target="_blank">{program.actionText2}</a>
                                    : <Link to={program.actionLink2}>{program.actionText2}</Link>
                                }
                            </div>
                            <div className="col">
                                {program.program === programConstants.SAFETY_NET
                                    ? <button
                                        className="displayAsLink"
                                        onClick={() => program.actionLink(true)}>{program.actionText}</button>
                                    : program.actionLink.startsWith('http')
                                        ? <a href={program.actionLink} target="_blank">{program.actionText}</a>
                                        : <Link to={program.actionLink}>{program.actionText}</Link>
                                }
                            </div>
                        </div>
                    }
                </div>
            </div>
        )
    }
}

MyProgramDetails.propTypes = {
    enrolled: PropTypes.bool.isRequired,
    first: PropTypes.bool.isRequired,
    last: PropTypes.bool.isRequired,
    program: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
}

export default MyProgramDetails
