import React from 'react'
import PropTypes from 'prop-types'
import { DateTime } from 'luxon'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableHead from '@mui/material/TableHead'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import TableFooter from '@mui/material/TableFooter'
import IconButton from '@mui/material/IconButton'
import Icon from '@mui/material/Icon'
import SrpTooltip from '../../common_tooltip/srp-tooltip'

import '../../../styles/tables.css'
import config from 'my-account-config'

import accountHistoryModule from '../../../srp_modules/account-history-table'
import { paymentConstants } from '../../../constants/payment-constants'
import { accountHistoryConstants } from '../../../constants/account-history-constants'

const BillingHistoryTable = ({numOfBillsDisplayed, accountHistoryList, eBillDocumentList, selectedBillAccount, billingAndPaymentHistoryList, showDetailedTransactionList,
    getPrevBillingHistoryOnClick, getNextBillingHistoryOnClick, billingHistoryIndex, t}) => {

    let historyType = showDetailedTransactionList ? accountHistoryConstants.EXCEL_EXPORT_TYPE.BILLING_DETAIL : accountHistoryConstants.EXCEL_EXPORT_TYPE.BILLING_SUMMARY

    return (
        <div>
            <div className="d-lg-block d-none" style={{overflow: 'auto'}}>
                <Table className="mb-4" id="billingtable">
                    <TableHead className="srp-table-head">
                        <TableRow>
                            <TableCell className="account-history-col-view border-0" padding="none">{t("View")}</TableCell>
                            <TableCell className="account-history-col-billDate border-0" padding="none">{t("Bill date")}</TableCell>
                            <TableCell className="account-history-col-balanceForward border-0" padding="none">{t("Balance forward")}</TableCell>
                            <TableCell className="account-history-col-newCharges border-0" padding="none">{t("New charges")}</TableCell>
                            <TableCell className="account-history-col-amountPaid border-0" padding="none">{t("Amount paid")}</TableCell>
                            <TableCell className="account-history-col-date border-0" padding="none">{t("Date")}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody className="srp-table-body">
                        {billingAndPaymentHistoryList.slice(0,numOfBillsDisplayed).map( (row, index) => {
                            let currentBillPDF=accountHistoryModule.getBill(eBillDocumentList, row.billDate)
                            let tableRow = []
                            tableRow.push(
                                <TableRow key={"billTable"+index}>
                                    <TableCell className="account-history-col-view" padding="none">
                                        <IconButton
                                            href={currentBillPDF}
                                            target="BillPDF"
                                            color="primary"
                                            className="button-focus srp-table-button"
                                            style={{textDecoration: 'none'}}
                                            disabled={currentBillPDF === "" ? true : false}
                                            size="large">
                                            <Icon>visibility</Icon>
                                        </IconButton>
                                    </TableCell>
                                    <TableCell className="account-history-col-billDate" padding="none">{DateTime.fromISO(row.billDate).toFormat('M/d/yy')}</TableCell>
                                    <TableCell className="account-history-col-balanceForward" padding="none">{formatDollarAmount(row.balanceForward)}</TableCell>
                                    <TableCell className="account-history-col-newCharges" padding="none">{formatDollarAmount(row.newCharges)}</TableCell>
                                    <TableCell className="account-history-col-amountPaid" padding="none">
                                        {row.amountPaid === 0 ? "--" : formatDollarAmount(row.amountPaid)}
                                    </TableCell>
                                    <TableCell className="account-history-col-date" padding="none">
                                        {row.paymentDate === "" ? "--" : DateTime.fromISO(row.paymentDate).toFormat('M/d')}
                                    </TableCell>
                                </TableRow>
                            )

                            for(let i = 0; i < row.transactionItems.length; i++) {
                                if(row.transactionItems[i].transactionType !== paymentConstants.TRANSACTION_TYPE_UNKNOWN) {
                                    let transactionColor = row.transactionItems[i].transactionType === paymentConstants.TRANSACTION_TYPE_FEE ? '#9E2A2B': '#737B4C'
                                    tableRow.push(
                                        <TableRow key={"detailTrans"+index+"_"+i} style={showDetailedTransactionList ? {display:'table-row'} : {display:'none'}}>
                                            <TableCell className="account-history-col-view" padding="none"/>
                                            <TableCell className="account-history-col-billDate" padding="none"/>
                                            <TableCell className="account-history-col-balanceForward" padding="none"/>
                                            <TableCell className="account-history-col-newCharges" padding="none"/>
                                            <TableCell className="account-history-col-amountPaid" padding="none" style={{color: transactionColor}}>
                                                <span style={{color: transactionColor, fontSize:'15px', verticalAlign: 'middle'}}>{row.transactionItems[i].paymentAmount === 0 ? "--" : formatDollarAmount(row.transactionItems[i].paymentAmount)}</span>
                                                {row.transactionItems[i].transactionType !== paymentConstants.TRANSACTION_TYPE_PAYMENT
                                                    ? getTooltip(row.transactionItems[i].transactionType, i)
                                                    : <span/>
                                                }
                                            </TableCell>
                                            <TableCell className="account-history-col-date" padding="none" style={{color: transactionColor}}>
                                                {row.transactionItems[i].transactionReceivedDate === "" ? "--" : DateTime.fromISO(row.transactionItems[i].transactionReceivedDate).toFormat('M/d')}
                                            </TableCell>
                                        </TableRow>
                                    )
                                }
                            }

                            return (tableRow)
                        })}
                    </TableBody>
                    <TableFooter className="srp-table-footer">
                        <TableRow>
                            <TableCell className="account-history-col-view" padding="none"/>
                            <TableCell className="account-history-col-billDate" padding="none">{t("Total")}</TableCell>
                            <TableCell className="account-history-col-balanceForward" padding="none"/>
                            <TableCell className="account-history-col-newCharges" padding="none">{getTotalNewCharges(billingAndPaymentHistoryList, numOfBillsDisplayed)}</TableCell>
                            <TableCell className="account-history-col-amountPaid" padding="none">{getTotalAmountPaid(billingAndPaymentHistoryList, numOfBillsDisplayed)}</TableCell>
                            <TableCell className="account-history-col-date" padding="none"/>
                        </TableRow>
                    </TableFooter>
                </Table>

                <div className="d-none d-lg-block">
                    <div className="d-flex justify-content-end">
                        <a 
                            href={`${config.apiBaseUrl}api/accounts/exportAccountHistoryToExcel?billAccount=${selectedBillAccount}&historyType=${historyType}&startDate=${accountHistoryList[numOfBillsDisplayed-1].billGeneratedDate}&endDate=${accountHistoryList[0].billGeneratedDate}`}
                            className="btn srp-btn btn-lightblue">
                            {t("Export to Excel")}
                        </a>
                    </div>
                </div>
            </div>
            <div className="d-lg-none d-block">
                <div className="row d-flex align-items-center">
                    <div className="col text-muted align-middle">
                        {t("Bill date")}
                    </div>
                    <div className="col text-right"> 
                        {DateTime.fromISO(billingAndPaymentHistoryList[billingHistoryIndex].billDate).toFormat('M/d/yy')}
                    </div>
                </div>
                <hr className="mt-2"/>
                <div className="row d-flex align-items-center">
                    <div className="col text-muted align-middle">
                        {t("Balance forward")}
                    </div>
                    <div className="col text-right"> 
                        {formatDollarAmount(billingAndPaymentHistoryList[billingHistoryIndex].balanceForward)}
                    </div>
                </div>
                <hr className="mt-2"/>
                <div className="row d-flex align-items-center">
                    <div className="col text-muted align-middle">
                        {t("New charges")}
                    </div>
                    <div className="col text-right">
                        {formatDollarAmount(billingAndPaymentHistoryList[billingHistoryIndex].newCharges)}
                    </div>
                </div>
                <hr className="mt-2"/>
                <div className="row d-flex align-items-center">
                    <div className="col text-muted align-middle">
                        {t("Amount paid")}
                    </div>
                    <div className="col text-right">
                        {billingAndPaymentHistoryList[billingHistoryIndex].amountPaid === 0 ? "--" : formatDollarAmount(billingAndPaymentHistoryList[billingHistoryIndex].amountPaid)}
                    </div>
                </div>
                <span style={showDetailedTransactionList ? {display:'block'} : {display:'none'}}>
                    {getMobileDetailedTransactions(billingAndPaymentHistoryList[billingHistoryIndex].transactionItems)}
                </span>
                <hr className="mt-2"/>
                <div className="row d-flex align-items-center">
                    <div className="col text-muted align-middle">
                        {t("Date")}
                    </div>
                    <div className="col text-right">
                        {billingAndPaymentHistoryList[billingHistoryIndex].paymentDate === "" ? "--" : DateTime.fromISO(billingAndPaymentHistoryList[billingHistoryIndex].paymentDate).toFormat('M/d')}
                    </div>
                </div>
                <hr className="mt-2 mb-4"/>
                                    
                <div className="d-flex justify-content-between">
                    <button onClick={()=>getPrevBillingHistoryOnClick()} disabled={billingHistoryIndex === 0} className="btn srp-btn btn-lightblue d-flex align-items-center"><i className="material-icons">{'keyboard_arrow_left'}</i></button>
                    <button onClick={()=>getNextBillingHistoryOnClick()} disabled={billingHistoryIndex === (accountHistoryList.length - 1)} className="btn srp-btn btn-lightblue d-flex align-items-center"><i className="material-icons">{'keyboard_arrow_right'}</i></button>
                </div>
            </div>
        </div>
    )
}

BillingHistoryTable.propTypes = { 
    numOfBillsDisplayed: PropTypes.number,
    accountHistoryList: PropTypes.arrayOf(PropTypes.object),
    eBillDocumentList: PropTypes.arrayOf(PropTypes.object),
    selectedBillAccount: PropTypes.number,
    billingAndPaymentHistoryList: PropTypes.arrayOf(PropTypes.object),
    showDetailedTransactionList: PropTypes.bool,

    getPrevBillingHistoryOnClick: PropTypes.func,
    getNextBillingHistoryOnClick: PropTypes.func,
    billingHistoryIndex: PropTypes.number,
    t: PropTypes.func.isRequired
}

export default BillingHistoryTable

function formatDollarAmount(number) {

    let formattedDollarAmount
    if(number < 0) {
        let posNum = number*(-1)
        formattedDollarAmount = "-$"+((posNum).toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    }
    else if(number === 0) {
        formattedDollarAmount = "$"+number
    }
    else {
        formattedDollarAmount = "$"+((number).toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    }
    
    return formattedDollarAmount
}

function getTotalNewCharges(list, numOfBills) {
    let totalCharges = 0
    for(let i=0; i < numOfBills; i++)
    {
        totalCharges += (list[i].newCharges*100)
    }

    return formatDollarAmount(totalCharges/100)
}

function getTotalAmountPaid(list, numOfBills) {
    let totalCharges = 0
    for(let i=0; i < numOfBills; i++)
    {
        totalCharges += (list[i].amountPaid*100)
    }

    return formatDollarAmount(totalCharges/100)
}

function getMobileDetailedTransactions(transactionItems) {
    let detailedTransactions = []
    let transactionColor = '#737B4C'
    for(let i = 0; i < transactionItems.length; i++) {
        if(transactionItems[i].transactionType === paymentConstants.TRANSACTION_TYPE_UNKNOWN)
            return

        transactionColor = transactionItems[i].transactionType === paymentConstants.TRANSACTION_TYPE_FEE ? '#9E2A2B' : '#737B4C'
        detailedTransactions.push(
            <div key={"detailTransMobile"+i}>
                <hr className="mt-2" style={{color:'rgb(204, 204, 204)'}}/>
                <div className="row" style={{alignItems: 'center'}}>
                    <div className="col text-muted"/>
                    <div className="col" style={{whitespace: 'normal', textAlign: 'right', color: transactionColor, verticalAlign: 'middle'}}>
                        {transactionItems[i].transactionType !== paymentConstants.TRANSACTION_TYPE_PAYMENT
                            ? <span className="mr-2">{getTooltip(transactionItems[i].transactionType, i)}</span>
                            : <span/>
                        }
                        <span className="mr-3">{transactionItems[i].transactionReceivedDate === "" ? "--" : DateTime.fromISO(transactionItems[i].transactionReceivedDate).toFormat('M/d')}</span>
                        <span>{transactionItems[i].paymentAmount === 0 ? "--" : formatDollarAmount(transactionItems[i].paymentAmount)}</span>
                    </div>
                </div>
            </div>           
        )
    }

    return detailedTransactions
}

function getTooltip(transType, key) {
    let tooltipColor = '#087ED8'
    let tooltipText = ""
    let tooltip = []
    switch(transType) {
        case paymentConstants.TRANSACTION_TYPE_FEE:
            tooltipColor = '#9E2A2B'
            tooltipText = "Returned item"
            break;
        case paymentConstants.TRANSACTION_TYPE_AGENCY_GUARANTEE:
            tooltipColor = '#087ED8'
            tooltipText = "Agency guarantee"
            break;
        case paymentConstants.TRANSACTION_TYPE_ADJUSTMENT:
            tooltipColor = '#087ED8'
            tooltipText = "Adjustment - Cash Correction"
            break;
    }

    tooltip.push(
        <SrpTooltip key={key} content={<span>{tooltipText}</span>}>
            <Icon style={{color: tooltipColor, fontSize:'15px', verticalAlign: 'middle'}}>info_outline</Icon>
        </SrpTooltip>
    )

    return tooltip
}

