import React from 'react'
import PropTypes from 'prop-types'
import IconButton from '@mui/material/IconButton'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'

let navButtonClasses = {
    root: "associated-account-pagination-buttons"
}

const AssociatedAccountRowsPagination = ({ totalAccounts, pageSize, currentPage, changeCurrentPage, changePageSize, t }) => {
    let totalPages = Math.ceil(totalAccounts / pageSize)
    let displayedPageNumbers = []
    let startingNumber = totalAccounts > 0 ? (currentPage - 1) * pageSize + 1 : 0

    if (totalPages <= 3) {
        for (let i = 1; i <= totalPages; i++) {
            displayedPageNumbers.push(i)
        }
    } else if (currentPage === 1) {
        displayedPageNumbers.push(1)
        displayedPageNumbers.push(2)
        displayedPageNumbers.push(3)
    } else if (currentPage === totalPages) {
        displayedPageNumbers.push(currentPage - 2)
        displayedPageNumbers.push(currentPage - 1)
        displayedPageNumbers.push(currentPage)
    } else {
        displayedPageNumbers.push(currentPage - 1)
        displayedPageNumbers.push(currentPage)
        displayedPageNumbers.push(currentPage + 1)
    }

    return (
        <div className="row pb-4 associated-account-pagination">
            <div className="col-3 pl-0 align-self-center">
                {startingNumber} - {Math.min(currentPage * pageSize, totalAccounts)} of {totalAccounts}
            </div>
            <div className="col-3 align-self-center">
                <label className="mr-2 mb-0">{t("Show")}:</label>
                <Select
                    value={pageSize}
                    onChange={event => changePageSize(event.target.value)}
                >
                    <MenuItem value={20}>20</MenuItem>
                    <MenuItem value={40}>40</MenuItem>
                    <MenuItem value={80}>80</MenuItem>
                    <MenuItem value={totalAccounts}>{t("all")}</MenuItem>
                </Select>
            </div>
            <div className="col-6 align-self-center" style={{ textAlign: 'right'}}>
                <IconButton
                    classes={navButtonClasses}
                    color="primary"
                    size="small"
                    onClick={changeCurrentPage(1)}
                    disabled={currentPage === 1}>
                    <i className="material-icons">first_page</i>
                </IconButton>
                <IconButton
                    classes={navButtonClasses}
                    color="primary"
                    size="small"
                    onClick={changeCurrentPage(currentPage - 1)}
                    disabled={currentPage === 1}>
                    <i className="material-icons">chevron_left</i>
                </IconButton>
                {
                    displayedPageNumbers.map(page => {
                        return (
                            <IconButton
                                classes={{ root: "associated-account-pagination-page-button"}}
                                key={page}
                                color="primary"
                                size="small"
                                onClick={changeCurrentPage(page)}
                                disabled={currentPage === page}>
                                <span>{page}</span>
                            </IconButton>
                        )
                    })
                }
                <IconButton
                    classes={navButtonClasses}
                    color="primary"
                    size="small"
                    onClick={changeCurrentPage(currentPage + 1)}
                    disabled={currentPage === totalPages}>
                    <i className="material-icons">chevron_right</i>
                </IconButton>
                <IconButton
                    classes={navButtonClasses}
                    color="primary"
                    size="small"
                    onClick={changeCurrentPage(totalPages)}
                    disabled={currentPage === totalPages}>
                    <i className="material-icons">last_page</i>
                </IconButton>
            </div>
        </div>
    )
}

AssociatedAccountRowsPagination.propTypes = {
    totalAccounts: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired,
    currentPage: PropTypes.number.isRequired,
    changeCurrentPage: PropTypes.func.isRequired,
    changePageSize: PropTypes.func.isRequired
}

export default AssociatedAccountRowsPagination
