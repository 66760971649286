import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import AppBar from '@mui/material/AppBar'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'

import BillingAlerts from './alerts-billing'
import UsageAlerts from './alerts-usage'
import OutageAlerts from './alerts-outage'
import MPowerAlerts from './alerts-mpower'
import CardLoading from '../../../dashboard_page/card-loading'
import AlertsErrorCard from './alerts-error-card'

import '../../../../styles/dashboard-page.css'
import BillingIconBlack from '../../../../images/nav/nav_icons/black_icons/payment-black.svg'
import BillingIconBlue from '../../../../images/nav/nav_icons/blue_icons/payment-blue.svg'
import UsageIconBlack from '../../../../images/nav/nav_icons/black_icons/usage-black.svg'
import UsageIconBlue from '../../../../images/nav/nav_icons/blue_icons/usage-blue.svg'
import OutageIconBlack from '../../../../images/nav/nav_icons/black_icons/outages-black.svg'
import OutageIconBlue from '../../../../images/nav/nav_icons/blue_icons/outages-blue.svg'

import { getContacts } from '../../../../actions/auth/contact/contact-actions'
import { getNotifications,
        refreshNotification,
        addNotification,
        updateNotification,
        deleteNotification,
        getAlertsTab } from '../../../../actions/auth/notification/notification-actions'
import { getBillingAddress, getMPowerAccountDetails } from '../../../../actions/auth/bill_account/bill-account-actions'
import { addSnackbarMessage } from '../../../../actions/common/snackbar'
import { rateMetaDataConstants } from '../../../../constants/rate-meta-data-constants'
import * as loadingStatus from '../../../../constants/loading-status-constants'
import alertsModule from '../../../../srp_modules/alerts-module'

const iconStyle = {
    height: '23px',
    width: '23px',
}

const tabStyle = {
    outline: 'none',
    boxShadow: 'none',
    textTransform: 'capitalize'
}

class AlertsContainer extends React.Component {
    constructor(props) {
        super(props)

        this.reloadAlerts = this.reloadAlerts.bind(this)
    }

    componentDidMount() {
        if (this.props.selectedBillAccount === 0)
            return

        if (this.props.notificationsStatus === loadingStatus.LOADING_STATUS_INIT) {
            this.props.getNotificationInfo(this.props.selectedBillAccount)
        }

        if (this.props.contactsStatus === loadingStatus.LOADING_STATUS_INIT) {
            this.props.getContactInfo(this.props.selectedBillAccount)
        }

        if (this.props.billingAddressStatus === loadingStatus.LOADING_STATUS_INIT) {
            this.props.getBillingAddressInfo(this.props.selectedBillAccount)
        }
        if (this.props.mPowerAcctDetailsStatus === loadingStatus.LOADING_STATUS_INIT) {
                this.props.getMPowerAccountDetails(this.props.selectedBillAccount)
        }
        
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.selectedBillAccount === nextProps.selectedBillAccount || nextProps.selectedBillAccount === 0)
            return

        if (nextProps.notificationsStatus !== loadingStatus.LOADING_STATUS_IN_PROGRESS) {
            this.props.getNotificationInfo(nextProps.selectedBillAccount)
        }

        if (nextProps.contactsStatus !== loadingStatus.LOADING_STATUS_IN_PROGRESS) {
            this.props.getContactInfo(nextProps.selectedBillAccount)
        }

        if (nextProps.billingAddressStatus !== loadingStatus.LOADING_STATUS_IN_PROGRESS) {
            this.props.getBillingAddressInfo(nextProps.selectedBillAccount)
        }
        if (nextProps.mPowerAcctDetailsStatus !== loadingStatus.LOADING_STATUS_IN_PROGRESS) {
            this.props.getMPowerAccountDetails(nextProps.selectedBillAccount)
        }
        }

    reloadAlerts() {
        if (this.props.notificationsStatus === loadingStatus.LOADING_STATUS_FAILURE)
            this.props.getNotificationInfo(this.props.selectedBillAccount)

        if (this.props.contactsStatus === loadingStatus.LOADING_STATUS_FAILURE)
            this.props.getContactInfo(this.props.selectedBillAccount)

        if (this.props.billingAddressStatus === loadingStatus.LOADING_STATUS_FAILURE)
            this.props.getBillingAddressInfo(this.props.selectedBillAccount)
    }

    render() {
        if (this.props.notificationsStatus === loadingStatus.LOADING_STATUS_INIT 
            || this.props.notificationsStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS
            || this.props.contactsStatus === loadingStatus.LOADING_STATUS_INIT 
            || this.props.contactsStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS
            || this.props.rateMetaDataStatus === loadingStatus.LOADING_STATUS_INIT 
            || this.props.rateMetaDataStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS
            || this.props.billingAddressStatus === loadingStatus.LOADING_STATUS_INIT 
            || this.props.billingAddressStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS
            || this.props.mPowerAcctDetailsStatus === loadingStatus.LOADING_STATUS_INIT
            || this.props.mPowerAcctDetailsStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS) {
            return <CardLoading title="Available alerts" />
        }
        else if (this.props.notificationsStatus === loadingStatus.LOADING_STATUS_SUCCESS && this.props.contactsStatus === loadingStatus.LOADING_STATUS_SUCCESS
                && this.props.billingAddressStatus === loadingStatus.LOADING_STATUS_SUCCESS) {
            let phoneContacts = alertsModule.getPhoneContactList(this.props.contacts)
            let emailContacts = alertsModule.getEmailContactList(this.props.contacts)
            return (
                <div>
                    <div className="srp-card-header">{this.props.t("Available alerts")}</div>
                    <div className="srp-card-body">
                        <AppBar position="static" style={{ backgroundColor: 'white', borderBottom: "1px solid lightgray" }} elevation={0}>
                            <Tabs
                                value={this.props.selectedAlertsTab}
                                onChange={(evt, value) => this.props.getAlertsTabOnClick(value)}
                                indicatorColor="primary"
                                textColor="primary"
                                style={{ color: 'black' }}
                                variant="fullWidth">
                                <Tab
                                    className="mui_tab"
                                    label={<div>
                                        <img className="img-fluid" src={this.props.selectedAlertsTab === 0 ? BillingIconBlack : BillingIconBlue} style={iconStyle} />
                                        <div>{this.props.selectedBillAccountDetails.isPrePay ? this.props.t("Purchases") : this.props.t("Billing")}</div>
                                    </div>}
                                    style={tabStyle} />
                                <Tab
                                    className="mui_tab"
                                    label={<div>
                                        <img className="img-fluid" src={this.props.selectedAlertsTab === 1 ? UsageIconBlack : UsageIconBlue} style={iconStyle} />
                                        <div>{this.props.t("Usage")}</div>
                                    </div>}
                                    style={tabStyle} />
                                <Tab
                                    className="mui_tab"
                                    label={<div>
                                        <img className="img-fluid" src={this.props.selectedAlertsTab === 2 ? OutageIconBlack : OutageIconBlue} style={iconStyle} />
                                        <div>{this.props.t("Outage")}</div>
                                    </div>}
                                    style={tabStyle} />
                            </Tabs>
                        </AppBar>

                        {this.props.selectedAlertsTab === 0
                            && (this.props.selectedBillAccountDetails.isPrePay
                                ? <MPowerAlerts
                                    allAlerts={this.props.allAlerts}
                                    phoneContacts={phoneContacts}
                                    emailContacts={emailContacts}
                                    mPowerAcctDetails={this.props.mPowerAcctDetails}
                                    addNotificationOnClick={this.props.addNotificationOnClick}
                                    deleteNotificationOnClick={this.props.deleteNotificationOnClick}
                                    deleteAllNotificationsOnClick={this.props.deleteAllNotificationsOnClick} 
                                    t={this.props.t}/>
                                : <BillingAlerts
                                    phoneContacts={phoneContacts}
                                    emailContacts={emailContacts}
                                    addNotificationOnClick={this.props.addNotificationOnClick}
                                    updateNotificationOnBlur={this.props.updateNotificationOnBlur}
                                    deleteNotificationOnClick={this.props.deleteNotificationOnClick}
                                    deleteAllNotificationsOnClick={this.props.deleteAllNotificationsOnClick}
                                    isSummaryMaster={this.props.rateMetaData.summaryBilling === rateMetaDataConstants.SUMMARY_BILLING_PARENT} 
                                    t={this.props.t}/>
                            )
                        }
                        {this.props.selectedAlertsTab === 1
                            && <UsageAlerts
                                allAlerts={this.props.allAlerts}
                                phoneContacts={phoneContacts}
                                emailContacts={emailContacts}
                                addNotificationOnClick={this.props.addNotificationOnClick}
                                deleteNotificationOnClick={this.props.deleteNotificationOnClick}
                                deleteAllNotificationsOnClick={this.props.deleteAllNotificationsOnClick} 
                                t={this.props.t}/>
                        }
                        {this.props.selectedAlertsTab === 2
                            && <OutageAlerts
                                allAlerts={this.props.allAlerts}
                                phoneContacts={phoneContacts}
                                emailContacts={emailContacts}
                                addNotificationOnClick={this.props.addNotificationOnClick}
                                deleteNotificationOnClick={this.props.deleteNotificationOnClick}
                                deleteAllNotificationsOnClick={this.props.deleteAllNotificationsOnClick} 
                                t={this.props.t}/>
                        }
                    </div>
                </div>
            )
        }
        else {
            return <AlertsErrorCard refreshData={this.reloadAlerts} />
        }
    }
}

const mapStateToProps = (state) => {
    return { ...state.accountInfo.billAccount, 
        ...state.accountInfo.notification, 
        ...state.accountInfo.contact, 
        ...state.rateMetaData,
        ...state.accountInfo.billAccount.mPowerAcctDetails
     }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getContactInfo: (billAccount) => {
            dispatch(getContacts(billAccount))
        },
        getBillingAddressInfo: (billAccount) => {
            dispatch(getBillingAddress(billAccount))
        },
        getNotificationInfo: (billAccount) => {
            dispatch(getNotifications(billAccount))
        },
        getAlertsTabOnClick: (tab) => {
            dispatch(getAlertsTab(tab))
        },
        getMPowerAccountDetails: (billAccount) => {
            dispatch(getMPowerAccountDetails(billAccount))
        },
        addNotificationOnClick: async (billAccount, notification, notificationData, contact) => {
            let notificationToAdd = alertsModule.createNotificationForAdd(billAccount, notification, notificationData, contact)
            let response = await dispatch(addNotification(billAccount, notificationToAdd))
            if (response.error || response.payload !== 1)
                dispatch(addSnackbarMessage(<span>Unable to add alert</span>))

            dispatch(refreshNotification(billAccount))
        },
        updateNotificationOnBlur: async (billAccount, notification, notificationData) => {
            let notificationToUpdate = alertsModule.createNotificationForUpdateDelete(billAccount, notification, notificationData, [])
            let response = await dispatch(updateNotification(billAccount, notificationToUpdate))
            if (response.error || response.payload !== 1)
                dispatch(addSnackbarMessage(<span>Unable to update alert</span>))

            dispatch(refreshNotification(billAccount))
        },
        deleteNotificationOnClick: async (billAccount, notification, notificationData, contact) => {
            let notificationToDelete = alertsModule.createNotificationForUpdateDelete(billAccount, notification, notificationData, contact)
            let response = await dispatch(deleteNotification(billAccount, notificationToDelete))
            if(response.error || (response.payload !== 1 && response.payload !== 12))
                dispatch(addSnackbarMessage(<span>Unable to remove alert</span>))

            dispatch(refreshNotification(billAccount))
            return response
        },
        deleteAllNotificationsOnClick: async (billAccount, notification, notificationData, contact) => {
            let responseArray = []
            let response
            let error = false

            for (let i = 0; i < contact.length; i++) {
                let notificationToDelete = alertsModule.createNotificationForUpdateDelete(billAccount, notification, notificationData, contact[i])
                response = await dispatch(deleteNotification(billAccount, notificationToDelete))
                if (response.error || (response.payload !== 1 && response.payload !== 12))
                    responseArray.push(response)
            }

            for (let i = 0; i < responseArray.length; i++) {
                if (responseArray[i].error || (responseArray[i].payload !== 1 && responseArray[i].payload !== 12))
                    error = true
            }

            if (error) {
                dispatch(addSnackbarMessage(<span>Unable to remove alert</span>))
            }

            dispatch(refreshNotification(billAccount))
        }
    }
}

AlertsContainer.propTypes = {
    selectedBillAccount: PropTypes.number,
    selectedBillAccountDetails: PropTypes.object,

    getAlertsTabOnClick: PropTypes.func,
    selectedAlertsTab: PropTypes.number,

    contacts: PropTypes.arrayOf(PropTypes.object),
    contactsStatus: PropTypes.string,
    getContactInfo: PropTypes.func,

    billingAddressStatus: PropTypes.string,
    getBillingAddressInfo: PropTypes.func,

    allAlerts: PropTypes.object,
    notificationsStatus: PropTypes.string,
    getNotificationInfo: PropTypes.func,

    addNotificationOnClick: PropTypes.func,
    addNotificationStatus: PropTypes.string,
    addNotificationError: PropTypes.bool,

    updateNotificationOnBlur: PropTypes.func,

    deleteNotificationOnClick: PropTypes.func,
    deleteAllNotificationsOnClick: PropTypes.func,

    rateMetaData: PropTypes.object,
    rateMetaDataStatus: PropTypes.string,

    mPowerAcctDetails: PropTypes.object,
    mPowerAcctDetailsStatus: PropTypes.string,
    getMPowerAccountDetails: PropTypes.func,

    t: PropTypes.func
}

export default connect(mapStateToProps, mapDispatchToProps)(AlertsContainer)