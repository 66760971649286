import React from 'react'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import { Formik } from 'formik'
import FormControl from '@mui/material/FormControl'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import Divider from '@mui/material/Divider'
import TextField from '@mui/material/TextField'
import CircularProgress from '@mui/material/CircularProgress'

import AnalyticsFormTracking from '../../common_formik/analytics-form-tracking'

import HealthyForestConfirmation from './healthy-forest-confirmation'

import { healthyForestFormConstants } from '../../../constants/healthy-forrest-constants'
import * as loadingStatus from '../../../constants/loading-status-constants'

import validate from '../../../srp_modules/validate'

const HealthyForestFormik = ({ customerName, formattedBillAccount, serviceAddress, formattedPhoneNumber, emailAddress,
    healthyForestDonationAmt, submitHealthyForestFormStatus, formType, submitHealthyForestForm,
    initialChangedState, onStateChanged, isCommercial, t, i18n }) => {
    let navigate = useNavigate()

    return (
        <Formik
            initialValues={{
                emailAddress: emailAddress,
                selectedDonationAmount: healthyForestDonationAmt,
                formType: formType
            }}
            validate={(values) => validateForm(values)}
            onSubmit={(values, actions) => {
                submitHealthyForestForm(values, actions)
            }}>
            {({ values, errors, touched, handleBlur, handleChange, handleSubmit }) => {
                let emailError = touched.emailAddress && errors.email !== undefined
                let invalidEmailError = touched.emailAddress && errors.invalidEmail !== undefined

                return (
                    <div>
                        <AnalyticsFormTracking
                            name={formType === healthyForestFormConstants.HEALTHY_FOREST_SIGNUP
                                    ? "SRP Healthy Forest Initiative Sign-Up"
                                    : "Change SRP Healthy Forest Initiative"}
                            formStep="select Healthy Forest Initiative donation amount"
                            meta={getMetaData(isCommercial, values.selectedDonationAmount)}
                            onStateChanged={onStateChanged}
                            initialChangedState={initialChangedState} />

                        {submitHealthyForestFormStatus !== loadingStatus.LOADING_STATUS_SUCCESS
                        ?
                        <form
                            className="mb-2 mt-2 text-muted"
                            onSubmit={handleSubmit}
                        >
                            <div className="srp-card-body text-muted">
                                <div className="srp-card-details">
                                    {submitHealthyForestFormStatus === loadingStatus.LOADING_STATUS_FAILURE
                                        ? <div className="srp-alert-error mb-4">{t("We're sorry, something went wrong. Please try again later.")}</div>
                                        : null
                                    }
                                    <h4 className="mb-3" style={{ color: 'rgba(51, 51, 51, 0.537)' }}>{t("SRP account information")}</h4>
                                    <div className="d-lg-block d-none mb-3">
                                        <div>
                                            <span className="font-weight-bold mr-2">{t("Name")}</span>
                                            <span>{customerName}</span>
                                        </div>
                                        <div>
                                            <span className="font-weight-bold mr-2">{t("SRP account number")}</span>
                                            <span>{formattedBillAccount}</span>
                                        </div>
                                        <div>
                                            <span className="font-weight-bold mr-2">{t("Service address")}</span>
                                            <span>{serviceAddress.fullStreetAddress}</span>
                                        </div>
                                        <div>
                                            <span className="font-weight-bold mr-2">{t("Phone number")}</span>
                                            <span>{formattedPhoneNumber}</span>
                                        </div>
                                    </div>
                                    <div className="d-lg-none d-block mb-3">
                                        <div className="row">
                                            <div className="col-12 font-weight-bold">{t("Name")}</div>
                                            <div className="col-12">{customerName}</div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12 font-weight-bold">{t("SRP account number")}</div>
                                            <div className="col-12">{formattedBillAccount}</div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12 font-weight-bold">{t("Service address")}</div>
                                            <div className="col-12">{serviceAddress.streetAddress}</div>
                                            <div className="col-12">{serviceAddress.cityStateZip}</div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12 font-weight-bold">{t("Phone number")}</div>
                                            <div className="col-12">{formattedPhoneNumber}</div>
                                        </div>
                                    </div>
                                    <div>
                                        {t("A confirmation will be sent to")}{` `}
                                        {emailAddress.length > 0
                                            ? <span className="font-weight-bold">{emailAddress}</span>
                                            : <div>
                                                <TextField
                                                    fullWidth
                                                    name="emailAddress"
                                                    value={values.emailAddress}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    helperText={
                                                        emailError
                                                            ? t(errors.email)
                                                            : invalidEmailError
                                                                ? t(errors.invalidEmail)
                                                                : ''}
                                                    error={emailError || invalidEmailError} />
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                            <Divider />
                            <div className="srp-card-body text-muted">
                                <div className="srp-card-details">
                                    <div className="font-16 text-dark font-weight-bold">{t("Help restore our forests and protect the water we drink")}</div>
                                    <div className="font-16">{t("Healthy_Forest_program_info")}</div>
                                </div>
                            </div>
                            <Divider />
                            <div className="srp-card-body text-muted">
                                <div className="srp-card-details">
                                    <div className="font-16 mb-2">{t("Amount to be added to my monthly bill")}</div>
                                    <FormControl className="mb-4">
                                        <RadioGroup
                                            id="selectedDonationAmount"
                                            name="selectedDonationAmount"
                                            value={values.selectedDonationAmount}
                                            onChange={handleChange}>
                                            <FormControlLabel className="m-0" value={healthyForestFormConstants.DONATION_AMT_3} control={<Radio color="primary" />} label="$3" />
                                            <FormControlLabel className="m-0" value={healthyForestFormConstants.DONATION_AMT_6} control={<Radio color="primary" />} label="$6" />
                                            <FormControlLabel className="m-0" value={healthyForestFormConstants.DONATION_AMT_9} control={<Radio color="primary" />} label="$9" />
                                            <FormControlLabel className="m-0" value={healthyForestFormConstants.DONATION_AMT_12} control={<Radio color="primary" />} label="$12" />
                                            {formType === healthyForestFormConstants.HEALTHY_FOREST_CHANGE
                                                ? <FormControlLabel
                                                    className="m-0"
                                                    value={healthyForestFormConstants.DONATION_AMT_UNENROLL}
                                                    control={<Radio color="primary" />}
                                                    label={t("Unenroll")} />
                                                : null}
                                        </RadioGroup>
                                    </FormControl>
                                    <div className="d-flex flex-wrap justify-content-end">
                                        <button
                                            className="btn srp-btn btn-lightblue"
                                            type="button"
                                            onClick={() => navigate(-1)}>
                                            {t("Cancel")}
                                        </button>
                                        <button
                                            className="btn srp-btn btn-green ml-2"
                                            type="submit"
                                            disabled={
                                                values.selectedDonationAmount === healthyForestFormConstants.DONATION_AMT_NONE
                                                || healthyForestDonationAmt === values.selectedDonationAmount
                                                || emailError
                                                || invalidEmailError
                                                || submitHealthyForestFormStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS}>
                                            {submitHealthyForestFormStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS
                                                ? <CircularProgress size={20} thickness={5} style={{ color: 'white' }} />
                                                : t("Submit")
                                            }
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                        :
                        <HealthyForestConfirmation formType={formType} t={t} />
                        }
                    </div>
                )
            }}
        </Formik>
    )
}

HealthyForestFormik.propTypes = {
    customerName: PropTypes.string,
    formattedBillAccount: PropTypes.string,
    serviceAddress: PropTypes.object,
    formattedPhoneNumber: PropTypes.string,
    emailAddress: PropTypes.string,
    healthyForestDonationAmt: PropTypes.string,
    submitHealthyForestForm: PropTypes.func,
    submitHealthyForestFormStatus: PropTypes.string,
    formType: PropTypes.number,
    initialChangedState: PropTypes.bool,
    onStateChanged: PropTypes.func,
    isCommercial: PropTypes.bool,

    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired,
}

export default HealthyForestFormik

function validateForm(values) {
    let errors = {}

    if (values.emailAddress === undefined || values.emailAddress.length === 0)
        errors.email = 'Email address is required'
    if (!validate.emailAddress(values.emailAddress))
        errors.invalidEmail = 'Please enter a valid email address'

    return errors
}

function getMetaData(isCommercial, selectedDonation){
    let donation = "none"
    let accountType = isCommercial ? "commercial" : "residential"
    switch(selectedDonation) {
        case healthyForestFormConstants.DONATION_AMT_3: donation = "$3"
            break
        case healthyForestFormConstants.DONATION_AMT_6: donation = "$6"
            break
        case healthyForestFormConstants.DONATION_AMT_9: donation = "$9"
            break
        case healthyForestFormConstants.DONATION_AMT_12: donation = "$12"
            break
        case healthyForestFormConstants.DONATION_AMT_UNENROLL: donation = "unenroll"
            break
        default: donation = "none"
    }

    return {
        accountType: accountType,
        amount: donation
    }
}