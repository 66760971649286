export const itemizedStatementConstants = {
    GET_ITEMIZED_STATEMENT_LINK_REQUEST: "GET_ITEMIZED_STATEMENT_LINK_REQUEST",
    GET_ITEMIZED_STATEMENT_LINK_SUCCESS: "GET_ITEMIZED_STATEMENT_LINK_SUCCESS",
    GET_ITEMIZED_STATEMENT_LINK_FAILURE: "GET_ITEMIZED_STATEMENT_LINK_FAILURE",
    REQUEST_ITEMIZED_STMT_REQUEST: "REQUEST_ITEMIZED_STMT_REQUEST",
    REQUEST_ITEMIZED_STMT_SUCCESS: "REQUEST_ITEMIZED_STMT_SUCCESS",
    REQUEST_ITEMIZED_STMT_FAILURE: "REQUEST_ITEMIZED_STMT_FAILURE",
    REFRESH_ITEMIZED_STATEMENT_LINK_REQUEST: "REFRESH_ITEMIZED_STATEMENT_LINK_REQUEST"
}

export const creditHistoryConstants = {
    GET_CREDIT_HISTORY_LINK_REQUEST: "GET_CREDIT_HISTORY_LINK_REQUEST",
    GET_CREDIT_HISTORY_LINK_SUCCESS: "GET_CREDIT_HISTORY_LINK_SUCCESS",
    GET_CREDIT_HISTORY_LINK_FAILURE: "GET_CREDIT_HISTORY_LINK_FAILURE",
    REFRESH_CREDIT_HISTORY_LINK_REQUEST:"REFRESH_CREDIT_HISTORY_LINK_REQUEST"
}

export const usageHistoryConstants = {
    GET_USAGE_HIST_LINK_REQUEST: "GET_USAGE_HIST_LINK_REQUEST",
    GET_USAGE_HIST_LINK_SUCCESS: "GET_USAGE_HIST_LINK_SUCCESS",
    GET_USAGE_HIST_LINK_FAILURE: "GET_USAGE_HIST_LINK_FAILURE",
    REFRESH_USAGE_HIST_LINK_REQUEST: "REFRESH_USAGE_HIST_LINK_REQUEST"
}

export const energyScoreCardConstants = {
    GET_SCORECARD_LINKS_REQUEST: "GET_SCORECARD_LINKS_REQUEST",
    GET_SCORECARD_LINKS_SUCCESS: "GET_SCORECARD_LINKS_SUCCESS",
    GET_SCORECARD_LINKS_FAILURE: "GET_SCORECARD_LINKS_FAILURE",
}

export const energyScoreCardFAQConstants = {
    GET_SCORECARD_FAQ_LINK_REQUEST: "GET_SCORECARD_FAQ_LINK_REQUEST",
    GET_SCORECARD_FAQ_LINK_SUCCESS: "GET_SCORECARD_FAQ_LINK_SUCCESS",
    GET_SCORECARD_FAQ_LINK_FAILURE: "GET_SCORECARD_FAQ_LINK_FAILURE",
}

export const daytimeSaverPilotConstants = {
    GET_DAYTIME_SAVER_LINKS_REQUEST: "GET_DAYTIME_SAVER_LINKS_REQUEST",
    GET_DAYTIME_SAVER_LINKS_SUCCESS: "GET_DAYTIME_SAVER_LINKS_SUCCESS",
    GET_DAYTIME_SAVER_LINKS_FAILURE: "GET_DAYTIME_SAVER_LINKS_FAILURE",
    REFRESH_DAYTIME_SAVER_LINKS_REQUEST:"REFRESH_DAYTIME_SAVER_LINKS_REQUEST"
}

export const communitySolarConstants = {
    GET_COMMUNITY_SOLAR_LINK_REQUEST: "GET_COMMUNITY_SOLAR_LINK_REQUEST",
    GET_COMMUNITY_SOLAR_LINK_SUCCESS: "GET_COMMUNITY_SOLAR_LINK_SUCCESS",
    GET_COMMUNITY_SOLAR_LINK_FAILURE: "GET_COMMUNITY_SOLAR_LINK_FAILURE",
    REFRESH_COMMUNITY_SOLAR_LINK_REQUEST:"REFRESH_COMMUNITY_SOLAR_LINK_REQUEST"
}

