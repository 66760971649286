import React from "react"
import PropTypes from "prop-types"
import Card from "@mui/material/Card"
import { Link } from 'react-router-dom'
import { withTranslation } from 'react-i18next'

const HelpFindPayLocationCard = ({t}) => (
    <div className="mt-lg-0 mt-3">
        <label className="h5">{t("Locations")}</label>
        <Card className="p-4">
            <h4 className="mb-4 text-muted">{t("Need help finding participating retail locations?")}</h4>
            <div>{`${t("Our")} `} <Link to="/myaccount/paymentlocations">{t("In-person Payment Locator")}</Link> {`${t("will get you where you need to go")}.`}</div>
        </Card>
    </div>
)

HelpFindPayLocationCard.propTypes = {
    t: PropTypes.func.isRequired
}

export default withTranslation('cashPaymentCard')(HelpFindPayLocationCard)