import { authorizedPartyConstants } from '../../../constants/authorized-party-constants'
import * as loadingStatus from '../../../constants/loading-status-constants'

const initialState = {
    submitAuthorizedPartyFormResult: {
        success: false,
        errorMessage: ""
    },
    submitAuthorizedPartyFormStatus: loadingStatus.LOADING_STATUS_INIT
}

export default function authorizedPartyReducer(state = initialState, action) {
    switch (action.type) {
        case authorizedPartyConstants.SUBMIT_AUTHORIZED_PARTY_FORM_REQUEST:
            return { ...state, submitAuthorizedPartyFormStatus: loadingStatus.LOADING_STATUS_IN_PROGRESS }
        case authorizedPartyConstants.SUBMIT_AUTHORIZED_PARTY_FORM_SUCCESS:
            return { ...state, submitAuthorizedPartyFormStatus: loadingStatus.LOADING_STATUS_SUCCESS, submitAuthorizedPartyFormResult: action.result }
        case authorizedPartyConstants.SUBMIT_AUTHORIZED_PARTY_FORM_FAILURE:
            return { ...state, submitAuthorizedPartyFormStatus: loadingStatus.LOADING_STATUS_FAILURE, submitAuthorizedPartyFormResult: initialState.submitAuthorizedPartyFormResult }
        case authorizedPartyConstants.RESET_AUTHORIZED_PARTY_FORM:
            return { ...state, submitAuthorizedPartyFormResult: initialState.submitAuthorizedPartyFormResult, submitAuthorizedPartyFormStatus: loadingStatus.LOADING_STATUS_INIT}
        default:
            return state
    }
}