import { RSAA } from 'redux-api-middleware'
import { outageConstants } from '../../../constants/outage-constants'
import config from 'my-account-config'

//Actions for getting all Outages
export const getAllOutages = () => dispatch => {
    return dispatch({
        [RSAA]: {
            endpoint: config.apiBaseUrl + 'api/outages/getall',
            method: 'GET',
            types: [
                outageConstants.GET_ALL_OUTAGES_REQUEST,
                outageConstants.GET_ALL_OUTAGES_SUCCESS,
                outageConstants.GET_ALL_OUTAGES_FAILURE
            ]
        }
    })
}

export const updateAllOutages = () => dispatch => {
    return dispatch({
        [RSAA]: {
            endpoint: config.apiBaseUrl + 'api/outages/getall',
            method: 'GET',
            types: [
                outageConstants.UPDATE_ALL_OUTAGES_REQUEST,
                outageConstants.UPDATE_ALL_OUTAGES_SUCCESS,
                outageConstants.UPDATE_ALL_OUTAGES_FAILURE
            ]
        }
    })
}

//Actions for getting assistance locations (ice locations)
export const getIceReimbursementInfo = () => dispatch => {
    return dispatch({
        [RSAA]: {
            endpoint: config.apiBaseUrl + 'api/outages/icereimbursementinfo',
            method: 'GET',
            types: [
                outageConstants.GET_ICE_REIMBURSEMENT_INFO_REQUEST,
                outageConstants.GET_ICE_REIMBURSEMENT_INFO_SUCCESS,
                outageConstants.GET_ICE_REIMBURSEMENT_INFO_FAILURE
            ]
        }
    })
}

//Action to update service address, outage info
export const getPublicOutageActions = () => {
    return dispatch => {
        dispatch( getAllOutages() )
    }
}

export const updatePublicOutageActions = () => {
    return dispatch => {
        dispatch( updateAllOutages() )
    }
}
