export const usageConstants = {
    GET_HOURLY_SUCCESS: 'GET_HOURLY_SUCCESS',
    GET_HOURLY_RANGE_SUCCESS: 'GET_HOURLY_RANGE_SUCCESS',
    GET_YESTERDAY_SUCCESS: 'GET_YESTERDAY_SUCCESS',
    GET_MONTHLY_SUCCESS: 'GET_MONTHLY_SUCCESS',

    GET_USAGE_CHART_TAB: 'GET_USAGE_CHART_TAB',
    GET_USAGE_PAGE_CHART_TAB: 'GET_USAGE_PAGE_CHART_TAB',

    GET_USAGE_HOURLY_CHART_TYPE: 'GET_USAGE_HOURLY_CHART_TYPE',
    GET_USAGE_DAILY_CHART_TYPE: 'GET_USAGE_DAILY_CHART_TYPE',
    GET_USAGE_MONTHLY_CHART_TYPE: 'GET_USAGE_MONTHLY_CHART_TYPE',

    CHANGE_DATE_RANGE: 'CHANGE_DATE_RANGE',
    CHANGE_HOURLY_DATE_RANGE: 'CHANGE_HOURLY_DATE_RANGE',
    CHANGE_MONTHLY_DATE_RANGE: 'CHANGE_MONTHLY_DATE_RANGE',
    INIT_HOURLY_USAGE_PAGE: 'INIT_HOURLY_USAGE_PAGE',
    INIT_DAILY_USAGE_PAGE: 'INIT_DAILY_USAGE_PAGE',

    GET_HOURLY_DATA_TABLE: 'GET_HOURLY_DATA_TABLE',
    GET_DAILY_DATA_TABLE: 'GET_DAILY_DATA_TABLE',
    GET_MONTHLY_DATA_TABLE: 'GET_MONTHLY_DATA_TABLE',

    SET_USAGE_PAGE_FROM_DASH_BUTTONS: 'SET_USAGE_PAGE_FROM_DASH_BUTTONS'
}