import React from 'react'
import PropTypes from 'prop-types'

import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'

import utils from '../../../srp_modules/utils'
import { Trans } from 'react-i18next'

class DaytimeSaverPilotDocs extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            selectedDocument: utils.createUrl(props.daytimeSaverPilotLinks[0].url)
        }

        this.changeSaverDocument = this.changeSaverDocument.bind(this)
    }

    changeSaverDocument(newURL) {
        this.setState({ selectedDocument: newURL })
    }

    render() {
        let daytimeSaverHTML = null

        if (this.props.isE23 && this.props.daytimeSaverPilotLinks.length > 0) {
            daytimeSaverHTML =
                (<div className="pt-1 pb-3">
                    <h3>Daytime Saver&trade; {this.props.t("pilot")}</h3>
                    <div className="text-muted">
                    <Trans i18nKey="learn_more_about_daytime_saver" t={this.props.t}>
                        See how and when you used energy, as well as the overall cost, throughout the month. Data shows your usage per calendar month and will not necessarily align to your billing cycle. Learn more about the Daytime Saver&trade; pilot <a href="http://srp.net/daytimesaver">here</a>.
                    </Trans>
                    </div>
                    <div className="row display-block pt-2">
                        <div className="col-12">
                            Daytime Saver&trade; {this.props.t("pilot energy use for")}:
                        </div>
                        <div className="col-6">
                            <Select
                                fullWidth
                                value={this.state.selectedDocument}
                                selected={this.state.selectedDocument}
                                onChange={event => this.changeSaverDocument(event.target.value)}
                            >
                                {this.props.daytimeSaverPilotLinks.map((daytimeSaverLink) => {
                                    return (<MenuItem key={daytimeSaverLink.url} value={utils.createUrl(daytimeSaverLink.url)}>{daytimeSaverLink.description}</MenuItem>)
                                })}
                            </Select>
                        </div>
                        <div className="col=6">
                            <a href={this.state.selectedDocument} className="btn srp-btn btn-lightblue">{this.props.t("Download report")}</a>
                        </div>
                    </div>
                </div>)
        }

        return (
            <div>
                {daytimeSaverHTML}
            </div>
        )
    }
}

DaytimeSaverPilotDocs.propTypes = {
    daytimeSaverPilotLinks: PropTypes.array.isRequired,
    isE23: PropTypes.bool.isRequired,

    refreshFailedLinks: PropTypes.func,
    t:  PropTypes.func
}

export default DaytimeSaverPilotDocs