import { DateTime } from 'luxon'
import { pricePlanConstants } from '../../constants/price-plan-constants'
import { formConstants } from '../../constants/form-constants'
import {
    mapPlanToServerEnum,
    mapChargingSupplyType,
    mapChargingVoltage,
    mapChargingAmps,
    mapIdentificationType
} from './turnon-mapper'
import format from '../format'
import { formatDayWithOrdinal } from '../utils'

const transferModule = {
    disableDates: disableDates,
    getMinDates: getMinDates,
    getPricePlanDescription: getPricePlanDescription,
    getSolarChoiceDisplay: getSolarChoiceDisplay,
    getIDTypeDisplay: getIDTypeDisplay,
    formatIDNumber: formatIDNumber,
    createTransferServiceModel: createTransferServiceModel
}

function disableDates(date, holidayList) {
    let isDisabled = false

    // Luxon uses 6 for Saturday and 7 for Sunday
    let isWeekend = DateTime.fromISO(date).weekday > 5

    let isHoliday = false
    holidayList.forEach(holiday => {
        if (DateTime.fromISO(date).hasSame(DateTime.fromISO(holiday), 'day')) {
            isHoliday = true
        }

        if (DateTime.fromISO(holiday).hasSame(DateTime.now().setZone('MST'), 'day')) {
            isHoliday = false
        }
    })

    if (isWeekend || isHoliday)
        isDisabled = true

    return isDisabled
}

function getMinDates() {
    let todayTenPM = DateTime.now().setZone('MST').startOf('day').plus({ hours: 22 })
    let isPastValidTime = DateTime.now().setZone('MST') > todayTenPM ? true : false

    let minDate = isPastValidTime ? DateTime.now().setZone('MST').plus({ days: 2 })
                                  : DateTime.now().setZone('MST').plus({ days: 1 })

    return minDate.startOf('day')
}

function getPricePlanDescription(pricePlan, language) {
    let description = pricePlanConstants.PRICE_PLAN_NAME.UNKNOWN

    if(language === "es") {
        switch (pricePlan) {
            case pricePlanConstants.PRICE_PLAN_RATE_CODE_MAP.EZ3:
                description = pricePlanConstants.PRICE_PLAN_NAME.ES.EZ3_RES
                break
            case pricePlanConstants.PRICE_PLAN_RATE_CODE_MAP.EZ3_2_5:
                description = pricePlanConstants.PRICE_PLAN_NAME.ES.EZ3_2_5
                break
            case pricePlanConstants.PRICE_PLAN_RATE_CODE_MAP.EZ3_4_7:
                description = pricePlanConstants.PRICE_PLAN_NAME.ES.EZ3_4_7
                break
            case pricePlanConstants.PRICE_PLAN_RATE_CODE_MAP.TOU:
                description = pricePlanConstants.PRICE_PLAN_NAME.ES.TOU_RES
                break
            case pricePlanConstants.PRICE_PLAN_RATE_CODE_MAP.BASIC:
                description = pricePlanConstants.PRICE_PLAN_NAME.ES.BASIC_RES
                break
            case pricePlanConstants.PRICE_PLAN_RATE_CODE_MAP.MPOWER:
                description = pricePlanConstants.PRICE_PLAN_NAME.ES.MPOWER_RES
                break
            case pricePlanConstants.PRICE_PLAN_RATE_CODE_MAP.EV:
                description = pricePlanConstants.PRICE_PLAN_NAME.ES.EV_RES
                break
            case pricePlanConstants.PRICE_PLAN_RATE_CODE_MAP.GENERATION:
                description = pricePlanConstants.PRICE_PLAN_NAME.ES.GENERATION_RES
                break
            case pricePlanConstants.PRICE_PLAN_RATE_CODE_MAP.TOU_EXPORT:
                description = pricePlanConstants.PRICE_PLAN_NAME.ES.TOU_EXPORT
                break
            case pricePlanConstants.PRICE_PLAN_RATE_CODE_MAP.EV_EXPORT:
                description = pricePlanConstants.PRICE_PLAN_NAME.ES.EV_EXPORT
                break
            case pricePlanConstants.PRICE_PLAN_RATE_CODE_MAP.AVERAGE_DEMAND:
                description = pricePlanConstants.PRICE_PLAN_NAME.ES.AVERAGE_DEMAND
                break
        }
    }
    else {
        switch (pricePlan) {
            case pricePlanConstants.PRICE_PLAN_RATE_CODE_MAP.EZ3:
                description = pricePlanConstants.PRICE_PLAN_NAME.EN.EZ3_RES
                break
            case pricePlanConstants.PRICE_PLAN_RATE_CODE_MAP.EZ3_2_5:
                description = pricePlanConstants.PRICE_PLAN_NAME.EN.EZ3_2_5
                break
            case pricePlanConstants.PRICE_PLAN_RATE_CODE_MAP.EZ3_4_7:
                description = pricePlanConstants.PRICE_PLAN_NAME.EN.EZ3_4_7
                break
            case pricePlanConstants.PRICE_PLAN_RATE_CODE_MAP.TOU:
                description = pricePlanConstants.PRICE_PLAN_NAME.EN.TOU_RES
                break
            case pricePlanConstants.PRICE_PLAN_RATE_CODE_MAP.BASIC:
                description = pricePlanConstants.PRICE_PLAN_NAME.EN.BASIC_RES
                break
            case pricePlanConstants.PRICE_PLAN_RATE_CODE_MAP.MPOWER:
                description = pricePlanConstants.PRICE_PLAN_NAME.EN.MPOWER_RES
                break
            case pricePlanConstants.PRICE_PLAN_RATE_CODE_MAP.EV:
                description = pricePlanConstants.PRICE_PLAN_NAME.EN.EV_RES
                break
            case pricePlanConstants.PRICE_PLAN_RATE_CODE_MAP.GENERATION:
                description = pricePlanConstants.PRICE_PLAN_NAME.EN.GENERATION_RES
                break
            case pricePlanConstants.PRICE_PLAN_RATE_CODE_MAP.TOU_EXPORT:
                description = pricePlanConstants.PRICE_PLAN_NAME.EN.TOU_EXPORT
                break
            case pricePlanConstants.PRICE_PLAN_RATE_CODE_MAP.EV_EXPORT:
                description = pricePlanConstants.PRICE_PLAN_NAME.EN.EV_EXPORT
                break
            case pricePlanConstants.PRICE_PLAN_RATE_CODE_MAP.AVERAGE_DEMAND:
                description = pricePlanConstants.PRICE_PLAN_NAME.EN.AVERAGE_DEMAND
                break
        }
    }

    return description
}

function getSolarChoiceDisplay(solarChoice) {
    let display = ""
    switch (solarChoice) {
        case pricePlanConstants.SOLAR_CHOICE.SC100:
        case formConstants.SOLARCHOICE_100:
            display = "100%"
            break
        case pricePlanConstants.SOLAR_CHOICE.SC50:
        case formConstants.SOLARCHOICE_50:
            display = "50%"
            break
        case pricePlanConstants.SOLAR_CHOICE.UNENROLL:
            display = "Unenroll"
            break
        default:
            break
    }

    return display
}

function getIDTypeDisplay(idType) {
    let display = ""
    switch (idType) {
        case formConstants.SOCIAL:
            display = "Social Security #"
            break
        case formConstants.DRIVERS_LICENSE:
            display = "Driver's License"
            break
        case formConstants.STATE_ID:
            display = "State ID"
            break
        case formConstants.GOVERNMENT_ID:
            display = "Government ID"
            break
        case formConstants.MILITARY_ID:
            display = "Military ID"
            break
        case formConstants.RESIDENCY_ID:
            display = "US Residency ID"
            break
        case formConstants.NATURALIZATION:
            display = "Certificate of Naturalization"
            break
        case formConstants.PASSPORT:
            display = "Passport"
            break
        case formConstants.MEXICAN_VOTER:
            display = "Mexico voter ID"
            break
        case formConstants.TEMP_WORK_AUTH:
            display = "Temporary worth auth"
            break
        case formConstants.ITIN:
            display = "ITIN"
            break
        default:
            break
    }

    return display
}

function formatIDNumber(idNum) {
    if (idNum.length <= 4) {
        return idNum
    }
    else {
        let lastFour = idNum.substring(idNum.length - 4)
        let formattedIDNum = '*'.repeat(idNum.length - 4) + lastFour

        return formattedIDNum
    }
}

function mapSolarChoiceOptValForTransfer(value) {
    let currentSolarChoice = pricePlanConstants.SOLAR_CHOICE.NONE
    switch (value) {
        case 1:
            currentSolarChoice = pricePlanConstants.SOLAR_CHOICE.SC100
            break
        case 0.5:
            currentSolarChoice = pricePlanConstants.SOLAR_CHOICE.SC50
            break
        default:
            break
    }

    return currentSolarChoice
}

function createTransferServiceModel(transfserServiceProps) {

    let model = {
        callBackWindow: "0 AM to 0 PM",
        callNextTwoHours: true,
        currentBillAccount: transfserServiceProps.selectedBillAccount,
        currentRatePlan: mapPlanToServerEnum(transfserServiceProps.currentRateCode.rateCode),
        currentSolarChoicePlan: mapSolarChoiceOptValForTransfer(transfserServiceProps.enrolledProgramsDetails.solarChoice.optionValue),
        currentServiceAddress: {
            streetAddress: transfserServiceProps.serviceAddress.streetAddress,
            state: transfserServiceProps.serviceAddress.state,
            city: transfserServiceProps.serviceAddress.cityName,
            zipCode: transfserServiceProps.serviceAddress.zipCode
        },
        customDueDate: formatDayWithOrdinal(transfserServiceProps.serviceBillingInfo.customDueDate),
        customer: {
            firstName: transfserServiceProps.customerInfo.firstName,
            middleName: transfserServiceProps.customerInfo.middleName,
            lastName: transfserServiceProps.customerInfo.lastName,
            emailAddress: transfserServiceProps.customerInfo.emailAddress,
            phoneNumber: format.formatPhoneNumber(transfserServiceProps.customerInfo.phoneNumber)
        },
        differentMailingAddress: !transfserServiceProps.serviceBillingInfo.serviceAddIsMailingAdd,
        isCDD: transfserServiceProps.enrolledProgramsDetails.customDueDate.isEnrolled,
        isEBill: transfserServiceProps.eBillState,
        electricVehicleMake: transfserServiceProps.electricVehicleMake,
        electricVehicleModel: transfserServiceProps.electricVehicleModel,
        electricVehicleYear: transfserServiceProps.electricVehicleYear !== "" ? parseInt(transfserServiceProps.electricVehicleYear) : 0,
        electricVehicleOwnOrLease: transfserServiceProps.evOwnershipType,
        electricVehicleChargingSupplyType: transfserServiceProps.evEquipmentType !== "" ? mapChargingSupplyType(transfserServiceProps.evEquipmentType) : "",
        electricVehicleChargingInputVoltage: transfserServiceProps.evInputVoltage !== "" ? mapChargingVoltage(transfserServiceProps.evInputVoltage) : "",
        electricVehicleChargingAmps: transfserServiceProps.evInputCurrent !== "" ? mapChargingAmps(transfserServiceProps.evInputCurrent) : "",
        identificationId: (transfserServiceProps.customerInfo.identificationNum).toString(),
        identificationType: mapIdentificationType(transfserServiceProps.customerInfo.identificationType),
        isMpower: transfserServiceProps.currentRateCode.isPrepay,
        isSurePay: transfserServiceProps.enrolledProgramsDetails.autopay.isEnrolled,
        newRatePlan: mapPlanToServerEnum(transfserServiceProps.selectedPricePlanResidential),
        newSolarChoicePlan: transfserServiceProps.selectedSolarChoice,
        newServiceAddress: {
            streetAddress: transfserServiceProps.serviceBillingInfo.serviceAddressOne,
            streetAddressLineTwo: transfserServiceProps.serviceBillingInfo.serviceAddressTwo,
            city: transfserServiceProps.serviceBillingInfo.serviceCity,
        },
        mailingAddress: {
            streetAddress: transfserServiceProps.serviceBillingInfo.mailingAddressOne,
            streetAddressLineTwo: transfserServiceProps.serviceBillingInfo.mailingAddressTwo,
            state: transfserServiceProps.serviceBillingInfo.mailingState,
            city: transfserServiceProps.serviceBillingInfo.mailingCity,
            zipCode: transfserServiceProps.serviceBillingInfo.mailingZipCode,
            country: transfserServiceProps.serviceBillingInfo.mailingCountry,
        },
        maintainPaperlessBill: transfserServiceProps.serviceBillingInfo.maintainPaperlessBill,
        maintainSurePay: transfserServiceProps.serviceBillingInfo.maintainAutopay,
        preferredLanguage: transfserServiceProps.preferredLanguage,
        sendMeABox: transfserServiceProps.sendMPowerBox,
        surePayLastFour: transfserServiceProps.serviceBillingInfo.maintainAutopay && !transfserServiceProps.currentRateCode.isPrepay
            ? transfserServiceProps.serviceBillingInfo.lastFourOfAutopayBank
            : "",
        turnOffDate: transfserServiceProps.endServiceDate,
        turnOnDate: transfserServiceProps.startServiceDate,
    }

    return model
}

export default transferModule