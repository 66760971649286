import React from 'react'
import PropTypes from 'prop-types'
import InputLabel from '@mui/material/InputLabel'

import AccountDetailsCommercial from './acct-details-commercial'
import AccountDetailsResidential from './acct-details-residential'

import ResidentialIconBlue from '../../../images/forms/home-blue.svg'
import ResidentialIconGrey from '../../../images/forms/home-grey.svg'
import CommercialIconBlue from '../../../images/forms/business-blue.svg'
import CommercialIconGrey from '../../../images/forms/business-grey.svg'

import { shareConstants } from '../../../constants/share-constants'

const accountTypeBtn = {
    borderRadius: '10px',
    border: '2px solid #DADADB',
    backgroundColor: 'white',
    color: '#087ED8',
    fontWeight: '400',
    width: '100%'
}

const accountTypeBtnSelected = {
    borderRadius: '10px',
    border: '3px solid #087ED8',
    backgroundColor: 'white',
    color: '#087ED8',
    fontWeight: '700',
    width: '100%'
}

const iconStyle = {
    width: '40px',
    height: '100%'
}

const AccountDetailsContainer = ({ activeStep, setActiveStepOnClick, setAccountTypeOnClick, accountDetails, setAccountDetailsOnChange, t, i18n }) => (
        <div className="srp-card-details text-muted">
            <InputLabel>{t("What type of account is this?")}</InputLabel>
            <div className="row">
                <div className="col-6">
                    <button
                        className="button-focus pointer-btns"
                        type="button"
                        name="accountType"
                        onClick={() => setAccountTypeOnClick(shareConstants.ACOUNT_TYPE.RESIDENTIAL)}
                        style={accountDetails.accountType === shareConstants.ACOUNT_TYPE.RESIDENTIAL ? accountTypeBtnSelected : accountTypeBtn}>
                        <img
                            className="mt-2 mb-1 img-fluid"
                            src={accountDetails.accountType === shareConstants.ACOUNT_TYPE.RESIDENTIAL ? ResidentialIconBlue : ResidentialIconGrey}
                            style={iconStyle} />
                        <div className="mb-2">{t("Residential")}</div>
                    </button>
                </div>
                <div className="col-6">
                    <button
                        className="button-focus pointer-btns"
                        type="button"
                        name="accountType"
                        onClick={() => setAccountTypeOnClick(shareConstants.ACOUNT_TYPE.COMMERCIAL)}
                        style={accountDetails.accountType === shareConstants.ACOUNT_TYPE.COMMERCIAL ? accountTypeBtnSelected : accountTypeBtn}>
                        <img
                            className="mt-2 mb-1 img-fluid"
                            src={accountDetails.accountType === shareConstants.ACOUNT_TYPE.COMMERCIAL ? CommercialIconBlue : CommercialIconGrey}
                            style={iconStyle} />
                        <div className="mb-2">{t("Business")}</div>
                    </button>
                </div>
            </div>
            {accountDetails.accountType === shareConstants.ACOUNT_TYPE.RESIDENTIAL
                ? <AccountDetailsResidential
                    activeStep={activeStep}
                    setActiveStepOnClick={setActiveStepOnClick}
                    accountDetails={accountDetails}
                    t={t} i18n={i18n}
                    setAccountDetailsOnChange={setAccountDetailsOnChange} />
                : accountDetails.accountType === shareConstants.ACOUNT_TYPE.COMMERCIAL
                    ? <AccountDetailsCommercial 
                        activeStep={activeStep}
                        setActiveStepOnClick={setActiveStepOnClick}
                        accountDetails={accountDetails}
                        t={t} i18n={i18n}
                        setAccountDetailsOnChange={setAccountDetailsOnChange} />
                    : null
            }
        </div>
    )

AccountDetailsContainer.propTypes = {
    activeStep: PropTypes.number,
    setActiveStepOnClick: PropTypes.func,

    setAccountTypeOnClick: PropTypes.func,

    accountDetails: PropTypes.object,
    setAccountDetailsOnChange: PropTypes.func,

    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired,
}

export default AccountDetailsContainer