/* eslint react/no-did-mount-set-state: "off" */
/* eslint react/no-did-update-set-state: "off" */
import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { DocumentTitle } from '../../../srp_modules/document-title'

import { rateMetaDataConstants } from '../../../constants/rate-meta-data-constants'
import * as loadingConstants from '../../../constants/loading-status-constants'
import AccountDetailsCard from './account-details-card'
import AccountDetailsLoadingCard from './account-details-loading-card';
import ProfileStatusCard from './profile-status-card';

import { getBillingAddress } from '../../../actions/auth/bill_account/bill-account-actions'
import { getEnrolledProgramsDetails } from '../../../actions/auth/payment/payment-actions'
import { withTranslation } from 'react-i18next'

class AccountInfoContainer extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            accountDetailsLoaded: false
        }
    }
    componentDidMount() {
        this.getAccountInfoIfNeeded(this.props)
        this.setState({ accountDetailsLoaded: !this.accountDetailsStillLoading(this.props) })
    }

    componentDidUpdate(prevProps) {
        this.getAccountInfoIfNeeded(this.props)

        if (this.accountDetailsStillLoading(prevProps) && !this.accountDetailsStillLoading(this.props)) {
            this.setState({ accountDetailsLoaded: !this.accountDetailsStillLoading(this.props) })
        }
    }

    getAccountInfoIfNeeded(props) {
        if (props.selectedBillAccount === 0)
            return

        if (props.billingAddressStatus === loadingConstants.LOADING_STATUS_INIT) {
            props.getBillingAddress(props.selectedBillAccount)
        }

        if (props.enrolledProgramsDetailsStatus === loadingConstants.LOADING_STATUS_INIT) {
            props.getEnrolledProgramsDetails(props.selectedBillAccount)
        }
    }

    accountDetailsStillLoading(props) {
        return (props.billAccountListStatus !== loadingConstants.LOADING_STATUS_SUCCESS
            && props.billAccountListStatus !== loadingConstants.LOADING_STATUS_FAILURE)
            || (props.billingAddressStatus !== loadingConstants.LOADING_STATUS_SUCCESS
                && props.billingAddressStatus !== loadingConstants.LOADING_STATUS_FAILURE)
            || (props.serviceAddressStatus !== loadingConstants.LOADING_STATUS_SUCCESS
                && props.serviceAddressStatus !== loadingConstants.LOADING_STATUS_FAILURE)
            || (props.enrolledProgramsDetailsStatus !== loadingConstants.LOADING_STATUS_SUCCESS
                && props.enrolledProgramsDetailsStatus !== loadingConstants.LOADING_STATUS_FAILURE)
    }

    render() {
        return (
            <DocumentTitle title="Account information | SRP Power">
                <div className="row mt-3">
                    {
                        this.state.accountDetailsLoaded
                            ? <AccountDetailsCard {...this.props}/>
                            : <AccountDetailsLoadingCard t={this.props.t}/>
                    }
                    {(this.props.rateMetaDataStatus === loadingConstants.LOADING_STATUS_SUCCESS
                        || this.props.rateMetaDataStatus === loadingConstants.LOADING_STATUS_FAILURE)
                        && this.props.rateMetaData.summaryBilling !== rateMetaDataConstants.SUMMARY_BILLING_PARENT
                        ? <ProfileStatusCard t={this.props.t}/>
                        : null
                    }
                </div>
            </DocumentTitle>
        )
    }
}

const mapStateToProps = state => {
    return {
        ...state.accountInfo.billAccount,
        ...state.login,
        ...state.rateMetaData,
        ...state.safetyNet,
        enrolledProgramsDetailsStatus: state.accountInfo.payment.enrolledProgramsDetailsStatus,
        safetyNetProgramInfo: state.accountInfo.payment.enrolledProgramsDetails.safetyNet
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getBillingAddress: billAccount => {
            dispatch(getBillingAddress(billAccount))
        },
        getEnrolledProgramsDetails: (billAccount) => {
            dispatch(getEnrolledProgramsDetails(billAccount))
        }
    }
}

AccountInfoContainer.propTypes = {
    selectedBillAccount: PropTypes.number.isRequired,
    billAccountList: PropTypes.array.isRequired,
    loginEmail: PropTypes.string.isRequired,
    serviceAddress: PropTypes.object.isRequired,
    billingAddress: PropTypes.object,
    singleLineBillingAddress: PropTypes.string,

    billAccountListStatus: PropTypes.string.isRequired,
    billingAddressStatus: PropTypes.string.isRequired,
    serviceAddressStatus: PropTypes.string.isRequired,

    rateMetaData: PropTypes.object,
    rateMetaDataStatus: PropTypes.string,

    getEnrolledProgramsDetails: PropTypes.func,
    enrolledProgramsDetailsStatus: PropTypes.string,
    safetyNetProgramInfo: PropTypes.object,

    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired,
}

export default withTranslation("profileSettings")(connect(mapStateToProps, mapDispatchToProps)(AccountInfoContainer))