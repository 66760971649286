import React from 'react'
import PropTypes from 'prop-types'

import Icon from '@mui/material/Icon'

const CreditAdvanceConfirmation = ({ mPowerSelfServiceAdvance, backToHomeClick, t }) => {
    const isReconnectAdvance = mPowerSelfServiceAdvance.advanceEligibility === 'ReconnectAdvance'
    const requestNumber = mPowerSelfServiceAdvance.advanceTaken + 1
    const maxAdvancesAllowed = mPowerSelfServiceAdvance.advanceAllowed
    const showAdvancesRemaining = !(isReconnectAdvance && requestNumber>maxAdvancesAllowed)

    return (
    <div className="srp-card">
        <div className="srp-card-header">
            {t("My M-Power")}
        </div>
        <div className="srp-card-body">
            <div className="srp-card-details">
                <div style={{ fontSize: "18px", color: "#737B4C", display: "inline-block"}}>
                    <Icon
                        classes={{ root: "align-middle"}}>check_circle</Icon>
                    <span style={{ verticalAlign: "middle"}}><strong>&nbsp;&nbsp;{t("You're all set!")}</strong></span>
                </div>
                <div className="credit-advance-text mt-3 mb-3">
                    {t("Power_remaining_balance_update")}
                </div>
                <div style={{ border: "1px solid rgb(228,228,228)", borderRadius: "5px", paddingLeft: "15px", paddingTop: "5px", paddingBottom: "5px"}}>
                    <div>
                        <div className="credit-advance-details-section-header">{t("Advance amount")}</div>
                        <div className="credit-advance-details-section-text">${mPowerSelfServiceAdvance.advanceAmount}</div>
                    </div>
                    {showAdvancesRemaining &&
                    <div className="mt-3">
                        <div className="credit-advance-details-section-header">{t("Advances remaining (this month)")}</div>
                        <div className="credit-advance-details-section-text">{mPowerSelfServiceAdvance.advanceAllowed - mPowerSelfServiceAdvance.advanceTaken - 1}</div>
                    </div>
                    }
                </div>
                <div style={{textAlign: "right", marginTop: "10px"}}>
                    <button 
                        className="btn btn-srp btn-blue align-self-end"
                        onClick={() => backToHomeClick()}>
                        {t("Back to Home")}
                    </button>
                </div>
            </div>
        </div>
    </div>
    )
}

CreditAdvanceConfirmation.propTypes = {
    t: PropTypes.func.isRequired,
    mPowerSelfServiceAdvance: PropTypes.shape({
        advanceAmount: PropTypes.number.isRequired,
        advanceTaken: PropTypes.number.isRequired,
        advanceAllowed: PropTypes.number.isRequired,
        advanceEligibility: PropTypes.string.isRequired,
    }).isRequired,
    backToHomeClick: PropTypes.func.isRequired
}

export default CreditAdvanceConfirmation