import React from 'react'
import PropTypes from 'prop-types'
import CircularProgress from '@mui/material/CircularProgress'
import Icon from '@mui/material/Icon'
import Dialog from '@mui/material/Dialog'

import TextInput from '../../common_formik/text-input'
import MaskedBankAccountNumberInput from '../../common_formik/masked-bank-account-number-input'
import EmailInput from '../../common_formik/email-input'
import PhoneNumberInput from '../../single_payment_page/payment_fields/phone-number-input'
import RoutingNumberInput from '../../common_formik/routing-number-input'
import YesNoToggleInput from '../../common_formik/yes-no-toggle-input'
import SrpTooltip from '../../common_tooltip/srp-tooltip'

import * as loadingStatus from '../../../constants/loading-status-constants'
import { myAccountConstants } from '../../../constants/myaccount-constants'

class EditBankForm extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {
        let commonFormikProps = {
            onChange: this.props.handleChange,
            onBlur: this.props.handleBlur,
            errors: this.props.errors,
            touched: this.props.touched
        }
        let alternateFormikProps = {
            errors: this.props.errors,
            touched: this.props.touched
        }
        let verifyStatus = this.props.status || {}
        let onToggleChange = e => {
            let name = e.target.name;
            let value = e.target.checked;
            this.props.setFieldValue(name, value);
        }
        let onBankPhoneNumberChange = values => {
            let value = values.value;
            this.props.setFieldValue('bankPhoneNumber', value);
        }

        const bankNameOrNickname = (this.props.bankAccountToDelete.nickName === ""
            ? this.props.bankAccountToDelete.bankName
            : this.props.bankAccountToDelete.nickName)

        let supportPhoneNumber = myAccountConstants.RESIDENTIAL_CUSTOMER_SERVICE_PHONE_NUMBER
        let supportPhoneNumberFormatted = myAccountConstants.RESIDENTIAL_CUSTOMER_SERVICE_PHONE_NUMBER_FMTTD
        if (this.props.selectedBillAccountDetails.isCommercial) {
            supportPhoneNumber = myAccountConstants.COMMERCIAL_CUSTOMER_SERVICE_PHONE_NUMBER
            supportPhoneNumberFormatted = myAccountConstants.COMMERCIAL_CUSTOMER_SERVICE_PHONE_NUMBER_FMTTD
        } else  if (this.props.i18n.language === "es") {
            supportPhoneNumber = myAccountConstants.SPANISH_CUSTOMER_SERVICE_PHONE_NUMBER
            supportPhoneNumberFormatted = myAccountConstants.SPANISH_CUSTOMER_SERVICE_PHONE_NUMBER_FMTTD
        }

        return (
            <div id="topOfEditBankForm">
                <form onSubmit={this.props.handleSubmit}>
                    {verifyStatus.hasUnhandledError
                        ? <div className="srp-alert-error mb-3">
                            <span className="d-lg-block d-none">{this.props.t("We_are_unable_to_update") + ' ' +  supportPhoneNumberFormatted}.</span>
                            <span className="d-lg-none d-block">{this.props.t("We_are_unable_to_update")} {<a href={"tel:" + supportPhoneNumber}>{supportPhoneNumberFormatted}</a>}.</span>
                        </div>
                        : <span />}
                    <div className="mb-3"><TextInput id="bankFirstName" label="First name" maxLength={30} value={this.props.values.bankFirstName} {...commonFormikProps} t={this.props.t} /></div>
                    <div className="mb-3"><TextInput id="bankLastName" label="Last name" maxLength={30} value={this.props.values.bankLastName} {...commonFormikProps} t={this.props.t} /></div>
                    <div className="mb-3">
                        <PhoneNumberInput
                            id="bankPhoneNumber"
                            label="Phone number"
                            value={this.props.values.bankPhoneNumber}
                            onValueChange={onBankPhoneNumberChange}
                            onBlur={() => this.props.setFieldTouched("bankPhoneNumber", true)}
                            t={this.props.t}
                            {...alternateFormikProps}
                        />
                    </div>
                    <div className="mb-3"><EmailInput id="bankEmailAddress" label="Email address" inputProps={{ maxLength: 50 }} value={this.props.values.bankEmailAddress} t={this.props.t} {...commonFormikProps} /></div>
                    <div className="mb-3">
                        <RoutingNumberInput
                            id="bankRoutingNumber"
                            label="9 digit routing number"
                            endAdornment={
                                <SrpTooltip content={<span>{this.props.t("Unable to edit routing number. New bank account must be submitted.")}</span>}>
                                    <Icon className="align-middle md-18 srp-icon-color ml-2">info_outline</Icon>
                                </SrpTooltip>
                            }
                            value={this.props.values.bankRoutingNumber}
                            t={this.props.t}
                            disabled={true} {...commonFormikProps} />
                    </div>
                    <div className="mb-3">
                        <MaskedBankAccountNumberInput
                            id="bankAccountNumberLeadingZeroesAreSignificant"
                            label="Bank account number"
                            endAdornment={
                                <SrpTooltip content={<span>{this.props.t("Unable to edit bank account number. New bank account must be submitted.")}</span>}>
                                    <Icon className="align-middle md-18 srp-icon-color ml-2">info_outline</Icon>
                                </SrpTooltip>
                            }
                            value={this.props.values.bankAccountNumberLeadingZeroesAreSignificant}
                            disabled={true}
                            t={this.props.t}
                            {...commonFormikProps} />
                    </div>
                    <div className="mb-3"><TextInput id="bankAccountNickname" label="Account nickname (optional)" maxLength={35} value={this.props.values.bankAccountNickname} {...commonFormikProps} t={this.props.t} /></div>
                    <p className="pb-2 pl-1 mb-1 text-muted" style={{ marginTop: "0", marginBottom: "0" }}>{this.props.t("Make this my default bank account:")}</p>
                    <div className="pl-3 mb-1"><YesNoToggleInput id="isDefaultOnMyAcct" label="For My Account payments" value={this.props.values.isDefaultOnMyAcct} onChange={onToggleChange} t={this.props.t} i18n={this.props.i18n} /></div>
                    <div className="pl-3"><YesNoToggleInput id="isDefaultWithIVR" label="For phone payments" value={this.props.values.isDefaultWithIVR} onChange={onToggleChange} t={this.props.t} i18n={this.props.i18n} /></div>
                    <div className="d-flex flex-wrap justify-content-end mt-3">
                        <button className="btn srp-btn btn-lightblue mb-2" type="button" onClick={() => this.props.cancelOnClick()}>
                            {this.props.t("Cancel")}
                                </button>
                        <button
                            className="btn srp-btn btn-lightblue ml-2 mb-2"
                            type="button"
                            disabled={!this.props.bankAccountToEdit.canDelete}
                            onClick={() => this.props.showDeleteBankModuleOnClick(this.props.bankAccountToEdit)}>
                            {this.props.t("Remove")}
                                </button>
                        <button
                            className="btn srp-btn btn-green ml-2 mb-2"
                            type="submit"
                            disabled={this.props.editBankAccountStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS}>
                            {this.props.editBankAccountStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS
                                ? <CircularProgress size={20} thickness={5} style={{ color: 'white' }} />
                                : this.props.t("Update bank account")
                            }
                        </button>
                    </div>
                </form>
                <Dialog
                    modal="true"
                    open={this.props.showDeleteBankModule}
                    PaperProps={{ className: "srp-modal" }}
                >
                    <div className="srp-modal-body">
                        <div className="srp-modal-close" onClick={() => this.props.closeDeleteBankModuleOnClick()} />
                        <div>
                            <div className="srp-modal-header">
                                <h4 className="srp-modal-title">{this.props.t("Are you sure?")}</h4>
                            </div>
                            <div>
                                <div>
                                    {this.props.t("Delete_bank_and_scheduled_payments_warning", {bankNameOrNickname, bankAccountNumber: this.props.bankAccountToDelete.bankAccountNumber})}
                                </div>
                                <div className="srp-modal-footer">
                                    <button className="btn srp-btn btn-lightblue mr-2" onClick={() => this.props.closeDeleteBankModuleOnClick()}>{this.props.t("Close")}</button>
                                        <button className="btn srp-btn btn-blue" disabled={this.props.deleteBankAccountStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS} onClick={() => this.props.deleteBankAccountOnClick(this.props.bankAccountToDelete, this.props.allBillAccountsAreCommercial, this.props.t)}>
                                        {this.props.deleteBankAccountStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS
                                            ? <CircularProgress size={20} thickness={5} style={{ color: 'white' }} />
                                            : this.props.t("Remove")
                                        }
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Dialog>
                <Dialog
                    modal="true"
                    open={this.props.showDeleteBankErrorModule}
                    PaperProps={{ className: "srp-modal" }}
                >
                    <div className="srp-modal-body">
                        <div className="srp-modal-close" onClick={() => this.props.closeDeleteBankErrorModuleOnClick()} />
                        <div>
                            <div className="srp-modal-header">
                                <h4 className="srp-modal-title">{this.props.t("Something went wrong")}</h4>
                            </div>
                            <div>
                                {this.props.t("Unable_to_remove_bank_account", {phone: supportPhoneNumberFormatted })}
                                    <div className="srp-modal-footer">
                                    <button className="btn srp-btn btn-blue" onClick={() => this.props.closeDeleteBankErrorModuleOnClick()}>{this.props.t("OK")}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Dialog>
            </div>
        )

    }
}

EditBankForm.propTypes = {
    selectedBillAccount: PropTypes.number,
    selectedBillAccountDetails: PropTypes.object,
    isCommercial: PropTypes.bool,
    allBillAccountsAreCommercial: PropTypes.bool.isRequired,

    bankAccountToEdit: PropTypes.object,
    editBankAccountStatus: PropTypes.string,
    updateBankAccount: PropTypes.func,

    storeUpdatedBankAccount: PropTypes.func,

    bankAccountToDelete: PropTypes.object,
    deleteBankAccountStatus: PropTypes.string,
    deleteBankAccountOnClick: PropTypes.func,

    showDeleteBankModule: PropTypes.bool,
    showDeleteBankModuleOnClick: PropTypes.func,
    cancelOnClick: PropTypes.func.isRequired,
    closeDeleteBankModuleOnClick: PropTypes.func,
    showDeleteBankErrorModule: PropTypes.bool,
    closeDeleteBankErrorModuleOnClick: PropTypes.func,

    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired,

    // Formik props
    errors: PropTypes.object,
    handleBlur: PropTypes.func,
    handleChange: PropTypes.func,
    handleSubmit: PropTypes.func,
    setFieldTouched: PropTypes.func,
    setFieldValue: PropTypes.func,
    status: PropTypes.object,
    touched: PropTypes.object,
    values: PropTypes.object
}

export default EditBankForm