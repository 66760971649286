import React from 'react'
import PropTypes from 'prop-types'
import TextField from '@mui/material/TextField'
import { NumericFormat } from 'react-number-format'

const PaymentAmountField = (props) => {
    let giftPaymentInputRef = React.useRef()
    let { t } = props

    let isError = props.touched['paymentAmount']
        && Object.prototype.hasOwnProperty.call(props.errors, 'paymentAmount')
    let errMsg = isError ? props.errors['paymentAmount'] : ''

    const getLocalizedErrMsg = errMsg.getLocalizedErrMsg
    if (typeof getLocalizedErrMsg === 'function')
        errMsg = getLocalizedErrMsg()
    else if (errMsg !== "")
        errMsg = t(errMsg)

    let meta = {
        id: 'paymentAmount',
        name: 'paymentAmount',
        label: t('Payment amount'),
        helperText: errMsg,
        error: isError
    }

    let numberFormatProps = {
        ...props,
        value: props.value.replace(/[$,]/g, '') // Formik always passes in the formatted value, we want the unformatted string value
    }
    delete numberFormatProps.setFieldValue
    delete numberFormatProps.setFieldTouched
    delete numberFormatProps.t

    return (
        <NumericFormat
            {...meta}
            {...numberFormatProps}
            valueIsNumericString={true}
            getInputRef={giftPaymentInputRef}
            thousandSeparator={true}
            prefix={'$'}
            decimalScale={2}
            fixedDecimalScale={false}
            allowNegative={false}
            customInput={TextField}
            onBlur={() => {
                let fixedPaymentAmount = Number(numberFormatProps.value).toFixed(2)
                props.setFieldValue('paymentAmount', fixedPaymentAmount, false)
                props.setFieldTouched(meta.id, true)
            }}
            isAllowed={field => field.floatValue === undefined || field.floatValue < 1000000000.00}
            type="text"
        />
    )
}

PaymentAmountField.propTypes = {
    t: PropTypes.func.isRequired,
    touched: PropTypes.shape({
        paymentAmount: PropTypes.bool
    }).isRequired,
    errors: PropTypes.shape({
        paymentAmount: PropTypes.oneOfType([
            PropTypes.string.isRequired,
            PropTypes.shape({
                getLocalizedErrMsg: PropTypes.func.isRequired,
            }),
        ]),
    }).isRequired,
    setFieldValue: PropTypes.func.isRequired,
    setFieldTouched: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired
}

export default PaymentAmountField