import { LOADING_STATUS_INIT, LOADING_STATUS_SUCCESS, LOADING_STATUS_IN_PROGRESS, LOADING_STATUS_FAILURE } from "../../../constants/loading-status-constants"
import landlordConstants from '../../../constants/landlord-constants'

const initialState = {
    landlordAgreementList: [],
    landlordAgreementListStatus: LOADING_STATUS_INIT,
    selectedLandlordAgreement: 0,
    landlordBillInfoStatus: LOADING_STATUS_INIT,
    landlordBillInfo: {
        /*
        Example landlord billing details:
        '987654001': {
            balance: 100.00,
            currentDueDate: '2019-01-01T00:00:00-0700',
        }
        */
    },
    landlordMostRecentPaymentsStatus: LOADING_STATUS_INIT,
    landlordMostRecentPayments: {
        /*
        Example landlord billing details:
        '987654001': {
            amountPaid: 100.00,
            lastPaymentDate: '2018-12-25T00:00:00-0700',
        }
        */
    },
    landlordAccountsStatus: LOADING_STATUS_INIT,
    landlordAccounts: {
        /*
        Example landlord account:
        '1000': {
            landlordAgreementNumber: 1000,
            contractStartDate: '2018-12-01T00:00:00-0700',
            primaryBillAccount: 123456001,
            landlordProperties: [
                {
                    billAccount: 987654001,
                    startDate: '2018-12-01T00:00:00-0700',
                    closedDate: null,
                    isFinalAccount: false,
                    serviceAddress: {
                        streetAddress: '123 Test St',
                        fullStreetAddress: '123 Test St, Phoenix, AZ 85048'
                        // There are more properties here, but they are just the separate address components
                        // (e.g. city, state, zip, house number)
                    }
                }
            ]
        }
        */
    },
    addLandlordAgreementStatus: LOADING_STATUS_INIT
}

function getSelectedLandlordAgreement(landlordAgreements, currentlySelectedLandlordAgreement) {
    if (landlordAgreements.length === 0)
        return 0

    for (let key in landlordAgreements) {
        if (landlordAgreements[key] === currentlySelectedLandlordAgreement)
            return currentlySelectedLandlordAgreement
    }

    return landlordAgreements[0]
}

export default function landlordReducer(state = initialState, action) {
    switch (action.type) {
        case landlordConstants.GET_LANDLORD_AGREEMENTS_REQUEST: {
            return { ...state, landlordAgreementListStatus: LOADING_STATUS_IN_PROGRESS }
        }
        case landlordConstants.GET_LANDLORD_AGREEMENTS_SUCCESS: {
            return {
                ...state,
                landlordAgreementListStatus: LOADING_STATUS_SUCCESS,
                landlordAgreementList: action.payload.landlordAgreements,
                selectedLandlordAgreement: getSelectedLandlordAgreement(action.payload.landlordAgreements, state.selectedLandlordAgreement)
            }
        }
        case landlordConstants.GET_LANDLORD_AGREEMENTS_FAILURE: {
            return { ...state, landlordAgreementListStatus: LOADING_STATUS_FAILURE, landlordAgreementList: initialState.landlordAgreementList }
        }

        case landlordConstants.GET_LANDLORD_ACCOUNT_LIST_REQUEST: {
            return { ...state, landlordAccountsStatus: LOADING_STATUS_IN_PROGRESS }
        }
        case landlordConstants.GET_LANDLORD_ACCOUNT_LIST_SUCCESS: {

            let key = 0
            for (let agreement in action.payload.landlordDetails) {

                let landlordProperties = action.payload.landlordDetails[agreement].landlordProperties
                for (let index = 0; index < landlordProperties.length; index++) {
                    landlordProperties[index].defaultSortKey = key
                    key++
                }
            }
            let nextState = {
                ...state,
                landlordAccountsStatus: LOADING_STATUS_SUCCESS,
                landlordAccounts: action.payload.landlordDetails,
                landlordAgreementList: Object.keys(action.payload.landlordDetails).map(k => parseInt(k)),
                landlordAgreementListStatus: LOADING_STATUS_SUCCESS
            }
            nextState.selectedLandlordAgreement = getSelectedLandlordAgreement(nextState.landlordAgreementList, state.selectedLandlordAgreement)
            return nextState
        }
        case landlordConstants.GET_LANDLORD_ACCOUNT_LIST_FAILURE: {
            return { ...state, landlordAccountsStatus: LOADING_STATUS_FAILURE, landlordAccounts: initialState.landlordAccounts }
        }

        case landlordConstants.CHANGE_SELECTED_LANDLORD_AGREEMENT: {
            return { ...state, selectedLandlordAgreement: action.selectedLandlordAgreement }
        }

        case landlordConstants.ADD_LANDLORD_AGREEMENT_REQUEST: {
            return { ...state, addLandlordAgreementStatus: LOADING_STATUS_IN_PROGRESS }
        }
        case landlordConstants.ADD_LANDLORD_AGREEMENT_SUCCESS: {
            return { ...state, addLandlordAgreementStatus: LOADING_STATUS_SUCCESS }
        }
        case landlordConstants.ADD_LANDLORD_AGREEMENT_FAILURE: {
            return { ...state, addLandlordAgreementStatus: LOADING_STATUS_FAILURE }
        }

        case landlordConstants.GET_LANDLORD_BILL_INFO_REQUEST: {
            return { ...state, landlordBillInfoStatus: LOADING_STATUS_IN_PROGRESS }
        }

        case landlordConstants.GET_LANDLORD_BILL_INFO_FAILURE: {
            return { ...state, landlordBillInfoStatus: LOADING_STATUS_FAILURE }
        }

        case landlordConstants.GET_LANDLORD_BILL_INFO_SUCCESS: {
            return { ...state, landlordBillInfoStatus: LOADING_STATUS_SUCCESS, landlordBillInfo: action.payload }
        }

        case landlordConstants.GET_LANDLORD_MOST_RECENT_PAYMENTS_REQUEST: {
            return { ...state, landlordMostRecentPaymentsStatus: LOADING_STATUS_IN_PROGRESS }
        }

        case landlordConstants.GET_LANDLORD_MOST_RECENT_PAYMENTS_FAILURE: {
            return { ...state, landlordMostRecentPaymentsStatus: LOADING_STATUS_FAILURE }
        }

        case landlordConstants.GET_LANDLORD_MOST_RECENT_PAYMENTS_SUCCESS: {
            return { ...state, landlordMostRecentPaymentsStatus: LOADING_STATUS_SUCCESS, landlordMostRecentPayments: action.payload }
        }

        default:
            return state
    }
}