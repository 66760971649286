import {getTooltipYCoordinates,getGenerationData} from './generation-data'
import drawVerticalBars from './draw-vertical-bars'
import * as d3 from 'd3'
import { DateTime } from 'luxon'
import {displaySizeConstants} from '../../constants/display-size-constants'
import {getDisplaySize} from '../../srp_modules/display-size'
import {dailyUsageChartTypeConstants} from '../../constants/daily-usage-chart-type-constants'
const margin = {
    top: 20,
    right: 70,
    bottom: 10,
    left: 70
}
const yOffset = 40
const standardChartKeys = ["superOffPeak", "offPeak", "shoulder",  "onPeak",  "total", "min", "max"]
let tooltipYCoordinates = []
export default function drawVerticalChart(data, isCost, barClickCallback, t) {
    let allNetData = getGenerationData(data, isCost);
    let adjustedDataGeneration = addTooltipInfoToData(allNetData.generationArray)
    let adjustedDataConsumption = addTooltipInfoToData(allNetData.consumptionArray)
    let maxY = allNetData.max
    let minY = allNetData.min
    let legendDisplayStates = getLegendDisplayStates(adjustedDataConsumption, isCost)
    let chart = d3.select("#usagePageChartContainer")
    d3.selectAll("#usagePageChart").remove()
    let displaySize = getDisplaySize(window.innerWidth)
    let chartSize = getChartSvgSize(displaySize)
    let svg = chart
        .insert('svg', 'div')
        .attr('width', chartSize.width)
        .attr('height', chartSize.height)
        .attr('id', 'usagePageChart')
        .attr('class', 'viz-chart')
    let width = +svg.attr("width") - margin.left - margin.right
    let height = +svg.attr("height") - margin.top - margin.bottom
    let svgOffsetLeft = calculateOffsetLeft(svg);
    
    let g = svg.append("g").attr("transform", "translate(" + margin.left + "," + margin.top + ")")
    let g2 = svg.append("g").attr("transform", "translate(" + margin.left + "," + (margin.top/2 + height/2 ) + ")")
    
    let x = d3.scaleBand()
        .range([0, width])
        .align(0.1)

    let y = d3.scaleLinear()
        .rangeRound([(height/2) - yOffset/2 - margin.top/2, 0])
    let y2 = d3.scaleLinear()
        .rangeRound([(height/2) - yOffset/2 - margin.top/2, 0])

    x.domain(adjustedDataConsumption.map(function (d) {
        return d.Date
    }))
    y.domain([0, maxY])
    y2.domain([minY, 0])
    tooltipYCoordinates = getTooltipYCoordinates(adjustedDataConsumption,maxY,y)
    const barWidth = (width / data.length / 1.5)
    const xOffset = (width / data.length - barWidth) / 2
    let initialTt = chart.select(".viz-tooltip-arrow-main")
    initialTt.remove()
    let tooltip = addTooltipToChart(chart)

    g.append("g").attr("id", "dailyChartMainGroup")
        .selectAll("g")
        .data(d3.stack().keys(standardChartKeys)(adjustedDataConsumption))
        .enter().append("g")
        .attr("class", function (d, i) {
            return getCssClass(standardChartKeys[i]) + " viz-bar"
        })
        .selectAll("path")
        .data(function (d) {
            return d
        })
        .enter().append("g")
        .append("path")
        .on("mouseover", function (d,i) {
            handleMouseOver(d, this, tooltip, barWidth, xOffset,
                svg, svgOffsetLeft, x, i, t)
        })
        .on("mouseout", function (d) { handleMouseOut(d, this, tooltip) })
        .on("click", function(d){ 
            barClickCallback(DateTime.fromISO(d.data.billStartDate).setZone('MST'),
            DateTime.fromISO(d.data.date).setZone('MST'), 
            dailyUsageChartTypeConstants.NET_ENERGY,
            false,
            true)})
        .attr("height", 0)
        .attr("d", function (d) {
            return drawVerticalBars(d, data, x, y, 0, maxY, xOffset, barWidth, "Date")
        })
       
        g2.append("g").attr("id", "dailyChartMainGroup")
        .selectAll("g")
        .data(d3.stack().keys(standardChartKeys)(adjustedDataGeneration))
        .enter().append("g")
        .attr("class", function (d, i) {
            return getCssClass(standardChartKeys[i]) + " viz-bar"
        })
        .selectAll("path")
        .data(function (d) {
            return d
        })
        .enter().append("g")
        .append("path")
        .on("mouseover", function (d,i) {
            handleMouseOver(d, this, tooltip, barWidth, xOffset,
                svg, svgOffsetLeft, x, i, t)
        })
        .on("mouseout", function (d) { handleMouseOut(d, this, tooltip) })
        .on("click", function(d){ 
            barClickCallback(DateTime.fromISO(d.data.billStartDate).setZone('MST'),
            DateTime.fromISO(d.data.date).setZone('MST'), 
            dailyUsageChartTypeConstants.NET_ENERGY,
            false,
            true)})
        .attr("height", 0)
        .attr("d", function (d) {
            return drawVerticalBars(d, data, x, y2, minY, 0, xOffset, barWidth, "Date")
        })
    drawAxisTicks(data, g, g2, height, yOffset, width, isCost, x, y, y2)
    drawAxisLabels(svg, chartSize.height, t)
    drawLegend(g, height, width, legendDisplayStates)

}

function drawAxisTicks(data, g, g2, height, yOffset, width, isCost, x, y, y2, displaySize) {
    g.append("g")
        .attr("class", "axis")
        .attr("class", "usage-date-axis")
        .attr("transform", "translate(0," + (height - yOffset) + ")")
        .call(d3.axisBottom(x).tickValues(x.domain().filter(function (d, i) {
            return calculateAxisTickStep(data.length, i, displaySize)
        }))
        .tickPadding(8)
        .tickFormat(function(x) {return DateTime.fromISO(x).toFormat('ccc, LLL dd')}))
        d3.selectAll("g.tick line")
        .attr("y2", 10)
        .style("stroke-width", "1px")
        .style('shape-rendering','crispEdges')
   

    if (isCost) {
        g.append("g")
            .attr("class", "axis")
            .call(d3.axisLeft(y).ticks(3, "s")
                .tickFormat(d3.format(["$", ""])))
            .append("text")
            .attr("x", 2)
            .attr("y", y(y.ticks().pop()) + 0.5)
            .attr("fill", "#000")
            .attr("font-weight", "bold")
            .attr("text-anchor", "start")
        
        g2.append("g")
            .attr("class", "axis")
            .call(d3.axisLeft(y2).ticks(3, "s")
                .tickFormat(d3.format(d3.format('$'))))
            .append("text")
            .attr("x", 2)
            .attr("y", y2(y2.ticks().pop()) + 0.5)
            .attr("fill", "#000")
            .attr("font-weight", "bold")
            .attr("text-anchor", "start")
    }
    else {
        g.append("g")
            .attr("class", "axis")
            .call(d3.axisLeft(y).ticks(3, "s"))
            .append("text")
            .attr("x", 2)
            .attr("y", y(y.ticks().pop()) + 0.5)
            .attr("fill", "#000")
            .attr("font-weight", "bold")
            .attr("text-anchor", "start")
        g2.append("g")
            .attr("class", "axis")
            .call(d3.axisLeft(y2).ticks(3, "s"))
            .append("text")
            .attr("x", 2)
            .attr("y", y2(y2.ticks().pop()) + 0.5)
            .attr("fill", "#000")
            .attr("font-weight", "bold")
            .attr("text-anchor", "start")
    }
}

function drawAxisLabels(svg,height, t){
    let leftLabel = t("Usage (kWh)")
    svg.append("text")
        .attr("transform", "rotate(-90)")
        .attr("y", 0 )
        .attr("x",0 - (height/2))
        .attr("dy", "1em")
        .style("text-anchor", "middle")
        .attr("class","chart-axis-label")
        .text(leftLabel)
    
}

function drawLegend(g, height, width, legendDisplayStates) {
    let legendContainer = d3.select('#dailyUsageLegendContainer')
    legendContainer
        .style("left", margin.left + "px")
        .style("width", width + "px")
    if (legendDisplayStates.hasSuperOff) {
        d3.select('#superOffLegendItem')
            .attr("class", "usage-chart-legend-item-show")
    }
    if (legendDisplayStates.hasOffPeak) {
        d3.select('#offPeakLegendItem')
            .attr('class', "usage-chart-legend-item-show")
    }
    if (legendDisplayStates.hasShoulder) {
        d3.select('#shoulderLegendItem')
            .attr("class", "usage-chart-legend-item-show")
    }
    if (legendDisplayStates.hasOnPeak) {
        d3.select('#onPeakLegendItem')
            .attr("class", "usage-chart-legend-item-show")
    }
    if (legendDisplayStates.hasTotal && legendDisplayStates.isCost) {
        d3.select('#totalLegendItemCost')
            .attr("class", "usage-chart-legend-item-show")
    }
    if (legendDisplayStates.hasTotal && !legendDisplayStates.isCost) {
        d3.select('#totalLegendItem')
            .attr("class", "usage-chart-legend-item-show")
    }
}
function getChartSvgSize(displaySize) {
    switch(displaySize){

    case displaySizeConstants.EXTRA_LARGE:
        return { height: 300, width: 780 }
    
    case displaySizeConstants.LARGE:
        return { height: 300, width: 650 }
    
    case displaySizeConstants.MEDIUM:
        return { height: 300, width: 480 }
    
    case displaySizeConstants.SMALL:
        return { height: 175, width: 480 }
    
    case displaySizeConstants.EXTRA_SMALL:
        return { height: 200, width: 420 }
    }
}

function calculateOffsetLeft(svg) {
    let offsetLeft = svg.node().offsetLeft
    if (typeof offsetLeft != "undefined") {
        return offsetLeft
    }
    let svgElement = document.getElementById(svg.node().id)
    let svgParent = svgElement.parentNode
    let left = svgElement.getBoundingClientRect().left -
        svgParent.getBoundingClientRect().left

    return left;
}
function getCssClass(text) {
    if (text === undefined){
        return "viz"
    }
    if (text.includes("offset")) {
        return "viz-invisible"
    }
  
    return "viz-" + text
}

function addTooltipToChart(chart) {
    let tooltip = chart
        .append("div")
        .attr("class", "viz-tooltip-arrow-main")
    let tooltipArrow =
        tooltip
            .append("div")
            .attr("class", "viz-tooltip-arrow")

    tooltipArrow.append("div")
        .attr("class", "viz-tooltip-main")
        .append("div")
        .attr("class", "viz-tooltip-inner")
        .attr("id", "viz-tooltipText")
    return tooltip
}

function handleMouseOut(d, element, tooltip) {
    $(element).removeClass("current-hover")
    tooltip.style("display", "none")
}

function handleMouseOver(d, element, tooltip, barWidth, xOffset,
    svg, svgOffsetLeft, x, index, t) {
    $(element).addClass("current-hover")
    createTooltip(d, tooltip, barWidth, xOffset, svg, svgOffsetLeft, x, index, t)
    return true
}

function createTooltip(d, tooltip, barWidth,
    xOffset, svg, svgOffsetLeft,
    x, index, t) {
    let xPosition = x(d.data.Date) + xOffset + margin.left +
        (barWidth - 20) / 2 + calculateOffsetLeft(svg)
    let yPosition = tooltipYCoordinates[index]
    
    tooltip
        .style("left", xPosition + "px")
        .style("top", yPosition + "px")
        .style("display", "block")
    let tooltipInner = tooltip.select(".viz-tooltip-inner")
    tooltipInner.selectAll("*").remove()
    tooltipInner.append("div").text(
        DateTime.fromISO(d.data.billStartDate).toFormat('MMM d')
        + ' - ' +
        DateTime.fromISO(d.data.date).toFormat('MMM d'))
    if (d.data.dailyCost != 0 && d.data.dailyCost != undefined) {
        tooltipInner.append("div")
            .attr("class", "viz-tooltip-text-line")
            .text(t("Cost") + ": $" + d.data.dailyCost.toFixed(2))
    }
    if (d.data.offPeak != 0 && d.data.offPeak != undefined) {
        tooltipInner.append("div")
            .attr("class", "viz-tooltip-text-line")
            .text(d.data.tooltipOffPeak)
    }
    if (d.data.onPeak != 0 && d.data.onPeak != undefined) {
        tooltipInner.append("div")
            .attr("class", "viz-tooltip-text-line")
            .text(d.data.tooltipOnPeak)
    }
    if (d.data.shoulder != 0 && d.data.shoulder != undefined) {
        tooltipInner.append("div")
            .attr("class", "viz-tooltip-text-line")
            .text(d.data.tooltipShoulder)
    }
    if (d.data.superOffPeak != 0 && d.data.superOffPeak != undefined) {
        tooltipInner.append("div")
            .attr("class", "viz-tooltip-text-line")
            .text(d.data.tooltipSuperOffPeak)
    }
    if (d.data.total != 0 && d.data.total != undefined) {
        tooltipInner.append("div")
            .attr("class", "viz-tooltip-text-line")
            .text(d.data.tooltipTotal)
    }
   
}

function calculateAxisTickStep(dataLength,i){
    return i % Math.round(dataLength / 5) === 0  
}

function addTooltipInfoToData(adjustedData){
        adjustedData.forEach(function (element) {
            element.day = DateTime.fromISO(element.day).toFormat('yyyy-MM-dd')
            // element.Date = DateTime.fromISO(element.day)
            element.date = DateTime.fromISO(element.date).setZone('MST')
        })
    return adjustedData
}

function getLegendDisplayStates(adjustedData,isCost){
    let hasOnPeak = false,
    hasSuperOff = false,
    hasOffPeak = false,
    hasShoulder = false,
    hasTotal = false

    adjustedData.forEach(function (element) {
        hasOnPeak = element.onPeak != 0 ? true : hasOnPeak
        hasOffPeak = element.offPeak != 0 ? true : hasOffPeak
        hasShoulder = element.shoulder != 0 ? true : hasShoulder
        hasSuperOff = element.superOffPeak != 0 ? true : hasSuperOff
        hasTotal = element.total != 0 ? true : hasTotal
    })
    return {hasOnPeak: hasOnPeak,
        hasSuperOff: hasSuperOff,
        hasOffPeak: hasOffPeak ,
        hasShoulder: hasShoulder,
        hasTotal: hasTotal,
        isCost: isCost
    }
}
