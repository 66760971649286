import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { withTranslation } from 'react-i18next'

import SuccessCheck from '../../../images/common/SuccessCheck.png'

const EconomyPricePlanSignupConfirmationPure = ({ t }) => {
    return (
        <div>
            <div className="srp-card-body text-muted">
                <div className="srp-card-details">
                    <div className="d-lg-block d-none">
                        <div className="mb-3 srp-olive-green d-flex align-items-center">
                            <img className="img-fluid mr-3" src={SuccessCheck} style={{ width: '50px' }} />
                            <span className="font-28 fw-500">{t("Youre_all_set")}</span>
                        </div>
                    </div>
                    <div className="d-lg-none d-block">
                        <div className="mb-3 srp-olive-green text-center">
                            <img className="img-fluid mr-3" src={SuccessCheck} style={{ width: '50px' }} />
                            <div className="font-28 fw-500">{t("Youre_all_set")}</div>
                        </div>
                    </div>

                    <div className="text-muted font-28 fw-500">{t("Request_Received")}</div>
                    <div className="text-muted mb-4">
                        <div>
                            <div className="mb-2">{t("Thanks_For_Your_Interest")}</div>
                            <div>{t("You_Should_recieve_Email_Shortly")}</div>
                        </div>
                    </div>

                    <div className="d-flex flex-wrap justify-content-end">
                        <Link to="/myaccount/dashboard" className="btn srp-btn btn-lightblue ml-2 mb-2">{t("Go_to_dashboard")}</Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

EconomyPricePlanSignupConfirmationPure.propTypes = {
    formType: PropTypes.number,

    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired
}

const EconomyPricePlanSignupConfirmation = withTranslation("economyPricePlanSignupForm")(EconomyPricePlanSignupConfirmationPure)
export { EconomyPricePlanSignupConfirmation as default, EconomyPricePlanSignupConfirmationPure }
