export const contactConstants = {
    GET_CONTACTS_REQUEST: 'GET_CONTACTS_REQUEST',
    GET_CONTACTS_SUCCESS: 'GET_CONTACTS_SUCCESS',
    GET_CONTACTS_FAILURE: 'GET_CONTACTS_FAILURE',

    REFRESH_CONTACTS_REQUEST: 'REFRESH_CONTACTS_REQUEST',
    REFRESH_CONTACTS_SUCCESS: 'REFRESH_CONTACTS_SUCCESS',
    REFRESH_CONTACTS_FAILURE: 'REFRESH_CONTACTS_FAILURE',
    
    ADD_CONTACTS_REQUEST: 'ADD_CONTACTS_REQUEST',
    ADD_CONTACTS_SUCCESS: 'ADD_CONTACTS_SUCCESS',
    ADD_CONTACTS_FAILURE: 'ADD_CONTACTS_FAILURE',

    DELETE_CONTACTS_REQUEST: 'DELETE_CONTACTS_REQUEST',
    DELETE_CONTACTS_SUCCESS: 'DELETE_CONTACTS_SUCCESS',
    DELETE_CONTACTS_FAILURE: 'DELETE_CONTACTS_FAILURE',

    UPDATE_CONTACTS_REQUEST: 'UPDATE_CONTACTS_REQUEST',
    UPDATE_CONTACTS_SUCCESS: 'UPDATE_CONTACTS_SUCCESS',
    UPDATE_CONTACTS_FAILURE: 'UPDATE_CONTACTS_FAILURE',

    MOVE_SUBSCRIPTIONS_AND_DELETE_CONTACT_REQUEST: 'MOVE_SUBSCRIPTIONS_AND_DELETE_CONTACT_REQUEST',
    MOVE_SUBSCRIPTIONS_AND_DELETE_CONTACT_SUCCESS: 'MOVE_SUBSCRIPTIONS_AND_DELETE_CONTACT_SUCCESS',
    MOVE_SUBSCRIPTIONS_AND_DELETE_CONTACT_FAILURE: 'MOVE_SUBSCRIPTIONS_AND_DELETE_CONTACT_FAILURE',

    REORDER_CONTACTS_REQUEST: 'REORDER_CONTACTS_REQUEST',
    REORDER_CONTACTS_SUCCESS: 'REORDER_CONTACTS_SUCCESS',
    REORDER_CONTACTS_FAILURE: 'REORDER_CONTACTS_FAILURE',    

    UNDO_DELETE_CONTACT_REQUEST: 'UNDO_DELETE_CONTACT_REQUEST',
    UNDO_DELETE_CONTACT_SUCCESS: 'UNDO_DELETE_CONTACT_SUCCESS',
    UNDO_DELETE_CONTACT_FAILURE: 'UNDO_DELETE_CONTACT_FAILURE',

    // contactTypes
    CONTACT_TYPE: {
        UNKNOWN: 0,
        EMAIL: 1,
        PHONE: 2,
        VOICE: 3,
        FAX: 4,
        PUSH: 5
    },

    SMS_STATUS: {
        UNVERIFIED: 0,
        VERIFIED: 1,
        STOPPED: 2
    }
    
}