import {dailyUsageConstants} from '../../../constants/daily-usage-constants'
import { RSAA } from 'redux-api-middleware'
import config from 'my-account-config'

export const getDailyUsage = (billAccount) =>{
    return dispatch => {
        return dispatch({
            [RSAA]: {
                endpoint: config.apiBaseUrl + 'api/usage/dailyusagedetail?billaccount=' +
                    encodeURIComponent(billAccount),
                method: 'GET',
                credentials: 'include',
                types: [
                    dailyUsageConstants.GET_DAILY_REQUEST,
                    dailyUsageConstants.GET_DAILY_SUCCESS,
                    dailyUsageConstants.GET_DAILY_FAILURE
                ]
            }
        })
    }
}


