import { autopayConstants } from '../../../constants/autopay-constants'
import { billAccountConstants } from '../../../constants/bill-account-constants'
import * as loadingStatus from '../../../constants/loading-status-constants'
import { DateTime } from 'luxon'

const initialState = {
    autopayInfo: {
        billAccount: 0,
        isCashOnly: false,
        isEligibleToEnroll: false,
        isEnrolled: false,
        isPendingRelease: false,
        isPrePay: false,
        isResidential: false,
        phoneNumber: '',
        emailAddress: '',
        shareAmount: 0,
        effectiveDate: new Date(0),
        surePayDate: new Date(0),
        hasPendingChange: false,
        hasPendingEnrollment: false,
        bankAccount: {
            nameOnBankAccount: '',
            routingNumber: '',
            bankAccountNumber: '',
            institutionName: '',
            bankAccountType: 0,
        },
        businessInfo: {
            businessName: '',
            contactName: '',
            lastFourSsn: '',
            phoneNumber: '',
            dbaName: '',
            emailAddress: ''
        },
        customerInfo: {
            firstName: '',
            middleName: '',
            lastName: '',
            ssn: '',
            phoneNumber: '',
            emailAddress: ''
        },
    },
    autopayInfoSubmitted: {
        billAccount: 0,
        isPendingRelease: false,
        isResidential: false,
    },
    autopayInfoStatus: loadingStatus.LOADING_STATUS_INIT,
    autopayInfoList: [
        {
            billAccount: 0,
            isCashOnly: false,
            isEligibleToEnroll: false,
            isEnrolled: false,
            isPendingRelease: false,
            isPrePay: false,
            isResidential: false,
            isSummaryBilling: false,
            phoneNumber: '',
            emailAddress: '',
            shareAmount: 0,
            effectiveDate: new Date(0),
            bankAccount: {
                nameOnBankAccount: '',
                routingNumber: '',
                bankAccountNumber: '',
                institutionName: '',
                bankAccountType: 0,
            },
            businessInfo: {
                businessName: '',
                contactName: '',
                lastFourSsn: '',
                phoneNumber: '',
                dbaName: '',
                emailAddress: ''
            },
            customerInfo: {
                firstName: '',
                middleName: '',
                lastName: '',
                ssn: '',
                phoneNumber: '',
                emailAddress: ''
            }
        }
    ],
    autopayInfoListStatus: loadingStatus.LOADING_STATUS_INIT,

    autopayFormData: {
        billAccount: 0,
        isResidential: false,
        firstName: '',
        lastName: '',
        routingNumber: '',
        bankAccountNumber: '',
        bankAccountType: autopayConstants.BANK_ACCOUNT_CHECKING,
        phoneNumber: '',
        emailAddress: '',
        shareAmount: 0,
        isPendingSubmit: false
    },

    bankData: {
        success: false,
        autopayBankErrors: {},
        institutionName: '',
    },
    bankDataStatus: loadingStatus.LOADING_STATUS_INIT,
    
    cancelAutopaySuccess: {
        isSuccess: false,
        dateSubmitted: DateTime.fromISO("0001-01-01T00:00:00")
    },
    cancelAutopayStatus: loadingStatus.LOADING_STATUS_INIT,

    enrollAutopaySuccess: false,
    enrollAutopayStatus: loadingStatus.LOADING_STATUS_INIT,

    updateAutopaySuccess: false,
    updateAutopayStatus: loadingStatus.LOADING_STATUS_INIT,

    getIsEnrolledStatus: loadingStatus.LOADING_STATUS_INIT,
    getEligibleToEnrollStatus: loadingStatus.LOADING_STATUS_INIT,

    showCancelAutopayModule: false,
    autopayToCancel: {
        billAccount: 0,
        isCashOnly: false,
        isEligibleToEnroll: false,
        isEnrolled: false,
        isPendingRelease: false,
        isPrePay: false,
        isResidential: false,
        phoneNumber: '',
        emailAddress: '',
        shareAmount: 0,
        bankAccount: {
            nameOnBankAccount: '',
            routingNumber: '',
            bankAccountNumber: '',
            institutionName: '',
            bankAccountType: 0,
        },
        customerInfo: {
            firstName: '',
            middleName: '',
            lastName: '',
            ssn: '',
            dob: '',
            phoneNumber: '',
            verifyPhoneNumber: '',
            emailAddress: '',
            verifyEmailAddress: ''
        },
        businessInfo: {
           businessName: '',
           dbaName: '',
           contactName: '',
           contactPosition: '',
           emailAddress: '',
           phoneNumber: '',
           lastFourSsn: ''
        },
        effectiveDate: new Date(0),
        surePayDate: new Date(0)
    },
    numOfAutopayToShowStart: 0,
    numOfAutopayToShowEnd: 10,
    ignorePendingRelease: false
}

export default function autopayReducer(state = initialState, action) {
    switch (action.type) {
        case autopayConstants.AUTOPAY_FORM_DATA_CANCEL:
            return { ...state, autopayFormData: initialState.autopayFormData }

        case autopayConstants.AUTOPAY_FORM_DATA_CHANGE:
            return { ...state, autopayFormData: action.autopayFormData }

        case autopayConstants.CANCEL_AUTOPAY_REQUEST:
            return { ...state, cancelAutopayStatus: loadingStatus.LOADING_STATUS_IN_PROGRESS }
        case autopayConstants.CANCEL_AUTOPAY_SUCCESS:
            return { ...state,
                cancelAutopaySuccess: action.payload.cancelAutopayResponse,
                cancelAutopayStatus: loadingStatus.LOADING_STATUS_SUCCESS,
                showCancelAutopayModule: false }
        case autopayConstants.CANCEL_AUTOPAY_FAILURE:
            return { ...state, cancelAutopaySuccess: initialState.cancelAutopaySuccess, cancelAutopayStatus: loadingStatus.LOADING_STATUS_FAILURE }
        case autopayConstants.SHOW_CACNEL_AUTOPAY_MODULE:
            return {
                ...state,
                showCancelAutopayModule: true,
                autopayToCancel: {
                    ...action.autopayToCancel

                } }
        case autopayConstants.CLOSE_CACNEL_AUTOPAY_MODULE:
            return { ...state, showCancelAutopayModule: false }

        case autopayConstants.ENROLL_AUTOPAY_REQUEST:
            return { ...state, enrollAutopayStatus: loadingStatus.LOADING_STATUS_IN_PROGRESS }
        case autopayConstants.ENROLL_AUTOPAY_SUCCESS:
            return { ...state, enrollAutopaySuccess: action.payload, enrollAutopayStatus: loadingStatus.LOADING_STATUS_SUCCESS,
                autopayInfoSubmitted: {
                    billAccount: state.autopayInfo.billAccount,
                    isPendingRelease: state.autopayInfo.isPendingRelease,
                    isResidential: state.autopayInfo.isResidential,
                },
            }
        case autopayConstants.ENROLL_AUTOPAY_FAILURE:
            return { ...state, enrollAutopaySuccess: initialState.enrollAutopaySuccess, enrollAutopayStatus: loadingStatus.LOADING_STATUS_FAILURE }

        case autopayConstants.GET_AUTOPAY_INFO_REQUEST:
            return { ...state, autopayInfoStatus: loadingStatus.LOADING_STATUS_IN_PROGRESS }
        case autopayConstants.GET_AUTOPAY_INFO_SUCCESS:
            return { ...state, autopayInfo: action.payload, autopayInfoStatus: loadingStatus.LOADING_STATUS_SUCCESS }            
        case autopayConstants.GET_AUTOPAY_INFO_FAILURE:
            return { ...state, autopayInfo: initialState.autopayInfo, autopayInfoStatus: loadingStatus.LOADING_STATUS_FAILURE, ignorePendingRelease: initialState.ignorePendingRelease }
        case autopayConstants.GET_AUTOPAY_INFO_LIST_REQUEST:
            return { ...state, autopayInfoListStatus: loadingStatus.LOADING_STATUS_IN_PROGRESS }
        case autopayConstants.GET_AUTOPAY_INFO_LIST_SUCCESS:
            return { ...state,
                autopayInfoList: action.payload,
                autopayInfoListStatus: loadingStatus.LOADING_STATUS_SUCCESS,
                numOfAutopayToShowStart: 0,
                numOfAutopayToShowEnd: action.payload.length >= 10 ? 10 : action.payload.length }
        case autopayConstants.GET_AUTOPAY_INFO_LIST_FAILURE:
            return { ...state, autopayInfoList: initialState.autopayInfoList, autopayInfoListStatus: loadingStatus.LOADING_STATUS_FAILURE }
            
        case autopayConstants.AUTOPAY_ENROLLED_REQUEST:
            return { ...state, getIsEnrolledStatus: loadingStatus.LOADING_STATUS_IN_PROGRESS }
        case autopayConstants.AUTOPAY_ENROLLED_SUCCESS:
            return { ...state, autopayInfo: { ...state.autopayInfo, isEnrolled: action.payload }, getIsEnrolledStatus: loadingStatus.LOADING_STATUS_SUCCESS }
        case autopayConstants.AUTOPAY_ENROLLED_FAILURE:
            return { ...state, autopayInfo: { ...state.autopayInfo, isEnrolled: initialState.autopayInfo.isEnrolled }, getIsEnrolledStatus: loadingStatus.LOADING_STATUS_FAILURE }

        case autopayConstants.AUTOPAY_ELIGIBLE_REQUEST:
            return { ...state, getEligibleToEnrollStatus: loadingStatus.LOADING_STATUS_IN_PROGRESS }
        case autopayConstants.AUTOPAY_ELIGIBLE_SUCCESS:
            return { ...state, autopayInfo: { ...state.autopayInfo, isEligbleToEnroll: action.payload }, getEligibleToEnrollStatus: loadingStatus.LOADING_STATUS_SUCCESS }
        case autopayConstants.AUTOPAY_ELIGIBLE_FAILURE:
            return { ...state, autopayInfo: { ...state.autopayInfo, isEligbleToEnroll: initialState.autopayInfo.isEligbleToEnroll }, getEligibleToEnrollStatus: loadingStatus.LOADING_STATUS_FAILURE }

        case autopayConstants.VALIDATE_AUTOPAY_BANK_REQUEST:
            return { ...state, bankDataStatus: loadingStatus.LOADING_STATUS_IN_PROGRESS }
        case autopayConstants.VALIDATE_AUTOPAY_BANK_SUCCESS:
            return {
                ...state,
                bankData: {
                    success: action.payload.success,
                    autopayBankErrors: action.payload.autopayBankErrors,
                    institutionName: action.payload.financialInstitutionName,
                },
                bankDataStatus: loadingStatus.LOADING_STATUS_SUCCESS
            }
        case autopayConstants.VALIDATE_AUTOPAY_BANK_FAILURE:
            return { ...state, bankData: initialState.bankData, bankDataStatus: loadingStatus.LOADING_STATUS_FAILURE }

        case autopayConstants.UPDATE_AUTOPAY_REQUEST:
            return { ...state, updateAutopayStatus: loadingStatus.LOADING_STATUS_IN_PROGRESS }
        case autopayConstants.UPDATE_AUTOPAY_SUCCESS:
            return { ...state, updateAutopaySuccess: action.payload, updateAutopayStatus: loadingStatus.LOADING_STATUS_SUCCESS,
                autopayInfoSubmitted: {
                    billAccount: state.autopayInfo.billAccount,
                    isPendingRelease: state.autopayInfo.isPendingRelease,
                    isResidential: state.autopayInfo.isResidential,
                },
                autopayInfo: initialState.autopayInfo,
                autopayInfoStatus: initialState.autopayInfoStatus }
        case autopayConstants.UPDATE_AUTOPAY_FAILURE:
            return { ...state, updateAutopaySuccess: initialState.updateAutopaySuccess, updateAutopayStatus: loadingStatus.LOADING_STATUS_FAILURE }

        case autopayConstants.GET_PREV_AUTOPAY:
            return { ...state,
                numOfAutopayToShowStart: state.numOfAutopayToShowStart === 0 ? 0 : state.numOfAutopayToShowStart - 10,
                numOfAutopayToShowEnd: state.numOfAutopayToShowEnd % 10 !== 0 ? state.numOfAutopayToShowStart : state.numOfAutopayToShowEnd - 10 }
        case autopayConstants.GET_NEXT_AUTOPAY:
            return { ...state,
                numOfAutopayToShowStart: state.numOfAutopayToShowStart + 10,
                numOfAutopayToShowEnd: state.numOfAutopayToShowEnd + 10 >= action.lengthOfList ? action.lengthOfList : state.numOfAutopayToShowEnd + 10 }
        
        case billAccountConstants.SELECT_BILL_ACCOUNT_SUCCESS:
            return { ...initialState, cancelAutopaySuccess: state.cancelAutopaySuccess }

        case autopayConstants.IGNORE_PENDING_RELEASE:
            return { ...state, ignorePendingRelease: true } 

        default:
            return state
    }
}
