import React from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'

import SelectPlan from './select-plan'
import Review from './review'

import { pricePlanConstants } from '../../constants/price-plan-constants'

const ChangePricePlanAuth = ({ activeStep, setActiveStep, isLoggedIn, isSolar, isCommercial, accountDetails,
    currentRateCode, currentRateCodeDescription, selectedPricePlanResidential, selectedPricePlanCommercial,
    selectNewPricePlanResidential, selectNewPricePlanCommercial, currentPlanIsTouOrSolar, selectedPricePlan,
    createAndSubmitChangePricePlanForm, changePricePlanStatus, fromCmpp, backButtonClick, i18n }) => {

    return (
        <div className="col-lg-6 col-12">
            <div className="srp-card-header" id="topOfCppForm">
                {i18n.language === "es"
                    ? pricePlanConstants.STEPS_AUTH[activeStep].titleEs
                    : pricePlanConstants.STEPS_AUTH[activeStep].titleEn
                }
            </div>
            <div className="srp-card-body">
                <div className="srp-card-details">
                    <div className="d-lg-block d-none">
                        {!fromCmpp && <div className="col-9 p-0 mb-4">
                            <Stepper activeStep={activeStep} className="p-0">
                                {pricePlanConstants.STEPS_AUTH.map(item => {
                                    return (
                                        <Step key={item.step}>
                                            <StepLabel>{i18n.language === "es" ? item.labelEs : item.labelEn}</StepLabel>
                                        </Step>
                                    )
                                })}
                            </Stepper>
                        </div>}
                    </div>
                    {!fromCmpp && <div className="d-lg-none d-block">
                        <div className="p-0 mb-4">
                            <Stepper activeStep={activeStep} alternativeLabel className="p-0">
                                {pricePlanConstants.STEPS_AUTH.map(item => {
                                    return (
                                        <Step key={item.step}>
                                            <StepLabel>{i18n.language === "es" ? item.labelEs : item.labelEn}</StepLabel>
                                        </Step>
                                    )
                                })}
                            </Stepper>
                        </div>
                    </div>}
                    {activeStep === 0
                        ? <SelectPlan
                            activeStep={activeStep}
                            setActiveStep={setActiveStep}
                            isLoggedIn={isLoggedIn}
                            isSolar={isSolar}
                            isCommercial={isCommercial}
                            currentRateCode={currentRateCode}
                            currentRateCodeDescription={currentRateCodeDescription}
                            selectedPricePlanResidential={selectedPricePlanResidential}
                            selectedPricePlanCommercial={selectedPricePlanCommercial}
                            selectNewPricePlanResidential={selectNewPricePlanResidential}
                            selectNewPricePlanCommercial={selectNewPricePlanCommercial}
                            backButtonClick={backButtonClick} />
                        : <Review
                            activeStep={activeStep}
                            setActiveStep={setActiveStep}
                            accountDetails={accountDetails}
                            isCommercial={isCommercial}
                            currentPlanIsTouOrSolar={currentPlanIsTouOrSolar}
                            selectedPricePlan={selectedPricePlan}
                            createAndSubmitChangePricePlanForm={createAndSubmitChangePricePlanForm}
                            changePricePlanStatus={changePricePlanStatus}
                            fromCmpp={fromCmpp}
                            backButtonClick={backButtonClick} />
                    }
                </div>
            </div>
        </div>
    )

}

ChangePricePlanAuth.propTypes = {
    activeStep: PropTypes.number,
    setActiveStep: PropTypes.func,
    isLoggedIn: PropTypes.bool,
    isSolar: PropTypes.bool,
    isCommercial: PropTypes.bool,
    accountDetails: PropTypes.object,
    currentRateCode: PropTypes.number,
    currentRateCodeDescription: PropTypes.string,
    selectedPricePlanResidential: PropTypes.number,
    selectedPricePlanCommercial: PropTypes.number,
    selectNewPricePlanResidential: PropTypes.func,
    selectNewPricePlanCommercial: PropTypes.func,
    currentPlanIsTouOrSolar: PropTypes.bool,
    selectedPricePlan: PropTypes.number,
    createAndSubmitChangePricePlanForm: PropTypes.func,
    changePricePlanStatus: PropTypes.string,
    fromCmpp: PropTypes.bool,
    backButtonClick: PropTypes.func,
    t: PropTypes.func,
    i18n: PropTypes.object
}

export default withTranslation('changePricePlan')(ChangePricePlanAuth)