import React from 'react'
import PropTypes from 'prop-types'

import Card from '@mui/material/Card'

import SinglePaymentFormikConnectedContainer from './single-payment-formik-container'

const SinglePaymentInputCard = ({t, i18n, formDataChanged}) => (
    <div>
    <label className="h5">{t("Payment Information")}</label>
    <Card className="p-4">
        <SinglePaymentFormikConnectedContainer formDataChanged={formDataChanged} t={t} i18n={i18n} />
    </Card>
    </div>
)

SinglePaymentInputCard.propTypes = {
    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired,
    formDataChanged: PropTypes.func.isRequired,
}

export default SinglePaymentInputCard