import React from 'react'
import PropTypes from 'prop-types'
import UsageChartDailyContainer from './usage-chart-daily-container'
import UsageChartMonthlyContainer from './usage-chart-monthly-container'
import NoMonthlyCard from './no-monthly-card'
import format from '../../../srp_modules/format'
import AppBar from '@mui/material/AppBar'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import { DateTime } from 'luxon'
import { dailyUsageChartTypeConstants } from '../../../constants/daily-usage-chart-type-constants'

const CardUsageMPowerCpp = ({
        billAccount,
        mostRecentCost,
        mostRecentUsage,
        getUsageChartTabOnClick,
        hasMonthly,
        mostRecentUsageDate,
        selectedUsageChartTab,
        setUsageChartType
    }) => {
    const t = s => s // TODO: remove this once t is defined

    return (
        <div className="srp-card d-inline tooltip-overflow">
            <div className="srp-card-header">
                My usage
            </div>
            <div className="srp-card-body">
                <div className="srp-card-summary d-flex justify-content-around">
                    <div className="d-flex flex-column align-items-center">
                        {mostRecentCost ?
                            <div className="h4 text-white">
                            {
                                mostRecentCost > 0
                                        ? <span>{format.formatDollarAmount(parseFloat(mostRecentCost))}</span>
                                        : format.formatNumber(parseFloat(mostRecentCost))
                                
                            }
                            </div>
                        :
                            <div className="h4 text-white">
                                {'--'}
                            </div>
                        }
                        
                        {getCostLabel(mostRecentUsageDate)}

                    </div>
                    <div className="d-flex flex-column align-items-center">
                        { mostRecentUsage ?
                            <div className="h4 text-white">
                            {
                                mostRecentUsage + ' kWh'
                            }
                            </div>
                        :
                            <div className="h4 text-white">
                                {'--'}
                            </div>
                        }

                        {getkWhLabel(mostRecentUsageDate)}

                    </div>
                </div>
                
                <div className="usage-card-buttons">
                    <div className="d-none d-lg-block ">
                        <div className="d-flex justify-content-end" style={{display: 'flex', flexWrap: 'wrap'}}>
                            <button className="btn srp-btn btn-lightblue ml-2 mb-2 " onClick={() => setUsageChartType(dailyUsageChartTypeConstants.COST, 1)}>View usage</button>
                        </div>
                    </div>
                </div>
                <div>

                    <AppBar position="static" style={{ backgroundColor: 'white', borderBottom: "1px solid lightgray" }} elevation={0}>
                        <Tabs
                            value={selectedUsageChartTab}
                            onChange={(evt, value) => getUsageChartTabOnClick(evt, value)}
                            indicatorColor="primary"
                            textColor="primary"
                            style={{ color: 'black' }}
                            variant="fullWidth"
                        >
                            <Tab className="mui_tab" label="7 day" style={{ outline: 'none', boxShadow: 'none', textTransform: 'lowercase' }} />
                            <Tab className="mui_tab" label="Monthly" style={{ outline: 'none', boxShadow: 'none', textTransform: 'capitalize' }} />
                        </Tabs>
                    </AppBar>

                    {selectedUsageChartTab === 0
                        && <div className="srp-card-details">
                             <UsageChartDailyContainer isCost={true} t={t => t} />
                            </div>
                    }
                    {selectedUsageChartTab === 1
                        && <div className="srp-card-details">
                            {hasMonthly
                            ? <UsageChartMonthlyContainer billAccount={billAccount} targetId="monthlyChart" t={t} />
                            : <NoMonthlyCard />}
                        </div>
                    }
                </div>

                <div className="d-sm-block d-lg-none srp-card-details" style={{ borderTop: "2px solid #E5E5E5"}}>
                    <div>
                        <div className="d-flex justify-content-end" style={{ display: 'flex', flexWrap: 'wrap' }}>
                            <button className="btn srp-btn btn-lightblue ml-2 mb-2 " onClick={() => setUsageChartType(dailyUsageChartTypeConstants.COST, 1)}>View usage</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        )
}

function getCostLabel(mostRecentUsageDate) {
    if (mostRecentUsageDate) {
        let yesterday = DateTime.now().setZone('MST').minus({ days: 1 }).toFormat('yyyy-MM-dd')
        let today = DateTime.now().setZone('MST').toFormat('yyyy-MM-dd')
        let usageDate = DateTime.fromFormat(mostRecentUsageDate, 'M/d/yy').toFormat('yyyy-MM-dd')
        if(yesterday === usageDate){
            return "cost yesterday"
        }
        if(today === usageDate){
            return "cost today"
        }
        return "cost " + usageDate
    }
}

function getkWhLabel(mostRecentUsageDate) {
    if (mostRecentUsageDate) {
        let yesterday = DateTime.now().setZone('MST').minus({ days: 1 }).toFormat('yyyy-MM-dd')
        let today = DateTime.now().setZone('MST').toFormat('yyyy-MM-dd')
        let usageDate = DateTime.fromFormat(mostRecentUsageDate, 'M/d/yy').toFormat('yyyy-MM-dd')
        if(yesterday === usageDate){
            return "used yesterday"
        }
        if(today === usageDate){
            return "used today"
        }
        return "used " + usageDate
    }
}
CardUsageMPowerCpp.propTypes = {
    billAccount: PropTypes.number,
    mostRecentCost: PropTypes.number,
    mostRecentUsage: PropTypes.number,
    getUsageChartTabOnClick: PropTypes.func,
    selectedUsageChartTab: PropTypes.number,
    hasMonthly: PropTypes.bool,
    mostRecentUsageDate: PropTypes.string,
    setUsageChartType: PropTypes.func,
}

export default CardUsageMPowerCpp