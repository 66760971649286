import React from 'react'
import PropTypes from 'prop-types'

import { styled } from '@mui/material/styles'

import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import OutlinedInput from '@mui/material/OutlinedInput'
import MenuItem from '@mui/material/MenuItem'
import ListItemText from '@mui/material/ListItemText'

// NOTE: Don't use this as a "good example" of how to style components,
// these styles were created using autogenerated code from a codemod script,
// and mui doesn't really recommend doing it this way.
const PREFIX = 'billing-documents'

const classes = {
    billDropdownStyle: `${PREFIX}-billDropdownStyle`,
    billListItemStyle: `${PREFIX}-billListItemStyle`
}

const StyledDiv = styled('div')(() => ({
    [`& .${classes.billDropdownStyle}`]: {
        padding: "8px",
    },

    [`& .${classes.billListItemStyle}`]: {
        lineHeight: 1.2,
        color: "currentColor",
    }
}))

const BillingDocuments = ({eBillDocumentList, selectedBillDownloadUrl, selectedBillViewUrl, selectedBillName, getUrlsForSelectedBill , t}) => {
    const [ selectedBillIndex, setSelectedBillIndex ] = React.useState(0)

    return (
        <StyledDiv>
        <p className="h5 font-weight-bold text-muted">{t("Billing documents")}</p>
        {eBillDocumentList.length === 0
        ?
        <div className="my-4">{t("No_billing_documents")}</div>
        :
        <FormControl variant="outlined" className="w-100 mt-3" disabled={false}>
            <label className="mb-0 text-muted">{t("Select a document")}</label>

            <Select
                value={selectedBillIndex}
                onChange={event => {
                    const newSelectedBillIndex = event.target.value
                    setSelectedBillIndex(newSelectedBillIndex)
                    getUrlsForSelectedBill(newSelectedBillIndex, eBillDocumentList[newSelectedBillIndex])
                }}
                input={
                    <OutlinedInput labelWidth={0} classes={{ input: classes.billDropdownStyle }} />
                }
            >
                {eBillDocumentList.map((bill, index) =>
                (<MenuItem key={index} value={index}>
                    <ListItemText primary="Monthly bill" secondary={bill.documentDisplayDate} classes={{ primary: classes.billListItemStyle, secondary: classes.billListItemStyle }} />
                </MenuItem>)
                )}
            </Select>

            <div className="d-flex flex-row justify-content-end mt-2">
                {selectedBillViewUrl !== ""
                ? <a href={selectedBillViewUrl} target="_blank" className="btn srp-btn btn-lightblue mr-2">{t("View")}</a>
                : <button disabled={true} className="btn srp-btn btn-lightblue mr-2">{t("View")}</button>
                }

                {selectedBillDownloadUrl !== ""
                ? <a href={selectedBillDownloadUrl} download={selectedBillName} className="btn srp-btn btn-lightblue">{t("Download")}</a>
                : <button disabled={true} className="btn srp-btn btn-lightblue">{t("Download")}</button>
                }
            </div>
        </FormControl>
        }
        </StyledDiv>
    )
}

BillingDocuments.propTypes = {
    eBillDocumentList: PropTypes.arrayOf(PropTypes.shape({
        documentID: PropTypes.string.isRequired,
        billingID: PropTypes.number.isRequired,
        documentDate: PropTypes.string.isRequired,
        documentDisplayDate: PropTypes.string.isRequired,
        documentType: PropTypes.number.isRequired,
    })),
    selectedBillName: PropTypes.string.isRequired,
    selectedBillDownloadUrl: PropTypes.string.isRequired,
    selectedBillViewUrl: PropTypes.string.isRequired,
    getUrlsForSelectedBill: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired
}

export default BillingDocuments