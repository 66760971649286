import { connect } from 'react-redux'

import ConfirmPaymentDetailsCard from './confirm-payment-details-card'

import { submitPaymentList, submitPaymentListFailure } from '../../../actions/auth/payment/gift-payment-actions'

import { formCompleteEvent, formErrorEvent } from '../../../srp_modules/analytics-events'
import { giftPaymentConstants } from '../common/gift-payment-constants'
import { withRouter } from '../../../srp_modules/with-router'

const mapStateToProps = state => {
    const paymentAmountList = state.giftPaymentList.paymentList.map(p => p.paymentAmount)

    const errorSubmittingPayment = state.giftPaymentApiResponse.errorSubmittingPayment
    const isSubmitting = state.giftPaymentApiResponse.isSubmittingPayment

    return { paymentAmountList, isSubmitting, errorSubmittingPayment }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
    goBackToEditPaymentDetails: () => {
        ownProps.router.navigate(-1);
    },
    submitPayment: async (paymentAmountList) => {
        const formName = giftPaymentConstants.ADOBE_ANALYTICS_FORM_NAME

        const response = await dispatch(submitPaymentList())

        if (response.error === true) {
            dispatch(submitPaymentListFailure())
            formErrorEvent(formName)
        }
        else if (response.payload.referenceNumber > 0) {
            const meta = {paymentCount: paymentAmountList.length, payments: paymentAmountList}
            formCompleteEvent(formName, meta)

            ownProps.router.navigate('/myaccount/payment/gift/submitted', { replace: true })
        }
    }
})

const ConfirmPaymentDetailsCardContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(ConfirmPaymentDetailsCard))
export default ConfirmPaymentDetailsCardContainer