import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { dailyUsageChartTypeConstants } from '../../constants/daily-usage-chart-type-constants'
import MonthlyUsageChartContainer from './monthly-usage-chart-container'
import MonthlyTableView from './monthly-table-view'
import Divider from '@mui/material/Divider'
import '../../styles/usage.css'
import { getUsageMonthlyChartType, getMonthlyDataTable } from '../../actions/auth/usage/usage-actions'
import exportUsageModule from '../../srp_modules/charting/export'

class MonthlyUsageContainer extends React.Component {
    constructor(props) {
        super(props)

        this.exportMonthlyToExcel = this.exportMonthlyToExcel.bind(this)
    }

    exportMonthlyToExcel(dataType, chartType) {
        let dataToExport = exportUsageModule.getUsageDataWithDateRangeForMonthly(this.props.accountHistoryList, this.props.monthlyStartDate, this.props.monthlyEndDate)
        exportUsageModule.getUsageChartExport(dataToExport, dataType, chartType)
    }

    render() {
        let clickVerbiage = ""
        switch (this.props.currentMonthlyChart) {
            case dailyUsageChartTypeConstants.USAGE:
                clickVerbiage = this.props.rateMetaData.rateMetaData.isIDRRate
                    ? this.props.t("Click a bar to see daily billed energy")
                    : this.props.t("Click a bar to see daily usage")
                break
            case dailyUsageChartTypeConstants.COST:
                this.props.rateMetaData.rateMetaData.hasCost
                    ? clickVerbiage = this.props.t("Click a bar to see daily cost")
                    : clickVerbiage = this.props.t("Click a bar to see daily usage")
                break
            case dailyUsageChartTypeConstants.GENERATION:
                clickVerbiage = this.props.t("Click a bar to see daily generation")
                break
            case dailyUsageChartTypeConstants.DEMAND:
                this.props.rateMetaData.rateMetaData.hasMonthlyDemandOnly
                    ? clickVerbiage = this.props.t("Click a bar to see daily usage")
                    : clickVerbiage = this.props.t("Click a bar to see daily demand")
                break
            case dailyUsageChartTypeConstants.EXPORT:
                clickVerbiage = this.props.t("Click a bar to see daily export")
                break
            case dailyUsageChartTypeConstants.CONSUMPTION:
                clickVerbiage = this.props.t("Click a bar to see daily usage")
                break
        }
        let isCommercialSolar = this.props.rateMetaData.rateMetaData.isCommercial && this.props.rateMetaData.rateMetaData.isSolar
        return (

            <div className="ml-4 mr-4 pb-2">
                {this.props.showMonthlyDataTable
                    ? <div className="d-none d-lg-block mb-4"/>
                    : <div className="d-none d-lg-block mb-4">
                        <div >{clickVerbiage}</div>
                    </div>
                }
                <div className="d-none d-lg-block chart-btn-container">
                    <span className="mr-2" style={{ color: 'black' }}>{this.props.t("View usage in")}</span>
                    <button className={this.props.monthlyButtonClassNames.costBtnClassName} onClick={() => this.props.getUsageMonthlyChartTypeOnClick(dailyUsageChartTypeConstants.COST)}>{this.props.t("Cost ($)")}</button>
                    <span className={isCommercialSolar ? "hidden-element" : "inline-element"}>
                        <button className={this.props.monthlyButtonClassNames.usageBtnClassName} onClick={() => this.props.getUsageMonthlyChartTypeOnClick(dailyUsageChartTypeConstants.USAGE)}>
                            {this.props.rateMetaData.rateMetaData.isIDRRate &&
                                <span>{this.props.t("Billed Energy")}</span>
                            }
                            {!this.props.rateMetaData.rateMetaData.isIDRRate &&
                                <span>{this.props.t("Usage (kWh)")}</span>
                            }
                        </button>
                        {this.props.rateMetaData.rateMetaData.isIDRRate &&
                            <button className={this.props.monthlyButtonClassNames.exportBtnClassName} onClick={() => this.props.getUsageMonthlyChartTypeOnClick(dailyUsageChartTypeConstants.EXPORT)}>{this.props.t("Export (kWh)")}</button>
                        }
                        {this.props.rateMetaData.rateMetaData.isIDRRate &&
                            <button className={this.props.monthlyButtonClassNames.generationBtnClassName} onClick={() => this.props.getUsageMonthlyChartTypeOnClick(dailyUsageChartTypeConstants.GENERATION)}>{this.props.t("Generation")}</button>
                        }
                        {this.props.rateMetaData.rateMetaData.isIDRRate &&
                            <button className={this.props.monthlyButtonClassNames.consumptionBtnClassName} onClick={() => this.props.getUsageMonthlyChartTypeOnClick(dailyUsageChartTypeConstants.CONSUMPTION)}>{this.props.t("Usage")}</button>
                        }
                    </span>
                    {this.props.rateMetaData.rateMetaData.isDemand &&
                        <button className={this.props.monthlyButtonClassNames.demandBtnClassName} onClick={() => this.props.getUsageMonthlyChartTypeOnClick(dailyUsageChartTypeConstants.DEMAND)}>{this.props.t("Demand")}</button>
                    }
                </div>

                <div className="d-block d-lg-none">
                    <div className="d-flex justify-content-end">
                        <button
                            className="btn srp-btn btn-lightblue mr-2"
                            onClick={() => this.props.getMonthlyDataTableOnClick(!this.props.showMonthlyDataTable)}>
                            {this.props.showMonthlyDataTable ? this.props.t("View chart") : this.props.t("View data table")}
                        </button>
                        <button
                            className="btn srp-btn btn-lightblue"
                            onClick={() => this.exportMonthlyToExcel(this.props.currentMonthlyChart, this.props.selectedUsagePageChartTab)}>
                            {this.props.t("Export")}
                        </button>
                    </div>
                </div>
                <Divider className="mt-4 mb-4 d-lg-none" id="top-of-monthly-chart" />
                <div className="d-block d-lg-none mb-4 ">
                    <div className="mb-2 mobile-chart-label">{this.props.t("View usage in")}</div>
                    <div>
                        <button className={this.props.monthlyButtonClassNames.costBtnClassName} onClick={() => this.props.getUsageMonthlyChartTypeOnClick(dailyUsageChartTypeConstants.COST)}>{this.props.t("Cost ($)")}</button>
                        <span className={isCommercialSolar ? "hidden-element" : "inline-element"}>
                            <button className={this.props.monthlyButtonClassNames.usageBtnClassName} onClick={() => this.props.getUsageMonthlyChartTypeOnClick(dailyUsageChartTypeConstants.USAGE)}>
                                {this.props.rateMetaData.rateMetaData.isIDRRate &&
                                    <span>{this.props.t("Billed Energy")}</span>
                                }
                                {!this.props.rateMetaData.rateMetaData.isIDRRate &&
                                    <span>{this.props.t("Usage (kWh)")}</span>
                                }
                            </button>
                            {this.props.rateMetaData.rateMetaData.isIDRRate &&
                                <button className={this.props.monthlyButtonClassNames.exportBtnClassName} onClick={() => this.props.getUsageMonthlyChartTypeOnClick(dailyUsageChartTypeConstants.EXPORT)}>{this.props.t("Export (kWh)")}</button>
                            }
                            {this.props.rateMetaData.rateMetaData.isIDRRate &&
                                <button className={this.props.monthlyButtonClassNames.generationBtnClassName} onClick={() => this.props.getUsageMonthlyChartTypeOnClick(dailyUsageChartTypeConstants.GENERATION)}>{this.props.t("Generation")}</button>
                            }
                            {this.props.rateMetaData.rateMetaData.isIDRRate &&
                                <button className={this.props.monthlyButtonClassNames.consumptionBtnClassName} onClick={() => this.props.getUsageMonthlyChartTypeOnClick(dailyUsageChartTypeConstants.CONSUMPTION)}>{this.props.t("Usage")}</button>
                            }
                        </span>
                        {this.props.rateMetaData.rateMetaData.isDemand &&
                            <button className={this.props.monthlyButtonClassNames.demandBtnClassName} onClick={() => this.props.getUsageMonthlyChartTypeOnClick(dailyUsageChartTypeConstants.DEMAND)}>{this.props.t("Demand")}</button>
                        }
                    </div>
                </div>

                {this.props.showMonthlyDataTable
                    ? null
                    : <div className="d-block d-lg-none mb-4">
                        <div >{clickVerbiage}</div>
                    </div>
                }

                {this.props.showMonthlyDataTable
                    ? null
                    : <div className="d-block d-lg-none mb-4 center-text">
                        <div className={this.props.currentMonthlyChart === dailyUsageChartTypeConstants.COST ? "block-element" : "hidden-element"}>{this.props.t("Billing amount ($)")}</div>
                        <div className={this.props.currentMonthlyChart === dailyUsageChartTypeConstants.USAGE || dailyUsageChartTypeConstants === dailyUsageChartTypeConstants.CONSUMPTION ? "block-element" : "hidden-element"}>{this.props.t("Usage (kWh)")}</div>
                        <div className={this.props.currentMonthlyChart === dailyUsageChartTypeConstants.DEMAND ? "block-element" : "hidden-element"}>{this.props.t("Peak Demand")}</div>
                    </div>
                }

                {this.props.showMonthlyDataTable
                    ? <MonthlyTableView
                        usageData={exportUsageModule.getUsageDataWithDateRangeForMonthly(this.props.accountHistoryList, this.props.monthlyStartDate, this.props.monthlyEndDate)}
                        currentUsageType={this.props.currentMonthlyChart} 
                        t={this.props.t}/>
                    : <MonthlyUsageChartContainer t={this.props.t}/>
                }

                <div className="d-none d-lg-block mt-4">
                    <div className="d-flex justify-content-end mb-1">
                        <button
                            className="btn srp-btn btn-lightblue mr-2"
                            onClick={() => this.props.getMonthlyDataTableOnClick(!this.props.showMonthlyDataTable)}>
                            {this.props.showMonthlyDataTable ? this.props.t("View as chart") : this.props.t("View as data table")}
                        </button>
                        <button
                            className="btn srp-btn btn-lightblue"
                            onClick={() => this.exportMonthlyToExcel(this.props.currentMonthlyChart, this.props.selectedUsagePageChartTab)}>
                            {this.props.t("Export to Excel")}
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        ...state.accountInfo.usagePageUI,
        rateMetaData: state.rateMetaData,
        ...state.accountHistory
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getUsageMonthlyChartTypeOnClick: (type) => {
            dispatch(getUsageMonthlyChartType(type))
        },
        getMonthlyDataTableOnClick: (showTable) => {
            dispatch(getMonthlyDataTable(showTable))
        }
    }
}

MonthlyUsageContainer.propTypes = {
    monthlyButtonClassNames: PropTypes.object,
    rateMetaData: PropTypes.object,
    currentMonthlyChart: PropTypes.string,
    getUsageMonthlyChartTypeOnClick: PropTypes.func,
    selectedUsagePageChartTab: PropTypes.number,
    accountHistoryList: PropTypes.array,
    monthlyStartDate: PropTypes.string,
    monthlyEndDate: PropTypes.string,
    getMonthlyDataTableOnClick: PropTypes.func,
    showMonthlyDataTable: PropTypes.bool,
    t: PropTypes.func
}

export default connect(mapStateToProps, mapDispatchToProps)(MonthlyUsageContainer);