import {hourlyUsageConstants} from '../../../constants/hourly-usage-constants'
import {billAccountConstants} from '../../../constants/bill-account-constants'
import * as loadingStatus from '../../../constants/loading-status-constants'

const initialState = {
    hourlyUsageList:[],
    hourlyGenerationList: [],
    hourlyConsumptionList: [],
    hourlyDemandList: [],
    hourlyReceivedList: [],
    isIDRRate: false,
    hourlyUsageStatus: loadingStatus.LOADING_STATUS_INIT
}

export default function hourlyUsageReducer(state = initialState, action){
    switch(action.type){
        case hourlyUsageConstants.GET_HOURLY_REQUEST:
            return {
                    ...state,
                    hourlyUsageStatus: loadingStatus.LOADING_STATUS_IN_PROGRESS
                }
        case hourlyUsageConstants.GET_HOURLY_SUCCESS:
            return {
                ...state,
                hourlyUsageList: action.payload.hourlyUsageList,
                hourlyGenerationList: action.payload.hourlyGenerationList,
                hourlyConsumptionList: action.payload.hourlyConsumptionList,
                hourlyDemandList: action.payload.demandList,
                hourlyReceivedList: action.payload.hourlyReceivedList,
                hourlyUsageStatus: loadingStatus.LOADING_STATUS_SUCCESS
            }
        case hourlyUsageConstants.GET_HOURLY_FAILURE:
            return{
                ...state,
                hourlyUsageList:[],
                hourlyGenerationList: [],
                hourlyConsumptionList: [],
                hourlyDemandList: [],
                hourlyReceivedList: [],
                hourlyUsageStatus: loadingStatus.LOADING_STATUS_FAILURE
            }
        case hourlyUsageConstants.GET_YESTERDAY_USAGE_REQUEST:
            return {
                    ...state,
                    yesterdaysUsageStatus: loadingStatus.LOADING_STATUS_IN_PROGRESS
                }
        case hourlyUsageConstants.GET_YESTERDAY_USAGE_SUCCESS:
            return {
                ...state,
                hourlyUsageList: action.payload,
                yesterdaysUsageStatus: loadingStatus.LOADING_STATUS_SUCCESS
            }
        case hourlyUsageConstants.GET_YESTERDAY_USAGE_FAILURE:
            return{
                ...state,
                hourlyUsageList:[],
                yesterdaysUsageStatus: loadingStatus.LOADING_STATUS_FAILURE
            }
        case billAccountConstants.SELECT_BILL_ACCOUNT_SUCCESS:
            return initialState
        default:
            return state;
    }
}