import React from "react"
import PropTypes from 'prop-types'

import * as loadingStatus from '../../../../constants/loading-status-constants'

import PaymentDetails from './payment-details'

const ScheduledPayment = function ({
    scheduledPayment,
    bankAccountList,
    showMakeAnotherPaymentButton,
    makeAnotherPayment,
    removePayment,
    scheduledBy,
    deletePaymentStatus,
    isScheduledPaymentDeleted,
    supportPhoneNumber,
    t,
    i18n })
{
const deletingPayment = (deletePaymentStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS)
const deletePaymentFailure = (
    (deletePaymentStatus === loadingStatus.LOADING_STATUS_SUCCESS && isScheduledPaymentDeleted===false) ||
    (deletePaymentStatus === loadingStatus.LOADING_STATUS_FAILURE)
)

return (
<div>
    <h4 className="text-secondary mb-3">{t("Scheduled payments")}</h4>

    {deletePaymentFailure &&
    <div className="srp-alert-error mb-3">
        {t("Unable to remove scheduled payment")} <a href={"tel:" + supportPhoneNumber}>{supportPhoneNumber}</a>.
    </div>
    }

    <div className="srp-alert-warn mb-4">
        <strong>{t("ATTENTION")}</strong> {t("This account has a scheduled payment")}
    </div>

    <PaymentDetails
        paymentAmount={scheduledPayment.paymentAmount}
        paymentDate={scheduledPayment.paymentDate}
        fundingSourceId={scheduledPayment.fundingSourceId}
        scheduledVia={scheduledPayment.scheduledVia}
        referenceNumber={scheduledPayment.referenceNumber}
        bankAccountList={bankAccountList}
        scheduledBy={scheduledBy}
        t={t}
        i18n={i18n}
    />

    <div className="d-flex justify-content-end mt-4">
        <button type="button" disabled={deletingPayment} className="btn srp-btn btn-lightblue" onClick={() => removePayment(scheduledPayment.referenceNumber)}>{deletingPayment ? t("Removing") : t("Remove")}</button>
        {showMakeAnotherPaymentButton &&
        <button type="button" disabled={deletingPayment} className="btn srp-btn btn-lightblue ml-2" onClick={makeAnotherPayment}>{t("Make payment")}</button>
        }
    </div>
</div>
)}

ScheduledPayment.propTypes = {
    scheduledPayment: PropTypes.shape({
        paymentAmount: PropTypes.number.isRequired,
        paymentDate: PropTypes.string.isRequired,
        fundingSourceId: PropTypes.number.isRequired,
        scheduledVia: PropTypes.string.isRequired,
        referenceNumber: PropTypes.number.isRequired,
    }).isRequired,
    bankAccountList: PropTypes.arrayOf(PropTypes.object).isRequired,
    scheduledBy: PropTypes.string.isRequired,
    showMakeAnotherPaymentButton: PropTypes.bool.isRequired,
    makeAnotherPayment: PropTypes.func.isRequired,
    removePayment: PropTypes.func.isRequired,
    deletePaymentStatus: PropTypes.string.isRequired,
    isScheduledPaymentDeleted: PropTypes.bool.isRequired,
    supportPhoneNumber: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired,
    i18n: PropTypes.object.isRequired
}

export default ScheduledPayment