// NOTE: This is needed because it was removed in react-router-dom v6, in an effort to push everyone to start using React Hooks more
import React from 'react'
import {
    useLocation,
    useNavigate,
    useParams
} from "react-router-dom";

export function withRouter(Component) {
    function ComponentWithRouterProp(props) {
      let location = useLocation();
      let navigate = useNavigate();
      let params = useParams();
      return (
        <Component
          {...props}
          router={{ location, navigate, params }}
        />
      );
    }

    return ComponentWithRouterProp
}