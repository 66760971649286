import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { dismissMessage, getDashboardMessages } from '../../actions/auth/myaccount/myaccount-actions'
import { LOADING_STATUS_INIT, LOADING_STATUS_SUCCESS } from '../../constants/loading-status-constants'

import Message from './message'

function updateDataIfNeeded(props) {
    if (props.dashboardMessagesStatus === LOADING_STATUS_INIT) {
        props.getDashboardMessages()
    }
    
    if (props.dashboardMessagesStatus !== LOADING_STATUS_SUCCESS) {
        return
    }
}

class MessageContainer extends React.Component {
    componentDidMount() {
        updateDataIfNeeded(this.props)
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        updateDataIfNeeded(nextProps)
    }

    render() {
        return (
            <Message
                dismissMessage={this.props.dismissMessage}
                dashboardMessages={this.props.dashboardMessages}
             />
        )
    }
}

const mapStateToProps = (state) => {
    return { ...state.myAccount }
}

const mapDispatchToProps = (dispatch) => {
    return {            
        dismissMessage: (index) => {
            dispatch(dismissMessage(index))

        },
        getDashboardMessages: () => {
            dispatch(getDashboardMessages())
        }
    }
}

MessageContainer.propTypes = {
    dismissMessage: PropTypes.func.isRequired,
    dashboardMessages: PropTypes.arrayOf(PropTypes.string)
}

export default connect(mapStateToProps, mapDispatchToProps)(MessageContainer)