import React from 'react'
import PropTypes from 'prop-types'

import AccountHistoryCardContainer from './account_history/account-history-card-container'
import ViewBillCardContainer from './view_bill/view-bill-card-container'
import CompareBillCardContainer from './compare_my_bill/compare-bill-card-container'
import { withTranslation } from 'react-i18next'

const MyBillPage = ({ getAccountHistory, getEBillListInfo, getRateMetaDataInfo, isSummaryMaster, t }) => {
    return (
        <div className="row">
            <div className="col-lg-6 col-12 mt-3">
                <div className="srp-card-header">{t("View bill")}</div>
                <ViewBillCardContainer
                    getAccountHistory={getAccountHistory}
                    getEBillListInfo={getEBillListInfo}
                    getRateMetaDataInfo={getRateMetaDataInfo} 
                    t={t}
                />
            </div>

            {!isSummaryMaster
                ? <div className="col-lg-6 col-12 mt-3">
                    <div className="srp-card-header">{t("Compare my bill")}</div>
                    <CompareBillCardContainer getAccountHistory={getAccountHistory} t={t}/>
                </div>
                : null

            }

            <div className="col-12 mt-3">
                <div className="srp-card-header">{t("Account history")}</div>
                <AccountHistoryCardContainer
                    getAccountHistory={getAccountHistory}
                    getEBillListInfo={getEBillListInfo} 
                    t={t}
                />
            </div>
        </div>
    )
}

MyBillPage.propTypes = {
    getAccountHistory: PropTypes.func,
    getEBillListInfo: PropTypes.func,
    getRateMetaDataInfo: PropTypes.func,
    isSummaryMaster: PropTypes.bool,
    t: PropTypes.func.isRequired
}

export default withTranslation("myBillPage")(MyBillPage)