import { formConstants } from '../../../constants/form-constants'
import { contactConstants } from '../../../constants/contact-constants'
import { billAccountConstants } from '../../../constants/bill-account-constants'
import { rateMetaDataConstants } from '../../../constants/rate-meta-data-constants'
import { DateTime } from 'luxon'

const initialState = {
  startDate: getMinDate().toJSDate(),
  selectedPlan: formConstants.TIME_OF_USE,
  pricePlanName: "",

  selectedSolarChoicePlan: formConstants.SOLARCHOICE_NONE,
  shouldRunValidation: false,

  holidayList: [],

  address1: "",
  address2: "",
  city: "",
  dayOfMonth: 0,
  isMailingAddressSame: true,
  isPaperlessBilling: true,

  mailingAddress1: "",
  mailingAddress2: "",
  mailingCity: "",
  mailingState: "",
  mailingCountry: "",
  mailingZipCode: "",

  // EV
  carMake: "",
  carModel: "",
  carYear: "",
  ownershipType: "",
  supplyEquipment: "",
  inputVoltage: "",
  inputCurrent: "",

  firstName: "",
  middleName: "",
  lastName: "",
  identificationType: formConstants.SOCIAL,
  identificationNumber: "",
  preferredLanguage: formConstants.ENGLISH,
  email: "",
  phoneNumber: "",
  authorizeSpouse: false,
  spouseFirstName: "",
  spouseLastName: "",

  hasAgreedToSafety: false,
  hasSubmitted: false,
  showSubmitError: false,
  hasCustomDueDate: false,
  submitSucceeded: false,
}

function getPrimaryEmail(payload) {
  let primaryEmail = payload.filter(contact => contact.contactType === 1)[0]
  return (primaryEmail === undefined) ? '' : primaryEmail.contactAddress
}

function getPrimaryPhone(payload) {
  let primaryPhone = payload.filter(contact => contact.contactType === 2)[0]
  return (primaryPhone === undefined) ? '' : primaryPhone.contactAddress
}

function getMinDate() {
  let todayTenPM = DateTime.now().setZone('MST').startOf('day').plus({ hours: 22 })
  let isTodayFriday = DateTime.fromISO(todayTenPM).weekday === 5
  let isPastValidTime = DateTime.now().setZone('MST') > todayTenPM ? true : false

  let minDate = isPastValidTime ? DateTime.now().setZone('MST').plus({ days: 2 }) : DateTime.now().setZone('MST').plus({ days: 1 })
  let minDateForFriday = isPastValidTime ? DateTime.now().setZone('MST').plus({ days: 4 }) : DateTime.now().setZone('MST').plus({ days: 3 })

  return isTodayFriday ? minDateForFriday : minDate
}

export default function turnOnReducer(state = initialState, action) {
  switch (action.type) {
    case contactConstants.GET_CONTACTS_SUCCESS:
      return { ...state, email: getPrimaryEmail(action.payload), phoneNumber: getPrimaryPhone(action.payload) }
    case rateMetaDataConstants.GET_RATE_META_DATA_SUCCESS:
      return {
        ...state,
        firstName: action.payload.isBusinessOnResidentialPlan
                    ? action.payload.displayName
                    : action.payload.firstName,
        middleName: action.payload.middleName,
        lastName: action.payload.lastName,
        spouseFirstName: action.payload.authorizedSpouseFirstName,
        authorizeSpouse: action.payload.authorizedSpouseFirstName.length > 0
      }
    case formConstants.CHANGE_RATE_PLAN:
      return {
        ...state,
        selectedPlan: action.newPlan,
        isPaperlessBilling: action.newPlan === formConstants.MPOWER ? false : state.isPaperlessBilling
      }
    case formConstants.CHANGE_SOLARCHOICE:{
      if (action.solarChoice === state.selectedSolarChoicePlan) {
        return { ...state, selectedSolarChoicePlan: formConstants.SOLARCHOICE_NONE }
      }
      else {
        return { ...state, selectedSolarChoicePlan: action.solarChoice }
      }
    }
    case formConstants.CHANGE_START_DATE:
      return { ...state, startDate: action.newStartDate }
    case formConstants.CHANGE_ADDRESS1:
      return { ...state, address1: action.newAddress1 }
    case formConstants.CHANGE_CITY:
      return { ...state, city: action.newCity }
    case formConstants.CHANGE_MAILING_ADDRESS:
      return { ...state, isMailingAddressSame: action.newSetting }
    case formConstants.CHANGE_ADDRESS2:
      return { ...state, address2: action.newAddress2 }
    case formConstants.CHANG_CUSTOM_DUE_DATE:
      return { ...state, dayOfMonth: action.dateChange }
    case formConstants.CHANGE_PAPERLESS_BILLING:
      return { ...state, isPaperlessBilling: action.newSetting }
    case formConstants.CHANGE_VEHICLE_OWNERSHIP:
      return { ...state, ownershipType: action.newOwnership }
    case formConstants.CHANGE_CAR_MAKE:
      return { ...state, carMake: action.newMake }
    case formConstants.CHANGE_CAR_MODEL:
      return { ...state, carModel: action.newModel }
    case formConstants.CHANGE_CAR_YEAR:
      return { ...state, carYear: action.newYear }
    case formConstants.CHANGE_SUPPLY_EQUIP:
      return { ...state, supplyEquipment: action.newEquipment }
    case formConstants.CHANGE_INPUT_VOLTAGE:
      return { ...state, inputVoltage: action.newVoltage }
    case formConstants.CHANGE_CURRENT:
      return { ...state, inputCurrent: action.newSetting }
    case formConstants.CHANGE_FIRST_NAME:
      return { ...state, firstName: action.newFirstName }
    case formConstants.CHANGE_MIDDLE_NAME:
      return { ...state, middleName: action.newMiddleName }
    case formConstants.CHANGE_LAST_NAME:
      return { ...state, lastName: action.newLastName }
    case formConstants.CHANGE_ID_TYPE:{
      if(action.newIDType !== state.identificationType){
        return { ...state, identificationType: action.newIDType, identificationNumber: "" }
      }
      return { ...state, identificationType: action.newIDType }
    }
    case formConstants.CHANGE_ID_NUMBER:
      return { ...state, identificationNumber: action.newIDNumber }
    case formConstants.CHANGE_PREFERRED_LANGUAGE:
      return { ...state, preferredLanguage: action.newLang }
    case formConstants.CHANGE_EMAIL:
      return { ...state, email: action.newEmail }
    case formConstants.CHANGE_PHONE_NUMBER:
      return { ...state, phoneNumber: action.newNumber }
    case formConstants.CHANGE_AUTH_SPOUSE:
      return { ...state, authorizeSpouse: action.newSetting }
    case formConstants.CHANGE_SPOUSE_FIRST_NAME:
      return { ...state, spouseFirstName: action.newName }
    case formConstants.CHANGE_SPOUSE_LAST_NAME:
      return { ...state, spouseLastName: action.newLastName }
    case formConstants.CHANGE_MAILING_ADDRESS1:
      return { ...state, mailingAddress1: action.address }
    case formConstants.CHANGE_MAILING_ADDRESS2:
      return { ...state, mailingAddress2: action.address }
    case formConstants.CHANGE_MAILING_CITY:
      return { ...state, mailingCity: action.city }
    case formConstants.CHANGE_MAILING_STATE:
      return { ...state, mailingState: action.state }
    case formConstants.CHANGE_MAILING_COUNTRY:
      return { ...state, mailingCountry: action.country }
    case formConstants.CHANGE_MAILING_ZIPCODE:
      return { ...state, mailingZipCode: action.zip }
    case formConstants.RUN_VALIDATION_CHECK:
      return { ...state, shouldRunValidation: action.shouldCheck }
    case formConstants.CHANGE_SAFETY_AGREEMENT:
      return { ...state, hasAgreedToSafety: action.hasAgreed }
    case formConstants.SUBMIT_TURNON_SUCCESS:
      return {
        ...state,
        hasSubmitted: false,
        submitSucceeded: action.payload.success,
        showSubmitError: false
      }
    case formConstants.SUBMIT_TURNON_REQUEST:
      return {
        ...state,
        hasSubmitted: true,
        submitSucceeded: false,
        showSubmitError: false
      }
    case formConstants.SUBMIT_TURNON_FAILURE:
      return {
        ...state,
        hasSubmitted: false,
        submitSucceeded: false,
        showSubmitError: true
      }
    case formConstants.HOLIDAY_SUCCESS:
      return {
        ...state,
        holidayList: action.payload.holidays
      }
    case formConstants.REFRESH_START_FORM:
      return {
        ...initialState,
        firstName: state.firstName,
        middleName: state.middleName,
        lastName: state.lastName,
        spouseFirstName: state.spouseFirstName,
        authorizeSpouse: state.authorizeSpouse,
        email: state.email,
        phoneNumber: state.phoneNumber
       }
    case billAccountConstants.SELECT_BILL_ACCOUNT_SUCCESS:
      return { ...initialState }
    default:
      return state
  }
}