import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import MyAccountMainContainer from '../../containers/my-account-main-container'
import UsagePage from './usage-page'

import { getUsagePageChartTab } from '../../actions/auth/usage/usage-actions'

class UsagePageContainer extends React.Component {
    constructor(props) {
        super(props)

    }

    render(){
        return(
            <MyAccountMainContainer pageTitle="Usage">
                <UsagePage
                    getUsagePageChartTabOnClick={this.props.getUsagePageChartTabOnClick}
                    selectedUsagePageChartTab={this.props.selectedUsagePageChartTab}
                    pricePlan={this.props.pricePlan}
                    rateMetaData={this.props.rateMetaData}
                    isCommercial={this.props.isCommercial}
                    hasAverageDemand={this.props.hasAverageDemand} />
            </MyAccountMainContainer>
        )
    }
}

const mapStateToProps = state => {
    return {
        ...state.accountInfo.usage,
        ...state.accountInfo.usagePageUI,
        pricePlan: state.accountInfo.billAccount.currentRateCode,
        isCommercial: state.accountInfo.billAccount.selectedBillAccountDetails.isCommercial,
        rateMetaData: state.rateMetaData,
        hasAverageDemand: state.dailyUsage.isAverageDemandRate }
}

const mapDispatchToProps = dispatch => {
    return {
        getUsagePageChartTabOnClick: (evt, tab) => {
            dispatch(getUsagePageChartTab(evt, tab))
        }
    }
}

UsagePageContainer.propTypes = {
    getUsagePageChartTabOnClick: PropTypes.func,
    selectedUsagePageChartTab: PropTypes.number,
    pricePlan: PropTypes.object,
    rateMetaData: PropTypes.object,
    isCommercial: PropTypes.bool,
    hasAverageDemand: PropTypes.bool
}

export default connect(mapStateToProps, mapDispatchToProps)(UsagePageContainer);