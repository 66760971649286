import React from 'react'
import PropTypes from 'prop-types'
import { Link, useLocation } from 'react-router-dom'
import { withTranslation } from 'react-i18next'

import DashboardIcon from '../../images/nav/nav_icons/dark_blue_icons/dashboard-dark-blue.svg'
import OutageIcon from '../../images/nav/nav_icons/dark_blue_icons/outages-dark-blue.svg'
import PricePlansProgramsIcon from '../../images/nav/nav_icons/dark_blue_icons/price-plan-programs-dark-blue.svg'
import ProfileIcon from '../../images/nav/nav_icons/dark_blue_icons/profile-dark-blue.svg'
import PaymentIcon from '../../images/nav/nav_icons/dark_blue_icons/payment-dark-blue.svg'

const PendingTurnOnCard = ({ colStyle, t }) => {
    let locationHook = useLocation()

    let icon = DashboardIcon
    let welcome = ''
    let title = ''

    let myAccountPageRegex = /^\/?myaccount\/([^/]+)/i
    let myAccountMatch = myAccountPageRegex.exec(locationHook.pathname)
    let pageName = ((myAccountMatch !== null) ? myAccountMatch[1] : '').toLowerCase()

    switch (pageName) {
        case 'dashboard':
            title='My dashboard'
            icon = DashboardIcon
            welcome = 'Welcome to your dashboard'
            break;
        case 'outages':
            title='My outages'
            icon = OutageIcon
            welcome='Looking to report or view an outage?'
            break;
        case 'payment':
            title='Your account has yet to be activated'
            icon = PaymentIcon
            welcome='Want to make a payment?'
            break;
        case 'profile':
            title='My profile & settings'
            icon = ProfileIcon
            welcome='Wanting to sign up for alerts?'
            break;
        case 'programs':
            title='My price plan & programs'
            icon = PricePlansProgramsIcon
            welcome='Looking for details on your price plan?'
            break;
        default:
            break;
    }


    let cls = colStyle == null ?  "srp-card-body col-12 col-lg-6" : "srp-card-body " + colStyle

    return (
        <div className="srp-card">
            <div className="srp-card-header mt-2">
                {t(title)}
            </div>
            <div className={cls}>
                <div className="srp-card-details">
                    <div className="text-center">
                        <div style={{ color: "#004B87" }}><img alt="icon" src={icon} style={{ height:"36px", width:"36px" }} /></div>
                        <div className="mb-2" style={{ color:"#004B87", fontSize:"1.125rem" }}><strong>{t(welcome)}</strong></div>
                        {   pageName === 'dashboard' ?
                                <div className="text-center srp-gray-color">
                                    <p className="mb-3">
                                        {t("Once your account setup is complete and electric service has been established, your main My Account information will be displayed here. You're welcome to get familiar with the site, but it may take a few days to start seeing details for your account.")}
                                    </p>
                                    {t("From the dashboard, you'll be able to")}:
                                    <ul className="mt-1 srp-list">
                                        <li><span className="srp-list">{t("Receive high level details of your bill, usage and outages")}</span></li>
                                        <li><span className="srp-list">{t("Have quick access to making a payment and report an outage")}</span></li>
                                        <li><span className="srp-list">{t("And more")}</span></li>
                                    </ul>
                                    <Link to="/myaccount/payment" className="btn srp-btn btn-lightblue">{t("Make payment")}</Link>
                                </div> :
                            pageName === 'outages' ?
                                <div className="srp-gray-color">
                                    {t("Once your account has been activated, this page will provide you all of your outage needs. You'll be able to")}:
                                    <ul className="mt-1 srp-list">
                                        <li><span className="srp-list">{t("View and report outages")}</span></li>
                                        <li><span className="srp-list">{t("Check out your outage history and service reliability")}</span></li>
                                    </ul>
                                </div> :
                            pageName === 'payment' ?
                                <div className="srp-gray-color">
                                    {t("Once your account has been activated, you will be able to start making payments. SRP is delighted to have you as a customer and look forward to serving you.")}
                                </div> :
                            pageName === 'profile' ?
                                <div className="srp-gray-color">
                                    {t("Once your account has been activated, this page will help you stay connected to your account. You'll be able to:")}
                                    <ul className="mt-1 srp-list">
                                        <li><span className="srp-list">{t("View and change your contact phone and email")}</span></li>
                                        <li><span className="srp-list">{t("Enroll in text and email alerts")}</span></li>
                                        <li><span className="srp-list">{t("And more")}</span></li>
                                    </ul>
                                </div> :
                            pageName === 'programs' ?
                                <div className="srp-gray-color">
                                    {t("Once your account has been activated, this page will provide you all of your price plan related needs. You'll be able to:")}
                                    <ul className="mt-1 srp-list">
                                        <li><span className="srp-list">{t("View and change your current price plan")}</span></li>
                                        <li><span className="srp-list">{t("Compare your current price plan to other offers")}</span></li>
                                        <li><span className="srp-list">{t("And more")}</span></li>
                                    </ul>
                                </div> :
                                <div className="srp-gray-color">&nbsp;</div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

PendingTurnOnCard.propTypes = {
    colStyle: PropTypes.string,
    t: PropTypes.func.isRequired,
}

export default withTranslation("pendingTurnOnCard")(PendingTurnOnCard)