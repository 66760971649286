import { evCommunityConstants } from '../../../constants/ev-community-constants'
import { billAccountConstants } from '../../../constants/bill-account-constants'

import * as loadingStatus from '../../../constants/loading-status-constants'

const initialState = {
    submitEVFormResult:
    {
        submitSuccess: false,
        emailSuccess: false
    },
    submitEVCommunityFormStatus: loadingStatus.LOADING_STATUS_INIT,
    unApprovedVehicleTypes:{},
    unApprovedVehicleTypesStatus: loadingStatus.LOADING_STATUS_INIT
}

export default function evCommunityReducer(state = initialState, action) {
    switch (action.type) {

        case evCommunityConstants.SUBMIT_EV_FORM_REQUEST:
            return { ...state, submitEVCommunityFormStatus: loadingStatus.LOADING_STATUS_IN_PROGRESS }
        case evCommunityConstants.SUBMIT_EV_FORM_SUCCESS: {
            return {
                ...state,
                submitEVFormResult: action.payload,
                submitEVCommunityFormStatus: loadingStatus.LOADING_STATUS_SUCCESS
            }
        }
        case evCommunityConstants.SUBMIT_EV_FORM_FAILURE:
            return {
                ...state,
                submitEVFormResult: initialState.submitEVFormResult,
                submitEVCommunityFormStatus: loadingStatus.LOADING_STATUS_FAILURE
            }

        case evCommunityConstants.RESET_FORM_SUBMISSIONS_STATUS:
            return {
                ...state,
                submitEVFormResult: initialState.submitEVFormResult,
                submitEVCommunityFormStatus: loadingStatus.LOADING_STATUS_INIT
            }

        case billAccountConstants.SELECT_BILL_ACCOUNT_SUCCESS:
            return { ...initialState }

        case evCommunityConstants.GET_UNAPPROVED_VEHICLETYPES_REQUEST:
            return { ...state, unApprovedVehicleTypesStatus: loadingStatus.LOADING_STATUS_IN_PROGRESS}
        case evCommunityConstants.GET_UNAPPROVED_VEHICLETYPES_SUCCESS:
            return { ...state, unApprovedVehicleTypesStatus: loadingStatus.LOADING_STATUS_SUCCESS, unApprovedVehicleTypes: action.payload }
        case evCommunityConstants.GET_UNAPPROVED_VEHICLETYPES_FAILURE:
            return { ...state, unApprovedVehicleTypesStatus: loadingStatus.LOADING_STATUS_FAILURE}
        default:
            return state
    }
}