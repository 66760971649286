/* eslint react/no-danger: 0 */
import React from 'react'
import PropTypes from 'prop-types'
import config from 'my-account-config'
import { Settings } from 'luxon'

const NavBarMenu = ({ i18n, navMenuHtml, preventLocalization }) => {
    React.useEffect(() => {
        const toggleLanguageOnClick = (event) => {
            event.preventDefault()
            event.stopPropagation()
    
            switch (i18n.language) {
                case 'en':
                    i18n.changeLanguage('es')
                    Settings.defaultLocale = 'es'
                    break;
                default:
                    i18n.changeLanguage('en')
                    Settings.defaultLocale = 'en'
            }
        }

        const attachLanguageToggleEvent = () => {
            let languageToggleDiv = document.getElementById('language-toggle')
            if (!languageToggleDiv)
                return
    
            languageToggleDiv.onclick = toggleLanguageOnClick
    
            let mobileLanguageToggle = document.getElementById('language-toggle-mobile')
            if (!mobileLanguageToggle)
                return

            mobileLanguageToggle.onclick = toggleLanguageOnClick
        }

        if (navMenuHtml && navMenuHtml.length > 0) {
            if (window.buildSharedHeaderDropdowns) {
                window.buildSharedHeaderDropdowns()
            }
            attachLanguageToggleEvent()
        }
    }, [ i18n, navMenuHtml ])

    return (
        <div className="d-print-none common-nav-container">
            <div id="commonNav" className={preventLocalization ? 'en-only' : 'es'} dangerouslySetInnerHTML={{ __html: navMenuHtml }} />
            {!config.isProduction &&
                <div className="srp-alert-error">
                    <strong>This is a non-production site.</strong> Please go to our production site <a href="https://myaccount.srpnet.com">https://myaccount.srpnet.com</a> to take advantage of all My Account features and functionality.
                </div>
            }
        </div>
    )
}

NavBarMenu.propTypes = {
    i18n: PropTypes.object,
    navMenuHtml: PropTypes.string,
    preventLocalization: PropTypes.bool
}

export default NavBarMenu