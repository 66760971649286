import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { dailyUsageChartTypeConstants } from '../../constants/daily-usage-chart-type-constants'
import DailyUsageChartContainer from './daily-usage-chart-container'
import DailyTableView from './daily-table-view'
import Divider from "@mui/material/Divider"
import '../../styles/usage.css'
import { getUsageDailyChartType, getDailyDataTable } from '../../actions/auth/usage/usage-actions'
import exportUsageModule from '../../srp_modules/charting/export'

class DailyUsageContainer extends React.Component {
    constructor(props) {
        super(props)

        this.exportDailyToExcel = this.exportDailyToExcel.bind(this)
        this.getUsageDataType = this.getUsageDataType.bind(this)
    }

    exportDailyToExcel(dataType, chartType) {
        let dataToExport = this.getUsageDataType(dataType)
        exportUsageModule.getUsageChartExport(dataToExport, dataType, chartType, this.props.dailyWeatherDataList)
    }

    getUsageDataType(dataType) {
        let usageData
        switch (dataType) {
            case dailyUsageChartTypeConstants.DEMAND:
                usageData = this.props.dailyDemandList
                break
            case dailyUsageChartTypeConstants.USAGE:
            case dailyUsageChartTypeConstants.COST:
                usageData = this.props.dailyUsageList
                break
            case dailyUsageChartTypeConstants.GENERATION:
                usageData = this.props.dailyGenerationList
                break
            case dailyUsageChartTypeConstants.CONSUMPTION:
                usageData = this.props.dailyConsumptionList
                break
            case dailyUsageChartTypeConstants.EXPORT:
                usageData = this.props.dailyReceivedList
        }

        let dataWithDateRange = exportUsageModule.getUsageDataWithDateRange(usageData, this.props.dailyStartDate, this.props.dailyEndDate)
        return dataWithDateRange
    }

    render() {
        let clickVerbiage = ""
        switch (this.props.currentDailyChart) {
            case dailyUsageChartTypeConstants.USAGE:
                clickVerbiage = this.props.rateMetaData.rateMetaData.isIDRRate
                    ? this.props.t("Click a bar to see hourly billed energy")
                    : this.props.t("Click a bar to see hourly usage")
                break
            case dailyUsageChartTypeConstants.COST:
                clickVerbiage = this.props.t("Click a bar to see hourly cost")
                break
            case dailyUsageChartTypeConstants.GENERATION:
                clickVerbiage = this.props.t("Click a bar to see hourly generation")
                break
            case dailyUsageChartTypeConstants.DEMAND:
                clickVerbiage = this.props.t("Click a bar to see hourly demand")
                break
            case dailyUsageChartTypeConstants.EXPORT:
                clickVerbiage = this.props.t("Click a bar to see hourly export")
                break
            case dailyUsageChartTypeConstants.CONSUMPTION:
                clickVerbiage = this.props.t("Click a bar to see hourly usage")
                break
        }
        let showDemand = this.props.rateMetaData.rateMetaData.isDemand && ! this.props.rateMetaData.rateMetaData.hasMonthlyDemandOnly
        return (
            <div className="ml-4 mr-4 pb-2">
                {this.props.showDailyDataTable
                    ? <div className="d-none d-lg-block mb-4"/>
                    : <div className="d-none d-lg-block mb-4">
                        <div >{clickVerbiage}</div>
                    </div>
                }
                {(this.props.rateMetaData.rateMetaData.hasCost
                    || showDemand
                    || this.props.rateMetaData.rateMetaData.isIDRRate)
                    && <div className="d-none d-lg-block chart-btn-container">
                        <span className="mr-2" style={{ color: 'black' }}>{this.props.t("View usage in")}</span>
                        {this.props.rateMetaData.rateMetaData.hasCost &&
                            <button className={this.props.dailyButtonClassNames.costBtnClassName} onClick={() => this.props.getUsageDailyChartTypeOnClick(dailyUsageChartTypeConstants.COST)}>{this.props.t("Cost ($)")}</button>
                        }
                        <button className={this.props.dailyButtonClassNames.usageBtnClassName} onClick={() => this.props.getUsageDailyChartTypeOnClick(dailyUsageChartTypeConstants.USAGE)}>
                            {this.props.rateMetaData.rateMetaData.isIDRRate &&
                                <span>{this.props.t("Billed Energy")}</span>
                            }
                            {!this.props.rateMetaData.rateMetaData.isIDRRate &&
                                <span>{this.props.t("Usage (kWh)")}</span>
                            }
                        </button>
                        {this.props.rateMetaData.rateMetaData.isIDRRate &&
                            <button className={this.props.dailyButtonClassNames.exportBtnClassName} onClick={() => this.props.getUsageDailyChartTypeOnClick(dailyUsageChartTypeConstants.EXPORT)}>{this.props.t("Export (kWh)")}</button>
                        }
                        {this.props.rateMetaData.rateMetaData.isIDRRate &&
                            <button className={this.props.dailyButtonClassNames.generationBtnClassName} onClick={() => this.props.getUsageDailyChartTypeOnClick(dailyUsageChartTypeConstants.GENERATION)}>{this.props.t("Generation")}</button>
                        }
                        {this.props.rateMetaData.rateMetaData.isIDRRate &&
                            <button className={this.props.dailyButtonClassNames.consumptionBtnClassName} onClick={() => this.props.getUsageDailyChartTypeOnClick(dailyUsageChartTypeConstants.CONSUMPTION)}>{this.props.t("Usage")}</button>
                        }
                        {showDemand &&
                            <button className={this.props.dailyButtonClassNames.demandBtnClassName} onClick={() => this.props.getUsageDailyChartTypeOnClick(dailyUsageChartTypeConstants.DEMAND)}>{this.props.t("Demand")}</button>
                        }
                    </div>}
                <div className="d-block d-lg-none">
                    <div className="d-flex justify-content-end">
                        <button
                            className="btn srp-btn btn-lightblue mr-2"
                            onClick={() => this.props.getDailyDataTableOnClick(!this.props.showDailyDataTable)}>
                            {this.props.showDailyDataTable ? this.props.t("View chart") : this.props.t("View data table")}
                        </button>
                        <button
                            className="btn srp-btn btn-lightblue"
                            onClick={() => this.exportDailyToExcel(this.props.currentDailyChart, this.props.selectedUsagePageChartTab)}>
                            {this.props.t("Export")}
                        </button>
                    </div>
                </div>
                <Divider className="mt-4 mb-4 d-lg-none" id="top-of-daily-chart" />
                {(this.props.rateMetaData.rateMetaData.hasCost || showDemand|| this.props.rateMetaData.rateMetaData.isIDRRate) &&
                    <div className="d-block d-lg-none mb-4">
                        <div className="mb-2 mobile-chart-label">{this.props.t("View usage in")}</div>
                        <div>
                            {this.props.rateMetaData.rateMetaData.hasCost &&
                                <button className={this.props.dailyButtonClassNames.costBtnClassName} onClick={() => this.props.getUsageDailyChartTypeOnClick(dailyUsageChartTypeConstants.COST)}>{this.props.t("Cost ($)")}</button>
                            }
                            <button className={this.props.dailyButtonClassNames.usageBtnClassName} onClick={() => this.props.getUsageDailyChartTypeOnClick(dailyUsageChartTypeConstants.USAGE)}>
                                {this.props.rateMetaData.rateMetaData.isIDRRate &&
                                    <span>{this.props.t("Billed Energy")}</span>
                                }
                                {!this.props.rateMetaData.rateMetaData.isIDRRate &&
                                    <span>{this.props.t("Usage (kWh)")}</span>
                                }
                            </button>
                            {this.props.rateMetaData.rateMetaData.isIDRRate &&
                                <button className={this.props.dailyButtonClassNames.exportBtnClassName} onClick={() => this.props.getUsageDailyChartTypeOnClick(dailyUsageChartTypeConstants.EXPORT)}>{this.props.t("Export (kWh)")}</button>
                            }
                            {this.props.rateMetaData.rateMetaData.isIDRRate &&
                                <button className={this.props.dailyButtonClassNames.generationBtnClassName} onClick={() => this.props.getUsageDailyChartTypeOnClick(dailyUsageChartTypeConstants.GENERATION)}>{this.props.t("Generation")}</button>
                            }
                            {this.props.rateMetaData.rateMetaData.isIDRRate &&
                                <button className={this.props.dailyButtonClassNames.consumptionBtnClassName} onClick={() => this.props.getUsageDailyChartTypeOnClick(dailyUsageChartTypeConstants.CONSUMPTION)}>{this.props.t("Usage")}</button>
                            }
                            {showDemand &&
                                <button className={this.props.dailyButtonClassNames.demandBtnClassName} onClick={() => this.props.getUsageDailyChartTypeOnClick(dailyUsageChartTypeConstants.DEMAND)}>{this.props.t("Demand")}</button>
                            }
                        </div>
                    </div>}

                {this.props.showDailyDataTable
                    ? null
                    : <div className="d-block d-lg-none mb-4">
                         <div >{clickVerbiage}</div>
                    </div>
                }

                {this.props.showDailyDataTable
                    ? null
                    : <div className="d-block d-lg-none mb-4 center-text">
                        <div className={this.props.currentDailyChart === dailyUsageChartTypeConstants.COST ? "block-element" : "hidden-element"}>{this.props.t("Energy cost per day")}</div>
                        <div className={this.props.currentDailyChart === dailyUsageChartTypeConstants.USAGE || dailyUsageChartTypeConstants === dailyUsageChartTypeConstants.CONSUMPTION ? "block-element" : "hidden-element"}>{this.props.t("Usage (kWh)")}</div>
                        <div className={this.props.currentDailyChart === dailyUsageChartTypeConstants.DEMAND ? "block-element" : "hidden-element"}>{this.props.t("Peak Demand")}</div>
                    </div>
                }

                {this.props.showDailyDataTable
                    ? <DailyTableView
                        usageData={this.getUsageDataType(this.props.currentDailyChart)}
                        currentUsageType={this.props.currentDailyChart}
                        weather={this.props.dailyWeatherDataList} 
                        t={this.props.t}/>
                    : <DailyUsageChartContainer t={this.props.t}/>}

                <div className="d-none d-lg-block mt-4">
                    <div className="d-flex justify-content-end mb-1">
                        <button
                            className="btn srp-btn btn-lightblue mr-2"
                            onClick={() => this.props.getDailyDataTableOnClick(!this.props.showDailyDataTable)}>
                            {this.props.showDailyDataTable ? this.props.t("View as chart") : this.props.t("View as data table")}
                        </button>
                        <button
                            className="btn srp-btn btn-lightblue"
                            onClick={() => this.exportDailyToExcel(this.props.currentDailyChart, this.props.selectedUsagePageChartTab)}>
                            {this.props.t("Export to Excel")}
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        ...state.accountInfo.usage,
        ...state.accountInfo.usagePageUI,
        ...state.dailyUsage,
        ...state.weatherData,
        rateMetaData: state.rateMetaData
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getUsageDailyChartTypeOnClick: (type) => {
            dispatch(getUsageDailyChartType(type))
        },
        getDailyDataTableOnClick: (showTable) => {
            dispatch(getDailyDataTable(showTable))
        }
    }
}

DailyUsageContainer.propTypes = {
    showDailyCostVerbiage: PropTypes.object,
    showDailyUsageVerbiage: PropTypes.object,
    showDailyCostChart: PropTypes.bool,
    showDailyUsageChart: PropTypes.bool,
    dailyButtonClassNames: PropTypes.object,
    getUsageDailyChartTypeOnClick: PropTypes.func,
    rateMetaData: PropTypes.object,
    currentDailyChart: PropTypes.string,
    dailyUsageList: PropTypes.arrayOf(PropTypes.object),
    dailyDemandList: PropTypes.arrayOf(PropTypes.object),
    dailyConsumptionList: PropTypes.array,
    dailyGenerationList: PropTypes.array,
    dailyReceivedList: PropTypes.array,
    dailyStartDate: PropTypes.object,
    dailyEndDate: PropTypes.object,
    selectedUsagePageChartTab: PropTypes.number,
    dailyWeatherDataList: PropTypes.arrayOf(PropTypes.object),
    getDailyDataTableOnClick: PropTypes.func,
    showDailyDataTable: PropTypes.bool,
    dailyUsage: PropTypes.array,
    t: PropTypes.func
}

export default connect(mapStateToProps, mapDispatchToProps)(DailyUsageContainer);