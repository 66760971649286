import { guestPaymentBankInfoConstants } from '../../../constants/guest/guest-payment-bank-info-constants'

const initialState = {
    bankFirstName: '',
    bankLastName: '',
    bankPhoneNumber: '',
    bankEmailAddress: '',
    bankRoutingNumber: '',
    bankAccountNumberLeadingZeroesAreSignificant: ''
}

export default function guestPaymentBankInfoReducer(state = initialState, action) {
    switch (action.type) {
        case guestPaymentBankInfoConstants.GUEST_PAYMENT_STORE_BANK_INFO_COLLECTED:
            return { ...state,
                bankFirstName: action.bankFirstName,
                bankLastName: action.bankLastName,
                bankPhoneNumber: action.bankPhoneNumber,
                bankEmailAddress: action.bankEmailAddress,
                bankRoutingNumber: action.bankRoutingNumber,
                bankAccountNumberLeadingZeroesAreSignificant: action.bankAccountNumberLeadingZeroesAreSignificant,
            }
        case guestPaymentBankInfoConstants.GUEST_PAYMENT_CLEAR_BANK_INFO:
            return { ...state, ...initialState }
        default:
            return state
    }
}