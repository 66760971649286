import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import UsagePageLoading from './usage-page-loading'
import { getDailyUsage } from '../../actions/auth/usage/daily-usage-actions'
import { initDailyUsagePage } from '../../actions/auth/usage/usage-actions'
import { dailyUsageChartTypeConstants } from '../../constants/daily-usage-chart-type-constants'
import * as loadingStatus from '../../constants/loading-status-constants'
import {getRateMetaData} from '../../actions/auth/usage/rate-meta-data-actions'
import { DateTime } from 'luxon'

class PeakDemandCard extends React.Component{
    constructor(props){
        super(props)
    }

    componentDidMount(){
        if (this.props.dailyUsageStatus === loadingStatus.LOADING_STATUS_INIT && this.props.selectedBillAccount > 0){
            this.props.getDailyUsageForAccount(this.props.selectedBillAccount)
        }

        if (this.props.rateMetaDataStatus === loadingStatus.LOADING_STATUS_INIT
            && this.props.selectedBillAccount > 0)
                this.props.getRateMetaDataForAccount(this.props.selectedBillAccount)
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.dailyUsageStatus === loadingStatus.LOADING_STATUS_INIT
            && nextProps.selectedBillAccount > 0)
            {
               this.props.getDailyUsageForAccount(nextProps.selectedBillAccount)
            }
        if (nextProps.rateMetaDataStatus === loadingStatus.LOADING_STATUS_INIT && nextProps.selectedBillAccount > 0)
            this.props.getRateMetaDataForAccount(nextProps.selectedBillAccount)
    }

    shouldComponentUpdate(nextProps) {
        return this.props.dailyUsageStatus !== nextProps.dailyUsageStatus
            || this.props.rateMetaDataStatus !== nextProps.rateMetaDataStatus
    }

    render(){
        if(this.props.rateMetaDataStatus === loadingStatus.LOADING_STATUS_FAILURE
            || this.props.dailyUsageStatus === loadingStatus.LOADING_STATUS_FAILURE)
            return (<div>{this.props.t("failure")}</div>)
        if(this.props.rateMetaDataStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS
            || this.props.dailyUsageStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS
            || this.props.rateMetaDataStatus === loadingStatus.LOADING_STATUS_INIT
            || this.props.dailyUsageStatus === loadingStatus.LOADING_STATUS_INIT
        )
            return(<div className="srp-card-body">
            <div className="srp-card-details">
                <UsagePageLoading />
            </div>
        </div>)
        return(
        <div>
           <div className="srp-card-body">
                <div className="srp-card-details">
                    <div className="text-muted mt-2">
                        {
                            this.props.rateMetaData.hasOnPeakDemand && !this.props.rateMetaData.hasOnOffShoulder
                            ? <span>{this.props.t("On-peak demand values are taken from your current bill cycle.")}</span>
                            : this.props.isAverageDemandRate
                                ? <span>{this.props.t("On-peak average demand values are taken from your current bill cycle.")}</span>
                                : <span>{this.props.t("High demand values are taken from your current bill cycle.")}</span>
                        }
                    </div>
                    <div  style={{'border': '1px solid', 'borderRadius': '5px', padding: '12px', borderColor: '#E5EDF3', marginTop: '20px'}}>
                    <div>
                        <span className="srp-blue-text">
                            {this.props.rateMetaData.hasOnPeakDemand
                                ? <strong >{this.props.t("ON-PEAK")}</strong>
                                : <strong>{this.props.t("HIGH DEMAND")}</strong>
                            }
                        </span>
                    </div>
                    <div className="d-flex justify-content-between mt-2">
                        {this.props.isAverageDemandRate
                            ? <div className="srp-red-text">
                                <strong>
                                    {getHighDemandAmount(this.props.highDemandInfoList,
                                        this.props.rateMetaData.hasOnOffShoulder,
                                        this.props.rateMetaData.hasOnPeakDemand,
                                        this.props.isAverageDemandRate)} kW
                                </strong>
                            </div>
                            : <div style={{fontSize: '14px'}}>
                                <div className="srp-red-text">
                                    <strong>
                                        {getHighDemandAmount(this.props.highDemandInfoList,
                                            this.props.rateMetaData.hasOnOffShoulder,
                                            this.props.rateMetaData.hasOnPeakDemand,
                                            this.props.isAverageDemandRate)} kW
                                    </strong>
                                </div>
                                {getHighDemandDate(this.props.highDemandInfoList,
                                            this.props.rateMetaData.hasOnOffShoulder,
                                            this.props.rateMetaData.hasOnPeakDemand, 
                                            this.props.t)}

                            </div>
                        }
                        <button
                            className="btn srp-btn btn-lightblue text-white"
                            onClick={()=>{this.props.viewDailyDemandChartOnClick(this.props.highDemandInfoList[0], dailyUsageChartTypeConstants.DEMAND)}}>
                            {this.props.t("View")}
                        </button>
                    </div>
                    { this.props.rateMetaData.hasOnOffShoulder &&
                    <div style={{borderTop: '1px solid', borderColor: '#E5EDF3', margin: '20px -12px 0 -12px'}}>
                    <div style={{padding: '12px'}}>
                    <div className="srp-blue-text">
                        <strong>
                        {this.props.t("OFF-PEAK/SHOULDER")}
                        </strong>
                    </div>
                    <div className="d-flex justify-content-between mt-2">
                        {<div style={{fontSize: '14px'}}>
                                <div className="srp-light-blue-text">
                                    <strong>
                                       {this.props.highDemandInfoList[0] === undefined ? "n/a" : this.props.highDemandInfoList[0].offPeakShoulderHighDemand} kW
                                    </strong>
                                </div>
                                {getOffpeakShoulderDemandDate(this.props.highDemandInfoList)}
                            </div>
                        }
                        <button
                            className="btn srp-btn btn-lightblue text-white"
                            onClick={()=>{this.props.viewDailyDemandChartOnClick(this.props.highDemandInfoList[0], dailyUsageChartTypeConstants.DEMAND)}}>
                            {this.props.t("View")}
                        </button>
                    </div>
                    </div>
                    </div>
                    }
                    </div>
                </div>
            </div>
        </div>
        )
    }
}

PeakDemandCard.propTypes = {
    dailyUsageStatus: PropTypes.string,
    selectedBillAccount: PropTypes.number,
    getDailyUsageForAccount: PropTypes.func,
    rateMetaDataStatus: PropTypes.string,
    getRateMetaDataForAccount: PropTypes.func,
    rateMetaData: PropTypes.object,
    highDemandInfoList: PropTypes.array,
    isAverageDemandRate: PropTypes.bool,
    viewDailyDemandChartOnClick: PropTypes.func,
    t: PropTypes.func
}
const mapStateToProps = state =>{
    return{
        ...state.accountInfo.billAccount,
        ...state.dailyUsage,
        ...state.rateMetaData
    }
}

const mapDispatchToProps = dispatch => {
    return{
        getDailyUsageForAccount: (billAccount) => {
            dispatch(getDailyUsage(billAccount))
        },
        getRateMetaDataForAccount: (billAccount) => {
            dispatch(getRateMetaData(billAccount))
        },
        viewDailyDemandChartOnClick: (demandInfo, chartType) => {
            let billEndDate = DateTime.fromISO(demandInfo.billEndDate).setZone('MST')
            let startDate = DateTime.fromISO(demandInfo.billStartDate).setZone('MST')
            let endDate = billEndDate.hasSame(DateTime.now().setZone('MST'), 'day')
                ? billEndDate.minus({ days: 1 })
                : billEndDate

            dispatch(initDailyUsagePage(startDate, endDate, chartType))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PeakDemandCard)

function getHighDemandAmount(demandDetails,hasOnOffShoulder,hasOnPeakDemand, hasAverageDemand) {
    if (demandDetails.length > 0) {
        if ((hasOnOffShoulder || hasOnPeakDemand) && demandDetails[0].isCurrentBill) {
            return demandDetails[0].onPeakHighDemand
        }
        else if (hasAverageDemand){
            return demandDetails[0].averageDemand
        }
        else return demandDetails[0].highDemand
    }
    return 0
}

function getHighDemandDate(demandDetails,hasOnOffShoulder,hasOnPeakDemand, t) {
    if (demandDetails.length > 0) {
        return (
            (hasOnOffShoulder || hasOnPeakDemand) && demandDetails[0].isCurrentBill
                ? <p>{DateTime.fromISO(demandDetails[0].onPeakHighDemandDate).toFormat('MM/dd/yy')}
                    <br />{t("at") + " " + DateTime.fromISO(demandDetails[0].onPeakHighDemandDate).toFormat('h:mm a')}
                </p>
                : <p>{DateTime.fromISO(demandDetails[0].highDemandDate).toFormat('MM/dd/yy')}
                    <br />{t("at") + " " + DateTime.fromISO(demandDetails[0].highDemandDate).toFormat('h:mm a')}
                </p>
        )
    }
    return 0
}

function getOffpeakShoulderDemandDate(demandDetails) {
    if (demandDetails.length > 0) {
        return (<p>{DateTime.fromISO(demandDetails[0].offPeakShoulderHighDemandDate).toFormat('MM/dd/yy')}
        <br />{"at " + DateTime.fromISO(demandDetails[0].offPeakShoulderHighDemandDate).toFormat('h:mm a')}</p>)
    }
    return 0
}