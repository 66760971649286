import React from 'react'
import PropTypes from 'prop-types'
import { DateTime } from 'luxon'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableHead from '@mui/material/TableHead'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import TableFooter from '@mui/material/TableFooter'
import IconButton from '@mui/material/IconButton'
import Icon from '@mui/material/Icon'
import Divider from '@mui/material/Divider'
import SrpTooltip from '../../common_tooltip/srp-tooltip'

import '../../../styles/tables.css'

import NoPurchasesCopyDesktop from './no-purchases-copy-desktop'
import NoPurchasesCopyResponsive from './no-purchases-copy-responsive'
import { withTranslation } from 'react-i18next'

const PurchaseHistoryTable = ({ purchasesHistoryByMonth, grandTotals, isShowPurchaseHistoryDetails, showPurchaseDetails, numOfMonthsDisplayedStart,
    numOfMonthsDisplayedEnd, purchasHistoryIndex, getPrevPurchaseHistoryOnClick, getNextPurchaseHistoryOnClick, startDate, endDate,
    selectedBillAccount, serviceAddress, customerName, flattenedPurchaseHistoryDetails, amountDueToSRP, t }) => {

    const isEmptyPurchaseHistory = (flattenedPurchaseHistoryDetails.length === 0)
    
    return (
        <div>
            <div className="d-lg-block d-none srp-card-details pt-0 d-print-block srp-print" style={{ overflow: 'auto' }}>
                <div className="d-none d-print-block">
                    <h4 className="font-weight-bold" style={{ paddingTop: '20px' }}>{t("Purchase history")}</h4>
                    <div>
                        <span className="font-weight-bold">{"Name: "}</span>
                        <span>{customerName}</span>
                        <span className="font-weight-bold">{"\n\u2022 Account number: \n"}</span>
                        <span>{selectedBillAccount + "\n\u2022\n"}</span>
                    </div>
                    <div className="mb-3">
                        <span className="font-weight-bold">{"Service address: "}</span>
                        <span>{serviceAddress}</span>
                        <span className="font-weight-bold">{"\n\u2022 Date range: \n"}</span>
                        <span>{startDate} to {endDate}</span>
                    </div>
                    <div className="col-12 d-flex align-items-center justify-content-start mb-3 border border-dark rounded">
                        <span className="mr-3 p-2">{t("Amount due to SRP")}</span>
                        <span className="font-weight-bold p-2" style={{ fontSize: '1.25rem' }}>{amountDueToSRP < 0 ? "$0.00" : formatDollarAmount(amountDueToSRP)}</span>
                    </div>
                </div>
                <Table className="mb-3" id="purchase-history-table">
                    {!isEmptyPurchaseHistory && <TableHead className="srp-table-head">
                        <TableRow>
                            <TableCell padding="none" className="border-0 d-print-none" />
                            <TableCell padding="none" className="border-0">{t("Date")}</TableCell>
                            <TableCell padding="none" className="border-0">
                                <span className="mr-1">{t("Transaction")}</span>
                                <SrpTooltip
                                    content={<span>{t("Credit_tooltip")}</span>}>
                                    <Icon className="srp-icon-color d-print-none" style={{ fontSize: '0.875rem', verticalAlign: 'middle' }}>info_outline</Icon>
                                </SrpTooltip>
                            </TableCell>
                            <TableCell padding="none" className="border-0 text-right">{t("Transaction amount")}</TableCell>
                            <TableCell padding="none" className="border-0 text-right">{t("(PLUS) Adjustments")}</TableCell>
                            <TableCell padding="none" className="border-0 text-right">{t("(LESS) Amount due to SRP")}</TableCell>
                            <TableCell padding="none" className="border-0 text-right">{t("(EQUALS) Power purchase")}</TableCell>
                        </TableRow>
                    </TableHead>}
                    <TableBody className="srp-table-body">
                        {purchasesHistoryByMonth.slice(numOfMonthsDisplayedStart, numOfMonthsDisplayedEnd).map((row, index) => {
                            let tableRow = []
                            tableRow.push(
                                <TableRow key={"purchaseHistory" + index}>
                                    <TableCell padding="none" className={isShowPurchaseHistoryDetails[index] ? "text-center border-0 d-print-none" : "text-center d-print-none"} style={{ width: '36px' }}>
                                        <IconButton
                                            color="primary"
                                            onClick={() => showPurchaseDetails(index)}
                                            className="button-focus"
                                            style={{ textDecoration: 'none' }}
                                            size="large">
                                            <Icon>
                                                {isShowPurchaseHistoryDetails[index] ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
                                            </Icon>
                                        </IconButton>
                                    </TableCell >
                                    <TableCell padding="none" className="font-weight-bold">{DateTime.now().set({ month: row.month }).toFormat('MMMM') + " " + row.year}</TableCell>
                                    <TableCell padding="none" className="font-weight-bold">
                                        {row.purchases.length <= 1
                                            ? row.purchases.length + " " + t("transaction")
                                            : row.purchases.length + " " + t("transactions")}
                                    </TableCell>
                                    <TableCell padding="none" className="font-weight-bold text-right">{formatDollarAmount(row.totalTransAmount)}</TableCell>
                                    <TableCell padding="none" className="font-weight-bold text-right">{formatDollarAmount(row.totalAdjustments)}</TableCell>
                                    <TableCell padding="none" className="font-weight-bold text-right">{formatDollarAmount(row.totalAmountDue)}</TableCell>
                                    <TableCell padding="none" className="font-weight-bold text-right">{formatDollarAmount(row.totalPowerPurchase)}</TableCell>
                                </TableRow>
                            )
                            for (let i = 0; i < row.purchases.length; i++) {
                                tableRow.push(
                                    <TableRow key={"purchaseHistoryDetails" + i + row.purchases[i].purchaseDate} style={isShowPurchaseHistoryDetails[index] ? { display: 'table-row' } : { display: 'none' }}>
                                        <TableCell padding="none" className={i != row.purchases.length - 1 ? "border-0 d-print-none" : "text-center d-print-none"} style={{ width: '36px' }} />
                                        <TableCell padding="none">{DateTime.fromISO(row.purchases[i].purchaseDate).toFormat('MMM d, yyyy')}</TableCell>
                                        <TableCell padding="none" style={{ textTransform: 'capitalize' }}>{t((row.purchases[i].transactionDescription).toLowerCase())}</TableCell>
                                        <TableCell padding="none" className="text-right">{formatDollarAmount(row.purchases[i].paidAmount)}</TableCell>
                                        <TableCell padding="none" className="text-right" style={row.purchases[i].adjustments < 0 ? { color: '#9E2A2B' } : {}}>
                                            {row.purchases[i].adjustments === 0 ? "- -" : formatDollarAmount(row.purchases[i].adjustments)}
                                        </TableCell>
                                        <TableCell padding="none" className="text-right" style={row.purchases[i].amountToArrears < 0 ? { color: '#9E2A2B' } : {}}>
                                            {row.purchases[i].amountToArrears === 0 ? "- -" : formatDollarAmount(row.purchases[i].amountToArrears)}
                                        </TableCell>
                                        <TableCell padding="none" className="text-right">{formatDollarAmount(row.purchases[i].powerCredit)}</TableCell>
                                    </TableRow>
                                )
                            }

                            return tableRow
                        })}
                    </TableBody>
                    {!isEmptyPurchaseHistory &&
                    <TableFooter className="srp-table-footer">
                        <TableRow style={{ borderTop: '2px' }}>
                            <TableCell padding="none" className="d-print-none" />
                            <TableCell padding="none">{t("Total")}</TableCell>
                            <TableCell padding="none" />
                            <TableCell padding="none" className="text-right">{formatDollarAmount(grandTotals.transAmount)}</TableCell>
                            <TableCell padding="none" className="text-right">{formatDollarAmount(grandTotals.adjustments)}</TableCell>
                            <TableCell padding="none" className="text-right">{formatDollarAmount(grandTotals.amountDue)}</TableCell>
                            <TableCell padding="none" className="text-right">{formatDollarAmount(grandTotals.powerPurchase)}</TableCell>
                        </TableRow>
                    </TableFooter>
                    }
                </Table>

                {!isEmptyPurchaseHistory &&
                <div className="d-none d-lg-block d-print-none">
                    <div className="d-flex justify-content-end">
                        <button className="btn srp-btn btn-lightblue" onClick={window.print}>{t("Print purchase history")}</button>
                    </div>
                </div>
                }

                {isEmptyPurchaseHistory &&
                <NoPurchasesCopyDesktop t={t}/>
                }
            </div>

            <div className="d-lg-none d-block d-print-none">
                <Divider />
                {isEmptyPurchaseHistory ?
                    <NoPurchasesCopyResponsive t={t}/>
                    :
                    <div className="srp-card-details mt-2">
                        <div className="row d-flex align-items-center">
                            <div className="col text-muted align-middle" style={{ fontSize: '0.875rem' }}>
                                {t("Date")}
                        </div>
                            <div className="col text-right">
                                {flattenedPurchaseHistoryDetails[purchasHistoryIndex].date}
                            </div>
                        </div>
                        <hr />
                        <div className="row d-flex align-items-center">
                            <div className="col text-muted align-middle">
                                <span className="mr-1" style={{ fontSize: '0.875rem' }}>{t("Transaction")}</span>
                                <SrpTooltip content={<span>{t("Credit_tooltip")}</span>}>
                                    <Icon className="srp-icon-color" style={{ fontSize: '0.875rem', verticalAlign: 'middle' }}>info_outline</Icon>
                                </SrpTooltip>
                            </div>
                            <div className="col text-right" style={{ textTransform: 'capitalize' }}>
                                {(flattenedPurchaseHistoryDetails[purchasHistoryIndex].transactionName).toLowerCase()}
                            </div>
                        </div>
                        <hr />
                        <div className="row d-flex align-items-center">
                            <div className="col text-muted align-middle" style={{ fontSize: '0.875rem' }}>
                                {t("Transaction amount")}
                        </div>
                            <div className="col text-right">
                                {formatDollarAmount(flattenedPurchaseHistoryDetails[purchasHistoryIndex].transactionAmount)}
                            </div>
                        </div>
                        <hr />
                        <div className="row d-flex align-items-center">
                            <div className="col-7 text-muted align-middle" style={{ fontSize: '0.875rem' }}>
                                {t("(PLUS) Adjustments")}
                            </div>
                            <div className="col-5 text-right">
                                {formatDollarAmount(flattenedPurchaseHistoryDetails[purchasHistoryIndex].adjustment)}
                            </div>
                        </div>
                        <hr />
                        <div className="row d-flex align-items-center">
                            <div className="col-8 text-muted align-middle" style={{ fontSize: '0.875rem' }}>
                                {t("(LESS) Amount due to SRP")}
                        </div>
                            <div className="col-4 text-right">
                                {formatDollarAmount(flattenedPurchaseHistoryDetails[purchasHistoryIndex].amountDue)}
                            </div>
                        </div>
                        <hr />
                        <div className="row d-flex align-items-center">
                            <div className="col-8 text-muted align-middle" style={{ fontSize: '0.875rem' }}>
                                {t("(EQUALS) Power purchase")}
                        </div>
                            <div className="col-4 text-right">
                                {formatDollarAmount(flattenedPurchaseHistoryDetails[purchasHistoryIndex].powerPurchase)}
                            </div>
                        </div>
                        <hr className="mb-2" style={{ border: '2px solid lightgray' }} />
                        <button className="displayAsLink" onClick={window.print}>{t("Print purchase history")}</button>
                        <div className="d-flex justify-content-between mt-2">
                            <button
                                onClick={() => getPrevPurchaseHistoryOnClick()} disabled={purchasHistoryIndex === 0}
                                className="btn srp-btn btn-lightblue d-flex align-items-center">
                                <i className="material-icons">{'keyboard_arrow_left'}</i>
                            </button>
                            <button
                                onClick={() => getNextPurchaseHistoryOnClick(flattenedPurchaseHistoryDetails.length)} disabled={purchasHistoryIndex === (flattenedPurchaseHistoryDetails.length - 1)}
                                className="btn srp-btn btn-lightblue d-flex align-items-center">
                                <i className="material-icons">{'keyboard_arrow_right'}</i>
                            </button>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}

PurchaseHistoryTable.propTypes = {
    purchasesHistoryByMonth: PropTypes.arrayOf(PropTypes.object),
    flattenedPurchaseHistoryDetails: PropTypes.arrayOf(PropTypes.object),
    grandTotals: PropTypes.object,
    isShowPurchaseHistoryDetails: PropTypes.arrayOf(PropTypes.bool),
    showPurchaseDetails: PropTypes.func,
    numOfMonthsDisplayedStart: PropTypes.number,
    numOfMonthsDisplayedEnd: PropTypes.number,
    purchasHistoryIndex: PropTypes.number,
    getPrevPurchaseHistoryOnClick: PropTypes.func,
    getNextPurchaseHistoryOnClick: PropTypes.func,

    startDate: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
    ]),
    endDate: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
    ]),
    selectedBillAccount: PropTypes.number,
    serviceAddress: PropTypes.string,
    customerName: PropTypes.string,
    amountDueToSRP: PropTypes.number,
    t: PropTypes.func.isRequired
}

export default withTranslation("purchaseHistoryTable")(PurchaseHistoryTable)

function formatDollarAmount(number) {
    let formattedDollarAmount
    if (number < 0) {
        let posNum = number * (-1)
        formattedDollarAmount = "-$" + ((posNum).toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    }
    else if (number === 0) {
        formattedDollarAmount = "$0.00"
    }
    else {
        formattedDollarAmount = "$" + ((number).toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    }

    return formattedDollarAmount
}