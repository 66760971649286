import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { DateTime } from 'luxon'

import AccountHistoryCard from './account-history-card'
import BillPageLoading from '../bill-page-loading'
import BillPageError from '../bill-page-error'

import { getNumOfBillsToDisplay,
            getAccountHistoryType,
            toggleDetailedTransactionList,
            getPrevAccountHistory,
            getNextAccountHistory } from '../../../actions/auth/bill_page/account-history-table-actions'
import { getPaymentHistory } from '../../../actions/auth/payment/payment-actions'
import * as loadingStatus from '../../../constants/loading-status-constants'
import { paymentConstants } from '../../../constants/payment-constants'

class AccountHistoryCardContainer extends React.Component{
    constructor(props) {
        super(props);

        this.refreshAccountHistoryCard = this.refreshAccountHistoryCard.bind(this)
    }

    componentDidMount() {
        if(this.props.selectedBillAccount === 0)
            return

        if(this.props.paymentHistoryStatus === loadingStatus.LOADING_STATUS_INIT) {
            this.props.getPaymentHistory(this.props.selectedBillAccount)
        }

    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        // TODO: For now, only update outage information on bill account change
        if(this.props.selectedBillAccount === nextProps.selectedBillAccount || nextProps.selectedBillAccount === 0)
            return

        if(nextProps.paymentHistoryStatus !== loadingStatus.LOADING_STATUS_IN_PROGRESS) {
            this.props.getPaymentHistory(nextProps.selectedBillAccount)
        }
    }

    refreshAccountHistoryCard() {
        if(this.props.accountHistoryStatus === loadingStatus.LOADING_STATUS_FAILURE)
            this.props.getAccountHistory(this.props.selectedBillAccount)

        if(this.props.paymentHistoryStatus === loadingStatus.LOADING_STATUS_FAILURE)
            this.props.getPaymentHistory(this.props.selectedBillAccount)

        if(this.props.eBillStatus === loadingStatus.LOADING_STATUS_FAILURE)
            this.props.getEBillListInfo(this.props.selectedBillAccount)
    }

    render() {
        let renderedCard = {}
        if(this.props.accountHistoryStatus === loadingStatus.LOADING_STATUS_INIT || this.props.accountHistoryStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS
            || this.props.paymentHistoryStatus === loadingStatus.LOADING_STATUS_INIT || this.props.paymentHistoryStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS
            || this.props.eBillStatus === loadingStatus.LOADING_STATUS_INIT || this.props.eBillStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS
            || this.props.currentRateCodeStatus === loadingStatus.LOADING_STATUS_INIT || this.props.currentRateCodeStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS)
        {
            renderedCard = <BillPageLoading/>
        }
        else if(this.props.accountHistoryStatus === loadingStatus.LOADING_STATUS_SUCCESS
                && this.props.paymentHistoryStatus === loadingStatus.LOADING_STATUS_SUCCESS
                && this.props.currentRateCodeStatus === loadingStatus.LOADING_STATUS_SUCCESS)
        {
            renderedCard = (<AccountHistoryCard
                numOfBills={this.props.numOfBills}
                numOfBillsDisplayed={this.props.numOfBillsDisplayed}
                getNumOfBillsToDisplayOnChange={this.props.getNumOfBillsToDisplayOnChange}
                historyType={this.props.historyType}
                getAccountHistoryTypeOnChange={this.props.getAccountHistoryTypeOnChange}
                showDetailedTransactionList={this.props.showDetailedTransactionList}
                toggleDetailedTransactionList={this.props.toggleDetailedTransactionList}
                billTableStyle={this.props.billTableStyle}
                usageTableStyle={this.props.usageTableStyle}
                getPrevAccountHistoryOnClick={this.props.getPrevAccountHistoryOnClick}
                getNextAccountHistoryOnClick={this.props.getNextAccountHistoryOnClick}
                accountHistoryIndex={this.props.accountHistoryIndex}
                accountHistoryList={this.props.accountHistoryList}
                eBillDocumentList={this.props.eBillDocumentList}
                selectedBillAccount={this.props.selectedBillAccount}
                billingAndPaymentHistoryList={getBillingPaymentHistoryList(this.props.accountHistoryList, this.props.paymentHistory)}
                isResidentialIDR={this.props.currentRateCode.rateCode === 35 || this.props.currentRateCode.rateCode === 36} 
                t={this.props.t}
            />)
        }
        else
        {
            renderedCard = <BillPageError dataThatFailed="Account history" refreshData={this.refreshAccountHistoryCard}/>
        }
        return renderedCard
    }
}

const mapStateToProps = state => {
    return { ...state.accountHistoryTable, ...state.accountHistory, ...state.accountInfo.billAccount, ...state.accountInfo.payment, ...state.eBill }
}

const mapDispatchToProps = dispatch => {
    return {
        getNumOfBillsToDisplayOnChange: (numOfBills, lengthOfList) => {
            dispatch(getNumOfBillsToDisplay(numOfBills, lengthOfList))
        },
        getAccountHistoryTypeOnChange: (historyType) => {
            dispatch(getAccountHistoryType(historyType))
        },
        toggleDetailedTransactionList: () => {
            dispatch(toggleDetailedTransactionList())
        },
        getPrevAccountHistoryOnClick: () => {
            dispatch(getPrevAccountHistory())
        },
        getNextAccountHistoryOnClick: (lengthOfList) => {
            dispatch(getNextAccountHistory(lengthOfList))
        },
        getPaymentHistory: (billAccount) => {
            dispatch(getPaymentHistory(billAccount))
        }
    }
}

AccountHistoryCardContainer.propTypes = {
    selectedBillAccount: PropTypes.number,

    numOfBills: PropTypes.number,
    numOfBillsDisplayed: PropTypes.number,
    getNumOfBillsToDisplayOnChange: PropTypes.func,
    historyType: PropTypes.string,
    getAccountHistoryTypeOnChange: PropTypes.func,
    showDetailedTransactionList: PropTypes.bool,
    toggleDetailedTransactionList: PropTypes.func,
    billTableStyle: PropTypes.object,
    usageTableStyle: PropTypes.object,
    getPrevAccountHistoryOnClick: PropTypes.func,
    getNextAccountHistoryOnClick: PropTypes.func,
    accountHistoryIndex: PropTypes.number,

    accountHistoryList: PropTypes.arrayOf(PropTypes.object),
    accountHistoryStatus: PropTypes.string,
    getAccountHistory: PropTypes.func,

    paymentHistory: PropTypes.arrayOf(PropTypes.object),
    paymentHistoryStatus: PropTypes.string,
    getPaymentHistory: PropTypes.func,

    eBillDocumentList: PropTypes.arrayOf(PropTypes.object),
    eBillStatus: PropTypes.string,
    getEBillListInfo: PropTypes.func,

    currentRateCodeStatus: PropTypes.string,
    currentRateCode: PropTypes.object,
    t: PropTypes.func.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountHistoryCardContainer)

function getBillingPaymentHistoryList(accountHistory, paymentHistory) {
    let newHistoryList = []
    for(let i = 0; i < accountHistory.length; i++){
        let amountPaid = 0
        let paymentDate = ""
        let transactionItemsList = []
        for(let j = 0; j < paymentHistory.length; j++) {
            if(DateTime.fromISO(paymentHistory[j].transactionReceivedDate) >= DateTime.fromISO(accountHistory[i].billGeneratedDate)
                && (i === 0 || DateTime.fromISO(paymentHistory[j].transactionReceivedDate) < DateTime.fromISO(accountHistory[i-1].billGeneratedDate)) ) {

                if(paymentHistory[j].transactionType === paymentConstants.TRANSACTION_TYPE_PAYMENT) {
                    amountPaid += (paymentHistory[j].paymentAmount*(-1))
                    if(paymentDate.length === 0)
                        paymentDate = paymentHistory[j].transactionReceivedDate
                }

                transactionItemsList.push(
                    {
                        selbo: paymentHistory[j].selbo,
                        revenueYear: paymentHistory[j].revenueYear,
                        revenueMonth: paymentHistory[j].revenueMonth,
                        transactionReceivedDate: paymentHistory[j].transactionReceivedDate,
                        transSeqNumber: paymentHistory[j].transSeqNumber,
                        paymentAmount: paymentHistory[j].transactionType === paymentConstants.TRANSACTION_TYPE_FEE ? Math.abs(paymentHistory[j].paymentAmount)*(-1) : Math.abs(paymentHistory[j].paymentAmount),
                        transCode: paymentHistory[j].transCode,
                        transactionType: paymentHistory[j].transactionType
                    }
                )
            }
        }

        let newListItem = {
            billDate: accountHistory[i].billGeneratedDate,
            balanceForward: accountHistory[i].balanceForward,
            newCharges: accountHistory[i].totalUsageCharges + accountHistory[i].nonEnergyChargeAmount,
            amountPaid: amountPaid,
            paymentDate: paymentDate,
            transactionItems: transactionItemsList
        }
        newHistoryList.push(newListItem)
    }

    return newHistoryList
}