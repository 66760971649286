import { connect } from 'react-redux'

import { withRouter } from '../../../srp_modules/with-router'

import SinglePurchaseFormik from './single-purchase-formik'

import { myAccountConstants } from '../../../constants/myaccount-constants'

import {
    addBankAccount,
    setBankIdForSinglePurchase,
    storeBankInfoCollected,
    addPurchaseToList,
    clearPurchaseList,
    verifyPurchaseList,
    setFormStartEventEmitted,
    setBankFormOpen,
    clearBankFormOpen
} from '../../../actions/auth/payment/single-purchase-actions'

const mapStateToProps = state => {
    const phoneNumber = state.accountInfo.billAccount.phoneNumber
    const loginEmail = state.login.loginEmail
    const primaryEmail = state.accountInfo.contact.primaryEmail

    return {
        ...state.singlePurchaseBankInfo, ...state.singlePurchaseList, bankAccountList: state.bankAccounts.bankAccountList,
        bankFirstName: state.singlePurchaseBankInfo.bankFirstName,
        bankLastName: state.singlePurchaseBankInfo.bankLastName,
        bankPhoneNumber: state.singlePurchaseBankInfo.bankPhoneNumber || (phoneNumber && phoneNumber.bestPhone || ''),
        bankEmailAddress: state.singlePurchaseBankInfo.bankEmailAddress || primaryEmail || loginEmail || '',
        primaryEmailStatus: state.accountInfo.contact.primaryEmailStatus,
        primaryEmail,
        payDownFraction: state.accountInfo.payment.payment.payDownPercent,
        amountOwedToSRP: state.accountInfo.payment.payment.amountDue
    }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
    storeBankInfoAndPurchase: (purchaseAndBankValues) => {
        dispatch(storeBankInfoCollected(purchaseAndBankValues))
        dispatch(setBankIdForSinglePurchase({ selectedBankId: purchaseAndBankValues.bankAccountIdSelected }))
        dispatch(clearPurchaseList())
        dispatch(addPurchaseToList(purchaseAndBankValues))
    },
    addBank: async (goodies, validationSchema, bankValues) => {
        let response = await dispatch(addBankAccount())
        goodies.setSubmitting(false)
        if (response.error === true) {
            goodies.setStatus({ hasUnhandledeChexValidationError: true })
            return false
        }

        if (response.payload.isSuccess === false) {
            const bankInputValues = {
                bankFirstName: bankValues.bankFirstName,
                bankLastName: bankValues.bankLastName,
                bankRoutingNumber: bankValues.bankRoutingNumber,
                bankAccountNumber: bankValues.bankAccountNumberLeadingZeroesAreSignificant
            }

            const eChexAddBankAccountErrors = response.payload.saveBankAccountErrors
            const numErrorsHandled = createValidationRulesFromeChexBankInfoValidationErrors(validationSchema.fields, eChexAddBankAccountErrors, bankInputValues)
            const hasUnhandledeChexValidationError = (Object.keys(eChexAddBankAccountErrors).length - numErrorsHandled) > 0
            goodies.setStatus({ hasUnhandledeChexValidationError })
            goodies.validateForm()

            if (eChexAddBankAccountErrors.errorBankAccountSetupLocked)
                ownProps.router.navigate('/myaccount/profile/banks/locked')

            return false
        }

        const savedBankAccount = response.payload.savedBankAccount
        if (savedBankAccount.bankAccountId === 0) {
            goodies.setStatus({ hasUnhandledeChexValidationError: true })
            return false
        }

        dispatch(setBankIdForSinglePurchase({ selectedBankId: savedBankAccount.bankAccountId }))
        return true
    },
    validatePurchase: async (goodies, validationSchema, purchaseAndBankValues, t, i18n) => {
        let response = await dispatch(verifyPurchaseList())
        goodies.setSubmitting(false)

        if (response.error === true)
            goodies.setStatus({ hasUnhandledeChexValidationError: true })
        else {
            const referenceNumber = response.payload.referenceNumberWhenNoError
            const eChexValidationErrors = response.payload.eChexValidationErrors
            const bankInputValues = {
                bankFirstName: purchaseAndBankValues.bankFirstName,
                bankLastName: purchaseAndBankValues.bankLastName,
                bankRoutingNumber: purchaseAndBankValues.bankRoutingNumber,
                bankAccountNumber: purchaseAndBankValues.bankAccountNumberLeadingZeroesAreSignificant
            }
            if (referenceNumber !== 0 && (Object.keys(eChexValidationErrors).length === 0 && eChexValidationErrors.constructor === Object))
                ownProps.router.navigate('/myaccount/payment/single/mpower/confirmation')
            else {
                let numErrorsHandled = createValidationRulesFromeChexBankInfoValidationErrors(validationSchema.fields, eChexValidationErrors, bankInputValues)
                numErrorsHandled += createValidationRulesFromeChexSRPAccountNumberAndPurchaseAmountValidationErrors(validationSchema.fields, eChexValidationErrors, t, i18n)
                const hasUnhandledeChexValidationError = (Object.keys(eChexValidationErrors).length - numErrorsHandled) > 0 && eChexValidationErrors.constructor === Object
                goodies.setStatus({ hasUnhandledeChexValidationError })
                goodies.validateForm()
            }
        }
    },
    setFormStartEventEmitted: () => {
        dispatch(setFormStartEventEmitted())
    },
    setBankFormOpen: () => {
        dispatch(setBankFormOpen())
    },
    clearBankFormOpen: () => {
        dispatch(clearBankFormOpen())
    }
})

// export for unit testing
export function createValidationRulesFromeChexBankInfoValidationErrors(validationRules, eChexValidationErrors, bankInputValues) {
    let numErrorsHandled = 0
    const earlyWarningNs = 'earlyWarning'

    for (let errorCode in eChexValidationErrors)
        switch (errorCode) {
            case 'errorInvalidCharacters': {
                const badBankFirstName = bankInputValues.bankFirstName
                const badBankLastName = bankInputValues.bankLastName

                validationRules.bankFirstName = validationRules.bankFirstName.test(errorCode,
                    earlyWarningNs + '::' + errorCode,
                    function (bankFirstName) {
                        const { bankLastName } = this.parent
                        return bankFirstName !== badBankFirstName
                            || bankLastName !== badBankLastName
                    })

                validationRules.bankLastName = validationRules.bankLastName.test(errorCode,
                    earlyWarningNs + '::' + errorCode,
                    function (bankLastName) {
                        const { bankFirstName } = this.parent
                        return bankFirstName !== badBankFirstName
                            || bankLastName !== badBankLastName
                    })

                ++numErrorsHandled
            }
                break

            case 'errorBankAccountInfoMismatch': {
                const badBankFirstName = bankInputValues.bankFirstName
                const badBankLastName = bankInputValues.bankLastName
                const pairedBankRoutingNumber = bankInputValues.bankRoutingNumber
                const pairedBankAccountNumber = bankInputValues.bankAccountNumber

                validationRules.bankFirstName = validationRules.bankFirstName.test(errorCode,
                    earlyWarningNs + '::' + errorCode,
                    function (bankFirstName) {
                        const { bankLastName, bankRoutingNumber, bankAccountNumberLeadingZeroesAreSignificant } = this.parent
                        return bankFirstName !== badBankFirstName
                            || bankLastName !== badBankLastName
                            || bankRoutingNumber !== pairedBankRoutingNumber
                            || bankAccountNumberLeadingZeroesAreSignificant !== pairedBankAccountNumber
                    })

                validationRules.bankLastName = validationRules.bankLastName.test(errorCode,
                    earlyWarningNs + '::' + errorCode,
                    function (bankLastName) {
                        const { bankFirstName, bankRoutingNumber, bankAccountNumberLeadingZeroesAreSignificant } = this.parent
                        return bankFirstName !== badBankFirstName
                            || bankLastName !== badBankLastName
                            || bankRoutingNumber !== pairedBankRoutingNumber
                            || bankAccountNumberLeadingZeroesAreSignificant !== pairedBankAccountNumber
                    })

                ++numErrorsHandled
            }
                break
            case 'errorRoutingNumberInvalid': {
                const badBankRoutingNumber = bankInputValues.bankRoutingNumber
                validationRules.bankRoutingNumber = validationRules.bankRoutingNumber.test(errorCode,
                    earlyWarningNs + '::' + errorCode,
                    bankRoutingNumber => bankRoutingNumber !== badBankRoutingNumber)
                ++numErrorsHandled
                }
                break
            case 'errorPaymentTypeInvalid':
            case 'errorBankAccountInfoInvalid': {
                const badBankAccountNumber = bankInputValues.bankAccountNumber
                const pairedBankRoutingNumber = bankInputValues.bankRoutingNumber
                validationRules.bankAccountNumberLeadingZeroesAreSignificant = validationRules.bankAccountNumberLeadingZeroesAreSignificant.test(errorCode,
                    earlyWarningNs + '::' + errorCode,
                    function (bankAccountNumber) {
                        const { bankRoutingNumber } = this.parent
                        return bankAccountNumber !== badBankAccountNumber
                            || bankRoutingNumber !== pairedBankRoutingNumber
                    })
                ++numErrorsHandled
                }
                break
        }

    return numErrorsHandled
}

// export for unit testing
export function createValidationRulesFromeChexSRPAccountNumberAndPurchaseAmountValidationErrors(validationRules, eChexValidationErrors, t, i18n) {
    let numErrorsHandled = 0

    for (let errorCode in eChexValidationErrors)
        switch (errorCode) {
            case 'errLockedBankAcct':
                validationRules.bankAccountIdSelected = validationRules.bankAccountIdSelected.test(errorCode,
                    {
                        getLocalizedErrMsg: () => t("There_is_an_issue_with_a_recent_eChex_payment", { supportNumber: i18n.language === "es" ? myAccountConstants.SPANISH_CUSTOMER_SERVICE_PHONE_NUMBER_FMTTD : myAccountConstants.RESIDENTIAL_CUSTOMER_SERVICE_PHONE_NUMBER_FMTTD })
                    },
                    bankAccountIdSelected => bankAccountIdSelected !== eChexValidationErrors[errorCode].bankAccountId)
                ++numErrorsHandled
                break
            case 'errExceedHardLimit': {
                const errorInfo = eChexValidationErrors[errorCode]
                validationRules.purchaseAmount = validationRules.purchaseAmount.test(errorCode,
                    {
                        getLocalizedErrMsg: () => t("Payments_over_not_allowed", { amount: errorInfo.maxPaymentAmountAllowed.toLocaleString('EN-US',{ style: 'currency', currency: 'USD' })})
                    },
                    function (purchaseAmount) {
                        const { srpAccountNumber } = this.parent
                        if (typeof purchaseAmount === "string")
                            purchaseAmount = parseFloat(purchaseAmount.replace(/[$,]/g, ''))
                        return purchaseAmount <= errorInfo.maxPaymentAmountAllowed || +srpAccountNumber !== +errorInfo.srpAccountNumber
                    })
            }
                ++numErrorsHandled
                break
        }

    return numErrorsHandled
}

const SinglePurchaseFormikConnectedContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(SinglePurchaseFormik))
export default SinglePurchaseFormikConnectedContainer