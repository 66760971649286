export const accountHistoryConstants = {
    GET_ACCOUNT_HISTORY_REQUEST: 'GET_ACCOUNT_HISTORY_REQUEST',
    GET_ACCOUNT_HISTORY_SUCCESS: 'GET_ACCOUNT_HISTORY_SUCCESS',
    GET_ACCOUNT_HISRORY_FAILURE: 'GET_ACCOUNT_HISTORY_FAILURE',

    EXCEL_EXPORT_TYPE: {
        UNKNOWN: 0,
        BILLING_DETAIL: 1,
        USAGE: 2,
        BILLING_SUMMARY: 3
    }
}