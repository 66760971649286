import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import TextField from '@mui/material/TextField'
import formikInputFilter from '../../../srp_modules/formik-input-filter'
import AddressWithAutoComplete from '../../address-with-autocomplete'


class MailingAddress extends Component {
    constructor(props) {
        super(props)

        this.state = {
            hasAddress1Error: false
            , hasCityError: false
            , hasStateError: false
            , hasZipError: false
            , zipErrorMessage: ""
        }

        this.autocompleteMailingAddress = this.autocompleteMailingAddress.bind(this)
    }
    componentDidUpdate(prevProps) {
        if(!this.props.isMailingAddressSame){
            if (this.props.shouldRunValidation !== prevProps.shouldRunValidation) {
                this.validateRequiredField(this.props.mailingAddress1, "hasAddress1Error")
                this.validateRequiredField(this.props.mailingCity, "hasCityError")
                this.validateRequiredField(this.props.mailingState, "hasStateError")
                this.validateRequiredField(this.props.mailingZipCode, "hasZipError")
            }
        }
    }

    validateRequiredField(fieldValue, errorStateValue) {
        this.setState({ [errorStateValue]: fieldValue.length === 0 })
    }

    validateState(state) {
        if (state.match(formikInputFilter.regex.alphaRegex))
            this.props.mailingStateChange(state)
    }

    validateZipCode(zipCode) {
        if (zipCode.match(formikInputFilter.regex.streetAddressRegex))
            this.props.mailingZipChange(zipCode)
    }

    mailingInputValid(newValue) {
        if (newValue.match(formikInputFilter.regex.streetAddressRegex)) {
            return true
        }

        return false
    }    

    autocompleteMailingAddress(addressComponents){
        let streetAddress = `${addressComponents.streetNumber} ${addressComponents.street}`
        if (this.mailingInputValid(streetAddress)) {
            this.props.mailingAddress1Change(streetAddress.replace(/ {1,}/g,' '))
        }
        if (this.mailingInputValid(addressComponents.city)) {
            this.props.mailingCityChange(addressComponents.city.replace(/ {1,}/g,' '))
        }
        this.validateState(addressComponents.state)
        if (this.mailingInputValid(addressComponents.country)) {
            this.props.mailingCountryChange(addressComponents.country.replace(/ {1,}/g,' '))
        }
        this.validateZipCode(addressComponents.zipCode)
    }

    render() {
        return (
            <div style={!this.props.isMailingAddressSame ? {} : { display: 'none' }}>
                <label className="h4 text-muted">{this.props.t("mailing_address")}</label>
                <div className="pt-3 pb-4">
                    <div className="">
                        <AddressWithAutoComplete
                            fieldName="MailingAddress1"
                            fieldValue={this.props.mailingAddress1}
                            handleAddressChange={e => { 
                                if (this.mailingInputValid(e.target.value)) {
                                    this.props.mailingAddress1Change(e.target.value.replace(/ {1,}/g,' '))
                                }
                            }}
                            handleAddressBlur={e => {
                                if (!this.props.isMailingAddressSame) {
                                    this.validateRequiredField(e.target.value, "hasAddress1Error")
                                }
                            }}
                            errorText={this.state.hasAddress1Error ? this.props.t("address_is_required") : ""}
                            hasError={this.state.hasAddress1Error}
                            updateAddressCallback={this.autocompleteMailingAddress}
                            isDomesticAddress={false} 
                            label={this.props.t("Street address")}
                        />

                        <TextField id="MailingAddress2"
                            fullWidth={true}
                            value={this.props.mailingAddress2}
                            inputProps={{ maxLength: 39 }}
                            className="mb-3"
                            onChange={e => { 
                                if (this.mailingInputValid(e.target.value)) {
                                    this.props.mailingAddress2Change(e.target.value.replace(/ {1,}/g,' '))
                                }
                            }}
                            label={this.props.t("Apt, suite, etc (optional)")}
                        />
                    </div>
                    {/* <InputLabel htmlFor="mailingcity">{this.props.t("city")}</InputLabel> */}
                    <div className="">
                        <TextField id="mailingcity"
                            fullWidth={true}
                            value={this.props.mailingCity}
                            onChange={e => {
                                if (this.mailingInputValid(e.target.value)) {
                                    this.props.mailingCityChange(e.target.value.replace(/ {1,}/g,' '))
                                }
                            }}
                            error={this.state.hasCityError}
                            inputProps={{ maxLength: 30 }}
                            className="mb-3"
                            helperText={this.state.hasCityError ? this.props.t("city_required") : ""}
                            onBlur={e => {
                                if (!this.props.isMailingAddressSame) {
                                    this.validateRequiredField(e.target.value, "hasCityError")
                                }
                            }}
                            label={this.props.t("city")}
                        />
                    </div>
                    {/* <InputLabel htmlFor="state">{this.props.t("state")}</InputLabel> */}
                    <div className="">
                        <TextField id="state"
                            fullWidth={true}
                            value={this.props.mailingState}
                            onChange={e => this.validateState(e.target.value)}
                            error={this.state.hasStateError}
                            inputProps={{ maxLength: 2 }}
                            className="mb-3"
                            helperText={this.state.hasStateError ? this.props.t("state_required") : ""}
                            onBlur={e => {
                                if (!this.props.isMailingAddressSame) {
                                    this.validateRequiredField(e.target.value, "hasStateError")
                                }
                            }}
                            label={this.props.t("state")}
                        />
                    </div>
                    {/* <InputLabel htmlFor="country">{this.props.t("country")}</InputLabel> */}
                    <div className="">
                        <TextField id="country"
                            fullWidth={true}
                            value={this.props.mailingCountry}
                            onChange={e => {
                                if (this.mailingInputValid(e.target.value)) {
                                    this.props.mailingCountryChange(e.target.value.replace(/ {1,}/g,' '))
                                }
                            }}
                            inputProps={{ maxLength: 25 }}
                            className="mb-3"
                            label={this.props.t("country")}
                        />
                    </div>
                    {/* <InputLabel htmlFor="zipCode">{this.props.t("zip_code")}</InputLabel> */}
                    <div className="">
                        <TextField
                            id="zipCode"
                            value={this.props.mailingZipCode}
                            fullWidth={true}
                            inputProps={{ maxLength: 9 }}
                            onChange={e => this.validateZipCode(e.target.value)}
                            onBlur={e => {
                                if (!this.props.isMailingAddressSame) {
                                    this.validateRequiredField(e.target.value, "hasZipError")
                                }
                            }}
                            helperText={this.state.hasZipError ? this.props.t("zip_code_required") : ""}
                            error={this.state.hasZipError}
                            label={this.props.t("zip_code")}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

MailingAddress.propTypes = {
    shouldRunValidation: PropTypes.bool,
    mailingAddress1: PropTypes.string,
    mailingAddress2: PropTypes.string,
    mailingCity: PropTypes.string,
    mailingState: PropTypes.string,
    mailingCountry: PropTypes.string,
    mailingZipCode: PropTypes.string,
    isMailingAddressSame: PropTypes.bool,
    mailingAddress1Change: PropTypes.func,
    mailingAddress2Change: PropTypes.func,
    mailingCityChange: PropTypes.func,
    mailingZipChange: PropTypes.func,
    mailingStateChange: PropTypes.func,
    mailingCountryChange: PropTypes.func,
    t: PropTypes.func
}

export default withTranslation('startService')(MailingAddress)