export const paperlessBillingState = {
    UNKNOWN: 'unknown',
    
    NOT_ENROLLED: 'not enrolled',
    ENROLLING: 'enrolling',
    JUST_ENROLLED: 'just enrolled',

    ALREADY_ENROLLED: 'already enrolled',
    UNENROLLING: 'unenrolling',
    JUST_UNENROLLED: 'just unenrolled',
}