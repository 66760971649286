import React from 'react'
import PropTypes from 'prop-types'

import utils from '../../../../srp_modules/utils'
import { Trans } from 'react-i18next'

const ErrorOnCIXSubmitCard = ({endCIXFlow, isCommercial, t, i18n}) => {
    const isSpanish = i18n.language==="es"
    const csPhoneNumber = isSpanish ? utils.getCSSpanishPhoneNumber() : utils.getCSPhoneNumber(isCommercial)
    const formattedCSPhoneNumber = isSpanish ? utils.getFormattedCSSpanishPhoneNumber() : utils.getFormattedCSPhoneNumber(isCommercial)

    return (
    <div className="srp-card">        
        <div className="srp-card-header">        
            {t("My bill")}
        </div>
        <div className="srp-card-body">
            <div className="srp-card-details">
                <h5 className="mb-4"><strong>{t("Something went wrong")}</strong></h5>
                <div className="d-none d-lg-block mb-4 text-muted">{t("cant_submit_cix_call_srp_desktop", {phone: formattedCSPhoneNumber})}</div>
                <div className="d-lg-none d-block mb-4 text-muted">
                    <Trans i18nKey="cant_submit_cix_call_srp_mobile" t={t}>
                    We're sorry, but we had problems extending your due date. Please try again later or call SRP Customer Service at <a href={"tel:" + csPhoneNumber}>{{phone: formattedCSPhoneNumber}}</a> for further assistance.
                    </Trans>
                </div>
                <div className="d-flex d-flex justify-content-end">
                    <button className="btn srp-btn btn-lightblue text-white mb-2" onClick={()=>endCIXFlow()}>{t("Back to Home")}</button>
                    <a href={"tel:" + csPhoneNumber} className="d-lg-none d-block btn srp-btn btn-blue ml-2 mb-2" >{t("Call SRP")}</a>
                </div> 
            </div>
        </div>
    </div>
    )
}

ErrorOnCIXSubmitCard.propTypes = {
    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired,

    endCIXFlow: PropTypes.func,
    isCommercial: PropTypes.bool
}

export default ErrorOnCIXSubmitCard