// Pass the setFieldValue from formik for the onChange handler. Otherwise this field will not work correctly.

import React from 'react'
import PropTypes from 'prop-types'
import TextField from '@mui/material/TextField'
import { PatternFormat } from 'react-number-format'

const SRPAccountNumber = props => {

    let isError = props.touched[props.id]
        && Object.prototype.hasOwnProperty.call(props.errors, props.id)
    let errMsg = isError ? props.t(props.errors[props.id]) : ''
    let meta = {
        id: props.id,
        name: props.id,
        helperText: errMsg,
        error: isError
    }

    return (
        <div>
            <PatternFormat
                {...meta}
                value={props.value}
                valueIsNumericString={true}
                fullWidth={true}
                type="tel"
                onValueChange={(values) => props.onChange(props.id, values.value, true)}
                onBlur={props.onBlur}
                format="###-###-###"
                customInput={TextField}
                className={props.inputClass||""}
                style={props.inputStyle||{}}
                label={props.label}
            />
        </div>
    )
}

SRPAccountNumber.propTypes = {
    touched: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired,
    labelClass: PropTypes.string,
    labelStyle: PropTypes.object,
    inputClass: PropTypes.string,
    inputStyle: PropTypes.object,
    t: PropTypes.func.isRequired,
}

export default SRPAccountNumber