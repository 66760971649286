import React from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

import DatesPlan from './dates-plan'
import RateSelection from './rate-selection'

import SolarChoice from './solar-choice'

const SelectDatePlan = ({startDate, currentStep, errorMessage, holidayList, planDateChange, onDateInitialized,
                        setSelectedPlan, selectedPlan, toggleSolarPopup,
                        goToServiceDetails, isLoggedIn, selectSolarChoiceFunc,
                        selectedSolarChoicePlan, isSolarChoiceDisabled, t
                    }) => (
    <div>
    <DatesPlan
        startDate={startDate}
        currentStep={currentStep}
        errorMessage={errorMessage}
        holidayList={holidayList}
        planDateChange={planDateChange}
        onDateInitialized={onDateInitialized}
         />
    <RateSelection
        rateChangeFunc={setSelectedPlan}
        selectedPlan={selectedPlan}
        toggleSolarMessage={(e) => toggleSolarPopup(e)} />
    {isLoggedIn &&
    <SolarChoice
        selectSolarChoiceFunc={selectSolarChoiceFunc}
        selectedSolarChoicePlan={selectedSolarChoicePlan}
        isSolarChoiceDisabled={isSolarChoiceDisabled} />
    }
    <div className="d-flex justify-content-end pt-2">
        <button className="btn srp-btn btn-blue" onClick={() => goToServiceDetails(currentStep + 1)}>
        {t("next")}
        </button>
    </div>
    </div>
)

SelectDatePlan.propTypes = {
    startDate: PropTypes.instanceOf(Date).isRequired,
    currentStep: PropTypes.number.isRequired,
    errorMessage: PropTypes.string.isRequired,
    holidayList: PropTypes.arrayOf(PropTypes.string).isRequired,
    planDateChange: PropTypes.func.isRequired,
    onDateInitialized: PropTypes.func.isRequired,

    setSelectedPlan: PropTypes.func.isRequired,
    selectedPlan: PropTypes.string.isRequired,
    toggleSolarPopup: PropTypes.func.isRequired,

    isLoggedIn: PropTypes.bool.isRequired,
    selectSolarChoiceFunc: PropTypes.func.isRequired,
    selectedSolarChoicePlan: PropTypes.string.isRequired,
    isSolarChoiceDisabled: PropTypes.bool.isRequired,

    goToServiceDetails: PropTypes.func.isRequired,
    t: PropTypes.func
}

export default withTranslation('startService')(SelectDatePlan)