import React from 'react'
import PropTypes from 'prop-types'

import { Link } from 'react-router-dom'

import { NumericFormat } from 'react-number-format'

import { DateTime } from 'luxon'
import Divider from '@mui/material/Divider'
import CircularProgress from '@mui/material/CircularProgress'

import * as loadingStatus from '../../../constants/loading-status-constants'

import ReconnectPowerSafetyStatement from './card-bill-mpower-reconnect-safety-statement'

const MPowerReconnectBillCard = ({ mPowerAcctDetails, reconnectPowerNow, reconnectPowerStatus, t }) => {
    const _lastReadDate = DateTime.fromISO(mPowerAcctDetails.lastEstimatedReadDate)
    const isMinDate = _lastReadDate.year===1 && _lastReadDate.month===1 && _lastReadDate.day===1
    const lastReadDate = isMinDate ? "- -" : _lastReadDate.toFormat("h:mm a, MMM d")
    const balanceRemaining = <NumericFormat value={mPowerAcctDetails.balanceRemaining} valueIsNumericString={true} prefix={'$'} thousandSeparator={true} fixedDecimalScale={true} decimalScale={2} displayType={'text'} />
    const onLinkClickHandler = (event) => {
        if (callingReconnectPower) event.preventDefault()
    }
    const onReconnectHandler = () => {
        reconnectPowerNow(mPowerAcctDetails.billAccount)
    }
    const callingReconnectPower = reconnectPowerStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS

    return (
        <div className="srp-card">
            <div className="srp-card-header">
                {t("My M-Power")}
            </div>

            <div className="srp-card-body">
                <div className={"srp-card-summary d-flex justify-content-around srp-gray"}>
                    <div className="d-flex flex-column align-items-center text-white">
                        <div className="h4">{t("Disconnected")}</div>
                        <span>{balanceRemaining} {t("meter_balance_as_of", {lastReadDate})}</span>
                    </div>
                </div>

                <div className="srp-card-details">
                    <div className="d-none d-lg-block mb-2">
                        <div className="d-flex flex-wrap justify-content-end">
                            <Link to="/myaccount/payment" onClick={onLinkClickHandler} className="btn srp-btn btn-lightblue mb-2" style={{ fontSize: '95%' }}>{t("Purchase power")}</Link>
                            <button
                                className="btn srp-btn btn-green ml-2 mb-2"
                                style={{ fontSize: '95%' }}
                                disabled={callingReconnectPower}
                                onClick={onReconnectHandler}>
                                {callingReconnectPower
                                    ? <CircularProgress size={20} thickness={5} aria-label="reconnecting power" alt="progress icon" />
                                    : t("Reconnect power now")
                                }
                            </button>
                        </div>
                    </div>

                    <div style={{ fontSize: '14px' }}>
                        <div className="srp-alert-warn">
                            {t("reconnect_fire_risk_notice")}
                            <ReconnectPowerSafetyStatement />
                            .
                        </div>
                    </div>
                </div>

                <div className="d-block d-lg-none">
                    <Divider />
                    <div className="srp-card-details">
                        <div className="d-flex flex-wrap justify-content-end">
                            <Link to="/myaccount/payment" onClick={onLinkClickHandler} className="btn srp-btn btn-lightblue my-1">{t("Purchase power")}</Link>
                            <button
                                className="btn srp-btn btn-green ml-2 my-1"
                                disabled={callingReconnectPower}
                                onClick={onReconnectHandler}>
                                {callingReconnectPower
                                    ? <CircularProgress size={20} thickness={5} aria-label="reconnecting power" alt="progress icon" />
                                    : t("Reconnect power now")
                                }
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

MPowerReconnectBillCard.propTypes = {
    mPowerAcctDetails: PropTypes.shape({
        billAccount: PropTypes.number.isRequired,
        balanceRemaining: PropTypes.number.isRequired,
        lastEstimatedReadDate: PropTypes.string.isRequired,
    }).isRequired,
    reconnectPowerStatus: PropTypes.string.isRequired,
    reconnectPowerNow: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
}

export default MPowerReconnectBillCard