import React from 'react'
import { withTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import MenuItem from '@mui/material/MenuItem'
import FormHelperText from '@mui/material/FormHelperText'
import TextField from '@mui/material/TextField'

import format from '../../../../srp_modules/format'

const BankAccountSelectorPure = ({ t, bankAccountList, ...props }) => {
    const isError = props.touched['bankAccountIdSelected']
        && Object.prototype.hasOwnProperty.call(props.errors, 'bankAccountIdSelected')
    let errMsg = isError ? props.errors['bankAccountIdSelected'] : ''

    const getLocalizedErrMsg = errMsg.getLocalizedErrMsg
    if (typeof getLocalizedErrMsg === 'function')
        errMsg = getLocalizedErrMsg()
    else if (errMsg !== "")
        errMsg = t(errMsg)

    const id = 'bankAccountIdSelected'
    const name = 'bankAccountIdSelected'
    const meta = {
        id: id,
        name: name,
        error: isError,
        fullWidth: true,
        SelectProps: { SelectDisplayProps: { id, name } }
    }

    const textFieldProps = {
        // default must be an empty string if it isn't in the drop down list
        value: !props.value ? '' : props.value,
        onChange: props.onChange,
        onBlur: props.onBlur,
        errors: props.errors,
        touched: props.touched,
        fullWidth: props.fullWidth
    }

    return (
        <div>
            <TextField select {...textFieldProps} {...meta} label={t("Select a bank account")}>
                {bankAccountList.map((bank) =>
                    <MenuItem key={bank.bankId} value={bank.bankId}>{format.bankAccount(bank)}</MenuItem>
                )}
            </TextField>
            <FormHelperText error={isError} style={!isError ? {display: 'none'} : {}}>{errMsg}</FormHelperText>
        </div>
    )
}

BankAccountSelectorPure.propTypes = {
    bankAccountList: PropTypes.arrayOf(PropTypes.shape({
        bankId: PropTypes.number.isRequired,
        bankName: PropTypes.string.isRequired,
        nickName: PropTypes.string.isRequired,
        accountNumber: PropTypes.string.isRequired
    })).isRequired,
    errors: PropTypes.shape({
        bankAccountIdSelected: PropTypes.oneOfType([
            PropTypes.shape({
                getLocalizedErrMsg: PropTypes.func.isRequired,
            }),
            PropTypes.string.isRequired,
        ]),
    }).isRequired,
    fullWidth: PropTypes.bool,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired,
    onBlur: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    touched: PropTypes.shape({
        bankAccountIdSelected: PropTypes.bool
    }).isRequired,
    value: PropTypes.number.isRequired
}

const BankAccountSelector = withTranslation("singlePurchasePage")(BankAccountSelectorPure)
export { BankAccountSelector as default, BankAccountSelectorPure }