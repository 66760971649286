export const loginConstants = {
    //Login constants
    TOGGLE_REMEMBER_MY_LOGIN_INFO: 'TOGGLE_REMEMBER_MY_LOGIN_INFO',

    SET_SITE_OFFLINE: 'SET_SITE_OFFLINE',

    LOGIN_PROFILE_REQUEST: 'LOGIN_PROFILE_REQUEST',
    LOGIN_PROFILE_SUCCESS: 'LOGIN_PROFILE_SUCCESS',
    LOGIN_PROFILE_FAILURE: 'LOGIN_PROFILE_FAILURE',

    LOGIN_REQUEST: 'LOGIN_REQUEST',
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    LOGIN_FAILURE: 'LOGIN_FAILURE',

    LOGOUT_REQUEST: 'LOGOUT_REQUEST',
    LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
    LOGOUT_FAILURE: 'LOGOUT_FAILURE',

    //Password reset constants
    PASSWORD_RESET_EMAIL_REQUEST: 'PASSWORD_RESET_EMAIL_REQUEST',
    PASSWORD_RESET_EMAIL_SUCCESS: 'PASSWORD_RESET_EMAIL_SUCCESS',
    PASSWORD_RESET_EMAIL_FAILURE: 'PASSWORD_RESET_EMAIL_FAILURE',

    REFRESH_PASSWORD_RESET: 'REFRESH_PASSWORD_RESET',

    GET_NEW_PASSWORD: 'GET_NEW_PASSWORD',
    GET_CONFIRM_PASSWORD: 'GET_CONFIRM_PASSWORD',
    CHECK_EMPTY_FORM: 'CHECK_EMPTY_FORM',

    CONFIRM_PASSWORD_RESET_REQUEST: 'CONFIRM_PASSWORD_RESET_REQUEST',
    CONFIRM_PASSWORD_RESET_SUCCESS: 'CONFIRM_PASSWORD_RESET_SUCCESS',
    CONFIRM_PASSWORD_RESET_FAILURE: 'CONFIRM_PASSWORD_RESET_FAILURE',

    COMPLETE_PASSWORD_RESET_REQUEST: 'COMPLETE_PASSWORD_RESET_REQUEST',
    COMPLETE_PASSWORD_RESET_SUCCESS: 'COMPLETE_PASSWORD_RESET_SUCCESS',
    COMPLETE_PASSWORD_RESET_FAILURE: 'COMPLETE_PASSWORD_RESET_FAILURE',
    CLOSE_PASSWORD_RESET_SUCCESS_MESSAGE: 'CLOSE_PASSWORD_RESET_SUCCESS_MESSAGE',

    REGISTER_NEW_LOGIN_REQUEST: 'REGISTER_NEW_LOGIN_REQUEST',
    REGISTER_NEW_LOGIN_SUCCESS: 'REGISTER_NEW_LOGIN_SUCCESS',
    REGISTER_NEW_LOGIN_FAILURE: 'REGISTER_NEW_LOGIN_FAILURE',

    CREATE_ACCOUNT_REQUEST: 'CREATE_ACCOUNT_REQUEST',
    CREATE_ACCOUNT_SUCCESS: 'CREATE_ACCOUNT_SUCCESS',
    CREATE_ACCOUNT_FAILURE: 'CREATE_ACCOUNT_FAILURE',

    COMPLETE_CREATE_ACCOUNT_REQUEST: 'COMPLETE_CREATE_ACCOUNT_REQUEST',
    COMPLETE_CREATE_ACCOUNT_SUCCESS: 'COMPLETE_CREATE_ACCOUNT_SUCCESS',
    COMPLETE_CREATE_ACCOUNT_FAILURE: 'COMPLETE_CREATE_ACCOUNT_FAILURE',

    REQUEST_LOGIN_EMAIL_UPDATE_REQUEST: 'REQUEST_LOGIN_EMAIL_UPDATE_REQUEST',
    REQUEST_LOGIN_EMAIL_UPDATE_SUCCESS: 'REQUEST_LOGIN_EMAIL_UPDATE_SUCCESS',
    REQUEST_LOGIN_EMAIL_UPDATE_FAILURE: 'REQUEST_LOGIN_EMAIL_UPDATE_FAILURE',

    COMPLETE_LOGIN_EMAIL_UPDATE_REQUEST: 'COMPLETE_LOGIN_EMAIL_UPDATE_REQUEST',
    COMPLETE_LOGIN_EMAIL_UPDATE_SUCCESS: 'COMPLETE_LOGIN_EMAIL_UPDATE_SUCCESS',
    COMPLETE_LOGIN_EMAIL_UPDATE_FAILURE: 'COMPLETE_LOGIN_EMAIL_UPDATE_FAILURE',

    CHANGE_PASSWORD_REQUEST: 'CHANGE_PASSWORD_REQUEST',
    CHANGE_PASSWORD_SUCCESS: 'CHANGE_PASSWORD_SUCCESS',
    CHANGE_PASSWORD_FAILURE: 'CHANGE_PASSWORD_FAILURE',

    ACCOUNT_LOGIN_DELETED: 'ACCOUNT_LOGIN_DELETED',
    CLEAR_LOGIN_DELETED: 'CLEAR_LOGIN_DELETED',

    DELETE_LOGIN_REQUEST: 'DELETE_LOGIN_REQUEST',
    DELETE_LOGIN_SUCCESS: 'DELETE_LOGIN_SUCCESS',
    DELETE_LOGIN_FAILURE: 'DELETE_LOGIN_FAILURE',

    REFRESH_ACCOUNT_SETUP: 'REFRESH_ACCOUNT_SETUP',

    LOGIN_RESP_TAB_VALUE: 'LOGIN_RESP_TAB_VALUE'
}