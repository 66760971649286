import { connect } from 'react-redux'

import ConfirmPaymentDetailsCard from './confirm-payment-details-card'

import { submitPaymentList, submitPaymentListFailure } from '../../../actions/guest/payment/guest-payment-actions'

import { formCompleteEvent, formErrorEvent } from '../../../srp_modules/analytics-events'
import { guestPaymentConstants } from '../common/guest-payment-constants'
import { withRouter } from '../../../srp_modules/with-router'

const mapStateToProps = state => {
    const errorSubmittingPayment = state.guestPaymentApiResponse.errorSubmittingPayment
    const isSubmitting = state.guestPaymentApiResponse.isSubmittingPayment
    const paymentCount = state.guestPaymentList.paymentList.length
    const paymentTotal = state.guestPaymentList.paymentList.reduce((subtotal, payment) => subtotal + payment.paymentAmount, 0)

    return { isSubmitting, errorSubmittingPayment, paymentCount, paymentTotal }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
    goBackToEditPaymentDetails: () => {
        ownProps.router.navigate(-1);
    },
    submitPayment: async (paymentCount, paymentTotal) => {
        const formName = guestPaymentConstants.ADOBE_ANALYTICS_FORM_NAME

        const response = await dispatch(submitPaymentList(ownProps.i18n.language))

        if (response.error === true) {
            dispatch(submitPaymentListFailure())

            formErrorEvent(formName)
        }
        else if (response.payload.referenceNumber > 0) {
            const meta = { paymentCount, paymentTotal}
            formCompleteEvent(formName, meta)

            ownProps.router.navigate('/myaccount/payment/guest/submitted', { replace: true })
        }
    }
})

const ConfirmPaymentDetailsCardContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(ConfirmPaymentDetailsCard))
export default ConfirmPaymentDetailsCardContainer