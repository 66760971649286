import { solarChoiceConstants } from '../../../constants/solar-choice-constants'
import * as loadingStatus from '../../../constants/loading-status-constants'

const initialState = {
    submitSolarChoiceFormResult:
    {
        success: false,
        signUpDateTime: "00:00",
        signUpDateDate: "01/01/0001",
        planDescription: "None"
    },
    submitSolarChoiceFormStatus: loadingStatus.LOADING_STATUS_INIT
}

export default function solarChoiceReducer(state = initialState, action) {
    switch (action.type) {

        case solarChoiceConstants.SUBMIT_SOLAR_CHOICE_FORM_REQUEST:
            return { ...state, submitSolarChoiceFormStatus: loadingStatus.LOADING_STATUS_IN_PROGRESS }
        case solarChoiceConstants.SUBMIT_SOLAR_CHOICE_FORM_SUCCESS:
            return { ...state, submitSolarChoiceFormResult: action.payload, submitSolarChoiceFormStatus: loadingStatus.LOADING_STATUS_SUCCESS }
        case solarChoiceConstants.SUBMIT_SOLAR_CHOICE_FORM_FAILURE:
            return { ...state, submitSolarChoiceFormResult: initialState.submitSolarChoiceFormResult, submitSolarChoiceFormStatus: loadingStatus.LOADING_STATUS_FAILURE }


        default:
            return state
    }
}