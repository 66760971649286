import React from 'react'
import PropTypes from 'prop-types'
import { DateTime } from 'luxon'
import config from 'my-account-config'
import Divider from '@mui/material/Divider'
import { formatDayWithOrdinal } from '../../../srp_modules/utils'

const borderStyle = {
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)"
}

const ClosedAcctPrograms = ({ programs, hasCommunitySolar, hasEnergyScorecard, numOfEnrolledPrograms, autopayInfo, budgetBillingAmount, isBudgetBillingActive,
    customDueDay, isCustomDueDateEligible, solarChoicePercentage, sharePledge, t }) => {

    let isBudgetBillingEnrolled = programs.budgetBilling.isEnrolled && isBudgetBillingActive

    let budgetBillingHasLabelEnrolled = !programs.autopay.isEnrolled
    let solarChoiceHasLabelEnrolled = !programs.autopay.isEnrolled && !isBudgetBillingEnrolled
    let communitySolarHasLabelEnrolled = !programs.autopay.isEnrolled && !isBudgetBillingEnrolled && !programs.solarChoice.isEnrolled
    let customDueDateHasLabelEnrolled = (!programs.autopay.isEnrolled && !programs.budgetBilling.isEnrolled && !programs.solarChoice.isEnrolled
        && !hasCommunitySolar)
    let energyScorecardHasLabelEnrolled = (!programs.autopay.isEnrolled && !isBudgetBillingEnrolled && !programs.solarChoice.isEnrolled
        && !hasCommunitySolar && (!programs.customDueDate.isEnrolled || !isCustomDueDateEligible))
    let shareHasLabelEnrolled = (!programs.autopay.isEnrolled && !isBudgetBillingEnrolled && !programs.solarChoice.isEnrolled
        && !hasCommunitySolar && (!programs.customDueDate.isEnrolled || !isCustomDueDateEligible) && !hasEnergyScorecard)
    let eppHasLabelEnrolled = (!programs.autopay.isEnrolled && !isBudgetBillingEnrolled && !programs.solarChoice.isEnrolled
        && !hasCommunitySolar && (!programs.customDueDate.isEnrolled || !isCustomDueDateEligible) && !hasEnergyScorecard
        && !programs.share.isEnrolled)

    let autopayHasBorderEnrolled = (isBudgetBillingEnrolled || programs.solarChoice.isEnrolled || hasCommunitySolar
        || (programs.customDueDate.isEnrolled && isCustomDueDateEligible) || hasEnergyScorecard || programs.share.isEnrolled
        || programs.economicPricePlan.isEnrolled)
    let budgetBillingHasBorderEnrolled = (programs.solarChoice.isEnrolled || hasCommunitySolar || (programs.customDueDate.isEnrolled
        && isCustomDueDateEligible) || hasEnergyScorecard || programs.share.isEnrolled || programs.economicPricePlan.isEnrolled)
    let solarChoiceHasBorderEnrolled = (hasCommunitySolar || (programs.customDueDate.isEnrolled && isCustomDueDateEligible) || hasEnergyScorecard
        || programs.share.isEnrolled || programs.economicPricePlan.isEnrolled)
    let communitySolarHasBorderEnrolled = (programs.customDueDate.isEnrolled && isCustomDueDateEligible) || hasEnergyScorecard
        || programs.share.isEnrolled || programs.economicPricePlan.isEnrolled
    let customeDueDateHasBorderEnrolled = hasEnergyScorecard || programs.share.isEnrolled || programs.economicPricePlan.isEnrolled
    let energyScoreCardHasBorderEnrolled = programs.share.isEnrolled || programs.economicPricePlan.isEnrolled
    let shareHasBorderEnrolled = programs.economicPricePlan.isEnrolled

    return (
        <div className="srp-card-body">
            <div className="d-lg-block d-none">
                <div className="srp-card-details pt-2 pb-0">
                    <div className="row" style={programs.autopay.isEnrolled ? { display: 'flex' } : { display: 'none' }}>
                        <div className="col-3 text-muted pt-2 pb-2">{t("Enrolled Programs")}</div>
                        <div className="col-3 pt-2 pb-2" style={autopayHasBorderEnrolled ? borderStyle : { borderBottom: 'none' }}>
                            <a
                                href={`${config.srpnetBaseUrl}account/electric/manage-bill/pay-electric-bill`}
                                target="_blank">
                                {t("SurePay")}
                            </a>
                        </div>
                        <div className="col-3 pt-2 pb-2" style={autopayHasBorderEnrolled ? borderStyle : { borderBottom: 'none' }}>
                            {autopayInfo.surePayDate !== "0001-01-01T00:00:00" && autopayInfo.surePayDate !== null
                                ? t("surepay_date", {surePayDate: formatDayWithOrdinal(DateTime.fromISO(autopayInfo.surePayDate).day)})
                                : ""
                            }
                        </div>
                        <div className="col-3 pt-2 pb-2 text-right text-muted" style={autopayHasBorderEnrolled ? borderStyle : { borderBottom: 'none' }}>
                            {t("Update bank")}
                        </div>
                    </div>
                    <div className="row" style={isBudgetBillingEnrolled ? { display: 'flex' } : { display: 'none' }}>
                        <div className="col-3 text-muted pt-2 pb-2">{budgetBillingHasLabelEnrolled ? t("Enrolled Programs") : ""}</div>
                        <div className="col-3 pt-2 pb-2" style={budgetBillingHasBorderEnrolled ? borderStyle : { borderBottom: 'none' }}>
                            <a
                                href={`${config.srpnetBaseUrl}account/electric/manage-bill/budget-billing-residential`}
                                target="_blank">
                                {t("Budget Billing")}
                            </a>
                        </div>
                        <div className="col-3 pt-2 pb-2" style={budgetBillingHasBorderEnrolled ? borderStyle : { borderBottom: 'none' }}>
                            {t("Amount_dollars", {amount: budgetBillingAmount.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")})}
                        </div>
                        <div className="col-3 pt-2 pb-2 text-right" style={budgetBillingHasBorderEnrolled ? borderStyle : { borderBottom: 'none' }} />
                    </div>
                    <div className="row" style={programs.solarChoice.isEnrolled ? { display: 'flex' } : { display: 'none' }}>
                        <div className="col-3 text-muted pt-2 pb-2">{solarChoiceHasLabelEnrolled ? t("Enrolled Programs") : ""}</div>
                        <div className="col-3 pt-2 pb-2" style={solarChoiceHasBorderEnrolled ? borderStyle : { borderBottom: 'none' }}>
                            <a href={`${config.srpnetBaseUrl}account/electric/manage-bill/solar-choice-program`} target="_blank">{t("SRP Solar Choice")}</a>
                        </div>
                        <div className="col-3 pt-2 pb-2" style={solarChoiceHasBorderEnrolled ? borderStyle : { borderBottom: 'none' }}>
                            {t("Renewables_percent", {solarChoicePercentage: solarChoicePercentage * 100})}
                        </div>
                        <div className="col-3 pt-2 pb-2 text-right" style={solarChoiceHasBorderEnrolled ? borderStyle : { borderBottom: 'none' }} />
                    </div>

                    <div className="row" style={hasCommunitySolar ? { display: 'flex' } : { display: 'none' }}>
                        <div className="col-3 text-muted pt-2 pb-2">{communitySolarHasLabelEnrolled ? t("Enrolled Programs") : ""}</div>
                        <div className="col-3 pt-2 pb-2" style={communitySolarHasBorderEnrolled ? borderStyle : { borderBottom: 'none' }}>{t("Community Solar")}</div>
                        <div className="col-3 pt-2 pb-2" style={communitySolarHasBorderEnrolled ? borderStyle : { borderBottom: 'none' }}>{t("Solar energy credit")}</div>
                        <div className="col-3 pt-2 pb-2 text-right text-muted" style={communitySolarHasBorderEnrolled ? borderStyle : { borderBottom: 'none' }}>
                            {t("Go to Document Center")}
                        </div>
                    </div>
                    <div className="row" style={programs.customDueDate.isEnrolled && isCustomDueDateEligible ? { display: 'flex' } : { display: 'none' }}>
                        <div className="col-3 text-muted pt-2 pb-2">{customDueDateHasLabelEnrolled ? t("Enrolled Programs") : ""}</div>
                        <div className="col-3 pt-2 pb-2" style={customeDueDateHasBorderEnrolled ? borderStyle : { borderBottom: 'none' }}>
                            <a
                                href={`${config.srpnetBaseUrl}account/electric/manage-bill/change-bill-due-date`}
                                target="_blank">
                                {t("Custom Due Date")}
                            </a>
                        </div>
                        <div className="col-3 pt-2 pb-2" style={customeDueDateHasBorderEnrolled ? borderStyle : { borderBottom: 'none' }}>
                            {!programs.autopay.isEnrolled
                                ? t("custom_due_date", {customDueDay: formatDayWithOrdinal(customDueDay)})
                                : ""
                            }
                        </div>
                        <div className="col-3 pt-2 pb-2 text-right text-muted" style={customeDueDateHasBorderEnrolled ? borderStyle : { borderBottom: 'none' }}>
                            {t("Change")}
                        </div>
                    </div>
                    <div className="row" style={hasEnergyScorecard ? { display: 'flex' } : { display: 'none' }}>
                        <div className="col-3 text-muted pt-2 pb-2">{energyScorecardHasLabelEnrolled ? t("Enrolled Programs") : ""}</div>
                        <div className="col-3 pt-2 pb-2" style={energyScoreCardHasBorderEnrolled ? borderStyle : { borderBottom: 'none' }}>{t("Energy Scorecard")}</div>
                        <div className="col-3 pt-2 pb-2" style={energyScoreCardHasBorderEnrolled ? borderStyle : { borderBottom: 'none' }}>{t("Your energy usage comparison")}</div>
                        <div className="col-3 pt-2 pb-2 text-right text-muted" style={energyScoreCardHasBorderEnrolled ? borderStyle : { borderBottom: 'none' }}>
                            {t("Go to Document Center")}
                        </div>
                    </div>
                    <div className="row" style={programs.share.isEnrolled ? { display: 'flex' } : { display: 'none' }}>
                        <div className="col-3 text-muted pt-2 pb-2">{shareHasLabelEnrolled ? t("Enrolled Programs") : ""}</div>
                        <div className="col-3 pt-2 pb-2" style={shareHasBorderEnrolled ? borderStyle : { borderBottom: 'none' }}>
                            <a
                                href={`${config.srpnetBaseUrl}account/donate/help-neighbors`}
                                target="_blank">
                                {t("SHARE")}
                            </a>
                        </div>
                        <div className="col-3 pt-2 pb-2" style={shareHasBorderEnrolled ? borderStyle : { borderBottom: 'none' }}>
                            {t("SHARE_pledge_amount", {pledgeAmount: sharePledge})}
                        </div>
                        <div className="col-3 pt-2 pb-2 text-right text-muted" style={shareHasBorderEnrolled ? borderStyle : { borderBottom: 'none' }}>
                            {t("Change")}
                        </div>
                    </div>
                    <div className="row" style={programs.economicPricePlan.isEnrolled ? { display: 'flex' } : { display: 'none' }}>
                        <div className="col-3 text-muted pt-2 pb-2">{eppHasLabelEnrolled ? t("Enrolled Programs") : ""}</div>
                        <div className="col-3 pt-2 pb-2">
                            <a
                                href={`${config.srpnetBaseUrl}customer-service/residential-electric/economy-price-plan`}
                                target="_blank">
                                {t("Economy Price Plan")}
                            </a>
                        </div>
                        <div className="col-3 pt-2 pb-2" />
                        <div className="col-3 pt-2 pb-2 text-right" />
                    </div>
                    <div className="row" style={numOfEnrolledPrograms === 0 ? { display: 'flex' } : { display: 'none' }}>
                        <div className="col-3 text-muted pt-2 pb-2">{numOfEnrolledPrograms === 0 ? t("Enrolled Programs") : ""}</div>
                        <div className="col-9 pt-2 pb-2">{t("youre_not_enrolled_in_any_programs")}</div>
                    </div>
                </div>
                <Divider />
                <div className="srp-card-details pb-0" />
            </div>

            <div className="d-lg-none d-block">
                <div className="srp-card-details text-muted font-weight-bold pb-0">{t("Enrolled Programs")}</div>
                <div className="srp-card-details pt-2 pb-2 row" style={programs.autopay.isEnrolled ? { alignItems: 'center' } : { display: 'none' }}>
                    <div className="col align-middle ml-3">
                        <div className="font-weight-bold">
                            <a
                                href={`${config.srpnetBaseUrl}account/electric/manage-bill/pay-electric-bill`}
                                target="_blank">
                                {t("SurePay")}
                            </a>
                        </div>
                        {autopayInfo.surePayDate !== "0001-01-01T00:00:00" && autopayInfo.surePayDate !== null
                            ? <div>{t("surepay_date", {surePayDate: formatDayWithOrdinal(DateTime.fromISO(autopayInfo.surePayDate).day)})}</div>
                            : <div />
                        }
                    </div>
                    <div className="col text-right text-muted">{t("Update bank")}</div>
                </div>
                <div style={programs.autopay.isEnrolled && autopayHasBorderEnrolled ? { display: 'block' } : { display: 'none' }}><Divider /></div>

                <div className="srp-card-details pt-2 pb-2 row" style={isBudgetBillingEnrolled ? { alignItems: 'center' } : { display: 'none' }}>
                    <div className="col align-middle ml-3">
                        <div className="font-weight-bold">
                            <a
                                href={`${config.srpnetBaseUrl}account/electric/manage-bill/budget-billing-residential`}
                                target="_blank">
                                {t("Budget Billing")}
                            </a>
                        </div>
                        <div>{t("Amount_dollars", {amount: budgetBillingAmount.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")})}</div>
                    </div>
                    <div className="col text-right" />
                </div>
                <div style={isBudgetBillingEnrolled && budgetBillingHasBorderEnrolled ? { display: 'block' } : { display: 'none' }}><Divider /></div>
                <div className="srp-card-details pt-2 pb-2 row" style={programs.solarChoice.isEnrolled ? { alignItems: 'center' } : { display: 'none' }}>
                    <div className="col align-middle ml-3">
                        <div className="font-weight-bold">
                            <a href={`${config.srpnetBaseUrl}account/electric/manage-bill/solar-choice-program`} target="_blank">{t("SRP Solar Choice")}</a>
                        </div>
                        <div>{t("Renewables_percent", {solarChoicePercentage: solarChoicePercentage * 100})}</div>
                    </div>
                    <div className="col text-right" />
                </div>
                <div style={programs.solarChoice.isEnrolled && solarChoiceHasBorderEnrolled ? { display: 'block' } : { display: 'none' }}><Divider /></div>

                <div className="srp-card-details pt-2 pb-2 row" style={hasCommunitySolar ? { alignItems: 'center' } : { display: 'none' }}>
                    <div className="col align-middle ml-3">
                        <div className="font-weight-bold">{t("Community Solar")}</div>
                        <div>{t("Solar energy credit")}</div>
                    </div>
                    <div className="col text-right text-muted">{t("Go to Document Center")}</div>
                </div>
                <div style={hasCommunitySolar && communitySolarHasBorderEnrolled ? { display: 'block' } : { display: 'none' }}><Divider /></div>

                <div className="srp-card-details pt-2 pb-2 row" style={programs.customDueDate.isEnrolled && isCustomDueDateEligible ? { alignItems: 'center' } : { display: 'none' }}>
                    <div className="col align-middle ml-3">
                        <div className="font-weight-bold">
                            <a
                                href={`${config.srpnetBaseUrl}account/electric/manage-bill/change-bill-due-date`}
                                target="_blank">
                                {t("Custom Due Date")}
                            </a>
                        </div>
                        <div>
                            {!programs.autopay.isEnrolled
                                ? t("custom_due_date", {customDueDay: formatDayWithOrdinal(customDueDay)})
                                : ""
                            }
                        </div>
                    </div>
                    <div className="col text-right text-muted">{t("Change")}</div>
                </div>
                <div style={programs.customDueDate.isEnrolled && isCustomDueDateEligible && customeDueDateHasBorderEnrolled ? { display: 'block' } : { display: 'none' }}><Divider /></div>

                <div className="srp-card-details pt-2 pb-2 row" style={hasEnergyScorecard ? { alignItems: 'center' } : { display: 'none' }}>
                    <div className="col align-middle ml-3">
                        <div className="font-weight-bold">{t("Energy Scorecard")}</div>
                        <div>{t("Your energy usage comparison")}</div>
                    </div>
                    <div className="col text-right text-muted">{t("Go to Document Center")}</div>
                </div>
                <div style={hasEnergyScorecard && energyScoreCardHasBorderEnrolled ? { display: 'block' } : { display: 'none' }}><Divider /></div>

                <div className="srp-card-details pt-2 pb-2 row" style={programs.share.isEnrolled ? { alignItems: 'center' } : { display: 'none' }}>
                    <div className="col align-middle ml-3">
                        <div className="font-weight-bold">
                            <a
                                href={`${config.srpnetBaseUrl}account/donate/help-neighbors`}
                                target="_blank">
                                {t("SHARE")}
                            </a>
                        </div>
                        <div>{t("SHARE_pledge_amount", {pledgeAmount: sharePledge})}</div>
                    </div>
                    <div className="col text-right text-muted">{t("Change")}</div>
                </div>
                <div style={programs.share.isEnrolled && shareHasBorderEnrolled ? { display: 'block' } : { display: 'none' }}><Divider /></div>

                <div className="srp-card-details pt-2 pb-2 row" style={programs.economicPricePlan.isEnrolled ? { alignItems: 'center' } : { display: 'none' }}>
                    <div className="col align-middle ml-3">
                        <div className="font-weight-bold">
                            <a
                                href={`${config.srpnetBaseUrl}customer-service/residential-electric/economy-price-plan`}
                                target="_blank">
                                {t("Economy Price Plan")}
                            </a>
                        </div>
                    </div>
                    <div className="col text-right text-muted" />
                </div>

                <div className="srp-card-details pt-2 pb-2 row" style={numOfEnrolledPrograms === 0 ? { alignItems: 'center' } : { display: 'none' }}>
                    <div className="col align-middle ml-3">{t("youre_not_enrolled_in_any_programs")}</div>
                </div>

                <Divider />
                <div className="srp-card-details pb-0" />
            </div>
        </div>
    )
}

ClosedAcctPrograms.propTypes = {
    programs: PropTypes.object,
    hasCommunitySolar: PropTypes.bool,
    hasEnergyScorecard: PropTypes.bool,
    numOfEnrolledPrograms: PropTypes.number,
    autopayInfo: PropTypes.object,
    budgetBillingAmount: PropTypes.number,
    isBudgetBillingActive: PropTypes.bool,
    customDueDay: PropTypes.number,
    isCustomDueDateEligible: PropTypes.bool,
    solarChoicePercentage: PropTypes.number,
    sharePledge: PropTypes.number,
    isCommercial: PropTypes.bool,
    t: PropTypes.func.isRequired,
}

export default ClosedAcctPrograms