import React from "react";
import PropTypes from 'prop-types'

import '../../styles/dashboard-page.css';
import '../../styles/login-page.css';

const RememberUser = ({rememberUser, rememberUserOnChange, t}) => (
    <div className="d-flex justify-content-start mt-4 align-items-center">
        <label className="switch keybd-navigable">
            <input type="checkbox" checked={rememberUser} onChange={rememberUserOnChange}
                aria-label={rememberUser ? "Yes, remember my username" : "No, don't remember my username"} />
            <span className="yesNoToggle"/>
        </label>
        <label className="text-muted ml-2">{t("Remember my username")}</label>
    </div>
)

RememberUser.propTypes = {
    rememberUser: PropTypes.bool.isRequired,
    rememberUserOnChange: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired
}

export default RememberUser