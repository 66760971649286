import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import MyAccountPageHeaderResponsive from './myaccount-page-header-responsive'

import { toggleServicePricePlanButton } from '../../actions/auth/navigation/page-header-actions'
import { rateMetaDataConstants } from '../../constants/rate-meta-data-constants'
import * as loadingStatus from '../../constants/loading-status-constants'

class MyAccountPageHeaderResponsiveContainer extends React.Component {

    render() {
        if (this.props.billAccountListStatus === loadingStatus.LOADING_STATUS_SUCCESS && this.props.accountManagerStatus === loadingStatus.LOADING_STATUS_SUCCESS) {
            return (
                <MyAccountPageHeaderResponsive
                    serviceAddress={this.props.serviceAddress}
                    currentRateCode={this.props.currentRateCode}
                    addressPricePlaceStyle={this.props.addressPricePlaceStyle}
                    arrowIcon={this.props.arrowIcon}
                    toggleServicePricePlanButtonOnClick={this.props.toggleServicePricePlanButtonOnClick}
                    selectedBillAccountDetails={this.props.selectedBillAccountDetails}
                    accountManager={this.props.accountManager}
                    defaultAccountManager={this.props.defaultAccountManager}
                    isSummaryMaster={this.props.rateMetaData.summaryBilling === rateMetaDataConstants.SUMMARY_BILLING_PARENT}
                    t={this.props.t}
                    i18n={this.props.i18n}
                />
            )
        }
        return <div />
    }
}

const mapStateToProps = state => {
    return { ...state.pageHeader, ...state.accountInfo.billAccount, ...state.rateMetaData }
}

const mapDispatchToProps = (dispatch) => {
    return {
        toggleServicePricePlanButtonOnClick: () => {
            dispatch(toggleServicePricePlanButton())
        }
    }
}

MyAccountPageHeaderResponsiveContainer.propTypes = {
    serviceAddress: PropTypes.object,
    currentRateCode: PropTypes.object,
    addressPricePlaceStyle: PropTypes.object,
    arrowIcon: PropTypes.string,
    toggleServicePricePlanButtonOnClick: PropTypes.func,
    selectedBillAccountDetails: PropTypes.object,
    accountManager: PropTypes.object,
    defaultAccountManager: PropTypes.object,
    billAccountListStatus: PropTypes.string,
    accountManagerStatus: PropTypes.string,
    rateMetaData: PropTypes.object,
    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(MyAccountPageHeaderResponsiveContainer)