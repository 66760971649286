import React from 'react'
import PropTypes from 'prop-types'
import { Navigate, useLocation } from 'react-router-dom'
import AccountInfoContainer from './account_info/account-info-container'
import BankInformationCard from './bank_information/bank-information-card'
import ContactInfoAndAlertsCard from './contact_info_and_alerts/contact-info-and-alerts-card'
import DocumentCenterContainer from './document-center-container'
import PendingTurnOnCard from '../common_pending_turn_on/pending-turn-on-card'

const ProfileAndSettingsPage = ({ isPendingTurnOn }) => {
    let locationHook = useLocation()
    let profilePageRegex = /^\/?myaccount\/[^/]+\/([^/]+)/i
    let profileMatch = profilePageRegex.exec(locationHook.pathname)
    let tabName = (profileMatch !== null) ? profileMatch[1] : ''

    switch (tabName.toLowerCase()) {
        case 'account':
            return <AccountInfoContainer />
        case 'alerts':
            return isPendingTurnOn ? <PendingTurnOnCard /> : <ContactInfoAndAlertsCard />
        case 'banks':
            return isPendingTurnOn ? <PendingTurnOnCard /> : <BankInformationCard />
        case 'documents':
            return isPendingTurnOn ? <PendingTurnOnCard /> : <DocumentCenterContainer />
        default:
            return <Navigate to="/myaccount/profile/account" replace={true} />
    }
}

ProfileAndSettingsPage.propTypes = {
    isPendingTurnOn: PropTypes.bool.isRequired
}

export default ProfileAndSettingsPage