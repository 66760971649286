import { formStopConstants } from '../../../constants/form-stop-constants'
import { billAccountConstants } from '../../../constants/bill-account-constants'
import { contactConstants } from '../../../constants/contact-constants'
import { rateMetaDataConstants } from '../../../constants/rate-meta-data-constants'
import * as loadingStatus from '../../../constants/loading-status-constants'
import { DateTime } from 'luxon'

const initialState = {
  isAccountDetailsShown: true,
  isReviewShown: false,

  selectedDate: getMinDate().toJSDate(),

  hasAccountsLoaded: false,

  bestContactEmail: '',
  bestContactPhone: '',
  serviceAddress: {},
  primaryEmail: '',
  primaryPhone: '',

  isMpowerAccount: false,
  mpowerSendBackBox: false,

  runValidation: false,
  submitSucceeded: false,
  submitTurnOffStatus: loadingStatus.LOADING_STATUS_INIT,
  holidayList: [],

  // Fields
  address1: '',
  address2: '',
  city: '',
  state: '',
  country: '',
  zip: '',
  phoneNumber: '',
  confirmPhoneNumber: '',
  email: '',
  confirmEmail: '',
  sendFinalBillAsEbill: false,

  firstName: "",
  lastName: "",
  middleName: "",
  businessName: "",
  dba: "",
  requestorsName: "",
  requestorsAssociaton: "",

  shouldSendPostage: false
}

function getPrimaryEmail(payload) {
  let primaryEmail = payload.filter(contact => contact.contactType === 1)[0]
  return (primaryEmail === undefined) ? '' : primaryEmail.contactAddress
}

function getPrimaryPhone(payload) {
  let primaryPhone = payload.filter(contact => contact.contactType === 2)[0]
  return (primaryPhone === undefined) ? '' : primaryPhone.contactAddress
}

function getMinDate() {
  let todayTenPM = DateTime.now().setZone('MST').startOf('day').plus({ hours: 22 })
  let isTodayFriday = DateTime.fromISO(todayTenPM).weekday === 5
  let isPastValidTime = DateTime.now().setZone('MST') > todayTenPM ? true : false

  let minDate = isPastValidTime ? DateTime.now().setZone('MST').plus({ days: 2 }) : DateTime.now().setZone('MST').plus({ days: 1 })
  let minDateForFriday = isPastValidTime ? DateTime.now().setZone('MST').plus({ days: 4 }) : DateTime.now().setZone('MST').plus({ days: 3 })

  return isTodayFriday ? minDateForFriday : minDate
}

export default function turnOffReducer(state = initialState, action) {
  switch (action.type) {
    case billAccountConstants.GET_ADDRESS_SUCCESS:
      return { ...state, serviceAddress: action.payload }
    case formStopConstants.SHOW_REVIEW:
      return {
        ...state,
        isReviewShown: true,
        isAccountDetailsShown: false,
        address1: action.formState.address1,
        address2: action.formState.address2,
        city: action.formState.city,
        state: action.formState.state,
        country: action.formState.country,
        zip: action.formState.zip,
        primaryPhone: action.formState.phoneNumber,
        confirmPhoneNumber: action.formState.confirmPhoneNumber,
        primaryEmail: action.formState.email,
        confirmEmail: action.formState.confirmEmail,
        isMpowerAccount: action.isMpower,
        requestorsName: action.formState.requestorsName,
        requestorsAssociaton: action.formState.requestorsAssociaton,
        sendFinalBillAsEbill: action.formState.sendFinalBillAsEbill
      }
    case formStopConstants.SHOW_DETAIL_FORM:
      return { ...state, isReviewShown: false, isAccountDetailsShown: true }
    case billAccountConstants.GET_LIST_SUCCESS:
      return { ...state, hasAccountsLoaded: true }
    case formStopConstants.CHANGE_STOP_DATE:
      return { ...state, selectedDate: action.selectedDate }
    case formStopConstants.CHANGE_ADDRESS1:
      return { ...state, address1: action.newAddress }
    case formStopConstants.CHANGE_ADDRESS2:
      return { ...state, address2: action.newAddress }
    case formStopConstants.CHANGE_PHONENUMBER:
      return { ...state, phoneNumber: action.phone }
    case formStopConstants.CHANGE_EMAIL:
      return { ...state, email: action.email }
    case formStopConstants.CHANGE_CITY:
      return { ...state, city: action.city }
    case formStopConstants.CHANGE_STATE:
      return { ...state, state: action.state }
    case formStopConstants.CHANGE_COUNTRY:
      return { ...state, country: action.country }
    case formStopConstants.CHANGE_ZIPCODE:
      return { ...state, zip: action.zip }
    case formStopConstants.CHANGE_CONFIRM_EMAIL:
      return { ...state, confirmEmail: action.email }
    case formStopConstants.CHANGE_CONFIRM_PHONE:
      return { ...state, confirmPhoneNumber: action.phone }
    case contactConstants.GET_CONTACTS_SUCCESS:
      return { ...state, primaryEmail: getPrimaryEmail(action.payload), primaryPhone: getPrimaryPhone(action.payload) }
    case rateMetaDataConstants.GET_RATE_META_DATA_SUCCESS:
      return {
        ...state,
        businessName: action.payload.displayName,
        firstName: action.payload.isBusinessOnResidentialPlan ? action.payload.displayName : action.payload.firstName,
        lastName: action.payload.lastName,
        middleName: action.payload.middleName
      }
    case billAccountConstants.GET_BILLING_ADDRESS_SUCCESS:
      return { ...state, dba: action.payload.dbaLineOne }
    case formStopConstants.RUN_VALIDATION_CHECK:
      return { ...state, runValidation: action.shouldRun }
    case formStopConstants.HOLIDAY_SUCCESS:
      return { ...state, holidayList: action.payload.holidays }
    case formStopConstants.SEND_ENVELOPE:
      return {
        ...state,
        mpowerSendBackBox: action.shouldSend,
        shouldSendPostage: action.shouldSend
      }
    case formStopConstants.SUBMIT_TURNOFF_SUCCESS:
      return {
        ...state,
        submitTurnOffStatus: action.payload.success ? loadingStatus.LOADING_STATUS_SUCCESS : loadingStatus.LOADING_STATUS_FAILURE,
        submitSucceeded: action.payload.success,
        isReviewShown: action.payload.success ? false : true,
        isAccountDetailsShown: false
      }
    case formStopConstants.SUBMIT_TURNOFF_REQUEST:
      return { ...state, submitTurnOffStatus: loadingStatus.LOADING_STATUS_IN_PROGRESS }
    case formStopConstants.SUBMIT_TURNOFF_FAILURE:
      return {
        ...state,
        submitTurnOffStatus: loadingStatus.LOADING_STATUS_FAILURE,
        submitSucceeded: false
      }
    case billAccountConstants.SELECT_BILL_ACCOUNT_SUCCESS:
      return { ...initialState }
    default:
      return state
  }
}