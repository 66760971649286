import React from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'

const StopServiceStepper = ({currentStep, t}) => {
  const stepLabels = [t("Account details")
    , t("Review")
  ]
  return (
    <div className="pb-4">
      <div className="d-lg-block d-none">
        <Stepper activeStep={currentStep} className="p-0">
          {stepLabels.map((label) => {
            return (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
      </div>
      <div className="d-lg-none d-block">
        <Stepper activeStep={currentStep} alternativeLabel className="p-0">
          {stepLabels.map((label) => {
            return (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
      </div>
    </div>
  )
}

StopServiceStepper.propTypes = {
  currentStep: PropTypes.number,
  t: PropTypes.func
}

export default withTranslation('stopService')(StopServiceStepper)