import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

import CircularProgress from '@mui/material/CircularProgress'
import Button from '@mui/material/Button'
import Icon from '@mui/material/Icon'
import { DocumentTitle } from '../../../srp_modules/document-title'


import EconomyPricePlanFormik from './economy-price-plan-formik'
import EconomyPricePlanHelp from './ecomony-price-plan-help'

import AccountInfoBarContainer from '../../myaccount_header/info_bar/account-info-bar-container'
import CallAnytime from '../../myaccount_header/call-anytime'

import { getContacts } from '../../../actions/auth/contact/contact-actions'
import { getCustomerNames, getServiceAddress, getPhoneNumber } from '../../../actions/auth/bill_account/bill-account-actions'
import { getEnrolledProgramsDetails } from '../../../actions/auth/payment/payment-actions'
import { submitEPPSignupForm } from '../../../actions/auth/forms/economy-price-plan-actions'

import eppStatusModule from '../../../srp_modules/epp-status-module'

import { economyPricePlanConstants } from '../../../constants/economy-price-plan-constants'
import * as loadingStatus from '../../../constants/loading-status-constants'

import format from '../../../srp_modules/format'
import { withRouter } from '../../../srp_modules/with-router'


class EconomyPricePlanSignupContainerPure extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            initialChangedState: false
        }

        this.onSubmitEPPForm = this.onSubmitEPPForm.bind(this)
        this.onStateChanged = this.onStateChanged.bind(this)
    }

    componentDidMount() {
        if (this.props.selectedBillAccount === 0) {
            return
        }

        if (this.props.customerNamesStatus === loadingStatus.LOADING_STATUS_INIT
            || this.props.customerNamesStatus === loadingStatus.LOADING_STATUS_FAILURE) {
            this.props.getCustomerNamesInfo(this.props.selectedBillAccount)
        }
        if (this.props.contactsStatus === loadingStatus.LOADING_STATUS_INIT
            || this.props.contactsStatus === loadingStatus.LOADING_STATUS_FAILURE) {
            this.props.getContactInfo(this.props.selectedBillAccount)
        }
        if (this.props.serviceAddressStatus === loadingStatus.LOADING_STATUS_INIT
            || this.props.serviceAddressStatus === loadingStatus.LOADING_STATUS_FAILURE) {
            this.props.getServiceAddressInfo(this.props.selectedBillAccount)
        }

        if (this.props.phoneNumberStatus === loadingStatus.LOADING_STATUS_INIT
            || this.props.phoneNumberStatus === loadingStatus.LOADING_STATUS_FAILURE) {
            this.props.getBestPhone(this.props.selectedBillAccount)
        }

        if (this.props.enrolledProgramsDetailsStatus === loadingStatus.LOADING_STATUS_INIT
            || this.props.enrolledProgramsDetailsStatus === loadingStatus.LOADING_STATUS_FAILURE) {
            this.props.getEnrolledProgramsDetails(this.props.selectedBillAccount)
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.selectedBillAccount === prevProps.selectedBillAccount || this.props.selectedBillAccount === 0)
            return

        if (this.props.customerNamesStatus !== loadingStatus.LOADING_STATUS_IN_PROGRESS) {
            this.props.getCustomerNamesInfo(this.props.selectedBillAccount)
        }

        if (this.props.contactsStatus !== loadingStatus.LOADING_STATUS_IN_PROGRESS) {
            this.props.getContactInfo(this.props.selectedBillAccount)
        }

        if (this.props.serviceAddressStatus !== loadingStatus.LOADING_STATUS_IN_PROGRESS) {
            this.props.getServiceAddressInfo(this.props.selectedBillAccount)
        }

        if (this.props.phoneNumberStatus !== loadingStatus.LOADING_STATUS_IN_PROGRESS) {
            this.props.getBestPhone(this.props.selectedBillAccount)
        }

        if (this.props.enrolledProgramsDetailsStatus !== loadingStatus.LOADING_STATUS_IN_PROGRESS) {
            this.props.getEnrolledProgramsDetails(this.props.selectedBillAccount)
        }
    }

    onStateChanged() {
        this.setState({
            initialChangedState: true
        })
    }

    isLoading() {
        return this.props.customerNamesStatus === loadingStatus.LOADING_STATUS_INIT || this.props.customerNamesStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS
            || this.props.contactsStatus === loadingStatus.LOADING_STATUS_INIT || this.props.contactsStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS
            || this.props.serviceAddressStatus === loadingStatus.LOADING_STATUS_INIT || this.props.serviceAddressStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS
            || this.props.phoneNumberStatus === loadingStatus.LOADING_STATUS_INIT || this.props.phoneNumberStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS
            || this.props.enrolledProgramsDetailsStatus === loadingStatus.LOADING_STATUS_INIT || this.props.enrolledProgramsDetailsStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS

    }

    getLanguageString() {
        if (this.props.i18n.language === 'es')
            return 'es-MX'

        return 'en-US'
    }

    createEPPModel(props, formValues) {
        let model = {
            billAccount: props.selectedBillAccount,
            peopleInHousehold: formValues.peopleInHousehold,
            householdIncome: formValues.householdIncome,
            firstName: props.customerNames.firstName ? props.customerNames.firstName : props.customerNames.displayName,
            lastName: props.customerNames.lastName,
            emailAddress: formValues.emailAddress,
            phoneNumber: format.formatPhoneNumber(props.phoneNumber),
            address1: props.serviceAddress.streetAddress,
            address2: '',
            city: props.serviceAddress.cityName,
            state: props.serviceAddress.state,
            zipCode: props.serviceAddress.zipCode,
            signature: formValues.signature,
            language: this.getLanguageString()
        }

        return model
    }

    onSubmitEPPForm(formValues, formActions) {
        let formDetails = this.createEPPModel(this.props, formValues)
        this.props.submitEPPForm(formDetails, formActions)
    }

    render() {
        let formComponent = null

        if (this.isLoading()) {
            formComponent = <div className="d-flex justify-content-center"><CircularProgress size={80} thickness={5} style={{ marginTop: "20%", marginBottom: "20%" }} /></div>
        }
        else {
            let canApply = eppStatusModule.getEppApplyEligibility(this.props.selectedBillAccountDetails,
                this.props.enrolledProgramsDetails.economicPricePlan, this.props.customerNames)

            formComponent = (
                <React.Fragment>
                    <EconomyPricePlanFormik
                        customerName={this.props.customerNames.displayName}
                        formattedBillAccount={format.formatBillAccountNumber(this.props.selectedBillAccount)}
                        serviceAddress={this.props.serviceAddress}
                        formattedPhoneNumber={format.formatPhoneNumber(this.props.phoneNumber)}
                        emailAddress={this.props.customerEmailAddress}
                        formType={economyPricePlanConstants.ECONOMY_PRICE_PLAN_SIGNUP}
                        submitEPPForm={this.onSubmitEPPForm}
                        submitEPPFormStatus={this.props.submitEPPFormStatus}
                        initialChangedState={this.state.initialChangedState}
                        onStateChanged={this.onStateChanged}
                        eppData={this.state.eligibilityData}
                        canApply={canApply}
                        eppIncomeThresholds={this.props.enrolledProgramsDetails.economicPricePlan.eppIncomeThresholds}
                    />
                </React.Fragment>
            )
        }

        return (
            <DocumentTitle title={this.props.t("Economy_Price_Plan_Browser_Tab_Title")}>
                <div>
                    <div className="d-block d-md-none">
                        <Button
                            variant="contained"
                            fullWidth
                            className="justify-content-start nav-button-responsive"
                            onClick={() => this.props.router.navigate(-1)}
                            disableRipple>
                            <Icon className="material-icons mr-2" style={{ color: 'rgb(33,108,224' }}>keyboard_backspace</Icon>
                            {this.props.t("EPP_Previous_Page_Button")}
                        </Button>
                    </div>
                    <div className="container py-3">
                        <CallAnytime />
                        <h3 className="d-lg-block d-none mt-3 mb-4 pb-4">{this.props.t("Economy_Price_Plan_Signup_Page_Title")}</h3>
                        <h4 className="d-lg-none d-block mt-3 mb-4">{this.props.t("Economy_Price_Plan_Signup_Page_Title")}</h4>

                        <AccountInfoBarContainer disableSelector={true} />

                        <div className="d-none d-md-block mb-4">
                            <h5 className="d-flex justify-content-start align-items-center">
                                <button className="displayAsLink" onClick={() => this.props.router.navigate(-1)}>{this.props.t("EPP_Previous_Page_Button")} </button>
                                <i className="material-icons" style={{ color: 'rgba(72, 72, 74, 0.376)' }} >keyboard_arrow_right</i>
                                <span style={{ color: '#707070' }}>{this.props.t("Economy_Price_Plan_Signup_Page_Title")}</span>
                            </h5>
                        </div>

                        <div className="row">
                            <div className="col-lg-6 col-12">
                                <div className="srp-card-header">
                                    {this.props.submitEPPFormStatus !== loadingStatus.LOADING_STATUS_SUCCESS
                                        ? this.props.t("Please_provide_your_information")
                                        : this.props.t("EPP_Submitted_Confirmation")
                                    }

                                </div>

                                <div className="srp-card-body text-muted">
                                    {formComponent}
                                </div>
                            </div>
                            <div className="col-lg-6 col-12 mt-lg-0 mt-4">
                                <EconomyPricePlanHelp />
                            </div>
                        </div>
                    </div>
                </div>
            </DocumentTitle>
        )
    }
}


const mapStateToProps = state => {
    return {
        selectedBillAccount: state.accountInfo.billAccount.selectedBillAccount,
        selectedBillAccountDetails: state.accountInfo.billAccount.selectedBillAccountDetails,
        isCommercial: state.accountInfo.billAccount.selectedBillAccountDetails.isCommercial,
        customerNames: state.accountInfo.billAccount.customerNames,
        customerNamesStatus: state.accountInfo.billAccount.customerNamesStatus,
        serviceAddress: state.accountInfo.billAccount.serviceAddress,
        serviceAddressStatus: state.accountInfo.billAccount.serviceAddressStatus,
        contactsStatus: state.accountInfo.contact.contactsStatus,
        customerEmailAddress: state.accountInfo.contact.primaryEmail,
        phoneNumber: state.accountInfo.billAccount.phoneNumber.bestPhone,
        phoneNumberStatus: state.accountInfo.billAccount.phoneNumberStatus,
        enrolledProgramsDetailsStatus: state.accountInfo.payment.enrolledProgramsDetailsStatus,
        enrolledProgramsDetails: state.accountInfo.payment.enrolledProgramsDetails,
        ...state.economyPricePlan,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getContactInfo: (billAccount) => {
            dispatch(getContacts(billAccount))
        },
        getCustomerNamesInfo: (billAccount) => {
            dispatch(getCustomerNames(billAccount))
        },
        getServiceAddressInfo: (billAccount) => {
            dispatch(getServiceAddress(billAccount))
        },
        getBestPhone: (billAccount) => {
            dispatch(getPhoneNumber(billAccount))
        },
        getEnrolledProgramsDetails: (billAccount) => {
            dispatch(getEnrolledProgramsDetails(billAccount))
        },
        submitEPPForm: async (formDetails, formActions) => {
            const res = await dispatch(submitEPPSignupForm(formDetails))
            const submitSuccessfully = (res.payload?.submitSuccess === true && res.payload?.emailSuccess === true)
            formActions.setStatus({ submitError: !submitSuccessfully })
            formActions.setSubmitting(false)
        }
    }
}

EconomyPricePlanSignupContainerPure.propTypes = {
    router: PropTypes.shape({
        navigate: PropTypes.func
    }),
    selectedBillAccount: PropTypes.number,
    selectedBillAccountDetails: PropTypes.object,

    customerNames: PropTypes.object,
    customerNamesStatus: PropTypes.string,
    getCustomerNamesInfo: PropTypes.func,

    contactsStatus: PropTypes.string,
    getContactInfo: PropTypes.func,
    customerEmailAddress: PropTypes.string,

    serviceAddressStatus: PropTypes.string,
    serviceAddress: PropTypes.object,
    getServiceAddressInfo: PropTypes.func,

    phoneNumberStatus: PropTypes.string,
    phoneNumber: PropTypes.string,
    getBestPhone: PropTypes.func,

    enrolledProgramsDetails: PropTypes.object,
    enrolledProgramsDetailsStatus: PropTypes.string,
    getEnrolledProgramsDetails: PropTypes.func,

    submitEPPForm: PropTypes.func,
    submitEPPFormStatus: PropTypes.string,

    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired
}

const EconomyPricePlanSignupContainer = withTranslation("economyPricePlanSignupForm")(EconomyPricePlanSignupContainerPure)
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EconomyPricePlanSignupContainer))