import React from 'react'
import PropTypes from 'prop-types'
import { Trans } from 'react-i18next'
import '../../styles/landlord.css'
import { DateTime } from 'luxon'
import { NumericFormat } from 'react-number-format'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import Icon from '@mui/material/Icon'
import IconButton from '@mui/material/IconButton'
import TextField from '@mui/material/TextField'
import utils from '../../srp_modules/utils'

import ExportAndPaymentButtons from './export-and-payment-buttons'

let initialState = {
    currentProperty: 0,
    propertiesPerPage: 1,
    filteredPropertyList: []
}

class LandlordAgreementPaginated extends React.Component {

    constructor(props) {
        super(props)

        this.state = initialState

        this.changePageOnClick = this.changePageOnClick.bind(this)
        this.filterProperties = this.filterProperties.bind(this)
        this.clickProperty = this.clickProperty.bind(this)
        this.blurSearch = this.blurSearch.bind(this)
    }

    blurSearch(event) {
        event.target.value = ''
    }

    filterProperties(event) {

        let filteredList = []

        this.props.landlordDetails.landlordProperties.forEach((propertyDetail, index) => {

            if (event.target.value !== '' && propertyDetail.serviceAddress.streetAddress.toLocaleLowerCase().indexOf(event.target.value.toLocaleLowerCase()) > -1) {

                filteredList.push(
                    {
                        index: index,
                        address: propertyDetail.serviceAddress.streetAddress
                    }
                )
            }
        })

        this.setState({ filteredPropertyList: filteredList })
    }

    clickProperty(propertyIndex) {
        this.setState({ ...this.state, currentProperty: propertyIndex, filteredPropertyList: [] })
    }

    changePageOnClick(nextProperty) {
        return () => {
            this.setState({ ...this.state, currentProperty: nextProperty })
        }
    }

    render() {
        const t = this.props.t
        const i18n = this.props.i18n

        let historicalDataDescription = null

        let sortedLandlordDetails = [...this.props.landlordDetails.landlordProperties].sort((a, b) => { return utils.serviceAddressSort(a.serviceAddress, b.serviceAddress) })

        let propertyDetail = sortedLandlordDetails[this.state.currentProperty]

        let billInfo = {
            hasBillInfo: false, dueDate: undefined, balance: undefined,
            hasMostRecentPayments: false, lastPaymentDate: undefined, amountPaid: undefined
        }

        if (this.props.landlordBillInfo[`${propertyDetail.billAccount}`]) {
            billInfo.hasBillInfo = this.props.landlordBillInfo[`${propertyDetail.billAccount}`].isEmpty === false;
            billInfo.dueDate = this.props.landlordBillInfo[`${propertyDetail.billAccount}`].currentDueDate;
            billInfo.balance = this.props.landlordBillInfo[`${propertyDetail.billAccount}`].balance;
        }

        if (this.props.landlordMostRecentPayments[`${propertyDetail.billAccount}`]) {
            billInfo.hasMostRecentPayments = this.props.landlordMostRecentPayments[`${propertyDetail.billAccount}`].isEmpty === false;
            billInfo.lastPaymentDate = this.props.landlordMostRecentPayments[`${propertyDetail.billAccount}`].lastPaymentDate;
            billInfo.amountPaid = this.props.landlordMostRecentPayments[`${propertyDetail.billAccount}`].amountPaid;
        }

        let serviceAddressText = null

        if (!propertyDetail.billAccount) {
            serviceAddressText = (<div>{propertyDetail.serviceAddress.streetAddress}<span className="text-danger"> *</span></div>)

            historicalDataDescription = (<div className="pt-3"><span className="text-danger">* </span>{t("No historical data available for bill accounts closed for more than two years.")}</div>)
        }
        else {
            if (this.props.billAccountSet.has(propertyDetail.billAccount)) {
                serviceAddressText = (<button className="displayAsLink" onClick={() => this.props.switchToBillAccount(propertyDetail.billAccount)}>{propertyDetail.serviceAddress.streetAddress}</button>)
            }
            else {
                serviceAddressText = (
                    <div>{propertyDetail.serviceAddress.streetAddress}
                        <IconButton
                            color="primary"
                            onClick={this.props.addLandlordBillAccount(propertyDetail.billAccount, propertyDetail.serviceAddress.streetAddress, t)}
                            className="button-focus srp-icon-color ml-1 mr-1 align-middle"
                            size="large">
                            <Icon>add_circle_outline</Icon>
                        </IconButton>
                    </div>)
            }
        }

        let pagination = null
        let billingAddressSearch = null
        let filteredList = null

        if (sortedLandlordDetails.length > this.state.propertiesPerPage) {

            pagination = (<div className="row p-2 pt-3">
                <div className="col-lg d-flex justify-content-between">
                    <span className="d-lg-none text-nowrap">
                        <button className="btn srp-btn btn-lightblue"
                            onClick={this.changePageOnClick(this.state.currentProperty - 1)}
                            disabled={this.state.currentProperty < 1}>
                            <strong>{" < "}</strong>
                        </button>
                    </span>

                    <span className="d-lg-none text-nowrap">
                        <button className="btn srp-btn btn-lightblue"
                            onClick={this.changePageOnClick(this.state.currentProperty + 1)}
                            disabled={(this.state.currentProperty + 1) * this.state.propertiesPerPage >= sortedLandlordDetails.length}>
                            <strong>{" > "}</strong>
                        </button>
                    </span>
                </div>
            </div>)

            if (this.state.filteredPropertyList.length > 0) {
                filteredList = (
                    <ul id="filtered-list" className="border bg-light">
                        {this.state.filteredPropertyList.map((a) => {
                            return (<li className="p-2 filtered-item" key={a.index} onClick={() => { this.clickProperty(a.index) }}>
                                {a.address}
                            </li>)
                        })}
                    </ul>
                )
            }

            billingAddressSearch = (<div className="pt-2 w-100">
                <TextField
                    fullWidth={true}
                    id="propertyfilter"
                    onChange={this.filterProperties}
                    onBlur={this.blurSearch}
                    InputProps={{ endAdornment: <Icon className="srp-icon-color">search</Icon> }} />
                {filteredList}
            </div >)
        }

        return (
            <div className="w-100" >
                <Trans i18nKey="Landlord_agreement_n_began_on_date" t={t}>
                    Landlord agreement <strong>{{landlordAgreementNumber: this.props.landlordDetails.landlordAgreementNumber}}</strong> began
                    on {{beginDate: DateTime.fromISO(this.props.landlordDetails.contractStartDate).toFormat('MMM d, yyyy')}}
                </Trans>

                <ExportAndPaymentButtons exportCallback={this.props.exportCallback} t={t} i18n={i18n} />

                {billingAddressSearch}
                <div className="pt-4">
                    <Table>
                        <TableBody className="srp-table-body">
                            <TableRow>
                                <TableCell padding="none">{t("Property address")}</TableCell>
                                <TableCell className="text-right" padding="none"> {serviceAddressText} </TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell padding="none">{t("Current Responsible Party")}</TableCell>
                                <TableCell className="text-right" padding="none">{this.props.GetPropertyState(propertyDetail)}</TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell padding="none">{t("Landlord Turn on Date")}</TableCell>
                                <TableCell className="text-right" padding="none">
                                    {this.props.isVacated(propertyDetail) ? DateTime.fromISO(propertyDetail.startDate).toFormat('MMM d, yyyy') : '--'}
                                </TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell padding="none">{t("Due date")}</TableCell>
                                <TableCell className="text-right" padding="none">
                                    {billInfo.hasBillInfo && billInfo.balance > 0 ? DateTime.fromISO(billInfo.dueDate).toFormat('MMM d, yyyy') : '--'}
                                </TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell padding="none">{t("Amount due")}</TableCell>
                                <TableCell className="text-right" padding="none">
                                {billInfo.balance > 0 ?
                                        (<NumericFormat
                                            value={billInfo.balance}
                                            valueIsNumericString={true}
                                            displayType="text"
                                            thousandSeparator={true}
                                            prefix={'$'}
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                        />) : '--'}
                                </TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell padding="none">{t("Last payment")}</TableCell>
                                <TableCell className="text-right" padding="none">
                                    {billInfo.hasMostRecentPayments ?
                                        DateTime.fromISO(billInfo.lastPaymentDate).toFormat('MMM d, yyyy') : '--'}
                                </TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell padding="none">{t("Amount paid")}</TableCell>
                                <TableCell className="text-right" padding="none">
                                    {billInfo.hasMostRecentPayments ?
                                        <NumericFormat
                                            value={Math.abs(billInfo.amountPaid)}
                                            valueIsNumericString={true}
                                            displayType="text"
                                            thousandSeparator={true}
                                            prefix={'$'}
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                        /> : '--'}
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </div >

                {pagination}

                <div className="pt-3">{t("Number_of_accounts", {count: sortedLandlordDetails.length})}</div>
                {historicalDataDescription}
            </div >
        )
    }
}

LandlordAgreementPaginated.propTypes = {
    billAccountSet: PropTypes.object,
    landlordDetails: PropTypes.object.isRequired,
    landlordBillInfo: PropTypes.object.isRequired,
    landlordMostRecentPayments: PropTypes.object.isRequired,
    addLandlordBillAccount: PropTypes.func.isRequired,
    switchToBillAccount: PropTypes.func.isRequired,
    isVacated: PropTypes.func.isRequired,
    exportCallback: PropTypes.func.isRequired,
    GetPropertyState: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired,
}

export default LandlordAgreementPaginated