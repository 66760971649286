export const displaySizeConstants = {
    MOBILE_SMALL: 'MOBILE_SMALL',
    MOBILE: 'MOBILE',
    EXTRA_SMALL: 'EXTRA_SMALL',
    SMALL: 'SMALL',
    TABLET_SMALL: 'TABLET_SMALL',
    TABLET_MEDIUM: 'TABLET_MEDIUM',
    TABLET_LARGE: 'TABLET_LARGE',
    MEDIUM: 'MEDIUM',
    LARGE: 'LARGE',
    EXTRA_LARGE: 'EXTRA_LARGE'
}