import React from "react"
import { withTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import Card from "@mui/material/Card"

import PayUsingBankAccountDiv from './pay-using-bank-account-div'
import PayByCreditCardLinkConnectedContainer from './pay-by-credit-card-link-container'
import PayByCashCardLink from '../../cash_card_page/common_payment_page/pay-by-cash-card-link'

const MakePurchaseByCardPure = ({t}) => (
    <div>
        <label className="h5">{t('Make purchase by')}</label>
        <Card className="d-none d-lg-block p-4">
            <div className="payment-nav nav nav-pills nav-justified flex-nowrap">
                <div className="nav-item">
                    <PayUsingBankAccountDiv />
                </div>
                <div className="nav-item">
                    <PayByCreditCardLinkConnectedContainer />
                </div>
                <div className="nav-item">
                    <PayByCashCardLink navPath="/myaccount/payment/single/mpower/cash" />
                </div>
            </div>
        </Card>
        <Card className="d-block d-lg-none pt-4 pb-4 pl-2 pr-2">
            <div className="payment-nav nav nav-pills nav-filled">
                <div className="nav-item">
                    <PayUsingBankAccountDiv />
                </div>
                <div className="nav-item">
                    <PayByCreditCardLinkConnectedContainer />
                </div>
                <div className="nav-item">
                    <PayByCashCardLink navPath="/myaccount/payment/single/mpower/cash" />
                </div>
            </div>
        </Card>
    </div>
)

MakePurchaseByCardPure.propTypes = {
    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired
}

const MakePurchaseByCard = withTranslation("singlePurchasePage")(MakePurchaseByCardPure)
export { MakePurchaseByCard as default, MakePurchaseByCardPure }