export const evCommunityConstants = {
    GET_VIN_INFORMATION_REQUEST: "GET_VIN_INFORMATION_REQUEST",
    GET_VIN_INFORMATION_SUCCESS: "GET_VIN_INFORMATION_SUCCESS",
    GET_VIN_INFORMATION_FAILURE: "GET_VIN_INFORMATION_FAILURE",

    SUBMIT_EV_FORM_REQUEST: "SUBMIT_EV_FORM_REQUEST",
    SUBMIT_EV_FORM_SUCCESS: "SUBMIT_EV_FORM_SUCCESS",
    SUBMIT_EV_FORM_FAILURE: "SUBMIT_EV_FORM_FAILURE",

    RESET_FORM_SUBMISSIONS_STATUS: "RESET_FORM_SUBMISSIONS_STATUS",

    EV_COMMUNITY_PLAN_SIGNUP: 0,

    EV_COMMUNITY_SIGNUP_ERROR_NONE: 0,
    EV_COMMUNITY_SIGNUP_ERROR_VIN_LOOKUP: 1,
    EV_COMMUNITY_SIGNUP_ERROR_INVALID_VIN: 2,
    EV_COMMUNITY_SIGNUP_ERROR_NOT_EV: 3,
    EV_COMMUNITY_SIGNUP_ERROR_PREVIOUS_REGISTRATION_BA: 4,
    EV_COMMUNITY_SIGNUP_ERROR_PREVIOUS_REGISTERED_NEW: 5,
    EV_COMMUNITY_SIGNUP_ERROR_UNKNOWN: 100,

    CHARGER_LEVEL_1: "Level 1",
    CHARGER_LEVEL_2: "Level 2",

    GET_UNAPPROVED_VEHICLETYPES_REQUEST: "GET_UNAPPROVED_VEHICLETYPES_REQUEST",
    GET_UNAPPROVED_VEHICLETYPES_SUCCESS: "GET_UNAPPROVED_VEHICLETYPES_SUCCESS",
    GET_UNAPPROVED_VEHICLETYPES_FAILURE: "GET_UNAPPROVED_VEHICLETYPES_FAILURE"
}