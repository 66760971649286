import { combineReducers } from 'redux';
import paymentReducer from './payment/payment-reducer'
import contactReducer from './contact/contact-reducer'
import notificationReducer from './notification/notification-reducer'
import usageReducer from './usage/usage-reducer'
import usagePageUIReducer from './usage/usage-page-ui-reducer'
import outageReducer from './outage/outage-reducer'
import reportOutageReducer from './outage/report-outage-reducer'
import billAccountReducer from './bill-account/bill-account-reducer'
import giftPaymentMultiReducer from './payment/multi_payment/gift-payment-multi-reducer'
import documentCenterReducer from './document/document-center-reducer'
import autopayReduccer from './payment/autopay-reducer'

const accountInfoReducer = combineReducers({
    billAccount: billAccountReducer,
    contact: contactReducer,
    notification: notificationReducer,
    payment: paymentReducer,
    giftPaymentMulti: giftPaymentMultiReducer,
    usage: usageReducer,
    usagePageUI: usagePageUIReducer,
    outage: outageReducer,
    reportOutage: reportOutageReducer,
    documentCenter: documentCenterReducer,
    autopay: autopayReduccer
})

export default accountInfoReducer