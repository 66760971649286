import { bankAccountConstants } from '../../../constants/bank-account-constants'
import { giftPaymentApiConstants } from '../../../constants/gift/gift-payment-api-constants'
import { singlePurchaseApiConstants } from '../../../constants/single-purchase/single-purchase-api-constants'
import { multiPaymentNgpConstants } from '../../../constants/payment/multi-payment-ngp-constants'
import * as loadingStatus from '../../../constants/loading-status-constants'
import validate from '../../../srp_modules/validate'

const initialState = {
    bankAccountList: [],
    bankAccountListStatus: loadingStatus.LOADING_STATUS_INIT,
    selectedBankAccountId: 0,
    selectedBankAccountInfo: {},
    addBankAccountInfo: {
        firstName: '',
        firstNameError: false,
        lastName: '',
        lastNameError: false,
        phoneNumber: '',
        phoneNumberError: false,
        emailAddress: '',
        emailAddressError: false,
        routingNumber: 0,
        routingNumberError: false,
        bankAccountNumber: '',
        bankAccountNumberError: false,
        nickName: '',
    },
    addBankAccountStatus: loadingStatus.LOADING_STATUS_INIT,
    addBankAccountResult: {
        success: false,
        bankAccountId: '',
        message: '',
        echexErrorCode: '',
        bankAccountError: '',
        routingNumberError: ''
    },

    //profile & settings bank info
    showDeleteBankModule: false,
    bankAccountToDelete: {
        bankName: '',
        nickName: '',
        bankAccountNumber: 0,
        bankId: 0,
        bankIdToken: ''
    },
    deleteBankAccountStatus: loadingStatus.LOADING_STATUS_INIT,
    deleteBankAccountError: [{
        error: false,
        bankId: 0
    }],
    showDeleteBankErrorModule: false,
    bankAccountToEdit: {
        routingNumber: 0,
        bankAccountNumber: '',
        nickName: '',
        bankId: 0,
        bankIdToken: '',
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        isDefault: false,
        isPhoneEligible: false,
        bankName: '',
        canDelete: true,
    },
    editBankAccountStatus: loadingStatus.LOADING_STATUS_INIT
}

let sortBankAccountList = (bankAccountList) => {
    let newBankAccountList = bankAccountList.sort((a,b) => {
        if (!a.nickName && !b.nickName) {
            let sortOrder = a.bankName.localeCompare(b.bankName, 'en', { numeric: true})

            if (sortOrder === 0) {
                sortOrder = a.bankAccountNumber.localeCompare(b.bankAccountNumber, 'en', { numeric: true })
            }

            return sortOrder
        }

        if (!a.nickName)
            return 1

        if (!b.nickName)
            return -1

        return a.nickName.localeCompare(b.nickName, 'en', { numeric: true})
    })

    return newBankAccountList
}

let getSelectedBankAccountInfo = (bankAccountList, selectedBankAccountId) => {
    let selectedBankAccountInfo = {}
    if (selectedBankAccountId > 0) {
        selectedBankAccountInfo = bankAccountList.find((bankAccount) => bankAccount.bankId === selectedBankAccountId)
    }

    return selectedBankAccountInfo
}

export default function bankAccountsReducer(state = initialState, action) {
    switch (action.type) {
        case bankAccountConstants.GET_BANK_ACCOUNT_LIST_REQUEST:
            return { ...state, bankAccountListStatus: loadingStatus.LOADING_STATUS_IN_PROGRESS }
        case bankAccountConstants.GET_BANK_ACCOUNT_LIST_SUCCESS: {
            const defaultBankAccount = action.payload.find((bankAccount) => bankAccount.isDefault)
            let defaultBankAccountId = (state.addBankAccountResult.bankAccountId !== undefined && state.addBankAccountResult.bankAccountId.length > 0)
                                     ? state.addBankAccountResult.bankAccountId
                                     : defaultBankAccount
                                        ? defaultBankAccount.bankId
                                        : action.payload.length > 0 ? action.payload[0].bankId : initialState.selectedBankAccountId
            return { ...state,
                    bankAccountList: sortBankAccountList(action.payload),
                    selectedBankAccountId: defaultBankAccountId,
                    selectedBankAccountInfo: getSelectedBankAccountInfo(action.payload, defaultBankAccountId),
                    bankAccountListStatus: loadingStatus.LOADING_STATUS_SUCCESS,

                    bankAccountToDelete: initialState.bankAccountToDelete,
                    deleteBankAccountStatus: initialState.deleteBankAccountStatus,
                    deleteBankAccountError: initialState.deleteBankAccountError }
        }
        case bankAccountConstants.GET_BANK_ACCOUNT_LIST_FAILURE:
            return { ...state, bankAccountList: initialState.bankAccountList, selectedBankAccountId: initialState.selectedBankAccountId, bankAccountListStatus: loadingStatus.LOADING_STATUS_FAILURE  }

        case multiPaymentNgpConstants.GET_SELECTED_BANK_ACCOUNT_MULTI_NGP:
        case bankAccountConstants.CHANGE_SELECTED_BANK_ACCOUNT_SUCCESS:
            return { ...state, selectedBankAccountId: action.bankAccountId, selectedBankAccountInfo: getSelectedBankAccountInfo(state.bankAccountList, action.bankAccountId) }

        case bankAccountConstants.POPULATE_ADD_BANK_ACCOUNT:
            return { ...state, addBankAccountInfo: {
                    ...state.addBankAccountInfo,
                    firstName: action.addBankInfo.firstName,
                    lastName: action.addBankInfo.lastName,
                    phoneNumber: action.addBankInfo.phoneNumber,
                    emailAddress: action.addBankInfo.emailAddress,
                    routingNumber: action.addBankInfo.routingNumber,
                    bankAccountNumber: action.addBankInfo.bankAccountNumber,
                    nickName: action.addBankInfo.nickName,
                }
            }

        // add bank account
        case bankAccountConstants.ADD_BANK_EARLY_WARNING_REQUEST:
            return { ...state, addBankAccountStatus: loadingStatus.LOADING_STATUS_IN_PROGRESS }

        case bankAccountConstants.ADD_BANK_EARLY_WARNING_SUCCESS: {
            if (action.payload.isSuccess === false)
                return { ...state, addBankAccountResult: initialState.addBankAccountResult,
                    addBankAccountStatus: loadingStatus.LOADING_STATUS_SUCCESS }

            const newlySavedBank = action.payload.savedBankAccount

            const bankAccountListCopy = JSON.parse(JSON.stringify(state.bankAccountList))
            const savedBankAccountId = newlySavedBank.bankAccountId
            const index = bankAccountListCopy.findIndex(ba => ba.bankId === savedBankAccountId)
            if (index > -1) {
                // remove old bank account with the same funding source ID
                bankAccountListCopy.splice(index, 1)
            }

            if (newlySavedBank.isDefaultBankAccount === true)
                bankAccountListCopy.forEach(bankAccount => bankAccount.isDefault = false)
            if (newlySavedBank.canBeUsedWithIntegratedVoiceResponse === true)
                bankAccountListCopy.forEach(bankAccount => bankAccount.isPhoneEligible = false)

            let normalizedBankInfo = {
                routingNumber: newlySavedBank.bankRoutingNumber,
                accountNumber: newlySavedBank.truncatedBankAccountNumber,
                bankAccountNumber: newlySavedBank.truncatedBankAccountNumber,
                nickName: newlySavedBank.nicknameForBankAccount,
                bankId: newlySavedBank.bankAccountId,
                bankIdToken: newlySavedBank.bankAccountIdToken,
                firstName: newlySavedBank.firstNameOnBankAccount,
                lastName: newlySavedBank.lastNameOnBankAccount,
                email: newlySavedBank.signerEmailAddress,
                phoneNumber: newlySavedBank.signerPhoneNumberTenDigits,
                isDefault: newlySavedBank.isDefaultBankAccount,
                isPhoneEligible: newlySavedBank.canBeUsedWithIntegratedVoiceResponse,
                bankName: newlySavedBank.bankName,
            }
            const bankAccountList = sortBankAccountList([...bankAccountListCopy, normalizedBankInfo])

            return { ...state,
                addBankAccountResult: action.payload,
                selectedBankAccountId: savedBankAccountId,
                addBankAccountStatus: loadingStatus.LOADING_STATUS_SUCCESS,
                bankAccountList: bankAccountList
            }
        }

        case bankAccountConstants.ADD_BANK_EARLY_WARNING_FAILURE:
            return { ...state, addBankAccountStatus: loadingStatus.LOADING_STATUS_FAILURE, addBankAccountResult: initialState.addBankAccountResult,
                addBankAccountInfo: {
                    ...state.addBankAccountInfo,
                    firstNameError: !validate.text(state.addBankAccountInfo.firstName),
                    lastNameError: !validate.text(state.addBankAccountInfo.lastName),
                    phoneNumberError: !validate.phoneNumber(state.addBankAccountInfo.phoneNumber),
                    emailAddressError: !validate.emailAddress(state.addBankAccountInfo.emailAddress),
                    routingNumberError: !validate.routingNumber(state.addBankAccountInfo.routingNumber),
                    bankAccountNumberError: !validate.bankAccountNumber(state.addBankAccountInfo.bankAccountNumber)
                }
            }

        case bankAccountConstants.ADD_BANK_ACCOUNT_VALIDATION_FAILURE:
            return { ...state,
                addBankAccountInfo: {
                    ...state.addBankAccountInfo,
                    firstNameError: !validate.text(state.addBankAccountInfo.firstName),
                    lastNameError: !validate.text(state.addBankAccountInfo.lastName),
                    phoneNumberError: !validate.phoneNumber(state.addBankAccountInfo.phoneNumber),
                    emailAddressError: !validate.emailAddress(state.addBankAccountInfo.emailAddress),
                    routingNumberError: !validate.routingNumber(state.addBankAccountInfo.routingNumber),
                    bankAccountNumberError: !validate.bankAccountNumber(state.addBankAccountInfo.bankAccountNumber)
                }
            }

        case bankAccountConstants.GET_FIRST_NAME:
            return { ...state, addBankAccountInfo:{
                ...state.addBankAccountInfo, firstName: action.firstName, firstNameError: !validate.text(action.firstName) }
            }
        case bankAccountConstants.GET_LAST_NAME:
            return { ...state, addBankAccountInfo:{
                ...state.addBankAccountInfo, lastName: action.lastName, lastNameError: !validate.text(action.lastName) }
            }
        case bankAccountConstants.GET_PHONE_NUMBER:
            return { ...state, addBankAccountInfo:{
                ...state.addBankAccountInfo, phoneNumber: action.phoneNumber, phoneNumberError: !validate.phoneNumber(action.phoneNumber) }
            }
        case bankAccountConstants.GET_EMAIL_ADDRESS:
            return { ...state, addBankAccountInfo:{
                ...state.addBankAccountInfo, emailAddress: action.emailAddress, emailAddressError: !validate.emailAddress(action.emailAddress) }
            }
        case bankAccountConstants.GET_ROUTING_NUMBER:
            return { ...state, addBankAccountInfo:{
                ...state.addBankAccountInfo, routingNumber: action.routingNumber, routingNumberError: !validate.routingNumber(action.routingNumber) }
            }
        case bankAccountConstants.GET_BANK_ACCOUNT_NUMBER:
            return { ...state, addBankAccountInfo:{
                ...state.addBankAccountInfo, bankAccountNumber: action.bankAccountNumber, bankAccountNumberError: !validate.bankAccountNumber(action.bankAccountNumber) }
            }
        case bankAccountConstants.GET_NICKNAME:
            return { ...state, addBankAccountInfo:{
                ...state.addBankAccountInfo, nickName: action.nickName }
            }
        case bankAccountConstants.ADD_BANK_ACCOUNT_INFO_CHANGE:
            return { ...state,
                addBankAccountInfo: action.addBankAccountInfo
            }

        // add bank from gift payment page
        case giftPaymentApiConstants.GIFT_PAGE_ADD_BANK_ACCOUNT_REQUEST:
        case singlePurchaseApiConstants.SINGLE_PURCHASE_ADD_BANK_ACCOUNT_REQUEST:
            return state
        case giftPaymentApiConstants.GIFT_PAGE_ADD_BANK_ACCOUNT_FAILURE:
        case singlePurchaseApiConstants.SINGLE_PURCHASE_ADD_BANK_ACCOUNT_FAILURE:
            return state
        case giftPaymentApiConstants.GIFT_PAGE_ADD_BANK_ACCOUNT_SUCCESS:
        case singlePurchaseApiConstants.SINGLE_PURCHASE_ADD_BANK_ACCOUNT_SUCCESS: {
            if (action.payload.isSuccess === false)
                return state

            const bankAccountListCopy = [...state.bankAccountList]
            const savedBankAccountId = action.payload.savedBankAccount.bankAccountId
            const index = bankAccountListCopy.findIndex(ba => ba.bankId === savedBankAccountId)
            if (index > -1) {
                // remove old bank account with the same funding source ID
                bankAccountListCopy.splice(index, 1)
            }

            const savedBankAccount = action.payload.savedBankAccount
            const bankAccount = {
                routingNumber: savedBankAccount.routingNumber,
                accountNumber: savedBankAccount.truncatedBankAccountNumber,
                bankAccountNumber: savedBankAccount.truncatedBankAccountNumber,
                nickName: savedBankAccount.nickName,
                bankId: savedBankAccount.bankAccountId,
                bankIdToken: savedBankAccount.bankAccountIdToken,
                firstName: savedBankAccount.signerFirstName,
                lastName: savedBankAccount.signerLastName,
                email: savedBankAccount.signerEmailAddress,
                phoneNumber: savedBankAccount.signerPhoneNumber,
                isDefault: savedBankAccount.isDefaultBankAccount,
                isLocked: savedBankAccount.isLocked,
                isPhoneEligible: false,
                bankName: savedBankAccount.bankName,
            }

            return { ...state, bankAccountList: sortBankAccountList([...bankAccountListCopy, bankAccount]) }
        }

        //profile & settings bank info
        case bankAccountConstants.SHOW_DELETE_BANK_MODULE:
            return { ...state, showDeleteBankModule: true, bankAccountToDelete: action.bankToDelete }
        case bankAccountConstants.CLOSE_DELETE_BANK_MODULE:
            return { ...state, showDeleteBankModule: false }
        case bankAccountConstants.CLOSE_DELETE_BANK_ERROR_MODULE:
        return { ...state, showDeleteBankErrorModule: false }

        case bankAccountConstants.DELETE_BANK_ACCOUNT_REQUEST:
            return { ...state, deleteBankAccountStatus: loadingStatus.LOADING_STATUS_IN_PROGRESS }
        case bankAccountConstants.DELETE_BANK_ACCOUNT_SUCCESS:
            return { ...state,
                    deleteBankAccountError: state.deleteBankAccountError.concat({error: !action.payload.isSuccess, bankId: state.bankAccountToDelete.bankId}),
                    deleteBankAccountStatus: loadingStatus.LOADING_STATUS_SUCCESS,
                    showDeleteBankModule: false,
                    showDeleteBankErrorModule: !action.payload.isSuccess,
                    bankAccountListStatus: loadingStatus.LOADING_STATUS_INIT }
        case bankAccountConstants.DELETE_BANK_ACCOUNT_FAILURE:
            return { ...state,
                    deleteBankAccountError: state.deleteBankAccountError.concat({error: true, bankId: state.bankAccountToDelete.bankId}),
                    deleteBankAccountStatus: loadingStatus.LOADING_STATUS_FAILURE,
                    showDeleteBankModule: false,
                    showDeleteBankErrorModule: true }
        case bankAccountConstants.GET_BANK_TO_EDIT:
            return { ...state, bankAccountToEdit: { ...action.bankAccount, canDelete: action.canDelete}}
        case bankAccountConstants.STORE_UPDATED_BANK_ACCOUNT: {
            const updatedBankId = action.updatedBankAccount.bankId

            const bankAccountBeforeUpdate = state.bankAccountList.find(ba => ba.bankId === updatedBankId)
            const updatedBankAccount = {
                routingNumber: Number(action.updatedBankAccount.routingNumber),
                bankAccountNumber: action.updatedBankAccount.bankAccountNumber,
                nickName: action.updatedBankAccount.nickName,
                bankId: action.updatedBankAccount.bankId,
                firstName: action.updatedBankAccount.firstName,
                lastName: action.updatedBankAccount.lastName,
                email: action.updatedBankAccount.emailAddress,
                phoneNumber: action.updatedBankAccount.phoneNumber,
                isDefault: action.updatedBankAccount.isDefaultAccount,
                isPhoneEligible: action.updatedBankAccount.isDefaultWithIVR,
                bankName: bankAccountBeforeUpdate.bankName,
            }

            const copyBankListMinusUpdatedBankAccount = state.bankAccountList.filter(ba => ba.bankId !== updatedBankId)
            return { ...state,
                bankAccountList: sortBankAccountList([...copyBankListMinusUpdatedBankAccount, updatedBankAccount])
            }
        }
        case bankAccountConstants.EDIT_BANK_ACCOUNT_REQUEST:
            return { ...state, editBankAccountStatus: loadingStatus.LOADING_STATUS_IN_PROGRESS }
        case bankAccountConstants.EDIT_BANK_ACCOUNT_SUCCESS:
            return { ...state, editBankAccountStatus: loadingStatus.LOADING_STATUS_SUCCESS, bankAccountListStatus: loadingStatus.LOADING_STATUS_INIT }
        case bankAccountConstants.EDIT_BANK_ACCOUNT_FAILURE:
            return { ...state, editBankAccountStatus: loadingStatus.LOADING_STATUS_FAILURE }
        default:
            return state;
    }
}