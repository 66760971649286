import React from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import StartServiceImage from "../../images/forms/start.png"
import TransferServiceImage from "../../images/forms/transfer.png"
import StopServiceImage from '../../images/forms/stop.png'
import {Link} from 'react-router-dom'

const ServiceOptionCard = ({props, blockCheckFunc, blockForSolar, t}) => {
  return (
    <div>
      <div className="srp-card-header">
        {t("What would you like to do?")}
      </div>
      <div className="srp-card-body">
          <div className="srp-card-details">
            <div className="row align-items-center">
              <div className="col-12 col-md-4 mt-3">
                <Link
                  to="/residentialelectric/start"
                  onClick={(e)=>blockCheckFunc(e, (props.blockedFromStartStopTransfer.start || props.isCommercial || props.rateMetaData.isBusinessOnResidentialPlan))}>
                  <div className="rounded border align-items-center flex-column d-flex p-2 btn btn-link" role="link">
                    <img src={StartServiceImage} style={{height: "50px", width: "50px"}} />
                    <div>{t("Start service")}</div>
                  </div>
                </Link>
              </div>
              <div className="col-12 col-md-4 mt-3">
                <Link
                  to="/residentialelectric/transfer"
                  onClick={(e)=>blockCheckFunc(e, (props.blockedFromStartStopTransfer.transfer || props.isCommercial || blockForSolar || props.rateMetaData.isBusinessOnResidentialPlan))}>
                  <div className="rounded border align-items-center flex-column d-flex p-2 btn btn-link" role="link">
                    <img src={TransferServiceImage} style={{height: "50px", width: "50px"}} />
                    <div>{t("Transfer service")}</div>
                  </div>
                </Link>
              </div>
              <div className="col-12 col-md-4 mt-3">
                <Link
                  to="/residentialelectric/stop"
                  onClick={(e)=>blockCheckFunc(e, props.blockedFromStartStopTransfer.stop)}>
                  <div className="rounded border align-items-center flex-column d-flex p-2 btn btn-link" role="link">
                    <img src={StopServiceImage} style={{height: "50px", width: "50px"}} />
                    <div>{t("Stop service")}</div>
                  </div>
                </Link>
              </div>
            </div>
          </div>
      </div>
    </div>
  )
}

ServiceOptionCard.propTypes = {
  props: PropTypes.object,
  blockedFromStartStopTransfer: PropTypes.object,
  isCommercial: PropTypes.bool,
  blockCheckFunc: PropTypes.func,
  blockForSolar: PropTypes.bool,
  rateMetaData: PropTypes.object,
  t: PropTypes.func.isRequired
}

export default withTranslation("startStopTransfer")(ServiceOptionCard)