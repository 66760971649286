import { connect } from 'react-redux'

import { withRouter } from '../../../srp_modules/with-router'

import { getBankAccountToEdit } from '../../../actions/auth/payment/bank-account-actions'

import SinglePurchaseSubmittedCard from './single-purchase-submitted-card'

const mapStateToProps = state => {
    const selectedBankId = state.singlePurchaseBankInfo.selectedBankId
    const bankAccount = state.bankAccounts.bankAccountList.find(ba => ba.bankId === selectedBankId)

    const confirmationEmailSent = state.singlePurchaseApiResponse.confirmationEmailSent
    const referenceNumber = state.singlePurchaseApiResponse.referenceNumber

    return { referenceNumber, confirmationEmailSent, bankAccountUsed: bankAccount }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
    updateBankEmailAddressOnClick: (bankAccountUsed) => {
        if (bankAccountUsed === undefined) {
            ownProps.router.navigate('/myaccount/profile/banks')
        } else {
            dispatch(getBankAccountToEdit(bankAccountUsed))
            ownProps.router.navigate('/myaccount/profile/banks/editbankaccount')
        }
    }
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SinglePurchaseSubmittedCard))