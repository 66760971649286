import { guestPaymentApiConstants } from '../../../constants/guest/guest-payment-api-constants'

const initialState = {
    referenceNumber: 0,
    confirmationEmailSent: false,
    isSubmittingPayment: false,
    errorSubmittingPayment: false,
    paymentSubmitted: false
}

export default function guestPaymentApiResponseReducer(state = initialState, action) {
    switch (action.type) {
        case guestPaymentApiConstants.VERIFY_GUEST_PAYMENT_LIST_REQUEST:
            return state
        case guestPaymentApiConstants.VERIFY_GUEST_PAYMENT_LIST_SUCCESS:
            return { ...state, referenceNumber: action.payload.referenceNumberWhenNoError, errorSubmittingPayment: false }
        case guestPaymentApiConstants.VERIFY_GUEST_PAYMENT_LIST_FAILURE:
            return { ...state, referenceNumber: 0 }

        case guestPaymentApiConstants.SUBMIT_GUEST_PAYMENT_LIST_REQUEST:
            return { ...state, errorSubmittingPayment: false, confirmationEmailSent: false, isSubmittingPayment: true, paymentSubmitted: false }
        case guestPaymentApiConstants.SUBMIT_GUEST_PAYMENT_LIST_SUCCESS:
            return { ...state,
                paymentSubmitted: action.payload.referenceNumber > 0,
                errorSubmittingPayment: action.payload.referenceNumber === 0,
                referenceNumber: action.payload.referenceNumber === 0 ? state.referenceNumber : action.payload.referenceNumber,
                confirmationEmailSent: action.payload.confirmationEmailSent,
                isSubmittingPayment: false
            }
        case guestPaymentApiConstants.SUBMIT_GUEST_PAYMENT_LIST_FAILURE:
            return { ...state, errorSubmittingPayment: true, confirmationEmailSent: false, isSubmittingPayment: false, paymentSubmitted: false }

        case guestPaymentApiConstants.CLEAR_GUEST_PAYMENT_SUBMITTED:
            return { ...state, paymentSubmitted: false }

        default:
            return state
    }
}