import React from 'react'
import { connect } from 'react-redux'

import format from '../../../../srp_modules/format'

import { storeBankInfoCollected, storePaymentList, verifyPaymentList } from '../../../../actions/guest/payment/guest-payment-actions'
import { addSnackbarMessage, dismissSnackbarMessage } from '../../../../actions/common/snackbar'
import { withRouter } from '../../../../srp_modules/with-router'

import MultiplePaymentInputCardWithRouter from './multiple-payment-input-card'

const mapStateToProps = state => {
    return { ...state.guestPaymentBankInfo, ...state.guestPaymentList }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return ({
        storeBankInfo: (bankValues) => {
            dispatch(storeBankInfoCollected(bankValues))
        },
        syncPaymentList: (paymentList) => {
            dispatch(storePaymentList(paymentList))
        },
        validatePaymentList: async (paymentList, handleValidatePaymentListErrors) => {
            dispatch(dismissSnackbarMessage())
            dispatch(storePaymentList(paymentList))

            const language = ownProps.i18n.language
            const response = await dispatch(verifyPaymentList(language))

            if (response.error === true) {
                handleValidatePaymentListErrors({networkError: true})
            } else {
                if (response.payload.isSuccess)
                    ownProps.router.navigate('/myaccount/payment/guest/confirmation')
                else {
                    const validationErrors = response.payload.eChexValidationErrors

                    if (validationErrors.errorBankAccountSetupLocked)
                        ownProps.router.navigate('/myaccount/payment/guest/locked')
                    else
                        handleValidatePaymentListErrors(validationErrors)
                }
            }
        },
        showUndoRemovePaymentSnackbar: (deletedPayment, addPaymentIfNotOnList) => {
            const deletedPaymentAmountDisplay = format.formatUsdAmount(deletedPayment.paymentAmount)
            dispatch(addSnackbarMessage(
                <span>{ownProps.t("Removed_x_payment", {amount: deletedPaymentAmountDisplay})}</span>,
                <button className="displayAsBlueText" onClick={() => {
                    dispatch(dismissSnackbarMessage())
                    if (addPaymentIfNotOnList(deletedPayment) === false)
                        dispatch(addSnackbarMessage(<span>{ownProps.t("Cant_restore_x_payment", {amount: deletedPaymentAmountDisplay})}</span>))
            }}>{ownProps.t("Undo")}</button>))
        }
    })
}

const MultiplePaymentInputCardContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(MultiplePaymentInputCardWithRouter))
export default MultiplePaymentInputCardContainer