import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import PasswordResetEmailConfirmationPage from './password-reset-email-confirmation-page'

class PasswordResetEmailConfirmationPageContainer extends React.Component {
    render() {
        return <PasswordResetEmailConfirmationPage emailToSendReset={this.props.emailToSendReset} />
    }
}

const mapStateToProps = state => {
    return { ...state.login }
}

PasswordResetEmailConfirmationPageContainer.propTypes = {
    emailToSendReset: PropTypes.string
}

export default connect(mapStateToProps)(PasswordResetEmailConfirmationPageContainer)