import React from 'react'
import PropTypes from 'prop-types'

import { Trans } from 'react-i18next'

import outageRestoration from '../../srp_modules/outage-restoration'

const ReportOutageSubmitted = ({lastReportedOutage, outageHistoryList, t}) => {
    let reportedOutageResolved = outageRestoration.isReportedOutageClosed(lastReportedOutage, outageHistoryList)
    let showSuccessAlert = lastReportedOutage.hasReported && !reportedOutageResolved
    
    return(
        <div>
            {showSuccessAlert
                ? <div className="mt-4">
                    <div className="srp-alert-success srp-card-body">
                        <Trans i18nKey="OUTAGE_REPORT_SUBMITTED" t={t}>
                        <strong>OUTAGE REPORT SUBMITTED</strong> We will resolve this outage as quickly as possible.
                        </Trans>
                    </div>
                </div> 
            : <span/>}
        </div>
    )
}

ReportOutageSubmitted.propTypes = {
    t: PropTypes.func.isRequired,
    lastReportedOutage: PropTypes.object,
    outageHistoryList: PropTypes.arrayOf(PropTypes.object)
}

export default ReportOutageSubmitted