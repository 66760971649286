import React from 'react'
import PropTypes from 'prop-types'

import Card from "@mui/material/Card"
import CircularProgress from '@mui/material/CircularProgress'

import { withTranslation } from 'react-i18next'

import AccountInfoBarContainer from '../myaccount_header/info_bar/account-info-bar-container'
import CallAnytime from '../myaccount_header/call-anytime'
import CheckDiagram from '../../images/payment/check-diagram.png'
import CheckDiagramSpanish from '../../images/payment/check-diagram-es.png'
import CheckDiagramMobile from '../../images/payment/check-diagram-mobile.png'
import CheckDiagramMobileSpanish from '../../images/payment/check-diagram-mobile-es.png'

import { myAccountConstants } from '../../constants/myaccount-constants'

let style = {
    marginTop: "10%",
    marginBottom: "10%"
}

class AutopayLoadingPage extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {
        const showSpanish = this.props.i18n.language === "es"
        const supportNumber = this.props.isResidential ?
            (showSpanish ?
                myAccountConstants.SPANISH_CUSTOMER_SERVICE_PHONE_NUMBER_FMTTD :
                myAccountConstants.RESIDENTIAL_CUSTOMER_SERVICE_PHONE_NUMBER_FMTTD) :
            myAccountConstants.COMMERCIAL_CUSTOMER_SERVICE_PHONE_NUMBER_FMTTD

        return (
            <div>
                <div className="container py-3">
                    <CallAnytime />

                    <h3 className="mt-3">{this.props.t("Sign up for SurePay")}</h3>

                    <AccountInfoBarContainer disableSelector={false} billAccount={this.props.billAccount} />

                    <div className="row">
                        <div className="col-lg-6 mt-3">
                            <label className="h5">{this.props.t("We'll need some information first")}</label>
                            <div className="srp-card-body">
                                <div className="srp-card-details d-flex justify-content-center">
                                    <CircularProgress size={80} thickness={5} style={style} />
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6 mt-3 d-none d-lg-block">
                            <label className="h5">{this.props.t("Need help?")}</label>
                            <Card>
                                <div className="srp-alert-warn">
                                    <div className="pt-2 pb-2">
                                        {this.props.t("For_questions_please_contact_SRP", {
                                            residentialOrCommercial: this.props.isResidential ? "Residential" : "Commercial",
                                            supportNumber,
                                            })}
                                </div>
                                    <Card>
                                        <img src={showSpanish ? CheckDiagramSpanish : CheckDiagram} className="d-none d-xl-block" />
                                        <img src={showSpanish ? CheckDiagramMobileSpanish : CheckDiagramMobile} className="mx-auto d-block img-fluid d-xl-none" />
                                    </Card>
                                </div>
                            </Card>
                        </div>

                    </div>
                </div>
            </div>
        )
    }
}

AutopayLoadingPage.propTypes = {
    billAccount: PropTypes.number.isRequired,
    isResidential: PropTypes.bool.isRequired,
    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired,
}

export default withTranslation("addUpdateSurePay")(AutopayLoadingPage)
