import NetCompliantChartData from './net-compliant-chart-data'
import drawVerticalBars from './draw-vertical-bars'
import * as d3 from 'd3'
import { DateTime } from 'luxon'
import {displaySizeConstants} from '../../constants/display-size-constants'
import {getDisplaySize} from '../../srp_modules/display-size'
import {convertDailyDataForCharting} from '../../srp_modules/charting/daily-data-dto'
import {dailyUsageChartTypeConstants} from '../../constants/daily-usage-chart-type-constants'
const margin = {
    top: 20,
    right: 70,
    bottom: 10,
    left: 70
}
const yOffset = 40
const standardChartKeys = ["superOffPeak", "offsetSuperOff", "offPeak", "offsetOff", "shoulder", "offsetShoulder", "onPeak", "offsetOn", "total", "offsetTotal", "min", "offsetMin", "max"]

export default function drawVerticalChart(data, weatherData, usageChartType, highDemandInfoList, hasOnOffShoulder, barClickCallback, isMPower, t) {
    window.daily_usage_selected_date =  ""
    let x
    let y
    let highDemandDate = highDemandInfoList.length > 0
        ? highDemandInfoList[0].isCurrentBill
            ? highDemandInfoList[0].onPeakHighDemand > 0
                ? highDemandInfoList[0].onPeakHighDemandDate
                : highDemandInfoList[0].highDemand > 0
                    ? highDemandInfoList[0].highDemandDate
                    : undefined
            : undefined
        :undefined
    let isCost = usageChartType === dailyUsageChartTypeConstants.COST
    let isDemand = usageChartType === dailyUsageChartTypeConstants.DEMAND
    let isConsumption = usageChartType === dailyUsageChartTypeConstants.CONSUMPTION
    let isGeneration = usageChartType === dailyUsageChartTypeConstants.GENERATION
    let dtoData = convertDailyDataForCharting(data, usageChartType)
    let netCompliantChartData = new NetCompliantChartData(dtoData, isCost, isDemand, isConsumption, isGeneration)
    let adjustedData = addTooltipInfoToData(netCompliantChartData.adjustedData,weatherData)
    let maxY = netCompliantChartData.maxY
    let minY = netCompliantChartData.minY
    let legendDisplayStates = getLegendDisplayStates(adjustedData, isCost)
    let chart = d3.select("#usagePageChartContainer")
    d3.selectAll("#usagePageChart").remove()
    let displaySize = getDisplaySize(window.innerWidth)
    let chartSize = getChartSvgSize(displaySize)
    let svg = chart
        .insert('svg', 'div')
        .attr('width', chartSize.width)
        .attr('height', chartSize.height)
        .attr('id', 'usagePageChart')
        .attr('class', 'viz-chart')
    let width = +svg.attr("width") - margin.left - margin.right
    let height = +svg.attr("height") - margin.top - margin.bottom
    let g = svg.append("g").attr("transform", "translate(" + margin.left + "," + margin.top + ")")

    x = d3.scaleBand()
        .range([0, width])
        .align(0.1)

    y = d3.scaleLinear()
        .rangeRound([height - yOffset, 0])

    let yTemp = d3.scaleLinear().range([height - yOffset, 10])

    x.domain(adjustedData.map(function (d) {
        return d.date
    }))
    y.domain([minY, maxY])
    yTemp.domain([0, d3.max(weatherData, function (d) {
        return Math.max(d.high);
    })]).nice()

    //add line at zero if the chart has negative data
    if (minY < 0) {
        g.append("line")
            .attr("y1", y(0))
            .attr("y2", y(0))
            .attr("x1", 20)
            .attr("x2", width - 20)
            .style("stroke-width", "0.01em")
            .style("stroke", "#1F1D1D")
    }

    const barWidth = (width / data.length / 1.5)
    const xOffset = (width / data.length - barWidth) / 2
    let initialTt = chart.select(".viz-tooltip-arrow-main")
    initialTt.remove()
    let tooltip = addTooltipToChart(chart)

    g.append("g").attr("id", "dailyChartMainGroup")
        .selectAll("g")
        .data(d3.stack().keys(standardChartKeys)(adjustedData))
        .enter().append("g")
        .attr("class", function (d, i) {
            return getCssClass(standardChartKeys[i],isGeneration,hasOnOffShoulder,d[i]) + " viz-bar"
        })
        .selectAll("path")
        .data(function (d) {
            return d
        })
        .enter().append("g")
        .classed("viz-high-demand-bar",function (d) {
            return highDemandDate !== undefined && isDemand
            && d.data.date.hasSame(DateTime.fromISO(highDemandDate).setZone('MST'), 'day')
         })
        .classed("viz-on-off-shoulder", function (d) {
            return d.data.onPeak === 0 && hasOnOffShoulder && isDemand})
        .append("path")
        .on("mouseover", function (d) {
            handleMouseOver(d, this, tooltip, barWidth, xOffset, maxY, svg, x, y, isCost, t)
        })
        .on("mousedown", function(d) {
            if('ontouchstart' in document.documentElement !== true) {
                return true
            }
            handleMouseOver(d, this, tooltip, barWidth, xOffset, maxY, svg, x, y, isCost, t)

            if(window.daily_usage_selected_date !== undefined
                && window.daily_usage_selected_date !== null
                && window.daily_usage_selected_date !== "")
            {
                if(window.daily_usage_selected_date.equals(d.data.date)){
                    barClickCallback(d.data.date, isMPower ? dailyUsageChartTypeConstants.USAGE : usageChartType)
                }
            }
            d3.event.stopPropagation()
            window.daily_usage_selected_date = d.data.date
        })
        .on("mouseout", function (d) { handleMouseOut(d, this, tooltip) })
        .on("click", function(d){
            if('ontouchstart' in document.documentElement){
                return true
            }
            barClickCallback(d.data.date, isMPower ? dailyUsageChartTypeConstants.USAGE : usageChartType)
        })
        .attr("height", 0)
        .attr("d", function (d) {
            return drawVerticalBars(d, data, x, y, minY, maxY, xOffset, barWidth, "date")
        })

    const yLineHigh = d3.line()
        .x(function (d) {
            return x(DateTime.fromISO(d.weatherDate)) + xOffset + (barWidth / 2)
        })
        .y(function (d) {
            return yTemp(d.high);
        })

    g.append("path")
        .attr("d", yLineHigh(weatherData))
        .attr("class", "viz-high-temp")
        .style("fill", "none")
        .style("stroke-width", "2px")

    const yLineLow = d3.line()
        .x(function (d) {
            return x(DateTime.fromISO(d.weatherDate)) + xOffset + (barWidth / 2)
        })
        .y(function (d) {
            return yTemp(d.low);
        })

    g.append("path")
        .attr("class", "viz-low-temp")
        .attr("d", yLineLow(weatherData))
        .style("fill", "none")
        .style("stroke-width", "2px");

    drawAxisTicks(data, g, height, yOffset, width, isCost, x, y, yTemp)
    drawAxisLabels(svg, chartSize.height, chartSize.width,usageChartType, t)
    drawLegend(width, legendDisplayStates, isGeneration)

}

function drawAxisTicks(data, g, height, yOffset, width, isCost, x, y, yTemp) {
    g.append("g")
        .attr("class", "axis")
        .attr("class", "usage-date-axis")
        .attr("transform", "translate(0," + (height - yOffset) + ")")
        .call(d3.axisBottom(x).tickValues(x.domain().filter(function (d, i) {
            return calculateAxisTickStep(data.length, i)
        }))
        .tickPadding(8)
        .tickFormat(function(x) {return DateTime.fromISO(x).toFormat("EEE, MMM d")}))

    d3.selectAll("g.tick line")
        .attr("y2", 10)
        .style("stroke-width", "1px")
        .style('shape-rendering','crispEdges')

    // NOTE: This is causing the "Unexpected value NaN parsing y attribute." console warning.
    // The other charts likely have the same issue, I believe it happens when there isn't enough weather data available
    g.append("g")
        .attr("transform", "translate(" + width + " ,0)")
        .attr("class", "axis")
        .call(d3.axisRight(yTemp).ticks(10, "s"))
        .append("text")
        .attr("x", 2)
        .attr("y", y(yTemp.ticks().pop()) + 0.5)
        .attr("fill", "#000")
        .attr("font-weight", "bold")
        .attr("text-anchor", "start")

    if (isCost) {
        g.append("g")
            .attr("class", "axis")
            .call(d3.axisLeft(y).ticks(4, "s")
                .tickFormat(d3.format(["$", ""])))
            .append("text")
            .attr("x", 2)
            .attr("y", y(y.ticks().pop()) + 0.5)
            .attr("fill", "#000")
            .attr("font-weight", "bold")
            .attr("text-anchor", "start")
    }
    else {
        g.append("g")
            .attr("class", "axis")
            .call(d3.axisLeft(y).ticks(4, "s"))
            .append("text")
            .attr("x", 2)
            .attr("y", y(y.ticks().pop()) + 0.5)
            .attr("fill", "#000")
            .attr("font-weight", "bold")
            .attr("text-anchor", "start")
    }
}

function drawAxisLabels(svg,height,width,usageChartType, t) {
    let leftLabel = ""
    switch(usageChartType){
        case dailyUsageChartTypeConstants.COST:
            leftLabel = t("Energy cost per day")
            break
        case dailyUsageChartTypeConstants.DEMAND:
            leftLabel = t("Peak Demand")
            break
        case dailyUsageChartTypeConstants.CONSUMPTION:
            leftLabel = t("Usage (kWh)")
            break
        case dailyUsageChartTypeConstants.USAGE:
            leftLabel = t("Usage (kWh)")
            break
        case dailyUsageChartTypeConstants.NET_ENERGY:
            leftLabel = t("Usage (kWh)")
            break
        case dailyUsageChartTypeConstants.GENERATION:
            leftLabel = t("Generation (kWh)")
    }

    svg.append("text")
        .attr("transform", "rotate(-90)")
        .attr("y", 0 )
        .attr("x",0 - (height/2))
        .attr("dy", "1em")
        .style("text-anchor", "middle")
        .attr("class","chart-axis-label")
        .text(leftLabel)

    svg.append("text")
        .attr("transform", "rotate(90)")
        .attr("y", 0 - width )
        .attr("x",0 + (height/2))
        .attr("dy", "1em")
        .style("text-anchor", "middle")
        .attr("class","chart-axis-label")
        .text(t("High / low temperature") + " (°F)")
}

function drawLegend(width, legendDisplayStates, isGeneration) {
    let legendContainer = d3.select('#dailyUsageLegendContainer')
    legendContainer
        .style("left", margin.left + "px")
        .style("width", width + "px")

    if (legendDisplayStates.hasSuperOff) {
        let superOffItem = d3.select('#superOffLegendItem')
        cssForLegnedItem(superOffItem, isGeneration)
    }
    else {
        d3.select('#superOffLegendItem')
            .attr("class", "chart-legend-item-hidden")
    }

    if (legendDisplayStates.hasOffPeak) {
        let offItem = d3.select('#offPeakLegendItem')
        cssForLegnedItem(offItem, isGeneration)
    }
    else {
        d3.select('#offPeakLegendItem')
            .attr("class", "chart-legend-item-hidden")
    }

    if (legendDisplayStates.hasShoulder) {
        let shoulderItem = d3.select('#shoulderLegendItem')
        cssForLegnedItem(shoulderItem, isGeneration)
    }
    else {
        d3.select('#shoulderLegendItem')
            .attr("class", "chart-legend-item-hidden")
    }

    if (legendDisplayStates.hasOnPeak) {
        let onItem = d3.select('#onPeakLegendItem')
        cssForLegnedItem(onItem, isGeneration)
    }
    else {
        d3.select('#onPeakLegendItem')
            .attr("class", "chart-legend-item-hidden")
    }

    if (legendDisplayStates.hasTotal && legendDisplayStates.isCost) {
        let totalItem = d3.select('#totalLegendItemCost')
        cssForLegnedItem(totalItem, isGeneration)
    }
    else {
        d3.select('#totalLegendItemCost')
            .attr("class", "chart-legend-item-hidden")
    }

    if (legendDisplayStates.hasTotal && !legendDisplayStates.isCost) {
        let total = d3.select('#totalLegendItem')
            .attr("class", "usage-chart-legend-item-show")
            cssForLegnedItem(total, isGeneration)
    }
    else {
        d3.select('#totalLegendItem')
            .attr("class", "chart-legend-item-hidden")
    }
}

function cssForLegnedItem(legendItem, isGeneration) {
    legendItem.attr("class", "usage-chart-legend-item-show")
    if(isGeneration){
        legendItem.select(".usage-chart-legend-circle").classed("generation", true)
    }
    else{
        legendItem.select(".usage-chart-legend-circle").classed("generation", false)
    }
}

function getChartSvgSize(displaySize) {
    switch(displaySize) {
        case displaySizeConstants.EXTRA_LARGE:
            return { height: 300, width: 780 }

        case displaySizeConstants.LARGE:
            return { height: 300, width: 650 }

        case displaySizeConstants.MEDIUM:
            return { height: 300, width: 480 }

        case displaySizeConstants.SMALL:
            return { height: 175, width: 480 }

        case displaySizeConstants.EXTRA_SMALL:
            return { height: 200, width: 420 }
    }
}

function calculateOffsetLeft(svg) {
    let offsetLeft = svg.node().offsetLeft
    if (typeof offsetLeft != "undefined") {
        return offsetLeft
    }
    let svgElement = document.getElementById(svg.node().id)
    let svgParent = svgElement.parentNode
    let left = svgElement.getBoundingClientRect().left -
        svgParent.getBoundingClientRect().left

    return left;
}

function getCssClass(text,isGeneration) {
    if (text.includes("offset")) {
        return "viz-invisible"
    }
    return isGeneration
        ? "viz-" + text + "-generation"
        : "viz-" + text
}

function addTooltipToChart(chart) {
    let tooltip = chart
        .append("div")
        .attr("class", "viz-tooltip-arrow-main")
    let tooltipArrow =
        tooltip
            .append("div")
            .attr("class", "viz-tooltip-arrow")

    tooltipArrow.append("div")
        .attr("class", "viz-tooltip-main")
        .append("div")
        .attr("class", "viz-tooltip-inner")
        .attr("id", "viz-tooltipText")
    return tooltip
}

function handleMouseOut(d, element, tooltip) {
    $(element).removeClass("current-hover")
    tooltip.style("display", "none")
}

function handleMouseOver(d, element, tooltip, barWidth, xOffset,
    maxY, svg, x, y, isCost, t) {
    $(element).addClass("current-hover")
    createTooltip(d, tooltip, barWidth, xOffset,
        maxY, svg, x, y, isCost, t)
    return true
}

function createTooltip(d, tooltip, barWidth,
    xOffset, maxY, svg, x, y, isCost, t) {
    let barTop = maxY - d.data.max

    let xPosition = x(d.data.date) + xOffset + margin.left +
        (barWidth - 20) / 2 + calculateOffsetLeft(svg)
    let yPosition = barTop === 0
        ? y(d.data.max)
        : y(barTop)
    let formatTime = d3.timeFormat("%a, %b %e")

    tooltip
        .style("left", xPosition + "px")
        .style("top", yPosition + "px")
        .style("display", "block")
    let tooltipInner = tooltip.select(".viz-tooltip-inner")
    tooltipInner.selectAll("*").remove()
    tooltipInner.append("div").text(formatTime(DateTime.fromISO(d.data.date)))
    if (isCost) {
        if (d.data.showTotalCostTip) {
            tooltipInner.append("div")
                .attr("class", "viz-tooltip-text-line")
                .text(t("Cost") + ": $" + d.data.dailyCost.toFixed(2))
        }
        if (d.data.showOffPeakCostTip) {
            tooltipInner.append("div")
                .attr("class", "viz-tooltip-text-line")
                .text(d.data.tooltipOffPeakCost)
        }
        if (d.data.showOnPeakCostTip) {
            tooltipInner.append("div")
                .attr("class", "viz-tooltip-text-line")
                .text(d.data.tooltipOnPeakCost)
        }
    }
    else {
        if (d.data.showOffPeakTip) {
            tooltipInner.append("div")
                .attr("class", "viz-tooltip-text-line")
                .text(d.data.tooltipOffPeak)
        }
        if (d.data.showShoulderTip) {
            tooltipInner.append("div")
                .attr("class", "viz-tooltip-text-line")
                .text(d.data.tooltipShoulder)
        }
        if (d.data.showOnPeakTip) {
            tooltipInner.append("div")
                .attr("class", "viz-tooltip-text-line")
                .text(d.data.tooltipOnPeak)
        }
        if (d.data.showSuperOffPeakTip) {
            tooltipInner.append("div")
                .attr("class", "viz-tooltip-text-line")
                .text(d.data.tooltipSuperOffPeak)
        }
        if (d.data.showTotalTip) {
            tooltipInner.append("div")
                .attr("class", "viz-tooltip-text-line")
                .text(d.data.tooltipTotal)
        }
    }

    if (d.data.lowTemp != 0 && d.data.highTemp !=0) {
        tooltipInner.append("div")
            .attr("class", "viz-tooltip-text-line")
            .text(t("High/low temp") + ": " + Math.round(d.data.highTemp)
                + "°/" + Math.round(d.data.lowTemp) + "°F" )
    }
}

function calculateAxisTickStep(dataLength,i){
    return i % Math.round(dataLength / 5) === 0
}

function addTooltipInfoToData(adjustedData,weatherData){
    let hasWeatherData = adjustedData !== undefined
        && (adjustedData.length === weatherData.length)
        adjustedData.forEach(function (element,i) {
            element.date = DateTime.fromISO(element.date).setZone('MST')
            element.day = DateTime.fromISO(element.day).toFormat('yyyy-MM-dd')
            element.highTemp = hasWeatherData ? weatherData[i].high : 0,
            element.lowTemp = hasWeatherData ? weatherData[i].low : 0
        })
    return adjustedData
}

function getLegendDisplayStates(adjustedData,isCost){
    let hasOnPeak = false,
    hasSuperOff = false,
    hasOffPeak = false,
    hasShoulder = false,
    hasTotal = false

    let hasWeatherData = adjustedData !== undefined
    adjustedData.forEach(function (element) {
        hasOnPeak = element.onPeak != 0 ? true : hasOnPeak
        hasOffPeak = element.offPeak != 0 ? true : hasOffPeak
        hasShoulder = element.shoulder != 0 ? true : hasShoulder
        hasSuperOff = element.superOffPeak != 0 ? true : hasSuperOff
        hasTotal = element.total != 0 ? true : hasTotal
    })
    return {hasOnPeak: hasOnPeak,
        hasSuperOff: hasSuperOff,
        hasOffPeak: hasOffPeak ,
        hasShoulder: hasShoulder,
        hasTotal: hasTotal,
        hasWeather: hasWeatherData,
        isCost: isCost
    }
}
