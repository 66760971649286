import { dailyUsageChartTypeConstants } from '../../constants/daily-usage-chart-type-constants'
import i18n from '../../i18n'

export function convertDailyDataForCharting(data, currentChartType){
    const t = i18n.t
    if(data != undefined && data.length > 0 ){
        let dailyData = []
        if(currentChartType === dailyUsageChartTypeConstants.COST){
            data.forEach(function(e){ 
                dailyData.push({
                    day: e.day,
                    date:e.date,
                    billStartDate: e.billStartDate,
                    billEndDate: e.date,
                    superOffPeak: e.superOffPeakCost,
                    offPeak: e.offPeakCost,
                    shoulder: e.shoulderCost,
                    onPeak: e.onPeakCost,
                    total: e.totalCost,
                    dailyCost: e.dailyCost,
                    totalCost: e.totalCost,
                    totalKwh: e.totalKwh,
                    onPeakCost: e.onPeakCost,
                    offPeakCost: e.offPeakCost,
                    shoulderCost: e.shoulderCost,
                    superOffPeakCost: e.superOffPeakCost,
                    showSuperOffPeakTip: e.superOffPeakKwh !== 0,
                    showOffPeakTip: e.offPeakKwh !== 0,
                    showShoulderTip: e.shoulderKwh !== 0,
                    showOnPeakTip: e.onPeakKwh !== 0,
                    showTotalTip: e.totalKwh !== 0,
                    showTotalCostTip: e.onPeakCost === 0 && e.offPeakCost === 0,
                    showOffPeakCostTip: e.offPeakCost !== 0,
                    showOnPeakCostTip: e.onPeakCost !== 0,
                    tooltipOffPeakCost: t("usageCard::Off-peak cost") + " $" + e.offPeakCost.toFixed(2).toLocaleString('US-EN'),
                    tooltipOnPeakCost: t("usageCard::On-peak cost") + " $" + e.onPeakCost.toFixed(2).toLocaleString('US-EN'),  
                    tooltipSuperOffPeak: t("usageCard::Super off-peak") + " kWh: " + e.superOffPeakKwh.toLocaleString('US-EN'),
                    tooltipOffPeak: t("usageCard::Off-peak") + " kWh: " + e.offPeakKwh.toLocaleString('US-EN'),
                    tooltipShoulder: t("usageCard::Shoulder") + " kWh: " +e.shoulderKwh.toLocaleString('US-EN'),
                    tooltipOnPeak: t("usageCard::On-peak") + " kWh: " + e.onPeakKwh.toLocaleString('US-EN'),
                    tooltipTotal: t("usageCard::Total") + " kWh: " + e.totalKwh.toLocaleString('US-EN')
            })
        })
    }

    if(currentChartType === dailyUsageChartTypeConstants.USAGE 
        || currentChartType === dailyUsageChartTypeConstants.NET_ENERGY ){
        data.forEach(function(e){ 
            dailyData.push({
                day: e.day,
                date:e.date,
                billStartDate: e.billStartDate,
                billEndDate: e.date,
                dailyCost: e.dailyCost,
                superOffPeak: e.superOffPeakKwh,
                offPeak: e.offPeakKwh,
                shoulder: e.shoulderKwh,
                onPeak: e.onPeakKwh,
                total: e.totalKwh,
                totalKwh: e.totalKwh,
                showSuperOffPeakTip: e.superOffPeakKwh !== 0,
                showOffPeakTip: e.offPeakKwh !== 0,
                showShoulderTip: e.shoulderKwh !== 0,
                showOnPeakTip: e.onPeakKwh !== 0,
                showTotalTip: e.totalKwh !== 0,
                totalCost: e.totalCost,
                tooltipSuperOffPeak: t("usageCard::Super off-peak") + " kWh: " + e.superOffPeakKwh.toLocaleString('US-EN'),
                tooltipOffPeak: t("usageCard::Off-peak") + " kWh: " + e.offPeakKwh.toLocaleString('US-EN'),
                tooltipShoulder: t("usageCard::Shoulder") + " kWh: " +e.shoulderKwh.toLocaleString('US-EN'),
                tooltipOnPeak: t("usageCard::On-peak") + " kWh: " + e.onPeakKwh.toLocaleString('US-EN'),
                tooltipTotal: t("usageCard::Total") + " kWh: " + e.totalKwh.toLocaleString('US-EN')
            })
        })
    }
        if(currentChartType === dailyUsageChartTypeConstants.DEMAND){
            data.forEach(function(e){ 
                dailyData.push({
                    day: e.day,
                    date:e.date,
                    billStartDate: e.billStartDate,
                    billEndDate: e.date,
                    dailyCost: 0,
                    superOffPeak: 0,
                    offPeak: 0,
                    shoulder: 0,
                    onPeak: e.onPeakDemand,
                    total: e.totalDemand,
                    intervalTimeInMinutes : e.intervalTimeInMinutes || 0,
                    showSuperOffPeakTip: e.superOffPeakDemand !== 0,
                    showOffPeakTip: e.offPeakDemand !== 0,
                    showShoulderTip: e.shoulderDemand !== 0,
                    showOnPeakTip: e.onPeakDemand !== 0,
                    showTotalTip: e.totalDemand !== 0,
                    showTotalCostTip: false,
                    tooltipSuperOffPeak: t("usageCard::Super off-peak demand") + " kW:" + e.superOffPeakDemand,
                    tooltipOffPeak: t("usageCard::Off-peak demand") + " kW: " + e.offPeakDemand.toLocaleString('US-EN'),
                    tooltipShoulder: t("usageCard::Shoulder demand") + " kW: " +e.shoulderDemand.toLocaleString('US-EN'),
                    tooltipOnPeak: t("usageCard::Tooltip On-peak demand") + " kW: " + e.onPeakDemand.toLocaleString('US-EN'),
                    tooltipTotal: t("usageCard::Total demand") + " kW: " + e.totalDemand.toLocaleString('US-EN')
                })
            })
        }
        if(currentChartType === dailyUsageChartTypeConstants.CONSUMPTION ||
            currentChartType === dailyUsageChartTypeConstants.GENERATION
            || currentChartType === dailyUsageChartTypeConstants.EXPORT){
            data.forEach(function(e){
                dailyData.push({
                    day: e.day,
                    date:e.date,
                    billStartDate: e.billStartDate,
                    billEndDate: e.date,
                    dailyCost: 0,
                    superOffPeak: e.superOffPeak,
                    offPeak: e.offPeak,
                    shoulder: e.shoulder,
                    onPeak: e.onPeak,
                    total: e.total,
                    totalKwh: e.total,
                    showSuperOffPeakTip: e.superOffPeak !== 0,
                    showOffPeakTip: e.offPeak !== 0,
                    showShoulderTip: e.shoulder !== 0,
                    showOnPeakTip: e.onPeak !== 0,
                    showTotalTip: e.total !== 0,
                    showTotalCostTip: false,
                    tooltipSuperOffPeak: t("usageCard::Super off-peak") + " kWh: " + e.superOffPeak,
                    tooltipOffPeak: t("usageCard::Off-peak") + " kWh: " + e.offPeak,
                    tooltipShoulder: t("usageCard::Shoulder") + " kWh: " +e.shoulder,
                    tooltipOnPeak: t("usageCard::On-peak") + " kWh: " + e.onPeak,
                    tooltipTotal: t("usageCard::Total") + " kWh: " + e.total
                })
            })
        }
        return dailyData
    }
    return []
}
