import React from "react"

let baseStyle = {
    borderStyle: 'solid',
    borderRadius: '10px',
    borderWidth: '1px',
    width: '100%',
    height: '250px',
    paddingTop: '10px',
    paddingBottom: '10px'
}

let centerStyle= {
    left: '0',
    right: '0',
    margin: 'auto'
}
let regularBoxStyle={
    ...baseStyle, 
    borderColor: 'rgba(215,215,215,1)'
}

let lightGrayStyle = {
    backgroundColor: "rgb(242,242,242)",
    borderRadius: "20px"
}

const MyPricingLoadingCardResponsive = () => {
    return (
        <div style={{position:"relative"}}>
                <div style={regularBoxStyle}>
                    <div className="clearfix" style={{marginLeft:"15px", marginRight:"15px", marginTop:"15px"}}>
                        <span className="cmpp-shine" style={{ backgroundColor:"rgb(204,219,231)", float: "left", width: "130px", height: "27px", borderRadius: "20px" }}/>
                        <span className="cmpp-shine" style={{ backgroundColor:"rgb(205,229,247)", float: "right", width: "30px", height: "27px", borderRadius: "20px" }}/>
                    </div>

                    <div className="clearfix" style={{marginLeft:"15px", marginRight:"15px", marginTop:"20px"}}>
                        <div className="cmpp-shine" style={{...lightGrayStyle, width:"130px", height:"33px", float: "left"}}/>
                        <div className="cmpp-shine" style={{...lightGrayStyle, width: "105px", height: "33px", float: "right"}}/>
                    </div>

                    <div className="clearfix" style={{marginLeft:"15px", marginRight:"15px", marginTop:"20px"}}>
                        <div className="cmpp-shine" style={{...lightGrayStyle, width:"130px", height:"33px", float: "left"}}/>
                        <div className="cmpp-shine" style={{...lightGrayStyle, width: "105px", height: "33px", float: "right"}}/>
                    </div>
                    <div className="cmpp-shine" style={{ ...centerStyle, backgroundColor:"rgb(205,229,247)", borderRadius:"4px", width:"90%", height:"32px", marginTop:"40px"}}/>
                </div>
            </div>
    )
}

export default MyPricingLoadingCardResponsive