import React from 'react'
import { DateTime } from 'luxon'
import PropTypes from 'prop-types'

function getCalendarHead(isSpanish) {
    let daysOfWeek = isSpanish ? ["D", "L", "M", "M", "J", "V", "S"] : ["S", "M", "T", "W", "T", "F", "S"]
    let header = []
    for(let i=0; i < daysOfWeek.length; i++)
    {
        header.push(<th scope="col" className="text-center" key={i} style={{width:"45px"}}>{daysOfWeek[i]}</th>)
    }

    return header
}

function getCalendarDetails(date) {
    let table = []
    let tableRows = []
    let dayOfMaxExtension = DateTime.fromISO(date).day
    let monthOfMaxExtension = DateTime.fromISO(date).startOf('month')
    let dayOfWeek = DateTime.fromISO(monthOfMaxExtension).weekday % 7

    let endOfMaxExtMonthDate = DateTime.fromISO(date).endOf('month')
    let numOfDaysInMaxExtMonth = DateTime.fromISO(endOfMaxExtMonthDate).day

    let currentMonthSameAsExtMonth = true
    if(DateTime.now().setZone('MST').month !== DateTime.fromISO(date).month)
    {
        currentMonthSameAsExtMonth = false
    }

    let calendarNum = 1
    for(let i = 1; i <= 35; i++)
    {
        if(!currentMonthSameAsExtMonth)
        {
            if((dayOfWeek+1) > i)
            {
                tableRows.push(<td key={"t"+i} style={{borderBottom:'4px solid white', borderTop:'4px solid white'}}/>)
            }
            else if(calendarNum < dayOfMaxExtension)
            {
                if(calendarNum === 1)
                {
                    tableRows.push(<td className="text-white" style={{backgroundColor:'rgba(115, 123, 76, .7)', borderBottom:'4px solid white', borderTop:'4px solid white' }} key={"td"+i}>{calendarNum}</td>)
                }
                else
                {
                    tableRows.push(<td className="text-white" style={{backgroundColor:'rgba(115, 123, 76, .7)', borderBottom:'4px solid white', borderTop:'4px solid white'}} key={"td"+i}>{calendarNum}</td>)
                }
                calendarNum++
            }
            else if(calendarNum === dayOfMaxExtension)
            {
                tableRows.push(<td className="text-white" style={{ backgroundColor:'rgba(115, 123, 76, 1)', borderBottomRightRadius: '20px', borderTopRightRadius: '20px', borderBottom:'4px solid white', borderTop:'4px solid white'}} key={"td"+i}>{calendarNum}</td>)
                calendarNum++
            }
            else
            {
                if(calendarNum <= numOfDaysInMaxExtMonth)
                {
                    tableRows.push(<td key={"td"+i} style={{borderBottom:'4px solid white', borderTop:'4px solid white'}}>{calendarNum}</td>)
                }
                else
                {
                    tableRows.push(<td key={"td"+i} style={{borderBottom:'4px solid white', borderTop:'4px solid white'}}/>)
                }
                calendarNum++
            }
        }
        else
        {
            if((dayOfWeek+1) > i)
            {
                tableRows.push(<td key={"t"+i} style={{borderBottom:'4px solid white', borderTop:'4px solid white'}}/>)
            }
            else if(calendarNum >= DateTime.now().setZone('MST').day && calendarNum < dayOfMaxExtension)
            {
                if(calendarNum === DateTime.now().setZone('MST').day)
                {
                    tableRows.push(<td className="text-white" style={{backgroundColor:'rgba(115, 123, 76, .7)', borderBottomLeftRadius: '20px', borderTopLeftRadius: '20px',borderBottom:'4px solid white', borderTop:'4px solid white' }} key={"td"+i}>{calendarNum}</td>)
                }
                else
                {
                    tableRows.push(<td className="text-white" style={{backgroundColor:'rgba(115, 123, 76, .7)', borderBottom:'4px solid white', borderTop:'4px solid white'}} key={"td"+i}>{calendarNum}</td>)
                }
                calendarNum++
            }
            else if(calendarNum === dayOfMaxExtension)
            {
                tableRows.push(<td className="text-white" style={{ backgroundColor:'rgba(115, 123, 76, 1)', borderBottomRightRadius: '20px', borderTopRightRadius: '20px', borderBottom:'4px solid white', borderTop:'4px solid white'}} key={"td"+i}>{calendarNum}</td>)
                calendarNum++
            }
            else
            {
                if(calendarNum <= numOfDaysInMaxExtMonth)
                {
                    tableRows.push(<td key={"td"+i} style={{borderBottom:'4px solid white', borderTop:'4px solid white'}}>{calendarNum}</td>)
                }
                else
                {
                    tableRows.push(<td key={"td"+i} style={{borderBottom:'4px solid white', borderTop:'4px solid white'}}/>)
                }
                calendarNum++
            }
        }

        if(i % 7 === 0)
        {
            table.push(<tr key={"trend"+i}>{tableRows}</tr>)
            tableRows = []
        }
    }

    return table
}

const CIXCalendar = ({maxExtensionDate, isSpanish}) => {

        return(
            <div className="row">
                <div className="col-12 d-flex justify-content-center">
                    <table>
                        <thead>
                            <tr>
                                {getCalendarHead(isSpanish)}
                            </tr>
                        </thead>
                        <tbody className="text-center">
                            {getCalendarDetails(maxExtensionDate)}
                        </tbody>
                    </table>
                </div>
            </div>
        )
}

CIXCalendar.propTypes = {
    isSpanish: PropTypes.bool.isRequired,
    maxExtensionDate: PropTypes.string
}

export default CIXCalendar