/* eslint react/no-multi-comp: "off" */

import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import CircularProgress from '@mui/material/CircularProgress'

import { LOADING_STATUS_INIT, LOADING_STATUS_IN_PROGRESS, LOADING_STATUS_SUCCESS, LOADING_STATUS_FAILURE } from '../../../constants/loading-status-constants'

import PayableAccountsTable from './payable-accounts-table'

import { getSelectedPayableAccounts, selectAllPayableAccounts, selectNoneAccounts, getPaymentDate, resetPaymentInfoList, getPaymentAmount,
         getMultiPaymentInfoFromBillAccountList, verifyMultiplePayments, selectFirst100UnpaidAccounts, selectUnpaidAccounts, reloadMultiPaymentInfoFromBillAccountList,
         verifyPaymentsNoState, submitPaymentsNoState, sortPaymentList, setPaymentBillAccountListCount,
         enableAccountFilter, filterAccountsDue, filterAccountsPastDue, filterAccountsScheduled, filterAccountsSurePay, filterAccountsSummaryMaster, filterAccountsText
    } from '../../../actions/auth/payment/multi-payment-actions'

import { setFormStartEventEmitted, } from '../../../actions/auth/payment/multi-payment-tracking-actions'

import { resetMultipleMpowerInfo } from '../../../actions/auth/payment/multi-payment-ngp-actions'
import { selectBillAccountOnChange, getBillAccounts } from '../../../actions/auth/bill_account/bill-account-actions'
import { getLandlordAccounts } from '../../../actions/auth/landlord/landlord-actions'
import { getBankAccountList } from '../../../actions/auth/payment/bank-account-actions'
import { deletePayment } from '../../../actions/auth/payment/payment-actions'

import { addSnackbarMessage, dismissSnackbarMessage } from '../../../actions/common/snackbar'
import { myAccountConstants } from '../../../constants/myaccount-constants'
import { startCIXFlow } from '../../../actions/auth/payment/cix-actions'

import multiPaymentUtils from '../../common_payment/multi-payment-utils'

import { billAccountConstants } from '../../../constants/bill-account-constants';

import { formStartEvent } from '../../../srp_modules/analytics-events'
import { paymentConstants } from '../../common_payment/payment-constants'
import { withRouter } from '../../../srp_modules/with-router'

const loadingStyle = {
    marginLeft: "45%",
    marginTop: "20%",
    marginBottom: "20%"
}

const loadingStyleResp = {
    marginLeft: "40%",
    marginTop: "20%",
    marginBottom: "20%"
}

let hasLoaded = (props) => {
    let loaded = true

    if (props.billAccountListStatus === LOADING_STATUS_INIT ||
        props.billAccountListStatus === LOADING_STATUS_IN_PROGRESS ||
        props.paymentInfoCompleteStatus === LOADING_STATUS_INIT ||
        props.paymentInfoCompleteStatus === LOADING_STATUS_IN_PROGRESS ||
        props.bankAccountListStatus === LOADING_STATUS_INIT ||
        props.bankAccountListStatus === LOADING_STATUS_IN_PROGRESS ||
        props.landlordAccountsStatus === LOADING_STATUS_INIT ||
        props.landlordAccountsStatus === LOADING_STATUS_IN_PROGRESS) {
            loaded = false
    }

    return loaded
}

let updateDataIfNeeded = (props) => {
    if (props.bankAccountListStatus === LOADING_STATUS_INIT) {
        props.getBankAccountList()
    }

    if (props.billAccountListStatus === LOADING_STATUS_INIT) {
        props.getBillAccounts()
    }

    if (props.landlordAccountsStatus === LOADING_STATUS_INIT) {
        props.getLandlordAccounts()
    }
}

let updatePaymentInfoListIfNeeded = async (props) => {
    if (props.billAccountListStatus !== LOADING_STATUS_SUCCESS
        || props.landlordAccountsStatus !== LOADING_STATUS_SUCCESS) {
            return
    }

    props.getMultiPaymentInfo(getPaymentAccountList(props))
}

let hasPaymentAccountListChanged = (prevProps, props) => {
    if(props.billAccountListStatus === LOADING_STATUS_SUCCESS &&
        props.landlordAccountsStatus === LOADING_STATUS_SUCCESS) {

        // get set of distinct billAccounts associated to current props
        let currentList = getPaymentAccountList(props)

        if(prevProps == null) {
            // the multi-payment redcuer will reset the paymentBillAccountListCount value
            // to the initial state value 0 in the event of any of the following actions:
            //  - multiPaymentConstants.SUBMIT_MULTIPLE_SUCCESS
            //  - multiPaymentNgpConstants.SUBMIT_MULTIPLE_NGP_SUCCESS
            // so if the currentList.size is greater than zero
            // AND props.paymentBillAccountListCount is equal to the currentList.size
            // then paymentBillAccountList has not changed since last load
            // and can use the current paymentInfoList from state
            if(currentList.size > 0 && props.paymentBillAccountListCount === currentList.size) {
                return false
            }

            return true
        }

        if(prevProps.billAccountListStatus !== props.billAccountListStatus ||
            prevProps.landlordAccountsStatus !== props.landlordAccountsStatus){
            return true
        }

        // get set of distinct billAccounts associated to previous props
        let prevList = getPaymentAccountList(prevProps)

        // create new set of distinct billAccounts that is the intersect of the currentList & prevList
        let intersect = new Set([...currentList].filter(billAccount => prevList.has(billAccount)))

        // if current list size equals previous list size and
        // the intersect list size equals the current list size
        // then prev list and current list contain the same billAccounts
        // which indicates the Payment Account List has not changed
        if(currentList.size === prevList.size && currentList.size === intersect.size) {
            return false
        }

        return true
    }
    return false
}

let getPaymentAccountList = (props) => {
    const powerAccountList = props.billAccountList.filter(o => o.accountType === billAccountConstants.POWER_ACCOUNT)
    let userBillAccountList = multiPaymentUtils.getUserBillAccountList(powerAccountList)
    let landlordBillAccountList = multiPaymentUtils.getLandlordBillAccountList(props.landlordAccounts)

    return new Set([...userBillAccountList, ...landlordBillAccountList])
}

let undoDelete = async (paymentInfo, dispatch, t) =>  {
    let verifyResult = await dispatch(verifyPaymentsNoState(paymentInfo))
    if (!verifyResult.payload.isSuccess) {
        dispatch(addSnackbarMessage(<span>{t("Undo remove payment failed")}</span>))
        return false
    }

    let submitResult = await dispatch(submitPaymentsNoState(paymentInfo, verifyResult.payload.paymentsReferenceNumber))

    if (!submitResult.payload.isSuccess) {
        dispatch(addSnackbarMessage(<span>{t("Undo remove payment failed")}</span>))
        return false
    }

    dispatch(reloadMultiPaymentInfoFromBillAccountList(paymentInfo.payments.map(payment => payment.billAccount)))

    dispatch(addSnackbarMessage(<span>{t("Undo remove payment successful")}</span>))
    return true
}

let getUndoDeleteElement = (deletedPayments, dispatch) => {
    let accountPayments = []
    let paymentDate = deletedPayments[0].paymentDate
    deletedPayments.forEach(paymentInfo => {
        accountPayments.push({
            billAccount: paymentInfo.billAccountNumber,
            paymentAmount: paymentInfo.paymentAmount,
            paymentDate: paymentDate
        })
    })
    let paymentInfo = {
        bankAccountId: deletedPayments[0].fundingSourceId,
        paymentDate: deletedPayments[0].paymentDate,
        payments: accountPayments
    }

    return (<button className="displayAsBlueText text-uppercase" onClick={() => {
        dispatch(dismissSnackbarMessage())
        undoDelete(paymentInfo, dispatch)}
    }>Undo</button>)
}

class PayableAccountsTableContainer extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            showGeneralWarning: false,
        }

        this.getLoadingCard = this.getLoadingCard.bind(this)
        this.verifyMultiplePaymentsOnClick = this.verifyMultiplePaymentsOnClick.bind(this)
        this.hasError = this.hasError.bind(this)

        this.refreshData = this.refreshData.bind(this)

        this.formDataChanged = this.formDataChanged.bind(this)
        this.creditAccountSelectedOnClickAndTrack = this.creditAccountSelectedOnClickAndTrack.bind(this)
        this.paymentAmountOnChangeAndTrack = this.paymentAmountOnChangeAndTrack.bind(this)
        this.paymentDateOnChangeAndTrack = this.paymentDateOnChangeAndTrack.bind(this)

        this.selectAllPayableAccountsOnClickAndTrack = this.selectAllPayableAccountsOnClickAndTrack.bind(this)
        this.selectUnpaidAccountsOnClickAndTrack = this.selectUnpaidAccountsOnClickAndTrack.bind(this)
        this.selectFirst100UnpaidAccountsOnClickAndTrack = this.selectFirst100UnpaidAccountsOnClickAndTrack.bind(this)
        this.selectNoneAccountsOnClickAndTrack = this.selectNoneAccountsOnClickAndTrack.bind(this)
    }

    componentDidMount(){
        this.props.enableAccountFilter(false)
        if(!hasLoaded(this.props)){
            updateDataIfNeeded(this.props)

            if(hasPaymentAccountListChanged(null, this.props)) {
                updatePaymentInfoListIfNeeded(this.props)
            }
        }
    }

    componentDidUpdate(prevProps) {
        if(!hasLoaded(this.props)) {
            updateDataIfNeeded(this.props)

            if(hasPaymentAccountListChanged(prevProps, this.props)) {
                updatePaymentInfoListIfNeeded(this.props)
            }
        }
    }

    refreshData() {
        updateDataIfNeeded(this.props)
        updatePaymentInfoListIfNeeded(this.props)
    }

    formDataChanged() {
        if (this.props.formStartEventEmitted === false) {
            const formName = paymentConstants.ADOBE_ANALYTICS_FORM_NAME
            const stepName = paymentConstants.PAYMENT_STEPS[0]
            formStartEvent(formName, stepName)

            this.props.setFormStartEventEmitted()
        }
    }

    creditAccountSelectedOnClickAndTrack(billAccount, paymentInfo) {
        this.props.getSelectedPayableAccountsOnClick(billAccount, paymentInfo)
        this.formDataChanged()
    }

    paymentAmountOnChangeAndTrack(billAccount, paymentAmount) {
        this.props.getPaymentAmountOnChange(billAccount, paymentAmount)
        this.formDataChanged()
    }

    paymentDateOnChangeAndTrack(billAccount, date, paymentAmount) {
        this.props.getPaymentDateOnChange(billAccount, date, paymentAmount)
        this.formDataChanged()
    }

    selectAllPayableAccountsOnClickAndTrack() {
        this.props.selectAllPayableAccountsOnClick()
        this.formDataChanged()
    }

    selectUnpaidAccountsOnClickAndTrack() {
        this.props.selectUnpaidAccountsOnClick()
        this.formDataChanged()
    }

    selectFirst100UnpaidAccountsOnClickAndTrack() {
        this.props.selectFirst100UnpaidAccountsOnClick()
        this.formDataChanged()
    }

    selectNoneAccountsOnClickAndTrack() {
        this.props.selectNoneAccountsOnClick()
        this.formDataChanged()
    }

    hasError(paymentList) {
        let error = false
        for (let i = 0; i < paymentList.length; i++) {
            if (paymentList[i].paymentDateError.errorLevel === myAccountConstants.ERROR_LEVEL.ERROR || paymentList[i].paymentAmountError.errorLevel === myAccountConstants.ERROR_LEVEL.ERROR) {
                error = true
            }
        }

        this.setState({
            ...this.state,
            showGeneralWarning: error
        })

        return error
    }

    getLoadingCard() {
        return (
            <div className="srp-card-body">
                <div className="srp-card-details">
                    <div className="d-none d-lg-block">
                        <CircularProgress size={80} thickness={5} style={loadingStyle} />
                    </div>
                    <div className="d-block d-lg-none">
                        <CircularProgress size={80} thickness={5} style={loadingStyleResp} />
                    </div>
                </div>
            </div>
        )
    }

    verifyMultiplePaymentsOnClick()  {
        if (!this.hasError(this.props.paymentList)) {
            this.props.verifyMultiplePaymentsOnClick()
        }
    }

    render(){
        let renderedCard = null
        let isSubmitting = this.props.verifyMultiPaymentResultStatus === LOADING_STATUS_IN_PROGRESS
        if (!hasLoaded(this.props)) {
            renderedCard = this.getLoadingCard()
        }
        else if (this.props.paymentInfoListStatus === LOADING_STATUS_SUCCESS) {
            if (this.props.paymentInfoList.length > 0)
            {
                let hasBankAccounts = this.props.bankAccountList.length > 0
                renderedCard = (
                    <PayableAccountsTable
                        paymentList={this.props.paymentList}
                        showMaxPaymentWarning={this.props.showMaxPaymentWarning}
                        paymentInfoList={this.props.paymentInfoList}
                        selectAllPayableAccountsOnClick={this.selectAllPayableAccountsOnClickAndTrack}
                        selectAll={this.props.selectAll}
                        selectUnpaidAccountsOnClick={this.selectUnpaidAccountsOnClickAndTrack}
                        selectFirst100UnpaidAccountsOnClick={this.selectFirst100UnpaidAccountsOnClickAndTrack}
                        selectNoneAccountsOnClick={this.selectNoneAccountsOnClickAndTrack}
                        getSelectedPayableAccountsOnClick={this.creditAccountSelectedOnClickAndTrack}
                        getPaymentAmountOnChange={this.paymentAmountOnChangeAndTrack}
                        getPaymentDateOnChange={this.paymentDateOnChangeAndTrack}
                        deletePaymentClick={this.props.deletePaymentClick}
                        verifyMultiplePaymentsOnClick={this.verifyMultiplePaymentsOnClick}
                        viewBillClick={this.props.viewBillClick}
                        isSubmitting={isSubmitting}
                        hasBankAccounts={hasBankAccounts}
                        showGeneralWarning={this.state.showGeneralWarning}
                        cixClick={this.props.cixClick}
                        unpaidBillAccountsCount={this.props.unpaidBillAccountsCount}
                        selectableCount={this.props.selectableCount}
                        accountFilter={this.props.accountFilter}
                        enableAccountFilter={this.props.enableAccountFilter}
                        filterAccountsDue={this.props.filterAccountsDue}
                        filterAccountsPastDue={this.props.filterAccountsPastDue}
                        filterAccountsScheduled={this.props.filterAccountsScheduled}
                        filterAccountsSurePay={this.props.filterAccountsSurePay}
                        filterAccountsSummaryMaster={this.props.filterAccountsSummaryMaster}
                        filterAccountsText={this.props.filterAccountsText}
                        totalCreditAccounts={this.props.totalCreditAccounts}
                        supportPhoneNumber={this.props.supportPhoneNumber}
                        errorVerifyingPayments={this.props.errorVerifyingPayments}
                    />
                )
            }
        }
        else if(this.props.paymentInfoListStatus === LOADING_STATUS_FAILURE) {
            renderedCard = (
                <div className="srp-card-body">
                    <div className="srp-card-details">
                        <div className="text-center">
                            <div className="mb-1" style={{color: "#9E2A2B"}}><i className="material-icons" style={{fontSize:"50px"}}>error_outline</i></div>
                            <div className="mb-1" style={{color: "#9E2A2B"}}><strong>{this.props.t("Something went wrong")}</strong></div>
                            <div className="mb-4">{this.props.t("We were unable to retrieve payment data.")}</div>
                        </div>
                        <div className="d-flex justify-content-end">
                            <a onClick={()=>this.refreshData()} className="btn srp-btn btn-lightblue text-white mr-2">{this.props.t("Try again")}</a>
                        </div>
                    </div>
                </div>
            )
        }

        return renderedCard
    }
}

const mapStateToProps = state => {
    let billAccountList = state.accountInfo.billAccount.billAccountList
    let paymentList = state.multiPayment.paymentList
    let allCommercial = false
    if (paymentList && billAccountList && paymentList.length > 0) {
        allCommercial = paymentList.every(p => billAccountList.find(b => b.account === p.billAccount)?.isCommercial ?? false)
    }

    let supportPhoneNumber = allCommercial
        ? myAccountConstants.COMMERCIAL_CUSTOMER_SERVICE_PHONE_NUMBER_FMTTD
        : myAccountConstants.RESIDENTIAL_CUSTOMER_SERVICE_PHONE_NUMBER_FMTTD

    return { ...state.multiPayment, ...state.bankAccounts,
                billAccountList,
                billAccountListStatus: state.accountInfo.billAccount.billAccountListStatus,
                landlordAccounts: state.landlord.landlordAccounts,
                landlordAccountsStatus: state.landlord.landlordAccountsStatus,
                supportPhoneNumber,
                summaryBillAccounts: state.accountInfo.billAccount.summaryBillAccounts }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getSelectedPayableAccountsOnClick: (billAccount, paymentInfo) => {
            dispatch(getSelectedPayableAccounts(billAccount, paymentInfo))
        },
        selectAllPayableAccountsOnClick: () => {
            dispatch(selectAllPayableAccounts())
        },
        selectUnpaidAccountsOnClick: () => {
            dispatch(selectUnpaidAccounts())
        },
        selectFirst100UnpaidAccountsOnClick: () => {
            dispatch(selectFirst100UnpaidAccounts())
        },
        selectNoneAccountsOnClick: () => {
            dispatch(selectNoneAccounts())
        },
        getPaymentDateOnChange: (billAccount, date, paymentAmount) => {
            dispatch(getPaymentDate(billAccount, date, paymentAmount))
        },
        getPaymentAmountOnChange: (billAccount, paymentAmount) => {
            dispatch(getPaymentAmount(billAccount, paymentAmount))
        },
        getBillAccounts: () => {
            dispatch(getBillAccounts())
        },
        getBankAccountList: () => {
            dispatch(getBankAccountList())
        },
        getMultiPaymentInfo: async (paymentAccountList) => {
            //await dispatch(resetPaymentInfoList())
            await dispatch(resetMultipleMpowerInfo())
            await dispatch(setPaymentBillAccountListCount(paymentAccountList.size))
            await dispatch(getMultiPaymentInfoFromBillAccountList(paymentAccountList))
        },
        deletePaymentClick: async (referenceNumber, deletedPayments) => {
            const bankAccountId = deletedPayments[0].fundingSourceId
            const billAccountList = deletedPayments.map(p => p.billAccountNumber)

            let deleteResult = await dispatch(deletePayment(referenceNumber, billAccountList, bankAccountId))
            if (deleteResult.type==="DELETE_PAYMENT_SUCCESS" && deleteResult.payload && deleteResult.payload.isSuccess) {
                dispatch(reloadMultiPaymentInfoFromBillAccountList(billAccountList))
                // snackbar with undo link
                dispatch(addSnackbarMessage(<span>{ownProps.t("Payment removed")}</span>, getUndoDeleteElement(deletedPayments, dispatch, ownProps.t)))
            } else {
                dispatch(addSnackbarMessage(<span>{ownProps.t("Remove payment failed")}</span>))
            }
        },
        resetPaymentInfoList: async () => {
            await dispatch(resetPaymentInfoList())
        },
        verifyMultiplePaymentsOnClick: async () => {
            const verifyPaymentResult = await dispatch(verifyMultiplePayments())

            if (typeof verifyPaymentResult.payload !== 'undefined'
                && verifyPaymentResult.payload.isSuccess) {
                await dispatch(sortPaymentList())
                ownProps.router.navigate('/myaccount/payment/multi/verify')
                // redirect to confirmation page
            }
        },
        viewBillClick: async (billAccount) => {
            await dispatch(selectBillAccountOnChange(billAccount))
            ownProps.router.navigate('/myaccount/bill')
        },
        getLandlordAccounts: () => {
            dispatch(getLandlordAccounts())
        },
        cixClick: async (billAccount) => {
            await dispatch(selectBillAccountOnChange(billAccount))
            await dispatch(startCIXFlow())
            ownProps.router.navigate('/myaccount/dashboard')
        },
        enableAccountFilter: async(enabled) => {
            await dispatch(enableAccountFilter(enabled))
        },
        setFormStartEventEmitted: () => {
            dispatch(setFormStartEventEmitted())
        },
        filterAccountsDue: async(enabled) => {
            await dispatch(filterAccountsDue(enabled))
        },
        filterAccountsPastDue: async(enabled) => {
            await dispatch(filterAccountsPastDue(enabled))
        },
        filterAccountsScheduled: async(enabled) => {
            await dispatch(filterAccountsScheduled(enabled))
        },
        filterAccountsSurePay: async (enabled) => {
            await dispatch(filterAccountsSurePay(enabled))
        },
        filterAccountsSummaryMaster: async (enabled) => {
            await dispatch(filterAccountsSummaryMaster(enabled))
        },
        filterAccountsText: async (enabled, filterText) => {
            await dispatch(filterAccountsText(enabled, filterText))
        }
    }
}

PayableAccountsTableContainer.propTypes = {
    bankAccountListStatus: PropTypes.string.isRequired,
    bankAccountList: PropTypes.arrayOf(PropTypes.object).isRequired,
    billAccountListStatus: PropTypes.string,
    billAccountList: PropTypes.arrayOf(PropTypes.object),
    summaryBillAccounts: PropTypes.arrayOf(PropTypes.object),
    paymentInfoList: PropTypes.arrayOf(PropTypes.object).isRequired,
    paymentInfoListStatus: PropTypes.string.isRequired,
    getSelectedPayableAccountsOnClick: PropTypes.func.isRequired,
    selectAllPayableAccountsOnClick: PropTypes.func.isRequired,
    selectAll: PropTypes.bool.isRequired,
    selectUnpaidAccountsOnClick: PropTypes.func.isRequired,
    selectFirst100UnpaidAccountsOnClick: PropTypes.func.isRequired,
    getPaymentDateOnChange: PropTypes.func.isRequired,
    getPaymentAmountOnChange: PropTypes.func.isRequired,
    getMultiPaymentInfo: PropTypes.func.isRequired,
    deletePaymentClick: PropTypes.func.isRequired,
    paymentList: PropTypes.arrayOf(PropTypes.object),
    showMaxPaymentWarning: PropTypes.bool.isRequired,
    verifyMultiplePaymentsOnClick: PropTypes.func,
    verifyMultiPaymentResultStatus: PropTypes.string.isRequired,
    viewBillClick: PropTypes.func.isRequired,
    resetPaymentInfoList: PropTypes.func.isRequired,
    landlordAccounts: PropTypes.object.isRequired,
    landlordAccountsStatus: PropTypes.string.isRequired,
    getLandlordAccounts: PropTypes.func.isRequired,
    paymentInfoCompleteStatus: PropTypes.string.isRequired,
    getBankAccountList: PropTypes.func.isRequired,
    cixClick: PropTypes.func.isRequired,
    paymentBillAccountListCount: PropTypes.number.isRequired,
    selectNoneAccountsOnClick: PropTypes.func.isRequired,
    unpaidBillAccountsCount: PropTypes.number.isRequired,
    selectableCount: PropTypes.number.isRequired,
    accountFilter: PropTypes.object.isRequired,
    enableAccountFilter: PropTypes.func.isRequired,
    filterAccountsDue: PropTypes.func.isRequired,
    filterAccountsPastDue: PropTypes.func.isRequired,
    filterAccountsScheduled: PropTypes.func.isRequired,
    filterAccountsSurePay: PropTypes.func.isRequired,
    filterAccountsSummaryMaster: PropTypes.func.isRequired,
    filterAccountsText: PropTypes.func.isRequired,
    totalCreditAccounts: PropTypes.number.isRequired,
    setFormStartEventEmitted: PropTypes.func.isRequired,
    formStartEventEmitted: PropTypes.bool.isRequired,
    supportPhoneNumber: PropTypes.string.isRequired,
    errorVerifyingPayments: PropTypes.bool.isRequired,
    router: PropTypes.shape({
        navigate: PropTypes.func
    }),
    t: PropTypes.func.isRequired,
    i18n: PropTypes.object.isRequired
}

export default withTranslation("multiPayment")(withRouter(connect(mapStateToProps, mapDispatchToProps)(PayableAccountsTableContainer)))