import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import NoBillAccountsAddAccountForm from './no-bill-accounts-add-account-form'
import CallAnytime from '../../components/myaccount_header/call-anytime'

import { addBillAccount, getBillAccounts, selectBillAccountOnChange } from '../../actions/auth/bill_account/bill-account-actions'
import { myAccountConstants } from '../../constants/myaccount-constants'
import { withRouter } from '../../srp_modules/with-router'

class NoBillAccountsAddAccountContainer extends React.Component {
  render() {
    return (
      <div>
        <div className="container py-3">
          <CallAnytime />
          <h3 className="mt-3">Add an account</h3>

          <div className="row">
            <div className="col-lg-6 col-12 mt-4">
              <div className="srp-card-header">Provide your account information</div>

              <div className="srp-card-body">
                <div className="srp-card-details">
                  <NoBillAccountsAddAccountForm addNewBillAccount={this.props.addNewBillAccount}/>
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-12 mt-4">
              <div className="srp-card-header">Need help?</div>
              <div className="srp-card-body srp-orange-background">
                <div className="srp-card-details">
                  <p className="d-lg-block d-none mb-0">
                    {`For questions please contact SRP Residential Customer Services at
                                    ${myAccountConstants.RESIDENTIAL_CUSTOMER_SERVICE_PHONE_NUMBER_FMTTD}
                                    , available to serve you 24 hours a day, seven days a week.`}
                  </p>
                  <p className="d-lg-none d-block mb-0">
                    {"For questions please contact SRP Residential Customer Services at "}
                    <a href={"tel:" + myAccountConstants.RESIDENTIAL_CUSTOMER_SERVICE_PHONE_NUMBER}>
                      {myAccountConstants.RESIDENTIAL_CUSTOMER_SERVICE_PHONE_NUMBER_FMTTD}
                    </a>
                    {", available to serve you 24 hours a day, seven days a week."}
                  </p>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    addNewBillAccount: async (values, goodies) => {
        let response = await dispatch(addBillAccount(values.accountNumber, values.phoneNumber, values.nickname))

        if (response.error === true) {
            goodies.setStatus({ error: 'We\'re sorry, something went wrong. Please try again later.' })
            goodies.setSubmitting(false)
            return
        }

        if (response.payload.success !== true && response.payload.error) {
            if (response.payload.errorNumber === 2) {
                goodies.setStatus({ error: 'This SRP account is already active in your list of accounts.' })
                goodies.setSubmitting(false)
                return
            }

            goodies.setStatus({ error: response.payload.error })
            goodies.setSubmitting(false)
            return
        }

        let accountListResponse = await dispatch(getBillAccounts())
        if (accountListResponse.error === true) {
            goodies.setStatus({ error: 'We\'re sorry, something went wrong. Please try again later.' })
            goodies.setSubmitting(false)
            return
        }

        let selectResponse = await dispatch(selectBillAccountOnChange(values.accountNumber))
        if (selectResponse.error === true) {
            goodies.setStatus({ error: 'We\'re sorry, something went wrong. Please try again later.' })
            goodies.setSubmitting(false)
            return
        }

        ownProps.router.navigate('/myaccount/dashboard', { replace: true })
    }
  }
}

NoBillAccountsAddAccountContainer.propTypes = {
  addNewBillAccount: PropTypes.func,
  router: PropTypes.shape({
    navigate: PropTypes.func
  })
}

export default withRouter(connect(null, mapDispatchToProps)(NoBillAccountsAddAccountContainer))