import React from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'

import SinglePaymentDetailsTable from './single-payment-details-table'
import MultiplePaymentDetailsTable from './multiple-payment-details-table'
import { withRouter } from '../../../srp_modules/with-router'

class PaymentDetailsTableContainer extends React.Component {
    constructor(props) {
        super(props)

        if (this.isStateEmpty())
            this.props.router.navigate('/myaccount/payment/gift', { replace: true })
    }

    isStateEmpty() { return this.props.bankName === '' && this.props.truncatedBankAccountNumber === '' && this.props.paymentList.length === 0 }

    render() {
        return (
            this.isStateEmpty() ? null : (this.props.paymentList.length === 1 ?
                <SinglePaymentDetailsTable bankName={this.props.bankName} truncatedBankAccountNumber={this.props.truncatedBankAccountNumber}
                    paymentList={this.props.paymentList} referenceNumber={this.props.referenceNumber} t={this.props.t} /> :
                <MultiplePaymentDetailsTable bankName={this.props.bankName} truncatedBankAccountNumber={this.props.truncatedBankAccountNumber}
                    paymentList={this.props.paymentList} referenceNumber={this.props.referenceNumber} t={this.props.t} />
            )
        )
    }
}

const mapStateToProps = state => {
    const selectedBankId = state.giftPaymentBankInfo.selectedBankId
    const selectedBank = state.bankAccounts.bankAccountList.find(ba => ba.bankId === selectedBankId)

    return {
        bankName: selectedBank ? (selectedBank.nickName || selectedBank.bankName) : '',
        truncatedBankAccountNumber: selectedBank ? selectedBank.accountNumber : '',
        paymentList: state.giftPaymentList.paymentList }
}

PaymentDetailsTableContainer.propTypes = {
    t: PropTypes.func.isRequired,
    bankName: PropTypes.string.isRequired,
    truncatedBankAccountNumber: PropTypes.string.isRequired,
    paymentList: PropTypes.arrayOf(PropTypes.shape({
        srpAccountNumber: PropTypes.string.isRequired,
        paymentAmount: PropTypes.number.isRequired
    })),
    referenceNumber: PropTypes.number,
    router: PropTypes.shape({
        navigate: PropTypes.func
    })
}

export default withRouter(connect(mapStateToProps)(PaymentDetailsTableContainer))