export const eraProgramConstants = {
    HOUSING: {
        RENT: "Rent",
        OWN: "Own Home",
    },

    RACE: {
        AMERICAN_INDIAN_ALASKA_NATIVE: "American Indian and Alaska Native",
        ASIAN: "Asian",
        BLACK_AFRICAN_AMERICAN: "Black or African American",
        HAWAIIAN_PACIFIC_ISLANDER: "Native Hawaiian or Other Pacific Islander",
        WHITE: "White"
    },

    ETHNICITY: {
        HISPANIC_LATINO: "Hispanic or Latino",
        NOT_HISPANIC_LATINO: "Not Hispanic or Latino",
    },

    GENDER: {
        FEMALE: "Female",
        MALE: "Male"
    },

    SUBMISSION_TYPE: {
        SIGNUP: 1,
        RECERTIFY: 2
    },

    YES: "yes",
    NO: "no",
    NONE: "none",
    CHOOSE_NOT_RESPOND: "Choose not to respond",

    HOUSING_DETAILS_STEP_1: 0,
    BACKGROUND_INFO_STEP_2: 1,

    INELIGIBLE_REASON: {
        OWN_HOME: 0,
        NO_COVID_IMPACT: 1,
        OVER_ALLOWED_ASSIST: 2,
        HARDSHIP: 3
    },

    SUBMIT_ERAP_FORM_REQUEST: "SUBMIT_ERAP_FORM_REQUEST",
    SUBMIT_ERAP_FORM_SUCCESS: "SUBMIT_ERAP_FORM_SUCCESS",
    SUBMIT_ERAP_FORM_FAILURE: "SUBMIT_ERAP_FORM_FAILURE",
    REFRESH_ERAP_FORM: "REFRESH_ERAP_FORM",

    STATUS: {
        NOT_ELIGIBLE: 0,
        NEW_ELIGIBLE: 1,
        APPLIED_UNDER_REVIEW: 2,
        APPROVED: 3,
        DENIED: 4,
        SELF_DECLARED_INELIGIBLE: 5,
        RECERTIFY: 6
    },

    PAYMENT_STATUS: {
        APPROVED: 0,
        PENDING: 1,
        DENIED: 2,
    }
}