import React from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'

import AccountDetailsContainer from './acct-details-container'
import SelectPlan from './select-plan'
import Review from './review'
import LoginPrefillFormBanner from '../common/login-prefill-form-banner'

import { pricePlanConstants } from '../../constants/price-plan-constants'

const ChangePricePlanAnon = ({ accountType, setAccountType, activeStep, setActiveStep, isLoggedIn, isCommercial,
    accountDetails, setAccountDetails, accountDetailsChanged, setAccountDetailsChanged, currentRateCodeDescription,
    selectedPricePlanResidential, selectedPricePlanCommercial, selectNewPricePlanResidential, selectNewPricePlanCommercial,
    currentPlanIsTouOrSolar, selectedPricePlan,
    createAndSubmitChangePricePlanForm, changePricePlanStatus, fromCmpp, backButtonClick, i18n }) => {

    return (
        <div className="col-lg-6 col-12">
            <div className="srp-card-header" id="topOfCppForm">
                {i18n.language === "es"
                    ? pricePlanConstants.STEPS_ANON[activeStep].titleEs
                    : pricePlanConstants.STEPS_ANON[activeStep].titleEn
                }
            </div>
            <div className="srp-card-body">
                <div className="srp-card-details">
                    {!isLoggedIn && activeStep === 0
                        ? <LoginPrefillFormBanner redirectToForm={'/changepriceplan'} />
                        : null
                    }
                    <div className="d-lg-block d-none">
                        <div className="col-9 p-0 mb-4">
                            <Stepper activeStep={activeStep} className="p-0">
                                {pricePlanConstants.STEPS_ANON.map(item => {
                                    return (
                                        <Step key={item.step}>
                                            <StepLabel>{i18n.language === "es" ? item.labelEs : item.labelEn}</StepLabel>
                                        </Step>
                                    )
                                })}
                            </Stepper>
                        </div>
                    </div>
                    <div className="d-lg-none d-block">
                        <div className="p-0 mb-4">
                            <Stepper activeStep={activeStep} alternativeLabel className="p-0">
                                {pricePlanConstants.STEPS_ANON.map(item => {
                                    return (
                                        <Step key={item.step}>
                                            <StepLabel>{i18n.language === "es" ? item.labelEs : item.labelEn}</StepLabel>
                                        </Step>
                                    )
                                })}
                            </Stepper>
                        </div>
                    </div>
                    {activeStep === 0
                        ? <AccountDetailsContainer
                            accountType={accountType}
                            setAccountType={setAccountType}
                            activeStep={activeStep}
                            setActiveStep={setActiveStep}
                            accountDetails={accountDetails}
                            setAccountDetails={setAccountDetails}
                            accountDetailsChanged={accountDetailsChanged}
                            setAccountDetailsChanged={setAccountDetailsChanged} />
                        : activeStep === 1
                            ? <SelectPlan
                                activeStep={activeStep}
                                setActiveStep={setActiveStep}
                                isLoggedIn={isLoggedIn}
                                isCommercial={isCommercial}
                                currentRateCodeDescription={currentRateCodeDescription}
                                selectedPricePlanResidential={selectedPricePlanResidential}
                                selectedPricePlanCommercial={selectedPricePlanCommercial}
                                selectNewPricePlanResidential={selectNewPricePlanResidential}
                                selectNewPricePlanCommercial={selectNewPricePlanCommercial}
                                backButtonClick={backButtonClick} />
                            : <Review
                                activeStep={activeStep}
                                setActiveStep={setActiveStep}
                                accountDetails={accountDetails}
                                isCommercial={isCommercial}
                                currentPlanIsTouOrSolar={currentPlanIsTouOrSolar}
                                selectedPricePlan={selectedPricePlan}
                                createAndSubmitChangePricePlanForm={createAndSubmitChangePricePlanForm}
                                changePricePlanStatus={changePricePlanStatus}
                                fromCmpp={fromCmpp}
                                backButtonClick={backButtonClick} />
                    }
                </div>
            </div>
        </div>
    )

}

ChangePricePlanAnon.propTypes = {
    accountType: PropTypes.number,
    setAccountType: PropTypes.func,
    activeStep: PropTypes.number,
    setActiveStep: PropTypes.func,
    isLoggedIn: PropTypes.bool,
    isCommercial: PropTypes.bool,
    accountDetails: PropTypes.object,
    setAccountDetails: PropTypes.func,
    currentRateCodeDescription: PropTypes.string,
    selectedPricePlanResidential: PropTypes.number,
    selectedPricePlanCommercial: PropTypes.number,
    selectNewPricePlanResidential: PropTypes.func,
    selectNewPricePlanCommercial: PropTypes.func,
    currentPlanIsTouOrSolar: PropTypes.bool,
    selectedPricePlan: PropTypes.number,
    createAndSubmitChangePricePlanForm: PropTypes.func,
    changePricePlanStatus: PropTypes.string,
    fromCmpp: PropTypes.bool,
    backButtonClick: PropTypes.func,

    accountDetailsChanged: PropTypes.bool,
    setAccountDetailsChanged: PropTypes.func,

    t: PropTypes.func,
    i18n: PropTypes.object
}

export default withTranslation('changePricePlan')(ChangePricePlanAnon)