import {weatherDataConstants} from '../../../constants/weather-data-constants'
import * as loadingStatus from '../../../constants/loading-status-constants'

const initialState ={
    dailyWeatherDataList:[],
    dailyWeatherStatus: loadingStatus.LOADING_STATUS_INIT
}

export default function dailyWeatherDataReducer(state = initialState, action){
    switch(action.type){
        case weatherDataConstants.GET_WEATHER_REQUEST:
            return {
                    ...state,
                    dailyWeatherStatus: loadingStatus.LOADING_STATUS_IN_PROGRESS
                }
        case weatherDataConstants.GET_WEATHER_SUCCESS:
            return {
                ...state,
                dailyWeatherDataList: action.payload,
                dailyWeatherStatus: loadingStatus.LOADING_STATUS_SUCCESS
            }
        case weatherDataConstants.GET_WEATHER_FAILURE:
            return {
                ...state,
                dailyWeatherDataList: [],
                dailyWeatherStatus: loadingStatus.LOADING_STATUS_FAILURE
            }
        
        default:
            return state
    }
}