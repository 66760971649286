import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import CircularProgress from '@mui/material/CircularProgress'
import TextField from '@mui/material/TextField'

import {
    getBillAccounts,
    getCustomerNames,
    getBillingAddress,
    getServiceAddress,
    getBusinessInfo,
    getPhoneNumber
} from '../../../actions/auth/bill_account/bill-account-actions'
import { getContacts } from '../../../actions/auth/contact/contact-actions'

import * as loadingStatus from '../../../constants/loading-status-constants'
import format from '../../../srp_modules/format'
import { withTranslation } from 'react-i18next'

class SolarChoiceAccountInfo extends React.Component {

    constructor(props) {
        super(props)

        this.getDisplayName = this.getDisplayName.bind(this)
        this.getCustomerInfoModel = this.getCustomerInfoModel.bind(this)
        this.getBusinessInfoModel = this.getBusinessInfoModel.bind(this)
        this.getEmailAddress = this.getEmailAddress.bind(this)
        this.getPhoneNumber = this.getPhoneNumber.bind(this)
        this.getServiceAddress = this.getServiceAddress.bind(this)

        this.pushCustomerData = this.pushCustomerData.bind(this)
        this.getServiceAddressForMobile = this.getServiceAddressForMobile.bind(this)
    }

    componentDidMount() {
        this.updateDataIfNeeded(this.props)
    }

    componentDidUpdate() {
        this.updateDataIfNeeded(this.props)
    }

    isLoaded(props) {
        return props.customerNamesStatus === loadingStatus.LOADING_STATUS_SUCCESS &&
            props.serviceAddressStatus === loadingStatus.LOADING_STATUS_SUCCESS &&
            props.primaryEmailStatus === loadingStatus.LOADING_STATUS_SUCCESS &&
            props.phoneNumberStatus === loadingStatus.LOADING_STATUS_SUCCESS &&
            props.billingAddressStatus === loadingStatus.LOADING_STATUS_SUCCESS &&
            (!props.isCommercial === true || props.businessInfoStatus === loadingStatus.LOADING_STATUS_SUCCESS)
    }

    updateDataIfNeeded(props) {

        if (props.selectedBillAccount === 0) {
            if (props.billAccountListStatus === loadingStatus.LOADING_STATUS_INIT) {
                props.getBillAccounts()
            }
            return
        }

        if (props.serviceAddressStatus === loadingStatus.LOADING_STATUS_INIT) {
            props.getServiceAddressInfo(props.selectedBillAccount)
        }

        if (props.primaryEmailStatus === loadingStatus.LOADING_STATUS_INIT) {
            props.getContactsInfo(props.selectedBillAccount)
        }

        if (props.billingAddressStatus === loadingStatus.LOADING_STATUS_INIT) {
            props.getBillingAddressInfo(props.selectedBillAccount)
        }

        if (props.customerNamesStatus === loadingStatus.LOADING_STATUS_INIT) {
            props.getCustomerNamesInfo(props.selectedBillAccount)
        }

        if (this.props.isCommercial === true && props.businessInfoStatus === loadingStatus.LOADING_STATUS_INIT) {
            props.getBusinessInfoModel(props.selectedBillAccount)
        }

        if (props.phoneNumberStatus === loadingStatus.LOADING_STATUS_INIT) {
            props.getPhoneInformation(props.selectedBillAccount)
        }

        if (this.isLoaded(this.props)) {
            this.pushCustomerData()
        }
    }

    getServiceAddress() {

        let serviceAddress = {}

        if (this.props.serviceAddress !== undefined) {
            serviceAddress = { ...this.props.serviceAddress, city: this.props.serviceAddress.cityName }
        }
        else {
            serviceAddress.streetAddress = ""
            serviceAddress.city = ""
            serviceAddress.zipCode = ""
        }

        return serviceAddress
    }

    getPhoneNumber() {
        let phoneNumber = ''

        if (this.props.isCommercial) {
            if (this.props.businessInfo !== undefined)
                return this.props.businessInfo.phoneNumber
        } else {
            if (this.props.phoneNumber !== undefined)
                return this.props.phoneNumber
        }
        return phoneNumber
    }

    getEmailAddress() {
        let emailAddress = ''

        if (this.props.isCommercial) {
            if (this.props.businessInfo)
                emailAddress = this.props.businessInfo.emailAddress ?
                    this.props.businessInfo.emailAddress
                    : ''
        }
        else {
            if (this.props.customerNames)
                emailAddress = this.props.customerEmailAddress ?
                    this.props.customerEmailAddress
                    : ''
        }

        return emailAddress
    }

    getDisplayName() {
        let displayName = ''

        if (this.props.isCommercial) {
            if (this.props.businessInfo !== undefined)
                displayName = this.props.businessInfo.businessName
        }
        else {
            if (this.props.customerNames !== undefined && this.props.customerNamesStatus === loadingStatus.LOADING_STATUS_SUCCESS)
                displayName = this.props.customerNames.displayName
        }

        return displayName
    }

    getCustomerInfoModel() {

        let customerInfoModel = {
            firstName: '',
            middleName: '',
            lastName: '',
            ssn: '',
            dob: '',
            emailAddress: '',
            verifyEmailAddress: '',
            phoneNumber: '',
            verifyPhoneNumber: ''
        }

        if (this.props.customerNames !== undefined) {
            customerInfoModel.firstName = this.props.customerNames.firstName
            customerInfoModel.middleName = this.props.customerNames.middleName
            customerInfoModel.lastName = this.props.customerNames.lastName
            customerInfoModel.emailAddress = this.props.customerEmailAddress
            customerInfoModel.phoneNumber = this.props.phoneNumber
        }

        return customerInfoModel
    }

    getBusinessInfoModel() {

        let businessInfoModel = {
            businessName: '',
            dbaName: '',
            contactName: '',
            contactPosition: '',
            emailAddress: '',
            phoneNumber: '',
            lastFourSSN: ''
        }

        if (this.props.isCommercial && this.props.businessInfo !== undefined) {
            businessInfoModel.businessName = this.props.customerNames.displayName
            businessInfoModel.dbaName = this.props.billingAddress.dbaLineOne
            businessInfoModel.contactName = this.props.businessInfo.contactName
            businessInfoModel.contactPosition = this.props.businessInfo.contactPosition
            businessInfoModel.emailAddress = this.props.customerEmailAddress
            businessInfoModel.phoneNumber = this.props.phoneNumber
        }

        return businessInfoModel
    }

    pushCustomerData() {
        let accountDetails = {
            customerName: this.getDisplayName(),
            serviceAddress: this.getServiceAddress(),
            customerInfoModel: this.getCustomerInfoModel(),
            businessInfoModel: this.getBusinessInfoModel(),
            isResidential: !this.props.isCommercial,
            emailAddress: this.getEmailAddress(),
        }

        this.props.setAccountInfo(accountDetails)
    }

    getServiceAddressForMobile(serviceAddress) {
        let serviceAddressHTML = (
            <React.Fragment>
                <div>
                    {`${serviceAddress.streetAddress}`}
                </div>
                <div>
                    <span>{` ${serviceAddress.city}, `}</span>
                    <span>AZ</span>
                    <span>{` ${format.zipcode(serviceAddress.zipCode)}`}</span>
                </div>
            </React.Fragment>
        )

        return serviceAddressHTML
    }

    render() {
        const t = this.props.t
        const i18n = this.props.i18n

        let content = null

        if (!this.isLoaded(this.props)) {
            content = (
                <div className="d-flex justify-content-center">
                    <CircularProgress size={80} thickness={5} style={{ marginTop: "20%", marginBottom: "20%" }} />
                </div>
            )
        }
        else {
            content = (
                <div className="text-muted">
                    <h4 className="mb-3" style={{ color: 'rgba(51, 51, 51, 0.537)' }}>{t("SRP account information")}</h4>
                    <div className="d-lg-block d-none">
                        <div>
                            <span className="font-weight-bold mr-2">{t("Name")}</span>
                            <span>{this.getDisplayName()}</span>
                        </div>
                        <div>
                            <span className="font-weight-bold mr-2">{t("SRP account number")}</span>
                            <span>{format.formatBillAccountNumber(this.props.selectedBillAccount)}</span>
                        </div>
                        <div>
                            <span className="font-weight-bold mr-2">{t("Service address")}</span>
                            <span>
                                {this.getServiceAddress().fullStreetAddress}
                            </span>
                        </div>
                        <div className="mb-3">
                            <span className="font-weight-bold mr-2">{t("Phone number")}</span>
                            <span>{format.formatPhoneNumber(this.getPhoneNumber())}</span>
                        </div>
                    </div>

                    <div className="d-lg-none d-block mb-3">
                        <div className="font-weight-bold">{t("Name")}</div>
                        <div>{this.getDisplayName()}</div>
                        <div className="font-weight-bold pt-2">{t("SRP account number")}</div>
                        <div>{format.formatBillAccountNumber(this.props.selectedBillAccount)}</div>
                        <div className="font-weight-bold pt-2">{t("Service address")}</div>
                        <div>
                            {this.getServiceAddressForMobile(this.getServiceAddress())}
                        </div>
                        <div className="font-weight-bold pt-2">{t("Phone number")}</div>
                        <div>{format.formatPhoneNumber(this.getPhoneNumber())}</div>
                    </div>
                    <div>
                        <span>{t("A confirmation will be sent to")}{` `}</span>
                        {this.getEmailAddress().length > 0
                            ? <span className="font-weight-bold">{(this.getEmailAddress()).toLowerCase()}</span>
                            : <div>
                                <TextField
                                    fullWidth
                                    name="emailAddressFromFormik"
                                    value={this.props.emailAddressFromFormik}
                                    onChange={this.props.handleChange}
                                    onBlur={this.props.handleBlur}
                                    helperText={
                                        this.props.emailError
                                            ? t(this.props.errors.email)
                                            : this.props.invalidEmailError
                                                ? t(this.props.errors.invalidEmail)
                                                : ''}
                                    error={this.props.emailError || this.props.invalidEmailError} />
                            </div>
                        }
                    </div>
                </div>
            )
        }

        return (<React.Fragment>{content}</React.Fragment>)
    }
}

SolarChoiceAccountInfo.propTypes = {
    setAccountInfo: PropTypes.func.isRequired,
    isCommercial: PropTypes.bool,
    selectedBillAccount: PropTypes.number,
    customerNames: PropTypes.object,
    customerNamesStatus: PropTypes.string,
    serviceAddress: PropTypes.object,
    serviceAddressStatus: PropTypes.string,
    customerEmailAddress: PropTypes.string,
    primaryEmailStatus: PropTypes.string,
    phoneNumber: PropTypes.string,
    phoneNumberStatus: PropTypes.string,
    businessInfoStatus: PropTypes.string,
    businessInfo: PropTypes.object,
    billingAddress: PropTypes.object,
    dbaLineOne: PropTypes.string,
    billingAddressStatus: PropTypes.string,
    emailAddressFromFormik: PropTypes.string,
    handleChange: PropTypes.func,
    handleBlur: PropTypes.func,
    errors: PropTypes.object,
    emailError: PropTypes.bool,
    invalidEmailError: PropTypes.bool,
    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired,
}

const mapStateToProps = state => {
    return {
        billAccountListStatus: state.accountInfo.billAccount.billAccountListStatus,
        isCommercial: state.accountInfo.billAccount.selectedBillAccountDetails.isCommercial,
        selectedBillAccount: state.accountInfo.billAccount.selectedBillAccount,
        customerNames: state.accountInfo.billAccount.customerNames,
        customerNamesStatus: state.accountInfo.billAccount.customerNamesStatus,
        serviceAddress: state.accountInfo.billAccount.serviceAddress,
        serviceAddressStatus: state.accountInfo.billAccount.serviceAddressStatus,
        customerEmailAddress: state.accountInfo.contact.primaryEmail,
        primaryEmailStatus: state.accountInfo.contact.primaryEmailStatus,
        phoneNumber: state.accountInfo.billAccount.phoneNumber.bestPhone,
        phoneNumberStatus: state.accountInfo.billAccount.phoneNumberStatus,
        billingAddress: state.accountInfo.billAccount.billingAddress,
        billingAddressStatus: state.accountInfo.billAccount.billingAddressStatus,
        businessInfoStatus: state.accountInfo.billAccount.businessInfoStatus,
        businessInfo: state.accountInfo.billAccount.businessInfo,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getBillAccounts: () => {
            dispatch(getBillAccounts())
        },
        getServiceAddressInfo: async (billAccount) => {
            await dispatch(getServiceAddress(billAccount));
        },
        getContactsInfo: (billAccount) => {
            dispatch(getContacts(billAccount))
        },
        getCustomerNamesInfo: (billAccount) => {
            dispatch(getCustomerNames(billAccount))
        },
        getBillingAddressInfo: (billAccount) => {
            dispatch(getBillingAddress(billAccount))
        },
        getBusinessInfoModel: (billAccount) => {
            dispatch(getBusinessInfo(billAccount))
        },
        getPhoneInformation: (billAccount) => {
            dispatch(getPhoneNumber(billAccount))
        }
    }
}

export default withTranslation('solarChoice')(connect(mapStateToProps, mapDispatchToProps)(SolarChoiceAccountInfo))