import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { dailyUsageChartTypeConstants } from '../../constants/daily-usage-chart-type-constants'
import HourlyNetChartContainer from './hourly-net-chart-container'
import HourlyUsageChartContainer from './hourly-usage-chart-container'
import HourlyTableView from './hourly-table-view'
import Divider from "@mui/material/Divider"
import '../../styles/usage.css'
import NoHourlyUsageMessage from './no-hourly-usage-message'
import { getUsageHourlyChartType, getHourlyDataTable } from '../../actions/auth/usage/usage-actions'
import exportUsageModule from '../../srp_modules/charting/export'
import * as loadingStatus from '../../constants/loading-status-constants'
import UsagePageLoading from './usage-page-loading'

class HourlyNetContainer extends React.Component {
    constructor(props) {
        super(props)

        this.exportHourlyNetToExcel = this.exportHourlyNetToExcel.bind(this)
        this.getUsageDataType = this.getUsageDataType.bind(this)
    }

    exportHourlyNetToExcel(dataType, chartType) {
        let dataToExport = this.getUsageDataType(dataType)
        exportUsageModule.getUsageChartExport(dataToExport, dataType, chartType)
    }

    getUsageDataType(dataType) {
        let usageData
        switch (dataType) {
            case dailyUsageChartTypeConstants.DEMAND:
                usageData = this.props.hourlyUsage.hourlyDemandList
                break
            case dailyUsageChartTypeConstants.CONSUMPTION:
                usageData = this.props.hourlyUsage.hourlyConsumptionList
                break
            case dailyUsageChartTypeConstants.GENERATION:
                usageData = this.props.hourlyUsage.hourlyGenerationList
                break
            case dailyUsageChartTypeConstants.NET_ENERGY:
            case dailyUsageChartTypeConstants.USAGE:
            case dailyUsageChartTypeConstants.COST:
                usageData = this.props.hourlyUsage.hourlyUsageList
                break
        }

        return usageData
    }

    render() {
        let isCommercialSolar = this.props.rateMetaData.rateMetaData.isCommercial && this.props.rateMetaData.rateMetaData.isSolar
        let NetOrDeliveredChipVerbiage = isCommercialSolar ? this.props.t("Delivered") : this.props.t("Net energy")
        let showNoData = showNoDataCard(this.props.currentHourlyChart, this.props.hourlyUsage)
        if (this.props.rateMetaData.rateMetaDataStatus === loadingStatus.LOADING_STATUS_INIT
            || this.props.rateMetaData.rateMetaDataStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS
            || this.props.dailyUsage.dailyUsageStatus === loadingStatus.LOADING_STATUS_INIT
            || this.props.dailyUsage.dailyUsageStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS
            || this.props.hourlyUsage.hourlyUsageStatus === loadingStatus.LOADING_STATUS_INIT
            || this.props.hourlyUsage.hourlyUsageStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS
        )
            return(
                <div>
                    <UsagePageLoading/>
                </div>
            )
        return (

            <div className="ml-4 mr-4 pb-2">

                <div className="d-none d-lg-block chart-btn-container mt-4">    
                    <span className="mr-2">{this.props.t("View my")}</span>
                    <button className={this.props.hourlyButtonClassNames.netBtnClassName} onClick={() => this.props.getUsageHourlyChartTypeOnClick(dailyUsageChartTypeConstants.NET_ENERGY)}>{NetOrDeliveredChipVerbiage}</button>
                    <button className={this.props.hourlyButtonClassNames.generationBtnClassName} onClick={() => this.props.getUsageHourlyChartTypeOnClick(dailyUsageChartTypeConstants.GENERATION)}>{this.props.t("Generation")}</button>
                    <button className={this.props.hourlyButtonClassNames.consumptionBtnClassName} onClick={() => this.props.getUsageHourlyChartTypeOnClick(dailyUsageChartTypeConstants.CONSUMPTION)}>{this.props.t("Usage")}</button>
                    {this.props.rateMetaData.rateMetaData.isDemand &&
                        <button className={this.props.hourlyButtonClassNames.demandBtnClassName} onClick={() => this.props.getUsageHourlyChartTypeOnClick(dailyUsageChartTypeConstants.DEMAND)}>{this.props.t("Demand")}</button>
                    }
                </div>
                <div className="d-block d-lg-none">
                    <div className="d-flex justify-content-end">
                        <button
                            className="btn srp-btn btn-lightblue text-white mr-2"
                            onClick={()=>this.props.getHourlyDataTableOnClick(!this.props.showHourlyDataTable)}>
                            {this.props.showHourlyDataTable ? this.props.t("View chart") : this.props.t("View data table")}
                        </button>
                        <button
                            className="btn srp-btn btn-lightblue text-white"
                            onClick={() => this.exportHourlyNetToExcel(this.props.currentHourlyChart, this.props.selectedUsagePageChartTab)}>
                            {this.props.t("Export")}
                        </button>
                    </div>
                </div>

                <Divider className="mt-4 mb-4 d-lg-none" id="top-of-hourly-chart"/>
                <div className="d-lg-none mt-2 mb-4">
                    <div className="mr-2 mb-2" >{this.props.t("View my")}</div>
                    <button className={this.props.hourlyButtonClassNames.netBtnClassName} onClick={() => this.props.getUsageHourlyChartTypeOnClick(dailyUsageChartTypeConstants.NET_ENERGY)}>{NetOrDeliveredChipVerbiage}</button>
                    <button className={this.props.hourlyButtonClassNames.generationBtnClassName} onClick={() => this.props.getUsageHourlyChartTypeOnClick(dailyUsageChartTypeConstants.GENERATION)}>{this.props.t("Generation")}</button>
                    <button className={this.props.hourlyButtonClassNames.consumptionBtnClassName} onClick={() => this.props.getUsageHourlyChartTypeOnClick(dailyUsageChartTypeConstants.CONSUMPTION)}>{this.props.t("Usage")}</button>
                    {this.props.rateMetaData.rateMetaData.isDemand &&
                        <button className={this.props.hourlyButtonClassNames.demandBtnClassName} onClick={() => this.props.getUsageHourlyChartTypeOnClick(dailyUsageChartTypeConstants.DEMAND)}>{this.props.t("Demand")}</button>
                    }
                </div>

                {showNoData ?
                <div><NoHourlyUsageMessage t={this.props.t}/></div>
                :
                <div>
                {this.props.showHourlyDataTable
                    ? null
                    : <div className="d-block d-lg-none mb-4 center-text">
                        <div className={(this.props.currentHourlyChart === dailyUsageChartTypeConstants.NET_ENERGY
                            || this.props.currentHourlyChart === dailyUsageChartTypeConstants.USAGE
                            || this.props.currentHourlyChart === dailyUsageChartTypeConstants.CONSUMPTION)
                            ? "block-element"
                            : "hidden-element"}>{this.props.t("Usage (kWh)")}
                        </div>
                        <div className={this.props.currentHourlyChart === dailyUsageChartTypeConstants.GENERATION ? "block-element" : "hidden-element"}>{this.props.t("Generation (kWh)")}</div>
                        <div className={this.props.currentHourlyChart === dailyUsageChartTypeConstants.DEMAND ? "block-element" : "hidden-element"}>{this.props.t("Peak Demand")}</div>
                    </div>

                }

                {this.props.showHourlyDataTable
                    ? <HourlyTableView
                        usageData={this.getUsageDataType(this.props.currentHourlyChart)}
                        currentUsageType={this.props.currentHourlyChart} 
                        t={this.props.t}/>
                    : this.props.currentHourlyChart === dailyUsageChartTypeConstants.NET_ENERGY
                        ? <HourlyNetChartContainer t={this.props.t}/>
                        : (this.props.currentHourlyChart === dailyUsageChartTypeConstants.DEMAND
                            || this.props.currentHourlyChart === dailyUsageChartTypeConstants.USAGE
                            || this.props.currentHourlyChart === dailyUsageChartTypeConstants.CONSUMPTION
                            || this.props.currentHourlyChart === dailyUsageChartTypeConstants.GENERATION
                        )
                            ? <HourlyUsageChartContainer t={this.props.t}/>
                            : null
                }


                <div className="d-none d-lg-block mt-4">
                    <div className="d-flex justify-content-end mb-1">
                        <button
                            className="btn srp-btn btn-lightblue text-white mr-2"
                            onClick={()=>this.props.getHourlyDataTableOnClick(!this.props.showHourlyDataTable)}>
                            {this.props.showHourlyDataTable ? this.props.t("View as chart") : this.props.t("View as data table")}
                        </button>
                        <button
                            className="btn srp-btn btn-lightblue text-white"
                            onClick={() => this.exportHourlyNetToExcel(this.props.currentHourlyChart, this.props.selectedUsagePageChartTab)}>
                            {this.props.t("Export to Excel")}
                        </button>
                    </div>
                </div>
                </div>
                }
            </div>

        )
    }
}

const mapStateToProps = state => {
    return {
        ...state.accountInfo.usage,
        ...state.accountInfo.usagePageUI,
        hourlyUsage: state.hourlyUsage,
        rateMetaData: state.rateMetaData,
        dailyUsage: state.dailyUsage
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getUsageHourlyChartTypeOnClick: (type) => {
            dispatch(getUsageHourlyChartType(type))
        },
        getHourlyDataTableOnClick: (showTable) => {
            dispatch(getHourlyDataTable(showTable))
        }
    }
}

HourlyNetContainer.propTypes = {
    hourlyButtonClassNames: PropTypes.object,
    getUsageHourlyChartTypeOnClick: PropTypes.func,
    rateMetaData: PropTypes.object,
    currentHourlyChart: PropTypes.string,
    selectedUsagePageChartTab: PropTypes.number,
    getHourlyDataTableOnClick: PropTypes.func,
    showHourlyDataTable: PropTypes.bool,
    hourlyUsage: PropTypes.object,
    dailyUsage: PropTypes.object,
    t: PropTypes.func
}

export default connect(mapStateToProps, mapDispatchToProps)(HourlyNetContainer);

function showNoDataCard(chartType, hourlyUsage){
    switch(chartType){
        case dailyUsageChartTypeConstants.COST:
            return hourlyUsage.hourlyUsageList === undefined
                || hourlyUsage.hourlyUsageList.length < 1
        case dailyUsageChartTypeConstants.USAGE:
            return hourlyUsage.hourlyUsageList === undefined
                || hourlyUsage.hourlyUsageList.length < 1
        case dailyUsageChartTypeConstants.CONSUMPTION:
            return hourlyUsage.hourlyConsumptionList === undefined
                || hourlyUsage.hourlyConsumptionList.length < 1
        case dailyUsageChartTypeConstants.GENERATION:
            return hourlyUsage.hourlyGenerationList === undefined
                || hourlyUsage.hourlyGenerationList.length < 1
        case dailyUsageChartTypeConstants.DEMAND:
            return hourlyUsage.hourlyDemandList === undefined
                || hourlyUsage.hourlyDemandList.length < 1
    }
    return false
}