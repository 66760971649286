import { guestCreditCardLinkConstants } from '../../../constants/guest/guest-credit-card-link-constants'

const initialState = {
    guestCreditCardPaymentUrl: '#'
}

export default function creditCardLinkReducer(state = initialState, action) {
    switch (action.type) {
        case guestCreditCardLinkConstants.GET_GUEST_CREDIT_CARD_PAYMENT_URL_REQUEST:
            return { ...state, ...initialState }
        case guestCreditCardLinkConstants.GET_GUEST_CREDIT_CARD_PAYMENT_URL_SUCCESS:
            return { ...state,
                guestCreditCardPaymentUrl: action.payload
            }
        case guestCreditCardLinkConstants.GET_GUEST_CREDIT_CARD_PAYMENT_URL_FAILURE:
            return { ...state, ...initialState }
        default:
            return state
    }
}