import React from 'react'
import PropTypes from 'prop-types'

const LookingForUsage = ({t}) => {
    return (
        <div className="usage-chart-info">
            <div className="text-center usage">
                <div><i className="material-icons">bar_chart</i></div>
                <div className="usage-chart-info-title">{t("Looking for your usage?")}</div>
                <div>{t("You're in the right place, but you'll need to check back tomorrow after your first day of usage has been calculated. SRP is delighted to have you as a customer, we look forward to serving you.")}</div>
            </div>
        </div>
    )
}

LookingForUsage.propTypes = {
    t: PropTypes.func
}

export default LookingForUsage
