import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import CardBillContainer from './bill/card-bill-container'
import CardUsageContainer from './usage/card-usage-container'
import CardOutageContainer from './outage/card-outage-container'

import * as loadingStatus from '../../constants/loading-status-constants'

class Cards extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    if (this.props.rateMetaDataStatus === loadingStatus.LOADING_STATUS_SUCCESS || this.props.rateMetaDataStatus === loadingStatus.LOADING_STATUS_FAILURE) {
      return (
        <div className="row">
          <div className="col-12 col-lg-4 mt-3"><CardBillContainer /></div>

          <div className="col-12 col-lg-4 mt-3"><CardUsageContainer /></div>

          <div className="col-12 col-lg-4 mt-3"><CardOutageContainer /></div>
        </div>
      )
    }
    else {
      return null
    }
  }
}

const mapStateToProps = state => {
  return { ...state.rateMetaData }
}

Cards.propTypes = {
  rateMetaDataStatus: PropTypes.string,
}

export default connect(mapStateToProps)(Cards)