import React from 'react'
import PropTypes from 'prop-types'

const NoMonthlyCard = ({t}) => {

    return(
        <div className="d-flex flex-column align-items-center monthly-chart-error">
            <div><i className="material-icons ">error_outline</i></div>
            <div className="text-overflow-center">
                <div className="monthly-chart-error-title align-items-center pb-2">{t("Unable to view monthly comparison")}</div>
                <div>{t("Monthly bill information will be available after your first bill is issued.")}</div>
            </div>
        </div>
    )
}

NoMonthlyCard.propTypes = {
    t: PropTypes.func.isRequired
}

export default NoMonthlyCard

