import * as React from 'react'
import PropTypes from 'prop-types'
import { LOADING_STATUS_SUCCESS } from '../../../constants/loading-status-constants'
import { withTranslation} from 'react-i18next'

const AccountManager = ({t, accountManager, myAccountType, defaultAccountManager, selectedBillAccountDetails, accountManagerStatus}) => {
    let getDisplayAccountManager = (myAccountType, accountManager, defaultAccountManager, isCommercial, accountManagerStatus) => {        
        if (myAccountType === 'water' || !isCommercial || accountManagerStatus !== LOADING_STATUS_SUCCESS) {
            return null
        } else if (!accountManager.hasAccountManager) {
            return defaultAccountManager
        } else {        
            return accountManager
        } 
    }

    let displayAccountManager = getDisplayAccountManager(myAccountType, accountManager, defaultAccountManager, selectedBillAccountDetails.isCommercial, accountManagerStatus)
    
    if (displayAccountManager === null) {
        return null
    } else {
        return (
            <div className="form-group ml-5">
                <label className="mb-0 font-weight-bold">{t(displayAccountManager.accountManagerTitle)}</label>
                {accountManager.hasAccountManager
                    ? <p className="form-control-static pt-0 text-muted">
                        <span>{displayAccountManager.fullName}</span>
                        <br />
                        <span>{displayAccountManager.phoneNumber}</span>
                        <br />
                        <span>
                            <a className="text-primary-ada" href={"mailto:" + displayAccountManager.emailAddress}>
                                {displayAccountManager.emailAddress}
                            </a>
                        </span>
                    </p>
                    : <p className="form-control-static pt-0 text-muted">
                        <span>{displayAccountManager.phoneNumber}</span>
                    </p>
                }
            </div>
        )
    }
}

AccountManager.propTypes = {
    accountManager: PropTypes.object.isRequired,
    myAccountType: PropTypes.string.isRequired,
    defaultAccountManager: PropTypes.object.isRequired,
    selectedBillAccountDetails: PropTypes.object.isRequired,
    accountManagerStatus: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired
}

export default withTranslation("accountInfoBar")(AccountManager)