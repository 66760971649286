import React from 'react'
import PropTypes from 'prop-types'
import { DateTime } from 'luxon'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableHead from '@mui/material/TableHead'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'

import format from '../../srp_modules/format'
import { eraProgramConstants } from '../../constants/era-program-constants'

const ErapApprovedPaymentsTable = ({ erapPayments, t }) => {
    return (
        <div>
            {/* desktop view */}
            <div className="d-lg-block d-none">
                <Table>
                    <colgroup>
                        <col width="15%" />
                        <col width="25%" />
                        <col width="20%" />
                        <col width="40%" />
                    </colgroup>
                    <TableHead>
                        <TableRow className="font-18 srp-table-bg-light-grey">
                            <TableCell className="p-2">{t("Current status")}</TableCell>
                            <TableCell className="text-right p-2">{t("Months of assistance")}</TableCell>
                            <TableCell className="text-right p-2">{t("Assistance amount")}</TableCell>
                            <TableCell className="p-2">{t("Date credit applied to account")}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {erapPayments.map((payment, index) => {
                            return (
                                <TableRow className="pat-payment-row" key={index}>
                                    <TableCell className="p-2">{getPaymentStatusText(payment.paymentStatus, t)}</TableCell>
                                    <TableCell className="text-right p-2">{payment.numMonthsOfAssist}</TableCell>
                                    <TableCell className="text-right p-2">{format.formatDollarAmount(payment.paymentAmount)}</TableCell>
                                    <TableCell className="p-2">{DateTime.fromISO(payment.paymentDate).toFormat('M/d/yyyy')}</TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </div>

            {/* responsive view */}
            <div className="d-block d-lg-none">
                <Table className="table-border-separate">
                    <TableBody>
                        {erapPayments.map((payment, index) => {
                            return (
                                <TableRow hover key={`r-${index}`}>
                                    <TableCell className="srp-table-row-responsive">
                                        <div className="row mb-2 mt-2">
                                            <div className="col text-muted">{t("Current status")}</div>
                                            <div className="col text-right">{getPaymentStatusText(payment.paymentStatus, t)}</div>
                                        </div>
                                        <div className="row mb-2">
                                            <div className="col text-muted">{t("Months of assistance")}</div>
                                            <div className="col text-right">{payment.paymentType === 0 ? payment.numMonthsOfAssist : 1}</div>
                                        </div>
                                        <div className="row mb-2">
                                            <div className="col text-muted">{t("Assistance amount")}</div>
                                            <div className="col text-right">{format.formatDollarAmount(payment.paymentAmount)}</div>
                                        </div>
                                        <div className="row mb-2">
                                            <div className="col text-muted">{t("Date credit applied to account")}</div>
                                            <div className="col text-right">{DateTime.fromISO(payment.paymentDate).toFormat('M/d/yyyy')}</div>
                                        </div>
                                    </TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </div>
        </div>
    )
}

ErapApprovedPaymentsTable.propTypes = {
    erapPayments: PropTypes.array,
    t: PropTypes.func.isRequired,
}

export default (ErapApprovedPaymentsTable)

function getPaymentStatusText(paymentStatus, t) {
    switch (paymentStatus) {
        case eraProgramConstants.PAYMENT_STATUS.APPROVED:
            return t("Approved")
        case eraProgramConstants.PAYMENT_STATUS.PENDING:
            return t("Under Review*")
        case eraProgramConstants.PAYMENT_STATUS.DENIED:
            return t("Denied")
    }
}