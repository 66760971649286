import React from 'react'
import PropTypes from 'prop-types'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableHead from '@mui/material/TableHead'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import { DateTime } from 'luxon'

import '../../styles/tables.css'
import { convertHourlyDataForCharting } from '../../srp_modules/charting/hourly-data-dto'
import exportUsageModule from '../../srp_modules/charting/export'
import formatUsageModule from '../../srp_modules/charting/format'
import { dailyUsageChartTypeConstants } from '../../constants/daily-usage-chart-type-constants'

const HourlyTableView = ({ usageData, currentUsageType, t }) => {
    let dtoUsageData = convertHourlyDataForCharting(usageData, currentUsageType)
    let columns = exportUsageModule.getColumnsToShow(dtoUsageData)
    let superOffPeakStyle = columns.superOffPeakCol ? { display: 'table-cell' } : { display: 'none' }
    let superOffPeakStyleResp = columns.superOffPeakCol

    let offPeakStyle = columns.offPeakCol ? { display: 'table-cell' } : { display: 'none' }
    let offPeakStyleResp = columns.offPeakCol

    let shoulderStyle = columns.shoulderCol ? { display: 'table-cell' } : { display: 'none' }
    let shoulderStyleResp = columns.shoulderCol

    let onPeakStyle = columns.onPeakCol ? { display: 'table-cell' } : { display: 'none' }
    let onPeakStyleResp = columns.onPeakCol

    let totalStyle = columns.totalCol ? { display: 'table-cell' } : { display: 'none' }
    let totalStyleResp = columns.totalCol

    let unitOfMesaurement = currentUsageType === dailyUsageChartTypeConstants.COST
        ? t("cost")
        : currentUsageType === dailyUsageChartTypeConstants.DEMAND
            ? "kW"
            : "kWh"

    return (
        <div id="usageDataTable">
            <div className="d-lg-block d-none" style={{ overflow: 'auto' }}>
                <Table>
                    <TableHead >
                        <TableRow>
                            <TableCell className="border-0" padding="none">{t("Usage date")}</TableCell>
                            <TableCell className="border-0" padding="none">{t("Interval")}</TableCell>
                            <TableCell className="border-0 text-right" padding="none" style={superOffPeakStyle}>{t("Super_off_peak_display", {unit : unitOfMesaurement})}</TableCell>
                            <TableCell className="border-0 text-right" padding="none" style={offPeakStyle}>{t("Off_peak_display", {unit : unitOfMesaurement})}</TableCell>
                            <TableCell className="border-0 text-right" padding="none" style={shoulderStyle}>{t("Shoulder_display", {unit : unitOfMesaurement})}</TableCell>
                            <TableCell className="border-0 text-right" padding="none" style={onPeakStyle}>{t("On_peak_display", {unit : unitOfMesaurement})}</TableCell>
                            <TableCell className="border-0 text-right" padding="none" style={totalStyle}>{t("Total_display", {unit : unitOfMesaurement})}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody className="srp-table-body">
                        {dtoUsageData.map((row, index) => {
                            return (
                                <TableRow key={index} style={{ border: 'none' }}>
                                    <TableCell padding="none">{DateTime.fromISO(row.date).toFormat('M/d/yyyy')}</TableCell>
                                    <TableCell padding="none">{DateTime.fromISO(row.day).toFormat('h:mm a')}</TableCell>
                                    <TableCell className="text-right" padding="none" style={superOffPeakStyle}>
                                        {formatUsageModule.formatNumberByUsageType(currentUsageType, row.superOffPeak)}
                                    </TableCell>
                                    <TableCell className="text-right" padding="none" style={offPeakStyle}>
                                        {formatUsageModule.formatNumberByUsageType(currentUsageType, row.offPeak)}
                                    </TableCell>
                                    <TableCell className="text-right" padding="none" style={shoulderStyle}>
                                        {formatUsageModule.formatNumberByUsageType(currentUsageType, row.shoulder)}
                                    </TableCell>
                                    <TableCell className="text-right" padding="none" style={onPeakStyle}>
                                        {formatUsageModule.formatNumberByUsageType(currentUsageType, row.onPeak)}
                                    </TableCell>
                                    <TableCell className="text-right" padding="none" style={totalStyle}>
                                        {formatUsageModule.formatNumberByUsageType(currentUsageType, row.total)}
                                    </TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </div>

            <div className="d-lg-none d-block">
                {dtoUsageData.map((row, index) => {
                    return (
                        <div key={"usageTableMobile" + index}>
                            <div className="row d-flex align-items-center">
                                <div className="col text-muted align-middle">{t("Meter read date")}</div>
                                <div className="col align-middle text-right">{DateTime.fromISO(row.date).toFormat('M/d/yyyy')}</div>
                            </div>

                            <hr className="mt-2" />
                            <div className="row d-flex align-items-center">
                                <div className="col text-muted align-middle">{t("Interval")}</div>
                                <div className="col align-middle text-right">{DateTime.fromISO(row.day).toFormat('h:mm a')}
                                </div>
                            </div>
                            {superOffPeakStyleResp
                                ? <div>
                                    <hr className="mt-2" />
                                    <div className="row">
                                        {/* <div className="col text-muted align-middle">{`Super Off-peak ${unitOfMesaurement}`}</div> */}
                                        <div className="col text-muted align-middle">{t("Super_off_peak_display", {unit : unitOfMesaurement})}</div> 
                                        <div className="col align-middle text-right">
                                            {formatUsageModule.formatNumberByUsageType(currentUsageType, row.superOffPeak)}
                                        </div>
                                    </div>
                                </div>
                                : null
                            }
                            {offPeakStyleResp
                                ? <div>
                                    <hr className="mt-2" />
                                    <div className="row">
                                        <div className="col text-muted align-middle">{t("off_peak_display", {unit : unitOfMesaurement})}</div>
                                        <div className="col align-middle text-right">
                                            {formatUsageModule.formatNumberByUsageType(currentUsageType, row.offPeak)}
                                        </div>
                                    </div>
                                </div>
                                : null
                            }
                            {shoulderStyleResp
                                ? <div>
                                    <hr className="mt-2" />
                                    <div className="row">
                                        <div className="col text-muted align-middle">{t("Shoulder_display", {unit : unitOfMesaurement})}</div>
                                        <div className="col align-middle text-right">
                                            {formatUsageModule.formatNumberByUsageType(currentUsageType, row.shoulder)}
                                        </div>
                                    </div>
                                </div>
                                : null
                            }
                            {onPeakStyleResp
                                ? <div>
                                    <hr className="mt-2" />
                                    <div className="row">
                                        <div className="col text-muted align-middle">{t("On-peak_display", {unit : unitOfMesaurement})}</div>
                                        <div className="col align-middle text-right">
                                            {formatUsageModule.formatNumberByUsageType(currentUsageType, row.onPeak)}
                                        </div>
                                    </div>
                                </div>
                                : null
                            }
                            {totalStyleResp
                                ? <div>
                                    <hr className="mt-2" />
                                    <div className="row">
                                        <div className="col text-muted align-middle">{t("Total_display", {unit : unitOfMesaurement})}</div>
                                        <div className="col align-middle text-right">
                                            {formatUsageModule.formatNumberByUsageType(currentUsageType, row.total)}
                                        </div>
                                    </div>
                                </div>
                                : null
                            }

                            <hr className="mt-3" style={{ border: '2px solid lightgray' }} />
                        </div>
                    )
                })}

                <div className="d-flex justify-content-end mb-2">
                    <button
                        className="btn srp-btn btn-lightblue" onClick={() => backToTop()}>
                        {t("Back to top")}
                    </button>
                </div>
            </div>
        </div>
    )
}

HourlyTableView.propTypes = {
    usageData: PropTypes.arrayOf(PropTypes.object),
    currentUsageType: PropTypes.string,
    t: PropTypes.t
}

export default HourlyTableView

function backToTop() {
    document.getElementById("top-of-hourly-chart").scrollIntoView()
}