import { solarNonprofitFormConstants } from '../../../constants/solar-nonprofit-constants'
import { RSAA } from 'redux-api-middleware'

import utils from '../../../srp_modules/utils'

export const submitSolarNonprofitForm = (solarNonprofitData) => {
    let url = utils.createUrl('forms/submitsolarnonprofitform')

    let stringData = JSON.stringify(solarNonprofitData)

    return dispatch => {
        return dispatch({
            [RSAA]: {
                endpoint: url,
                body: stringData,
                method: 'POST',
                credentials: 'include',
                headers: { 'Content-Type': 'application/json' },
                types: [
                    solarNonprofitFormConstants.SUBMIT_SOLAR_NONPROFIT_FORM_REQUEST,
                    solarNonprofitFormConstants.SUBMIT_SOLAR_NONPROFIT_FORM_SUCCESS,
                    solarNonprofitFormConstants.SUBMIT_SOLAR_NONPROFIT_FORM_FAILURE,
                ]
            },
            meta: {
                debounce: {
                    leading: false,
                    trailing: false
                }
            }
        })
    }
}