import React from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import config from 'my-account-config'
import { Link } from 'react-router-dom'

import CheckImage from '../../../images/common/SuccessCheck.png'

const YouAreAllSet = ({isLoggedIn, refreshForm, t, i18n}) => (
    <div>
        <div style={{ color: "#737B4C" }} className="row align-items-start">
            <div className="col-md-auto py-1"><img src={CheckImage} /></div>
            <div className="col-md-auto py-3"><h4>{t("youre_all_set")}</h4></div>
        </div>
        <div>
            {t("thank_you_request_text")}
        </div>
        {isLoggedIn ? (
            <div className="d-flex justify-content-end pt-2">
                <Link to="/myaccount/dashboard" onClick={refreshForm} className="btn srp-btn btn-lightblue mr-3">
                    {t("go_to_dshboard")}
                </Link>
            </div>
        ) : (
            <div className="d-flex justify-content-end pt-2">
                <a href={i18n.language === "es" ? config.misrpBaseUrl : config.srpnetBaseUrl} className="btn srp-btn btn-lightblue mr-3">
                    {t("go_to_srp_site")}
                </a>
            </div>
        )}
    </div>
)

YouAreAllSet.propTypes = {
    isLoggedIn: PropTypes.bool.isRequired,
    refreshForm: PropTypes.func.isRequired,
    t: PropTypes.func,
    i18n: PropTypes.object
}

export default withTranslation('startService')(YouAreAllSet)