import React from 'react'
import PropTypes from 'prop-types'

import { formatDollarAmount } from './card-bill'

const PaymentArrangementDetails = ({ setPaymentArrangementDetailsDisplay, paymentInfo, t }) => {
    return (
        <div className="srp-card">
            <div className="srp-card-header">
                {t("My bill")}
            </div>
            <div className="srp-card-body">
                <div className="srp-card-details">
                    <h5><strong>{t("Payment Arrangement details")}</strong></h5>
                    <h5 className="d-flex justify-content-start align-items-center mb-4">
                        <i className="material-icons text-primary-ada md-18">keyboard_arrow_left</i>
                        <button
                            className="displayAsLink"
                            onClick={() => setPaymentArrangementDetailsDisplay(false)}>
                            {t("Back to Home")}
                        </button>
                    </h5>

                    <div className="text-muted mb-4">
                        <div className="row d-flex align-items-center mb-3">
                            <div className="col-8 align-middle">{t("Arrangement amount")}</div>
                            <div className="col-4 text-right font-weight-bold">
                                {formatDollarAmount(paymentInfo.arrangementRemainingAmountDue)}
                            </div>
                        </div>
                        <div className="row d-flex align-items-center">
                            <div className="col-8 align-middle">{t("Monthly Installment amount")}</div>
                            <div className="col-4 text-right font-weight-bold">
                                {formatDollarAmount(paymentInfo.arrangementInstallmentAmount)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

PaymentArrangementDetails.propTypes = {
    t: PropTypes.func.isRequired,
    setPaymentArrangementDetailsDisplay: PropTypes.func,
    paymentInfo: PropTypes.object
}

export default PaymentArrangementDetails