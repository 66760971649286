import { paymentLocationConstants } from '../../constants/payment-location-constants'
import { RSAA } from 'redux-api-middleware'
import config from 'my-account-config'

export const getPayLocationMap = (map) => ({
    type: paymentLocationConstants.GET_PAY_LOCATION_MAP,
    map
})

export const getCurrentLocation = (location) => ({
    type: paymentLocationConstants.GET_CURRENT_LOCATION,
    location
})

export const clearClosestPayLocations = () => ({
    type: paymentLocationConstants.CLEAR_CLOSEST_PAY_LOCATIONS
})

export const findClosestPayLocations = (closestPayLocations, isFromSearch) => ({
    type: paymentLocationConstants.FIND_PAYMENT_LOCATIONS,
    closestPayLocations, isFromSearch
})

export const getStartAddressForDirections = (address) => ({
    type: paymentLocationConstants.GET_START_ADDRESS_FOR_DIRECTIONS,
    address
})

export const getEndAddressForDirections = (address) => ({
    type: paymentLocationConstants.GET_END_ADDRESS_FOR_DIRECTIONS,
    address
})

export const getSearchAddressCoords = (lat, lng) => ({
    type: paymentLocationConstants.GET_SEARCH_ADDRESS_COORDS,
    lat, lng
})

export const hidePayLocationDetails = () => ({
    type: paymentLocationConstants.HIDE_PAY_LOCATION_DETAILS
})

export const showPayLocationDetails = (markerInfo) => ({
    type: paymentLocationConstants.SHOW_PAY_LOCATION_DETAILS,
    markerInfo
})

export const showStartAddrDetails = () => ({
    type: paymentLocationConstants.SHOW_START_ADDR_DETAILS
})

export const hideStartAddrDetails = (showDetails) => ({
    type: paymentLocationConstants.HIDE_START_ADDR_DETAILS,
    showDetails
})

export const setFilteredPayLocationList = (filteredList, filteredType) => ({
    type: paymentLocationConstants.SET_FILTERED_PAY_LOCATION_LIST,
    filteredList, filteredType
})

export const getAllPayLocations = () => dispatch => {
    return dispatch({
        [RSAA]: {
            endpoint: config.apiBaseUrl + 'api/paycenter/getall',
            method: 'GET',
            types: [
                paymentLocationConstants.GET_ALL_PAY_LOCATIONS_REQUEST,
                    paymentLocationConstants.GET_ALL_PAY_LOCATIONS_SUCCESS,
                    paymentLocationConstants.GET_ALL_PAY_LOCATIONS_FAILURE
            ]
        }
    })
}