import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { LOADING_STATUS_SUCCESS } from '../../../constants/loading-status-constants'

import { resetMultipleMpowerInfo, clearPurchaseList, clearFormStartEventEmitted } from '../../../actions/auth/payment/multi-payment-ngp-actions'
import { withRouter } from '../../../srp_modules/with-router'

import MultiPaymentNgpPage from './multi-payment-ngp-page'

class MultiPaymentPageContainer extends React.Component {
    constructor(props) {
        super(props)
    }

    componentDidMount() {
        if (this.props.multiPurchasesSubmitted)
            this.props.clearState()
    }

    render() {
        let billAccountList = this.props.billAccountList
        let  isCash = this.props.router.location.pathname === "/myaccount/payment/multi/mpower/cash"
        return (
            !this.props.multiPurchasesSubmitted && <MultiPaymentNgpPage isCash = {isCash} billAccountList = {billAccountList}/>
        )
    }
}

const mapStateToProps = state => ({
    multiPurchasesSubmitted: state.multiPaymentNgp.submitMultiPurchaseResultStatus === LOADING_STATUS_SUCCESS,
    ...state.accountInfo.billAccount
})

const mapDispatchToProps = (dispatch) => {
    return {
        clearState: () => {
            dispatch(resetMultipleMpowerInfo())
            dispatch(clearPurchaseList())
            dispatch(clearFormStartEventEmitted())
        }
    }
}

MultiPaymentPageContainer.propTypes = {
    multiPurchasesSubmitted: PropTypes.bool.isRequired,
    clearState: PropTypes.func.isRequired,
    billAccountList: PropTypes.array,
    router: PropTypes.shape({
        location: PropTypes.object
    })
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MultiPaymentPageContainer))