import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Provider } from 'react-redux'
import { HelmetProvider } from 'react-helmet-async'
import { ReduxRouter } from '@lagunovsky/redux-react-router'
import RouteWrapper from '../routes2'

import config from 'my-account-config'

const routerSelector = (state) => state.navigator

export default class Root extends Component {
    render() {
        const { store, history } = this.props;
        return (
            <HelmetProvider>
            <Provider store={store}>
                <ReduxRouter basename={config.appPath} history={history} routerSelector={routerSelector}>
                    <RouteWrapper />
                </ReduxRouter>
            </Provider>
            </HelmetProvider>
        )
    }
}

Root.propTypes = {
    history: PropTypes.object.isRequired,
    store: PropTypes.object.isRequired
};
