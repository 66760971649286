import React from 'react'
import PropTypes from 'prop-types'
import FormGroup from '@mui/material/FormGroup'
import PlanSelection from './plan-selection'

import '../../styles/price-plan.css'

import { pricePlanConstants } from '../../constants/price-plan-constants'
import { StartServiceDictionary } from '../forms/web_turnon/start-service-dictionary'

const ResidentialSelectPlan = ({ selectedPricePlanResidential, selectNewPricePlanResidential, currentRateCode }) => {
    let commonSelectionProps = {
        selectedPricePlanResidential: selectedPricePlanResidential,
        selectNewPricePlanResidential: selectNewPricePlanResidential,
        currentRateCode: currentRateCode,
        disabled: false
    }
    return (
        <FormGroup>
            <PlanSelection
                rateCode={pricePlanConstants.PRICE_PLAN_RES_SERVER_MAP.TOU}
                description={StartServiceDictionary.en.tou_plan}
                learnMoreLink="price-plans/residential-electric/time-of-use"
                {...commonSelectionProps}
            />
            <PlanSelection
                rateCode={pricePlanConstants.PRICE_PLAN_RES_SERVER_MAP.EZ3}
                description={StartServiceDictionary.en.ez3_3_6_plan}
                learnMoreLink="price-plans/residential-electric/ez-3"
                {...commonSelectionProps}
            />
            <PlanSelection
                rateCode={pricePlanConstants.PRICE_PLAN_RES_SERVER_MAP.EZ3_4_7}
                description={StartServiceDictionary.en.ez3_4_7_plan}
                learnMoreLink="price-plans/residential-electric/ez-3"
                {...commonSelectionProps}
            />
            <PlanSelection
                rateCode={pricePlanConstants.PRICE_PLAN_RES_SERVER_MAP.EV}
                description={StartServiceDictionary.en.ev_plan}
                learnMoreLink="price-plans/residential-electric/electric-vehicle"
                {...commonSelectionProps}
            />
            <PlanSelection
                rateCode={pricePlanConstants.PRICE_PLAN_RES_SERVER_MAP.BASIC}
                description={StartServiceDictionary.en.basic_plan}
                learnMoreLink="price-plans/residential-electric/basic"
                {...commonSelectionProps}
            />
            <PlanSelection
                rateCode={pricePlanConstants.PRICE_PLAN_RES_SERVER_MAP.TOU_DEMAND}
                description={StartServiceDictionary.en.tou_demand_plan}
                learnMoreLink="price-plans/residential-electric/demand"
                {...commonSelectionProps}
            />
            <PlanSelection
                rateCode={pricePlanConstants.PRICE_PLAN_RES_SERVER_MAP.GENERATION}
                description={StartServiceDictionary.en.customer_generation_plan}
                learnMoreLink="price-plans/residential-electric/solar/customer-generation"
                selectedPricePlanResidential={selectedPricePlanResidential}
                selectNewPricePlanResidential={selectNewPricePlanResidential}
                currentRateCode={currentRateCode}
                disabled={true}
            />
            <PlanSelection
                rateCode={pricePlanConstants.PRICE_PLAN_RES_SERVER_MAP.MPOWER}
                description={StartServiceDictionary.en.mpower_plan}
                learnMoreLink="price-plans/residential-electric/m-power"
                selectedPricePlanResidential={selectedPricePlanResidential}
                selectNewPricePlanResidential={selectNewPricePlanResidential}
                currentRateCode={currentRateCode}
                disabled={true}
             />
        </FormGroup>
    )
}

ResidentialSelectPlan.propTypes = {
    selectedPricePlanResidential: PropTypes.number,
    selectNewPricePlanResidential: PropTypes.func,
    currentRateCode: PropTypes.number
}

export default ResidentialSelectPlan