import React from 'react'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import Card from '@mui/material/Card'
import AddLandlordForm from '../profile_settings_page/account_info/add_landlord/add-landlord-form'

const AddLandlordCard = ({t, i18n}) => {
    const navigate = useNavigate()

    return (
        <React.Fragment>
            <div className="col-12 col-lg-9">
                <div className="srp-card-header">{t("Add landlord agreement")}</div>
                <Card className="srp-card-details">
                    <AddLandlordForm hideForm={() => navigate('/myaccount/dashboard')} t={t} i18n={i18n} />
                </Card>
            </div>
        </React.Fragment>
    )
}

AddLandlordCard.propTypes = {
    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired,
}

export default AddLandlordCard