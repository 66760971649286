import React from 'react'
import { withTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import CircularProgress from '@mui/material/CircularProgress'
import format from '../../../srp_modules/format'
import * as loadingStatus from '../../../constants/loading-status-constants'
import Card from '@mui/material/Card'
import '../../../styles/dashboard-page.css'
import utils from '../../../srp_modules/utils'

import BankDetailsSection from './bank-details-section'
import TotalPurchaseLine from './total-purchase-line'

import PurchaseDetailsLine from '../../single_purchase_page/common/purchase-details-line'
import PurchaseDetailsPanel from '../../single_purchase_page/common/purchase-details-panel'
import MpowerPurchaseAcknowledgement from '../../single_purchase_page/confirmation_page/mpower-purchase-acknowledgement'

import { formEvent } from '../../../srp_modules/analytics-events'
import { withRouter } from '../../../srp_modules/with-router'

import { purchaseConstants } from '../../common_payment/purchase-constants'
import { myAccountConstants } from '../../../constants/myaccount-constants'

class VerifyMultiPaymentNgpPagePure extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            confirmPurchaseFormEventEmitted: false
        }

        this.goBack = this.goBack.bind(this)
        this.emitFormStepEvent = this.emitFormStepEvent.bind(this)
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.showSubmitError) {
            document.getElementById("verify-multi-payment-page").scrollIntoView({ behavior: 'smooth' })
        }
    }

    componentDidUpdate() {
        this.emitFormStepEvent()
    }

    goBack() {
        const formName = purchaseConstants.ADOBE_ANALYTICS_FORM_NAME
        const stepName = purchaseConstants.PURCHASE_STEPS[0]
        formEvent(formName, stepName)

        this.props.clearSubmitPurchaseError()

        this.props.router.navigate(-1)
    }

    emitFormStepEvent() {
        if (this.state.confirmPurchaseFormEventEmitted === false) {
            const formName = purchaseConstants.ADOBE_ANALYTICS_FORM_NAME
            const stepName = purchaseConstants.PURCHASE_STEPS[1]
            formEvent(formName, stepName)

            this.setState({ ...this.state, confirmPurchaseFormEventEmitted: true })
        }
    }

    render() {
        return (
            <div className="container py-3 hidden-sm-down" id="verify-multi-payment-page">
                <h3 className="mt-3">{this.props.t("Make a purchase")}</h3>
                <br />
                <div className="row">
                    <div className="col-lg-6 col-12">
                        <label className="h5">{this.props.t("Confirm purchase information")}</label>
                        <Card>
                            {(this.props.showSubmitError) &&
                                <div className="srp-alert-error mx-3 mt-3">
                                    {this.props.t("We_are_unable_to_complete_your_online_request")}
                                    {this.props.i18n.language === "es" ? myAccountConstants.SPANISH_CUSTOMER_SERVICE_PHONE_NUMBER_FMTTD
                                                                       : myAccountConstants.RESIDENTIAL_CUSTOMER_SERVICE_PHONE_NUMBER_FMTTD}.
                                </div>
                            }

                            <BankDetailsSection selectedBankAccountDescription={this.props.selectedBankAccountDescription} />
                            <TotalPurchaseLine totalPurchase={this.props.totalPurchase} />

                            <h4 className="px-3 mt-4 text-secondary">{this.props.t("Purchase details")}</h4>
                            {this.props.purchaseList.map((purchase, index) => {
                                const srpAccountNumber = format.formatBillAccountNumber(purchase.billAccount)
                                const purchaseAmount = utils.paymentToNumber(purchase.purchaseAmount)

                                const paymentInfo = this.props.paymentInfoList.find(pi => pi.billAccount === purchase.billAccount)
                                const amountOwedToSRP = paymentInfo.paymentInfo.amountDue
                                const payDownFraction = paymentInfo.paymentInfo.payDownFraction

                                return (
                                    <div key={index}>
                                        <div className="px-3">
                                            {amountOwedToSRP <= 0 ?
                                                <PurchaseDetailsLine srpAccountNumber={srpAccountNumber} purchaseAmount={purchaseAmount} purchaseDate={new Date()} /> :
                                                <PurchaseDetailsPanel
                                                    srpAccountNumber={srpAccountNumber}
                                                    purchaseAmount={purchaseAmount}
                                                    applyWholePurchaseTowardsArrears={purchase.payAmountOwedToSRP}
                                                    purchaseDate={new Date()}
                                                    amountOwedToSRP={amountOwedToSRP}
                                                    payDownFraction={payDownFraction}
                                                    defaultExpanded={true}
                                                />
                                            }
                                        </div>
                                        <hr />
                                    </div>
                                )
                            })}

                            <MpowerPurchaseAcknowledgement />

                            <div className="mx-3 mt-3">
                                <p className="font-14 text-muted">
                                    {this.props.t("By_clicking_the_Submit_purchase_button")}
                                </p>
                            </div>

                            <div className="d-flex justify-content-start m-3 flex-row-reverse flex-wrap-reverse">
                                <button disabled={this.props.submitMultiPurchaseResultStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS} className="btn srp-btn btn-green mt-2" type="button" onClick={this.props.onSubmitClick}>{this.props.submitMultiPurchaseResultStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS ? <CircularProgress size={20} thickness={5} style={{ color: 'white' }} /> : "Submit purchase"}</button>
                                <button disabled={this.props.submitMultiPurchaseResultStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS} className="btn srp-btn btn-lightblue mx-2 mt-2" type="button" onClick={this.goBack} {...this.props.submitMultiPurchaseResultStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS && { 'aria-disabled': true, tabIndex: -1 }}>
                                    <span className="d-lg-none">{this.props.t("Edit")}</span>
                                    <span className="d-none d-lg-block">{this.props.t("Edit purchase details")}</span>
                                </button>
                            </div>
                        </Card>
                    </div>
                </div>
            </div>
        )
    }
}

VerifyMultiPaymentNgpPagePure.propTypes = {
    purchaseList: PropTypes.arrayOf(PropTypes.object).isRequired,
    paymentInfoList: PropTypes.arrayOf(PropTypes.object),
    totalPurchase: PropTypes.number.isRequired,
    submitMultiPurchaseResultStatus: PropTypes.string.isRequired,
    selectedBankAccountDescription: PropTypes.string.isRequired,
    onSubmitClick: PropTypes.func.isRequired,
    showSubmitError: PropTypes.bool.isRequired,
    clearSubmitPurchaseError: PropTypes.func.isRequired,
    router: PropTypes.shape({
        navigate: PropTypes.func
    }),

    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired
}

const VerifyMultiPaymentNgpPage = withTranslation("multiPurchasePage")(withRouter(VerifyMultiPaymentNgpPagePure))
export { VerifyMultiPaymentNgpPage as default, VerifyMultiPaymentNgpPagePure }