import { dailyUsageConstants } from '../../../constants/daily-usage-constants'
import { billAccountConstants } from '../../../constants/bill-account-constants'
import * as loadingStatus from '../../../constants/loading-status-constants'
import { DateTime } from 'luxon'
const initialState = {
    dailyUsageList:[],
    highDemandInfoList:[],
    dailyDemandList:[],
    dailyConsumptionList:[],
    dailyGenerationList:[],
    dailyReceivedList: [],
    monthlyCostAndKwhList: [],
    isAverageDemandRate: false,
    usageRangeMin: DateTime.now().setZone('MST'),
    usageRangeMax: DateTime.now().setZone('MST'),
    IsIDRRate: false,
    dailyUsageStatus: loadingStatus.LOADING_STATUS_INIT,
}

export default function dailyUsageReducer(state = initialState, action){
    switch(action.type){
        case dailyUsageConstants.GET_DAILY_REQUEST:
            return {
                    ...state,
                    dailyUsageStatus: loadingStatus.LOADING_STATUS_IN_PROGRESS
                }
        case dailyUsageConstants.GET_DAILY_SUCCESS:
            return {
                ...state,
                dailyUsageList: action.payload.dailyReadList,
                highDemandInfoList: action.payload.highDemandInfoList,
                dailyDemandList: action.payload.dailyDemandList,
                dailyConsumptionList: action.payload.dailyConsumptionList,
                dailyGenerationList: action.payload.dailyGenerationList,
                dailyReceivedList: action.payload.dailyReceivedList,
                isAverageDemandRate: action.payload.isAverageDemandRate,
                monthlyCostAndKwhList: action.payload.monthlyCostAndKwhList,
                usageRangeMin: action.payload.dailyReadList.length > 0
                                    ? DateTime.fromISO(action.payload.dailyReadList[0].date).setZone('MST')
                                    : DateTime.now().setZone('MST'),
                usageRangeMax: action.payload.dailyReadList.length > 0
                                    ? DateTime.fromISO(action.payload.dailyReadList[action.payload.dailyReadList.length - 1].date).setZone('MST')
                                    : DateTime.now().setZone('MST'),
                IsIDRRate: action.payload.IsIDRRate,
                dailyUsageStatus: loadingStatus.LOADING_STATUS_SUCCESS
                }
        case dailyUsageConstants.GET_DAILY_FAILURE:
            return {
                ...state,
                dailyUsageList: [],
                dailyDemandList:[],
                highDemandInfoList:[],
                dailyConsumptionList:[],
                dailyGenerationList:[],
                dailyReceivedList:[],
                monthlyCostAndKwhList: [],
                usageRangeMin: DateTime.now().setZone('MST'),
                usageRangeMax: DateTime.now().setZone('MST'),
                IsIDRRate: false,
                dailyUsageStatus: loadingStatus.LOADING_STATUS_FAILURE
            }
        case billAccountConstants.SELECT_BILL_ACCOUNT_SUCCESS:
            return initialState
        default:
            return state
    }
}