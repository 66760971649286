export const creditAdvanceConstants = {
    GET_MPOWER_SELF_SERVICE_REQUEST: 'GET_MPOWER_SELF_SERVICE_REQUEST',
    GET_MPOWER_SELF_SERVICE_SUCCESS: 'GET_MPOWER_SELF_SERVICE_SUCCESS',
    GET_MPOWER_SELF_SERVICE_FAILURE: 'GET_MPOWER_SELF_SERVICE_FAILURE',

    SUBMIT_MPOWER_SELF_SERVICE_REQUEST: 'SUBMIT_MPOWER_SELF_SERVICE_REQUEST',
    SUBMIT_MPOWER_SELF_SERVICE_SUCCESS: 'SUBMIT_MPOWER_SELF_SERVICE_SUCCESS',
    SUBMIT_MPOWER_SELF_SERVICE_FAILURE: 'SUBMIT_MPOWER_SELF_SERVICE_FAILURE',

    RESET_MPOWER_SELF_SERVICE: 'RESET_MPOWER_SELF_SERVICE',
}