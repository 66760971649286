import React from 'react'
import PropTypes from 'prop-types'

import PasswordField from './password-field'

const UnmaskPasswordIconAndPasswordField = ({password, passwordOnChange, passwordOnBlur, idSuffix, showPassword, togglePasswordVisibility,
    isLoginError, loginErrorText, autoFocus, t}) => (
    <PasswordField
        password={password}
        passwordOnChange={passwordOnChange}
        passwordOnBlur={passwordOnBlur}
        idSuffix={idSuffix}
        showPassword={showPassword}
        unmaskPasswordIconClick={togglePasswordVisibility}
        isLoginError={isLoginError}
        loginErrorText={isLoginError ? loginErrorText : ''}
        autoFocus={autoFocus}
        t={t} />
)

UnmaskPasswordIconAndPasswordField.propTypes = {
    password: PropTypes.string.isRequired,
    passwordOnChange: PropTypes.func.isRequired,
    passwordOnBlur: PropTypes.func.isRequired,
    idSuffix: PropTypes.string.isRequired,
    showPassword: PropTypes.bool.isRequired,
    autoFocus: PropTypes.bool,
    isLoginError: PropTypes.bool,
    loginErrorText: PropTypes.string,
    togglePasswordVisibility: PropTypes.func.isRequired,
    t: PropTypes.func
}

export default UnmaskPasswordIconAndPasswordField