import React from 'react'
import { withTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import TextField from '@mui/material/TextField'
import { NumericFormat } from 'react-number-format'

class PurchaseAmountFieldPure extends React.Component {
    render() {
        let isError = this.props.touched['purchaseAmount']
            && Object.prototype.hasOwnProperty.call(this.props.errors, 'purchaseAmount')
        let errMsg = isError ? this.props.errors['purchaseAmount'] : ''

        const getLocalizedErrMsg = errMsg.getLocalizedErrMsg
        if (typeof getLocalizedErrMsg === 'function')
            errMsg = getLocalizedErrMsg()
        else if (errMsg !== "")
            errMsg = this.props.t(errMsg)

        let meta = {
            id: 'purchaseAmount',
            name: 'purchaseAmount',
            label: this.props.t("Purchase amount"),
            helperText: errMsg,
            error: isError
        }

        let numberFormatProps = {
            value: this.props.value.replace(/[$,]/g, ''), // Formik always passes in the formatted value, we want the unformatted string value
            onChange: this.props.onChange,
            onBlur: this.props.onBlur,
            errors: this.props.errors,
            touched: this.props.touched,
            fullWidth: this.props.fullWidth
        }

        return (
            <NumericFormat
                {...meta}
                {...numberFormatProps}
                valueIsNumericString={true}
                thousandSeparator={true}
                prefix={'$'}
                decimalScale={2}
                fixedDecimalScale={false}
                allowNegative={false}
                customInput={TextField}
                InputProps={{
                    inputProps: { inputMode: 'decimal' }
                }}
                onBlur={() => {
                    let fixedPaymentAmount = Number(numberFormatProps.value).toFixed(2)
                    this.props.setFieldValue('purchaseAmount', fixedPaymentAmount, false)
                    this.props.setFieldTouched(meta.id, true)
                }}
                isAllowed={field => field.floatValue === undefined || field.floatValue < 1000000000.00}
                type="text"
            />
        )
    }
}

PurchaseAmountFieldPure.propTypes = {
    errors: PropTypes.shape({
        purchaseAmount: PropTypes.oneOfType([
            PropTypes.shape({
                getLocalizedErrMsg: PropTypes.func.isRequired,
            }),
            PropTypes.string.isRequired,
        ]),
    }).isRequired,
    fullWidth: PropTypes.bool,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired,
    onBlur: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    setFieldTouched: PropTypes.func.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    touched: PropTypes.shape({
        purchaseAmount: PropTypes.bool
    }).isRequired,
    value: PropTypes.string.isRequired
}

const PurchaseAmountField = withTranslation("singlePurchasePage")(PurchaseAmountFieldPure)
export { PurchaseAmountField as default, PurchaseAmountFieldPure }