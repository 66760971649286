import React from "react"
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { withTranslation } from 'react-i18next'

import '../../styles/dashboard-page.css'
import '../../styles/login-page.css'

const SignUpForm = ({t}) => (
    <div className="srp-card-details">
        <h5 className="text-muted">{t("Why you'll love My Account")}</h5>
            <ul className="mt-2 login-list">
                <li><span className="login-list">{t("Quick access to your current and previous bills")}</span></li>
                <li><span className="login-list">{t("Manage your energy usage with charts and bill projection")}</span></li>
                <li><span className="login-list">{t("Up to the minute details regarding outages")}</span></li>
                <li><span className="login-list">{t("And more!")}</span></li>
            </ul>

            <div className="srp-card-buttons d-flex justify-content-end mt-4">
                <Link to="/accountSetup" className="btn srp-btn btn-lightblue">{t("Sign up for free")}</Link>
            </div>

            {/*
                We used to have the Norton Secured and Trust eVerified logos here; but I was unable to
                verify whether we are actually allowed to put them on our page, so I removed them.
            */}
    </div>
)

SignUpForm.propTypes = {
    t: PropTypes.func.isRequired
}

export default withTranslation('login')(SignUpForm)