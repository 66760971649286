import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet-async'

import { withTranslation } from 'react-i18next'

import OutagesPage from './outages-page'
import PublicOutagePage from './outages-page-public'

import { getAllOutages, getIceReimbursementInfo } from '../../actions/guest/outage/outage-public-actions'
import { getIsInOutageArea, getOutageHistory } from '../../actions/auth/outage/outage-actions'
import { getMedicalSupportOption, getIsIneligibleToReport, getLastReportedOutage } from '../../actions/auth/outage/report-outage-actions'
import { getRateMetaData } from '../../actions/auth/usage/rate-meta-data-actions'
import { withRouter } from '../../srp_modules/with-router'

import * as loadingStatus from '../../constants/loading-status-constants'

class OutagesPageContainer extends React.Component {
    constructor(props) {
        super(props)

        this.refreshOutageHistoryCard = this.refreshOutageHistoryCard.bind(this)
        this.refreshOutageMapCard = this.refreshOutageMapCard.bind(this)
    }

    componentDidMount() {
        this.props.getPublicOutageInfo()
        this.props.getIceReimbursementInfo()

        if (this.props.selectedBillAccount === 0 || this.props.selectedBillAccountDetails.isPendingTurnOn)
            return

        this.props.getCustomerInOutageAreaInfo(this.props.selectedBillAccount)
        this.props.getOutageHistoryInfo(this.props.selectedBillAccount)
        this.props.getLastReportedOutageInfo(this.props.selectedBillAccount)

        if (this.props.hasMedicalSupportOptionStatus === loadingStatus.LOADING_STATUS_INIT) {
            this.props.getMedicalSupportOptionInfo(this.props.selectedBillAccount)
        }

        if (this.props.isIneligibleToReportStatus === loadingStatus.LOADING_STATUS_INIT) {
            this.props.getIsIneligibleToReportInfo(this.props.selectedBillAccount)
        }

        if (this.props.rateMetaDataStatus === loadingStatus.LOADING_STATUS_INIT) {
            this.props.getRateMetaDataInfo(this.props.selectedBillAccount)
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if((this.props.selectedBillAccount === nextProps.selectedBillAccount && nextProps.selectedBillAccount !== 0)
            && (this.props.customerInOutageInfo.isInOutageArea && !nextProps.customerInOutageInfo.isInOutageArea)){
                this.props.getOutageHistoryInfo(nextProps.selectedBillAccount)
            }

        if (this.props.selectedBillAccount === nextProps.selectedBillAccount)
            return

        if (nextProps.allOutagesStatus !== loadingStatus.LOADING_STATUS_IN_PROGRESS) {
            this.props.getPublicOutageInfo()
        }

        if (nextProps.iceReimbursementInfoStatus !== loadingStatus.LOADING_STATUS_IN_PROGRESS) {
            this.props.getIceReimbursementInfo()
        }

        if (nextProps.selectedBillAccount === 0 || nextProps.selectedBillAccountDetails.isPendingTurnOn)
            return

        if (nextProps.customerInOutageInfoStatus !== loadingStatus.LOADING_STATUS_IN_PROGRESS) {
            this.props.getCustomerInOutageAreaInfo(nextProps.selectedBillAccount)
        }

        if (nextProps.outageHistoryStatus !== loadingStatus.LOADING_STATUS_IN_PROGRESS) {
            this.props.getOutageHistoryInfo(nextProps.selectedBillAccount)
        }

        if (nextProps.hasMedicalSupportOptionStatus !== loadingStatus.LOADING_STATUS_IN_PROGRESS) {
            this.props.getMedicalSupportOptionInfo(nextProps.selectedBillAccount)
        }

        if (nextProps.isIneligibleToReportStatus !== loadingStatus.LOADING_STATUS_IN_PROGRESS) {
            this.props.getIsIneligibleToReportInfo(nextProps.selectedBillAccount)
        }

        if (nextProps.lastReportedOutageStatus !== loadingStatus.LOADING_STATUS_IN_PROGRESS) {
            this.props.getLastReportedOutageInfo(nextProps.selectedBillAccount)
        }

        if (nextProps.rateMetaDataStatus !== loadingStatus.LOADING_STATUS_IN_PROGRESS) {
            this.props.getRateMetaDataInfo(nextProps.selectedBillAccount)
        }
    }

    refreshOutageHistoryCard() {
        if (this.props.outageHistoryStatus === loadingStatus.LOADING_STATUS_FAILURE && !this.props.selectedBillAccountDetails.isPendingTurnOn) {
            this.props.getOutageHistoryInfo(this.props.selectedBillAccount)
        }
    }

    refreshOutageMapCard() {
        if (this.props.allOutagesStatus === loadingStatus.LOADING_STATUS_FAILURE) {
            this.props.getPublicOutageInfo()
        }
        if (this.props.hasMedicalSupportOptionStatus === loadingStatus.LOADING_STATUS_FAILURE) {
            this.props.getMedicalSupportOptionInfo(this.props.selectedBillAccount)
        }

        if (this.props.isIneligibleToReportStatus === loadingStatus.LOADING_STATUS_FAILURE) {
            this.props.getIsIneligibleToReportInfo(this.props.selectedBillAccount)
        }

    }

    render() {
        const t = this.props.t
        const i18n = this.props.i18n
        
        let renderedCard = {}
        if (this.props.isLoggedIn) {
            renderedCard = (
                <OutagesPage
                    navLocation={this.props.router.location.pathname}
                    customerInOutageInfo={this.props.customerInOutageInfo}
                    lastReportedOutage={this.props.lastReportedOutage}
                    lastReportedOutageStatus={this.props.lastReportedOutageStatus}
                    outageHistoryList={this.props.outageHistoryList}
                    outageHistoryStatus={this.props.outageHistoryStatus}
                    refreshOutageHistoryCard={this.refreshOutageHistoryCard}
                    refreshOutageMapCard={this.refreshOutageMapCard}
                    t={t}
                    i18n={i18n}
                    isPendingTurnOn={this.props.selectedBillAccountDetails.isPendingTurnOn} />
            )
        }
        else {
            renderedCard = <PublicOutagePage refreshOutageMapCard={this.refreshOutageMapCard} t={t} i18n={i18n} />
        }

        return (
            <div>
                <Helmet>
                    <title>{t("Outages | SRP Power")}</title>
                    <meta name="description" content={t("Check for outages, updates and restoration times.")} />

                    <meta name="twitter:card" content="summary_large_image" />
                    <meta name="twitter:site" content="@srpconnect" />
                    <meta name="twitter:image" content="https://www.srpnet.com/assets/srpnet/images/hero/customer-service/outages-and-storm-safety.jpg" />

                    <meta property="og:url" content="https://myaccount.srpnet.com/power/MyAccount/Outages" />
                    <meta property="og:type" content="website" />
                    <meta property="og:title" content={t("SRP Outage Map")} />
                    <meta property="og:description" content={t("Check for outages, updates and restoration times.")} />
                    <meta property="og:image" content="https://www.srpnet.com/assets/srpnet/images/hero/customer-service/outages-and-storm-safety.jpg" />
                </Helmet>
                {renderedCard}
            </div>
        )
    }
}

const mapStateToProps = state => {
    return { ...state.accountInfo.outage, ...state.accountInfo.reportOutage, ...state.accountInfo.billAccount, ...state.login, ...state.rateMetaData }
}

const mapDispatchToProps = dispatch => {
    return {
        getCustomerInOutageAreaInfo: (billAccount) => {
            dispatch(getIsInOutageArea(billAccount))
        },
        getOutageHistoryInfo: (billAccount) => {
            dispatch(getOutageHistory(billAccount))
        },
        getPublicOutageInfo: () => {
            dispatch(getAllOutages())
        },
        getMedicalSupportOptionInfo: (billAccount) => {
            dispatch(getMedicalSupportOption(billAccount))
        },
        getIsIneligibleToReportInfo: (billAccount) => {
            dispatch(getIsIneligibleToReport(billAccount))
        },
        getLastReportedOutageInfo: (billAccount) => {
            dispatch(getLastReportedOutage(billAccount))
        },
        getRateMetaDataInfo: (billAccount) => {
            dispatch(getRateMetaData(billAccount))
        },
        getIceReimbursementInfo: () => {
            dispatch(getIceReimbursementInfo())
        }
    }
}

OutagesPageContainer.propTypes = {
    customerInOutageInfo: PropTypes.object,
    serviceReliability: PropTypes.number,
    outageHistoryList: PropTypes.arrayOf(PropTypes.object),
    outageHistoryCount: PropTypes.string,
    outageHistoryTimeSpan: PropTypes.string,
    outageHistoryStatus: PropTypes.string,
    getPublicOutageInfo: PropTypes.func,
    allOutagesStatus: PropTypes.string,
    selectedBillAccount: PropTypes.number,
    selectedWaterAccount: PropTypes.number,
    customerInOutageInfoStatus: PropTypes.string,
    getCustomerInOutageAreaInfo: PropTypes.func,
    getOutageHistoryInfo: PropTypes.func,
    isLoggedIn: PropTypes.bool,
    getMedicalSupportOptionInfo: PropTypes.func,
    hasMedicalSupportOptionStatus: PropTypes.string,
    isIneligibleToReportStatus: PropTypes.string,
    getIsIneligibleToReportInfo: PropTypes.func,
    lastReportedOutage: PropTypes.object,
    lastReportedOutageStatus: PropTypes.string,
    getLastReportedOutageInfo: PropTypes.func,
    getRateMetaDataInfo: PropTypes.func,
    rateMetaDataStatus: PropTypes.string,
    selectedBillAccountDetails: PropTypes.object,
    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired,
    getIceReimbursementInfo: PropTypes.func,
    iceReimbursementInfoStatus: PropTypes.string,
    iceReimbursementInfo: PropTypes.object,
    router: PropTypes.shape({
        location: PropTypes.object
    })
}

export default withTranslation('outageMap')(withRouter(connect(mapStateToProps, mapDispatchToProps)(OutagesPageContainer)))