import React from 'react'
import PropTypes from 'prop-types'
import { MenuItem, TextField } from '@mui/material'

class LandlordAgreementDropdown extends React.Component {
    render() {
        const t= this.props.t
        const label = t('Landlord agreement number')
        return (
            <TextField select sx={{ minWidth: label.length * 7 }}
                    value={this.props.selectedLandlordAgreement}
                    onChange={this.props.landlordAgreementOnChange}
                    name="Landlord agreement number"
                    label={label}
                    disabled={this.props.disabled}>
                {this.props.landlordAgreementList.map(a => <MenuItem key={a} value={a}>{a}</MenuItem>)}
            </TextField>
        )
    }
}

LandlordAgreementDropdown.propTypes = {
    disabled: PropTypes.bool.isRequired,
    landlordAgreementList: PropTypes.array.isRequired,
    selectedLandlordAgreement: PropTypes.number.isRequired,

    landlordAgreementOnChange: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
}

export default LandlordAgreementDropdown