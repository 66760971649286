import { paymentLocationConstants } from '../../constants/payment-location-constants'
import * as loadingStatus from '../../constants/loading-status-constants'

const initialState = {
    paymentLocationStatus: loadingStatus.LOADING_STATUS_INIT,
    paymentLocationList: [],
    filteredPayLocationList: [],
    filterType: paymentLocationConstants.PAY_LOCATION_TYPE.ALL,

    topFivePayLocations: [],
    displayClosestPaymentLocations: true,
    isClosestPayLocFromSearch: false,
    
    payLocationDetailStyle: { width: '0', top: '0' },
    payLocationMarkerInfo: {
        locationName: "",
        type: 0,
        street: "",
        city: "",
        stateZip: "",
        phone: "",
        hours: "",
        notes: "",
        paycenterAvailable: 0,
        paycenterUnavailable: 0,
        days: []
    },

    currentLocation: {},
    currentLocationLoading: true,
    searchAddressCoords: {},
    startAddress: "",
    endAddress: "",
    startAddrDetailStyle: { width: '0', top: '0' }
}

export default function paymentLocationReducer(state = initialState, action) {
    switch (action.type) {
        case paymentLocationConstants.CLEAR_CLOSEST_PAY_LOCATIONS:
            return { ...state, displayClosestPaymentLocations: false, topFivePayLocations: [], currentLocationLoading: false }
        case paymentLocationConstants.FIND_PAYMENT_LOCATIONS:
            return { ...state, displayClosestPaymentLocations: true, topFivePayLocations: action.closestPayLocations, isClosestPayLocFromSearch: action.isFromSearch }
        case paymentLocationConstants.HIDE_PAY_LOCATION_DETAILS:
            return { ...state, payLocationDetailStyle: { width: '0' }, startAddrDetailStyle: { width: '0' } }
        case paymentLocationConstants.SHOW_PAY_LOCATION_DETAILS:
            return { ...state, payLocationDetailStyle: { width: '300px' }, payLocationMarkerInfo: action.markerInfo, startAddrDetailStyle: { width: '0' } }
        case paymentLocationConstants.SHOW_START_ADDR_DETAILS:
            return { ...state, startAddrDetailStyle: { width: '300px' }, payLocationDetailStyle: { width: '0' } }
        case paymentLocationConstants.HIDE_START_ADDR_DETAILS:
            return {
                ...state,
                startAddrDetailStyle: { width: '0' },
                payLocationDetailStyle: action.showDetails ? { width: '300px' } : { width: '0' } }
        case paymentLocationConstants.GET_ALL_PAY_LOCATIONS_REQUEST:
            return { ...state, paymentLocationStatus: loadingStatus.LOADING_STATUS_IN_PROGRESS }
        case paymentLocationConstants.GET_ALL_PAY_LOCATIONS_SUCCESS:
            return {
                ...state,
                paymentLocationList: action.payload.paymentLocationsList,
                filteredPayLocationList: action.payload.paymentLocationsList,
                paymentLocationStatus: action.payload.success ? loadingStatus.LOADING_STATUS_SUCCESS : loadingStatus.LOADING_STATUS_FAILURE
            }
        case paymentLocationConstants.GET_ALL_PAY_LOCATIONS_FAILURE:
            return {
                ...state,
                paymentLocationList: [],
                filteredPayLocationList: [],
                paymentLocationStatus: loadingStatus.LOADING_STATUS_FAILURE
            }
        case paymentLocationConstants.GET_CURRENT_LOCATION:
            return { ...state, currentLocation: action.location, currentLocationLoading: false }
        case paymentLocationConstants.GET_END_ADDRESS_FOR_DIRECTIONS:
            return { ...state, endAddress: action.address }
        case paymentLocationConstants.GET_START_ADDRESS_FOR_DIRECTIONS:
            return { ...state, startAddress: action.address }
        case paymentLocationConstants.GET_SEARCH_ADDRESS_COORDS:
            return { ...state, searchAddressCoords: {lat: action.lat, lng: action.lng} }
        case paymentLocationConstants.SET_FILTERED_PAY_LOCATION_LIST:
            return { ...state, filteredPayLocationList: action.filteredList, filterType: action.filteredType }
        default:
            return state
    }
}