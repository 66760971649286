// the dangerouslySetInnerHTML is used because otherwise the message is rendered as escaped text, not HTML
/* eslint react/no-danger: "off" */

import React from 'react'
import PropTypes from 'prop-types'
import 'react-widgets/styles.css'

const Message = ({ dashboardMessages, dismissMessage }) => {
    let rows = []
    for (let i = 0; i < dashboardMessages.length; i++) {
        rows.push(
            <div className="srp-card-body srp-alert-notice d-flex justify-content-between mt-3 align-items-center" key={i}>
                <div dangerouslySetInnerHTML={{ __html: dashboardMessages[i] }} />
                <button className="mui-button p-1" aria-label="close banner" onClick={()=>{dismissMessage(i)}}>
                    <i className="material-icons align-middle">close</i>
                </button>
            </div>
        )
    }

    return (
        <div>
            {rows}
        </div>
    )
}

Message.propTypes = {
    dashboardMessages: PropTypes.arrayOf(PropTypes.string),
    dismissMessage: PropTypes.func.isRequired
}

export default Message