import React from 'react'
import PropTypes from 'prop-types'

const LookingForUsageMonthly = ({t}) => {
    return (
        <div className="usage-chart-error">
            <div className="text-center">
                <div><i className="material-icons">error_outline</i></div>
                <div className="usage-chart-error-title">{t("Unable to view monthly comparison")}</div>
                <div style={{ marginRight: "25%", marginLeft: "25%", textAlign: "center" }}>
                    {t("Monthly bill information will be available after your first bill is issued.")}
                </div>
            </div>
        </div>
    )
}

LookingForUsageMonthly.PropTypes = {
    t: PropTypes.func
}

export default LookingForUsageMonthly