import React from 'react'
import PropTypes from 'prop-types'
import Button from '@mui/material/Button'
import Icon from '@mui/material/Icon'

const NavMenuButtonResponsive = ({openResponsiveNavOnClick, pageTitle, t}) => {
    return (
        <div className="d-block d-md-none">
            <Button 
                variant="contained"
                fullWidth={true}
                className="justify-content-start nav-button-responsive"
                onClick={openResponsiveNavOnClick}>
                <Icon className="material-icons mr-2" style={{color: 'rgb(33,108,224)'}}>dehaze</Icon>
                {t(pageTitle)}
            </Button>
        </div>
    )

}

NavMenuButtonResponsive.propTypes = {
    openResponsiveNavOnClick: PropTypes.func,
    pageTitle: PropTypes.string,
    t: PropTypes.func.isRequired
}

export default NavMenuButtonResponsive