import React from 'react'
import PropTypes from 'prop-types'

const OutagePageError = ({dataThatFailed, refreshData, t}) => {

    return(
        <div className="srp-card-details">
            <div className="text-center">
                <div className="mb-1" style={{color: "#9E2A2B"}}><i className="material-icons" style={{fontSize:"50px"}}>error_outline</i></div>
                <div className="mb-1" style={{color: "#9E2A2B"}}><strong>{t("Something went wrong")}</strong></div>
                <div className="mb-4">{t("unable_to_retrieve_data", {dataThatFailed})}</div>
            </div>
            <div className="d-flex justify-content-end">
                <a onClick={()=>refreshData()} className="btn srp-btn btn-lightblue text-white mr-2">{t("Try again")}</a>
            </div> 
        </div>
    )
}

OutagePageError.propTypes = {
    t: PropTypes.func.isRequired,
    dataThatFailed: PropTypes.string,
    refreshData: PropTypes.func
}

export default OutagePageError