import React from 'react'
import PropTypes from 'prop-types'
import { DateTime, Duration } from 'luxon'

import '../../styles/outages-page.css'

import { Trans } from 'react-i18next'

const OutageHistory = ({serviceReliability, outageHistoryList, outageHistoryCount, totalOutageHistoryDuration, outageHistoryTimeSpan,
                        getNextOutageHistoryOnClick, getPrevOutageHistoryOnClick, outageHistoryIndex, t, i18n}) => {

    const isSpanish = (i18n.language==="es")

    let isNextDisabled
    let isPrevDisabled
    if(outageHistoryIndex === 0) {
        isPrevDisabled = true
    }
    else {
        isPrevDisabled = false
    }

    if(outageHistoryIndex === (outageHistoryList.length - 1)) {
        isNextDisabled = true
    }
    else {
        isNextDisabled = false
    }

    return (
        <div>
            <div className="srp-card-summary d-flex justify-content-around">
                <div className="d-flex flex-column align-items-center">
                    <Trans i18nKey="pct_service_reliability" t={t}>
                    <div className="h4 text-white">{{serviceReliability}}%</div>
                    service reliability
                    </Trans>
                </div>
            </div>

            <div className="srp-card-details" id="outage-history-description" >
                <div className="text-muted mt-2">
                    {outageHistoryVerbiage(outageHistoryCount, outageHistoryTimeSpan, totalOutageHistoryDuration, t)}
                </div>
                {Object.keys(outageHistoryList).length !== 0
                    ? (<div className="outage-history-description" id="outageHistory">
                        <div className="d-none d-lg-block" >
                            {outageHistoryList.map( (row, index) => (
                                <div key={index}>
                                    <div className="d-flex justify-content-between mt-3">
                                        <div> {formatDate(row.outageStart)} </div>
                                        <div className="text-muted"> {formatOutageDuration(row.duration, t) + " "} duration </div>
                                    </div>
                                    <div className="mt-2">
                                        {isSpanish ? row.outageReasonEs : row.outageReason}
                                    </div>
                                    <hr className="mt-1"/>
                                </div>
                            ))}
                        </div>
                        <div className="d-lg-none d-block">
                            <div >
                                <div className="row mt-3 d-flex align-items-center">
                                    <div className="col text-muted align-middle">
                                        {t("Outage date")}
                                    </div>
                                    <div className="col align-middle text-right">
                                        {formatDate(outageHistoryList[outageHistoryIndex].outageStart)}
                                    </div>
                                </div>
                                <hr className="mt-2"/>
                                <div className="row mt-2 d-flex align-items-center">
                                    <div className="col text-muted align-middle">
                                        {t("Duration")}
                                    </div>
                                    <div className="col align-middle text-right">
                                        {formatOutageDuration(outageHistoryList[outageHistoryIndex].duration, t) + " "}
                                    </div>
                                </div>
                                <hr className="mt-2"/>
                                <div className="row mt-2 d-flex align-items-center">
                                    <div className="col text-muted align-middle">
                                        {t("Outage cause")}
                                    </div>
                                    <div className="col align-middle text-right">
                                        {isSpanish ? outageHistoryList[outageHistoryIndex].outageReasonEs : outageHistoryList[outageHistoryIndex].outageReason}
                                    </div>
                                </div>
                                <hr className="mt-2" style={{border: '2px solid lightgray'}}/>
                            </div>

                            <div className="d-flex justify-content-between mt-4">
                                <button onClick={()=>getPrevOutageHistoryOnClick()} disabled={isPrevDisabled} className="btn srp-btn btn-lightblue d-flex align-items-center"><i className="material-icons">{'keyboard_arrow_left'}</i></button>
                                <button onClick={()=>getNextOutageHistoryOnClick()} disabled={isNextDisabled} className="btn srp-btn btn-lightblue d-flex align-items-center"><i className="material-icons">{'keyboard_arrow_right'}</i></button>
                            </div>
                        </div>
                    </div>)
                    : <div/>}
            </div>
            {Object.keys(outageHistoryList).length !== 0
                ? <div className="outage-history-fadeout-container d-none d-lg-block">
                    <div className="outage-history-fadeout"/>
                </div>
                : <span/>
            }
        </div>
    )
}

OutageHistory.propTypes = {
    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired,

    serviceReliability: PropTypes.number,
    outageHistoryList: PropTypes.arrayOf(PropTypes.object),
    outageHistoryCount: PropTypes.string,
    totalOutageHistoryDuration: PropTypes.string, // NOTE: reducer should be storing this as ISO 8601 for now
    outageHistoryTimeSpan: PropTypes.string,
    getPrevOutageHistoryOnClick: PropTypes.func,
    getNextOutageHistoryOnClick: PropTypes.func,
    outageHistoryIndex: PropTypes.number
}

export default OutageHistory

function formatDate(date) {
    let dateNoTime = DateTime.fromISO(date).toFormat('EEE, MMM d, yyyy')
    return dateNoTime
}

function formatOutageDuration(duration, t) {
    // TODO: This will currently fail for outages longer than 24 hours
    // We can either convert the C# timespans into ISO 8601 timespans client-side,
    // or have the API return ISO 8601 times
    let luxonDuration = Duration.fromISOTime(duration, 'hours')

    let numberHours = luxonDuration.hours
    let numberMinutes = luxonDuration.minutes

    let displayHours = numberHours + "h"
    let displayMinutes = numberMinutes + "m"

    let displayText = `${displayHours} ${displayMinutes}`

    if (numberHours === 0) {
        return (numberMinutes === 0 ? t("less than 1m") : displayText)
    }

    return displayText
}

function outageHistoryVerbiage(count, timeSpan, duration, t) {
    if(count === "0 outages")
    {
        return t("zero_outages_summary", {timeSpan})
    }
    else
    {
        return t("outages_summary", {countOutages: count, timeSpan, outageDuration: formatOutageDuration(duration, t)})
    }
}