import { RSAA } from 'redux-api-middleware'
import config from 'my-account-config'
import utils from '../../../srp_modules/utils'
import { DateTime } from 'luxon'

import { outageConstants } from '../../../constants/outage-constants'

export const togglePowerRestoredCallback = (isRequestCallback) => ({
    type: outageConstants.TOGGLE_POWER_RESTORED_CALLBACK,
    isRequestCallback
})

//report outage
export const submitReportOutageForm = (billAccount, phoneNumber, requestCallback) => dispatch => {
    return dispatch({
        [RSAA]: {
            endpoint: config.apiBaseUrl + 'api/outages/report',
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ billAccount, phoneNumber, requestCallback }),
            credentials: 'include',
            types: [
                outageConstants.REPORT_OUTAGE_REQUEST,
                {
                    type: outageConstants.REPORT_OUTAGE_SUCCESS,
                    payload: (action, state, res) => {
                            return res.json().then(json => {
                                let returnJson = {}
                                if(json === true) {
                                    returnJson.lastReportedOutage = {
                                        hasReported: true,
                                        dateReported: DateTime.now().setZone('MST'),
                                        callBackPhoneNumber: phoneNumber,
                                        requestCallback
                                    }
                                }
                                else
                                {
                                    returnJson.lastReportedOutage = {
                                        hasReported: false,
                                        dateReported: DateTime.fromISO("0001-01-01T00:00:00"),
                                        callBackPhoneNumber: "",
                                        requestCallback: false
                                    }
                                }
                                
                                return returnJson
                            });
                    }
                },
                outageConstants.REPORT_OUTAGE_FAILURE
            ]
        }
    })
}

export const getMedicalSupportOption = billAccount => dispatch => {
    return dispatch({
        [RSAA]: {
            endpoint: utils.createUrl('outages/getMedicalSupportOption', { billAccount: billAccount }),
            method: 'GET',
            credentials: 'include',
            types: [
                outageConstants.GET_MEDICAL_SUPPORT_OPTION_REQUEST,
                outageConstants.GET_MEDICAL_SUPPORT_OPTION_SUCCESS,
                outageConstants.GET_MEDICAL_SUPPORT_OPTION_FAILURE
            ]
        }
    })
}

export const getIsIneligibleToReport = billAccount => dispatch => {
    return dispatch({
        [RSAA]: {
            endpoint: utils.createUrl('outages/isIneligibleToReport', { billAccount: billAccount }),
            method: 'GET',
            credentials: 'include',
            types: [
                outageConstants.GET_INELIGIBLE_TO_REPORT_REQUEST,
                outageConstants.GET_INELIGIBLE_TO_REPORT_SUCCESS,
                outageConstants.GET_INELIGIBLE_TO_REPORT_FAILURE
            ]
        }
    })
}

export const getLastReportedOutage = billAccount => dispatch => {
    return dispatch({
        [RSAA]: {
            endpoint: utils.createUrl('outages/lastReportedOutage', { billAccount: billAccount }),
            method: 'GET',
            credentials: 'include',
            types: [
                outageConstants.GET_LAST_REPORTED_OUTAGE_REQUEST,
                outageConstants.GET_LAST_REPORTED_OUTAGE_SUCCESS,
                outageConstants.GET_LAST_REPORTED_OUTAGE_FAILURE
            ]
        }
    })
}