import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import MenuItem from '@mui/material/MenuItem'
import Button from '@mui/material/Button'
import {changeMonthlyDateRange} from '../../actions/auth/usage/usage-actions'
import TextField from '@mui/material/TextField'
import { DateTime } from 'luxon'
import * as loadingStatus from '../../constants/loading-status-constants'

class MonthlyDateRange extends React.Component{
    constructor(props){
        super(props)
        this.state={
            startDate:  this.props.monthlyStartDate,
            endDate: this.props.monthlyEndDate,
            startDateAvailableList: this.props.billStartDates,
            endDateAvailableList: this.props.billEndDates,
        }
    }

    componentDidMount() {
        this.sliceDateDropdownItems(this.state.startDate, this.state.endDate)
    }

    sliceDateDropdownItems(startDate, endDate) {
        let endDateIndex = this.props.billStartDates.findIndex(getIndexOfDate, endDate)
        let startDateIndex = this.props.billEndDates.findIndex(getIndexOfDate, startDate)

        this.setState({
            endDateAvailableList: this.props.billEndDates.slice(0, startDateIndex + 1),
            startDateAvailableList:this.props.billStartDates.slice(endDateIndex, this.props.billStartDates.length)
        })
    }

    handleStartOnChange(event) {
        this.setState({startDate: event.target.value})
        this.sliceDateDropdownItems(event.target.value, this.state.endDate)
    }

    handleEndOnChange(event) {
        this.setState({endDate: event.target.value})
        this.sliceDateDropdownItems(this.state.startDate, event.target.value)
    }

    render() {
        if(this.props.accountHistoryStatus === loadingStatus.LOADING_STATUS_SUCCESS){
        return(
            <div>
                <div className="mr-4 ml-4 mt-3">
                    <div className="mb-2 d-none d-lg-flex row">
                        <div className="col-5 pt-1">
                            <TextField
                                select
                                fullWidth
                                value={this.state.startDate}
                                label={this.props.t("From bill end date")}
                                onChange={event => this.handleStartOnChange(event)}
                                className={"mr-4"}>
                                {startDateMenuItems(this.state.startDateAvailableList)}
                            </TextField>
                        </div>
                        <div className="col-5 pt-1">
                            <TextField
                                select
                                fullWidth
                                value={this.state.endDate}
                                label={this.props.t("To bill end date")}
                                onChange={event => this.handleEndOnChange(event)}
                                className={"mr-4"}>
                                {endDateMenuItems(this.state.endDateAvailableList)}
                            </TextField>
                        </div>
                        <div className="col-2 mt-4">
                            <Button size="small" className="btn srp-btn btn-green text-white" onClick={() => {this.props.changeDateRange(this.state.startDate, this.state.endDate) }}>
                            {this.props.t("Submit")}
                            </Button>
                        </div>
                    </div>
                </div>
                <div className="mr-4 ml-4 mt-3 mb-2 d-lg-none justify-right">
                    <TextField
                        select
                        value={this.state.startDate}
                        label={this.props.t("From bill end date")}
                        onChange={event => this.handleStartOnChange(event)}
                        style = {{width: "100%"}}>
                            {startDateMenuItems(this.state.startDateAvailableList)}
                    </TextField>
                    <TextField
                        select
                        value={this.state.endDate}
                        label={this.props.t("To bill end date")}
                        onChange={event => this.handleEndOnChange(event)}
                        style = {{width: "100%"}}>
                            {endDateMenuItems(this.state.endDateAvailableList)}
                    </TextField>
                    <div className="d-flex justify-content-end">
                        <Button size="small" className=" btn srp-btn btn-green text-white flex-align-self-right" onClick={() => {this.props.changeDateRange(this.state.startDate, this.state.endDate) }}>
                            {this.props.t("Submit")}
                        </Button>
                    </div>
                </div>
            </div>
        )
        }
        else{
            return(<div/>)
        }
    }

}

const mapStateToProps = state => {
    return {
        ...state.accountHistory,
        ...state.accountInfo.usagePageUI,
        selectedBillAccount: state.accountInfo.billAccount.selectedBillAccount,
        rateMetaData: state.rateMetaData
    }
}
const mapDispatchToProps = dispatch => {
    return {
            changeDateRange: (startDate,endDate) => {
            dispatch(changeMonthlyDateRange(startDate,endDate))

        }
    }
}
MonthlyDateRange.propTypes = {
    selectedBillAccount: PropTypes.number,
    monthlyStartDate: PropTypes.string,
    monthlyEndDate: PropTypes.string,
    changeDateRange: PropTypes.func,
    billStartDates: PropTypes.array,
    billEndDates: PropTypes.array,
    accountHistoryStatus: PropTypes.string,
    t: PropTypes.func
}

export default connect(mapStateToProps,mapDispatchToProps)(MonthlyDateRange);

function startDateMenuItems(startDates){
   let startDateItems =[]
   startDates.forEach(e => {
       startDateItems.push(<MenuItem
        key = {e}
        value = {e}>
            {DateTime.fromISO(e).toFormat('MMM d, yyyy')}
        </MenuItem>
    )
   })
   return startDateItems
}

function endDateMenuItems(endDates){
    let endDateItems =[]
    endDates.forEach(e => {
        endDateItems.push(<MenuItem
         key = {e}
         value = {e}>
             {DateTime.fromISO(e).toFormat('MMM d, yyyy')}
         </MenuItem>
     )
    })
    return endDateItems
 }

 function getIndexOfDate(date) {
    return DateTime.fromISO(date).hasSame(DateTime.fromISO(this), 'day')
}