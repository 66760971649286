/* eslint react/no-multi-comp: "off" */
import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { DateTime } from 'luxon'
import TextField from '@mui/material/TextField'
import MenuItem from '@mui/material/MenuItem'
import Chip from '@mui/material/Chip'
import Divider from '@mui/material/Divider'
import CircularProgress from '@mui/material/CircularProgress'

import { myAccountConstants } from '../../../constants/myaccount-constants'
import * as loadingStatus from '../../../constants/loading-status-constants'

const budgetBillingEstimateStyle = {
    backgroundColor: 'rgba(115, 123, 76, 1)',
    color: '#FFFFFF',
    fontSize: '1.25rem',
    fontWeight: '700',
    maxWidth: '370px',
    minHeight: '42px',
    borderRadius: '2px',
    paddingTop: '4px',
    paddingBottom: '4px',
    paddingLeft: '14px',
    paddingRight: '14px'
}

const compareBillStyle = {
    backgroundColor: 'rgba(207, 216, 220, 0.2)',
    height: '36px',
    borderRadius: '2px',
    padding: '0'
}

const compareBillTitleStyle = {
    color:'#707070',
    fontSize:'0.9375rem',
    lineHeight: '5px'
}

const CompareBillCard = ({accountHistoryList, billChipInfo, billToCompare, addBillToCompareOnClick, removeBillFromCompareOnClick,
    budgetBillingEstimate, submitBudgetBillingStatus, createAndSubmitBudgetBillingForm, isCommercial, isBudgetBillingEligible, hasEmail, t }) => {
    
    let availableBillDates = getBillDates(accountHistoryList, billChipInfo)
    
    return (
        <div className="srp-card-body ">
            <div className="srp-card-details pb-0">
                <div className="text-muted mb-2"> {t("What other bills would you like to compare your current bill to?")}</div>
                <div style={{display: 'flex', flexWrap: 'wrap'}}>
                    {billChipInfo.map( (chip) => (
                        chip === billChipInfo[0]
                        ? <Chip
                            key={chip.billGeneratedDate}
                            style={{margin: 4}}
                            label={DateTime.fromISO(chip.billGeneratedDate).toFormat('MMMM d, yyyy')}
                        />
                        : <Chip
                            key={chip.billGeneratedDate}
                            onDelete={()=>removeBillFromCompareOnClick(chip)}
                            style={{margin: 4}}
                            label={DateTime.fromISO(chip.billGeneratedDate).toFormat('MMMM d, yyyy')}
                        />
                    ))}
                </div>
                <div className="mt-2 mb-2">
                    <TextField
                        select
                        disabled={availableBillDates.length === 0}
                        label={t("Add bill")}
                        SelectProps={{ renderValue: () => t("Select bill date") }}
                        value={billToCompare}
                        onChange={event=>addBillToCompareOnClick(event.target.value, accountHistoryList[event.target.value])}
                        style={{width: 200}}>
                        {availableBillDates}
                    </TextField>
                </div>
            </div>
            <div className="d-lg-none d-block mt-4">
                <Divider/>
            </div>
            <div className="srp-card-details">
                <div className="pr-4">
                    <div className="d-lg-block d-none">
                        <div className="row mb-2" style={{height:'36px',alignItems: 'center'}}>
                            <div className="col-xl-4 col-lg-5 text-right">
                                <div>{DateTime.fromISO(billChipInfo[0].billGeneratedDate).toFormat('MMMM d, yyyy')}</div>
                            </div>
                            <div className="col-xl-6 col-lg-5" style={compareBillStyle}>
                                {calculateBarSize(accountHistoryList, billChipInfo[0])}
                            </div>
                            <div className="col-xl-2 col-lg-2 pr-0">
                                <div style={{fontSize:'0.875rem'}}>{"$" + ((billChipInfo[0].totalUsageCharges).toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
                            </div>
                        </div>
                    </div>
                    <div className="d-lg-none d-block mb-2">
                        <div>{DateTime.fromISO(billChipInfo[0].billGeneratedDate).toFormat('MMMM d, yyyy')}</div>
                        <div style={compareBillStyle}>{calculateBarSize(accountHistoryList, billChipInfo[0])}</div>
                        <div>
                            <span className="mr-3" style={{fontSize:'0.875rem'}}>{"$" + ((billChipInfo[0].totalUsageCharges).toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                        </div>
                    </div>
                    {billChipInfo.slice(1,billChipInfo.length).map( (bill, index) => (
                        <div key={index}>
                            <div className="d-lg-block d-none">
                                <div className="row mb-2" style={{height:'36px', alignItems: 'center'}}>
                                    <div className="col-xl-4 col-lg-5 col-12 text-right">
                                        {getChartTitle(billChipInfo[0], bill, t)}
                                    </div>
                                    <div className="col-xl-6 col-lg-5 col-12" style={compareBillStyle}>
                                        {calculateBarSize(accountHistoryList, bill)}
                                    </div>
                                    <div className="col-xl-2 col-lg-2 col-12 pr-0">
                                        <div className="mr-3" style={{fontSize:'0.875rem'}}>{"$" + ((bill.totalUsageCharges).toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="d-lg-none d-block mb-2">
                                <div>{getChartTitle(billChipInfo[0], bill, t)}</div>
                                <div style={compareBillStyle}>{calculateBarSize(accountHistoryList, bill)}</div>
                                <div>
                                    <span className="mr-3" style={{fontSize:'0.875rem'}}>{"$" + ((bill.totalUsageCharges).toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            {isBudgetBillingEligible
                ? <div>
                    <Divider/>
                    <div className="srp-card-details">
                        <div className="mb-4" style={{color: 'rgba(51, 51, 51, 0.537)', fontSize: '1.5rem', fontWeight: '500'}}>{t("Life's unpredictable. Make sure your bills aren't.")}</div>
                        {budgetBillingEstimate
                            ? <div className="mb-3" style={budgetBillingEstimateStyle}>
                                {t("Pay_per_month", {amount : budgetBillingEstimate.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")})}
                            </div>
                            :<div className="mb-3 srp-subscription-warn">
                                {t("More usage history is required to calculate your Budget Billing payment.")}
                            </div>
                        }
                        <div className="mb-3" style={{color: '#707070'}}>{t("With Budget Billing, you can make sure you're never surprised by a high bill.")}</div>
                        {budgetBillingEstimate && hasEmail
                            ? <div>
                                <div className="d-flex justify-content-end mb-3">
                                    <button
                                        className="btn srp-btn btn-lightblue"
                                        disabled={submitBudgetBillingStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS}
                                        onClick={()=>createAndSubmitBudgetBillingForm()}>
                                        {submitBudgetBillingStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS
                                            ? <CircularProgress size={20} thickness={5} />
                                            : t("Sign up")
                                        }
                                    </button>
                                </div>
                                <p style={{color: '#707070', fontSize: '0.75rem'}}>
                                    {/* *This is an estimated value based on many factors. In addition to the 12 months of previous power usage, other variables can influence
                                    this value such as the time of year you enroll and any pricing changes. For more information about your Budget Billing Payment Estimate please call
                                    {isCommercial ? " " + myAccountConstants.COMMERCIAL_CUSTOMER_SERVICE_PHONE_NUMBER_FMTTD  : " " + myAccountConstants.RESIDENTIAL_CUSTOMER_SERVICE_PHONE_NUMBER_FMTTD}. */}
                                    {t("Estimated_value_notice", 
                                        {phoneNumber : isCommercial 
                                            ? " " + myAccountConstants.COMMERCIAL_CUSTOMER_SERVICE_PHONE_NUMBER_FMTTD  
                                            : " " + myAccountConstants.RESIDENTIAL_CUSTOMER_SERVICE_PHONE_NUMBER_FMTTD})
                                    }
                                </p>
                            </div>
                            : <div className="d-flex justify-content-end mb-3">
                                <Link to="/budgetbilling" className="btn srp-btn btn-lightblue">{t("Sign up")}</Link>
                            </div>
                        }
                    </div>
                </div>
                :<span/>
            }
        </div>
    )
}

CompareBillCard.propTypes = {
    accountHistoryList: PropTypes.arrayOf(PropTypes.object),

    billChipInfo: PropTypes.arrayOf(PropTypes.object),
    billToCompare: PropTypes.number,
    addBillToCompareOnClick: PropTypes.func,
    removeBillFromCompareOnClick: PropTypes.func,

    budgetBillingEstimate: PropTypes.number,
    submitBudgetBillingStatus: PropTypes.string,
    createAndSubmitBudgetBillingForm: PropTypes.func,

    isCommercial: PropTypes.bool,
    isBudgetBillingEligible: PropTypes.bool,
    hasEmail: PropTypes.bool,
    t: PropTypes.func.isRequired
}

export default CompareBillCard

function getBillDates(accountHistory, billChipInfo) {
    let items = [];
    for(let i = 0; i < accountHistory.length; i++)
    {
        if(billChipInfo.indexOf(accountHistory[i]) === -1) {
            items.push(<MenuItem
                key={"compareBillDate"+(i+1)}
                value={i}
                >{DateTime.fromISO(accountHistory[i].billGeneratedDate).toFormat('MMMM d, yyyy')}</MenuItem> )
        }
    }
    return items
}

function getChartTitle(currentBill, pastBill, t) {
    if((currentBill.revenueMonth === pastBill.revenueMonth) && (currentBill.revenueYear-1 === pastBill.revenueYear)) {
        return (
            <div>
                <div className="d-lg-block d-none">
                    <div>{DateTime.fromISO(pastBill.billGeneratedDate).toFormat('MMMM d, yyyy')}</div>
                    <div style={compareBillTitleStyle}>{t("last year")}</div>
                </div>
                <div className="d-lg-none d-block d-flex justify-content-between">
                    <div>{DateTime.fromISO(pastBill.billGeneratedDate).toFormat('MMMM d, yyyy')}</div>
                    <div style={{color:'#707070'}}>{t("last year")}</div>
                </div>
            </div>
        )
    }
    else if((currentBill.revenueMonth-1 === pastBill.revenueMonth && currentBill.revenueYear === pastBill.revenueYear)
            || (currentBill.revenueMonth === 1 && pastBill.revenueMonth === 12 && currentBill.revenueYear-1 === pastBill.revenueYear)) {
            return (
                <div>
                    <div className="d-lg-block d-none">
                        <div>{DateTime.fromISO(pastBill.billGeneratedDate).toFormat('MMMM d, yyyy')}</div>
                        <div style={compareBillTitleStyle}>{t("last month")}</div>
                    </div>
                    <div className="d-lg-none d-block d-flex justify-content-between">
                        <div>{DateTime.fromISO(pastBill.billGeneratedDate).toFormat('MMMM d, yyyy')}</div>
                        <div style={{color:'#707070'}}>{t("last month")}</div>
                    </div>
                </div>
            )
    }
    else {
        return (<div>{DateTime.fromISO(pastBill.billGeneratedDate).toFormat('MMMM d, yyyy')}</div>)
    }
}

function calculateBarSize(accountHistory, bill) {
    let isCurrentBill = accountHistory[0].billGeneratedDate === bill.billGeneratedDate
    let usage = []
    for(let i=0; i < accountHistory.length; i++) {
        usage = usage.concat(accountHistory[i].totalUsageCharges)
    }

    let maxUsage = Math.max(...usage)
    let barWidth = (bill.totalUsageCharges/maxUsage)*100

    const barStyle = {
        backgroundColor: isCurrentBill ? 'rgb(25, 93, 147)' : 'rgba(127, 165, 195, 1)',
        color: '#FFFFFF',
        fontSize: '13px',
        fontWeight: '700',
        height: '36px',
        borderRadius: '2px',
        width: barWidth+"%",
        padding: '4px',
        verticalAlign: 'middle'
    }

    return (
        <div style={barStyle}/>
    )
}