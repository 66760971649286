import React from 'react'
import { withTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { Formik } from 'formik'
import * as Yup from 'yup'

import SinglePurchaseForm from './single-purchase-form'

class SinglePurchaseFormikPure extends React.Component {
    constructor (props) {
        super(props)

        this.state = {
            initialBankFormOpen: props.bankFormOpen
        }

        if (props.bankAccountList.length===0 && props.bankFormOpen===false) {
            props.setBankFormOpen()
            this.state.initialBankFormOpen = true
        }

        this.addBankAccountButtonClicked = this.addBankAccountButtonClicked.bind(this)
        this.addBankCancelButtonClicked = this.addBankCancelButtonClicked.bind(this)

        this.validationSchema = Yup.object().shape({
            srpAccountNumber: Yup.string().ensure()
                .transform(function(srpAccountNumber) {
                    return this.isType(srpAccountNumber) && srpAccountNumber !== null ? srpAccountNumber.replace(/[- ]/g, '') : srpAccountNumber
                }),
            bankFormOpen: Yup.boolean(),
            bankAccountIdSelected: Yup.number()
                .when('bankFormOpen', {
                    is: false,
                    then: (schema) => schema
                        .moreThan(0, "Invalid bank account ID.")
                }),
            bankFirstName: Yup.string()
                .when('bankFormOpen', {
                    is: true,
                    then: (schema) => schema.ensure().trim()
                        .required("First name is required.")
                }),
            bankLastName: Yup.string()
                .when('bankFormOpen', {
                    is: true,
                    then: (schema) => schema.ensure().trim()
                        .required("Last name is required.")
                        .test("not all spaces", "Last name is required.", bankLastName => typeof bankLastName === "string" && bankLastName.trim().length > 0)
                        .test('at least 2 characters', "Last name must have at least two characters.", bankLastName => typeof bankLastName === "string" && bankLastName.trim().length > 1)
                }),
            bankPhoneNumber: Yup.string()
                .when('bankFormOpen', {
                    is: true,
                    then: (schema) => schema.ensure().trim()
                        .transform(function(bankPhoneNumber) {
                            return this.isType(bankPhoneNumber) && bankPhoneNumber !== null ? bankPhoneNumber.replace(/[() -]/g, '') : bankPhoneNumber
                        })
                        .required("Phone number is required.")
                        .test('must have 10 digits', "Phone number must have 10 digits.", bankPhoneNumber => /^\d{10}$/.test(bankPhoneNumber))
                }),
            bankEmailAddress: Yup.string()
                .when('bankFormOpen', {
                    is: true,
                    then: (schema) => schema.ensure().trim()
                        .required("Email address is required.")
                        .email("Invalid email address.")
                }),
            bankRoutingNumber: Yup.string()
                .when('bankFormOpen', {
                    is: true,
                    then: (schema) => schema.ensure().trim()
                        .required("Routing number is required.")
                        .test('must have 9 digits', "Enter a valid 9-digit routing number.", bankRoutingNumber => /^\d{9}$/.test(bankRoutingNumber))
                }),
            bankAccountNumberLeadingZeroesAreSignificant: Yup.string()
                .when('bankFormOpen', {
                    is: true,
                    then: (schema) => schema.ensure().trim()
                        .required("Bank account number is required.")
                        .test('17 digits max', "Your account number must be 1-17 digits.", bankAccountNumber => /^\d{1,17}$/.test(bankAccountNumber))
                }),
            purchaseAmount: Yup.string().ensure().trim()
                .required("A payment amount is required.")
                .test('payment amount is valid', "Invalid payment amount.", purchaseAmount => /^(\$?\d+(,\d{3})*\.?[0-9]?[0-9]?|\$?\.\d{1,2})$/.test(purchaseAmount)) // regex from https://stackoverflow.com/questions/8829765/regular-expression-for-dollar-amount-in-javascript
                .test('payment amount is a penny or more', "Invalid payment amount.", purchaseAmount => parseFloat(purchaseAmount.replace(/[$,]/g, '')) >= 0.01)
        })
    }

    addBankAccountButtonClicked(setFieldValue) {
        this.props.setBankFormOpen()
        setFieldValue('bankFormOpen', true)
    }
    addBankCancelButtonClicked(setFieldValue) {
        this.props.clearBankFormOpen()
        setFieldValue('bankFormOpen', false)
    }

    transformValues (values) {
        return {
            ...values,
            srpAccountNumber: values.srpAccountNumber.replace(/[- ]/g, ''),
            bankAccountIdSelected: values.bankAccountIdSelected,
            bankFirstName: values.bankFirstName.trim(),
            bankLastName: values.bankLastName.trim(),
            bankPhoneNumber: values.bankPhoneNumber.replace(/[() -]/g, ''),
            bankEmailAddress: values.bankEmailAddress.trim(),
            bankRoutingNumber: values.bankRoutingNumber.trim(),
            bankAccountNumberLeadingZeroesAreSignificant: values.bankAccountNumberLeadingZeroesAreSignificant.trim(),
            bankNickname: values.bankNickname.trim(),
            purchaseAmount: parseFloat(values.purchaseAmount.trim().replace(/[$,]/g, ''))
        }
    }

    render () {
        let bankAccountIdSelected = this.props.selectedBankId
        if (bankAccountIdSelected === 0 && this.props.bankAccountList.length > 0) {
            const defaultBankAccount = this.props.bankAccountList.find((bankAccount) => bankAccount.isDefault === true)
            bankAccountIdSelected = (defaultBankAccount !== undefined) ?
                defaultBankAccount.bankId :
                this.props.bankAccountList[0].bankId
        }
    
        return (
            <Formik
                initialValues={{
                    srpAccountNumber: this.props.purchaseList.length > 0 ? this.props.purchaseList[0].srpAccountNumber : this.props.selectedBillAccount.toString(),
                    bankFormOpen: this.state.initialBankFormOpen,
                    bankAccountIdSelected: bankAccountIdSelected,
                    bankFirstName: this.props.bankFirstName,
                    bankLastName: this.props.bankLastName,
                    bankPhoneNumber: this.props.bankPhoneNumber,
                    bankEmailAddress: this.props.bankEmailAddress,
                    bankRoutingNumber: this.props.bankRoutingNumber,
                    bankAccountNumberLeadingZeroesAreSignificant: this.props.bankAccountNumberLeadingZeroesAreSignificant,
                    bankNickname: this.props.bankNickname,
                    purchaseAmount: this.props.purchaseList.length > 0 ? String(this.props.purchaseList[0].purchaseAmount) : '',
                    payAmountOwedToSRP: this.props.purchaseList.length > 0 ? this.props.purchaseList[0].payAmountOwedToSRP : false
                }}
                validationSchema={this.validationSchema}
                onSubmit={async ( purchaseAndBankValues, goodies ) => {
                    const _purchaseAndBankValues = this.transformValues(purchaseAndBankValues)
                    this.props.storeBankInfoAndPurchase(_purchaseAndBankValues)
                    if (_purchaseAndBankValues.bankFormOpen) {
                        const bankAdded = await this.props.addBank(goodies, this.validationSchema, _purchaseAndBankValues)
                        if (bankAdded === false)
                            return
                    }
                    this.props.validatePurchase(goodies, this.validationSchema, _purchaseAndBankValues, this.props.t, this.props.i18n)
                }}
            >
                    {formikProps => (<SinglePurchaseForm {...formikProps} {...this.props} {...this.state}
                        addBankAccountButtonClicked={this.addBankAccountButtonClicked}
                        addBankCancelButtonClicked={this.addBankCancelButtonClicked} />)}
            </Formik>
        )
    }
}

SinglePurchaseFormikPure.propTypes = {
    selectedBillAccount: PropTypes.number.isRequired,
    purchaseList: PropTypes.arrayOf(PropTypes.shape({
        srpAccountNumber: PropTypes.string.isRequired,
        purchaseAmount: PropTypes.number.isRequired,
        payAmountOwedToSRP: PropTypes.bool.isRequired
    })),
    bankAccountList: PropTypes.arrayOf(PropTypes.shape({
        bankId: PropTypes.number.isRequired,
        bankName: PropTypes.string.isRequired,
        nickName: PropTypes.string.isRequired,
        accountNumber: PropTypes.string.isRequired
    })).isRequired,
    selectedBankId: PropTypes.number.isRequired,
    bankFirstName: PropTypes.string.isRequired,
    bankLastName: PropTypes.string.isRequired,
    bankPhoneNumber: PropTypes.string.isRequired,
    bankEmailAddress: PropTypes.string.isRequired,
    bankRoutingNumber: PropTypes.string.isRequired,
    bankAccountNumberLeadingZeroesAreSignificant: PropTypes.string.isRequired,
    bankNickname: PropTypes.string.isRequired,

    addBank: PropTypes.func.isRequired,
    validatePurchase: PropTypes.func.isRequired,
    storeBankInfoAndPurchase: PropTypes.func.isRequired,

    bankFormOpen: PropTypes.bool.isRequired,
    setBankFormOpen: PropTypes.func.isRequired,
    clearBankFormOpen: PropTypes.func.isRequired,

    formStartEventEmitted: PropTypes.bool.isRequired,
    setFormStartEventEmitted: PropTypes.func.isRequired,

    payDownFraction: PropTypes.number.isRequired,
    amountOwedToSRP: PropTypes.number.isRequired,

    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired
}

const SinglePurchaseFormik = withTranslation("singlePurchasePage")(SinglePurchaseFormikPure)
export { SinglePurchaseFormik as default, SinglePurchaseFormikPure }