import { autopayConstants } from '../../../constants/autopay-constants'
import { myAccountConstants } from '../../../constants/myaccount-constants'
import { RSAA } from 'redux-api-middleware'
import utils from '../../../srp_modules/utils'
import { DateTime } from 'luxon'

export const autopayFormDataCancel = () => ({
    type: autopayConstants.AUTOPAY_FORM_DATA_CANCEL
})

export const autopayFormDataChange = (autopayFormData) => ({
    type: autopayConstants.AUTOPAY_FORM_DATA_CHANGE,
    autopayFormData
})

export const ignorePendingRelease = () => ({
  type: autopayConstants.IGNORE_PENDING_RELEASE
})

export const cancelAutopay = (autopayInfo) => {
    let newAutopayInfo = {
        ...autopayInfo,
        shareAmount: Math.trunc(autopayInfo.shareAmount || 0), // make sure share amount is an integer
        effectiveDate: DateTime.now().setZone('MST')
    }

    let jsonBody = JSON.stringify(newAutopayInfo)
    return (dispatch) => {
        return dispatch({
            [RSAA]: {
                endpoint: utils.createUrl('autopay/cancel'),
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                credentials: 'include',
                body: jsonBody,
                types: [
                    autopayConstants.CANCEL_AUTOPAY_REQUEST,
                    {
                        type: autopayConstants.CANCEL_AUTOPAY_SUCCESS,
                        payload: (action, state, res) => {
                                return res.json().then(json => {
                                    let returnJson = {}
                                    if(json === true) {
                                        returnJson.cancelAutopayResponse = {
                                            isSuccess: true,
                                            dateSubmitted: DateTime.now().setZone('MST')
                                        }
                                    }
                                    else
                                    {
                                        returnJson.cancelAutopayResponse = {
                                            isSuccess: false,
                                            dateSubmitted: DateTime.fromISO("0001-01-01T00:00:00")
                                        }
                                    }

                                    return returnJson
                                })
                        }
                    },
                    autopayConstants.CANCEL_AUTOPAY_FAILURE
                ]
            }
        })
    }
}

export const showCancelAutopayModule = (autopayToCancel) => ({
    type: autopayConstants.SHOW_CACNEL_AUTOPAY_MODULE,
    autopayToCancel
})

export const closeCancelAutopayModule = () => ({
    type: autopayConstants.CLOSE_CACNEL_AUTOPAY_MODULE
})

export const enrollAutopay = (autopayInfo, autopayFormData, bankData) => {
    return (dispatch) => {

        let custInfo = {
            ...autopayInfo.customerInfo
        }

        let enrollAutopayInfo = {
            ...autopayInfo,
            billAccount: autopayFormData.billAccount,
            isResidential: autopayFormData.isResidential,
            phoneNumber: autopayFormData.phoneNumber,
            emailAddress: autopayFormData.emailAddress,
            shareAmount: Math.trunc(autopayFormData.shareAmount || 0), // make sure share amount is an integer
            bankAccount: {
                nameOnBankAccount: autopayFormData.firstName + ' ' + autopayFormData.lastName,
                routingNumber: autopayFormData.routingNumber,
                bankAccountNumber: autopayFormData.bankAccountNumber,
                institutionName: bankData.institutionName,
                bankAccountType: autopayConstants.BANK_ACCOUNT_CHECKING,
            },
            customerInfo: {
                ...custInfo
            }
        }

        let jsonBody = JSON.stringify(enrollAutopayInfo)

        return dispatch({
            [RSAA]: {
                endpoint: utils.createUrl('autopay/enroll'),
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                credentials: 'include',
                body: jsonBody,
                types: [
                    autopayConstants.ENROLL_AUTOPAY_REQUEST,
                    autopayConstants.ENROLL_AUTOPAY_SUCCESS,
                    autopayConstants.ENROLL_AUTOPAY_FAILURE
                ]
            }
        })
    }
}

export const getAutopayInfo = (billAccount) => {
    let url = utils.createUrl('autopay/getautopayinfo', { billAccount: billAccount})

    return dispatch => {
        return dispatch({
            [RSAA]: {
                endpoint: url,
                method: 'GET',
                credentials: 'include',
                types: [
                    autopayConstants.GET_AUTOPAY_INFO_REQUEST,
                    autopayConstants.GET_AUTOPAY_INFO_SUCCESS,
                    autopayConstants.GET_AUTOPAY_INFO_FAILURE
                ]
            }
        })
    }
}

export const getAutopayEligible = (billAccount) => {

    let url = utils.createUrl('autopay/getiseligible', { billAccount: billAccount})

    return dispatch => {
        return dispatch({
            [RSAA]: {
                endpoint: url,
                method: 'GET',
                credentials: 'include',
                types: [
                    autopayConstants.AUTOPAY_ELIGIBLE_REQUEST,
                    autopayConstants.AUTOPAY_ELIGIBLE_SUCCESS,
                    autopayConstants.AUTOPAY_ELIGIBLE_FAILURE
                ]
            }
        })
    }
}

export const getAutopayEnrolled = (billAccount) => {
    let url = utils.createUrl('autopay/getisenrolled', { billAccount: billAccount})

    return dispatch => {
        return dispatch({
            [RSAA]: {
                endpoint: url,
                method: 'GET',
                credentials: 'include',
                types: [
                    autopayConstants.AUTOPAY_ENROLLED_REQUEST,
                    autopayConstants.AUTOPAY_ENROLLED_SUCCESS,
                    autopayConstants.AUTOPAY_ENROLLED_FAILURE
                ]
            }
        })
    }
}

export const getAutopayInfoList = () => {
    let url = utils.createUrl('autopay/getautopayinfolist')

    return dispatch => {
        return dispatch({
            [RSAA]: {
                endpoint: url,
                method: 'GET',
                credentials: 'include',
                types: [
                    autopayConstants.GET_AUTOPAY_INFO_LIST_REQUEST,
                    autopayConstants.GET_AUTOPAY_INFO_LIST_SUCCESS,
                    autopayConstants.GET_AUTOPAY_INFO_LIST_FAILURE
                ]
            }
        })
    }
}

export const validateAutopayBankAccount = (autopayFormData) => {
    return (dispatch) => {
        let bankInfo = {
            billAccount: autopayFormData.billAccount,
            bankAccountType: autopayFormData.bankAccountType,
            routingNumber: autopayFormData.routingNumber,
            bankAccountNumber: autopayFormData.bankAccountNumber,
            firstName: autopayFormData.firstName,
            lastName: autopayFormData.lastName,
            transactionSource: myAccountConstants.TRANSACTION_SOURCE.WEB,
            requestor: ""
        }

        let jsonBody = JSON.stringify(bankInfo)

        return dispatch({
            [RSAA]: {
                endpoint: utils.createUrl('autopay/validatebankaccount'),
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                credentials: 'include',
                body: jsonBody,
                types: [
                    autopayConstants.VALIDATE_AUTOPAY_BANK_REQUEST,
                    autopayConstants.VALIDATE_AUTOPAY_BANK_SUCCESS,
                    autopayConstants.VALIDATE_AUTOPAY_BANK_FAILURE
                ]
            }
        })
    }
}

export const updateAutopay = (autopayInfo, autopayFormData, bankData) => {
    return (dispatch) => {

        let custInfo = {
            ...autopayInfo.customerInfo
        }

        let updateAutopayInfo = {
            ...autopayInfo,
            billAccount: autopayFormData.billAccount,
            isResidential: autopayFormData.isResidential,
            phoneNumber: autopayFormData.phoneNumber,
            emailAddress: autopayFormData.emailAddress,
            shareAmount: Math.trunc(autopayInfo.shareAmount || 0), // make sure share amount is an integer
            bankAccount: {
                nameOnBankAccount: autopayFormData.firstName + ' ' + autopayFormData.lastName,
                routingNumber: autopayFormData.routingNumber,
                bankAccountNumber: autopayFormData.bankAccountNumber,
                institutionName: bankData.institutionName,
                bankAccountType: autopayConstants.BANK_ACCOUNT_CHECKING,
            },
            customerInfo: {
                ...custInfo
            }
        }

        let jsonBody = JSON.stringify(updateAutopayInfo)

        return dispatch({
            [RSAA]: {
                endpoint: utils.createUrl('autopay/update'),
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                credentials: 'include',
                body: jsonBody,
                types: [
                    autopayConstants.UPDATE_AUTOPAY_REQUEST,
                    autopayConstants.UPDATE_AUTOPAY_SUCCESS,
                    autopayConstants.UPDATE_AUTOPAY_FAILURE
                ]
            }
        })
    }
}

export const getPrevAutopay = () => ({
    type: autopayConstants.GET_PREV_AUTOPAY
})

export const getNextAutopay = (lengthOfList) => ({
    type: autopayConstants.GET_NEXT_AUTOPAY,
    lengthOfList
})
