import { billAccountConstants } from '../../../constants/bill-account-constants'
import { notificationConstants } from '../../../constants/notification-constants'
import { myAccountConstants } from '../../../constants/myaccount-constants'
import * as loadingStatus from '../../../constants/loading-status-constants'
import { paperlessBillingState } from '../../../constants/paperless-billing-state-enum'

import alertsModule from '../../../srp_modules/alerts-module'

const billingNotificationType = 1

const initialState = {
    notifications: {
        available: [],
        subscribed: []
    },
    notificationsStatus: loadingStatus.LOADING_STATUS_INIT,
    eBillState: false,
    eBillStateStatus: loadingStatus.LOADING_STATUS_INIT,
    paperlessBillingState: paperlessBillingState.UNKNOWN,

    outageAlertState: false,
    outageAlertStateStatus: loadingStatus.LOADING_STATUS_INIT,

    //profile & settings - contacts and alerts
    selectedAlertsTab: 0,

    allAlerts: {
        billingAlert: {
            isAvailable: false,
            subscribedToEmail: false,
            subscribedToText: false,
            email: [],
            phone: [],
            notificationInfo: {}
        },
        disconnectAlert: {
            isAvailable: false,
            subscribedToEmail: false,
            subscribedToText: false,
            email: [],
            phone: [],
            notificationInfo: {}
        },
        billProjectionAlert: {
            isAvailable: false,
            subscribedToEmail: false,
            subscribedToText: false,
            email: [],
            phone: [],
            notificationInfo: {}
        },        
        monthlyProjectionAlert: {
            isAvailable: false,
            subscribedToEmail: false,
            subscribedToText: false,
            email: [],
            phone: [],
            notificationInfo: {}
        },
        timeOfDayAlert: {
            isAvailable: false,
            subscribedToEmail: false,
            subscribedToText: false,
            email: [],
            phone: [],
            notificationInfo: {}
        },
        poolTimerAlert: {
            isAvailable: false,
            subscribedToEmail: false,
            subscribedToText: false,
            email: [],
            phone: [],
            notificationInfo: {}
        },
        outageAlert: {
            isAvailable: false,
            subscribedToEmail: false,
            subscribedToText: false,
            email: [],
            phone: [],
            notificationInfo: {}
        },
        mPowerPurchaseAlert: {
            isAvailable: false,
            subscribedToEmail: false,
            subscribedToText: false,
            email: [],
            phone: [],
            notificationInfo: {}
        },
        heatMoratoriumAlert: {
            isAvailable: false,
            subscribedToEmail: false,
            subscribedToText: false,
            email: [],
            phone: [],
            notificationInfo: {}
        },
        heatMoratoriumAccumulatedDebtAlert: {
            isAvailable: false,
            subscribedToEmail: false,
            subscribedToText: false,
            email: [],
            phone: [],
            notificationInfo: {}
        },
        landlordAlert: {
            isAvailable: false,
            subscribedToEmail: false,
            subscribedToText: false,
            email: [],
            phone: [],
            notificationInfo: {}
        },
        highUsageAlert: {
            isAvailable: false,
            subscribedToEmail: false,
            subscribedToText: false,
            email: [],
            phone: [],
            notificationInfo: {}
        }
    },

    refreshNotificationStatus: loadingStatus.LOADING_STATUS_INIT,

    addNotificationStatus: loadingStatus.LOADING_STATUS_INIT,
    addNotificationError: false,

    updateNotificationStatus: loadingStatus.LOADING_STATUS_INIT,
    updateNotificationError: false,

    deleteNotificationStatus: loadingStatus.LOADING_STATUS_INIT,
    deleteNotificationError: false,

    copySubscriptionsToNewContactStatus: loadingStatus.LOADING_STATUS_INIT,
    copySubscriptionsToNewContactResult: myAccountConstants.CUSTOMER_ACCOUNT_DATA_STATUS.UNKNOWN
}

function getEbillState(notifications) {
    let eBillState = false
    notifications.subscribed.forEach(function (notification) {
        if (notification.notificationType === billingNotificationType && notification.hasEbill === true) {
            eBillState = true
        }
    }, this)

    return eBillState
}

function getOutageAlertState(notifications) {
    let outageAlertState = false
    notifications.subscribed.forEach(function (notification) {
        if (notification.notificationType === notificationConstants.NOTIFICATION_TYPE.OUTAGE) {
            outageAlertState = true
        }
    }, this)

    return outageAlertState
}

export default function notificationReducer(state = initialState, action) {
    switch (action.type) {
        case notificationConstants.GET_NOTIFICATIONS_REQUEST:
            return { ...state, notificationsStatus: loadingStatus.LOADING_STATUS_IN_PROGRESS }
        case notificationConstants.GET_NOTIFICATIONS_SUCCESS: {
            const eBillState = getEbillState(action.payload)
            const outageAlertState = getOutageAlertState(action.payload)
            return {
                ...state,
                notifications: action.payload,
                allAlerts: getAllAlerts(action.payload, state.allAlerts),
                eBillState,
                outageAlertState,
                paperlessBillingState: eBillState===true ? paperlessBillingState.ALREADY_ENROLLED : paperlessBillingState.NOT_ENROLLED,
                notificationsStatus: loadingStatus.LOADING_STATUS_SUCCESS,
                eBillStateStatus: loadingStatus.LOADING_STATUS_SUCCESS,
                outageAlertStateStatus: loadingStatus.LOADING_STATUS_SUCCESS
            }
        }
        case notificationConstants.GET_NOTIFICATIONS_FAILURE:
            return {
                ...state,
                notifications: initialState.notifications,
                allAlerts: initialState.allAlerts,
                eBillState: initialState.eBillState,
                notificationsStatus: loadingStatus.LOADING_STATUS_FAILURE,
                eBillStateStatus: loadingStatus.LOADING_STATUS_FAILURE,
                outageAlertStateStatus: loadingStatus.LOADING_STATUS_FAILURE
            }
        
        case notificationConstants.REFRESH_NOTIFICATIONS_REQUEST:
            return { ...state, refreshNotificationStatus: loadingStatus.LOADING_STATUS_IN_PROGRESS }
        case notificationConstants.REFRESH_NOTIFICATIONS_SUCCESS: {
            const eBillState = getEbillState(action.payload)
            const outageAlertState = getOutageAlertState(action.payload)

            let newPaperlessBillingState = paperlessBillingState.NOT_ENROLLED
            if (eBillState === true) {
                // Keep paperless billing state in JUST_ENROLLED for single payment confirmation page.
                // If JUST_ENROLLED state is changed to ALREADY_ENROLLED, the You're Set banner
                // and dialog will show and then quickly disappear after the user enrolls in eBill
                // from the confirmation page.
                newPaperlessBillingState = state.paperlessBillingState === paperlessBillingState.JUST_ENROLLED
                    ? paperlessBillingState.JUST_ENROLLED
                    : paperlessBillingState.ALREADY_ENROLLED
            }

            return {
                ...state,
                notifications: action.payload,
                allAlerts: getAllAlerts(action.payload, state.allAlerts),
                eBillState,
                outageAlertState,
                paperlessBillingState: newPaperlessBillingState,
                refreshNotificationStatus: loadingStatus.LOADING_STATUS_SUCCESS,
                eBillStateStatus: loadingStatus.LOADING_STATUS_SUCCESS,
                outageAlertStateStatus: loadingStatus.LOADING_STATUS_SUCCESS
            }
        }

        case notificationConstants.PAPERLESS_BILLLING_NOT_ENROLLED:
            return {
                ...state,
                paperlessBillingState: paperlessBillingState.NOT_ENROLLED,
            }

        case notificationConstants.PAPERLESS_BILLLING_ENROLLING:
            return {
                ...state,
                paperlessBillingState: paperlessBillingState.ENROLLING,
            }

        case notificationConstants.PAPERLESS_BILLLING_JUST_ENROLLED:
            return {
                ...state,
                paperlessBillingState: paperlessBillingState.JUST_ENROLLED,
            }

        case notificationConstants.REFRESH_NOTIFICATIONS_FAILURE:
            return {
                ...state,
                notifications: initialState.notifications,
                allAlerts: initialState.allAlerts,
                eBillState: initialState.eBillState,
                refreshNotificationStatus: loadingStatus.LOADING_STATUS_FAILURE,
                notificationsStatus: loadingStatus.LOADING_STATUS_FAILURE,
                eBillStateStatus: loadingStatus.LOADING_STATUS_FAILURE
            }
        
        case notificationConstants.ADD_NOTIFICATION_REQUEST:
            return { ...state, addNotificationStatus: loadingStatus.LOADING_STATUS_IN_PROGRESS }
        case notificationConstants.ADD_NOTIFICATION_SUCCESS:
            return (action.payload !== myAccountConstants.CUSTOMER_ACCOUNT_DATA_STATUS.SUCCESS) 
                ? { ...state, addNotificationStatus: loadingStatus.LOADING_STATUS_FAILURE, addNotificationError: true }
                : { ...state, addNotificationStatus: loadingStatus.LOADING_STATUS_SUCCESS, addNotificationError: false }
        case notificationConstants.ADD_NOTIFICATION_FAILURE:
            return { ...state, addNotificationStatus: loadingStatus.LOADING_STATUS_FAILURE, addNotificationError: true }
        
        case notificationConstants.UPDATE_NOTIFICATION_REQUEST:
            return { ...state, updateNotificationStatus: loadingStatus.LOADING_STATUS_IN_PROGRESS }
        case notificationConstants.UPDATE_NOTIFICATION_SUCCESS:
            return (action.payload !== myAccountConstants.CUSTOMER_ACCOUNT_DATA_STATUS.SUCCESS) 
                ? { ...state, updateNotificationStatus: loadingStatus.LOADING_STATUS_FAILURE, updateNotificationError: true }
                : { ...state, updateNotificationStatus: loadingStatus.LOADING_STATUS_SUCCESS, updateNotificationError: false }
        case notificationConstants.UPDATE_NOTIFICATION_FAILURE:
            return { ...state, updateNotificationStatus: loadingStatus.LOADING_STATUS_FAILURE, updateNotificationError: true }

        case notificationConstants.DELETE_NOTIFICATION_REQUEST:
            return { ...state, deleteNotificationStatus: loadingStatus.LOADING_STATUS_IN_PROGRESS }
        case notificationConstants.DELETE_NOTIFICATION_SUCCESS:
            return (action.payload !== myAccountConstants.CUSTOMER_ACCOUNT_DATA_STATUS.SUCCESS) 
                ? { ...state, deleteNotificationStatus: loadingStatus.LOADING_STATUS_SUCCESS, deleteNotificationError: true }
                : { ...state, deleteNotificationStatus: loadingStatus.LOADING_STATUS_SUCCESS, deleteNotificationError: false }
        case notificationConstants.DELETE_NOTIFICATION_FAILURE:
            return { ...state, deleteNotificationStatus: loadingStatus.LOADING_STATUS_FAILURE, deleteNotificationError: true }

        case notificationConstants.TOGGLE_EBILL_REQUEST:
            return { ...state, eBillStateStatus: loadingStatus.LOADING_STATUS_IN_PROGRESS }
        case notificationConstants.TOGGLE_EBILL_SUCCESS:
            return { ...state, eBillState: !state.eBillState, eBillStateStatus: loadingStatus.LOADING_STATUS_SUCCESS }
        case notificationConstants.TOGGLE_EBILL_FAILURE:
            return { ...state, eBillStateStatus: loadingStatus.LOADING_STATUS_FAILURE }

        case notificationConstants.TOGGLE_OUTAGE_REQUEST:
            return { ...state, outageAlertStateStatus: loadingStatus.LOADING_STATUS_IN_PROGRESS }
        case notificationConstants.TOGGLE_OUTAGE_SUCCESS:
            return { ...state, outageAlertState: !state.outageAlertState, outageAlertStateStatus: loadingStatus.LOADING_STATUS_SUCCESS}
        case notificationConstants.TOGGLE_OUTAGE_FAILURE:
            return{ ...state, outageAlertStateStatus: loadingStatus.LOADING_STATUS_FAILURE }

        case notificationConstants.COPY_SUBSCRIPTIONS_TO_NEW_CONTACT_REQUEST:
            return { ...state, copySubscriptionsToNewContactStatus: loadingStatus.LOADING_STATUS_IN_PROGRESS }
        case notificationConstants.COPY_SUBSCRIPTIONS_TO_NEW_CONTACT_SUCCESS:
            return { ...state, copySubscriptionsToNewContactStatus: loadingStatus.LOADING_STATUS_SUCCESS, copySubscriptionsToNewContactResult: action.payload}
        case notificationConstants.COPY_SUBSCRIPTIONS_TO_NEW_CONTACT_FAILURE:
            return  { ...state,
                copySubscriptionsToNewContactStatus: loadingStatus.LOADING_STATUS_FAILURE,
                copySubscriptionsToNewContactResult: initialState.copySubscriptionsToNewContactResult}

        case notificationConstants.GET_ALERTS_TAB:
            return { ...state, selectedAlertsTab: action.tab }
        
        case billAccountConstants.SELECT_BILL_ACCOUNT_SUCCESS:
            return { ...initialState }
        default:
            return state
    }
}

function getAllAlerts(availableAlerts, allAlertsInfo) {
    let combinedAlerts = (availableAlerts.available).concat(availableAlerts.subscribed)
    let newAlerts = allAlertsInfo
    for (let i = 0; i < combinedAlerts.length; i++) {
        let phoneContacts = alertsModule.getPhoneContactList(combinedAlerts[i].contacts)
        let emailContacts = alertsModule.getEmailContactList(combinedAlerts[i].contacts)
        switch (combinedAlerts[i].notificationType) {
            case notificationConstants.NOTIFICATION_TYPE.BILLING_PAYMENT: {
                if (combinedAlerts[i].contacts.length !== 0) {
                    newAlerts = {
                        ...newAlerts,
                        billingAlert: {
                            isAvailable: true,
                            subscribedToEmail: emailContacts.length !== 0,
                            subscribedToText: phoneContacts.length !== 0,
                            email: emailContacts,
                            phone: phoneContacts,
                            notificationInfo: combinedAlerts[i]
                        }
                    }
                }
                else {
                    newAlerts = {
                        ...newAlerts,
                        billingAlert: {
                            isAvailable: true,
                            subscribedToEmail: false,
                            subscribedToText: false,
                            email: [],
                            phone: [],
                            notificationInfo: combinedAlerts[i]
                        }
                    }
                }
                break
            }
            case notificationConstants.NOTIFICATION_TYPE.DISCONNECT: {
                if (combinedAlerts[i].contacts.length !== 0) {
                    newAlerts = {
                        ...newAlerts,
                        disconnectAlert: {
                            isAvailable: true,
                            subscribedToEmail: emailContacts.length !== 0,
                            subscribedToText: phoneContacts.length !== 0,
                            email: emailContacts,
                            phone: phoneContacts,
                            notificationInfo: combinedAlerts[i]
                        }
                    }
                }
                else {
                    newAlerts = {
                        ...newAlerts,
                        disconnectAlert: {
                            isAvailable: true,
                            subscribedToEmail: false,
                            subscribedToText: false,
                            email: [],
                            phone: [],
                            notificationInfo: combinedAlerts[i]
                        }
                    }
                }
                break
            }
            case notificationConstants.NOTIFICATION_TYPE.BILL_PROJECTION: {
                if (combinedAlerts[i].contacts.length !== 0) {
                    newAlerts = {
                        ...newAlerts,
                        billProjectionAlert: {
                            isAvailable: true,
                            subscribedToEmail: emailContacts.length !== 0,
                            subscribedToText: phoneContacts.length !== 0,
                            email: emailContacts,
                            phone: phoneContacts,
                            notificationInfo: combinedAlerts[i]
                        }
                    }
                }
                else {
                    newAlerts = {
                        ...newAlerts,
                        billProjectionAlert: {
                            isAvailable: true,
                            subscribedToEmail: false,
                            subscribedToText: false,
                            email: [],
                            phone: [],
                            notificationInfo: combinedAlerts[i]
                        }
                    }
                }
                break
            }
            case notificationConstants.NOTIFICATION_TYPE.MONTHLY_BILL_THRESHOLD: {
                if (combinedAlerts[i].contacts.length !== 0) {
                    newAlerts = {
                        ...newAlerts,
                        monthlyProjectionAlert: {
                            isAvailable: true,
                            subscribedToEmail: emailContacts.length !== 0,
                            subscribedToText: phoneContacts.length !== 0,
                            email: emailContacts,
                            phone: phoneContacts,
                            notificationInfo: combinedAlerts[i]
                        }
                    }
                }
                else {
                    newAlerts = {
                        ...newAlerts,
                        monthlyProjectionAlert: {
                            isAvailable: true,
                            subscribedToEmail: false,
                            subscribedToText: false,
                            email: [],
                            phone: [],
                            notificationInfo: combinedAlerts[i]
                        }
                    }
                }
                break
            }
            case notificationConstants.NOTIFICATION_TYPE.TIME_OF_USE: {
                if (combinedAlerts[i].contacts.length !== 0) {
                    newAlerts = {
                        ...newAlerts,
                        timeOfDayAlert: {
                            isAvailable: true,
                            subscribedToEmail: emailContacts.length !== 0,
                            subscribedToText: phoneContacts.length !== 0,
                            email: emailContacts,
                            phone: phoneContacts,
                            notificationInfo: combinedAlerts[i]
                        }
                    }
                }
                else {
                    newAlerts = {
                        ...newAlerts,
                        timeOfDayAlert: {
                            isAvailable: true,
                            subscribedToEmail: false,
                            subscribedToText: false,
                            email: [],
                            phone: [],
                            notificationInfo: combinedAlerts[i]
                        }
                    }
                }
                break
            }
            case notificationConstants.NOTIFICATION_TYPE.POOL_TIMER: {
                if (combinedAlerts[i].contacts.length !== 0) {
                    newAlerts = {
                        ...newAlerts,
                        poolTimerAlert: {
                            isAvailable: true,
                            subscribedToEmail: emailContacts.length !== 0,
                            subscribedToText: phoneContacts.length !== 0,
                            email: emailContacts,
                            phone: phoneContacts,
                            notificationInfo: combinedAlerts[i]
                        }
                    }
                }
                else {
                    newAlerts = {
                        ...newAlerts,
                        poolTimerAlert: {
                            isAvailable: true,
                            subscribedToEmail: false,
                            subscribedToText: false,
                            email: [],
                            phone: [],
                            notificationInfo: combinedAlerts[i]
                        }
                    }
                }
                break
            }
            case notificationConstants.NOTIFICATION_TYPE.OUTAGE: {
                if (combinedAlerts[i].contacts.length !== 0) {
                    newAlerts = {
                        ...newAlerts,
                        outageAlert: {
                            isAvailable: true,
                            subscribedToEmail: emailContacts.length !== 0,
                            subscribedToText: phoneContacts.length !== 0,
                            email: emailContacts,
                            phone: phoneContacts,
                            notificationInfo: combinedAlerts[i]
                        }
                    }
                }
                else {
                    newAlerts = {
                        ...newAlerts,
                        outageAlert: {
                            isAvailable: true,
                            subscribedToEmail: false,
                            subscribedToText: false,
                            email: [],
                            phone: [],
                            notificationInfo: combinedAlerts[i]
                        }
                    }
                }
                break
            }
            case notificationConstants.NOTIFICATION_TYPE.MPOWER_PURCHASE: {
                if (combinedAlerts[i].contacts.length !== 0) {
                    newAlerts = {
                        ...newAlerts,
                        mPowerPurchaseAlert: {
                            isAvailable: true,
                            subscribedToEmail: emailContacts.length !== 0,
                            subscribedToText: phoneContacts.length !== 0,
                            email: emailContacts,
                            phone: phoneContacts,
                            notificationInfo: combinedAlerts[i]
                        }
                    }
                }
                else {
                    newAlerts = {
                        ...newAlerts,
                        mPowerPurchaseAlert: {
                            isAvailable: true,
                            subscribedToEmail: false,
                            subscribedToText: false,
                            email: [],
                            phone: [],
                            notificationInfo: combinedAlerts[i]
                        }
                    }
                }
                break 
            }
            case notificationConstants.NOTIFICATION_TYPE.LOW_BALANCE: {
                if (combinedAlerts[i].contacts.length !== 0) {
                    newAlerts = {
                        ...newAlerts,
                        mPowerLowBalanceAlert: {
                            isAvailable: true,
                            subscribedToEmail: emailContacts.length !== 0,
                            subscribedToText: phoneContacts.length !== 0,
                            email: emailContacts,
                            phone: phoneContacts,
                            notificationInfo: combinedAlerts[i]
                        }
                    }
                }
                else {
                    newAlerts = {
                        ...newAlerts,
                        mPowerLowBalanceAlert: {
                            isAvailable: true,
                            subscribedToEmail: false,
                            subscribedToText: false,
                            email: [],
                            phone: [],
                            notificationInfo: combinedAlerts[i]
                        }
                    }
                }
                break 
            }
            case notificationConstants.NOTIFICATION_TYPE.HEAT_MORATORIUM: {
                if (combinedAlerts[i].contacts.length !== 0) {
                    newAlerts = {
                        ...newAlerts,
                        heatMoratoriumAlert: {
                            isAvailable: true,
                            subscribedToEmail: emailContacts.length !== 0,
                            subscribedToText: phoneContacts.length !== 0,
                            email: emailContacts,
                            phone: phoneContacts,
                            notificationInfo: combinedAlerts[i]
                        }
                    }
                }
                else {
                    newAlerts = {
                        ...newAlerts,
                        heatMoratoriumAlert: {
                            isAvailable: true,
                            subscribedToEmail: false,
                            subscribedToText: false,
                            email: [],
                            phone: [],
                            notificationInfo: combinedAlerts[i]
                        }
                    }
                }
                break 
            }
            case notificationConstants.NOTIFICATION_TYPE.HEAT_MORATORIUM_ACCUMULATED_DEBT: {
                if (combinedAlerts[i].contacts.length !== 0) {
                    newAlerts = {
                        ...newAlerts,
                        heatMoratoriumAccumulatedDebtAlert: {
                            isAvailable: true,
                            subscribedToEmail: emailContacts.length !== 0,
                            subscribedToText: phoneContacts.length !== 0,
                            email: emailContacts,
                            phone: phoneContacts,
                            notificationInfo: combinedAlerts[i]
                        }
                    }
                }
                else {
                    newAlerts = {
                        ...newAlerts,
                        heatMoratoriumAccumulatedDebtAlert: {
                            isAvailable: true,
                            subscribedToEmail: false,
                            subscribedToText: false,
                            email: [],
                            phone: [],
                            notificationInfo: combinedAlerts[i]
                        }
                    }
                }
                break 
            }
            case notificationConstants.NOTIFICATION_TYPE.HEAT_MORATORIUM_END_WITH_ACCUMULATED_DEBT: {
                if (combinedAlerts[i].contacts.length !== 0) {
                    newAlerts = {
                        ...newAlerts,
                        heatMoratoriumEndWithAccumulatedDebtAlert: {
                            isAvailable: true,
                            subscribedToEmail: emailContacts.length !== 0,
                            subscribedToText: phoneContacts.length !== 0,
                            email: emailContacts,
                            phone: phoneContacts,
                            notificationInfo: combinedAlerts[i]
                        }
                    }
                }
                else {
                    newAlerts = {
                        ...newAlerts,
                        heatMoratoriumEndWithAccumulatedDebtAlert: {
                            isAvailable: true,
                            subscribedToEmail: false,
                            subscribedToText: false,
                            email: [],
                            phone: [],
                            notificationInfo: combinedAlerts[i]
                        }
                    }
                }
                break 
            }
            case notificationConstants.NOTIFICATION_TYPE.LANDLORD_ALERT: {
                if (combinedAlerts[i].contacts.length !== 0) {
                    newAlerts = {
                        ...newAlerts,
                        landlordAlert: {
                            isAvailable: true,
                            subscribedToEmail: emailContacts.length !== 0,
                            subscribedToText: phoneContacts.length !== 0,
                            email: emailContacts,
                            phone: phoneContacts,
                            notificationInfo: combinedAlerts[i]
                        }
                    }
                }
                else {
                    newAlerts = {
                        ...newAlerts,
                        landlordAlert: {
                            isAvailable: true,
                            subscribedToEmail: false,
                            subscribedToText: false,
                            email: [],
                            phone: [],
                            notificationInfo: combinedAlerts[i]
                        }
                    }
                }
                break 
            }
            case notificationConstants.NOTIFICATION_TYPE.HIGH_USAGE: {
                if (combinedAlerts[i].contacts.length !== 0) {
                    newAlerts = {
                        ...newAlerts,
                        highUsageAlert: {
                            isAvailable: true,
                            subscribedToEmail: emailContacts.length !== 0,
                            subscribedToText: phoneContacts.length !== 0,
                            email: emailContacts,
                            phone: phoneContacts,
                            notificationInfo: combinedAlerts[i]
                        }
                    }
                }
                else {
                    newAlerts = {
                        ...newAlerts,
                        highUsageAlert: {
                            isAvailable: true,
                            subscribedToEmail: false,
                            subscribedToText: false,
                            email: [],
                            phone: [],
                            notificationInfo: combinedAlerts[i]
                        }
                    }
                }
                break 
            }
            default:
                break
        }
    }

    return newAlerts
}



