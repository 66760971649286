import React from 'react'
import PropTypes from 'prop-types'
import format from '../../../../srp_modules/format'
import { Trans } from 'react-i18next'

const PhoneAlertsSubscribed = ({ subscriptions, phone, t }) => {
    let verifiedSubscriptions = GetVerifiedSubs(subscriptions, phone).map((sub, i) =>
        <li key={`verified_sub_${i}`}>{sub.displayTitle + " from " + sub.associatedShortCode}</li>)

    let unVerifiedSubscriptions = GetUnVerifiedSubs(subscriptions, phone).map((sub, i) =>
        <li key={`unverified_sub_${i}`}>{sub.displayTitle + " from " + sub.associatedShortCode}</li>)

    let hasVerifiedSubs = verifiedSubscriptions && verifiedSubscriptions.length > 0
    let hasUnverifiedSubs = unVerifiedSubscriptions && unVerifiedSubscriptions.length > 0
    let formattedPhoneNumber = format.formatPhoneNumber(phone)

    return (
        <div>
            <div>
                <label className="h6" style={{color: "#737B4C", fontWeight: "bold"}}>{t("Enrolled Alerts")}</label>
                {hasVerifiedSubs ?
                    <div>
                        <div>
                            {t("Alerts currently being sent to")} <span style={{whiteSpace: "nowrap"}}>{formattedPhoneNumber}</span>:
                        </div>
                        <ul className="dash">{verifiedSubscriptions}</ul>
                    </div>

                : <div>{t("There are currently no enrolled text alerts for")} {formattedPhoneNumber}</div>
                }
            </div>

            <div className="mt-2">
                <label className="h6" style={{color: "#6787B7", fontWeight: "bold"}}>{t("Alerts Needing START Reply")}</label>
                {hasUnverifiedSubs ?
                <div>
                    <div>
                        <Trans i18nKey="alerts_will_be_sent_to" t={t}>
                            The following alerts will be sent to <span style={{whiteSpace: "nowrap"}}>{{formattedPhoneNumber : formattedPhoneNumber}}</span> after receiving a start message:
                        </Trans>
                    </div>
                    <ul className="dash">{unVerifiedSubscriptions}</ul>
                </div>
                : <div>{t("There are currently no text alerts needing a START reply to complete enrollment")}</div>
                }
            </div>
        </div>
    )
}

const GetVerifiedSubs = (subs, phone) =>{
    let verifedSubscriptions = []
    subs.forEach(s => {
        let contact = s.contacts.find(c => c.contactAddress === phone)
        if (contact !== "undefined" && contact.verifiedShortCodes.includes(s.associatedShortCode))
        {
            verifedSubscriptions.push(s)
        }
    });
    return verifedSubscriptions
}

const GetUnVerifiedSubs = (subs, phone) =>{
    let unVerifedSubscriptions = []
    subs.forEach(s => {
        let contact = s.contacts.find(c => c.contactAddress === phone)
        if (contact !== "undefined" && !contact.verifiedShortCodes.includes(s.associatedShortCode))
        {
            unVerifedSubscriptions.push(s)
        }
    });
    return unVerifedSubscriptions
}

PhoneAlertsSubscribed.propTypes = {
    phone: PropTypes.string.isRequired,
    subscriptions: PropTypes.array.isRequired,
    t: PropTypes.func
}

export default PhoneAlertsSubscribed