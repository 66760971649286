import React from 'react'
import PropTypes from 'prop-types'

const BankInfoErrorCard = ({refreshData, t}) => {

    return(
        <div className="col-lg-9 col-12 mt-3">
            <div className="srp-card-header">
                {t("Banking information")}
            </div>
            <div className="srp-card-body">
                <div className="srp-card-details">
                    <div className="text-center">
                        <div className="mb-1" style={{color: "#9E2A2B"}}><i className="material-icons" style={{fontSize:"50px"}}>error_outline</i></div>
                        <div className="mb-1" style={{color: "#9E2A2B"}}><strong>{t("Something went wrong")}</strong></div>
                        <div className="mb-4">{t("We were unable to retrieve your Bank information.")}</div>
                    </div>
                    <div className="d-flex justify-content-end">
                        <a onClick={()=>refreshData()} className="btn srp-btn btn-lightblue text-white mr-2">{t("Try again")}</a>
                    </div> 
                </div>
            </div>
        </div>
    )
}

BankInfoErrorCard.propTypes = {
    refreshData: PropTypes.func,
    t: PropTypes.func
}

export default BankInfoErrorCard