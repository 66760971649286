import React from 'react'
import PropTypes from 'prop-types'

import Card from "@mui/material/Card"
import CircularProgress from '@mui/material/CircularProgress'

import { withTranslation, Trans } from 'react-i18next'


import '../../styles/autopay-page.css'
import AccountInfoBarContainer from '../myaccount_header/info_bar/account-info-bar-container'
import AutopayStep from '../common_autopay/autopay-step'
import CallAnytime from '../myaccount_header/call-anytime'
import { NumericFormat, PatternFormat } from 'react-number-format'
import { withRouter } from '../../srp_modules/with-router'
import * as loadingStatus from '../../constants/loading-status-constants'
import format from '../../srp_modules/format'
import CardLoading from '../../components/dashboard_page/card-loading'
import { myAccountConstants } from '../../constants/myaccount-constants'

let rowStyle = {
    borderBottom: "1px solid #E5E5E5",
    borderRadius: "0"
}

const VerifyAutopayPage = ({ billAccount, customerName, bankName, routingNumber,
    bankAccountNumber, emailAddress, phoneNumber, shareAmount, isResidential,
    onSubmitClick, enrollAutopayStatus,
    updateAutopayStatus, isEnrolled, isPendingRelease, autopayInfoStatus,
    selectBillAccountStatus, router, t, i18n }) => {

    const inProgress = enrollAutopayStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS || updateAutopayStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS

    const helpPhoneNumber = isResidential
        ? i18n.language==="es"
                            ? myAccountConstants.SPANISH_CUSTOMER_SERVICE_PHONE_NUMBER_FMTTD
                            : myAccountConstants.RESIDENTIAL_CUSTOMER_SERVICE_PHONE_NUMBER_FMTTD
                        : myAccountConstants.COMMERCIAL_CUSTOMER_SERVICE_PHONE_NUMBER_FMTTD

    const helpText =
        <>
        {
        isResidential
        ? t("For_questions_please_contact_SRP", {residentialOrCommercial: "Residential", supportNumber: helpPhoneNumber})
        : t("For_questions_please_contact_SRP_commercial", {supportNumber: helpPhoneNumber})
        }
        </>

    return (
        <div>
            <div className="container py-3 hidden-sm-down">
                <div className="d-sm-block d-md-none">
                    <CallAnytime />
                </div>

                <h3 className="mt-3">{!isEnrolled ? t("Sign up for SurePay") : t("Edit SurePay")}</h3>

                <AccountInfoBarContainer disableSelector={true} billAccount={billAccount} />

                <div className="row">
                    <div className="col-lg-6 mt-3">
                        <div>
                            <label className="h5">{t("Review my information")}</label>
                            {autopayInfoStatus !== loadingStatus.LOADING_STATUS_SUCCESS
                                && autopayInfoStatus !== loadingStatus.LOADING_STATUS_FAILURE
                                && selectBillAccountStatus !== loadingStatus.LOADING_STATUS_SUCCESS
                                && selectBillAccountStatus !== loadingStatus.LOADING_STATUS_FAILURE
                                ? (<CardLoading title={t("SurePay")} />)
                                : (<Card className="px-4 pb-4">
                                    <AutopayStep activeStep={2} t={t} />

                                    {!isEnrolled
                                        ? <div className="srp-alert-notice">
                                            <span style={{ fontWeight: "bold" }}>{t("NOTE:")}</span>{' '}
                                            {t("SurePay_will_go_into_effect")}
                                        </div>
                                        : (isEnrolled && !isPendingRelease)
                                            ? <div className="srp-alert-notice">
                                                <span style={{ fontWeight: "bold" }}>{t("NOTE:")}</span>{' '}
                                                {t("two_business_days_to_process")}{' '}
                                                {helpText}
                                            </div>
                                            : <div className="srp-alert-warn">
                                                <span style={{ fontWeight: "bold" }}>{t("ATTENTION")}</span>{' '}
                                                {t("within_2_days_of_SurePay_payment_date")}{' '}
                                                {helpText}
                                            </div>
                                    }

                                    <div className="row srp-row" />

                                    <div className="row srp-row p-3" style={rowStyle}>
                                        <div className="col srp-label">
                                            <div>{t("SRP account")}</div>
                                        </div>
                                        <div className="col">
                                            <div>
                                                <PatternFormat
                                                    value={format.srpAccountNumber(billAccount)}
                                                    valueIsNumericString={true}
                                                    format="###-###-###"
                                                    displayType="text"
                                                    style={{ width: '100%' }}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row srp-row p-3" style={rowStyle}>
                                        <div className="col srp-label">
                                            <div>{t("Name on account")}</div>
                                        </div>
                                        <div className="col">
                                            <div>{customerName}</div>
                                        </div>
                                    </div>

                                    <div className="row srp-row p-3" style={rowStyle}>
                                        <div className="col srp-label">
                                            <div>{t("Bank name")}</div>
                                        </div>
                                        <div className="col">
                                            <div>{bankName}</div>
                                        </div>
                                    </div>

                                    <div className="row srp-row p-3" style={rowStyle}>
                                        <div className="col srp-label">
                                            <div>{t("Routing number")}</div>
                                        </div>
                                        <div className="col">
                                            <div>
                                                <NumericFormat
                                                    value={routingNumber}
                                                    valueIsNumericString={true}
                                                    displayType="text"
                                                    style={{ width: '100%' }}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row srp-row p-3" style={rowStyle}>
                                        <div className="col srp-label">
                                            <div>{t("Bank account number")}</div>
                                        </div>
                                        <div className="col">
                                            <div>
                                                <NumericFormat
                                                    value={bankAccountNumber}
                                                    valueIsNumericString={true}
                                                    displayType="text"
                                                    style={{ width: '100%' }}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row srp-row p-3" style={rowStyle}>
                                        <div className="col srp-label">
                                            <div>{t("Email address")}</div>
                                        </div>
                                        <div className="col">
                                            <div>{emailAddress}</div>
                                        </div>
                                    </div>

                                    <div className="row srp-row p-3" style={rowStyle}>
                                        <div className="col srp-label">
                                            <div>{t("Phone number")}</div>
                                        </div>
                                        <div className="col">
                                            <div>
                                                <PatternFormat
                                                    value={phoneNumber}
                                                    valueIsNumericString={true}
                                                    displayType="text"
                                                    format="(###) ###-####"
                                                    style={{ width: '100%' }}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    {shareAmount != 0
                                        ? <div className="row srp-row p-3" style={rowStyle}>
                                            <div className="col srp-label">
                                                <div>{t("SHARE amount")}</div>
                                            </div>
                                            <div className="col">
                                                {t("shareAmount_per_month", {shareAmount})}
                                            </div>
                                        </div>
                                        : null
                                    }

                                    <div className="p-3" >
                                        <span style={{ fontSize: "14px", color: "rgb(112,112,112)" }}>
                                            {t("By_clicking_the_Submit_button")}
                                            </span>
                                    </div>

                                    <div className="d-flex flex-wrap justify-content-end mt-4">
                                        <button disabled={inProgress} className="btn srp-btn btn-lightblue" onClick={() => router.navigate(-1)}>{t("Previous")}</button>
                                        <button className="btn srp-btn btn-blue ml-2" disabled={inProgress} onClick={onSubmitClick}>
                                            {inProgress
                                                ? <CircularProgress size={20} thickness={5} style={{ color: 'white' }} />
                                                : t("Submit")
                                            }
                                        </button>
                                    </div>

                                </Card>
                                )
                            }
                        </div>
                    </div>
                    <div className="col-lg-6 mt-3">
                        <label className="h5">{t("Need help?")}</label>
                        <Card>
                            <div className="srp-alert-warn">
                                <div className="pt-2 pb-2 d-lg-block d-none">
                                    {helpText}
                                </div>
                                <div className="pt-2 pb-2 d-lg-none d-md-block">
                                    {isResidential
                                    ?
                                    <Trans i18nKey="For_questions_please_contact_SRP_phone_link" t={t}>
                                        For questions please contact SRP Residential Customer Services at
                                        <a href={"tel:" + helpPhoneNumber}>{{residentialOrCommercial: "Residential", supportNumber: helpPhoneNumber}}</a>,
                                        available to serve you 24 hours a day, seven days a week.
                                    </Trans>
                                    :
                                    <Trans i18nKey="For_questions_please_contact_SRP_commercial_phone_link" t={t}>
                                        For questions please contact SRP Business Contact Center at
                                        <a href={"tel:" + helpPhoneNumber}>{{supportNumber: helpPhoneNumber}}</a>,
                                        Monday through Friday, 7 a.m.-5 p.m.
                                    </Trans>
                                    }
                                </div>
                            </div>
                        </Card>
                    </div>
                </div>
            </div>
        </div>
    )
}

VerifyAutopayPage.propTypes = {
    billAccount: PropTypes.number.isRequired,
    customerName: PropTypes.string.isRequired,
    bankName: PropTypes.string.isRequired,
    routingNumber: PropTypes.string.isRequired,
    bankAccountNumber: PropTypes.string.isRequired,
    emailAddress: PropTypes.string.isRequired,
    phoneNumber: PropTypes.string.isRequired,
    shareAmount: PropTypes.oneOfType([PropTypes.string,PropTypes.number]).isRequired,
    isResidential: PropTypes.bool.isRequired,
    onSubmitClick: PropTypes.func.isRequired,
    enrollAutopayStatus: PropTypes.string.isRequired,
    updateAutopayStatus: PropTypes.string.isRequired,
    isEnrolled: PropTypes.bool.isRequired,
    isPendingRelease: PropTypes.bool.isRequired,
    autopayInfoStatus: PropTypes.string.isRequired,
    selectBillAccountStatus: PropTypes.string.isRequired,
    router: PropTypes.shape({
        navigate: PropTypes.func
    }).isRequired,
    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired,
}

export default withTranslation("addUpdateSurePay")(withRouter(VerifyAutopayPage))