import React from 'react'
import PropTypes from 'prop-types'

import * as loadingStatus from '../../../constants/loading-status-constants'

import SinglePaymentFormFunc from './single-payment-form-func'

class SinglePaymentForm extends React.Component {
    constructor (props) {
        super(props)
    }

    componentDidUpdate(prevProps) {
        if (this.props.primaryEmailStatus === loadingStatus.LOADING_STATUS_SUCCESS &&
            prevProps.primaryEmailStatus !== loadingStatus.LOADING_STATUS_SUCCESS) {
            this.props.setFieldValue('bankEmailAddress', this.props.primaryEmail, false)
        }
    }

    render() {
        return (
            <SinglePaymentFormFunc {...this.props} />
        )
    }
}

SinglePaymentForm.propTypes = {
    t:  PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired,
    values: PropTypes.shape({
        srpAccountNumber: PropTypes.string.isRequired,
        bankAccountIdSelected: PropTypes.number.isRequired,
        bankFirstName: PropTypes.string.isRequired,
        bankLastName: PropTypes.string.isRequired,
        bankPhoneNumber: PropTypes.string.isRequired,
        bankEmailAddress: PropTypes.string.isRequired,
        bankRoutingNumber: PropTypes.string.isRequired,
        bankAccountNumberLeadingZeroesAreSignificant: PropTypes.string.isRequired,
        bankNickname: PropTypes.string.isRequired,
        paymentAmount: PropTypes.string.isRequired
    }).isRequired,
    errors: PropTypes.shape({
        srpAccountNumber: PropTypes.oneOfType([
            PropTypes.string.isRequired,
            PropTypes.shape({
                getLocalizedErrMsg: PropTypes.func.isRequired,
            }),
        ]),
        bankAccountIdSelected: PropTypes.oneOfType([
            PropTypes.string.isRequired,
            PropTypes.shape({
                getLocalizedErrMsg: PropTypes.func.isRequired,
            }),
        ]),
        bankFirstName: PropTypes.string,
        bankLastName: PropTypes.string,
        bankPhoneNumber: PropTypes.string,
        bankEmailAddress: PropTypes.string,
        bankRoutingNumber: PropTypes.string,
        bankAccountNumberLeadingZeroesAreSignificant: PropTypes.string,
        bankNickname: PropTypes.string,
        paymentAmount: PropTypes.oneOfType([
            PropTypes.string.isRequired,
            PropTypes.shape({
                getLocalizedErrMsg: PropTypes.func.isRequired,
            }),
        ]),
    }).isRequired,
    touched: PropTypes.shape({
        srpAccountNumber: PropTypes.bool,
        bankAccountIdSelected: PropTypes.bool,
        bankFirstName: PropTypes.bool,
        bankLastName: PropTypes.bool,
        bankPhoneNumber: PropTypes.bool,
        bankEmailAddress: PropTypes.bool,
        bankRoutingNumber: PropTypes.bool,
        bankAccountNumberLeadingZeroesAreSignificant: PropTypes.bool,
        bankNickname: PropTypes.bool,
        paymentAmount: PropTypes.bool
    }).isRequired,
    handleChange: PropTypes.func.isRequired,
    handleBlur: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
    bankFormOpen: PropTypes.bool.isRequired,
    addBankAccountButtonClicked: PropTypes.func.isRequired,
    addBankCancelButtonClicked: PropTypes.func.isRequired,
    status: PropTypes.shape({
        hasUnhandledeChexValidationError: PropTypes.bool
    }),
    setPayMultipleAccountsButtonClicked: PropTypes.func.isRequired,
    clearPayMultipleAccountsButtonClicked: PropTypes.func.isRequired,
    submitForm: PropTypes.func.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    setFieldTouched: PropTypes.func.isRequired,
    bankAccountList: PropTypes.arrayOf(PropTypes.shape({
        bankId: PropTypes.number.isRequired,
        bankName: PropTypes.string.isRequired,
        nickName: PropTypes.string.isRequired,
        accountNumber: PropTypes.string.isRequired
    })).isRequired,

    formDataChanged: PropTypes.func.isRequired,

    primaryEmailStatus: PropTypes.string.isRequired,
    primaryEmail: PropTypes.string.isRequired
}

export default SinglePaymentForm