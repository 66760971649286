export const cleanPowerConstants = {
    SEND_USAGE_DATA_REQUEST: 'SEND_USAGE_DATA_REQUEST',
    SEND_USAGE_DATA_SUCCESS: 'SEND_USAGE_DATA_SUCCESS',
    SEND_USAGE_DATA_FAILURE: 'SEND_USAGE_DATA_FAILURE',

    HAS_ENOUGH_HISTORY_REQUEST: "HAS_ENOUGH_HISTORY_REQUEST",
    HAS_ENOUGH_HISTORY_SUCCESS: "HAS_ENOUGH_HISTORY_SUCCESS",
    HAS_ENOUGH_HISTORY_FAILURE: "HAS_ENOUGH_HISTORY_FAILURE",

    CALCULATOR_URL_REQUEST: "CALCULATOR_URL_REQUEST",
    CALCULATOR_URL_SUCCESS: "CALCULATOR_URL_SUCCESS",
    CALCULATOR_URL_FAILURE: "CALCULATOR_URL_FAILURE"
}