import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { dailyUsageChartTypeConstants } from '../../constants/daily-usage-chart-type-constants'
import MonthlyNetChartContainer from './monthly-net-chart-container'
import MonthlyUsageChartContainer from './monthly-usage-chart-container'
import MonthlyTableView from './monthly-table-view'
import Divider from '@mui/material/Divider'
import '../../styles/usage.css'

import { getUsageMonthlyChartType, getMonthlyDataTable } from '../../actions/auth/usage/usage-actions'
import exportUsageModule from '../../srp_modules/charting/export'

class MonthlyNetContainer extends React.Component {
    constructor(props) {
        super(props)

        this.exportMonthlyNetToExcel = this.exportMonthlyNetToExcel.bind(this)
    }

    exportMonthlyNetToExcel(dataType, chartType) {
        let dataToExport = exportUsageModule.getUsageDataWithDateRangeForMonthly(this.props.accountHistoryList, this.props.monthlyStartDate, this.props.monthlyEndDate)
        exportUsageModule.getUsageChartExport(dataToExport, dataType, chartType)
    }

    render() {
        return (
            <div className="ml-4 mr-4 pb-2">


                {this.props.showMonthlyDataTable
                    ? <div className="d-none d-lg-block mb-4"/>
                    : <div className="d-none d-lg-block mb-4">
                        <div className={this.props.currentMonthlyChart === dailyUsageChartTypeConstants.NET_ENERGY ? "block-element" : "hidden-element"}>{this.props.t("Click a bar to see daily net energy")}</div>
                        <div className={this.props.currentMonthlyChart === dailyUsageChartTypeConstants.GENERATION ? "block-element" : "hidden-element"}>{this.props.t("Click a bar to see daily generation")}</div>
                        <div className={this.props.currentMonthlyChart === dailyUsageChartTypeConstants.CONSUMPTION ? "block-element" : "hidden-element"}>{this.props.t("Click a bar to see daily usage")}</div>
                        <div className={this.props.currentMonthlyChart === dailyUsageChartTypeConstants.DEMAND ? "block-element" : "hidden-element"}>{this.props.t("Click a bar to see daily demand")}</div>
                    </div>
                }

                <div className="d-none d-lg-block chart-btn-container">
                    <span className="mr-2">{this.props.t("View my")}</span>
                    <button className={this.props.monthlyButtonClassNames.netBtnClassName} onClick={() => this.props.getUsageMonthlyChartTypeOnClick(dailyUsageChartTypeConstants.NET_ENERGY)}>{this.props.t("Net energy")}</button>
                    <button className={this.props.monthlyButtonClassNames.generationBtnClassName} onClick={() => this.props.getUsageMonthlyChartTypeOnClick(dailyUsageChartTypeConstants.GENERATION)}>{this.props.t("Generation")}</button>
                    <button className={this.props.monthlyButtonClassNames.consumptionBtnClassName} onClick={() => this.props.getUsageMonthlyChartTypeOnClick(dailyUsageChartTypeConstants.CONSUMPTION)}>{this.props.t("Usage")}</button>
                    {this.props.rateMetaData.rateMetaData.isDemand &&
                        <button className={this.props.monthlyButtonClassNames.demandBtnClassName} onClick={() => this.props.getUsageMonthlyChartTypeOnClick(dailyUsageChartTypeConstants.DEMAND)}>{this.props.t("Demand")}</button>
                    }
                </div>
                <div className="d-block d-lg-none">
                    <div className="d-flex justify-content-end">
                        <button
                            className="btn srp-btn btn-lightblue mr-2"
                            onClick={() => this.props.getMonthlyDataTableOnClick(!this.props.showMonthlyDataTable)}>
                            {this.props.showMonthlyDataTable ? this.props.t("View chart") : this.props.t("View data table")}
                        </button>
                        <button
                            className="btn srp-btn btn-lightblue"
                            onClick={() => this.exportMonthlyNetToExcel(this.props.currentMonthlyChart, this.props.selectedUsagePageChartTab)}>
                            {this.props.t("Export")}
                        </button>
                    </div>
                </div>
                <Divider className="mt-4 mb-4 d-lg-none" id="top-of-monthly-chart" />
                <div className="d-lg-none mt-2 mb-4">
                    <div className="mr-2 mb-2" >{this.props.t("View my")}</div>
                    <button className={this.props.monthlyButtonClassNames.netBtnClassName} onClick={() => this.props.getUsageMonthlyChartTypeOnClick(dailyUsageChartTypeConstants.NET_ENERGY)}>{this.props.t("Net energy")}</button>
                    <button className={this.props.monthlyButtonClassNames.generationBtnClassName} onClick={() => this.props.getUsageMonthlyChartTypeOnClick(dailyUsageChartTypeConstants.GENERATION)}>{this.props.t("Generation")}</button>
                    <button className={this.props.monthlyButtonClassNames.consumptionBtnClassName} onClick={() => this.props.getUsageMonthlyChartTypeOnClick(dailyUsageChartTypeConstants.CONSUMPTION)}>{this.props.t("Usage")}</button>
                    {this.props.rateMetaData.rateMetaData.isDemand &&
                        <button className={this.props.monthlyButtonClassNames.demandBtnClassName} onClick={() => this.props.getUsageMonthlyChartTypeOnClick(dailyUsageChartTypeConstants.DEMAND)}>{this.props.t("Demand")}</button>
                    }
                </div>

                {this.props.showMonthlyDataTable
                    ? null
                    : <div className="d-block d-lg-none mb-4 ">
                        <div className={this.props.currentMonthlyChart === dailyUsageChartTypeConstants.NET_ENERGY ? "block-element" : "hidden-element"}>{this.props.t("Click a bar to see daily net energy")}</div>
                        <div className={this.props.currentMonthlyChart === dailyUsageChartTypeConstants.GENERATION ? "block-element" : "hidden-element"}>{this.props.t("Click a bar to see daily generation")}</div>
                        <div className={this.props.currentMonthlyChart === dailyUsageChartTypeConstants.CONSUMPTION ? "block-element" : "hidden-element"}>{this.props.t("Click a bar to see daily usage")}</div>
                        <div className={this.props.currentMonthlyChart === dailyUsageChartTypeConstants.DEMAND ? "block-element" : "hidden-element"}>{this.props.t("Click a bar to see daily demand")}</div>
                    </div>
                }

                {this.props.showMonthlyDataTable
                    ? null
                    : <div className="d-block d-lg-none mb-4 center-text ">
                        <div className={(this.props.currentMonthlyChart === dailyUsageChartTypeConstants.NET_ENERGY
                            || this.props.currentMonthlyChart === dailyUsageChartTypeConstants.USAGE
                            || this.props.currentMonthlyChart === dailyUsageChartTypeConstants.CONSUMPTION)
                            ? "block-element"
                            : "hidden-element"}>{this.props.t("Usage (kWh)")}
                        </div>
                        <div className={this.props.currentMonthlyChart === dailyUsageChartTypeConstants.GENERATION ? "block-element" : "hidden-element"}>{this.props.t("Generation (kWh)")}</div>
                        <div className={this.props.currentMonthlyChart === dailyUsageChartTypeConstants.DEMAND ? "block-element" : "hidden-element"}>{this.props.t("Peak Demand")}</div>
                    </div>
                }

                {this.props.showMonthlyDataTable
                    ? <MonthlyTableView 
                        usageData={exportUsageModule.getUsageDataWithDateRangeForMonthly(this.props.accountHistoryList, this.props.monthlyStartDate, this.props.monthlyEndDate)}
                        currentUsageType={this.props.currentMonthlyChart} 
                        t={this.props.t}/>
                    : this.props.currentMonthlyChart === dailyUsageChartTypeConstants.NET_ENERGY
                        ? <MonthlyNetChartContainer t={this.props.t}/>
                        : (this.props.currentMonthlyChart === dailyUsageChartTypeConstants.DEMAND
                            || this.props.currentMonthlyChart === dailyUsageChartTypeConstants.USAGE
                            || this.props.currentMonthlyChart === dailyUsageChartTypeConstants.CONSUMPTION
                            || this.props.currentMonthlyChart === dailyUsageChartTypeConstants.GENERATION
                        )
                            ? <MonthlyUsageChartContainer t={this.props.t}/>
                            : null
                }

                <div className="d-none d-lg-block mt-4">
                    <div className="d-flex justify-content-end mb-1">
                        <button
                            className="btn srp-btn btn-lightblue mr-2"
                            onClick={() => this.props.getMonthlyDataTableOnClick(!this.props.showMonthlyDataTable)}>
                            {this.props.showMonthlyDataTable ? this.props.t("View as chart") : this.props.t("View as data table")}
                        </button>
                        <button
                            className="btn srp-btn btn-lightblue"
                            onClick={() => this.exportMonthlyNetToExcel(this.props.currentMonthlyChart, this.props.selectedUsagePageChartTab)}>
                            {this.props.t("Export to Excel")}
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        ...state.accountInfo.usagePageUI,
        ...state.accountHistory,
        rateMetaData: state.rateMetaData
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getUsageMonthlyChartTypeOnClick: (type) => {
            dispatch(getUsageMonthlyChartType(type))
        },
        getMonthlyDataTableOnClick: (showTable) => {
            dispatch(getMonthlyDataTable(showTable))
        }
    }
}

MonthlyNetContainer.propTypes = {
    monthlyButtonClassNames: PropTypes.object,
    rateMetaData: PropTypes.object,
    currentMonthlyChart: PropTypes.string,
    getUsageMonthlyChartTypeOnClick: PropTypes.func,
    selectedUsagePageChartTab: PropTypes.number,
    accountHistoryList: PropTypes.array,
    monthlyStartDate: PropTypes.string,
    monthlyEndDate: PropTypes.string,
    getMonthlyDataTableOnClick: PropTypes.func,
    showMonthlyDataTable: PropTypes.bool,
    t: PropTypes.func
}

export default connect(mapStateToProps, mapDispatchToProps)(MonthlyNetContainer);