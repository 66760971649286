import { DateTime } from 'luxon'
import format from '../../srp_modules/format'

export const mapToServerStruct = (formState, accountNumber, isCommercial, centralPrepayStatus) => {
  return {
    accountNumber: accountNumber,
    isResidential: !isCommercial,
    date: DateTime.fromJSDate(formState.selectedDate).toFormat('M/d/yyyy'),
    residentialModel:
    {
      firstName: !isCommercial ? formState.firstName : "",
      lastName: !isCommercial ? formState.lastName : "",
      middleName: !isCommercial ? formState.middleName : "",
      emailAddress: formState.primaryEmail,
      phoneNumber: format.formatPhoneNumber(formState.primaryPhone)
    },
    businessModel: {
      businessName: isCommercial ? formState.businessName : "",
      dbaName: formState.dba,
      contactName: formState.requestorsName,
      contactPosition: formState.requestorsAssociaton
    },
    serviceAddress: {
      streetAddress: formState.serviceAddress.streetAddress,
      streetAddressLineTwo: '',
      state: formState.serviceAddress.state,
      city: formState.serviceAddress.cityName,
      zipCode: formState.serviceAddress.zipCode
    },
    finalBillingAddress: {
      streetAddress: formState.address1,
      streetAddressLineTwo: formState.address2,
      state: formState.state,
      city: formState.city,
      zipcode: formState.zip,
      addressType: 1,
      country: formState.country
    },    
    isMpowerBoxSentBack: formState.mpowerSendBackBox,
    isMPower: formState.isMpowerAccount,
    sendFinalBillAsEbill: formState.sendFinalBillAsEbill,
    centralPrepayStatus: centralPrepayStatus
  }
}