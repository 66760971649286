import React from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import { clearPaymentList, clearBankInfo, clearGiftPaymentSubmitted, disableMultiplePayments, setFormStartEventEmitted, clearFormStartEventEmitted } from '../../../actions/auth/payment/gift-payment-actions'

import { getContacts } from '../../../actions/auth/contact/contact-actions'

import * as loadingStatus from '../../../constants/loading-status-constants'

import '../../../styles/payment-page.css'

import GiftPaymentPage from './gift-payment-page'

class GiftPaymentPageContainer extends React.Component {
    constructor(props) {
        super(props)
    }

    componentDidMount() {
        if (this.props.paymentSubmitted)
            this.props.clearState()
    }

    componentDidUpdate() {
        if (this.props.selectedBillAccount === 0)
            return

        if (this.props.primaryEmailStatus === loadingStatus.LOADING_STATUS_INIT)
            this.props.getContactsInfo(this.props.selectedBillAccount)
    }

    render() {
        return (
            !this.props.paymentSubmitted &&
            <GiftPaymentPage multiplePaymentsEnabled={this.props.multiplePaymentsEnabled} bankFormOpen={this.props.bankFormOpen}
                formStartEventEmitted={this.props.formStartEventEmitted} setFormStartEventEmitted={this.props.setFormStartEventEmitted}
            />
        )
    }
}

const mapStateToProps = state => ({
    multiplePaymentsEnabled: state.giftPaymentList.multiplePaymentsEnabled,
    bankFormOpen: state.giftPaymentBankInfo.bankFormOpen,
    paymentSubmitted: state.giftPaymentApiResponse.paymentSubmitted,
    selectedBillAccount: state.accountInfo.billAccount.selectedBillAccount,
    primaryEmailStatus: state.accountInfo.contact.primaryEmailStatus,
    formStartEventEmitted: state.giftPaymentList.formStartEventEmitted,
})

const mapDispatchToProps = (dispatch) => ({
    setFormStartEventEmitted: () => {
        dispatch(setFormStartEventEmitted())
    },
    getContactsInfo: (billAccount) => {
        dispatch(getContacts(billAccount))
    },
    clearState: () => {
        dispatch(clearPaymentList())
        dispatch(clearBankInfo())
        dispatch(clearFormStartEventEmitted())
        dispatch(clearGiftPaymentSubmitted())
        dispatch(disableMultiplePayments())
    }
})

GiftPaymentPageContainer.propTypes = {
    multiplePaymentsEnabled: PropTypes.bool.isRequired,
    bankFormOpen: PropTypes.bool.isRequired,
    paymentSubmitted: PropTypes.bool.isRequired,
    clearState: PropTypes.func.isRequired,

    selectedBillAccount: PropTypes.number.isRequired,
    primaryEmailStatus: PropTypes.string.isRequired,
    getContactsInfo: PropTypes.func.isRequired,

    formStartEventEmitted: PropTypes.bool.isRequired,
    setFormStartEventEmitted: PropTypes.func.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(GiftPaymentPageContainer)