import { outageConstants } from '../../../constants/outage-constants'

export const hideLegend = () => ({
    type: outageConstants.HIDE_LEGEND
})

export const showLegend = () => ({
    type: outageConstants.SHOW_LEGEND
})

export const hideOutageDetail = () => ({
    type: outageConstants.HIDE_OUTAGE_DETAILS
})

export const showOutageDetail = (outageMarkerInfo) => ({
    type: outageConstants.SHOW_OUTAGE_DETAILS,
    outageMarkerInfo
})

export const hideCustomerInOutageDetail = () => ({
    type: outageConstants.HIDE_CUSTOMER_IN_OUTAGE_DETAILS
})

export const showCustomerInOutageDetail = () => ({
    type: outageConstants.SHOW_CUSTOMER_IN_OUTAGE_DETAILS
})

export const outageMapActions = {
    hideLegend,
    showLegend,
    hideOutageDetail,
    showOutageDetail,
    hideCustomerInOutageDetail,
    showCustomerInOutageDetail
}
