import { styled } from '@mui/material/styles'

const PREFIX = 'purchase-details-styles'

export const classes = {
    root: `${PREFIX}-root`,
    column: `${PREFIX}-column`,
    twoColumns: `${PREFIX}-twoColumns`,
    column50pct: `${PREFIX}-column50pct`,
    secondaryText: `${PREFIX}-secondaryText`,
    amount: `${PREFIX}-amount`,
    icon: `${PREFIX}-icon`,
    detailsBox: `${PREFIX}-detailsBox`
}

export const PurchaseDetailsStyledDiv = styled('div')(({theme}) => ({
    [`& .${classes.root}`]: {
        width: '100%',
    },
    [`& .${classes.column}`]: {
        flexBasis: '33.33%',
    },
    [`& .${classes.twoColumns}`]: {
        flexBasis: '66.67%',
    },
    [`& .${classes.column50pct}`]: {
        flexBasis: '50%',
    },
    [`& .${classes.secondaryText}`]: {
        color: theme.palette.text.secondary,
    },
    [`& .${classes.amount}`]: {
        color: theme.palette.text.primary,
        fontWeight: 'bold',
    },
    [`& .${classes.icon}`]: {
        height: 24,
        width: 24,
    },
    [`& .${classes.detailsBox}`]: {
        borderColor: 'Gainsboro',
    }
}))