import React from 'react'
import PropTypes from 'prop-types'

import CheckDiagramDialog from './check-diagram-dialog'
import IconButton from '@mui/material/IconButton'

class BankRoutingNumberAccountNumberInfoIcon extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            showDialog: false
        }

        this.HandleCheckDiagramDialogClose = this.HandleCheckDiagramDialogClose.bind(this)
        this.InfoIconClick = this.InfoIconClick.bind(this)
    }

    HandleCheckDiagramDialogClose() { this.setState({ showDialog: false }) }
    InfoIconClick() { this.setState({ showDialog: true })}

    render() {
        return (
            <div className={this.props.className}>
                <IconButton
                    style={{outline: "none", zIndex: 1}}
                    onClick={this.InfoIconClick}
                    size="large">
                    <i className="material-icons md-18 srp-icon-color">info_outline</i>
                </IconButton>
                <CheckDiagramDialog showDialog={this.state.showDialog}
                    showRoutingNumberDescription={this.props.showRoutingNumberDescription}
                    showBankAccountNumberDescription={this.props.showBankAccountNumberDescription}
                    handleClose={this.HandleCheckDiagramDialogClose} />
            </div>
        )
    }
}

BankRoutingNumberAccountNumberInfoIcon.propTypes = {
    className: PropTypes.string.isRequired,
    showRoutingNumberDescription: PropTypes.bool.isRequired,
    showBankAccountNumberDescription: PropTypes.bool.isRequired
}

export default BankRoutingNumberAccountNumberInfoIcon
