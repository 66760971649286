import React from 'react'
import PropTypes from 'prop-types'

import { Trans } from 'react-i18next'

import { myAccountConstants } from '../../constants/myaccount-constants'

const UnableToReportOutage = ({t}) => {
    const supportNumber = myAccountConstants.RESIDENTIAL_CUSTOMER_SERVICE_PHONE_NUMBER_FMTTD
    const supportNumberLink = myAccountConstants.RESIDENTIAL_CUSTOMER_SERVICE_PHONE_NUMBER

    return(
        <div className="row">
            <div className="col-lg-6 col-12 mt-4">
                <div className="srp-card-header">
                    {t("Unable to report outages")}
                </div>

                <div className="srp-card-body">
                    <div className="srp-card-details text-muted">
                        <span className="d-lg-block d-none">{t("not_able_to_report_outages_desktop", {supportNumber})}</span>
                        <span className="d-lg-none d-block">
                            <Trans i18nKey="not_able_to_report_outages_mobile" t={t}>
                                We're sorry, but this account is not able to report outages online. If you are currently experiencing a power outage, please contact SRP Customer Service at <a href={"tel:"+supportNumberLink}>{{supportNumber}}</a>.
                            </Trans>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    )
}

UnableToReportOutage.propTypes = {
    t: PropTypes.func.isRequired,
}

export default UnableToReportOutage