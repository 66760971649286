import React from 'react'
import PropTypes from 'prop-types'

import Card from "@mui/material/Card"
import { withTranslation, Trans } from 'react-i18next'

import { Link } from 'react-router-dom'

import '../../styles/autopay-page.css'
import AccountInfoBarContainer from '../myaccount_header/info_bar/account-info-bar-container'
import AutopayStep from '../common_autopay/autopay-step'
import CallAnytime from '../myaccount_header/call-anytime'
import { PatternFormat } from 'react-number-format'
import { DateTime } from 'luxon'
import format from '../../srp_modules/format'
import CardLoading from '../../components/dashboard_page/card-loading'
import * as loadingStatus from '../../constants/loading-status-constants'

const AutopaySubmittedPage = ({ billAccount, customerName, bankName, routingNumber, bankAccountNumber,
    emailAddress, phoneNumber, shareAmount, isEdit, isPendingRelease, autopayInfoStatus, selectBillAccountStatus, t, i18n }) => {
    return (
        <div>
            <div className="container py-3 hidden-sm-down">
                <CallAnytime />

                <h3 className="mt-3">{t(!isEdit ? "Sign up for SurePay" : "Edit SurePay")}</h3>

                <AccountInfoBarContainer disableSelector={true} billAccount={billAccount} />

                <div className="row">
                    <div className="col-lg-6 mt-3">
                        <div>
                            <label className="h5">{t(!isEdit ? "SurePay request submitted" : "SurePay changes submitted")}</label>
                            {autopayInfoStatus !== loadingStatus.LOADING_STATUS_SUCCESS
                                && autopayInfoStatus !== loadingStatus.LOADING_STATUS_FAILURE
                                && selectBillAccountStatus !== loadingStatus.LOADING_STATUS_SUCCESS
                                && selectBillAccountStatus !== loadingStatus.LOADING_STATUS_FAILURE
                                ? (<CardLoading title={t("SurePay")} />)
                                : (
                                    <Card className="px-4 pb-4">
                                        <AutopayStep activeStep={3} t={t} />

                                        {!isEdit
                                            ? <div className="pt-2 pb-2">
                                                {t("enrollment_has_been_submitted")}
                                            </div>
                                            : null
                                        }

                                        {isEdit && isPendingRelease
                                            ? <div className="pt-2 pb-2">
                                                {t("change_has_been_submitted_next_bill")}
                                            </div>
                                            : null
                                        }

                                        {isEdit && !isPendingRelease
                                            ? <div className="pt-2 pb-2">
                                                {t("change_has_been_submitted")}
                                            </div>
                                            : null
                                        }

                                        <label className="h4 text-muted pt-2">{t("Payment information provided")}</label>

                                        <div className="row srp-row pt-1">
                                            <div className="col">
                                                {t("The_following_information_was_submitted_on_date_at_time", {date: DateTime.now().toFormat('M/d/yyyy'), time: DateTime.now().toFormat('h:mm a')})}
                                            </div>
                                        </div>

                                        <div className="row srp-row pt-2">
                                            <div className="col">
                                                <span className="srp-label">{t("SRP account")}: </span>
                                                <span className="pull-right">
                                                    <PatternFormat
                                                        value={format.srpAccountNumber(billAccount)}
                                                        valueIsNumericString={true}
                                                        format="###-###-###"
                                                        displayType="text"
                                                        style={{ width: '100%' }}
                                                    />
                                                </span>
                                            </div>
                                        </div>

                                        <div className="row srp-row">
                                            <div className="col">
                                                <span className="srp-label">{t("Name")}: </span>
                                                <span className="pull-right">{customerName}</span>
                                            </div>
                                        </div>

                                        <div className="row srp-row">
                                            <div className="col">
                                                <span className="srp-label">{t("Bank name")}: </span>
                                                <span className="pull-right">{bankName}</span>
                                            </div>
                                        </div>

                                        <div className="row srp-row">
                                            <div className="col">
                                                <span className="srp-label">{t("Routing number")}: </span>
                                                <span className="pull-right">
                                                    <PatternFormat
                                                        value={routingNumber}
                                                        valueIsNumericString={true}
                                                        format="#########"
                                                        displayType="text"
                                                        style={{ width: '100%' }}
                                                    />
                                                </span>
                                            </div>
                                        </div>

                                        <div className="row srp-row">
                                            <div className="col">
                                                <span className="srp-label">{t("Bank account number")}: </span>
                                                <span className="pull-right">
                                                    <PatternFormat
                                                        value={format.maskBankAccountNumber(bankAccountNumber)}
                                                        format="****####"
                                                        valueIsNumericString={false}
                                                        displayType="text"
                                                        style={{ width: '100%' }}
                                                    />
                                                </span>
                                            </div>
                                        </div>

                                        <div className="row srp-row">
                                            <div className="col">
                                                <span className="srp-label">{t("Email address")}: </span>
                                                <span className="pull-right">{emailAddress}</span>
                                            </div>
                                        </div>

                                        <div className="row srp-row">
                                            <div className="col">
                                                <span className="srp-label">{t("Phone number")}: </span>
                                                <span className="pull-right">
                                                    <PatternFormat
                                                        value={phoneNumber}
                                                        valueIsNumericString={true}
                                                        displayType="text"
                                                        format="(###) ###-####"
                                                        style={{ width: '100%' }}
                                                    />
                                                </span>
                                            </div>
                                        </div>

                                        {shareAmount != 0
                                            ? <div className="row srp-row">
                                                <div className="col">
                                                    <span className="srp-label">{t("SHARE amount")}: </span>
                                                    <span className="pull-right">{t("shareAmount_per_month", {shareAmount})}</span>
                                                </div>
                                            </div>
                                            : null
                                        }

                                        <div className="d-flex flex-wrap justify-content-end mt-4">
                                            <a
                                                href="https://www.savewithsrp.com/"
                                                className="btn srp-btn btn-lightblue mb-2"
                                                target="_blank">
                                                {t("Find savings & rebates")}
                                            </a>
                                            <Link to="/myaccount/dashboard" className="btn srp-btn btn-blue mb-2 ml-2">{t("My Account Home")}</Link>
                                        </div>
                                    </Card>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

AutopaySubmittedPage.propTypes = {
    billAccount: PropTypes.number.isRequired,
    customerName: PropTypes.string.isRequired,
    bankName: PropTypes.string.isRequired,
    routingNumber: PropTypes.string.isRequired,
    bankAccountNumber: PropTypes.string.isRequired,
    emailAddress: PropTypes.string.isRequired,
    phoneNumber: PropTypes.string.isRequired,
    shareAmount: PropTypes.number.isRequired,
    isEdit: PropTypes.bool.isRequired,
    isPendingRelease: PropTypes.bool.isRequired,
    autopayInfoStatus: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired,
    selectBillAccountStatus: PropTypes.string.isRequired
}

export default withTranslation("addUpdateSurePay")(AutopaySubmittedPage)