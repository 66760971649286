import { billPageConstants } from '../../../constants/bill-page-constants'
import { accountHistoryConstants } from '../../../constants/account-history-constants'

const initialState = {
    numOfBills: 12,
    numOfBillsDisplayed: 12,
    historyType: billPageConstants.HISTORY_TYPE_BILLING,
    showDetailedTransactionList: false,
    billTableStyle: {width: '100%'},
    usageTableStyle: {display: 'none'},
    accountHistoryIndex: 0
}

export default function accountHistoryTableReducer (state = initialState, action) {
    switch (action.type) {
        case billPageConstants.GET_NUM_BILLS_TO_DISPLAY:
            return { ...state, numOfBills: action.numOfBills, numOfBillsDisplayed: action.lengthOfList >= action.numOfBills ? action.numOfBills : action.lengthOfList }
        case billPageConstants.GET_ACCOUNT_HISTORY_TYPE:
            return { ...state, 
                historyType: action.historyType,
                billTableStyle: action.historyType === billPageConstants.HISTORY_TYPE_BILLING ? {width: '100%'} : {display: 'none'},
                usageTableStyle: action.historyType === billPageConstants.HISTORY_TYPE_USAGE ? {width: '100%'} : {display: 'none'}
            }
        case billPageConstants.TOGGLE_DETAILED_TRANSACTION_LIST:
            return { ...state, showDetailedTransactionList: !state.showDetailedTransactionList}
        case accountHistoryConstants.GET_ACCOUNT_HISTORY_SUCCESS:
            if(action.payload.accountHistoryList.length >= 12 ) {
                return { ...state,
                    numOfBills: 12,    
                    numOfBillsDisplayed: 12 }
            }
            else if(action.payload.accountHistoryList.length < 12 && action.payload.accountHistoryList.length > 6) {
                return { ...state,
                    numOfBills: 12,    
                    numOfBillsDisplayed: action.payload.accountHistoryList.length }
            }
            else if(action.payload.accountHistoryList.length <= 6 && action.payload.accountHistoryList.length > 3) {
                return { ...state,
                    numOfBills: 6,    
                    numOfBillsDisplayed: action.payload.accountHistoryList.length }
            }
            else {
                return { ...state,
                    numOfBills: 3,    
                    numOfBillsDisplayed: action.payload.accountHistoryList.length }
            }
        case billPageConstants.GET_NEXT_ACCT_HISTORY:
            return { ...state, accountHistoryIndex: state.accountHistoryIndex === (action.lengthOfList - 1) ? state.accountHistoryIndex : state.accountHistoryIndex + 1 }
        case billPageConstants.GET_PREV_ACCT_HISTORY:
            return { ...state, accountHistoryIndex: state.accountHistoryIndex === 0 ? 0 : state.accountHistoryIndex - 1 }
        default:
            return state
    }
}