class NetCompliantChartData{
    constructor(data){
        
        let range = this.getRange(this.json2array(data,["superOffPeak", "offPeak", "shoulder", "onPeak","total"]));
		let mins = this.getMinSeries(this.json2array(data,["superOffPeak", "offPeak", "shoulder", "onPeak","total"]),range.minY);										
		let maxs = this.getMaxSeries(this.json2array(data,["superOffPeak", "offPeak", "shoulder", "onPeak","total"]), range.maxY);
		data = this.addMinsAndMaxes(data, mins.mins,maxs.maxs);
		let offsets = this.createInvisibleSeries(data.map(function(a) {return a.superOffPeak;}),
										data.map(function(a) {return a.offPeak;}),
										data.map(function(a) {return a.shoulder;}),
										data.map(function(a) {return a.onPeak;}),
										data.map(function(a) {return a.total;}),
										data.map(function(a) {return a.min;}),
										data.map(function(a) {return a.max;}));
		this.maxY = range.maxY;
		this.minY = range.minY;			
		this.adjustedData = this.createJsonArray(data,offsets);
    }
    createInvisibleSeries(){
        let args = Array.prototype.slice.call(arguments);
        if (args.length === 0)
            return new Array();

        let invisibleSeriess = new Array(args.length);
        for (let s = 0; s < invisibleSeriess.length; s++) {
            invisibleSeriess[s] = new Array();
        }

        let firstSeries = args[0];
        for (let i = 0; i < firstSeries.length; i++) {
            let currentHeight = Math.abs(firstSeries[i]);
            let currentPosition = firstSeries[i];

            for (let j = 1; j < args.length; j++) {
                let currentSeries = args[j];

                let oppositeSign = (currentPosition > 0) ? -1 : 1;
                let invisibleValue = (currentSeries[i] * currentPosition < 0) ? currentHeight * oppositeSign : 0;

                invisibleSeriess[j - 1][i] = invisibleValue;

                currentPosition += invisibleValue + currentSeries[i];
                currentHeight += Math.abs(currentSeries[i]);
            }
        }
		return invisibleSeriess;
    }

    getRange(){
          let args = Array.prototype.slice.call(arguments)[0];
                  if (args.length === 0)
                      return new Array();
          let seriesMin = [];
          let seriesMax = [];
          for(let i=0; i<args.length; i++){
              let itemMax = 0;
              let itemMin = 0;
              for( let y=0; y<args[i].length; y++)
              {
                  if(args[i][y] < 0)
                  {
                      itemMin += args[i][y];
                  }
                  if(args[i][y] > 0)
                  {
                      itemMax += args[i][y];
                  }
              }
              seriesMin.push(itemMin);
              seriesMax.push(itemMax);
          }
          if(Math.min(...seriesMin) === 0 && Math.max(...seriesMax) === 0){
              return {minY: 0, maxY: 10}
          }
          if (Math.min(...seriesMin) == 0){
              return {minY: 0, maxY: Math.max(...seriesMax) * 1.2}
          }
          let minMax = Math.max(-1 * Math.min(...seriesMin), Math.max(...seriesMax))
          return {minY: -1 * (minMax * 1.2), maxY:  minMax * 1.2}
      
    }

    getMinSeries(series, minY){
        if (series.length === 0)
            return new Array();
        let mins = [];
        series.forEach(function(item){
            let currentTotalMin = 0;
            item.forEach(function(seriesItem){
                if(seriesItem < 0){
                    currentTotalMin += seriesItem;
                }
    
            })
            mins.push(minY - currentTotalMin);
        }); 
        return {mins: mins};
    }

    getMaxSeries(series, maxY){
        if (series.length === 0)
            return new Array();
        let maxs = [];
        series.forEach(function(item){
            let currentTotalMax = 0;
            item.forEach(function(seriesItem){
            if(seriesItem > 0){
                currentTotalMax += seriesItem;
            }
    
        })
            maxs.push(maxY - currentTotalMax);
        }); 
    return {maxs: maxs}; 
    }

    createJsonArray(data,offsets){
        for(let i = 0; i < data.length; i++){
			data[i].offsetSuperOff = offsets[0][i];
			data[i].offsetOff = offsets[1][i];
			data[i].offsetShoulder = offsets[2][i];
			data[i].offsetOn = offsets[3][i];
			data[i].offsetTotal = offsets[4][i];
			data[i].offsetMin = offsets[5][i];
			
		}
		return data;
    }
    addMinsAndMaxes(data, mins, maxs){
        for(let i = 0; i < data.length; i++){
			data[i].min = mins[i];
			data[i].max = maxs[i]
		}
		return data;
    }

    json2array(json, usageKeys){
        let result = [];
		json.forEach(function(obj){
			let keys = Object.keys(obj);
			let currentObject = [];
			keys.forEach(function(key){
				if(usageKeys.indexOf(key) > -1){
					currentObject.push(obj[key]);
				}
			});
		result.push(currentObject);
		});
		return result;
    }
    setDataType(data,isCost,isDemand,isConsumption,isGeneration){
        if(isCost){
            return this.setCost(data)
        }
        if(isDemand){
            return this.setDemand(data)
        }
        if(isConsumption || isGeneration){
            return this.setConsumptionOrGeneration(data)
        }
        return this.setKwh(data)
    }
}


export default NetCompliantChartData
