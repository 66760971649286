import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { DocumentTitle } from '../../../srp_modules/document-title'
import { withTranslation } from 'react-i18next'

import CircularProgress from '@mui/material/CircularProgress'
import Button from '@mui/material/Button'
import Icon from '@mui/material/Icon'

import AuthorizedPartySignupFormik from './authorized-party-signup-formik'
import AuthorizedPartyNotEligibleCard from './authorized-party-not-eligible-card'
import NeedHelpSidebar from './need-help-sidebar'

import AccountInfoBarContainer from '../../myaccount_header/info_bar/account-info-bar-container'
import CallAnytime from '../../myaccount_header/call-anytime'

import { getContacts } from '../../../actions/auth/contact/contact-actions'
import { getCustomerNames, getServiceAddress, getPhoneNumber } from '../../../actions/auth/bill_account/bill-account-actions'
import { submitAuthorizedPartyForm, resetAuthorizedPartyForm } from '../../../actions/auth/forms/authorized-party-actions'

import { myAccountConstants } from '../../../constants/myaccount-constants'
import * as loadingStatus from '../../../constants/loading-status-constants'

import format from '../../../srp_modules/format'
import AuthorizedPartySignupConfirmation from './authorized-party-signup-confirmation'
import { withRouter } from '../../../srp_modules/with-router'

let progressCirclestyle = {
    marginTop: "10%",
    marginBottom: "20%"
}

class AuthorizedPartyContainer extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            initialChangedState: false
        }

        this.getAuthorizedPartyDetails = this.getAuthorizedPartyDetails.bind(this)
        this.onStateChanged = this.onStateChanged.bind(this)
        this.setFormLanguage = this.setFormLanguage.bind(this)
    }

    componentDidMount() {
        if (this.props.selectedBillAccount === 0) {
            return
        }

        if (this.props.customerNamesStatus === loadingStatus.LOADING_STATUS_INIT
            || this.props.customerNamesStatus === loadingStatus.LOADING_STATUS_FAILURE) {
            this.props.getCustomerNamesInfo(this.props.selectedBillAccount)
        }
        if (this.props.contactsStatus === loadingStatus.LOADING_STATUS_INIT
            || this.props.contactsStatus === loadingStatus.LOADING_STATUS_FAILURE) {
            this.props.getContactInfo(this.props.selectedBillAccount)
        }
        if (this.props.serviceAddressStatus === loadingStatus.LOADING_STATUS_INIT
            || this.props.serviceAddressStatus === loadingStatus.LOADING_STATUS_FAILURE) {
            this.props.getServiceAddressInfo(this.props.selectedBillAccount)
        }

        if (this.props.phoneNumberStatus === loadingStatus.LOADING_STATUS_INIT
            || this.props.phoneNumberStatus === loadingStatus.LOADING_STATUS_FAILURE) {
            this.props.getBestPhone(this.props.selectedBillAccount)
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.selectedBillAccount === prevProps.selectedBillAccount || this.props.selectedBillAccount === 0)
            return

        if (this.props.customerNamesStatus !== loadingStatus.LOADING_STATUS_IN_PROGRESS) {
            this.props.getCustomerNamesInfo(this.props.selectedBillAccount)
        }

        if (this.props.contactsStatus !== loadingStatus.LOADING_STATUS_IN_PROGRESS) {
            this.props.getContactInfo(this.props.selectedBillAccount)
        }

        if (this.props.serviceAddressStatus !== loadingStatus.LOADING_STATUS_IN_PROGRESS) {
            this.props.getServiceAddressInfo(this.props.selectedBillAccount)
        }

        if (this.props.phoneNumberStatus !== loadingStatus.LOADING_STATUS_IN_PROGRESS) {
            this.props.getBestPhone(this.props.selectedBillAccount)
        }
    }

    componentWillUnmount() {
        this.props.resetAuthorizedPartyForm()
    }

    getAuthorizedPartyDetails(formValues, formActions) {
        let authorizedPartyDetails = {
            billAccount: formValues.billAccount,
            emailAddress: formValues.emailAddress,
            customerFirstName: this.props.customerNames.firstName,
            customerFullName: this.props.customerNames.displayName,
            PhoneNumber: this.props.phoneNumber,
            serviceAddress: {
                streetAddress: this.props.serviceAddress.streetAddress,
                city: this.props.serviceAddress.cityName,
                state: this.props.serviceAddress.state,
                zipCode: this.props.serviceAddress.zipCode
            },
            authorizedPartyName: formValues.authorizedPartyName,
            authorizedPartyAddress: {
                streetAddress: formValues.streetAddress,
                state: formValues.state,
                city: formValues.city,
                zipCode: formValues.zipCode
            },
            authorizedPartyEmailAddress: formValues.authorizedPartyEmailAddress,
            digitalSignature: formValues.digitalSignature,
            agreementAcknowledged: formValues.isAgreementAckd,
            action: formValues.add ? "add" : "remove",
            authorizedPartyType: formValues.company ? "company" : "individual",
            preferredLanguage: this.props.i18n.language
        }

        this.props.submitAuthorizedPartyForm(authorizedPartyDetails, formActions)
    }

    onStateChanged() {
        this.setState({
            initialChangedState: true
        })
    }

    setFormLanguage() {
        let newLang = this.props.i18n.language == "es" ? "en" : "es"
        this.props.i18n.changeLanguage(newLang)
    }

    getDetails() {
        let customerServicePhone = this.props.i18n.language === "es"
            ? myAccountConstants.SPANISH_CUSTOMER_SERVICE_PHONE_NUMBER
            : myAccountConstants.RESIDENTIAL_CUSTOMER_SERVICE_PHONE_NUMBER
        let fmttdcustomerServicePhone = this.props.i18n.language === "es"
            ? myAccountConstants.SPANISH_CUSTOMER_SERVICE_PHONE_NUMBER_FMTTD
            : myAccountConstants.RESIDENTIAL_CUSTOMER_SERVICE_PHONE_NUMBER_FMTTD

        if (this.props.customerNamesStatus === loadingStatus.LOADING_STATUS_INIT || this.props.customerNamesStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS
            || this.props.contactsStatus === loadingStatus.LOADING_STATUS_INIT || this.props.contactsStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS
            || this.props.serviceAddressStatus === loadingStatus.LOADING_STATUS_INIT || this.props.serviceAddressStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS
            || this.props.phoneNumberStatus === loadingStatus.LOADING_STATUS_INIT || this.props.phoneNumberStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS
            || this.props.enrolledProgramsDetailsStatus === loadingStatus.LOADING_STATUS_INIT || this.props.enrolledProgramsDetailsStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS
            && this.props.submitAuthorizedPartyFormStatus === loadingStatus.LOADING_STATUS_INIT) {
            return <div className="d-flex justify-content-center"><CircularProgress size={80} thickness={5} style={progressCirclestyle} /></div>
        }

        if (this.props.submitAuthorizedPartyFormStatus === loadingStatus.LOADING_STATUS_SUCCESS) {
            return (
                <div className="row">
                    <div className="col-lg-6 col-12">
                        <div className="srp-card-header" id="topOfAuthorizedPartyForm">
                            {this.props.t("Confirmation")}
                        </div>
                        <AuthorizedPartySignupConfirmation />
                    </div>
                    <NeedHelpSidebar
                        setFormLanguage={this.setFormLanguage}
                    />
                </div>)
        }

        return (<div>
            {this.props.isCommercial
                ? <div className="row">
                    <div className="col-lg-6 col-12">
                        <div className="srp-card-header" id="topOfAuthorizedPartyForm">
                            {this.props.t("Not Eligible")}
                        </div>
                        <AuthorizedPartyNotEligibleCard
                            phone={customerServicePhone}
                            fmttdPhone={fmttdcustomerServicePhone} />
                    </div>

                </div>
                : <div className="row">
                    <div className="col-lg-6 col-12">
                        <div className="srp-card-header" id="topOfAuthorizedPartyForm">
                            {this.props.t("Authorize a person or company to act on your behalf")}
                        </div>
                        <AuthorizedPartySignupFormik
                            customerName={this.props.customerNames}
                            billAccount={this.props.selectedBillAccount}
                            serviceAddress={this.props.serviceAddress}
                            formattedPhoneNumber={format.formatPhoneNumber(this.props.phoneNumber)}
                            emailAddress={this.props.customerEmailAddress}
                            getAuthorizedPartyDetails={this.getAuthorizedPartyDetails}
                            submitAuthorizedPartyFormStatus={this.props.submitAuthorizedPartyFormStatus}
                            submitAuthorizedPartyForm={this.submitAUthorizedPartyForm}
                            initialChangedState={this.state.initialChangedState}
                            onStateChanged={this.onStateChanged}
                            formLanguage={this.props.i18n.language}
                        />
                    </div>
                    <NeedHelpSidebar
                        setFormLanguage={this.setFormLanguage}
                     />

                </div>
            }
        </div>)
    }

    render() {
        return (
            <DocumentTitle title={`${this.props.t("Share Your SRP Account Information")} | SRP Power}`}>
                <div>
                    <div className="d-block d-md-none">
                        <Button
                            variant="contained"
                            fullWidth
                            className="justify-content-start nav-button-responsive"
                            onClick={() => this.props.router.navigate(-1)}
                            disableRipple>
                            <Icon className="material-icons mr-2" style={{ color: 'rgb(33,108,224' }}>keyboard_backspace</Icon>
                            {this.props.t("Previous page")}
                        </Button>
                    </div>
                    <div className="container py-3">
                        <CallAnytime />
                        <h3 className="d-lg-block d-none mt-3 mb-4 pb-4">{this.props.t("Share Your SRP Account Information")}</h3>
                        <h4 className="d-lg-none d-block mt-3 mb-4">{this.props.t("Share Your SRP Account Information")}</h4>

                        <AccountInfoBarContainer disableSelector={true} />

                        <div className="d-none d-md-block mb-4">
                            <h5 className="d-flex justify-content-start align-items-center">
                                <button className="displayAsLink" onClick={() => this.props.router.navigate(-1)}>{this.props.t("Previous page")} </button>
                                <i className="material-icons" style={{ color: 'rgba(72, 72, 74, 0.376)' }} >keyboard_arrow_right</i>
                                <span style={{ color: '#707070' }}>{this.props.t("Share Your SRP Account Information")}</span>
                            </h5>
                        </div>

                        {this.getDetails()}

                    </div>
                </div>
            </DocumentTitle>
        )
    }
}

const mapStateToProps = state => {
    return {
        selectedBillAccount: state.accountInfo.billAccount.selectedBillAccount,
        isCommercial: state.accountInfo.billAccount.selectedBillAccountDetails.isCommercial,
        isPrePay: state.accountInfo.billAccount.selectedBillAccountDetails.isPrePay,
        customerNames: state.accountInfo.billAccount.customerNames,
        customerNamesStatus: state.accountInfo.billAccount.customerNamesStatus,
        serviceAddress: state.accountInfo.billAccount.serviceAddress,
        serviceAddressStatus: state.accountInfo.billAccount.serviceAddressStatus,
        contactsStatus: state.accountInfo.contact.contactsStatus,
        customerEmailAddress: state.accountInfo.contact.primaryEmail,
        phoneNumber: state.accountInfo.billAccount.phoneNumber.bestPhone,
        phoneNumberStatus: state.accountInfo.billAccount.phoneNumberStatus,
        ...state.authorizedParty
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getContactInfo: (billAccount) => {
            dispatch(getContacts(billAccount))
        },
        getCustomerNamesInfo: (billAccount) => {
            dispatch(getCustomerNames(billAccount))
        },
        getServiceAddressInfo: (billAccount) => {
            dispatch(getServiceAddress(billAccount))
        },
        getBestPhone: (billAccount) => {
            dispatch(getPhoneNumber(billAccount))
        },
        submitAuthorizedPartyForm: async (authorizedPartyDetails, formActions) => {
            let res = {}
            res = await dispatch(submitAuthorizedPartyForm(authorizedPartyDetails))

            const submitSuccessfully = (res.payload?.success === true)

            formActions.setStatus({ submitError: !submitSuccessfully })
            formActions.setSubmitting(false)

            if (!submitSuccessfully && document.getElementById("topOfAuthorizedPartyForm")) {
                document.getElementById("topOfAuthorizedPartyForm").scrollIntoView()
                return
            }
        },
        resetAuthorizedPartyForm: () => {
            dispatch(resetAuthorizedPartyForm())
        }
    }
}

AuthorizedPartyContainer.propTypes = {
    router: PropTypes.shape({
        navigate: PropTypes.func
    }),

    enrolledProgramsDetailsStatus: PropTypes.string,

    selectedBillAccount: PropTypes.number,
    isCommercial: PropTypes.bool,
    isPrePay: PropTypes.bool,
    myAccountPreferredLanguage: PropTypes.string,

    customerNames: PropTypes.object,
    customerNamesStatus: PropTypes.string,
    getCustomerNamesInfo: PropTypes.func,

    contactsStatus: PropTypes.string,
    getContactInfo: PropTypes.func,
    customerEmailAddress: PropTypes.string,

    serviceAddressStatus: PropTypes.string,
    serviceAddress: PropTypes.object,
    getServiceAddressInfo: PropTypes.func,

    phoneNumberStatus: PropTypes.string,
    phoneNumber: PropTypes.string,
    getBestPhone: PropTypes.func,

    resetAuthorizedPartyForm: PropTypes.func,
    submitAuthorizedPartyForm: PropTypes.func,
    submitAuthorizedPartyFormStatus: PropTypes.string,

    t: PropTypes.func,
    i18n: PropTypes.object,
}

export default withTranslation('authorizedParty')(withRouter(connect(mapStateToProps, mapDispatchToProps)(AuthorizedPartyContainer)))