import { guestPaymentListConstants } from '../../../constants/guest/guest-payment-list-constants'

const initialState = {
    paymentList: [],
    multiplePaymentsEnabled: false,
    formStartEventEmitted: false
}

export default function guestPaymentListReducer(state = initialState, action) {
    switch (action.type) {
        case guestPaymentListConstants.ADD_GUEST_PAYMENT_TO_LIST:
            return { ...state,
                paymentList: [...state.paymentList, {srpAccountNumber: action.srpAccountNumber, paymentAmount: action.paymentAmount}]
            }
        case guestPaymentListConstants.STORE_GUEST_PAYMENT_LIST:
            return { ...state,
                paymentList: action.paymentList
            }
        case guestPaymentListConstants.CLEAR_GUEST_PAYMENT_LIST:
            return { ...state,
                paymentList: []
            }
        case guestPaymentListConstants.ENABLE_MULTIPLE_GUEST_PAYMENTS:
            return { ...state,
                multiplePaymentsEnabled: true
            }
        case guestPaymentListConstants.DISABLE_MULTIPLE_GUEST_PAYMENTS:
            return { ...state,
                multiplePaymentsEnabled: false
            }
        case guestPaymentListConstants.SET_FORM_START_EVENT_EMITTED:
            return { ...state,
                formStartEventEmitted: true
            }
        case guestPaymentListConstants.CLEAR_FORM_START_EVENT_EMITTED:
            return { ...state,
                formStartEventEmitted: false
            }
        default:
            return state
    }
}