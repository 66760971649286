import {prepayHistoryConstants} from '../../../constants/payment/prepay-history-constants'
import {billAccountConstants} from '../../../constants/bill-account-constants'
import * as loadingStatus from '../../../constants/loading-status-constants'

const initialState = {
    monthlyPrepayPurchasesList:[],
    mostRecentPayment: 0,
    mostRecentPaymentDate: new Date(),
    prepayHistoryStatus: loadingStatus.LOADING_STATUS_INIT,

    purchaseHistoryDetails: [
        {
            transactionDescription: "",
            purchaseDate: new Date(0),
            paidAmount: 0,
            adjustments: 0,
            amountToArrears: 0,
            powerCredit: 0,
            isAuthorized: false,
            meterBalanceAfterCreditApplied: 0,
            meterUpdateTimestamp: new Date(0),
            transactionOrder: 0
        }
    ],
    purchaseHistoryDetailsStatus: loadingStatus.LOADING_STATUS_INIT
}

export default function prepayHistoryRedducer(state = initialState, action){
    switch(action.type){
        case prepayHistoryConstants.GET_PREPAY_HISTORY_REQUEST:
            return {
                ...state,
                prepayHistoryStatus: loadingStatus.LOADING_STATUS_IN_PROGRESS
            }
        case prepayHistoryConstants.GET_PREPAY_HISTORY_SUCCESS:
            return{
                ...state,
                monthlyPrepayPurchasesList: action.payload.monthlyPurchaseList,
                mostRecentPayment: action.payload.mostRecentPayment,
                mostRecentPaymentDate: action.payload.mostRecentPaymentDate,
                prepayHistoryStatus: loadingStatus.LOADING_STATUS_SUCCESS
            }
        case prepayHistoryConstants.GET_PREPAY_HISTORY_FAILURE:
            return{
                ...state,
                monthlyPrepayPurchasesList:[],
                mostRecentPayment:0,
                mostRecentPaymentDate: new Date(),
                prepayHistoryStatus: loadingStatus.LOADING_STATUS_FAILURE
            }

        case prepayHistoryConstants.GET_PREPAY_PURCHASE_HISTORY_DETAILS_REQUEST:
            return { ...state, purchaseHistoryDetailsStatus: loadingStatus.LOADING_STATUS_IN_PROGRESS }
        case prepayHistoryConstants.GET_PREPAY_PURCHASE_HISTORY_DETAILS_SUCCESS:
            return { ...state, purchaseHistoryDetails: action.payload, purchaseHistoryDetailsStatus: loadingStatus.LOADING_STATUS_SUCCESS }
        case prepayHistoryConstants.GET_PREPAY_PURCHASE_HISTORY_DETAILS_FAILURE:
            return { ...state, purchaseHistoryDetails: initialState.purchaseHistoryDetails, purchaseHistoryDetailsStatus: loadingStatus.LOADING_STATUS_FAILURE }
        
        case billAccountConstants.SELECT_BILL_ACCOUNT_SUCCESS:
            return initialState
        default:
            return state
    }
}