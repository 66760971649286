export const formStopConstants = {
  CHANGE_STOP_DATE: "CHANGE_STOP_DATE",
  CHANGE_ADDRESS1: "CHANGE_ADDRESS1",
  CHANGE_ADDRESS2: "CHANGE_ADDRESS2",
  CHANGE_CITY: "CHANGE_CITY",
  CHANGE_STATE: "CHANGE_STATE",
  CHANGE_COUNTRY: "CHANGE_COUNTRY",
  CHANGE_ZIPCODE: "CHANGE_ZIPCODE",
  CHANGE_PHONENUMBER: "CHANGE_PHONENUMBER",
  CHANGE_EMAIL: "CHANGE_EMAIL",
  CHANGE_CONFIRM_EMAIL: "CHANGE_CONFIRM_EMAIL",
  CHANGE_CONFIRM_PHONE: "CHANGE_CONFIRM_PHONE",

  SHOW_REVIEW: "SHOW_REVIEW",
  SHOW_DETAIL_FORM: "SHOW_DETAIL_FORM",

  RUN_VALIDATION_CHECK: "RUN_VALIDATION_CHECK",

  SUBMIT_FORM: "SUBMIT_FORM",

  SUBMIT_TURNOFF_SUCCESS: "SUBMIT_TURNOFF_SUCCESS",
  SUBMIT_TURNOFF_FAILURE: "SUBMIT_TURNOFF_FAILURE",
  SUBMIT_TURNOFF_REQUEST: "SUBMIT_TURNOFF_REQUEST",

  HOLIDAY_REQUEST: "HOLIDAY_REQUEST",
  HOLIDAY_SUCCESS: "HOLIDAY_SUCCESS",
  HOLIDAY_FAILURE: "HOLIDAY_FAILURE",

  SEND_ENVELOPE: "SEND_ENVELOPE"
}