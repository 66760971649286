import React from 'react'
import PropTypes from 'prop-types'

import utils from '../../../srp_modules/utils'

const DocumentCenterDocError = ({ isCommercial, t }) => {
    return (
        <div className="srp-alert-error" style={{marginTop:'-15px', marginBottom: '20px'}}>
            {t("document_unavailable", {phoneNumber: utils.getFormattedCSPhoneNumber(isCommercial)})}
        </div>
    )
}

DocumentCenterDocError.propTypes = {
    isCommercial: PropTypes.bool.isRequired,
    t: PropTypes.func
}

export default DocumentCenterDocError