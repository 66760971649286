import React from 'react'
import PropTypes from 'prop-types'
import TextField from '@mui/material/TextField'

const UsernameField = ({username, usernameOnChange, usernameOnBlur, idSuffix, isLoginError, loginErrorText, t}) => {
    let id_name = 'username' + idSuffix
    return (
        <TextField 
            fullWidth={true}
            id={id_name}
            name="username"
            label={t("Email or username")}
            value={username}
            error={isLoginError}
            helperText={loginErrorText}
            onChange={usernameOnChange}
            onBlur={usernameOnBlur}
            autoFocus={username === undefined || username.length === 0} />
    )
}

UsernameField.propTypes = {
    username: PropTypes.string.isRequired,
    usernameOnChange: PropTypes.func.isRequired,
    usernameOnBlur: PropTypes.func.isRequired,
    idSuffix: PropTypes.string.isRequired,
    loginErrorText: PropTypes.string,
    isLoginError: PropTypes.bool,
    t: PropTypes.func
}

export default UsernameField