import React from 'react'
import PropTypes from 'prop-types'
import { DateTime } from 'luxon'

import { Trans } from 'react-i18next'

import utils from '../../../srp_modules/utils'

const CardBillClosedAcct = ({ isMPower, isCommercial, closedDate, t, i18n }) => {
    const isSpanish = i18n.language==="es"
    const localizedClosedDate = DateTime.fromISO(closedDate).toFormat(isSpanish ? "d/M/yyyy" : "M/d/yyyy")
    const csPhoneNumber = isSpanish ? utils.getCSSpanishPhoneNumber() : utils.getCSPhoneNumber(isCommercial)
    const formattedCSPhoneNumber = isSpanish ? utils.getFormattedCSSpanishPhoneNumber() : utils.getFormattedCSPhoneNumber(isCommercial)

    return (
    <div className="srp-card">
        <div className="srp-card-header">
            {t(isMPower ? "My M-Power" : "My bill")}
        </div>
        <div className="srp-card-body">
            <div className="srp-card-summary d-flex justify-content-around" style={{ backgroundColor: '#747474' }}>
                <div className="d-flex flex-column align-items-center text-white">
                    <div className="h4">{t("Account closed")}</div>
                    {t("as_of_date", {closedDate: localizedClosedDate})}
                </div>
            </div>
            <div className="srp-card-details d-flex flex-column" style={{ color: '#707070' }}>
                <div>
                    <div className="d-lg-block d-none">
                        {t("If_you_have_any_questions_desktop", {phone: formattedCSPhoneNumber})}
                    </div>
                    <div className="d-block d-lg-none">
                        <Trans i18nKey="If_you_have_any_questions_mobile" t={t}>
                        If you have any questions regarding your account, please call SRP Customer Service at <a href={"tel:"+csPhoneNumber}>{{phone: formattedCSPhoneNumber}}</a>.
                        </Trans>
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
}

CardBillClosedAcct.propTypes = {
    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired,

    isMPower: PropTypes.bool,
    isCommercial: PropTypes.bool,
    closedDate: PropTypes.string
}


export default CardBillClosedAcct