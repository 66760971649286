import { singlePurchaseBankInfoConstants } from '../../../constants/single-purchase/single-purchase-bank-info-constants'
import { singlePurchaseApiConstants } from '../../../constants/single-purchase/single-purchase-api-constants'

const initialState = {
    bankFormOpen: false,

    bankFirstName: '',
    bankLastName: '',
    bankPhoneNumber: '',
    bankEmailAddress: '',
    bankRoutingNumber: '',
    bankNickname: '',
    bankAccountNumberLeadingZeroesAreSignificant: '',

    selectedBankId: 0
}

export default function singlePurchaseBankInfoReducer(state = initialState, action) {
    switch (action.type) {
        case singlePurchaseBankInfoConstants.SINGLE_PURCHASE_STORE_BANK_INFO_COLLECTED:
            return { ...state,
                bankFirstName: action.bankFirstName,
                bankLastName: action.bankLastName,
                bankPhoneNumber: action.bankPhoneNumber,
                bankEmailAddress: action.bankEmailAddress,
                bankRoutingNumber: action.bankRoutingNumber,
                bankAccountNumberLeadingZeroesAreSignificant: action.bankAccountNumberLeadingZeroesAreSignificant,
                bankNickname: action.bankNickname
            }
        case singlePurchaseBankInfoConstants.SINGLE_PURCHASE_CLEAR_BANK_INFO:
            return { ...state, ...initialState }

        case singlePurchaseBankInfoConstants.SET_BANK_ID_FOR_SINGLE_PURCHASE:
            return { ...state, selectedBankId: action.selectedBankId }

        case singlePurchaseBankInfoConstants.SINGLE_PURCHASE_SET_BANK_FORM_OPEN:
            return { ...state, bankFormOpen: true }
        case singlePurchaseBankInfoConstants.SINGLE_PURCHASE_CLEAR_BANK_FORM_OPEN:
            return { ...state, bankFormOpen: false }
        case singlePurchaseApiConstants.VERIFY_SINGLE_PURCHASE_LIST_SUCCESS:
            return {
                ...state,
                bankFormOpen: false,
                bankRoutingNumber: '',
                bankAccountNumberLeadingZeroesAreSignificant: '',
                bankNickname: '' }

        default:
            return state
    }
}