import React from 'react'
import PropTypes from 'prop-types'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { DateTime } from 'luxon'
import { CustomLuxonDateAdapter } from '../../../srp_modules/custom-luxon-date-adapter'

const DateInput = props => {
    let meta = {
        id: props.id,
        name: props.id
    }
    let isError = props.touched[props.id]
        && Object.prototype.hasOwnProperty.call(props.errors, props.id)
    let errMsg = isError ? props.errors[props.id] : ''

    return (
        <div>
            <LocalizationProvider dateAdapter={CustomLuxonDateAdapter} adapterLocale="en-US">
                <DatePicker
                    {...meta}
                    {...props.dateProps} // spread operators need to happen first, otherwise values won't be overridden below
                    animateYearScrolling={false}
                    className="mr-4 chart-date-picker fullWidth"
                    disabled={props.dateProps.disablePast &&  props.dateProps.disableFuture}
                    onChange={props.onChange}
                    onBlur={props.onBlur}
                    slotProps={{ textField: { helperText: errMsg, fullWidth: true }}}
                    value={DateTime.fromISO(props.value)}
                    maxDate={DateTime.fromISO(props.dateProps.maxDate)}
                    minDate={DateTime.fromISO(props.dateProps.minDate)}
                    label={props.label}
                />
            </LocalizationProvider>
        </div>
    )
}

DateInput.propTypes = {
    dateProps: PropTypes.shape({
        disablePast: PropTypes.bool.isRequired,
        disableFuture: PropTypes.bool.isRequired,
        invalidDateMessage: PropTypes.string.isRequired,
        minDate: PropTypes.string.isRequired,
        maxDate: PropTypes.string.isRequired,
        minDateMessage: PropTypes.string.isRequired,
        maxDateMessage: PropTypes.string.isRequired
    }).isRequired,
    id: PropTypes.string.isRequired,
    errors: PropTypes.object.isRequired,
    label: PropTypes.string.isRequired,
    onBlur: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    touched: PropTypes.object.isRequired,
    value: PropTypes.string.isRequired
}

export default DateInput