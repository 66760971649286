import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import config from 'my-account-config'

import homeEnergyProfile from '../../images/ads/homeEnergyProfile.jpg'
import homeEnergyProfileEs from '../../images/ads/homeEnergyProfileEs.jpg'

const HomeEnergyProfileAd = ({hashedAccountForOpower, singleBillAccount, t, isSpanish}) => {
    let homeEnergyProfileLink = (hashedAccountForOpower || singleBillAccount) ? config.homeEnergyProfile : ''

    if (hashedAccountForOpower) {
        const queryParam = `ou-entity-id=${hashedAccountForOpower}`
        const homeEnergyProfileLinkHasQueryParam = (homeEnergyProfileLink.indexOf('?') > -1)

        homeEnergyProfileLink += (homeEnergyProfileLinkHasQueryParam ? '&' : '?') + queryParam
    }

    return (
        <Link to={homeEnergyProfileLink}>
            <img alt={t("Home Energy Profile")} className="img-fluid w-100" src={isSpanish ? homeEnergyProfileEs : homeEnergyProfile} />
        </Link>
    )
}

HomeEnergyProfileAd.propTypes = {
    hashedAccountForOpower: PropTypes.string.isRequired,
    singleBillAccount: PropTypes.bool.isRequired,
    t: PropTypes.func.isRequired,
    isSpanish: PropTypes.bool.isRequired,
}

export default HomeEnergyProfileAd