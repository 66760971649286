import React from 'react'
import { createRoot } from 'react-dom/client'
import { createBrowserHistory } from 'history'
import Root from './components/root'

import configureStore from './store/configure-store'
require('./favicon.ico') // eslint-disable-line import/default
import './styles/site.css'
import './styles/myaccount.css'
import './styles/mui-tabs.css'

import './i18n'

const initialState = { }
const history  = createBrowserHistory()
const store = configureStore(initialState, history)

const container = document.getElementById('app')
const root = createRoot(container)
root.render(<Root store={store} history={history} />)