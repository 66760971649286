export const eBillConstants = {
    GET_E_BILL_LIST_REQUEST: 'GET_E_BILL_LIST_REQUEST',
    GET_E_BILL_LIST_SUCCESS: 'GET_E_BILL_LIST_SUCCESS',
    GET_E_BILL_LIST_FAILURE: 'GET_E_BILL_LIST_FAILURE',

    GET_E_BILL_DOCUMENT_REQUEST: 'GET_E_BILL_DOCUMENT_REQUEST',
    GET_E_BILL_DOCUMENT_SUCCESS: 'GET_E_BILL_DOCUMENT_SUCCESS',
    GET_E_BILL_DOCUMENT_FAILURE: 'GET_E_BILL_DOCUMENT_FAILURE',

    GET_E_BILL_REMINDER_REQUEST: 'GET_E_BILL_REMINDER_REQUEST',
    GET_E_BILL_REMINDER_SUCCESS: 'GET_E_BILL_REMINDER_SUCCESS',
    GET_E_BILL_REMINDER_FAILURE: 'GET_E_BILL_REMINDER_FAILURE',

    DOCUMENT_TYPE_UNKNOWN: 0,
    DOCUMENT_TYPE_BILL: 1,
    DOCUMENT_TYPE_REMINDER: 2
}