import React from 'react'
import PropTypes from 'prop-types'
import TextField from '@mui/material/TextField'
import { NumericFormat } from 'react-number-format'

const PaymentInput = (props) => {
    let inputRef = React.useRef()

    let isError = props.touched[props.id]
        && Object.prototype.hasOwnProperty.call(props.errors, props.id)
    let errMsg = isError ? props.errors[props.id] : ''
    let meta = {
        id: props.id,
        name: props.id,
            helperText: errMsg==="" ? "" : props.t(errMsg),
        error: isError
    }

    return (
        <div>
            <NumericFormat
                {...meta}
                getInputRef={inputRef}
                value={props.value}
                valueIsNumericString={true}
                fullWidth={true}
                onValueChange={props.onValueChange}
                onBlur={() => props.setFieldValue(props.id, Number(props.value).toFixed(2), false)}
                decimalScale={2}
                thousandSeparator={true}
                prefix={'$'}
                fixedDecimalScale={false}
                allowNegative={false}
                customInput={TextField}
                isAllowed={field => field.floatValue === undefined || field.floatValue < 1000000000.00}
                type="text"
                label={props.t(props.label)}
            />
        </div>
    )
}

PaymentInput.propTypes = {
    t: PropTypes.func.isRequired,
    touched: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    onValueChange: PropTypes.func.isRequired,
    setFieldValue: PropTypes.func.isRequired
}

export default PaymentInput
