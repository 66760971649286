/* eslint react/no-did-update-set-state: "off" */
import React from 'react'
import { connect } from 'react-redux'
import cookies from 'js-cookie'
import PropTypes from 'prop-types'

import InputLabel from '@mui/material/InputLabel'
import { PatternFormat } from 'react-number-format'
import CircularProgress from '@mui/material/CircularProgress'
import Dialog from '@mui/material/Dialog'

import * as loadingStatus from '../../constants/loading-status-constants'
import {
    getPrimaryContactInfoReminder,
    setPrimaryContactInfoReminderHasUpdated,
    setPrimaryContactInfoReminderWasReminded
} from '../../actions/auth/bill_account/primary-contact-info-reminder-actions'
import { getBillingAddress } from '../../actions/auth/bill_account/bill-account-actions'
import { getNotifications } from '../../actions/auth/notification/notification-actions'
import { getContacts } from '../../actions/auth/contact/contact-actions'
import { withRouter } from '../../srp_modules/with-router'
import { withTranslation } from 'react-i18next'

class PrimaryContactInfoReminderModal extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            modalIsOpen: false,
            isLoading: false
        }

        this.remindMeLaterOnClick = this.remindMeLaterOnClick.bind(this)
        this.confirmOnClick = this.confirmOnClick.bind(this)
        this.editOnClick = this.editOnClick.bind(this)
    }

    componentDidMount() {
        this.loadReminderData(null, this.props)
    }

    UNSAFE_componentWillUpdate(nextProps) {
        this.loadReminderData(this.props, nextProps)
    }

    componentDidUpdate() {
        if (this.shouldRemind() && this.props.selectedBillAccount > 0) {
            if (this.state.modalIsOpen === false) {
                this.setState({ modalIsOpen: true })
            }

            this.loadPrimaryContactInfoData(this.props.selectedBillAccount)
        }
    }

    loadReminderData(props, nextProps) {
        if ((!props || props.selectedBillAccount !== nextProps.selectedBillAccount) && nextProps.selectedBillAccount > 0) {
            if (nextProps.contactInfoReminderStatus !== loadingStatus.LOADING_STATUS_IN_PROGRESS) {
                this.props.loadContactReminderInfo(nextProps.selectedBillAccount)
            }
        }
    }

    loadPrimaryContactInfoData(billAccount) {
        if (this.props.notificationsStatus === loadingStatus.LOADING_STATUS_INIT) {
            this.props.getNotificationsInfo(billAccount)
        }

        if (this.props.billingAddressStatus === loadingStatus.LOADING_STATUS_INIT) {
            this.props.getBillingAddress(billAccount)
        }

        if (this.props.contactsStatus === loadingStatus.LOADING_STATUS_INIT) {
            this.props.getContactsInfo(billAccount)
        }
    }

    isLoaded() {
        if (this.props.notificationsStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS) {
            return false
        }

        if (this.props.billingAddressStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS) {
            return false
        }

        if (this.props.contactsStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS) {
            return false
        }

        return true
    }

    closeModal() {
        this.setState({ ...this.state, modalIsOpen: false })
    }

    shouldRemind() {
        let remind = !this.getPrimaryContactInfoSessionReminder() && this.props.needsReminder
        return remind
    }

    setReminders(billAccount) {
        this.setPrimaryContactInfoSessionReminder()
        this.props.wasReminded(billAccount)
    }

    remindMeLaterOnClick(billAccount) {
        this.closeModal()
        this.setReminders(billAccount)
    }

    confirmOnClick(billAccount) {
        this.closeModal()
        this.setReminders(billAccount)
        this.props.confirmContactInfo(billAccount)
    }

    editOnClick(billAccount) {
        this.closeModal()
        this.setReminders(billAccount)

        this.props.router.navigate('/myaccount/profile/alerts')
    }

    setPrimaryContactInfoSessionReminder() {
        cookies.set('contactreminder', 'true', { secure: true, sameSite: 'Strict' })
    }

    getPrimaryContactInfoSessionReminder() {
        let wasRemindedThisSession = cookies.get('contactreminder') === undefined ? false : true
        return wasRemindedThisSession
    }

    getAddressStreet(address) {
        if (address) {
            let street = address.streetAddress
            return street
        }

        return ''
    }

    getAddressCityStateZip(address) {
        if (address) {
            if (address.city.length > 0 && address.state.length > 0 && address.zipcode.length > 0) {
                let cityStateZip = address.city + ', ' + address.state + ' ' + address.zipcode
                return cityStateZip
            }
        }

        return ''
    }

    render() {
        if (this.shouldRemind() === true) {
            let buttons = []
            let title = ''
            let body = ''

            let reminderButtons = (<div key="1" className="row text-end pr-3">
                <button key="1" className="btn srp-btn btn-lightblue ml-2 mt-1" type="button" onClick={() => { this.remindMeLaterOnClick(this.props.selectedBillAccount) }}>{this.props.t("Remind me later")}</button>
                <button key="2" className="btn srp-btn btn-lightblue ml-2 mt-1" type="button" onClick={() => { this.editOnClick(this.props.selectedBillAccount) }}>{this.props.t("Edit")}</button>
                <button key="3" className="btn srp-btn btn-lightblue ml-2 mt-1" type="button" onClick={() => { this.confirmOnClick(this.props.selectedBillAccount) }}>{this.props.t("Confirm")}</button>
            </div>)

            let contactInfo = this.isLoaded() === false
                ? (<div className="d-flex justify-content-center p-4">
                    <CircularProgress size={80} thickness={5} />
                </div>)
                :
                (<React.Fragment>
                    <div>
                        <InputLabel className="font-14 text-muted" htmlFor="primaryEmailAddress">{this.props.t("Primary Email address")}</InputLabel>
                        <div id="primaryEmailAddress" className="pt-1 font-16 srp-gray-color font-weight-bold">
                            {this.props.primaryEmailAddress}
                        </div>
                    </div>

                    <div className="mt-4">
                        <InputLabel className="font-14 text-muted" htmlFor="primaryPhoneNumber">{this.props.t("Primary Phone number")}</InputLabel>
                        <div>
                            <PatternFormat
                                id="primaryPhoneNumber"
                                name="primaryPhoneNumber"
                                className="font-16 srp-gray-color font-weight-bold"
                                value={this.props.primaryPhoneNumber}
                                valueIsNumericString={true}
                                format="(###) ###-####"
                                type="tel"
                                displayType="text"
                            />
                        </div>
                    </div>

                    <div className="mt-4">
                        <InputLabel className="font-14 text-muted" htmlFor="mailingAddressStreet">{this.props.t("Mailing address")}</InputLabel>
                        <div className="font-16 srp-gray-color font-weight-bold">
                            {this.getAddressStreet(this.props.mailingAddress)}
                        </div>
                        <div className="font-16 srp-gray-color font-weight-bold">
                            {this.getAddressCityStateZip(this.props.mailingAddress)}
                        </div>
                    </div>

                    <div className="mt-4">
                        <InputLabel className="text-muted font-14" htmlFor="primaryPhoneNumber">{this.props.t("Paperless billing is")}</InputLabel>
                        <span className="pl-1 font-16 srp-gray-color font-weight-bold"><b>{this.props.hasEBill ? this.props.t("ON") : this.props.t("OFF")}</b></span>
                    </div>
                </React.Fragment>)

            buttons.push(reminderButtons)
            title = this.props.t("Let’s verify your contact information")
            body = (<React.Fragment>
                <p>{this.props.t("SRP wants to make sure you receive the latest information about your accounts. Please take a moment to review your contact information and make sure everything is correct. You can make changes if needed. Thank you for helping us keep you connected.")}</p>
                <div>{contactInfo}</div>
            </React.Fragment>)

            let reminderModal = (
                <React.Fragment>
                    <div>
                        <Dialog
                            className="d-block d-lg-none"
                            fullScreen={true}
                            fullWidth={true}
                            open={this.state.modalIsOpen}
                        >
                            <div className="srp-modal-body">
                                <div>
                                    <div className="srp-modal-header">
                                        <h4 className="srp-modal-title">{title}</h4>
                                    </div>
                                    <div>
                                        {body}
                                        <div className="srp-modal-footer">
                                            {buttons}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Dialog>
                    </div>
                    <div>
                        <Dialog
                            className="d-none d-lg-block"
                            modal="true"
                            open={this.state.modalIsOpen}
                            PaperProps={{ className: "srp-modal" }}
                        >
                            <div className="srp-modal-body-scroll">
                                <div>
                                    <div className="srp-modal-header">
                                        <h4 className="srp-modal-title">{title}</h4>
                                    </div>
                                    <div>
                                        {body}
                                        <div className="srp-modal-footer">
                                            {buttons}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Dialog>
                    </div>
                </React.Fragment>
            )

            return reminderModal
        }

        return null
    }
}

const mapStateToProps = state => {
    return {
        needsReminder: state.accountInfo.billAccount.primaryContactInfoReminder.needsReminder,
        selectedBillAccount: state.accountInfo.billAccount.selectedBillAccount,

        primaryEmailAddress: state.accountInfo.contact.primaryEmail,
        primaryPhoneNumber: state.accountInfo.contact.primaryPhone,
        contactsStatus: state.accountInfo.contact.contactsStatus,

        mailingAddress: state.accountInfo.billAccount.billingAddress,
        billingAddressStatus: state.accountInfo.billAccount.billingAddressStatus,

        hasEBill: state.accountInfo.notification.eBillState,
        eBillStateStatus: state.accountInfo.notification.eBillStateStatus,
        notificationsStatus: state.accountInfo.notification.eBillStateStatus,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        loadContactReminderInfo: (billAccount) => {
            dispatch(getPrimaryContactInfoReminder(billAccount))
        },
        confirmContactInfo: (billAccount) => {
            dispatch(setPrimaryContactInfoReminderHasUpdated(billAccount))
        },
        wasReminded: (billAccount) => {
            dispatch(setPrimaryContactInfoReminderWasReminded(billAccount))
        },
        getBillingAddress: (billAccount) => {
            dispatch(getBillingAddress(billAccount))
        },
        getNotificationsInfo: (billAccount) => {
            dispatch(getNotifications(billAccount))
        },
        getContactsInfo: (billAccount) => {
            dispatch(getContacts(billAccount))
        }
    }
}

PrimaryContactInfoReminderModal.propTypes = {
    needsReminder: PropTypes.bool,
    selectedBillAccount: PropTypes.number,
    loadContactReminderInfo: PropTypes.func,

    primaryEmailAddress: PropTypes.string,
    primaryPhoneNumber: PropTypes.string,
    contactsStatus: PropTypes.string,

    mailingAddress: PropTypes.object,
    billingAddressStatus: PropTypes.string,

    hasEBill: PropTypes.bool,
    eBillStateStatus: PropTypes.string,
    notificationsStatus: PropTypes.string,

    confirmContactInfo: PropTypes.func,
    wasReminded: PropTypes.func,
    getBillingAddress: PropTypes.func,
    getNotificationsInfo: PropTypes.func,
    getContactsInfo: PropTypes.func,

    router: PropTypes.shape({
        navigate: PropTypes.func
    }),

    t: PropTypes.func
}


export default withTranslation("primaryContactInfoReminder")(withRouter(connect(mapStateToProps, mapDispatchToProps)(PrimaryContactInfoReminderModal)))