import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { useLocation } from 'react-router-dom'

import DashboardPage from './dashboard-page'
import MyAccountMainContainer from '../../containers/my-account-main-container'

import { closePasswordResetSuccessMessage } from '../../actions/auth/login/login-actions'
import * as loadingStatus  from '../../constants/loading-status-constants'
import { billAccountConstants } from '../../constants/bill-account-constants'
import TransferredAccount from './transferred-account'

const DashboardPageContainer = props => {
    let locationHook = useLocation()

    return (
        <MyAccountMainContainer pageTitle="Dashboard" navLocation={locationHook.pathname}>
        <DashboardPage
            passwordResetComplete={props.passwordResetComplete}
            closePasswordResetSuccessMessageOnClick={props.closePasswordResetSuccessMessageOnClick}
            accountClosedDate={props.selectBillAccountStatus === loadingStatus.LOADING_STATUS_SUCCESS ? props.closedDate : null}
            isPendingTurnOn={props.isPendingTurnOn}
            navLocation={locationHook.pathname}
            isClosed={props.closedState === billAccountConstants.CLOSED_STATE.CLOSED}
            />
        <TransferredAccount />
        </MyAccountMainContainer>
    )
}

const mapStateToProps = state => {
    return { ...state.login,
        isPendingTurnOn: state.accountInfo.billAccount.selectedBillAccountDetails.isPendingTurnOn,
        closedDate: state.accountInfo.billAccount.selectedBillAccountDetails.closedDate,
        selectBillAccountStatus: state.accountInfo.billAccount.selectBillAccountStatus,
        selectedBillAccount: state.accountInfo.billAccount.selectedBillAccount,
        billAccountListStatus: state.accountInfo.billAccount.billAccountListStatus,
        closedState: state.accountInfo.billAccount.selectedBillAccountDetails.closedState }
}

const mapDispatchToProps = dispatch => {
    return {
        closePasswordResetSuccessMessageOnClick: () => {
            dispatch(closePasswordResetSuccessMessage())
        }
    }
}

DashboardPageContainer.propTypes = {
    passwordResetComplete: PropTypes.bool,
    closePasswordResetSuccessMessageOnClick: PropTypes.func,
    closedDate: PropTypes.string,
    selectBillAccountStatus: PropTypes.string,
    selectedBillAccount: PropTypes.number,
    billAccountListStatus: PropTypes.string,
    isPendingTurnOn: PropTypes.bool,
    closedState: PropTypes.number
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardPageContainer)