import { singlePaymentConstants } from '../../../constants/single-payment/single-payment-constants'
import { singlePaymentBankInfoConstants } from '../../../constants/single-payment/single-payment-bank-info-constants'

export const storeBankInfoCollected = ({isDefaultBankAccount, forUseWithIVR}) => ({
    type: singlePaymentBankInfoConstants.SINGLE_PAYMENT_STORE_BANK_INFO_COLLECTED,
    isDefaultBankAccount,
    forUseWithIVR
})

export const clearBankInfo = () => ({
    type: singlePaymentBankInfoConstants.SINGLE_PAYMENT_CLEAR_BANK_INFO
})

export const setBankFormOpen = () => ({
    type: singlePaymentBankInfoConstants.SINGLE_PAYMENT_SET_BANK_FORM_OPEN
})

export const clearBankFormOpen = () => ({
    type: singlePaymentBankInfoConstants.SINGLE_PAYMENT_CLEAR_BANK_FORM_OPEN
})

export const setFormStartEventEmitted = () => ({
    type: singlePaymentConstants.SET_FORM_START_EVENT_EMITTED
})

export const clearFormStartEventEmitted = () => ({
    type: singlePaymentConstants.CLEAR_FORM_START_EVENT_EMITTED
})

export const ignoreScheduledAndRecentPayments = () => ({
    type: singlePaymentConstants.IGNORE_SCHEDULED_AND_RECENT_PAYMENTS
})

export const showScheduledAndRecentPayments = () => ({
    type: singlePaymentConstants.SHOW_SCHEDULED_AND_RECENT_PAYMENTS
})