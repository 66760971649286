import { myAccountConstants } from '../../../constants/myaccount-constants'
import { RSAA } from 'redux-api-middleware'
import utils from '../../../srp_modules/utils'
import localization from '../../../srp_modules/localization'

export const getDashboardMessages = () => {
    let url = utils.createUrl('myaccount/dashboardmessages')

    return dispatch => {
        return dispatch({
            [RSAA]: {
                endpoint: url,
                method: 'GET',
                credentials: 'include',
                types: [
                    myAccountConstants.GET_DASHBOARD_MESSAGES_REQUEST,
                    myAccountConstants.GET_DASHBOARD_MESSAGES_SUCCESS,
                    myAccountConstants.GET_DASHBOARD_MESSAGES_FAILURE
                ]
            }
        })
    }
}

export const dismissMessage = (index) => {
    return (dispatch) => {
        dispatch(dismissMessageSuccess(index))
    }
}

export const dismissMessageSuccess = (index) => ({
    type: myAccountConstants.DISMISS_MESSAGE_SUCCESS,
    index
})

export const setPreventLocalization = (preventLocalization) => {
    return {
        type: preventLocalization ? myAccountConstants.PREVENT_LOCALIZATION
                                  : myAccountConstants.ALLOW_LOCALIZATION
    }
}

export const getExpectedLanguageString = language => {
    let loweredLangauge = language.toLowerCase()

    switch(loweredLangauge) {
        case 'es':
        case 'sp':
        case 'spanish':
            loweredLangauge = 'es'
            break
        default:
            loweredLangauge = 'en'
    }

    return loweredLangauge
}

export const savePreferredLanguage = (preferredLanguage) => {
    let loweredLangauge = getExpectedLanguageString(preferredLanguage)

    localization.setCookieLanguage(loweredLangauge)
    return {
        type: myAccountConstants.SAVE_PREFERRED_LANGUAGE,
        preferredLanguage: loweredLangauge
    }
}