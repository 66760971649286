import React from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

import TextField from '@mui/material/TextField'
import MenuItem from '@mui/material/MenuItem'
import { Link } from 'react-router-dom'
import format from '../../../srp_modules/format'

const BankAccountSelectorCard = ({selectedBankAccountId, getSelectedBankAccountForMultiPaymentOnClick, bankAccountList, t}) => {
    return (
        <div>
            {(bankAccountList.length > 0) ?
                <div>
                    <TextField
                        select
                        value={selectedBankAccountId}
                        onChange={event=>getSelectedBankAccountForMultiPaymentOnClick(event.target.value)}
                        fullWidth
                        className="mb-4"
                        label={t("Select a bank account")}
                    >
                        {bankAccountList.map((n, index) => {
                            return (
                                <MenuItem value={n.bankId} key={index}>{format.bankAccount(n)}</MenuItem>
                            )
                        })}
                    </TextField>
                </div>
            : <div className="text-muted mb-4">{t("no_banks_on_file")}</div>

            }
            <div className="d-flex justify-content-end">
                <Link to="/myaccount/profile/banks/addbankaccount" className="btn srp-btn btn-lightblue">{t("Add bank account")}</Link>
            </div>
        </div>
    )
}

BankAccountSelectorCard.propTypes = {
    selectedBankAccountId: PropTypes.number,
    bankAccountList: PropTypes.arrayOf(PropTypes.object),
    getSelectedBankAccountForMultiPaymentOnClick: PropTypes.func,
    t: PropTypes.func.isRequired,
    i18n: PropTypes.object.isRequired
}

export default withTranslation("multiPayment")(BankAccountSelectorCard)