import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Trans } from 'react-i18next'

import CallAnytime from '../../myaccount_header/call-anytime'

import SuccessCheck from '../../../images/common/SuccessCheck.png'

import * as loadingStatus from '../../../constants/loading-status-constants'

import utils from '../../../srp_modules/utils'

const Confirmation = ({ isLoggedIn, isCommercial, emailAddress, submitShareFormStatus, t, i18n }) => {
    const isSpanish = (i18n.language === "es")
    const supportNumber = utils.GetCustomerServicePhoneNumber(isCommercial, isSpanish)
    const supportNumberFmt = utils.GetCustomerServicePhoneNumberFmt(isCommercial, isSpanish)

    return (
        <div>
            <div className="container py-3">
                <CallAnytime />
                <h3 className="d-lg-block d-none mt-3 mb-4 pb-4">{t("SHARE a little and help a lot")}</h3>
                <h4 className="d-lg-none d-block mt-3 mb-4">{t("SHARE a little and help a lot")}</h4>

                {submitShareFormStatus !== loadingStatus.LOADING_STATUS_FAILURE
                    ? <div className="row">
                        <div className="col-lg-7 col-12">
                            <div className="srp-card-header">{t("SHARE donation submitted")}</div>
                            <div className="srp-card-body text-muted">
                                <div className="srp-card-details">
                                    <div className="d-lg-block d-none">
                                        <div className="mb-3 srp-olive-green d-flex align-items-center">
                                            <img className="img-fluid mr-3" src={SuccessCheck} style={{ width: '50px' }} />
                                            <span style={{ fontSize: '1.75rem', fontWeight: '500' }}>{t("Thank you!")}</span>
                                        </div>
                                    </div>
                                    <div className="d-lg-none d-block">
                                        <div className="mb-3 srp-olive-green text-center">
                                            <img className="img-fluid mr-3" src={SuccessCheck} style={{ width: '50px' }} />
                                            <div style={{ fontSize: '1.75rem', fontWeight: '500' }}>{t("Thank you!")}</div>
                                        </div>
                                    </div>

                                    <div className="mb-4">
                                        <span>{t("Your donation has been submitted. A copy of the donation receipt has been sent to")}{' '}</span>
                                        <span className="font-weight-bold">{emailAddress}</span>
                                    </div>

                                    <div className="d-flex flex-wrap justify-content-end">
                                        <a className="btn srp-btn btn-lightblue mb-2" href="https://srpnet.com/">{t("Go to SRPnet.com")}</a>
                                        {isLoggedIn
                                            ? <Link to="/myaccount/dashboard" className="btn srp-btn btn-lightblue ml-2 mb-2">{t("Go to dashboard")}</Link>
                                            : <Link to="/accountSetup" className="btn srp-btn btn-lightblue ml-2 mb-2">{t("Sign up for My Account")}</Link>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    : <div className="row">
                        <div className="col-lg-7 col-12">
                            <div className="srp-card-header">{t("Unable to submit SHARE donation")}</div>
                            <div className="srp-card-body text-muted">
                                <div className="srp-card-details">
                                    <div className="mb-3">{t("We_are_sorry")}</div>
                                    <p className="d-lg-block d-none">
                                        {t("Please_try_again_desktop", {phone: supportNumberFmt})}
                                    </p>
                                    <p className="d-lg-none d-block">
                                        <Trans i18nKey="Please_try_again_remote" t={t}>
                                        Please try again later or call SRP Customer Service at
                                        <a href={"tel:"+supportNumber}>{{phone: supportNumberFmt}}</a>,
                                        available to serve you 24 hours a day, seven days a week.
                                        </Trans>
                                    </p>

                                    <div className="d-flex flex-wrap justify-content-end">
                                        <a className="btn srp-btn btn-lightblue mb-2" href="https://srpnet.com/">{t("Go to SRPnet.com")}</a>
                                        {isLoggedIn
                                            ? <Link to="/myaccount/dashboard" className="btn srp-btn btn-lightblue ml-2 mb-2">{t("Go to dashboard")}</Link>
                                            : <Link to="/accountSetup" className="btn srp-btn btn-lightblue ml-2 mb-2">{t("Sign up for My Account")}</Link>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    )

}

Confirmation.propTypes = {
    isLoggedIn: PropTypes.bool,
    isCommercial: PropTypes.bool,
    emailAddress: PropTypes.string,
    submitShareFormStatus: PropTypes.string,

    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired,
}

export default Confirmation