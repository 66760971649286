import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import TextField from '@mui/material/TextField'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import { PatternFormat } from 'react-number-format'
import Calendar from '../calendar'
import validate from '../../../srp_modules/validate'
import StopServiceStepper from './stop-service-stepper'
import formikInputFilter from '../../../srp_modules/formik-input-filter'
import AddressWithAutoComplete from '../../address-with-autocomplete'

class DetailsForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      confirmPhone: '',
      confirmPhoneError: '',

      confirmEmail: '',
      confirmEmailError: '',

      hasAddressError: false,
      hasCityError: false,
      hasStateError: false,
      hasCountryError: false,
      hasZipCodeError: false,
      hasPhoneNumberError: false,
      hasConfirmPhoneError: false,
      hasEmailError: false,
      hasConfirmEmailError: false,

      confirmPhoneErrorMessage: '',
      confirmEmailErrorMessage: '',
      emailErrorMessage: '',
      phoneNumberMessage: '',

      hasDbaError: false,
      hasRequestorNameError: false,
      hasRequestorAssociationError: false
    }
  }

  setConfirmFieldState(fieldKey, fieldValue) {
    this.setState({ [fieldKey]: fieldValue })
  }

  validateRequiredField(fieldValue, errorStateValue) {
    this.setState({ [errorStateValue]: fieldValue.length === 0 })
    return fieldValue.length > 0
  }

  validatePhone() {
    if (this.props.pageState.phoneNumber.length == 0) {
      this.setState({ hasPhoneNumberError: true })
      this.setState({ phoneNumberMessage: this.props.t("Phone number is required") })
      return false
    }

    if (this.props.pageState.phoneNumber.length < 10 && this.props.pageState.phoneNumber.length > 0) {
      this.setState({ hasPhoneNumberError: true })
      this.setState({ phoneNumberMessage: this.props.t("Phone must have 10 digits") })
      return false
    }

    this.setState({ hasPhoneNumberError: false })
    this.setState({ phoneNumberMessage: "" })

    return true
  }

  validateConfirmPhone() {
    if (this.props.pageState.confirmPhoneNumber.length == 0) {
      this.setState({ hasConfirmPhoneError: true })
      this.setState({ confirmPhoneErrorMessage: this.props.t("Confirm phone number is required") })
      return false
    }

    if (this.props.pageState.phoneNumber != this.props.pageState.confirmPhoneNumber) {
      this.setState({ hasConfirmPhoneError: true })
      this.setState({ confirmPhoneErrorMessage: this.props.t("Phone numbers must match") })
      return false
    }

    this.setState({ hasConfirmPhoneError: false })
    this.setState({ confirmPhoneErrorMessage: "" })

    return true
  }

  validateEmail() {
    if (this.props.pageState.email.length == 0) {
      this.setState({ hasEmailError: true })
      this.setState({ emailErrorMessage: this.props.t("Email address is required") })
      return false
    }

    if (validate.emailAddress(this.props.pageState.email) == false) {
      this.setState({ hasEmailError: true })
      this.setState({ emailErrorMessage: this.props.t("Invalid email address") })
      return false
    }

    this.setState({ hasEmailError: false })
    this.setState({ emailErrorMessage: "" })

    return true
  }

  validateConfirmEmail() {
    if (this.props.pageState.confirmEmail.length == 0) {
      this.setState({ hasConfirmEmailError: true })
      this.setState({ confirmEmailErrorMessage: this.props.t("Confirm email address is required") })
      return false
    }

    if ((this.props.pageState.email).toLowerCase() != (this.props.pageState.confirmEmail).toLowerCase()) {
      this.setState({ hasConfirmEmailError: true })
      this.setState({ confirmEmailErrorMessage: this.props.t("Email must match") })
      return false
    }

    this.setState({ hasConfirmEmailError: false })
    this.setState({ confirmEmailErrorMessage: "" })

    return true
  }

  checkState(entry) {
    const alphaOnly = /^[A-Za-z]+$/
    if (alphaOnly.test(entry) || entry.length == 0) {
      this.props.updateField('state', entry)
    }
  }

  checkZip(entry) {
    const alphaNumSpaces = /^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$/
    if (alphaNumSpaces.test(entry) || entry.length == 0) {
      this.props.updateField('zip', entry)
    }
  }

  checkRequestors(entry, fieldName) {
    const alphaNumSpaces = /^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _\-']*$/
    if (alphaNumSpaces.test(entry) || entry.length == 0) {
      this.props.updateField(fieldName, entry)
    }
  }

  validateCommercialFields() {
    if (!this.props.isCommercial) return true

    this.setState({ hasRequestorNameError: this.props.pageState.requestorsName.length === 0 })
    this.setState({ hasRequestorAssociationError: this.props.pageState.requestorsAssociaton.length === 0 })

    return !(this.props.pageState.requestorsName.length === 0 ||
      this.props.pageState.requestorsAssociaton.length === 0)
  }

  mailingAddressInputValid(newAddress) {
    if (newAddress.match(formikInputFilter.regex.streetAddressRegex)) {
      return true
    }

    return false
  }

  mailingAddressStateValid(newState) {
    if (newState.match(formikInputFilter.regex.alphaRegex)) {
      return true
    }

    return false
  }

  checkValidation(event, goToNextScreenFunc, currentStep) {
    event.preventDefault()

    const hasPhonePassed = this.validatePhone()
    let hasConfirmPhonePassed = true
    if (hasPhonePassed) {
      hasConfirmPhonePassed = this.validateConfirmPhone()
    }

    const hasEmailPassed = this.validateEmail()
    let hasConfirmedEmailPassed = true
    if (hasEmailPassed) {
      hasConfirmedEmailPassed = this.validateConfirmEmail()
    }

    const hasAddressPassed = this.validateRequiredField(this.props.pageState.address1, "hasAddressError")
    const hasCityPassed = this.validateRequiredField(this.props.pageState.city, "hasCityError")
    const hasStatePassed = this.validateRequiredField(this.props.pageState.state, "hasStateError")
    const hasZipPassed = this.validateRequiredField(this.props.pageState.zip, "hasZipCodeError")

    const hasCommercialPassed = this.validateCommercialFields()

    if (hasPhonePassed == false || hasConfirmPhonePassed == false ||
      hasEmailPassed == false || hasConfirmedEmailPassed == false ||
      hasAddressPassed == false ||
      hasCityPassed == false ||
      hasStatePassed == false ||
      hasZipPassed == false ||
      hasCommercialPassed == false) {
      return
    }

    goToNextScreenFunc(currentStep)
  }

  updateAddressWithAutocomplete(addressComponents){
    this.props.updateField('address1', `${addressComponents.streetNumber} ${addressComponents.street}`)
    this.props.updateField('city', addressComponents.city)
    this.props.updateField('state', addressComponents.state)
    this.props.updateField('zip', addressComponents.zipCode)
    this.props.updateField('country', addressComponents.country)
  }

  render() {
    return (<form onSubmit={(e) => this.checkValidation(e, this.props.goToReview, this.props.currentStep + 1)}>
      <StopServiceStepper
        currentStep={this.state.currentStep}
      />
      <div className="text-muted">
        {`${this.props.t("You are turning off")} ${this.props.isCommercial ? this.props.t("commercial") : this.props.t("residential electric")}
        ${this.props.t("account number")}`} <span className="font-weight-bold">{this.props.selectedBillAccount}</span>.
      </div>

      <div className="py-3 text-muted">
        {this.props.dba.length > 0
          ? <div>{this.props.dba}</div>
          : null
        }
        {this.props.isCommercial
          ? <div>{this.props.businessName}</div>
          : <div>{this.props.firstName} {this.props.middleName} {this.props.lastName}</div>
        }
        <div>{this.props.serviceAddress.streetAddress}</div>
        <div>{this.props.serviceAddress.cityStateZip}</div>
      </div>

      <div className="mb-3 text-muted">{this.props.t("select_date_to_stop_service")}</div>

      <div className="row justify-content-center">
        <Calendar
          accentColor={'#087ed8'}
          holidayList={this.props.holidayList}
          onDatePicked={(d) => {
            this.props.stopDateChange(d.toJSDate())
          }}
          onDateInitialized={(d) => {
            this.props.stopDateChange(d)
          }}
          selectedDate={this.props.selectedDate}
        />
      </div>

      <div className="py-2 text-muted">{this.props.t("apply_deposit_towards_final_bill")}</div>

      <div className="pt-3">
        <div className="mb-3">
          <AddressWithAutoComplete
            fieldName="Address1"
            fieldValue={this.props.pageState.address1}
            handleAddressChange={(e) => {
              if (this.mailingAddressInputValid(e.target.value)) {
                this.props.updateField('address1', e.target.value)
              }
            }}
            handleAddressBlur={e => this.validateRequiredField(e.target.value, "hasAddressError")}
            errorText={this.state.hasAddressError ? this.props.t("Address is required") : ""}
            hasError={this.state.hasAddressError}
            updateAddressCallback={this.updateAddressWithAutocomplete.bind(this)}
            isDomesticAddress={false}
            label={this.props.t("Street address")}
          />
          <TextField id="Address2"
            fullWidth={true}
            inputProps={{ maxLength: 39 }}
            onChange={(e) => {
              if (this.mailingAddressInputValid(e.target.value)) {
                this.props.updateField('address2', e.target.value)
              }
            }}
            value={this.props.pageState.address2}
            label={this.props.t("Apt, suite, etc (optional)")}
          />
        </div>
        <div className="mb-3">
          <TextField id="city"
            fullWidth={true}
            value={this.props.pageState.city}
            onChange={(e) => {
              if (this.mailingAddressInputValid(e.target.value)) {
                this.props.updateField('city', e.target.value)
              }
            }}
            error={this.state.hasCityError}
            inputProps={{ maxLength: 30 }}
            onBlur={e => this.validateRequiredField(e.target.value, "hasCityError")}
            helperText={this.state.hasCityError ? this.props.t("City is required") : ""}
            label={this.props.t("City")}
          />
        </div>
        <div className="mb-3">
          <TextField id="state"
            fullWidth={true}
            value={this.props.pageState.state}
            onChange={(e) => {
              if (this.mailingAddressStateValid(e.target.value)) {
                this.checkState(e.target.value)
              }
            }}
            error={this.state.hasStateError}
            inputProps={{ maxLength: 2 }}
            onBlur={e => this.validateRequiredField(e.target.value, "hasStateError")}
            helperText={this.state.hasStateError ? this.props.t("State is required") : ""}
            label={this.props.t("State")}
          />
        </div>
        <div className="mb-3">
          <TextField id="country"
            fullWidth={true}
            value={this.props.pageState.country}
            inputProps={{ maxLength: 25 }}
            onChange={(e) => {
              if (this.mailingAddressInputValid(e.target.value)) {
                this.props.updateField('country', e.target.value)
              }
            }}
            label={this.props.t("Country (optional)")}
          />
        </div>
        <div className="mb-3">
          <TextField id="zipCode"
            fullWidth={true}
            value={this.props.pageState.zip}
            onChange={(e) => this.checkZip(e.target.value)}
            inputProps={{ maxLength: 9 }}
            error={this.state.hasZipCodeError}
            onBlur={e => this.validateRequiredField(e.target.value, "hasZipCodeError")}
            helperText={this.state.hasZipCodeError ? this.props.t("Zip code is required") : ""}
            label={this.props.t("Zip code")}
          />
        </div>
      </div>
      <div className="mb-3">
        <div>
          <TextField id="emailAddress"
            fullWidth={true}
            type="email"
            value={this.props.pageState.email}
            inputProps={{ maxLength: 100 }}
            error={this.state.hasEmailError}
            onBlur={e => this.validateEmail(e.target.value)}
            onChange={e => this.props.updateField('email', e.target.value)}
            helperText={this.state.hasEmailError ? this.state.emailErrorMessage : ""}
            label={this.props.t("Email address")}
          />
        </div>
      </div>
      <div className="mb-3">
        <div>
          <TextField id="confirmEmail"
            fullWidth={true}
            type="email"
            value={this.props.pageState.confirmEmail}
            error={this.state.hasConfirmEmailError}
            inputProps={{ maxLength: 100 }}
            onBlur={e => this.validateConfirmEmail(e.target.value)}
            onChange={e => this.props.updateField('confirmEmail', e.target.value)}
            helperText={this.state.hasConfirmEmailError ? this.state.confirmEmailErrorMessage : ""}
            label={this.props.t("Confirm email address")}
          />
        </div>
      </div>
      {!this.props.eBillState && !this.props.isMpower
        ? <div className="mb-3 srp-alert-notice p-0 pl-3 pr-3">
          <FormControlLabel
            control={<Checkbox
              name="sendFinalBillAsEbill"
              checked={this.props.pageState.sendFinalBillAsEbill}
              onChange={e => this.props.updateCheckbox('sendFinalBillAsEbill', e.target.checked)}
              color="primary"
            />}
            className="pt-2"
            label={<span className="font-weight-bold">{this.props.t("paperless_bill_final_bill")}</span>}
          />
        </div>
        : null}
      <div className="mb-3">
        <div>
          <PatternFormat
            id="phoneNumber"
            value={this.props.pageState.phoneNumber}
            valueIsNumericString={true}
            format="(###) ###-####"
            type="tel"
            customInput={TextField}
            style={{ width: '100%' }}
            onValueChange={(e) => this.props.updateField('phoneNumber', e.value)}
            onBlur={e => this.validatePhone(e.target.value)}
            error={this.state.hasPhoneNumberError}
            helperText={this.state.hasPhoneNumberError ? this.state.phoneNumberMessage : ""}
            label={this.props.t("Phone number")}
          />
        </div>
      </div>
      <div className="mb-3">
        <div>
          <PatternFormat
            id="confirmPhoneNumber"
            value={this.props.pageState.confirmPhoneNumber}
            valueIsNumericString={true}
            format="(###) ###-####"
            type="tel"
            customInput={TextField}
            style={{ width: '100%' }}
            error={this.state.hasConfirmPhoneError}
            onValueChange={e => this.props.updateField('confirmPhoneNumber', e.value)}
            helperText={this.state.hasConfirmPhoneError ? this.state.confirmPhoneErrorMessage : ""}
            onBlur={e => this.validateConfirmPhone(e.target.value)}
            label={this.props.t("Confirm phone number")}
          />
        </div>
      </div>
      {this.props.pageState.dba && <div>
        <div className="mb-3">
          <div>
            <TextField id="dba"
              fullWidth={true}
              value={this.props.pageState.dba}
              disabled={true}
              error={this.state.hasDbaError}
              onBlur={e => this.validateRequiredField(e.target.value, "dba")}
              onChange={e => this.props.updateField('dba', e.target.value)}
              helperText={this.state.hasConfirmEmailError ? this.props.t("DBA is required") : ""}
              label={this.props.t("DBA(Doing Business As)")}
            />
          </div>
        </div>
      </div>}

      {this.props.isCommercial && <div> <div className="mb-3">
        <div>
          <TextField id="requestorsName"
            fullWidth={true}
            value={this.props.pageState.requestorsName}
            error={this.state.hasRequestorNameError}
            inputProps={{ maxLength: 30 }}
            onBlur={e => this.validateRequiredField(e.target.value, "requestorsName")}
            onChange={e => this.checkRequestors(e.target.value, 'requestorsName')}
            helperText={this.state.hasRequestorNameError ? this.props.t("Requestor's name is required") : ""}
            label={this.props.t("Requestor's name")}
          />
        </div>
      </div>
        <div className="mb-3">
          <div>
            <TextField id="requestorsAssociation"
              fullWidth={true}
              value={this.props.pageState.requestorsAssociaton}
              error={this.state.hasRequestorAssociationError}
              inputProps={{ maxLength: 30 }}
              onBlur={e => this.validateRequiredField(e.target.value, "requestorsAssociaton")}
              onChange={e => this.checkRequestors(e.target.value, 'requestorsAssociaton')}
              helperText={this.state.hasRequestorAssociationError ? this.props.t("Requestor's association with business is required") : ""}
              label={this.props.t("Requestor's association with business")}
            />
          </div>
        </div>
      </div>
      }

      <div className="d-flex justify-content-end">
        <button className="btn srp-btn btn-blue" type="submit">{this.props.t("Next")}</button>
      </div>
    </form>)
  }
}

DetailsForm.propTypes = {
  address1: PropTypes.string,
  address2: PropTypes.string,
  businessName: PropTypes.string,
  city: PropTypes.string,
  confirmEmail: PropTypes.string,
  confirmPhoneNumber: PropTypes.string,
  country: PropTypes.string,
  currentStep: PropTypes.number,
  dba: PropTypes.string,
  eBillState: PropTypes.bool,
  email: PropTypes.string,
  firstName: PropTypes.string,
  goToReview: PropTypes.func,
  hasRequestorAssociationError: PropTypes.bool,
  holidayList: PropTypes.arrayOf(PropTypes.string),
  isCommercial: PropTypes.bool,
  isLoggedIn: PropTypes.bool,
  isMpower: PropTypes.bool,
  lastName: PropTypes.string,
  middleName: PropTypes.string,
  pageState: PropTypes.shape({
    address1: PropTypes.string,
    address2: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    country: PropTypes.string,
    zip: PropTypes.string,
    email: PropTypes.string,
    confirmEmail: PropTypes.string,
    sendFinalBillAsEbill: PropTypes.bool.isRequired,
    phoneNumber: PropTypes.string,
    confirmPhoneNumber: PropTypes.string,
    dba: PropTypes.string,
    requestorsName: PropTypes.string,
    requestorsAssociaton: PropTypes.string,
  }).isRequired,
  phoneNumber: PropTypes.string,
  primaryEmail: PropTypes.string,
  primaryPhone: PropTypes.string,
  requestorsAssociaton: PropTypes.string,
  requestorsName: PropTypes.string,
  selectedBillAccount: PropTypes.string,
  selectedDate: PropTypes.object,
  serviceAddress: PropTypes.object,
  state: PropTypes.string,
  stopDateChange: PropTypes.func,
  updateAddress1: PropTypes.func,
  updateAddress2: PropTypes.func,
  updateCheckbox: PropTypes.func,
  updateCity: PropTypes.func,
  updateConfirmEmail: PropTypes.func,
  updateConfirmPhone: PropTypes.func,
  updateCountry: PropTypes.func,
  updateEmail: PropTypes.func,
  updateField: PropTypes.func,
  updatePhone: PropTypes.func,
  updateState: PropTypes.func,
  updateZip: PropTypes.func,
  zip: PropTypes.string,
  t: PropTypes.func
}

export default withTranslation('stopService')(DetailsForm)