import React from 'react'
import PropTypes from 'prop-types'

import { Trans } from 'react-i18next'
import utils from '../../../../srp_modules/utils'

import * as loadingStatus from '../../../../constants/loading-status-constants'

import { CardErrorJsx } from '../../../common_error/card-error'

let getErrorHtml = (errorCode, t, i18n) => {
    const isSpanish = i18n.language==="es"
    const csPhoneNumber = isSpanish ? utils.getCSSpanishPhoneNumber() : utils.getCSPhoneNumber(false)
    const formattedCSPhoneNumber = isSpanish ? utils.getFormattedCSSpanishPhoneNumber() : utils.getFormattedCSPhoneNumber(false)

    switch (errorCode) {
        case 'NotEligibleMonthlyAdvanceLimitReached':
        case 'NotSubmittedMonthlyAdvanceLimitReached':
            return t("reached_the_maximum_number_of_advances")
        case 'NotEligibleOtherReason':
        case 'NotSubmittedOtherReason':
        case loadingStatus.LOADING_STATUS_FAILURE:
            return (
                <Trans i18nKey="something_went_wrong_call_SRP" t={t}>
                We’re sorry, something went wrong. Please call SRP Customer Service at <a href={"tel:" + csPhoneNumber}>{{phone: formattedCSPhoneNumber}}</a> for assistance.
                </Trans>
            )
        default:
            return (
                <Trans i18nKey="not_eligible_for_an_advance" t={t}>
                Your account is currently not eligible for an advance at this time. Please call SRP Customer Service at <a href={"tel:" + csPhoneNumber}>{{phone: formattedCSPhoneNumber}}</a> for assistance.
                </Trans>
            )
    }
}

const CreditAdvanceError = ({ errorCode, t, i18n, backToHomeClick }) => (
    <div className="srp-card">
        <div className="srp-card-header">
            {t("My M-Power")}
        </div>
        <div className="srp-card-body">
            <div className="srp-card-details">
                <div className="credit-advance-header" style={{ marginBottom: "5px"}}>
                    {t("Credit advance is not available")}
                </div>
                <div className="d-flex align-items-center mb-3">
                    <i className="material-icons text-primary-ada">keyboard_arrow_left</i>
                    <button className="displayAsLink text-primary-ada credit-advance-back" onClick={() => backToHomeClick()}>
                        {t("Back to Home")}
                    </button>
                </div>
                <div>
                    <CardErrorJsx
                        open={true} 
                        jsxMessage={getErrorHtml(errorCode, t, i18n)}
                    />
                </div>
            </div>
        </div>
    </div>
)

CreditAdvanceError.propTypes = {
    errorCode: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired,
    backToHomeClick: PropTypes.func.isRequired
}

export default CreditAdvanceError