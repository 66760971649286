import { DateTime } from 'luxon'
import { billAccountConstants } from '../../../constants/bill-account-constants'
import { transferServiceConstants } from '../../../constants/transfer-service-constants'
import { pricePlanConstants } from '../../../constants/price-plan-constants'
import { formConstants } from '../../../constants/form-constants'
import { paymentConstants } from '../../../constants/payment-constants'
import { rateMetaDataConstants } from '../../../constants/rate-meta-data-constants'
import * as loadingStatus from '../../../constants/loading-status-constants'
import transferModule from '../../../srp_modules/forms/transfer-module'

const initialState = {
    activeStep: 0,

    endServiceDate: null,
    endServiceDateTouched: false,
    endDateError: false,
    endDateErrorMessage: '',

    endServiceMinDate: transferModule.getMinDates(),
    endServiceMaxDate: DateTime.now().startOf('day').plus({ days: 60}),

    startServiceDate: null,
    startServiceDateTouched: false,
    startDateError: false,
    startDateErrorMessage: '',

    selectedPricePlanResidential: pricePlanConstants.PRICE_PLAN_RATE_CODE_MAP.UNKNOWN,
    selectedSolarChoice: pricePlanConstants.SOLAR_CHOICE.NONE,

    electricVehicleMake: '',
    electricVehicleModel: '',
    electricVehicleYear: '',
    evYearError: false,
    evYearErrorMessage: '',
    evOwnershipType: '',
    evEquipmentType: '',
    evInputVoltage: '',
    evInputCurrent: '',

    serviceBillingInfo: {
        serviceAddressOne: '',
        serviceAddressTwo: '',
        serviceCity: '',
        serviceAddIsMailingAdd: true,
        mailingAddressOne: '',
        mailingAddressTwo: '',
        mailingCity: '',
        mailingState: '',
        mailingCountry: '',
        mailingZipCode: '',
        maintainAutopay: true,
        lastFourOfAutopayBank: '',
        maintainCustomDueDate: true,
        customDueDate: 0,
        maintainPaperlessBill: true
    },

    customerInfo: {
        firstName: '',
        middleName: '',
        lastName: '',
        identificationType: formConstants.SOCIAL,
        identificationNum: '',
        emailAddress: '',
        confirmEmail: '',
        phoneNumber: '',
        confirmPhone: ''
    },

    agreeToSafetyStmt: false,
    sendMPowerBox: false,

    submitTransferServiceStatus: loadingStatus.LOADING_STATUS_INIT
}

export default function transferServiceReducer(state = initialState, action) {
    switch (action.type) {
        case transferServiceConstants.SET_ACTIVE_STEP:
            return { ...state, activeStep: action.activeStep }

        case paymentConstants.GET_ENROLLED_PROGRAMS_DETAILS_SUCCESS: {
            let currentSolarChoice = pricePlanConstants.SOLAR_CHOICE.NONE
            if (action.payload.solarChoice.isEnrolled) {
                switch (action.payload.solarChoice.optionValue) {
                    case 1:
                        currentSolarChoice = pricePlanConstants.SOLAR_CHOICE.SC100
                        break
                    case 0.5:
                        currentSolarChoice = pricePlanConstants.SOLAR_CHOICE.SC50
                        break
                    default:
                        break
                }
            }

            return { ...state, selectedSolarChoice: currentSolarChoice }
        }
        case billAccountConstants.GET_RATE_CODE_SUCCESS:
            return { ...state, selectedPricePlanResidential: action.payload.rateCode }
        case rateMetaDataConstants.GET_RATE_META_DATA_SUCCESS:
            return {
                ...state,
                customerInfo: {
                    ...state.customerInfo,
                    firstName: action.payload.isBusinessOnResidentialPlan
                                ? action.payload.displayName
                                : action.payload.firstName,
                    middleName: action.payload.middleName,
                    lastName: action.payload.lastName
                }
            }
        case billAccountConstants.GET_PHONE_SUCCESS:
            return {
                ...state,
                customerInfo: {
                    ...state.customerInfo,
                    phoneNumber: action.payload.bestPhone
                }
            }

        case transferServiceConstants.SET_END_SERVICE_DATE: {
            let isEmpty = false
            let isEmptyMessage = ""

            let hasError = false
            let errorMessage = ""

            let minDate = state.endServiceMinDate
            let maxDate = state.endServiceMaxDate

            let endDate = action.endDate !== null ? DateTime.fromISO(action.endDate).startOf('day') : null
            let startDate = DateTime.fromISO(state.startServiceDate).startOf('day')

            if (endDate === null) {
                isEmpty = true
                isEmptyMessage = "Please enter an end date"
            }
            else if (DateTime.fromISO(endDate) < DateTime.fromISO(startDate)) {
                hasError = true
                errorMessage = "The end date must be the same day or later than the start date. Please adjust the dates or contact us at (602) 236-8888."
            }
            else if (endDate < minDate || endDate > maxDate) {
                hasError = true
                errorMessage = "The end date must be within 60 days from today. Please adjust the dates or contact us at (602) 236-8888."
            }
            else {
                isEmpty = false
                isEmptyMessage = ""
                hasError = false
                errorMessage = ""
            }

            return {
                ...state,
                endServiceDate: endDate,
                endDateError: isEmpty,
                endDateErrorMessage: isEmptyMessage,
                startDateError: hasError,
                startDateErrorMessage: errorMessage
            }
        }
        case transferServiceConstants.SET_END_SERVICE_DATE_TOUCHED: {
            if (state.endServiceDate === null) {
                return {
                    ...state,
                    endDateError: true,
                    endDateErrorMessage: "Please enter an end date",
                }
            }

            return {...state};
        }
        case transferServiceConstants.SET_START_SERVICE_DATE_TOUCHED: {
            if (state.startServiceDate === null) {
                return {
                    ...state,
                    startDateError: true,
                    startDateErrorMessage: "Please enter a start date",
                }
            }

            return {...state};
        }
        case transferServiceConstants.SET_START_SERVICE_DATE: {
            let hasError = false
            let errorMessage = ""
            let endDate = DateTime.fromISO(state.endServiceDate).startOf('day')
            let startDate = DateTime.fromISO(action.startDate).startOf('day')
            if (action.startDate === null) {
                hasError = true
                errorMessage = "Please enter a start date"
            }
            else if (DateTime.fromISO(startDate) > DateTime.fromISO(endDate)) {
                hasError = true
                errorMessage = "The end date must be the same day or later than the start date. Please adjust the dates or contact us at (602) 236-8888."
            }
            else {
                hasError = false
                errorMessage = ""
            }

            return {
                ...state,
                startServiceDate: action.startDate,
                startDateError: hasError,
                startDateErrorMessage: errorMessage
            }
        }
        case transferServiceConstants.SELECT_PRICE_PLAN:
            return { ...state, selectedPricePlanResidential: action.pricePlan }
        case transferServiceConstants.SELECT_SOLAR_CHOICE: {
            if (action.solarChoice === state.selectedSolarChoice) {
                return { ...state, selectedSolarChoice: pricePlanConstants.SOLAR_CHOICE.NONE }
            }
            else {
                return { ...state, selectedSolarChoice: action.solarChoice }
            }
        }
        case transferServiceConstants.SET_EV_MAKE:
            return { ...state, electricVehicleMake: action.make }
        case transferServiceConstants.SET_EV_MODEL:
            return { ...state, electricVehicleModel: action.model }
        case transferServiceConstants.SET_EV_YEAR:
            return { ...state, electricVehicleYear: action.year }
        case transferServiceConstants.VALIDATE_EV_YEAR: {
            let error = false
            let errorMessage = ''
            if ((action.year).length > 0 && (action.year).length < 4) {
                error = true
                errorMessage = "Please enter valid year"
            }
            return { ...state, evYearError: error, evYearErrorMessage: errorMessage }
        }
        case transferServiceConstants.SELECT_EV_OWNERSHIP:
            return { ...state, evOwnershipType: action.ownership }
        case transferServiceConstants.SELECT_EV_EQUIPMENT_TYPE:
            return { ...state, evEquipmentType: action.equipmentType }
        case transferServiceConstants.SELECT_EV_VOLTAGE:
            return { ...state, evInputVoltage: action.voltage }
        case transferServiceConstants.SELECT_EV_CURRENT:
            return { ...state, evInputCurrent: action.current }
        case transferServiceConstants.SET_SERVICE_AND_BILL_INFO:
            return { ...state, serviceBillingInfo: action.serviceBillingInfo }
        case transferServiceConstants.SET_CUSTOMER_INFO:
            return { ...state, customerInfo: action.customerInfo }
        case transferServiceConstants.SET_SAFETY_STMT_AGREE:
            return { ...state, agreeToSafetyStmt: action.doesAgree }
        case transferServiceConstants.SEND_MPOWER_BOX:
            return { ...state, sendMPowerBox: action.sendBox }

        case transferServiceConstants.SUBMIT_TRANSFER_SERVICE_REQUEST:
            return { ...state, submitTransferServiceStatus: loadingStatus.LOADING_STATUS_IN_PROGRESS }
        case transferServiceConstants.SUBMIT_TRANSFER_SERVICE_SUCCESS:
            return { ...state, submitTransferServiceStatus: loadingStatus.LOADING_STATUS_SUCCESS }
        case transferServiceConstants.SUBMIT_TRANSFER_SERVICE_FAILURE:
            return { ...state, submitTransferServiceStatus: loadingStatus.LOADING_STATUS_FAILURE }

        case billAccountConstants.SELECT_BILL_ACCOUNT_SUCCESS:
            return { ...initialState }
        default:
            return state
    }
}