import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { DateTime } from 'luxon'
import CardLoading from '../card-loading'
import CardUsage from './card-usage'
import CardUsageMPowerCpp from './card-usage-mpower-cpp'
import EmptyState from '../../empty-state'
import { withRouter } from '../../../srp_modules/with-router'
import { getRateMetaData } from '../../../actions/auth/usage/rate-meta-data-actions'
import { getBillProjection } from '../../../actions/auth/payment/bill-projection-actions'
import { getDailyUsage } from '../../../actions/auth/usage/daily-usage-actions'
import { getAccountHistory } from '../../../actions/auth/payment/account-history-actions'
import { getPrepayHistory } from '../../../actions/auth/payment/prepay-history-actions'
import { getUsageChartTab, setUsagePageFromDashButtons } from '../../../actions/auth/usage/usage-actions'
import { rateMetaDataConstants } from '../../../constants/rate-meta-data-constants'
import * as loadingStatus from '../../../constants/loading-status-constants'
import { getMPowerAccountDetails } from '../../../actions/auth/bill_account/bill-account-actions'
import { centralPrepayConstants } from '../../../constants/central-prepay-constants'
import { withTranslation } from 'react-i18next'

class CardUsageContainer extends React.Component {
    constructor(props) {
        super(props);
    }

   componentDidMount() {
        if (this.props.rateMetaData.rateMetaDataStatus === loadingStatus.LOADING_STATUS_INIT
            && this.props.selectedBillAccount > 0)
                this.props.getRateMetaDataForAccount(this.props.selectedBillAccount)

        if (this.props.billProjection.billProjectionStatus === loadingStatus.LOADING_STATUS_INIT
            && this.props.selectedBillAccount > 0)
                this.props.getBillProjectionForAccount(this.props.selectedBillAccount)

        if (this.props.dailyUsage.dailyUsageStatus === loadingStatus.LOADING_STATUS_INIT
            && this.props.selectedBillAccount > 0){
                this.props.getDailyUsageForAccount(this.props.selectedBillAccount)
            }
        if (this.props.accountHistory.accountHistoryStatus === loadingStatus.LOADING_STATUS_INIT
            && this.props.selectedBillAccount > 0 )
                this.props.getAccountHistoryForAccount(this.props.selectedBillAccount)
                
        if (this.props.prepayHistory.prepayHistoryStatus === loadingStatus.LOADING_STATUS_INIT
            && this.props.selectedBillAccount > 0 && this.props.selectedBillAccountDetails.isPrePay === true)
                this.props.getPrepayHistoryForAccount(this.props.selectedBillAccount)

        if (this.props.mPowerAcctDetailsStatus === loadingStatus.LOADING_STATUS_INIT) {
                this.props.getMPowerAccountDetails(this.props.selectedBillAccount)
            }
    }

   UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.rateMetaData.rateMetaDataStatus === loadingStatus.LOADING_STATUS_INIT && nextProps.selectedBillAccount > 0)
            this.props.getRateMetaDataForAccount(nextProps.selectedBillAccount)
        if (nextProps.billProjection.billProjectionStatus === loadingStatus.LOADING_STATUS_INIT && nextProps.selectedBillAccount > 0)
            this.props.getBillProjectionForAccount(nextProps.selectedBillAccount)
        if (nextProps.dailyUsage.dailyUsageStatus === loadingStatus.LOADING_STATUS_INIT && nextProps.selectedBillAccount > 0){
           this.props.getDailyUsageForAccount(nextProps.selectedBillAccount)
        }
        if (nextProps.accountHistory.accountHistoryStatus === loadingStatus.LOADING_STATUS_INIT && nextProps.selectedBillAccount > 0)
            this.props.getAccountHistoryForAccount(nextProps.selectedBillAccount)
        if (nextProps.prepayHistory.prepayHistoryStatus === loadingStatus.LOADING_STATUS_INIT && nextProps.selectedBillAccount > 0  && nextProps.selectedBillAccountDetails.isPrePay === true)
            this.props.getPrepayHistoryForAccount(nextProps.selectedBillAccount)
        if (nextProps.mPowerAcctDetailsStatus !== loadingStatus.LOADING_STATUS_IN_PROGRESS) {
                this.props.getMPowerAccountDetails(nextProps.selectedBillAccount)
        }
   }

    render() {
       if (this.props.rateMetaData.rateMetaDataStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS
            || this.props.rateMetaData.rateMetaDataStatus === loadingStatus.LOADING_STATUS_INIT
            || this.props.billProjection.billProjectionStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS
            || this.props.billProjection.billProjectionStatus === loadingStatus.LOADING_STATUS_INIT
            || this.props.dailyUsage.dailyUsageStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS
            || this.props.dailyUsage.dailyUsageStatus === loadingStatus.LOADING_STATUS_INIT
            || this.props.accountHistory.accountHistoryStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS
            || this.props.accountHistory.accountHistoryStatus === loadingStatus.LOADING_STATUS_INIT
            || this.props.prepayHistory.prepayHistoryStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS
            || this.props.prepayHistory.prepayHistoryStatuss === loadingStatus.LOADING_STATUS_INIT
            || this.props.billAccount.currentRateCodeStatus === loadingStatus.LOADING_STATUS_INIT
            || this.props.payment.paymentStatus === loadingStatus.LOADING_STATUS_INIT
            || this.props.billAccount.mPowerAcctDetailsStatus === loadingStatus.LOADING_STATUS_INIT
            || this.props.billAccount.mPowerAcctDetailsStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS)
            return <CardLoading title={"My usage"}/>

        if (this.props.rateMetaData.rateMetaDataStatus === loadingStatus.LOADING_STATUS_FAILURE
            || this.props.billProjection.billProjectionStatus === loadingStatus.LOADING_STATUS_FAILURE
            || this.props.dailyUsage.dailyUsageStatus === loadingStatus.LOADING_STATUS_FAILURE
            || this.props.accountHistory.accountHistoryStatus === loadingStatus.LOADING_STATUS_FAILURE
            || this.props.billAccount.currentRateCodeStatus === loadingStatus.LOADING_STATUS_FAILURE
            || this.props.prepayHistory.prepayHistoryStatus === loadingStatus.LOADING_STATUS_FAILURE)
            {return (<div className="srp-card">
            <div className="srp-card-header">
                {this.props.t("My usage")}
            </div>
            <EmptyState isCommercial={this.props.selectedBillAccountDetails.isCommercial} dataType="usage"/>
            </div> )}


        if (this.props.rateMetaData !== undefined){
            let mostRecentDailyUsage = this.props.dailyUsage.dailyUsageList[this.props.dailyUsage.dailyUsageList.length - 1]
            return(this.props.billAccount.mPowerAcctDetails.centralPrepayStatus == centralPrepayConstants.PREPAY_CENTRAL
                ? <CardUsageMPowerCpp billAccount={this.props.selectedBillAccount}
                            mostRecentCost = {mostRecentDailyUsage.dailyCost}
                            mostRecentUsage={mostRecentDailyUsage.totalKwh}
                            getUsageChartTabOnClick = {this.props.getUsageChartTabOnClick}
                            hasMonthly = {getHasMonthly(this.props.rateMetaData.rateMetaData.isMPower,
                                            this.props.accountHistory.accountHistoryList,
                                            this.props.prepayHistory.monthlyPrepayPurchasesList)} 
                            mostRecentUsageDate={getMostRecentUsageDate(this.props.dailyUsage.dailyUsageList)}
                            selectedUsageChartTab = {this.props.usageChartTab}
                            setUsageChartType={this.props.setUsageChartType}
                            />
                : <CardUsage billAccount={this.props.selectedBillAccount}
                    billProjection = {this.props.billProjection.amount}
                    isMetered = {this.props.rateMetaData.rateMetaData.isMetered}
                    lastUsed = {
                        setLastUsage(
                            this.props.dailyUsage.dailyUsageList,
                            (this.props.rateMetaData.rateMetaData.hasCost
                                || this.props.payment.payment.supportsRemotePrepay),
                            this.props.rateMetaData.rateMetaData.isMPower,
                            this.props.payment.payment.supportsRemotePrepay,
                            this.props.prepayHistory.mostRecentPayment
                            )
                    }
                    isCost = {
                        (this.props.rateMetaData.rateMetaData.hasCost
                        || this.props.payment.payment.supportsRemotePrepay)
                        && !this.props.billAccount.currentRateCode.isSolar
                        }
                    getUsageChartTabOnClick = {this.props.getUsageChartTabOnClick}
                    wasYesterday = {hasUsageYesterday(this.props.dailyUsage.dailyUsageList)}
                    isDemand = {this.props.rateMetaData.rateMetaData.isDemand}
                    hasDaily = {this.props.dailyUsage.dailyUsageList.length > 0}
                    hasMonthly = {getHasMonthly(this.props.rateMetaData.rateMetaData.isMPower,
                        this.props.accountHistory.accountHistoryList,
                        this.props.prepayHistory.monthlyPrepayPurchasesList)}
                    hasOnPeakDemand = {this.props.rateMetaData.rateMetaData.hasOnPeakDemand}
                    hasOnOffShoulder = {this.props.rateMetaData.rateMetaData.hasOnOffShoulder}
                    demandAmount = {getHighDemandAmount(this.props.dailyUsage.highDemandInfoList, this.props.rateMetaData.rateMetaData.hasOnOffShoulder,this.props.rateMetaData.rateMetaData.hasOnPeakDemand)}
                    highDemandDate ={getHighDemandDate(this.props.dailyUsage.highDemandInfoList, this.props.rateMetaData.rateMetaData.hasOnOffShoulder,this.props.rateMetaData.rateMetaData.hasOnPeakDemand)}
                    isMPower={this.props.rateMetaData.rateMetaData.isMPower}
                    isTokenlessPrepay={this.props.payment.payment.supportsRemotePrepay}
                    lastPurchaseDate={DateTime.fromISO(this.props.prepayHistory.mostRecentPaymentDate).toFormat('M/d/yy')}
                    mostRecentUsageDate={getMostRecentUsageDate(this.props.dailyUsage.dailyUsageList)}
                    selectedUsageChartTab = {this.props.usageChartTab}
                    payment = {this.props.payment}
                    isCommercial={this.props.selectedBillAccountDetails.isCommercial}
                    isSummaryMaster={this.props.rateMetaData.rateMetaData.summaryBilling === rateMetaDataConstants.SUMMARY_BILLING_PARENT}
                    setUsageChartType={this.props.setUsageChartType}
                    isNetBilled = {this.props.rateMetaData.rateMetaData.isNetBilled}
                    isAverageDemandRate = {this.props.dailyUsage.isAverageDemandRate}
                    averageDemandAmount = {getAverageDemandAmount(this.props.dailyUsage.highDemandInfoList)}
                    hasMonthlyDemandOnly = {this.props.rateMetaData.rateMetaData.hasMonthlyDemandOnly}
                    isNonSmartMeterRate = {this.props.rateMetaData.rateMetaData.isNonSmartMeterRate}
                    />
            )
        }
        return null;

    }
}

CardUsageContainer.propTypes = {
    dailyUsage: PropTypes.object,
    accountHistory: PropTypes.object,
    rateMetaData: PropTypes.object,
    selectedBillAccount: PropTypes.number,
    billProjection: PropTypes.object,
    currentRateCode: PropTypes.object,
    billAccount: PropTypes.object,
    getUsageChartTabOnClick: PropTypes.func,
    selectedUsageChartTab: PropTypes.number,
    getRateMetaDataForAccount: PropTypes.func,
    getBillProjectionForAccount: PropTypes.func,
    getDailyUsageForAccount: PropTypes.func,
    getAccountHistoryForAccount: PropTypes.func,
    usageChartTab: PropTypes.number,
    payment: PropTypes.object,
    getAccountHistory: PropTypes.func,
    getPrepayHistoryForAccount: PropTypes.func,
    prepayHistory: PropTypes.object,
    selectedBillAccountDetails: PropTypes.object,
    setUsageChartType: PropTypes.func,
    mPowerAcctDetails: PropTypes.object,
    mPowerAcctDetailsStatus: PropTypes.string,
    getMPowerAccountDetails: PropTypes.func,
    t: PropTypes.func
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
       getRateMetaDataForAccount: (billAccount) => {
           dispatch(getRateMetaData(billAccount))
       },
       getBillProjectionForAccount: (billAccount) => {
           dispatch(getBillProjection(billAccount))
       },
       getDailyUsageForAccount: (billAccount) => {
        dispatch(
         getDailyUsage(billAccount))
        },
        getAccountHistoryForAccount: (billAccount) =>{
            dispatch(getAccountHistory(billAccount))
        },
        getPrepayHistoryForAccount: (billAccount) => {
            dispatch(getPrepayHistory(billAccount))
        },
        getUsageChartTabOnClick: (evt, value) => {
            dispatch(getUsageChartTab(evt, value))
        },
        setUsageChartType: (chartType, beginningTab) => {
            dispatch(setUsagePageFromDashButtons(chartType, beginningTab))
            ownProps.router.navigate('/myaccount/usage')
        },
        getMPowerAccountDetails: (billAccount) => {
            dispatch(getMPowerAccountDetails(billAccount))
        }
    }
}

const mapStateToProps = state => {
    return {
        dailyUsage: state.dailyUsage,
        accountHistory: state.accountHistory,
        rateMetaData: state.rateMetaData,
        selectedBillAccount: state.accountInfo.billAccount.selectedBillAccount,
        selectedBillAccountDetails: state.accountInfo.billAccount.selectedBillAccountDetails,
        billProjection: state.billProjection,
        billAccount: state.accountInfo.billAccount,
        payment: state.accountInfo.payment,
        usageChartTab: state.accountInfo.usage.usageChartTab,
        prepayHistory: state.prepayHistory
    }
}

export default withTranslation("usageCard")(withRouter(connect(mapStateToProps, mapDispatchToProps)(CardUsageContainer)))

function hasUsageYesterday(dailyUsage) {
    if (dailyUsage.length > 0) {
        let yesterday = DateTime.now().setZone('MST').minus({ days: 1 }).toFormat('yyyy-MM-dd')
        let lastUsageDate = DateTime.fromISO(dailyUsage[dailyUsage.length-1].day).toFormat('yyyy-MM-dd')
        if (yesterday === lastUsageDate)
            return true
        return false
    }
    return false
}

function setLastUsage(dailyUsage, isCost,
    isMPower, isTokenlessPrepay, mostRecentPayment) {

    if (isMPower && !isTokenlessPrepay) {
        if (mostRecentPayment > 0) {
            return mostRecentPayment.toString()
        }
        else {
            return "--"
        }
    }

    if (dailyUsage.length > 0) {
        let last = dailyUsage[dailyUsage.length - 1]
        if (isCost) {
            return last.dailyCost.toString()
        }
        else {
            return (last.superOffPeakKwh +
                last.shoulderKwh +
                last.offPeakKwh +
                last.onPeakKwh +
                last.totalKwh).toString()
        }
    }
    return "--"
}

function getHighDemandAmount(demandDetails,hasOnOffShoulder,hasOnPeakDemand) {
    if (demandDetails.length > 0) {
        return (hasOnOffShoulder || hasOnPeakDemand) && demandDetails[0].isCurrentBill
            ? demandDetails[0].onPeakHighDemand
            : demandDetails[0].highDemand
    }
    return 0
}

function getHighDemandDate(demandDetails,hasOnOffShoulder,hasOnPeakDemand) {
    if (demandDetails.length >0)
        return (hasOnOffShoulder || hasOnPeakDemand) && demandDetails[0].isCurrentBill
            ? DateTime.fromISO(demandDetails[0].onPeakHighDemandDate).toFormat('M/d/yy')
            : DateTime.fromISO(demandDetails[0].highDemandDate).toFormat('M/d/yy')
}

function getAverageDemandAmount(demandDetails) {
    return demandDetails.length > 0
        ?  demandDetails[0].averageDemand
        :  0
}

function getHasMonthly(isMPower, accountHistory, prepayHistory) {
    if (isMPower && prepayHistory.length > 0)
        return true
    if (!isMPower && accountHistory.length > 0)
        return true
    return false
}

function getMostRecentUsageDate(usageData) {
    return usageData.length > 0
        ? DateTime.fromISO(usageData[usageData.length -1].date).toFormat('M/d/yy')
        : "N/A"
}