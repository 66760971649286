const multiPaymentUtils = {
    getLandlordBillAccountList,
    getUserBillAccountList,
    getSummaryBillingSubAccountList
}

function getLandlordBillAccountList(landlordAccounts) {
    if(Object.getOwnPropertyNames(landlordAccounts).length === 0) {
        return new Set()
    }

    let landlordBillAccountList = [].concat(...Object.values(landlordAccounts).map(o => Object.values(o.landlordProperties))).map(b => b.billAccount)
    landlordBillAccountList = landlordBillAccountList.filter(ba => ba !== null)
    return new Set(landlordBillAccountList)
}

function getUserBillAccountList(billAccountList) {
    let userBillAccountList = Object.values(billAccountList).map(o => o.account)
    return new Set(userBillAccountList)
}

function getSummaryBillingSubAccountList(summaryBillAccounts) {
    let summaryBillAccountList = new Set()
    summaryBillAccounts.forEach(summaryBillAccount => {
        summaryBillAccount.subAccounts.forEach(subAccount => {
            summaryBillAccountList.add(subAccount.billAccount)
        })
    })

    return summaryBillAccountList
}
export default multiPaymentUtils