import React from 'react'
import { useNavigate } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Formik } from 'formik'
import { CircularProgress, TextField } from '@mui/material'
import { PatternFormat } from 'react-number-format'
import { addLandlordAgreement, getLandlordAccounts, changeSelectedLandlordAgreement } from '../../../../actions/auth/landlord/landlord-actions'
import { addSnackbarMessage } from '../../../../actions/common/snackbar'
import * as loadingStatus from '../../../../constants/loading-status-constants'
import { Trans } from 'react-i18next'

import utils from '../../../../srp_modules/utils'

const AddLandlordForm = ({ addLandlordAgreement, loadLandlordAccounts, addSnackbarMessage,
    hideForm, handleSuccess, changeLandlordAgreement, addLandlordAgreementStatus, t, i18n }) => {

    let navigate = useNavigate()

    let addAgreementSubmit = async (values, formikGoodies) => {
        let status = {}
        formikGoodies.setStatus(status)
        let addResponse = await addLandlordAgreement(values.landlordAgreement, values.phoneNumber)


        if (addResponse.error === true) {
            status.error = "We're sorry, something went wrong. Please try again later."
        }
        else if (addResponse.payload.success === false) {
            status.error = addResponse.payload.error
        }

        formikGoodies.setStatus(status)
        formikGoodies.setSubmitting(false)

        if (addResponse.payload !== undefined && addResponse.payload.success === true) {
            addSnackbarMessage(t("Landlord agreement added"))

            loadLandlordAccounts()

            if (handleSuccess)
                handleSuccess()

            changeLandlordAgreement(values.landlordAgreement)

            navigate('/myaccount/landlord', { replace: true })
        }

        return addResponse
    }

    const isCommercial = true
    const isSpanish = (i18n.language === "es")
    const supportNumberFmt = utils.GetCustomerServicePhoneNumberFmt(isCommercial, isSpanish)

    return (
        <Formik
            initialValues={{
                landlordAgreement: '',
                phoneNumber: ''
            }}
            validate={(values) => validate(values, t)}
            onSubmit={addAgreementSubmit}
        >
            {({ values, errors, status, dirty, touched, handleBlur, handleSubmit, submitCount, setFieldValue }) => {
                let isAgreementError = (dirty || submitCount > 0) && touched.landlordAgreement && errors.landlordAgreement !== undefined
                let isPhoneError = (dirty || submitCount > 0) && touched.phoneNumber && errors.phoneNumber !== undefined

                let formStatus = status || {}

                return (
                    <form onSubmit={handleSubmit}>
                        <div className="row align-items-end">
                            <div className="col-12">
                                <span className="text-muted">
                                    <Trans i18nKey="enter_your_landlord_information" t={t}>
                                            Please enter your landlord information below. For
                                            questions please contact SRP Business Customer Services
                                    at <span className="text-nowrap">{{phoneNumber: supportNumberFmt}}</span> weekdays
                                    from 7 AM - 5 PM excluding holidays.
                                    </Trans>

                                </span>
                            </div>
                            {formStatus.error ?
                                <div className="mt-2 col-12">
                                    <div className="srp-alert-error p-2">{t(formStatus.error)}</div>
                                </div> : null}
                            <div className="mt-2 col-xl-7 col-lg-6 col-12">
                                {/* <InputLabel htmlFor="landlordAgreement" error={isAgreementError} className="mt-3">Landlord agreement number</InputLabel> */}
                                <PatternFormat
                                    id="landlordAgreement"
                                    name="landlordAgreement"
                                    value={values.landlordAgreement}
                                    valueIsNumericString={true}
                                    format="################"
                                    type="tel"
                                    customInput={TextField}
                                    fullWidth={true}
                                    onValueChange={v => setFieldValue('landlordAgreement', v.value)}
                                    onBlur={handleBlur}
                                    error={isAgreementError}
                                    helperText={isAgreementError ? t(errors.landlordAgreement) : ''} 
                                    label={t("Landlord agreement number")}
                                />
                                {/* <InputLabel htmlFor="phoneNumber" error={isPhoneError} className="mt-3">Phone number</InputLabel> */}
                                <PatternFormat
                                    id="phoneNumber"
                                    name="phoneNumber"
                                    value={values.phoneNumber}
                                    valueIsNumericString={true}
                                    format="(###) ###-####"
                                    type="tel"
                                    customInput={TextField}
                                    fullWidth={true}
                                    onValueChange={v => setFieldValue('phoneNumber', v.value)}
                                    onBlur={handleBlur}
                                    error={isPhoneError}
                                    helperText={isPhoneError ? t(errors.phoneNumber) : ''} 
                                    label={t("Phone number")}
                                />
                            </div>
                            <div className="col-xl-5 col-lg-6 col-12 pt-2 text-right">
                                <button
                                    type="button"
                                    disabled={addLandlordAgreementStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS}
                                    className="btn srp-btn btn-lightblue"
                                    onClick={hideForm}>
                                    {t("Cancel")}
                                </button>
                                <button
                                    type="submit"
                                    disabled={addLandlordAgreementStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS}
                                    className="ml-2 btn srp-btn btn-green">
                                    {addLandlordAgreementStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS
                                        ? <CircularProgress size={20} thickness={5} color="secondary" />
                                        : t("Submit")}
                                </button>
                            </div>
                        </div>
                    </form>
                )
            }}
        </Formik>
    )
}

const mapStateToProps = state => {
    return { addLandlordAgreementStatus: state.landlord.addLandlordAgreementStatus }
}

const mapDispatchToProps = dispatch => {
    return {
        addLandlordAgreement: (landlordAgreement, phoneNumber) => {
            return dispatch(addLandlordAgreement(landlordAgreement, phoneNumber))
        },
        addSnackbarMessage: (message) => {
            return dispatch(addSnackbarMessage(message))
        },
        loadLandlordAccounts: () => {
            return dispatch(getLandlordAccounts())
        },
        changeLandlordAgreement: (landlordAgreement) => {
            return dispatch(changeSelectedLandlordAgreement(landlordAgreement))
        }
    }
}

AddLandlordForm.propTypes = {
    addLandlordAgreement: PropTypes.func.isRequired,
    addSnackbarMessage: PropTypes.func.isRequired,
    hideForm: PropTypes.func.isRequired,

    handleSuccess: PropTypes.func,
    addLandlordAgreementStatus: PropTypes.string,
    loadLandlordAccounts: PropTypes.func,
    changeLandlordAgreement: PropTypes.func,

    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(AddLandlordForm)

function validate(values, t){
    let errors = {}

    if (values.landlordAgreement.length === 0)
        errors.landlordAgreement = t("Landlord agreement number is required")

    if (values.phoneNumber.length === 0)
        errors.phoneNumber = t("Phone number is required")
    else if (values.phoneNumber.length !== 10)
        errors.phoneNumber = t("You must enter a valid phone number")

    return errors
}