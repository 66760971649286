import React from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { withTranslation } from 'react-i18next'

const CashCardError = ({t}) => {
    return (
        <div>
            <div className="ml-4">
                <h2 id="you-have-reached-this" className="mt-3 pb-4">{t("You have reached this page in error")}</h2>
                <div id="used-a-bookmark" className="mt-4 pb-4">
                {t("remove_from_bookmark")}
                </div>
                <div className="mt-4 pt-4">
                    <Link to="/myaccount/dashboard" className="btn srp-btn btn-lightblue ml-2 mb-2">{t("Go to My Account Dashboard")}</Link>
                </div>
            </div>
        </div>
    );
};

CashCardError.propTypes = {
    t: PropTypes.func.isRequired
}

export default withTranslation('cashPaymentCard')(CashCardError);
