import React from 'react'
import PropTypes from 'prop-types'

import format from '../../../srp_modules/format'

import { DateTime } from 'luxon'

const SinglePaymentDetailsTable = ({paymentList, bankAccountNumber, referenceNumber, t}) => (
    <div>
    {(referenceNumber || referenceNumber===0) &&
    <div>
    <h5 className="text-muted srp-olive-green mb-0 pl-3 pt-3 d-lg-none d-block">{t("Reference number")}: {referenceNumber}</h5>
    <h4 className="text-muted srp-olive-green mb-0 pl-3 pt-3 d-none d-lg-block">{t("Reference number")}: {referenceNumber}</h4>
    </div>
    }

    <table className="table mb-0">
        <thead>
            <tr>
                <th className="border-0 srp-fit" />
                <th className="border-0" />
            </tr>
        </thead>
        <tbody>
            <tr>
                <td className="border-top-0 pl-3 srp-fit">{t("SRP account")}</td>
                <td className="border-top-0">{format.formatBillAccountNumber(paymentList[0].srpAccountNumber)}</td>
            </tr>
            <tr>
                <td className="pl-3 srp-fit">{t("Bank account")}</td>
                <td>{format.maskBankAccountNumber(bankAccountNumber)}</td>
            </tr>
            <tr>
                <td className="pl-3 srp-fit">{t("Payment amount")}</td>
                <td>{'$'+paymentList[0].paymentAmount.toFixed(2)}</td>
            </tr>
            <tr>
                <td className="pl-3 srp-fit">{t("Payment date")}</td>
                <td>{DateTime.now().toFormat('M/d/yyyy')}</td>
            </tr>
            <tr>
                <td />
                <td />
            </tr>
        </tbody>
    </table>
    </div>
)

SinglePaymentDetailsTable.propTypes = {
    paymentList: PropTypes.arrayOf(PropTypes.shape({
        srpAccountNumber: PropTypes.string.isRequired,
        paymentAmount: PropTypes.number.isRequired,
    })),
    bankAccountNumber: PropTypes.string.isRequired,
    referenceNumber: PropTypes.number,
    t: PropTypes.func.isRequired,
}

export default SinglePaymentDetailsTable