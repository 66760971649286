import React from 'react'
import UsageChartMonthlyContainer from './usage-chart-monthly-container'
import PropTypes from 'prop-types'

import { myAccountConstants } from '../../../constants/myaccount-constants'
import { Trans } from 'react-i18next'

const UnmerteredCard = ({isCommercial, t}) =>
{
    let phoneNumber = isCommercial ? myAccountConstants.COMMERCIAL_CUSTOMER_SERVICE_PHONE_NUMBER_FMTTD : + myAccountConstants.RESIDENTIAL_CUSTOMER_SERVICE_PHONE_NUMBER_FMTTD 
    return (
        
    <div>
        <div className="srp-alert-warn">
           <div className={"d-md-block d-none"}>
                {t("unmetered card unable to display large view", {phoneNumber : phoneNumber})}
            </div>
            <div className={"d-block d-md-none"}>
                <Trans i18nKey="unmetered card unable to display mobile view" t={t}>   
                    Unfortunately, we are unable to display usage 
                    data for unmetered accounts. 
                    If you have any questions regarding your usage, 
                    please call SRP Customer Service at <a href={"tel:" + phoneNumber}>{{phoneNumber : phoneNumber}}</a>.
                </Trans>
            </div>
        </div>
        <UsageChartMonthlyContainer targetId="monthlyChart" t={t}/>
    </div>
)
}

UnmerteredCard.propTypes = {
    isCommercial: PropTypes.bool.isRequired,
    t: PropTypes.func.isRequired
}

export default UnmerteredCard