import React from 'react'
import { withTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import '../../../styles/dashboard-page.css'

import CallAnytime from '../../myaccount_header/call-anytime'

import ConfirmPaymentDetailsCardContainer from './confirm-payment-details-card-container'

const SinglePaymentConfirmationPage = ({t}) => (
    <div className="container py-3">
        <CallAnytime />

        <h3 className="mt-3 mb-5">{t("Make a payment")}</h3>

        <div className="row">
            <div className="col-lg-6">
                <ConfirmPaymentDetailsCardContainer />
            </div>
        </div>
    </div>
)

SinglePaymentConfirmationPage.propTypes = {
    t: PropTypes.func.isRequired
}

export default withTranslation("singlePayment")(SinglePaymentConfirmationPage)
