import React from 'react'
import CircularProgress from '@mui/material/CircularProgress'

let style = {
    marginTop: "20%",
    marginBottom: "20%"
}

const OutagePageLoading = () => {

    return(
        <div className="srp-card-details d-flex justify-content-center">
            <CircularProgress size={80} thickness={5} style={style} />
        </div>
    )
}

export default OutagePageLoading