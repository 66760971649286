export const billAccountConstants = {
    ADD_BILL_ACCOUNT_REQUEST: 'ADD_BILL_ACCOUNT_REQUEST',
    ADD_BILL_ACCOUNT_SUCCESS: 'ADD_BILL_ACCOUNT_SUCCESS',
    ADD_BILL_ACCOUNT_FAILURE: 'ADD_BILL_ACCOUNT_FAILURE',

    ADD_MULTIPLE_BILL_ACCOUNTS_REQUEST: 'ADD_MULTIPLE_BILL_ACCOUNTS_REQUEST',
    ADD_MULTIPLE_BILL_ACCOUNTS_SUCCESS: 'ADD_MULTIPLE_BILL_ACCOUNTS_SUCCESS',
    ADD_MULTIPLE_BILL_ACCOUNTS_FAILURE: 'ADD_MULTIPLE_BILL_ACCOUNTS_FAILURE',

    UPDDATE_BILL_ACCOUNT_NICKNAME_REQUEST: 'UPDDATE_BILL_ACCOUNT_NICKNAME_REQUEST',
    UPDDATE_BILL_ACCOUNT_NICKNAME_SUCCESS: 'UPDDATE_BILL_ACCOUNT_NICKNAME_SUCCESS',
    UPDDATE_BILL_ACCOUNT_NICKNAME_FAILURE: 'UPDDATE_BILL_ACCOUNT_NICKNAME_FAILURE',

    DELETE_BILL_ACCOUNT_REQUEST: 'DELETE_BILL_ACCOUNT_REQUEST',
    DELETE_BILL_ACCOUNT_SUCCESS: 'DELETE_BILL_ACCOUNT_SUCCESS',
    DELETE_BILL_ACCOUNT_FAILURE: 'DELETE_BILL_ACCOUNT_FAILURE',

    GET_LIST_REQUEST: 'GET_LIST_REQUEST',
    GET_LIST_SUCCESS: 'GET_LIST_SUCCESS',
    GET_LIST_FAILURE: 'GET_LIST_FAILURE',

    GET_UNLINKED_ACCOUNTS_REQUEST: 'GET_UNLINKED_ACCOUNTS_REQUEST',
    GET_UNLINKED_ACCOUNTS_SUCCESS: 'GET_UNLINKED_ACCOUNTS_SUCCESS',
    GET_UNLINKED_ACCOUNTS_FAILURE: 'GET_UNLINKED_ACCOUNTS_FAILURE',

    UPDATE_SELCTED_UNLINKED_ACCOUNTS: 'UPDATE_SELCTED_UNLINKED_ACCOUNTS',

    UPDATE_MY_ACCOUNT_TYPE: 'UPDATE_MY_ACCOUNT_TYPE',

    GET_BUSINESS_INFO_REQUEST: "GET_BUSINESS_INFO_REQUEST",
    GET_BUSINESS_INFO_SUCCESS:"GET_BUSINESS_INFO_SUCCESS",
    GET_BUSINESS_INFO_FAILURE:"GET_BUSINESS_INFO_FAILURE",

    GET_BILLING_ADDRESS_REQUEST: 'GET_BILLING_ADDRESS_REQUEST',
    GET_BILLING_ADDRESS_SUCCESS: 'GET_BILLING_ADDRESS_SUCCESS',
    GET_BILLING_ADDRESS_FAILURE: 'GET_BILLING_ADDRESS_FAILURE',

    UPDATE_BILLING_ADDRESS_REQUEST:"UPDATE_BILLING_ADDRESS_REQUEST",
    UPDATE_BILLING_ADDRESS_SUCCESS:"UPDATE_BILLING_ADDRESS_SUCCESS",
    UPDATE_BILLING_ADDRESS_FAILURE:"UPDATE_BILLING_ADDRESS_FAILURE",

    GET_RATE_CODE_REQUEST: 'GET_RATE_CODE_REQUEST',
    GET_RATE_CODE_SUCCESS: 'GET_RATE_CODE_SUCCESS',
    GET_RATE_CODE_FAILURE: 'GET_RATE_CODE_FAILURE',

    GET_ADDRESS_REQUEST: 'GET_ADDRESS_REQUEST',
    GET_ADDRESS_SUCCESS: 'GET_ADDRESS_SUCCESS',
    GET_ADDRESS_FAILURE: 'GET_ADDRESS_FAILURE',

    GET_PHONE_REQUEST: 'GET_PHONE_REQUEST',
    GET_PHONE_SUCCESS: 'GET_PHONE_SUCCESS',
    GET_PHONE_FAILURE: 'GET_PHONE_FAILURE',

    GET_ACCOUNT_HISTORY_REQUEST: 'GET_ACCOUNT_HISTORY_REQUEST',
    GET_ACCOUNT_HISTORY_SUCCESS: 'GET_ACCOUNT_HISTORY_SUCCESS',
    GET_ACCOUNT_HISTORY_FAILURE: 'GET_ACCOUNT_HISTORY_FAILURE',

    SELECT_BILL_ACCOUNT_REQUEST: 'SELECT_BILL_ACCOUNT_REQUEST',
    SELECT_BILL_ACCOUNT_SUCCESS: 'SELECT_BILL_ACCOUNT_SUCCESS',
    SELECT_BILL_ACCOUNT_FAILURE: 'SELECT_BILL_ACCOUNT_FAILURE',

    GET_ACCOUNT_MANAGER_REQUEST: 'GET_ACCOUNT_MANAGER_REQUEST',
    GET_ACCOUNT_MANAGER_SUCCESS: 'GET_ACCOUNT_MANAGER_SUCCESS',
    GET_ACCOUNT_MANAGER_FAILURE: 'GET_ACCOUNT_MANAGER_FAILURE',

    GET_CURRENT_BILL_INFO_REQUEST: 'GET_CURRENT_BILL_INFO_REQUEST',
    GET_CURRENT_BILL_INFO_SUCCESS: 'GET_CURRENT_BILL_INFO_SUCCESS',
    GET_CURRENT_BILL_INFO_FAILURE: 'GET_CURRENT_BILL_INFO_FAILURE',

    GET_MPOWER_DETAILS_REQUEST: 'GET_MPOWER_DETAILS_REQUEST',
    GET_MPOWER_DETAILS_SUCCESS: 'GET_MPOWER_DETAILS_SUCCESS',
    GET_MPOWER_DETAILS_FAILURE: 'GET_MPOWER_DETAILS_FAILURE',
    
    POWER_ACCOUNT: 1,
    WATER_ACCOUNT: 2,

    GET_COUNTRY_ASSESSOR_INFO_REQUEST: 'GET_COUNTRY_ASSESSOR_INFO_REQUEST',
    GET_COUNTRY_ASSESSOR_INFO_SUCCESS: 'GET_COUNTRY_ASSESSOR_INFO_SUCCESS',
    GET_COUNTRY_ASSESSOR_INFO_FAILURE: 'GET_COUNTRY_ASSESSOR_INFO_FAILURE',

    CLOSED_STATE: {
        UNKNOWN: 0,
        OPEN: 1,
        CLOSED: 2,
        PURGED: 3
    },
    GET_FORM_STATUS_REQUEST: "GET_FORM_STATUS_REQUEST",
    GET_FORM_STATUS_SUCCESS: "GET_FORM_STATUS_SUCCESS",
    GET_FORM_STATUS_FAILURE: "GET_FORM_STATUS_FAILURE",

    GET_SCANNABLE_CODE_REQUEST: "GET_SCANNABLE_CODE_REQUEST",
    GET_SCANNABLE_CODE_SUCCESS: "GET_SCANNABLE_CODE_SUCCESS",
    GET_SCANNABLE_CODE_FAILURE: "GET_SCANNABLE_CODE_FAILURE",

    GET_COMPARE_MY_PRICE_PLAN_REQUEST: "GET_COMPARE_MY_PRICE_PLAN_REQUEST",
    GET_COMPARE_MY_PRICE_PLAN_SUCCESS: "GET_COMPARE_MY_PRICE_PLAN_SUCCESS",
    GET_COMPARE_MY_PRICE_PLAN_FAILURE: "GET_COMPARE_MY_PRICE_PLAN_FAILURE",

    SELECT_COMPARE_MY_PRICE_PLAN_BUSINESSOFFERING: "SELECT_COMPARE_MY_PRICE_PLAN_BUSINESSOFFERING",
    UPDATE_CHANGE_PRICE_PLAN_FROM_PAGE: "UPDATE_CHANGE_PRICE_PLAN_FROM_PAGE",
    RESET_CHANGE_MY_PRICE_PLAN: "RESET_CHANGE_MY_PRICE_PLAN",

    SUMMARY_BILLING_STATUS: {
        NONE: 0,
        PARENT: 1,
        CHILD: 2
    },

    GET_TRANSFERRED_ACCOUNT_STATUS_REQUEST: "GET_TRANSFERRED_ACCOUNT_STATUS_REQUEST",
    GET_TRANSFERRED_ACCOUNT_STATUS_SUCCESS: "GET_TRANSFERRED_ACCOUNT_STATUS_SUCCESS",
    GET_TRANSFERRED_ACCOUNT_STATUS_FAILURE: "GET_TRANSFERRED_ACCOUNT_STATUS_FAILURE",

    HASH_BILL_ACCOUNT_REQUEST: "HASH_BILL_ACCOUNT_REQUEST",
    HASH_BILL_ACCOUNT_SUCCESS: "HASH_BILL_ACCOUNT_SUCCESS",
    HASH_BILL_ACCOUNT_FAILURE: "HASH_BILL_ACCOUNT_FAILURE",

    DISMISS_TRANSFERRED_ACCOUNT_PROMPT: "DISMISS_TRANSFERRED_ACCOUNT_PROMPT"
}