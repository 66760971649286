/* eslint react/no-did-update-set-state: "off" */
import React from 'react'
import { styled } from '@mui/material/styles';
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import Snackbar from '@mui/material/Snackbar'

import { myAccountConstants } from '../../constants/myaccount-constants'
import { dismissSnackbarMessage } from '../../actions/common/snackbar'

const PREFIX = 'srp-snackbar'

const classes = {
    close: `${PREFIX}-close`,
    root: `${PREFIX}-root`
}

const StyledSnackbar = styled(Snackbar)(({ theme }) => ({
    [`& .${classes.close}`]: {
        width: theme.spacing.unit * 4,
        height: theme.spacing.unit * 4,
    },

    [`& .${classes.root}`]: {
        background: 'rgb(9, 9, 9)',
        fontSize: '1rem'
    }
}))

class SrpSnackbar extends React.Component {
    constructor(props) {
        super(props)

        // Since the snackbar is effectively being mounted right away,
        // it should be safe to assume that initial state is empty.
        this.state = {
            open: false,
            message: null,
            actions: null
        }

        this.handleClose = this.handleClose.bind(this)
        this.updateSnackbar = this.updateSnackbar.bind(this)
    }

    componentDidMount() {
        if (!this.state.open && this.props.snackbarMessages.length > 0) {
            this.updateSnackbar(this.props.snackbarMessages[0])
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (!this.state.open && this.props.snackbarMessages.length > 0) {
            if (prevState.open) {
                // If the snackbar is in the process of being hidden, delay processing the next message
                setTimeout(() => this.updateSnackbar(this.props.snackbarMessages[0]), myAccountConstants.SNACK_BAR_MULTI_MESSAGE_DELAY)
            }
            else {
                this.updateSnackbar(this.props.snackbarMessages[0])
            }
        }

        if (this.state.open) {
            let prevMessage = prevProps.snackbarMessages.length > 0 ? prevProps.snackbarMessages[0] : { }
            let nextMessage = this.props.snackbarMessages.length > 0 ? this.props.snackbarMessages[0] : { }

            // Another component dismissed the snackbar, start the close action;
            // after open is set to false, react should trigger a re-render,
            // which will process the next message in the queue
            if (prevMessage !== nextMessage) {
                this.setState({ ...this.state, open: false })
            }
        }
    }

    handleClose(event, reason) {
        if (reason === 'clickaway') {
            return
        }

        this.props.dismissSnackbarMessage()
    }

    updateSnackbar(snackbarMessage) {
        this.setState({
            ...this.state,
            open: true,
            message: snackbarMessage.message,
            actions: snackbarMessage.actions
        })
    }

    render() {
        return (
            <StyledSnackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={this.state.open}
                autoHideDuration={myAccountConstants.SNACK_BAR_DURATION}
                onClose={this.handleClose}
                ContentProps={{
                    'aria-describedby': 'message-id',
                    classes: {
                        root: classes.root
                    }
                }}
                message={this.state.message}
                action={this.state.actions}
            />

        )
     }
}

const mapStateToProps = (state) => {
    return {
        snackbarMessages: state.myAccount.snackbarMessages
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        dismissSnackbarMessage: () => {
            dispatch(dismissSnackbarMessage())
        }
    }
}

SrpSnackbar.propTypes = {
  snackbarMessages: PropTypes.arrayOf(PropTypes.object),
  dismissSnackbarMessage: PropTypes.func.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(SrpSnackbar)