import React from 'react'
import { withTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import TextField from '@mui/material/TextField'
import { PatternFormat } from 'react-number-format'

const BankAccountNumberFieldPure = props => {
    let isError = props.touched['bankAccountNumberLeadingZeroesAreSignificant']
        && Object.prototype.hasOwnProperty.call(props.errors, 'bankAccountNumberLeadingZeroesAreSignificant')
    let errMsg = isError ? props.errors['bankAccountNumberLeadingZeroesAreSignificant'] : ''
    let meta = {
        id: 'bankAccountNumberLeadingZeroesAreSignificant',
        name: 'bankAccountNumberLeadingZeroesAreSignificant',
        label: props.t("Bank account number"),
        helperText: errMsg==="" ? "" : props.t(errMsg),
        error: isError
    }

    let textFieldProps = {
        value: !props.value ? '' : props.value,
        onChange: props.onChange,
        onBlur: props.onBlur,
        errors: props.errors,
        touched: props.touched,
        fullWidth: props.fullWidth
    }

    return (
        <PatternFormat
            {...meta}
            {...textFieldProps}
            valueIsNumericString={true}
            format="#################"
            type="tel"
            customInput={TextField}
            InputProps={{ endAdornment: props.endAdornment }}
        />
    )
}

BankAccountNumberFieldPure.propTypes = {
    errors: PropTypes.shape({
        bankAccountNumberLeadingZeroesAreSignificant: PropTypes.string
    }).isRequired,
    fullWidth: PropTypes.bool,
    endAdornment: PropTypes.object.isRequired,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired,
    onBlur: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    touched: PropTypes.shape({
        bankAccountNumberLeadingZeroesAreSignificant: PropTypes.bool
    }).isRequired,
    value: PropTypes.string.isRequired
}

const BankAccountNumberField = withTranslation("singlePurchasePage")(BankAccountNumberFieldPure)
export { BankAccountNumberField as default, BankAccountNumberFieldPure }