import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import * as loadingStatus from '../../constants/loading-status-constants'
import DateRangePickers from './daily-date-range-pickers'
import DailyUsageContainer from './daily-usage-container'
import DailyNetContainer from './daily-net-container'
import UsagePageLoading from './usage-page-loading'
import {getDailyUsage} from '../../actions/auth/usage/daily-usage-actions'
import {getRateMetaData} from '../../actions/auth/usage/rate-meta-data-actions'
import {getDailyWeather} from '../../actions/auth/weather/weather-data-actions'
import {rateMetaDataConstants} from '../../constants/rate-meta-data-constants'
import NoDailyUsageMessage from './no-daily-usage-message'
import UnmeteredMessage from './unmetered-message'
import SummaryMasterMessage from './summary-master-message'
import LookingForUsage from './looking_for_usage'
import { withTranslation } from 'react-i18next' 

class DailyUsageFormContainer extends React.Component {
    constructor(props){
        super(props)
    }

    componentDidMount(){
        if (this.props.rateMetaData.rateMetaDataStatus === loadingStatus.LOADING_STATUS_INIT
            && this.props.selectedBillAccount > 0)
                this.props.getRateMetaDataForAccount(this.props.selectedBillAccount)
        if (this.props.dailyUsageStatus === loadingStatus.LOADING_STATUS_INIT && this.props.selectedBillAccount > 0){
            this.props.getDailyUsageForAccount(this.props.selectedBillAccount)
        }
        if (this.props.dailyWeatherStatus === loadingStatus.LOADING_STATUS_INIT){
            this.props.getDailyWeather()
        }


    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.dailyUsageStatus === loadingStatus.LOADING_STATUS_INIT && nextProps.selectedBillAccount > 0)
            this.props.getDailyUsageForAccount(nextProps.selectedBillAccount)
        if (nextProps.rateMetaData.rateMetaDataStatus === loadingStatus.LOADING_STATUS_INIT && nextProps.selectedBillAccount > 0)
            this.props.getRateMetaDataForAccount(nextProps.selectedBillAccount)
        if (nextProps.dailyWeatherStatus  === loadingStatus.LOADING_STATUS_INIT && nextProps.selectedBillAccount > 0)
            this.props.getDailyWeather()

    }

    render(){
        if(this.props.isPendingTurnOn)
        return(
            <div>
                <LookingForUsage t={this.props.t}/>
            </div>
        )
        if (this.props.rateMetaData.rateMetaDataStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS
            || this.props.rateMetaData.rateMetaDataStatus === loadingStatus.LOADING_STATUS_INIT
            || this.props.dailyUsageStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS
            || this.props.dailyUsageStatus === loadingStatus.LOADING_STATUS_INIT
            || this.props.dailyWeatherStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS
            || this.props.dailyWeatherStatus === loadingStatus.LOADING_STATUS_INIT
        )
        return(
            <div>
                <UsagePageLoading/>
            </div>
        )
        if(this.props.rateMetaData.rateMetaDataStatus === loadingStatus.LOADING_STATUS_FAILURE
            || this.props.dailyUsageStatus === loadingStatus.LOADING_STATUS_FAILURE)
        return(
            <div>
                <NoDailyUsageMessage t={this.props.t}/>
            </div>
        )

        if(this.props.rateMetaData.rateMetaDataStatus === loadingStatus.LOADING_STATUS_SUCCESS
            && this.props.rateMetaData.rateMetaData.summaryBilling === rateMetaDataConstants.SUMMARY_BILLING_PARENT)
            return(
                <SummaryMasterMessage isCommercial={this.props.isCommercial} t={this.props.t}/>
            )
        if(this.props.rateMetaData.rateMetaDataStatus === loadingStatus.LOADING_STATUS_SUCCESS
            && !this.props.rateMetaData.rateMetaData.isMetered)
            return(
                <div><UnmeteredMessage isCommercial={this.props.isCommercial} t={this.props.t}/></div>
            )


        if(this.props.rateMetaData.rateMetaData.isNetBilled)
        return(
            <div>
                <DateRangePickers t={this.props.t}/>
                <DailyNetContainer t={this.props.t}/>
            </div>)
         if(this.props.dailyUsageList.length < 1
            || this.props.rateMetaData.rateMetaData.isNonSmartMeterRate)
         return(
             <div>
                 <DateRangePickers t={this.props.t}/>
                 <NoDailyUsageMessage t={this.props.t}/>
             </div>
         )
        return(
            <div>
                <DateRangePickers t={this.props.t}/>
                <DailyUsageContainer t={this.props.t}/>
            </div>)


    }
}

const mapStateToProps = state => {
    return {
        ...state.accountInfo.usage,
        ...state.accountInfo.usagePageUI,
        ...state.weatherData,
        ...state.dailyUsage,
        ...state.accountInfo.payment,
        isTokenless: state.accountInfo.payment.payment.supportsRemotePrepay,
        selectedBillAccount: state.accountInfo.billAccount.selectedBillAccount,
        isCommercial: state.accountInfo.billAccount.selectedBillAccountDetails.isCommercial,
        rateMetaData: state.rateMetaData,
        isPendingTurnOn: state.accountInfo.billAccount.selectedBillAccountDetails.isPendingTurnOn
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getRateMetaDataForAccount: (billAccount) => {
            dispatch(getRateMetaData(billAccount))
        },
        getDailyUsageForAccount: (billAccount) => {
            dispatch(
             getDailyUsage(billAccount))
        },
        getDailyWeather: () =>
        {
            dispatch (
                getDailyWeather())
        }
    }
}

DailyUsageFormContainer.propTypes = {
    showDailyCostVerbiage: PropTypes.object,
    showDailyUsageVerbiage: PropTypes.object,
    dailyButtonClassNames: PropTypes.object,
    rateMetaData: PropTypes.object,
    selectedBillAccount: PropTypes.number,
    getRateMetaDataForAccount: PropTypes.func,
    getDailyUsageForAccount: PropTypes.func,
    dailyUsageStatus: PropTypes.string,
    dailyWeatherStatus: PropTypes.string,
    getDailyWeather: PropTypes.func,
    paymentStatus: PropTypes.string,
    isTokenless: PropTypes.bool,
    dailyUsageList: PropTypes.array,
    isCommercial: PropTypes.bool,
    isPendingTurnOn: PropTypes.bool
}

export default withTranslation("usageForms")(connect(mapStateToProps, mapDispatchToProps)(DailyUsageFormContainer));