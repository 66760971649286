import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import * as loadingStatus from '../../../constants/loading-status-constants'
import config from 'my-account-config'
class BarCodeContainer extends React.Component {
    render() {
        let cashCardUrl = config.appPath !== '/' ? '..' + config.appPath + '/myaccount/cashpaymentcard' : '/myaccount/cashpaymentcard'
        
        if (this.props.selectedBillAccountDetails.isCommercial)
            return null

        if (this.props.selectBillAccountStatus === loadingStatus.LOADING_STATUS_FAILURE 
            ) {
            return (
                null
            )
        }

        if (this.props.selectBillAccountStatus === loadingStatus.LOADING_STATUS_SUCCESS && this.props.selectedBillAccountDetails.isBarcodeEligible) {
            return (
               <div>
                     <div style={{display:"inline-block"}}>
                        <label className="mb-0 font-weight-bold">{this.props.t("In-person payments")}</label>
                        <p className="form-control-static pt-0">
                        <a className="text-primary-ada" target="_blank"
                           href={cashCardUrl}>
                            {this.props.t("My cash payment card")}
                        </a>
                        </p>
                    </div>
               </div>
            )
        }
        else{
            return null
        }
       
    }
}

function mapStateToProps(state) {
    return {
        ...state.accountInfo.billAccount,
        ...state.rateMetaData
    }
}

BarCodeContainer.propTypes = {
    selectedBillAccountDetails: PropTypes.object.isRequired,
    selectBillAccountStatus: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired
}

export default connect(mapStateToProps)(BarCodeContainer)