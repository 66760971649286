import React from 'react'
import PropTypes from 'prop-types'
import ReactDOM from 'react-dom'
import { connect } from 'react-redux'
import TextField from '@mui/material/TextField'

import * as loadingStatus from '../../constants/loading-status-constants'

let google
class SearchFieldWithAutocomplete extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            searchAddress: this.props.searchValue,
        }

        this.autocompleteSearch = {}
        this.googleAutoCompleteForAddress = this.googleAutoCompleteForAddress.bind(this)
        this.handleAutocompleteSearch = this.handleAutocompleteSearch.bind(this)
        this.handleSearchChange = this.handleSearchChange.bind(this)
    }

    componentDidMount(){
        if (this.props.mapScriptLoadedStatus === loadingStatus.LOADING_STATUS_SUCCESS) {
            this.googleAutoCompleteForAddress(ReactDOM.findDOMNode(this.searchAddressRef))
        } 
    }
    componentDidUpdate(prevProps) {
        if (prevProps.mapScriptLoadedStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS
            && this.props.mapScriptLoadedStatus === loadingStatus.LOADING_STATUS_SUCCESS) {
            this.googleAutoCompleteForAddress(ReactDOM.findDOMNode(this.searchAddressRef))
        }
    }

    googleAutoCompleteForAddress(addressElement) {
        google = window.google
        if (addressElement) {
            this.autocompleteSearch = new google.maps.places.Autocomplete(addressElement)
            this.autocompleteSearch.setBounds(new google.maps.LatLngBounds(new google.maps.LatLng(32.599615, -113.141349), new google.maps.LatLng(34.161393, -111.141838)))
            this.autocompleteSearch.addListener("place_changed", ()=>{
                let place = this.autocompleteSearch.getPlace()
                this.handleAutocompleteSearch(place.formatted_address)
            })
        }
    }

    handleAutocompleteSearch(addressSelected) {
        this.setState({
            searchAddress: this.searchAddressRef ? addressSelected : "",
        })
        this.props.handleChange(addressSelected)
    }

    handleSearchChange(value) {
        this.setState({
            searchAddress: value
        })
        this.props.handleChange(value)
    }

    render() {
        return (
            <TextField
                inputProps={{ ref: x => this.searchAddressRef = x }}
                id={this.props.id}
                label={this.props.label}
                value={this.state.searchAddress}
                placeholder={this.props.placeholderText}
                error={this.props.hasError}
                helperText={this.props.errorText}
                onChange={e => this.handleSearchChange(e.target.value)}
                fullWidth
            />
        )
    }
}

const mapStateToProps = state => {
    return { ...state.paymentLocation, ...state.login }
}

SearchFieldWithAutocomplete.propTypes = {
    id: PropTypes.string,
    label: PropTypes.string,
    searchValue: PropTypes.string,
    placeholderText: PropTypes.string,
    hasError: PropTypes.bool,
    errorText: PropTypes.string,
    handleChange: PropTypes.func,
    useStyle: PropTypes.bool,
    hasFullWidth: PropTypes.bool,
    mapScriptLoadedStatus: PropTypes.string
}

export default connect(mapStateToProps)(SearchFieldWithAutocomplete)