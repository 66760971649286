import React from 'react'
import PropTypes from 'prop-types'

import { withTranslation } from 'react-i18next'

import '../../../styles/dashboard-page.css'

import MakePaymentByCard from './make-payment-by-card'
import OtherPaymentOptions from './other-payment-options'

import CallAnytime from '../../myaccount_header/call-anytime'

import SinglePaymentInputCard from './single-payment-input-card'
import MultiplePaymentInputCardContainer from './multi-account/multiple-payment-input-card-container'
import CheckDiagramCard from '../common/check-diagram-card'

import { formStartEvent } from '../../../srp_modules/analytics-events'
import { guestPaymentConstants } from '../common/guest-payment-constants'

const GuestPaymentPage = ({multiplePaymentsEnabled, formStartEventEmitted, setFormStartEventEmitted, i18n, t}) => {
    //eslint-disable-next-line no-unused-vars
    const formDataChanged = (e) => {
        if (formStartEventEmitted === false) {
            const formName = guestPaymentConstants.ADOBE_ANALYTICS_FORM_NAME
            const stepName = guestPaymentConstants.GUEST_PAYMENT_STEPS[0]
            formStartEvent(formName, stepName)

            setFormStartEventEmitted()
        }
    }

    return (
        <div className="container py-3">
            <CallAnytime />

            <h3 className="mt-3 mb-4">{t("Make a payment")}</h3>

            <div className="row mt-3">
                <div className="col-lg-6">
                    <MakePaymentByCard parentPage="GuestPaymentPage" t={t} i18n={i18n} />

                    <div className="mt-3">
                    {multiplePaymentsEnabled
                        ? <MultiplePaymentInputCardContainer t={t} i18n={i18n} formDataChanged={formDataChanged} />
                        : <SinglePaymentInputCard t={t} i18n={i18n} formDataChanged={formDataChanged} />}
                    </div>
                </div>

                <div className="col-lg-6">
                    <div className="mt-3 mt-lg-0">
                        <OtherPaymentOptions t={t} i18n={i18n} />
                    </div>

                    <div className="mt-3 d-none d-lg-block">
                        <CheckDiagramCard t={t} i18n={i18n} />
                    </div>
                </div>
            </div>
        </div>
    )
}

GuestPaymentPage.propTypes = {
    multiplePaymentsEnabled: PropTypes.bool.isRequired,
    formStartEventEmitted: PropTypes.bool.isRequired,
    setFormStartEventEmitted: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired,
    t: PropTypes.func.isRequired,
}

export default withTranslation(["guestPayment", "earlyWarning"])(GuestPaymentPage)