import React from "react"
import PropTypes from 'prop-types'
import CashOnlyPaymentCard from '../../common_payment/cash-only-payment-card'
import HowToCashPayCardContainer from '../../cash_card_page/common_payment_page/how-to-cash-pay-card-container'
import OtherPaymentOptions from '../../common_payment/other-payment-options'
const CashOnlyPaymentContainer = ({ isBarcodeEligible }) => (
    <div>
        <div className="row p-2">
            <div className="col-lg-6">
                <div className="row">
                    <CashOnlyPaymentCard />
                </div>
                <div className="row mt-4">
                    <HowToCashPayCardContainer isBarcodeEligible={isBarcodeEligible} />
                </div>
            </div>
            <div className="col-lg-6 pl-lg-4 p-0 pt-4 pt-lg-0">
                <OtherPaymentOptions
                    showAutoPay={false}
                    showCreditDebit={false}
                    showGiftPayment={false}
                    showPayCenterLink={true}
                />
            </div>
        </div>
    </div>
)
CashOnlyPaymentContainer.propTypes = {
    isBarcodeEligible: PropTypes.bool
 }
export default CashOnlyPaymentContainer