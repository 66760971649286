import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import config from 'my-account-config'

import { withTranslation } from 'react-i18next'

import SrpModal from '../../common_modal/srp-modal'
import { sendUsageData, getHas12MonthsBillingHistory, getCalculatorURL } from '../../../actions/auth/forms/clean-power-actions'
import utils from '../../../srp_modules/utils'

const initialState = {
    showSusPort: false,
    solarChoiceModal: null
}

class SolarChoiceEstimator extends React.Component {
    constructor(props) {
        super(props)

        this.state = initialState

        this.susPortClick = this.susPortClick.bind(this)
        this.closeModalClick = this.closeModalClick.bind(this)
        this.getModalInfo = this.getModalInfo.bind(this)
    }

    susPortClick() {
        return () => {
            let newPort = !this.state.showSusPort
            this.setState({ ...initialState, showSusPort: newPort })
        }
    }

    closeModalClick() {
        this.setState({ ...this.state, solarChoiceModal: null })
    }

    async getModalInfo(t, isSpanish) {
        let wattPlanInfo = {
            wattPlanType: "SolarChoice",
            billAccount: this.props.selectedBillAccount
        }

        let buttons = []
        let title = ''
        let body = ''

        let result = await this.props.get12MonthsHistory(this.props.selectedBillAccount)

        if (result.error === true) {
            let errorButton = (<div key="1">
                <button key="solarChoice1" className="btn srp-btn btn-blue ml-2" type="button" onClick={() => { this.closeModalClick() }}>{t("OK")}</button>
            </div>)
            buttons.push(errorButton)
            title = t("Error encountered")
            body = t("cannot_set_up_Solar_Choice_calculator", {phone: utils.GetCustomerServicePhoneNumberFmt(this.props.isCommercial, isSpanish)})
        }
        else if (result.ratePlanIsEligible === false) {
            let submissionForm = (<div key="2">
                <button key="solarChoice2" className="btn srp-btn btn-blue ml-2" type="button" onClick={() => { this.closeModalClick() }}>{t("OK")}</button>
            </div>)
            buttons.push(submissionForm)
            title = t("We're sorry")
            body = t("Your_current_price_plan_isnt_eligible", {phone: utils.GetCustomerServicePhoneNumberFmt(this.props.isCommercial, isSpanish)})
        }
        else if (result.hasEnoughHistory === true) {
            let submissionForm = (<div key="3">
                <button key="solarChoice3" className="btn srp-btn btn-lightblue ml-2" type="button" onClick={() => { this.closeModalClick() }}>{t("Decline")}</button>
                <button key="solarChoice4" className="btn srp-btn btn-blue ml-2" type="button" onClick={this.props.submitSendUsageData(wattPlanInfo, this.closeModalClick, result.solarChoiceURL)}>{t("Accept")}</button>
            </div>)
            buttons.push(submissionForm)
            title = t("Consent needed to start estimate")
            body = t("By_clicking_the_Accept_button")
        }
        else if (result.hasEnoughHistory === false) {
            let submissionForm = (<div key="4">
                <button key="solarChoice5" className="btn srp-btn btn-lightblue ml-2" type="button" onClick={() => { this.closeModalClick() }}>{t("Close")}</button>
            </div>)
            buttons.push(submissionForm)
            title = t("Not enough data")
            body = t("less_than_12_months_service", {phone: utils.GetCustomerServicePhoneNumberFmt(this.props.isCommercial, isSpanish)})
        }

        let solarChoiceModal = (<SrpModal
            open={true}
            title={title}
            content={<div>{body}</div>}
            closeClick={this.closeModalClick}
            buttons={buttons}
        />)

        this.setState({ ...this.state, solarChoiceModal: solarChoiceModal })
    }

    render() {
        const t = this.props.t
        const i18n = this.props.i18n
        const isSpanish = i18n.language==="es"

        return (
            <div className="font-16">
                <b>{t("How much does it cost?")}</b>
                <p className="pb-1 text-muted">
                    {t("Use_SRPs_calculator")}
                </p>
                <div>
                    <button className="btn btn-blue" onClick={() => { this.getModalInfo(t, isSpanish) }}>{t("Get estimate")}</button>
                </div>

                {this.state.solarChoiceModal}
            </div>
        )
    }
}

SolarChoiceEstimator.propTypes = {
    isCommercial: PropTypes.bool,
    selectedBillAccount: PropTypes.number.isRequired,
    hasEnoughHistoryInfo: PropTypes.object,
    hasEnoughHistoryStatus: PropTypes.string,
    submitSendUsageData: PropTypes.func,
    get12MonthsHistory: PropTypes.func,
    getCalculatorURL: PropTypes.func,
    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired,
}

const mapStateToProps = state => {
    return {
        isCommercial: state.accountInfo.billAccount.selectedBillAccountDetails.isCommercial,
        selectedBillAccount: state.accountInfo.billAccount.selectedBillAccount,
        hasEnoughHistoryInfo: state.cleanPower.hasEnoughHistoryInfo,
        hasEnoughHistoryStatus: state.cleanPower.hasEnoughHistoryStatus
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getCalculatorURL: async () => {

            let result = await dispatch(getCalculatorURL())

            if (result.error === true || result.payload.success === false) {
                return {
                    error: true
                }
            }

            return result.payload
        },
        get12MonthsHistory: async (billAccount) => {

            let result = await dispatch(getHas12MonthsBillingHistory(billAccount))

            if (result.error === true || result.payload.success === false) {
                return {
                    error: true
                }
            }

            return result.payload
        },
        submitSendUsageData: (wattPlanInfo, closeModal, actionURL) => async () => {

            closeModal()

            let appPathWithTrailingSlash = config.appPath === '/' ? config.appPath : `${config.appPath}/`
            let redirect = window.location.origin + appPathWithTrailingSlash + 'solarchoiceredirect'
            let newWindow = window.open(redirect, 'SolarChoiceCPR')

            let result = await dispatch(sendUsageData(wattPlanInfo))

            let newBody = newWindow.document.getElementsByTagName('body')[0]

            if (result.error === true || result.payload.success === false) {
                newWindow.location = window.location.origin + appPathWithTrailingSlash + 'error'
                return false
            }

            let form = document.createElement('form')
            form.setAttribute('method', 'POST')
            form.setAttribute('action', actionURL)

            let input1 = document.createElement('input')
            input1.setAttribute('type', 'hidden')
            input1.setAttribute('name', 'customerid')
            input1.setAttribute('value', result.payload.cachedIntervalDataInfo.customerId)

            let input2 = document.createElement('input')
            input2.setAttribute('type', 'hidden')
            input2.setAttribute('name', 'sessionid')
            input2.setAttribute('value', result.payload.cachedIntervalDataInfo.sessionId)

            let input3 = document.createElement('input')
            input3.setAttribute('type', 'hidden')
            input3.setAttribute('name', 'uuid')
            input3.setAttribute('value', result.payload.cachedIntervalDataInfo.uuid)

            form.appendChild(input1)
            form.appendChild(input2)
            form.appendChild(input3)

            newBody.appendChild(form)

            form.submit()
        }
    }
}

export default withTranslation('solarChoice')(connect(mapStateToProps, mapDispatchToProps)(SolarChoiceEstimator))
