import { RSAA } from 'redux-api-middleware'

import { guestPaymentBankInfoConstants } from '../../../constants/guest/guest-payment-bank-info-constants'
import { guestPaymentListConstants } from '../../../constants/guest/guest-payment-list-constants'
import { guestPaymentApiConstants } from '../../../constants/guest/guest-payment-api-constants'

import utils from '../../../srp_modules/utils'

export const storeBankInfoCollected = ({bankFirstName, bankLastName, bankPhoneNumber, bankEmailAddress, bankRoutingNumber, bankAccountNumberLeadingZeroesAreSignificant}) => ({
    type: guestPaymentBankInfoConstants.GUEST_PAYMENT_STORE_BANK_INFO_COLLECTED,
    bankFirstName,
    bankLastName,
    bankPhoneNumber,
    bankEmailAddress,
    bankRoutingNumber,
    bankAccountNumberLeadingZeroesAreSignificant
})

export const clearBankInfo = () => ({
    type: guestPaymentBankInfoConstants.GUEST_PAYMENT_CLEAR_BANK_INFO
})

export const addPaymentToList = ({srpAccountNumber, paymentAmount}) => ({
    type: guestPaymentListConstants.ADD_GUEST_PAYMENT_TO_LIST,
    srpAccountNumber,
    paymentAmount
})

export const storePaymentList = (paymentList) => ({
    type: guestPaymentListConstants.STORE_GUEST_PAYMENT_LIST,
    paymentList
})

export const clearPaymentList = () => ({
    type: guestPaymentListConstants.CLEAR_GUEST_PAYMENT_LIST
})

export const enableMultiplePayments = () => ({
    type: guestPaymentListConstants.ENABLE_MULTIPLE_GUEST_PAYMENTS
})

export const disableMultiplePayments = () => ({
    type: guestPaymentListConstants.DISABLE_MULTIPLE_GUEST_PAYMENTS
})

export const setFormStartEventEmitted = () => ({
    type: guestPaymentListConstants.SET_FORM_START_EVENT_EMITTED
})

export const clearFormStartEventEmitted = () => ({
    type: guestPaymentListConstants.CLEAR_FORM_START_EVENT_EMITTED
})

export const verifyPaymentList = (language) => (dispatch, getState) => {
    const state = getState()
    const guestPaymentBankInfo = state.guestPaymentBankInfo
    const guestPaymentList = state.guestPaymentList.paymentList

    let parameters = {
        paymentList: guestPaymentList.map(p => ({billAccountNumber: +p.srpAccountNumber, paymentAmount: p.paymentAmount})),
        bankInfo: guestPaymentBankInfo
    }

    return dispatch({
        [RSAA]: {
            endpoint: utils.createUrl('guestpayments/verifypaymentlist' + (language ? '?lang='+language : '')),
            method: 'POST',
            body: JSON.stringify(parameters),
            headers: { 'Content-Type': 'application/json' },
            types: [
                guestPaymentApiConstants.VERIFY_GUEST_PAYMENT_LIST_REQUEST,
                guestPaymentApiConstants.VERIFY_GUEST_PAYMENT_LIST_SUCCESS,
                guestPaymentApiConstants.VERIFY_GUEST_PAYMENT_LIST_FAILURE
            ]
        },
        meta: {
            debounce: {
                leading: false,
                trailing: false
            }
        }
    })
}

export const submitPaymentList = (language) => (dispatch, getState) => {
    const state = getState()

    let parameters = {
        referenceNumberFromValidatePayment: state.guestPaymentApiResponse.referenceNumber,
        paymentList: state.guestPaymentList.paymentList.map(p => ({billAccountNumber: +p.srpAccountNumber, paymentAmount: p.paymentAmount})),
        bankInfo: state.guestPaymentBankInfo
    }

    return dispatch({
        [RSAA]: {
            endpoint: utils.createUrl('guestpayments/submitpaymentlist_emailconfirmation'+ (language ? '?lang='+language : '')),
            method: 'POST',
            body: JSON.stringify(parameters),
            headers: { 'Content-Type': 'application/json' },
            types: [
                guestPaymentApiConstants.SUBMIT_GUEST_PAYMENT_LIST_REQUEST,
                guestPaymentApiConstants.SUBMIT_GUEST_PAYMENT_LIST_SUCCESS,
                guestPaymentApiConstants.SUBMIT_GUEST_PAYMENT_LIST_FAILURE
            ]
        }
    })    
}

export const submitPaymentListFailure = () => ({
    type: guestPaymentApiConstants.SUBMIT_GUEST_PAYMENT_LIST_FAILURE
})

export const clearGuestPaymentSubmitted = () => ({
    type: guestPaymentApiConstants.CLEAR_GUEST_PAYMENT_SUBMITTED
})