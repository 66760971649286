import React from "react"
import PropTypes from 'prop-types'
import { DocumentTitle } from '../../../srp_modules/document-title'
import Card from '@mui/material/Card'
import { connect } from 'react-redux'

import PurchaseHistoryDocs from './purchase-history-docs'
import ItemizedStatementDocs from './itemized-statement-docs'
import CreditHistoryDocs from './credit-history-docs'
import UsageHistoryDocs from './usage-history-docs'
import EnergyScoreCardDocs from './energy-scorecard-docs'
import DaytimeSaverPilotDocs from './daytime-saver-pilot-docs'
import CommunitySolarDocs from './community-solar-docs'

import { rateMetaDataConstants } from '../../../constants/rate-meta-data-constants'

class DocumentCenterCard extends React.Component {
    render() {
        let mPowerDocs = null
        if (this.props.isMPower === true) {
            mPowerDocs =
                (<div>
                    <ItemizedStatementDocs
                        itemizedStatementLink={this.props.itemizedStatementLink}
                        itemizedStatementLinkResultStatus={this.props.itemizedStatementLinkResultStatus}
                        requestItemizedHistoryOnClick={this.props.requestItemizedHistoryOnClick}
                        itemizedHistoryRequestResultStatus={this.props.itemizedHistoryRequestResultStatus}
                        isCommercial={this.props.isCommercial}
                        refreshFailedLinks={this.props.refreshFailedLinks}
                        primaryEmail={this.props.primaryEmail}
                        t={this.props.t}
                    />
                    <PurchaseHistoryDocs t={this.props.t}/>
                </div>)
        }

        let daytimeSaverDocs = null
        if (this.props.daytimeSaverPilotLinks.pdfLinks && this.props.daytimeSaverPilotLinks.pdfLinks.length > 0) {
            daytimeSaverDocs = (<DaytimeSaverPilotDocs
                daytimeSaverPilotLinks={this.props.daytimeSaverPilotLinks.pdfLinks}
                isE23={this.props.currentRateCode === 8}
                t={this.props.t}
            />)
        }

        return (
            <DocumentTitle title="Document Center | SRP Power">
                <div className="mt-lg-0 mt-3">
                    <div className="srp-card-header">
                        {this.props.t("Select a document to request/view")}
                    </div>
                    <Card className="col-lg-6">
                        {this.props.rateMetaData.summaryBilling === rateMetaDataConstants.SUMMARY_BILLING_PARENT
                            ? <div className="srp-card-details">{this.props.t("This account is a Summary billing account. Please view individual account for available documents.")}</div>
                            : <div style={{ paddingTop: '10px', paddingBottom: '15px' }}>
                                {daytimeSaverDocs}

                                { false &&
                                <EnergyScoreCardDocs
                                    energyScoreCardFAQLink={this.props.energyScoreCardFAQLink}
                                    energyScoreCardLinks={this.props.energyScoreCardLinks}
                                    isCommercial={this.props.isCommercial}
                                    t={this.props.t}
                                />
                                }

                                <CommunitySolarDocs
                                    communitySolarLink={this.props.communitySolarLink}
                                    isCommercial={this.props.isCommercial}
                                />

                                {mPowerDocs}

                                <CreditHistoryDocs
                                    creditHistoryLink={this.props.creditHistoryLink}
                                    creditHistoryLinkResultStatus={this.props.creditHistoryLinkResultStatus}
                                    isCommercial={this.props.isCommercial}
                                    refreshFailedLinks={this.props.refreshFailedLinks}
                                    t={this.props.t}
                                />

                                <UsageHistoryDocs
                                    usageHistoryLink={this.props.usageHistoryLink}
                                    usageHistoryLinkResultStatus={this.props.usageHistoryLinkResultStatus}
                                    isCommercial={this.props.isCommercial}
                                    refreshFailedLinks={this.props.refreshFailedLinks}
                                    t={this.props.t}
                                />
                            </div>
                        }
                    </Card>
                </div>
            </DocumentTitle>
        )
    }
}

DocumentCenterCard.propTypes = {
    isMPower: PropTypes.bool.isRequired,
    currentRateCode: PropTypes.number,
    isCommercial: PropTypes.bool.isRequired,

    usageHistoryLink: PropTypes.object,
    usageHistoryLinkResultStatus: PropTypes.string,

    creditHistoryLink: PropTypes.object,
    creditHistoryLinkResultStatus: PropTypes.string,

    itemizedStatementLink: PropTypes.object,
    itemizedStatementLinkResultStatus: PropTypes.string,
    requestItemizedHistoryOnClick: PropTypes.func,
    itemizedHistoryRequestResultStatus: PropTypes.string,

    energyScoreCardLinks: PropTypes.array,
    energyScoreCardLinksResultStatus: PropTypes.string,

    energyScoreCardFAQLink: PropTypes.object,
    energyScoreCardFAQLinkResultStatus: PropTypes.string,

    daytimeSaverPilotLinks: PropTypes.object,

    daytimeSaverPilotResultStatus: PropTypes.string,

    communitySolarLink: PropTypes.object,
    communitySolarLinkResultStatus: PropTypes.string,

    refreshFailedLinks: PropTypes.func,

    rateMetaData: PropTypes.object,
    primaryEmail: PropTypes.string,
    t: PropTypes.func
}

const mapStateToProps = state => {
    return {
        mPowerHistoryResultStatus: state.accountInfo.documentCenter.mPowerHistoryResultStatus,
        currentRateCode: state.accountInfo.billAccount.currentRateCode.rateCode,
        ...state.rateMetaData
    }
}

export default connect(mapStateToProps)(DocumentCenterCard)