import { billAccountConstants } from '../../../constants/bill-account-constants'
import { paymentConstants } from '../../../constants/payment-constants'
import { multiPaymentConstants } from '../../../constants/payment/multi-payment-constants'
import { giftPaymentMultiConstants } from '../../../constants/payment/gift-payment-multi-constants'
import { giftPaymentApiConstants } from '../../../constants/gift/gift-payment-api-constants'
import { eppConstants } from '../../../constants/epp-constants'
import { creditAdvanceConstants } from '../../../constants/credit-advance-constants'
import { eraProgramConstants } from '../../../constants/era-program-constants'
import { safetyNetConstants } from '../../../constants/safety-net-constants'
import * as loadingStatus from '../../../constants/loading-status-constants'
import { DateTime } from 'luxon'
import validate from '../../../srp_modules/validate'

const initialState = {
    payment: {
        amountDue: 0,
        autoAuthorize: false,
        balance: 0,
        dueDate: new Date(0),
        hardLimit: 9999.99,
        hasError: false,
        hasPayment: false,
        heldPaymentAmount: 0.0,
        isSurePay: false,
        pastDue: 0,
        recentlyBilledCharges: 0,
        surePayDate: new Date(0),
        supportsRemotePrepay: false,
        payDownPercent: 0,
        mPowerLastUpdatedDate: new Date(0),
        paymentArrangementFlag: "",
        arrangementCurrentDueAmount: 0,
        arrangementInstallmentAmount: 0,
        arrangementRemainingAmountDue: 0
    },
    isPaymentInfoValid: false,
    paymentStatus: loadingStatus.LOADING_STATUS_INIT,

    isEligibleStatus: loadingStatus.LOADING_STATUS_INIT,

    creditExtension: {
        isEligible: false,
        safeThruExtensionDate: new Date(0),
        maxExtensionDate: new Date(0),
        masExtensionLatePaymentFee: 0,
        maxExtensionAmount: 0,
        hasExtension: false,
        extensionDueDate: new Date(0),
        totalAmountDue: 0
    },
    creditExtensionStatus: loadingStatus.LOADING_STATUS_INIT,
    submittingCreditExtension: false,
    extensionNotGrantedReasonCodes: [],
    cixCardToShow: paymentConstants.CIX_CARD, // 'CreditExtensionCard'
    showBillCard: true,
    shouldSendCIXConfirmationEmail: true,
    showCIXbutton: true,

    lastPayment: {},

    enrolledProgramsDetails: {
        budgetBilling: {
            name: "Budget Billing",
            isEnrolled: false,
            isAvailable: false,
            enrolledDate: new Date(0),
            optionValue: 0
        },
        autopay: {
            name: "Autopay",
            isEnrolled: false,
            isAvailable: false,
            enrolledDate: new Date(0),
            optionValue: 0
        },
        customDueDate: {
            name: "Custom Due Date",
            isEnrolled: false,
            isAvailable: false,
            enrolledDate: new Date(0),
            optionValue: 0
        },
        solarChoice: {
            name: "SRP Solar Choice",
            isEnrolled: false,
            isAvailable: false,
            enrolledDate: new Date(0),
            optionValue: 0
        },
        cashOnly: {
            name: "Cash Only",
            isEnrolled: false,
            isAvailable: false,
            enrolledDate: new Date(0),
            optionValue: 0
        },
        share: {
            name: "Share",
            isEnrolled: false,
            isAvailable: false,
            enrolledDate: new Date(0),
            optionValue: 0
        },
        economicPricePlan: {
            name: "Economic Price Plan",
            isEnrolled: false,
            isAvailable: false,
            enrolledDate: new Date(0),
            optionValue: 0,
            discountValue: 0,
            statusId: eppConstants.AUDIT_STATUS.NONE,
            statusDate: new Date(0),
            removedWithinOneYear: false,
            isInActiveAudit: false,
            hasMissingInformation: false,
            missingInformationDescription: "",
            eppIncomeThresholds: []
        },
        healthyForest: {
            name: "SRP Healthy Forest Initiative",
            isEnrolled: false,
            isAvailable: false,
            enrolledDate: new Date(0),
            optionValue: 0
        },
        solarNonprofit: {
            name: "SRP Solar for Nonprofit",
            isEnrolled: false,
            isAvailable: false,
            enrolledDate: new Date(0),
            optionValue: 0
        },
        evCommunityCredits: {
            name: "EV Community Credits",
            isEnrolled: false,
            isAvailable: false,
            enrolledDate: new Date(0),
            optionValue: 0
        },
        eraProgram: {
            name: "AZ DES ERA Program",
            isEligible: false,
            status: eraProgramConstants.STATUS.NOT_ELIGIBLE,
            statusDate: new Date(0),
            isRecertify: false,
            azDesPaymentList: {},
            formSubmissionNum: 0
        },
        safetyNet: {
            name: "AZ DES ERA Program",
            isEnrolled: false,
            isAvailable: false,
            safetyNetsName: "",
            streetAddress: "",
            city: "",
            state: "",
            zipCode: ""
        }
},
    enrolledProgramsDetailsStatus: loadingStatus.LOADING_STATUS_INIT,

    paymentHistory: [],
    paymentHistoryStatus: loadingStatus.LOADING_STATUS_INIT,

    scheduledPayments: [],
    scheduledPaymentsStatus: loadingStatus.LOADING_STATUS_INIT,

    pendingPayments: [],
    pendingPaymentsStatus: loadingStatus.LOADING_STATUS_INIT,

    isFetchingCreditCardPaymentUrl: false,
    creditCardPaymentUrl: '#',
    creditCardPaymentUrlStatus: loadingStatus.LOADING_STATUS_INIT,


    // Make payment information
    makePaymentInfo: {
        accountNumber: '',
        accountNumberError: false,
        amount: '',
        amountError: false,
        shareAmount: '0',
        paymentDate: DateTime.fromISO(new Date()).toFormat('yyyy-MM-dd'),
        paymentDateError: false,
        bankAccountId: 0,
        paymentAmountWarningText: "",
        paymentDateWarningText: ""
    },

    makePaymentResultStatus: loadingStatus.LOADING_STATUS_INIT,
    makePaymentResult: {
        isSuccessful: false,
        emailAddress: "",
        emailConfirmationSentSuccessfully: true,
        paymentReferenceNumber: 0
    },

    verifyPaymentResultStatus: loadingStatus.LOADING_STATUS_INIT,
    verifyPaymentResult: {
        isSuccessful: true,
        referenceNumber: 0,
        errorInvalidBillAccountNumber: false,
        errorMaxPaymentAmountExceeded: false,
        errorOther: false,
        maxAmountAllowedIfMaxPaymentAmountExceededError: 0
    },

    scheduledPaymentsByAcct: [],
    scheduledPaymentsByAcctStatus: loadingStatus.LOADING_STATUS_INIT,

    pendingPaymentsByAcct: [],
    pendingPaymentsByAcctStatus: loadingStatus.LOADING_STATUS_INIT,

    postedPaymentsByAcct: [],
    postedPaymentsByAcctStatus: loadingStatus.LOADING_STATUS_INIT,

    paymentsByAcctStatus: loadingStatus.LOADING_STATUS_INIT,

    isScheduledPaymentDeleted: false,
    deletePaymentStatus: loadingStatus.LOADING_STATUS_INIT,

    hasPayments: false,
    hasScheduledPayment: false,
    isCashOnly: false,
    isBudgetBilling: false,
    isCustomDueDate: false,

    customDueDateInfo: {
        success: false,
        isEligible: false,
        customDueDate: 1,
        lastSetDateTime: '00:00',
        lastSetDateDate: '01/01/0001'
    },
    setCustomDueDateStatus: loadingStatus.LOADING_STATUS_INIT,

    isCustomDueDateEligible: false,
    isCustomDueDateEligibleStatus: loadingStatus.LOADING_STATUS_INIT,
    CustomDueDate: new Date(0),

    authorizeTokenlessReconnectStatus: loadingStatus.LOADING_STATUS_INIT,
    authorizeReconnectSucceeded: false,

    mPowerSelfServiceAdvance: {
        isAdvanceEligible: false,
        advanceEligibility: 'NotEligibleOtherReason',
        advanceAmount: 0,
        advanceTaken: 2,
        advanceRemain: 0,
        advanceAllowed: 2,
        daysApplied: 3,
        advanceDataToken: "",
    },
    mPowerSelfServiceAdvanceStatus: loadingStatus.LOADING_STATUS_INIT,

    submitMpowerSelfServiceResponse: {
        success: false,
        submitAdvanceStatus: 'NotEligibleOtherReason',
    },
    submitMpowerSelfServiceResponseStatus: loadingStatus.LOADING_STATUS_INIT
}

const getPaymentDateError = (paymentDate) => {
    return !(DateTime.fromISO(paymentDate).isValid() && DateTime.fromISO(paymentDate) >= DateTime.now().startOf('day'))
}

const getHasPayments = (paymentsByAcctResponse) => {
    return (paymentsByAcctResponse.scheduledPayments && paymentsByAcctResponse.scheduledPayments.length > 0) ||
        (paymentsByAcctResponse.pendingPayments && paymentsByAcctResponse.pendingPayments.length > 0) ||
        (paymentsByAcctResponse.postedPayments && paymentsByAcctResponse.postedPayments.filter(last24Hours).length > 0)
}

const last24Hours = (payment) => {
    return DateTime.fromISO(payment.paymentDate).startOf('day') >= DateTime.now().minus({ days: 1 }).startOf('day')
}

export default function paymentReducer(state = initialState, action) {
    switch (action.type) {
        case paymentConstants.GET_PAYMENT_REQUEST:
            return { ...state, paymentStatus: loadingStatus.LOADING_STATUS_IN_PROGRESS }
        case paymentConstants.GET_PAYMENT_SUCCESS:
            return { ...state,
                payment: action.payload,
                isPaymentInfoValid: true,
                paymentStatus: loadingStatus.LOADING_STATUS_SUCCESS
            }
        case paymentConstants.GET_PAYMENT_FAILURE:
            return { ...state, payment: initialState.payment, isPaymentInfoValid: initialState.isPaymentInfoValid, paymentStatus: loadingStatus.LOADING_STATUS_FAILURE }

        case paymentConstants.GET_LAST_PAYMENT_SUCCESS:
            return { ...state, lastPayment: action.lastPayment }

        case paymentConstants.SET_CUSTOM_DUE_DATE_REQUEST:
            return { ...state, setCustomDueDateStatus: loadingStatus.LOADING_STATUS_IN_PROGRESS }
        case paymentConstants.SET_CUSTOM_DUE_DATE_SUCCESS:
            return { ...state, customDueDateInfo: action.payload, setCustomDueDateStatus: loadingStatus.LOADING_STATUS_SUCCESS }
        case paymentConstants.SET_CUSTOM_DUE_DATE_FAILURE:
            return { ...state, customDueDateInfo: initialState.customDueDateInfo, setCustomDueDateStatus: loadingStatus.LOADING_STATUS_FAILURE }

        case paymentConstants.GET_CUSTOM_DUE_DATE_ELIGIBLE_REQUEST:
            return { ...state, isCustomDueDateEligibleStatus: loadingStatus.LOADING_STATUS_IN_PROGRESS }
        case paymentConstants.GET_CUSTOM_DUE_DATE_ELIGIBLE_SUCCESS:
            return { ...state, customDueDateInfo: action.payload, isCustomDueDateEligibleStatus: loadingStatus.LOADING_STATUS_SUCCESS }
        case paymentConstants.GET_CUSTOM_DUE_DATE_ELIGIBLE_FAILURE:
            return { ...state, customDueDateInfo: initialState.customDueDateInfo, isCustomDueDateEligibleStatus: loadingStatus.LOADING_STATUS_FAILURE }

        case paymentConstants.GET_ENROLLED_PROGRAMS_DETAILS_REQUEST:
            return { ...state, enrolledProgramsDetailsStatus: loadingStatus.LOADING_STATUS_IN_PROGRESS }
        case paymentConstants.GET_ENROLLED_PROGRAMS_DETAILS_SUCCESS:
            return {
                ...state,
                enrolledProgramsDetails: action.payload,
                isCashOnly: action.payload.cashOnly.isEnrolled,
                isBudgetBilling: action.payload.budgetBilling.isEnrolled,
                isCustomDueDate: action.payload.customDueDate.isEnrolled,
                enrolledProgramsDetailsStatus: loadingStatus.LOADING_STATUS_SUCCESS
            }
        case paymentConstants.GET_ENROLLED_PROGRAMS_DETAILS_FAILURE:
            return { ...state, enrolledProgramsDetails: initialState.enrolledProgramsDetails, enrolledProgramsDetailsStatus: loadingStatus.LOADING_STATUS_FAILURE }

        case paymentConstants.GET_PAYMENT_HISTORY_REQUEST:
            return { ...state, paymentHistoryStatus: loadingStatus.LOADING_STATUS_IN_PROGRESS }
        case paymentConstants.GET_PAYMENT_HISTORY_SUCCESS:
            return { ...state, paymentHistory: action.payload, paymentHistoryStatus: loadingStatus.LOADING_STATUS_SUCCESS }
        case paymentConstants.GET_PAYMENT_HISTORY_FAILURE:
            return { ...state, paymentHistory: initialState.paymentHistory, paymentHistoryStatus: loadingStatus.LOADING_STATUS_FAILURE }

        case paymentConstants.GET_SCHEDULED_PAYMENTS_REQUEST:
            return { ...state, scheduledPaymentsStatus: loadingStatus.LOADING_STATUS_IN_PROGRESS }
        case paymentConstants.GET_SCHEDULED_PAYMENTS_SUCCESS:
            return { ...state, scheduledPayments: action.payload, scheduledPaymentsStatus: loadingStatus.LOADING_STATUS_SUCCESS }
        case paymentConstants.GET_SCHEDULED_PAYMENTS_FAILURE:
            return { ...state, scheduledPayments: initialState.scheduledPayments, scheduledPaymentsStatus: loadingStatus.LOADING_STATUS_FAILURE }

        case paymentConstants.GET_PENDING_PAYMENTS_REQUEST:
            return { ...state, pendingPaymentsStatus: loadingStatus.LOADING_STATUS_IN_PROGRESS }
        case paymentConstants.GET_PENDING_PAYMENTS_SUCCESS:
            return { ...state, pendingPayments: action.payload, pendingPaymentsStatus: loadingStatus.LOADING_STATUS_SUCCESS }
        case paymentConstants.GET_PENDING_PAYMENTS_FAILURE:
            return { ...state, pendingPayments: initialState.pendingPayments, pendingPaymentsStatus: loadingStatus.LOADING_STATUS_FAILURE }

        case paymentConstants.GET_CREDIT_CARD_PAYMENT_URL_REQUEST:
            return { ...state, isFetchingCreditCardPaymentUrl: true, creditCardPaymentUrlStatus: loadingStatus.LOADING_STATUS_IN_PROGRESS }
        case paymentConstants.GET_CREDIT_CARD_PAYMENT_URL_SUCCESS:
            return {
                ...state,
                isFetchingCreditCardPaymentUrl: false,
                creditCardPaymentUrl: action.payload,
                creditCardPaymentUrlStatus: loadingStatus.LOADING_STATUS_SUCCESS
            }
        case paymentConstants.GET_CREDIT_CARD_PAYMENT_URL_FAILURE:
            return {
                ...state,
                isFetchingCreditCardPaymentUrl: false,
                creditCardPaymentUrl: '#',
                creditCardPaymentUrlStatus: loadingStatus.LOADING_STATUS_FAILURE
            }
        case paymentConstants.SELECT_BILL_ACCOUNT_ON_CHANGE:
            return { ...state, creditCardPaymentUrl: '#', creditCardPaymentUrlStatus: initialState.creditCardPaymentUrlStatus }

        case paymentConstants.START_CIX_FLOW:
            return { ...state, cixCardToShow: paymentConstants.CIX_CARD, showBillCard: false }
        case paymentConstants.END_CIX_FLOW:
            return { ...state, cixCardToShow: paymentConstants.CIX_CARD, showBillCard: true }
        case paymentConstants.SHOW_CIX_CARD_ON_CHANGE:
            return { ...state, cixCardToShow: action.cardToShow }
        case paymentConstants.SEND_CIX_CONFIRMATION_EMAIL:
            return { ...state, shouldSendCIXConfirmationEmail: action.checked }
        case paymentConstants.GET_CREDIT_EXTENSION_REQUEST:
            return { ...state, creditExtensionStatus: loadingStatus.LOADING_STATUS_IN_PROGRESS }
        case paymentConstants.GET_CREDIT_EXTENSION_SUCCESS:
            return { ...state, creditExtension: action.payload, creditExtensionStatus: loadingStatus.LOADING_STATUS_SUCCESS }
        case paymentConstants.GET_CREDIT_EXTENSION_FAILURE:
            return { ...state, creditExtension: initialState.creditExtension, creditExtensionStatus: loadingStatus.LOADING_STATUS_FAILURE }
        case paymentConstants.SUBMIT_CREDIT_EXTENSION_REQUEST:
            return { ...state, submittingCreditExtension: true }
        case paymentConstants.SUBMIT_CREDIT_EXTENSION_SUCCESS:
            return (action.payload.extensionNotGrantedReasonCodes.length != 0)
                ? {
                    ...state,
                    extensionNotGrantedReasonCodes: action.payload.extensionNotGrantedReasonCodes,
                    submittingCreditExtension: false,
                    cixCardToShow: paymentConstants.OUT_OF_EXTENSIONS_CARD
                }
                : {
                    ...state,
                    submittingCreditExtension: false,
                    cixCardToShow: paymentConstants.CIX_CONFIRMATION_CARD,
                    showCIXbutton: false,
                    creditExtension: {
                        ...state.creditExtension,
                        hasExtension: true,
                        extensionDueDate: state.creditExtension.maxExtensionDate
                    }
                }
        case paymentConstants.SUBMIT_CREDIT_EXTENSION_FAILURE:
            return { ...state, submittingCreditExtension: false, cixCardToShow: paymentConstants.CIX_ERROR_ON_SUBMIT_CARD }

        case paymentConstants.GET_PAYMENT_ACCOUNT_NUMBER:
            return { ...state, makePaymentInfo: { ...state.makePaymentInfo, accountNumber: action.accountNumber, accountNumberError: !validate.accountNumber(action.accountNumber) } }
        case paymentConstants.GET_PAYMENT_DATE:
            return { ...state, makePaymentInfo: { ...state.makePaymentInfo, paymentDate: action.paymentDate, paymentDateError: getPaymentDateError(action.paymentDate) } }
        case paymentConstants.GET_PAYMENT_AMOUNT:
            return { ...state, makePaymentInfo: { ...state.makePaymentInfo, amount: action.amount, amountError: !validate.paymentAmount(action.amount) } }


        case paymentConstants.VERIFY_PAYMENT_VALIDATION_FAILURE:
            return {
                ...state, makePaymentInfo: {
                    ...state.makePaymentInfo,
                    accountNumberError: !validate.accountNumber(state.makePaymentInfo.accountNumber),
                    amountError: !validate.paymentAmount(state.makePaymentInfo.amount),
                    paymentDateError: getPaymentDateError(state.makePaymentInfo.paymentDate),
                }
            }

        case paymentConstants.POPULATE_PAYMENT: {
            return {
                ...state, makePaymentInfo: {
                    ...state.makePaymentInfo,
                    accountNumber: action.paymentInfo.billAccount,
                    amount: action.paymentInfo.paymentAmount,
                    paymentDate: action.paymentInfo.paymentDate,
                    bankAccountId: action.paymentInfo.bankAccountId,
                    paymentAmountWarningText: action.paymentInfo.paymentAmountWarningText,
                    paymentDateWarningText: action.paymentInfo.paymentDateWarningText
                }
            }
        }

        // verify single payment
        case paymentConstants.VERIFY_SINGLE_PAYMENT_REQUEST:
            return { ...state, verifyPaymentResultStatus: loadingStatus.LOADING_STATUS_IN_PROGRESS }
        case paymentConstants.VERIFY_SINGLE_PAYMENT_SUCCESS: {
            const referenceNumber = action.payload.referenceNumberWhenNoError
            const eChexValidationErrors = action.payload.eChexValidationErrors

            const isSuccessful = (referenceNumber !== 0 && (Object.keys(eChexValidationErrors).length === 0 && eChexValidationErrors.constructor === Object))

            const errorInvalidBillAccountNumber = Object.prototype.hasOwnProperty.call(eChexValidationErrors, 'errorIneligibleBillAccount')
            const errorMaxPaymentAmountExceeded = Object.prototype.hasOwnProperty.call(eChexValidationErrors, 'errExceedHardLimit')
            const maxAmountAllowedIfMaxPaymentAmountExceededError = errorMaxPaymentAmountExceeded ? eChexValidationErrors.errExceedHardLimit.maxPaymentAmountAllowed : 0

            let errorOther = false
            for (let error in eChexValidationErrors)
                if (Object.prototype.hasOwnProperty.call(eChexValidationErrors, error))
                    if (error !== 'errorIneligibleBillAccount' &&
                        error !== 'errExceedHardLimit' &&
                        error.startsWith('err')) {
                        errorOther = true
                        break
                    }


            const verifyPaymentResult = {
                isSuccessful,
                referenceNumber,
                errorInvalidBillAccountNumber,
                errorMaxPaymentAmountExceeded,
                errorOther,
                maxAmountAllowedIfMaxPaymentAmountExceededError
            }

            return {
                ...state,
                verifyPaymentResult: verifyPaymentResult,
                verifyPaymentResultStatus: loadingStatus.LOADING_STATUS_SUCCESS,
                makePaymentInfo: {
                    ...state.makePaymentInfo,
                    accountNumberError: errorInvalidBillAccountNumber,
                    amountError: errorMaxPaymentAmountExceeded
                }
            }
        }
        case paymentConstants.VERIFY_SINGLE_PAYMENT_FAILURE:
            return { ...state, verifyPaymentResult: initialState.verifyPaymentResult, verifyPaymentResultStatus: loadingStatus.LOADING_STATUS_FAILURE }

        // verify payment
        case paymentConstants.VERIFY_PAYMENT_REQUEST:
            return { ...state, verifyPaymentResultStatus: loadingStatus.LOADING_STATUS_IN_PROGRESS }
        case paymentConstants.VERIFY_PAYMENT_SUCCESS:
            return {
                ...state,
                verifyPaymentResult: action.payload, verifyPaymentResultStatus: loadingStatus.LOADING_STATUS_SUCCESS,
                makePaymentInfo: {
                    ...state.makePaymentInfo,
                    accountNumberError: action.payload.errorInvalidBillAccountNumber,
                    amountError: action.payload.errorMaxPaymentAmountExceeded
                }
            }
        case paymentConstants.VERIFY_PAYMENT_FAILURE:
            return { ...state, verifyPaymentResult: initialState.verifyPaymentResult, verifyPaymentResultStatus: loadingStatus.LOADING_STATUS_FAILURE }

        // submit payment - single
        case paymentConstants.SUBMIT_PAYMENT_REQUEST:
            return { ...state, makePaymentResultStatus: loadingStatus.LOADING_STATUS_IN_PROGRESS }
        case paymentConstants.SUBMIT_PAYMENT_SUCCESS:
            return {
                ...state,
                makePaymentResult: action.payload,
                makePaymentResultStatus: loadingStatus.LOADING_STATUS_SUCCESS,
                paymentStatus: loadingStatus.LOADING_STATUS_INIT,
                scheduledPaymentsByAcctStatus: loadingStatus.LOADING_STATUS_INIT,
                pendingPaymentsStatus: loadingStatus.LOADING_STATUS_INIT,
                scheduledPaymentsStatus: loadingStatus.LOADING_STATUS_INIT
            }
        case paymentConstants.SUBMIT_PAYMENT_FAILURE:
            return { ...state, makePaymentResult: initialState.makePaymentResult, makePaymentResultStatus: loadingStatus.LOADING_STATUS_FAILURE }

        case paymentConstants.CLEAR_PAYMENT_INFO:
            return {
                ...state,
                makePaymentInfo: initialState.makePaymentInfo,
                verifyPaymentResult: initialState.verifyPaymentResult,
                verifyPaymentResultStatus: initialState.verifyPaymentResultStatus,
                makePaymentResult: initialState.makePaymentResult,
                makePaymentResultStatus: initialState.makePaymentResultStatus
            }

        case paymentConstants.CLEAR_MAKE_PAYMENT_RESULT:
            return { ...state, makePaymentResult: initialState.makePaymentResult, makePaymentResultStatus: initialState.makePaymentResultStatus }

        case billAccountConstants.SELECT_BILL_ACCOUNT_SUCCESS:
            return {
                ...state,
                creditExtensionStatus: loadingStatus.LOADING_STATUS_INIT,
                enrolledProgramsDetailsStatus: loadingStatus.LOADING_STATUS_INIT,
                isEligibleStatus: loadingStatus.LOADING_STATUS_INIT,
                isPaymentInfoValid: initialState.isPaymentInfoValid,
                paymentHistoryStatus: loadingStatus.LOADING_STATUS_INIT,
                paymentsByAcctStatus: loadingStatus.LOADING_STATUS_INIT,
                paymentStatus: loadingStatus.LOADING_STATUS_INIT,
                pendingPaymentsByAcctStatus: loadingStatus.LOADING_STATUS_INIT,
                postedPaymentsByAcctStatus: loadingStatus.LOADING_STATUS_INIT,
                scheduledPaymentsByAcctStatus: loadingStatus.LOADING_STATUS_INIT,
                scheduledPaymentsStatus: loadingStatus.LOADING_STATUS_INIT,
                hasPayments: initialState.hasPayments,
                hasScheduledPayment: initialState.hasScheduledPayment,
                isCashOnly: initialState.isCashOnly,
                isScheduledPaymentDeleted: initialState.isScheduledPaymentDeleted,
                deletePaymentStatus: initialState.deletePaymentStatus,
                isCustomDueDateEligibleStatus: initialState.isCustomDueDateEligibleStatus,
                setCustomDueDateStatus: initialState.setCustomDueDateStatus,
                authorizeTokenlessReconnectStatus: initialState.authorizeTokenlessReconnectStatus,
                authorizeReconnectSucceeded: false,
                showBillCard: initialState.showBillCard,
                cixCardToShow: initialState.cixCardToShow,
                mPowerSelfServiceAdvance: initialState.mPowerSelfServiceAdvance,
                mPowerSelfServiceAdvanceStatus: initialState.mPowerSelfServiceAdvanceStatus,
                submitMpowerSelfServiceResponse: initialState.submitMpowerSelfServiceResponse,
                submitMpowerSelfServiceResponseStatus: initialState.submitMpowerSelfServiceResponseStatus,
                enrolledProgramsDetails: initialState.enrolledProgramsDetails
            }

        case paymentConstants.MAKE_PAYMENT_INFO_CHANGE:
            return {
                ...state,
                makePaymentInfo: action.makePaymentInfo
            }

        case paymentConstants.GET_SCHEDULED_PAYMENTS_BY_ACCT_REQUEST:
            return { ...state, scheduledPaymentsByAcctStatus: loadingStatus.LOADING_STATUS_IN_PROGRESS }

        case paymentConstants.GET_SCHEDULED_PAYMENTS_BY_ACCT_SUCCESS:
            return {
                ...state,
                scheduledPaymentsByAcct: action.payload,
                scheduledPaymentsByAcctStatus: loadingStatus.LOADING_STATUS_SUCCESS
            }

        case paymentConstants.GET_SCHEDULED_PAYMENTS_BY_ACCT_FAILURE:
            return {
                ...state,
                scheduledPaymentsByAcct: initialState.scheduledPaymentsByAcct,
                scheduledPaymentsByAcctStatus: loadingStatus.LOADING_STATUS_FAILURE
            }

        case paymentConstants.GET_PENDING_PAYMENTS_BY_ACCT_REQUEST:
            return { ...state, pendingPaymentsByAcctStatus: loadingStatus.LOADING_STATUS_IN_PROGRESS }

        case paymentConstants.GET_PENDING_PAYMENTS_BY_ACCT_SUCCESS:
            return {
                ...state,
                pendingPaymentsByAcct: action.payload,
                pendingPaymentsByAcctStatus: loadingStatus.LOADING_STATUS_SUCCESS
            }

        case paymentConstants.GET_PENDING_PAYMENTS_BY_ACCT_FAILURE:
            return {
                ...state,
                pendingPaymentsByAcct: initialState.pendingPaymentsByAcct,
                pendingPaymentsByAcctStatus: loadingStatus.LOADING_STATUS_FAILURE
            }

        case paymentConstants.GET_POSTED_PAYMENTS_BY_ACCT_REQUEST:
            return { ...state, postedPaymentsByAcctStatus: loadingStatus.LOADING_STATUS_IN_PROGRESS }

        case paymentConstants.GET_POSTED_PAYMENTS_BY_ACCT_SUCCESS:
            return {
                ...state,
                postedPaymentsByAcct: action.payload,
                postedPaymentsByAcctStatus: loadingStatus.LOADING_STATUS_SUCCESS
            }

        case paymentConstants.GET_POSTED_PAYMENTS_BY_ACCT_FAILURE:
            return {
                ...state,
                postedPaymentsByAcct: initialState.postedPaymentsByAcct,
                postedPaymentsByAcctStatus: loadingStatus.LOADING_STATUS_FAILURE
            }

        case paymentConstants.DELETE_PAYMENT_REQUEST:
            return { ...state, deletePaymentStatus: loadingStatus.LOADING_STATUS_IN_PROGRESS }

        case paymentConstants.DELETE_PAYMENT_SUCCESS:
            return action.payload.isSuccess ? {
                ...state,
                deletePaymentStatus: loadingStatus.LOADING_STATUS_SUCCESS,
                isPaymentInfoValid: initialState.isPaymentInfoValid,
                isScheduledPaymentDeleted: true,
                payment: initialState.payment,
                paymentsByAcctStatus: initialState.paymentsByAcctStatus,
                paymentStatus: initialState.paymentStatus,
                pendingPaymentsByAcctStatus: initialState.pendingPaymentsByAcctStatus,
                postedPaymentsByAcctStatus: initialState.postedPaymentsByAcctStatus,
                scheduledPaymentsByAcctStatus: initialState.scheduledPaymentsByAcctStatus,
                scheduledPaymentsStatus: initialState.scheduledPaymentsStatus
            } : {
                ...state,
                deletePaymentStatus: loadingStatus.LOADING_STATUS_SUCCESS
            }

        case paymentConstants.DELETE_PAYMENT_FAILURE:
            return {
                ...state,
                isScheduledPaymentDeleted: initialState.isScheduledPaymentDeleted,
                deletePaymentStatus: loadingStatus.LOADING_STATUS_FAILURE
            }

        case paymentConstants.GET_PAYMENTS_BY_ACCT_REQUEST:
            return {
                ...state,
                paymentsByAcctStatus: loadingStatus.LOADING_STATUS_IN_PROGRESS
            }

        case paymentConstants.GET_PAYMENTS_BY_ACCT_SUCCESS:
            return {
                ...state,
                pendingPaymentsByAcct: action.payload.pendingPayments,
                postedPaymentsByAcct: action.payload.postedPayments,
                scheduledPaymentsByAcct: action.payload.scheduledPayments,
                paymentsByAcctStatus: loadingStatus.LOADING_STATUS_SUCCESS,
                hasPayments: getHasPayments(action.payload),
                hasScheduledPayment: action.payload.scheduledPayments && action.payload.scheduledPayments.length > 0
            }

        case paymentConstants.GET_PAYMENTS_BY_ACCT_FAILURE:
            return {
                ...state,
                pendingPaymentsByAcct: initialState.pendingPaymentsByAcct,
                postedPaymentsByAcct: initialState.postedPaymentsByAcct,
                scheduledPaymentsByAcct: initialState.scheduledPaymentsByAcct,
                paymentsByAcctStatus: loadingStatus.LOADING_STATUS_FAILURE,
                hasPayments: initialState.hasPayments,
                hasScheduledPayment: initialState.hasScheduledPayment
            }

        case paymentConstants.AUTHORIZE_TOKENLESS_RECONNECT_REQUEST:
            return { ...state, authorizeTokenlessReconnectStatus: loadingStatus.LOADING_STATUS_IN_PROGRESS }
        case paymentConstants.AUTHORIZE_TOKENLESS_RECONNECT_SUCCESS:
            return { ...state, authorizeTokenlessReconnectStatus: loadingStatus.LOADING_STATUS_SUCCESS, authorizeReconnectSucceeded: action.payload }
        case paymentConstants.AUTHORIZE_TOKENLESS_RECONNECT_FAILURE:
            return { ...state, authorizeTokenlessReconnectStatus: loadingStatus.LOADING_STATUS_FAILURE, authorizeReconnectSucceeded: false }

        case multiPaymentConstants.SUBMIT_MULTIPLE_SUCCESS:
            return {
                ...state,
                paymentStatus: loadingStatus.LOADING_STATUS_INIT,
                scheduledPaymentsByAcctStatus: loadingStatus.LOADING_STATUS_INIT,
                pendingPaymentsStatus: loadingStatus.LOADING_STATUS_INIT,
                scheduledPaymentsStatus: loadingStatus.LOADING_STATUS_INIT
            }
        case giftPaymentMultiConstants.SUBMIT_GIFT_MULTIPLE_SUCCESS:
            return { ...state, paymentStatus: loadingStatus.LOADING_STATUS_INIT, scheduledPaymentsByAcctStatus: loadingStatus.LOADING_STATUS_INIT }
        case giftPaymentApiConstants.SUBMIT_GIFT_PAYMENT_LIST_SUCCESS:
            return { ...state, paymentStatus: loadingStatus.LOADING_STATUS_INIT, scheduledPaymentsByAcctStatus: loadingStatus.LOADING_STATUS_INIT }

        case creditAdvanceConstants.GET_MPOWER_SELF_SERVICE_REQUEST:
            return { ...state, mPowerSelfServiceAdvanceStatus: loadingStatus.LOADING_STATUS_IN_PROGRESS }
        case creditAdvanceConstants.GET_MPOWER_SELF_SERVICE_SUCCESS:
            return { ...state, mPowerSelfServiceAdvance: action.payload, mPowerSelfServiceAdvanceStatus: loadingStatus.LOADING_STATUS_SUCCESS }
        case creditAdvanceConstants.GET_MPOWER_SELF_SERVICE_FAILURE:
            return { ...state, mPowerSelfServiceAdvance: initialState.mPowerSelfServiceAdvance, mPowerSelfServiceAdvanceStatus: loadingStatus.LOADING_STATUS_FAILURE }

        case creditAdvanceConstants.SUBMIT_MPOWER_SELF_SERVICE_REQUEST:
            return { ...state, submitMpowerSelfServiceResponseStatus: loadingStatus.LOADING_STATUS_IN_PROGRESS }
        case creditAdvanceConstants.SUBMIT_MPOWER_SELF_SERVICE_SUCCESS:
            return { ...state, submitMpowerSelfServiceResponse: action.payload, submitMpowerSelfServiceResponseStatus: loadingStatus.LOADING_STATUS_SUCCESS }
        case creditAdvanceConstants.SUBMIT_MPOWER_SELF_SERVICE_FAILURE:
            return { ...state, submitMpowerSelfServiceResponse: initialState.submitMpowerSelfServiceResponse, submitMpowerSelfServiceResponseStatus: loadingStatus.LOADING_STATUS_FAILURE }

        case creditAdvanceConstants.RESET_MPOWER_SELF_SERVICE:
            return { ...state,
                payment: initialState.payment,
                paymentStatus: initialState.paymentStatus,
                mPowerSelfServiceAdvance: initialState.mPowerSelfServiceAdvance,
                mPowerSelfServiceAdvanceStatus: initialState.mPowerSelfServiceAdvanceStatus,
                submitMpowerSelfServiceResponse: initialState.submitMpowerSelfServiceResponse,
                submitMpowerSelfServiceResponseStatus: initialState.submitMpowerSelfServiceResponseStatus
            }
        case safetyNetConstants.DELETE_SAFETYNET_SUCCESS:
            return { ...state,
                enrolledProgramsDetails: {
                    ...state.enrolledProgramsDetails,
                    safetyNet: {
                        name: "AZ DES ERA Program",
                        isEnrolled: false,
                        isAvailable: true,
                        safetyNetsName: "",
                        streetAddress: "",
                        city: "",
                        state: "",
                        zipCode: ""
                    }
                }
            }
        case safetyNetConstants.UNDO_SAFETYNET_FORM_SUCCESS:
            return { ...state,
                enrolledProgramsDetails: {
                    ...state.enrolledProgramsDetails,
                    safetyNet: {
                        name: "AZ DES ERA Program",
                        isEnrolled: true,
                        isAvailable: true,
                        safetyNetsName: action.payload.safetyNetAddress.mailNameLineTwo,
                        streetAddress: action.payload.safetyNetAddress.streetAddress,
                        city: action.payload.safetyNetAddress.city,
                        state: action.payload.safetyNetAddress.state,
                        zipCode: action.payload.safetyNetAddress.zipCode
                    }
                }
            }

        default:
            return state
    }
}
