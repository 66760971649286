/* eslint react/no-multi-comp: "off" */

import React from "react"
import PropTypes from 'prop-types'
import { Formik } from 'formik'
import styled from '@emotion/styled'
import * as Yup from 'yup'
import validateFormik from '../../../../srp_modules/validate-formik'
import TextField from '@mui/material/TextField'
import { PatternFormat } from 'react-number-format'
import IconButton from '@mui/material/IconButton'
import CircularProgress from '@mui/material/CircularProgress'
import Icon from '@mui/material/Icon'
import { myAccountConstants } from '../../../../constants/myaccount-constants'

let StyledPrimaryTextField = styled(TextField)`
    & .MuiInputLabel-root {
        color: rgb(0,75,135);
        font-weight: 700;
    }
    & .MuiInputLabel-root.Mui-disabled {
        color: rgb(0,75,135);
    }
    & .MuiOutlinedInput-notchedOutline {
        font-size: 1.2em; /* this is needed to increase the label notch size to account for bold font */
    }
`

let contactLabelStyle = {
    fontSize: "18px",
    color: "#6787B7"
}

let copyPhone = (originalPhone, newValues) => {
    let newPhone = JSON.parse(JSON.stringify(originalPhone))

    newPhone.contactAddress = newValues.contactAddress
    newPhone.contactLabel =newValues.contactLabel
    newPhone.displaySequenceNumber = newValues.makePrimary ? 1 : originalPhone.displaySequenceNumber

    return newPhone
}

export const getPhoneNumberErrorInfo = (touched, errors) => {
    const isError = touched["contactAddress"]
        && Object.prototype.hasOwnProperty.call(errors, "contactAddress")
    const errMsg = isError ? errors["contactAddress"] : ""
    return { isError, errMsg }
}

export const getPhoneLabelErrorInfo = (touched, errors) => {
    const isNickNameError = touched["contactLabel"]
        && Object.prototype.hasOwnProperty.call(errors, "contactLabel")
    const nickNameErrMsg = isNickNameError ? errors["contactLabel"] : ""
    return { isNickNameError, nickNameErrMsg }
}

class PhoneItem extends React.Component {
    constructor(props) {
        super(props)

        this.validationSchema = Yup.object().shape({
            contactAddress: validateFormik.phoneNumberRequired,
            contactLabel: validateFormik.nickNameMaxCharacter
        })

        this.editItem = this.editItem.bind(this)
        this.closeEdit = this.closeEdit.bind(this)
    }

    editItem() {
        this.props.setOpenItem(this.props.phone.contactAddress)
    }

    closeEdit() {
        this.props.setOpenItem('')
    }

    render() {
        let isEditing = this.props.phone.contactAddress === this.props.openItem

        return (
            <div>
                <Formik
                    initialValues={{
                        contactAddress: this.props.phone.contactAddress,
                        makePrimary: this.props.phone.displaySequenceNumber === 1,
                        contactLabel: this.props.phone.contactLabel
                    }}
                    validationSchema={this.validationSchema}
                    onSubmit={async (values, others) => {
                        let result = myAccountConstants.CUSTOMER_ACCOUNT_DATA_STATUS.UNKNOWN
                        let newPhone = copyPhone(this.props.phone, values)
                        if ((values.makePrimary && this.props.phone.displaySequenceNumber !== 1) && ((newPhone.contactAddress !== this.props.phone.contactAddress) || (newPhone.contactLabel !== this.props.phone.contactLabel))) {
                            result = await this.props.updateContactAndMakePrimaryClick(this.props.phone, newPhone)
                        } else if (values.makePrimary && this.props.phone.displaySequenceNumber !== 1) {
                            result = await this.props.makePrimaryClick(this.props.phone)
                        } else {
                            result = await this.props.updateContactClick(this.props.phone, newPhone)
                        }
                        others.setSubmitting(false)
                        this.closeEdit()
                        if (result !== myAccountConstants.CUSTOMER_ACCOUNT_DATA_STATUS.SUCCESS) {
                            others.resetForm()
                        }
                    }}>
                    {({values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, resetForm, setFieldTouched, setFieldValue}) => {
                        const {isError, errMsg} = getPhoneNumberErrorInfo(touched, errors)
                        const {isNickNameError, nickNameErrMsg} = getPhoneLabelErrorInfo(touched, errors)

                        let editAdornment = (<IconButton color="primary" size="small" key="phoneEdit"
                                                onClick={() => {
                                                    this.props.hideAlert()
                                                    if (isEditing) {
                                                        resetForm()
                                                    }
                                                    this.editItem()
                                                }}
                                            >
                                                <Icon>edit</Icon>
                                            </IconButton>)

                        let deleteAdornment = (<IconButton color="primary" size="small" key="phoneDelete"
                                                    onClick={() => {
                                                        this.props.hideAlert()
                                                        setTimeout(() => {this.props.deleteContactClick(this.props.phone)}, 50)
                                                    }}
                                                >
                                                    <Icon>delete</Icon>
                                                </IconButton>)

                        let adornments = []
                        if (!isEditing) {
                            adornments.push(editAdornment)
                            if (!this.props.phone.isLoginContact) {
                                adornments.push(deleteAdornment)
                            }
                        }

                        return (
                            <form onSubmit={handleSubmit} className={isEditing ? 'py-4' : ''}>
                                {values.contactLabel && !isEditing && <div style={contactLabelStyle} className="ml-2">{values.contactLabel}</div>}
                                <div className="clearFix">
                                    <PatternFormat
                                        id="contactAddress"
                                        name="contactAddress"
                                        label={this.props.phone.displaySequenceNumber === 1 ? this.props.t('Primary') : (isEditing ? this.props.t('Phone number') : null)}
                                        helperText={errMsg}
                                        error={isError}
                                        value={values.contactAddress}
                                        valueIsNumericString={true}
                                        fullWidth={true}
                                        InputProps={{ endAdornment: adornments }}
                                        onValueChange={(values) => setFieldValue("contactAddress", values.value, true)}
                                        onBlur={handleBlur}
                                        format="(###) ###-####"
                                        type="tel"
                                        disabled={!isEditing}
                                        customInput={this.props.phone.displaySequenceNumber === 1 ? StyledPrimaryTextField : TextField}
                                    />
                                </div>
                                {isEditing &&
                                <div>
                                    <TextField
                                        id="contactLabel"
                                        name="contactLabel"
                                        label={this.props.t("Nickname (optional)")}
                                        helperText={nickNameErrMsg}
                                        error={isNickNameError}
                                        value={values.contactLabel}
                                        fullWidth={true}
                                        onChange={handleChange}
                                        onBlur ={(e) => {
                                            let value = e.target.value.substring(0,20)
                                            setFieldValue('contactLabel', value, true)
                                            setFieldTouched('contactLabel', true)
                                        }}
                                        inputProps={{ maxLength: 21 }}
                                    />
                                    <br />
                                    {this.props.phone.displaySequenceNumber > 1 &&
                                        <div>
                                            <input
                                                id="makePrimary"
                                                name="makePrimary"
                                                type="checkbox"
                                                checked={values.makePrimary}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            /><span className="pl-2" style={{color:"rgb(112,112,112)"}}>{this.props.t("Make primary phone number")}</span>
                                        </div>
                                    }
                                    {this.props.phone.displaySequenceNumber === 1 &&
                                        <div style={{ color: "rgb{112,112,112)" }}>
                                            {this.props.t("To change primary phones, select a different phone as your primary")}
                                        </div>
                                    }
                                    <div className="d-flex flex-row-reverse pt-2">
                                        <button className="btn srp-btn btn-green ml-2" type="submit" disabled={isSubmitting}>
                                            {isSubmitting ? <CircularProgress size={20} thickness={5} style={{color:'white'}} /> : this.props.t("Update")}
                                        </button>
                                        <button className="btn srp-btn btn-lightblue ml-2"
                                            onClick={() =>{
                                                resetForm()
                                                this.closeEdit()
                                            }}>{this.props.t("Cancel")}</button>
                                    </div>
                                </div>}
                            </form>
                        )
                    }}
                </Formik>
            </div>
        )
    }
}

PhoneItem.propTypes = {
    billAccount: PropTypes.number.isRequired,
    phone: PropTypes.object.isRequired,
    updateContactClick: PropTypes.func.isRequired,
    makePrimaryClick: PropTypes.func.isRequired,
    updateContactAndMakePrimaryClick: PropTypes.func.isRequired,
    deleteContactClick: PropTypes.func.isRequired,
    hideAlert: PropTypes.func.isRequired,
    openItem: PropTypes.string,
    setOpenItem: PropTypes.func.isRequired,
    t: PropTypes.func
}

export default PhoneItem