import React from 'react'
import PropTypes from 'prop-types'

import { withTranslation } from 'react-i18next'

import '../../../styles/dashboard-page.css'

import CallAnytime from '../../myaccount_header/call-anytime'

import GuestPaymentSubmittedCardContainer from './guest-payment-submitted-card-container'

const GuestPaymentSubmittedPagePure = ({t, i18n}) => (
    <div className="container py-3">
    <CallAnytime />

    <h3 className="mt-3 mb-5">{t("Make a payment")}</h3>

    <div className="row">
        <div className="col-lg-6">
            <GuestPaymentSubmittedCardContainer t={t} i18n={i18n} />
        </div>
    </div>
    </div>
)


GuestPaymentSubmittedPagePure.propTypes = {
    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired,
}

const GuestPaymentSubmittedPage = withTranslation("guestPayment")(GuestPaymentSubmittedPagePure)
export { GuestPaymentSubmittedPage as default, GuestPaymentSubmittedPagePure }