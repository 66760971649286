export const healthyForestFormConstants = {
    DONATION_AMT_NONE:"DONATION_AMT_NONE",
    DONATION_AMT_3: "DONATION_AMT_3",
    DONATION_AMT_6: "DONATION_AMT_6",
    DONATION_AMT_9: "DONATION_AMT_9",
    DONATION_AMT_12: "DONATION_AMT_12",
    DONATION_AMT_UNENROLL: "DONATION_AMT_UNENROLL",

    HEALTHY_FOREST_SIGNUP: 0,
    HEALTHY_FOREST_CHANGE: 1,
    HEALTHY_FOREST_UNENROLL: 2,

    SUBMIT_HEALTHY_FOREST_FORM_REQUEST: 'SUBMIT_HEALTHY_FOREST_FORM_REQUEST',
    SUBMIT_HEALTHY_FOREST_FORM_SUCCESS: 'SUBMIT_HEALTHY_FOREST_FORM_SUCCESS',
    SUBMIT_HEALTHY_FOREST_FORM_FAILURE: 'SUBMIT_HEALTHY_FOREST_FORM_FAILURE',
}