import React from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

import Address from './address'
import BillDate from './bill-date'
import EVDetails from './ev-details'

import { formConstants } from '../../../constants/form-constants'

const ServiceDetails = ({selectedPlan, currentStep,
    goBackToServiceDates, goToAboutYou, t, ...props}) => (
    <div>
    <Address
        {...props}
        currentStep={currentStep}
    />
    {selectedPlan !== formConstants.MPOWER &&
    <BillDate
        {...props}
        selectedPlan={selectedPlan}
    />
    }
    {selectedPlan === formConstants.ELECTRIC_VEHICLE &&
    <EVDetails
        {...props}
        selectedPlan={selectedPlan}
    />
    }
    <div className="d-flex justify-content-end pt-2">
        <button className="btn srp-btn btn-lightblue mr-3" onClick={() => goBackToServiceDates(currentStep - 1)}>
            {t("previous")}
        </button>
        <button className="btn srp-btn btn-blue" onClick={() => goToAboutYou(currentStep + 1)}>
            {t("next")}
        </button>
    </div>
    </div>
)

ServiceDetails.propTypes = {
    selectedPlan: PropTypes.string.isRequired,
    currentStep: PropTypes.number.isRequired,
    goBackToServiceDates: PropTypes.func.isRequired,
    goToAboutYou: PropTypes.func.isRequired,
    t: PropTypes.func
}

export default withTranslation('startService')(ServiceDetails)