import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

import CircularProgress from '@mui/material/CircularProgress'
import Button from '@mui/material/Button'
import Icon from '@mui/material/Icon'
import { DocumentTitle } from '../../../srp_modules/document-title'


import EVCommunityFormik from './ev-community-formik'
import EVCommunityHelp from './ev-community-help'

import AccountInfoBarContainer from '../../myaccount_header/info_bar/account-info-bar-container'
import CallAnytime from '../../myaccount_header/call-anytime'

import { getContacts } from '../../../actions/auth/contact/contact-actions'
import { getCustomerNames, getServiceAddress, getPhoneNumber } from '../../../actions/auth/bill_account/bill-account-actions'
import { getEnrolledProgramsDetails } from '../../../actions/auth/payment/payment-actions'
import { submitEVCommunitySignupForm, getUnApprovedVehicleTypes } from '../../../actions/auth/forms/ev-community-actions'

import evCommunityStatusModule from '../../../srp_modules/ev-community-status-module'

import { evCommunityConstants } from '../../../constants/ev-community-constants'
import * as loadingStatus from '../../../constants/loading-status-constants'

import format from '../../../srp_modules/format'
import { withRouter } from '../../../srp_modules/with-router'


class EVCommunitySignupContainerPure extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            initialChangedState: false
        }

        this.onSubmitEVCommunityForm = this.onSubmitEVCommunityForm.bind(this)
        this.onStateChanged = this.onStateChanged.bind(this)
    }

    componentDidMount() {
        if (this.props.selectedBillAccount === 0) {
            return
        }

        if (this.props.customerNamesStatus === loadingStatus.LOADING_STATUS_INIT
            || this.props.customerNamesStatus === loadingStatus.LOADING_STATUS_FAILURE) {
            this.props.getCustomerNamesInfo(this.props.selectedBillAccount)
        }
        if (this.props.contactsStatus === loadingStatus.LOADING_STATUS_INIT
            || this.props.contactsStatus === loadingStatus.LOADING_STATUS_FAILURE) {
            this.props.getContactInfo(this.props.selectedBillAccount)
        }
        if (this.props.serviceAddressStatus === loadingStatus.LOADING_STATUS_INIT
            || this.props.serviceAddressStatus === loadingStatus.LOADING_STATUS_FAILURE) {
            this.props.getServiceAddressInfo(this.props.selectedBillAccount)
        }

        if (this.props.phoneNumberStatus === loadingStatus.LOADING_STATUS_INIT
            || this.props.phoneNumberStatus === loadingStatus.LOADING_STATUS_FAILURE) {
            this.props.getBestPhone(this.props.selectedBillAccount)
        }

        if (this.props.enrolledProgramsDetailsStatus === loadingStatus.LOADING_STATUS_INIT
            || this.props.enrolledProgramsDetailsStatus === loadingStatus.LOADING_STATUS_FAILURE) {
            this.props.getEnrolledProgramsDetails(this.props.selectedBillAccount)
        }

        if (this.props.unApprovedVehicleTypesStatus === loadingStatus.LOADING_STATUS_INIT
            || this.props.unApprovedVehicleTypesStatus === loadingStatus.LOADING_STATUS_FAILURE) {
            this.props.getUnApprovedVehicleTypes()
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.selectedBillAccount === prevProps.selectedBillAccount || this.props.selectedBillAccount === 0)
            return

        if (this.props.customerNamesStatus !== loadingStatus.LOADING_STATUS_IN_PROGRESS) {
            this.props.getCustomerNamesInfo(this.props.selectedBillAccount)
        }

        if (this.props.contactsStatus !== loadingStatus.LOADING_STATUS_IN_PROGRESS) {
            this.props.getContactInfo(this.props.selectedBillAccount)
        }

        if (this.props.serviceAddressStatus !== loadingStatus.LOADING_STATUS_IN_PROGRESS) {
            this.props.getServiceAddressInfo(this.props.selectedBillAccount)
        }

        if (this.props.phoneNumberStatus !== loadingStatus.LOADING_STATUS_IN_PROGRESS) {
            this.props.getBestPhone(this.props.selectedBillAccount)
        }

        if (this.props.enrolledProgramsDetailsStatus !== loadingStatus.LOADING_STATUS_IN_PROGRESS) {
            this.props.getEnrolledProgramsDetails(this.props.selectedBillAccount)
        }

        if (this.props.unApprovedVehicleTypesStatus !== loadingStatus.LOADING_STATUS_IN_PROGRESS) {
            this.props.getUnApprovedVehicleTypes()
        }
    }

    onStateChanged() {
        this.setState({
            initialChangedState: true
        })
    }

    isLoading() {
        return this.props.customerNamesStatus === loadingStatus.LOADING_STATUS_INIT || this.props.customerNamesStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS
            || this.props.contactsStatus === loadingStatus.LOADING_STATUS_INIT || this.props.contactsStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS
            || this.props.serviceAddressStatus === loadingStatus.LOADING_STATUS_INIT || this.props.serviceAddressStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS
            || this.props.phoneNumberStatus === loadingStatus.LOADING_STATUS_INIT || this.props.phoneNumberStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS
            || this.props.enrolledProgramsDetailsStatus === loadingStatus.LOADING_STATUS_INIT || this.props.enrolledProgramsDetailsStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS
            || this.props.unApprovedVehicleTypesStatus === loadingStatus.LOADING_STATUS_INIT || this.props.unApprovedVehicleTypesStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS

    }

    createEVCommunityModel(props, formValues) {

        let model = {
            billAccount: props.selectedBillAccount,
            firstName: props.customerNames.firstName ? props.customerNames.firstName : props.customerNames.displayName,
            emailAddress: formValues.emailAddress,
            homeChargeLevel: formValues.chargerLevel,
            vin: formValues.vinNumber,
            vehicleNew: formValues.newUsedVehicle === "new" ? true : false
        }

        return model
    }

    onSubmitEVCommunityForm(formValues, formActions) {
        let formDetails = this.createEVCommunityModel(this.props, formValues)
        this.props.submitEVForm(formDetails, formActions)
    }

    render() {
        let formComponent = null

        if (this.isLoading()) {
            formComponent = <div className="d-flex justify-content-center"><CircularProgress size={80} thickness={5} style={{ marginTop: "20%", marginBottom: "20%" }} /></div>
        }
        else {
            let canApply = evCommunityStatusModule.getEVCommunitySignupEligibility(this.props.selectedBillAccountDetails,
                this.props.enrolledProgramsDetails.evCommunityCredits, this.props.customerNames)

            let hasCreditsRemaining = this.props.enrolledProgramsDetails.evCommunityCredits.optionValue > 0

            formComponent = (
                <React.Fragment>
                    <EVCommunityFormik
                        customerName={this.props.customerNames.displayName}
                        formattedBillAccount={format.formatBillAccountNumber(this.props.selectedBillAccount)}
                        serviceAddress={this.props.serviceAddress}
                        formattedPhoneNumber={format.formatPhoneNumber(this.props.phoneNumber)}
                        emailAddress={this.props.customerEmailAddress}
                        formType={evCommunityConstants.EV_COMMUNITY_PLAN_SIGNUP}
                        submitEVCommunityForm={this.onSubmitEVCommunityForm}
                        submitEVCommunityFormStatus={this.props.submitEVCommunityFormStatus}
                        unApprovedVehicleTypes={this.props.unApprovedVehicleTypes}
                        initialChangedState={this.state.initialChangedState}
                        onStateChanged={this.onStateChanged}
                        canApply={canApply}
                        hasNoCredits={!hasCreditsRemaining}
                    />
                </React.Fragment>
            )
        }

        return (
            <DocumentTitle title={this.props.t("EV_Community_Browser_Tab_Title")}>
                <div>
                    <div className="d-block d-md-none">
                        <Button
                            variant="contained"
                            fullWidth
                            className="justify-content-start nav-button-responsive"
                            onClick={() => this.props.router.navigate(-1)}
                            disableRipple>
                            <Icon className="material-icons mr-2" style={{ color: 'rgb(33,108,224' }}>keyboard_backspace</Icon>
                            {this.props.t("EV_Community_Previous_Page_Button")}
                        </Button>
                    </div>
                    <div className="container py-3">
                        <CallAnytime />
                        <h3 className="d-lg-block d-none mt-3 mb-4 pb-4">{this.props.t("EV_Community_Signup_Page_Title")}</h3>
                        <h4 className="d-lg-none d-block mt-3 mb-4">{this.props.t("EV_Community_Signup_Page_Title")}</h4>

                        <AccountInfoBarContainer disableSelector={true} />

                        <div className="d-none d-md-block mb-4">
                            <h5 className="d-flex justify-content-start align-items-center">
                                <button className="displayAsLink" onClick={() => this.props.router.navigate(-1)}>{this.props.t("EV_Community_Previous_Page_Button")} </button>
                                <i className="material-icons" style={{ color: 'rgba(72, 72, 74, 0.376)' }} >keyboard_arrow_right</i>
                                <span style={{ color: '#707070' }}>{this.props.t("EV_Community_Signup_Page_Title")}</span>
                            </h5>
                        </div>

                        <div className="row">
                            <div className="col-lg-6 col-12">
                                <div className="srp-card-header">
                                    {this.props.submitEVCommunityFormStatus !== loadingStatus.LOADING_STATUS_SUCCESS
                                        ? this.props.t("Join_EV_Community")
                                        : this.props.t("EV_Community_Submitted_Confirmation")
                                    }

                                </div>

                                <div className="srp-card-body text-muted">
                                    {formComponent}
                                </div>
                            </div>
                            <div className="col-lg-6 col-12 mt-lg-0 mt-4">
                                <EVCommunityHelp />
                            </div>
                        </div>
                    </div>
                </div>
            </DocumentTitle>
        )
    }
}


const mapStateToProps = state => {
    return {
        selectedBillAccount: state.accountInfo.billAccount.selectedBillAccount,
        selectedBillAccountDetails: state.accountInfo.billAccount.selectedBillAccountDetails,
        isCommercial: state.accountInfo.billAccount.selectedBillAccountDetails.isCommercial,
        customerNames: state.accountInfo.billAccount.customerNames,
        customerNamesStatus: state.accountInfo.billAccount.customerNamesStatus,
        serviceAddress: state.accountInfo.billAccount.serviceAddress,
        serviceAddressStatus: state.accountInfo.billAccount.serviceAddressStatus,
        contactsStatus: state.accountInfo.contact.contactsStatus,
        customerEmailAddress: state.accountInfo.contact.primaryEmail,
        phoneNumber: state.accountInfo.billAccount.phoneNumber.bestPhone,
        phoneNumberStatus: state.accountInfo.billAccount.phoneNumberStatus,
        enrolledProgramsDetailsStatus: state.accountInfo.payment.enrolledProgramsDetailsStatus,
        enrolledProgramsDetails: state.accountInfo.payment.enrolledProgramsDetails,
        ...state.evCommunity,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getContactInfo: (billAccount) => {
            dispatch(getContacts(billAccount))
        },
        getCustomerNamesInfo: (billAccount) => {
            dispatch(getCustomerNames(billAccount))
        },
        getServiceAddressInfo: (billAccount) => {
            dispatch(getServiceAddress(billAccount))
        },
        getBestPhone: (billAccount) => {
            dispatch(getPhoneNumber(billAccount))
        },
        getEnrolledProgramsDetails: (billAccount) => {
            dispatch(getEnrolledProgramsDetails(billAccount))
        },
        getUnApprovedVehicleTypes: () => {
            dispatch(getUnApprovedVehicleTypes())
        },
        submitEVForm: async (formDetails, formActions) => {
            let res = await dispatch(submitEVCommunitySignupForm(formDetails))

            let submitErrors = {
                submitFailed: res.payload?.submitSuccess === false,
                emailFailed: res.payload?.emailSuccess === false
            }

            if (res.payload?.errorCode !== evCommunityConstants.EV_COMMUNITY_SGNUP_ERROR_NONE)

                switch (res.payload.errorCode) {
                    case evCommunityConstants.EV_COMMUNITY_SIGNUP_ERROR_PREVIOUS_REGISTRATION_BA:
                        submitErrors.registrationErrorVINAlreadyUsed = true
                        break
                    case evCommunityConstants.EV_COMMUNITY_SIGNUP_ERROR_PREVIOUS_REGISTERED_NEW:
                        submitErrors.registrationErrorPreviouslyNew = true
                        break
                    case evCommunityConstants.EV_COMMUNITY_SIGNUP_ERROR_UNKNOWN:
                        submitErrors.registrationErrorUnknown = true
                        break
                }

            formActions.setStatus({ submitErrors })
            formActions.setSubmitting(false)
        }
    }
}

EVCommunitySignupContainerPure.propTypes = {
    router: PropTypes.shape({
        navigate: PropTypes.func
    }),
    selectedBillAccount: PropTypes.number,
    selectedBillAccountDetails: PropTypes.object,

    customerNames: PropTypes.object,
    customerNamesStatus: PropTypes.string,
    getCustomerNamesInfo: PropTypes.func,

    contactsStatus: PropTypes.string,
    getContactInfo: PropTypes.func,
    customerEmailAddress: PropTypes.string,

    serviceAddressStatus: PropTypes.string,
    serviceAddress: PropTypes.object,
    getServiceAddressInfo: PropTypes.func,

    phoneNumberStatus: PropTypes.string,
    phoneNumber: PropTypes.string,
    getBestPhone: PropTypes.func,

    enrolledProgramsDetails: PropTypes.object,
    enrolledProgramsDetailsStatus: PropTypes.string,
    getEnrolledProgramsDetails: PropTypes.func,

    submitEVForm: PropTypes.func,
    submitEVCommunityFormStatus: PropTypes.string,

    getUnApprovedVehicleTypes: PropTypes.func,
    unApprovedVehicleTypesStatus: PropTypes.string,
    unApprovedVehicleTypes: PropTypes.object,

    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired
}

const EVCommunitySignupContainer = withTranslation("evCommunitySignupForm")(EVCommunitySignupContainerPure)
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EVCommunitySignupContainer))