import { isRSAA } from 'redux-api-middleware'

const SKIP_BILL_ACCOUNT_CHECK = 'srp-my-account/SKIP_BILL_ACCOUNT_CHECK'
const BILL_ACCOUNT_ERROR = 'srp-my-account/BILL_ACCOUNT_ERROR'

const billAccountMiddleware = apiMiddleware => ({ dispatch, getState }) => next => action => {
    if (!isRSAA(action))
        return(next(action))

    let newAction = { ...action }

    let skipBillAccountCheck = newAction[SKIP_BILL_ACCOUNT_CHECK]
    if (skipBillAccountCheck !== undefined) {
        delete newAction[SKIP_BILL_ACCOUNT_CHECK]
    }

    if (skipBillAccountCheck === true) {
        return apiMiddleware({ dispatch, getState })(next)(newAction)
    }

    let selectedBillAccount = getState().accountInfo.billAccount.selectedBillAccount
    return apiMiddleware({ dispatch, getState })(dropRequestsIfBillAccountChanged(selectedBillAccount, getState)(next))(newAction)
}

const dropRequestsIfBillAccountChanged = (billAccount, getState) => next => action => {
    let selectedBillAccount = getState().accountInfo.billAccount.selectedBillAccount

    if (billAccount !== 0 && billAccount !== selectedBillAccount) {
        let message = `Ignoring ${action.type} for ${billAccount}, because a new bill account was selected: ${selectedBillAccount}.`
        return next({ type: BILL_ACCOUNT_ERROR, error: true, payload: message })
    }

    return next(action)
}

export { billAccountMiddleware, SKIP_BILL_ACCOUNT_CHECK }