import React from 'react'
import PropTypes from 'prop-types'
import AppBar from '@mui/material/AppBar'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import AddNewAccountForm from './add-new-account-form'
import RestoreHiddenAccountForm from './restore-hidden-account-form'

class AddAccountContainer extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            selectedTab: 0
        }

        this.selectTab = this.selectTab.bind(this)
    }

    selectTab(index) {
        this.setState({ ...this.state, selectedTab: index })
    }

    render() {
        return (
            <React.Fragment>
                <AppBar position="static" style={{ backgroundColor: 'white', borderBottom: "1px solid lightgray" }} elevation={0}>
                    <Tabs
                        value={this.state.selectedTab}
                        onChange={(e, value) => this.selectTab(value)}
                        indicatorColor="primary"
                        textColor="primary"
                        style={{ color: 'black' }}
                        variant="fullWidth"
                    >
                        <Tab className="mui_tab" label={this.props.t("Add new")} style={{ outline: 'none', boxShadow: 'none', textTransform: 'none' }} />
                        <Tab className="mui_tab" label={this.props.t("Add from list")} style={{ outline: 'none', boxShadow: 'none', textTransform: 'none' }} />
                    </Tabs>
                </AppBar>
                <div className="mt-2">
                    {this.state.selectedTab === 0 ? <AddNewAccountForm {...this.props} /> : null}
                    {this.state.selectedTab === 1 ? <RestoreHiddenAccountForm {...this.props} /> : null}
                </div>
            </React.Fragment>
        )
    }
}

AddAccountContainer.propTypes = {
    hideForm: PropTypes.func
}

export default AddAccountContainer