export const outageConstants = {
    
    //outageActions constants (PRIVATE)
    GET_IS_IN_OUTAGE_REQUEST: 'GET_IS_IN_OUTAGE_REQUEST',
    GET_IS_IN_OUTAGE_SUCCESS: 'GET_IS_IN_OUTAGE_SUCCESS',
    GET_IS_IN_OUTAGE_FAILURE: 'GET_IS_IN_OUTAGE_FAILURE',

    UPDATE_IS_IN_OUTAGE_REQUEST: 'UPDATE_IS_IN_OUTAGE_REQUEST',
    UPDATE_IS_IN_OUTAGE_SUCCESS: 'UPDATE_IS_IN_OUTAGE_SUCCESS',
    UPDATE_IS_IN_OUTAGE_FAILURE: 'UPDATE_IS_IN_OUTAGE_FAILURE',

    GET_OUTAGE_HISTORY_REQUEST: 'GET_OUTAGE_HISTORY_REQUEST',
    GET_OUTAGE_HISTORY_SUCCESS: 'GET_OUTAGE_HISTORY_SUCCESS',
    GET_OUTAGE_HISTORY_FAILURE: 'GET_OUTAGE_HISTORY_FAILURE',

    GET_NEXT_OUTAGE_HISTORY: 'GET_NEXT_OUTAGE_HISTORY',
    GET_PREV_OUTAGE_HISTORY: 'GET_PREV_OUTAGE_HISTORY',

    //outageActions constants (PUBLIC)
    GET_ALL_OUTAGES_REQUEST: 'GET_ALL_OUTAGES_REQUEST',
    GET_ALL_OUTAGES_SUCCESS: 'GET_ALL_OUTAGES_SUCCESS',
    GET_ALL_OUTAGES_FAILURE: 'GET_ALL_OUTAGES_FAILURE',

    UPDATE_ALL_OUTAGES_REQUEST: 'UPDATE_ALL_OUTAGES_REQUEST',
    UPDATE_ALL_OUTAGES_SUCCESS: 'UPDATE_ALL_OUTAGES_SUCCESS',
    UPDATE_ALL_OUTAGES_FAILURE: 'UPDATE_ALL_OUTAGES_FAILURE',

    GET_ICE_REIMBURSEMENT_INFO_REQUEST: 'GET_ICE_REIMBURSEMENT_INFO_REQUEST',
    GET_ICE_REIMBURSEMENT_INFO_SUCCESS: 'GET_ICE_REIMBURSEMENT_INFO_SUCCESS',
    GET_ICE_REIMBURSEMENT_INFO_FAILURE: 'GET_ICE_REIMBURSEMENT_INFO_FAILURE',

    //outageMapActions constants
    HIDE_LEGEND: 'HIDE_LEGEND',
    SHOW_LEGEND: 'SHOW_LEGEND',
    HIDE_OUTAGE_DETAILS: 'HIDE_OUTAGE_DETAILS',
    SHOW_OUTAGE_DETAILS: 'SHOW_OUTAGE_DETAILS',
    SHOW_OUTAGE_DETAILS_RESP: 'SHOW_OUTAGE_DETAILS_RESP',
    SHOW_CUSTOMER_IN_OUTAGE_DETAILS: 'SHOW_CUSTOMER_IN_OUTAGE_DETAILS',
    HIDE_CUSTOMER_IN_OUTAGE_DETAILS: 'HIDE_CUSTOMER_IN_OUTAGE_DETAILS',

    //ReportOutageActions constants
    TOGGLE_POWER_RESTORED_CALLBACK: 'TOGGLE_POWER_RESTORED_CALLBACK',

    REPORT_OUTAGE_REQUEST: 'REPORT_OUTAGE_REQUEST',
    REPORT_OUTAGE_SUCCESS: 'REPORT_OUTAGE_SUCCESS',
    REPORT_OUTAGE_FAILURE: 'REPORT_OUTAGE_FAILURE',

    GET_MEDICAL_SUPPORT_OPTION_REQUEST: 'GET_MEDICAL_SUPPORT_OPTION_REQUEST',
    GET_MEDICAL_SUPPORT_OPTION_SUCCESS: 'GET_MEDICAL_SUPPORT_OPTION_SUCCESS',
    GET_MEDICAL_SUPPORT_OPTION_FAILURE: 'GET_MEDICAL_SUPPORT_OPTION_FAILURE',

    GET_INELIGIBLE_TO_REPORT_REQUEST: 'GET_INELIGIBLE_TO_REPORT_REQUEST',
    GET_INELIGIBLE_TO_REPORT_SUCCESS: 'GET_INELIGIBLE_TO_REPORT_SUCCESS',
    GET_INELIGIBLE_TO_REPORT_FAILURE: 'GET_INELIGIBLE_TO_REPORT_FAILURE',

    GET_LAST_REPORTED_OUTAGE_REQUEST: 'GET_LAST_REPORTED_OUTAGE_REQUEST',
    GET_LAST_REPORTED_OUTAGE_SUCCESS: 'GET_LAST_REPORTED_OUTAGE_SUCCESS',
    GET_LAST_REPORTED_OUTAGE_FAILURE: 'GET_LAST_REPORTED_OUTAGE_FAILURE'
}