import { eraProgramConstants } from '../../../constants/era-program-constants'
import { RSAA } from 'redux-api-middleware'

import utils from '../../../srp_modules/utils'

export const submitEraProgramForm = (erapFormDetails) => {
    let url = utils.createUrl('forms/submiterapquestionnaire')

    let stringData = JSON.stringify(erapFormDetails)

    return dispatch => {
        return dispatch({
            [RSAA]: {
                endpoint: url,
                body: stringData,
                method: 'POST',
                credentials: 'include',
                headers: { 'Content-Type': 'application/json' },
                types: [
                    eraProgramConstants.SUBMIT_ERAP_FORM_REQUEST,
                    eraProgramConstants.SUBMIT_ERAP_FORM_SUCCESS,
                    eraProgramConstants.SUBMIT_ERAP_FORM_FAILURE,
                ]
            },
            meta: {
                debounce: {
                    leading: false,
                    trailing: false
                }
            }
        })
    }
}

export const refreshErapForm = () => ({
    type: eraProgramConstants.REFRESH_ERAP_FORM
})