import React from 'react'
import PropTypes from 'prop-types'

import { withTranslation } from 'react-i18next'

import '../../../styles/dashboard-page.css'

import CallAnytime from '../../myaccount_header/call-anytime'
import Breadcrumb from '../../common_payment/breadcrumb'

import OtherPaymentOptions from './other-payment-options'

import AccountInfoBarContainer from '../../myaccount_header/info_bar/account-info-bar-container'

import ConnectedSinglePaymentInputCard from './single-payment-input-card'
import MultiplePaymentInputCardContainer from './multi-account/multiple-payment-input-card-container'
import CheckDiagramCard from '../common/check-diagram-card'

import { formStartEvent } from '../../../srp_modules/analytics-events'
import { giftPaymentConstants } from '../common/gift-payment-constants'

const GiftPaymentPage = ({multiplePaymentsEnabled, bankFormOpen, formStartEventEmitted, setFormStartEventEmitted, t, i18n}) => {
    const formDataChanged = (e) => {
        e === e // to fix ESLint no-unused-vars warning
        if (formStartEventEmitted === false) {
            const formName = giftPaymentConstants.ADOBE_ANALYTICS_FORM_NAME
            const stepName = giftPaymentConstants.GIFT_PAYMENT_STEPS[0]
            formStartEvent(formName, stepName)

            setFormStartEventEmitted()
        }
    }

    return (
    <div className="container py-3">
        <CallAnytime />

        <h3 className="mt-3 mb-4">{t("Make a gift payment")}</h3>

        <AccountInfoBarContainer />

        <Breadcrumb pageTitle={t("Make a gift payment")} />

        <div className="row">
            <div className="col-lg-6 mt-3">
                {multiplePaymentsEnabled ? <MultiplePaymentInputCardContainer formDataChanged={formDataChanged} t={t} i18n={i18n} />
                    : <ConnectedSinglePaymentInputCard formDataChanged={formDataChanged} t={t} i18n={i18n} />}
            </div>
            <div className="col-lg-6 mt-3">
                <OtherPaymentOptions t={t} i18n={i18n} />
                {bankFormOpen &&
                <div className="d-none d-lg-block mt-4">
                    <CheckDiagramCard t={t} i18n={i18n} />
                </div>
                }
            </div>
        </div>
    </div>
    )
}

GiftPaymentPage.propTypes = {
    multiplePaymentsEnabled: PropTypes.bool.isRequired,
    bankFormOpen: PropTypes.bool.isRequired,

    formStartEventEmitted: PropTypes.bool.isRequired,
    setFormStartEventEmitted: PropTypes.func.isRequired,

    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired,
    t: PropTypes.func.isRequired,
}

export default withTranslation(["giftPayment", "earlyWarning"])(GiftPaymentPage)