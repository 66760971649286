import {formConstants} from './../../constants/form-constants'

export const displayRateCode = (key, t) => {
  switch (key){
    case formConstants.EZ_3: return t("ez3_3_6_plan")
    case formConstants.EZ_34: return t("ez3_4_7_plan")
    case formConstants.TIME_OF_USE: return t("tou_plan")
    case formConstants.ELECTRIC_VEHICLE: return t("ev_plan")
    case formConstants.BASIC: return t("basic_plan")
    case formConstants.CUSTOMER_GENERATION: return t("customer_generation_plan")
    case formConstants.MPOWER: return t("mpower")
  }
}
