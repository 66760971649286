import React from 'react'
import PropTypes from 'prop-types'
import FormGroup from '@mui/material/FormGroup'

import '../../styles/price-plan.css'

import PlanSelection from './plan-selection'
import { pricePlanConstants } from '../../constants/price-plan-constants'
import { StartServiceDictionary } from '../forms/web_turnon/start-service-dictionary'

const ResidentialGenerationSelectPlan = ({ selectedPricePlanResidential, selectNewPricePlanResidential, currentRateCode }) => {
    let commonSelectionProps = {
        selectedPricePlanResidential: selectedPricePlanResidential,
        selectNewPricePlanResidential: selectNewPricePlanResidential,
        currentRateCode: currentRateCode,
        disabled: false
    }

    return (
        <FormGroup>
            <PlanSelection
                rateCode={pricePlanConstants.PRICE_PLAN_RES_SERVER_MAP.TOU_EXPORT}
                description={StartServiceDictionary.en.tou_export_plan}
                learnMoreLink="price-plans/residential-electric/solar/time-of-use-export"
                {...commonSelectionProps}
            />
            <PlanSelection
                rateCode={pricePlanConstants.PRICE_PLAN_RES_SERVER_MAP.EV_EXPORT}
                description={StartServiceDictionary.en.ev_export_plan}
                learnMoreLink="price-plans/residential-electric/solar/electric-vehicle-export"
                {...commonSelectionProps}
            />
            <PlanSelection
                rateCode={pricePlanConstants.PRICE_PLAN_RES_SERVER_MAP.AVERAGE_DEMAND}
                description={StartServiceDictionary.en.average_demand_plan}
                learnMoreLink="price-plans/residential-electric/solar/average-demand"
                {...commonSelectionProps}
            />
            <PlanSelection
                rateCode={pricePlanConstants.PRICE_PLAN_RES_SERVER_MAP.GENERATION}
                description={StartServiceDictionary.en.customer_generation_plan}
                learnMoreLink="price-plans/residential-electric/solar/customer-generation"
                {...commonSelectionProps}
            />
        </FormGroup>
    )
}

ResidentialGenerationSelectPlan.propTypes = {
    selectedPricePlanResidential: PropTypes.number,
    selectNewPricePlanResidential: PropTypes.func,
    currentRateCode: PropTypes.number
}

export default ResidentialGenerationSelectPlan