import React from "react"
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { deletePayment } from '../../../../actions/auth/payment/payment-actions'

import ScheduledPayment from './scheduled-payment'

class ScheduledPaymentContainer extends React.Component {
    constructor(props) {
        super(props)

        this.onRemovePaymentClicked = this.onRemovePaymentClicked.bind(this)
    }

    onRemovePaymentClicked = async () => {
        const paymentRemoved = await this.props.removePayment(
            this.props.scheduledPayment.referenceNumber,
            this.props.scheduledPayment.billAccountNumber,
            this.props.scheduledPayment.fundingSourceId
        )

        // If there's only scheduled payment and no recent payment (showMakeAnotherPaymentButton is true),
        // go to make payment page if the scheduled payment is deleted successfully.
        const noRecentPayment = this.props.showMakeAnotherPaymentButton
        if (paymentRemoved && noRecentPayment)
            this.props.makeAnotherPayment()
    }

    render() {
        return (
            <ScheduledPayment
                scheduledPayment={this.props.scheduledPayment}
                bankAccountList={this.props.bankAccountList}
                scheduledBy={this.props.scheduledBy}
                showMakeAnotherPaymentButton={this.props.showMakeAnotherPaymentButton}
                makeAnotherPayment={this.props.makeAnotherPayment}
                removePayment={this.onRemovePaymentClicked}
                deletePaymentStatus={this.props.deletePaymentStatus}
                isScheduledPaymentDeleted={this.props.isScheduledPaymentDeleted}
                supportPhoneNumber={this.props.supportPhoneNumber}
                t={this.props.t}
                i18n={this.props.i18n}
            />
        )
    }
}

ScheduledPaymentContainer.propTypes = {
    scheduledPayment: PropTypes.object.isRequired,
    bankAccountList: PropTypes.arrayOf(PropTypes.object).isRequired,
    scheduledBy: PropTypes.string.isRequired,
    showMakeAnotherPaymentButton: PropTypes.bool.isRequired,
    makeAnotherPayment: PropTypes.func.isRequired,
    removePayment: PropTypes.func.isRequired,
    deletePaymentStatus: PropTypes.string.isRequired,
    isScheduledPaymentDeleted: PropTypes.bool.isRequired,
    supportPhoneNumber: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired,
    i18n: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
    deletePaymentStatus: state.accountInfo.payment.deletePaymentStatus,
    isScheduledPaymentDeleted: state.accountInfo.payment.isScheduledPaymentDeleted
})

const mapDispatchToProps = (dispatch) => ({
    removePayment: async (referenceNumber, billAccountNumber, bankAccountId) => {
        const deletePaymentResult = await dispatch(deletePayment(referenceNumber, [billAccountNumber], bankAccountId))

        const paymentRemoved = (deletePaymentResult.type==="DELETE_PAYMENT_SUCCESS" && deletePaymentResult.payload && deletePaymentResult.payload.isSuccess===true)
        return paymentRemoved
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(ScheduledPaymentContainer)