
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { myAccountConstants } from '../../../constants/myaccount-constants'


const EconomyPricePlanHelpPure = ({ t, i18n }) => {
    let lang = "es"
    let phone = myAccountConstants.RESIDENTIAL_CUSTOMER_SERVICE_PHONE_NUMBER
    let fmttdPhone = myAccountConstants.RESIDENTIAL_CUSTOMER_SERVICE_PHONE_NUMBER_FMTTD
    if (i18n.language === 'es') {
        lang = "en"
        phone = myAccountConstants.SPANISH_CUSTOMER_SERVICE_PHONE_NUMBER
        fmttdPhone = myAccountConstants.SPANISH_CUSTOMER_SERVICE_PHONE_NUMBER_FMTTD
    }

    return (
        <React.Fragment>
            <div className="srp-card-header">{t("EPP_Need_Help")}</div>
            <div className="srp-card-body srp-orange-background">
                <div className="srp-card-details">
                    <p className="d-lg-block d-none mb-0">
                        {t("EPP_Help_Contact_Us")}
                        <span> {fmttdPhone}</span>
                        {t("EPP_Available_24_Hours")}
                    </p>
                    <p className="d-lg-none d-block mb-0">
                        {t("EPP_Help_Contact_Us")}
                        <a href={"tel:" + phone}> {fmttdPhone}</a>
                        {t("EPP_Available_24_Hours")}
                    </p>
                    <br />
                    <div>
                        {t("EPP_Help_Available_In")} <button className="displayAsLink" onClick={() => { i18n.changeLanguage(lang) }}>{t("EPP_Help_Available_In_Language")}</button>
                    </div>
                </div>
            </div>
        </React.Fragment >
    )
}

EconomyPricePlanHelpPure.propTypes = {
    setPreferredLanguage: PropTypes.func,

    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
        changeLanguage: PropTypes.func.isRequired,
    }).isRequired
}

const EconomyPricePlanHelp = withTranslation("economyPricePlanSignupForm")(EconomyPricePlanHelpPure)
export default connect()(EconomyPricePlanHelp)
