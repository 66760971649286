import drawHorizontalBars from './draw-horizontal-bars'
import {getGenerationData} from './generation-data'
import * as d3 from 'd3'
import { DateTime } from 'luxon'
import {displaySizeConstants} from '../../constants/display-size-constants'
import {getDisplaySize} from '../../srp_modules/display-size'

const standardChartKeys = ["superOffPeak", "offPeak", "shoulder",  "onPeak",  "total", "min", "max"]
const margin = {
    top: 20,
    right: 20,
    bottom: 80,
    left: 130
}

export default function drawHorizontalChart(data, t){
    let isCost = false
    let allNetData = getGenerationData(data, false)
    let adjustedDataGeneration = allNetData.generationArray
    let adjustedDataConsumption = allNetData.consumptionArray
    
    let maxX = allNetData.max
    let minX = allNetData.min

    let legendDisplayStates = getLegendDisplayStates(adjustedDataConsumption, isCost)
    
    let barHeight = 20;
    let yOffset = 5;
    let chart = d3.select("#usagePageChartContainer")
    d3.selectAll("#usagePageChart").remove()
    
    let displaySize = getDisplaySize(window.innerWidth)
    let chartSize = getChartSvgSize(displaySize, data.length, barHeight,margin)
    
    let  svg = chart
    .insert('svg','div')
    .attr('width', chartSize.width)
    .attr('height', chartSize.height)
    .attr('id', 'usagePageChart')
    .attr('class','viz-chart')

    let g = svg.append("g")
        .attr("transform", "translate(0," + margin.top + ")")
    let g2 = svg.append("g")
        .attr("transform", "translate(0," + margin.top + ")")
    let gTemp = svg.append("g")
        .attr("transform", "translate(" + margin.left + "," + margin.top + ")")
    let svgOffsetLeft = calculateOffsetLeft(svg);
    
    let y = d3.scaleBand()
        .range([0, chartSize.height - margin.top - margin.bottom])
        .align(0.1)
        .padding(0.1)
    
    let xTemp = d3.scaleLinear().range([chartSize.width - margin.left - margin.right - pageXOffset, 0])
    let x = d3.scaleLinear()
        .rangeRound([chartSize.width/2 + margin.left/2, chartSize.width - margin.right])
    let x2 = d3.scaleLinear()
        .rangeRound([margin.left, (chartSize.width/2 + margin.left/2) - margin.right])
    y.domain(adjustedDataConsumption.map(function (d) {
        return d.date
    }))
    x.domain([0, maxX])
    x2.domain([minX, 0])
   
    let initialTt = chart.select(".viz-tooltip-arrow-main")
    initialTt.remove()
    let tooltip = addTooltipToChart(chart)

    g.append("g").attr("id","usagePageChartMainGroup")
    .selectAll("g")
    .data(d3.stack().keys(standardChartKeys)(adjustedDataConsumption))
    .enter().append("g")
    .attr("class", function (d,i) {
        return getCssClass(standardChartKeys[i]) + " viz-bar"
    })
    .selectAll("path")
    .data(function (d) {
        return d
    })
    .enter().append("g")
    .append("path")
    .on("mouseover", function (d) {
        handleMouseOver(d, this, tooltip, barHeight, yOffset,
            svg, svgOffsetLeft, x, y,x(0), t)
    })
    .on("mouseout", function (d) { handleMouseOut(d, this, tooltip) })
    .attr("height", 0)
    .attr("d", function (d) {
        return drawHorizontalBars(d,data,x,y,0,maxX,yOffset,barHeight,"date")
    })

    g2.append("g").attr("id","usagePageChartMainGroup")
    .selectAll("g")
    .data(d3.stack().keys(standardChartKeys)(adjustedDataGeneration))
    .enter().append("g")
    .attr("class", function (d,i) {
        return getCssClass(standardChartKeys[i]) + " viz-bar"
    })
    .selectAll("path")
    .data(function (d) {
        return d
    })
    .enter().append("g")
    .append("path")
    .on("mouseover", function (d) {
        handleMouseOver(d, this, tooltip, barHeight, yOffset,
            svg, svgOffsetLeft, x2, y, x2(0), t)
    })
    .on("mouseout", function (d) { handleMouseOut(d, this, tooltip) })
    .attr("height", 0)
    .attr("d", function (d) {
        return drawHorizontalBars(d,data,x2,y,minX,0,yOffset,barHeight,"date")
    })

    let chartHeight = chartSize.height - margin.top - margin.bottom
    let chartWidth = chartSize.width - margin.left - margin.right
    drawAxisTicks(data, g, g2, gTemp, chartSize, chartHeight,chartWidth,isCost, x, x2, y, xTemp)
    drawLegend(g, chartSize.height, chartSize.width - margin.left - margin.right, legendDisplayStates)
}

function drawAxisTicks(data, g, g2, gTemp, chartSize, yOffset, width, isCost, x, x2, y, xTemp){
    if(isCost){ 
        g.append("g")
        .attr("class", "axis")
        .call(d3.axisTop(x).ticks(2, "s")
            .tickFormat(d3.format(["$", ""])))

        g2.append("g")
            .attr("class", "axis")
            .call(d3.axisTop(x2).ticks(2, "s")
                .tickFormat(d3.format(["$", ""])))
    }
    else{ 
        g.append("g")
            .attr("class", "axis")
            .call(d3.axisTop(x).ticks(2, "s"))
        
        g2.append("g")
                .attr("class", "axis")
                .call(d3.axisTop(x2).ticks(2, "s"))
    }
    gTemp.append("g")
        .attr("class", "axis")
        .attr("transform", "translate(-10, 2)")
        .call(d3.axisLeft(y).tickValues(y.domain().filter(function(d,i){
            return !(i % 7)
        }))
        .tickFormat(function(x){return DateTime.fromISO(x).toFormat('MMM d h:mm a')})
        )
    gTemp.append("g")
        .attr("transform", "translate(0," + (chartSize.height  - margin.top - margin.bottom) + ")")   
        .attr("class", "axis")
        .call(d3.axisBottom(xTemp).ticks(5, "s"))
        .append("text")
        .attr("x", 2)
        .attr("y", x(xTemp.ticks().pop()) + 0.5)
        .attr("fill", "#000")
        .attr("font-weight", "bold")
        .attr("text-anchor", "start")
}


function getChartSvgSize(displaySize, numberOfBars, barHeight, margin){
    let chartHeight = numberOfBars * (barHeight + 10) + margin.top + margin.bottom
    switch(displaySize){
        case displaySizeConstants.MEDIUM:
        return {
                height: chartHeight, 
                width :640
            }
        case displaySizeConstants.SMALL:
            return {
                height: chartHeight, 
                width :480
            }
        case displaySizeConstants.EXTRA_SMALL:
            return {
                height: chartHeight, 
                width :400
            }
        case displaySizeConstants.MOBILE_LARGE:
            return {
                height: chartHeight, 
                width :340
            }
        case displaySizeConstants.MOBILE:
            return {
                height: chartHeight, 
                width :310
            }
        case displaySizeConstants.MOBILE_SMALL:
            return {
                height: chartHeight, 
                width :260
            }
        default:
            return {
                height: chartHeight, 
                width :640
            }
        }
}

function getCssClass(text) {
    if (text.includes("offset")) {
        return "viz-invisible"
    }
    return "viz-" + text
}

function handleMouseOver(d, element, tooltip, barHeight, yOffset, svg, svgOffsetLeft, x, y, arrowPosition, t) {
    $(element).addClass("current-hover")
    createTooltip(d, tooltip, barHeight, yOffset, svg, svgOffsetLeft, x, y, arrowPosition, t)
return true
}

function handleMouseOut(d, element, tooltip) {
    $(element).removeClass("current-hover")
    tooltip.style("display", "none")
}

function calculateOffsetLeft(svg) {
    let offsetLeft = svg.node().offsetLeft
    if (typeof offsetLeft != "undefined") {
        return offsetLeft
    }
    let svgElement = document.getElementById(svg.node().id)
    let svgParent = svgElement.parentNode
    let left = svgElement.getBoundingClientRect().left -
        svgParent.getBoundingClientRect().left

    return left;
}

function addTooltipToChart(chart) {
    let tooltip = chart
        .append("div")
        .attr("class", "viz-tooltip-arrow-main")
    let tooltipArrow =
        tooltip
            .append("div")
            .attr("class", "viz-tooltip-arrow")

    tooltipArrow.append("div")
        .attr("class", "viz-tooltip-main")
        .append("div")
        .attr("class", "viz-tooltip-inner")
        .attr("id", "viz-tooltipText")
    return tooltip
}

function createTooltip(d, tooltip, barHeight, 
    yOffset, svg, svgOffsetLeft,
    x, y, arrowPosition, t) {
    let yPosition = y(d.data.date) - (barHeight/2) + yOffset + margin.top
        
    let xPosition = arrowPosition - 8
    tooltip
        .style("left", xPosition + "px")
        .style("top", yPosition + "px")
        .style("display", "block")
    let tooltipInner = tooltip.select(".viz-tooltip-inner")
    tooltipInner.selectAll("*").remove()
    tooltipInner.append("div").text(DateTime.fromISO(d.data.date).toFormat('EEE, MMM d'))
    tooltipInner.append("div").text(DateTime.fromISO(d.data.date).toFormat('h:mm a') 
        + " - " + DateTime.fromISO(d.data.date).plus({ hours: 1 }).toFormat('h:mm a'))
    
    if (d.data.offPeak != 0 && d.data.offPeak != undefined) {
        tooltipInner.append("div")
            .attr("class", "viz-tooltip-text-line")
            .text(t("Off-peak") + " kWh: " + d.data.offPeak)
            
    }
    if (d.data.onPeak != 0 && d.data.onPeak != undefined) {
        tooltipInner.append("div")
            .attr("class", "viz-tooltip-text-line")
            .text(t("On-peak") + " kWh: " + d.data.onPeak)
    }
    if (d.data.shoulder != 0 && d.data.shoulder != undefined) {
        tooltipInner.append("div")
            .attr("class", "viz-tooltip-text-line")
            .text(t("Shoulder") + " kWh: " + d.data.shoulder)
    }
    if (d.data.superOffPeak != 0 && d.data.superOffPeak != undefined) {
        tooltipInner.append("div")
            .attr("class", "viz-tooltip-text-line")
            .text(t("Super off-peak") + " kWh: " + d.data.superOffPeak)
    }
    if (d.data.total != 0 && d.data.total != undefined) {
        tooltipInner.append("div")
            .attr("class", "viz-tooltip-text-line")
            .text(t("Total") + " kWh: " + d.data.total)
    }
   
}

function drawLegend(g, height, width, legendDisplayStates) {
    let legendContainer = d3.select('#dailyUsageLegendContainer')
    legendContainer
        .style("width", width + "px")
    if (legendDisplayStates.hasSuperOff) {
        d3.select('#superOffLegendItem')
            .attr("class", "usage-chart-legend-item-show")
    }
    if (legendDisplayStates.hasOffPeak) {
        d3.select('#offPeakLegendItem')
            .attr('class', "usage-chart-legend-item-show")
    }
    if (legendDisplayStates.hasShoulder) {
        d3.select('#shoulderLegendItem')
            .attr("class", "usage-chart-legend-item-show")
    }
    if (legendDisplayStates.hasOnPeak) {
        d3.select('#onPeakLegendItem')
            .attr("class", "usage-chart-legend-item-show")
    }
    if (legendDisplayStates.hasTotal && legendDisplayStates.isCost) {
        d3.select('#totalLegendItemCost')
            .attr("class", "usage-chart-legend-item-show")
    }
    if (legendDisplayStates.hasTotal && !legendDisplayStates.isCost) {
        d3.select('#totalLegendItem')
            .attr("class", "usage-chart-legend-item-show")
    }
}


function getLegendDisplayStates(adjustedData,isCost){
    let hasOnPeak = false,
    hasSuperOff = false,
    hasOffPeak = false,
    hasShoulder = false,
    hasTotal = false

    adjustedData.forEach(function (element) {
        hasOnPeak = element.onPeak != 0 ? true : hasOnPeak
        hasOffPeak = element.offPeak != 0 ? true : hasOffPeak
        hasShoulder = element.shoulder != 0 ? true : hasShoulder
        hasSuperOff = element.superOffPeak != 0 ? true : hasSuperOff
        hasTotal = element.total != 0 ? true : hasTotal
    })
    return {hasOnPeak: hasOnPeak,
        hasSuperOff: hasSuperOff,
        hasOffPeak: hasOffPeak ,
        hasShoulder: hasShoulder,
        hasTotal: hasTotal,
        isCost: isCost
    }
}