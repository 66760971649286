import cookies from 'js-cookie'
// NOTE: Please use i18n.js for localization
import i18n from '../i18n'

const localization = {
    getNavigatorLanguage,
    getCookieLanguage,
    setCookieLanguage,
    getPreferredLanguage,
    setCookieNewFeature,
    getCookieNewFeature
}

function getNavigatorLanguage() {
    if (navigator.languages && navigator.languages.length) {
        if (navigator.languages.length > 1) {
            for (let i = 0; i < navigator.languages.length; i++) {

                // only use the first two characters of language. Covers all versions of English and Spanish
                let lang = navigator.languages[i].substring(0,2)
                if (lang === 'en' || lang === 'es') {
                    return lang
                }
            }

            // if you get here for some reason, return english as the default
            return 'en'
        } else {
            return navigator.languages[0]
        }

      } else {
        return navigator.userLanguage || navigator.language || navigator.browserLanguage || 'en'
      }
}

function getCookieLanguage() {
    return i18n.language
}

function setCookieLanguage(preferredLanguage) {
    if (preferredLanguage !== 'en' && preferredLanguage !== 'es') {
        console.error(`Language not set: ${preferredLanguage} is not en nor es.`)
        return
    }

    i18n.changeLanguage(preferredLanguage)
}

function getPreferredLanguage(location) {
    // querystring > cookie > en
    if (location === null || location === undefined) {
        return getCookieLanguage()
    }

    let params = new URLSearchParams(location.search)
    let preferredLanguage = params.get('lang') || getCookieLanguage()

    return preferredLanguage
}

function setCookieNewFeature(newFeature){
    cookies.set(newFeature, true)
}

function getCookieNewFeature(newFeature) {
    const newFeatureCookie = cookies.get(newFeature)

    if (typeof newFeatureCookie === 'undefined')
        return false

    return newFeatureCookie
}

export default localization
