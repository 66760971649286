import { RSAA } from 'redux-api-middleware'

import { giftPaymentBankInfoConstants } from '../../../constants/gift/gift-payment-bank-info-constants'
import { giftPaymentListConstants } from '../../../constants/gift/gift-payment-list-constants'
import { giftPaymentApiConstants } from '../../../constants/gift/gift-payment-api-constants'

import utils from '../../../srp_modules/utils'

export const storeBankInfoCollected = ({bankFirstName, bankLastName, bankPhoneNumber, bankEmailAddress, bankRoutingNumber, bankAccountNumberLeadingZeroesAreSignificant, bankNickname}) => ({
    type: giftPaymentBankInfoConstants.GIFT_PAYMENT_STORE_BANK_INFO_COLLECTED,
    bankFirstName,
    bankLastName,
    bankPhoneNumber,
    bankEmailAddress,
    bankRoutingNumber,
    bankAccountNumberLeadingZeroesAreSignificant,
    bankNickname
})

export const clearBankInfo = () => ({
    type: giftPaymentBankInfoConstants.GIFT_PAYMENT_CLEAR_BANK_INFO
})

export const setBankFormOpen = () => ({
    type: giftPaymentBankInfoConstants.GIFT_PAYMENT_SET_BANK_FORM_OPEN
})

export const clearBankFormOpen = () => ({
    type: giftPaymentBankInfoConstants.GIFT_PAYMENT_CLEAR_BANK_FORM_OPEN
})

export const setBankIdForGiftPayment = ({selectedBankId}) => ({
    type: giftPaymentBankInfoConstants.SET_BANK_ID_FOR_GIFT_PAYMENT,
    selectedBankId
})

export const addBankAccount = () => (dispatch, getState) => {
    const state = getState()
    const giftPaymentBankInfo = state.giftPaymentBankInfo

    let billAccountList = state.accountInfo.billAccount.billAccountList
    let allCommercial = billAccountList?.every(b => b.isCommercial) ?? false

    let parameters = {
        firstNameOnBankAccount: giftPaymentBankInfo.bankFirstName,
        lastNameOnBankAccount: giftPaymentBankInfo.bankLastName,
        phoneNumber: giftPaymentBankInfo.bankPhoneNumber,
        emailAddress: giftPaymentBankInfo.bankEmailAddress,
        bankRoutingNumber: giftPaymentBankInfo.bankRoutingNumber,
        bankAccountNumberLeadingZeroesAreSignificant: giftPaymentBankInfo.bankAccountNumberLeadingZeroesAreSignificant,
        nicknameForBankAccount: giftPaymentBankInfo.bankNickname,
        makeDefaultBankAccount: false,
        sendCommercialConfirmationEmail: allCommercial
    }

    return dispatch({
        [RSAA]: {
            endpoint: utils.createUrl('bankaccount/addAndEmailConfirmation'),
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(parameters),
            headers: { 'Content-Type': 'application/json' },
            types: [
                giftPaymentApiConstants.GIFT_PAGE_ADD_BANK_ACCOUNT_REQUEST,
                giftPaymentApiConstants.GIFT_PAGE_ADD_BANK_ACCOUNT_SUCCESS,
                giftPaymentApiConstants.GIFT_PAGE_ADD_BANK_ACCOUNT_FAILURE
            ]
        },
        meta: {
            debounce: {
                leading: false,
                trailing: false
            }
        }
    })    
}

export const addPaymentToList = ({srpAccountNumber, paymentAmount}) => ({
    type: giftPaymentListConstants.ADD_GIFT_PAYMENT_TO_LIST,
    srpAccountNumber,
    paymentAmount
})

export const storePaymentList = (paymentList) => ({
    type: giftPaymentListConstants.STORE_GIFT_PAYMENT_LIST,
    paymentList
})

export const clearPaymentList = () => ({
    type: giftPaymentListConstants.CLEAR_GIFT_PAYMENT_LIST
})

export const setFormStartEventEmitted = () => ({
    type: giftPaymentListConstants.SET_FORM_START_EVENT_EMITTED
})

export const clearFormStartEventEmitted = () => ({
    type: giftPaymentListConstants.CLEAR_FORM_START_EVENT_EMITTED
})

export const enableMultiplePayments = () => ({
    type: giftPaymentListConstants.ENABLE_MULTIPLE_GIFT_PAYMENTS
})

export const disableMultiplePayments = () => ({
    type: giftPaymentListConstants.DISABLE_MULTIPLE_GIFT_PAYMENTS
})

export const verifyPaymentList = () => (dispatch, getState) => {
    const state = getState()
    const giftPaymentBankInfo = state.giftPaymentBankInfo
    const giftPaymentList = state.giftPaymentList.paymentList

    const selectedBankId = giftPaymentBankInfo.selectedBankId
    let selectedBank = state.bankAccounts.bankAccountList.find(ba => ba.bankId === selectedBankId)
    const bankAccountIdToken = (selectedBank === undefined ? '' : selectedBank.bankIdToken)

    let parameters = {
        paymentList: giftPaymentList.map(p => ({billAccountNumber: +p.srpAccountNumber, paymentAmount: p.paymentAmount})),
        bankAccountIdToken: bankAccountIdToken
    }

    return dispatch({
        [RSAA]: {
            endpoint: utils.createUrl('giftpayments/verifypaymentlist'),
            method: 'POST',
            body: JSON.stringify(parameters),
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include',
            types: [
                giftPaymentApiConstants.VERIFY_GIFT_PAYMENT_LIST_REQUEST,
                giftPaymentApiConstants.VERIFY_GIFT_PAYMENT_LIST_SUCCESS,
                giftPaymentApiConstants.VERIFY_GIFT_PAYMENT_LIST_FAILURE
            ]
        },
        meta: {
            debounce: {
                leading: false,
                trailing: false
            }
        }
    })
}

export const submitPaymentList = () => (dispatch, getState) => {
    const state = getState()

    const selectedBankId = state.giftPaymentBankInfo.selectedBankId
    let selectedBank = state.bankAccounts.bankAccountList.find(ba => ba.bankId === selectedBankId)
    if (selectedBank === undefined) {
        selectedBank = {
            bankIdToken: '',
            firstName: '',
            lastName: '',
            email: '',
            accountNumber: ''
        }
    }

    let parameters = {
        paymentList: state.giftPaymentList.paymentList.map(p => ({billAccountNumber: +p.srpAccountNumber, paymentAmount: p.paymentAmount})),
        bankAccountIdToken: selectedBank.bankIdToken,
        bankSignerFirstNameForConfirmationEmail: selectedBank.firstName,
        bankSignerLastNameForConfirmationEmail: selectedBank.lastName,
        bankSignerEmailAddressForConfirmationEmail: selectedBank.email,
        bankAccountNumberTruncatedForConfirmationEmail: selectedBank.accountNumber,
        referenceNumberFromValidatePayment: state.giftPaymentApiResponse.referenceNumber
    }

    return dispatch({
        [RSAA]: {
            endpoint: utils.createUrl('giftpayments/submitpaymentlist_emailconfirmation'),
            method: 'POST',
            body: JSON.stringify(parameters),
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include',
            types: [
                giftPaymentApiConstants.SUBMIT_GIFT_PAYMENT_LIST_REQUEST,
                giftPaymentApiConstants.SUBMIT_GIFT_PAYMENT_LIST_SUCCESS,
                giftPaymentApiConstants.SUBMIT_GIFT_PAYMENT_LIST_FAILURE
            ]
        }
    })    
}

export const submitPaymentListFailure = () => ({
    type: giftPaymentApiConstants.SUBMIT_GIFT_PAYMENT_LIST_FAILURE
})

export const clearGiftPaymentSubmitted = () => ({
    type: giftPaymentApiConstants.CLEAR_GIFT_PAYMENT_SUBMITTED
})