import React from "react"
import PropTypes from "prop-types"
import Card from "@mui/material/Card"
import { withTranslation } from 'react-i18next'

const IneligibleCashPaymentCard = ({t}) => {
    return (
        <div>
            <label className="h5">{t("My cash payment")}</label>
            <Card className="p-4">
                <div className="srp-alert-error">
                    {t("This account is not eligible to make cash payments at retail locations")}
                </div>
            </Card>
        </div>
    )
}

IneligibleCashPaymentCard.propTypes = {
    t: PropTypes.func.isRequired
}

export default withTranslation('cashPaymentCard')(IneligibleCashPaymentCard)
