import { billAccountConstants } from '../../../constants/bill-account-constants'
import { budgetBillingConstants } from '../../../constants/budget-billing-constants'
import * as loadingStatus from '../../../constants/loading-status-constants'

const initialState = {
    activeStep: 0,

    accountDetailsForAnonymous: {
        billAccount: '',
        firstName: '',
        middleInitial: '',
        lastName: '',
        serviceAddressLineOne: '',
        serviceAddressLineTwo: '',
        city: '',
        zipCode: '',
        email: '',
        phone: ''
    },

    squareFootage: "0-800",
    electricType: "",
    hasPool: false,
    hasEvapCooler: false,

    submitBudgetBillingStatus: loadingStatus.LOADING_STATUS_INIT
}

export default function budgetBillingReducer(state = initialState, action) {
    switch (action.type) {
        case budgetBillingConstants.SET_ACTIVE_STEP:
            return { ...state, activeStep: action.activeStep }

        case budgetBillingConstants.SET_ACCOUNT_DETAILS:
            return { ...state, accountDetailsForAnonymous: action.accountDetails }

        case budgetBillingConstants.SET_SQUARE_FOOTAGE:
            return { ...state, squareFootage: action.squareFootage }
        case budgetBillingConstants.SET_ELECTRIC_TYPE:
            return { ...state, electricType: action.electricType }
        case budgetBillingConstants.SELECT_POOL:
            return { ...state, hasPool: action.hasPool }
        case budgetBillingConstants.SELECT_EVAP_COOLER:
            return { ...state, hasEvapCooler: action.hasEvapCooler }

        case budgetBillingConstants.SUBMIT_BUDGET_BILLING_REQUEST:
            return { ...state, submitBudgetBillingStatus: loadingStatus.LOADING_STATUS_IN_PROGRESS }
        case budgetBillingConstants.SUBMIT_BUDGET_BILLING_SUCCESS:
            return (action.payload === true)
                ? { ...state, submitBudgetBillingStatus: loadingStatus.LOADING_STATUS_SUCCESS }
                : { ...state, submitBudgetBillingStatus: loadingStatus.LOADING_STATUS_FAILURE }
        case budgetBillingConstants.SUBMIT_BUDGET_BILLING_FAILURE:
            return { ...state, submitBudgetBillingStatus: loadingStatus.LOADING_STATUS_FAILURE }

        case billAccountConstants.SELECT_BILL_ACCOUNT_SUCCESS:
            return { ...initialState }
        default:
            return state
    }
}