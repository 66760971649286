import React from 'react'
import PropTypes from 'prop-types'
import MenuItem from '@mui/material/MenuItem'
import TextField from '@mui/material/TextField'

const SelectorInput = props => {
    let isError = props.touched[props.id] && Object.prototype.hasOwnProperty.call(props.errors, props.id)
    let errMsg = isError ? props.errors[props.id] : ''
    let meta = {
        id: props.id,
        name: props.id,
        helperText: errMsg,
        error: isError
    }

    let selectProps = {
        displayEmpty: props.displayEmpty
    }

    return (
        <div>
            <TextField
                {...meta}
                value={props.value}
                label={props.label}
                select
                fullWidth={true}
                SelectProps={selectProps}
                onChange={props.onChange}
                onBlur={props.onBlur}
            >
                {props.optionList.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.text}
                  </MenuItem>
                ))}
              </TextField>
        </div>
    )
}

SelectorInput.propTypes = {
    touched: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    optionList: PropTypes.arrayOf(PropTypes.object).isRequired,
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired,
    displayEmpty: PropTypes.bool.isRequired
}

export default SelectorInput
