import { singlePurchaseApiConstants } from '../../../constants/single-purchase/single-purchase-api-constants'

const initialState = {
    referenceNumber: 0,
    confirmationEmailSent: false,
    isSubmittingPurchase: false,
    errorSubmittingPurchase: false,
    purchaseSubmitted: false
}

export default function singlePurchaseApiResponseReducer(state = initialState, action) {
    switch (action.type) {
        case singlePurchaseApiConstants.VERIFY_SINGLE_PURCHASE_LIST_REQUEST:
            return state
        case singlePurchaseApiConstants.VERIFY_SINGLE_PURCHASE_LIST_SUCCESS:
            return { ...state, referenceNumber: action.payload.referenceNumberWhenNoError, errorSubmittingPurchase: false }
        case singlePurchaseApiConstants.VERIFY_SINGLE_PURCHASE_LIST_FAILURE:
            return { ...state, referenceNumber: 0 }

        case singlePurchaseApiConstants.SUBMIT_SINGLE_PURCHASE_LIST_REQUEST:
            return { ...state, purchaseSubmitted: false, errorSubmittingPurchase: false, confirmationEmailSent: false, isSubmittingPurchase: true }
        case singlePurchaseApiConstants.SUBMIT_SINGLE_PURCHASE_LIST_SUCCESS:
            return { ...state,
                purchaseSubmitted: action.payload.referenceNumber > 0,
                errorSubmittingPurchase: action.payload.referenceNumber === 0,
                referenceNumber: action.payload.referenceNumber === 0 ? state.referenceNumber : action.payload.referenceNumber,
                confirmationEmailSent: action.payload.confirmationEmailSent,
                isSubmittingPurchase: false
            }
        case singlePurchaseApiConstants.SUBMIT_SINGLE_PURCHASE_LIST_FAILURE:
            return { ...state, purchaseSubmitted: false, errorSubmittingPurchase: true, confirmationEmailSent: false, isSubmittingPurchase: false }

        case singlePurchaseApiConstants.CLEAR_PURCHASE_SUBMITTED:
            return { ...state, purchaseSubmitted: false }

        default:
            return state
    }
}