import { solarChoiceConstants } from '../../../constants/solar-choice-constants'
import { RSAA } from 'redux-api-middleware'

import utils from '../../../srp_modules/utils'

export const submitSolarChoiceForm = (solarChoiceData) => {
    let url = utils.createUrl('forms/submitsolarchoicesignup')

    let stringData = JSON.stringify(solarChoiceData)

    return dispatch => {
        return dispatch({
            [RSAA]: {
                endpoint: url,
                body: stringData,
                method: 'POST',
                credentials: 'include',
                headers: { 'Content-Type': 'application/json' },
                types: [
                    solarChoiceConstants.SUBMIT_SOLAR_CHOICE_FORM_REQUEST,
                    solarChoiceConstants.SUBMIT_SOLAR_CHOICE_FORM_SUCCESS,
                    solarChoiceConstants.SUBMIT_SOLAR_CHOICE_FORM_FAILURE,
                ]
            },
            meta: {
                debounce: {
                    leading: false,
                    trailing: false
                }
            }
        })
    }
}