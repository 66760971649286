import React from 'react'
import PropTypes from 'prop-types'

import Card from "@mui/material/Card"
import CheckDiagram from '../../images/payment/check-diagram.png'
import CheckDiagramEs from '../../images/payment/check-diagram-es.png'
import * as loadingStatus from '../../constants/loading-status-constants'
import { myAccountConstants } from '../../constants/myaccount-constants'

import '../../styles/autopay-page.css'
import AccountInfoBarContainer from '../myaccount_header/info_bar/account-info-bar-container'
import AutopayStep from '../common_autopay/autopay-step'
import AutopaySrpAcctInfo from '../common_autopay/autopay-srp-acct-info'
import CallAnytime from '../myaccount_header/call-anytime'
import EnrollAutopayFormik from './enroll-autopay-formik'
import CardLoading from '../../components/dashboard_page/card-loading'

const EnrollAutopayPage = ({ autopayInfo, autopayInfoStatus, billAccount, selectBillAccountStatus, customerName, serviceAddress,
    isResidential, t, i18n }) => {

    const showSpanish = (i18n.language === "es")

    let helpPhoneNumber = isResidential
        ? showSpanish
            ? myAccountConstants.SPANISH_CUSTOMER_SERVICE_PHONE_NUMBER_FMTTD
            : myAccountConstants.RESIDENTIAL_CUSTOMER_SERVICE_PHONE_NUMBER_FMTTD
        : myAccountConstants.COMMERCIAL_CUSTOMER_SERVICE_PHONE_NUMBER_FMTTD

    return (
        <div>
            <div className="container py-3">
                <CallAnytime />

                <h3 className="mt-3">{t("Sign up for SurePay")}</h3>

                <AccountInfoBarContainer disableSelector={false} billAccount={billAccount} />

                <div className="row">
                    <div className="col-lg-6 mt-3">
                        <div>
                            <label className="h5">{t("We'll need some information first")}</label>
                            {autopayInfoStatus !== loadingStatus.LOADING_STATUS_SUCCESS && autopayInfoStatus !== loadingStatus.LOADING_STATUS_FAILURE
                                && selectBillAccountStatus !== loadingStatus.LOADING_STATUS_SUCCESS && selectBillAccountStatus !== loadingStatus.LOADING_STATUS_FAILURE
                                ? (<CardLoading title={t("SurePay")} />) :
                                (
                                    <Card className="px-4 pb-4">
                                        <AutopayStep activeStep={1} t={t} />

                                        <div className="srp-alert-notice">
                                            <b>{t("NOTE")}</b>{' '}
                                            {t("SurePay_will_go_into_effect")}
                                        </div>

                                        <AutopaySrpAcctInfo
                                            billAccount={billAccount}
                                            customerName={customerName}
                                            serviceAddress={serviceAddress}
                                            t={t}
                                        />

                                        <EnrollAutopayFormik
                                            autopayInfo={autopayInfo}
                                            t={t}
                                            i18n={i18n}
                                        />
                                    </Card>
                                )}
                        </div>
                    </div>

                    <div className="col-lg-6 mt-3">
                        <label className="h5">{t("Need help?")}</label>
                        <Card>
                            <div className="srp-alert-warn">
                                <div className="pt-2 pb-2">
                                    {isResidential
                                        ? t("For_questions_please_contact_SRP", {residentialOrCommercial: "Residential", supportNumber: helpPhoneNumber})
                                        : t("For_questions_please_contact_SRP_commercial", {supportNumber: helpPhoneNumber})
                                    }
                                </div>
                                <Card>
                                    <img src={showSpanish ? CheckDiagramEs : CheckDiagram} className="d-none d-lg-block img-fluid" />
                                </Card>
                            </div>
                        </Card>
                    </div>

                </div>
            </div>
        </div>
    )
}

EnrollAutopayPage.propTypes = {
    autopayInfo: PropTypes.object.isRequired,
    autopayInfoStatus: PropTypes.string.isRequired,
    billAccount: PropTypes.number.isRequired,
    selectBillAccountStatus: PropTypes.string.isRequired,
    customerName: PropTypes.string.isRequired,
    serviceAddress: PropTypes.shape({
        streetAddress: PropTypes.string.isRequired,
        cityStateZip: PropTypes.string.isRequired
    }).isRequired,
    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired,
    isResidential: PropTypes.bool.isRequired
}

export default EnrollAutopayPage
