/*eslint no-unused-vars: "warn"*/
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { enrollAutopay, updateAutopay } from '../../actions/auth/payment/autopay-actions'
import { withRouter } from '../../srp_modules/with-router'

import VerifyAutopayPage from './verify-autopay-page'

class VerifyAutopayPageContainer extends React.Component {
    constructor(props) {
        super(props)

        this.submit = this.submit.bind(this)
    }

    submit() {
        if(this.props.autopayInfo.isEnrolled) {
            this.props.actions.updateEnrollment(this.props.autopayInfo, this.props.autopayFormData, this.props.bankData)
        }
        else{
            this.props.actions.submitEnrollment(this.props.autopayInfo, this.props.autopayFormData, this.props.bankData)
        }
    }

    render() {
        return (
            <VerifyAutopayPage
                billAccount={this.props.autopayInfo.billAccount}
                customerName={this.props.autopayFormData.firstName + ' ' + this.props.autopayFormData.lastName}
                bankName={this.props.bankData.institutionName}
                routingNumber={this.props.autopayFormData.routingNumber}
                bankAccountNumber={this.props.autopayFormData.bankAccountNumber}
                emailAddress={this.props.autopayFormData.emailAddress}
                phoneNumber={this.props.autopayFormData.phoneNumber}
                shareAmount={this.props.autopayFormData.shareAmount}
                isResidential={this.props.autopayFormData.isResidential}
                onSubmitClick={this.submit}
                enrollAutopayStatus={this.props.enrollAutopayStatus}
                updateAutopayStatus={this.props.updateAutopayStatus}
                isEnrolled={this.props.autopayInfo.isEnrolled}
                isPendingRelease={this.props.autopayInfo.isPendingRelease}
                autopayInfoStatus={this.props.autopayInfoStatus}
                selectBillAccountStatus={this.props.selectBillAccountStatus}
            />
        )
    }
}

VerifyAutopayPageContainer.propTypes = {
    actions: PropTypes.object.isRequired,

    autopayInfo: PropTypes.object.isRequired,

    autopayFormData: PropTypes.object.isRequired,

    bankData: PropTypes.object.isRequired,

    enrollAutopayStatus: PropTypes.string.isRequired,

    updateAutopayStatus: PropTypes.string.isRequired,

    autopayInfoStatus: PropTypes.string.isRequired,

    selectBillAccountStatus: PropTypes.string.isRequired
}

const mapStateToProps = (state) => {
    return {
        autopayInfo: state.accountInfo.autopay.autopayInfo,

        autopayFormData: state.accountInfo.autopay.autopayFormData,

        bankData: state.accountInfo.autopay.bankData,

        enrollAutopayStatus: state.accountInfo.autopay.enrollAutopayStatus,

        updateAutopayStatus: state.accountInfo.autopay.updateAutopayStatus,

        selectBillAccountStatus: state.accountInfo.billAccount.selectBillAccountStatus,

        autopayInfoStatus: state.accountInfo.autopay.autopayInfoStatus
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        actions: {
            updateEnrollment: async (autopayInfo, autopayFormData, bankData) => {
                let updateAutopayResult = await dispatch(updateAutopay(autopayInfo, autopayFormData, bankData))
                if (updateAutopayResult.error === true || updateAutopayResult.payload === false) {
                    ownProps.router.navigate('/myaccount/error')
                }
                else {
                    ownProps.router.navigate('/myaccount/payment/autopay/confirm')
                }
            },
            submitEnrollment: async (autopayInfo, autopayFormData, bankData) => {
                let enrollAutopayResult = await dispatch(enrollAutopay(autopayInfo, autopayFormData, bankData))
                if (enrollAutopayResult.error === true || enrollAutopayResult.payload === false) {
                    ownProps.router.navigate('/myaccount/error')
                }
                else {
                    ownProps.router.navigate('/myaccount/payment/autopay/confirm')
                }
            }
        }
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(VerifyAutopayPageContainer))
