import React from 'react'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'

import CircularProgress from '@mui/material/CircularProgress'

import TextInput from '../../common_formik/text-input'
import BankAccountNumberInput from '../../common_formik/bank-account-number-input'
import EmailInput from '../../common_formik/email-input'
import PhoneNumberInput from '../../single_payment_page/payment_fields/phone-number-input'
import RoutingNumberInput from '../../common_formik/routing-number-input'
import BankRoutingNumberAccountNumberInfoIcon from '../../common_payment/bank-routing-number-account-number-info-icon'
import YesNoToggleInput from '../../common_formik/yes-no-toggle-input'

import * as loadingStatus from '../../../constants/loading-status-constants'
import { myAccountConstants } from '../../../constants/myaccount-constants'

const AddBankForm = (props) => {
    let navigate = useNavigate()

    let commonFormikProps = {
        onChange: props.handleChange,
        onBlur: props.handleBlur,
        errors: props.errors,
        touched: props.touched
    }
    let alternateFormikProps = {
        errors: props.errors,
        touched: props.touched
    }
    let verifyStatus = props.status || {}
    let onToggleChange = e => {
        let name = e.target.name;
        let value = e.target.checked;
        props.setFieldValue(name, value);
    }
    let onBankPhoneNumberChange = values => {
        let value = values.value;
        props.setFieldValue('bankPhoneNumber', value);
    }

    const validateAndSubmit = (event) => {
        event.preventDefault()
        event.stopPropagation()

        props.validateForm().then(() => {
            if (props.isValid)
                props.submitForm()
            else {
                // get the names of the fields with validation errors
                const fieldsWithErrors = Object.keys(props.errors)
                // set those fields as touched so error messages would display
                fieldsWithErrors.forEach(fieldName =>
                    props.setFieldTouched(fieldName, true /* isTouched */, false /* shouldValidate */))
            }
        })
    }

        let supportPhoneNumber = myAccountConstants.RESIDENTIAL_CUSTOMER_SERVICE_PHONE_NUMBER
        let supportPhoneNumberFormatted = myAccountConstants.RESIDENTIAL_CUSTOMER_SERVICE_PHONE_NUMBER_FMTTD
        if (props.selectedBillAccountDetails.isCommercial) {
            supportPhoneNumber = myAccountConstants.COMMERCIAL_CUSTOMER_SERVICE_PHONE_NUMBER
            supportPhoneNumberFormatted = myAccountConstants.COMMERCIAL_CUSTOMER_SERVICE_PHONE_NUMBER_FMTTD
        } else if (props.i18n.language === "es") {
            supportPhoneNumber = myAccountConstants.SPANISH_CUSTOMER_SERVICE_PHONE_NUMBER
            supportPhoneNumberFormatted = myAccountConstants.SPANISH_CUSTOMER_SERVICE_PHONE_NUMBER_FMTTD
        }

    return (
        <form onSubmit={validateAndSubmit} id="topOfAddBankForm">
                {verifyStatus.hasUnhandledError &&
                        <div className="srp-alert-error mb-3">
                            <span className="d-lg-block d-none">{props.t("We_are_unable_to_complete") + ' ' + supportPhoneNumberFormatted}.</span>
                            <span className="d-lg-none d-block">{props.t("We_are_unable_to_complete")} {<a href={"tel:"+supportPhoneNumber}>{supportPhoneNumberFormatted}</a>}.</span>
                    </div>
                }
                <div className="mb-3"><TextInput id="bankFirstName" label="First name" maxLength={30} value={props.values.bankFirstName} t={props.t} {...commonFormikProps} /></div>
                <div className="mb-3"><TextInput id="bankLastName" label="Last name" maxLength={30} value={props.values.bankLastName} t={props.t} {...commonFormikProps} /></div>
            <div className="mb-3">
                <PhoneNumberInput
                    id="bankPhoneNumber"
                    label="Phone number"
                    value={props.values.bankPhoneNumber}
                    onValueChange={onBankPhoneNumberChange}
                    onBlur={() => props.setFieldTouched("bankPhoneNumber", true)}
                        t={props.t}
                    {...alternateFormikProps}
                />
            </div>
                <div className="mb-3"><EmailInput id="bankEmailAddress" label="Email address" inputProps={{ maxLength: 50 }} value={props.values.bankEmailAddress} t={props.t} {...commonFormikProps} /></div>
            <div className="mb-3 position-relative">
                <RoutingNumberInput
                    id="bankRoutingNumber"
                    label="9 digit routing number"
                    value={props.values.bankRoutingNumber}
                    endAdornment={
                        <BankRoutingNumberAccountNumberInfoIcon
                            showRoutingNumberDescription={true}
                            showBankAccountNumberDescription={false}
                            className="d-lg-none" />
                    }
                        t={props.t}
                    {...commonFormikProps} />
            </div>
            <div className="mb-3 position-relative">
                <BankAccountNumberInput
                    id="bankAccountNumberLeadingZeroesAreSignificant"
                    label="Bank account number"
                    value={props.values.bankAccountNumberLeadingZeroesAreSignificant}
                    endAdornment={
                        <BankRoutingNumberAccountNumberInfoIcon
                            showRoutingNumberDescription={false}
                            showBankAccountNumberDescription={true}
                            className="d-lg-none" />
                    }
                        t={props.t}
                {...commonFormikProps} />
            </div>
                <div className="mb-3"><TextInput id="bankAccountNickname" label="Account nickname (optional)" maxLength={35} value={props.values.bankAccountNickname} t={props.t} {...commonFormikProps} /></div>
                <p className="pb-2 pl-1 mb-1 text-muted" style={{marginTop: "0", marginBottom: "0"}}>{props.t("Make this my default bank account:")}</p>
                <div className="pl-3 mb-1"><YesNoToggleInput id="isDefaultOnMyAcct" label="For My Account payments" value={props.values.isDefaultOnMyAcct} onChange={onToggleChange} t={props.t} i18n={props.i18n} /></div>
                <div className="pl-3"><YesNoToggleInput id="isDefaultWithIVR" label="For phone payments" value={props.values.isDefaultWithIVR} onChange={onToggleChange} t={props.t} i18n={props.i18n} /></div>
            <div className="d-flex flex-wrap justify-content-end mt-3">
                <button onClick={() => navigate(-1)} className="btn srp-btn btn-lightblue" type="button">{props.t("Cancel")}</button>
                <button className="btn srp-btn btn-green ml-2" type="submit" disabled={props.addBankAccountStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS}>
                    {props.addBankAccountStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS
                        ? <CircularProgress size={20} thickness={5} style={{color:'white'}} />
                            : props.t("Add bank account")
                    }
                </button>
            </div>
        </form>
    )
}

AddBankForm.propTypes = {
    handleChange: PropTypes.func.isRequired,
    handleBlur: PropTypes.func.isRequired,
    errors: PropTypes.object.isRequired,
    status: PropTypes.object,
    touched: PropTypes.object.isRequired,

    setFieldValue: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,

    isValid: PropTypes.bool.isRequired,
    validateForm: PropTypes.func.isRequired,
    submitForm: PropTypes.func.isRequired,

    selectedBillAccountDetails: PropTypes.object.isRequired,

    values: PropTypes.object.isRequired,
    setFieldTouched: PropTypes.func.isRequired,

    addBankAccountStatus: PropTypes.string.isRequired,

    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired,
}

export default AddBankForm