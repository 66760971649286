import React from 'react'
import PropTypes from 'prop-types'

import format from '../../../srp_modules/format'

const PaymentListDetailsMobile = ({paymentDate, paymentList, referenceNumber, t}) => (
    <div>
    <p className="mx-3 mt-3 mb-0">{t("Payment date")} {paymentDate}</p>
    <div className="pb-4">
    <ul className="list-group list-group-flush mx-3 my-0">
        {paymentList.map((p, paymentIndex) => (
        <li key={paymentIndex} className="list-group-item p-2 border-bottom">
            <div>{format.formatBillAccountNumber(p.srpAccountNumber)}</div>
            <small>
            {'$'+p.paymentAmount.toFixed(2) +
            ((referenceNumber || referenceNumber===0) ? ", " + t("Reference") + " #" + referenceNumber : "")
            }
            </small>
        </li>
        ))}
    </ul>
    </div>
    </div>
)

PaymentListDetailsMobile.propTypes = {
    paymentDate: PropTypes.string.isRequired,
    paymentList: PropTypes.arrayOf(PropTypes.shape({
        srpAccountNumber: PropTypes.string.isRequired,
        paymentAmount: PropTypes.number.isRequired,
    })),
    referenceNumber: PropTypes.number,
    t: PropTypes.func.isRequired,
}

export default PaymentListDetailsMobile