import React from 'react'
import PropTypes from 'prop-types'
import config from 'my-account-config'
import { DateTime } from 'luxon'
import { withTranslation } from 'react-i18next'
import InputLabel from '@mui/material/InputLabel'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { CustomLuxonDateAdapter } from '../../../srp_modules/custom-luxon-date-adapter'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'

import { formConstants } from '../../../constants/form-constants'

import ElectricVehicleInfo from './electric-vehicle-info'

import '../../../styles/price-plan.css'

import { formStartEvent } from '../../../srp_modules/analytics-events'
import { transferServiceConstants } from '../../../constants/transfer-service-constants'

import { pricePlanConstants } from '../../../constants/price-plan-constants'
import transferModule from '../../../srp_modules/forms/transfer-module'

const SelectDatesAndPlanResidential = ({ activeStep, validateDatesAndPlan, serviceAddress, currentRateCode, isMPower,
    currentSolarChoice, holidayList, endServiceDate, setEndServiceDateOnChange, setEndServiceDateTouched, endDateError, endDateErrorMessage,
    startServiceDate, setStartServiceDateOnChange, setStartServiceDateTouched, startDateError, startDateErrorMessage, selectedPricePlanResidential,
    selectPricePlanOnClick, selectedSolarChoice, selectSolarChoiceOnClick, errorMessage, t, i18n }) => {

    let errorMessageDiv = null
    if (errorMessage && errorMessage != '') {
        errorMessageDiv = (<div className="mb-3 w-100">
            <div className="srp-alert-error">{errorMessage}</div>
        </div>)
    }

    const [ formStartEventEmitted, setFormStartEventEmitted ] = React.useState(false)
    const [ startDateOpen, setStartDateOpen ] = React.useState(false)
    const [ endDateOpen, setEndDateOpen ] = React.useState(false)

    const emitFormStartEvent = () => {
        if (formStartEventEmitted === true)
            return

        const formName = transferServiceConstants.ADOBE_ANALYTICS_FORM_NAME
        const stepName = transferServiceConstants.getStepName(activeStep)
        formStartEvent(formName, stepName)

        setFormStartEventEmitted(true)
    }

    const trackSelectPricePlanOnClick = (pricePlan) => {
        selectPricePlanOnClick(pricePlan)
        emitFormStartEvent()
    }

    const trackSelectSolarChoiceOnClick = (solarChoice) => {
        selectSolarChoiceOnClick(solarChoice)
        emitFormStartEvent()
    }

    const minDate = transferModule.getMinDates()
    const maxDate = DateTime.now().setZone('MST').plus({ days: 60 })

    const isSpanish = (i18n.language === "es")
    const rateDescription = isSpanish ? currentRateCode.descriptionEs : currentRateCode.description

    return (
        <form
            className="mb-2 mt-2 text-muted"
            onSubmit={(event) => validateDatesAndPlan(event, endDateError, startDateError, activeStep)}>
            {errorMessageDiv}
            <InputLabel error={endDateError}>
                {`${t("Select a day to end service at")} `}
                <span className="text-capitalize font-weight-bold">{(serviceAddress).toLowerCase()}</span>
            </InputLabel>
            <LocalizationProvider dateAdapter={CustomLuxonDateAdapter} adapterLocale="en-US">
                <DatePicker
                    format="M/d/yyyy"
                    emptyLabel="mm/dd/yyyy"
                    shouldDisableDate={(d) => transferModule.disableDates(d, holidayList)}
                    minDate={minDate}
                    maxDate={maxDate}
                    value={endServiceDate}
                    open={endDateOpen}
                    onOpen={() => setEndDateOpen(true)}
                    onClose={() => {setEndDateOpen(false);setEndServiceDateTouched()}}
                    onChange={(value) => { setEndServiceDateOnChange(value); emitFormStartEvent() }}
                    //onFocus={() => setEndServiceDateTouched()}
                    slotProps={{
                        textField: {
                            fullWidth: true,
                            readOnly: true,
                            error: endDateError,
                            onClick: () => setEndDateOpen(true),
                            helperText: endDateError
                                ? <span style={{ fontSize :'16px'}}>{t(endDateErrorMessage)}</span>
                                : <span style={{ color: 'rgba(112, 112, 112, 0.537)', fontSize :'16px'}}>
                                    {t("remember_power_may_be_turned_off")}
                                </span>
                        }
                    }}
                    animateYearScrolling={false}
                    className="mr-4 chart-date-picker mb-4"
                    label={t("End date")}
                />
            </LocalizationProvider>
            <InputLabel error={startDateError}>
                {t("Select a day to start service at your new address")}
            </InputLabel>
            <LocalizationProvider dateAdapter={CustomLuxonDateAdapter} adapterLocale="en-US">
                <DatePicker
                    format="M/d/yyyy"
                    emptyLabel="mm/dd/yyyy"
                    shouldDisableDate={(d) => transferModule.disableDates(d, holidayList)}
                    minDate={minDate}
                    maxDate={DateTime.min(endServiceDate || maxDate, maxDate)}
                    value={startServiceDate}
                    open={startDateOpen}
                    onOpen={() => setStartDateOpen(true)}
                    onClose={() => {setStartDateOpen(false);setStartServiceDateTouched()}}
                    onChange={(value) => { setStartServiceDateOnChange(value); emitFormStartEvent() }}
                    //onFocus={() => setStartServiceDateTouched()}
                    error={startDateError}
                    slotProps={{
                        textField: {
                            fullWidth: true,
                            readOnly: true,
                            error: startDateError,
                            onClick: () => setStartDateOpen(true),
                            helperText: startDateError && <span style={{ fontSize :'16px'}}>{t(startDateErrorMessage)}</span>
                        }
                    }}
                    animateYearScrolling={false}
                    className="mr-4 chart-date-picker mb-4"
                    label={t("Start date")}
                />
            </LocalizationProvider>

            {isMPower
                ? <div className="mb-3">{t("remain_on_current_plan")}</div>
                : <div>
                    <h4 style={{ color: 'rgba(51, 51, 51, 0.537)' }}>{t("Select a new plan, or keep your current one")}</h4>
                    <div className="srp-alert-notice mb-3">
                        <span className="font-weight-bold mr-4">{t("CURRENT PRICE PLAN")}</span>
                        <span>
                            {currentSolarChoice.isEnrolled
                                ? `${rateDescription}, ${t("as well as")} ${(currentSolarChoice.optionValue * 100)}% ${t("SRP Solar Choice")}`
                                : rateDescription
                            }
                        </span>
                    </div>
                    <div className="mb-3">
                        <FormGroup>
                            <FormControlLabel
                                control={<Radio
                                    checked={selectedPricePlanResidential === pricePlanConstants.PRICE_PLAN_RATE_CODE_MAP.TOU}
                                    onChange={() => trackSelectPricePlanOnClick(pricePlanConstants.PRICE_PLAN_RATE_CODE_MAP.TOU)}
                                    value={pricePlanConstants.PRICE_PLAN_RATE_CODE_MAP.TOU}
                                    color="primary"
                                    name={pricePlanConstants.PRICE_PLAN_RATE_CODE_MAP.TOU.toString()}
                                />}
                                label={
                                    <div>
                                        <span className="font-weight-bold mui-radio-label-color">{`${t("tou_plan")}: `}</span>
                                        <span className="mui-radio-label-color">{`${t("tou_plan_description")} `}</span>
                                        <a className="displayAsLink" target="_blank"
                                           href={`${config.srpnetBaseUrl}price-plans/residential-electric/time-of-use`}>{t("Learn more")} »</a>
                                    </div>
                                }
                            />
                            {currentRateCode.rateCode === pricePlanConstants.PRICE_PLAN_RATE_CODE_MAP.EZ3_2_5
                                ? <FormControlLabel
                                    control={<Radio
                                        checked={selectedPricePlanResidential === pricePlanConstants.PRICE_PLAN_RATE_CODE_MAP.EZ3_2_5}
                                        onChange={() => trackSelectPricePlanOnClick(pricePlanConstants.PRICE_PLAN_RATE_CODE_MAP.EZ3_2_5)}
                                        value={pricePlanConstants.PRICE_PLAN_RATE_CODE_MAP.EZ3_2_5}
                                        color="primary"
                                        name={pricePlanConstants.PRICE_PLAN_RATE_CODE_MAP.EZ3_2_5.toString()}
                                    />}
                                    label={
                                        <div>
                                            <span className="font-weight-bold mui-radio-label-color">{`${t("EZ-3 Time of Day Plan (2-5 p.m.)")}: `}</span>
                                            <span className="mui-radio-label-color">{`${t("minimize_your_usage")} `}</span>
                                            <span className="font-italic">{`(${t("You are eligible to remain on this discontinued plan")})`}</span>
                                        </div>
                                    }
                                />
                                : null
                            }
                            <FormControlLabel
                                control={<Radio
                                    checked={selectedPricePlanResidential === pricePlanConstants.PRICE_PLAN_RATE_CODE_MAP.EZ3}
                                    onChange={() => trackSelectPricePlanOnClick(pricePlanConstants.PRICE_PLAN_RATE_CODE_MAP.EZ3)}
                                    value={pricePlanConstants.PRICE_PLAN_RATE_CODE_MAP.EZ3}
                                    color="primary"
                                    name={pricePlanConstants.PRICE_PLAN_RATE_CODE_MAP.EZ3.toString()}
                                />}
                                label={
                                    <div>
                                        <span className="font-weight-bold mui-radio-label-color">{`${t("ez3_3_6_plan")}: `}</span>
                                        <span className="mui-radio-label-color">{`${t("ez3_3_6_description")} `}</span>
                                        <a className="displayAsLink" target="_blank"
                                           href={`${config.srpnetBaseUrl}price-plans/residential-electric/ez-3`}>{t("Learn more")} »</a>
                                    </div>
                                }
                            />
                            <FormControlLabel
                                control={<Radio
                                    checked={selectedPricePlanResidential === pricePlanConstants.PRICE_PLAN_RATE_CODE_MAP.EZ3_4_7}
                                    onChange={() => trackSelectPricePlanOnClick(pricePlanConstants.PRICE_PLAN_RATE_CODE_MAP.EZ3_4_7)}
                                    value={pricePlanConstants.PRICE_PLAN_RATE_CODE_MAP.EZ3_4_7}
                                    color="primary"
                                    name={pricePlanConstants.PRICE_PLAN_RATE_CODE_MAP.EZ3_4_7.toString()}
                                />}
                                label={
                                    <div>
                                        <span className="font-weight-bold mui-radio-label-color">{`${t("ez3_4_7_plan")}: `}</span>
                                        <span className="mui-radio-label-color">{`${t("ez3_4_7_description")} `}</span>
                                        <a className="displayAsLink" target="_blank"
                                           href={`${config.srpnetBaseUrl}price-plans/residential-electric/ez-3`}>{t("Learn more")} »</a>
                                    </div>
                                }
                            />
                            <FormControlLabel
                                control={<Radio
                                    checked={selectedPricePlanResidential === pricePlanConstants.PRICE_PLAN_RATE_CODE_MAP.EV}
                                    onChange={() => trackSelectPricePlanOnClick(pricePlanConstants.PRICE_PLAN_RATE_CODE_MAP.EV)}
                                    value={pricePlanConstants.PRICE_PLAN_RATE_CODE_MAP.EV}
                                    color="primary"
                                    name={pricePlanConstants.PRICE_PLAN_RATE_CODE_MAP.EV.toString()}
                                />}
                                label={
                                    <div>
                                        <span className="font-weight-bold mui-radio-label-color">{`${t("ev_plan")}: `}</span>
                                        <span className="mui-radio-label-color">{`${t("ev_plan_description")} `}</span>
                                        <a className="displayAsLink" target="_blank"
                                           href={`${config.srpnetBaseUrl}price-plans/residential-electric/electric-vehicle`}>{t("Learn more")} »</a>
                                    </div>
                                }
                            />
                            <FormControlLabel
                                control={<Radio
                                    checked={selectedPricePlanResidential === pricePlanConstants.PRICE_PLAN_RATE_CODE_MAP.BASIC}
                                    onChange={() => trackSelectPricePlanOnClick(pricePlanConstants.PRICE_PLAN_RATE_CODE_MAP.BASIC)}
                                    value={pricePlanConstants.PRICE_PLAN_RATE_CODE_MAP.BASIC}
                                    color="primary"
                                    name={pricePlanConstants.PRICE_PLAN_RATE_CODE_MAP.BASIC.toString()}
                                />}
                                label={
                                    <div>
                                        <span className="font-weight-bold mui-radio-label-color">{`${t("basic_plan")}: `}</span>
                                        <span className="mui-radio-label-color">{`${t("basic_plan_description")} `}</span>
                                        <a className="displayAsLink" href={`${config.srpnetBaseUrl}price-plans/residential-electric/basic`} target="_blank">{t("Learn more")} »</a>
                                    </div>
                                }
                            />
                            <FormControlLabel
                                control={<Radio
                                    color="default"
                                    disabled
                                />}
                                label={
                                    <div>
                                        <div className="d-lg-block d-none mui-radio-label-color">
                                            <span className="font-weight-bold">{`${t("customer_generation_plan")}: `}</span>
                                            <span>
                                                {`${t("produce_your_own_energy")} `}
                                            </span>
                                            <a className="displayAsLink" href={`${config.srpnetBaseUrl}price-plans/residential-electric/solar/compare-solar-plans`} target="_blank">{t("Learn more")} »</a>
                                        </div>
                                        <div className="d-lg-none d-block mui-radio-label-color">
                                            <span className="font-weight-bold">{`${t("customer_generation_plan")}: `}</span>
                                            <span className="mr-2">
                                                {`${t("produce_your_own_energy_mobile")} `} <a href="tel:6022364448">(602) 236-4448</a> {` ${t("to enroll")}.`}
                                            </span>
                                            <a className="displayAsLink" href={`${config.srpnetBaseUrl}price-plans/residential-electric/solar/compare-solar-plans`} target="_blank">{t("Learn more")} »</a>
                                        </div>
                                    </div>
                                }
                            />
                            <FormControlLabel
                                control={<Radio
                                    checked={selectedPricePlanResidential === pricePlanConstants.PRICE_PLAN_RATE_CODE_MAP.MPOWER}
                                    onChange={() => trackSelectPricePlanOnClick(pricePlanConstants.PRICE_PLAN_RATE_CODE_MAP.MPOWER)}
                                    value={pricePlanConstants.PRICE_PLAN_RATE_CODE_MAP.MPOWER}
                                    color="default"
                                    disabled
                                />}
                                label={
                                    <div>
                                        <div className="d-lg-block d-none mui-radio-label-color">
                                            <span className="font-weight-bold">{`${t("mpower_plan")}: `}</span>
                                            <span>
                                                {`${t("Please call (602) 236-8888 to enroll.")} `}
                                            </span>
                                            <a className="displayAsLink" href={`${config.srpnetBaseUrl}price-plans/residential-electric/m-power`} target="_blank">{t("Learn more")} »</a>
                                        </div>
                                        <div className="d-lg-none d-block mui-radio-label-color">
                                            <span className="font-weight-bold">{`${t("mpower_plan")}: `}</span>
                                            <span className="mr-2">
                                                {`${t("Please call")} `} <a href="tel:6022368888">(602) 236-8888</a> {` ${t("to enroll")}.`}
                                            </span>
                                            <a className="displayAsLink" href={`${config.srpnetBaseUrl}price-plans/residential-electric/m-power`} target="_blank">{t("Learn more")} »</a>
                                        </div>
                                    </div>
                                }
                            />
                        </FormGroup>
                    </div>
                </div>
            }

            {selectedPricePlanResidential === pricePlanConstants.PRICE_PLAN_RATE_CODE_MAP.EV
                && currentRateCode.rateCode !== pricePlanConstants.PRICE_PLAN_RATE_CODE_MAP.EV
                && <ElectricVehicleInfo emitFormStartEvent={emitFormStartEvent} />
            }

            {isMPower
                ? <div className="mb-4" />
                : <div className="mb-4 solar-choice-box">
                    <div className="solar-choice-details">
                        <div className="srp-olive-green mb-2" style={{ fontSize: '1.25rem' }}>
                            <span style={{ fontWeight: '700' }}>{`${t("SRP Solar Choice")} `}</span>
                            <span>({t("optional")})</span>
                        </div>
                        <div className="mb-4">
                            <span className="text-muted">{`${t("green_up_home_or_business")} `}</span>
                            <a className="displayAsLink" href={`${config.srpnetBaseUrl}account/electric/manage-bill/solar-choice-program`} target="_blank">{t("Learn more")} »</a>
                        </div>
                        <div className="text-muted mb-2">{t("Select one of the following")}:</div>
                        <RadioGroup
                            id="xferSolarChoicePlanSelector"
                            name="xferSolarChoicePlanSelector"
                            value={selectedSolarChoice}
                        >
                            <FormControlLabel
                                name="xfer.SolarChoice100"
                                value={formConstants.SOLARCHOICE_100}
                                control={
                                    <Radio
                                        color="primary"
                                        onClick={() => trackSelectSolarChoiceOnClick(pricePlanConstants.SOLAR_CHOICE.SC100)}
                                    />
                                }
                                style={{ marginBottom: '0' }}
                                label={<span className="checkmark-label-blue">{t("SRP Solar Choice Program")} (100%)</span>}
                            />
                            <FormControlLabel
                                name="xfer.SolarChoice50"
                                value={formConstants.SOLARCHOICE_50}
                                control={
                                    <Radio
                                        color="primary"
                                        onClick={() => trackSelectSolarChoiceOnClick(pricePlanConstants.SOLAR_CHOICE.SC50)}
                                    />
                                }
                                style={{ marginBottom: '0' }}
                                label={<span className="checkmark-label-blue">{t("SRP Solar Choice Program")} (50%)</span>}
                            />
                            {currentSolarChoice.isEnrolled &&
                                <FormControlLabel
                                    name="xfer.SolarChoiceUnenroll"
                                    value={formConstants.SOLARCHOICE_UNENROLL}
                                    control={
                                        <Radio
                                            color="primary"
                                            onClick={() => trackSelectSolarChoiceOnClick(pricePlanConstants.SOLAR_CHOICE.UNENROLL)}
                                        />
                                    }
                                    style={{ marginBottom: '0' }}
                                    label={<span className="checkmark-label-blue">{t("Unenroll")}</span>}
                                />
                            }
                        </RadioGroup>
                    </div>
                </div>
            }

            <div className="d-flex flex-wrap justify-content-end mt-4">
                <button className="btn srp-btn btn-blue" type="submit">{t("Next")}</button>
            </div>
        </form>

    )
}

SelectDatesAndPlanResidential.propTypes = {
    activeStep: PropTypes.number,
    validateDatesAndPlan: PropTypes.func,
    serviceAddress: PropTypes.string,
    currentRateCode: PropTypes.object,
    isMPower: PropTypes.bool,
    currentSolarChoice: PropTypes.object,

    holidayList: PropTypes.array,
    endServiceDate: PropTypes.object,
    setEndServiceDateOnChange: PropTypes.func,
    setEndServiceDateTouched: PropTypes.func,
    endDateError: PropTypes.bool,
    endDateErrorMessage: PropTypes.string,

    startServiceDate: PropTypes.object,
    setStartServiceDateOnChange: PropTypes.func,
    setStartServiceDateTouched: PropTypes.func,
    startDateError: PropTypes.bool,
    startDateErrorMessage: PropTypes.string,

    selectedPricePlanResidential: PropTypes.number,
    selectPricePlanOnClick: PropTypes.func,

    selectedSolarChoice: PropTypes.string,
    selectSolarChoiceOnClick: PropTypes.func,

    errorMessage: PropTypes.string,

    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired,
}

export default withTranslation('transferService')(SelectDatesAndPlanResidential)