import React from 'react'
import { withTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import Checkbox from '@mui/material/Checkbox'

const PayAmountOwedToSRPCheckboxPure = ({ checked, onChange, amountOwedToSRP, t }) => {
    let meta = {
        id: 'payAmountOwedToSRP',
        name: 'payAmountOwedToSRP',
        color: 'primary'
    }

    return (
        amountOwedToSRP > 0 &&
        <div className="d-flex flex-row align-items-center">
            <Checkbox {...meta} checked={checked} onChange={onChange} />
            <label htmlFor={meta.id} className="mb-0">
                {t("I_would_like_to_apply_the_total_purchase")} <b> {`${t("current balance")} ${amountOwedToSRP.toLocaleString('EN-US', { style: 'currency', currency: 'USD' })}`}</b>
            </label>
        </div>
    )
}

PayAmountOwedToSRPCheckboxPure.propTypes = {
    checked: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    amountOwedToSRP: PropTypes.number.isRequired,

    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired
}

const PayAmountOwedToSRPCheckbox = withTranslation("singlePurchasePage")(PayAmountOwedToSRPCheckboxPure)
export { PayAmountOwedToSRPCheckbox as default, PayAmountOwedToSRPCheckboxPure }