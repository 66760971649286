import React from "react"
import PropTypes from 'prop-types'

import { useNavigate } from 'react-router-dom'
import { withTranslation } from 'react-i18next'

// export for unit testing
export const GeneralBreadcrumb = ({pageTitle, t}) => {
    let navigate = useNavigate()

    return (<div className="d-none d-md-block mb-4">
        <h5 className="d-flex align-items-center">
            <button className="displayAsLink" onClick={() => navigate(-1)}>{t("Previous page")}</button>
            <i className="material-icons" style={{ color: 'LightGray' }}>keyboard_arrow_right</i>
            <span className="text-muted">{pageTitle}</span>
        </h5>
    </div>)
}

GeneralBreadcrumb.propTypes = {
    pageTitle: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired,
    i18n: PropTypes.object.isRequired
}

export default withTranslation("breadCrumb")(GeneralBreadcrumb)