export const programConstants = {
    AUTOPAY: 'AUTOPAY',
    BUDGET_BILLING: 'BUDGET_BILLING',
    EARTHWISE_ENERGY: 'EARTHWISE_ENERGY',
    SOLAR_CHOICE: 'SOLAR_CHOICE',
    COMMUNITY_SOLAR: 'COMMUNITY_SOLAR',
    CUSTOM_DUE_DATE: 'CUSTOM_DUE_DATE',
    ENERGY_SCORECARD: 'ENERGY_SCORECARD',
    SHARE: 'SHARE',
    ECONOMIC_PRICE_PLAN: 'ECONOMIC_PRICE_PLAN',
    HOME_ENERGY_PROFILE: 'HOME_ENERGY_PROFILE',
    ENERGY_INSIGHTS: 'ENERGY_INSIGHTS',
    HEALTHY_FOREST: 'HEALTHY_FOREST',
    SOLAR_NONPROFIT: 'SOLAR_NONPROFIT',
    EV_COMMUNITY_CREDITS: "EV_COMMUNITY_CREDITS",
    SAFETY_NET: "SAFETY_NET"
}
