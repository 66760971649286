import React from 'react'
import PropTypes from 'prop-types'

import config from 'my-account-config'

import CircularProgress from '@mui/material/CircularProgress'

const EppApplication = ({eppAppState, envelopeAdjective, t}) => {
    if (envelopeAdjective !== "")
        envelopeAdjective = envelopeAdjective + " "

    return (
        <div className="row">
        <div className="col-lg-6 col-12 mt-4 mb-2">
        <div className="srp-card-body srp-card-details text-muted">
            {
            eppAppState==="OpeningEnvelope"
            ?
            <div>
            <div className="srp-dark-blue-1 font-24 font-weight-bold">
            {t("Preparing your document")}
            </div>
            <div>
            {t("This may take several seconds.")}
            </div>
            <div className="d-flex justify-content-center mt-3">
                <CircularProgress />
            </div>
            </div>
            :
            <div>
            <div className="srp-dark-blue-1 font-24 font-weight-bold">
            {
            eppAppState === "EnvelopeSigned"
            ? t("You're all set!")
            : eppAppState === "FinishLater"
            ? t("Finish whenever you're ready")
            : eppAppState === "RepLoginNotAllowed"
            ? t("Rep login not supported")
            : eppAppState === "PendingReview"
            ? t("Pending review")
            : t("Something went wrong")
            }
            </div>

            <div>
            {
            eppAppState === "EnvelopeSigned" ?
            t("Envelope_signed_and_submitted")
            : eppAppState === "FinishLater" ?
            t("completed_entries_have_been_saved")
            : eppAppState === "RepLoginNotAllowed" ?
            t("log_in_as_customer")
            : eppAppState === "PendingReview" ?
            t("pending_review")
            :
            t("issue_loading_document")
            }
            </div>

            {/* Rep login not supported message stays in English because only SRP reps will see it. */}
            <button className="btn srp-btn btn-lightblue mt-4" onClick={() => window.close()}>{eppAppState==="RepLoginNotAllowed" ? "Close Tab" : t("Close Tab")}</button>

            {!config.isProduction && envelopeAdjective &&
            <div className="srp-alert-notice mt-2">
                Document: <b>{envelopeAdjective}</b>
            </div>
            }
            </div>
            }
        </div>
        </div>
        </div>
    )
}

EppApplication.propTypes = {
    eppAppState: PropTypes.string.isRequired,
    envelopeAdjective: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired,
}

export default EppApplication