import React from 'react'
import PropTypes from 'prop-types'
import { Trans } from 'react-i18next'
import { DateTime } from 'luxon'
import '../../styles/landlord.css'
import { NumericFormat } from 'react-number-format'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableHead from '@mui/material/TableHead'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import Icon from '@mui/material/Icon'
import IconButton from '@mui/material/IconButton'
import utils from '../../srp_modules/utils'
import SrpTooltip from '../common_tooltip/srp-tooltip'

import ExportAndPaymentButtons from './export-and-payment-buttons'

const LandlordAgreementTable = ({ landlordDetails, landlordBillInfo, landlordMostRecentPayments, billAccountSet, switchToBillAccount,
    addLandlordBillAccount, isVacated, GetPropertyState, exportCallback, showTooltipForCRPCol, showTooltipForLTDCol, landlordTurnOnDateColRef, t, i18n }) => {

    let sortedLandlordDetails = [...landlordDetails.landlordProperties].sort((a, b) => { return utils.serviceAddressSort(a.serviceAddress, b.serviceAddress) })
    const showHistoricalDataDescription = sortedLandlordDetails.some(a => !a.billAccount)

    return (
        <React.Fragment>
            <div className="pb-4 greyBottomBorder">
            <div>
                <Trans i18nKey="Landlord_agreement_n_began_on_date" t={t}>
                    Landlord agreement <strong>{{landlordAgreementNumber: landlordDetails.landlordAgreementNumber}}</strong> began
                    on {{beginDate: DateTime.fromISO(landlordDetails.contractStartDate).toFormat('MMM d, yyyy')}}
                </Trans>
            </div>

            <div className="mt-4">{t("Number_of_accounts", {count: sortedLandlordDetails.length})}</div>
            {showHistoricalDataDescription && <div className="pt-2"><span className="text-danger">* </span>{t("No historical data available for bill accounts closed for more than two years.")}</div>}
            </div>

            <ExportAndPaymentButtons exportCallback={exportCallback} t={t} i18n={i18n} />

            <Table>
                <TableHead className="srp-table-head">
                    <TableRow>
                        <TableCell className="border-0 p-1" padding="none">{t("Property address")}</TableCell>
                        <TableCell className="border-0 p-1" padding="none">
                            <SrpTooltip
                                content={<span>{t("Current Responsible Party")}</span>}
                                disableHoverListener={!showTooltipForCRPCol}>
                                    <div>
                                        <div>{t("Current")}</div>
                                        <div>{t("Responsible")}</div>
                                        <div>{t("Party")}</div>
                                    </div>
                            </SrpTooltip>

                        </TableCell>
                        <TableCell className="border-0 p-1" padding="none">
                            <SrpTooltip
                                content={<span>{t("Landlord Turn on Date")}</span>}
                                disableHoverListener={!showTooltipForLTDCol}>
                                    <div>
                                        <div>{t("Landlord")}</div>
                                        <div
                                            className="ellipsis-overflow"
                                            id="landlordTurnOnDateColRef"
                                            ref={landlordTurnOnDateColRef}>
                                            {t("Turn on Date")}
                                        </div>
                                    </div>
                            </SrpTooltip>
                        </TableCell>
                        <TableCell className="border-0 p-1" padding="none">{t("Due date")}</TableCell>
                        <TableCell className="border-0 p-1" padding="none">{t("Amount due")}</TableCell>
                        <TableCell className="border-0 p-1" padding="none">{t("Last payment")}</TableCell>
                        <TableCell className="border-0 p-1" padding="none">{t("Amount paid")}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody className="srp-table-body">
                    {sortedLandlordDetails.map((a) => {
                        let billInfo = {
                            hasBillInfo: false, dueDate: undefined, balance: undefined,
                            hasMostRecentPayments: false, lastPaymentDate: undefined, amountPaid: undefined
                        }

                        if (a.billAccount) {
                            if (typeof landlordBillInfo[`${a.billAccount}`] !== "undefined") {
                                billInfo.hasBillInfo = landlordBillInfo[`${a.billAccount}`].isEmpty === false;
                                billInfo.dueDate = landlordBillInfo[`${a.billAccount}`].currentDueDate;
                                billInfo.balance = landlordBillInfo[`${a.billAccount}`].balance;

                                billInfo.hasMostRecentPayments = landlordMostRecentPayments[`${a.billAccount}`].isEmpty === false;
                                billInfo.lastPaymentDate = landlordMostRecentPayments[`${a.billAccount}`].lastPaymentDate;
                                billInfo.amountPaid = landlordMostRecentPayments[`${a.billAccount}`].amountPaid;
                            }
                        }

                        let serviceAddressText = null

                        if (!a.billAccount) {
                            serviceAddressText = (<div>{a.serviceAddress.streetAddress}<span className="text-danger"> *</span></div>)
                        }
                        else {
                            if (billAccountSet && billAccountSet.has(a.billAccount)) {
                                serviceAddressText = (<button className="displayAsLink" onClick={() => { switchToBillAccount(a.billAccount) }}>{a.serviceAddress.streetAddress}</button>)
                            }
                            else {
                                serviceAddressText = (
                                    <div className="text-nowrap">{a.serviceAddress.streetAddress}
                                        <IconButton
                                            color="primary"
                                            onClick={addLandlordBillAccount(a.billAccount, a.serviceAddress.streetAddress, t)}
                                            className="button-focus srp-icon-color ml-1 mr-1 align-middle">
                                            <Icon >add_circle_outline</Icon>
                                        </IconButton>
                                    </div>)
                            }
                        }
                        return (
                            <TableRow key={`${a.defaultSortKey}_landlord_row`}>
                                <TableCell className="p-1" padding="none"> {serviceAddressText} </TableCell>
                                <TableCell className="p-1" padding="none">{GetPropertyState(a)}</TableCell>
                                <TableCell className="p-1" padding="none">
                                    {isVacated(a) ? DateTime.fromISO(a.startDate).toFormat('MMM d, yyyy') : '--'}
                                </TableCell>
                                <TableCell className="p-1" padding="none">
                                    {billInfo.hasBillInfo && billInfo.balance > 0 ? DateTime.fromISO(billInfo.dueDate).toFormat('MMM d, yyyy') : '--'}
                                </TableCell>
                                <TableCell className="p-1" padding="none">
                                    {billInfo.balance > 0 ?
                                        (<NumericFormat
                                            value={billInfo.balance}
                                            valueIsNumericString={true}
                                            displayType="text"
                                            thousandSeparator={true}
                                            prefix={'$'}
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                        />) : '--'}
                                </TableCell>
                                <TableCell className="p-1" padding="none">
                                    {billInfo.hasMostRecentPayments ?
                                        DateTime.fromISO(billInfo.lastPaymentDate).toFormat('MMM d, yyyy') : '--'}
                                </TableCell>
                                <TableCell className="p-1" padding="none">
                                    {billInfo.hasMostRecentPayments ?
                                        <NumericFormat
                                            value={Math.abs(billInfo.amountPaid)}
                                            valueIsNumericString={true}
                                            displayType="text"
                                            thousandSeparator={true}
                                            prefix={'$'}
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                        /> : '--'}
                                </TableCell>
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
        </React.Fragment>
    )
}

LandlordAgreementTable.propTypes = {
    billAccountSet: PropTypes.object,
    landlordDetails: PropTypes.object.isRequired,
    landlordBillInfo: PropTypes.object.isRequired,
    landlordMostRecentPayments: PropTypes.object.isRequired,
    switchToBillAccount: PropTypes.func.isRequired,
    addLandlordBillAccount: PropTypes.func.isRequired,
    isVacated: PropTypes.func.isRequired,
    GetPropertyState: PropTypes.func.isRequired,
    exportCallback: PropTypes.func.isRequired,
    showTooltipForCRPCol: PropTypes.bool,
    showTooltipForLTDCol: PropTypes.bool,
    landlordTurnOnDateColRef: PropTypes.object,
    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired,
}

export default LandlordAgreementTable