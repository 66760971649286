import { solarNonprofitFormConstants } from '../../../constants/solar-nonprofit-constants'
import { paymentConstants } from '../../../constants/payment-constants'
import { billAccountConstants } from '../../../constants/bill-account-constants'
import * as loadingStatus from '../../../constants/loading-status-constants'
import solarNonprofittMapper from '../../../srp_modules/forms/solar-nonprofit-mapper'

const initialState = {
    solarNonprofitDonationAmt: solarNonprofitFormConstants.DONATION_AMT_NONE,
    submitSolarNonprofitFormResult:
    {
        success: false,
        signUpDateTime: "00:00",
        signUpDateDate: "01/01/0001"
    },
    submitSolarNonprofitFormStatus: loadingStatus.LOADING_STATUS_INIT
}

export default function solarNonprofitReducer(state = initialState, action) {
    switch (action.type) {
        case solarNonprofitFormConstants.SUBMIT_SOLAR_NONPROFIT_FORM_REQUEST:
            return { ...state, submitSolarNonprofitFormStatus: loadingStatus.LOADING_STATUS_IN_PROGRESS }
        case solarNonprofitFormConstants.SUBMIT_SOLAR_NONPROFIT_FORM_SUCCESS:
            return {
                ...state,
                submitSolarNonprofitFormResult: action.payload,
                submitSolarNonprofitFormStatus: action.payload.success ? loadingStatus.LOADING_STATUS_SUCCESS : loadingStatus.LOADING_STATUS_FAILURE
            }
        case solarNonprofitFormConstants.SUBMIT_SOLAR_NONPROFIT_FORM_FAILURE:
            return {
                ...state,
                submitSolarNonprofitFormResult: initialState.submitSolarNonprofitFormResult,
                submitSolarNonprofitFormStatus: loadingStatus.LOADING_STATUS_FAILURE
            }
        case paymentConstants.GET_ENROLLED_PROGRAMS_DETAILS_SUCCESS:
            return {
                ...state,
                solarNonprofitDonationAmt: action.payload.solarNonprofit.isEnrolled
                    ? solarNonprofittMapper.mapServerToDonationAmount(action.payload.solarNonprofit.optionValue)
                    : solarNonprofitFormConstants.DONATION_AMT_NONE
            }
        case billAccountConstants.SELECT_BILL_ACCOUNT_SUCCESS:
            return { ...initialState }
        default:
            return state
    }
}