import React from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'


const TransferServiceHelp = ({customerServicePhone, customerServicePhoneFmttd, t}) => {
    return (
        <div>
            <div className="srp-card-header">Need help?</div>
            <div className="srp-card-body" style={{ backgroundColor: '#f7dfd0' }}>
                <div className="srp-card-details">
                    <p className="d-lg-block d-none mb-0">
                        {`${t("for_questions_contact_srp")} ${customerServicePhoneFmttd},
                        ${t("available_24_hours")}.`}
                    </p>
                    <p className="d-lg-none d-block mb-0">
                        {`${t("for_questions_contact_srp")} `}
                        <a href={"tel:" + customerServicePhone}>
                            {customerServicePhoneFmttd}
                        </a>
                        {`, ${t("available_24_hours")}.`}
                    </p>
                </div>
            </div>
        </div>
    )
}

TransferServiceHelp.propTypes = {
    customerServicePhone: PropTypes.string.isRequired,
    customerServicePhoneFmttd: PropTypes.string.isRequired,
    t: PropTypes.func
}

export default withTranslation('transferService')(TransferServiceHelp)