import { creditAdvanceConstants } from '../../../constants/credit-advance-constants'
import { RSAA } from 'redux-api-middleware'
import utils from '../../../srp_modules/utils'

export const getMpowerSelfServiceAdvance = (billAccount) => {
    return dispatch => { 
        return dispatch({
            [RSAA]: {
                endpoint: utils.createUrl('selfcredit/getmpoweradvanceinfo', { billAccount }),
                method: 'GET',
                credentials: 'include',
                types: [
                    creditAdvanceConstants.GET_MPOWER_SELF_SERVICE_REQUEST,
                    creditAdvanceConstants.GET_MPOWER_SELF_SERVICE_SUCCESS,
                    creditAdvanceConstants.GET_MPOWER_SELF_SERVICE_FAILURE
                ]
            }
        })
    }
}

export const submitMpowerSelfServiceAdvanceRequest = (billAccount, advanceDataToken) => {
    return dispatch => { 
        return dispatch({
            [RSAA]: {
                endpoint: utils.createUrl('selfcredit/submitmpoweradvance', { billAccount, advanceDataToken }),
                method: 'POST',
                credentials: 'include',
                types: [
                    creditAdvanceConstants.SUBMIT_MPOWER_SELF_SERVICE_REQUEST,
                    creditAdvanceConstants.SUBMIT_MPOWER_SELF_SERVICE_SUCCESS,
                    creditAdvanceConstants.SUBMIT_MPOWER_SELF_SERVICE_FAILURE
                ]
            }
        })
    }
}

export const resetMpowerSelfServiceAdvance = () => ({
    type: creditAdvanceConstants.RESET_MPOWER_SELF_SERVICE
})