import React from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'

import { clearPurchaseList, clearBankInfo, clearPurchaseSubmitted, clearFormStartEventEmitted } from '../../../actions/auth/payment/single-purchase-actions'

import { getContacts } from '../../../actions/auth/contact/contact-actions'
import { withRouter } from '../../../srp_modules/with-router'

import * as loadingStatus from '../../../constants/loading-status-constants'

import SinglePurchasePage from './single-purchase-page'
import { getEnrolledProgramsDetails} from  '../../../actions/auth/payment/payment-actions'
class SinglePurchasePageContainer extends React.Component {
    constructor(props) {
        super(props)
    }

    componentDidMount() {
        if (this.props.purchaseSubmitted)
            this.props.clearState()
    }

    componentDidUpdate() {
        if (this.props.selectedBillAccount === 0)
            return

        if (this.props.primaryEmailStatus === loadingStatus.LOADING_STATUS_INIT)
            this.props.getContactsInfo(this.props.selectedBillAccount)

        if (this.props.enrolledProgramsDetailsStatus === loadingStatus.LOADING_STATUS_INIT)
            this.props.getEnrolledProgramsDetails(this.props.selectedBillAccount)
    }

    render() {
        return (
            !this.props.purchaseSubmitted &&
            <SinglePurchasePage
                bankFormOpen={this.props.bankFormOpen}
                isCashPayTab={this.props.router.location.pathname === "/myaccount/payment/single/mpower/cash"}
                isBarcodeEligible={this.props.isBarcodeEligible}
                isMpower={this.props.isMpower}
                isCashOnly={this.props.isCashOnly}
                hasLoaded={this.props.enrolledProgramsDetailsStatus === loadingStatus.LOADING_STATUS_SUCCESS}/>
        )
    }
}

const mapStateToProps = state => ({
    bankFormOpen: state.singlePurchaseBankInfo.bankFormOpen,
    purchaseSubmitted: state.singlePurchaseApiResponse.purchaseSubmitted,
    selectedBillAccount: state.accountInfo.billAccount.selectedBillAccount,
    primaryEmailStatus: state.accountInfo.contact.primaryEmailStatus,
    isBarcodeEligible: state.accountInfo.billAccount.selectedBillAccountDetails.isBarcodeEligible,
    enrolledProgramsDetailsStatus: state.accountInfo.payment.enrolledProgramsDetailsStatus,
    isCashOnly: state.accountInfo.payment.isCashOnly,
    isMpower: state.accountInfo.billAccount.selectedBillAccountDetails.isPrePay
})

const mapDispatchToProps = (dispatch) => ({
    getContactsInfo: (billAccount) => {
        dispatch(getContacts(billAccount))
    },
    getEnrolledProgramsDetails: async (billAccount) => {
        await dispatch(getEnrolledProgramsDetails(billAccount))
    },
    clearState: () => {
        dispatch(clearPurchaseList())
        dispatch(clearBankInfo())
        dispatch(clearFormStartEventEmitted())
        dispatch(clearPurchaseSubmitted())
    }
})

SinglePurchasePageContainer.propTypes = {
    bankFormOpen: PropTypes.bool.isRequired,
    purchaseSubmitted: PropTypes.bool.isRequired,
    clearState: PropTypes.func.isRequired,

    selectedBillAccount: PropTypes.number.isRequired,
    primaryEmailStatus: PropTypes.string.isRequired,
    getContactsInfo: PropTypes.func.isRequired,
    isBarcodeEligible: PropTypes.bool.isRequired,
    router: PropTypes.shape({
        location: PropTypes.object
    }),
    isCashOnly: PropTypes.bool,
    isMpower: PropTypes.bool,
    enrolledProgramsDetailsStatus: PropTypes.string,
    getEnrolledProgramsDetails: PropTypes.func
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SinglePurchasePageContainer))