import { usageConstants } from '../../../constants/usage-constants'
import { dailyUsageConstants } from '../../../constants/daily-usage-constants'
import { rateMetaDataConstants } from '../../../constants/rate-meta-data-constants'
import { dailyUsageChartTypeConstants } from '../../../constants/daily-usage-chart-type-constants'
import { hourlyUsageConstants } from '../../../constants/hourly-usage-constants'
import { accountHistoryConstants } from '../../../constants/account-history-constants'
import { usagePageChartCategoryConstants } from '../../../constants/usage-page-chart-category-constants'
import { DateTime } from 'luxon'

const solarAvailableCharts = [
    dailyUsageChartTypeConstants.NET_ENERGY,
    dailyUsageChartTypeConstants.GENERATION,
    dailyUsageChartTypeConstants.CONSUMPTION
]
const IDRAvailableCharts = [
    dailyUsageChartTypeConstants.USAGE,
    dailyUsageChartTypeConstants.GENERATION,
    dailyUsageChartTypeConstants.CONSUMPTION,
    dailyUsageChartTypeConstants.EXPORT
]
const costAvailableCharts = [
    dailyUsageChartTypeConstants.COST,
    dailyUsageChartTypeConstants.USAGE
]

const initialState = {
    selectedUsagePageChartTab: 1,
    usagePageChartCategory: usagePageChartCategoryConstants.DAILY,
    billStartDates: [],
    billEndDates: [],
    showHourlyCostChart: { display: 'none' },
    showHourlyUsageChart: { display: 'block' },
    showHourlyUsageVerbiage: { visibility: 'visible' },
    currentDailyChart: dailyUsageChartTypeConstants.COST,
    currentHourlyChart: dailyUsageChartTypeConstants.COST,
    currentMonthlyChart: dailyUsageChartTypeConstants.COST,
    dailyButtonClassNames: getButtonCssClasses(dailyUsageChartTypeConstants.COST),
    hourlyButtonClassNames: getButtonCssClasses(dailyUsageChartTypeConstants.COST),
    monthlyButtonClassNames: getButtonCssClasses(dailyUsageChartTypeConstants.COST),
    showDailyCostChart: true,
    showDailyUsageChart: false,
    showDailyNetEnergyChart: false,
    showDailyGenerationChart: false,
    showDailyDemandChart: false,
    showDailyExportChart: false,
    showDailyCostVerbiage: { display: 'block' },
    showDailyUsageVerbiage: { display: 'none' },
    forceHourlyRequest: false,
    showMonthlyCostChart: { display: 'none' },
    showMonthlyUsageChart: { display: 'block' },
    showMonthlyCostVerbiage: { display: 'none' },
    showMonthlyUsageVerbiage: { display: 'block' },
    dailyEndDate: DateTime.now().setZone('MST').startOf('day'),
    dailyStartDate: DateTime.now().setZone('MST').startOf('day').minus({ days: 30 }),
    hourlyStartDate: DateTime.now().setZone('MST').startOf('day'),
    hourlyEndDate: DateTime.now().setZone('MST').startOf('day'),
    monthlyStartDate: DateTime.now().setZone('MST').startOf('day'),
    monthlyEndDate: DateTime.now().setZone('MST').startOf('day'),
    dailyMinDate: DateTime.now().setZone('MST'),
    dailyMaxDate: DateTime.now().setZone('MST'),
    showHourlyDataTable: false,
    showDailyDataTable: false,
    showMonthlyDataTable: false,
    hasNet: false,
    hasCost: false
}

function setCurrentChart(currentChart, isNetBilled, hasCost, isSolar) {
    if (isNetBilled) {
        if (solarAvailableCharts.indexOf(currentChart) >= 0)
            return currentChart
        else
            return solarAvailableCharts[0]
    }
    if (isSolar && !isNetBilled) {
        if (IDRAvailableCharts.indexOf(currentChart) >= 0)
            return currentChart
        else
            return IDRAvailableCharts[0]
    }
    if (hasCost) {
        if (costAvailableCharts.indexOf(currentChart) >= 0)
            return currentChart
        else
            return costAvailableCharts[0]
    }
    return dailyUsageChartTypeConstants.USAGE
}
function getButtonCssClasses(currentChart) {
    let buttons = {
        costBtnClassName: "chart-type-btn button-focus",
        usageBtnClassName: "chart-type-btn button-focus",
        netBtnClassName: "chart-type-btn button-focus",
        generationBtnClassName: "chart-type-btn button-focus",
        demandBtnClassName: "chart-type-btn button-focus",
        exportBtnClassName: "chart-type-btn button-focus",
        consumptionBtnClassName: "chart-type-btn button-focus",
    }
    switch (currentChart) {
        case dailyUsageChartTypeConstants.COST:
            {
                buttons.costBtnClassName += " chart-type-btn-selected"
                break
            }
        case dailyUsageChartTypeConstants.USAGE:
            {
                buttons.usageBtnClassName += " chart-type-btn-selected"
                break
            }
        case dailyUsageChartTypeConstants.CONSUMPTION:
            {
                buttons.consumptionBtnClassName += " chart-type-btn-selected"
                break
            }
        case dailyUsageChartTypeConstants.DEMAND:
            {
                buttons.demandBtnClassName += " chart-type-btn-selected"
                break
            }
        case dailyUsageChartTypeConstants.NET_ENERGY:
            {
                buttons.netBtnClassName += " chart-type-btn-selected"
                break
            }
        case dailyUsageChartTypeConstants.GENERATION:
            {
                buttons.generationBtnClassName += " chart-type-btn-selected"
                break
            }
        case dailyUsageChartTypeConstants.EXPORT:
            {
                buttons.exportBtnClassName += " chart-type-btn-selected"
                break
            }
    }
    return buttons
}
export default function usageReducer(state = initialState, action) {
    switch (action.type) {
        case usageConstants.SET_USAGE_PAGE_FROM_DASH_BUTTONS:{
            return {
                ...state,
                selectedUsagePageChartTab: action.beginningTab,
                currentDailyChart: action.chartType,
                dailyButtonClassNames: getButtonCssClasses(action.chartType)
            }
        }
        case accountHistoryConstants.GET_ACCOUNT_HISTORY_REQUEST: {
            return {
                ...state,
                billStartDates: [],
                billEndDates: []
            }
        }
        case accountHistoryConstants.GET_ACCOUNT_HISTORY_FAILURE: {
            return {
                ...state,
                billStartDates: [],
                billEndDates: []
            }
        }
        case accountHistoryConstants.GET_ACCOUNT_HISTORY_SUCCESS: {
            let monthlyEndDates = fillEndDates(action.payload.accountHistoryList)
            return {
                ...state,
                billStartDates: monthlyEndDates,
                billEndDates: monthlyEndDates,
                monthlyStartDate: setStartDate(monthlyEndDates),
                monthlyEndDate: setEndDate(monthlyEndDates)
            }
        }
        case usageConstants.INIT_HOURLY_USAGE_PAGE:
            {
                return {
                    ...state,
                    hourlyEndDate: action.date,
                    hourlyStartDate: action.date,
                    currentHourlyChart: action.chartType,
                    selectedUsagePageChartTab: 0,
                    usagePageChartCategory: usagePageChartCategoryConstants.HOURLY,
                    forceHourlyRequest: true,
                    hourlyButtonClassNames: getButtonCssClasses(action.chartType)
                }

            }
        case usageConstants.INIT_DAILY_USAGE_PAGE:
            {
                return {
                    ...state,
                    dailyStartDate: action.startDate,
                    dailyEndDate: action.endDate,
                    currentDailyChart: action.chartType,
                    selectedUsagePageChartTab: 1,
                    usagePageChartCategory: usagePageChartCategoryConstants.DAILY,
                    dailyButtonClassNames: getButtonCssClasses(action.chartType)
                }
            }

        case rateMetaDataConstants.GET_RATE_META_DATA_SUCCESS:
            {
                let currentDailyChart = setCurrentChart(state.currentDailyChart,
                    action.payload.isNetBilled,
                    action.payload.hasCost || state.selectedUsagePageChartTab === 2,
                    action.payload.isSolar)
                let currentHourlyChart =
                    action.payload.isMPower
                        ? dailyUsageChartTypeConstants.USAGE
                        : setCurrentChart(state.currentHourlyChart,
                            action.payload.isNetBilled,
                            action.payload.hasCost || state.selectedUsagePageChartTab === 2,
                            action.payload.isSolar)
                let currentMonthlyChart = action.payload.isSolar && action.payload.isCommercial
                ?  dailyUsageChartTypeConstants.COST
                :  setCurrentChart(state.currentMonthlyChart,
                    action.payload.isNetBilled,
                    true,
                    action.payload.isSolar)
                return {
                    ...state,
                    showDailyCostVerbiage: action.payload.hasCost ? { display: 'block' } : { display: 'none' },
                    showDailyUsageVerbiage: action.payload.hasCost ? { display: 'none' } : { display: 'block' },
                    currentDailyChart: currentDailyChart,
                    currentHourlyChart: currentHourlyChart,
                    currentMonthlyChart: currentMonthlyChart,
                    hasCost: action.payload.hasCost,
                    hasNet: action.payload.isNetBilled,
                    dailyButtonClassNames: getButtonCssClasses(currentDailyChart),
                    hourlyButtonClassNames: getButtonCssClasses(currentHourlyChart),
                    monthlyButtonClassNames: getButtonCssClasses(currentMonthlyChart),
                    selectedUsagePageChartTab: action.payload.isNonSmartMeterRate ? 2 : state.selectedUsagePageChartTab
                }
            }
        case hourlyUsageConstants.GET_HOURLY_SUCCESS: {
            return {
                ...state,
                forceHourlyRequest: false
            }
        }
        case dailyUsageConstants.GET_DAILY_SUCCESS:
            {
                let dates = getStartAndEndDate(state.dailyStartDate, state.dailyEndDate, action.payload.dailyMinDate, action.payload.dailyMaxDate)
                return {
                    ...state,
                    dailyMinDate: DateTime.fromISO(action.payload.dailyMinDate).setZone('MST').startOf('day'),
                    dailyMaxDate: DateTime.fromISO(action.payload.dailyMaxDate).setZone('MST').startOf('day'),
                    dailyStartDate: dates.startDate,
                    hourlyStartDate: DateTime.fromISO(action.payload.dailyMaxDate).setZone('MST').startOf('day'),
                    hourlyEndDate: DateTime.fromISO(action.payload.dailyMaxDate).setZone('MST').startOf('day'),
                    dailyEndDate: dates.endDate
                }
            }
        case usageConstants.CHANGE_DATE_RANGE:
            {
                return {
                    ...state,
                    dailyStartDate: action.startDate,
                    dailyEndDate: action.endDate
                }
            }
        case usageConstants.CHANGE_HOURLY_DATE_RANGE:
            {
                return {
                    ...state,
                    hourlyStartDate: action.startDate,
                    hourlyEndDate: action.endDate
                }
            }
        case usageConstants.CHANGE_MONTHLY_DATE_RANGE:
            {
                return {
                    ...state,
                    monthlyStartDate: action.startDate,
                    monthlyEndDate: action.endDate
                }
            }
        case usageConstants.GET_USAGE_PAGE_CHART_TAB:
            switch (action.value) {
                case 0: {
                    let currentChart = setCurrentChart(state.currentHourlyChart, state.hasNet, state.hasCost,false)
                    return { ...state,
                        currentHourlyChart: currentChart,
                        selectedUsagePageChartTab: action.value,
                        usagePageChartCategory: usagePageChartCategoryConstants.HOURLY,
                        hourlyButtonClassNames: getButtonCssClasses(currentChart)
                    }
                }
                case 1: {
                    let currentChart = setCurrentChart(state.currentDailyChart, state.hasNet, state.hasCost,false)
                    return { ...state,
                        currentDailyChart: currentChart,
                        selectedUsagePageChartTab: action.value,
                        usagePageChartCategory: usagePageChartCategoryConstants.DAILY,
                        dailyButtonClassNames: getButtonCssClasses(currentChart)
                    }
                }
                case 2: {
                    return {
                        ...state,
                        selectedUsagePageChartTab: action.value,
                        usagePageChartCategory: usagePageChartCategoryConstants.MONTHLY
                    }
                }
            }
            break
        case usageConstants.GET_USAGE_HOURLY_CHART_TYPE:
            switch (action.chartType) {
                case dailyUsageChartTypeConstants.COST:
                    return {
                        ...state,
                        hourlyCostBtnClassName: "chart-type-btn-selected button-focus",
                        hourlyUsageBtnClassName: "chart-type-btn button-focus",
                        showHourlyCostChart: true,
                        showHourlyUsageChart: false,
                        showHourlyUsageVerbiage: { visibility: 'hidden' },
                        currentHourlyChart: dailyUsageChartTypeConstants.COST,
                        hourlyButtonClassNames: getButtonCssClasses(action.chartType)
                    }

                case dailyUsageChartTypeConstants.USAGE:
                    return {
                        ...state,
                        hourlyCostBtnClassName: "chart-type-btn button-focus",
                        hourlyUsageBtnClassName: "chart-type-btn-selected button-focus",
                        showHourlyCostChart: false,
                        showHourlyUsageChart: true,
                        showHourlyUsageVerbiage: { visibility: 'visible' },
                        currentHourlyChart: dailyUsageChartTypeConstants.USAGE,
                        hourlyButtonClassNames: getButtonCssClasses(action.chartType)
                    }
                case dailyUsageChartTypeConstants.GENERATION:
                    return {
                        ...state,
                        hourlyCostBtnClassName: "chart-type-btn button-focus",
                        hourlyUsageBtnClassName: "chart-type-btn-selected button-focus",
                        showHourlyCostChart: false,
                        showHourlyUsageChart: true,
                        showHourlyUsageVerbiage: { visibility: 'visible' },
                        currentHourlyChart: dailyUsageChartTypeConstants.GENERATION,
                        hourlyButtonClassNames: getButtonCssClasses(action.chartType)
                    }
                case dailyUsageChartTypeConstants.NET_ENERGY:
                    return {
                        ...state,
                        hourlyCostBtnClassName: "chart-type-btn button-focus",
                        hourlyUsageBtnClassName: "chart-type-btn-selected button-focus",
                        showHourlyCostChart: false,
                        showHourlyUsageChart: true,
                        showHourlyUsageVerbiage: { visibility: 'visible' },
                        currentHourlyChart: dailyUsageChartTypeConstants.NET_ENERGY,
                        hourlyButtonClassNames: getButtonCssClasses(action.chartType)
                    }
                case dailyUsageChartTypeConstants.CONSUMPTION:
                    return {
                        ...state,
                        hourlyCostBtnClassName: "chart-type-btn button-focus",
                        hourlyUsageBtnClassName: "chart-type-btn-selected button-focus",
                        showHourlyCostChart: false,
                        showHourlyUsageChart: true,
                        showHourlyUsageVerbiage: { visibility: 'visible' },
                        currentHourlyChart: dailyUsageChartTypeConstants.CONSUMPTION,
                        hourlyButtonClassNames: getButtonCssClasses(action.chartType)
                    }
                case dailyUsageChartTypeConstants.DEMAND:
                    return {
                        ...state,
                        hourlyCostBtnClassName: "chart-type-btn button-focus",
                        hourlyUsageBtnClassName: "chart-type-btn-selected button-focus",
                        showHourlyCostChart: false,
                        showHourlyUsageChart: true,
                        showHourlyUsageVerbiage: { visibility: 'visible' },
                        currentHourlyChart: dailyUsageChartTypeConstants.DEMAND,
                        hourlyButtonClassNames: getButtonCssClasses(action.chartType)
                    }
                case dailyUsageChartTypeConstants.EXPORT:
                    return {
                        ...state,
                        hourlyCostBtnClassName: "chart-type-btn button-focus",
                        hourlyUsageBtnClassName: "chart-type-btn-selected button-focus",
                        showHourlyCostChart: false,
                        showHourlyUsageChart: true,
                        showHourlyUsageVerbiage: { visibility: 'visible' },
                        currentHourlyChart: dailyUsageChartTypeConstants.EXPORT,
                        hourlyButtonClassNames: getButtonCssClasses(action.chartType)
                    }

            }
            break
        case usageConstants.GET_USAGE_DAILY_CHART_TYPE:
            switch (action.chartType) {
                case dailyUsageChartTypeConstants.COST:
                    return {
                        ...state, showDailyCostChart: true,
                        showDailyUsageChart: false,
                        showDailyNetEnergyChart: false,
                        showDailyGenerationChart: false,
                        showDailyDemandChart: false,
                        showDailyExportChart: false,
                        showDailyCostVerbiage: { display: 'block' },
                        showDailyUsageVerbiage: { display: 'none' },
                        currentDailyChart: dailyUsageChartTypeConstants.COST,
                        dailyButtonClassNames: getButtonCssClasses(action.chartType)
                    }
                case dailyUsageChartTypeConstants.USAGE:
                    return {
                        ...state,
                        showDailyCostChart: false,
                        showDailyUsageChart: true,
                        showDailyNetEnergyChart: false,
                        showDailyGenerationChart: false,
                        showDailyDemandChart: false,
                        showDailyExportChart: false,
                        showDailyCostVerbiage: { display: 'none' },
                        showDailyUsageVerbiage: { display: 'block' },
                        currentDailyChart: dailyUsageChartTypeConstants.USAGE,
                        dailyButtonClassNames: getButtonCssClasses(action.chartType)
                    }
                case dailyUsageChartTypeConstants.NET_ENERGY:
                    return {
                        ...state,
                        showDailyCostChart: false,
                        showDailyUsageChart: false,
                        showDailyNetEnergyChart: true,
                        showDailyGenerationChart: false,
                        showDailyDemandChart: false,
                        showDailyExportChart: false,
                        showDailyCostVerbiage: { display: 'none' },
                        showDailyUsageVerbiage: { display: 'none' },
                        currentDailyChart: dailyUsageChartTypeConstants.NET_ENERGY,
                        dailyButtonClassNames: getButtonCssClasses(action.chartType)
                    }
                case dailyUsageChartTypeConstants.GENERATION:
                    return {
                        ...state,
                        showDailyCostChart: false,
                        showDailyUsageChart: false,
                        showDailyNetEnergyChart: false,
                        showDailyGenerationChart: true,
                        showDailyDemandChart: false,
                        showDailyExportChart: false,
                        showDailyCostVerbiage: { display: 'none' },
                        showDailyUsageVerbiage: { display: 'none' },
                        currentDailyChart: dailyUsageChartTypeConstants.GENERATION,
                        dailyButtonClassNames: getButtonCssClasses(action.chartType)
                    }
                case dailyUsageChartTypeConstants.DEMAND:
                    return {
                        ...state,
                        showDailyCostChart: false,
                        showDailyUsageChart: false,
                        showDailyNetEnergyChart: false,
                        showDailyGenerationChart: true,
                        showDailyDemandChart: true,
                        showDailyExportChart: false,
                        showDailyCostVerbiage: { display: 'none' },
                        showDailyUsageVerbiage: { display: 'none' },
                        currentDailyChart: dailyUsageChartTypeConstants.DEMAND,
                        dailyButtonClassNames: getButtonCssClasses(action.chartType)
                    }
                case dailyUsageChartTypeConstants.CONSUMPTION:
                    return {
                        ...state,
                        showDailyCostChart: false,
                        showDailyUsageChart: false,
                        showDailyNetEnergyChart: false,
                        showDailyGenerationChart: true,
                        showDailyDemandChart: false,
                        showDailyExportChart: false,
                        showDailyCostVerbiage: { display: 'none' },
                        showDailyUsageVerbiage: { display: 'none' },
                        currentDailyChart: dailyUsageChartTypeConstants.CONSUMPTION,
                        dailyButtonClassNames: getButtonCssClasses(action.chartType)
                    }
                case dailyUsageChartTypeConstants.EXPORT:
                    return {
                        ...state,
                        showDailyCostChart: false,
                        showDailyUsageChart: false,
                        showDailyNetEnergyChart: false,
                        showDailyGenerationChart: false,
                        showDailyDemandChart: false,
                        showDailyExportChart: true,
                        showDailyCostVerbiage: { display: 'none' },
                        showDailyUsageVerbiage: { display: 'none' },
                        currentDailyChart: dailyUsageChartTypeConstants.EXPORT,
                        dailyButtonClassNames: getButtonCssClasses(action.chartType)
                    }
            }
            break
        case usageConstants.GET_USAGE_MONTHLY_CHART_TYPE:
        switch(action.chartType)
        {
            case dailyUsageChartTypeConstants.COST:
                return { ...state,
                            currentMonthlyChart: dailyUsageChartTypeConstants.COST,
                            monthlyButtonClassNames: getButtonCssClasses(action.chartType) }

            case dailyUsageChartTypeConstants.USAGE:
                return { ...state,
                            currentMonthlyChart: dailyUsageChartTypeConstants.USAGE,
                            monthlyButtonClassNames: getButtonCssClasses(action.chartType) }
            case dailyUsageChartTypeConstants.DEMAND:
                return { ...state,
                            currentMonthlyChart: dailyUsageChartTypeConstants.DEMAND,
                            monthlyButtonClassNames: getButtonCssClasses(action.chartType) }
            case dailyUsageChartTypeConstants.GENERATION:
                return { ...state,
                            currentMonthlyChart: dailyUsageChartTypeConstants.GENERATION,
                            monthlyButtonClassNames: getButtonCssClasses(action.chartType)
                    }
            case dailyUsageChartTypeConstants.NET_ENERGY:
                return { ...state,
                            currentMonthlyChart: dailyUsageChartTypeConstants.NET_ENERGY,
                            monthlyButtonClassNames: getButtonCssClasses(action.chartType)
                    }
            case dailyUsageChartTypeConstants.CONSUMPTION:
                return { ...state,
                            currentMonthlyChart: dailyUsageChartTypeConstants.CONSUMPTION,
                            monthlyButtonClassNames: getButtonCssClasses(action.chartType)
                    }
            case dailyUsageChartTypeConstants.EXPORT:
                return { ...state,
                            currentMonthlyChart: dailyUsageChartTypeConstants.EXPORT,
                            monthlyButtonClassNames: getButtonCssClasses(action.chartType)
                    }

        }
            break
        case usageConstants.GET_HOURLY_DATA_TABLE:
            return { ...state, showHourlyDataTable: action.showTable }
        case usageConstants.GET_DAILY_DATA_TABLE:
            return { ...state, showDailyDataTable: action.showTable }
        case usageConstants.GET_MONTHLY_DATA_TABLE:
            return { ...state, showMonthlyDataTable: action.showTable }
        default:
            return state;
    }
}

function getStartAndEndDate(dailyStartDate, dailyEndDate, dailyMinDate, dailyMaxDate) {
    let endDate = DateTime.fromISO(dailyEndDate).setZone('MST')
    let startDate = DateTime.fromISO(dailyStartDate).setZone('MST')
    let minDate = DateTime.fromISO(dailyMinDate).setZone('MST')
    let maxDate = DateTime.fromISO(dailyMaxDate).setZone('MST')
    if (endDate.startOf('day') > maxDate.startOf('day')
        || startDate.startOf('day') < minDate.startOf('day')) {
        endDate = maxDate
        endDate.minus({ days: 30 }) > minDate
            ? startDate = endDate.minus({ days: 30 })
            : startDate = minDate
    }
    return { endDate, startDate }
}

function fillEndDates(accountHistoryList) {
    let billEndDates = []
    if (accountHistoryList != undefined && accountHistoryList.length > 0) {
        accountHistoryList.forEach(e => {
            billEndDates.push(e.billEndDate)
        });
    }
    return billEndDates
}

function setStartDate(startDates) {
    if (startDates != undefined && startDates.length > 0) {
        if (startDates.length >= 12) {
            return startDates[11]
        }
        else return startDates[startDates.length - 1]
    }
}

function setEndDate(endDates) {
    if (endDates != undefined && endDates.length > 0) {
        return endDates[0]
    }
    else return DateTime.now()
}