import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import '../../../../styles/dashboard-page.css'

import { addSnackbarMessage, dismissSnackbarMessage } from '../../../../actions/common/snackbar'
import { getContacts, refreshContacts, addContacts, deleteContacts, updateContacts, moveSubscriptionsAndDeleteContact, reorderContacts } from '../../../../actions/auth/contact/contact-actions'
import { getBillingAddress } from '../../../../actions/auth/bill_account/bill-account-actions'
import { getNotifications, copySubscriptionsToNewContact, addNotification, refreshNotification } from '../../../../actions/auth/notification/notification-actions'
import * as loadingStatus from '../../../../constants/loading-status-constants'
import { contactConstants } from '../../../../constants/contact-constants'
import { myAccountConstants } from '../../../../constants/myaccount-constants'

import Contacts from './contacts'
import CardLoading from '../../../dashboard_page/card-loading'

function updateDataIfNeeded(props) {
    if (props.selectedBillAccount !== 0) {
        if (props.contactsStatus === loadingStatus.LOADING_STATUS_INIT
            || props.primaryEmailStatus === loadingStatus.LOADING_STATUS_INIT) {
            props.getContacts(props.selectedBillAccount)
        }

        if (props.notificationsStatus === loadingStatus.LOADING_STATUS_INIT) {
            props.getNotifications(props.selectedBillAccount)
        }

        if (props.mailingAddressStatus === loadingStatus.LOADING_STATUS_INIT) {
            props.getMailingAddressInfo(props.selectedBillAccount)
        }
    }
}

function compareContacts(a, b) {
    if (a.contactLabel && b.contactLabel) {
        if (a.contactLabel.toLowerCase() < b.contactLabel.toLowerCase()) {
            return -1
        } else if (a.contactLabel.toLowerCase() === b.contactLabel.toLowerCase()) {
            if (a.contactAddress.toLowerCase() < b.contactAddress.toLowerCase()) {
                return -1
            } else {
                return 1
            }
        } else {
            return 1
        }
    }

    if (a.contactLabel && !b.contactLabel) {
        return -1
    }

    if (!a.contactLabel && b.contactLabel) {
        return 1
    }

    if (a.contactAddress.toLowerCase() < b.contactAddress.toLowerCase()) {
        return -1
    } else {
        return 1
    }
}

function sortContacts(contacts) {
    if (contacts.length === 0) {
        return contacts
    }

    let sortedContacts = []

    // first contact is primary, need to save it off
    let firstContact = contacts[0]

    contacts.sort((a, b) => { return compareContacts(a, b) })

    // push the primary onto the array
    sortedContacts.push(firstContact)

    for (let i = 0; i < contacts.length; i++) {
        if (contacts[i].contactAddress != firstContact.contactAddress) {
            sortedContacts.push(contacts[i])
        }
    }

    return sortedContacts
}

function filterContactsByType(contacts, contactType) {
    let filteredContacts = []
    contacts.forEach(contact => {
        if (contact.contactType === contactType) {
            filteredContacts.push(contact)
        }
    })

    filteredContacts = sortContacts(filteredContacts)

    return filteredContacts
}

function reorderContactsList(primaryContact, contacts) {
    let contactsToReorder = filterContactsByType(contacts, primaryContact.contactType)

    let reorderedContacts = [primaryContact]

    contactsToReorder.forEach(c => {
        if (c.contactAddress !== primaryContact.contactAddress) {
            reorderedContacts.push(c)
        }
    })

    return reorderedContacts
}

export function getUndoDeleteContactInfo(contactAddress, contactType, contactLabel, subscribedContacts) {
    let subscribedNotifications = []

    if (contactType === contactConstants.CONTACT_TYPE.EMAIL) {
        if (Object.prototype.hasOwnProperty.call(subscribedContacts.emails, contactAddress)) {
            subscribedNotifications = subscribedContacts.emails[contactAddress].subscribedNotifications
        }
    } else if (contactType === contactConstants.CONTACT_TYPE.PHONE) {
        if (Object.prototype.hasOwnProperty.call(subscribedContacts.phones, contactAddress)) {
            subscribedNotifications = subscribedContacts.phones[contactAddress].subscribedNotifications
        }
    }

    let undoDeleteInfo = {
        contact: {
            contactAddress,
            contactType,
            contactLabel
        },
        subscribedNotifications: subscribedNotifications
    }

    return undoDeleteInfo
}

function contactAlreadyExists(contacts, oldContact, contactToLookFor) {
    let exists = false

    // first, remove the original contact so we don't accidentally match that. This is important since the user could be just updating the contactLabel

    let contactsToSearch = contacts.filter((value) => {
        return value.contactAddress.toLowerCase() !== oldContact.contactAddress.toLowerCase()
    })

    for (let i = 0; i < contactsToSearch.length; i++) {
        if (contactsToSearch[i].contactAddress.toLowerCase() == contactToLookFor.contactAddress.toLowerCase()) {
            exists = true
        }
    }

    return exists
}

function hasLoaded(props) {
    if (props.contactsStatus === loadingStatus.LOADING_STATUS_INIT ||
        props.contactsStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS ||
        props.mailingAddressStatus === loadingStatus.LOADING_STATUS_INIT ||
        props.mailingAddressStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS ||
        props.primaryEmailStatus === loadingStatus.LOADING_STATUS_INIT ||
        props.primaryEmailStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS ||
        props.notificationsStatus === loadingStatus.LOADING_STATUS_INIT ||
        props.notificationsStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS) {
        return false
    }

    return true
}

class ContactsContainer extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            alertInfo: {
                status: myAccountConstants.CUSTOMER_ACCOUNT_DATA_STATUS.UNKNOWN,
                show: false,
                contactType: contactConstants.CONTACT_TYPE.UNKNOWN,
                actionType: myAccountConstants.ACTION_TYPE.UNKNOWN
            },
            initialLoadComplete: false
        }

        this.showAlert = this.showAlert.bind(this)
        this.hideAlert = this.hideAlert.bind(this)
        this.addContactsClick = this.addContactsClick.bind(this)
        this.updateContactClick = this.updateContactClick.bind(this)
        this.makePrimaryClick = this.makePrimaryClick.bind(this)
        this.updateContactAndMakePrimaryClick = this.updateContactAndMakePrimaryClick.bind(this)
        this.setStateLoaded = this.setStateLoaded.bind(this)
        this.addContactsAndCopySubscriptionsClick = this.addContactsAndCopySubscriptionsClick.bind(this)
        this.deleteContactClick = this.deleteContactClick.bind(this)
    }

    componentDidMount() {
        updateDataIfNeeded(this.props)
        this.setStateLoaded()
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        updateDataIfNeeded(nextProps)
        if (this.props.selectedBillAccount != nextProps.selectedBillAccount) {
            this.hideAlert()
        }

        if (this.props.selectedAlertsTab != nextProps.selectedAlertsTab) {
            this.hideAlert()
        }

        this.setStateLoaded()
    }

    setStateLoaded() {
        if (hasLoaded(this.props)) {
            this.setState({
                initialLoadComplete: true
            })
        }
    }

    showAlert(status, contactType, actionType) {
        this.setState({
            alertInfo: {
                status: status,
                show: true,
                contactType: contactType,
                actionType: actionType
            }
        })
    }

    hideAlert() {
        this.setState({
            alertInfo: {
                ...this.state.alertInfo,
                show: false
            }
        })
    }

    updateContactClick(oldContact, newContact) {
        if (contactAlreadyExists(this.props.contacts, oldContact, newContact)) {
            this.showAlert(myAccountConstants.CUSTOMER_ACCOUNT_DATA_STATUS.DUPLICATECONTACT, newContact.contactType, myAccountConstants.ACTION_TYPE.UPDATE)
            return myAccountConstants.CUSTOMER_ACCOUNT_DATA_STATUS.DUPLICATECONTACT
        } else {
            return this.props.updateContactClick(this.props.selectedBillAccount, oldContact, newContact, this.showAlert)
        }
    }

    updateContactAndMakePrimaryClick(oldContact, newContact) {
        if (contactAlreadyExists(this.props.contacts, oldContact, newContact)) {
            this.showAlert(myAccountConstants.CUSTOMER_ACCOUNT_DATA_STATUS.DUPLICATECONTACT, newContact.contactType, myAccountConstants.ACTION_TYPE.UPDATE)
            return myAccountConstants.CUSTOMER_ACCOUNT_DATA_STATUS.DUPLICATECONTACT
        } else {
            let reorderedContacts = reorderContactsList(oldContact, this.props.contacts)
            reorderedContacts[0] = newContact
            return this.props.updateContactAndMakePrimaryClick(this.props.selectedBillAccount, oldContact, newContact, reorderedContacts, this.showAlert)
        }
    }

    makePrimaryClick(contact) {
        let reorderedContacts = reorderContactsList(contact, this.props.contacts)
        return this.props.reorderContactsClick(this.props.selectedBillAccount, reorderedContacts, this.showAlert)
    }

    addContactsClick(contact, makeNewContactPrimary) {
        let reorderedContacts = reorderContactsList(contact, this.props.contacts)
        this.props.addContactsClick(this.props.selectedBillAccount, contact, reorderedContacts, makeNewContactPrimary, this.showAlert)
    }

    addContactsAndCopySubscriptionsClick(newContact, contactAddressToCopySubscriptionsFrom, makeNewContactPrimary) {
        let reorderedContacts = reorderContactsList(newContact, this.props.contacts)
        let contactToCopyFrom = this.props.contacts.find(c => { if (c.contactAddress === contactAddressToCopySubscriptionsFrom) { return c } })
        this.props.addContactsAndCopySubscriptionsClick(this.props.selectedBillAccount, newContact, contactToCopyFrom, reorderedContacts, makeNewContactPrimary, this.showAlert)
    }

    deleteContactClick(contact) {
        let undoDeleteContactInfo = getUndoDeleteContactInfo(contact.contactAddress, contact.contactType, contact.contactLabel, this.props.subscribedContacts)
        this.props.deleteContactsClick(this.props.selectedBillAccount, contact, undoDeleteContactInfo)
    }

    render() {
        if (hasLoaded(this.props) || this.state.initialLoadComplete) {
            return (
                <div>
                    <Contacts
                        billAccount={this.props.selectedBillAccount}
                        primaryEmail={this.props.primaryEmail}
                        emails={filterContactsByType(this.props.contacts, contactConstants.CONTACT_TYPE.EMAIL)}
                        phoneNumbers={filterContactsByType(this.props.contacts, contactConstants.CONTACT_TYPE.PHONE)}
                        addContactsClick={this.addContactsClick}
                        addContactsAndCopySubscriptionsClick={this.addContactsAndCopySubscriptionsClick}
                        updateContactClick={this.updateContactClick}
                        makePrimaryClick={this.makePrimaryClick}
                        updateContactAndMakePrimaryClick={this.updateContactAndMakePrimaryClick}
                        deleteContactClick={this.deleteContactClick}
                        moveSubscriptionsAndDeleteContactClick={this.props.moveSubscriptionsAndDeleteContactClick}
                        subscribedContacts={this.props.subscribedContacts}
                        alertInfo={this.state.alertInfo}
                        hideAlert={this.hideAlert}
                        mailingAddress={this.props.mailingAddress}
                        t={this.props.t}
                    />
                </div>
            )
        } else {
            return (
                <CardLoading title="My contact information" />
            )
        }
    }
}

const mapStateToProps = (state) => {
    return { ...state.accountInfo.contact, ...state.accountInfo.notification,
        selectedBillAccount: state.accountInfo.billAccount.selectedBillAccount,
        mailingAddress: state.accountInfo.billAccount.billingAddress,
        mailingAddressStatus: state.accountInfo.billAccount.billingAddressStatus

    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getContacts: (billAccount) => {
            dispatch(getContacts(billAccount))
        },
        getNotifications: (billAccount) => {
            dispatch(getNotifications(billAccount))
        },
        getMailingAddressInfo: (billAccount) => {
            dispatch(getBillingAddress(billAccount))
        },
        addContactsClick: async (billAccount, contact, reorderedContacts, makeNewContactPrimary, showAlert) => {
            if (reorderedContacts.length > myAccountConstants.MAX_CONTACT_COUNT) {
                showAlert(myAccountConstants.CUSTOMER_ACCOUNT_DATA_STATUS.MAXCONTACTLIMITREACHED, contact.contactType, myAccountConstants.ACTION_TYPE.ADD)
            } else {
                let addContactsResult = await dispatch(addContacts(billAccount, contact))
                if (addContactsResult.payload === myAccountConstants.CUSTOMER_ACCOUNT_DATA_STATUS.SUCCESS) {
                    if (makeNewContactPrimary) {
                        await dispatch(reorderContacts(billAccount, reorderedContacts))
                    }

                    await dispatch(refreshContacts(billAccount))
                    await dispatch(refreshNotification(billAccount))
                }

                showAlert(addContactsResult.payload, contact.contactType, myAccountConstants.ACTION_TYPE.ADD)
            }
        },
        addContactsAndCopySubscriptionsClick: async (billAccount, contact, contactToCopySubscriptions, reorderedContacts, makeNewContactPrimary, showAlert) => {
            if (reorderContacts.length > myAccountConstants.MAX_CONTACT_COUNT) {
                showAlert(myAccountConstants.CUSTOMER_ACCOUNT_DATA_STATUS.MAXCONTACTLIMITREACHED, contact.contactType, myAccountConstants.ACTION_TYPE.ADD)
            } else {
                let addContactsResult = await dispatch(copySubscriptionsToNewContact(billAccount, contactToCopySubscriptions, contact))
                if (addContactsResult.payload === myAccountConstants.CUSTOMER_ACCOUNT_DATA_STATUS.SUCCESS) {
                    if (makeNewContactPrimary) {
                        await dispatch(reorderContacts(billAccount, reorderedContacts))
                    }

                    await dispatch(refreshContacts(billAccount))
                    await dispatch(refreshNotification(billAccount))
                }

                showAlert(addContactsResult.payload, contact.contactType, myAccountConstants.ACTION_TYPE.ADD)
            }
        },
        deleteContactsClick: async (billAccount, contact, undoDeleteContactInfo) => {
            let undoDeleteContact = async (contactInfo) => {
                let contactType = contact.contactType === contactConstants.CONTACT_TYPE.EMAIL ? "email" : "phone number"
                let addContactResult = await dispatch(addContacts(billAccount, contactInfo.contact))
                if (addContactResult.payload === myAccountConstants.CUSTOMER_ACCOUNT_DATA_STATUS.SUCCESS) {
                    for (let i = 0; i < contactInfo.subscribedNotifications.length; i++) {
                        await dispatch(addNotification(billAccount, { billAccount: billAccount, notificationToAdd: contactInfo.subscribedNotifications[i] }))
                    }

                    await dispatch(refreshContacts(billAccount))
                    await dispatch(refreshNotification(billAccount))
                    dispatch(addSnackbarMessage(<span>Undo remove {contactType} successful</span>))
                } else {
                    dispatch(addSnackbarMessage(<span>Undo remove {contactType} failed</span>))
                }
            }

            let contactType = contact.contactType === contactConstants.CONTACT_TYPE.EMAIL ? "email" : "phone number"
            let message = `Remove ${contactType} failed`
            let deleteContactsResult = await dispatch(deleteContacts(billAccount, contact))
            if (deleteContactsResult.payload === myAccountConstants.CUSTOMER_ACCOUNT_DATA_STATUS.SUCCESS) {
                await dispatch(refreshContacts(billAccount))
                await dispatch(refreshNotification(billAccount))
                contactType = contact.contactType === contactConstants.CONTACT_TYPE.EMAIL ? "Email" : "Phone number"
                message = `${contactType} removed`
                dispatch(addSnackbarMessage(<span>{message}</span>, <button className="displayAsBlueText text-uppercase" onClick={() => {
                    dispatch(dismissSnackbarMessage())
                    undoDeleteContact(undoDeleteContactInfo)
                }}>Undo</button>))
            } else {
                dispatch(addSnackbarMessage(<span>{message}</span>))
            }
        },
        reorderContactsClick: async (billAccount, contacts, showAlert) => {
            let reorderContactsResult = await dispatch(reorderContacts(billAccount, contacts))
            if (reorderContactsResult.payload === myAccountConstants.CUSTOMER_ACCOUNT_DATA_STATUS.SUCCESS) {
                await dispatch(refreshContacts(billAccount))
                await dispatch(refreshNotification(billAccount))
            }
            showAlert(reorderContactsResult.payload, contacts[0].contactType, myAccountConstants.ACTION_TYPE.UPDATE)
            return reorderContactsResult.payload
        },
        updateContactClick: async (billAccount, oldContact, newContact, showAlert) => {
            let updateContactResult = await dispatch(updateContacts(billAccount, oldContact, newContact))
            if (updateContactResult.payload === myAccountConstants.CUSTOMER_ACCOUNT_DATA_STATUS.SUCCESS) {
                await dispatch(refreshContacts(billAccount))
                await dispatch(refreshNotification(billAccount))
            }
            showAlert(updateContactResult.payload, oldContact.contactType, myAccountConstants.ACTION_TYPE.UPDATE)
            return updateContactResult.payload
        },
        updateContactAndMakePrimaryClick: async (billAccount, oldContact, newContact, contacts, showAlert) => {
            let updateContactResult = await dispatch(updateContacts(billAccount, oldContact, newContact))
            let reorderContactsResult = myAccountConstants.CUSTOMER_ACCOUNT_DATA_STATUS.UNKNOWN

            if (updateContactResult.payload === myAccountConstants.CUSTOMER_ACCOUNT_DATA_STATUS.SUCCESS) {
                reorderContactsResult = await dispatch(reorderContacts(billAccount, contacts))
                if (reorderContactsResult.payload === myAccountConstants.CUSTOMER_ACCOUNT_DATA_STATUS.SUCCESS) {
                    await dispatch(refreshContacts(billAccount))
                    await dispatch(refreshNotification(billAccount))
                }

                showAlert(reorderContactsResult.payload, contacts[0].contactType, myAccountConstants.ACTION_TYPE.UPDATE)
                return reorderContactsResult.payload
            } else {
                showAlert(updateContactResult.payload, contacts[0].contactType, myAccountConstants.ACTION_TYPE.UPDATE)
                return updateContactResult.payload
            }
        },
        moveSubscriptionsAndDeleteContactClick: (billAccount, sourceContact, targetContact) => {
            dispatch(moveSubscriptionsAndDeleteContact(billAccount, sourceContact, targetContact))
        }
    }
}

ContactsContainer.propTypes = {
    selectedBillAccount: PropTypes.number.isRequired,
    contacts: PropTypes.arrayOf(PropTypes.object).isRequired,
    contactsStatus: PropTypes.string.isRequired,
    primaryEmail: PropTypes.string.isRequired,
    primaryEmailStatus: PropTypes.string.isRequired,
    getContacts: PropTypes.func.isRequired,
    mailingAddressStatus: PropTypes.string.isRequired,
    getMailingAddressInfo: PropTypes.func.isRequired,
    mailingAddress: PropTypes.shape({
        streetAddress: PropTypes.string.isRequired,
        city: PropTypes.string.isRequired,
        state: PropTypes.string.isRequired,
        zipcode: PropTypes.string.isRequired,
    }).isRequired,
    addContactsClick: PropTypes.func.isRequired,
    addContactsAndCopySubscriptionsClick: PropTypes.func.isRequired,
    deleteContactsClick: PropTypes.func.isRequired,
    updateContactClick: PropTypes.func.isRequired,
    reorderContactsClick: PropTypes.func.isRequired,
    updateContactAndMakePrimaryClick: PropTypes.func.isRequired,
    moveSubscriptionsAndDeleteContactClick: PropTypes.func.isRequired,
    notificationsStatus: PropTypes.string.isRequired,
    subscribedContacts: PropTypes.object.isRequired,
    selectedAlertsTab: PropTypes.number.isRequired,
    t: PropTypes.func
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactsContainer)