import React from 'react'
import PropTypes from 'prop-types'
import TextField from '@mui/material/TextField'
import { PatternFormat } from 'react-number-format'

const BankAccountNumberInput = props => {
    let isError = props.touched[props.id]
        && Object.prototype.hasOwnProperty.call(props.errors, props.id)
    let errMsg = isError ? props.errors[props.id] : ''
    let isDisabled = props.disabled || false
    let meta = {
        id: props.id,
        name: props.id,
        helperText: errMsg==="" ? "" : props.t(errMsg),
        error: isError
    }

    const label = typeof props.label==="string" ? props.t(props.label) : props.label

    return (
        <div>
            <PatternFormat
                {...meta}
                value={props.value}
                valueIsNumericString={true}
                fullWidth={true}
                onChange={props.onChange}
                onBlur={props.onBlur}
                format="#################"
                type="tel"
                customInput={TextField}
                disabled={isDisabled}
                label={label}
                InputProps={{ endAdornment: props.endAdornment }}
            />
        </div>
    )
}

BankAccountNumberInput.propTypes = {
    t: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    endAdornment: PropTypes.object,
    errors: PropTypes.object.isRequired,
    id: PropTypes.string.isRequired,
    label: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
    ]),
    onBlur: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    touched: PropTypes.object.isRequired,
    value: PropTypes.string.isRequired
}

export default BankAccountNumberInput