import React from 'react'

const BillProjectionUnavailable = ({t}) => {
    return (
        <div className="srp-card-body">
            <div className="srp-card-summary srp-inactive d-flex justify-content-around">
                <div className="d-flex flex-column align-items-center">
                    <div className="h4 text-white">{t("N/A")}</div>
                    <span>{t("projected bill amount")}</span>
                </div>
            </div>

            <div className="srp-card-details">
                <div className="text-muted mt-2">{t("A bill projection is not available for your account at this time.")}</div>
            </div>
        </div>
    )
}

export default BillProjectionUnavailable