export const primaryContactInfoReminderConstants = {

    PRIMARY_CONTACT_INFO_STATUS_CREATE_REQUEST: 'PRIMARY_CONTACT_INFO_STATUS_CREATE_REQUEST',
    PRIMARY_CONTACT_INFO_STATUS_CREATE_SUCCESS: 'PRIMARY_CONTACT_INFO_STATUS_CREATE_SUCCESS',
    PRIMARY_CONTACT_INFO_STATUS_CREATE_FAILURE: 'PRIMARY_CONTACT_INFO_STATUS_CREATE_FAILURE',

    GET_PRIMARY_CONTACT_INFO_STATUS_REQUEST: 'GET_PRIMARY_CONTACT_INFO_STATUS_REQUEST',
    GET_PRIMARY_CONTACT_INFO_STATUS_SUCCESS: 'GET_PRIMARY_CONTACT_INFO_STATUS_SUCCESS',
    GET_PRIMARY_CONTACT_INFO_STATUS_FAILURE: 'GET_PRIMARY_CONTACT_INFO_STATUS_FAILURE',

    SET_PRIMARY_CONTACT_INFO_STATUS_HAS_UPDATED_REQUEST: 'SET_PRIMARY_CONTACT_INFO_STATUS_HAS_UPDATED_REQUEST',
    SET_PRIMARY_CONTACT_INFO_STATUS_HAS_UPDATED_SUCCESS: 'SET_PRIMARY_CONTACT_INFO_STATUS_HAS_UPDATED_SUCCESS',
    SET_PRIMARY_CONTACT_INFO_STATUS_HAS_UPDATED_FAILURE: 'SET_PRIMARY_CONTACT_INFO_STATUS_HAS_UPDATED_FAILURE',

    SET_PRIMARY_CONTACT_INFO_STATUS_WAS_REMINDED_REQUEST: 'SET_PRIMARY_CONTACT_INFO_STATUS_WAS_REMINDED_REQUEST',
    SET_PRIMARY_CONTACT_INFO_STATUS_WAS_REMINDED_SUCCESS: 'SET_PRIMARY_CONTACT_INFO_STATUS_WAS_REMINDED_SUCCESS',
    SET_PRIMARY_CONTACT_INFO_STATUS_WAS_REMINDED_FAILURE: 'SET_PRIMARY_CONTACT_INFO_STATUS_WAS_REMINDED_FAILURE'
}