import React from 'react'
import PropTypes from 'prop-types'

import { myAccountConstants } from '../../../../constants/myaccount-constants'
import { withRouter } from '../../../../srp_modules/with-router'

import * as Yup from 'yup'

import { createValidationRulesFromeChexBankInfoValidationErrors, createValidationRulesFromeChexSRPAccountNumberAndPaymentAmountValidationErrors } from '../single-payment-formik-container'

import Card from '@mui/material/Card'

import CircularProgress from '@mui/material/CircularProgress'

import BankInfoSection from './bank-info-section'
import SRPAccountNumberPaymentAmountFormik from './srp-account-number-payment-amount-formik'

export class MultiplePaymentInputCard extends React.Component {
    constructor (props) {
        super(props)

        this.state = {
            showBankForm: false,
            verifyingPaymentList: false,
            hasUnhandledeChexValidationError: false,
            paymentList: [...this.props.paymentList],
            paymentListErrors: [],
            bankValidationSchema: Yup.object().shape({
                bankFirstName: Yup.string().ensure().trim().
                    required("First name is required."),
                bankLastName: Yup.string().ensure().trim().
                    required("Last name is required.")
                    .test('not all spaces', "Last name is required.", bankLastName => typeof bankLastName === "string" && bankLastName.trim().length > 0)
                    .test('at least 2 characters', "Last name must have at least two characters.", bankLastName => typeof bankLastName === "string" && bankLastName.trim().length > 1),
                bankPhoneNumber: Yup.string().ensure().
                    transform(function(bankPhoneNumber) {
                        return this.isType(bankPhoneNumber) && bankPhoneNumber !== null ? bankPhoneNumber.replace(/[() -]/g, '') : bankPhoneNumber
                    }).
                    required("Phone number is required.").
                    test('must have 10 digits', "Phone number must have 10 digits.", bankPhoneNumber => /^\d{10}$/.test(bankPhoneNumber)),
                bankEmailAddress: Yup.string().ensure().trim().
                    required("Email address is required.").
                    email("Invalid email address."),
                bankRoutingNumber: Yup.string().ensure().trim().
                    required("Routing number is required.").
                    test('must have 9 digits', "Enter a valid 9-digit routing number.", bankRoutingNumber => /^\d{9}$/.test(bankRoutingNumber)),
                bankAccountNumberLeadingZeroesAreSignificant: Yup.string().ensure().trim().
                    required("Bank account number is required.").
                    test('17 digits max', "Your account number must be 1-17 digits.", bankAccountNumber => /^\d{1,17}$/.test(bankAccountNumber)),
            }),
            paymentListValidationSchema: Yup.object().shape({
                srpAccountNumber: Yup.string().
                    transform(function(srpAccountNumber) {
                        return this.isType(srpAccountNumber) && srpAccountNumber !== null ? srpAccountNumber.replace(/[- ]/g, '') : srpAccountNumber
                    }),
                paymentAmount: Yup.number(),
            })
        }

        this.openBankForm = this.openBankForm.bind(this)
        this.handleBankFormClose = this.handleBankFormClose.bind(this)

        this.handleVerifyPaymentList = this.handleVerifyPaymentList.bind(this)

        this.addPaymentIfNotOnList = this.addPaymentIfNotOnList.bind(this)

        this.addPayment = this.addPayment.bind(this)
        this.removePayment = this.removePayment.bind(this)

        this.getBankInfo = this.getBankInfo.bind(this)
        this.bankInfoHasErrors = this.bankInfoHasErrors.bind(this)

        this.paymentListHasErrors = this.paymentListHasErrors.bind(this)

        this.handleValidatePaymentListErrors = this.handleValidatePaymentListErrors.bind(this)

        this.updatePaymentListErrors = this.updatePaymentListErrors.bind(this)
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.paymentList.length !== prevState.paymentList.length) {
            const paymentListErrors = this.updatePaymentListErrors(this.state.paymentList, this.state.paymentListValidationSchema)

            /* eslint react/no-did-update-set-state: "off" */
            /* It's ok to call setState in componentDidUpdate. See https://reactjs.org/docs/react-component.html . */
            this.setState({ paymentListErrors })
        }
     }

     componentWillUnmount() {
        this.props.syncPaymentList(this.state.paymentList)
    }

    updatePaymentListErrors(paymentList, paymentListValidationSchema) {
        let paymentListErrors = paymentList.map(function(p) {
            let error = { srpAccountNumber: '', paymentAmount: '' }
            try {
                const options = { abortEarly: false }
                paymentListValidationSchema.validateSync(p, options)
                return error
            }
            catch (e) {
                if (e instanceof Yup.ValidationError) {
                    e.inner.forEach(function(ve) {
                        if (ve.path === 'srpAccountNumber')
                            error.srpAccountNumber = ve.errors[0]
                        else if (ve.path === 'paymentAmount')
                            error.paymentAmount = ve.errors[0]
                    })
                    return error
                }
                else
                    throw e
            }
        })

        return paymentListErrors
   }

    paymentListHasErrors() {
        const firstPaymentError = this.state.paymentListErrors.find(e => e.srpAccountNumber || e.paymentAmount)
        return firstPaymentError !== undefined
    }

    getBankInfo() {
        return {
            bankFirstName: this.props.bankFirstName,
            bankLastName: this.props.bankLastName,
            bankPhoneNumber: this.props.bankPhoneNumber,
            bankEmailAddress: this.props.bankEmailAddress,
            bankRoutingNumber: this.props.bankRoutingNumber,
            bankAccountNumberLeadingZeroesAreSignificant: this.props.bankAccountNumberLeadingZeroesAreSignificant
        }
    }

    openBankForm() {
        this.setState({ showBankForm: true })
    }

    handleBankFormClose() {
        this.setState({ showBankForm: false })
    }

    addPaymentIfNotOnList({srpAccountNumber, paymentAmount}) {
        if (this.state.paymentList.some(p => p.srpAccountNumber === srpAccountNumber))
            return false

        this.setState(prevState => ({
            paymentList: [...prevState.paymentList, {srpAccountNumber, paymentAmount}]
        }))
        return true
    }

    addPayment({srpAccountNumber, paymentAmount}) {
        this.setState(prevState => ({
            paymentList: [...prevState.paymentList, {srpAccountNumber, paymentAmount}]
        }))
    }

    removePayment(paymentIndex) {
        if (paymentIndex < 0 || paymentIndex >= this.state.paymentList.length)
            return
        const deletedPayment = this.state.paymentList[paymentIndex]
        let paymentListCopy = [...this.state.paymentList]
        paymentListCopy.splice(paymentIndex, 1)
        this.setState({ paymentList: paymentListCopy })

        this.props.showUndoRemovePaymentSnackbar(deletedPayment, this.addPaymentIfNotOnList)
    }

    bankInfoHasErrors() {
        const hasNoError = this.state.bankValidationSchema.isValidSync(this.getBankInfo())
        return !hasNoError
    }

    handleValidatePaymentListErrors(errors) {
        this.setState({ verifyingPaymentList: false})

        if (errors.networkError) {
            this.setState({ hasUnhandledeChexValidationError: true })
            return
        }

        const bankInputValues = {
            bankFirstName: this.props.bankFirstName,
            bankLastName: this.props.bankLastName,
            bankRoutingNumber: this.props.bankRoutingNumber,
            bankAccountNumber: this.props.bankAccountNumberLeadingZeroesAreSignificant
        }
        const numBankErrorsHandled = createValidationRulesFromeChexBankInfoValidationErrors(this.state.bankValidationSchema.fields, errors, bankInputValues)
        const numSrpAccountNumberPaymentAmountErrorsHandled = createValidationRulesFromeChexSRPAccountNumberAndPaymentAmountValidationErrors(this.state.paymentListValidationSchema.fields, errors, this.props.t, this.props.i18n)
        const numErrorsHandled = numBankErrorsHandled + numSrpAccountNumberPaymentAmountErrorsHandled
        const hasUnhandledeChexValidationError = (Object.keys(errors).length - numErrorsHandled) > 0 && errors.constructor === Object

        this.setState({ hasUnhandledeChexValidationError })
        if (numBankErrorsHandled > 0)
            this.setState({ showBankForm: true })
        if (numSrpAccountNumberPaymentAmountErrorsHandled > 0) {
            const paymentListErrors = this.updatePaymentListErrors(this.state.paymentList, this.state.paymentListValidationSchema)
            this.setState({ paymentListErrors })
        }
    }

    handleVerifyPaymentList() {
        if (this.bankInfoHasErrors()) {
            this.openBankForm()
            return
        }

        this.setState({ verifyingPaymentList: true, hasUnhandledeChexValidationError: false })
        this.props.validatePaymentList(this.state.paymentList, this.handleValidatePaymentListErrors, this.props.router.navigate)
    }

    render () {
        return (
            <div>
            <label className="h5">{this.props.t("Payment Information")}</label>
            <Card className="p-4">
                {this.state.hasUnhandledeChexValidationError ? (
                    <div className="srp-alert-error mb-3">
                    {this.props.t("unable_to_complete",
                        {phone: this.props.i18n.language==='es' ? myAccountConstants.SPANISH_CUSTOMER_SERVICE_PHONE_NUMBER_FMTTD : myAccountConstants.RESIDENTIAL_CUSTOMER_SERVICE_PHONE_NUMBER_FMTTD})}
                    </div>
                ) : null}

                <BankInfoSection
                    {...this.props}
                    showBankForm={this.state.showBankForm} openBankForm={this.openBankForm}
                    handleBankFormClose={this.handleBankFormClose}
                    bankValidationSchema={this.state.bankValidationSchema}
                    verifyingPaymentList={this.state.verifyingPaymentList}
                />
                <SRPAccountNumberPaymentAmountFormik paymentList={this.state.paymentList}
                    paymentListErrors={this.state.paymentListErrors} verifyingPaymentList={this.state.verifyingPaymentList}
                    handleAddPayment={this.addPayment} handleRemovePayment={this.removePayment}
                    t={this.props.t} formDataChanged={this.props.formDataChanged} />

                <button className="btn srp-btn btn-blue mt-2 float-right" type="button" onClick={this.handleVerifyPaymentList} disabled={this.state.paymentList.length === 0 || this.paymentListHasErrors() || (this.state.showBankForm && this.bankInfoHasErrors())}>
                    {this.state.verifyingPaymentList ? <CircularProgress size={20} thickness={5} style={{color:'white'}} /> : this.props.t("Confirm payment")}
                </button>
            </Card>
            </div>
        )
    }
}

MultiplePaymentInputCard.propTypes = {
    bankFirstName: PropTypes.string.isRequired,
    bankLastName: PropTypes.string.isRequired,
    bankPhoneNumber: PropTypes.string.isRequired,
    bankEmailAddress: PropTypes.string.isRequired,
    bankRoutingNumber: PropTypes.string.isRequired,
    bankAccountNumberLeadingZeroesAreSignificant: PropTypes.string.isRequired,

    paymentList: PropTypes.arrayOf(PropTypes.shape({
        srpAccountNumber: PropTypes.string.isRequired,
        paymentAmount: PropTypes.number.isRequired
    })).isRequired,

    syncPaymentList: PropTypes.func.isRequired,
    storeBankInfo: PropTypes.func.isRequired,
    validatePaymentList: PropTypes.func.isRequired,
    showUndoRemovePaymentSnackbar: PropTypes.func.isRequired,

    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired,
    formDataChanged: PropTypes.func.isRequired,

    router: PropTypes.shape({
        navigate: PropTypes.func
    })
}

const MultiplePaymentInputCardWithRouter = withRouter(MultiplePaymentInputCard)
export default MultiplePaymentInputCardWithRouter