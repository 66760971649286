import React from 'react'
import PropTypes from 'prop-types'

const ReconnectAdvanceDetails = ({t}) => (
    <div>
    {t("Reconnect_advance_details_dialog_text")}
    </div>
)

ReconnectAdvanceDetails.propTypes = {
    t: PropTypes.func.isRequired,
}

export default ReconnectAdvanceDetails