import React from 'react'
import PropTypes from 'prop-types'

import { Trans } from 'react-i18next'
import { Link } from 'react-router-dom'

import Card from '@mui/material/Card'

import PaymentDetailsTableContainer from '../common/payment-details-table-container'

const GiftPaymentSubmittedCard = ({referenceNumber, confirmationEmailAddress, confirmationEmailSent, bankAccountUsed, updateBankEmailAddressOnClick, t}) => (
    <div>
        <label className="h5">{t("Your payment has been submitted")}</label>

        <Card>
            <div>
                <PaymentDetailsTableContainer referenceNumber={referenceNumber} t={t} />

                <h6 className="text-muted px-3">
                    {confirmationEmailSent ?
                    <div>
                    <Trans i18nKey="confirmation_email_has_been_sent_to" t={t}>
                        A confirmation email has been sent to <span className="font-weight-bold">{{confirmationEmailAddress}}</span> for this payment.
                    </Trans>
                    &nbsp;
                    <button className="displayAsLink d-print-none" onClick={() => updateBankEmailAddressOnClick(bankAccountUsed)}>{t("Update your email address")} &raquo;</button>
                    </div>
                    :
                    <div>
                    <Trans i18nKey="unable_to_send_a_confirmation_email" t={t}>
                        Unable to send a confirmation email to <span className="font-weight-bold">{{confirmationEmailAddress}}</span> for this payment.
                    </Trans>
                    &nbsp;
                    <button className="displayAsLink d-print-none" onClick={() => updateBankEmailAddressOnClick(bankAccountUsed)}>{t("Update your email address")} &raquo;</button>
                    </div>
                    }
                </h6>
            </div>

            <div className="d-flex justify-content-end mt-4 px-3 pb-3 d-print-none">
                <button className="btn srp-btn btn-lightblue mr-2" onClick={() => { window.print() }}>{t("Print")}</button>
                <Link to="/myaccount/dashboard" className="btn srp-btn btn-blue">{t("Go to dashboard")}</Link>
            </div>
        </Card>
    </div>
)

GiftPaymentSubmittedCard.propTypes = {
    t: PropTypes.func.isRequired,
    referenceNumber: PropTypes.number.isRequired,
    confirmationEmailAddress: PropTypes.string.isRequired,
    confirmationEmailSent: PropTypes.bool.isRequired,
    bankAccountUsed: PropTypes.object,
    updateBankEmailAddressOnClick: PropTypes.func.isRequired
}

export default GiftPaymentSubmittedCard