import { eBillConstants } from '../../../constants/ebill-constants'
import { billAccountConstants } from '../../../constants/bill-account-constants'  
import * as loadingStatus from '../../../constants/loading-status-constants'

const initialState = {
    eBillDocumentList: [],
    eBillStatus: loadingStatus.LOADING_STATUS_INIT
}

export default function eBillReducer (state = initialState, action) {
    switch (action.type) {
        case eBillConstants.GET_E_BILL_LIST_REQUEST:
            return { ...state, eBillStatus: loadingStatus.LOADING_STATUS_IN_PROGRESS }
        case eBillConstants.GET_E_BILL_LIST_SUCCESS:
            return { ...state, 
                        eBillDocumentList: action.payload.documentList,
                        eBillStatus: loadingStatus.LOADING_STATUS_SUCCESS }
        case eBillConstants.GET_E_BILL_LIST_FAILURE:
            return { ...initialState, eBillStatus: loadingStatus.LOADING_STATUS_FAILURE }

        case billAccountConstants.SELECT_BILL_ACCOUNT_SUCCESS:
            return { ...initialState }
        default:
            return state
    }
}