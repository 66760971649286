import { RSAA } from 'redux-api-middleware'
import { notificationConstants } from '../../../constants/notification-constants'
import utils from '../../../srp_modules/utils'

export const getNotifications = (billAccount) => {    
    return dispatch => {
        return dispatch({
            [RSAA]: {
                endpoint: utils.createUrl('notification/getnotifications', { billAccount: billAccount }),
                method: 'GET',
                credentials: 'include',
                types: [
                    notificationConstants.GET_NOTIFICATIONS_REQUEST,
                    notificationConstants.GET_NOTIFICATIONS_SUCCESS,
                    notificationConstants.GET_NOTIFICATIONS_FAILURE            
                ]
            }
        })
    }
}

export const addNotification = (billAccount, notificationToAdd) => {    
    return dispatch => {
        return dispatch({
            [RSAA]: {
                endpoint: utils.createUrl('notification/addnotification', { billAccount: billAccount }),
                body: JSON.stringify(notificationToAdd),
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                credentials: 'include',
                types: [
                    notificationConstants.ADD_NOTIFICATION_REQUEST,
                    notificationConstants.ADD_NOTIFICATION_SUCCESS,
                    notificationConstants.ADD_NOTIFICATION_FAILURE            
                ]
            },
            meta: { //this turns off debouncer
                debounce: {
                    leading: false,
                    trailing: false
                }
            }
        })
    }
}

export const updateNotification = (billAccount, notificationToUpdate) => {    
    return dispatch => {
        return dispatch({
            [RSAA]: {
                endpoint: utils.createUrl('notification/updatenotification', { billAccount: billAccount }),
                body: JSON.stringify(notificationToUpdate),
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                credentials: 'include',
                types: [
                    notificationConstants.UPDATE_NOTIFICATION_REQUEST,
                    notificationConstants.UPDATE_NOTIFICATION_SUCCESS,
                    notificationConstants.UPDATE_NOTIFICATION_FAILURE            
                ]
            },
            meta: { //this turns off debouncer
                debounce: {
                    leading: false,
                    trailing: false
                }
            }
        })
    }
}

export const deleteNotification = (billAccount, notificationToDelete) => {    
    return dispatch => {
        return dispatch({
            [RSAA]: {
                endpoint: utils.createUrl('notification/deletenotification', { billAccount: billAccount }),
                body: JSON.stringify(notificationToDelete),
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                credentials: 'include',
                types: [
                    notificationConstants.DELETE_NOTIFICATION_REQUEST,
                    notificationConstants.DELETE_NOTIFICATION_SUCCESS,
                    notificationConstants.DELETE_NOTIFICATION_FAILURE            
                ]
            },
            meta: { //this turns off debouncer
                debounce: {
                    leading: false,
                    trailing: false
                }
            }
        })
    }
}

export const refreshNotification = (billAccount) => {    
    return dispatch => {
        return dispatch({
            [RSAA]: {
                endpoint: utils.createUrl('notification/getnotifications', { billAccount: billAccount }),
                method: 'GET',
                credentials: 'include',
                types: [
                    notificationConstants.REFRESH_NOTIFICATIONS_REQUEST,
                    notificationConstants.REFRESH_NOTIFICATIONS_SUCCESS,
                    notificationConstants.REFRESH_NOTIFICATIONS_FAILURE            
                ]
            },
            meta: { //this turns off debouncer
                debounce: {
                    leading: false,
                    trailing: false
                }
            }
        })
    }
}

export const toggleEbill = (isEnrollingEbill, preferPhone) => {    
    return (dispatch, getState) => {
        let selectedBillAccount = getState().accountInfo.billAccount.selectedBillAccount  
        let parameters = {
            billAccount: selectedBillAccount,
            ebillState: isEnrollingEbill,
            preferPhone: preferPhone
        }
        
        return dispatch({
            [RSAA]: {
                endpoint: utils.createUrl('notification/toggleebill', parameters),
                method: 'POST',
                credentials: 'include',
                types: [
                    notificationConstants.TOGGLE_EBILL_REQUEST,
                    notificationConstants.TOGGLE_EBILL_SUCCESS,
                    notificationConstants.TOGGLE_EBILL_FAILURE            
                ]
            },
            meta: {
                // Turn off debouncer because the eBill button can be toggled on/off
                // really fast on the billing alert card. If the debouncer is on
                // the call to updateEbillEnrollment() in getEbillToggle()
                // in alerts\alerts-billing.js may get bounced and never return,
                // causing the eBill toggle not to be re-enabled.
                debounce: {
                    leading: false,
                    trailing: false
                }
            }
        })
    }    
}

export const toggleOutageAlert = (outageAlertState) => {
    return (dispatch, getState) => {
        let selectedBillAccount = getState().accountInfo.billAccount.selectedBillAccount
        let parameters = {
            billAccount: selectedBillAccount,
            outageAlertState: outageAlertState
        }
        
        return dispatch({
            [RSAA]: {
                endpoint: utils.createUrl('notification/toggleoutagealert', parameters),
                method: 'POST',
                credentials: 'include',
                types: [
                    notificationConstants.TOGGLE_OUTAGE_REQUEST,
                    notificationConstants.TOGGLE_OUTAGE_SUCCESS,
                    notificationConstants.TOGGLE_OUTAGE_FAILURE
                ]
            },
            meta: {
                debounce: {
                    leading: false,
                    trailing: false
                }
            }
        })
    }    
}

export const getAlertsTab = (tab) => ({
    type: notificationConstants.GET_ALERTS_TAB,
    tab
})

export const copySubscriptionsToNewContact = (billAccount, sourceContact, targetContact) => {
    let model = {
        sourceContact: sourceContact,
        targetContact: targetContact        
    }
    return dispatch => {
        return dispatch({
            [RSAA]: {
                endpoint: utils.createUrl('notification/copysubscriptionstonewcontact', { billAccount: billAccount }),
                body: JSON.stringify(model),
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                credentials: 'include',
                types: [
                    notificationConstants.COPY_SUBSCRIPTIONS_TO_NEW_CONTACT_REQUEST,
                    notificationConstants.COPY_SUBSCRIPTIONS_TO_NEW_CONTACT_SUCCESS,
                    notificationConstants.COPY_SUBSCRIPTIONS_TO_NEW_CONTACT_FAILURE            
                ]
            }
        })
    }
}

export const setPaperlessBillingNotEnrolled = () => ({
    type: notificationConstants.PAPERLESS_BILLLING_NOT_ENROLLED,
})

export const setPaperlessBillingEnrolling = () => ({
    type: notificationConstants.PAPERLESS_BILLLING_ENROLLING,
})

export const setPaperlessBillingJustEnrolled = () => ({
    type: notificationConstants.PAPERLESS_BILLLING_JUST_ENROLLED,
})