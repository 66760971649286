import React from 'react'
import PropTypes from 'prop-types'

import BankAccountSelectFieldContainer from '../common_payment/bank-account-select-field-container'

const SinglePaymentBankSelector = ({onAddBankAccountClick, onBankAccountChanged, t}) => (
    <div className="mt-1">
        <div className="pb-4">
            <BankAccountSelectFieldContainer onBankAccountChanged={onBankAccountChanged} />
        </div>
        <div className="d-flex flex-row-reverse">
            <div className="btn srp-btn btn-lightblue" onClick={() => onAddBankAccountClick()}>{t("Add bank account")}</div>
        </div>
    </div>
)

SinglePaymentBankSelector.propTypes = {
    onAddBankAccountClick: PropTypes.func,
    onBankAccountChanged: PropTypes.func,
    t: PropTypes.func.isRequired
}

export default SinglePaymentBankSelector
