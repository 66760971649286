import React from "react"
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import TextAlert from './text-alert'
import EmailAlert from './email-alert'

import * as loadingStatus from '../../../../constants/loading-status-constants'

class CommonAlert extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            isSubscribedToEmail: this.props.alert.subscribedToEmail,
            isSubscribedToText: this.props.alert.subscribedToText,
            isEmailToggleDisabled: false,
            isTextToggleDisabled: false
        }

        this.handleEmailAlertToggle = this.handleEmailAlertToggle.bind(this)
        this.handleTextAlertToggle = this.handleTextAlertToggle.bind(this)

    }

    componentDidUpdate(prevProps) {
        if (prevProps.refreshNotificationStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS
            && this.props.refreshNotificationStatus === loadingStatus.LOADING_STATUS_SUCCESS) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({
                isSubscribedToEmail: this.props.alert.subscribedToEmail,
                isSubscribedToText: this.props.alert.subscribedToText,
                isEmailToggleDisabled: false,
                isTextToggleDisabled: false
            })
        }
    }

    handleEmailAlertToggle(isSubscribed, notification) {
        this.setState({
            isSubscribedToEmail: !isSubscribed
        })

        if (this.props.alert.subscribedToEmail && isSubscribed) {
            this.props.deleteAllNotificationsOnClick(this.props.selectedBillAccount, notification.notificationInfo, notification.notificationInfo.notificationData, notification.email)
            this.setState({
                isEmailToggleDisabled: true
            })
        }
    }

    handleTextAlertToggle(isSubscribed, notification) {
        this.setState({
            isSubscribedToText: !isSubscribed
        })

        if (this.props.alert.subscribedToText && isSubscribed) {
            this.props.deleteAllNotificationsOnClick(this.props.selectedBillAccount, notification.notificationInfo, notification.notificationInfo.notificationData, notification.phone)
            this.setState({
                isTextToggleDisabled: true
            })
        }
    }

    render() {
        return (
            <div>
                <div className="row d-flex align-items-center">
                    <div className="col-lg-8 col-12">
                        <h4 className="d-lg-block d-none" style={{ color: 'rgb(103, 135, 183)' }}>{this.props.alertTitle}</h4>
                        <h5 className="d-lg-none d-block" style={{ color: 'rgb(103, 135, 183)' }}>{this.props.alertTitle}</h5>
                        <div className="d-lg-none d-block">
                            <p className="text-muted">{this.props.alertDescription}</p>
                        </div>
                    </div>
                    {this.props.allowEmailSubscription
                        ? <div className="col-lg-2 col-4">
                            <div className="d-lg-none d-block text-muted">{this.props.t("Email")}</div>
                            <label className="switch align-middle mb-0">
                                <input
                                    type="checkbox"
                                    checked={this.state.isSubscribedToEmail}
                                    disabled={this.state.isEmailToggleDisabled ? this.props.t("disabled") : ""}
                                    onChange={() => this.handleEmailAlertToggle(this.state.isSubscribedToEmail, this.props.alert)}
                                />
                                <span className="slider" />
                            </label>
                        </div>
                        : null}
                    {this.props.allowTextSubscription
                        ? <div className="col-lg-2 col-4">
                            <div className="d-lg-none d-block text-muted">{this.props.t("Text")}</div>
                            <label className="switch align-middle mb-0">
                                <input
                                    type="checkbox"
                                    checked={this.state.isSubscribedToText}
                                    disabled={this.state.isTextToggleDisabled ? this.props.t("disabled") : ""}
                                    onChange={() => this.handleTextAlertToggle(this.state.isSubscribedToText, this.props.alert)}
                                />
                                <span className="slider" />
                            </label>
                        </div>
                        : null}
                </div>
                <span className="d-lg-none d-block mb-3" />
                <div className="d-lg-block d-none">
                    <div className="row">
                        <div className="col-lg-8">
                            <p className="text-muted mb-3">{this.props.alertDescription}</p>
                        </div>
                    </div>
                </div>
                {this.state.isSubscribedToEmail && this.props.allowEmailSubscription
                    ? <div className="mb-3">
                        <EmailAlert
                            alert={this.props.alert}
                            notificationData={this.props.alert.notificationInfo.notificationData}
                            emailContacts={this.props.emailContacts}
                            addNotification={this.props.addNotification}
                            deleteNotification={this.props.deleteNotification} 
                            t={this.props.t}/>
                    </div>
                    : null}

                {this.state.isSubscribedToText && this.props.allowTextSubscription
                    ? <div className="mb-3">
                        <TextAlert
                            alert={this.props.alert}
                            notificationData={this.props.alert.notificationInfo.notificationData}
                            phoneContacts={this.props.phoneContacts}
                            addNotification={this.props.addNotification}
                            deleteNotification={this.props.deleteNotification} 
                            t={this.props.t}/>
                    </div>
                    : null}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        selectedBillAccount: state.accountInfo.billAccount.selectedBillAccount,
        refreshNotificationStatus: state.accountInfo.notification.refreshNotificationStatus
    }
}

CommonAlert.propTypes = {
    selectedBillAccount: PropTypes.number,
    phoneContacts: PropTypes.arrayOf(PropTypes.object),
    emailContacts: PropTypes.arrayOf(PropTypes.object),

    alert: PropTypes.object,
    alertTitle: PropTypes.string,
    alertDescription: PropTypes.string,
    allowEmailSubscription: PropTypes.bool,
    allowTextSubscription: PropTypes.bool,

    addNotification: PropTypes.func,
    deleteNotification: PropTypes.func,
    deleteAllNotificationsOnClick: PropTypes.func,

    refreshNotificationStatus: PropTypes.string,

    t: PropTypes.func
}

export default connect(mapStateToProps)(CommonAlert)