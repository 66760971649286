import React from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

import { formatDayWithOrdinal } from '../../../srp_modules/utils'
import transferModule from '../../../srp_modules/forms/transfer-module'
import { formConstants } from '../../../constants/form-constants'
import { displayRateCode } from '../../../srp_modules/forms/display-mapper'

const AboutNewAccountMobile = ({selectedPlan, selectedSolarChoicePlan,
    dayOfMonth, isPaperlessBilling, email,
    address1, address2, city, isMailingAddressSame,
    mailingAddress1, mailingAddress2,
    mailingCity, mailingState, mailingZipCode,
    mailingCountry, t
    }) => (
    <div className="d-lg-none d-block mb-4">
        <div className="row d-flex align-items-center">
            <div className="col-5 text-muted font-weight-bold">{t("price_plan")}</div>
            <div className="col-7 text-right">{displayRateCode(selectedPlan, t)}</div>
        </div>
        <hr className="mt-1 mb-1" />
        {selectedPlan !== formConstants.MPOWER &&
        <div>
            {selectedSolarChoicePlan !== formConstants.SOLARCHOICE_NONE &&
            <div>
                <div className="row d-flex align-items-center">
                    <div className="col-7 text-muted font-weight-bold">{t("solarchoice")}</div>
                    <div className="col-5 text-right">{transferModule.getSolarChoiceDisplay(selectedSolarChoicePlan)}</div>
                </div>
                <hr className="mt-1 mb-1" />
            </div>
            }
            <div className="row d-flex align-items-center">
                <div className="col-5 text-muted font-weight-bold">{t("billing_due_date")}</div>
                <div className="col-7 text-right">{`${formatDayWithOrdinal(dayOfMonth)} day of every month.`}</div>
            </div>
            <hr className="mt-1 mb-1" />
            <div className="row d-flex align-items-center">
                <div className="col-5 text-muted font-weight-bold">{t("paperless_billing")}</div>
                <div className="col-7 text-right">
                {isPaperlessBilling
                ? <div>
                    <div>Active</div>
                    <div>{`Billing notifications will be sent to `}<span className="font-weight-bold">{email}</span></div>
                  </div>
                : "Inactive"
                }
                </div>
            </div>
            <hr className="mt-1 mb-1" />
        </div>
        }
        <div className="row d-flex align-items-center">
            <div className="col-5 text-muted font-weight-bold">{t("mailing_address")}</div>
            <div className="col-7 text-right">
            {isMailingAddressSame
            ? address2.length === 0
                ? <div>
                  <div>{address1}</div>
                  <div>{`${city}, AZ`}</div>
                </div>
                : <div>
                  <div>{`${address1} ${address2}`}</div>
                  <div>{`${city}, AZ`}</div>
                </div>
            : mailingAddress2.length === 0
                ? <div>
                  <div>{mailingAddress1}</div>
                  <div>{`${mailingCity}, ${mailingState} ${mailingZipCode}`}</div>
                  <div>{`${mailingCountry}`}</div>
                </div>
                : <div>
                  <div>{`${mailingAddress1} ${mailingAddress2}`}</div>
                  <div>{`${mailingCity}, ${mailingState} ${mailingZipCode}`}</div>
                  <div>{`${mailingCountry}`}</div>
                </div>
            }
            </div>
        </div>
        <hr className="mt-1" />
    </div>
)

AboutNewAccountMobile.propTypes = {
    selectedPlan: PropTypes.string.isRequired,
    selectedSolarChoicePlan: PropTypes.string.isRequired,
    dayOfMonth: PropTypes.number.isRequired,
    isPaperlessBilling: PropTypes.bool.isRequired,
    email: PropTypes.string.isRequired,
    address1: PropTypes.string.isRequired,
    address2: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
    isMailingAddressSame: PropTypes.bool.isRequired,
    mailingAddress1: PropTypes.string.isRequired,
    mailingAddress2: PropTypes.string.isRequired,
    mailingCity: PropTypes.string.isRequired,
    mailingState: PropTypes.string.isRequired,
    mailingZipCode: PropTypes.string.isRequired,
    mailingCountry: PropTypes.string.isRequired,
    t: PropTypes.func
}

export default withTranslation('startService')(AboutNewAccountMobile)