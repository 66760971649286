import React from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'

import Card from '@mui/material/Card'

import * as loadingStatus from '../../../constants/loading-status-constants'
import { getBankAccountList } from '../../../actions/auth/payment/bank-account-actions'
import { getEnrolledProgramsDetails } from '../../../actions/auth/payment/payment-actions'
import { getCustomerNames, getPhoneNumber } from '../../../actions/auth/bill_account/bill-account-actions'

import CashOnlyPaymentCard from '../../common_payment/cash-only-payment-card'

import FetchErrorCardContent from '../common/fetch-error-card-content'
import FetchInProgressCardContent from '../common/fetch-in-progress-card-content'
import SinglePaymentFormikConnectedContainer from './single-payment-formik-container'

export class SinglePaymentInputCard extends React.Component {
    constructor(props) {
        super(props)

        this.refetchBankAccountOrEnrolledProgramStatus = this.refetchBankAccountOrEnrolledProgramStatus.bind(this)
    }

    componentDidMount() {
        if (this.props.enrolledProgramsDetailsStatus === loadingStatus.LOADING_STATUS_SUCCESS && this.props.isCashOnly)
            return

        if (this.props.bankAccountListStatus === loadingStatus.LOADING_STATUS_INIT)
            this.props.fetchBankAccountList()

        if (this.props.enrolledProgramsDetailsStatus === loadingStatus.LOADING_STATUS_INIT && this.props.selectedBillAccount !== 0)
            this.props.getEnrolledProgramsDetails(this.props.selectedBillAccount)

        if (this.props.customerNamesStatus === loadingStatus.LOADING_STATUS_INIT && this.props.selectedBillAccount !== 0)
            this.props.getCustomerName(this.props.selectedBillAccount)

        if (this.props.phoneNumberStatus === loadingStatus.LOADING_STATUS_INIT && this.props.selectedBillAccount !== 0)
            this.props.getPhoneNumber(this.props.selectedBillAccount)
    }

    componentDidUpdate(prevProps) {
        if (this.props.selectedBillAccount === prevProps.selectedBillAccount || this.props.selectedBillAccount === 0)
            return

        this.props.getEnrolledProgramsDetails(this.props.selectedBillAccount)
        this.props.getCustomerName(this.props.selectedBillAccount)
        this.props.getPhoneNumber(this.props.selectedBillAccount)
    }

    refetchBankAccountOrEnrolledProgramStatus() {
        if (this.props.bankAccountListStatus === loadingStatus.LOADING_STATUS_FAILURE)
            this.props.fetchBankAccountList()

        if (this.props.enrolledProgramsDetailsStatus === loadingStatus.LOADING_STATUS_FAILURE && this.props.selectedBillAccount !== 0)
            this.props.getEnrolledProgramsDetails(this.props.selectedBillAccount)

        if (this.props.customerNamesStatus === loadingStatus.LOADING_STATUS_FAILURE && this.props.selectedBillAccount !== 0)
            this.props.getCustomerName(this.props.selectedBillAccount)

        if (this.props.phoneNumberStatus === loadingStatus.LOADING_STATUS_FAILURE && this.props.selectedBillAccount !== 0)
            this.props.getPhoneNumber(this.props.selectedBillAccount)
    }

    render() {
        let cardContent
        if (this.props.bankAccountListStatus === loadingStatus.LOADING_STATUS_INIT ||
            this.props.bankAccountListStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS ||
            this.props.enrolledProgramsDetailsStatus === loadingStatus.LOADING_STATUS_INIT ||
            this.props.enrolledProgramsDetailsStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS ||
            this.props.customerNamesStatus === loadingStatus.LOADING_STATUS_INIT ||
            this.props.customerNamesStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS ||
            this.props.phoneNumberStatus === loadingStatus.LOADING_STATUS_INIT ||
            this.props.phoneNumberStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS)
            cardContent = <FetchInProgressCardContent />
        else if (this.props.enrolledProgramsDetailsStatus === loadingStatus.LOADING_STATUS_SUCCESS && this.props.isCashOnly)
            return <CashOnlyPaymentCard />
        else if (this.props.bankAccountListStatus === loadingStatus.LOADING_STATUS_SUCCESS)
            cardContent = <SinglePaymentFormikConnectedContainer t={this.props.t} i18n={this.props.i18n} formDataChanged={this.props.formDataChanged} />
        else
            cardContent = (<FetchErrorCardContent localizedErrorDescription={this.props.t("We were unable to retrieve bank account or program status data.")}
                t={this.props.t} fetchAgainCallback={this.refetchBankAccountOrEnrolledProgramStatus} />)

        return (
            <div>
                <label className="h5">{this.props.t('Payment Information')}</label>
                <Card className="p-4">
                    {cardContent}
                </Card>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    selectedBillAccount: state.accountInfo.billAccount.selectedBillAccount,
    bankAccountListStatus: state.bankAccounts.bankAccountListStatus,
    enrolledProgramsDetailsStatus: state.accountInfo.payment.enrolledProgramsDetailsStatus,
    customerNamesStatus: state.accountInfo.billAccount.customerNamesStatus,
    phoneNumberStatus: state.accountInfo.billAccount.phoneNumberStatus,
    isCashOnly: state.accountInfo.payment.isCashOnly
})
const mapDispatchToProps = (dispatch) => ({
    fetchBankAccountList: () => dispatch(getBankAccountList()),
    getEnrolledProgramsDetails: (billAccount) => dispatch(getEnrolledProgramsDetails(billAccount)),
    getCustomerName: (billAccount) => dispatch(getCustomerNames(billAccount)),
    getPhoneNumber: (billAccount) => dispatch(getPhoneNumber(billAccount)),
})

SinglePaymentInputCard.propTypes = {
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired,
    t: PropTypes.func.isRequired,

    selectedBillAccount: PropTypes.number.isRequired,
    bankAccountListStatus: PropTypes.string.isRequired,
    enrolledProgramsDetailsStatus: PropTypes.string.isRequired,
    customerNamesStatus: PropTypes.string.isRequired,
    phoneNumberStatus: PropTypes.string.isRequired,
    isCashOnly: PropTypes.bool.isRequired,

    formDataChanged: PropTypes.func.isRequired,

    fetchBankAccountList: PropTypes.func.isRequired,
    getEnrolledProgramsDetails: PropTypes.func.isRequired,
    getCustomerName: PropTypes.func.isRequired,
    getPhoneNumber: PropTypes.func.isRequired
}

const ConnectedSinglePaymentInputCard = connect(mapStateToProps, mapDispatchToProps)(SinglePaymentInputCard)
export default ConnectedSinglePaymentInputCard