import React from 'react'
import { withTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import TextField from '@mui/material/TextField'

const BankEmailAddressFieldPure = props => {
    let isError = props.touched['bankEmailAddress']
        && Object.prototype.hasOwnProperty.call(props.errors, 'bankEmailAddress')
    let errMsg = isError ? props.errors['bankEmailAddress'] : ''
    let meta = {
        id: 'bankEmailAddress',
        name: 'bankEmailAddress',
        label: props.t("Email address"),
        helperText: errMsg==="" ? "" : props.t(errMsg),
        error: isError
    }

    let textFieldProps = {
        value: !props.value ? '' : props.value,
        onChange: props.onChange,
        onBlur: props.onBlur,
        errors: props.errors,
        touched: props.touched,
        fullWidth: props.fullWidth
    }

    return (
        <TextField {...meta} {...textFieldProps} type="email" inputProps={{maxLength: 50}} />
    )
}

BankEmailAddressFieldPure.propTypes = {
    errors: PropTypes.shape({
        bankEmailAddress: PropTypes.string
    }).isRequired,
    fullWidth: PropTypes.bool,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired,
    onBlur: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    touched: PropTypes.shape({
        bankEmailAddress: PropTypes.bool
    }).isRequired,
    value: PropTypes.string.isRequired
}

const BankEmailAddressField = withTranslation("singlePurchasePage")(BankEmailAddressFieldPure)
export { BankEmailAddressField as default, BankEmailAddressFieldPure }