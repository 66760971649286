import React from 'react'
import PropTypes from 'prop-types'
import { Formik } from 'formik'

import BankInfoForm from './bank-info-form'

class BankInfoFormik extends React.Component {
    constructor(props) {
        super(props)
    }

    transformValues(values) {
        return {
            ...values,
            bankFirstName: values.bankFirstName.trim(),
            bankLastName: values.bankLastName.trim(),
            bankPhoneNumber: values.bankPhoneNumber.replace(/[() -]/g, ''),
            bankEmailAddress: values.bankEmailAddress.trim(),
            bankRoutingNumber: values.bankRoutingNumber.trim(),
            bankAccountNumberLeadingZeroesAreSignificant: values.bankAccountNumberLeadingZeroesAreSignificant.trim()
        }
    }

    render() {
        return (
            <Formik
                initialValues={{
                    bankFirstName: this.props.bankFirstName,
                    bankLastName: this.props.bankLastName,
                    bankPhoneNumber: this.props.bankPhoneNumber,
                    bankEmailAddress: this.props.bankEmailAddress,
                    bankRoutingNumber: this.props.bankRoutingNumber,
                    bankAccountNumberLeadingZeroesAreSignificant: this.props.bankAccountNumberLeadingZeroesAreSignificant,
                }}
                validationSchema={this.props.bankValidationSchema}
                onSubmit={(bankValues) => {
                    const _bankValues = this.transformValues(bankValues)
                    this.props.storeBankInfo(_bankValues)
                    this.props.handleBankFormClose()
                }}>
                {formikProps => <BankInfoForm {...formikProps} {...this.props} />}
            </Formik>
        )
    }
}

BankInfoFormik.propTypes = {
    bankFirstName: PropTypes.string.isRequired,
    bankLastName: PropTypes.string.isRequired,
    bankPhoneNumber: PropTypes.string.isRequired,
    bankEmailAddress: PropTypes.string.isRequired,
    bankRoutingNumber: PropTypes.string.isRequired,
    bankAccountNumberLeadingZeroesAreSignificant: PropTypes.string.isRequired,

    bankValidationSchema: PropTypes.object.isRequired,

    storeBankInfo: PropTypes.func.isRequired,
    handleBankFormClose: PropTypes.func.isRequired,

    verifyingPaymentList: PropTypes.bool.isRequired
}

export default BankInfoFormik