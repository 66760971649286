import { evCommunityConstants } from '../../../constants/ev-community-constants'
import { RSAA } from 'redux-api-middleware'

import utils from '../../../srp_modules/utils'

export const getVINInformation = (vinNumber) => {
    let url = 'https://vpic.nhtsa.dot.gov/api/vehicles/decodevinvalues/' + vinNumber + '?format=json'

    return dispatch => {
        return dispatch({
            [RSAA]: {
                endpoint: url,
                method: 'GET',
                headers: { 'Content-Type': 'application/json' },
                types: [
                    evCommunityConstants.GET_VIN_INFORMATION_REQUEST,
                    evCommunityConstants.GET_VIN_INFORMATION_SUCCESS,
                    evCommunityConstants.GET_VIN_INFORMATION_FAILURE
                ]
            },
            meta: {
                debounce: {
                    leading: false,
                    trailing: false
                }
            }
        })
    }
}

export const getUnApprovedVehicleTypes = () => {
    let url = utils.createUrl('forms/getunapprovedvehicletypes')

    return dispatch => {
        return dispatch({
            [RSAA]: {
                endpoint: url,
                method: 'GET',
                credentials: 'include',
                types: [
                    evCommunityConstants.GET_UNAPPROVED_VEHICLETYPES_REQUEST,
                    evCommunityConstants.GET_UNAPPROVED_VEHICLETYPES_SUCCESS,
                    evCommunityConstants.GET_UNAPPROVED_VEHICLETYPES_FAILURE,
                ]
            }
        })
    }
}

export const submitEVCommunitySignupForm = (evData) => {
    let url = utils.createUrl('forms/submitevcommunityform')

    let stringData = JSON.stringify(evData)

    return dispatch => {
        return dispatch({
            [RSAA]: {
                endpoint: url,
                body: stringData,
                method: 'POST',
                credentials: 'include',
                headers: { 'Content-Type': 'application/json' },
                types: [
                    evCommunityConstants.SUBMIT_EV_FORM_REQUEST,
                    evCommunityConstants.SUBMIT_EV_FORM_SUCCESS,
                    evCommunityConstants.SUBMIT_EV_FORM_FAILURE,
                ]
            },
            meta: {
                debounce: {
                    leading: false,
                    trailing: false
                }
            }
        })
    }
}

export const resetFormSubmissionStatus = () => dispatch => {
    return dispatch({ type: evCommunityConstants.RESET_FORM_SUBMISSIONS_STATUS })
}