import { economyPricePlanConstants } from '../../../constants/economy-price-plan-constants'
import { billAccountConstants } from '../../../constants/bill-account-constants'

import * as loadingStatus from '../../../constants/loading-status-constants'

const initialState = {
    submitEPPFormResult:
    {
        submitSuccess: false,
        emailSuccess: false
    },
    submitEPPFormStatus: loadingStatus.LOADING_STATUS_INIT
}

export default function economyPricePlanReducer(state = initialState, action) {
    switch (action.type) {

        case economyPricePlanConstants.SUBMIT_EPP_FORM_REQUEST:
            return { ...state, submitEPPFormStatus: loadingStatus.LOADING_STATUS_IN_PROGRESS }
        case economyPricePlanConstants.SUBMIT_EPP_FORM_SUCCESS: {
            let submitSuccessfully = (action.payload?.submitSuccess === true && action.payload?.emailSuccess === true)
            return {
                ...state,
                submitEPPFormResult: action.payload,
                submitEPPFormStatus: submitSuccessfully ? loadingStatus.LOADING_STATUS_SUCCESS : loadingStatus.LOADING_STATUS_FAILURE
            }
        }
        case economyPricePlanConstants.SUBMIT_EPP_FORM_FAILURE:
            return {
                ...state,
                submitEPPFormResult: initialState.submitEPPFormResult,
                submitEPPFormStatus: loadingStatus.LOADING_STATUS_FAILURE
            }

        case billAccountConstants.SELECT_BILL_ACCOUNT_SUCCESS:
            return { ...initialState }

        default:
            return state
    }
}