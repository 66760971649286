/* eslint-disable react/no-did-mount-set-state */
import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import CircularProgress from '@mui/material/CircularProgress'

import { DateTime } from 'luxon'

import {
    getPlanDescription, getSolarChoiceDescription, getCustomDueDateXfer,
    getOwnershipType, mapChargingSupplyType, mapChargingVoltage, mapChargingAmps,
} from '../../../srp_modules/forms/turnon-mapper'

import AccountInfoBarContainer from '../../myaccount_header/info_bar/account-info-bar-container'
import CallAnytime from '../../myaccount_header/call-anytime'

import SelectDatesAndPlanResidential from './select-dates-and-plan-res'
import ServiceAndBillingInfo from './service-billing-info'
import AboutYou from './about-you'
import ReviewTransferServiceInfo from './review-transfer-service-info'
import TransferServiceConfirmation from './transfer-service-confirmation'
import TransferServiceHelp from './transfer-service-help'
import TransferServiceStartUpCosts from './transfer-service-startup-costs'

import { getContacts } from '../../../actions/auth/contact/contact-actions'
import { getCustomerNames, getServiceAddress, getRateCode, getMPowerAccountDetails } from '../../../actions/auth/bill_account/bill-account-actions'
import {
    setActiveStep,
    setEndServiceDate,
    setEndServiceDateTouched as setEndServiceDateTouchedAction,
    setStartServiceDate,
    setStartServiceDateTouched as setStartServiceDateTouchedAction,
    selectSolarChoice,
    selectPricePlan,
    setServiceAndBillingInfo,
    setCustomerInfo,
    setSafetyStmtAgreement,
    sendMPowerBox,
    submitTransferServiceForm
} from '../../../actions/auth/transfer_service/transfer-service-actions'
import { getHolidays } from '../../../actions/guest/form/turn-on-actions'
import { getEnrolledProgramsDetails } from '../../../actions/auth/payment/payment-actions'
import { getAutopayInfo } from '../../../actions/auth/payment/autopay-actions'
import { getNotifications } from '../../../actions/auth/notification/notification-actions'

import { formEvent, formCompleteEvent, formErrorEvent } from '../../../srp_modules/analytics-events'
import { transferServiceConstants } from '../../../constants/transfer-service-constants'
import * as loadingStatus from '../../../constants/loading-status-constants'

import { pricePlanConstants } from '../../../constants/price-plan-constants'
import { myAccountConstants } from '../../../constants/myaccount-constants'

import transferModule from '../../../srp_modules/forms/transfer-module'

let progressCirclestyle = {
    marginTop: "10%",
    marginBottom: "20%"
}

class TransferServiceContainer extends React.Component {
    constructor(props) {
        super(props)

        this.validateDatesAndPlan = this.validateDatesAndPlan.bind(this)
        this.setFormStep = this.setFormStep.bind(this)
        this.submitForm = this.submitForm.bind(this)
        this.state = {
            errorMessage: ''
        }
    }

    componentDidMount() {
        this.props.getHolidaysForBlockedDates()

        if (this.props.contactsStatus === loadingStatus.LOADING_STATUS_SUCCESS) {
            let updatedCustomerInfo = {
                ...this.props.customerInfo,
                emailAddress: this.props.primaryEmail
            }
            this.props.setCustomerInfoOnChange(updatedCustomerInfo)
        }

        if (this.props.selectedBillAccount === 0)
            return

        if (this.props.customerNamesStatus === loadingStatus.LOADING_STATUS_INIT
            || this.props.customerNamesStatus === loadingStatus.LOADING_STATUS_FAILURE) {
            this.props.getCustomerNameInfo(this.props.selectedBillAccount)
        }
        if (this.props.contactsStatus === loadingStatus.LOADING_STATUS_INIT
            || this.props.contactsStatus === loadingStatus.LOADING_STATUS_FAILURE) {
            this.props.getContactInfo(this.props.selectedBillAccount)
        }
        if (this.props.serviceAddressStatus === loadingStatus.LOADING_STATUS_INIT
            || this.props.serviceAddressStatus === loadingStatus.LOADING_STATUS_FAILURE) {
            this.props.getServiceAddressInfo(this.props.selectedBillAccount)
        }

        if (this.props.currentRateCodeStatus === loadingStatus.LOADING_STATUS_INIT
            || this.props.currentRateCodeStatus === loadingStatus.LOADING_STATUS_FAILURE) {
            this.props.getCurrentRateCode(this.props.selectedBillAccount)
        }

        if (this.props.enrolledProgramsDetailsStatus === loadingStatus.LOADING_STATUS_INIT
            || this.props.enrolledProgramsDetailsStatus === loadingStatus.LOADING_STATUS_FAILURE) {
            this.props.getEnrolledProgramsDetailsInfo(this.props.selectedBillAccount)
        }

        if (this.props.notificationsStatus === loadingStatus.LOADING_STATUS_INIT
            || this.props.notificationsStatus === loadingStatus.LOADING_STATUS_FAILURE) {
            this.props.getNotificationsInfo(this.props.selectedBillAccount)
        }

        if (this.props.enrolledProgramsDetailsStatus === loadingStatus.LOADING_STATUS_SUCCESS
            && this.props.enrolledProgramsDetails.autopay.isEnrolled) {
            if (this.props.autopayInfoStatus === loadingStatus.LOADING_STATUS_INIT
                || this.props.autopayInfoStatus === loadingStatus.LOADING_STATUS_FAILURE) {
                this.props.getAutopayDetails(this.props.selectedBillAccount)
            }
        }

        if (this.props.currentRateCode.isPrepay &&
            (this.props.mPowerAcctDetailsStatus === loadingStatus.LOADING_STATUS_INIT
                || this.props.mPowerAcctDetailsStatus === loadingStatus.LOADING_STATUS_FAILURE)) {
            this.props.loadMPowerAccountDetails(this.props.selectedBillAccount)
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.contactsStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS
            && nextProps.contactsStatus === loadingStatus.LOADING_STATUS_SUCCESS) {
            let updatedCustomerInfo = {
                ...this.props.customerInfo,
                emailAddress: nextProps.primaryEmail
            }
            this.props.setCustomerInfoOnChange(updatedCustomerInfo)
        }

        if (this.props.enrolledProgramsDetailsStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS
            && nextProps.enrolledProgramsDetailsStatus === loadingStatus.LOADING_STATUS_SUCCESS
            && nextProps.enrolledProgramsDetails.autopay.isEnrolled) {
            if (nextProps.autopayInfoStatus === loadingStatus.LOADING_STATUS_INIT
                || nextProps.autopayInfoStatus === loadingStatus.LOADING_STATUS_FAILURE) {
                this.props.getAutopayDetails(this.props.selectedBillAccount)
            }
        }

        if (this.props.selectedBillAccount === nextProps.selectedBillAccount || nextProps.selectedBillAccount === 0)
            return

        if (nextProps.customerNamesStatus !== loadingStatus.LOADING_STATUS_IN_PROGRESS) {
            this.props.getCustomerNameInfo(nextProps.selectedBillAccount)
        }

        if (nextProps.contactsStatus !== loadingStatus.LOADING_STATUS_IN_PROGRESS) {
            this.props.getContactInfo(nextProps.selectedBillAccount)
        }

        if (nextProps.serviceAddressStatus !== loadingStatus.LOADING_STATUS_IN_PROGRESS) {
            this.props.getServiceAddressInfo(nextProps.selectedBillAccount)
        }

        if (nextProps.currentRateCodeStatus !== loadingStatus.LOADING_STATUS_IN_PROGRESS) {
            this.props.getCurrentRateCode(nextProps.selectedBillAccount)
        }

        if (nextProps.enrolledProgramsDetailsStatus !== loadingStatus.LOADING_STATUS_IN_PROGRESS) {
            this.props.getEnrolledProgramsDetailsInfo(nextProps.selectedBillAccount)
        }

        if (nextProps.notificationsStatus !== loadingStatus.LOADING_STATUS_IN_PROGRESS) {
            this.props.getNotificationsInfo(nextProps.selectedBillAccount)
        }

        if (nextProps.enrolledProgramsDetailsStatus === loadingStatus.LOADING_STATUS_SUCCESS
            && nextProps.enrolledProgramsDetails.autopay.isEnrolled) {
                if (nextProps.autopayInfoStatus !== loadingStatus.LOADING_STATUS_IN_PROGRESS) {
                    this.props.getAutopayDetails(nextProps.selectedBillAccount)
                }
        }
    }

    componentDidUpdate(prevProps) {
        if ((this.props.submitTransferServiceStatus === loadingStatus.LOADING_STATUS_SUCCESS && this.state.errorMessage === '')
            || this.props.submitTransferServiceStatus === loadingStatus.LOADING_STATUS_FAILURE) {
            document.getElementById("topOfConfirmation").scrollIntoView()
        }

        if ((this.props.selectedBillAccount === prevProps.selectedBillAccount || this.props.selectedBillAccount === 0)
            && !(this.props.currentRateCodeStatus !== prevProps.currentRateCodeStatus && this.props.currentRateCodeStatus === loadingStatus.LOADING_STATUS_SUCCESS))
            return
  

        if (this.props.currentRateCode.isPrepay
            && this.props.mPowerAcctDetailsStatus === loadingStatus.LOADING_STATUS_INIT) {
            this.props.loadMPowerAccountDetails(this.props.selectedBillAccount)
        }
    }

    validateDatesAndPlan(e, endDateError, startDateError, activeStep) {
        e.preventDefault()
        let error = 0

        if (endDateError || startDateError || this.props.evYearError) {
            error++
        }
        else {
            if (this.props.endServiceDate === null) {
                this.props.setEndServiceDateOnChange(this.props.endServiceDate)
                error++
            }

            if (this.props.startServiceDate === null) {
                this.props.setStartServiceDateOnChange(this.props.startServiceDate)
                error++
            }
        }

        if (error === 0) {
            let nextStep = activeStep + 1
            this.setFormStep(nextStep)
        }
    }

    setFormStep(step) {
        this.props.setActiveStepOnClick(step)
        document.getElementById("topOfForm").scrollIntoView()

        formEvent(transferServiceConstants.ADOBE_ANALYTICS_FORM_NAME, transferServiceConstants.getStepName(step))
    }

    getMetaData() {
        return {
            serviceEndDate: DateTime.fromISO(this.props.endServiceDate).toFormat('yyyy-MM-dd'),
            serviceEndDay: DateTime.fromISO(this.props.endServiceDate).toFormat('EEEE'),
            serviceStartDate: DateTime.fromISO(this.props.startServiceDate).toFormat('yyyy-MM-dd'),
            serviceStartDay: DateTime.fromISO(this.props.startServiceDate).toFormat('EEEE'),

            newRatePlan: this.props.selectedPricePlanResidential === this.props.currentRateCode.rateCode
                ? ""
                : getPlanDescription(this.props.selectedPricePlanResidential),
            evDetails: (this.props.selectedPricePlanResidential === pricePlanConstants.PRICE_PLAN_RATE_CODE_MAP.EV)
            ?
            {
                make: this.props.electricVehicleMake,
                model: this.props.electricVehicleModel,
                year: this.props.electricVehicleYear,
                ownershipType: getOwnershipType(this.props.evOwnershipType),
                supplyEquipmentType: mapChargingSupplyType(this.props.evEquipmentType),
                inputVoltage: mapChargingVoltage(this.props.evInputVoltage),
                inputCurrent: mapChargingAmps(this.props.evInputCurrent),
            }
            :
            {},

            solarChoice: getSolarChoiceDescription(this.props.selectedSolarChoice),

            serviceAndMailingAddressSame: this.props.serviceBillingInfo.serviceAddIsMailingAdd,
            maintainSurePay: this.props.serviceBillingInfo.maintainAutopay,
            maintainPaperlessBill: this.props.serviceBillingInfo.maintainPaperlessBill,
            maintainCustomDueDate: this.props.serviceBillingInfo.maintainCustomDueDate,
            newCustomDueDate: getCustomDueDateXfer(this.props.enrolledProgramsDetails.customDueDate.isEnrolled,
                this.props.serviceBillingInfo.maintainCustomDueDate,
                this.props.serviceBillingInfo.customDueDate),

            idType: transferModule.getIDTypeDisplay(this.props.customerInfo.identificationType),

            wantMPowerBox: this.props.sendMPowerBox,
        }
    }

    async submitForm() {
        let transferModel = transferModule.createTransferServiceModel(this.props)
        const result = await this.props.submitTransferServiceFormOnClick(transferModel)

        if (result.payload.success === true) {
            this.setState({errorMessage: ''})

            const meta = this.getMetaData()
            formCompleteEvent(transferServiceConstants.ADOBE_ANALYTICS_FORM_NAME, meta)
        }
        else {
            if (result.payload.success === false && typeof result.payload.message !== "undefined") {
                const message = result.payload.message
                if (message) {
                    let errorText = ''
                    if (message.includes('start') && message.includes('stop')) {
                        errorText = this.props.t("turn-on and turn-off")
                    } else if (message.includes('start')) {
                        errorText = this.props.t("turn-on")
                    } else if (message.includes('stop')) {
                        errorText = this.props.t("turn-off")
                    }

                    this.setState({errorMessage: `${this.props.t("please_select_valid")} ${errorText} ${this.props.t("date")}.`})
                    this.setFormStep(0)
                }
            }

            formErrorEvent(transferServiceConstants.ADOBE_ANALYTICS_FORM_NAME)
        }
    }

    getFormDetails() {
        if (this.props.customerNamesStatus === loadingStatus.LOADING_STATUS_INIT || this.props.customerNamesStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS
            || this.props.contactsStatus === loadingStatus.LOADING_STATUS_INIT || this.props.contactsStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS
            || this.props.serviceAddressStatus === loadingStatus.LOADING_STATUS_INIT || this.props.serviceAddressStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS
            || this.props.currentRateCodeStatus === loadingStatus.LOADING_STATUS_INIT || this.props.currentRateCodeStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS
            || this.props.enrolledProgramsDetailsStatus === loadingStatus.LOADING_STATUS_INIT || this.props.enrolledProgramsDetailsStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS
            || this.props.notificationsStatus === loadingStatus.LOADING_STATUS_INIT || this.props.notificationsStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS
            || (this.props.currentRateCode.isPrepay && (this.props.mPowerAcctDetailsStatus === loadingStatus.LOADING_STATUS_INIT || this.props.mPowerAcctDetailsStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS))
            || this.props.autopayInfoStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS) {
            return <div className="d-flex justify-content-center"><CircularProgress size={80} thickness={5} style={progressCirclestyle} /></div>
        }

        return (
            <div className="row">
                <div className="col-lg-7 col-12">
                    <div className="srp-card-header" id="topOfForm">{transferServiceConstants.TRANSFER_SERVICE_STEPS[this.props.activeStep].title}</div>
                    <div className="srp-card-body">
                        <div className="srp-card-details">
                            <div className="d-lg-block d-none">
                                <div className="col-12 p-0 pb-4">
                                    <Stepper activeStep={this.props.activeStep} className="p-0" sx={{'& .MuiStep-root': {padding: '0 2px'}, '& .MuiStepConnector-root' : {margin:'0 6px'}}}>
                                        {transferServiceConstants.TRANSFER_SERVICE_STEPS.map(item => {
                                            return (
                                                <Step key={item.step}>
                                                    <StepLabel >{this.props.i18n.language === "es" ? item.labelEs: item.labelEn}</StepLabel>
                                                </Step>
                                            )
                                        })}
                                    </Stepper>
                                </div>
                            </div>
                            <div className="d-lg-none d-block">
                                <div className="col-12 p-0 pb-4">
                                    <Stepper activeStep={this.props.activeStep} alternativeLabel className="p-0">
                                        {transferServiceConstants.TRANSFER_SERVICE_STEPS.map(item => {
                                            return (
                                                <Step key={item.step}>
                                                    <StepLabel className="stepper-label-mobile">
                                                        {this.props.i18n.language === "es" ? item.labelMobileEs: item.labelMobileEn}
                                                    </StepLabel>
                                                </Step>
                                            )
                                        })}
                                    </Stepper>
                                </div>
                            </div>
                            {this.props.activeStep === 0
                                ? <SelectDatesAndPlanResidential
                                    activeStep={this.props.activeStep}
                                    validateDatesAndPlan={this.validateDatesAndPlan}
                                    serviceAddress={this.props.serviceAddress.streetAddress}
                                    currentRateCode={this.props.currentRateCode}
                                    isMPower={this.props.currentRateCode.isPrepay}
                                    currentSolarChoice={this.props.enrolledProgramsDetails.solarChoice}
                                    holidayList={this.props.holidayList}
                                    endServiceDate={this.props.endServiceDate}
                                    setEndServiceDateOnChange={this.props.setEndServiceDateOnChange}
                                    setEndServiceDateTouched={this.props.setEndServiceDateTouched}
                                    endDateError={this.props.endDateError}
                                    endDateErrorMessage={this.props.endDateErrorMessage}
                                    startServiceDate={this.props.startServiceDate}
                                    setStartServiceDateOnChange={this.props.setStartServiceDateOnChange}
                                    setStartServiceDateTouched={this.props.setStartServiceDateTouched}
                                    startDateError={this.props.startDateError}
                                    startDateErrorMessage={this.props.startDateErrorMessage}
                                    selectedPricePlanResidential={this.props.selectedPricePlanResidential}
                                    selectPricePlanOnClick={this.props.selectPricePlanOnClick}
                                    selectedSolarChoice={this.props.selectedSolarChoice}
                                    selectSolarChoiceOnClick={this.props.selectSolarChoiceOnClick}
                                    errorMessage={this.state.errorMessage} />
                                : this.props.activeStep === 1
                                    ? <ServiceAndBillingInfo
                                        activeStep={this.props.activeStep}
                                        setFormStep={this.setFormStep}
                                        billAccount={this.props.selectedBillAccount}
                                        isMPower={this.props.currentRateCode.isPrepay}
                                        customerNames={this.props.customerNames}
                                        serviceAddress={this.props.serviceAddress}
                                        endServiceDate={this.props.endServiceDate}
                                        startServiceDate={this.props.startServiceDate}
                                        serviceBillingInfo={this.props.serviceBillingInfo}
                                        setServiceAndBillingInfoOnChange={this.props.setServiceAndBillingInfoOnChange}
                                        currentAutoPayEnrollment={this.props.enrolledProgramsDetails.autopay}
                                        lastFourAutopayBank={this.props.autopayInfo.bankAccount.bankAccountNumber}
                                        currentCustomDueDateEnrollment={this.props.enrolledProgramsDetails.customDueDate}
                                        hasEBill={this.props.eBillState} />
                                    : this.props.activeStep === 2
                                        ? <AboutYou
                                            activeStep={this.props.activeStep}
                                            setFormStep={this.setFormStep}
                                            customerInfo={this.props.customerInfo}
                                            setCustomerInfoOnChange={this.props.setCustomerInfoOnChange} />
                                        : <ReviewTransferServiceInfo
                                            activeStep={this.props.activeStep}
                                            setFormStep={this.setFormStep}
                                            billAccount={this.props.selectedBillAccount}
                                            isMPower={this.props.currentRateCode.isPrepay}
                                            currentServiceAddress={this.props.serviceAddress}
                                            startServiceDate={this.props.startServiceDate}
                                            endServiceDate={this.props.endServiceDate}
                                            selectedPricePlan={this.props.selectedPricePlanResidential}
                                            isOnCentralPrepay={this.props.centralPrepayStatus==='PrepayCentral'}
                                            selectedSolarChoice={this.props.selectedSolarChoice}
                                            currentAutoPayEnrollment={this.props.enrolledProgramsDetails.autopay}
                                            currentCustomDueDateEnrollment={this.props.enrolledProgramsDetails.customDueDate}
                                            hasEBill={this.props.eBillState}
                                            serviceBillingInfo={this.props.serviceBillingInfo}
                                            customerInfo={this.props.customerInfo}
                                            agreeToSafetyStmt={this.props.agreeToSafetyStmt}
                                            setSafetyStmtAgreementOnClick={this.props.setSafetyStmtAgreementOnClick}
                                            sendMPowerBox={this.props.sendMPowerBox}
                                            sendMPowerBoxOnClick={this.props.sendMPowerBoxOnClick}
                                            submitTransferServiceStatus={this.props.submitTransferServiceStatus}
                                            submitForm={this.submitForm} />
                            }
                        </div>
                    </div>
                </div>

                <div className="col-lg-5 col-12 mt-lg-0 mt-4">
                    {this.props.activeStep !== 3 &&
                          <TransferServiceHelp
                            customerServicePhone={this.props.i18n.language === "es" ? myAccountConstants.SPANISH_CUSTOMER_SERVICE_PHONE_NUMBER : myAccountConstants.RESIDENTIAL_CUSTOMER_SERVICE_PHONE_NUMBER}
                            customerServicePhoneFmttd={this.props.i18n.language === "es" ? myAccountConstants.SPANISH_CUSTOMER_SERVICE_PHONE_NUMBER_FMTTD : myAccountConstants.RESIDENTIAL_CUSTOMER_SERVICE_PHONE_NUMBER_FMTTD} />
                    }
                    {this.props.activeStep === 3 && this.props.selectedPricePlanResidential !== pricePlanConstants.PRICE_PLAN_RATE_CODE_MAP.MPOWER &&
                          <TransferServiceStartUpCosts pricePlan={this.props.selectedPricePlanResidential} />
                    }
                </div>
            </div>)
    }

    render() {
        if (this.props.submitTransferServiceStatus === loadingStatus.LOADING_STATUS_INIT
            || this.props.submitTransferServiceStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS
            || (this.props.submitTransferServiceStatus === loadingStatus.LOADING_STATUS_SUCCESS && this.state.errorMessage !== '')) {
            return (
                <div>
                    <div className="container py-3">
                        <CallAnytime />
                        <h3 className="d-lg-block d-none mt-3 mb-4 pb-4">{this.props.t("Transfer my electric service")}</h3>
                        <h3 className="d-lg-none d-block mt-3 mb-3">{this.props.t("Transfer my electric service")}</h3>

                        <AccountInfoBarContainer disableSelector={true} />

                        {this.getFormDetails()}

                    </div>
                </div>
            )
        }
        else {
            return (
                <TransferServiceConfirmation
                    submitTransferServiceStatus={this.props.submitTransferServiceStatus} />
            )
        }
    }
}

const mapStateToProps = state => {
    return {
        ...state.transferService,
        ...state.turnOn,
        ...state.accountInfo.billAccount,
        centralPrepayStatus: state.accountInfo.billAccount.mPowerAcctDetails.centralPrepayStatus ?? '',
        mPowerAcctDetailsStatus: state.accountInfo.billAccount.mPowerAcctDetailsStatus,
        ...state.accountInfo.contact,
        ...state.accountInfo.payment,
        ...state.accountInfo.autopay,
        ...state.accountInfo.notification,
        ...state.myAccount
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getContactInfo: (billAccount) => {
            dispatch(getContacts(billAccount))
        },
        getCustomerNameInfo: (billAccount) => {
            dispatch(getCustomerNames(billAccount))
        },
        getServiceAddressInfo: (billAccount) => {
            dispatch(getServiceAddress(billAccount))
        },
        getCurrentRateCode: (billAccount) => {
            dispatch(getRateCode(billAccount))
        },
        getEnrolledProgramsDetailsInfo: (billAccount) => {
            dispatch(getEnrolledProgramsDetails(billAccount))
        },
        getNotificationsInfo: (billAccount) => {
            dispatch(getNotifications(billAccount))
        },
        getAutopayDetails: (billAccount) => {
            dispatch(getAutopayInfo(billAccount))
        },
        loadMPowerAccountDetails: (billAccount) =>
            dispatch(getMPowerAccountDetails(billAccount)),
        setActiveStepOnClick: (step) => {
            dispatch(setActiveStep(step))
        },
        setEndServiceDateOnChange: (endDate) => {
            dispatch(setEndServiceDate(endDate))
        },
        setEndServiceDateTouched: () => {
            dispatch(setEndServiceDateTouchedAction())
        },
        setStartServiceDateOnChange: (startDate) => {
            dispatch(setStartServiceDate(startDate))
        },
        setStartServiceDateTouched: () => {
            dispatch(setStartServiceDateTouchedAction())
        },
        selectSolarChoiceOnClick: (solarChoice) => {
            dispatch(selectSolarChoice(solarChoice))
        },
        selectPricePlanOnClick: (pricePlan) => {
            dispatch(selectPricePlan(pricePlan))
        },
        getHolidaysForBlockedDates: () => {
            dispatch(getHolidays())
        },
        setServiceAndBillingInfoOnChange: (info) => {
            dispatch(setServiceAndBillingInfo(info))
        },
        setCustomerInfoOnChange: (custInfo) => {
            dispatch(setCustomerInfo(custInfo))
        },
        setSafetyStmtAgreementOnClick: (doesAgree) => {
            dispatch(setSafetyStmtAgreement(doesAgree))
        },
        sendMPowerBoxOnClick: (sendBox) => {
            dispatch(sendMPowerBox(sendBox))
        },
        submitTransferServiceFormOnClick: (form) =>
            dispatch(submitTransferServiceForm(form)),
    }
}

TransferServiceContainer.propTypes = {
    selectedBillAccount: PropTypes.number,

    customerNames: PropTypes.object,
    customerNamesStatus: PropTypes.string,
    getCustomerNameInfo: PropTypes.func,

    primaryEmail: PropTypes.string,
    contactsStatus: PropTypes.string,
    getContactInfo: PropTypes.func,

    serviceAddress: PropTypes.object,
    serviceAddressStatus: PropTypes.string,
    getServiceAddressInfo: PropTypes.func,

    currentRateCode: PropTypes.object,
    currentRateCodeStatus: PropTypes.string,
    getCurrentRateCode: PropTypes.func,

    enrolledProgramsDetails: PropTypes.object,
    enrolledProgramsDetailsStatus: PropTypes.string,
    getEnrolledProgramsDetailsInfo: PropTypes.func,

    eBillState: PropTypes.bool,
    notificationsStatus: PropTypes.string,
    getNotificationsInfo: PropTypes.func,

    autopayInfo: PropTypes.object,
    autopayInfoStatus: PropTypes.string,
    getAutopayDetails: PropTypes.func,

    activeStep: PropTypes.number,
    setActiveStepOnClick: PropTypes.func,

    endServiceDate: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.string
    ]),
    setEndServiceDateOnChange: PropTypes.func,
    setEndServiceDateTouched: PropTypes.func,
    endDateError: PropTypes.bool,
    endDateTouched: PropTypes.bool,
    endDateErrorMessage: PropTypes.string,

    startServiceDate: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.string
    ]),
    setStartServiceDateOnChange: PropTypes.func,
    setStartServiceDateTouched: PropTypes.func,
    startDateError: PropTypes.bool,
    startDateTouched: PropTypes.bool,
    startDateErrorMessage: PropTypes.string,

    electricVehicleMake: PropTypes.string.isRequired,
    electricVehicleModel: PropTypes.string.isRequired,
    electricVehicleYear: PropTypes.string,
    evOwnershipType: PropTypes.string.isRequired,
    evEquipmentType: PropTypes.string.isRequired,
    evInputVoltage: PropTypes.string.isRequired,
    evInputCurrent: PropTypes.string.isRequired,

    evYearError: PropTypes.bool,

    selectedPricePlanResidential: PropTypes.number,
    selectPricePlanOnClick: PropTypes.func,

    selectedSolarChoice: PropTypes.string,
    selectSolarChoiceOnClick: PropTypes.func,

    holidayList: PropTypes.array,
    getHolidaysForBlockedDates: PropTypes.func,

    serviceBillingInfo: PropTypes.object,
    setServiceAndBillingInfoOnChange: PropTypes.func,

    customerInfo: PropTypes.object,
    setCustomerInfoOnChange: PropTypes.func,

    agreeToSafetyStmt: PropTypes.bool,
    setSafetyStmtAgreementOnClick: PropTypes.func,

    sendMPowerBox: PropTypes.bool,
    sendMPowerBoxOnClick: PropTypes.func,

    submitTransferServiceStatus: PropTypes.string,
    submitTransferServiceFormOnClick: PropTypes.func,

    loadMPowerAccountDetails: PropTypes.func.isRequired,
    mPowerAcctDetailsStatus: PropTypes.string.isRequired,
    centralPrepayStatus: PropTypes.string.isRequired,
    
    t: PropTypes.func,
    i18n: PropTypes.object,
}

export default withTranslation('transferService')(connect(mapStateToProps, mapDispatchToProps)(TransferServiceContainer))