import React from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

const LowBalanceNotifications = ({cssClass, t}) => {
  return (
    <div className={cssClass}>
      <div className="section-header">{t("Low Balance Notifications")}</div>

      <div className="mt-2">
      {t("SRP_requires_all_SRP_MPower_customers")}

      <ul className="mt-3">
        <li>
          {t("Email or Text via SRP My Account™")}
        </li>
        <li>{t("Push notifications via the SRP M-Power app")}</li>
      </ul>

      {t("Please_subscribe_to_at_least_one")}
      </div>
    </div>
  )
}
LowBalanceNotifications.propTypes = {
  cssClass: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
}

export default withTranslation('transferService')(LowBalanceNotifications)