import React from 'react'
import PropTypes from 'prop-types'
import { Formik } from 'formik'

import SelectDonation from './select-donation'

import { shareConstants } from '../../../constants/share-constants'

class SelectDonationFormik extends React.Component {

    render() {
        return (
            <Formik
                initialValues={{
                    otherAmountMonthly: this.props.otherDonationAmount,
                    otherAmountOneTime: this.props.otherDonationAmount,
                }}
                validate={(values) => validateForm(values, this.props.donationType, this.props.donationAmount)}
                onSubmit={() => {
                    this.props.setActiveStepOnClick(this.props.activeStep + 1)
                }}>
              {formikProps => (<SelectDonation {...formikProps} {...this.props} />)}
            </Formik>
        )
    }
}

SelectDonationFormik.propTypes = {
    activeStep: PropTypes.number,
    setActiveStepOnClick: PropTypes.func,
    donationType: PropTypes.number,
    setDonationTypeOnClick: PropTypes.func,
    donationAmount: PropTypes.number,
    setDonationAmountOnClick: PropTypes.func,
    otherDonationAmount: PropTypes.string,
    setOtherDonationAmountOnChange: PropTypes.func,

    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired,
}

export default SelectDonationFormik

function validateForm(values, selectedDonationType, selectedDonationAmount) {
    let errors = {}

    if (selectedDonationType === shareConstants.DONATION_TYPE.MONTHLY
        && selectedDonationAmount === shareConstants.DONATION_AMOUNT.OTHER) {
        let amount = Number(values.otherAmountMonthly)
        if (amount < 1 || amount > 99) {
            errors.otherAmountMonthly = 'Please enter an amount between $1 and $99'
        }
    }

    if (selectedDonationType === shareConstants.DONATION_TYPE.ONE_TIME
        && selectedDonationAmount === shareConstants.DONATION_AMOUNT.OTHER) {
        let amount = Number(values.otherAmountOneTime)
        if (amount < 1 || amount > 99) {
            errors.otherAmountOneTime = 'Please enter an amount between $1 and $99'
        }
    }

    return errors
}