import { giftPaymentListConstants } from '../../../constants/gift/gift-payment-list-constants'

const initialState = {
    paymentList: [],
    multiplePaymentsEnabled: false,
    formStartEventEmitted: false,
}

export default function giftPaymentListReducer(state = initialState, action) {
    switch (action.type) {
        case giftPaymentListConstants.ADD_GIFT_PAYMENT_TO_LIST:
            return { ...state,
                paymentList: [...state.paymentList, {srpAccountNumber: action.srpAccountNumber, paymentAmount: action.paymentAmount}]
            }
        case giftPaymentListConstants.STORE_GIFT_PAYMENT_LIST:
            return { ...state,
                paymentList: action.paymentList
            }
        case giftPaymentListConstants.CLEAR_GIFT_PAYMENT_LIST:
            return { ...state,
                paymentList: []
            }
        case giftPaymentListConstants.ENABLE_MULTIPLE_GIFT_PAYMENTS:
            return { ...state,
                multiplePaymentsEnabled: true
            }
        case giftPaymentListConstants.DISABLE_MULTIPLE_GIFT_PAYMENTS:
            return { ...state,
                multiplePaymentsEnabled: false
            }
        case giftPaymentListConstants.SET_FORM_START_EVENT_EMITTED:
            return { ...state,
                formStartEventEmitted: true
            }
        case giftPaymentListConstants.CLEAR_FORM_START_EVENT_EMITTED:
            return { ...state,
                formStartEventEmitted: false
            }
        default:
            return state
    }
}