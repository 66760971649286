import React from "react"
import PropTypes from 'prop-types'

import { DateTime } from 'luxon'

import PaymentDetails from './payment-details'

const RecentPayments = ({
    recentPayment,
    bankAccountList,
    makeAnotherPayment,
    scheduledBy,
    onFirstRecentPayment, 
    onLastRecentPayment,
    goToPreviousRecentPayment, 
    goToNextRecentPayment,
    t,
    i18n}) =>
(
<div>
    <h4 className="text-secondary mb-3">{t("Recent payments")}</h4>

    <div className="srp-alert-warn mb-4">
        {t("At least one payment")}
    </div>

    <PaymentDetails
        paymentAmount={recentPayment.paymentAmount}
        paymentDate={DateTime.fromISO(recentPayment.paymentDate).startOf('day').toFormat('yyyy-MM-dd')}
        fundingSourceId={recentPayment.fundingSourceId}
        scheduledVia={recentPayment.scheduledVia}
        referenceNumber={recentPayment.referenceNumber}
        bankAccountList={bankAccountList}
        scheduledBy={scheduledBy}
        t={t}
        i18n={i18n}
    />

    <div className="d-flex flex-column flex-lg-row justify-content-lg-end mt-4">
        {(!onFirstRecentPayment || !onLastRecentPayment) &&
        <div className="d-flex justify-content-between mb-2 mb-lg-0">
        <button type="button" disabled={onFirstRecentPayment} className="btn srp-btn btn-lightblue mr-2" onClick={goToPreviousRecentPayment}><strong>&lt;</strong></button>
        <button type="button" disabled={onLastRecentPayment} className="btn srp-btn btn-lightblue mr-0 mr-lg-2" onClick={goToNextRecentPayment}><strong>&gt;</strong></button>
        </div>
        }
        <div className="d-flex justify-content-end">
        <button type="button" className="btn srp-btn btn-lightblue" onClick={makeAnotherPayment}>{t("Make payment")}</button>
        </div>
    </div>
</div>
)

RecentPayments.propTypes = {
    recentPayment: PropTypes.shape({
        paymentAmount: PropTypes.number.isRequired,
        paymentDate: PropTypes.string.isRequired,
        fundingSourceId: PropTypes.number.isRequired,
        scheduledVia: PropTypes.string.isRequired,
        referenceNumber: PropTypes.number.isRequired,
    }).isRequired,
    bankAccountList: PropTypes.arrayOf(PropTypes.object).isRequired,
    scheduledBy: PropTypes.string.isRequired,
    onFirstRecentPayment: PropTypes.bool.isRequired,
    onLastRecentPayment: PropTypes.bool.isRequired,
    goToPreviousRecentPayment: PropTypes.func.isRequired,
    goToNextRecentPayment: PropTypes.func.isRequired,
    makeAnotherPayment: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    i18n: PropTypes.object.isRequired
}

export default RecentPayments