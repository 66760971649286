import React from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import Calendar from './../calendar'
import StartServiceStepper from './start-stepper'

const DatesPlan = ({ currentStep, errorMessage, holidayList, planDateChange, onDateInitialized, startDate, t }) => {
  let errorMessageDiv = null
  {
    if (errorMessage && errorMessage != '') {
      errorMessageDiv = (<div className="mb-3 w-100">
        <div className="srp-alert-error">{t(errorMessage)}</div>
      </div>)
    }
  }

  return (<div>
    <StartServiceStepper
      currentStep={currentStep}
    />
    {errorMessageDiv}
    <div className="mb-2 text-muted">
      {t("select_a_day")}
    </div>
    <div className="row justify-content-center">
      <Calendar
        accentColor={'#087ed8'}
        holidayList={holidayList}
        onDatePicked={(d) => {
          planDateChange(d)
        }}
        onDateInitialized={onDateInitialized}
        selectedDate={startDate}
      />
    </div>
  </div>)
}

DatesPlan.propTypes = {
  currentStep: PropTypes.number,
  errorMessage: PropTypes.string,
  holidayList: PropTypes.arrayOf(PropTypes.string).isRequired,
  planDateChange: PropTypes.func.isRequired,
  onDateInitialized: PropTypes.func.isRequired,
  startDate: PropTypes.instanceOf(Date).isRequired,
  t: PropTypes.func
}

export default withTranslation('startService')(DatesPlan)