import React from "react"
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'

import guestPaymentIconGrey from '../../../images/payment/payment-grey.png'
import guestPaymentIconWhite from '../../../images/payment/payment-white.png'

const iconSize = {
    height: "32px", width: "32px"
}

const mobileIconSize = {
    height: "24px", width: "24px"
}

const PayAsGuestLink = ({ t }) => (
    <div>
        <div className="d-none d-lg-block">
            <NavLink to={{ pathname: "/myaccount/payment/guest" }} className="nav-link flex-column align-items-center">
                <img src={guestPaymentIconGrey} style={iconSize} className="icon" />
                <img src={guestPaymentIconWhite} style={iconSize} className="iconSelected" />
                <div>{t("Pay as guest")}</div>
            </NavLink>
        </div>
        <div className="d-block d-lg-none">
            <NavLink
                to={{ pathname: "/myaccount/payment/guest" }}
                className="nav-link nav-link-mobile d-flex justify-content-start align-items-center p-1">
                <img src={guestPaymentIconGrey} style={mobileIconSize} className="icon ml-1 mr-2" />
                <img src={guestPaymentIconWhite} style={mobileIconSize} className="iconSelected ml-1 mr-2" />
                <span>{t("Pay as guest")}</span>
            </NavLink>
        </div>
    </div>
)

PayAsGuestLink.propTypes = {
    t: PropTypes.func.isRequired,
}

export default PayAsGuestLink