import React from 'react'
import { RSAA } from 'redux-api-middleware'
import { SKIP_BILL_ACCOUNT_CHECK } from '../../../srp_modules/middleware/bill-account-middleware'
import { billAccountConstants } from '../../../constants/bill-account-constants'
import { pricePlanConstants } from '../../../constants/price-plan-constants'
import { addSnackbarMessage, dismissSnackbarMessage } from '../../common/snackbar'
import utils from '../../../srp_modules/utils'
import { viewChangedEvent } from '../../../srp_modules/analytics-events'
import { getRateMetaData } from '../usage/rate-meta-data-actions'

export const getBillAccounts = () => dispatch => {
    return dispatch({
        [RSAA]: {
            endpoint: utils.createUrl('accounts/all?includeWater=true'),
            method: 'GET',
            credentials: 'include',
            types: [
                billAccountConstants.GET_LIST_REQUEST,
                billAccountConstants.GET_LIST_SUCCESS,
                billAccountConstants.GET_LIST_FAILURE
            ]
        },
        [SKIP_BILL_ACCOUNT_CHECK]: true,
        meta: {
            debounce: {
                key: billAccountConstants.GET_LIST_REQUEST,
                time: 100,
                leading: false,
                trailing: true
            }
        }
    })
}

export const getUnlinkedBillAccounts = () => dispatch => {
    return dispatch({
        [RSAA]: {
            endpoint: utils.createUrl('accounts/findothers'),
            method: 'GET',
            credentials: 'include',
            types: [
                billAccountConstants.GET_UNLINKED_ACCOUNTS_REQUEST,
                billAccountConstants.GET_UNLINKED_ACCOUNTS_SUCCESS,
                billAccountConstants.GET_UNLINKED_ACCOUNTS_FAILURE
            ]
        },
        [SKIP_BILL_ACCOUNT_CHECK]: true
    })
}

export const updateSelectedUnlinkedBillAccounts = (selectedUnlinkedBillAccounts) => {
    return {
        type: billAccountConstants.UPDATE_SELCTED_UNLINKED_ACCOUNTS,
        selectedUnlinkedBillAccounts
    }
}

export const addBillAccount = (billAccount, phoneNumber, nickname) => dispatch => {
    return dispatch({
        [RSAA]: {
            endpoint: utils.createUrl('accounts/attach/userid', { billAccount, phoneNumber, nickName: nickname }),
            method: 'POST',
            credentials: 'include',
            types: [
                billAccountConstants.ADD_BILL_ACCOUNT_REQUEST,
                billAccountConstants.ADD_BILL_ACCOUNT_SUCCESS,
                billAccountConstants.ADD_BILL_ACCOUNT_FAILURE
            ]
        },
        [SKIP_BILL_ACCOUNT_CHECK]: true,
        meta: {
            debounce: {
                key: `${billAccountConstants.ADD_BILL_ACCOUNT_REQUEST}_${billAccount}`,
                time: 100,
                leading: true,
                trailing: false
            }
        }
    })
}

export const addMultipleBillAccounts = accountsToAdd => async dispatch => {
    let addMutlipleBillAccountsResponse = await dispatch({
        [RSAA]: {
            endpoint: utils.createUrl('accounts/addmultiple'),
            method: 'POST',
            body: JSON.stringify(accountsToAdd),
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include',
            types: [
                billAccountConstants.ADD_MULTIPLE_BILL_ACCOUNTS_REQUEST,
                billAccountConstants.ADD_MULTIPLE_BILL_ACCOUNTS_SUCCESS,
                billAccountConstants.ADD_MULTIPLE_BILL_ACCOUNTS_FAILURE
            ]
        },
        [SKIP_BILL_ACCOUNT_CHECK]: true,
        meta: {
            debounce: {
                key: `${billAccountConstants.ADD_MULTIPLE_BILL_ACCOUNTS_REQUEST}`,
                time: 100,
                leading: true,
                trailing: false
            }
        }
    })

    return addMutlipleBillAccountsResponse
}

export const attachTransferredAccount = accountsToAdd => async dispatch => {
    let addMutlipleBillAccountsResponse = await dispatch({
        [RSAA]: {
            endpoint: utils.createUrl('accounts/attachTransferAccount'),
            method: 'POST',
            body: JSON.stringify(accountsToAdd),
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include',
            types: [
                billAccountConstants.ADD_MULTIPLE_BILL_ACCOUNTS_REQUEST,
                billAccountConstants.ADD_MULTIPLE_BILL_ACCOUNTS_SUCCESS,
                billAccountConstants.ADD_MULTIPLE_BILL_ACCOUNTS_FAILURE
            ]
        },
        [SKIP_BILL_ACCOUNT_CHECK]: true,
        meta: {
            debounce: {
                key: `${billAccountConstants.ADD_MULTIPLE_BILL_ACCOUNTS_REQUEST}`,
                time: 100,
                leading: true,
                trailing: false
            }
        }
    })

    return addMutlipleBillAccountsResponse
}

export const updateBillAccountNickname = (billAccount, nickname) => async dispatch => {
    let updateNicknameResponse = await dispatch({
        [RSAA]: {
            endpoint: utils.createUrl('accounts/update/nickname', { billAccount, nickname }),
            method: 'POST',
            credentials: 'include',
            types: [
                billAccountConstants.UPDDATE_BILL_ACCOUNT_NICKNAME_REQUEST,
                {
                    type: billAccountConstants.UPDDATE_BILL_ACCOUNT_NICKNAME_SUCCESS,
                    meta: { billAccount, nickname }
                },
                billAccountConstants.UPDDATE_BILL_ACCOUNT_NICKNAME_FAILURE
            ]
        },
        [SKIP_BILL_ACCOUNT_CHECK]: true,
        meta: {
            debounce: {
                leading: false,
                trailing: false
            }
        }
    })

    return updateNicknameResponse
}

export const deleteBillAccount = (billAccount, nickname) => async dispatch => {
    let deleteResult = await dispatch({
        [RSAA]: {
            endpoint: utils.createUrl('accounts/delete', { billAccount }),
            method: 'DELETE',
            credentials: 'include',
            types: [
                {
                    type: billAccountConstants.DELETE_BILL_ACCOUNT_REQUEST,
                    payload: { billAccount }
                },
                billAccountConstants.DELETE_BILL_ACCOUNT_SUCCESS,
                {
                    type: billAccountConstants.DELETE_BILL_ACCOUNT_FAILURE,
                    payload: { billAccount }
                }
            ]
        },
        [SKIP_BILL_ACCOUNT_CHECK]: true
    })

    if (!deleteResult || deleteResult.error === true || deleteResult.payload.success === false) {
        dispatch(addSnackbarMessage(<span>Remove account failed</span>))
        return deleteResult
    }

    if (deleteResult.payload.undoDeleteToken) {
        dispatch(addSnackbarMessage(
            <span>Account removed</span>,
            <button className="displayAsBlueText text-uppercase" onClick={() => {
                dispatch(dismissSnackbarMessage())
                dispatch(undoDeleteBillAccount(billAccount, nickname, deleteResult.payload.undoDeleteToken))
            }}>Undo</button>))
    }
    else {
        dispatch(addSnackbarMessage(<span>Account removed</span>))
    }

    return deleteResult
}

export const undoDeleteBillAccount = (billAccount, nickName, undoToken) => async dispatch => {
    let parameters = { billAccount, undoToken }
    if (nickName) parameters.nickName = nickName

    let undoResult = await dispatch({
        [RSAA]: {
            endpoint: utils.createUrl('accounts/delete/undo', parameters),
            method: 'POST',
            credentials: 'include',
            types: [
                billAccountConstants.ADD_BILL_ACCOUNT_REQUEST,
                billAccountConstants.ADD_BILL_ACCOUNT_SUCCESS,
                billAccountConstants.ADD_BILL_ACCOUNT_FAILURE
            ]
        },
        [SKIP_BILL_ACCOUNT_CHECK]: true
    })

    if (!undoResult || undoResult.error === true || undoResult.payload.success === false) {
        dispatch(addSnackbarMessage(<span>Undo remove account failed</span>))
    }
    else {
        await dispatch(getBillAccounts())
        dispatch(addSnackbarMessage(<span>Undo remove account successful</span>))
    }

    return undoResult
}

export const selectBillAccountOnChange = billAccount => async (dispatch, getState) => {
    let billAccountResponse = await dispatch({
        [RSAA]: {
            endpoint: utils.createUrl('accounts/select', { billAccount: billAccount }),
            method: 'POST',
            credentials: 'include',
            types: [
                billAccountConstants.SELECT_BILL_ACCOUNT_REQUEST,
                billAccountConstants.SELECT_BILL_ACCOUNT_SUCCESS,
                billAccountConstants.SELECT_BILL_ACCOUNT_FAILURE
            ]
        }
    })

    let state = getState()

    if (billAccount && !billAccountResponse.error && billAccountResponse.payload && billAccountResponse.payload.success
        && billAccountResponse.payload.accountType === billAccountConstants.POWER_ACCOUNT) {
        viewChangedEvent(state.accountInfo.billAccount.selectedBillAccountDetails.customerKey,
                         state.accountInfo.billAccount.selectedBillAccountDetails.userAccountId,
                         state.login.userId)
    }

    return billAccountResponse
}

export const getCustomerNames = billAccount => dispatch => {
    return dispatch(getRateMetaData(billAccount))
}

export const getBusinessInfo = billAccount => dispatch => {
    return dispatch({
        [RSAA]: {
            endpoint: utils.createUrl('accounts/businessInfo', { billAccount: billAccount }),
            method: 'GET',
            credentials: 'include',
            types: [
                billAccountConstants.GET_BUSINESS_INFO_REQUEST,
                billAccountConstants.GET_BUSINESS_INFO_SUCCESS,
                billAccountConstants.GET_BUSINESS_INFO_FAILURE
            ]
        }
    })
}

export const getRateCode = billAccount => dispatch => {
    return dispatch({
        [RSAA]: {
            endpoint: utils.createUrl('accounts/getratecode', { billAccount: billAccount }),
            method: 'GET',
            credentials: 'include',
            types: [
                billAccountConstants.GET_RATE_CODE_REQUEST,
                billAccountConstants.GET_RATE_CODE_SUCCESS,
                billAccountConstants.GET_RATE_CODE_FAILURE
            ]
        }
    })
}

export const getServiceAddress = billAccount => dispatch => {
    return dispatch({
        [RSAA]: {
            endpoint: utils.createUrl('accounts/getaddress', { billAccount: billAccount }),
            method: 'GET',
            credentials: 'include',
            types: [
                billAccountConstants.GET_ADDRESS_REQUEST,
                billAccountConstants.GET_ADDRESS_SUCCESS,
                billAccountConstants.GET_ADDRESS_FAILURE
            ]
        }
    })
}

export const getBillingAddress = billAccount => dispatch => {
    return dispatch({
        [RSAA]: {
            endpoint: utils.createUrl('accounts/billingaddress', { billAccount: billAccount }),
            method: 'GET',
            credentials: 'include',
            types: [
                billAccountConstants.GET_BILLING_ADDRESS_REQUEST,
                billAccountConstants.GET_BILLING_ADDRESS_SUCCESS,
                billAccountConstants.GET_BILLING_ADDRESS_FAILURE
            ]
        }
    })
}

export const updateBillingAddress = (billAccount, newBillingAddress) => dispatch => {
    return dispatch({
        [RSAA]: {
            endpoint: utils.createUrl('accounts/billingaddress/update', { billAccount: billAccount }),
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(newBillingAddress),
            credentials: 'include',
            types: [
                billAccountConstants.UPDATE_BILLING_ADDRESS_REQUEST,
                billAccountConstants.UPDATE_BILLING_ADDRESS_SUCCESS,
                billAccountConstants.UPDATE_BILLING_ADDRESS_FAILURE
            ]
        },
        meta: {
            debounce: {
                leading: false,
                trailing: false
            }
        }
    })
}

export const getPhoneNumber = billAccount => dispatch => {
    return dispatch({
        [RSAA]: {
            endpoint: utils.createUrl('accounts/getphone', { billAccount: billAccount }),
            method: 'GET',
            credentials: 'include',
            types: [
                billAccountConstants.GET_PHONE_REQUEST,
                billAccountConstants.GET_PHONE_SUCCESS,
                billAccountConstants.GET_PHONE_FAILURE
            ]
        }
    })
}

export const getAccountHistory = billAccount => dispatch => {
    return dispatch({
        [RSAA]: {
            endpoint: utils.createUrl('accounts/getaccounthistory', { billAccount: billAccount }),
            method: 'GET',
            credentials: 'include',
            types: [
                billAccountConstants.GET_ACCOUNT_HISTORY_REQUEST,
                billAccountConstants.GET_ACCOUNT_HISTORY_SUCCESS,
                billAccountConstants.GET_ACCOUNT_HISTORY_FAILURE
            ]
        }
    })
}

export const getAccountManager = billAccount => dispatch => {
    return dispatch({
        [RSAA]: {
            endpoint: utils.createUrl('accounts/getaccountmanager', { billAccount: billAccount }),
            method: 'GET',
            credentials: 'include',
            types: [
                billAccountConstants.GET_ACCOUNT_MANAGER_REQUEST,
                billAccountConstants.GET_ACCOUNT_MANAGER_SUCCESS,
                billAccountConstants.GET_ACCOUNT_MANAGER_FAILURE
            ]
        }
    })
}

export const getCurrentBill = billAccount => dispatch => {
    return dispatch({
        [RSAA]: {
            endpoint: utils.createUrl('accounts/getcurrentbillinfo', { billAccount: billAccount }),
            method: 'GET',
            credentials: 'include',
            types: [
                billAccountConstants.GET_CURRENT_BILL_INFO_REQUEST,
                billAccountConstants.GET_CURRENT_BILL_INFO_SUCCESS,
                billAccountConstants.GET_CURRENT_BILL_INFO_FAILURE
            ]
        }
    })
}

export const getMPowerAccountDetails = billAccount => dispatch => {
    return dispatch({
        [RSAA]: {
            endpoint: utils.createUrl('accounts/getmpowerdata', { billAccount: billAccount }),
            method: 'GET',
            credentials: 'include',
            types: [
                billAccountConstants.GET_MPOWER_DETAILS_REQUEST,
                billAccountConstants.GET_MPOWER_DETAILS_SUCCESS,
                billAccountConstants.GET_MPOWER_DETAILS_FAILURE
            ]
        }
    })
}

export const getScannableCode = (billAccount) => dispatch => {
    return dispatch({
        [RSAA]: {
            endpoint: utils.createUrl('accounts/getscannablecodejson', { billAccount: billAccount }),
            method: 'GET',
            credentials: 'include',
            types: [
                billAccountConstants.GET_SCANNABLE_CODE_REQUEST,
                billAccountConstants.GET_SCANNABLE_CODE_SUCCESS,
                billAccountConstants.GET_SCANNABLE_CODE_FAILURE
            ]
        }
    })
}

//action to call all header actions
export const getBillAccountActions = billAccount => dispatch => {
    dispatch(getRateCode(billAccount))
    dispatch(getServiceAddress(billAccount))
    dispatch(getPhoneNumber(billAccount))
}

export const getCountyAssessorInfo = billAccount => dispatch => {
    return dispatch({
        [RSAA]: {
            endpoint: utils.createUrl('accounts/getcountyassessor', { billAccount: billAccount }),
            method: 'GET',
            credentials: 'include',
            types: [
                billAccountConstants.GET_COUNTRY_ASSESSOR_INFO_REQUEST,
                billAccountConstants.GET_COUNTRY_ASSESSOR_INFO_SUCCESS,
                billAccountConstants.GET_COUNTRY_ASSESSOR_INFO_FAILURE
            ]
        }
    })
}

export const submitChangePricePlan = changePricePlanForm => dispatch => {
    return dispatch({
        [RSAA]: {
            endpoint: utils.createUrl('accounts/changepriceplan'),
            body: JSON.stringify(changePricePlanForm),
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            types: [
                pricePlanConstants.SUBMIT_CHANGE_PRICE_PLAN_REQUEST,
                pricePlanConstants.SUBMIT_CHANGE_PRICE_PLAN_SUCCESS,
                pricePlanConstants.SUBMIT_CHANGE_PRICE_PLAN_FAILURE
            ]
        }
    })
}

export const isAccountBlockedFromTurnOnOff = (billAccount, isLoggedIn) => dispatch => {
    return dispatch({
        [RSAA]: {
            endpoint: utils.createUrl('forms/checkstatusbyformtype', { billAccount: billAccount, isLoggedIn: isLoggedIn }),
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
            types: [
                billAccountConstants.GET_FORM_STATUS_REQUEST,
                billAccountConstants.GET_FORM_STATUS_SUCCESS,
                billAccountConstants.GET_FORM_STATUS_FAILURE
            ]
        }
    })
}

export const getCompareMyPricePlan = (billAccount) => dispatch => {
    return dispatch({
        [RSAA]: {
            endpoint: utils.createUrl('accounts/getcomparemypriceplan', { billAccount: billAccount }),
            credentials: 'include',
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
            types: [
                billAccountConstants.GET_COMPARE_MY_PRICE_PLAN_REQUEST,
                billAccountConstants.GET_COMPARE_MY_PRICE_PLAN_SUCCESS,
                billAccountConstants.GET_COMPARE_MY_PRICE_PLAN_FAILURE
            ]
        }
    })
}

export const selectCompareMyPricePlanBusinessOffering = (selectedBusinessOffering) => {
    return {
        type: billAccountConstants.SELECT_COMPARE_MY_PRICE_PLAN_BUSINESSOFFERING,
        selectedBusinessOffering
    }
}

export const updateChangeMyPricePlanFromPage = (fromCmpp) => {
    return {
        type: billAccountConstants.UPDATE_CHANGE_PRICE_PLAN_FROM_PAGE,
        fromCmpp
    }
}

export const resetChangeMyPricePlan = () => {
    return {
        type: billAccountConstants.RESET_CHANGE_MY_PRICE_PLAN
    }
}

export const getTransferredAccountStatus = (billAccount) => dispatch => {
    return dispatch({
        [RSAA]: {
            endpoint: utils.createUrl('accounts/gettransferredaccountstatus', { billAccount: billAccount }),
            credentials: 'include',
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
            types: [
                billAccountConstants.GET_TRANSFERRED_ACCOUNT_STATUS_REQUEST,
                billAccountConstants.GET_TRANSFERRED_ACCOUNT_STATUS_SUCCESS,
                billAccountConstants.GET_TRANSFERRED_ACCOUNT_STATUS_FAILURE
            ]
        }
    })
}

export const hashBillAccount = (billAccount) => dispatch => {
    return dispatch({
        [RSAA]: {
            endpoint: utils.createUrl('accounts/hashaccount', { billAccount: billAccount }),
            credentials: 'include',
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
            types: [
                billAccountConstants.HASH_BILL_ACCOUNT_REQUEST,
                billAccountConstants.HASH_BILL_ACCOUNT_SUCCESS,
                billAccountConstants.HASH_BILL_ACCOUNT_FAILURE
            ]
        }
    })
}

export const dismissTransferredAccountPrompt = () => {
    return {
        type: billAccountConstants.DISMISS_TRANSFERRED_ACCOUNT_PROMPT
    }
}