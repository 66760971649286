import React from 'react'
import PropTypes from 'prop-types'
import { Trans } from 'react-i18next'
import utils from '../../../srp_modules/utils'

const BudgetBillingIneligible = ({isCommercial, t, i18n}) => {
    const srpSupport =(isCommercial ? "Business Contact Center" : "Residential Customer Service")

    const isSpanish = (i18n.language === "es")
    const supportNumber = utils.GetCustomerServicePhoneNumber(isCommercial, isSpanish)
    const supportNumberFmt = utils.GetCustomerServicePhoneNumberFmt(isCommercial, isSpanish)

    return (
        <div className="row">
            <div className="col-lg-6 col-12 mt-lg-4 mt-md-4">
                <div className="srp-card-header">{t("Unable to sign up for Budget Billing")}</div>
                <div className="srp-card-body">
                    <div className="srp-card-details text-muted">
                        <p className="d-lg-block d-none mb-0">
                            {t("this_account_is_not_eligible_desktop", { srpSupport, supportNumber: supportNumberFmt })}
                        </p>
                        <p className="d-lg-none d-block mb-0">
                            <Trans i18nKey="this_account_is_not_eligible_mobile" t={t}>
                                We're sorry, but this account is not eligible for Budget Billing. If you have any questions, please call SRP {{srpSupport}} at 
                                <a href={"tel:" + supportNumber}>{{supportNumber: supportNumberFmt}}</a>.
                            </Trans>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

BudgetBillingIneligible.propTypes = {
    isCommercial: PropTypes.bool.isRequired,

    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired,
}

export default BudgetBillingIneligible