import { healthyForestFormConstants } from '../../../constants/healthy-forrest-constants'
import { paymentConstants } from '../../../constants/payment-constants'
import { billAccountConstants } from '../../../constants/bill-account-constants'
import * as loadingStatus from '../../../constants/loading-status-constants'
import healthyForestMapper from '../../../srp_modules/forms/healthy-forest-mapper'

const initialState = {
    healthyForestDonationAmt: healthyForestFormConstants.DONATION_AMT_NONE,
    submitHealthyForestFormResult:
    {
        success: false,
        signUpDateTime: "00:00",
        signUpDateDate: "01/01/0001"
    },
    submitHealthyForestFormStatus: loadingStatus.LOADING_STATUS_INIT
}

export default function healthyForestReducer(state = initialState, action) {
    switch (action.type) {
        case healthyForestFormConstants.SUBMIT_HEALTHY_FOREST_FORM_REQUEST:
            return { ...state, submitHealthyForestFormStatus: loadingStatus.LOADING_STATUS_IN_PROGRESS }
        case healthyForestFormConstants.SUBMIT_HEALTHY_FOREST_FORM_SUCCESS:
            return {
                ...state,
                submitHealthyForestFormResult: action.payload,
                submitHealthyForestFormStatus: action.payload.success ? loadingStatus.LOADING_STATUS_SUCCESS : loadingStatus.LOADING_STATUS_FAILURE
            }
        case healthyForestFormConstants.SUBMIT_HEALTHY_FOREST_FORM_FAILURE:
            return {
                ...state,
                submitHealthyForestFormResult: initialState.submitSolarChoiceFormResult,
                submitHealthyForestFormStatus: loadingStatus.LOADING_STATUS_FAILURE 
            }
        case paymentConstants.GET_ENROLLED_PROGRAMS_DETAILS_SUCCESS:
            return {
                ...state,
                healthyForestDonationAmt: action.payload.healthyForest.isEnrolled
                                            ? healthyForestMapper.mapServerToDonationAmount(action.payload.healthyForest.optionValue)
                                            : healthyForestFormConstants.DONATION_AMT_NONE
            }
        case billAccountConstants.SELECT_BILL_ACCOUNT_SUCCESS:
            return { ...initialState }
        default:
            return state
    }
}