import { RSAA } from 'redux-api-middleware'
import { shareConstants } from '../../../constants/share-constants'
import utils from '../../../srp_modules/utils'

export const setActiveStep = (activeStep) => ({
    type: shareConstants.SET_ACTIVE_STEP,
    activeStep
})

export const setAccountType = (accountType) => ({
    type: shareConstants.SET_ACCOUNT_TYPE,
    accountType
})

export const setAccountDetails = (accountDetails) => ({
    type: shareConstants.SET_ACCOUNT_DETAILS,
    accountDetails
})

export const setDonationType = (donationType) => ({
    type: shareConstants.SET_DONATION_TYPE,
    donationType
})

export const setDonationAmount = (donationAmount) => ({
    type: shareConstants.SET_DONATION_AMOUNT,
    donationAmount
})

export const setOtherDonationAmount = (donationAmount) => ({
    type: shareConstants.SET_OTHER_DONATION_AMOUNT,
    donationAmount
})

export const submitShareForm = (accountDetails, donationType, donationAmount, otherDonationAmount) => dispatch => {
    let shareForm = {
        IsResidential: accountDetails.accountType === shareConstants.ACOUNT_TYPE.RESIDENTIAL,
        AccountNumber: accountDetails.billAccount,
        CustomerInfoModel: {
            FirstName: accountDetails.firstName,
            MiddleName: accountDetails.middleInitial,
            LastName: accountDetails.lastName,
            EmailAddress: accountDetails.emailAddress,
            PhoneNumber: accountDetails.phoneNumber
        },
        BusinessInfoModel: {
            BusinessName: accountDetails.businessName,
            DBAName: accountDetails.dbaName,
            ContactName: accountDetails.contactName,
            ContactPosition: accountDetails.contactPosition,
            EmailAddress: accountDetails.emailAddress,
            PhoneNumber: accountDetails.phoneNumber
        },
        ServiceAddress: {
            StreetAddress: accountDetails.serviceAddressOne,
            StreetAddressLineTwo: accountDetails.serviceAddressTwo,
            State: "Arizona",
            City: accountDetails.city,
            Zipcode: accountDetails.zipCode,
            Country: "United States"
        },
        DonationType: donationType,
        DonationAmount: donationAmount === shareConstants.DONATION_AMOUNT.OTHER ? otherDonationAmount : donationAmount.toString(),
        Language: "English"
    }

    return dispatch({
        [RSAA]: {
            endpoint: utils.createUrl('forms/submitshare'),
            body: JSON.stringify(shareForm),
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            types: [
                shareConstants.SUBMIT_SHARE_REQUEST,
                shareConstants.SUBMIT_SHARE_SUCCESS,
                shareConstants.SUBMIT_SHARE_FAILURE
            ]
        }
    })
}