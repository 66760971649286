import React from 'react'
import PropTypes from 'prop-types'
import AddAccountContainer from './add_account/add-account-container'
import AddLandlordForm from './add_landlord/add-landlord-form'

class AddAccountOrLandlordContainer extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            displayAddAccount: false,
            displayAddLandlordAgreement: false
        }

        this.setAddAccountForm = this.setAddAccountForm.bind(this)
    }

    setAddAccountForm(display) {
        return () => {
            this.setState({ ...this.state, displayAddAccount: display })
        }
    }

    setAddLandlordForm(display) {
        return () => {
            this.setState({ ...this.state, displayAddLandlordAgreement: display })
        }
    }

    render() {
        return (
            <div className="row pt-2 justify-content-start">
                <div className="col-12">
                    {this.state.displayAddAccount
                        ? <div className="p-2"><AddAccountContainer hideForm={this.setAddAccountForm(false)} t={this.props.t}/></div>
                        : null}

                    {this.state.displayAddLandlordAgreement
                        ? <div className="p-2"><AddLandlordForm hideForm={this.setAddLandlordForm(false)} handleSuccess={this.setAddLandlordForm(false)} t={this.props.t} i18n={this.props.i18n} /></div>
                        : null}

                    {this.state.displayAddAccount || this.state.displayAddLandlordAgreement
                        ? null
                        : <div className="row justify-content-start">
                              <div className="col-lg text-left">
                                  <button className="btn srp-btn btn-lightblue mt-0 ml-0 mr-0 mb-2" onClick={this.setAddAccountForm(true)}>{this.props.t("Add account")}</button>
                                  <button className="btn srp-btn btn-lightblue ml-2 mb-2" onClick={this.setAddLandlordForm(true)}>{this.props.t("Add landlord agreement")}</button>
                              </div>
                          </div>}
                </div>
            </div>
        )
    }
}

AddAccountOrLandlordContainer.propTypes = {
    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired,
}

export default AddAccountOrLandlordContainer