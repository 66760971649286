import {hourlyUsageConstants} from '../../../constants/hourly-usage-constants'
import { RSAA } from 'redux-api-middleware'
import config from 'my-account-config'

export const getHourlyUsage = (billAccount,beginDate,endDate) =>{
    return dispatch => {
        return dispatch({
            [RSAA]: {
                endpoint: config.apiBaseUrl 
                + 'api/usage/hourlydetail?billaccount=' +
                    encodeURIComponent(billAccount) 
                + '&beginDate=' +
                    encodeURIComponent(beginDate)
                +'&endDate=' +
                    encodeURIComponent(endDate),
                method: 'GET',
                credentials: 'include',
                types: [
                    hourlyUsageConstants.GET_HOURLY_REQUEST,
                    hourlyUsageConstants.GET_HOURLY_SUCCESS,
                    hourlyUsageConstants.GET_HOURLY_FAILURE
                ]
            }
        })
    }
}
export const getYesterdaysUsage = (billAccount) =>{
    return dispatch => {
        return dispatch({
            [RSAA]: {
                endpoint: config.apiBaseUrl 
                + 'api/usage/yesterdayusage?billaccount=' +
                    encodeURIComponent(billAccount) ,
                method: 'GET',
                credentials: 'include',
                types: [
                    hourlyUsageConstants.GET_YESTERDAY_USAGE_REQUEST,
                    hourlyUsageConstants.GET_YESTERDAY_USAGE_SUCCESS,
                    hourlyUsageConstants.GET_YESTERDAY_USAGE_FAILURE
                ]
            }
        })
    }
}