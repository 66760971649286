import React from 'react'
import PropTypes from 'prop-types'

const NoPurchasesCopyResponsive = ({t}) => (
    <div className="text-center p-4">
        <div style={{ color: "#004B87" }}>
            <i className="material-icons" style={{ fontSize: "3rem" }}>attach_money</i>
        </div>
        <div className="mb-1" style={{ color: "#326E9F", fontSize: "1.125rem" }}>
            <strong>{t("Looking for your purchases?")}</strong>
        </div>
        <div className="text-muted" style={{lineHeight: 1}}>
            <small>{t("You're in the right place, but you have yet to make a purchase. SRP is delighted to have you as a customer, we look forward to serving you.")}</small>
        </div>
    </div>
)

NoPurchasesCopyResponsive.propTypes = {
    t: PropTypes.func.isRequired
}

export default NoPurchasesCopyResponsive