import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import InputLabel from '@mui/material/InputLabel'
import TextField from '@mui/material/TextField'
import { PatternFormat } from 'react-number-format'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormGroup from '@mui/material/FormGroup'
import Radio from '@mui/material/Radio'
import MenuItem from '@mui/material/MenuItem'
import { formConstants } from '../../../constants/form-constants'

class EVDetails extends Component {
  constructor(props) {
    super(props)
    this.state = {
      evYearError: false
    }
  }

  componentDidMount() {
    if (this.props.selectedPlan === formConstants.ELECTRIC_VEHICLE)
      this.clearErrors()
  }

  componentDidUpdate(prevProps) {
    if (this.props.shouldRunValidation !== prevProps.shouldRunValidation) {
      if (this.props.selectedPlan === formConstants.ELECTRIC_VEHICLE)
        this.checkValidation()
    }
  }

  validateEvYear(year) {
    if ((year).length > 0 && (year).length < 4) {
      this.setState({
        evYearError: true
      })
    }
    else {
      this.setState({
        evYearError: false
      })
    }
  }

  checkValidation() {
    if (!this.props.shouldRunValidation) return

    this.validateEvYear(this.props.carYear)
  }

  clearErrors() {
    this.setState({
      evYearError: false
    })
  }

  render() {
    return (<div>
      <div>
        <div className="mb-4"><h5>{this.props.t("electric_vehicle_details")}</h5></div>
        <div className="mb-3">
          <TextField id="make"
            fullWidth={true}
            value={this.props.carMake}
            inputProps={{ maxLength: 30 }}
            onChange={e => this.props.carMakeChange(e.target.value)} 
            label={this.props.t("make")}
          />
        </div>
        <div className="mb-3">
          <TextField id="model"
            fullWidth={true}
            value={this.props.carModel}
            inputProps={{ maxLength: 50 }}
            onChange={e => this.props.carModelChange(e.target.value)} 
            label={this.props.t("model")}
          />
        </div>
        <div className="mb-3">
          <PatternFormat
            value={this.props.carYear}
            valueIsNumericString={true}
            fullWidth={true}
            type="tel"
            onValueChange={(e) => this.props.carYearChange(e.value)}
            onBlur={(e) => this.validateEvYear(e.target.value.trim())}
            format="####"
            customInput={TextField}
            error={this.state.evYearError}
            helperText={this.state.evYearError ? this.props.t("invalid_year") : ""}
            label={this.props.t("year")}
          />
        </div>

        <div className="d-none d-lg-block">
          <InputLabel>{this.props.t("own_or_lease_vehicle")}</InputLabel>
          <FormGroup row>
            <FormControlLabel
              control={<Radio color="primary" name={formConstants.VEHICLE_OWN} />}
              value={formConstants.VEHICLE_OWN}
              checked={this.props.ownershipType === formConstants.VEHICLE_OWN}
              onChange={e => this.props.vehicleOwnershipChange(e.target.value)}
              label={this.props.t("own")} />
            <FormControlLabel
              control={<Radio color="primary" />}
              value={formConstants.VEHICLE_LEASE}
              checked={this.props.ownershipType === formConstants.VEHICLE_LEASE}
              onChange={e => this.props.vehicleOwnershipChange(e.target.value)}
              label={this.props.t("lease")} />
          </FormGroup>
        </div>

        <div className="d-lg-none d-block mb-3">
          <TextField
            select
            value={this.props.ownershipType}
            onChange={e => this.props.vehicleOwnershipChange(e.target.value)}
            inputProps={{ id: "own-or-lease" }}
            fullWidth
            label={this.props.t("own_or_lease_vehicle")}
            >
            <MenuItem value={""} />
            <MenuItem value={formConstants.VEHICLE_OWN}> {this.props.t("own")}</MenuItem>
            <MenuItem value={formConstants.VEHICLE_LEASE}>{this.props.t("lease")}</MenuItem>
          </TextField>
        </div>

        <div className="d-none d-lg-block">
          <InputLabel>{this.props.t("supply_equipment_type")}</InputLabel>
          <FormGroup row>
            <FormControlLabel
              control={<Radio color="primary" name={formConstants.SUPPLY_TYPE_1}  />}
              checked={this.props.supplyEquipment === formConstants.SUPPLY_TYPE_1}
              value={formConstants.SUPPLY_TYPE_1}
              onChange={e => this.props.supplyEquipmentChange(e.target.value)}
              label={this.props.t("type1")} />
            <FormControlLabel
              control={<Radio color="primary" name={formConstants.SUPPLY_TYPE_2}  />}
              value={formConstants.SUPPLY_TYPE_2}
              checked={this.props.supplyEquipment === formConstants.SUPPLY_TYPE_2}
              onChange={e => this.props.supplyEquipmentChange(e.target.value)}
              label={this.props.t("type2")} />
            <FormControlLabel
              control={<Radio color="primary" name={formConstants.SUPPLY_DONT_KNOW}  />}
              value={formConstants.SUPPLY_DONT_KNOW}
              checked={this.props.supplyEquipment === formConstants.SUPPLY_DONT_KNOW}
              onChange={e => this.props.supplyEquipmentChange(e.target.value)}
              label={this.props.t("i_dont_know")} />
          </FormGroup>
        </div>

        <div className="d-lg-none d-block mb-3">
          <TextField
            select
            value={this.props.supplyEquipment}
            onChange={e => this.props.supplyEquipmentChange(e.target.value)}
            inputProps={{ id: "supply-equipment" }}
            fullWidth
            label={this.props.t("supply_equipment_type")}
            >
            <MenuItem value={""} />
            <MenuItem value={formConstants.SUPPLY_TYPE_1}> {this.props.t("type1")}</MenuItem>
            <MenuItem value={formConstants.SUPPLY_TYPE_2}>{this.props.t("type2")}</MenuItem>
            <MenuItem value={formConstants.SUPPLY_DONT_KNOW}>{this.props.t("i_dont_know")}</MenuItem>
          </TextField>
        </div>

        <div className="d-none d-lg-block">
          <InputLabel>{this.props.t("input_voltage")}</InputLabel>
          <FormGroup row>
            <FormControlLabel
              control={<Radio color="primary" name={formConstants.INPUT_VOLTAGE_120} />}
              value={formConstants.INPUT_VOLTAGE_120}
              checked={this.props.inputVoltage === formConstants.INPUT_VOLTAGE_120}
              onChange={e => this.props.inputVoltageChange(e.target.value)}
              label="120V" />
            <FormControlLabel
              control={<Radio color="primary" name={formConstants.INPUT_VOLTAGE_220} />}
              value={formConstants.INPUT_VOLTAGE_220}
              checked={this.props.inputVoltage === formConstants.INPUT_VOLTAGE_220}
              onChange={e => this.props.inputVoltageChange(e.target.value)}
              label="220V" />
            <FormControlLabel
              control={<Radio color="primary" name={formConstants.VOLTAGE_DONT_KNOW} />}
              value={formConstants.VOLTAGE_DONT_KNOW}
              checked={this.props.inputVoltage === formConstants.VOLTAGE_DONT_KNOW}
              onChange={e => this.props.inputVoltageChange(e.target.value)}
              label={this.props.t("i_dont_know")} />
          </FormGroup>
        </div>

        <div className="d-lg-none d-block mb-3">
          <TextField
            select
            value={this.props.inputVoltage}
            onChange={e => this.props.inputVoltageChange(e.target.value)}
            inputProps={{ id: 'input_voltage' }}
            fullWidth
            label={this.props.t("input_voltage")}
            >
            <MenuItem value={""} />
            <MenuItem value={formConstants.INPUT_VOLTAGE_120}>120V</MenuItem>
            <MenuItem value={formConstants.INPUT_VOLTAGE_220}>220V</MenuItem>
            <MenuItem value={formConstants.VOLTAGE_DONT_KNOW}>{this.props.t("i_dont_know")}</MenuItem>
          </TextField>
        </div>

        <div className="d-none d-lg-block">
          <InputLabel>{this.props.t("input_current")}</InputLabel>
          <FormGroup row>
            <FormControlLabel
              control={<Radio color="primary" name={formConstants.AMPS_15} />}
              value={formConstants.AMPS_15}
              checked={this.props.inputCurrent === formConstants.AMPS_15}
              onChange={e => this.props.inputCurrentChange(e.target.value)}
              label="15 amps" />
            <FormControlLabel
              control={<Radio color="primary" name={formConstants.AMPS_20} />}
              value={formConstants.AMPS_20}
              checked={this.props.inputCurrent === formConstants.AMPS_20}
              onChange={e => this.props.inputCurrentChange(e.target.value)}
              label="20 amps" />
            <FormControlLabel
              control={<Radio color="primary" name={formConstants.AMPS_DONT_KNOW} />}
              value={formConstants.AMPS_DONT_KNOW}
              checked={this.props.inputCurrent === formConstants.AMPS_DONT_KNOW}
              onChange={e => this.props.inputCurrentChange(e.target.value)}
              label={this.props.t("i_dont_know")} />
          </FormGroup>
        </div>

        <div className="d-lg-none d-block mb-3">
          <TextField
            select
            value={this.props.inputCurrent}
            onChange={e => this.props.inputCurrentChange(e.target.value)}
            inputProps={{ id: "current" }}
            fullWidth
            label={this.props.t("input_current")}
            >
            <MenuItem value={""} />
            <MenuItem value={formConstants.AMPS_15}>15 amps</MenuItem>
            <MenuItem value={formConstants.AMPS_20}>20 amps</MenuItem>
            <MenuItem value={formConstants.AMPS_DONT_KNOW}>{this.props.t("i_dont_know")}</MenuItem>
          </TextField>
        </div>
      </div>
    </div >)
  }
}

EVDetails.propTypes = {
  carMake: PropTypes.string.isRequired,
  carMakeChange: PropTypes.func.isRequired,
  carModel: PropTypes.string.isRequired,
  carModelChange: PropTypes.func.isRequired,
  carYear: PropTypes.string.isRequired,
  carYearChange: PropTypes.func.isRequired,
  ownershipType: PropTypes.string.isRequired,
  vehicleOwnershipChange: PropTypes.func.isRequired,
  supplyEquipment: PropTypes.string.isRequired,
  supplyEquipmentChange: PropTypes.func.isRequired,
  inputVoltage: PropTypes.string.isRequired,
  inputVoltageChange: PropTypes.func.isRequired,
  inputCurrent: PropTypes.string.isRequired,
  inputCurrentChange: PropTypes.func.isRequired,
  shouldRunValidation: PropTypes.bool.isRequired,
  selectedPlan: PropTypes.string.isRequired,
  t: PropTypes.func
}

export default withTranslation('startService')(EVDetails)