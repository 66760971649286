import React from 'react'

import { Link } from 'react-router-dom'
import config from 'my-account-config'

const ThirdPartyAuthorization = ({t}) => {
    return (
        <div>
            <div className="row border-bottom d-lg-flex d-none">
                <div className="col-lg-3 d-flex align-self-center mr-2">
                    <div className="text-muted text-nowrap p-lg-2 pt-2 pl-2 pb-0">
                        <a href={`${config.srpnetBaseUrl}account/electric/residential/share-account-information-third-party`} target="_new">
                            {t("Third party authorization")}
                        </a>
                    </div>
                </div>
                <div className="col-lg">
                    <div className="pl-1">
                        <div className="d-flex flex-row h-100 pt-lg-2 pb-2 pr-2">
                            <div className="d-flex flex-wrap align-self-center" style={{ flex: '1' }}>
                                <div className="d-flex flex-wrap mr-2 p-lg-0 pl-lg-1 pl-1" style={{ maxWidth: '100%' }}>
                                    {t("Authorize a person or company to act on your behalf")}
                                </div>
                            </div>
                            <div className="text-right align-self-center">
                                <Link
                                    className=""
                                    to="/forms/authorizedparty">
                                    {t("Manage third party authorization")}
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row border-bottom p-2 d-lg-none d-block">
                <div className="col lg-3 mr-2 text-muted text-nowrap">
                    <a href={`${config.srpnetBaseUrl}account/electric/residential/share-account-information-third-party`} target="_new">
                        {t("Third party authorization")}
                    </a>
                </div>
                <div className="col lg-3 mr-2">
                    {t("Authorize a person or company to act on your behalf")}
                </div>
                <div className="col lg-3 mr-2" style={{ textAlign: 'right' }}>
                    <Link
                        className=""
                        to="/forms/authorizedparty">
                            {t("Manage third party authorization")}
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default ThirdPartyAuthorization