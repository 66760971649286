import React from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

const BillPageError = ({dataThatFailed, refreshData, t}) => {

    return(
        <div className="srp-card-body">
            <div className="srp-card-details">
                <div className="text-center">
                    <div className="mb-1" style={{color: "#9E2A2B"}}><i className="material-icons" style={{fontSize:"50px"}}>error_outline</i></div>
                    <div className="mb-1" style={{color: "#9E2A2B"}}><strong>{t("Something went wrong")}</strong></div>
                    <div className="mb-4">{t("Unable_to_retrieve", {data : dataThatFailed})}</div>
                </div>
                <div className="d-flex justify-content-end">
                    <a onClick={()=>refreshData()} className="btn srp-btn btn-lightblue text-white mr-2">{t("Try again")}</a>
                </div> 
            </div>
        </div>
    )
}

BillPageError.propTypes = {
    dataThatFailed: PropTypes.string,
    refreshData: PropTypes.func,
    t: PropTypes.func.isRequired
}

export default withTranslation("billPage")(BillPageError)