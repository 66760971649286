import React from 'react'
import PropTypes from 'prop-types'

import utils from '../../../../srp_modules/utils'
import { Trans } from 'react-i18next'

function getReasonCodes(codes){
    let reasonCodes = []
    if(codes)
    {
        for(let i=0; i < codes.length; i++)
        {
            reasonCodes.push(<div className="text-muted">• {codes[i]}</div>)
        }
    }
}

const OutOfExtensionsCard = ({extensionNotGrantedReasonCodes, endCIXFlow, t, i18n}) => {
    const isSpanish = i18n.language==="es"
    const csPhoneNumber = isSpanish ? utils.getCSSpanishPhoneNumber() : utils.getCSPhoneNumber(false)
    const formattedCSPhoneNumber = isSpanish ? utils.getFormattedCSSpanishPhoneNumber() : utils.getFormattedCSPhoneNumber(false)

    return (
    <div className="srp-card">        
        <div className="srp-card-header">        
            {t("My bill")}
        </div>
        <div className="srp-card-body">
            <div className="srp-card-details">
                <h5 className="mb-4"><strong>{t("We're not able to extend your due date")}</strong></h5>
                <div className="mb-3 text-muted">{t("payment_extension_not_available_reason")}</div>
                {getReasonCodes(extensionNotGrantedReasonCodes)}
                <div className="d-none d-lg-block mt-3 mb-4 text-muted">{t("contact_SRP_desktop", {phone: formattedCSPhoneNumber})}</div>
                <div className="d-lg-none d-sm-block mb-4 text-muted">
                    <Trans i18nKey="contact_SRP_mobile" t={t}>
                    Please contact SRP Residential Customer Services at <a href={"tel:"+csPhoneNumber}>{{phone: formattedCSPhoneNumber}}</a> to discuss further options, available 24 hours a day, seven days a week.
                    </Trans>
                </div>
                <div className="d-flex justify-content-end" style={{display: 'flex', flexWrap: 'wrap'}}>
                    <button className="btn srp-btn btn-lightblue text-white mb-2" onClick={()=>endCIXFlow()}>{t("Back to Home")}</button>
                    <a href={"tel:"+csPhoneNumber} className="d-lg-none d-sm-block btn srp-btn btn-blue ml-2 mb-2" >{t("Call SRP")}</a>
                </div> 
            </div>
        </div>
    </div>
    )
}

OutOfExtensionsCard.propTypes = {
    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired,
    endCIXFlow: PropTypes.func.isRequired,
    extensionNotGrantedReasonCodes: PropTypes.arrayOf(PropTypes.string)
}

export default OutOfExtensionsCard