import React from 'react'
import PropTypes from 'prop-types'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Radio from '@mui/material/Radio'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import CircularProgress from '@mui/material/CircularProgress'

import PoolIconBlue from '../../../images/budget_billing/pool-blue.svg'
import PoolIconGrey from '../../../images/budget_billing/pool-grey.svg'
import EvapCoolerIconBlue from '../../../images/budget_billing/evap-cooler-blue.svg'
import EvapCoolerIconGrey from '../../../images/budget_billing/evap-cooler-grey.svg'

import { Trans } from 'react-i18next'

import { budgetBillingConstants } from '../../../constants/budget-billing-constants'
import * as loadingStatus from '../../../constants/loading-status-constants'

const budgetBillingBtn = {
    borderRadius: '10px',
    border: '2px solid #DADADB',
    backgroundColor: 'white',
    color: '#087ED8',
    fontWeight: '400',
    width: '100%'
}

const budgetBillingBtnSelected = {
    borderRadius: '10px',
    border: '3px solid #087ED8',
    backgroundColor: 'white',
    color: '#087ED8',
    fontWeight: '700',
    width: '100%'
}

const iconStyle = {
    width: '40px',
    height: '100%'
}

const BudgetBillingHomeDetails = ({ activeStep, setActiveStepOnClick, accountDetails, squareFootage, setSquareFootage, electricType, setElectricType,
    hasPool, selectPoolOnClick, hasEvapCooler, selectEvapCoolerOnClick, createAndSubmitBudgetBillingForm, submitBudgetBillingStatus, t, i18n }) => {

    function submitForm(e) {
        e.preventDefault()

        createAndSubmitBudgetBillingForm(accountDetails, electricType, squareFootage, hasEvapCooler, hasPool)
    }

    return (

        <div className="text-muted">
            <h4 className="mb-3" style={{ color: 'rgba(51, 51, 51, 0.537)' }}>{t("SRP account information")}</h4>
            <div className="d-lg-block d-none">
                <div>
                    <span className="font-weight-bold mr-2">{t("Name")}</span>
                    <span>{`${accountDetails.firstName} ${accountDetails.lastName}`}</span>
                </div>
                <div className="mb-3">
                    <span className="font-weight-bold mr-2">{t("Service address")}</span>
                    <span style={{ textTransform: 'capitalize' }}>
                        {`${(accountDetails.serviceAddressLineOne).toLowerCase()}`}
                        {(accountDetails.serviceAddressLineTwo).length !== 0
                            ? ` ${accountDetails.serviceAddressLineTwo}`
                            : null
                        }
                    </span>
                    <span style={{ textTransform: 'capitalize' }}>{` ${(accountDetails.city).toLowerCase()}, `}</span>
                    <span>AZ</span>
                    <span>{` ${accountDetails.zipCode}`}</span>
                </div>
            </div>
            <div className="d-lg-none d-block mb-3">
                <div className="row">
                    <div className="col-5 font-weight-bold">{t("Name")}</div>
                    <div className="col-7 text-right">{`${accountDetails.firstName} ${accountDetails.lastName}`}</div>
                </div>
                <div className="row">
                    <div className="col-6 font-weight-bold">{t("Service address")}</div>
                    <div className="col-6 text-right">
                        <div style={{ textTransform: 'capitalize' }}>
                            {`${accountDetails.serviceAddressLineOne}`}
                            {(accountDetails.serviceAddressLineTwo).length !== 0
                                ? ` ${accountDetails.serviceAddressLineTwo}`
                                : null
                            }
                        </div>
                        <div>
                            <span style={{ textTransform: 'capitalize' }}>{` ${(accountDetails.city).toLowerCase()}, `}</span>
                            <span>AZ</span>
                            <span>{` ${accountDetails.zipCode}`}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mb-4">
                <Trans i18nKey="Confirmation_email_will_be_sent_to" t={t}>
                A confirmation email will be sent to <span className="font-weight-bold">{{email: (accountDetails.email).toLowerCase()}}</span>
                </Trans>
            </div>

            <form onSubmit={(e) => submitForm(e)}>
                <div>
                    <h4 className="mb-3" style={{ color: 'rgba(51, 51, 51, 0.537)' }}>{t("Home information")}</h4>
                    <div className="mb-3">
                        <label className="mb-0">{t("Square footage")}</label>
                        <Select
                            value={squareFootage}
                            onChange={event => setSquareFootage(event.target.value)}
                            fullWidth>
                            <MenuItem value={"0-800"}><span className="ml-2">0-800</span></MenuItem>
                            <MenuItem value={"801-1200"}><span className="ml-2">801-1200</span></MenuItem>
                            <MenuItem value={"1201-1400"}><span className="ml-2">1201-1400</span></MenuItem>
                            <MenuItem value={"1401-1600"}><span className="ml-2">1401-1600</span></MenuItem>
                            <MenuItem value={"1601-1800"}><span className="ml-2">1601-1800</span></MenuItem>
                            <MenuItem value={"1801-2000"}><span className="ml-2">1801-2000</span></MenuItem>
                            <MenuItem value={"2001-2500"}><span className="ml-2">2001-2500</span></MenuItem>
                            <MenuItem value={"2501-3000"}><span className="ml-2">2501-3000</span></MenuItem>
                            <MenuItem value={"3001-3500"}><span className="ml-2">3001-3500</span></MenuItem>
                            <MenuItem value={"3501-4000"}><span className="ml-2">3501-4000</span></MenuItem>
                            <MenuItem value={"4001-4500"}><span className="ml-2">4001-4500</span></MenuItem>
                            <MenuItem value={"4501-5000"}><span className="ml-2">4501-5000</span></MenuItem>
                            <MenuItem value={"5000+"}><span className="ml-2">5000+</span></MenuItem>
                        </Select>
                    </div>
                    <div>
                        <label className="mb-0">{t("Home's electric type")}</label>
                        <div className="d-lg-block d-none">
                            <FormGroup row className="ml-2">
                                <FormControlLabel
                                    control={<Radio
                                        checked={electricType === budgetBillingConstants.ELECTRIC_TYPE.ALL_ELECTRIC}
                                        onChange={event => setElectricType(event.target.value)}
                                        value={budgetBillingConstants.ELECTRIC_TYPE.ALL_ELECTRIC}
                                        color="primary"
                                    />}
                                    label={<span style={{ color: '#087ED8' }}>{t("All electric")}</span>}
                                />
                                <FormControlLabel
                                    control={<Radio
                                        checked={electricType === budgetBillingConstants.ELECTRIC_TYPE.DUAL}
                                        onChange={event => setElectricType(event.target.value)}
                                        value={budgetBillingConstants.ELECTRIC_TYPE.DUAL}
                                        color="primary"
                                    />}
                                    label={<span style={{ color: '#087ED8' }}>{t("Dual system")}</span>}
                                />
                                <FormControlLabel
                                    control={<Radio
                                        checked={electricType === budgetBillingConstants.ELECTRIC_TYPE.UNKNOWN}
                                        onChange={event => setElectricType(event.target.value)}
                                        value={budgetBillingConstants.ELECTRIC_TYPE.UNKNOWN}
                                        color="primary"
                                    />}
                                    label={<span style={{ color: '#087ED8' }}>{t("Don't know")}</span>}
                                />
                            </FormGroup>
                        </div>
                        <div className="d-lg-none d-block">
                            <FormGroup className="ml-2" row={false}>
                                <FormControlLabel
                                    control={<Radio
                                        checked={electricType === budgetBillingConstants.ELECTRIC_TYPE.ALL_ELECTRIC}
                                        onChange={event => setElectricType(event.target.value)}
                                        value={budgetBillingConstants.ELECTRIC_TYPE.ALL_ELECTRIC}
                                        color="primary"
                                    />}
                                    label={<span style={{ color: '#087ED8' }}>{t("All electric")}</span>}
                                    className="mb-0 mr-0"
                                />
                                <FormControlLabel
                                    control={<Radio
                                        checked={electricType === budgetBillingConstants.ELECTRIC_TYPE.DUAL}
                                        onChange={event => setElectricType(event.target.value)}
                                        value={budgetBillingConstants.ELECTRIC_TYPE.DUAL}
                                        color="primary"
                                    />}
                                    label={<span style={{ color: '#087ED8' }}>{t("Dual system")}</span>}
                                    className="mb-0 mr-0"
                                />
                                <FormControlLabel
                                    control={<Radio
                                        checked={electricType === budgetBillingConstants.ELECTRIC_TYPE.UNKNOWN}
                                        onChange={event => setElectricType(event.target.value)}
                                        value={budgetBillingConstants.ELECTRIC_TYPE.UNKNOWN}
                                        color="primary"
                                    />}
                                    label={<span style={{ color: '#087ED8' }}>{t("Don't know")}</span>}
                                    className="mr-0"
                                />
                            </FormGroup>
                        </div>
                    </div>
                    <div className="mb-4">
                        <label>{t("Select options if applicable")}</label>
                        <div className="row">
                            <div className="col-6">
                                <button
                                    className="button-focus pointer-btns"
                                    type="button"
                                    onClick={() => selectPoolOnClick(!hasPool)}
                                    style={hasPool ? budgetBillingBtnSelected : budgetBillingBtn}>
                                    <img className="mt-2" src={hasPool ? PoolIconBlue : PoolIconGrey} style={iconStyle} />
                                    <div className="mb-2">{t("I have a pool")}</div>
                                </button>
                            </div>
                            <div className="col-6">
                                <button
                                    className="button-focus pointer-btns"
                                    type="button"
                                    onClick={() => selectEvapCoolerOnClick(!hasEvapCooler)}
                                    style={hasEvapCooler ? budgetBillingBtnSelected : budgetBillingBtn}>
                                    <img className="mt-2" src={hasEvapCooler ? EvapCoolerIconBlue : EvapCoolerIconGrey} style={iconStyle} />
                                    <div className="mb-2">{t("I have an evaporative cooler")}</div>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="d-flex flex-wrap justify-content-end">
                    <button className="btn srp-btn btn-lightblue ml-2" type="button" onClick={() => setActiveStepOnClick(activeStep - 1)}>{t("Previous")}</button>
                    <button
                        className="btn srp-btn btn-green ml-2"
                        type="submit"
                        disabled={submitBudgetBillingStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS}>
                        {submitBudgetBillingStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS
                            ? <CircularProgress size={20} thickness={5} style={{ color: 'white' }} />
                            : t("Submit")
                        }
                    </button>
                </div>
            </form>
        </div>

    )

}

BudgetBillingHomeDetails.propTypes = {
    activeStep: PropTypes.number,
    setActiveStepOnClick: PropTypes.func,

    accountDetails: PropTypes.object,

    squareFootage: PropTypes.string,
    setSquareFootage: PropTypes.func,

    electricType: PropTypes.string,
    setElectricType: PropTypes.func,

    hasPool: PropTypes.bool,
    selectPoolOnClick: PropTypes.func,

    hasEvapCooler: PropTypes.bool,
    selectEvapCoolerOnClick: PropTypes.func,

    createAndSubmitBudgetBillingForm: PropTypes.func,
    submitBudgetBillingStatus: PropTypes.string,

    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired,
}

export default BudgetBillingHomeDetails