import React from 'react'
import { withTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import '../../../styles/dashboard-page.css'

import CallAnytime from '../../myaccount_header/call-anytime'

import SinglePurchaseSubmittedCardContainer from './single-purchase-submitted-card-container'

const SinglePurchaseSubmittedPagePure = ({t}) => (
    <div className="container py-3">
        <CallAnytime />

        <h3 className="mt-3 mb-5">{t("Make a purchase")}</h3>

        <div className="row">
            <div className="col-lg-6">
                <SinglePurchaseSubmittedCardContainer />
            </div>
        </div>
    </div>
)

SinglePurchaseSubmittedPagePure.propTypes = {
    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired
}

const SinglePurchaseSubmittedPage = withTranslation("singlePurchasePage")(SinglePurchaseSubmittedPagePure)
export { SinglePurchaseSubmittedPage as default, SinglePurchaseSubmittedPagePure }