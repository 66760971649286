import React from 'react'
import PropTypes from 'prop-types'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'

const CustomDueDateStepper = (props) => {
  const stepLabels = ['Account details', 'Select date']
  return (
    <div>
      <Stepper activeStep={props.currentStep}>
        {stepLabels.map((label) => {
          return (
            <Step key={label}>
              <StepLabel>{props.t(label)}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
    </div>
  )
}

CustomDueDateStepper.propTypes = {
  currentStep: PropTypes.number,
  t: PropTypes.func.isRequired,
}

export default CustomDueDateStepper