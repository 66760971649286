import * as loadingStatus from '../../../constants/loading-status-constants'
import { billAccountConstants } from '../../../constants/bill-account-constants'
import {
    itemizedStatementConstants,
    creditHistoryConstants,
    usageHistoryConstants,
    energyScoreCardConstants,
    energyScoreCardFAQConstants,
    daytimeSaverPilotConstants,
    communitySolarConstants
} from '../../../constants/document-center-constants'

const initialState = {
    itemizedStatementLink: {},
    itemizedStatementLinkResultStatus: loadingStatus.LOADING_STATUS_INIT,
    requestItemizedHistory: "notRequested",
    itemizedHistoryRequestResultStatus: loadingStatus.LOADING_STATUS_INIT,

    creditHistoryLink: {},
    creditHistoryLinkResultStatus: loadingStatus.LOADING_STATUS_INIT,

    usageHistoryLink: {},
    usageHistoryLinkResultStatus: loadingStatus.LOADING_STATUS_INIT,

    energyScoreCardLinks: [],
    energyScoreCardLinksResultStatus: loadingStatus.LOADING_STATUS_INIT,

    energyScoreCardFAQLink: {},
    energyScoreCardFAQLinkResultStatus: loadingStatus.LOADING_STATUS_INIT,

    daytimeSaverPilotLinks: {},
    daytimeSaverPilotResultStatus: loadingStatus.LOADING_STATUS_INIT,

    communitySolarLink: {},
    communitySolarLinkResultStatus: loadingStatus.LOADING_STATUS_INIT
}

export default function documentCenterReducer(state = initialState, action) {
    switch (action.type) {

        case billAccountConstants.SELECT_BILL_ACCOUNT_SUCCESS:
            return initialState

        case itemizedStatementConstants.REFRESH_ITEMIZED_STATEMENT_LINK_REQUEST:
            return { ...state, itemizedStatementLink: initialState.itemizedStatementLink, itemizedStatementLinkResultStatus: initialState.itemizedStatementLinkResultStatus }
        case itemizedStatementConstants.GET_ITEMIZED_STATEMENT_LINK_REQUEST:
            return state
        case itemizedStatementConstants.GET_ITEMIZED_STATEMENT_LINK_SUCCESS:
            return { ...state, itemizedStatementLink: action.payload, itemizedStatementLinkResultStatus: loadingStatus.LOADING_STATUS_SUCCESS }
        case itemizedStatementConstants.GET_ITEMIZED_STATEMENT_LINK_FAILURE:
            return { ...state, itemizedStatementLink: initialState.itemizedStatementLink, itemizedStatementLinkResultStatus: loadingStatus.LOADING_STATUS_FAILURE }

        case itemizedStatementConstants.REQUEST_ITEMIZED_STMT_REQUEST:
            return { ...state, itemizedHistoryRequestResultStatus: loadingStatus.LOADING_STATUS_IN_PROGRESS }
        case itemizedStatementConstants.REQUEST_ITEMIZED_STMT_SUCCESS:
            return { ...state, requestItemizedHistory: "success", itemizedHistoryRequestResultStatus: loadingStatus.LOADING_STATUS_SUCCESS }
        case itemizedStatementConstants.REQUEST_ITEMIZED_STMT_FAILURE:
            return { ...state, requestItemizedHistory: "failure", itemizedHistoryRequestResultStatus: loadingStatus.LOADING_STATUS_FAILURE }

        case creditHistoryConstants.GET_CREDIT_HISTORY_LINK_REQUEST:
            return state
        case creditHistoryConstants.GET_CREDIT_HISTORY_LINK_SUCCESS:
            return { ...state, creditHistoryLink: action.payload, creditHistoryLinkResultStatus: loadingStatus.LOADING_STATUS_SUCCESS }
        case creditHistoryConstants.GET_CREDIT_HISTORY_LINK_FAILURE:
            return { ...state, creditHistoryLink: initialState.creditHistoryLink, creditHistoryLinkResultStatus: loadingStatus.LOADING_STATUS_FAILURE }

        case usageHistoryConstants.REFRESH_USAGE_HIST_LINK_REQUEST:
            return { ...state, usageHistoryLink: initialState.usageHistoryLink, usageHistoryLinkResultStatus: initialState.usageHistoryLinkResultStatus }
        case usageHistoryConstants.GET_USAGE_HIST_LINK_REQUEST:
            return state
        case usageHistoryConstants.GET_USAGE_HIST_LINK_SUCCESS:
            return { ...state, usageHistoryLink: action.payload, usageHistoryLinkResultStatus: loadingStatus.LOADING_STATUS_SUCCESS }
        case usageHistoryConstants.GET_USAGE_HIST_LINK_FAILURE:
            return { ...state, usageHistoryLink: initialState.usageHistoryLink, usageHistoryLinkResultStatus: loadingStatus.LOADING_STATUS_FAILURE }

        case energyScoreCardConstants.GET_SCORECARD_LINKS_REQUEST:
            return state
        case energyScoreCardConstants.GET_SCORECARD_LINKS_SUCCESS:
            return { ...state, energyScoreCardLinks: action.payload, energyScoreCardLinksResultStatus: loadingStatus.LOADING_STATUS_SUCCESS }
        case energyScoreCardConstants.GET_SCORECARD_LINKS_FAILURE:
            return { ...state, energyScoreCardLinks: initialState.energyScoreCardLinks, energyScoreCardLinksResultStatus: loadingStatus.LOADING_STATUS_FAILURE }

        case energyScoreCardFAQConstants.GET_SCORECARD_FAQ_LINK_REQUEST:
            return state
        case energyScoreCardFAQConstants.GET_SCORECARD_FAQ_LINK_SUCCESS:
            return { ...state, energyScoreCardFAQLink: action.payload, energyScoreCardFAQLinkResultStatus: loadingStatus.LOADING_STATUS_SUCCESS }
        case energyScoreCardFAQConstants.GET_SCORECARD_FAQ_LINK_FAILURE:
            return { ...state, energyScoreCardFAQLink: initialState.energyScoreCardFAQLink, energyScoreCardFAQLinkResultStatus: loadingStatus.LOADING_STATUS_FAILURE }


        case daytimeSaverPilotConstants.GET_DAYTIME_SAVER_LINKS_REQUEST:
            return state
        case daytimeSaverPilotConstants.GET_DAYTIME_SAVER_LINKS_SUCCESS:
            return { ...state, daytimeSaverPilotLinks: action.payload, daytimeSaverPilotResultStatus: loadingStatus.LOADING_STATUS_SUCCESS }
        case daytimeSaverPilotConstants.GET_DAYTIME_SAVER_LINKS_FAILURE:
            return { ...state, daytimeSaverPilotLinks: initialState.daytimeSaverPilotLinks, daytimeSaverPilotResultStatus: loadingStatus.LOADING_STATUS_FAILURE }


        case communitySolarConstants.GET_COMMUNITY_SOLAR_LINK_REQUEST:
            return state
        case communitySolarConstants.GET_COMMUNITY_SOLAR_LINK_SUCCESS:
            return { ...state, communitySolarLink: action.payload, communitySolarLinkResultStatus: loadingStatus.LOADING_STATUS_SUCCESS }
        case communitySolarConstants.GET_COMMUNITY_SOLAR_LINK_FAILURE:
            return { ...state, communitySolarLink: initialState.communitySolarLink, communitySolarLinkResultStatus: loadingStatus.LOADING_STATUS_FAILURE }

        default:
            return state
    }
}