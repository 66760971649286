import { usageConstants } from '../../../constants/usage-constants'

export const getUsageChartTab = (event, value) => ({
    type: usageConstants.GET_USAGE_CHART_TAB,
    value
})

export const getUsagePageChartTab = (event, value) => ({
    type: usageConstants.GET_USAGE_PAGE_CHART_TAB,
    value
})

export const getUsageHourlyChartType = (chartType) => ({
    type: usageConstants.GET_USAGE_HOURLY_CHART_TYPE,
    chartType
})

export const getUsageDailyChartType = (chartType) => ({
    type: usageConstants.GET_USAGE_DAILY_CHART_TYPE,
    chartType
})

export const getUsageMonthlyChartType = (chartType) => ({
    type: usageConstants.GET_USAGE_MONTHLY_CHART_TYPE,
    chartType
})

export const changeDateRange = (startDate, endDate) => ({
    type: usageConstants.CHANGE_DATE_RANGE,
    startDate,
    endDate
})
export const changeHourlyDateRange = (startDate, endDate) =>({
    type: usageConstants.CHANGE_HOURLY_DATE_RANGE,
    startDate,
    endDate
})
export const changeMonthlyDateRange = (startDate, endDate) =>({
    type: usageConstants.CHANGE_MONTHLY_DATE_RANGE,
    startDate,
    endDate
}) 
export const initHourlyUsagePage = (date, chartType) =>({
    type: usageConstants.INIT_HOURLY_USAGE_PAGE,
    date,
    chartType
})
export const initDailyUsagePage = (startDate, endDate, chartType) =>({
    type: usageConstants.INIT_DAILY_USAGE_PAGE,
    startDate,
    endDate,
    chartType
})
export const initMonthlyUsagePage = (startDate, endDate, chartType) =>({
    type: usageConstants.INIT_MONTHLY_USAGE_PAGE,
    startDate,
    endDate,
    chartType
})
export const getHourlyDataTable = (showTable) => ({
    type: usageConstants.GET_HOURLY_DATA_TABLE,
    showTable
})

export const getDailyDataTable = (showTable) => ({
    type: usageConstants.GET_DAILY_DATA_TABLE,
    showTable
})

export const getMonthlyDataTable = (showTable) => ({
    type: usageConstants.GET_MONTHLY_DATA_TABLE,
    showTable
})

export const setUsagePageFromDashButtons = (chartType, beginningTab) => ({
    type: usageConstants.SET_USAGE_PAGE_FROM_DASH_BUTTONS,
    chartType,
    beginningTab

})

