import React from 'react'
import PropTypes from 'prop-types'
import { DateTime } from 'luxon'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableHead from '@mui/material/TableHead'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import TableFooter from '@mui/material/TableFooter'
import IconButton from '@mui/material/IconButton'
import Icon from '@mui/material/Icon'

import '../../../styles/tables.css'
import config from 'my-account-config'

import accountHistoryTable from '../../../srp_modules/account-history-table'

const UsageHistoryTable = ({numOfBillsDisplayed, accountHistoryList, getPrevUsageHistoryOnClick, getNextUsageHistoryOnClick, usageHistoryIndex,
    eBillDocumentList, selectedBillAccount, isResidentialIDR, t}) => {

    let showOnOffPeak = {display:'none'}
    let showOnOffPeakResp = {display:'none'}

    let showShoulder = {display:'none'}
    let showShoulderResp = {display:'none'}

    let showSuperOffPeak = {display:'none'}
    let showSuperOffPeakResp = {display:'none'}

    let showTotalKwh = {display:'none'}
    let showTotalKwhResp = {display:'none'}

    let showDemand = {display:'none'}
    let showDemandResp = {display:'none'}

    let showOnPeakDemand = {display:'none'}
    let showOnPeakDemandResp = {display:'none'}

    let showAvgDemand = {display:'none'}
    let showAvgDemandResp = {display:'none'}

    let showExport = {display:'none'}
    let showExportResp = {display:'none'}

    let showOnsiteGen = {display:'none'}
    let showOnsiteGenResp = {display:'none'}

    let showTotalEnergy = {display:'none'}
    let showTotalEnergyResp = {display:'none'}

    if(accountHistoryList.length !== 0)
    {
        for(let i=0; i < numOfBillsDisplayed; i++)
        {
            if(accountHistoryList[i].meteredOffPeakUsageAmount !== 0) {
                showOnOffPeak = {display:'table-cell'}
            }

            if(accountHistoryList[i].meteredShoulderUsageAmount !== 0) {
                showShoulder = {display:'table-cell'}
            }

            if(accountHistoryList[i].meteredSuperOffPeakUsageAmount !== 0) {
                showSuperOffPeak = {display:'table-cell'}
            }

            if(accountHistoryList[i].meteredUsageAmount !== 0) {
                showTotalKwh = {display:'table-cell'}
            }

            if(accountHistoryList[i].demand !== 0) {
                showDemand = {display:'table-cell'}
            }

            if(accountHistoryList[i].onPeakDemand !== 0) {
                showOnPeakDemand = {display:'table-cell'}
            }

            if(accountHistoryList[i].averageDemand !== 0) {
                showAvgDemand = {display:'table-cell'}
            }

            if(accountHistoryList[i].totalReceived !== 0 || isResidentialIDR) {
                showExport = {display:'table-cell'}
            }

            if(accountHistoryList[i].totalGeneration !== 0) {
                showOnsiteGen = {display:'table-cell'}
            }

            if(accountHistoryList[i].totalConsumption !== 0) {
                showTotalEnergy = {display:'table-cell'}
            }
        }

        accountHistoryList.forEach(history => {
            if(history.meteredOffPeakUsageAmount !== 0) {
                showOnOffPeakResp = {alignItems: 'center'}
            }

            if(history.meteredShoulderUsageAmount !== 0) {
                showShoulderResp = {alignItems: 'center'}
            }

            if(history.meteredSuperOffPeakUsageAmount !== 0) {
                showSuperOffPeakResp = {alignItems: 'center'}
            }

            if(history.meteredUsageAmount !== 0) {
                showTotalKwhResp = {alignItems: 'center'}
            }

            if(history.demand !== 0) {
                showDemandResp = {alignItems: 'center'}
            }

            if(history.onPeakDemand !== 0) {
                showOnPeakDemandResp = {alignItems: 'center'}
            }

            if(history.averageDemand !== 0) {
                showAvgDemandResp = {alignItems: 'center'}
            }

            if(history.totalReceived !== 0 || isResidentialIDR) {
                showExportResp = {alignItems: 'center'}
            }

            if(history.totalGeneration !== 0) {
                showOnsiteGenResp = {alignItems: 'center'}
            }

            if(history.totalConsumption !== 0) {
                showTotalEnergyResp = {alignItems: 'center'}
            }
        })
    }


    return (
        <div>
            <div className="d-lg-block d-none" style={{overflow: 'auto'}}>
                <Table className="mb-4" id="usagetable">
                    <TableHead className="srp-table-head">
                        <TableRow>
                            <TableCell className="account-history-col-view border-0" padding="none">{t("View")}</TableCell>
                            <TableCell className="account-history-col-usageBillDate border-0" padding="none">{t("Bill date")}</TableCell>
                            <TableCell className="account-history-col-readDate border-0" padding="none">{t("Read date")}</TableCell>
                            <TableCell className="account-history-usage-col border-0" padding="none" style={showOnPeakDemand}>{t("On-peak Billed kW")}</TableCell>
                            <TableCell className="account-history-usage-col border-0" padding="none" style={showAvgDemand}>{t("On-peak kW Avg")}</TableCell>
                            <TableCell className="account-history-usage-col border-0" padding="none" style={showDemand}>{t("Billed kW")}</TableCell>
                            <TableCell className="account-history-usage-col border-0" padding="none" style={showSuperOffPeak}>{t("Super Off-peak kWh")}</TableCell>
                            <TableCell className="account-history-usage-col border-0" padding="none" style={showOnOffPeak}>{t("Off-peak kWh")}</TableCell>
                            <TableCell className="account-history-usage-col border-0" padding="none" style={showShoulder}>{t("Shoulder kWh")}</TableCell>
                            <TableCell className="account-history-usage-col border-0" padding="none" style={showOnOffPeak}>{t("On-peak kWh")}</TableCell>
                            <TableCell className="account-history-usage-col border-0" padding="none" style={showTotalKwh}>{t("Total kWh")}</TableCell>
                            <TableCell className="account-history-usage-col border-0" padding="none" style={showExport}>{t("Export kWh")}</TableCell>
                            <TableCell className="account-history-usage-col border-0" padding="none" style={showOnsiteGen}>{t("Onsite Generation kWh")}</TableCell>
                            <TableCell className="account-history-usage-col border-0" padding="none" style={showTotalEnergy}>{t("Total Household Energy Use kWh")}</TableCell>
                            <TableCell className="account-history-usage-col border-0" padding="none">{t("Days")}</TableCell>
                            <TableCell className="account-history-usage-col border-0" padding="none">{t("New charges")}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody className="srp-table-body">
                        {accountHistoryList.slice(0, numOfBillsDisplayed).map( (row, index) => {
                            let currentBillPDF=accountHistoryTable.getBill(eBillDocumentList, row.billGeneratedDate)
                            return (
                                <TableRow key={index}>
                                    <TableCell className="account-history-col-view" padding="none">
                                        <IconButton
                                            href={currentBillPDF}
                                            target="BillPDF"
                                            className="button-focus srp-table-button"
                                            color="primary"
                                            style={{textDecoration: 'none'}}
                                            disabled={currentBillPDF === "" ? true : false}
                                            size="large">
                                            <Icon>visibility</Icon>
                                        </IconButton>
                                    </TableCell>
                                    <TableCell className="account-history-col-usageBillDate" padding="none">{DateTime.fromISO(row.billGeneratedDate).toFormat('M/d/yy')}</TableCell>
                                    <TableCell className="account-history-col-readDate" padding="none">{DateTime.fromISO(row.displayEndDate).toFormat('M/d')}</TableCell>
                                    <TableCell className="account-history-usage-col" padding="none" style={showOnPeakDemand}>
                                        {row.onPeakDemand === 0 ? "- -" : formatNumber(row.onPeakDemand)}
                                    </TableCell>
                                    <TableCell className="account-history-usage-col" padding="none" style={showAvgDemand}>
                                        {row.averageDemand === 0 ? "- -" : formatNumber(row.averageDemand)}
                                    </TableCell>
                                    <TableCell className="account-history-usage-col" padding="none" style={showDemand}>
                                        {row.demand === 0 ? "- -" : formatNumber(row.demand)}
                                    </TableCell>
                                    <TableCell className="account-history-usage-col" padding="none" style={showSuperOffPeak}>
                                        {row.meteredSuperOffPeakUsageAmount === 0 ? "- -" : roundAndFormatNumber(row.meteredSuperOffPeakUsageAmount)}
                                    </TableCell>
                                    <TableCell className="account-history-usage-col" padding="none" style={showOnOffPeak}>
                                        {row.meteredOffPeakUsageAmount === 0 ? "- -" : roundAndFormatNumber(row.meteredOffPeakUsageAmount)}
                                    </TableCell>
                                    <TableCell className="account-history-usage-col" padding="none" style={showShoulder}>
                                        {row.meteredShoulderUsageAmount === 0 ? "- -" : roundAndFormatNumber(row.meteredShoulderUsageAmount)}
                                    </TableCell>
                                    <TableCell className="account-history-usage-col" padding="none" style={showOnOffPeak}>
                                        {row.meteredOnPeakUsageAmount === 0 ? "- -" : roundAndFormatNumber(row.meteredOnPeakUsageAmount)}
                                    </TableCell>
                                    <TableCell className="account-history-usage-col" padding="none" style={showTotalKwh}>
                                        {row.meteredUsageAmount === 0 ? "- -" : roundAndFormatNumber(row.meteredUsageAmount)}
                                    </TableCell>
                                    <TableCell className="account-history-usage-col" padding="none" style={showExport}>
                                        {row.totalReceived === 0 ? "- -" : roundAndFormatNumber(row.totalReceived)}
                                    </TableCell>
                                    <TableCell className="account-history-usage-col" padding="none" style={showOnsiteGen}>
                                        {row.totalGeneration === 0 ? "- -" : roundAndFormatNumber(row.totalGeneration)}
                                    </TableCell>
                                    <TableCell className="account-history-usage-col" padding="none" style={showTotalEnergy}>
                                        {row.totalConsumption === 0 ? "- -" : roundAndFormatNumber(row.totalConsumption)}
                                    </TableCell>
                                    <TableCell className="account-history-usage-col" padding="none">{getDaysInBillCycle(row.billStartDate, row.billEndDate)}</TableCell>
                                    <TableCell className="account-history-usage-col" padding="none">${formatNumber((row.totalUsageCharges + row.nonEnergyChargeAmount).toFixed(2))}</TableCell>
                                </TableRow>
                            )})}
                    </TableBody>
                    <TableFooter className="srp-table-footer">
                        <TableRow>
                            <TableCell className="account-history-col-view" padding="none"/>
                            <TableCell className="account-history-col-usageBillDate" padding="none">{t("Total")}</TableCell>
                            <TableCell className="account-history-col-readDate" padding="none"/>
                            <TableCell className="account-history-usage-col" padding="none" style={showOnPeakDemand}/>
                            <TableCell className="account-history-usage-col" padding="none"style={showAvgDemand}/>
                            <TableCell className="account-history-usage-col" padding="none" style={showDemand}/>
                            <TableCell className="account-history-usage-col" padding="none" style={showSuperOffPeak}>{getTotalSuperOff(accountHistoryList, numOfBillsDisplayed)}</TableCell>
                            <TableCell className="account-history-usage-col" padding="none" style={showOnOffPeak}>{getTotalOffPeak(accountHistoryList, numOfBillsDisplayed)}</TableCell>
                            <TableCell className="account-history-usage-col" padding="none" style={showShoulder}>{getTotalShoulder(accountHistoryList, numOfBillsDisplayed)}</TableCell>
                            <TableCell className="account-history-usage-col" padding="none" style={showOnOffPeak}>{getTotalOnPeak(accountHistoryList, numOfBillsDisplayed)}</TableCell>
                            <TableCell className="account-history-usage-col" padding="none" style={showTotalKwh}>{getTotalUsage(accountHistoryList, numOfBillsDisplayed)}</TableCell>
                            <TableCell className="account-history-usage-col" padding="none" style={showExport}>{getTotalExport(accountHistoryList, numOfBillsDisplayed)}</TableCell>
                            <TableCell className="account-history-usage-col" padding="none" style={showOnsiteGen}>{getTotalOnsiteGen(accountHistoryList, numOfBillsDisplayed)}</TableCell>
                            <TableCell className="account-history-usage-col" padding="none" style={showTotalEnergy}>{getTotalEnergy(accountHistoryList, numOfBillsDisplayed)}</TableCell>
                            <TableCell className="account-history-usage-col" padding="none"/>
                            <TableCell className="account-history-usage-col" padding="none">${getTotalUsageCharges(accountHistoryList, numOfBillsDisplayed)}</TableCell>
                        </TableRow>
                    </TableFooter>
                </Table>

                <div className="d-none d-lg-block">
                    <div className="d-flex justify-content-end">
                        <a
                            href={config.apiBaseUrl+"api/accounts/exportAccountHistoryToExcel?billAccount="+selectedBillAccount+"&historyType=2&startDate="+accountHistoryList[numOfBillsDisplayed-1].billGeneratedDate+"&endDate="+accountHistoryList[0].billGeneratedDate}
                            className="btn srp-btn btn-lightblue">
                            {t("Export to Excel")}
                        </a>
                    </div>
                </div>
            </div>

            <div className="d-lg-none d-block">
                    <div className="row d-flex align-items-center">
                        <div className="col text-muted align-middle">
                            {t("Read date")}
                        </div>
                        <div className="col text-right">
                            {DateTime.fromISO(accountHistoryList[usageHistoryIndex].displayEndDate).toFormat('M/d/yy')}
                        </div>
                    </div>
                    <hr className="mt-2"/>
                    <div className="row" style={showOnPeakDemandResp}>
                        <div className="col text-muted align-middle">
                            {t("On-peak Demand kW")}
                        </div>
                        <div className="col text-right">
                            {accountHistoryList[usageHistoryIndex].onPeakDemand === 0
                                ? "- -"
                                : formatNumber(accountHistoryList[usageHistoryIndex].onPeakDemand)
                            }
                        </div>
                    </div>
                    <hr className="mt-2" style={showOnPeakDemandResp}/>
                    <div className="row" style={showAvgDemandResp}>
                        <div className="col text-muted align-middle">
                            {t("On-peak kW Avg")}
                        </div>
                        <div className="col text-right">
                            {accountHistoryList[usageHistoryIndex].averageDemand === 0
                                ? "- -"
                                : formatNumber(accountHistoryList[usageHistoryIndex].averageDemand)
                            }
                        </div>
                    </div>
                    <hr className="mt-2" style={showAvgDemandResp}/>
                    <div className="row" style={showDemandResp}>
                        <div className="col text-muted align-middle">
                            {t("Demand kW")}
                        </div>
                        <div className="col text-right">
                            {accountHistoryList[usageHistoryIndex].demand === 0
                                ? "- -"
                                : formatNumber(accountHistoryList[usageHistoryIndex].demand)

                            }
                        </div>
                    </div>
                    <hr className="mt-2" style={showDemandResp}/>
                    <div className="row" style={showSuperOffPeakResp}>
                        <div className="col text-muted align-middle">
                            {t("Super Off-peak kWh")}
                        </div>
                        <div className="col text-right">
                            {accountHistoryList[usageHistoryIndex].meteredSuperOffPeakUsageAmount === 0
                                ? "- -"
                                : roundAndFormatNumber(accountHistoryList[usageHistoryIndex].meteredSuperOffPeakUsageAmount)
                            }
                        </div>
                    </div>
                    <hr className="mt-2" style={showSuperOffPeakResp}/>
                    <div className="row" style={showOnOffPeakResp}>
                        <div className="col text-muted align-middle">
                            {t("Off-peak kWh")}
                        </div>
                        <div className="col text-right">
                            {accountHistoryList[usageHistoryIndex].meteredOffPeakUsageAmount === 0
                                ? "- -"
                                : roundAndFormatNumber(accountHistoryList[usageHistoryIndex].meteredOffPeakUsageAmount)
                            }
                        </div>
                    </div>
                    <hr className="mt-2" style={showOnOffPeakResp}/>
                    <div className="row" style={showShoulderResp}>
                        <div className="col text-muted align-middle">
                            {t("Shoulder kWh")}
                        </div>
                        <div className="col text-right">
                            {accountHistoryList[usageHistoryIndex].meteredShoulderUsageAmount === 0
                                ? "- -"
                                : roundAndFormatNumber(accountHistoryList[usageHistoryIndex].meteredShoulderUsageAmount)
                            }
                        </div>
                    </div>
                    <hr className="mt-2" style={showShoulderResp}/>
                    <div className="row" style={showOnOffPeakResp}>
                        <div className="col text-muted align-middle">
                            {t("On-peak kWh")}
                        </div>
                        <div className="col text-right">
                            {accountHistoryList[usageHistoryIndex].meteredOnPeakUsageAmount === 0
                                ? "- -"
                                : roundAndFormatNumber(accountHistoryList[usageHistoryIndex].meteredOnPeakUsageAmount)
                            }
                        </div>
                    </div>
                    <hr className="mt-2" style={showOnOffPeakResp}/>
                    <div className="row" style={showTotalKwhResp}>
                        <div className="col text-muted align-middle">
                            {t("Total kWh")}
                        </div>
                        <div className="col text-right">
                            {accountHistoryList[usageHistoryIndex].meteredUsageAmount === 0
                                ? "- -"
                                : roundAndFormatNumber(accountHistoryList[usageHistoryIndex].meteredUsageAmount)
                            }
                        </div>
                    </div>
                    <hr className="mt-2" style={showTotalKwhResp}/>
                    <div className="row" style={showExportResp}>
                        <div className="col text-muted align-middle">
                            {t("Export kWh")}
                        </div>
                        <div className="col text-right">
                            {accountHistoryList[usageHistoryIndex].totalReceived === 0
                                ? "- -"
                                : roundAndFormatNumber(accountHistoryList[usageHistoryIndex].totalReceived)
                            }
                        </div>
                    </div>
                    <hr className="mt-2" style={showExportResp}/>
                    <div className="row" style={showOnsiteGenResp}>
                        <div className="col text-muted align-middle">
                            {t("Onsite Generation kWh")}
                        </div>
                        <div className="col text-right">
                            {accountHistoryList[usageHistoryIndex].totalGeneration === 0
                                ? "- -"
                                : roundAndFormatNumber(accountHistoryList[usageHistoryIndex].totalGeneration)
                            }
                        </div>
                    </div>
                    <hr className="mt-2" style={showOnsiteGenResp}/>
                    <div className="row" style={showTotalEnergyResp}>
                        <div className="col text-muted align-middle">
                            {t("Total Household Energy Use kWh")}
                        </div>
                        <div className="col text-right">
                            {accountHistoryList[usageHistoryIndex].totalConsumption === 0
                                ? "- -"
                                : roundAndFormatNumber(accountHistoryList[usageHistoryIndex].totalConsumption)
                            }
                        </div>
                    </div>
                    <hr className="mt-2" style={showTotalEnergyResp}/>
                    <div className="row d-flex align-items-center">
                        <div className="col text-muted align-middle">
                            {t("Days")}
                        </div>
                        <div className="col text-right">
                            {getDaysInBillCycle(accountHistoryList[usageHistoryIndex].billStartDate, accountHistoryList[usageHistoryIndex].billEndDate)}
                        </div>
                    </div>
                    <hr className="mt-2"/>
                    <div className="row d-flex align-items-center">
                        <div className="col text-muted align-middle">
                            {t("New charges")}
                        </div>
                        <div className="col text-right">
                            ${formatNumber((accountHistoryList[usageHistoryIndex].totalUsageCharges).toFixed(2))}
                        </div>
                    </div>
                    <hr className="mt-2 mb-4"/>

                <div className="d-flex justify-content-between">
                    <button onClick={()=>getPrevUsageHistoryOnClick()} disabled={usageHistoryIndex === 0} className="btn srp-btn btn-lightblue d-flex align-items-center"><i className="material-icons">{'keyboard_arrow_left'}</i></button>
                    <button onClick={()=>getNextUsageHistoryOnClick(accountHistoryList.length)} disabled={usageHistoryIndex === (accountHistoryList.length - 1)} className="btn srp-btn btn-lightblue d-flex align-items-center"><i className="material-icons">{'keyboard_arrow_right'}</i></button>
                </div>
            </div>
        </div>
    )
}

UsageHistoryTable.propTypes = {
    numOfBillsDisplayed: PropTypes.number,
    accountHistoryList: PropTypes.arrayOf(PropTypes.object),
    getPrevUsageHistoryOnClick: PropTypes.func,
    getNextUsageHistoryOnClick: PropTypes.func,
    usageHistoryIndex: PropTypes.number,
    eBillDocumentList: PropTypes.arrayOf(PropTypes.object),
    selectedBillAccount: PropTypes.number,
    isResidentialIDR: PropTypes.bool,
    t: PropTypes.func.isRequired
}

export default UsageHistoryTable

function getDaysInBillCycle(startDate, endDate) {
    let billCycle = DateTime.fromISO(endDate).diff(DateTime.fromISO(startDate), 'days')
    return (billCycle.days + 1)
}

function getTotalOnPeak(list, numOfBills) {
    let totalOnPeak = 0
    for(let i=0; i < numOfBills; i++)
    {
        totalOnPeak += list[i].meteredOnPeakUsageAmount
    }

    return roundAndFormatNumber(totalOnPeak)
}

function getTotalOffPeak(list, numOfBills) {
    let totalOffPeak = 0
    for(let i=0; i < numOfBills; i++)
    {
        totalOffPeak += list[i].meteredOffPeakUsageAmount
    }

    return roundAndFormatNumber(totalOffPeak)
}

function getTotalShoulder(list, numOfBills) {
    let totalShoulder = 0
    for(let i=0; i < numOfBills; i++)
    {
        totalShoulder += list[i].meteredShoulderUsageAmount
    }

    return roundAndFormatNumber(totalShoulder)
}

function getTotalSuperOff(list, numOfBills) {
    let totalSuperOff = 0
    for(let i=0; i < numOfBills; i++)
    {
        totalSuperOff += list[i].meteredSuperOffPeakUsageAmount
    }

    return roundAndFormatNumber(totalSuperOff)
}

function getTotalUsage(list, numOfBills) {
    let totalUsage = 0
    for(let i=0; i < numOfBills; i++)
    {
        totalUsage += list[i].meteredUsageAmount
    }

    return roundAndFormatNumber(totalUsage)
}

function getTotalUsageCharges(list, numOfBills) {
    let totalCharges = 0
    for(let i=0; i < numOfBills; i++)
    {
        let charge = list[i].totalUsageCharges + list[i].nonEnergyChargeAmount
        totalCharges += (charge*100)
    }

    return formatNumber((totalCharges/100).toFixed(2))
}

function getTotalExport(list, numOfBills){
    let totalReceived = 0
    for(let i=0; i < numOfBills; i++)
    {
        totalReceived += list[i].totalReceived
    }

    return roundAndFormatNumber(totalReceived)
}

function getTotalOnsiteGen(list, numOfBills){
    let totalOnsiteGen = 0
    for(let i=0; i < numOfBills; i++)
    {
        totalOnsiteGen += list[i].totalGeneration
    }

    return roundAndFormatNumber(totalOnsiteGen)
}

function getTotalEnergy(list, numOfBills){
    let totalEnergy = 0
    for(let i=0; i < numOfBills; i++)
    {
        totalEnergy += list[i].totalConsumption
    }

    return roundAndFormatNumber(totalEnergy)
}

function roundAndFormatNumber(number) {
    let roundedNum = Math.round(number)
    return roundedNum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}

function formatNumber(dollar) {
    return dollar.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}