import {barCodeConstants} from '../../../constants/bar-code-constants'
import { RSAA } from 'redux-api-middleware'
import config from 'my-account-config'

export const getBarCode = (billAccount) =>{
    return dispatch => {
        return dispatch({
            [RSAA]: {
                endpoint: config.apiBaseUrl + 'api/payments/barcode?billaccount=' +
                    encodeURIComponent(billAccount),
                method: 'GET',
                credentials: 'include',
                types: [
                    barCodeConstants.GET_BAR_CODE_REQUEST,
                    {
                        type: barCodeConstants.GET_BAR_CODE_SUCCESS,
                        payload: (action, state, res) => {
                            return res.blob().then(blob => {
                                return blob
                            });
                    }
                    },
                    barCodeConstants.GET_BAR_CODE_FAILURE
                ]
            }
        })
    }
}