import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

import TextField from '@mui/material/TextField'
import CircularProgress from '@mui/material/CircularProgress'

import { getVINInformation, resetFormSubmissionStatus } from '../../../actions/auth/forms/ev-community-actions'
import { evCommunityConstants } from '../../../constants/ev-community-constants'

class EVCommunityVINValidationPure extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            isCheckingVIN: false,
            vinChecked: false,
            isVINValid: true,
            vinNumber: '',
            vehicleInfo: {
                make: '',
                model: '',
                year: '',
                isEV: false
            },
        }

        this.validateVIN = this.validateVIN.bind(this)
        this.vinLengthCheck = this.vinLengthCheck.bind(this)
        this.onTryAgainClicked = this.onTryAgainClicked.bind(this)
        this.onCheckVINClicked = this.onCheckVINClicked.bind(this)
        this.handleChange = this.handleChange.bind(this)
    }

    onTryAgainClicked() {
        this.clearVINCheckState(true)
    }

    clearVINCheckState(clearVIN) {
        this.setState({ vinChecked: false, isVINValid: true, vehicleInfo: {} })
        this.props.resetForm()

        if (clearVIN) {
            this.setState({ vinNumber: '' })
        }
        else {
            this.props.setFieldValue('vinNumber', this.state.vinNumber)
        }

        this.props.resetFormSubmitStatus()
    }

    vinLengthCheck() {
        let vinIsOk = true

        if (this.state.vinNumber.length === 0) {
            vinIsOk = false
        }

        if (this.state.vinNumber.length !== 17 && this.state.vinNumber.length > 0) {
            vinIsOk = false
        }

        return vinIsOk
    }

    onCheckVINClicked() {
        if (this.state.vinChecked === true) {
            this.clearVINCheckState(false)
        }

        if (this.vinLengthCheck() === true) {
            this.setState({ isCheckingVIN: true })

            this.props.getVINDetails(this.state.vinNumber, this.validateVIN)
        }
    }

    getIsElectric(car) {
        // since the electrificationLevel description is inconsistent, only check for the BEV or PHEV designation
        let approvedElectrificationLevels = ["bev", "phev"]
        let isElectric = false
        if (car.FuelTypePrimary === "Electric" || car.FuelTypeSecondary === "Electric") {
            isElectric = true
        }

        let bevPhev = false
        for (let i = 0; i < approvedElectrificationLevels.length; i++) {
            if (car.ElectrificationLevel.toLowerCase().includes(approvedElectrificationLevels[i])) {
                bevPhev = true
            }
        }

        if (!bevPhev) {
            isElectric = false
        }

        return isElectric
    }

    validateVIN(vehicleInformation) {
        let car = vehicleInformation.Results[0]
        let vehicleInfo = {}

        let validVIN = car.ErrorCode === '0'
        // car.ErrorText

        if (validVIN === true) {
            let isElectric = this.getIsElectric(car)
            let isUnApprovedVehicleType = this.props.unApprovedVehicleTypes.vehicleTypes.includes(car.VehicleType)

            vehicleInfo = {
                make: car.Make,
                model: car.Model,
                year: car.ModelYear,
                isEV: isElectric,
                isUnApprovedVehicleType: isUnApprovedVehicleType
            }

        }

        this.props.setFieldValue('vinNumber', this.state.vinNumber)
        this.props.setFieldValue('vinChecked', true)
        this.props.setFieldValue('vinPassesCheck', validVIN)
        this.props.setFieldValue('vehicleInfo', vehicleInfo)
        this.setState({ vinChecked: true, isVINValid: validVIN, vehicleInfo: vehicleInfo, isCheckingVIN: false })
    }

    handleChange(event) {

        if (event.target.value.match(/^[0-9a-zA-Z]+$/) || event.target.value === "") {
            if (event.target.value.length <= 17) {
                this.setState({ vinNumber: event.target.value })

                if (this.state.vinChecked === true && this.state.isVINValid === false) {
                    this.setState({ vinChecked: false, isVINValid: true })
                }
            }
        }
    }

    render() {

        let vinError = this.state.isVINValid === false && this.state.vinChecked === true
        let vehicleIneligibile = this.props.vinAlreadyUsed === true || this.props.vinPreviouslyNew === true || (this.state.vinChecked === true && (this.state.vehicleInfo.isEV === false || this.state.vehicleInfo.isUnApprovedVehicleType))
        let errors = {
            vinErrors: vinError ? this.props.t("VIN_not_valid") : ''
        }

        if (!this.props.canApply || this.props.hasNoCredits) {

            let notEligibileExplanation = this.props.hasNoCredits
                ? this.props.t("NOT_ELIGIBLE_Explanation_No_Credits")
                : this.props.t("NOT_ELIGIBLE_Explanation")

            return (
                <React.Fragment>
                    <div className="srp-card-details">
                        <div className="srp-alert-error mb-3">
                            <p>
                                <strong>{this.props.t("NOT_ELIGIBLE")} </strong> {notEligibileExplanation}
                            </p>
                            <Link to="/myaccount/dashboard" className="displayAsLink ml-2 mb-2">{this.props.t("Go_to_dashboard")}</Link>
                        </div>
                    </div>
                </React.Fragment>
            )
        }

        let vechicleNotValid = null
        let makeModelYear = null

        if (this.props.vinAlreadyUsed === true || this.props.vinPreviouslyNew === true) {
            vechicleNotValid = (
                <React.Fragment>
                    <div className="pt-2">
                        <div className="srp-alert-error mb-3">
                            {this.props.t("VIN_Already_Used")}
                        </div>
                    </div>
                </React.Fragment>
            )
        }

        if (this.state.isVINValid && this.state.vinChecked && !(this.props.vinAlreadyUsed || this.props.vinPreviouslyNew)) {

            if (!this.state.vehicleInfo.isEV || this.state.vehicleInfo.isUnApprovedVehicleType) {
                vechicleNotValid = (
                    <React.Fragment>
                        <div className="pt-2">
                            <div className="srp-alert-error mb-3">
                                {this.props.t("Vechicle_Not_EV")}
                            </div>
                        </div>
                    </React.Fragment >
                )
            }

            makeModelYear = (
                <React.Fragment>
                    <div className="srp-alert-notice">
                        <p>
                            {this.props.t("Vehicle_Details")}
                        </p>
                        <div>
                            <span className="font-weight-bold mr-2">{this.props.t("Vehicle_Make")}</span>
                            <span>{this.state.vehicleInfo.make}</span>
                        </div>
                        <div>
                            <span className="font-weight-bold mr-2">{this.props.t("Vehicle_Model")}</span>
                            <span>{this.state.vehicleInfo.model}</span>
                        </div>
                        <div>
                            <span className="font-weight-bold mr-2">{this.props.t("Vehicle_Year")}</span>
                            <span>{this.state.vehicleInfo.year}</span>
                        </div>
                    </div>
                    <div className="mt-2">
                        <span>
                            {this.props.t("Not_Correct_Vehicle")}&nbsp;
                            <button className="displayAsLink" onClick={() => this.onTryAgainClicked()}>{this.props.t("Enter_Different_VIN")}</button>
                            &nbsp;{this.props.t("Try_Again")}
                        </span>
                    </div>
                </React.Fragment>
            )
        }

        let buttons = null
        if (this.props.hideCheckVINButton === false) {
            buttons = (<div className="d-flex flex-wrap justify-content-end">
                <Link
                    className="btn srp-btn btn-lightblue p-1"
                    type="button"
                    to="/myaccount/programs">
                    {this.props.t("EV_Go_Back")}
                </Link>
                {vehicleIneligibile === false
                    ? <button
                        className="btn srp-btn btn-green ml-2 p-1"
                        disabled={
                            this.props.canApply === false ||
                            this.vinLengthCheck() === false ||
                            (this.state.vinChecked === true &&
                                this.state.isVINValid === false)
                        }
                        onClick={() => this.onCheckVINClicked()}>
                        {this.state.isCheckingVIN
                            ? <CircularProgress size={20} thickness={5} style={{ color: 'white' }} />
                            : this.props.t("Check_VIN")
                        }

                    </button>
                    : null}
            </div>)
        }

        return (
            <React.Fragment>
                <div className="srp-card-details">
                    <div>
                        <TextField
                            fullWidth
                            className="mb-3"
                            id="vinNumber"
                            name="vinnumber"
                            value={this.state.vinNumber}
                            onChange={this.handleChange}
                            disabled={
                                this.props.canApply === false ||
                                (this.state.vinChecked === true &&
                                    this.state.isVINValid === true)
                            }
                            FormHelperTextProps={{
                                className: vinError ? '' : 'd-flex justify-content-end pr-3'
                            }}
                            helperText={vinError ? errors.vinErrors : this.state.vinNumber.length + '/17'}
                            error={vinError}
                            inputProps={{ maxLength: 17 }}
                            label={this.props.t("EV_VIN_Label")}
                        />
                    </div>
                    {makeModelYear}
                    {vechicleNotValid}
                    {buttons}
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        ...state.evCommunity,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getVINDetails: async (vinNumber, callback) => {
            const result = await dispatch(getVINInformation(vinNumber))

            if (result.type == evCommunityConstants.GET_VIN_INFORMATION_SUCCESS) {
                callback(result.payload)
            }
        },
        resetFormSubmitStatus: async () => {
            await dispatch(resetFormSubmissionStatus())
        }
    }
}


EVCommunityVINValidationPure.propTypes = {
    setFieldValue: PropTypes.func.isRequired,
    setStatus: PropTypes.func.isRequired,
    resetForm: PropTypes.func.isRequired,
    resetFormSubmitStatus: PropTypes.func,
    hideCheckVINButton: PropTypes.bool,
    getVINDetails: PropTypes.func,
    canApply: PropTypes.bool,
    hasNoCredits: PropTypes.bool,
    hasCheckedVIN: PropTypes.bool,
    vinAlreadyUsed: PropTypes.bool,
    vinPreviouslyNew: PropTypes.bool,

    submitEVSignupForm: PropTypes.func,
    submitEVSignupFormStatus: PropTypes.string,

    unApprovedVehicleTypes: PropTypes.object,

    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired
}

const EVCommunityVINValidation = withTranslation("evCommunitySignupForm")(EVCommunityVINValidationPure)
export default connect(mapStateToProps, mapDispatchToProps)(EVCommunityVINValidation)
