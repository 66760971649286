import React from 'react'
import PropTypes from 'prop-types'

import { useNavigate } from 'react-router-dom'

import Card from '@mui/material/Card'
import CircularProgress from '@mui/material/CircularProgress'

import { myAccountConstants } from '../../../constants/myaccount-constants'

import PaymentDetailsTableContainer from '../common/payment-details-table-container'

import { formEvent } from '../../../srp_modules/analytics-events'
import { guestPaymentConstants } from '../common/guest-payment-constants'

export const ConfirmPaymentDetailsCardInternal = ({paymentCount, paymentTotal, isSubmitting, submitPayment, goBackToEditPaymentDetails, errorSubmittingPayment, t, i18n }) => {
    const [ confirmPaymentFormEventEmitted, setConfirmPaymentFormEventEmitted ] = React.useState(false)
    const navigate = useNavigate()

    if (paymentCount === 0) {
        navigate('/myaccount/payment/guest', { replace: true })
        return null
    }

    const formName = guestPaymentConstants.ADOBE_ANALYTICS_FORM_NAME

    const goBackToEditPaymentDetailsAndTrack = () => {
        const stepName = guestPaymentConstants.GUEST_PAYMENT_STEPS[0]
        formEvent(formName, stepName)

        goBackToEditPaymentDetails()
    }

    if (confirmPaymentFormEventEmitted === false) {
        const stepName = guestPaymentConstants.GUEST_PAYMENT_STEPS[1]
        formEvent(formName, stepName)

        setConfirmPaymentFormEventEmitted(true)
    }

    return (
    <div>
        <label className="h5">{t("Confirm payment details")}</label>
        <Card>
            {errorSubmittingPayment &&
            <div className="srp-alert-error mx-3 mt-3">
                {t("We_are_unable_to_complete", {phone: i18n.language==='es' ? myAccountConstants.SPANISH_CUSTOMER_SERVICE_PHONE_NUMBER_FMTTD : myAccountConstants.RESIDENTIAL_CUSTOMER_SERVICE_PHONE_NUMBER_FMTTD})}
            </div>
            }

            <PaymentDetailsTableContainer t={t} i18n={i18n} />

            <div className="px-3 pb-3">
                <h6 className="text-muted pb-2">{t("mpower_purchase_acknowledgement")}</h6>
                <h6 className="text-muted">{t("By_clicking_the_Submit_payment_button")}</h6>

                <div className="d-flex justify-content-end mt-4">
                    <button className={"btn srp-btn btn-lightblue mr-2"} disabled={isSubmitting} type="button" onClick={goBackToEditPaymentDetailsAndTrack} {...isSubmitting && {'aria-disabled': true, tabIndex: -1 }}>
                        <span className="d-lg-none d-block">{t("Edit_Cambiar")}</span>
                        <span className="d-none d-lg-block">{t("Edit payment details")}</span>
                    </button>
                    <button className="btn srp-btn btn-green" type="button" onClick={() => submitPayment(paymentCount, paymentTotal)} disabled={isSubmitting}>
                        {isSubmitting ? <CircularProgress size={20} thickness={5} style={{color:'white'}} /> : t("Submit payment")}
                    </button>
                </div>
            </div>
        </Card>
    </div>
    )
}

ConfirmPaymentDetailsCardInternal.propTypes = {
    paymentCount: PropTypes.number.isRequired,
    paymentTotal: PropTypes.number.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
    submitPayment: PropTypes.func.isRequired,
    goBackToEditPaymentDetails: PropTypes.func.isRequired,
    errorSubmittingPayment: PropTypes.bool.isRequired,
    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired
}

export default ConfirmPaymentDetailsCardInternal