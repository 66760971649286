import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import CreditAdvanceRequest from './credit-advance-request'
import CreditAdvanceConfirmation from './credit-advance-confirmation'
import CreditAdvanceError from './credit-advance-error'

import * as loadingStatus from '../../../../constants/loading-status-constants'

import { getMpowerSelfServiceAdvance, submitMpowerSelfServiceAdvanceRequest, resetMpowerSelfServiceAdvance } from '../../../../actions/auth/credit_advance/credit-advance-actions'
import { getPayment } from '../../../../actions/auth/payment/payment-actions'

class CreditAdvanceContainer extends React.Component {

    constructor(props) {
        super(props)

        this.backToHomeClick = this.backToHomeClick.bind(this)
    }

    backToHomeClick() {
        this.props.resetMpowerSelfServiceAdvance()
        this.props.getPayment(this.props.selectedBillAccount)
        this.props.getMpowerSelfServiceAdvance(this.props.selectedBillAccount)
        this.props.toggleSelfServiceAdvance(false)
    }

    render() {
        if (!this.props.mPowerSelfServiceAdvance.isAdvanceEligible) {
            return (
                <CreditAdvanceError
                    t={this.props.t}
                    i18n={this.props.i18n}
                    errorCode={this.props.mPowerSelfServiceAdvance.advanceEligibility}
                    backToHomeClick={this.backToHomeClick} 
                />
            )
        } else if (this.props.submitMpowerSelfServiceResponseStatus === loadingStatus.LOADING_STATUS_FAILURE) {
            return (
                <CreditAdvanceError
                    t={this.props.t}
                    i18n={this.props.i18n}
                    errorCode={loadingStatus.LOADING_STATUS_FAILURE}
                    backToHomeClick={this.backToHomeClick} 
                />
            )
        } else if (this.props.submitMpowerSelfServiceResponseStatus === loadingStatus.LOADING_STATUS_SUCCESS && !this.props.submitMpowerSelfServiceResponse.success) {
            return (
                <CreditAdvanceError
                    t={this.props.t}
                    i18n={this.props.i18n}
                    errorCode={this.props.submitMpowerSelfServiceResponse.submitAdvanceStatus}
                    backToHomeClick={this.backToHomeClick} 
                />
            )
        } else if (this.props.submitMpowerSelfServiceResponseStatus === loadingStatus.LOADING_STATUS_SUCCESS && this.props.submitMpowerSelfServiceResponse.success) {
            return (
                <CreditAdvanceConfirmation 
                    t={this.props.t}
                    mPowerSelfServiceAdvance={this.props.mPowerSelfServiceAdvance} 
                    backToHomeClick={this.backToHomeClick} 
                />
            )
        } else {
            return (
                <CreditAdvanceRequest 
                    t={this.props.t}
                    billAccount={this.props.selectedBillAccount}
                    mPowerSelfServiceAdvance={this.props.mPowerSelfServiceAdvance} 
                    payment={this.props.payment}
                    submitMpowerSelfServiceAdvanceRequest={this.props.submitMpowerSelfServiceAdvanceRequest}
                    toggleSelfServiceAdvance={this.props.toggleSelfServiceAdvance} 
                />
            )
        }
    }
}

const mapStateToProps = (state) => {
    return { ...state.accountInfo.billAccount, ...state.accountInfo.payment }
}

const mapDispatchToProps = (dispatch) => {
    return {
        submitMpowerSelfServiceAdvanceRequest: (billAccount, advanceAmount) => {
            dispatch(submitMpowerSelfServiceAdvanceRequest(billAccount, advanceAmount))
        },
        resetMpowerSelfServiceAdvance: () => {
            dispatch(resetMpowerSelfServiceAdvance())
        },
        getPayment: (billAccount) => {
            dispatch(getPayment(billAccount))
        },
        getMpowerSelfServiceAdvance: (billAccount) => {
            dispatch(getMpowerSelfServiceAdvance(billAccount))
        }
    }
}

CreditAdvanceContainer.propTypes = {
    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired,
    payment: PropTypes.object.isRequired,
    selectedBillAccountDetails: PropTypes.object.isRequired,
    submitMpowerSelfServiceResponse: PropTypes.shape({
        success: PropTypes.bool.isRequired,
        submitAdvanceStatus: PropTypes.string.isRequired,
    }).isRequired,
    submitMpowerSelfServiceResponseStatus: PropTypes.string,
    submitMpowerSelfServiceAdvanceRequest: PropTypes.func.isRequired,
    resetMpowerSelfServiceAdvance: PropTypes.func.isRequired,
    getPayment: PropTypes.func.isRequired,
    getMpowerSelfServiceAdvance: PropTypes.func.isRequired,
    selectedBillAccount: PropTypes.number.isRequired,
    mPowerSelfServiceAdvance: PropTypes.shape({
        isAdvanceEligible: PropTypes.bool.isRequired,
        advanceEligibility: PropTypes.string.isRequired,
    }).isRequired,
    toggleSelfServiceAdvance: PropTypes.func.isRequired

}

export default connect(mapStateToProps, mapDispatchToProps)(CreditAdvanceContainer)