// eslint is detecting the extra functions as multiple React components
/* eslint react/no-multi-comp: "off" */

import React from 'react'
import PropTypes from 'prop-types'
import { DateTime } from 'luxon'

import { Trans } from 'react-i18next'

const OutageAlert = ({t, customerInOutageInfo}) => {
    return(
        <div>
            {(customerInOutageInfo.isInOutageArea) &&
            <div className="mt-4">
                <div className="srp-alert-error srp-card-body">
                    <Trans i18nKey="OUTAGE_ALERT" t={t}>
                    <strong>OUTAGE ALERT</strong> A power outage is currently affecting your service address. Current estimated restoration time is {{restorationTime: DateTime.fromISO(customerInOutageInfo.estimatedRestorationTime).toFormat('EEE, MMM d, yyyy h:mm a')}}.
                    </Trans>
                </div>
            </div>}
        </div>
    )
}

OutageAlert.propTypes = {
    t: PropTypes.func.isRequired,
    customerInOutageInfo: PropTypes.object
}

export default OutageAlert