import { connect } from 'react-redux'
import { myAccountConstants } from '../../../constants/myaccount-constants'

import ConfirmPurchaseDetailsCard from './confirm-purchase-details-card'

import { submitPurchaseList, submitPurchaseListFailure } from '../../../actions/auth/payment/single-purchase-actions'

import { formCompleteEvent, formErrorEvent } from '../../../srp_modules/analytics-events'
import { purchaseConstants } from '../../common_payment/purchase-constants'
import { withRouter } from '../../../srp_modules/with-router'

const mapStateToProps = state => {
    const errorSubmittingPurchase = state.singlePurchaseApiResponse.errorSubmittingPurchase
    const isSubmitting = state.singlePurchaseApiResponse.isSubmittingPurchase

    const residentialCustomerServicePhoneNumber = myAccountConstants.RESIDENTIAL_CUSTOMER_SERVICE_PHONE_NUMBER_FMTTD
    const commercialCustomerServicePhoneNumber = myAccountConstants.COMMERCIAL_CUSTOMER_SERVICE_PHONE_NUMBER_FMTTD

    const payDownFraction = state.accountInfo.payment.payment.payDownPercent
    const amountOwedToSRP = state.accountInfo.payment.payment.amountDue
    const purchase = state.singlePurchaseList.purchaseList[0]

    return { isSubmitting, errorSubmittingPurchase, residentialCustomerServicePhoneNumber, commercialCustomerServicePhoneNumber,
        purchase, amountOwedToSRP, payDownFraction
    }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
    goBackToEditPurchaseDetails: () => {
        ownProps.router.navigate(-1)
    },
    submitPurchase: async (amountToSRP, amountToMeter) => {
        const formName = purchaseConstants.ADOBE_ANALYTICS_FORM_NAME

        const response = await dispatch(submitPurchaseList())

        if (response.error === true) {
            dispatch(submitPurchaseListFailure())
            formErrorEvent(formName)
        }
        else if (response.payload.referenceNumber > 0) {
            const meta = {
                purchaseCount: 1,
                purchases: [{amountToSRP, amountToMeter}],
                total: {amountToSRP, amountToMeter},
                sendCreditToMeter: "true" //always send yes for now until echex can change on their end to remove from endpoint US #84438
            }
            formCompleteEvent(formName, meta)

            ownProps.router.navigate('/myaccount/payment/single/mpower/submitted', { replace: true })
        }
    }
})

const ConfirmSinglePurchaseDetailsCardContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(ConfirmPurchaseDetailsCard))
export default ConfirmSinglePurchaseDetailsCardContainer