import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { withTranslation } from 'react-i18next'

import '../../../styles/dashboard-page.css'

import Button from '@mui/material/Button'
import Icon from '@mui/material/Icon'

import AccountInfoBarContainer from '../../myaccount_header/info_bar/account-info-bar-container'
import CustomDueDateForm from './custom-due-date-form'
import CustomDueDateHelp from './custom-due-date-help'
import {
    getCustomerNames,
    getServiceAddress,
    getBillingAddress,
    getBusinessInfo,
    getPhoneNumber
} from '../../../actions/auth/bill_account/bill-account-actions'
import {
    getEnrolledProgramsDetails,
    getCustomDueDateEligible
} from '../../../actions/auth/payment/payment-actions'
import CallAnytime from '../../myaccount_header/call-anytime'
import { getContacts } from '../../../actions/auth/contact/contact-actions'
import { withRouter } from '../../../srp_modules/with-router'
import * as loadingStatus from '../../../constants/loading-status-constants'

let initialState = {
    showCommercial: false
}

let updateDocumentDataIfNeeded = (props) => {
    if (props.selectedBillAccount === 0)
        return

    if (props.serviceAddressStatus === loadingStatus.LOADING_STATUS_INIT) {
        props.getServiceAddressInfo(props.selectedBillAccount)
    }

    if (props.primaryEmailStatus === loadingStatus.LOADING_STATUS_INIT) {
        props.getContactsInfo(props.selectedBillAccount)
    }

    if (props.customerNamesStatus === loadingStatus.LOADING_STATUS_INIT) {
        props.getCustomerNamesInfo(props.selectedBillAccount)
    }

    if (props.billingAddressStatus === loadingStatus.LOADING_STATUS_INIT) {
        props.getBillingAddressInfo(props.selectedBillAccount)
    }

    if (props.isCommercial === true && props.businessInfoStatus === loadingStatus.LOADING_STATUS_INIT) {
        props.getBusinessInfoModel(props.selectedBillAccount)
    }

    if (props.phoneNumberStatus === loadingStatus.LOADING_STATUS_INIT) {
        props.getPhoneInformation(props.selectedBillAccount)
    }

    if (props.isCustomDueDateEligibleStatus === loadingStatus.LOADING_STATUS_INIT) {
        props.getIsCustomDueDateEligible(props.selectedBillAccount)
    }

    if (props.enrolledProgramsDetailsStatus === loadingStatus.LOADING_STATUS_INIT) {
        props.getEnrolledProgramsDetails(props.selectedBillAccount)
    }
}

class CustomDueDateContainer extends React.Component {

    constructor(props) {
        super(props)
        this.state = initialState

        this.getIsCommercial = this.getIsCommercial.bind(this)
        this.setShowCommercial = this.setShowCommercial.bind(this)
        this.navigateBack = this.navigateBack.bind(this)
    }

    componentDidMount() {
        updateDocumentDataIfNeeded(this.props)
    }

    componentDidUpdate() {
        updateDocumentDataIfNeeded(this.props)
    }

    navigateBack() {
        this.props.router.navigate(-1)
    }

    getIsCommercial() {

        if (this.props.isLoggedIn) {
            return this.props.isCommercial
        }

        return this.state.showCommercial
    }

    setShowCommercial(showCommercial) {
        this.setState({ ...this.state, showCommercial: showCommercial })
    }

    render() {
        const t = this.props.t
        const i18n = this.props.i18n

        let accountInfoBar = null

        let commercial = this.getIsCommercial()

        if (this.props.isLoggedIn) {
            accountInfoBar = (<div>
                <AccountInfoBarContainer disableSelector={true} />
            </div>)
        }

        let title = t(this.props.isCustomDueDateEnrolled ? "Custom Due Date Change" : "Custom Due Date Signup")

        return (
            <div>
                <div className="d-block d-md-none">
                    <Button
                        variant="contained"
                        fullWidth
                        className="justify-content-start nav-button-responsive"
                        onClick={this.navigateBack}
                        disableRipple>
                        <Icon className="material-icons mr-2" style={{ color: 'rgb(33,108,224' }}>keyboard_backspace</Icon>
                        {title}
                    </Button>
                </div>

                <CallAnytime />

                <div className="container py-3">
                    <h2>{t("Select a custom due date")}</h2>
                    <div>
                        {accountInfoBar}
                        <div className="d-none d-md-block pt-4">
                            <h5 className="d-flex justify-content-start align-items-center">
                                <button className="displayAsLink" onClick={this.navigateBack}>{t("Previous page")} </button>
                                <i className="material-icons" style={{ color: 'rgba(72, 72, 74, 0.376)' }}>keyboard_arrow_right</i>
                                <span style={{ color: '#707070' }}>{title}</span>
                            </h5>
                        </div>
                    </div>

                    <div className="clearfix pt-3 pt-md-2 row">
                        <div className="col-md-6 col-12">
                            <CustomDueDateForm
                                isCommercial={commercial}
                                isLoggedIn={this.props.isLoggedIn}
                                navigateBack={this.navigateBack}
                                customerNames={this.props.customerNames}
                                customerNamesStatus={this.props.customerNamesStatus}
                                serviceAddress={this.props.serviceAddress}
                                serviceAddressStatus={this.props.serviceAddressStatus}
                                billingAddress={this.props.billingAddress}
                                billingAddressStatus={this.props.billingAddressStatus}
                                customerEmailAddress={this.props.customerEmailAddress}
                                primaryEmailStatus={this.props.primaryEmailStatus}
                                phoneNumber={this.props.phoneNumber}
                                phoneNumberStatus={this.props.phoneNumberStatus}
                                businessInfoModel={this.props.businessInfo}
                                customDueDateInfo={this.props.customDueDateInfo}
                                isCustomDueDateEligibleStatus={this.props.isCustomDueDateEligibleStatus}
                                enrolledProgramsDetailsStatus={this.props.enrolledProgramsDetailsStatus}
                                customDueDayEnrolled={this.props.customDueDayEnrolled}
                                isCustomDueDateEnrolled={this.props.isCustomDueDateEnrolled}
                                businessInfoStatus={this.props.businessInfoStatus}
                                setShowCommercial={this.setShowCommercial}
                                getIsCommercial={this.getIsCommercial}
                            />
                        </div>
                        <div className="col-md-6 col-12 pt-4 pt-md-0">
                            <CustomDueDateHelp
                                isCommercial={commercial}
                                t={t} i18n={i18n}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

CustomDueDateContainer.propTypes = {
    isLoggedIn: PropTypes.bool,
    router: PropTypes.shape({
        navigate: PropTypes.func
    }),

    getContactsInfo: PropTypes.func,
    getBillingAddressInfo: PropTypes.func,

    selectedBillAccount: PropTypes.number.isRequired,
    isCommercial: PropTypes.bool.isRequired,

    getCustomerNamesInfo: PropTypes.func,
    customerNames: PropTypes.object,
    customerNamesStatus: PropTypes.string,

    getServiceAddressInfo: PropTypes.func,
    serviceAddress: PropTypes.object,
    serviceAddressStatus: PropTypes.string,

    getBusinessInfoModel: PropTypes.func,
    businessInfo: PropTypes.object,
    businessInfoStatus: PropTypes.string,

    getPhoneInformation: PropTypes.func,
    phoneNumber: PropTypes.string,
    phoneNumberStatus: PropTypes.string,

    customerEmailAddress: PropTypes.string,
    primaryEmailStatus: PropTypes.string,

    billingAddress: PropTypes.object,
    billingAddressStatus: PropTypes.string,

    getEnrolledProgramsDetails: PropTypes.func.isRequired,
    enrolledProgramsDetailsStatus: PropTypes.string.isRequired,
    isCustomDueDateEnrolled: PropTypes.bool.isRequired,
    customDueDayEnrolled: PropTypes.number.isRequired,

    customDueDate: PropTypes.number,
    customDueDateInfo: PropTypes.object,
    isCustomDueDateEligibleStatus: PropTypes.string,

    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired,
}

const mapStateToProps = state => {
    return {
        isCommercial: state.accountInfo.billAccount.selectedBillAccountDetails.isCommercial,
        selectedBillAccount: state.accountInfo.billAccount.selectedBillAccount,
        ...state.login,
        ...state.navigation,
        customerNames: state.accountInfo.billAccount.customerNames,
        customerNamesStatus: state.accountInfo.billAccount.customerNamesStatus,
        serviceAddress: state.accountInfo.billAccount.serviceAddress,
        serviceAddressStatus: state.accountInfo.billAccount.serviceAddressStatus,
        customerEmailAddress: state.accountInfo.contact.primaryEmail,
        primaryEmailStatus: state.accountInfo.contact.primaryEmailStatus,
        phoneNumber: state.accountInfo.billAccount.phoneNumber.bestPhone,
        phoneNumberStatus: state.accountInfo.billAccount.phoneNumberStatus,
        billingAddress: state.accountInfo.billAccount.billingAddress,
        billingAddressStatus: state.accountInfo.billAccount.billingAddressStatus,
        businessInfoStatus: state.accountInfo.billAccount.businessInfoStatus,
        businessInfo: state.accountInfo.billAccount.businessInfo,
        customDueDateInfo: state.accountInfo.payment.customDueDateInfo,
        isCustomDueDateEnrolled: state.accountInfo.payment.enrolledProgramsDetails.customDueDate.isEnrolled,
        customDueDayEnrolled: state.accountInfo.payment.enrolledProgramsDetails.customDueDate.optionValue,
        enrolledProgramsDetailsStatus: state.accountInfo.payment.enrolledProgramsDetailsStatus,
        isCustomDueDateEligibleStatus: state.accountInfo.payment.isCustomDueDateEligibleStatus,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getServiceAddressInfo: async (billAccount) => {
            await dispatch(getServiceAddress(billAccount));
        },
        getContactsInfo: (billAccount) => {
            dispatch(getContacts(billAccount))
        },
        getCustomerNamesInfo: (billAccount) => {
            dispatch(getCustomerNames(billAccount))
        },
        getBillingAddressInfo: (billAccount) => {
            dispatch(getBillingAddress(billAccount))
        },
        getBusinessInfoModel: (billAccount) => {
            dispatch(getBusinessInfo(billAccount))
        },
        getPhoneInformation: (billAccount) => {
            dispatch(getPhoneNumber(billAccount))
        },
        getIsCustomDueDateEligible: (billAccount) => {
            dispatch(getCustomDueDateEligible(billAccount))
        },
        getEnrolledProgramsDetails: (billAccount) => {
            dispatch(getEnrolledProgramsDetails(billAccount))
        },
    }
}

export default withTranslation("customDueDate")(withRouter(connect(mapStateToProps, mapDispatchToProps)(CustomDueDateContainer)))