import {prepayHistoryConstants} from '../../../constants/payment/prepay-history-constants'
import {RSAA} from 'redux-api-middleware'
import config from 'my-account-config'

export const getPrepayHistory = (billAccount) => {
    return dispatch => {
        return dispatch({
            [RSAA]: {
                endpoint: config.apiBaseUrl 
                    + 'api/payments/prepaypaymenthistory?billaccount='
                    + encodeURIComponent(billAccount),
                method: 'GET',
                credentials: 'include',
                types: [
                    prepayHistoryConstants.GET_PREPAY_HISTORY_REQUEST,
                    prepayHistoryConstants.GET_PREPAY_HISTORY_SUCCESS,
                    prepayHistoryConstants.GET_PREPAY_HISTORY_FAILURE
                ]
            }
        })
    }
}

export const getPrepayPurchaseHistoryDetails = (billAccount) => {
    return dispatch => {
        return dispatch({
            [RSAA]: {
                endpoint: config.apiBaseUrl 
                    + 'api/payments/prepaypurchasehistorydetails?billaccount='
                    + encodeURIComponent(billAccount),
                method: 'GET',
                credentials: 'include',
                types: [
                    prepayHistoryConstants.GET_PREPAY_PURCHASE_HISTORY_DETAILS_REQUEST,
                    prepayHistoryConstants.GET_PREPAY_PURCHASE_HISTORY_DETAILS_SUCCESS,
                    prepayHistoryConstants.GET_PREPAY_PURCHASE_HISTORY_DETAILS_FAILURE
                ]
            }
        })
    }
}