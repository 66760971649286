import React from 'react'
import PropTypes from 'prop-types'

import format from '../../srp_modules/format'

import MenuItem from '@mui/material/MenuItem'
import TextField from '@mui/material/TextField'

const BankAccountSelectField = ({selectedBankAccountId, bankAccountList, bankAccountOnChange, t}) => (
    <div>
        <TextField select value={selectedBankAccountId} fullWidth={true} onChange={(event) => bankAccountOnChange(event.target.value)} label={t("Select a bank account")}>
            {bankAccountList.map((bank) =>
                <MenuItem key={bank.bankId} value={bank.bankId}>{format.bankAccount(bank)}</MenuItem>
            )}
        </TextField>
    </div>
)

BankAccountSelectField.propTypes = {
    selectedBankAccountId: PropTypes.number.isRequired,
    bankAccountList: PropTypes.arrayOf(PropTypes.shape({
        bankId: PropTypes.number.isRequired,
        bankName: PropTypes.string.isRequired,
        accountNumber: PropTypes.string.isRequired
    })).isRequired,
    bankAccountOnChange: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired
}

export default BankAccountSelectField