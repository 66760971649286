import { billPageConstants } from '../../../constants/bill-page-constants'

export const getNumOfBillsToDisplay = (numOfBills, lengthOfList) => ({
    type: billPageConstants.GET_NUM_BILLS_TO_DISPLAY,
    numOfBills, lengthOfList
})

export const getAccountHistoryType = (historyType) => ({
    type: billPageConstants.GET_ACCOUNT_HISTORY_TYPE,
    historyType
})

export const toggleDetailedTransactionList = () => ({
    type: billPageConstants.TOGGLE_DETAILED_TRANSACTION_LIST
})

export const getPrevAccountHistory = () => ({
    type: billPageConstants.GET_PREV_ACCT_HISTORY
})

export const getNextAccountHistory = (lengthOfList) => ({
    type: billPageConstants.GET_NEXT_ACCT_HISTORY,
    lengthOfList
})