import React from "react"
import { withTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import Card from "@mui/material/Card"
import NgpPayUsingBankAccountLink from './ngp-pay-using-bank-account-link'
import PayByCashCardLink from '../../cash_card_page/common_payment_page/pay-by-cash-card-link'

const NgpMakePaymentByCardPure = ({t}) => (
    <div>
        <label className="h5">{t("Make payment by")}</label>
        <Card className="p-4 d-none d-lg-block">
            <div className="payment-nav nav nav-justified">
                <div className="nav-item">
                    <NgpPayUsingBankAccountLink />
                </div>
                <div className="nav-item">
                    <PayByCashCardLink navPath="/myaccount/payment/multi/mpower/cash" />
                </div>
            </div>
        </Card>
        <Card className="d-block d-lg-none pt-4 pb-4 pl-2 pr-2">
            <div className="payment-nav">
                <div className="nav-item">
                    <NgpPayUsingBankAccountLink />
                </div>
                <div className="nav-item">
                    <PayByCashCardLink navPath="/myaccount/payment/multi/mpower/cash" />
                </div>
            </div>
        </Card>
    </div>
)

NgpMakePaymentByCardPure.propTypes = {
    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired
}

const NgpMakePaymentByCard = withTranslation("multiPurchasePage")(NgpMakePaymentByCardPure)
export { NgpMakePaymentByCard as default, NgpMakePaymentByCardPure }