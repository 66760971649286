import React from "react"
import PropTypes from 'prop-types'

import Card from "@mui/material/Card"

import PayUsingBankAccountDiv from './pay-using-bank-account-div'
import PayUsingBankAccountLink from './pay-using-bank-account-link'
import PayByCreditCardLinkConnectedContainer from './pay-by-credit-card-link-container'
import PayAsGuestDiv from './pay-as-guest-div'
import PayAsGuestLink from './pay-as-guest-link'

const MakePaymentByCard = ({parentPage, t, i18n}) => (
    <div>
        <label className="h5">{t("Make payment by")}</label>
        <Card className="p-4 d-none d-lg-block">
            <div className="payment-nav nav nav-pills nav-justified flex-nowrap">
                <div className="nav-item">
                    {parentPage==='PayUsingBankAccountLoginPage' ? <PayUsingBankAccountDiv t={t} /> : <PayUsingBankAccountLink t={t} />}
                </div>
                <div className="nav-item">
                    <PayByCreditCardLinkConnectedContainer t={t} i18n={i18n} />
                </div>
                <div className="nav-item">
                    {parentPage==='GuestPaymentPage' ? <PayAsGuestDiv t={t} /> : <PayAsGuestLink t={t} />}
                </div>
            </div>
        </Card>
        <Card className="d-block d-lg-none pt-4 pb-4 pl-2 pr-2">
            <div className="payment-nav">
                <div className="nav-item">
                    {parentPage==='PayUsingBankAccountLoginPage' ? <PayUsingBankAccountDiv t={t} /> : <PayUsingBankAccountLink t={t} />}
                </div>
                <div className="nav-item">
                    <PayByCreditCardLinkConnectedContainer t={t} i18n={i18n} />
                </div>
                <div className="nav-item">
                    {parentPage==='GuestPaymentPage' ? <PayAsGuestDiv t={t} /> : <PayAsGuestLink t={t} />}
                </div>
            </div>
        </Card>
    </div>
)

MakePaymentByCard.propTypes = {
    parentPage: PropTypes.string.isRequired,
    t:  PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired,
}

export default MakePaymentByCard