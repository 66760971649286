import React from 'react'
import PropTypes from 'prop-types'

import { Link } from 'react-router-dom'

import { NumericFormat } from 'react-number-format'

import { DateTime } from 'luxon'
import Divider from '@mui/material/Divider'

const MPowerReconnectSuccessCard = ({ mPowerAcctDetails, t }) => {
    const _lastReadDate = DateTime.fromISO(mPowerAcctDetails.lastEstimatedReadDate)
    const isMinDate = _lastReadDate.year===1 && _lastReadDate.month===1 && _lastReadDate.day===1
    const lastReadDate = isMinDate ? "- -" : _lastReadDate.toFormat("h:mm a, MMM d")
    const balanceRemaining = <NumericFormat value={mPowerAcctDetails.balanceRemaining} valueIsNumericString={true} prefix={'$'} thousandSeparator={true} fixedDecimalScale={true} decimalScale={2} displayType={'text'} />

    return (
        <div className="srp-card">
            <div className="srp-card-header">
                {t("My M-Power")}
            </div>

            <div className="srp-card-body">
                <div className={"srp-card-summary d-flex justify-content-around srp-gray"}>
                    <div className="d-flex flex-column align-items-center text-white">
                        <div className="h4">{t("Disconnected")}</div>
                        <span>{balanceRemaining} {t("meter_balance_as_of", {lastReadDate})}</span>
                    </div>
                </div>

                <div className="srp-card-details">
                    <div className="d-none d-lg-block mb-2">
                        <div className="d-flex flex-wrap justify-content-end">
                            <Link to="/myaccount/payment" className="btn srp-btn btn-lightblue mb-2" style={{ fontSize: '95%' }}>{t("Purchase power")}</Link>
                        </div>
                    </div>

                    <div style={{ fontSize: '14px' }}>
                        <div className="srp-alert-success">
                            <b>{t("YOU'RE ALL SET")}</b>&nbsp;&nbsp;&nbsp;
                            {t("Power may take several minutes to reconnect.")}
                        </div>
                    </div>
                </div>

                <div className="d-block d-lg-none">
                    <Divider />
                    <div className="srp-card-details">
                        <div className="d-flex flex-wrap justify-content-end">
                            <Link to="/myaccount/payment" className="btn srp-btn btn-lightblue my-1">{t("Purchase power")}</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

MPowerReconnectSuccessCard.propTypes = {
    mPowerAcctDetails: PropTypes.shape({
        billAccount: PropTypes.number.isRequired,
        balanceRemaining: PropTypes.number.isRequired,
        lastEstimatedReadDate: PropTypes.string.isRequired,
    }).isRequired,
    t: PropTypes.func.isRequired,
}

export default MPowerReconnectSuccessCard