import { DateTime } from 'luxon'

const outageRestoration = {
    getRestorationForCard,
    getRestorationForPage,
    isReportedOutageClosed
}

function getRestorationForCard(endTime) {
    let restorationDuration = "0h 15m"

    let now = DateTime.now().setZone("MST")
    let numberHours = Math.floor(DateTime.fromISO(endTime).diff(now, 'hours').hours)
    let numberMinutes = Math.round(DateTime.fromISO(endTime).diff(now, 'minutes').minutes - (numberHours * 60))
    let displayHours = numberHours + "h"
    let displayMinutes = numberMinutes + "m"

    if(DateTime.fromISO(endTime) < now || (numberHours <= 0 && numberMinutes <= 15 )) {
        return restorationDuration
    }

    restorationDuration = displayHours + " " + displayMinutes

    return restorationDuration
}

function getRestorationForPage(endTime, t) {
    const eta = DateTime.fromISO(endTime).setZone("MST").toFormat('h:mm a')
    let restorationDuration = t("15_minutes_and_eta", {eta})

    const now = DateTime.now().setZone("MST")
    const numberHours = Math.floor(DateTime.fromISO(endTime).diff(now, 'hours').hours)
    const numberMinutes = Math.round(DateTime.fromISO(endTime).diff(now, 'minutes').minutes - (numberHours * 60))

    if(DateTime.fromISO(endTime) < now || (numberHours <= 0 && numberMinutes <= 15 )) {
        return restorationDuration
    }

    const hour_or_hours = t(numberHours===1 ? "hour" : "hours")
    const minute_or_minutes = t(numberMinutes===1 ? "minute" : "minutes")
    restorationDuration = t("hours_minutes_and_eta", {numberHours, hour_or_hours, numberMinutes, minute_or_minutes, eta})

    return restorationDuration
}

function isReportedOutageClosed(reportOutage, outageHistory) {
    let isResolved = false
    if(outageHistory.length !== 0)
    {
        isResolved = DateTime.fromISO(reportOutage.dateReported) < DateTime.fromISO(outageHistory[0].outageEnd)
    }

    return isResolved
}

export default outageRestoration