import React from 'react'
import PropTypes from 'prop-types'
import TextField from '@mui/material/TextField'

const BankNicknameField = ({t, ...props}) => {
    let isError = props.touched['bankNickname']
        && Object.prototype.hasOwnProperty.call(props.errors, 'bankNickname')
    let errMsg = isError ? props.errors['bankNickname'] : ''
    let meta = {
        id: 'bankNickname',
        name: 'bankNickname',
        label: t('Nickname (optional)'),
        helperText: errMsg==="" ? "" : t(errMsg),
        error: isError
    }

    let textFieldProps = {
        value: !props.value ? '' : props.value,
        onChange: props.onChange,
        onBlur: props.onBlur,
        errors: props.errors,
        touched: props.touched,
        fullWidth: props.fullWidth
    }

    return (
        <TextField {...meta} {...textFieldProps} type="text" inputProps={{maxLength: 35}} />
    )
}

BankNicknameField.propTypes = {
    errors: PropTypes.shape({
        bankNickname: PropTypes.string
    }).isRequired,
    fullWidth: PropTypes.bool,
    onBlur: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    touched: PropTypes.shape({
        bankNickname: PropTypes.bool
    }).isRequired,
    value: PropTypes.string.isRequired
}

export default BankNicknameField