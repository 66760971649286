import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Trans } from 'react-i18next'
import { DateTime } from 'luxon'
import Dialog from '@mui/material/Dialog'
import Divider from '@mui/material/Divider'

import CircularProgress from '@mui/material/CircularProgress'

import utils from '../../../srp_modules/utils'
import alertsModule from '../../../srp_modules/alerts-module'

import GoPaperlessBanner from '../../common/go-paperless-banner'

const CardBill = ({ currentBillInfo, accountHistoryList, hasEBill, disableEbillToggle, ebillEnrollContacts,
    billingAlertEmailContacts, billingAlertPhoneContacts, billingAddress, getEbillEnrollmentChange,
    showEbillEnrollDialog, showEbillUnenrollDialog, closeEbillEnrollDialog, closeEbillUnenrollDialog,
    creditExtensionInfo, isBudgetBilling, isCashOnly, isCommercial, scheduledPaymentsByAcct, paymentInfo,
    startCIXFlowOnClick, showCIXbutton, isClosedAccount, setPaymentArrangementDetailsDisplay,
    hasPendingAutopayChange, hasPendingAutopayEnrollment, t, i18n}) => {

    const isSpanish = (i18n.language === "es")
    const csPhoneNumber = isSpanish ? utils.getCSSpanishPhoneNumber() : utils.getCSPhoneNumber(isCommercial)
    const formattedCSPhoneNumber = isSpanish ? utils.getFormattedCSSpanishPhoneNumber() : utils.getFormattedCSPhoneNumber(isCommercial)

    const luxonDateFormat = isSpanish ? 'D/m/yyyy' : 'M/d/yyyy'

    let billingCycleStartMonthYear = ""
    let billingCycleEndMonthDayYear = ""
    if (accountHistoryList.length > 0) {
        const displayStartDate = DateTime.fromISO(accountHistoryList[0].displayStartDate)
        const displayEndDate = DateTime.fromISO(accountHistoryList[0].displayEndDate)

        if (isSpanish) {
            const startMonth = utils.getSpanishMonthAbbreviation(displayStartDate.month)
            const endMonth = utils.getSpanishMonthAbbreviation(displayEndDate.month)

            // Don't use string interpolation, as month abbreviation may be interpreted as format tokens.
            billingCycleStartMonthYear = displayStartDate.toFormat('d ') + startMonth
            billingCycleEndMonthDayYear = displayEndDate.toFormat('d ') + endMonth + displayEndDate.toFormat(', yyyy')
        } else {
            billingCycleStartMonthYear = displayStartDate.toFormat('MMM d')
            billingCycleEndMonthDayYear = displayEndDate.toFormat('MMM d, yyyy')
        }
    }

    let cardVariation = getCardVariations(currentBillInfo, paymentInfo, isBudgetBilling, isClosedAccount, t)
    return (
        <div className="srp-card">
            <div className="srp-card-header">
                {t("My bill")}
            </div>
            <div className="srp-card-body">
                <div className={"srp-card-summary d-flex justify-content-around " + cardVariation.titleColor}>
                    <div className="d-flex flex-column align-items-center text-white">
                        <div className="h4">{formatDollarAmount(Math.abs(cardVariation.titleAmount))}</div>
                        {cardVariation.title}
                    </div>
                </div>

                <div className="srp-card-details">
                    <div className="d-none d-lg-block">
                        <div className="d-flex flex-wrap justify-content-end mb-2">
                            {creditExtensionInfo.isEligible && paymentInfo.amountDue > 0 && showCIXbutton
                                ? <button
                                    className="btn srp-btn btn-lightblue mb-2"
                                    style={{ paddingLeft: '10px', paddingRight: '10px' }}
                                    onClick={() => startCIXFlowOnClick()}>
                                    {t("Can't pay now?")}
                                </button>
                                : <span />
                            }
                            {paymentInfo.hasPaymentArrangement
                                ? <button
                                    className="btn srp-btn btn-lightblue mb-2"
                                    onClick={() => setPaymentArrangementDetailsDisplay(true)}>
                                    {t("Arrangement Details")}
                                </button>
                                : <span />
                            }
                            <Link
                                to="/myaccount/bill"
                                className="btn srp-btn btn-lightblue ml-2 mb-2">
                                {t("View bill")}
                            </Link>
                            {!isCashOnly
                                ? paymentInfo.amountDue <= 0
                                    || paymentInfo.isSurePay
                                    || scheduledPaymentsByAcct.length !== 0
                                    ? <Link
                                        to="/myaccount/payment"
                                        className="btn srp-btn btn-lightblue ml-2 mb-2">
                                        {t("Make payment")}
                                    </Link>
                                    : <Link
                                        to="/myaccount/payment"
                                        className="btn srp-btn btn-blue ml-2 mb-2">
                                        {t("Pay bill")}
                                    </Link>
                                : <span />
                            }
                        </div>
                    </div>
                    {hasPendingAutopayEnrollment
                        ? <div className="srp-alert-notice font-14 mb-3">
                             {t("SurePay enrollment pending")}
                        </div>
                        : <span />
                    }
                    {hasPendingAutopayChange && !hasPendingAutopayEnrollment
                        ? <div className="srp-alert-notice font-14 mb-3">
                             {t("Your recent SurePay changes are pending")}
                        </div>
                        : <span />
                    }
                    {creditExtensionInfo.hasExtension
                        ? <div className="srp-alert-notice font-14 mb-3">
                            {t("Your_due_date_has_been_extended_to", {dueDate: DateTime.fromISO(creditExtensionInfo.extensionDueDate).toFormat(luxonDateFormat)})}
                        </div>
                        : <span />
                    }
                    {paymentInfo.hasPaymentArrangement
                        ? <div className="srp-alert-notice font-14 mb-3">
                            {t("Your account has payment arrangements.")}
                        </div>
                        : <span />
                    }
                    {scheduledPaymentsByAcct.length !== 0
                        ? <div className="srp-alert-notice font-14 mb-3">
                            <div className="mb-2">
                                <Trans i18nKey="A_payment_of_is_scheduled_for" t={t}>
                                A payment of <strong>{{paymentAmount: formatDollarAmount(scheduledPaymentsByAcct[0].paymentAmount)}}</strong> is scheduled for <strong>{{paymentDate: DateTime.fromISO(scheduledPaymentsByAcct[0].paymentDate).toFormat(luxonDateFormat)}}</strong>
                                </Trans>
                            </div>
                            <Link to="/myaccount/payment">{t("View details")}</Link>
                        </div>
                        : <span />
                    }
                    {isCashOnly
                        ? <div className="mb-3">
                            <div className="d-lg-block d-none srp-alert-warn font-14">
                                {t("payments_cant_be_made_online_desktop", {phone: formattedCSPhoneNumber})}
                            </div>
                            <div className="d-block d-lg-none srp-alert-warn font-14">
                                <Trans i18nKey="payments_cant_be_made_online_mobile" t={t}>
                                We're sorry, payments can’t be made online for this bill account. Please call SRP Customer Service at <a href={"tel:" + csPhoneNumber}>{{phone: formattedCSPhoneNumber}}</a> if you have questions.
                                </Trans>
                            </div>
                        </div>
                        : <span />
                    }
                    {isBudgetBilling && cardVariation.titleColor === 'srp-red' && !isClosedAccount &&
                        <div className="srp-alert-notice font-14 mb-3">
                            {t("Budget billing may be cancelled if you are unable to pay the total amount due.")}
                        </div>
                    }
                    {currentBillInfo.accountBalanceAmount > 0
                        && currentBillInfo.accountBalanceAmount != currentBillInfo.billedAmount
                        && !isBudgetBilling
                        && !paymentInfo.hasPaymentArrangement
                        ? <div className="row d-flex align-items-center mb-3">
                            <div className="col text-muted align-middle">{t("Current balance")}</div>
                            <div className="col text-right">
                                {formatDollarAmount(currentBillInfo.accountBalanceAmount)}
                            </div>
                        </div>
                        : <span />
                    }
                    <div className="text-muted font-14 mb-2">
                        {t("For_billing_cycle", {startMonthYear: billingCycleStartMonthYear, endMonthDayYear: billingCycleEndMonthDayYear})}
                    </div>

                    {isBudgetBilling &&
                        (paymentInfo.isSurePay
                            ? <div>
                                    <div className="row d-flex align-items-center">
                                        <div className="col-7 text-muted align-middle">{t("Budget billing amount")}</div>
                                        <div className="col-5 text-right">
                                            {formatDollarAmount(isClosedAccount ? currentBillInfo.billedAmount : cardVariation.budgetBillingCurrentDue)}
                                        </div>
                                    </div>
                                    {paymentInfo.shareOptionAmount > 0.0 &&
                                        <div className="row d-flex align-items-center">
                                            <div className="col-7 text-muted align-middle">{t("SHARE pledge amount")}</div>
                                            <div className="col-5 text-right">
                                                {formatDollarAmount(paymentInfo.shareOptionAmount)}
                                            </div>
                                        </div>
                                    }
                                    <div className="row d-flex align-items-center">
                                        <div className="col-5 text-muted align-middle">{t("SurePay date")}</div>
                                        <div className="col-7 text-right srp-green-text">
                                            {paymentInfo.surePayDate === "0001-01-01T00:00:00"
                                                ? t("No current amount due")
                                                : DateTime.fromISO(paymentInfo.surePayDate).toFormat(luxonDateFormat)}
                                        </div>
                                    </div>
                                    <div className="row d-flex align-items-center mb-3">
                                        <div className="col-7 text-muted align-middle">{t("Balance after payment")}</div>
                                        <div className="col-5 text-right">
                                            {formatDollarAmount(isClosedAccount ? 0 : currentBillInfo.budgetBillBalanceAmount)}
                                        </div>
                                    </div>
                                </div>
                            : <div>
                                <div className="row d-flex align-items-center">
                                    <div className="col-7 text-muted align-middle">{t("Budget billing amount")}</div>
                                    <div className="col-5 text-right">
                                        {formatDollarAmount(isClosedAccount ? currentBillInfo.billedAmount : cardVariation.budgetBillingCurrentDue)}
                                    </div>
                                </div>
                                {paymentInfo.shareOptionAmount > 0.0 &&
                                    <div className="row d-flex align-items-center">
                                        <div className="col-7 text-muted align-middle">{t("SHARE pledge amount")}</div>
                                        <div className="col-5 text-right">
                                            {formatDollarAmount(paymentInfo.shareOptionAmount)}
                                        </div>
                                    </div>
                                }
                                {currentBillInfo.dueDate !== null
                                    ? <div className="row d-flex align-items-center">
                                        <div className="col-7 text-muted align-middle">{t("Due date")}</div>
                                        <div className="col-5 text-right srp-green-text">
                                            {DateTime.fromISO(currentBillInfo.dueDate).toFormat(luxonDateFormat)}
                                        </div>
                                    </div>
                                    : null
                                }
                                <div className="row d-flex align-items-center mb-3">
                                    <div className="col-7 text-muted align-middle">{t("Balance after payment")}</div>
                                    <div className="col-5 text-right">
                                        {formatDollarAmount(isClosedAccount ? 0 : currentBillInfo.budgetBillBalanceAmount)}
                                    </div>
                                </div>
                            </div>
                        )
                    }

                    {paymentInfo.hasPaymentArrangement
                        ? paymentInfo.isSurePay
                            ? <div>
                                <div className="row d-flex align-items-center">
                                    <div className="col-7 text-muted align-middle">{t("Amount billed")}</div>
                                    <div className="col-5 text-right">
                                        {formatDollarAmount(currentBillInfo.billedAmount)}
                                    </div>
                                </div>
                                {paymentInfo.shareOptionAmount > 0.0 &&
                                    <div className="row d-flex align-items-center">
                                        <div className="col-7 text-muted align-middle">{t("SHARE pledge amount")}</div>
                                        <div className="col-5 text-right">
                                            {formatDollarAmount(paymentInfo.shareOptionAmount)}
                                        </div>
                                    </div>
                                }
                                <div className="row d-flex align-items-center">
                                    <div className="col-5 text-muted align-middle">{t("SurePay date")}</div>
                                    <div className="col-7 text-right srp-green-text">
                                        {paymentInfo.surePayDate === "0001-01-01T00:00:00"
                                            ? t("No current amount due")
                                            : DateTime.fromISO(paymentInfo.surePayDate).toFormat(luxonDateFormat)}
                                    </div>
                                </div>
                                <div className="row d-flex align-items-center mb-3">
                                    <div className="col-7 text-muted align-middle">{t("Balance after payment")}</div>
                                    <div className="col-5 text-right">
                                        {formatDollarAmount(paymentInfo.arrangementRemainingAmountDue)}
                                    </div>
                                </div>
                            </div>
                            : <div>
                                <div className="row d-flex align-items-center">
                                    <div className="col-7 text-muted align-middle">{t("Amount billed")}</div>
                                    <div className="col-5 text-right">
                                        {formatDollarAmount(currentBillInfo.billedAmount)}
                                    </div>
                                </div>
                                {paymentInfo.shareOptionAmount > 0.0 &&
                                    <div className="row d-flex align-items-center">
                                        <div className="col-7 text-muted align-middle">{t("SHARE pledge amount")}</div>
                                        <div className="col-5 text-right">
                                            {formatDollarAmount(paymentInfo.shareOptionAmount)}
                                        </div>
                                    </div>
                                }
                                {currentBillInfo.dueDate !== null
                                    ? <div className="row d-flex align-items-center">
                                        <div className="col-7 text-muted align-middle">{t("Due date")}</div>
                                        <div className="col-5 text-right srp-green-text">
                                            {DateTime.fromISO(currentBillInfo.dueDate).toFormat(luxonDateFormat)}
                                        </div>
                                    </div>
                                    : null
                                }
                                <div className="row d-flex align-items-center mb-3">
                                    <div className="col-7 text-muted align-middle">{t("Balance after payment")}</div>
                                    <div className="col-5 text-right">
                                        {formatDollarAmount(paymentInfo.arrangementRemainingAmountDue)}
                                    </div>
                                </div>
                            </div>
                        : <span />
                    }

                    {paymentInfo.isSurePay && !isBudgetBilling && !paymentInfo.hasPaymentArrangement
                        ? <div>
                            <div className="row d-flex align-items-center">
                                <div className="col-7 text-muted align-middle">{t("Amount billed")}</div>
                                <div className="col-5 text-right">
                                    {currentBillInfo.billedAmount < 0
                                        ? "$0.00"
                                        : formatDollarAmount(currentBillInfo.billedAmount)
                                    }
                                </div>
                            </div>
                            {paymentInfo.shareOptionAmount > 0.0 &&
                                <div className="row d-flex align-items-center">
                                    <div className="col-7 text-muted align-middle">{t("SHARE pledge amount")}</div>
                                    <div className="col-5 text-right">
                                        {formatDollarAmount(paymentInfo.shareOptionAmount)}
                                    </div>
                                </div>
                            }
                            <div className="row d-flex align-items-center mb-3">
                                <div className="col-5 text-muted align-middle">{t("SurePay date")}</div>
                                <div className="col-7 text-right srp-green-text">
                                    {paymentInfo.surePayDate === "0001-01-01T00:00:00"
                                        ? t("No current amount due")
                                        : DateTime.fromISO(paymentInfo.surePayDate).toFormat(luxonDateFormat)}
                                </div>
                            </div>
                        </div>
                        : <span/>
                    }

                    {!isBudgetBilling && !paymentInfo.isSurePay && !paymentInfo.hasPaymentArrangement
                        ? <div>
                            <div className="row d-flex align-items-center">
                                <div className="col-7 text-muted align-middle">{t("Amount billed")}</div>
                                <div className="col-5 text-right">
                                    {formatDollarAmount(currentBillInfo.billedAmount)}
                                </div>
                            </div>
                            {paymentInfo.shareOptionAmount > 0.0 &&
                                <div className="row d-flex align-items-center">
                                    <div className="col-7 text-muted align-middle">{t("SHARE pledge amount")}</div>
                                    <div className="col-5 text-right">
                                        {formatDollarAmount(paymentInfo.shareOptionAmount)}
                                    </div>
                                </div>
                            }
                            {currentBillInfo.dueDate !== null
                                ? <div className="row d-flex align-items-center mb-3">
                                    <div className="col-7 text-muted align-middle">{t("Due date")}</div>
                                    <div className="col-5 text-right srp-green-text">
                                        {DateTime.fromISO(currentBillInfo.dueDate).toFormat(luxonDateFormat)}
                                    </div>
                                </div>
                                : null
                            }
                        </div>
                        : <span />
                    }

                    <span className="d-lg-block d-none p-2" />

                    {hasEBill ?
                    <div className="srp-alert-success px-3 pt-3 d-flex justify-content-between">
                        <h6 className="mb-0">
                            <Trans i18nKey="Paperless_billing_is_ON" t={t}>
                                Paperless billing is <b>ON</b>
                            </Trans>
                        </h6>
                        <button type="button" className="displayAsLink py-0" disabled={disableEbillToggle} onClick={() => getEbillEnrollmentChange(false, false)}>
                            {disableEbillToggle ? <CircularProgress size={20} thickness={5} aria-label="turning off paperless billing" alt="progress icon" /> : <h6><b>{t("Turn off")}</b></h6>}
                        </button>
                    </div>
                    :
                    <GoPaperlessBanner turnOnPaperlessBilling={() => getEbillEnrollmentChange(true, false)} isSubmitting={disableEbillToggle} disabled={disableEbillToggle} t={t} />
                    }
                </div>
                <div className="d-block d-lg-none">
                    <Divider />
                    <div className="srp-card-details">
                        <div className="d-flex flex-wrap justify-content-end">
                            {creditExtensionInfo.isEligible
                                && paymentInfo.amountDue > 0
                                && showCIXbutton
                                ? <button
                                    className="btn srp-btn btn-lightblue mb-2"
                                    style={{ paddingLeft: '10px', paddingRight: '10px' }}
                                    onClick={() => startCIXFlowOnClick()}>
                                    {t("Can't pay now?")}
                                </button>
                                : <span />
                            }
                            {paymentInfo.hasPaymentArrangement
                                ? <button
                                    className="btn srp-btn btn-lightblue mb-2"
                                    onClick={() => setPaymentArrangementDetailsDisplay(true)}>
                                    {t("Arrangement Details")}
                                </button>
                                : <span />
                            }
                            <Link
                                to="/myaccount/bill"
                                className="btn srp-btn btn-lightblue ml-2 mb-2">
                                {t("View bill")}
                            </Link>
                            {!isCashOnly
                                ? paymentInfo.amountDue <= 0
                                    || paymentInfo.isSurePay
                                    || scheduledPaymentsByAcct.length !== 0
                                    ? <Link
                                        to="/myaccount/payment"
                                        className="btn srp-btn btn-lightblue ml-2 mb-2">
                                        {t("Make payment")}
                                    </Link>
                                    : <Link
                                        to="/myaccount/payment"
                                        className="btn srp-btn btn-blue ml-2 mb-2">
                                        {t("Pay bill")}
                                    </Link>
                                : <span />
                            }
                        </div>
                    </div>
                </div>
            </div>

            <Dialog
                modal="true"
                open={showEbillEnrollDialog}
                PaperProps={{ className: "srp-modal" }}
            >
                <div className="srp-modal-body">
                    <button className="srp-modal-close srp-modal-close-btn" onClick={() => closeEbillEnrollDialog()} aria-label="close dialog" alt="" />
                    <div>
                        <div className="srp-modal-header">
                            <h4 className="srp-modal-title">{t("eBill paperless billing")}</h4>
                        </div>
                        <p>
                            {t("You_have_turned_on_paperless_billing", {contacts: ebillEnrollContacts})}
                        </p>
                        <div className="srp-modal-footer mb-0">
                            <Link
                                to="/myaccount/profile/alerts"
                                className="btn srp-btn btn-lightblue mr-2"
                                onClick={() => closeEbillEnrollDialog()}>
                                {t("Manage account")}
                            </Link>
                            <button
                                className="btn srp-btn btn-lightblue"
                                onClick={() => closeEbillEnrollDialog()}>
                                {t("Close")}
                            </button>
                        </div>
                    </div>
                </div>
            </Dialog>

            <Dialog
                modal="true"
                open={showEbillUnenrollDialog}
                PaperProps={{ className: "srp-modal" }}
            >
                <div className="srp-modal-body">
                    <button className="srp-modal-close srp-modal-close-btn" onClick={() => closeEbillUnenrollDialog()} aria-label="close dialog" alt="" />
                    <div>
                        <div className="srp-modal-header">
                            <h4 className="srp-modal-title">{t("eBill paperless billing")}</h4>
                        </div>
                        <div>
                            <div>
                                {t("You_have_turned_off_paperless_billing", {contacts: alertsModule.createEBillContactsString(billingAlertEmailContacts, billingAlertPhoneContacts)})}
                            </div>
                            <br />
                            <div style={{ marginLeft: '10px' }}>
                                {billingAddress}
                            </div>
                            <br />
                            <div>
                                {t("You_will_still_receive_billing_and_payment_eNotes")}
                            </div>
                        </div>
                        <div className="srp-modal-footer mb-0">
                            <Link
                                to="/myaccount/profile/alerts"
                                className="btn srp-btn btn-lightblue mr-2"
                                onClick={() => closeEbillUnenrollDialog()}>
                                {t("Manage account")}
                            </Link>
                            <button
                                className="btn srp-btn btn-lightblue"
                                onClick={() => closeEbillUnenrollDialog()}>
                                {t("Close")}
                            </button>
                        </div>
                    </div>
                </div>
            </Dialog>

        </div>
    )
}

CardBill.propTypes = {
    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired,
    currentBillInfo: PropTypes.object,
    accountHistoryList: PropTypes.arrayOf(PropTypes.object),
    hasEBill: PropTypes.bool,
    disableEbillToggle: PropTypes.bool,
    ebillEnrollContacts: PropTypes.string,
    billingAlertEmailContacts: PropTypes.arrayOf(PropTypes.object),
    billingAlertPhoneContacts: PropTypes.arrayOf(PropTypes.object),
    billingAddress: PropTypes.string,
    getEbillEnrollmentChange: PropTypes.func,
    showEbillEnrollDialog: PropTypes.bool,
    showEbillUnenrollDialog: PropTypes.bool,
    closeEbillEnrollDialog: PropTypes.func,
    closeEbillUnenrollDialog: PropTypes.func,
    creditExtensionInfo: PropTypes.object,
    hasPendingAutopayEnrollment: PropTypes.bool.isRequired,
    isBudgetBilling: PropTypes.bool,
    isCashOnly: PropTypes.bool,
    isCommercial: PropTypes.bool,
    scheduledPaymentsByAcct: PropTypes.arrayOf(PropTypes.object),
    paymentInfo: PropTypes.object,
    startCIXFlowOnClick: PropTypes.func,
    showCIXbutton: PropTypes.bool,
    isClosedAccount: PropTypes.bool,
    setPaymentArrangementDetailsDisplay: PropTypes.func,
    hasPendingAutopayChange: PropTypes.bool
}

export default CardBill

function getCardVariations(currentBill, payment, isBudgetBilling, isClosed, t) {
    let cardVariation = {
        titleColor: 'srp-blue',
        title: t("please pay"),
        titleAmount: 0,
        budgetBillingCurrentDue: currentBill.currentBudgetBillPaymentDue
    }

    if (isClosed) {
        if (currentBill.accountBalanceAmount < 0) {
            if (isBudgetBilling) {
                cardVariation.titleColor = 'srp-green'
                cardVariation.title = t("please pay")
                cardVariation.titleAmount = 0
                cardVariation.budgetBillingCurrentDue = 0
            }
            else {
                cardVariation.titleColor = 'srp-green'
                cardVariation.title = t("credit balance")
                cardVariation.titleAmount = currentBill.accountBalanceAmount + payment.shareOptionAmount
            }
        }
        else if (currentBill.accountBalanceAmount === 0) {
            cardVariation.titleColor = 'srp-green'
            cardVariation.title = t("no payment required")
            cardVariation.titleAmount = 0
        }
        else {
            if (currentBill.analyzeCode === " " || currentBill.analyzeCode === "1" || currentBill.analyzeCode === "L") {
                cardVariation.titleColor = 'srp-blue'
                cardVariation.title = t("please pay")
                cardVariation.titleAmount = currentBill.accountBalanceAmount + payment.shareOptionAmount
            }
            else {
                cardVariation.titleColor = 'srp-red'
                cardVariation.title = t("due immediately")
                cardVariation.titleAmount = currentBill.accountBalanceAmount + payment.shareOptionAmount
            }
        }
    }
    else if (isBudgetBilling) {
        if (currentBill.currentBudgetBillPaymentDue < 0) {
            cardVariation.titleColor = 'srp-green'
            cardVariation.title = t("please pay")
            cardVariation.titleAmount = 0
            cardVariation.budgetBillingCurrentDue = 0
        }
        else if (currentBill.currentBudgetBillPaymentDue === 0) {
            cardVariation.titleColor = 'srp-green'
            cardVariation.title = t("no payment required")
            cardVariation.titleAmount = 0
        }
        else {
            if (currentBill.analyzeCode === " "
                || currentBill.analyzeCode === "1"
                || currentBill.analyzeCode === "L") {
                cardVariation.titleColor = 'srp-blue'
                cardVariation.title = t("please pay")
                cardVariation.titleAmount = currentBill.currentBudgetBillPaymentDue + payment.shareOptionAmount
            }
            else {
                cardVariation.titleColor = 'srp-red'
                cardVariation.title = t("due immediately")
                cardVariation.titleAmount = currentBill.currentBudgetBillPaymentDue + payment.shareOptionAmount
            }
        }
    }
    else if (payment.hasPaymentArrangement) {
        if (payment.arrangementCurrentDueAmount < 0) {
            cardVariation.titleColor = 'srp-green'
            cardVariation.title = t("credit balance")
            cardVariation.titleAmount = payment.arrangementCurrentDueAmount
        }
        else if (payment.arrangementCurrentDueAmount === 0) {
            cardVariation.titleColor = 'srp-green'
            cardVariation.title = t("no payment required")
            cardVariation.titleAmount = 0
        }
        else {
            if (currentBill.analyzeCode === " "
                || currentBill.analyzeCode === "1"
                || currentBill.analyzeCode === "L") {
                cardVariation.titleColor = 'srp-blue'
                cardVariation.title = t("please pay")
                cardVariation.titleAmount = payment.arrangementCurrentDueAmount + payment.shareOptionAmount
            }
            else {
                if (payment.pastDue > 0) {
                    cardVariation.titleColor = 'srp-red'
                    cardVariation.title = t("due immediately")
                    cardVariation.titleAmount = payment.pastDue + payment.shareOptionAmount
                }
                else {
                    cardVariation.titleColor = 'srp-blue'
                    cardVariation.title = t("please pay")
                    cardVariation.titleAmount = payment.arrangementCurrentDueAmount + payment.shareOptionAmount
                }
            }
        }
    }
    else {
        if (payment.balance < 0) {
            cardVariation.titleColor = 'srp-green'
            cardVariation.title = t("credit balance")
            cardVariation.titleAmount = payment.balance
        }
        else if (payment.balance === 0) {
            cardVariation.titleColor = 'srp-green'
            cardVariation.title = t("no payment required")
            cardVariation.titleAmount = 0
        }
        else {
            if (currentBill.analyzeCode === " "
                || currentBill.analyzeCode === "1"
                || currentBill.analyzeCode === "L") {
                cardVariation.titleColor = 'srp-blue'
                cardVariation.title = t("please pay")
                cardVariation.titleAmount = payment.balance + payment.shareOptionAmount
            }
            else {
                if (payment.pastDue > 0) {
                    cardVariation.titleColor = 'srp-red'
                    cardVariation.title = t("due immediately")
                    cardVariation.titleAmount = payment.pastDue + payment.shareOptionAmount
                }
                else {
                    cardVariation.titleColor = 'srp-blue'
                    cardVariation.title = t("please pay")
                    cardVariation.titleAmount = payment.balance + payment.shareOptionAmount
                }
            }
        }
    }

    return cardVariation
}

export function formatDollarAmount(number) {
    let formattedDollarAmount
    if (number < 0) {
        let posNum = number * (-1)
        formattedDollarAmount = "-$" + ((posNum).toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    }
    else if (number === 0) {
        formattedDollarAmount = "$0.00"
    }
    else {
        formattedDollarAmount = "$" + ((number).toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    }

    return formattedDollarAmount
}