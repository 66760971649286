import * as React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import * as loadingStatus from '../../constants/loading-status-constants'
import drawHorizontalChart from '../../srp_modules/charting/daily-usage-horizontal'
import drawVerticalChart from '../../srp_modules/charting/daily-usage-vertical'
import { dailyUsageChartTypeConstants } from '../../constants/daily-usage-chart-type-constants'
import { DateTime } from 'luxon'
import { initHourlyUsagePage } from '../../actions/auth/usage/usage-actions'

let initializeChart = (dailyUsageList,
    weatherData,
    targetId,
    highDemandInfoList,
    currentChart,
    startDate,
    endDate,
    hasOnOffShoulder,
    barClickCallback,
    isMPower,
    t
) => {
    if (dailyUsageList.length > 0) {
        let usage = sliceUsageData(dailyUsageList, startDate, endDate)
        let weather = sliceWeatherData(weatherData, startDate, endDate)

        window.innerWidth < 1024
            ? drawHorizontalChart(usage, weather, highDemandInfoList, currentChart, hasOnOffShoulder, barClickCallback, isMPower, t)
            : drawVerticalChart(usage, weather, currentChart, highDemandInfoList, hasOnOffShoulder, barClickCallback, isMPower, t)
    }
}

class DailyUsageChartContainer extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            resizeListener: this.updateChart.bind(this)
        }
    }

    componentDidMount() {
        window.addEventListener("resize", this.state.resizeListener)
        if (this.props.dailyUsage.dailyUsageStatus === loadingStatus.LOADING_STATUS_SUCCESS
            && this.props.dailyWeather.dailyWeatherStatus === loadingStatus.LOADING_STATUS_SUCCESS)

            this.updateChart()
    }

    shouldComponentUpdate(nextProps) {
        return (this.props.dailyUsage.dailyUsageStatus !== nextProps.dailyUsage.dailyUsageStatus
            || this.props.rateMetaData.rateMetaDataStatus !== nextProps.rateMetaData.rateMetaDataStatus
            || this.props.dailyWeather.dailyWeatherStatus !== nextProps.dailyWeather.dailyWeatherStatus
            || this.props.dailyStartDate !== nextProps.dailyStartDate
            || this.props.dailyEndDate !== nextProps.dailyEndDate
            || this.props.currentDailyChart !== nextProps.currentDailyChart
            || this.props.t !== nextProps.t
        )
    }

    componentDidUpdate() {
        if (this.props.dailyUsage.dailyUsageStatus === loadingStatus.LOADING_STATUS_SUCCESS
            && this.props.dailyWeather.dailyWeatherStatus === loadingStatus.LOADING_STATUS_SUCCESS
            && this.props.rateMetaData.rateMetaDataStatus === loadingStatus.LOADING_STATUS_SUCCESS
        )
            this.updateChart()
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.state.resizeListener)
    }

    updateChart() {
        let usageData = [];
        switch (this.props.currentDailyChart) {
            case dailyUsageChartTypeConstants.DEMAND:
                usageData = this.props.dailyDemandList
                break
            case dailyUsageChartTypeConstants.USAGE:
                usageData = this.props.dailyUsageList
                break
            case dailyUsageChartTypeConstants.COST:
                usageData = this.props.dailyUsageList
                break
            case dailyUsageChartTypeConstants.CONSUMPTION:
                usageData = this.props.dailyConsumptionList
                break
            case dailyUsageChartTypeConstants.GENERATION:
                usageData = this.props.dailyGenerationList
                break
            case dailyUsageChartTypeConstants.EXPORT:
                usageData = this.props.dailyReceivedList
                break
        }

        initializeChart(
            usageData,
            this.props.dailyWeather.dailyWeatherDataList,
            "chart",
            this.props.highDemandInfoList,
            this.props.currentDailyChart,
            this.props.dailyStartDate,
            this.props.dailyEndDate,
            this.props.rateMetaData.rateMetaData.hasOnOffShoulder,
            this.props.initHourlyUsagePage,
            this.props.rateMetaData.rateMetaData.isMPower,
            this.props.t
        )
    }

    render() {

        return (
            <div>
                <div id="usagePageChartContainer">
                    {this.props.currentDailyChart === dailyUsageChartTypeConstants.DEMAND
                        && <div className="demand-info-card mb-2">
                            <span>{this.props.t("DARKER TONES REPRESENT PEAK DEMAND VALUES")}</span>
                        </div>}
                    <div id="dailyUsageLegendContainer" className="chart-legend-container">
                        <div id="superOffLegendItem" className="chart-legend-item-hidden">
                            <span>{this.props.t("Super off-peak")}</span>
                            <div className="usage-chart-legend-circle viz-SuperOffPeak" />
                        </div>
                        <div id="offPeakLegendItem" className="chart-legend-item-hidden">
                            <span>{this.props.t("Off-peak")}</span>
                            <div className="usage-chart-legend-circle viz-OffPeak" />
                        </div>
                        <div id="shoulderLegendItem" className="chart-legend-item-hidden">
                            <span>{this.props.t("Shoulder")}</span>
                            <div className="usage-chart-legend-circle viz-Shoulder" />
                        </div>
                        <div id="onPeakLegendItem" className="chart-legend-item-hidden">
                            <span>{this.props.t("On-peak")}</span>
                            <div className="usage-chart-legend-circle viz-OnPeak" />
                        </div>
                        <div id="totalLegendItem" className="chart-legend-item-hidden">
                            <span>{this.props.t("Total")}</span>
                            <div className="usage-chart-legend-circle viz-Total" />
                        </div>
                        <div id="totalLegendItemCost" className="chart-legend-item-hidden">
                            <span>{this.props.t("Cost")}</span>
                            <div className="usage-chart-legend-circle viz-Total" />
                        </div>
                        <div id="highTempLegendItem" className="usage-chart-legend-item-show">
                            <span>{this.props.t("High temperature")}</span>
                            <div className="usage-chart-legend-line viz-high-temp" />
                        </div>
                        <div id="lowTempLegendItem" className="usage-chart-legend-item-show">
                            <span>{this.props.t("Low temperature")}</span>
                            <div className="usage-chart-legend-line viz-low-temp" />
                        </div>
                    </div>
                    <div className=".viz-tooltip-arrow-main" />
                </div>
            </div>
        )
    }

}

DailyUsageChartContainer.propTypes = {
    dailyUsage: PropTypes.object,
    dailyUsageList: PropTypes.array,
    dailyDemandList: PropTypes.array,
    dailyConsumptionList: PropTypes.array,
    dailyGenerationList: PropTypes.array,
    highDemandInfoList: PropTypes.array,
    dailyReceivedList: PropTypes.array,
    selectedBillAccount: PropTypes.number,
    dailyWeather: PropTypes.object,
    getDailyUsageForAccount: PropTypes.func,
    getDailyWeather: PropTypes.func,
    selectedUsagePageChartTab: PropTypes.number,
    rateMetaData: PropTypes.object,
    getRateMetaDataForAccount: PropTypes.func,
    showDailyCostChart: PropTypes.bool,
    dailyStartDate: PropTypes.object,
    dailyEndDate: PropTypes.object,
    currentDailyChart: PropTypes.string,
    initHourlyUsagePage: PropTypes.func
}

const mapStateToProps = (state) => {
    return {
        dailyUsage: state.dailyUsage,
        dailyUsageList: state.dailyUsage.dailyUsageList,
        dailyDemandList: state.dailyUsage.dailyDemandList,
        dailyConsumptionList: state.dailyUsage.dailyConsumptionList,
        dailyGenerationList: state.dailyUsage.dailyGenerationList,
        highDemandInfoList: state.dailyUsage.highDemandInfoList,
        dailyReceivedList: state.dailyUsage.dailyReceivedList,
        selectedBillAccount: state.accountInfo.billAccount.selectedBillAccount,
        dailyWeather: state.weatherData,
        isCost: PropTypes.bool,
        selectedUsagePageChartTab: state.accountInfo.usagePageUI.selectedUsagePageChartTab,
        rateMetaData: state.rateMetaData,
        ...state.accountInfo.usagePageUI
    }
}

function sliceUsageData(data, startDate, endDate) {
    let startIndex = data.findIndex(getIndexOfUsageDate, startDate)
    let endIndex = data.findIndex(getIndexOfUsageDate, endDate)

    startIndex = startIndex === -1 ? 1 : startIndex
    endIndex = endIndex === -1 ? data.length - 1 : endIndex
    return (startIndex >= 0 && endIndex >= 0)
        ? data.slice(startIndex, endIndex + 1)
        : []
}

function sliceWeatherData(data, startDate, endDate) {
    let startIndex = data.findIndex(getIndexOfWeatherDate, startDate)
    let endIndex = data.findIndex(getIndexOfWeatherDate, endDate)

    return (startIndex >= 0 && endIndex >= 0)
        ? data.slice(startIndex, endIndex + 1)
        : []
}

function getIndexOfUsageDate(element) {
    let elementDate = DateTime.fromISO(element.date).setZone('MST').startOf('day')
    return elementDate.equals(this.startOf('day'))
}

function getIndexOfWeatherDate(element) {
    let elementDate = DateTime.fromISO(element.weatherDate).setZone('MST').startOf('day')
    return elementDate.equals(this.startOf('day'))
}

const mapDispatchToProps = (dispatch) => {
    return {
        initHourlyUsagePage: (date, chartType) => {
            dispatch(
                initHourlyUsagePage(date, chartType))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DailyUsageChartContainer)

