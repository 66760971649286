import { RSAA } from 'redux-api-middleware'

import { singlePurchaseBankInfoConstants } from '../../../constants/single-purchase/single-purchase-bank-info-constants'
import { singlePurchaseListConstants } from '../../../constants/single-purchase/single-purchase-list-constants'
import { singlePurchaseApiConstants } from '../../../constants/single-purchase/single-purchase-api-constants'

import utils from '../../../srp_modules/utils'

export const storeBankInfoCollected = ({bankFirstName, bankLastName, bankPhoneNumber, bankEmailAddress, bankRoutingNumber, bankAccountNumberLeadingZeroesAreSignificant, bankNickname}) => ({
    type: singlePurchaseBankInfoConstants.SINGLE_PURCHASE_STORE_BANK_INFO_COLLECTED,
    bankFirstName,
    bankLastName,
    bankPhoneNumber,
    bankEmailAddress,
    bankRoutingNumber,
    bankAccountNumberLeadingZeroesAreSignificant,
    bankNickname
})

export const clearBankInfo = () => ({
    type: singlePurchaseBankInfoConstants.SINGLE_PURCHASE_CLEAR_BANK_INFO
})

export const setBankFormOpen = () => ({
    type: singlePurchaseBankInfoConstants.SINGLE_PURCHASE_SET_BANK_FORM_OPEN
})

export const clearBankFormOpen = () => ({
    type: singlePurchaseBankInfoConstants.SINGLE_PURCHASE_CLEAR_BANK_FORM_OPEN
})

export const setBankIdForSinglePurchase = ({selectedBankId}) => ({
    type: singlePurchaseBankInfoConstants.SET_BANK_ID_FOR_SINGLE_PURCHASE,
    selectedBankId
})

export const addBankAccount = () => (dispatch, getState) => {
    const state = getState()
    const singlePurchaseBankInfo = state.singlePurchaseBankInfo

    let billAccountList = state.accountInfo.billAccount.billAccountList
    let allCommercial = billAccountList?.every(b => b.isCommercial) ?? false

    let bankAccountInfo = {
        firstNameOnBankAccount: singlePurchaseBankInfo.bankFirstName,
        lastNameOnBankAccount: singlePurchaseBankInfo.bankLastName,
        phoneNumber: singlePurchaseBankInfo.bankPhoneNumber,
        emailAddress: singlePurchaseBankInfo.bankEmailAddress,
        bankRoutingNumber: singlePurchaseBankInfo.bankRoutingNumber,
        bankAccountNumberLeadingZeroesAreSignificant: singlePurchaseBankInfo.bankAccountNumberLeadingZeroesAreSignificant,
        nicknameForBankAccount: singlePurchaseBankInfo.bankNickname,
        makeDefaultBankAccount: false, //apps set this property, but web does not. Always set to false for web.
        sendCommercialConfirmationEmail: allCommercial
    }

    return dispatch({
        [RSAA]: {
            endpoint: utils.createUrl('bankaccount/addAndEmailConfirmation'),
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(bankAccountInfo),
            headers: { 'Content-Type': 'application/json' },
            types: [
                singlePurchaseApiConstants.SINGLE_PURCHASE_ADD_BANK_ACCOUNT_REQUEST,
                singlePurchaseApiConstants.SINGLE_PURCHASE_ADD_BANK_ACCOUNT_SUCCESS,
                singlePurchaseApiConstants.SINGLE_PURCHASE_ADD_BANK_ACCOUNT_FAILURE
            ]
        },
        meta: {
            debounce: {
                leading: false,
                trailing: false
            }
        }
    })    
}

export const addPurchaseToList = ({srpAccountNumber, purchaseAmount, payAmountOwedToSRP}) => ({
    type: singlePurchaseListConstants.ADD_SINGLE_PURCHASE_TO_LIST,
    srpAccountNumber,
    purchaseAmount,
    payAmountOwedToSRP
})

export const clearPurchaseList = () => ({
    type: singlePurchaseListConstants.CLEAR_SINGLE_PURCHASE_LIST
})

export const setFormStartEventEmitted = () => ({
    type: singlePurchaseListConstants.SET_FORM_START_EVENT_EMITTED
})

export const clearFormStartEventEmitted = () => ({
    type: singlePurchaseListConstants.CLEAR_FORM_START_EVENT_EMITTED
})

export const verifyPurchaseList = () => (dispatch, getState) => {
    const state = getState()
    const singlePurchaseBankInfo = state.singlePurchaseBankInfo
    const singlePurchaseList = state.singlePurchaseList.purchaseList

    const bankAccountId = singlePurchaseBankInfo.selectedBankId
    const bankAccount = state.bankAccounts.bankAccountList.find(ba => ba.bankId === bankAccountId)
    const bankAccountIdToken = (bankAccount === undefined) ? "" : bankAccount.bankIdToken

    let parameters = {
        purchaseList: singlePurchaseList.map(p => ({billAccountNumber: +p.srpAccountNumber, purchaseAmount: p.purchaseAmount, payAmountOwedToSRP: p.payAmountOwedToSRP})),
        bankAccountIdToken: bankAccountIdToken
    }

    return dispatch({
        [RSAA]: {
            endpoint: utils.createUrl('payments/verifypurchaselist'),
            method: 'POST',
            body: JSON.stringify(parameters),
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include',
            types: [
                singlePurchaseApiConstants.VERIFY_SINGLE_PURCHASE_LIST_REQUEST,
                singlePurchaseApiConstants.VERIFY_SINGLE_PURCHASE_LIST_SUCCESS,
                singlePurchaseApiConstants.VERIFY_SINGLE_PURCHASE_LIST_FAILURE
            ]
        },
        meta: {
            debounce: {
                leading: false,
                trailing: false
            }
        }
    })    
}

export const submitPurchaseList = () => (dispatch, getState) => {
    const state = getState()

    const selectedBankId = state.singlePurchaseBankInfo.selectedBankId
    let selectedBank = state.bankAccounts.bankAccountList.find(ba => ba.bankId === selectedBankId)
    if (selectedBank === undefined) {
        selectedBank = {
            bankIdToken: '',
            firstName: '',
            lastName: '',
            email: '',
            accountNumber: ''
        }
    }

    let purchaseList = state.singlePurchaseList.purchaseList.map(p => ({
        billAccountNumber: +p.srpAccountNumber,
        purchaseAmount: p.purchaseAmount,
        payAmountOwedToSRP: p.payAmountOwedToSRP
    }))

    let billAccountList = state.accountInfo.billAccount.billAccountList
    let allCommercial = false
    if (purchaseList && billAccountList && purchaseList.length > 0) {
        allCommercial = purchaseList.every(p => billAccountList.find(b => b.account === p.billAccountNumber)?.isCommercial ?? false)
    }
    
    let sendToMeterAuthorized = "true" //always send true for now until echex can change on their end to remove from endpoint US #84438
    if (sendToMeterAuthorized === "") {
        // occurs only if user is applying whole purchase towards arrears and purchase amount <= arrears
        sendToMeterAuthorized = "false"
    }

    let parameters = {
        purchaseList: purchaseList,
        isReconnectAuthorized: sendToMeterAuthorized,
        bankAccountIdToken: selectedBank.bankIdToken,
        sendCommercialConfirmationEmail: allCommercial,
        bankSignerFirstNameForConfirmationEmail: selectedBank.firstName,
        bankSignerLastNameForConfirmationEmail: selectedBank.lastName,
        bankSignerEmailAddressForConfirmationEmail: selectedBank.email,
        bankAccountNumberTruncatedForConfirmationEmail: selectedBank.accountNumber,
        referenceNumberFromValidatePurchase: state.singlePurchaseApiResponse.referenceNumber
    }

    return dispatch({
        [RSAA]: {
            endpoint: utils.createUrl('payments/submitpurchaselist_emailconfirmation'),
            method: 'POST',
            body: JSON.stringify(parameters),
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include',
            types: [
                singlePurchaseApiConstants.SUBMIT_SINGLE_PURCHASE_LIST_REQUEST,
                singlePurchaseApiConstants.SUBMIT_SINGLE_PURCHASE_LIST_SUCCESS,
                singlePurchaseApiConstants.SUBMIT_SINGLE_PURCHASE_LIST_FAILURE
            ]
        }
    })    
}

export const submitPurchaseListFailure = () => ({
    type: singlePurchaseApiConstants.SUBMIT_SINGLE_PURCHASE_LIST_FAILURE
})

export const clearPurchaseSubmitted = () => ({
    type: singlePurchaseApiConstants.CLEAR_PURCHASE_SUBMITTED
})