import config from 'my-account-config'

const appPathWithTrailingSlash = config.appPath === '/' ? config.appPath : `${config.appPath}/`

export const printByIdInNewWindow = (docId, windowName) => {
    let doc = document.getElementById(docId)
    printContentInNewWindow(doc.outerHTML, windowName)
}

export const printContentInNewWindow = (content, windowName) => {
    localStorage.setItem('printContent', content)

    let redirect = window.location.origin + appPathWithTrailingSlash + 'print'
    window.open(redirect, windowName)
}