import * as React from 'react'
import * as d3 from 'd3'
import { DateTime } from 'luxon'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import NetCompliantChartData from '../../../srp_modules/charting/net-compliant-chart-data'
import { getDailyUsage } from '../../../actions/auth/usage/daily-usage-actions'
import drawVerticalBars from '../../../srp_modules/charting/draw-vertical-bars'
import * as loadingStatus from '../../../constants/loading-status-constants'
import { getDisplaySize } from '../../../srp_modules/display-size'
import { displaySizeConstants } from '../../../constants/display-size-constants'
import { initHourlyUsagePage } from '../../../actions/auth/usage/usage-actions'
import { withRouter } from '../../../srp_modules/with-router'
import { dailyUsageChartTypeConstants } from '../../../constants/daily-usage-chart-type-constants'
import { convertDailyDataForCharting } from '../../../srp_modules/charting/daily-data-dto'

const yOffset = 40
const standardChartKeys = ["superOffPeak", "offsetSuperOff", "offPeak", "offsetOff", "shoulder", "offsetShoulder", "onPeak", "offsetOn", "total", "offsetTotal", "min", "offsetMin", "max"]
const margin = {
    top: 20,
    right: 20,
    bottom: 10,
    left: 45
}

const en = d3.timeFormatLocale(
    {
        "decimal": ".",
        "thousands": ",",
        "grouping": [3],
        "currency": ["$", ""],
        "dateTime": "%a %b %e %X %Y",
        "date": "%m/%d/%Y",
        "time": "%H:%M:%S",
        "periods": ["AM", "PM"],
        "days": ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
        "shortDays": ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
        "months": ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
        "shortMonths": ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
    }
)
const es = d3.timeFormatLocale(
    {
        "decimal": ".",
        "thousands": ",",
        "grouping": [3],
        "currency": ["$", ""],
        "dateTime": "%x, %X",
        "date": "%d/%m/%Y",
        "time": "%-I:%M:%S %p",
        "periods": ["AM", "PM"],
        "days": ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"],
        "shortDays": ["Dom", "Lun", "Mar", "Mié", "Jue", "Vie", "Sáb"],
        "months": ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"],
        "shortMonths": ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"]
    }
)

class UsageChartDailyContainer extends React.Component {
    constructor(props) {
        super(props)
      this.state = {
          resizeListener: this.updateChart.bind(this)
      }
    }

    componentDidMount() {
        window.addEventListener("resize", this.state.resizeListener)

        if (this.props.dailyUsage.dailyUsageStatus === loadingStatus.LOADING_STATUS_INIT && this.props.selectedBillAccount > 0){
            this.props.getDailyUsageForAccount(this.props.selectedBillAccount)
        }

        // If component is mounting and we already have daily usage, we still need to redraw the chart
        if (this.props.dailyUsage.dailyUsageStatus === loadingStatus.LOADING_STATUS_SUCCESS)
            this.updateChart()
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.dailyUsage.dailyUsageStatus === loadingStatus.LOADING_STATUS_INIT && nextProps.selectedBillAccount > 0)
            {
               this.props.getDailyUsageForAccount(nextProps.selectedBillAccount)
            }
    }

    shouldComponentUpdate(nextProps) {
        return (this.props.dailyUsage.dailyUsageStatus !== nextProps.dailyUsage.dailyUsageStatus)
            || (this.props.t !== nextProps.t)       
    }

    componentDidUpdate() {
        if (this.props.dailyUsage.dailyUsageStatus === loadingStatus.LOADING_STATUS_SUCCESS)
            this.updateChart()
    }

    componentWillUnmount(){
        window.removeEventListener("resize", this.state.resizeListener)
    }

    updateChart() {
        initializeChart(
            this.props.dailyUsage.dailyUsageList,
            "dailyDashChart",
            (this.props.isCost || this.props.payment.payment.supportsRemotePrepay),
            this.props.initHourlyUsagePage,
            this.props.isNetBilled,
            this.props.isMPower,
            this.props.i18n,
            this.props.t
        )
    }

    render() {
        return (
        <div id="dailyDashChartContainer" >
          <div id="dailyDashChartLegendContainer" className="chart-legend-container">
            <div id="superOffLegendItem" className="chart-legend-item-hidden">
              <span>{this.props.t("Super off-peak")}</span>
              <div className="chart-legend-circle viz-SuperOffPeak" />
            </div>
            <div id="offPeakLegendItem" className="chart-legend-item-hidden">
              <span>{this.props.t("Off-peak")}</span>
              <div className="chart-legend-circle viz-OffPeak" />
            </div>
            <div id="shoulderLegendItem" className="chart-legend-item-hidden">
              <span>{this.props.t("Shoulder")}</span>
              <div className="chart-legend-circle viz-Shoulder" />
            </div>
            <div id="onPeakLegendItem" className="chart-legend-item-hidden">
              <span>{this.props.t("On-peak")}</span>
              <div className="chart-legend-circle viz-OnPeak" />
            </div>
            <div id="totalLegendItem" className="chart-legend-item-hidden">
              <span>{this.props.t("Total usage")}</span>
              <div className="chart-legend-circle viz-Total" />
            </div>
            <div id="totalCostLegendItem" className="chart-legend-item-hidden">
              <span>{this.props.t("Total cost")}</span>
              <div className="chart-legend-circle viz-Total" />
            </div>
          </div>
          <div className=".viz-tooltip-arrow-main" />
        </div>
        )
    }
}

UsageChartDailyContainer.propTypes = {
    dailyUsage: PropTypes.object,
    dailyUsageList: PropTypes.array,
    selectedBillAccount: PropTypes.number,
    getDailyUsageForAccount: PropTypes.func,
    isCost: PropTypes.bool,
    payment: PropTypes.object,
    initHourlyUsagePage: PropTypes.func,
    isNetBilled: PropTypes.bool,
    isMPower: PropTypes.bool,
    t: PropTypes.func.isRequired,
    i18n: PropTypes.object.isRequired
}

const mapStateToProps = (state) => {
    return {
        dailyUsage: state.dailyUsage,
        dailyUsageList: state.dailyUsage.dailyUsageList,
        selectedBillAccount: state.accountInfo.billAccount.selectedBillAccount,
        payment: state.accountInfo.payment,
        isNetBilled: state.rateMetaData.rateMetaData.isNetBilled,
        isMPower: state.rateMetaData.rateMetaData.isMPower
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getDailyUsageForAccount: (billAccount) => {
           dispatch(
            getDailyUsage(billAccount))
       },
       initHourlyUsagePage: (date, chartType) => {
           dispatch(
            initHourlyUsagePage(date, chartType))
            ownProps.router.navigate('/myaccount/usage')
       }
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UsageChartDailyContainer))

let initializeChart = (dailyUsageList, targetId, isCost, barClickCallback, isNetBilled, isMPower, i18n, t) => {
    if(dailyUsageList.length > 0){
         let usage =  dailyUsageList.slice(dailyUsageList.length -7, dailyUsageList.length)
         drawChart(usage, targetId, isCost, barClickCallback, isNetBilled, isMPower, i18n, t)
     }
 }

let drawChart = (data, targetId, isCost, barClickCallback, isNetBilled, isMPower, i18n, t) => {
    window.dash_usage_selected_date =  ""
    let dtoData = convertDailyDataForCharting(data, isCost ? dailyUsageChartTypeConstants.COST : dailyUsageChartTypeConstants.USAGE)
    let netCompliantChartData = new NetCompliantChartData(dtoData,isCost)
    let adjustedData = netCompliantChartData.adjustedData
    let maxY = netCompliantChartData.maxY
    let minY = netCompliantChartData.minY

    let displaySize = getDisplaySize(window.innerWidth)
    let chartSize = getChartSvgSize(displaySize)
    let chart = d3.select("#dailyDashChartContainer")
    d3.selectAll("#dailyDashChart").remove()

    let  svg = chart
        .insert('svg','div')
        .attr('width', chartSize.width)
        .attr('height', chartSize.height)
        .attr('id', 'dailyDashChart')
        .attr('class','viz-chart')
    let width = +svg.attr("width") - margin.left - margin.right
    let height = +svg.attr("height") - margin.top - margin.bottom
    let svgOffsetLeft = calculateOffsetLeft(svg);
    let g = svg.append("g").attr("transform", "translate(" + margin.left + "," + margin.top + ")")

    const barWidth = (width / data.length / 1.3)
    const xOffset = (width / data.length - barWidth) / 2

    let x = d3.scaleBand()
        .rangeRound([0, width])
        .align(0.1)

    let y = d3.scaleLinear()
        .rangeRound([height - yOffset, 0])

    let hasOnPeak = false,
    hasSuperOff = false,
    hasOffPeak = false,
    hasShoulder = false,
    hasTotal = false

    adjustedData.forEach(function (element) {
        element.Date = DateTime.fromISO(element.day)
        element.day = DateTime.fromISO(element.day).toFormat('yyyy-MM-dd')
        hasOnPeak = element.onPeak != 0 ? true : hasOnPeak
        hasOffPeak = element.offPeak != 0 ? true : hasOffPeak
        hasShoulder = element.shoulder != 0 ? true : hasShoulder
        hasSuperOff = element.superOffPeak != 0 ? true : hasSuperOff
        hasTotal = element.total != 0 ? true : hasTotal
    })

    x.domain(adjustedData.map(function (d) {
        return d.Date
    }))

    y.domain([minY, maxY])

    if (minY < 0) {
       addZeroLineForNetEnergy(g,y,width)
    }

    let tooltip = addTooltipToChart(chart)

    g.append("g").attr("id", "dailyChartMainGroupDashboard")
        .selectAll("g")
        .data(d3.stack().keys(standardChartKeys)(adjustedData))
        .enter().append("g")
        .attr("class", function (d, i) {
            return getCssClass(standardChartKeys[i]) + " viz-bar"
        })
        .selectAll("path")
        .data(function (d) {
            return d
        })
        .enter().append("g")
        .append("path")
        .on("mouseover", function (d) {
            handleMouseOver(d, this, tooltip, barWidth, xOffset, maxY, minY, svg, svgOffsetLeft, x, y, chartSize, i18n, t)
        })
        .on("mousedown", function (d) {
            if('ontouchstart' in document.documentElement !== true) {
                return true
            }
            handleMouseOver(d, this, tooltip, barWidth, xOffset, maxY, minY, svg, svgOffsetLeft, x, y, chartSize, i18n, t)
            if(window.dash_usage_selected_date !== undefined
                && window.dash_usage_selected_date !== null
                && window.dash_usage_selected_date !== "")
                {
                    if(window.dash_usage_selected_date.equals(d.data.Date)){
                        barClickCallback(d.data.Date,
                            isCost && !isMPower
                                ? dailyUsageChartTypeConstants.COST
                                : isNetBilled
                                    ? dailyUsageChartTypeConstants.NET_ENERGY
                                    : dailyUsageChartTypeConstants.USAGE)
                    }
                }
                d3.event.stopPropagation()
                window.dash_usage_selected_date = d.data.Date
        })
        .on("mouseout", function (d) {handleMouseOut(d, this, tooltip) })
        .on("click", function (d){
            if('ontouchstart' in document.documentElement) {
                return true
            }
            barClickCallback(d.data.Date,
                isCost && !isMPower
                    ? dailyUsageChartTypeConstants.COST
                    : isNetBilled
                        ? dailyUsageChartTypeConstants.NET_ENERGY
                        : dailyUsageChartTypeConstants.USAGE)})
        .attr("height", 0)
        .attr("d", function (d) {
            return drawVerticalBars(d,data,x,y,minY,maxY,xOffset,barWidth,"Date")
        })

    drawAxisTicks(data, g, height, yOffset, width, isCost, x, y, i18n)
    drawLegend(isCost, width, hasOnPeak, hasSuperOff, hasShoulder, hasOffPeak, hasTotal)
}


function addZeroLineForNetEnergy(g, y, width){
    g.append("line")
    .attr("y1", y(0))
    .attr("y2", y(0))
    .attr("x1", 20)
    .attr("x2", width - 20)
    .style("stroke-width", "0.01em")
    .style("stroke", "#1F1D1D")
}

function drawAxisTicks(data, g, height, yOffset, width, isCost, x, y, i18n) {
    
    g.append("g")
        .attr("class", "axis")
        .attr("class", "date-axis")
        .attr("transform", "translate(0," + (height - yOffset) + ")")
        .call(d3.axisBottom(x).tickValues(x.domain().filter(function (d, i) {
            return width == 150 ? !(i % 3) : !(i % 2)
        }))
            .tickFormat(i18n && i18n.language === 'es' ? es.format("%a") : en.format("%a"))
        )
        
    g.append("g")
        .attr("class", "axis")
        .attr("class", "date-axis")
        .attr("transform", "translate(0," + (height - yOffset + 14) + ")")
        .call(d3.axisBottom(x).tickValues(x.domain().filter(function (d, i) {
            return width == 150 ? !(i % 3) : !(i % 2)
        }))
            .tickFormat(d3.timeFormat("%-m/%-d/%-y")))

    if (isCost) {
        g.append("g")
            .attr("class", "axis")
            .call(d3.axisLeft(y).ticks(4, "s")
                .tickFormat(d3.format(["$", ""])))
            .append("text")
            .attr("x", 2)
            .attr("y", y(y.ticks().pop()) + 0.5)
            .attr("fill", "#000")
            .attr("font-weight", "bold")
            .attr("text-anchor", "start")
    }
    else {
        g.append("g")
            .attr("class", "axis")
            .call(d3.axisLeft(y).ticks(4, "s"))
            .append("text")
            .attr("x", 2)
            .attr("y", y(y.ticks().pop()) + 0.5)
            .attr("fill", "#000")
            .attr("font-weight", "bold")
            .attr("text-anchor", "start")
    }
}

function drawLegend(isCost,width, hasOnPeak, hasSuperOff, hasShoulder, hasOffPeak, hasTotal) {
    let legendContainer = d3.select('#dailyDashChartLegendContainer')
    legendContainer
        .style("left", margin.left + "px")
        .style("width", width + "px")
    if (hasSuperOff) {
        d3.select('#superOffLegendItem')
            .attr("class", "chart-legend-item-show")
    }
    if (hasOffPeak) {
        d3.select('#offPeakLegendItem')
            .attr('class', "chart-legend-item-show")
    }
    if (hasShoulder) {
        d3.select('#shoulderLegendItem')
            .attr("class", "chart-legend-item-show")
    }
    if (hasOnPeak) {
        d3.select('#onPeakLegendItem')
            .attr("class", "chart-legend-item-show")
    }
    if (hasTotal) {
        isCost ?
        d3.select('#totalCostLegendItem')
            .attr("class", "chart-legend-item-show")
            :
        d3.select('#totalLegendItem')
            .attr("class", "chart-legend-item-show")
    }
}

//Have to do this for IE 11 support, go back to offsetLeft when IE support dropped
function calculateOffsetLeft(svg) {
    let offsetLeft = svg.node().offsetLeft
    if (typeof offsetLeft != "undefined") {
        return offsetLeft
    }
    let svgElement = document.getElementById(svg.node().id)
    let svgParent = svgElement.parentNode
    let left = svgElement.getBoundingClientRect().left -
        svgParent.getBoundingClientRect().left

    return left;
}

function getCssClass(text) {
    if (text.includes("offset")) {
        return "viz-invisible"
    }
    return "viz-" + text
}

function handleMouseOver(d, element, tooltip, barWidth, xOffset, maxY, minY, svg, svgOffsetLeft, x, y, chartSize, i18n, t) {
    $(element).addClass("current-hover")
    createTooltip(d, tooltip, barWidth, xOffset, maxY, minY, svg, svgOffsetLeft, x, y, chartSize, i18n, t)
    return true
}

function createTooltip(d, tooltip, barWidth, xOffset, maxY, minY, svg, svgOffsetLeft, x, y, chartSize, i18n, t) {
    let barTop = maxY - d.data.max

    let xPosition = x(d.data.Date) + xOffset + margin.left +
        (barWidth - 20) / 2 + calculateOffsetLeft(svg)
        let yPosition = y(barTop)
    let relativeArrowPositionX =
        xPosition/chartSize.width * 98
    // let formatTime = d3.timeFormat("%a, %b %e")
    let formatTime = i18n && i18n.language === "es" ? es.format("%a, %b %e") : en.format("%a, %b %e")
    tooltip
        .style("left", xPosition + "px")
        .style("top", yPosition + "px")
        .style("display", "block")
    let tooltipInner = tooltip.select(".viz-tooltip-inner")
    tooltipInner.selectAll("*").remove()
    tooltipInner.append("div").text(formatTime(d.data.Date))
    tooltipInner.style("right", relativeArrowPositionX + '%')
    if (d.data.dailyCost != 0
        && d.data.offPeakCost == 0
        && d.data.onPeakCost == 0) {
        tooltipInner.append("div")
            .attr("class", "viz-tooltip-text-line")
            .text(t("Cost") + ": $" + d.data.dailyCost.toFixed(2))
    }
    if (d.data.offPeakCost != 0
        && d.data.offPeakCost != undefined) {
        tooltipInner.append("div")
            .attr("class", "viz-tooltip-text-line")
            .text(t("Off peak cost") + ": $" + d.data.offPeakCost.toFixed(2))
    }
    if (d.data.onPeakCost != 0
        && d.data.onPeakCost != undefined) {
        tooltipInner.append("div")
            .attr("class", "viz-tooltip-text-line")
            .text(t("On peak cost") +": $" + d.data.onPeakCost.toFixed(2))
    }
    if (d.data.superOffPeak != 0) {
        tooltipInner.append("div")
            .attr("class", "viz-tooltip-text-line")
            .text(d.data.tooltipSuperOffPeak)
    }
    if (d.data.offPeak != 0) {
        tooltipInner.append("div")
            .attr("class", "viz-tooltip-text-line")
            .text(d.data.tooltipOffPeak)
    }
    if (d.data.shoulder != 0) {
        tooltipInner.append("div")
            .attr("class", "viz-tooltip-text-line")
            .text(d.data.tooltipShoulder)
    }
    if (d.data.onPeak != 0) {
        tooltipInner.append("div")
            .attr("class", "viz-tooltip-text-line")
            .text(d.data.tooltipOnPeak)
    }
    if (d.data.totalKwh != 0) {
        tooltipInner.append("div")
            .attr("class", "viz-tooltip-text-line")
            .text(d.data.tooltipTotal)
    }
}

function handleMouseOut(d, element, tooltip) {
    $(element).removeClass("current-hover")
    tooltip.style("display", "none")
}

function getChartSvgSize(displaySize) {
    switch(displaySize){

        case displaySizeConstants.EXTRA_LARGE:
        return {
            height: 200,
            width: 300,
        }
        case displaySizeConstants.LARGE:
        return {
            height: 200,
            width: 240,
        }
        case displaySizeConstants.MEDIUM:
        return {
            height: 350,
            width: 650,
        }
        case displaySizeConstants.SMALL:
        return {
            height: 200,
            width :480,
        }
        case displaySizeConstants.EXTRA_SMALL:
        return {
            height: 200,
            width :400,
        }
        case displaySizeConstants.MOBILE_LARGE:
        return {
            height: 200,
            width :340,
        }
        case displaySizeConstants.MOBILE:
        return {
            height: 200,
            width :300,
        }
        case displaySizeConstants.MOBILE_SMALL:
        return {
            height: 200,
            width :260,
        }
    }
}

function addTooltipToChart(chart) {
    let initialTt = chart.select(".viz-tooltip-arrow-main")
    initialTt.remove()
    let tooltip = chart
        .append("div")
        .attr("class", "viz-tooltip-arrow-main")
    let tooltipArrow =
        tooltip
            .append("div")
            .attr("class", "viz-tooltip-arrow")

    tooltipArrow.append("div")
        .attr("class", "viz-tooltip-main")
        .append("div")
        .attr("class", "viz-tooltip-inner")
        .attr("id", "viz-tooltipText")
    return tooltip
}

