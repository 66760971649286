import React from 'react'
import PropTypes from 'prop-types'
import TextField from '@mui/material/TextField'
import { PatternFormat } from 'react-number-format'

const PhoneNumberInput = (props) => {
    let isError = props.touched[props.id]
        && Object.prototype.hasOwnProperty.call(props.errors, props.id)
    let errMsg = isError ? props.errors[props.id] : ''
    let meta = {
        id: props.id,
        name: props.id,
        helperText: errMsg==="" ? "" : props.t(errMsg),
        error: isError
    }

    return (
        <div>
            <PatternFormat
                {...meta}
                value={props.value}
                valueIsNumericString={true}
                fullWidth={true}
                onValueChange={props.onValueChange}
                onBlur={props.onBlur}
                format="(###) ###-####"
                type="tel"
                customInput={TextField}
                label={props.t(props.label)}
            />
        </div>
    )
}

PhoneNumberInput.propTypes = {
    t: PropTypes.func.isRequired,
    touched: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    onValueChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired
}

export default PhoneNumberInput
