export const budgetBillingConstants = {
    SET_ACTIVE_STEP: 'SET_ACTIVE_STEP',

    SET_ACCOUNT_DETAILS: 'SET_ACCOUNT_DETAILS',
    
    SET_SQUARE_FOOTAGE: 'SET_SQUARE_FOOTAGE',
    SET_ELECTRIC_TYPE: 'SET_ELECTRIC_TYPE',
    SELECT_POOL: 'SELECT_POOL',
    SELECT_EVAP_COOLER: 'SELECT_EVAP_COOLER',

    SUBMIT_BUDGET_BILLING_REQUEST: 'SUBMIT_BUDGET_BILLING_REQUEST',
    SUBMIT_BUDGET_BILLING_SUCCESS: 'SUBMIT_BUDGET_BILLING_SUCCESS',
    SUBMIT_BUDGET_BILLING_FAILURE: 'SUBMIT_BUDGET_BILLING_FAILURE',

    MINIMUM_ACCOUNT_HISTORY: 12,

    ELECTRIC_TYPE: {
        ALL_ELECTRIC: 'ALL_ELECTRIC',
        DUAL: 'DUAL',
        UNKNOWN: 'UNKNOWN'
    },

    BUDGET_BILLING_STEPS: [
        {
            step: 0,
            label: "Account details",
            title: "Tell us about yourself"
        },
        {
            step: 1,
            label: "Home information",
            title: "Account & home information"
        }
    ]
}