import React from 'react'
import PropTypes from 'prop-types'
import queryString from 'query-string'

import config from 'my-account-config'

import { myAccountConstants } from '../../constants/myaccount-constants'

import { connect } from 'react-redux'

import { withTranslation } from 'react-i18next'

import { getExpectedLanguageString } from '../../actions/auth/myaccount/myaccount-actions'

import * as loadingStatus from '../../constants/loading-status-constants'
import { economyPricePlanConstants } from '../../constants/economy-price-plan-constants'

import { getBillAccounts } from '../../actions/auth/bill_account/bill-account-actions'
import { getRateMetaData } from '../../actions/auth/usage/rate-meta-data-actions'
import { generateEppApplicationUrl } from '../../actions/auth/forms/economy-price-plan-actions'

import EppApplication from './epp-application'

function classifyEvent(event, isLoggedIn) {
    if (!event) event = ""

    switch (event) {
        case "":
            return isLoggedIn ? "OpeningEnvelope" : "SomethingWentWrong"

        case "cancel":
        case "decline":
            return "FinishLater"

        case "signing_complete":
            return "EnvelopeSigned"

        case "access_code_failed":
        case "exception":
        case "fax_pending":
        case "id_check_failed":
        case "session_timeout":
        case "ttl_expired":
        case "viewing_complete":
        default:
            return "SomethingWentWrong"
    }
}

class EppApplicationContainer extends React.Component {
    constructor(props) {
        super(props)

        const params = queryString.parse(location.search)
    
        const eppAppState = classifyEvent(params.event, this.props.isLoggedIn)

        this.repLoginNotAllowedCallback = this.repLoginNotAllowedCallback.bind(this)
        this.pendingReviewCallback = this.pendingReviewCallback.bind(this)
        this.cantGenerateSigningUrlCallback = this.cantGenerateSigningUrlCallback.bind(this)

        this.state = {
            eppAppState,
        }
    }

    componentDidMount() {
        if (!this.props.isLoggedIn || this.state.eppAppState!=="OpeningEnvelope")
            return;

        if (this.props.billAccountListStatus === loadingStatus.LOADING_STATUS_INIT)
            this.props.getBillAccounts()
    }

    componentDidUpdate(prevProps) {
        if (!this.props.isLoggedIn || this.state.eppAppState!=="OpeningEnvelope")
            return;

        if (prevProps.billAccountListStatus !== this.props.billAccountListStatus) {
            if (this.props.billAccountListStatus === loadingStatus.LOADING_STATUS_FAILURE)
                this.cantGenerateSigningUrlCallback()

            if (this.props.billAccountListStatus === loadingStatus.LOADING_STATUS_SUCCESS
                && this.props.billingLanguageStatus === loadingStatus.LOADING_STATUS_INIT)
                this.props.getBillingLanguage(this.props.billAccount)
        }
 
        if (prevProps.billingLanguageStatus !== this.props.billingLanguageStatus &&
            (this.props.billingLanguageStatus === loadingStatus.LOADING_STATUS_SUCCESS
            || this.props.billingLanguageStatus === loadingStatus.LOADING_STATUS_FAILURE)) {
            // If we can't get the billing language, go ahead and open the EPP application in English.
            let billingLanguage = (this.props.billingLanguageStatus === loadingStatus.LOADING_STATUS_SUCCESS
                ? this.props.billingLanguage : "en")
            billingLanguage = getExpectedLanguageString(billingLanguage)

            this.props.generateEppApplicationUrl(this.props.billAccount, billingLanguage, this.repLoginNotAllowedCallback, this.pendingReviewCallback, this.cantGenerateSigningUrlCallback)
        }
    }

    repLoginNotAllowedCallback() {
        this.setState({eppAppState: "RepLoginNotAllowed"})
    }

    pendingReviewCallback() {
        this.setState({eppAppState: "PendingReview"})
    }

    cantGenerateSigningUrlCallback() {
        this.setState({eppAppState: "SomethingWentWrong"})
    }

    render() {
        let envelopeType = ""
        if (!config.isProduction) {
            const newEnvelope = sessionStorage.getItem("newEnvelope") ?? ""
            if (newEnvelope === "true")
                envelopeType = "NEW"
            else if (newEnvelope === "false")
                envelopeType = "EXISTING"
        }

        return (
            <EppApplication eppAppState={this.state.eppAppState} envelopeAdjective={envelopeType} t={this.props.t} />
        )
    }
}

const mapStateToProps = state => {
    return {
        isLoggedIn: state.login.isLoggedIn,
        billAccount: state.accountInfo.billAccount.selectedBillAccount,
        billAccountListStatus: state.accountInfo.billAccount.billAccountListStatus,
        billingLanguage: state.myAccount.billingLanguage,
        billingLanguageStatus: state.rateMetaData.rateMetaDataStatus,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        generateEppApplicationUrl: (billAccount, newEnvelopeLanguage, repLoginNotAllowedCallback, pendingReviewCallback, cantGenerateSigningUrlCallback) => {
            // Language toggle is not turned on site-wide yet. For
            // now, explicitly set the UI language to the billing
            // language to match the EPP envelope's language.
            // Once Spanish is turned on, we don't need to do this
            // because the envelope language can be set to the UI
            // language, not billing language. Remove dispatch()
            // call once language toggle is enabled.
            dispatch({
                type: myAccountConstants.SAVE_PREFERRED_LANGUAGE,
                preferredLanguage: newEnvelopeLanguage
            })

            dispatch(generateEppApplicationUrl(billAccount, newEnvelopeLanguage, document.location.href)).then(res => {
                if (res.type === economyPricePlanConstants.GENERATE_EPP_APP_URL_SUCCESS && res.payload.eppAppUrl !== "") {
                    if (!config.isProduction) {
                        sessionStorage.setItem("newEnvelope", res.payload.newEnvelope)
                    }
                    document.location.replace(res.payload.eppAppUrl)
                }
                else if (res.type === economyPricePlanConstants.GENERATE_EPP_APP_URL_SUCCESS && res.payload.repLoginNotAllowed === true)
                    repLoginNotAllowedCallback()
                else if (res.type === economyPricePlanConstants.GENERATE_EPP_APP_URL_SUCCESS && res.payload.pendingReview === true)
                    pendingReviewCallback()
                else // res.type === economyPricePlanConstants.GENERATE_EPP_APP_URL_FAILURE or empty eppAppUrl
                    cantGenerateSigningUrlCallback()
            })
        },
        getBillAccounts: () => {
            dispatch(getBillAccounts())
        },
        getBillingLanguage: (billAccount) => {
            dispatch(getRateMetaData(billAccount))
        },
    }
}

EppApplicationContainer.propTypes = {
    isLoggedIn: PropTypes.bool.isRequired,

    generateEppApplicationUrl: PropTypes.func.isRequired,

    billAccount: PropTypes.number,
    getBillAccounts: PropTypes.func.isRequired,
    billAccountListStatus: PropTypes.string.isRequired,

    billingLanguage: PropTypes.string,
    getBillingLanguage: PropTypes.func.isRequired,
    billingLanguageStatus: PropTypes.string.isRequired,

    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        changeLanguage: PropTypes.func.isRequired,
    }).isRequired
}

export default withTranslation('economyPricePlanSignupForm')(connect(mapStateToProps, mapDispatchToProps)(EppApplicationContainer))