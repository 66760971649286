import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { DateTime } from 'luxon'

import { withTranslation } from 'react-i18next'

import ErapApprovedPaymentsTable from './erap-approved-payments-table'

import { refreshErapForm } from '../../actions/auth/forms/era-program-actions'
import { eraProgramConstants } from '../../constants/era-program-constants'
import eraProgramMapper from '../../srp_modules/forms/era-program-mapper'

class ErapStatusCard extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {
        const t = this.props.t
        const spanish = (this.props.i18n.language === "es")

        return (
            <div>
                <div className="srp-card-header">{t("ADES Emergency Rental Assistance Utility Eligibility")}</div>
                <div className="srp-card-body">
                    <div className="srp-card-details">
                        {this.props.eraProgramStatusDetails.status === eraProgramConstants.STATUS.NEW_ELIGIBLE
                            || this.props.eraProgramStatusDetails.status === eraProgramConstants.STATUS.SELF_DECLARED_INELIGIBLE
                            || this.props.eraProgramStatusDetails.status === eraProgramConstants.STATUS.RECERTIFY
                            ? <div className="srp-alert-warn mb-2">
                                {t("funding_has_been_exhausted")}
                            </div>
                            : null}
                        {this.props.eraProgramStatusDetails.status === eraProgramConstants.STATUS.APPLIED_UNDER_REVIEW
                            || this.props.eraProgramStatusDetails.status === eraProgramConstants.STATUS.DENIED
                            ? <div className="d-inline-block border rounded mb-2">
                                <div className="row ml-0 mr-0">
                                    <div className="p-3">
                                        <div className="text-muted">
                                            {this.props.eraProgramStatusDetails.isRecertify
                                                ? t("Recertification Status")
                                                : t("Current Status")
                                            }
                                        </div>
                                        <div className="font-weight-bold">
                                            {eraProgramMapper.mapEraProgramStatusText(this.props.eraProgramStatusDetails.status, t)}
                                        </div>
                                    </div>
                                    <div className="p-3">
                                        <div className="text-muted">
                                            {this.props.eraProgramStatusDetails.status === eraProgramConstants.STATUS.APPLIED_UNDER_REVIEW
                                                ? t("Submitted Date")
                                                : t("Status Date")
                                            }
                                        </div>
                                        <div className="font-weight-bold">{DateTime.fromISO(this.props.eraProgramStatusDetails.statusDate).toFormat('M/d/yyyy')}</div>
                                    </div>
                                </div>
                            </div>
                            : null
                        }
                        {this.props.erapPayments.length > 0
                            ? <ErapApprovedPaymentsTable erapPayments={this.props.erapPayments} t={t} />
                            : null
                        }
                        {this.props.eraProgramStatusDetails.status === eraProgramConstants.STATUS.APPLIED_UNDER_REVIEW
                            ? <div className="text-muted mt-2">
                                <div className="mb-2">
                                    {t("take_up_to_45_days")}
                                </div>
                                <div>
                                    {t("until_eligibility_is_determined")}
                                </div>
                                <ul>
                                    <li>
                                        {t("mpower_app_option")}
                                    </li>
                                </ul>
                            </div>
                            : null
                        }
                        {this.props.eraProgramStatusDetails.status === eraProgramConstants.STATUS.DENIED
                            ? <div className="text-muted mt-3">
                                {t("not_eligible_for_erap")}
                                <ul>
                                    <li>
                                        {spanish ? this.props.eraProgramStatusDetails.statusDetailsEs : this.props.eraProgramStatusDetails.statusDetails}
                                    </li>
                                </ul>
                            </div>
                            : null
                        }
                        {this.props.eraProgramStatusDetails.status === eraProgramConstants.STATUS.APPROVED
                            || this.props.eraProgramStatusDetails.status === eraProgramConstants.STATUS.APPLIED_UNDER_REVIEW
                            ? <div className="text-muted mt-4">
                                {t("erap_disclaimer")}
                            </div>
                            : null
                        }
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        eraProgramStatusDetails: state.accountInfo.payment.enrolledProgramsDetails.eraProgram,
        erapPayments: state.accountInfo.payment.enrolledProgramsDetails.eraProgram.azDesPaymentList,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        refreshErapFormOnClick: () => {
            dispatch(refreshErapForm())
        }
    }
}

ErapStatusCard.propTypes = {
    eraProgramStatusDetails: PropTypes.object,
    erapPayments: PropTypes.array,

    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired,
}

export default withTranslation('erap')(connect(mapStateToProps, mapDispatchToProps)(ErapStatusCard))