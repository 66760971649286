import { convertDailyExportDataForCharting } from './daily-export-data-dto'
import { convertHourlyDataForCharting } from './hourly-data-dto'
import { convertMonthlyDataForCharting } from './monthly-data-dto'
import { dailyUsageChartTypeConstants } from '../../constants/daily-usage-chart-type-constants'
import formatUsageModule from '../../srp_modules/charting/format'
import { DateTime } from 'luxon'

const exportUsageModule = {
    getUsageDataWithDateRange,
    getUsageDataWithDateRangeForMonthly,
    getUsageTotals,
    getColumnsToShow,
    getUsageChartExport,
    buildCSVForHourly,
    buildCSVForDaily,
    buildCSVForMonthly,
    downloadCSV,
    mapWeatherData,
    getTotalLabel
}

function getUsageDataWithDateRange(data, startDate, endDate) {
    let startIndex = 0
    let endIndex = 0
    for (let i = 0; i < data.length; i++) {
        if (DateTime.fromISO(data[i].date).equals(DateTime.fromISO(startDate)))
            startIndex = i

        if (DateTime.fromISO(data[i].date).equals(DateTime.fromISO(endDate)))
            endIndex = i
    }

    let dataWithDateRange = data.slice(startIndex, endIndex + 1)
    return dataWithDateRange
}

function getUsageDataWithDateRangeForMonthly(data, startDate, endDate) {
    let monthlyUsageData = []
    let addDataToArray = false

    let i = data.length - 1
    for (i; i >= 0; i--) {
        if (DateTime.fromISO(data[i].billEndDate).equals(DateTime.fromISO(startDate)))
            addDataToArray = true

        if (addDataToArray)
            monthlyUsageData.push(data[i])

        if (DateTime.fromISO(data[i].billEndDate).equals(DateTime.fromISO(endDate)))
            addDataToArray = false
    }

    return monthlyUsageData
}

function getUsageTotals(usageData, columnsForTotal) {
    let totalSuperOffPeak = 0
    let totalOffPeak = 0
    let totalShoulder = 0
    let totalOnPeak = 0
    let totalUsage = 0

    usageData.forEach(function (data) {
        totalSuperOffPeak = columnsForTotal.superOffPeakCol ? totalSuperOffPeak + data.superOffPeak : 0
        totalOffPeak = columnsForTotal.offPeakCol ? totalOffPeak + data.offPeak : 0
        totalShoulder = columnsForTotal.shoulderCol ? totalShoulder + data.shoulder : 0
        totalOnPeak = columnsForTotal.onPeakCol ? totalOnPeak + data.onPeak : 0
        totalUsage = columnsForTotal.totalCol ? totalUsage + data.total : 0
    })

    let totals = {
        superOffPeak: totalSuperOffPeak,
        offPeak: totalOffPeak,
        shoulder: totalShoulder,
        onPeak: totalOnPeak,
        usageTotal: totalUsage
    }

    return totals
}

function getColumnsToShow(data) {
    let superOffPeakCol = false
    let offPeakCol = false
    let shoulderCol = false
    let onPeakCol = false
    let totalCol = false

    data.forEach(function (e) {
        if (e.superOffPeak !== 0)
            superOffPeakCol = true

        if (e.offPeak !== 0)
            offPeakCol = true

        if (e.shoulder !== 0)
            shoulderCol = true

        if (e.onPeak !== 0)
            onPeakCol = true

        if (e.total !== 0)
            totalCol = true
    })

    let columns = {
        superOffPeakCol,
        offPeakCol,
        shoulderCol,
        onPeakCol,
        totalCol
    }

    return columns
}

function getUsageChartExport(data, dataType, chartType, weatherData = []) {
    let dtoData = []
    let chartTab = "daily"
    switch (chartType) {
        case 0:
            dtoData = convertHourlyDataForCharting(data, dataType)
            chartTab = "hourly"
            break
        case 1:
            dtoData = convertDailyExportDataForCharting(data, dataType)
            chartTab = "daily"
            break
        case 2:
            chartTab = "monthly"
            dtoData = convertMonthlyDataForCharting(data, dataType)
            break
    }

    let unitOfMeasurement = "kWh"
    let filename = "Usage"
    switch (dataType) {
        case dailyUsageChartTypeConstants.USAGE: {
            filename = "Usage"
            break
        }
        case dailyUsageChartTypeConstants.GENERATION: {
            filename = "Generation"
            break
        }
        case dailyUsageChartTypeConstants.COST: {
            unitOfMeasurement = "cost"
            filename = "Cost"
            break
        }
        case dailyUsageChartTypeConstants.NET_ENERGY: {
            filename = "NetEnergy"
            break
        }
        case dailyUsageChartTypeConstants.DEMAND: {
            unitOfMeasurement = "kW"
            filename = "Demand"
            break
        }
        case dailyUsageChartTypeConstants.CONSUMPTION: {
            filename = "Consumption"
            break
        }
    }

    let startDate = chartType === 2 ? DateTime.fromISO(dtoData[0].date).toFormat('MMMyyyy') : DateTime.fromISO(dtoData[0].date).toFormat('M/d/yyyy')
    let endDate = chartType === 2 ? DateTime.fromISO(dtoData[dtoData.length - 1].date).toFormat('MMMyyyy') : DateTime.fromISO(dtoData[dtoData.length - 1].date).toFormat('M/d/yyyy')
    let dateForFilename = startDate === endDate
        ? startDate
        : `${startDate}_to_${endDate}`
    filename = chartTab + filename + dateForFilename

    let csv = ''
    let mappedWeather = []
    switch (chartType) {
        case 0:
            csv = buildCSVForHourly(dtoData, unitOfMeasurement, dataType)
            break
        case 1:
            mappedWeather = mapWeatherData(dtoData, weatherData)
            csv = buildCSVForDaily(dtoData, unitOfMeasurement, mappedWeather, dataType)
            break
        case 2:
            csv = buildCSVForMonthly(dtoData, unitOfMeasurement, dataType)
            break
    }

    downloadCSV(csv, filename)
}

function buildCSVForHourly(data, unitOfMeasurement, dataType) {
    let normalizedUsageData = []
    let columns = getColumnsToShow(data)

    data.forEach(function (e) {
        let usageArray = []

        usageArray.push(DateTime.fromISO(e.date).toFormat('M/d/yyyy'))
        usageArray.push(DateTime.fromISO(e.day).toFormat('h:m a'))

        if (columns.superOffPeakCol) {
            usageArray.push(`"${formatUsageModule.formatNumberByUsageType(dataType, e.superOffPeak)}"`)
        }
        if (columns.offPeakCol) {
            usageArray.push(`"${formatUsageModule.formatNumberByUsageType(dataType, e.offPeak)}"`)
        }
        if (columns.shoulderCol) {
            usageArray.push(`"${formatUsageModule.formatNumberByUsageType(dataType, e.shoulder)}"`)
        }
        if (columns.onPeakCol) {
            usageArray.push(`"${formatUsageModule.formatNumberByUsageType(dataType, e.onPeak)}"`)
        }
        if (columns.totalCol) {
            usageArray.push(`"${formatUsageModule.formatNumberByUsageType(dataType, e.total)}"`)
        }

        normalizedUsageData.push(usageArray)
    })

    let columnHeader = ["Usage date", "Interval"]
    if (columns.superOffPeakCol) {
        columnHeader.push(`Super Off-peak ${unitOfMeasurement}`)
    }
    if (columns.offPeakCol) {
        columnHeader.push(`Off-peak ${unitOfMeasurement}`)
    }
    if (columns.shoulderCol) {
        columnHeader.push(`Shoulder ${unitOfMeasurement}`)
    }
    if (columns.onPeakCol) {
        columnHeader.push(`On-peak ${unitOfMeasurement}`)
    }
    if (columns.totalCol) {
        columnHeader.push(`Total ${unitOfMeasurement}`)
    }

    let headerArray = [columnHeader]
    let csvArray = headerArray.concat(normalizedUsageData)

    let columnDelimiter = ","
    let lineDelimiter = "\r\n"

    let csvResult = ""
    csvArray.forEach(function (row) {
        csvResult += row.join(columnDelimiter)
        csvResult += lineDelimiter
    })

    return csvResult
}

function buildCSVForDaily(data, unitOfMeasurement, weatherData, dataType) {
    let normalizedUsageData = []
    let columns = getColumnsToShow(data)
    let totalLabel = getTotalLabel(dataType)

    data.forEach(function (e, index) {
        let usageArray = []

        usageArray.push(DateTime.fromISO(e.meterReadDate).toFormat('M/d/yyyy'))
        usageArray.push(DateTime.fromISO(e.day).toFormat('M/d/yyyy'))

        if (columns.superOffPeakCol) {
            usageArray.push(`"${formatUsageModule.formatNumberByUsageType(dataType, e.superOffPeak)}"`)
        }
        if (columns.offPeakCol) {
            usageArray.push(`"${formatUsageModule.formatNumberByUsageType(dataType, e.offPeak)}"`)
        }
        if (columns.shoulderCol) {
            usageArray.push(`"${formatUsageModule.formatNumberByUsageType(dataType, e.shoulder)}"`)
        }
        if (columns.onPeakCol) {
            usageArray.push(`"${formatUsageModule.formatNumberByUsageType(dataType, e.onPeak)}"`)
        }
        if (columns.totalCol) {
            usageArray.push(`"${formatUsageModule.formatNumberByUsageType(dataType, e.total)}"`)
        }

        if (weatherData[index]) {
            usageArray.push(Math.round(weatherData[index].high))
            usageArray.push(Math.round(weatherData[index].low))
        }
        else {
            usageArray.push("n/a")
            usageArray.push("n/a")
        }


        normalizedUsageData.push(usageArray)
    })

    let columnHeader = ["Meter read date", "Usage date"]
    if (columns.superOffPeakCol) {
        columnHeader.push(`Super Off-peak ${unitOfMeasurement}`)
    }
    if (columns.offPeakCol) {
        columnHeader.push(`Off-peak ${unitOfMeasurement}`)
    }
    if (columns.shoulderCol) {
        columnHeader.push(`Shoulder ${unitOfMeasurement}`)
    }
    if (columns.onPeakCol) {
        columnHeader.push(`On-peak ${unitOfMeasurement}`)
    }
    if (columns.totalCol) {
        columnHeader.push(`Total ${unitOfMeasurement}`)
    }

    columnHeader.push('High temperature (F)')
    columnHeader.push('Low temperature (F)')

    let columnFooter = []
    if(dataType !== dailyUsageChartTypeConstants.DEMAND) {
        let totals = getUsageTotals(data, columns)
        columnFooter = [`Combined total ${totalLabel}`, ""]
        if (columns.superOffPeakCol) {
            columnFooter.push(`"${formatUsageModule.formatNumberByUsageType(dataType, totals.superOffPeak)}"`)
        }
        if (columns.offPeakCol) {
            columnFooter.push(`"${formatUsageModule.formatNumberByUsageType(dataType, totals.offPeak)}"`)
        }
        if (columns.shoulderCol) {
            columnFooter.push(`"${formatUsageModule.formatNumberByUsageType(dataType, totals.shoulder)}"`)
        }
        if (columns.onPeakCol) {
            columnFooter.push(`"${formatUsageModule.formatNumberByUsageType(dataType, totals.onPeak)}"`)
        }
        if (columns.totalCol) {
            columnFooter.push(`"${formatUsageModule.formatNumberByUsageType(dataType, totals.usageTotal)}"`)
        }
    }

    let headerArray = [columnHeader]
    let footerArray = [columnFooter]
    let body = headerArray.concat(normalizedUsageData)
    let csvArray = body.concat(footerArray)

    let columnDelimiter = ','
    let lineDelimiter = "\r\n"

    let csvResult = ''
    csvArray.forEach(function (row) {
        csvResult += row.join(columnDelimiter)
        csvResult += lineDelimiter
    })

    return csvResult
}

function buildCSVForMonthly(data, unitOfMeasurement, dataType) {
    let normalizedUsageData = []
    let columns = getColumnsToShow(data)
    let totalLabel = getTotalLabel(dataType)

    data.forEach(function (e) {
        let usageArray = []

        usageArray.push(DateTime.fromISO(e.billStartDate).toFormat('M/d/yyyy'))
        usageArray.push(DateTime.fromISO(e.billEndDate).toFormat('M/d/yyyy'))

        if (columns.superOffPeakCol) {
            usageArray.push(`"${formatUsageModule.formatNumberByUsageType(dataType, e.superOffPeak)}"`)
        }
        if (columns.offPeakCol) {
            usageArray.push(`"${formatUsageModule.formatNumberByUsageType(dataType, e.offPeak)}"`)
        }
        if (columns.shoulderCol) {
            usageArray.push(`"${formatUsageModule.formatNumberByUsageType(dataType, e.shoulder)}"`)
        }
        if (columns.onPeakCol) {
            usageArray.push(`"${formatUsageModule.formatNumberByUsageType(dataType, e.onPeak)}"`)
        }
        if (columns.totalCol) {
            usageArray.push(`"${formatUsageModule.formatNumberByUsageType(dataType, e.total)}"`)
        }

        normalizedUsageData.push(usageArray)
    })

    let columnHeader = ["Bill start date", "Bill end date"]
    if (columns.superOffPeakCol) {
        columnHeader.push(`Super Off-peak ${unitOfMeasurement}`)
    }
    if (columns.offPeakCol) {
        columnHeader.push(`Off-peak ${unitOfMeasurement}`)
    }
    if (columns.shoulderCol) {
        columnHeader.push(`Shoulder ${unitOfMeasurement}`)
    }
    if (columns.onPeakCol) {
        columnHeader.push(`On-peak ${unitOfMeasurement}`)
    }
    if (columns.totalCol) {
        columnHeader.push(`Total ${unitOfMeasurement}`)
    }

    let columnFooter = []
    if(dataType !== dailyUsageChartTypeConstants.DEMAND) {
        let totals = getUsageTotals(data, columns)
        columnFooter = [`Combined total ${totalLabel}`, ""]
        if (columns.superOffPeakCol) {
            columnFooter.push(`"${formatUsageModule.formatNumberByUsageType(dataType, totals.superOffPeak)}"`)
        }
        if (columns.offPeakCol) {
            columnFooter.push(`"${formatUsageModule.formatNumberByUsageType(dataType, totals.offPeak)}"`)
        }
        if (columns.shoulderCol) {
            columnFooter.push(`"${formatUsageModule.formatNumberByUsageType(dataType, totals.shoulder)}"`)
        }
        if (columns.onPeakCol) {
            columnFooter.push(`"${formatUsageModule.formatNumberByUsageType(dataType, totals.onPeak)}"`)
        }
        if (columns.totalCol) {
            columnFooter.push(`"${formatUsageModule.formatNumberByUsageType(dataType, totals.usageTotal)}"`)
        }
    }

    let headerArray = [columnHeader]
    let footerArray = [columnFooter]
    let body = headerArray.concat(normalizedUsageData)
    let csvArray = body.concat(footerArray)

    let columnDelimiter = ','
    let lineDelimiter = "\r\n"

    let csvResult = ''
    csvArray.forEach(function (row) {
        csvResult += row.join(columnDelimiter)
        csvResult += lineDelimiter
    })

    return csvResult

}

function downloadCSV(csv, usageType) {
    let filename = `${usageType}.csv`
    let downloadLink
    let dataType = 'text/csv;charset=utf-8'

    downloadLink = document.createElement("a")

    document.body.appendChild(downloadLink)

    let msCsv = csv
    let attachment = new Blob(['\ufeff', msCsv], {
        type: dataType
    })
    if (navigator.msSaveOrOpenBlob) {
        navigator.msSaveOrOpenBlob(attachment, filename)
    }
    else {
        downloadLink.href = URL.createObjectURL(attachment)
        downloadLink.download = filename
        downloadLink.click()
    }
}

function mapWeatherData(usage, weather) {
    let mappedWeatherData =
        (usage && usage.length > 0)
            ? sliceWeatherData(weather, usage[0].date, usage[usage.length - 1].date)
            : []
    return mappedWeatherData
}

function sliceWeatherData(data, startDate, endDate) {
    let startIndex = data.findIndex(getIndexOfWeatherDate, startDate)
    let endIndex = data.findIndex(getIndexOfWeatherDate, endDate)
    endIndex = startIndex > -1 && endIndex === -1
        ? data.length - 1
        : endIndex
    return (startIndex >= 0 && endIndex >= 0)
        ? data.slice(startIndex, endIndex + 1)
        : []
}

function getIndexOfWeatherDate(element) {
    let elementDate = DateTime.fromISO(element.weatherDate)
    return elementDate.hasSame(DateTime.fromISO(this), "day")
}

function getTotalLabel(dataType){
    let totalLabel = "usage"
    switch (dataType) {
        case dailyUsageChartTypeConstants.USAGE: {
            totalLabel = "usage"
            break
        }
        case dailyUsageChartTypeConstants.GENERATION: {
            totalLabel = "generation"
            break
        }
        case dailyUsageChartTypeConstants.COST: {
            totalLabel = "cost"
            break
        }
        case dailyUsageChartTypeConstants.NET_ENERGY: {
            totalLabel = "net energy"
            break
        }
        case dailyUsageChartTypeConstants.CONSUMPTION: {
            totalLabel = "usage"
            break
        }
        default: {
            totalLabel = "usage"
            break
        }
    }

    return totalLabel
}

export default exportUsageModule