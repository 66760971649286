import React from "react"
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import Dialog from '@mui/material/Dialog'
import Icon from '@mui/material/Icon'
class MakeCreditCardPaymentLink extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isMpowerPurchaseAckModalOpen: false,
            displaySafetyStatement: false
        }

        this.mpowerPurchaseAckModalOnClick = this.mpowerPurchaseAckModalOnClick.bind(this)
        this.toggleSafetyStatement = this.toggleSafetyStatement.bind(this)
    }

    mpowerPurchaseAckModalOnClick(isOpen) {
        this.setState({
            isMpowerPurchaseAckModalOpen: isOpen
        })
    }

    toggleSafetyStatement(display) {
        this.setState({
            displaySafetyStatement: display
        })
    }

    render() {
        const disableLink = (this.props.creditCardPaymentUrl === '' || this.props.creditCardPaymentUrl === "#")
        const target = disableLink ? "_self" : "_blank"

        return (
            <div>
                {this.props.hasMpower
                    ? <button
                        onClick={() => this.mpowerPurchaseAckModalOnClick(true)}
                        disabled={disableLink}
                        className="displayAsLink">
                        {this.props.t("Make a credit/debit card payment (additional fee)")}
                    </button>
                    : <a href={this.props.creditCardPaymentUrl} target={target} disabled={disableLink}>{this.props.t("Make a credit/debit card payment (additional fee)")}</a>
                }
                <Dialog
                    modal="true"
                    open={this.state.isMpowerPurchaseAckModalOpen}
                    PaperProps={{ className: "srp-modal" }}
                >
                    <div className="srp-modal-body" id="purchaseAckModal">
                        <div className="srp-modal-close" onClick={() => this.mpowerPurchaseAckModalOnClick(false)} />
                        <div>
                            <div className="srp-modal-header">
                                <h4 className="srp-modal-title">{this.props.t("Proceed with Credit/Debit Purchase?")}</h4>
                            </div>
                            <div>
                                <div>
                                    <div>
                                        {this.props.t("mpower_warning")}
                                        <button
                                            className="displayAsLink font-weight-bold"
                                            onClick={() => this.toggleSafetyStatement(!this.state.displaySafetyStatement)}>
                                            <span className="d-flex align-items-center">
                                                {this.props.t("safety statement.")}
                                                <Icon>
                                                    {this.state.displaySafetyStatement ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
                                                </Icon>
                                            </span>
                                        </button>
                                    </div>
                                    {this.state.displaySafetyStatement
                                        ? <div className="mt-2">
                                            {this.props.t("safety_statement_text")}
                                        </div>
                                        : null
                                    }
                                </div>
                            </div>
                            <div className="srp-modal-footer mb-0 pt-3">
                                <button className="btn srp-btn btn-lightblue mr-2" onClick={() => this.mpowerPurchaseAckModalOnClick(false)}>{this.props.t("Cancel")}</button>
                                <a
                                    href={this.props.creditCardPaymentUrl}
                                    onClick={() => this.mpowerPurchaseAckModalOnClick(false)}
                                    className="btn srp-btn btn-blue"
                                    target={target}>
                                    OK
                                </a>
                            </div>
                        </div>
                    </div>
                </Dialog>
            </div>
        )
    }
}

MakeCreditCardPaymentLink.propTypes = {
    creditCardPaymentUrl: PropTypes.string,
    hasMpower: PropTypes.bool,
    multiPayment: PropTypes.bool,

    i18n: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired
}

export default withTranslation("makeACreditCardPaymentLink")(MakeCreditCardPaymentLink)
