import React from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import { withRouter } from '../../../srp_modules/with-router'

import SinglePurchaseDetailsTable from './single-purchase-details-table'

class SinglePurchaseDetailsTableContainer extends React.Component {
    constructor(props) {
        super(props)

        if (this.isStateEmpty())
            this.props.router.navigate('/myaccount/payment/single/mpower', { replace: true })
    }

    isStateEmpty() { return this.props.bankName === '' && this.props.truncatedBankAccountNumber === '' && this.props.purchaseList.length === 0 }

    render() {
        return (
            !this.isStateEmpty() &&
            <SinglePurchaseDetailsTable
                bankName={this.props.bankName} truncatedBankAccountNumber={this.props.truncatedBankAccountNumber}
                purchaseList={this.props.purchaseList} referenceNumber={this.props.referenceNumber}
                yesSendToMeter={this.props.yesSendToMeterSelected}
                amountOwedToSRP={this.props.amountOwedToSRP}
                payDownFraction={this.props.payDownFraction}
            />
        )
    }
}

const mapStateToProps = state => {
    const selectedBankId = state.singlePurchaseBankInfo.selectedBankId
    const selectedBank = state.bankAccounts.bankAccountList.find(ba => ba.bankId === selectedBankId)

    const amountOwedToSRP = state.accountInfo.payment.payment.amountDue

    return {
        bankName: selectedBank ? (selectedBank.nickName || selectedBank.bankName) : '',
        truncatedBankAccountNumber: selectedBank ? selectedBank.accountNumber : '',
        purchaseList: state.singlePurchaseList.purchaseList,
        yesSendToMeterSelected: state.singlePurchaseList.yesSendToMeterSelected === "true",
        amountOwedToSRP,
        payDownFraction: state.accountInfo.payment.payment.payDownPercent,
    }
}

SinglePurchaseDetailsTableContainer.propTypes = {
    bankName: PropTypes.string.isRequired,
    truncatedBankAccountNumber: PropTypes.string.isRequired,
    purchaseList: PropTypes.arrayOf(PropTypes.shape({
        srpAccountNumber: PropTypes.string.isRequired,
        purchaseAmount: PropTypes.number.isRequired,
        payAmountOwedToSRP: PropTypes.bool.isRequired
    })),
    yesSendToMeterSelected: PropTypes.bool.isRequired,
    referenceNumber: PropTypes.number.isRequired,
    amountOwedToSRP: PropTypes.number.isRequired,
    payDownFraction: PropTypes.number.isRequired,
    router: PropTypes.shape({
        navigate: PropTypes.func
    }).isRequired,
}

export default withRouter(connect(mapStateToProps)(SinglePurchaseDetailsTableContainer))