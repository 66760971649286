import React from 'react'
import PropTypes from 'prop-types'
import { Formik } from 'formik'
import { withTranslation } from 'react-i18next'

import { Link } from 'react-router-dom'
import TextField from '@mui/material/TextField'
import CircularProgress from '@mui/material/CircularProgress'
import Divider from '@mui/material/Divider'

import AnalyticsFormTracking from '../../common_formik/analytics-form-tracking'

import EconomyPricePlanSignupConfirmation from './economy-price-plan-signup-confirmation'
import EconomyPricePlanEligibility from './economy-price-plan-eligibility'

import * as loadingStatus from '../../../constants/loading-status-constants'

import validate from '../../../srp_modules/validate'


const EconomyPricePlanFormikPure = ({ customerName, formattedBillAccount, serviceAddress, formattedPhoneNumber, emailAddress,
    formType, initialChangedState, onStateChanged, submitEPPForm, submitEPPFormStatus, t, canApply, eppIncomeThresholds }) => {
    return (
        <Formik
            initialValues={{
                formType: formType,
                emailAddress: emailAddress,
                signature: '',
                eligibilityChecked: false,
                passesEligibilityCheck: false,
                peopleInHousehold: 1,
                householdIncome: null,
            }}
            validate={(values) => validateForm(values, t)}
            onSubmit={(values, actions) => {
                submitEPPForm(values, actions)
            }}>
            {({ values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue }) => {
                let signatureError = touched.signature && errors.invalidSignature !== undefined
                let emailError = touched.emailAddress && errors.email !== undefined
                let invalidEmailError = touched.emailAddress && errors.invalidEmail !== undefined

                return (
                    <div>
                        <AnalyticsFormTracking
                            name="Economy Price Plan Sign-Up"
                            formStep="sign up for economy price plan"
                            onStateChanged={onStateChanged}
                            initialChangedState={initialChangedState} />

                        {submitEPPFormStatus !== loadingStatus.LOADING_STATUS_SUCCESS
                            ? <React.Fragment>
                                <EconomyPricePlanEligibility
                                    formikValues={values}
                                    handleChange={handleChange}
                                    setFieldValue={setFieldValue}
                                    hasCheckedEligibility={values.eligibilityChecked}
                                    hideEligibilityButton={!canApply || values.eligibilityChecked}
                                    canApply={canApply}
                                    eppIncomeThresholds={eppIncomeThresholds}
                                />

                                {values.passesEligibilityCheck === true
                                    ? <React.Fragment>
                                        <Divider />

                                        <form
                                            className="mb-2 mt-2 text-muted"
                                            onSubmit={handleSubmit}
                                        >
                                            <div className="srp-card-details">
                                                {submitEPPFormStatus === loadingStatus.LOADING_STATUS_FAILURE
                                                    ? <div className="srp-alert-error mb-4">{t("Something_Went_Wrong")}</div>
                                                    : null
                                                }
                                                <div className="srp-alert-success mb-3">
                                                    {t("You_Are_Eligible")}
                                                </div>
                                                <h4 className="mb-3" style={{ color: 'rgba(51, 51, 51, 0.537)' }}>{t("Account_Details")}</h4>

                                                <div className="d-lg-block d-none mb-3">
                                                    <div>
                                                        <span className="font-weight-bold mr-2">{t("Name")}</span>
                                                        <span>{customerName}</span>
                                                    </div>
                                                    <div>
                                                        <span className="font-weight-bold mr-2">{t("SRP_Account_Number")}</span>
                                                        <span>{formattedBillAccount}</span>
                                                    </div>
                                                    <div>
                                                        <span className="font-weight-bold mr-2">{t("Service_Address")}</span>
                                                        <span>{serviceAddress.fullStreetAddress}</span>
                                                    </div>
                                                    <div>
                                                        <span className="font-weight-bold mr-2">{t("Phone_Number")}</span>
                                                        <span>{formattedPhoneNumber}</span>
                                                    </div>
                                                </div>
                                                <div className="d-lg-none d-block mb-3">
                                                    <div className="row">
                                                        <div className="col-12 font-weight-bold">{t("Name")}</div>
                                                        <div className="col-12">{customerName}</div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-12 font-weight-bold">{t("SRP_Account_Number")}</div>
                                                        <div className="col-12">{formattedBillAccount}</div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-12 font-weight-bold">{t("Service_Address")}</div>
                                                        <div className="col-12">{serviceAddress.streetAddress}</div>
                                                        <div className="col-12">{serviceAddress.cityStateZip}</div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-12 font-weight-bold">{t("Phone_Number")}</div>
                                                        <div className="col-12">{formattedPhoneNumber}</div>
                                                    </div>
                                                </div>
                                                <div className="mb-4">
                                                    {emailAddress.length > 0
                                                        ? <>
                                                            <span>{t("Confirmation_Sent_To")}</span>
                                                            <span className="font-weight-bold"> {emailAddress}</span>
                                                          </>
                                                        : <div>
                                                            <TextField
                                                                fullWidth
                                                                name="emailAddress"
                                                                value={values.emailAddress}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                helperText={
                                                                    emailError
                                                                        ? t(errors.email)
                                                                        : invalidEmailError
                                                                            ? t(errors.invalidEmail)
                                                                            : ''}
                                                                error={emailError || invalidEmailError}
                                                                label={t("Confirmation email address")} />
                                                        </div>
                                                    }
                                                </div>
                                                <div>
                                                    <h4 className="mb-3" style={{ color: 'rgba(51, 51, 51, 0.537)' }}>{t("My_Signature_Indicates")}</h4>
                                                    <p>
                                                        {t("The_Information_I_Have_Provided")}
                                                    </p>
                                                    <div className="col-11 col-md-9 mx-0 px-0 mb-3">
                                                        <TextField
                                                            name="signature"
                                                            inputProps={{ maxLength: 250 }}
                                                            value={values.signature}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            fullWidth
                                                            helperText={
                                                                signatureError ?
                                                                    t(errors.invalidSignature) :
                                                                    ''
                                                            }
                                                            error={signatureError}
                                                            label={t("Digital_Signature")}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="d-flex flex-wrap justify-content-end">
                                                    <Link
                                                        className="btn srp-btn btn-lightblue"
                                                        type="button"
                                                        to="/myaccount/programs">
                                                        {t("EPP_Go_Back")}
                                                    </Link>
                                                    <button
                                                        className="btn srp-btn btn-green ml-2"
                                                        type="submit"
                                                        disabled={emailError
                                                            || invalidEmailError
                                                            || submitEPPFormStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS}>
                                                        {submitEPPFormStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS
                                                            ? <CircularProgress size={20} thickness={5} style={{ color: 'white' }} />
                                                            : t("Submit")
                                                        }
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </React.Fragment>
                                    : values.eligibilityChecked === true
                                        ? <React.Fragment>
                                            <Divider />
                                            <div className="srp-card-details">
                                                <div className="srp-alert-error mb-3">
                                                    {t("Income_Not_Qualified")}
                                                </div>
                                                <div className="d-flex flex-wrap justify-content-end">
                                                    <Link
                                                        className="btn srp-btn btn-lightblue"
                                                        type="button"
                                                        to="/myaccount/programs">
                                                        {t("EPP_Go_Back")}
                                                    </Link>
                                                </div>
                                            </div>
                                        </React.Fragment>
                                        : null
                                }
                            </React.Fragment>
                            : <EconomyPricePlanSignupConfirmation formType={formType} />
                        }
                    </div>
                )
            }}
        </Formik>
    )
}

EconomyPricePlanFormikPure.propTypes = {
    customerName: PropTypes.string,
    formattedBillAccount: PropTypes.string,
    serviceAddress: PropTypes.object,
    formattedPhoneNumber: PropTypes.string,
    emailAddress: PropTypes.string,
    submitEPPForm: PropTypes.func,
    submitEPPFormStatus: PropTypes.string,
    formType: PropTypes.number,
    history: PropTypes.object,
    initialChangedState: PropTypes.bool,
    onStateChanged: PropTypes.func,
    canApply: PropTypes.bool,
    eppIncomeThresholds: PropTypes.array,

    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired
}

const EconomyPricePlanFormik = withTranslation("economyPricePlanSignupForm")(EconomyPricePlanFormikPure)
export { EconomyPricePlanFormik as default, EconomyPricePlanFormikPure }

function validateForm(values, t) {
    let errors = {}

    if (values.emailAddress === undefined || values.emailAddress.length === 0)
        errors.email = "Email_Address_Required"
    if (!validate.emailAddress(values.emailAddress))
        errors.invalidEmail = "Email_Address_Error"
    if (values.signature.length === 0)
        errors.invalidSignature = "Digital_Signature_Required"

    return errors
}