import { TOGGLE_SERVICE_PRICEPLAN_BUTTON } from '../../../actions/auth/navigation/page-header-actions'

const initialState = {
    addressPricePlaceStyle: {display: 'none'},
    arrowIcon: 'keyboard_arrow_down'
}

export default function pageHeaderReducer(state = initialState, action) {
    switch (action.type) {
        case TOGGLE_SERVICE_PRICEPLAN_BUTTON:
            if(state.addressPricePlaceStyle == null)
            {
                return { ...state, addressPricePlaceStyle: {display: 'none'}, arrowIcon: 'keyboard_arrow_down' }
            }
            else 
            {
                return { ...state, addressPricePlaceStyle: null, arrowIcon: 'keyboard_arrow_up' }
            }
        default:
            return state
    }
}