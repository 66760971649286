export const solarNonprofitFormConstants = {
    DONATION_AMT_NONE:"DONATION_AMT_NONE",
    DONATION_AMT_3: "DONATION_AMT_3",
    DONATION_AMT_6: "DONATION_AMT_6",
    DONATION_AMT_9: "DONATION_AMT_9",
    DONATION_AMT_12: "DONATION_AMT_12",
    DONATION_AMT_UNENROLL: "DONATION_AMT_UNENROLL",

    SOLAR_NONPROFIT_SIGNUP: 0,
    SOLAR_NONPROFIT_CHANGE: 1,
    SOLAR_NONPROFIT_UNENROLL: 2,

    SUBMIT_SOLAR_NONPROFIT_FORM_REQUEST: 'SUBMIT_SOLAR_NONPROFIT_FORM_REQUEST',
    SUBMIT_SOLAR_NONPROFIT_FORM_SUCCESS: 'SUBMIT_SOLAR_NONPROFIT_FORM_SUCCESS',
    SUBMIT_SOLAR_NONPROFIT_FORM_FAILURE: 'SUBMIT_SOLAR_NONPROFIT_FORM_FAILURE',
}