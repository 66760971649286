import {weatherDataConstants} from '../../../constants/weather-data-constants'
import { RSAA } from 'redux-api-middleware'
import config from 'my-account-config'

export const getDailyWeather = () =>{
    return dispatch => {
        return dispatch({
            [RSAA]: {
                endpoint: config.apiBaseUrl + 'api/usage/dailyweather',
                method: 'GET',
                credentials: 'include',
                types: [
                    weatherDataConstants.GET_WEATHER_REQUEST,
                    weatherDataConstants.GET_WEATHER_SUCCESS,
                    weatherDataConstants.GET_WEATHER_FAILURE
                ]
            }
        })
    }
}
