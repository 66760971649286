import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { DateTime } from 'luxon'
import { DocumentTitle } from '../../../srp_modules/document-title'

import BankInfoLoadingCard from './bank-info-loading-card'
import BankInfoErrorCard from './bank-info-error-card'
import BankInformationTable from './bank-information-table'
import AutopayTable from './autopay-table'

import { getBankAccountList, showDeleteBankModule, closeDeleteBankModule, closeDeleteBankErrorModule, deleteBankAccount, getBankAccountToEdit } from '../../../actions/auth/payment/bank-account-actions'
import { getAutopayInfoList, cancelAutopay, showCancelAutopayModule, closeCancelAutopayModule, getPrevAutopay, getNextAutopay } from '../../../actions/auth/payment/autopay-actions'
import { getScheduledPayments, getPendingPayments } from '../../../actions/auth/payment/payment-actions'
import { selectBillAccountOnChange } from '../../../actions/auth/bill_account/bill-account-actions'
import { addSnackbarMessage } from '../../../actions/common/snackbar'
import * as loadingStatus from '../../../constants/loading-status-constants'
import { withRouter } from '../../../srp_modules/with-router'
import { withTranslation } from 'react-i18next'

class BankInformationCard extends React.Component {
    constructor(props) {
        super(props);

        this.refreshData = this.refreshData.bind(this)
    }

    componentDidMount() {
        if (this.props.selectedBillAccount === 0)
            return

        if (this.props.bankAccountListStatus === loadingStatus.LOADING_STATUS_INIT) {
            this.props.getBankAccountListInfo()
        }

        if (this.props.autopayInfoListStatus === loadingStatus.LOADING_STATUS_INIT) {
            this.props.getAutopayInfoList()
        }

        if (this.props.scheduledPaymentsStatus === loadingStatus.LOADING_STATUS_INIT) {
            this.props.getScheduledPaymentsInfo()
        }

        if (this.props.pendingPaymentsStatus === loadingStatus.LOADING_STATUS_INIT) {
            this.props.getPendingPaymentsInfo()
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.bankAccountListStatus === loadingStatus.LOADING_STATUS_INIT) {
            this.props.getBankAccountListInfo()
        }

        if (this.props.selectedBillAccount === nextProps.selectedBillAccount || nextProps.selectedBillAccount === 0)
            return

        if (nextProps.bankAccountListStatus !== loadingStatus.LOADING_STATUS_IN_PROGRESS) {
            this.props.getBankAccountListInfo()
        }

        if (nextProps.autopayInfoListStatus !== loadingStatus.LOADING_STATUS_IN_PROGRESS) {
            this.props.getAutopayInfoList()
        }

        if (nextProps.scheduledPaymentsStatus !== loadingStatus.LOADING_STATUS_IN_PROGRESS) {
            this.props.getScheduledPaymentsInfo()
        }

        if (nextProps.pendingPaymentsStatus !== loadingStatus.LOADING_STATUS_IN_PROGRESS) {
            this.props.getPendingPaymentsInfo()
        }
    }

    refreshData(){
        if (this.props.bankAccountListStatus === loadingStatus.LOADING_STATUS_FAILURE) {
            this.props.getBankAccountListInfo()
        }

        if (this.props.autopayInfoListStatus === loadingStatus.LOADING_STATUS_FAILURE) {
            this.props.getAutopayInfoList()
        }

        if (this.props.scheduledPaymentsStatus === loadingStatus.LOADING_STATUS_FAILURE) {
            this.props.getScheduledPaymentsInfo()
        }

        if (this.props.pendingPaymentsStatus === loadingStatus.LOADING_STATUS_FAILURE) {
            this.props.getPendingPaymentsInfo()
        }
    }

    render() {
        if (this.props.bankAccountListStatus === loadingStatus.LOADING_STATUS_INIT || this.props.bankAccountListStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS
            || this.props.autopayInfoListStatus === loadingStatus.LOADING_STATUS_INIT || this.props.autopayInfoListStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS
            || this.props.scheduledPaymentsStatus === loadingStatus.LOADING_STATUS_INIT || this.props.scheduledPaymentsStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS
            || this.props.pendingPaymentsStatus === loadingStatus.LOADING_STATUS_INIT || this.props.pendingPaymentsStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS) {
            return (
                <DocumentTitle title="Bank information | SRP Power">
                    <div className="row mt-3">
                        <div className="col-lg-9 col-12">
                            <div className="srp-card-header">
                                {this.props.t("Banking information")}
                        </div>
                            <div className="srp-card-body">
                                <div className="srp-card-details">
                                    <BankInfoLoadingCard />
                                </div>
                            </div>
                        </div>
                    </div>
                </DocumentTitle>
            )
        }
        else if (this.props.bankAccountListStatus === loadingStatus.LOADING_STATUS_SUCCESS && this.props.autopayInfoListStatus === loadingStatus.LOADING_STATUS_SUCCESS
            && this.props.scheduledPaymentsStatus === loadingStatus.LOADING_STATUS_SUCCESS && this.props.pendingPaymentsStatus === loadingStatus.LOADING_STATUS_SUCCESS) {
            return (
                <DocumentTitle title="Bank information | SRP Power">
                    <div className="row mt-3">
                        <div className="col-lg-9 col-12">
                            {this.props.cancelAutopaySuccess.isSuccess
                                ? <div className="srp-alert-success mb-3">
                                    {this.props.t(
                                        "cancel_surepay", 
                                        {
                                            day : DateTime.fromISO(this.props.cancelAutopaySuccess.dateSubmitted).toFormat('MMMM d, yyyy'),
                                            time : DateTime.fromISO(this.props.cancelAutopaySuccess.dateSubmitted).toFormat('h:mm a')
                                        }
                                    )}
                                    {/* {`Cancel SurePay request submitted ${DateTime.fromISO(this.props.cancelAutopaySuccess.dateSubmitted).toFormat('MMMM d, yyyy')} at */}
                                    {/* ${DateTime.fromISO(this.props.cancelAutopaySuccess.dateSubmitted).toFormat('h:mm a')}. Please allow two business days for changes to be visible on My Account.`} */}
                                </div>
                                : null
                            }
                            <div className="srp-card-header">
                                {this.props.t("Banking information")}
                            </div>
                            <div className="srp-card-body">
                                <div className="srp-card-details">
                                    <h4 style={{ color: 'rgba(51, 51, 51, 0.537)' }}>{this.props.t("eChex accounts")}</h4>
                                    {this.props.bankAccountList.length !== 0
                                        ? <BankInformationTable
                                            bankAccountList={this.props.bankAccountList}
                                            scheduledPayments={this.props.scheduledPayments}
                                            pendingPayments={this.props.pendingPayments}
                                            isCommercial={this.props.selectedBillAccountDetails.isCommercial}
                                            allBillAccountsAreCommercial={this.props.allBillAccountsAreCommercial}
                                            showDeleteBankModule={this.props.showDeleteBankModule}
                                            bankAccountToDelete={this.props.bankAccountToDelete}
                                            showDeleteBankModuleOnClick={this.props.showDeleteBankModuleOnClick}
                                            closeDeleteBankModuleOnClick={this.props.closeDeleteBankModuleOnClick}
                                            showDeleteBankErrorModule={this.props.showDeleteBankErrorModule}
                                            closeDeleteBankErrorModuleOnClick={this.props.closeDeleteBankErrorModuleOnClick}
                                            deleteBankAccountOnClick={this.props.deleteBankAccountOnClick}
                                            deleteBankAccountError={this.props.deleteBankAccountError}
                                            deleteBankAccountStatus={this.props.deleteBankAccountStatus}
                                            getBankAccountToEditOnClick={this.props.getBankAccountToEditOnClick} 
                                            t={this.props.t}/>
                                        : <div className="mb-2" style={{ color: '#707070' }}>{this.props.t("You currently have no bank accounts for online payments.")}</div>}
                                    <div className="d-flex justify-content-end">
                                        <Link
                                            to="/myaccount/profile/banks/addbankaccount"
                                            className="btn srp-btn btn-lightblue mb-4">
                                            {this.props.t("Add")}
                                        </Link>
                                    </div>
                                    <h4 style={{ color: 'rgba(51, 51, 51, 0.537)' }}>{this.props.t("SurePay accounts")}</h4>
                                    <AutopayTable
                                        autopayInfoList={this.props.autopayInfoList}
                                        showCancelAutopayModule={this.props.showCancelAutopayModule}
                                        showCancelAutopayModuleOnClick={this.props.showCancelAutopayModuleOnClick}
                                        closeCancelAutopayModuleOnClick={this.props.closeCancelAutopayModuleOnClick}
                                        autopayToCancel={this.props.autopayToCancel}
                                        cancelAutopayStatus={this.props.cancelAutopayStatus}
                                        cancelAutopayOnClick={this.props.cancelAutopayOnClick}
                                        numOfAutopayToShowStart={this.props.numOfAutopayToShowStart}
                                        numOfAutopayToShowEnd={this.props.numOfAutopayToShowEnd}
                                        getPrevAutopayOnClick={this.props.getPrevAutopayOnClick}
                                        getNextAutopayOnClick={this.props.getNextAutopayOnClick}
                                        enrollAutopayOnClick={this.props.enrollAutopayOnClick}
                                        editAutopayOnClick={this.props.editAutopayOnClick} 
                                        t={this.props.t}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </DocumentTitle>
            )
        }
        else {
            return (
                <DocumentTitle title="Bank information | SRP Power">
                    <BankInfoErrorCard refreshData={this.refreshData} t={this.props.t}/>
                </DocumentTitle>
            )
        }
    }
}

const mapStateToProps = state => {
    return {
        ...state.accountInfo.billAccount,
        ...state.bankAccounts,
        ...state.accountInfo.autopay,
        ...state.accountInfo.payment,
        allBillAccountsAreCommercial: state.accountInfo.billAccount.billAccountList?.every(b => b.isCommercial) ?? false
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getBankAccountListInfo: () => {
            dispatch(getBankAccountList())
        },
        getAutopayInfoList: () => {
            dispatch(getAutopayInfoList())
        },
        getScheduledPaymentsInfo: () => {
            dispatch(getScheduledPayments())
        },
        getPendingPaymentsInfo: () => {
            dispatch(getPendingPayments())
        },
        showDeleteBankModuleOnClick: (bankAccountInfo) => {
            dispatch(showDeleteBankModule(bankAccountInfo))
        },
        closeDeleteBankModuleOnClick: () => {
            dispatch(closeDeleteBankModule())
        },
        closeDeleteBankErrorModuleOnClick: () => {
            dispatch(closeDeleteBankErrorModule())
        },
        deleteBankAccountOnClick: async (bankToDelete, allBillAccountsAreCommercial) => {
            let response = await dispatch(deleteBankAccount(bankToDelete.bankIdToken, allBillAccountsAreCommercial))
            if (response.error || !response.payload.isSuccess)
                return

            let snackbarMessage = bankToDelete.nickName.length > 0 ? `${bankToDelete.nickName} removed` : "Bank account removed"
            dispatch(addSnackbarMessage(<span>{snackbarMessage}</span>))
        },
        addSnackbarMessageOnDelete: (message) => {
            dispatch(addSnackbarMessage(message))
        },
        getBankAccountToEditOnClick: (bankAccount, canDelete) => {
            dispatch(getBankAccountToEdit(bankAccount, canDelete))
            ownProps.router.navigate('/myaccount/profile/banks/editbankaccount');
        },
        showCancelAutopayModuleOnClick: (autopayInfo) => {
            dispatch(showCancelAutopayModule(autopayInfo))
        },
        closeCancelAutopayModuleOnClick: () => {
            dispatch(closeCancelAutopayModule())
        },
        cancelAutopayOnClick: (autopayToCancel) => {
            dispatch(cancelAutopay(autopayToCancel))
        },
        getPrevAutopayOnClick: () => {
            dispatch(getPrevAutopay())
        },
        getNextAutopayOnClick: (lengthOfList) => [
            dispatch(getNextAutopay(lengthOfList))
        ],
        enrollAutopayOnClick: async (billAccount) => {
            await dispatch(selectBillAccountOnChange(billAccount))
            ownProps.router.navigate('/myaccount/payment/autopay')
        },
        editAutopayOnClick: async (billAccount) => {
            await dispatch(selectBillAccountOnChange(billAccount))
            ownProps.router.navigate('/myaccount/payment/autopay/edit')
        }

    }
}

BankInformationCard.propTypes = {
    selectedBillAccount: PropTypes.number,
    selectedBillAccountDetails: PropTypes.object,

    bankAccountList: PropTypes.arrayOf(PropTypes.object),
    bankAccountListStatus: PropTypes.string,
    getBankAccountListInfo: PropTypes.func,

    autopayInfoList: PropTypes.arrayOf(PropTypes.object),
    autopayInfoListStatus: PropTypes.string,
    getAutopayInfoList: PropTypes.func,

    scheduledPayments: PropTypes.arrayOf(PropTypes.object),
    scheduledPaymentsStatus: PropTypes.string,
    getScheduledPaymentsInfo: PropTypes.func,

    pendingPayments: PropTypes.arrayOf(PropTypes.object),
    pendingPaymentsStatus: PropTypes.string,
    getPendingPaymentsInfo: PropTypes.func,

    showDeleteBankModule: PropTypes.bool,
    showDeleteBankModuleOnClick: PropTypes.func,
    closeDeleteBankModuleOnClick: PropTypes.func,
    showDeleteBankErrorModule: PropTypes.bool,
    closeDeleteBankErrorModuleOnClick: PropTypes.func,

    bankAccountToDelete: PropTypes.object,
    deleteBankAccountError: PropTypes.arrayOf(PropTypes.object),
    deleteBankAccountStatus: PropTypes.string,
    deleteBankAccountOnClick: PropTypes.func,

    getBankAccountToEditOnClick: PropTypes.func,

    addSnackbarMessageOnDelete: PropTypes.func,

    showCancelAutopayModule: PropTypes.bool,
    showCancelAutopayModuleOnClick: PropTypes.func,
    closeCancelAutopayModuleOnClick: PropTypes.func,

    autopayToCancel: PropTypes.object,
    cancelAutopaySuccess: PropTypes.object,
    cancelAutopayStatus: PropTypes.string,
    cancelAutopayOnClick: PropTypes.func,

    numOfAutopayToShowStart: PropTypes.number,
    numOfAutopayToShowEnd: PropTypes.number,
    getPrevAutopayOnClick: PropTypes.func,
    getNextAutopayOnClick: PropTypes.func,

    enrollAutopayOnClick: PropTypes.func,
    editAutopayOnClick: PropTypes.func,

    allBillAccountsAreCommercial: PropTypes.bool.isRequired,
    t: PropTypes.func
}

export default withTranslation("bankInformation")(withRouter(connect(mapStateToProps, mapDispatchToProps)(BankInformationCard)))