import React from 'react'
import PropTypes from 'prop-types'

import { Link } from 'react-router-dom'
import Divider from '@mui/material/Divider'

const CardBillSummaryMaster = ({t}) => (
    <div className="srp-card">
        <div className="srp-card-header">{t("My bill")}</div>
        <div className="srp-card-body">
            <div className="srp-card-summary d-flex justify-content-around" style={{ backgroundColor: '#747474' }}>
                <div className="d-flex flex-column align-items-center text-white">
                    <div className="h4">{t("N/A")}</div>
                    {t("No billing information")}
                </div>
            </div>
            <div className="srp-card-details">
                <div className="d-lg-block d-none">
                    <div className="d-flex flex-wrap justify-content-end">
                        <Link to="/myaccount/bill" className="btn srp-btn btn-lightblue mb-3">{t("View bill")}</Link>
                        <Link to="/myaccount/payment" className="btn srp-btn btn-blue ml-2 mb-3">{t("Pay bill")}</Link>
                    </div>
                </div>
                <span style={{ color: '#707070' }}>{t("This_is_a_summary_billing_account")}</span>
            </div>
            <div className="d-lg-none d-block">
                <Divider />
                <div className="srp-card-details">
                    <div className="d-flex flex-wrap justify-content-end">
                        <Link to="/myaccount/bill" className="btn srp-btn btn-lightblue">{t("View bill")}</Link>
                        <Link to="/myaccount/payment" className="btn srp-btn btn-blue ml-2">{t("Pay bill")}</Link>
                    </div>
                </div>
            </div>
        </div>
    </div>
)

CardBillSummaryMaster.propTypes = {
    t: PropTypes.func.isRequired,
}

export default CardBillSummaryMaster