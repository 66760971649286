import React from 'react'
import MenuItem from '@mui/material/MenuItem'
import { contactConstants } from '../constants/contact-constants'
import format from './format'

const alertsModule = {
    getPhoneContactList,
    getEmailContactList,
    normalizeEnrolledContacts,
    getContactMenuItems,
    getContactLabel,
    createNotificationForAdd,
    createNotificationForUpdateDelete,
    formatDollarAmount,
    createEBillContactsString
}

function getPhoneContactList(contacts) {
    let phoneList = []
    for (let i = 0; i < contacts.length; i++) {
        if (contacts[i].contactType === contactConstants.CONTACT_TYPE.PHONE) {
            phoneList.push(contacts[i])
        }
    }
    return phoneList
}

function getEmailContactList(contacts) {
    let emailList = []
    for (let i = 0; i < contacts.length; i++) {
        if (contacts[i].contactType === contactConstants.CONTACT_TYPE.EMAIL) {
            emailList.push(contacts[i])
        }
    }

    return emailList
}

function normalizeEnrolledContacts(contactList, enrolledContactList) {
    let newEnrolledContactList = []

    for (let i = 0; i < contactList.length; i++) {
        for (let j = 0; j < enrolledContactList.length; j++) {
            if (contactList[i].contactAddress === enrolledContactList[j].contactAddress) {
                newEnrolledContactList.push({
                    ...enrolledContactList[j],
                    contactAddressDisplay: contactList[i].contactAddressDisplay,
                    contactLabel: contactList[i].contactLabel
                })
            }
        }
    }

    return newEnrolledContactList
}

function getContactMenuItems(contactList, enrolledContactList) {
    let items = []

    for (let i = 0; i < contactList.length; i++) {
        if (enrolledContactList.find(e => e.contactAddress === contactList[i].contactAddress) === undefined) {
            let label = getContactLabel(contactList[i])
            items.push(<MenuItem
                key={i + 1}
                value={contactList[i]}
            >{label}</MenuItem>)
        }
    }

    return items
}

function getContactLabel(contact) {
    let label = ""
    if (contact.contactLabel === null || contact.contactLabel === "") {
        label = contact.contactLabel
        label = contact.contactAddress
        if (contact.contactType === contactConstants.CONTACT_TYPE.PHONE) {
            label = format.formatPhoneNumber(label)
        }
    }
    else {
        label = contact.contactLabel
    }

    return label.toLowerCase()
}

function createNotificationForAdd(billAccount, notification, notificationData, contact) {
    let newNotification = {
        billAccount: billAccount,
        notificationToAdd: {
            notificationType: notification.notificationType,
            group: notification.group,
            subGroup: notification.subGroup,
            contacts: [contact],
            notificationData: notificationData,
            required: notification.required,
            description: notification.description,
            title: notification.title,
            accountNumber: billAccount,
            sortOrder: notification.sortOrder,
            hasEbill: notification.hasEbill,
            hasPurchaseEnote: notification.hasPurchaseEnote,
            hasCourtesyReminder: notification.hasCourtesyReminder,
            deviceClientId: notification.deviceClientId,
            availibleForPush: notification.availibleForPush
        }
    }

    return newNotification
}

function createNotificationForUpdateDelete(billAccount, notification, notificationData, contactsToUpdate) {
    let newNotification = {
        notificationType: notification.notificationType,
        group: notification.group,
        subGroup: notification.subGroup,
        contacts: contactsToUpdate.length === 0 ? notification.contacts : [contactsToUpdate],
        notificationData: notificationData,
        required: notification.required,
        description: notification.description,
        title: notification.title,
        accountNumber: billAccount,
        sortOrder: notification.sortOrder,
        hasEbill: notification.hasEbill,
        hasPurchaseEnote: notification.hasPurchaseEnote,
        hasCourtesyReminder: notification.hasCourtesyReminder,
        deviceClientId: notification.deviceClientId,
        availibleForPush: notification.availibleForPush
    }

    return newNotification
}

function formatDollarAmount(number) {
    let formattedDollarAmount = ((number).toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")

    return formattedDollarAmount
}

function createEBillContactsString(emailContacts, phoneContacts) {
    let allContacts = emailContacts.concat(phoneContacts)
    let string = ""

    for(let i=0; i < allContacts.length; i++){
        let contactLabel = allContacts[i].contactType === contactConstants.CONTACT_TYPE.PHONE
                            ? format.formatPhoneNumber(allContacts[i].contactAddress)
                            : allContacts[i].contactAddress

        if(allContacts.length === 1 || i === allContacts.length-1){
            string = string + `${contactLabel}`
        }
        else {
            string = string + `${contactLabel}, `
        }
    }

    return string.toLowerCase()
}

export default alertsModule