export const getShareAmountList = t => [
    {
        value: "0",
        text: t("Select an amount")
    },
    {
        value: "1",
        text: "$1.00"
    },
    {
        value: "2",
        text: "$2.00"
    },
    {
        value: "5",
        text: "$5.00"
    }
];

export default {getShareAmountList};