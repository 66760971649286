import React from 'react'
import PropTypes from 'prop-types'
import Icon from '@mui/material/Icon'

import * as loadingStatus from '../../../constants/loading-status-constants'
import DocumentCenterDocError from './document-center-doc-error'
import DocumentSuccess from './document-success'
import DocumentFailure from './document-failure'

const ItemizedStatementDocs = ({ itemizedStatementLink, itemizedStatementLinkResultStatus, requestItemizedHistoryOnClick, itemizedHistoryRequestResultStatus, isCommercial, refreshFailedLinks, primaryEmail, t }) => {

    let resultStatusMessage = null
    let descriptiveText = t("An account summary providing a 90 day purchase and usage history. This report can often be used for agency assistance requests.")
    let linkName = t("M-Power Customer Report")

    if (itemizedHistoryRequestResultStatus === loadingStatus.LOADING_STATUS_SUCCESS) {
        resultStatusMessage = <DocumentSuccess primaryEmail={primaryEmail} t={t} />
    }
    else if (itemizedHistoryRequestResultStatus === loadingStatus.LOADING_STATUS_FAILURE) {
        resultStatusMessage = <DocumentFailure t={t}/>
    }

    let itemizedStatement = null
    if (itemizedStatementLinkResultStatus === loadingStatus.LOADING_STATUS_SUCCESS) {
        itemizedStatement =
            (<div style={{ paddingTop: '5px' }}>
                <button type="button" className="displayAsLink" onClick={() => {
                    requestItemizedHistoryOnClick(itemizedStatementLink.url)
                }}>
                    <h3>{linkName}</h3>
                </button>
                <p className="text-muted">{descriptiveText}</p>
                <p className="text-muted">{t("*This request will be emailed to you in 2-3 business days.")}</p>
                {resultStatusMessage}
            </div>)
    }
    else if (itemizedStatementLinkResultStatus === loadingStatus.LOADING_STATUS_FAILURE) {
        itemizedStatement =
            (<div style={{ paddingTop: '5px' }}>
                <div>
                    <h3 className="text-muted" style={{ display: 'inline' }}>{linkName}</h3>
                    <button className="displayAsText" style={{ marginLeft: '1em', verticalAlign: 'sub' }}
                        onClick={() => { refreshFailedLinks() }}
                    >
                        <Icon style={{ color: '#087ed8' }}>refresh</Icon>
                    </button>
                </div>
                <p className="text-muted">{descriptiveText}</p>
                <DocumentCenterDocError isCommercial={isCommercial} t={t}/>
            </div>)
    }

    return (
        <div>
            {itemizedStatement}
        </div>
    )
}

ItemizedStatementDocs.propTypes = {
    isCommercial: PropTypes.bool.isRequired,

    itemizedStatementLink: PropTypes.object.isRequired,
    itemizedStatementLinkResultStatus: PropTypes.string.isRequired,

    requestItemizedHistoryOnClick: PropTypes.func.isRequired,
    itemizedHistoryRequestResultStatus: PropTypes.string.isRequired,

    refreshFailedLinks: PropTypes.func,

    primaryEmail: PropTypes.string,
    t: PropTypes.func
}

export default ItemizedStatementDocs