import React from 'react'
import PropTypes from 'prop-types'

import marketplace from '../../images/ads/marketplace.jpg'
import marketplaceEs from '../../images/ads/marketplaceEs.jpg'

const MarketplaceAd = ({t, isSpanish}) => (
    <a href="https://srpmarketplace.com/" target="_new">
        <img alt = {t("SRP Marketplace")} className="img-fluid w-100" src={isSpanish ? marketplaceEs : marketplace} />
    </a>
)

MarketplaceAd.propTypes = {
    t: PropTypes.func.isRequired,
    isSpanish: PropTypes.bool.isRequired,
}

export default MarketplaceAd
