import React from 'react'
import PropTypes from 'prop-types'

import BankAccountSelector from './bank-account-selector'

const SelectABankAccount = ({bankAccountList, bankAccountIdSelected, addBankAccountButtonClicked, setFieldValue, isSubmitting, t, ...commonFormikProps}) => (
    <div>
        <BankAccountSelector bankAccountList={bankAccountList} value={bankAccountIdSelected} t={t} {...commonFormikProps} />

        <div className="d-flex justify-content-end mt-3">
            <button className="btn srp-btn btn-lightblue mb-2" type="button" onClick={() => {addBankAccountButtonClicked(setFieldValue)}} disabled={isSubmitting}>{t("Add bank account")}</button>
        </div>
    </div>
)

SelectABankAccount.propTypes = {
    bankAccountList: PropTypes.arrayOf(PropTypes.shape({
        bankId: PropTypes.number.isRequired,
        bankName: PropTypes.string.isRequired,
        nickName: PropTypes.string.isRequired,
        accountNumber: PropTypes.string.isRequired
    })).isRequired,
    bankAccountIdSelected: PropTypes.number.isRequired,
    addBankAccountButtonClicked: PropTypes.func.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
    t: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired,
    errors: PropTypes.shape({
        srpAccountNumber: PropTypes.oneOfType([
            PropTypes.string.isRequired,
            PropTypes.shape({
                getLocalizedErrMsg: PropTypes.func.isRequired,
            }),
        ]),
        bankAccountIdSelected: PropTypes.oneOfType([
            PropTypes.string.isRequired,
            PropTypes.shape({
                getLocalizedErrMsg: PropTypes.func.isRequired,
            }),
        ]),
        bankFirstName: PropTypes.string,
        bankLastName: PropTypes.string,
        bankPhoneNumber: PropTypes.string,
        bankEmailAddress: PropTypes.string,
        bankRoutingNumber: PropTypes.string,
        bankAccountNumberLeadingZeroesAreSignificant: PropTypes.string,
        bankNickname: PropTypes.string,
        paymentAmount: PropTypes.oneOfType([
            PropTypes.string.isRequired,
            PropTypes.shape({
                getLocalizedErrMsg: PropTypes.func.isRequired,
            }),
        ]),
    }).isRequired,
    touched: PropTypes.shape({
        srpAccountNumber: PropTypes.bool,
        bankAccountIdSelected: PropTypes.bool,
        bankFirstName: PropTypes.bool,
        bankLastName: PropTypes.bool,
        bankPhoneNumber: PropTypes.bool,
        bankEmailAddress: PropTypes.bool,
        bankRoutingNumber: PropTypes.bool,
        bankAccountNumberLeadingZeroesAreSignificant: PropTypes.bool,
        bankNickname: PropTypes.bool,
        paymentAmount: PropTypes.bool
    }).isRequired
}

export default SelectABankAccount