import React from 'react'
import PropTypes from 'prop-types'

import { withTranslation } from 'react-i18next'

import CallAnytime from '../../myaccount_header/call-anytime'

import '../../../styles/dashboard-page.css'

import MakePaymentByCard from './make-payment-by-card'
import OtherPaymentOptions from './other-payment-options'

import LoginForm from '../../../components/login_page/login-form'

const PayUsingBankAccountLoginPagePure = ({t, i18n}) => (
    <div className="container py-3">
        <CallAnytime />

        <h3 className="mt-3 mb-4">Make a payment</h3>

        <div className="row">
            <div className="col-lg-6">
                <MakePaymentByCard parentPage="PayUsingBankAccountLoginPage" t={t} i18n={i18n} />

                <div className="mt-3">
                    <div className="srp-card-header">
                        Enter login information
                    </div>

                    <div className="srp-card-body srp-card-details">
                        <LoginForm showSignUpButtonAndBlueLoginButton={true} />
                    </div>
                </div>
            </div>

            <div className="col-lg-6 mt-3 mt-lg-0">
                <OtherPaymentOptions t={t} i18n={i18n} />
            </div>
        </div>
    </div>
)


PayUsingBankAccountLoginPagePure.propTypes = {
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired,
    t: PropTypes.func.isRequired,
}


const PayUsingBankAccountLoginPage = withTranslation("guestPayment")(PayUsingBankAccountLoginPagePure)
export { PayUsingBankAccountLoginPage as default, PayUsingBankAccountLoginPagePure }