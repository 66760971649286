import { paymentConstants } from '../../../constants/payment-constants'
import { multiPaymentConstants } from '../../../constants/payment/multi-payment-constants'
import { myAccountConstants } from '../../../constants/myaccount-constants'
import { RSAA } from 'redux-api-middleware'
import utils from '../../../srp_modules/utils'
import validate from '../../../srp_modules/validate'

let getPaymentAccounts = (billAccount, selectedMailingZipCode, selectedServiceZipCode, paymentInfoList, mpowerPaymentInfoList) => {
    function getZipCode(mailingZip, serviceZip) {
        const mailingZipCode = mailingZip ?? ""
        const serviceZipCode = serviceZip ?? ""
        return mailingZipCode!=="" ? mailingZipCode : serviceZipCode
    }

    let paymentAccounts = []

    if (paymentInfoList.length == 0 && mpowerPaymentInfoList.length == 0) {
        // single payment/purchase page
        const zipCode = getZipCode(selectedMailingZipCode, selectedServiceZipCode)
        paymentAccounts.push({billAccount: billAccount, zipCode })
    } else {
        // multi payment/purchase page
        paymentInfoList.forEach(p => {
            if (p.paymentStatus !== multiPaymentConstants.MULTI_PAYMENT_STATUS.CASH_ONLY && !p.paymentInfo.isClosedAccountWithZeroBalance) {
                const mailingZipCode = p.mailingAddress.zipcode
                const serviceZipCode = p.serviceAddress.zipCode
                const zipCode = getZipCode(mailingZipCode, serviceZipCode)

                paymentAccounts.push({billAccount: p.billAccount, zipCode})
            }
        });
        mpowerPaymentInfoList.forEach(p => {
            if (!p.isCashOnly && !p.paymentInfo.isClosedAccountWithZeroBalance) {
                const mailingZipCode = p.mailingAddress.zipcode
                const serviceZipCode = p.serviceAddress.zipCode
                const zipCode = getZipCode(mailingZipCode, serviceZipCode)

                paymentAccounts.push({billAccount: p.billAccount, zipCode})
            }
        });
    }

    return paymentAccounts
}

export const validateMakePayment = () => (dispatch, getState) => {
    dispatch({ type: paymentConstants.VERIFY_PAYMENT_VALIDATION_REQUEST })

    let validationType = paymentConstants.VERIFY_PAYMENT_VALIDATION_SUCCESS
    let isValid = true
    let paymentInfo = getState().accountInfo.payment.makePaymentInfo

    let formError = (paymentInfo.accountNumberError || paymentInfo.amountError || paymentInfo.paymentDateError)

    let valueError = (paymentInfo.accountNumber === '' ||
        paymentInfo.paymentDate === '' ||
        !validate.paymentAmount(paymentInfo.amount)
    )

    if (formError || valueError) {
        isValid = false
        validationType = paymentConstants.VERIFY_PAYMENT_VALIDATION_FAILURE
    }

    dispatch({
        type: validationType,
        isValid
    })

    return new Promise(function (resolve) {
        resolve(isValid)
    })
}

export const getPayment = (billAccount) => {
    let url = utils.createUrl('payments/accountpayment', { billAccount: billAccount })

    return dispatch => {
        return dispatch({
            [RSAA]: {
                endpoint: url,
                method: 'GET',
                credentials: 'include',
                types: [
                    paymentConstants.GET_PAYMENT_REQUEST,
                    paymentConstants.GET_PAYMENT_SUCCESS,
                    paymentConstants.GET_PAYMENT_FAILURE
                ]
            }
        })
    }
}

// get payment history
export const getPaymentHistory = (billAccount) => {
    let url = utils.createUrl('payments/historybyacct', { billAccount: billAccount })

    return dispatch => {
        return dispatch({
            [RSAA]: {
                endpoint: url,
                method: 'GET',
                credentials: 'include',
                types: [
                    paymentConstants.GET_PAYMENT_HISTORY_REQUEST,
                    paymentConstants.GET_PAYMENT_HISTORY_SUCCESS,
                    paymentConstants.GET_PAYMENT_HISTORY_FAILURE
                ]
            }
        })
    }
}

// get scheduled payments
export const getScheduledPayments = () => {
    let url = utils.createUrl('payments/getschedules')

    return dispatch => {
        return dispatch({
            [RSAA]: {
                endpoint: url,
                method: 'GET',
                credentials: 'include',
                types: [
                    paymentConstants.GET_SCHEDULED_PAYMENTS_REQUEST,
                    paymentConstants.GET_SCHEDULED_PAYMENTS_SUCCESS,
                    paymentConstants.GET_SCHEDULED_PAYMENTS_FAILURE
                ]
            }
        })
    }
}

// get pending payments
export const getPendingPayments = () => {
    let url = utils.createUrl('payments/getpending')

    return dispatch => {
        return dispatch({
            [RSAA]: {
                endpoint: url,
                method: 'GET',
                credentials: 'include',
                types: [
                    paymentConstants.GET_PENDING_PAYMENTS_REQUEST,
                    paymentConstants.GET_PENDING_PAYMENTS_SUCCESS,
                    paymentConstants.GET_PENDING_PAYMENTS_FAILURE
                ]
            }
        })
    }
}

// actions to get enrolled programs
export const getEnrolledPrograms = (billAccount) => {
    let url = utils.createUrl('payments/enrolledprograms', { billAccount: billAccount })

    return dispatch => {
        return dispatch({
            [RSAA]: {
                endpoint: url,
                method: 'GET',
                credentials: 'include',
                types: [
                    paymentConstants.GET_ENROLLED_PROGRAMS_REQUEST,
                    paymentConstants.GET_ENROLLED_PROGRAMS_SUCCESS,
                    paymentConstants.GET_ENROLLED_PROGRAMS_FAILURE,
                ]
            }
        })
    }
}

export const getEnrolledProgramsDetails = (billAccount) => {
    let url = utils.createUrl('payments/billingoptionsdetails', { billAccount: billAccount })

    return dispatch => {
        return dispatch({
            [RSAA]: {
                endpoint: url,
                method: 'GET',
                credentials: 'include',
                types: [
                    paymentConstants.GET_ENROLLED_PROGRAMS_DETAILS_REQUEST,
                    paymentConstants.GET_ENROLLED_PROGRAMS_DETAILS_SUCCESS,
                    paymentConstants.GET_ENROLLED_PROGRAMS_DETAILS_FAILURE,
                ]
            }
        })
    }
}

export const setCustomDueDate = (customDueDateSubmit) => {
    let url = utils.createUrl('payments/setcustomduedate')

    return dispatch => {
        return dispatch({
            [RSAA]: {
                endpoint: url,
                body: JSON.stringify(customDueDateSubmit),
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                types: [
                    paymentConstants.SET_CUSTOM_DUE_DATE_REQUEST,
                    paymentConstants.SET_CUSTOM_DUE_DATE_SUCCESS,
                    paymentConstants.SET_CUSTOM_DUE_DATE_FAILURE,
                ]
            },
            meta: {
                debounce: {
                    leading: false,
                    trailing: false
                }
            }
        })
    }
}

export const getCustomDueDateEligible = (billAccount) => {
    let url = utils.createUrl('payments/iscustomduedateeligible', { billAccount: billAccount })

    return dispatch => {
        return dispatch({
            [RSAA]: {
                endpoint: url,
                method: 'GET',
                credentials: 'include',
                types: [
                    paymentConstants.GET_CUSTOM_DUE_DATE_ELIGIBLE_REQUEST,
                    paymentConstants.GET_CUSTOM_DUE_DATE_ELIGIBLE_SUCCESS,
                    paymentConstants.GET_CUSTOM_DUE_DATE_ELIGIBLE_FAILURE,
                ]
            }
        })
    }
}

export const selectBillAccountOnChange = () => ({
    type: paymentConstants.SELECT_BILL_ACCOUNT_ON_CHANGE
})

export const getCreditCardMultiPaymentUrl = () => {
    return (dispatch, getState) => {
        let state = getState()

        const paymentUrl = state.accountInfo.payment.creditCardPaymentUrl
        const selectedBillAccount = state.accountInfo.billAccount.selectedBillAccount
        const selectedMailingAddress = state.accountInfo.billAccount.billingAddress
        const selectedMailingZip = selectedMailingAddress?.zipcode
        const selectedServiceAddress = state.accountInfo.billAccount.serviceAddress
        const selectedServiceZip = selectedServiceAddress?.zipCode
        const emailAddress = state.accountInfo.contact.primaryEmail

        let rateMetaData = state.rateMetaData.rateMetaData

        let paymentAccounts = getPaymentAccounts(selectedBillAccount, selectedMailingZip, selectedServiceZip, state.multiPayment.paymentInfoList, state.multiPaymentNgp.mpowerPaymentInfoList )

        let url = utils.createUrl('payments/getcreditcardmultipaymenturl', { billAccount: selectedBillAccount }, true)

        if (paymentUrl === '#') {
            return dispatch({
                [RSAA]: {
                    endpoint: url,
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        firstName: rateMetaData.firstName,
                        lastName: rateMetaData.lastName,
                        emailAddress: emailAddress,
                        billAccounts: paymentAccounts
                    }),
                    credentials: 'include',
                    types: [
                        paymentConstants.GET_CREDIT_CARD_PAYMENT_URL_REQUEST,
                        paymentConstants.GET_CREDIT_CARD_PAYMENT_URL_SUCCESS,
                        paymentConstants.GET_CREDIT_CARD_PAYMENT_URL_FAILURE
                    ]
                }
            })
        }
    }
}

export const getCreditExtension = (billAccount) => {
    return dispatch => {
        return dispatch({
            [RSAA]: {
                endpoint: utils.createUrl('payments/creditextensioncheck', { billAccount: billAccount }),
                method: 'GET',
                credentials: 'include',
                types: [
                    paymentConstants.GET_CREDIT_EXTENSION_REQUEST,
                    paymentConstants.GET_CREDIT_EXTENSION_SUCCESS,
                    paymentConstants.GET_CREDIT_EXTENSION_FAILURE
                ]
            }
        })
    }
}

export const submitCreditExtension = (billAccount, emailAddress) => {
    return (dispatch) => {
        let parameters = {
            billAccount,
            extensionType: myAccountConstants.MAX_EXTENSION_TYPE,
            emailAddress: emailAddress
        }

        let url = utils.createUrl('payments/submitcreditextension', parameters)
        return dispatch({
            [RSAA]: {
                endpoint: url,
                method: 'POST',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                credentials: 'include',
                types: [
                    paymentConstants.SUBMIT_CREDIT_EXTENSION_REQUEST,
                    paymentConstants.SUBMIT_CREDIT_EXTENSION_SUCCESS,
                    paymentConstants.SUBMIT_CREDIT_EXTENSION_FAILURE
                ]
            }
        })
    }
}

export const verifySinglePayment = (bankAccountId) => (dispatch, getState) => {
    const state = getState()
    const makePaymentInfo = state.accountInfo.payment.makePaymentInfo

    const selectedBankId = bankAccountId == 0 ? makePaymentInfo.bankAccountId : bankAccountId
    let selectedBank = state.bankAccounts.bankAccountList.find(ba => ba.bankId === selectedBankId)
    const bankAccountIdToken = (selectedBank === undefined ? '' : selectedBank.bankIdToken)

    let parameters = {
        paymentList: [{
            billAccountNumber: +makePaymentInfo.accountNumber,
            paymentAmount: +makePaymentInfo.amount,
            scheduledPaymentDate: makePaymentInfo.paymentDate
        }],
        bankAccountIdToken: bankAccountIdToken
    }

    return dispatch({
        [RSAA]: {
            endpoint: utils.createUrl('payments/verifypaymentlist'),
            method: 'POST',
            body: JSON.stringify(parameters),
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include',
            types: [
                paymentConstants.VERIFY_SINGLE_PAYMENT_REQUEST,
                paymentConstants.VERIFY_SINGLE_PAYMENT_SUCCESS,
                paymentConstants.VERIFY_SINGLE_PAYMENT_FAILURE
            ]
        },
        meta: {
            debounce: {
                leading: false,
                trailing: false
            }
        }
    })
}

export const submitSinglePayment = () => (dispatch, getState) => {
    const state = getState()

    const selectedBankId = state.accountInfo.payment.makePaymentInfo.bankAccountId
    let selectedBank = state.bankAccounts.bankAccountList.find(ba => ba.bankId === selectedBankId)
    if (selectedBank === undefined) {
        selectedBank = {
            bankIdToken: '',
            firstName: '',
            lastName: '',
            email: '',
            accountNumber: ''
        }
    }

    let payment = {
        billAccountNumber: +state.accountInfo.payment.makePaymentInfo.accountNumber,
        paymentAmount: +state.accountInfo.payment.makePaymentInfo.amount,
        scheduledPaymentDate: state.accountInfo.payment.makePaymentInfo.paymentDate
    }

    let billAccountList = state.accountInfo.billAccount.billAccountList
    let isCommercial = billAccountList?.find(b => b.account === payment.billAccountNumber)?.isCommercial ?? false ?? false

    let parameters = {
        paymentList: [payment],
        bankAccountIdToken: selectedBank.bankIdToken,
        sendCommercialConfirmationEmail: isCommercial,
        bankSignerFirstNameForConfirmationEmail: selectedBank.firstName,
        bankSignerLastNameForConfirmationEmail: selectedBank.lastName,
        bankSignerEmailAddressForConfirmationEmail: selectedBank.email,
        bankAccountNumberTruncatedForConfirmationEmail: selectedBank.accountNumber,
        referenceNumberFromValidatePayment: state.accountInfo.payment.verifyPaymentResult.referenceNumber
    }

    return dispatch({
        [RSAA]: {
            endpoint: utils.createUrl('payments/submitpaymentlist_emailconfirmation'),
            method: 'POST',
            body: JSON.stringify(parameters),
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include',
            types: [
                paymentConstants.SUBMIT_PAYMENT_REQUEST,
                paymentConstants.SUBMIT_PAYMENT_SUCCESS,
                paymentConstants.SUBMIT_PAYMENT_FAILURE
            ]
        }
    })
}

export const clearPaymentInfo = () => ({
    type: paymentConstants.CLEAR_PAYMENT_INFO
})

export const clearMakePaymentResult = () => ({
    type: paymentConstants.CLEAR_MAKE_PAYMENT_RESULT
})

// make payment information
export const getAccountNumber = (accountNumber) => ({
    type: paymentConstants.GET_PAYMENT_ACCOUNT_NUMBER,
    accountNumber
})

export const getPaymentAmount = (amount) => ({
    type: paymentConstants.GET_PAYMENT_AMOUNT,
    amount
})

export const getPaymentDate = (date) => ({
    type: paymentConstants.GET_PAYMENT_DATE,
    date
})

export const makePaymentInfoChange = (makePaymentInfo) => ({
    type: paymentConstants.MAKE_PAYMENT_INFO_CHANGE,
    makePaymentInfo
})

export const deletePayment = (paymentReferenceNumber, paymentBillAccounts, paymentBankAccountId) => (dispatch, getState) => {
    const state = getState()

    let bankSignerFirstName = ""
    let bankSignerEmailAddress = ""
    if (state.bankAccounts && state.bankAccounts.bankAccountList) {
        let bankProfile = state.bankAccounts.bankAccountList.find(p => p.bankId === paymentBankAccountId)
        if (bankProfile) {
            bankSignerFirstName = bankProfile.firstName
            bankSignerEmailAddress = bankProfile.email
        }
    }

    let billAccountList = state.accountInfo.billAccount.billAccountList
    let allCommercial = false
    if (paymentBillAccounts && billAccountList && paymentBillAccounts.length > 0) {
        allCommercial = paymentBillAccounts.every(paymentBillAccount => billAccountList.find(b => b.account === paymentBillAccount)?.isCommercial ?? false)
    }

    let parameters = {
        paymentReferenceNumber,
        bankSignerFirstNameForConfirmationEmail: bankSignerFirstName,
        bankSignerEmailAddressForConfirmationEmail: bankSignerEmailAddress,
        sendCommercialConfirmationEmail: allCommercial
    }

    return dispatch({
        [RSAA]: {
            endpoint: utils.createUrl('payments/deleteAndEmailConfirmation'),
            method: 'POST',
            body: JSON.stringify(parameters),
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include',
            types: [
                paymentConstants.DELETE_PAYMENT_REQUEST,
                paymentConstants.DELETE_PAYMENT_SUCCESS,
                paymentConstants.DELETE_PAYMENT_FAILURE
            ]
        }
    })
}

export const getScheduledPaymentsByAcct = (billAccount) => {
    let url = utils.createUrl('payments/getscheduledbyacct', { billAccount: billAccount })

    return dispatch => {
        return dispatch({
            [RSAA]: {
                endpoint: url,
                method: 'GET',
                credentials: 'include',
                types: [
                    paymentConstants.GET_SCHEDULED_PAYMENTS_BY_ACCT_REQUEST,
                    paymentConstants.GET_SCHEDULED_PAYMENTS_BY_ACCT_SUCCESS,
                    paymentConstants.GET_SCHEDULED_PAYMENTS_BY_ACCT_FAILURE
                ]
            }
        })
    }
}

export const getPaymentsByAcct = (billAccount) => {
    let url = utils.createUrl('payments/getpaymentsbyacct', { billAccount: billAccount })

    return dispatch => {
        return dispatch({
            [RSAA]: {
                endpoint: url,
                method: 'GET',
                credentials: 'include',
                types: [
                    paymentConstants.GET_PAYMENTS_BY_ACCT_REQUEST,
                    paymentConstants.GET_PAYMENTS_BY_ACCT_SUCCESS,
                    paymentConstants.GET_PAYMENTS_BY_ACCT_FAILURE
                ]
            }
        })
    }
}

export const authorizeTokenlessReconnect = (billAccount) => {
    let url = utils.createUrl('payments/reconnect', { billAccount: billAccount })

    return dispatch => {
        return dispatch({
            [RSAA]: {
                endpoint: url,
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                credentials: 'include',
                types: [
                    paymentConstants.AUTHORIZE_TOKENLESS_RECONNECT_REQUEST,
                    paymentConstants.AUTHORIZE_TOKENLESS_RECONNECT_SUCCESS,
                    paymentConstants.AUTHORIZE_TOKENLESS_RECONNECT_FAILURE
                ]
            }
        })
    }
}
