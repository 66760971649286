import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Select from '@mui/material/Select'
import Chip from '@mui/material/Chip'
import CircularProgress from '@mui/material/CircularProgress'
import Icon from '@mui/material/Icon'
import '../../../../styles/dashboard-page.css'

import { notificationConstants } from '../../../../constants/notification-constants'
import alertsModule from '../../../../srp_modules/alerts-module'

const verifiedStyle = {
    marginTop: 0.5,
    marginBottom: 0.5,
    marginRight: 0.5,
    paddingLeft: 0,
    backgroundColor:'#E3E4DB',
    color: '#757575',
    fontSize: '1rem'
}

const pendingStyle = {
    marginTop: 0.5,
    marginBottom: 0.5,
    marginRight: 0.5,
    paddingLeft: 0,
    backgroundColor:'#E0E7F0',
    color: '#757575',
    fontSize: '1rem'
}

class TextAlert extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            addTextAlertLoading: false,
            deleteTextAlertLoading: false,
            chipToDelete: {},
            subscribedPhoneContacts: alertsModule.normalizeEnrolledContacts(this.props.phoneContacts, this.props.alert.phone)
        }

        this.addTextChip = this.addTextChip.bind(this)
        this.deleteTextChip = this.deleteTextChip.bind(this)
    }

    UNSAFE_componentWillUpdate(nextProps) {
        if (nextProps.alert.phone !== this.props.alert.phone || nextProps.contacts !== this.props.contacts) {
            this.setState({
                subscribedPhoneContacts: alertsModule.normalizeEnrolledContacts(nextProps.phoneContacts, nextProps.alert.phone)
            })
        }
    }

    async addTextChip(billAccount, notificationInfo, notificationData, contact) {
        this.setState({
            addTextAlertLoading: true
        })

        if (notificationInfo.notificationType === notificationConstants.NOTIFICATION_TYPE.MPOWER_ALERT) {
            await this.props.addNotification(billAccount, notificationInfo, this.props.allAlerts.mPowerPurchaseAlert, contact)
        }
        else {
            await this.props.addNotification(billAccount, notificationInfo, notificationData, contact)
        }

        this.setState({
            addTextAlertLoading: false
        })
    }

    async deleteTextChip(billAccount, notificationInfo, notificationData, contact) {
        this.setState({
            deleteTextAlertLoading: true,
            chipToDelete: contact
        })

        if (notificationInfo.notificationType === notificationConstants.NOTIFICATION_TYPE.MPOWER_ALERT) {
            await this.props.deleteNotification(billAccount, notificationInfo, this.props.allAlerts.mPowerPurchaseAlert, contact)
        }
        else {
            await this.props.deleteNotification(billAccount, notificationInfo, notificationData, contact)
        }

        this.setState({
            deleteTextAlertLoading: false
        })
    }

    render() {
        return (
            <div>
                <div className="d-lg-block d-none">
                    <div className="text-muted pl-3">{this.props.t("phone_numbers_subsribed", {shortCode : this.props.alert.notificationInfo.associatedShortCode})}</div>
                    <div className="d-flex flex-wrap pl-3">
                        <span className="mr-2">
                            {this.state.subscribedPhoneContacts.map((chip) => (
                                <Chip
                                    key={chip.contactAddress}
                                    onDelete={() => this.deleteTextChip(this.props.selectedBillAccount, this.props.alert.notificationInfo, this.props.notificationData, chip)}

                                    deleteIcon={this.state.deleteTextAlertLoading && this.state.chipToDelete === chip
                                        ? <CircularProgress size={20} thickness={5} />
                                        : <Icon style={{color: "#757575"}}>cancel</Icon>}
                                    sx={chip.verifiedShortCodes.includes(this.props.alert.notificationInfo.associatedShortCode)
                                        ? verifiedStyle
                                        : pendingStyle}
                                    label={alertsModule.getContactLabel(chip)}
                                    icon={chip.verifiedShortCodes.includes(this.props.alert.notificationInfo.associatedShortCode)
                                        ? <Icon sx={{ '&&': { ml: -.3, mr: -1, fontSize: "2.4rem", color: "#737B4C" }}}>check_circle</Icon>
                                        : <Icon sx={{ '&&': { ml: -.3, mr: -1, fontSize: "2.4rem", color: "#6787B7" }}}>pending</Icon>}
                                />
                            ))}
                        </span>
                        <span>
                            <Select
                                value={0}
                                onChange={event => this.addTextChip(this.props.selectedBillAccount, this.props.alert.notificationInfo, this.props.notificationData, event.target.value)}
                                className="d-flex align-bottom"
                                disabled={this.state.addTextAlertLoading}
                                renderValue={()=>this.state.addTextAlertLoading ? <CircularProgress size={20} thickness={5} /> : this.props.t("Add phone")}
                            >
                                {alertsModule.getContactMenuItems(this.props.phoneContacts, this.state.subscribedPhoneContacts)}
                            </Select>
                        </span>
                    </div>
                </div>
                <div className="d-lg-none d-block">
                    <div className="text-muted">Phone numbers subscribed (Messages sent to/from {this.props.alert.notificationInfo.associatedShortCode})</div>
                    <div className="d-flex flex-wrap mb-2 mt-2">
                        {this.state.subscribedPhoneContacts.map((chip) => (
                        <Chip
                            key={chip.contactAddress}
                            onDelete={() => this.deleteTextChip(this.props.selectedBillAccount, this.props.alert.notificationInfo, this.props.notificationData, chip)}
                            deleteIcon={this.state.deleteTextAlertLoading && this.state.chipToDelete === chip
                                ? <CircularProgress size={20} thickness={5} />
                                : <Icon>cancel</Icon>}
                            sx={chip.verifiedShortCodes.includes(this.props.alert.notificationInfo.associatedShortCode)
                                ? verifiedStyle
                                : pendingStyle}
                            label={alertsModule.getContactLabel(chip)}
                            icon={chip.verifiedShortCodes.includes(this.props.alert.notificationInfo.associatedShortCode)
                                ? <Icon sx={{ '&&': { ml: -.3, mr: -1, fontSize: "2.4rem", color: "#737B4C" }}}>check_circle</Icon>
                                : <Icon sx={{ '&&': { ml: -.3, mr: -1, fontSize: "2.4rem", color: "#6787B7" }}}>pending</Icon>}
                        />
                        ))}
                    </div>
                    <div>
                        <Select
                            value={0}
                            onChange={event => this.addTextChip(this.props.selectedBillAccount, this.props.alert.notificationInfo, this.props.notificationData, event.target.value)}
                            disabled={this.state.addTextAlertLoading}
                            renderValue={()=>this.state.addTextAlertLoading ? <CircularProgress size={20} thickness={5} /> : "Add phone"}
                        >
                            {alertsModule.getContactMenuItems(this.props.phoneContacts, this.state.subscribedPhoneContacts)}
                        </Select>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return { ...state.accountInfo.billAccount, ...state.accountInfo.notification }
}

TextAlert.propTypes = {
    selectedBillAccount: PropTypes.number,
    phoneContacts: PropTypes.arrayOf(PropTypes.object),
    allAlerts: PropTypes.object,
    alert: PropTypes.object,
    notificationData: PropTypes.string,
    contacts: PropTypes.arrayOf(PropTypes.object),

    addNotification: PropTypes.func,
    deleteNotification: PropTypes.func,

    t: PropTypes.func
}

export default connect(mapStateToProps)(TextAlert)