import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import Divider from '@mui/material/Divider'
import CompareMyPricePlan from './compare-my-price-plan'

class MyPricing extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {
        const t = this.props.t
        const isSpanish = this.props.i18n.language === "es"

        return (
            <div className="srp-card-body">
                <div className="srp-card-details">
                    <div className="d-lg-block d-none">
                        <div className="d-flex align-items-center" style={{ fontSize: '1.125rem' }}>
                            <div className="col-3 text-muted text-left p-0">
                                {t("Current price plan")}
                            </div>
                            <div className="col-7">{this.props.isSummaryMaster ? t("Summary billing")
                                : (isSpanish ? this.props.currentRateCode.descriptionEs : this.props.currentRateCode.description)}</div>
                            <div className="col-2 d-flex justify-content-end">
                                {!this.props.isSummaryMaster
                                    ? this.props.isClosed
                                        ? <span className="text-muted">{t("Change")}</span>
                                        : <Link to="/changepriceplan">{t("Change")}</Link>
                                    : null
                                }
                            </div>
                        </div>
    
                        {this.props.currentRateCode.isTimeOfUse
                            ? <div className="srp-alert-notice mt-4">
                                <span className="font-weight-bold mr-2">{t("Price plan tip")}</span>
                                <span>{t("Shifting energy usage to lower cost off-peak hours can save money on your bill.")}</span>
                            </div>
                            : null
                        }
                    </div>
    
                    <div className="d-lg-none d-block">
                        <div className="text-muted font-weight-bold mb-1">{t("Current price plan")}</div>
                        <div className="row">
                            <div className="col-8">
                                <div className="pl-2">{this.props.isSummaryMaster ? t("Summary billing")
                                    : (isSpanish ? this.props.currentRateCode.descriptionEs : this.props.currentRateCode.description)}</div>
                            </div>
                            <div className="col-4 d-flex justify-content-end">
                                {!this.props.isSummaryMaster
                                    ? this.props.isClosed
                                        ? <span className="text-muted">{t("Change")}</span>
                                        : <Link to="/changepriceplan">{t("Change")}</Link>
                                    : null
                                }
                            </div>
                        </div>
                        {this.props.currentRateCode.isTimeOfUse
                            ? <div className="srp-alert-notice mt-3">
                                <span className="font-weight-bold mr-2">{t("Price plan tip")}</span>
                                <span>{t("Shifting energy usage to lower cost off-peak hours can save money on your bill.")}</span>
                            </div>
                            : null
                        }
                    </div>
    
                </div>
                <Divider />
                {/* Compare My Price Plan */}
                {this.props.compareMyPricePlan.isEligible && 
                    <CompareMyPricePlan 
                        currentRateCode={this.props.currentRateCode}
                        compareMyPricePlan={this.props.compareMyPricePlan}
                        selectPricePlanClick={this.props.selectPricePlanClick}
                        isCommercial={this.props.isCommercial}
                        t={t}
                    />}
            </div>
        )
    }
}

MyPricing.propTypes = {
    currentRateCode: PropTypes.object,
    isSummaryMaster: PropTypes.bool,
    isClosed: PropTypes.bool,
    isCommercial: PropTypes.bool,
    compareMyPricePlan: PropTypes.object,
    selectPricePlanClick: PropTypes.func.isRequired,

    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired,
}

export default MyPricing