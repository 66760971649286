import React from 'react'
import PropTypes from 'prop-types'
import { DateTime } from 'luxon'
import { connect } from 'react-redux'
import { Card, CircularProgress } from '@mui/material'

import { Trans } from 'react-i18next'
import utils from '../../srp_modules/utils'

import LandlordAgreementDropdown from './landlord-agreement-dropdown'
import LandlordAgreementTable from './landlord-agreement-table'
import LandlordAgreementPaginated from './landlord-agreement-paginated'
import AddLandlordForm from '../profile_settings_page/account_info/add_landlord/add-landlord-form'
import { LOADING_STATUS_IN_PROGRESS, LOADING_STATUS_FAILURE, LOADING_STATUS_INIT } from '../../constants/loading-status-constants'
import { changeSelectedLandlordAgreement, getLandlordBillInfo, getLandlordMostRecentPayments } from '../../actions/auth/landlord/landlord-actions'
import { selectBillAccountOnChange, addMultipleBillAccounts, getBillAccounts } from '../../actions/auth/bill_account/bill-account-actions'
import { addSnackbarMessage } from '../../actions/common/snackbar'
import { withRouter } from '../../srp_modules/with-router'

class LandlordCard extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            showAddLandlordForm: false,
            showTooltipForCRPCol: false,
            showTooltipForLTDCol: false,
        }

        this.landlordTurnOnDateColRef = React.createRef()

        this.GetPropertyState = this.GetPropertyState.bind(this)
        this.isVacated = this.isVacated.bind(this)
        this.msCSVData = this.mapCSVData.bind(this)
        this.exportCallback = this.exportCallback.bind(this)
        this.refetchLandlordAccounts = this.refetchLandlordAccounts.bind(this)
        this.addTooltip = this.addTooltip.bind(this)
    }

    componentDidMount() {
        this.getLandlordBillInfo(this.props)
    }

    componentDidUpdate(prevProps) {
        if (this.props.selectedLandlordAgreement !== prevProps.selectedLandlordAgreement) {
            this.getLandlordBillInfo(this.props)
        }

        if (prevProps.selectedBillAccount !== this.props.selectedBillAccount &&
            prevProps.selectedBillAccount > 0) {
            this.props.router.navigate('/MyAccount/Dashboard')
        }

        this.addTooltip(this.landlordTurnOnDateColRef, this.state.showTooltipForLTDCol, "showTooltipForLTDCol")
    }

    GetPropertyState(landlordProperty) {
        const t = this.props.t

        if (landlordProperty.isOffAccount)
            return t("Off")

        if (!landlordProperty.billAccount)
            return t("Tenant")

        if (landlordProperty.isFinalAccount)
            return t("Tenant")

        return t("Owner")
    }

    isVacated(landlordProperty) {

        if (!landlordProperty.startDate)
            return false

        if (!landlordProperty.billAccount)
            return false

        if (landlordProperty.isFinalAccount)
            return false

        return true
    }


    mapCSVData(landlordInfo, landlordBillInfo, landlordMostRecentPayments) {
        const t = this.props.t

        let csvArray = [[
            t('Property address'),
            t('Current Responsible Party'),
            t('Landlord Turn on Date'),
            t('Due date'),
            t('Amount due'),
            t('Last payment'),
            t('Amount paid')
        ]]

        landlordInfo.landlordProperties.forEach((a) => {
            let billInfo = {
                hasBillInfo: false, dueDate: undefined, balance: undefined,
                hasMostRecentPayments: false, lastPaymentDate: undefined, amountPaid: undefined
            }

            if (a.billAccount) {
                billInfo.hasBillInfo = landlordBillInfo[`${a.billAccount}`].isEmpty === false;
                billInfo.dueDate = landlordBillInfo[`${a.billAccount}`].currentDueDate;
                billInfo.balance = landlordBillInfo[`${a.billAccount}`].balance;

                billInfo.hasMostRecentPayments = landlordMostRecentPayments[`${a.billAccount}`].isEmpty === false;
                billInfo.lastPaymentDate = landlordMostRecentPayments[`${a.billAccount}`].lastPaymentDate;
                billInfo.amountPaid = landlordMostRecentPayments[`${a.billAccount}`].amountPaid;
            }

            csvArray.push([
                a.serviceAddress.streetAddress,
                this.GetPropertyState(a),
                this.isVacated(a) ? DateTime.fromISO(a.startDate).toFormat('MMM d yyyy') : '--',
                (billInfo.hasBillInfo && billInfo.balance > 0) ? DateTime.fromISO(billInfo.dueDate).toFormat('MMM d yyyy') : '--',
                billInfo.balance > 0 ? billInfo.balance.toLocaleString('en-US', { style: "currency", currency: "USD" }) : '--',
                billInfo.hasMostRecentPayments ? DateTime.fromISO(billInfo.lastPaymentDate).toFormat('MMM d yyyy') : '--',
                billInfo.hasMostRecentPayments ? Math.abs(billInfo.amountPaid).toLocaleString('en-US', { style: "currency", currency: "USD" }) : '--',
            ])
        })

        let columnDelimiter = ','
        let lineDelimiter = '\n'
        let csvResult = ''
        for (let i = 0; i < csvArray.length; i++) {
            for (let j = 0; j < csvArray[i].length; j++) {
                csvResult += csvArray[i][j]
                csvResult += columnDelimiter
            }
            csvResult += lineDelimiter
        }

        return csvResult
    }

    getLandlordBillInfo(props) {
        if (props.billAccountListStatus === LOADING_STATUS_INIT || props.billAccountListStatus === LOADING_STATUS_FAILURE) {
            props.getBillAccounts()
        }
        props.updateLandlordBillInfo(props.selectedLandlordAgreement)
        props.updateLandlordMostRecentPayments(props.selectedLandlordAgreement)
    }

    getCardStatus() {
        let showLoadingProgress = false
        let showErrorCard = false

        if (!this.props.landlordAccounts || this.props.landlordAccountsStatus === LOADING_STATUS_FAILURE
            || this.props.landlordBillInfoStatus === LOADING_STATUS_FAILURE
            || this.props.landlordMostRecentPaymentsStatus === LOADING_STATUS_FAILURE)
            showErrorCard = true
        else if (this.props.landlordBillInfoStatus === LOADING_STATUS_INIT
            || this.props.landlordBillInfoStatus === LOADING_STATUS_IN_PROGRESS
            || this.props.landlordMostRecentPaymentsStatus === LOADING_STATUS_INIT
            || this.props.landlordMostRecentPaymentsStatus === LOADING_STATUS_IN_PROGRESS
            || this.props.billAccountListStatus === LOADING_STATUS_INIT
            || this.props.billAccountListStatus === LOADING_STATUS_IN_PROGRESS)
            showLoadingProgress = true;

        return { showErrorCard: showErrorCard, showLoadingProgress: !showErrorCard && showLoadingProgress }
    }

    refetchLandlordAccounts() {
        this.props.getLandlordAccounts()
    }

    addTooltip(currentRef, currentStateVar, currentStateVarText) {
        if (!currentRef.current)
            return

        let isOverflowed = currentRef.current.scrollWidth > currentRef.current.clientWidth
        if(currentStateVar !== isOverflowed){
            this.setState({
                [currentStateVarText]: isOverflowed
            })
        }
    }

    exportCallback() {
        const selectedLandlordDetails = this.props.landlordAccounts[`${this.props.selectedLandlordAgreement}`]
        this.props.downloadPropertiesToCSV(this.mapCSVData(selectedLandlordDetails, this.props.landlordBillInfo, this.props.landlordMostRecentPayments), selectedLandlordDetails.landlordAgreementNumber);
    }

    render() {
        const t = this.props.t
        const i18n = this.props.i18n

        const isCommercial = true
        const isSpanish = (i18n.language === "es")
        const supportNumberFmt = utils.GetCustomerServicePhoneNumberFmt(isCommercial, isSpanish)

        let selectedLandlordDetails = this.props.landlordAccounts[`${this.props.selectedLandlordAgreement}`]

        let { showErrorCard, showLoadingProgress } = this.getCardStatus()

        if (showErrorCard) {
            return (
                <React.Fragment>
                    <div className="col-12 col-lg-9 pb-2">
                        <div className="srp-card-header">{t("My properties")}</div>
                        <Card className="srp-card-details p-3">
                            <div className="mb-1 text-center srp-100-red"><i className="material-icons srp-3x-font-size">error_outline</i></div>
                            <div className="mb-1 text-center srp-100-red"><strong>{t("Something went wrong")}</strong></div>
                            <div className="mb-4 text-center">{t("We were unable to retrieve landlord data.")}</div>

                            <div className="d-flex justify-content-end">
                                <button type="button" onClick={()=>this.refetchLandlordAccounts()} className="btn srp-btn btn-lightblue">{t("Try again")}</button>
                            </div> 
                        </Card>
                    </div>
                    <div className="col-12 col-lg-3">
                        <div className="d-lg-none mt-3" />
                        <div className="srp-card-header">{t("Need help?")}</div>
                        <Card className="srp-card-details" style={{ backgroundColor: 'rgb(247, 223, 208)' }}>
                            <Trans i18nKey="contact_info_commercial" t={t}>
                                For questions please contact SRP Business Customer Services
                                at <span className="text-nowrap">{{phoneNumber: supportNumberFmt}}</span> weekdays
                                from 7 AM - 5 PM excluding holidays.
                            </Trans>
                        </Card>
                    </div>
                </React.Fragment>
            )
        }

        return (
            <React.Fragment>
                <div className="col-12 col-lg-9 pb-2">
                    <div className="srp-card-header">{t("My properties")}</div>
                    <Card className="srp-card-details p-0">
                        <div className="d-flex flex-wrap p-3 greyBottomBorder">
                            <div className="flex-fill">
                                <LandlordAgreementDropdown landlordAgreementList={this.props.landlordAgreementList}
                                    selectedLandlordAgreement={this.props.selectedLandlordAgreement}
                                    landlordAgreementOnChange={this.props.landlordAgreementOnChange}
                                    disabled={showLoadingProgress} t={t} />
                            </div>
                            {(!this.state.showAddLandlordForm && !showErrorCard)
                                ? (<div className="mt-3">
                                    <button type="button" className="btn srp-btn btn-lightblue" onClick={() => this.setState({ showAddLandlordForm: true })}>{t("Add new agreement")}</button>
                                </div>) : null}
                        </div>

                        {this.state.showAddLandlordForm
                            ? (<div className="p-3 greyBottomBorder">
                                <AddLandlordForm hideForm={() => this.setState({ showAddLandlordForm: false })}
                                    t={t} i18n={i18n}
                                    handleSuccess={() => {
                                        this.props.getLandlordAccounts()
                                        this.setState({ showAddLandlordForm: false })
                                    }} />
                            </div>) : null}

                        {showLoadingProgress
                            ? (<div className="d-flex justify-content-center align-items-center" style={{ height: '10rem' }}>
                                <CircularProgress thickness={5} />
                            </div>) : null}

                        {!showErrorCard && !showLoadingProgress
                            ? <React.Fragment>
                                <div className="d-none d-lg-block p-3">
                                    <LandlordAgreementTable
                                        billAccountSet={this.props.billAccountSet}
                                        landlordDetails={selectedLandlordDetails}
                                        landlordBillInfo={this.props.landlordBillInfo}
                                        landlordMostRecentPayments={this.props.landlordMostRecentPayments}
                                        switchToBillAccount={this.props.switchToBillAccount}
                                        addLandlordBillAccount={this.props.addLandlordBillAccount}
                                        GetPropertyState={this.GetPropertyState}
                                        exportCallback={this.exportCallback}
                                        isVacated={this.isVacated}
                                        showTooltipForCRPCol={this.state.showTooltipForCRPCol}
                                        showTooltipForLTDCol={this.state.showTooltipForLTDCol}
                                        landlordTurnOnDateColRef={this.landlordTurnOnDateColRef}
                                        t={t} i18n={i18n}
                                        />
                                </div>
                                <div className="d-flex d-lg-none p-3">
                                    <LandlordAgreementPaginated
                                        billAccountSet={this.props.billAccountSet}
                                        landlordDetails={selectedLandlordDetails}
                                        landlordBillInfo={this.props.landlordBillInfo}
                                        landlordMostRecentPayments={this.props.landlordMostRecentPayments}
                                        switchToBillAccount={this.props.switchToBillAccount}
                                        addLandlordBillAccount={this.props.addLandlordBillAccount}
                                        GetPropertyState={this.GetPropertyState}
                                        exportCallback={this.exportCallback}
                                        isVacated={this.isVacated}
                                        t={t} i18n={i18n}
                                        />
                                </div>
                            </React.Fragment>
                            : null}
                    </Card>
                </div>
                <div className="col-12 col-lg-3">
                    <div className="d-lg-none mt-3" />
                    <div className="srp-card-header">{t("Need help?")}</div>
                    <Card className="srp-card-details" style={{ backgroundColor: 'rgb(247, 223, 208)' }}>
                        <Trans i18nKey="contact_info_commercial" t={t}>
                            For questions please contact SRP Business Customer Services
                            at <span className="text-nowrap">{{phoneNumber: supportNumberFmt}}</span> weekdays
                            from 7 AM - 5 PM excluding holidays.
                        </Trans>
                    </Card>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        selectedBillAccount: state.accountInfo.billAccount.selectedBillAccount,
        billAccountSet: state.accountInfo.billAccount.billAccountSet,
        billAccountListStatus: state.accountInfo.billAccount.billAccountListStatus,
        ...state.landlord
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getBillAccounts: () => {
            dispatch(getBillAccounts())
        },
        landlordAgreementOnChange: (event) => {
            return dispatch(changeSelectedLandlordAgreement(event.target.value))
        },
        updateLandlordBillInfo: (selectedLandlordAgreement) => {
            dispatch(getLandlordBillInfo(selectedLandlordAgreement))
        },
        updateLandlordMostRecentPayments: (selectedLandlordAgreement) => {
            dispatch(getLandlordMostRecentPayments(selectedLandlordAgreement))
        },
        switchToBillAccount: (billAccount) => {
            return dispatch(selectBillAccountOnChange(billAccount))
        },
        downloadPropertiesToCSV(csv, csvName) {
            let filename = `${csvName}.csv`
            let dataType = 'text/csv;charset=utf-8'

            let attachment = new Blob(['\ufeff', csv], {
                type: dataType
            })

            if (navigator.msSaveOrOpenBlob) {
                navigator.msSaveOrOpenBlob(attachment, filename)
            }
            else {
                let downloadLink = document.createElement("a")

                downloadLink.href = URL.createObjectURL(attachment)
                downloadLink.download = filename

                document.body.appendChild(downloadLink)

                downloadLink.click()

                document.body.removeChild(downloadLink)
            }
        },
        addLandlordBillAccount: (billAccount, nickname, t) => async () => {

            let accountList = [{
                BillAccount: billAccount,
                Nickname: nickname
            }]

            let result = await dispatch(addMultipleBillAccounts(accountList))

            if (result.error || !result.payload.success) {
                let snackBarMsg = (<span>{t("We're sorry, something went wrong trying to add the account. Please try again later.")}</span>)
                dispatch(addSnackbarMessage(snackBarMsg))
                return false
            }

            dispatch(getBillAccounts())
        }
    }
}

LandlordCard.propTypes = {
    billAccountListStatus: PropTypes.string.isRequired,
    selectedBillAccount: PropTypes.number.isRequired,
    landlordAccounts: PropTypes.object.isRequired,
    landlordBillInfo: PropTypes.object.isRequired,
    landlordMostRecentPayments: PropTypes.object.isRequired,
    landlordAgreementList: PropTypes.array.isRequired,
    selectedLandlordAgreement: PropTypes.number.isRequired,
    getBillAccounts: PropTypes.func.isRequired,
    getLandlordAccounts: PropTypes.func.isRequired,
    landlordAgreementOnChange: PropTypes.func.isRequired,
    landlordAccountsStatus: PropTypes.string.isRequired,
    landlordBillInfoStatus: PropTypes.string.isRequired,
    landlordMostRecentPaymentsStatus: PropTypes.string.isRequired,
    billAccountSet: PropTypes.object.isRequired,
    switchToBillAccount: PropTypes.func.isRequired,
    addLandlordBillAccount: PropTypes.func.isRequired,
    downloadPropertiesToCSV: PropTypes.func.isRequired,

    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired,

    router: PropTypes.shape({
        navigate: PropTypes.func
    })
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LandlordCard))