import React from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

import RecentPayments from './recent-payments'

class RecentPaymentsContainer extends React.Component {
    constructor(props) {
        super(props)

        if (this.props.recentPaymentsList.length === 0)
            throw "One or more recent payments required."

        this.state = {
            indexOfRecentPaymentToShow: 0,
            lastRecentPaymentIndex: this.props.recentPaymentsList.length-1
        }

        this.goToPreviousRecentPayment = this.goToPreviousRecentPayment.bind(this)
        this.goToNextRecentPayment = this.goToNextRecentPayment.bind(this)
    }

    goToPreviousRecentPayment() {
        this.setState({
            indexOfRecentPaymentToShow: Math.max(0, this.state.indexOfRecentPaymentToShow-1)
        })
    }
 
    goToNextRecentPayment() {
        this.setState({
            indexOfRecentPaymentToShow: Math.min(this.state.indexOfRecentPaymentToShow+1, this.state.lastRecentPaymentIndex)
        })
    }
 
    render() {
        const onFirstRecentPayment = (this.state.indexOfRecentPaymentToShow === 0)
        const onLastRecentPayment = (this.state.indexOfRecentPaymentToShow === this.state.lastRecentPaymentIndex)

        return (
            <RecentPayments
                recentPayment={this.props.recentPaymentsList[this.state.indexOfRecentPaymentToShow]}
                bankAccountList={this.props.bankAccountList}
                makeAnotherPayment={this.props.makeAnotherPayment}
                scheduledBy={this.props.scheduledBy}
                onFirstRecentPayment={onFirstRecentPayment}
                onLastRecentPayment={onLastRecentPayment}
                goToPreviousRecentPayment={this.goToPreviousRecentPayment}
                goToNextRecentPayment={this.goToNextRecentPayment}
                t={this.props.t}
                i18n={this.props.i18n}
            />
        )
    }
}

RecentPaymentsContainer.propTypes = {
    recentPaymentsList: PropTypes.arrayOf(PropTypes.object).isRequired,
    bankAccountList: PropTypes.arrayOf(PropTypes.object).isRequired,
    makeAnotherPayment: PropTypes.func.isRequired,
    scheduledBy: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired,
    i18n: PropTypes.object.isRequired
}

export default withTranslation("singlePayment")(RecentPaymentsContainer)