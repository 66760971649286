import React from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'

import SinglePaymentDetailsTable from './single-payment-details-table'
import MultiplePaymentDetailsTable from './multiple-payment-details-table'
import { withRouter } from '../../../srp_modules/with-router'

class PaymentDetailsTableContainer extends React.Component {
    constructor(props) {
        super(props)

        if (this.isStateEmpty())
            this.props.router.navigate('/myaccount/payment/guest', { replace: true })
    }

    isStateEmpty() { return this.props.bankAccountNumber === '' && this.props.paymentList.length === 0 }

    render() {
        return (
            this.isStateEmpty() ? null : (this.props.paymentList.length === 1 ?
                <SinglePaymentDetailsTable bankAccountNumber={this.props.bankAccountNumber} paymentList={this.props.paymentList} referenceNumber={this.props.referenceNumber} t={this.props.t} /> :
                <MultiplePaymentDetailsTable bankAccountNumber={this.props.bankAccountNumber} paymentList={this.props.paymentList} referenceNumber={this.props.referenceNumber} t={this.props.t} />
            )
        )
    }
}

const mapStateToProps = state => {
    return { bankAccountNumber: state.guestPaymentBankInfo.bankAccountNumberLeadingZeroesAreSignificant, paymentList: state.guestPaymentList.paymentList }
}

PaymentDetailsTableContainer.propTypes = {
    bankAccountNumber: PropTypes.string.isRequired,
    paymentList: PropTypes.arrayOf(PropTypes.shape({
        srpAccountNumber: PropTypes.string.isRequired,
        paymentAmount: PropTypes.number.isRequired,
    })),
    referenceNumber: PropTypes.number,
    router: PropTypes.shape({
        navigate: PropTypes.func
    }),
    t: PropTypes.func.isRequired,
}

export default withRouter(connect(mapStateToProps)(PaymentDetailsTableContainer))