import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { withTranslation } from 'react-i18next'

import CircularProgress from '@mui/material/CircularProgress'

import * as loadingStatus from '../../../constants/loading-status-constants'

import BankAccountSelectorCard from './bank-account-selector-card'

import { getSelectedBankAccountForMultiPayment, setFormStartEventEmitted } from '../../../actions/auth/payment/multi-payment-ngp-actions'
import { getBankAccountList } from '../../../actions/auth/payment/bank-account-actions'

import { formStartEvent } from '../../../srp_modules/analytics-events'
import { purchaseConstants } from '../../common_payment/purchase-constants'

const loadingStyle = {
    marginLeft: "45%",
    marginTop: "20%",
    marginBottom: "20%"
}

const loadingStyleResp = {
    marginLeft: "40%",
    marginTop: "20%",
    marginBottom: "20%"
}

class BankAccountSelectorCardContainerPure extends React.Component {
    constructor(props) {
        super(props)
        this.getLoadingCard = this.getLoadingCard.bind(this)

        this.formDataChanged = this.formDataChanged.bind(this)
        this.bankAccountOnChangeAndTrack = this.bankAccountOnChangeAndTrack.bind(this)
    }

    componentDidMount(){
        if(this.props.bankAccountListStatus === loadingStatus.LOADING_STATUS_INIT )
            this.props.getBankAccountListInfo()
    }

    getLoadingCard() {
        return (
            <div>
                <div className="d-none d-md-block">
                    <CircularProgress size={80} thickness={5} style={loadingStyle} />
                </div>
                <div className="d-block d-md-none">
                    <CircularProgress size={80} thickness={5} style={loadingStyleResp} />
                </div>
            </div>
        )
    }

    formDataChanged() {
        if (this.props.formStartEventEmitted === false) {
            const formName = purchaseConstants.ADOBE_ANALYTICS_FORM_NAME
            const stepName = purchaseConstants.PURCHASE_STEPS[0]
            formStartEvent(formName, stepName)

            this.props.setFormStartEventEmitted()
        }
    }    

    bankAccountOnChangeAndTrack(bankAccount) {
        this.props.getSelectedBankAccountForMultiPaymentOnClick(bankAccount)
        this.formDataChanged()
    }

    render(){
        let renderedCard = {}
        if(this.props.bankAccountListStatus === loadingStatus.LOADING_STATUS_INIT || this.props.bankAccountListStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS)
        {
            renderedCard = this.getLoadingCard()
        }
        else if(this.props.bankAccountListStatus === loadingStatus.LOADING_STATUS_SUCCESS)
        {
            renderedCard = (
                <BankAccountSelectorCard
                    selectedBankAccountId={this.props.selectedBankAccountId}
                    getSelectedBankAccountForMultiPaymentOnClick={this.bankAccountOnChangeAndTrack}
                    bankAccountList={this.props.bankAccountList} />
            )

        }
        else if(this.props.bankAccountListStatus === loadingStatus.LOADING_STATUS_FAILURE)
        {
            renderedCard = (
                <div>
                    <Link to="/myaccount/profile/banks" className="btn srp-btn btn-lightblue">{this.props.t("Add bank account")}</Link>
                </div>
            )
        }
        return renderedCard
    }

}

const mapStateToProps = state => {
    return { ...state.multiPaymentNgp, bankAccountList: state.bankAccounts.bankAccountList, bankAccountListStatus: state.bankAccounts.bankAccountListStatus }
}

const mapDispatchToProps = dispatch => {
    return {
        getBankAccountListInfo: () => {
            dispatch(getBankAccountList())
        },
        getSelectedBankAccountForMultiPaymentOnClick: (bankAccount) => {
            dispatch(getSelectedBankAccountForMultiPayment(bankAccount))
        },
        setFormStartEventEmitted: () => {
            dispatch(setFormStartEventEmitted())
        },    
    }
}

BankAccountSelectorCardContainerPure.propTypes = {
    bankAccountListStatus: PropTypes.string,
    bankAccountList: PropTypes.arrayOf(PropTypes.object),
    getBankAccountListInfo: PropTypes.func,
    selectedBankAccountId: PropTypes.number,
    getSelectedBankAccountForMultiPaymentOnClick: PropTypes.func,
    setFormStartEventEmitted: PropTypes.func.isRequired,
    formStartEventEmitted: PropTypes.bool.isRequired,

    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired
}

const BankAccountSelectorCardContainer = withTranslation("multiPurchasePage")(connect(mapStateToProps, mapDispatchToProps)(BankAccountSelectorCardContainerPure))
export { BankAccountSelectorCardContainer as default, BankAccountSelectorCardContainerPure }