import { isRSAA, RSAA } from 'redux-api-middleware'
import cookies from 'js-cookie'
import config from 'my-account-config'

export const SKIP_XSRF = 'srp-my-account/SKIP_XSRF'
export const XSRF_TOKEN_REQUEST = 'XSRF_TOKEN_REQUEST'
export const XSRF_TOKEN_FAILURE = 'XSRF_TOKEN_FAILURE'
export const XSRF_TOKEN_SUCCESS = 'XSRF_TOKEN_SUCCESS'
export const XSRF_TOKEN_MISSING = 'XSRF_TOKEN_MISSING'

export const getXsrfToken = () => async dispatch => {
    let xsrfToken = cookies.get('xsrf-token') || '';
    if (xsrfToken !== '')
        return dispatch({ type: XSRF_TOKEN_SUCCESS, payload: { xsrfToken }})

    let xsrfResponse = await dispatch({
        [RSAA]: {
            endpoint: config.apiBaseUrl + 'api/login/antiforgerytoken',
            method:'GET',
            credentials: 'include',
            types: [
                XSRF_TOKEN_REQUEST,
                XSRF_TOKEN_SUCCESS,
                XSRF_TOKEN_FAILURE
            ]
        },
        [SKIP_XSRF]: true
    })

    if (xsrfResponse && xsrfResponse.type === XSRF_TOKEN_FAILURE) {
        cookies.remove('xsrf-token')
    }

    return xsrfResponse
}

export const xsrfMiddleware = ({ getState }) => next => action => {
    if (!isRSAA(action))
        return next(action)

    let rsaa = action[RSAA]
    // NOTE: Do not include xsrf-token on endpoints that are not our own
    if (!rsaa.endpoint.startsWith(config.apiBaseUrl))
        return next(action)

    let rsaaAction = { ...action }

    let xsrfProperty = rsaaAction[SKIP_XSRF]
    if (rsaaAction[SKIP_XSRF] !== undefined) {
        delete rsaaAction[SKIP_XSRF]
    }

    if (rsaa.credentials !== 'same-origin' && rsaa.credentials !== 'include' || xsrfProperty === true)
        return next(rsaaAction)

    let xsrfToken = cookies.get('xsrf-token') || getState().login.xsrfToken
    if (xsrfToken !== '') {
        rsaa.headers = { 'x-xsrf-token': decodeURIComponent(xsrfToken), ...rsaa.headers }
        return next(rsaaAction)
    }

    return next({ type: XSRF_TOKEN_MISSING, rsaaAction })
}