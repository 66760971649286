import React from 'react'
import { withTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import { PurchaseDetailsStyledDiv, classes } from './purchase-details-styles'

import utils from '../../../srp_modules/utils'

import toMeterIconBlue from '../../../images/payment/to-meter-blue.svg'
import toSRPIconBlue from '../../../images/payment/to-srp-blue.svg'

const PurchaseBreakdownPure = ({purchaseAmount, applyWholePurchaseTowardsArrears, amountOwedToSRP, payDownFraction, t}) => {
    const {amountToMeter, amountToSRP} = utils.calcAmountToMeterAndToSRP({purchaseAmount, amountOwedToSRP, payDownFraction, applyWholePurchaseTowardsArrears})
    const payDownPercent = Math.trunc(payDownFraction*100)

    return (
        <PurchaseDetailsStyledDiv>
        <div className={`${classes.root} ${classes.detailsBox} d-flex flex-row align-items-center border rounded p-2`}>
        <img src={toMeterIconBlue} className={classes.icon} />
        <h6 className="mb-0 ml-2 mr-4">
            <span className={classes.secondaryText}>{t("Amount to meter")}</span><br />
            <span className={classes.amount}>{isNaN(amountToMeter) ? '- -' : amountToMeter.toLocaleString('EN-US',{ style: 'currency', currency: 'USD' })}</span>
        </h6>

        <img src={toSRPIconBlue} className={classes.icon} />
        <h6 className="mb-0 ml-2">
            {applyWholePurchaseTowardsArrears ?
            <span className={classes.secondaryText}>{t("Amount to SRP")}</span> :
            <span className={classes.secondaryText}>{t("Amount to SRP")} ({payDownPercent}%)</span>
            }
            <br />
            <span className={classes.amount}>{isNaN(amountToSRP) ? '- -' : amountToSRP.toLocaleString('EN-US',{ style: 'currency', currency: 'USD' })}</span>
        </h6>
        </div>
        </PurchaseDetailsStyledDiv>
    )
}

PurchaseBreakdownPure.propTypes = {
    purchaseAmount: PropTypes.number.isRequired,
    applyWholePurchaseTowardsArrears: PropTypes.bool.isRequired,
    amountOwedToSRP: PropTypes.number.isRequired,
    payDownFraction: PropTypes.number.isRequired,

    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired
}

const PurchaseBreakdown = withTranslation("singlePurchasePage")(PurchaseBreakdownPure)
export { PurchaseBreakdown as default, PurchaseBreakdownPure }