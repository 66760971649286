import { cleanPowerConstants } from '../../../constants/clean-power-constants'
import * as loadingStatus from '../../../constants/loading-status-constants'

const initialState = {
    hasEnoughHistoryInfo:
    {
        success: false,
        hasEnoughHistory: false,
        ratePlanIsEligible: true,
        solarEstimatorURL: ""
    },
    hasEnoughHistoryStatus: loadingStatus.LOADING_STATUS_INIT,

    sendUsageDataInfo:
    {
        success: false,
        cachedIntervalDataInfo:
        {
            numBillingMonths: 0,
            customerId: 0,
            sessionId: "",
            uuid: "",
            timeoutMs: 0
        }
    },
    sendUsageDataStatus: loadingStatus.LOADING_STATUS_INIT,
}

export default function cleanPowerReducer(state = initialState, action) {
    switch (action.type) {

        case cleanPowerConstants.HAS_ENOUGH_HISTORY_REQUEST:
            return { ...state, hasEnoughHistoryStatus: loadingStatus.LOADING_STATUS_IN_PROGRESS }
        case cleanPowerConstants.HAS_ENOUGH_HISTORY_SUCCESS:
            return { ...state, hasEnoughHistoryInfo: action.payload, hasEnoughHistoryStatus: loadingStatus.LOADING_STATUS_SUCCESS }
        case cleanPowerConstants.HAS_ENOUGH_HISTORY_FAILURE:
            return { ...state, hasEnoughHistoryInfo: initialState.hasEnoughHistoryInfo, hasEnoughHistoryStatus: loadingStatus.LOADING_STATUS_FAILURE }


        case cleanPowerConstants.SEND_USAGE_DATA_REQUEST:
            return { ...state, sendUsageDataStatus: loadingStatus.LOADING_STATUS_IN_PROGRESS }
        case cleanPowerConstants.SEND_USAGE_DATA_SUCCESS:
            return { ...state, sendUsageDataInfo: action.payload, sendUsageDataStatus: loadingStatus.LOADING_STATUS_SUCCESS }
        case cleanPowerConstants.SEND_USAGE_DATA_FAILURE:
            return { ...state, sendUsageDataInfo: initialState.sendUsageDataInfo, sendUsageDataStatus: loadingStatus.LOADING_STATUS_FAILURE }


        default:
            return state
    }
}
