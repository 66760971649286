import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import CardBlue from '../../common_error/card-blue'
import { addSnackbarMessage } from '../../../actions/common/snackbar'
import SrpModal from '../../common_modal/srp-modal'
import { billAccountConstants } from '../../../constants/bill-account-constants'
import { deleteBillAccount, selectBillAccountOnChange, addMultipleBillAccounts, getBillAccounts } from '../../../actions/auth/bill_account/bill-account-actions'

import AssociatedAccountRowsHeader from './associated-account-rows-header'
import AssociatedAccountRow from './associated-account-row'
import SummaryBillingRow from './summary-billing-row'
import AddAccountOrLandlordContainer from './add-account-or-landlord-container'
import AssociatedAccountRowsPagination from './associated-account-rows-pagination'

import AssociatedAccountRowsHeaderResponsive from './responsive/associated-account-rows-header-responsive'
import AssociatedAccountRowResponsive from './responsive/associated-account-row-responsive'
import SummaryBillingRowResponsive from './responsive/summary-billing-row-responsive'
import AssociatedAccountRowsPaginationResponsive from './responsive/associated-account-rows-pagination-responsive'


class AssociatedAccountRows extends React.Component {
    constructor(props) {
        super(props)

        let currentSortType = 'Nickname Asc'
        let adjustedAccountList = props.billAccountList.length > 0 ? this.sortBillAccountList([...props.billAccountList], currentSortType) : []
        let adjustedSummaryList = props.summaryBillAccounts.length > 0 ? this.sortSubAccountList(this.spreadSummaryArray(props.summaryBillAccounts), currentSortType) : []
        this.state = {
            pageSize: 20,
            pageNumber: 1,
            adjustedAccountList,
            adjustedSummaryList,
            currentSortType,
            doSorting: false,
            isTextFiltering: false,
            filterText: '',
            doTextFiltering: false,
            isFiltered: false,
            showDeleteSummaryMasterDialog: false,
            deleteSummaryMasterButtons: []
        }

        this.changePageOnClick = this.changePageOnClick.bind(this)
        this.changePageSize = this.changePageSize.bind(this)
        this.deleteBillAccountOnClick = this.deleteBillAccountOnClick.bind(this)
        this.accountToString = this.accountToString.bind(this)
        this.subAccountToString - this.subAccountToString.bind(this)
        this.toggleFiltering = this.toggleFiltering.bind(this)
        this.onFilterTextChanged = this.onFilterTextChanged.bind(this)
        this.handleFilterKeysUp = this.handleFilterKeysUp.bind(this)
        this.filterAccounts = this.filterAccounts.bind(this)
        this.detectMatch = this.detectMatch.bind(this)
        this.setSortedBy = this.setSortedBy.bind(this)
        this.sortBillAccountList = this.sortBillAccountList.bind(this)
        this.sortNicknameBillAccount = this.sortNicknameBillAccount.bind(this)
        this.spreadSummaryArray = this.spreadSummaryArray.bind(this)
        this.sortSubAccountList = this.sortSubAccountList.bind(this)
        this.sortNicknameBillAccountSummary = this.sortNicknameBillAccountSummary.bind(this)
        this.sortServiceAddressAccountSummary = this.sortServiceAddressAccountSummary.bind(this)
        this.sortThenFilterBillAccountListAndSetState = this.sortThenFilterBillAccountListAndSetState.bind(this)
        this.sortServiceAddressBillAccount = this.sortServiceAddressBillAccount.bind(this)
        this.closeDeleteSummaryMasterDialog = this.closeDeleteSummaryMasterDialog.bind(this)
        this.getDeleteSummaryMasterButtons = this.getDeleteSummaryMasterButtons.bind(this)
        this.deleteBillAccountFromDialog = this.deleteBillAccountFromDialog.bind(this)
    }

    UNSAFE_componentWillUpdate(nextProps, nextState) {
        if (this.state.pageNumber > 1 && (this.state.pageNumber - 1) * this.state.pageSize + 1 > nextProps.billAccountList.length) {
            this.setState({ ...this.state, pageNumber: 1 })
            return
        }

        if (this.props.billAccountList !== nextProps.billAccountList) {
            this.sortThenFilterBillAccountListAndSetState(nextProps.billAccountList, nextProps.summaryBillAccounts, this.state.currentSortType)
            return
        }

        if (Object.keys(this.props.pendingDelete).length > 0 && Object.keys(nextProps.pendingDelete).length === 0) {
            if (nextProps.billAccountList.length === 0)
                return

            for (let index in nextProps.billAccountList) {
                let billAccountItem = nextProps.billAccountList[index]
                if (nextProps.selectedBillAccount === billAccountItem.account)
                    return
            }

            // The selected bill account is not in the list, change account to the first bill account in the list
            this.props.changeAccount(nextProps.billAccountList[0].account)
        }

        let willTextFilter = false
        let runFilterSort = false
        if ((this.state.isTextFiltering === true && nextState.isTextFiltering === false)) {
            willTextFilter = false
            runFilterSort = true
        }

        if (nextState.doTextFiltering === true) {
            runFilterSort = true
            willTextFilter = nextState.doTextFiltering
        }

        if (nextState.doSorting === true) {
            runFilterSort = true
            willTextFilter = this.state.doTextFiltering || this.state.isFiltered
        }

        if (runFilterSort === true) {
            this.sortThenFilterBillAccountListAndSetState(this.props.billAccountList, this.props.summaryBillAccounts, nextState.currentSortType, willTextFilter, this.state.filterText)
        }
    }

    spreadSummaryArray(summaryBillAccountList) {
        let spreadList = []

        summaryBillAccountList.forEach(summary => {
            let newSummary = {}
            newSummary.parentBillAccount = summary.parentBillAccount
            newSummary.subAccounts = summary.subAccounts.map(value => Object.assign({}, value))
            spreadList.push(newSummary)
        })

        return spreadList
    }

    accountToString(account) {
        if (account.accountType === billAccountConstants.WATER_ACCOUNT)
            return account.account + ' ' + account.accountName

        let billAccountString = String(account.account)
        billAccountString = billAccountString.padStart(9, '0')

        let billAccountDashed = billAccountString.replace(/^([0-9]{3})([0-9]{3})([0-9]{3})$/, '$1-$2-$3')

        let searchableString = billAccountString + ' ' + billAccountDashed + ' ' + account.accountName.toLowerCase() + ' ' + account.serviceAddress.fullStreetAddress.toLowerCase()
        return searchableString
    }

    subAccountToString(account) {

        let billAccountString = String(account.billAccount)
        billAccountString = billAccountString.padStart(9, '0')

        let billAccountDashed = billAccountString.replace(/^([0-9]{3})([0-9]{3})([0-9]{3})$/, '$1-$2-$3')

        return billAccountString + ' ' + billAccountDashed + ' ' + account.accountName.toLowerCase() + ' ' + account.serviceAddress.fullStreetAddress.toLowerCase()
    }

    detectMatch(filterArray, matchString) {
        let matched = true

        for (let i = 0; i < filterArray.length; i++) {
            let comparator = filterArray[i].trim().toLowerCase()
            if (comparator.length > 0)
                if (!matchString.includes(comparator)) {
                    matched = false
                    break
                }
        }

        return matched
    }

    filterAccounts(accountsToFilter, summaryAccountsToFilter, filterString) {
        let filteredAccounts = {
            accounts: [],
            subAccounts: []
        }

        if (!accountsToFilter)
            return filteredAccounts

        if (!filterString) {
            filteredAccounts.accounts = accountsToFilter
            filteredAccounts.subAccounts = summaryAccountsToFilter
            return filteredAccounts
        }

        let filters = filterString.split(' ')

        let filteredSubAccounts = []

        accountsToFilter = accountsToFilter.filter((account) => {
            let accountString = this.accountToString(account)

            let matched = false

            if (account.summaryBillingStatus === billAccountConstants.SUMMARY_BILLING_STATUS.PARENT) {
                let masterAccount = summaryAccountsToFilter.find(sb => sb.parentBillAccount === account.account)

                if (masterAccount.subAccounts) {
                    masterAccount.subAccounts = masterAccount.subAccounts.filter((subAccount) => {
                        let subAccountString = this.subAccountToString(subAccount)
                        return this.detectMatch(filters, subAccountString)
                    })

                    if (masterAccount.subAccounts.length > 0) {
                        filteredSubAccounts.push(masterAccount)
                        matched = true
                    }
                    else {
                        matched = false
                    }
                }
            }
            else {
                matched = this.detectMatch(filters, accountString)
            }

            return matched
        })

        filteredAccounts = {
            accounts: accountsToFilter,
            subAccounts: filteredSubAccounts
        }

        return filteredAccounts
    }

    handleFilterKeysUp(event) {
        if ((this.state.filterText.length < 3 && this.state.isFiltered === true)
            || this.state.filterText.length >= 3
            || event.key === ' '
            || event.key === 'Enter') {
            this.setState({ doTextFiltering: true })
            return
        }
    }

    onFilterTextChanged(event) {
        this.setState({ filterText: event.target.value })
    }

    toggleFiltering(isTextFiltering) {
        let keepFiltering = isTextFiltering && this.state.isFiltered
        this.setState({ isTextFiltering: isTextFiltering, isFiltered: keepFiltering })
    }

    sortServiceAddressBillAccount(accountA, accountB) {
        let comparison = 1

        if (accountA.serviceAddress && accountB.serviceAddress)
            comparison = accountA.serviceAddress.fullStreetAddress.localeCompare(accountB.serviceAddress.fullStreetAddress, 'en', { numeric: true, sensitivity: 'accent' })

        if (comparison === 0)
            comparison = accountA.account.toString().localeCompare(accountB.account.toString(), 'en', { numeric: true, sensitivity: 'accent' })

        return comparison
    }

    sortNicknameBillAccount(accountA, accountB) {
        let comparison = 1

        comparison = accountA.accountName.localeCompare(accountB.accountName, 'en', { numeric: true, sensitivity: 'accent' })

        if (comparison === 0)
            comparison = accountA.account.toString().localeCompare(accountB.account.toString(), 'en', { numeric: true, sensitivity: 'accent' })

        return comparison
    }

    setSortedBy(sortType) {
        if (this.state.currentSortType !== sortType) {
            this.setState({ currentSortType: sortType, doSorting: true })
        }
    }

    sortBillAccountList(sortableBillAccountList, sortType) {
        if (!sortableBillAccountList)
            return []

        switch (sortType) {

            case 'Nickname Asc':
                sortableBillAccountList = sortableBillAccountList.sort((a, b) => { return this.sortNicknameBillAccount(a, b) })
                break

            case 'Nickname Desc':
                sortableBillAccountList = sortableBillAccountList.sort((a, b) => { return this.sortNicknameBillAccount(a, b) })
                sortableBillAccountList = sortableBillAccountList.reverse()
                break

            case 'Account number Asc':
                sortableBillAccountList = sortableBillAccountList.sort((a, b) => { return a.account.toString().localeCompare(b.account.toString(), 'en', { numeric: true, sensitivity: 'accent' }) })
                break

            case 'Account number Desc':
                sortableBillAccountList = sortableBillAccountList.sort((a, b) => { return a.account.toString().localeCompare(b.account.toString(), 'en', { numeric: true, sensitivity: 'accent' }) })
                sortableBillAccountList = sortableBillAccountList.reverse()
                break

            case 'Service address Asc':
                sortableBillAccountList = sortableBillAccountList.sort((a, b) => { return this.sortServiceAddressBillAccount(a, b) })
                break

            case 'Service address Desc':
                sortableBillAccountList = sortableBillAccountList.sort((a, b) => { return this.sortServiceAddressBillAccount(a, b) })
                sortableBillAccountList = sortableBillAccountList.reverse()
                break
        }

        return sortableBillAccountList
    }

    sortSubAccountList(sortableSubAccountList, sortBy) {
        if (!sortableSubAccountList)
            return []

        sortableSubAccountList.forEach(masterAccount => {
            switch (sortBy) {
                case 'Nickname Asc':
                    masterAccount.subAccounts = masterAccount.subAccounts.sort((a, b) => { return this.sortNicknameBillAccountSummary(a, b) })
                    break

                case 'Nickname Desc':
                    masterAccount.subAccounts = masterAccount.subAccounts.sort((a, b) => { return this.sortNicknameBillAccountSummary(a, b) })
                    masterAccount.subAccounts = masterAccount.subAccounts.reverse()
                    break

                case 'Account number Asc':
                    masterAccount.subAccounts = masterAccount.subAccounts.sort((a, b) => { return a.billAccount.toString().localeCompare(b.billAccount.toString(), 'en', { numeric: true, sensitivity: 'accent' }) })
                    break

                case 'Account number Desc':
                    masterAccount.subAccounts = masterAccount.subAccounts.sort((a, b) => { return a.billAccount.toString().localeCompare(b.billAccount.toString(), 'en', { numeric: true, sensitivity: 'accent' }) })
                    masterAccount.subAccounts = masterAccount.subAccounts.reverse()
                    break

                case 'Service address Asc':
                    masterAccount.subAccounts = masterAccount.subAccounts.sort((a, b) => { return this.sortServiceAddressAccountSummary(a, b) })
                    break

                case 'Service address Desc':
                    masterAccount.subAccountseList = masterAccount.subAccounts.sort((a, b) => { return this.sortServiceAddressAccountSummary(a, b) })
                    masterAccount.subAccounts = masterAccount.subAccounts.reverse()
                    break
            }
        })

        return sortableSubAccountList
    }

    sortServiceAddressAccountSummary(accountA, accountB) {
        let comparison = 1

        comparison = accountA.serviceAddress.fullStreetAddress.localeCompare(accountB.serviceAddress.fullStreetAddress, 'en', { numeric: true, sensitivity: 'accent' })

        if (comparison === 0)
            comparison = accountA.billAccount.toString().localeCompare(accountB.billAccount.toString(), 'en', { numeric: true, sensitivity: 'accent' })

        return comparison
    }

    sortNicknameBillAccountSummary(accountA, accountB) {
        let comparison = 1

        let displayNameA = (accountA.accountName && accountA.accountName.length > 0) ? accountA.accountName : accountA.serviceAddress.fullStreetAddress
        let displayNameB = (accountB.accountName && accountB.accountName.length > 0) ? accountB.accountName : accountB.serviceAddress.fullStreetAddress

        comparison = displayNameA.localeCompare(displayNameB, 'en', { numeric: true, sensitivity: 'accent' })

        if (comparison === 0)
            comparison = accountA.billAccount.toString().localeCompare(accountB.billAccount.toString(), 'en', { numeric: true, sensitivity: 'accent' })

        return comparison
    }

    sortThenFilterBillAccountListAndSetState(billAccountList, summaryAccountList, sortType, doTextFiltration, filterString) {
        let sortedBillAccountList = this.sortBillAccountList([...billAccountList], sortType)
        let sortedSummaryList = this.sortSubAccountList(this.spreadSummaryArray(summaryAccountList), sortType)

        let wasTextFiltered = false
        if (doTextFiltration === true) {
            let filteredAccounts = this.filterAccounts(sortedBillAccountList, sortedSummaryList, filterString)

            sortedBillAccountList = filteredAccounts.accounts
            sortedSummaryList = filteredAccounts.subAccounts
            wasTextFiltered = true
        }

        this.setState({ adjustedAccountList: sortedBillAccountList, adjustedSummaryList: sortedSummaryList, currentSortType: sortType, doSorting: false, doTextFiltering: false, isFiltered: wasTextFiltered })
    }

    closeDeleteSummaryMasterDialog() {
        this.setState({ ...this.state, showDeleteSummaryMasterDialog: false })
    }

    getDeleteSummaryMasterButtons(billAccount, nickname) {
        let buttons = []
        buttons.push(<div key="1" className="btn srp-btn btn-lightblue ml-2" onClick={() => { this.deleteBillAccountFromDialog(billAccount, nickname) }}>Ok</div>)
        buttons.push(<div key="2" className="btn srp-btn btn-blue ml-2" onClick={() => { this.closeDeleteSummaryMasterDialog() }}>Cancel</div>)
        return buttons
    }

    changePageOnClick(nextPageNumber) {
        return () => {
            this.setState({ ...this.state, pageNumber: nextPageNumber })
        }
    }

    changePageSize(nextPageSize) {
        this.setState({ ...this.state, pageSize: nextPageSize, pageNumber: 1 })
    }

    async deleteBillAccountFromDialog(billAccount, nickname) {
        this.setState({ ...this.state, showDeleteSummaryMasterDialog: false })
        await this.props.deleteBillAccount(billAccount, nickname)
    }

    deleteBillAccountOnClick(billAccount, nickname, isSummaryBilling = false) {
        if (isSummaryBilling) {
            return () => {
                this.setState({ ...this.state, showDeleteSummaryMasterDialog: true, buttons: this.getDeleteSummaryMasterButtons(billAccount, nickname) })
            }
        } else {
            return async () => {
                if (this.props.billAccountList.filter(item => this.props.pendingDelete[`${item.account}`] !== true).length <= 1) {
                    this.props.displayLastBillAccountMessage()
                    return
                }

                this.setState({ ...this.state, showDeleteSummaryMasterDialog: false })
                this.props.deleteBillAccount(billAccount, nickname)
            }
        }
    }

    render() {
        let hasWaterAccounts = this.props.billAccountList.find(a => a.accountType === billAccountConstants.WATER_ACCOUNT) !== undefined

        let accountListHeader = (<span className="align-middle"
                                       style={{ marginRight: "0.2rem", fontSize: "24px", color: "#004B87" }}
                                >
                                    <strong>{this.props.t("My accounts")}</strong>
                                </span>)

        return (
            <div>
                <SrpModal
                    open={this.state.showDeleteSummaryMasterDialog}
                    title="Remove Summary Master Account"
                    content={<div>{this.props.t("Are you sure you want to delete this Summary Master? All added Sub accounts will be displayed without the Master.")}</div>}
                    closeClick={this.closeDeleteSummaryMasterDialog}
                    buttons={this.state.buttons}
                />
                {/* Desktop view */}
                <div className="d-none d-lg-block">
                    <div className="profile-account-container">
                        <div className="" style={{ zIndex: 1 }}>
                            <div className="row pt-2 pl-2 pr-2">
                                <div className="col text-nowrap">
                                    {accountListHeader}
                                </div>
                            </div>
                        </div>
                        <AddAccountOrLandlordContainer t={this.props.t} i18n={this.props.i18n} />
                        {hasWaterAccounts && <CardBlue
                            open={true}
                            message={this.props.t("Only associated power accounts displayed. To view associated water accounts, select a water account from the main account list.")}
                        />}
                    </div>
                    <AssociatedAccountRowsHeader
                        setSortBy={this.setSortedBy}
                        sortedBy={this.state.currentSortType}
                        filterText={this.state.filterText}
                        searchKeysUp={this.handleFilterKeysUp}
                        searchOnChange={this.onFilterTextChanged}
                        t={this.props.t}
                    />
                    <div className="container">
                        {
                            this.state.adjustedAccountList.slice((this.state.pageNumber - 1) * this.state.pageSize, this.state.pageNumber * this.state.pageSize)
                                .map((billAccountItem) => {
                                    if (billAccountItem.accountType === billAccountConstants.WATER_ACCOUNT)
                                        return null

                                    let disableEditAndDelete = this.props.pendingDelete[`${billAccountItem.account}`] === true

                                    if (billAccountItem.summaryBillingStatus === billAccountConstants.SUMMARY_BILLING_STATUS.PARENT) {
                                        return (
                                            <SummaryBillingRow
                                                key={billAccountItem.account}
                                                billAccountItem={billAccountItem}
                                                summaryBillAccount={this.state.adjustedSummaryList.find(sb => sb.parentBillAccount === billAccountItem.account)}
                                                hideDelete={this.props.billAccountList.length === 1}
                                                disableEditAndDelete={disableEditAndDelete}
                                                deleteBillAccountOnClick={this.deleteBillAccountOnClick}
                                                addAccountToProfile={this.props.addAccountToProfile}
                                                showAllSubAccounts={this.state.showAllSubAccounts}
                                                filterText={this.state.isFiltered ? this.state.filterText : null}
                                                pendingDelete={this.props.pendingDelete}
                                                t={this.props.t}
                                            />
                                        )
                                    } else {
                                        return (
                                            <AssociatedAccountRow
                                                key={billAccountItem.account}
                                                billAccountItem={billAccountItem}
                                                hideDelete={this.props.billAccountList.length === 1}
                                                disableEditAndDelete={disableEditAndDelete}
                                                deleteBillAccountOnClick={this.deleteBillAccountOnClick} 
                                                t={this.props.t}/>
                                        )
                                    }
                                })
                        }
                        <div className="container">
                            <AssociatedAccountRowsPagination
                                totalAccounts={this.state.adjustedAccountList.length}
                                pageSize={this.state.pageSize}
                                currentPage={this.state.pageNumber}
                                changeCurrentPage={this.changePageOnClick}
                                changePageSize={this.changePageSize} 
                                t={this.props.t}/>
                        </div>
                    </div>
                </div>
                {/* Responsive View */}
                <div className="d-block d-lg-none">
                    <div className="profile-account-container">
                        <div className="" style={{ zIndex: 1 }}>
                            <div className="row pt-2 pl-2 pr-2">
                                <div className="col text-nowrap">
                                    {accountListHeader}
                                </div>
                                {/* <div className="col-2">
                                    {displaySearch}
                                </div> */}
                            </div>
                        </div>
                        <AddAccountOrLandlordContainer t={this.props.t} i18n={this.props.i18n} />
                        {hasWaterAccounts && <CardBlue
                            open={true}
                            message={this.props.t("Only associated power accounts displayed. To view associated water accounts, select a water account from the main account list.")}
                        />}
                    </div>
                    <AssociatedAccountRowsHeaderResponsive
                        totalAccounts={this.props.billAccountList.length}
                        pageSize={this.state.pageSize}
                        currentPage={this.state.pageNumber}
                        changePageSize={this.changePageSize}
                        setSortBy={this.setSortedBy}
                        sortedBy={this.state.currentSortType}
                        filterText={this.state.filterText}
                        searchKeysUp={this.handleFilterKeysUp}
                        searchOnChange={this.onFilterTextChanged}
                        t={this.props.t}
                    />
                    <div className="container">
                        {
                            this.state.adjustedAccountList.slice((this.state.pageNumber - 1) * this.state.pageSize, this.state.pageNumber * this.state.pageSize)
                                .map((billAccountItem) => {
                                    if (billAccountItem.accountType === billAccountConstants.WATER_ACCOUNT)
                                        return null

                                    let disableEditAndDelete = this.props.pendingDelete[`${billAccountItem.account}`] === true

                                    if (billAccountItem.summaryBillingStatus === billAccountConstants.SUMMARY_BILLING_STATUS.PARENT) {
                                        return (
                                            <SummaryBillingRowResponsive
                                                key={billAccountItem.account}
                                                billAccountItem={billAccountItem}
                                                summaryBillAccount={this.state.adjustedSummaryList.find(sb => sb.parentBillAccount === billAccountItem.account)}
                                                hideDelete={this.props.billAccountList.length === 1}
                                                disableEditAndDelete={disableEditAndDelete}
                                                deleteBillAccountOnClick={this.deleteBillAccountOnClick}
                                                filterText={this.state.isFiltered ? this.state.filterText : null}
                                                addAccountToProfile={this.state.addAccountToProfile}
                                                pendingDelete={this.props.pendingDelete}
                                                t={this.props.t}
                                            />
                                        )
                                    } else {
                                        return (
                                            <AssociatedAccountRowResponsive
                                                key={billAccountItem.account}
                                                billAccountItem={billAccountItem}
                                                hideDelete={this.props.billAccountList.length === 1}
                                                disableEditAndDelete={disableEditAndDelete}
                                                deleteBillAccountOnClick={this.deleteBillAccountOnClick} 
                                                t={this.props.t}/>
                                        )
                                    }
                                })
                        }
                        <div className="container">
                            <AssociatedAccountRowsPaginationResponsive
                                totalAccounts={this.state.adjustedAccountList.length}
                                pageSize={this.state.pageSize}
                                currentPage={this.state.pageNumber}
                                changeCurrentPage={this.changePageOnClick} 
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        selectedBillAccount: state.accountInfo.billAccount.selectedBillAccount,
        billAccountList: state.accountInfo.billAccount.billAccountList,
        summaryBillAccounts: state.accountInfo.billAccount.summaryBillAccounts,
        pendingDelete: state.accountInfo.billAccount.pendingDelete
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeAccount: (billAccount) => {
            dispatch(selectBillAccountOnChange(billAccount))
        },
        deleteBillAccount: (billAccount, nickname) => {
            dispatch(deleteBillAccount(billAccount, nickname))
        },
        displayLastBillAccountMessage: () => {
            dispatch(addSnackbarMessage(<span>Can't remove only bill account</span>))
        },
        addAccountToProfile: async (billAccount, nickname) => {
            let accountsToAdd = []
            accountsToAdd.push({ billAccount: billAccount, nickname: nickname })

            await dispatch(addMultipleBillAccounts(accountsToAdd))
            await dispatch(getBillAccounts())

            dispatch(addSnackbarMessage(<span>Accounts added</span>))
        }
    }
}

AssociatedAccountRows.propTypes = {
    selectedBillAccount: PropTypes.number.isRequired,
    billAccountList: PropTypes.array.isRequired,
    summaryBillAccounts: PropTypes.array.isRequired,
    pendingDelete: PropTypes.object.isRequired,

    changeAccount: PropTypes.func.isRequired,
    deleteBillAccount: PropTypes.func.isRequired,
    displayLastBillAccountMessage: PropTypes.func.isRequired,
    addAccountToProfile: PropTypes.func.isRequired,

    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(AssociatedAccountRows)