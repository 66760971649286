import drawHorizontalBars from './draw-horizontal-bars'
import {dailyUsageChartTypeConstants} from '../../constants/daily-usage-chart-type-constants'
import * as d3 from 'd3'
import { DateTime } from 'luxon'
import {displaySizeConstants} from '../../constants/display-size-constants'
import {getDisplaySize} from '../../srp_modules/display-size'
import NetCompliantChartData from './net-compliant-chart-data'
import {convertHourlyDataForCharting} from '../../srp_modules/charting/hourly-data-dto'
const standardChartKeys = ["superOffPeak", "offsetSuperOff", "offPeak", "offsetOff", "shoulder", "offsetShoulder", "onPeak", "offsetOn", "total", "offsetTotal", "min", "offsetMin", "max"]
const margin = {
    top: 20,
    right: 20,
    bottom: 60,
    left: 130
}

export default function drawHorizontalChart(data,highDemandInfoList,usageChartType,hasOnOffShoulder, t){
    let isCost = usageChartType === dailyUsageChartTypeConstants.COST
    let isDemand = usageChartType === dailyUsageChartTypeConstants.DEMAND
    let isConsumption = usageChartType === dailyUsageChartTypeConstants.CONSUMPTION
    let isGeneration = usageChartType === dailyUsageChartTypeConstants.GENERATION
    let dtoData = convertHourlyDataForCharting(data, usageChartType)
    let netCompliantChartData = new NetCompliantChartData(dtoData,isCost,isDemand,isConsumption,isGeneration)
    let adjustedData = netCompliantChartData.adjustedData
    let maxValue = netCompliantChartData.maxY
    let minValue = netCompliantChartData.minY
    let legendDisplayStates = getLegendDisplayStates(adjustedData, isCost)
    let chart = d3.select("#usagePageChartContainer")
    d3.selectAll("#usagePageChart").remove()
    let x
    let y
    let barHeight = 20;
    let yOffset = 5;
    let displaySize = getDisplaySize(window.innerWidth)
    let chartSize = getChartSvgSize(displaySize, data.length, barHeight,margin)
    let  svg = chart
    .insert('svg','div')
    .attr('width', chartSize.width)
    .attr('height', chartSize.height)
    .attr('id', 'usagePageChart')
    .attr('class','viz-chart')

    let g = svg.append("g")
        .attr("transform", "translate(" + margin.left + "," + margin.top + ")")
    y = d3.scaleBand()
    .range([0, chartSize.height - margin.top - margin.bottom])
    .align(0.1)
    .padding(0.1)
    x = d3.scaleLinear()
        .rangeRound([0, chartSize.width - margin.left - margin.right])


    y.domain(adjustedData.map(function (d) {
        return d.date
    }))
    x.domain([minValue, maxValue])
    
    let initialTt = chart.select(".viz-tooltip-arrow-main")
    initialTt.remove()
    let tooltip = addTooltipToChart(chart)

    g.append("g").attr("id","usagePageChartMainGroup")
    .selectAll("g")
    .data(d3.stack().keys(standardChartKeys)(adjustedData))
    .enter().append("g")
    .attr("class", function (d,i) {
        return getCssClass(standardChartKeys[i],isGeneration) + " viz-bar"
    })
    .selectAll("path")
    .data(function (d) {
        return d
    })
    .enter().append("g")
    .classed("viz-on-off-shoulder", function (d) {
        return d.data.onPeak === 0 && hasOnOffShoulder && isDemand})
    .append("path")
    .on("mouseover", function (d) {
        handleMouseOver(d, this, tooltip, barHeight, yOffset, maxValue, x, y, isCost, isDemand, t)
    })
    .on("mouseout", function (d) { handleMouseOut(d, this, tooltip) })
    .attr("height", 0)
    .attr("d", function (d) {
        return drawHorizontalBars(d,data,x,y,minValue,maxValue,yOffset,barHeight,"date")
    })
    
    drawAxisTicks(g, isCost, x, y)
    drawLegend(legendDisplayStates, isGeneration)
}

function drawAxisTicks(g, isCost, x, y){
    if(isCost){ 
        g.append("g")
        .attr("class", "axis")
        .call(d3.axisTop(x).ticks(4, "s")
            .tickFormat(d3.format(["$", ""])))
    }
    else{ 
        g.append("g")
            .attr("class", "axis")
            .call(d3.axisTop(x).ticks(4, "s"))
    }
    g.append("g")
        .attr("class", "axis")
        .attr("transform", "translate(-10, 2)")
        .call(d3.axisLeft(y).tickValues(y.domain().filter(function(d,i){
            return !(i % 7)
        }))
        .tickFormat(function(x){return DateTime.fromISO(x).toFormat('MMM d h:mm a')})
        )
   
}

function getChartSvgSize(displaySize, numberOfBars, barHeight, margin){
    let chartHeight = numberOfBars * (barHeight + 10) + margin.top + margin.bottom
    switch(displaySize){
        case displaySizeConstants.MEDIUM:
        return {
                height: chartHeight, 
                width :640
            }
        case displaySizeConstants.SMALL:
            return {
                height: chartHeight, 
                width :480
            }
        case displaySizeConstants.EXTRA_SMALL:
            return {
                height: chartHeight, 
                width :400
            }
        case displaySizeConstants.MOBILE_LARGE:
            return {
                height: chartHeight, 
                width :340
            }
        case displaySizeConstants.MOBILE:
            return {
                height: chartHeight, 
                width :310
            }
        case displaySizeConstants.MOBILE_SMALL:
            return {
                height: chartHeight, 
                width :260
            }
        default:
            return {
                height: chartHeight, 
                width :640
            }
        }
   
}

function getCssClass(text,isGeneration) {
    if (text.includes("offset")) {
        return "viz-invisible"
    }
    return isGeneration 
        ? "viz-" + text + "-generation" 
        : "viz-" + text
}

function handleMouseOver(d, element, tooltip, barHeight, yOffset, 
    maxValue, x, y, isCost, isDemand, t) {
    $(element).addClass("current-hover")
    createTooltip(d, tooltip, barHeight, yOffset, maxValue, x, y, isCost, isDemand, t)
return true
}

function handleMouseOut(d, element, tooltip) {
    $(element).removeClass("current-hover")
    tooltip.style("display", "none")
}

function addTooltipToChart(chart) {
    let tooltip = chart
        .append("div")
        .attr("class", "viz-tooltip-arrow-main")
    let tooltipArrow =
        tooltip
            .append("div")
            .attr("class", "viz-tooltip-arrow")

    tooltipArrow.append("div")
        .attr("class", "viz-tooltip-main")
        .append("div")
        .attr("class", "viz-tooltip-inner")
        .attr("id", "viz-tooltipText")
    return tooltip
}

function getTootipArrowposition(xPosition,barMaxX){
    let relativeXPosition = xPosition/barMaxX
    if(relativeXPosition >= 0.8){
        return 80;
    }
    if(relativeXPosition <= 0.2){
        return 20
    }
    return relativeXPosition * 100
}

function createTooltip(d, tooltip, barHeight, 
    yOffset, maxValue, x, y,isCost, isDemand, t) {
    let yPosition = y(d.data.date) - (barHeight/2) + yOffset + margin.top
        
    let xPosition = margin.left + (x(maxValue) - x(d.data.max))
    
    tooltip
        .style("left", xPosition + "px")
        .style("top", yPosition + "px")
        .style("display", "block")
    let tooltipInner = tooltip.select(".viz-tooltip-inner")
    tooltipInner.selectAll("*").remove()
    tooltipInner.append("div").text(DateTime.fromISO(d.data.date).toFormat('EEE, MMM d'))
    isDemand 
       ? tooltipInner
            .append("div")
            .text(
                DateTime.fromISO(d.data.date)
                .toFormat('h:mm a') 
                + " - " + 
                DateTime.fromISO(d.data.date)
                .plus({ minutes: d.data.intervalTimeInMinutes })
                .toFormat('h:mm a')
            )
        : tooltipInner
            .append("div")
            .text(
                DateTime.fromISO(d.data.date)
                .toFormat('h:mm a')
                + " - " +
                DateTime.fromISO(d.data.date)
                .plus({ hours: 1 })
                .toFormat('h:mm a')
            )
    tooltipInner.style("right", getTootipArrowposition(xPosition,x(maxValue) + margin.left) + "%")
    if(isCost){
        if (d.data.showTotalCostTip) {
            tooltipInner.append("div")
                .attr("class", "viz-tooltip-text-line")
                .text(t("Cost") + ": " + (d.data.totalCost
                        + d.data.onPeakCost
                        + d.data.offPeakCost
                        + d.data.shoulderCost
                        + d.data.superOffPeakCost).toLocaleString('en-US', { style: "currency", currency: "USD" })
                    )
        }
    }
    else{
        if (d.data.showOffPeakTip) {
            tooltipInner.append("div")
                .attr("class", "viz-tooltip-text-line")
                .text(d.data.tooltipOffPeak)
        }
        if (d.data.showShoulderTip) {
            tooltipInner.append("div")
                .attr("class", "viz-tooltip-text-line")
                .text(d.data.tooltipShoulder)
        }
        if (d.data.showOnPeakTip) {
            tooltipInner.append("div")
                .attr("class", "viz-tooltip-text-line")
                .text(d.data.tooltipOnPeak)
        }
        if (d.data.showSuperOffPeakTip) {
            tooltipInner.append("div")
                .attr("class", "viz-tooltip-text-line")
                .text(d.data.tooltipSuperOffPeak)
        }
        if (d.data.showTotalTip) {
            tooltipInner.append("div")
                .attr("class", "viz-tooltip-text-line")
                .text(d.data.tooltipTotal)
        }
    }
   
}

function drawLegend(legendDisplayStates, isGeneration) {
    if (legendDisplayStates.hasSuperOff) {
        let item = d3.select('#superOffLegendItem')
            .attr("class", "usage-chart-legend-item-show")
            cssForLegnedItem(item, isGeneration)
    }
    if (legendDisplayStates.hasOffPeak) {
        let item =d3.select('#offPeakLegendItem')
            .attr('class', "usage-chart-legend-item-show")
            cssForLegnedItem(item, isGeneration)
    }
    if (legendDisplayStates.hasShoulder) {
        let item =d3.select('#shoulderLegendItem')
            .attr("class", "usage-chart-legend-item-show")
            cssForLegnedItem(item, isGeneration)
    }
    if (legendDisplayStates.hasOnPeak) {
        let item = d3.select('#onPeakLegendItem')
            .attr("class", "usage-chart-legend-item-show")
            cssForLegnedItem(item, isGeneration)
    }
    if (legendDisplayStates.hasTotal && legendDisplayStates.isCost) {
        d3.select('#totalLegendItemCost')
            .attr("class", "usage-chart-legend-item-show")
        d3.select('#totalLegendItem')
            .attr("class", "chart-legend-item-hidden")
    }
    if (legendDisplayStates.hasTotal && !legendDisplayStates.isCost) {
        let item = d3.select('#totalLegendItem')
            .attr("class", "usage-chart-legend-item-show")
            cssForLegnedItem(item, isGeneration)
    }
    if (!legendDisplayStates.isCost) {
        d3.select('#totalLegendItemCost')
            .attr("class", "chart-legend-item-hidden")
    }
}
function cssForLegnedItem(legendItem, isGeneration){
    legendItem.attr("class", "usage-chart-legend-item-show")
    if(isGeneration){
        legendItem.select(".usage-chart-legend-circle").classed("generation", true)
    }
    else{
        legendItem.select(".usage-chart-legend-circle").classed("generation", false)
    }
}
function getLegendDisplayStates(adjustedData,isCost){
    let hasOnPeak = false,
    hasSuperOff = false,
    hasOffPeak = false,
    hasShoulder = false,
    hasTotal = false

    let hasWeatherData = adjustedData !== undefined 
    adjustedData.forEach(function (element) {
        hasOnPeak = element.onPeak != 0 ? true : hasOnPeak
        hasOffPeak = element.offPeak != 0 ? true : hasOffPeak
        hasShoulder = element.shoulder != 0 ? true : hasShoulder
        hasSuperOff = element.superOffPeak != 0 ? true : hasSuperOff
        hasTotal = element.total != 0 ? true : hasTotal
    })
    return {hasOnPeak: hasOnPeak,
        hasSuperOff: hasSuperOff,
        hasOffPeak: hasOffPeak ,
        hasShoulder: hasShoulder,
        hasTotal: hasTotal,
        hasWeather: hasWeatherData,
        isCost: isCost
    }
}