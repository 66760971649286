import React from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

const AuthorizedPartyNotEligibleCard = ({ phone, fmttdPhone, t }) => {
    return (
        <div className="srp-card-body text-muted">
            <div className="srp-card-details">
                <p className="d-lg-block d-none mb-4">
                    {`${t("Ineligible message")} ${fmttdPhone}, ${t("available 24 hours a day, seven days a week")}.`}
                </p>
                <p className="d-lg-none d-block mb-4">
                    {`${t("Ineligible message")} `}
                    <a href={"tel:" + phone}>
                        {fmttdPhone}
                    </a>
                    {`, ${t("available_24_7")}.`}
                </p>

                <div className="d-flex flex-wrap justify-content-end">
                    <Link to="/myaccount/dashboard" className="btn srp-btn btn-lightblue ml-2 mb-2">{t("Go to dashboard")}</Link>
                </div>
            </div>
        </div>
    )
}

AuthorizedPartyNotEligibleCard.propTypes = {
    phone: PropTypes.string,
    fmttdPhone: PropTypes.string,
    t: PropTypes.func
}

export default withTranslation('authorizedParty')(AuthorizedPartyNotEligibleCard)