import React from 'react'
import PropTypes from 'prop-types'
import queryString from 'query-string'
import { connect } from 'react-redux'

import { completeCreateAccount } from '../../actions/auth/login/login-actions'
import { LOADING_STATUS_SUCCESS, LOADING_STATUS_FAILURE } from '../../constants/loading-status-constants'
import { withRouter } from '../../srp_modules/with-router'

import MobileVerificationPage from './mobile-verification-page'

function deriveMobileVerificationFromQueryString() {
    let mobileVerificationState = {
        completeCreateAccount: false,
        email: '',
        token: '',
        source: ''
    }

    let params = queryString.parse(location.search)

    if (params === undefined)
        return mobileVerificationState

    if (params.t !== undefined && params.e !== undefined && params.s !== undefined) {
        return {
            token: params.t,
            email: params.e,
            source: params.s,
            completeCreateAccount: true
        }
    }

    return mobileVerificationState
}
class MobileVerificationPageContainer extends React.Component {
    constructor(props) {
        super(props)

        let mobileVerificationState = deriveMobileVerificationFromQueryString()

        this.state = {
            mobileVerificationState
        }
    }

    componentDidMount() {
        if (!this.state.mobileVerificationState.completeCreateAccount || this.state.mobileVerificationState.source === 'w') {
            this.props.router.navigate({
                pathname: '/',
                search: this.props.router.location.search
            })
        } else {
            this.processAccountVerification()
        }
    }

    componentDidUpdate() {
        if (!this.state.mobileVerificationState.completeCreateAccount || this.state.mobileVerificationState.source === 'w') {
            this.props.router.navigate({
                pathname: '/',
                search: this.props.router.location.search
            })
        } else {
            this.processAccountVerification()
        }
    }

    processAccountVerification() {
        this.props.completeCreateAccount(this.state.mobileVerificationState.email, this.state.mobileVerificationState.token)
    }

    render() {
        let customerIsCompletingAccountCreation = this.props.completeCreateAccountStatus !== LOADING_STATUS_SUCCESS && this.props.completeCreateAccountStatus !== LOADING_STATUS_FAILURE

        return (<div>
            <div>
                {!customerIsCompletingAccountCreation ?
                    <MobileVerificationPage
                        completeCreateAccountStatus={this.props.completeCreateAccountStatus}
                        completeCreateAccountResult={this.props.completeCreateAccountResult}
                    /> : null
                }

            </div>
        </div>)
    }


}

const mapStateToProps = state => {
    return {
        ...state.login
    }
}

const mapDispatchToProps = dispatch => {
    return {
        completeCreateAccount: (e,t) => {
            dispatch(completeCreateAccount(e, t))
        }
    }
}

MobileVerificationPageContainer.propTypes = {
    completeCreateAccount: PropTypes.func.isRequired,
    completeCreateAccountStatus: PropTypes.string,
    completeCreateAccountResult: PropTypes.object,
    router: PropTypes.shape({
        location: PropTypes.object,
        navigate: PropTypes.func
    })
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MobileVerificationPageContainer))