import React from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'

import * as loadingConstants from '../../../constants/loading-status-constants'

import ConfirmPaymentDetailsCard from './confirm-payment-details-card'

import { setPaperlessBillingNotEnrolled, setPaperlessBillingEnrolling, setPaperlessBillingJustEnrolled, toggleEbill, refreshNotification } from '../../../actions/auth/notification/notification-actions'

import { submitSinglePayment, clearMakePaymentResult } from '../../../actions/auth/payment/payment-actions'
import { addSnackbarMessage } from '../../../actions/common/snackbar'
import { getEBillContactList } from '../../dashboard_page/bill/card-bill-container'
import { formCompleteEvent, formErrorEvent } from '../../../srp_modules/analytics-events'
import { paymentConstants } from '../../common_payment/payment-constants'
import { setFormStartEventEmitted } from '../../../actions/auth/payment/single-payment-actions'
import { withRouter } from '../../../srp_modules/with-router'

class ConfirmPaymentDetailsCardContainer extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            showPaperlessBillingDialog: false
        }

        this.turnOnPaperlessBillingOnClick = this.turnOnPaperlessBillingOnClick.bind(this)
        this.closePaperlessBillingDialogOnClick = this.closePaperlessBillingDialogOnClick.bind(this)
    }

    async turnOnPaperlessBillingOnClick() {
        let success = await this.props.turnOnPaperlessBilling(this.props.billAccount)
        if (success) {
            this.setState({showPaperlessBillingDialog: true})
        }
    }

    closePaperlessBillingDialogOnClick() {
        this.setState({showPaperlessBillingDialog: false})
    }

    render() {
        return (<ConfirmPaymentDetailsCard
            isSubmitting={this.props.isSubmitting}
            submitPayment={this.props.submitPayment}
            goBackToEditPaymentDetails={this.props.goBackToEditPaymentDetails}
            errorSubmittingPayment={this.props.errorSubmittingPayment}
            paperlessBillingState={this.props.paperlessBillingState}
            turnOnPaperlessBilling={this.turnOnPaperlessBillingOnClick}
            showPaperlessBillingDialog={this.state.showPaperlessBillingDialog}
            closePaperlessBillingDialogOnClick={this.closePaperlessBillingDialogOnClick}
            billAccount={this.props.billAccount}
            paymentInfo={this.props.paymentInfo}
            eBillEmailAddrPhoneNo={getEBillContactList(this.props.billingAlert, this.props.primaryEmail)}
            formStartEventEmitted={this.props.formStartEventEmitted}
            setFormStartEventEmitted={this.props.setFormStartEventEmitted}
            t={this.props.t}
            i18n={this.props.i18n}
        />)
    }
}

let displayError = (makePaymentResult, makePaymentResultStatus) => (
    (makePaymentResultStatus === loadingConstants.LOADING_STATUS_SUCCESS && !makePaymentResult.isSuccessful) ||
    (makePaymentResultStatus === loadingConstants.LOADING_STATUS_FAILURE)
)

const mapStateToProps = state => {
    const errorSubmittingPayment = displayError(state.accountInfo.payment.makePaymentResult, state.accountInfo.payment.makePaymentResultStatus)
    const isSubmitting = state.accountInfo.payment.makePaymentResultStatus === loadingConstants.LOADING_STATUS_IN_PROGRESS
    const paperlessBillingState = state.accountInfo.notification.paperlessBillingState
    const billingAlert = state.accountInfo.notification.allAlerts.billingAlert
    const primaryEmail = state.accountInfo.contact.primaryEmail
    const billAccount = +state.accountInfo.payment.makePaymentInfo.accountNumber

    const paymentInfo = {
        paymentAmount: +state.accountInfo.payment.makePaymentInfo.amount,
        paymentDate: state.accountInfo.payment.makePaymentInfo.paymentDate,
        shareAmount: +state.accountInfo.payment.makePaymentInfo.shareAmount,
    }

    const formStartEventEmitted = state.singlePaymentInfo.formStartEventEmitted

    return { isSubmitting, errorSubmittingPayment, paperlessBillingState, billingAlert, primaryEmail, billAccount, paymentInfo, formStartEventEmitted }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
    goBackToEditPaymentDetails: () => {
        dispatch(clearMakePaymentResult())
        ownProps.router.navigate(-1)
    },
    turnOnPaperlessBilling: async (billAccount) => {
        dispatch(setPaperlessBillingEnrolling())

        const isEnrolling = true
        const preferText = false
        let response = await dispatch(toggleEbill(isEnrolling, preferText))
        const success = !response.error && response.payload
        if (success === true) {
            dispatch(setPaperlessBillingJustEnrolled())
            dispatch(refreshNotification(billAccount))
        } else {
            dispatch(setPaperlessBillingNotEnrolled())
            dispatch(addSnackbarMessage(<span>{ownProps.t("Unable_enroll_paperless_billing")}</span>))
        }

        return success
    },
    submitPayment: async ({paymentAmount, paymentDate, shareAmount}) => {
        const formName = paymentConstants.ADOBE_ANALYTICS_FORM_NAME

        const response = await dispatch(submitSinglePayment())

        if (response.error === true)
            // TODO: handle network error -- dispatch(submitSinglePaymentFailure()) like gift payment page does?
            formErrorEvent(formName)
        else if (response.payload.isSuccess === true) {
            const meta = {paymentCount: 1, paymentTotal: paymentAmount, payments: [{paymentAmount, paymentDate}], shareAmount}
            formCompleteEvent(formName, meta)

            ownProps.router.navigate('/myaccount/payment/single/submitted', { replace: true })
        }
    },
    setFormStartEventEmitted: () => {
        dispatch(setFormStartEventEmitted())
    },
})

ConfirmPaymentDetailsCardContainer.propTypes = {
    turnOnPaperlessBilling: PropTypes.func.isRequired,
    billAccount: PropTypes.number.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
    submitPayment: PropTypes.func.isRequired,
    goBackToEditPaymentDetails: PropTypes.func.isRequired,
    errorSubmittingPayment: PropTypes.bool.isRequired,
    paperlessBillingState: PropTypes.string.isRequired,
    billingAlert:  PropTypes.object.isRequired,
    primaryEmail: PropTypes.string.isRequired,
    paymentInfo: PropTypes.shape({
        paymentAmount: PropTypes.number.isRequired,
        paymentDate: PropTypes.string.isRequired,
        shareAmount: PropTypes.number.isRequired,
    }).isRequired,
    formStartEventEmitted: PropTypes.bool.isRequired,
    setFormStartEventEmitted: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    i18n: PropTypes.object.isRequired
}

export default withTranslation("singlePayment")(withRouter(connect(mapStateToProps, mapDispatchToProps)(ConfirmPaymentDetailsCardContainer)))