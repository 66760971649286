import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { DateTime } from 'luxon'
import Divider from '@mui/material/Divider'
import Icon from '@mui/material/Icon'
import CircularProgress from '@mui/material/CircularProgress'
import SrpTooltip from '../../common_tooltip/srp-tooltip'

import { CardErrorJsx } from '../../common_error/card-error'

import { Trans } from 'react-i18next'

import * as loadingStatus from '../../../constants/loading-status-constants'
import { centralPrepayConstants, mpowerMeterStatus } from '../../../constants/central-prepay-constants'
import utils from '../../../srp_modules/utils'

import MPowerNegativeBalanceExplanation from '../mpower-negative-balance-explanation'

const MPowerBillCard = ({ mPowerAcctDetails, paymentInfo, mPowerSelfServiceAdvance, isCashOnly, authorizeTokenlessReconnect, authorizeTokenlessReconnectStatus, toggleSelfServiceAdvance, t, i18n }) => {
    const isSpanish = i18n.language === "es"
    const _lastReadDate = DateTime.fromISO(mPowerAcctDetails.lastEstimatedReadDate)
    const isMinDate = _lastReadDate.year===1 && _lastReadDate.month===1 && _lastReadDate.day===1
    let lastReadDateFormat = { hour: 'numeric', minute: '2-digit', hour12: true, month: 'short', day: 'numeric' }
    let lastReadDate = isMinDate ? "- -" : _lastReadDate.toLocaleString(lastReadDateFormat, { locale: isSpanish ? 'es-MX' : 'en-US' })

    const csPhoneNumber = isSpanish ? utils.getCSSpanishPhoneNumber() : utils.getCSPhoneNumber(false)
    const formattedCSPhoneNumber = isSpanish ? utils.getFormattedCSSpanishPhoneNumber() : utils.getFormattedCSPhoneNumber(false)

    const reallyAdvanceIneligible = !mPowerSelfServiceAdvance.isAdvanceEligible && mPowerSelfServiceAdvance.advanceEligibility !== 'NotEligibleOtherReason'

    const hideCentralPrepayDaysOfPower = mPowerAcctDetails.meterStatus===mpowerMeterStatus.TBD
        && (mPowerAcctDetails.centralPrepayStatus===centralPrepayConstants.PREPAY_CENTRAL
            || mPowerAcctDetails.centralPrepayStatus===centralPrepayConstants.IN_CONVERSION_TO_CENTRAL
            || mPowerAcctDetails.centralPrepayStatus===centralPrepayConstants.PREPAY_EDGE)
        

    const getAdvanceIneligibilityReason = (ineligibilityCode) => {
        let text = ""

        switch (ineligibilityCode) {
            case 'NotEligibleMonthlyAdvanceLimitReached':
                text = t("reached_the_maximum_number_of_advances")
                break
            case 'NotEligibleOtherReason':
                text = (
                    <Trans i18nKey="something_went_wrong_call_SRP" t={t}>
                    We’re sorry, something went wrong. Please call SRP Customer Service at <a href={"tel:" + csPhoneNumber}>{{phone: formattedCSPhoneNumber}}</a> for assistance.
                    </Trans>
                    )
                break
            default:
                text = (
                    <Trans i18nKey="not_eligible_for_an_advance" t={t}>
                    Your account is currently not eligible for an advance at this time. Please call SRP Customer Service at <a href={"tel:" + csPhoneNumber}>{{phone: formattedCSPhoneNumber}}</a> for assistance.
                    </Trans>
                    )
                break
        }

        return text
    }

    return (
        <div className="srp-card">
            <div className="srp-card-header">
                {t("My M-Power")}
            </div>
            <div className="srp-card-body">
                {mPowerAcctDetails.meterStatus==="CppDisconnected"
                ?<div className="srp-card-summary d-flex justify-content-around srp-gray">
                    <div className="d-flex flex-column align-items-center text-white">
                        <div className="h4">{t("Disconnected")}</div>
                        <span>&nbsp;</span>
                    </div>
                </div>
                :<div className={"srp-card-summary d-flex justify-content-around " + getMPowerTitleColor(mPowerAcctDetails.daysRemaining, paymentInfo.heldPaymentAmount, hideCentralPrepayDaysOfPower)}>
                    {paymentInfo.heldPaymentAmount > 0
                        ? <div className="d-flex flex-column align-items-center text-white">
                            <div className="h4">{t("Safety statement")}</div>
                            <span>{t("held_amount_waiting_for_your_meter", {heldAmount: formatDollarAmount(paymentInfo.heldPaymentAmount)})}</span>
                        </div>
                        : <div className="d-flex flex-column align-items-center text-white">
                            <div className="h4">{hideCentralPrepayDaysOfPower ? "- -" : mPowerAcctDetails.daysRemaining}</div>
                            <span>{t("days of power remaining")}</span>
                        </div>
                    }
                </div>
                }

                <div className="srp-card-details">
                    {isCashOnly && paymentInfo.heldPaymentAmount <= 0
                        ? <div className="mb-2">
                            <div className="d-lg-block d-none srp-alert-warn" style={{ fontSize: '14px' }}>
                                {t("purchases_cant_be_made_online_desktop", {phone: formattedCSPhoneNumber})}
                            </div>
                            <div className="d-block d-lg-none srp-alert-warn" style={{ fontSize: '14px' }}>
                                <Trans i18nKey="purchases_cant_be_made_online_mobile" t={t}>
                                We’re sorry, purchases can’t be made online for this bill account. Please call SRP Customer Service at <a href={"tel:" + csPhoneNumber}>{{phone: formattedCSPhoneNumber}}</a> if you have questions.
                                </Trans>
                            </div>
                        </div>
                        : null
                    }

                    {hideCentralPrepayDaysOfPower &&
                    <div className="srp-alert-warn mb-2" style={{ fontSize: '14px' }}>
                        {t("no_days_of_power_remaining_explanation")}
                    </div>
                    }

                    {reallyAdvanceIneligible &&
                    <div className="mb-2">
                    <CardErrorJsx
                        open={true}
                        jsxMessage={getAdvanceIneligibilityReason(mPowerSelfServiceAdvance.advanceEligibility)}
                    />
                    </div>
                    }

                    <div className="d-none d-lg-block">
                        <div className="d-flex flex-wrap justify-content-end mb-2">
                            <button type="button" disabled={reallyAdvanceIneligible} onClick={() => toggleSelfServiceAdvance(true)} className="btn srp-btn btn-lightblue ml-2 mb-2">{t("Request advance")}</button>
                            {isCashOnly ? null : <Link to="/myaccount/payment" className="btn srp-btn btn-blue ml-2 mb-2">{t("Purchase power")}</Link>}
                            {paymentInfo.heldPaymentAmount > 0
                            ? <div>
                                {authorizeTokenlessReconnectStatus === loadingStatus.LOADING_STATUS_SUCCESS
                                    ? null
                                    : <button
                                        className="btn srp-btn btn-green ml-2 mb-2"
                                        onClick={() => authorizeTokenlessReconnect(mPowerAcctDetails.billAccount)}>
                                        {authorizeTokenlessReconnectStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS
                                            ? <CircularProgress size={20} thickness={5} aria-label="sending credit to meter" alt="progress icon" />
                                            : t("Yes, send credit to meter")
                                        }
                                    </button>
                                }
                                </div>
                                : null
                            }
                        </div>

                    </div>

                    {paymentInfo.heldPaymentAmount > 0
                        ? <div className="mb-2">
                            <div className="srp-alert-warn" style={{ fontSize: '14px' }}>
                                {authorizeTokenlessReconnectStatus === loadingStatus.LOADING_STATUS_SUCCESS
                                    ? t("Power remaing balance may take several minutes to update.")
                                    : <div>
                                        <div className="mb-2">
                                            <Trans i18nKey="fire_risk_notice" t={t}>
                                            A purchase or credit of <span className="font-weight-bold">{{heldAmount: formatDollarAmount(paymentInfo.heldPaymentAmount)}}</span>
                                            is waiting to be applied to your meter. Please be aware of the risk of fire if any appliances or
                                            other electric devices, such as an electric stove, are turned on at the time power is delivered.
                                            Please ensure all of the appliances and electric devices at the
                                            premises are turned off.
                                            </Trans>
                                        </div>
                                        <div>{t("Is it safe to send credit to your meter at this time?")}</div>
                                    </div>
                                }
                            </div>
                        </div>
                        : null
                    }

                    <div className="text-muted mb-2" style={{ fontSize: '14px' }}>
                        {t("Balances_as_of", {hh_mm_day_month: lastReadDate})}
                    </div>
                    <div className="row d-flex align-items-center">
                        <div className="col-lg-8 col-md-6 col-8 text-muted align-middle">{t(mPowerAcctDetails.balanceRemaining < 0 ? "Accumulated debt" : "Power remaining")}</div>
                        <div className="col-lg-4 col-md-6 col-4 text-right">{formatDollarAmount(mPowerAcctDetails.balanceRemaining)}</div>
                    </div>

                    {mPowerAcctDetails.balanceRemaining < 0 &&
                    <MPowerNegativeBalanceExplanation initCollapsed={true} cssClass="my-2" i18n={i18n} t={t} />
                    }

                    {paymentInfo.amountDue > 0
                        ? <div>
                            <span className="d-lg-block d-lg-none mb-2" />
                            <div className="row d-flex align-items-center">
                                <div className="col-lg-8 col-md-6 col-8 text-muted d-flex align-items-center">
                                    <span className="mr-2">{t("Amount owed to SRP")}</span>
                                    <SrpTooltip content={<span>{t("paying_off_arrears_explanation")}</span>}>
                                        <Icon className="srp-icon-color mb-1" style={{ fontSize: '18px', verticalAlign: 'middle' }}>info_outline</Icon>
                                    </SrpTooltip>
                                </div>
                                <div className="col-lg-4 col-md-6 col-4 text-right">{formatDollarAmount(paymentInfo.amountDue)}</div>
                            </div>
                        </div>
                        : null}
                </div>

                <div className="d-block d-lg-none">
                    <Divider />
                    <div className="srp-card-details">
                        <div className="d-flex flex-wrap justify-content-end">
                            <button type="button" disabled={reallyAdvanceIneligible} onClick={() => toggleSelfServiceAdvance(true)} className="btn srp-btn btn-lightblue ml-2 mb-2">{t("Request advance")}</button>
                            {!isCashOnly && <Link to="/myaccount/payment" className={"btn srp-btn ml-2 mb-2 " + (paymentInfo.heldPaymentAmount > 0 ? "btn-lightblue" : "btn-blue")}>{t("Purchase power")}</Link>}
                            {paymentInfo.heldPaymentAmount > 0 && authorizeTokenlessReconnectStatus !== loadingStatus.LOADING_STATUS_SUCCESS &&
                                <button
                                    className="btn srp-btn btn-green ml-2 mb-2"
                                    onClick={() => authorizeTokenlessReconnect(mPowerAcctDetails.billAccount)}>
                                    {authorizeTokenlessReconnectStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS
                                        ? <CircularProgress size={20} thickness={5} aria-label="sending credit to meter" alt="progress icon" />
                                        : t("Yes, send credit to meter")
                                    }
                                </button>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

MPowerBillCard.propTypes = {
    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired,
    mPowerAcctDetails: PropTypes.shape({
        balanceRemaining: PropTypes.number.isRequired,
        billAccount: PropTypes.number.isRequired,
        daysRemaining: PropTypes.number.isRequired,
        lastEstimatedReadDate: PropTypes.string.isRequired,
        meterStatus: PropTypes.string.isRequired,
        centralPrepayStatus: PropTypes.string.isRequired,

    }).isRequired,
    paymentInfo: PropTypes.object,
    mPowerSelfServiceAdvance: PropTypes.shape({
        isAdvanceEligible: PropTypes.bool.isRequired,
        advanceEligibility: PropTypes.string.isRequired,
    }).isRequired,
    isCashOnly: PropTypes.bool,
    authorizeTokenlessReconnect: PropTypes.func,
    authorizeTokenlessReconnectStatus: PropTypes.string,
    toggleSelfServiceAdvance: PropTypes.func.isRequired
}

export default MPowerBillCard

function getMPowerTitleColor(daysRemaining, heldPaymentAmount, hideCentralPrepayDaysOfPower) {

    let titleColor = 'srp-mpower-green'

    if (heldPaymentAmount > 0) {
        titleColor = 'srp-mpower-orange'
    }
    else if (hideCentralPrepayDaysOfPower) {
        titleColor = 'srp-gray'
    }
    else {
        if (daysRemaining >= 7) {
            titleColor = 'srp-mpower-green'
        }
        else if (daysRemaining >= 3 && daysRemaining <= 6) {
            titleColor = 'srp-mpower-yellow'
        }
        else {
            titleColor = 'srp-mpower-red'
        }
    }

    return titleColor
}

function formatDollarAmount(number) {
    let formattedDollarAmount
    if (number < 0) {
        let posNum = number * (-1)
        formattedDollarAmount = "-$" + ((posNum).toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    }
    else if (number === 0) {
        formattedDollarAmount = "$0.00"
    }
    else {
        formattedDollarAmount = "$" + ((number).toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    }

    return formattedDollarAmount
}