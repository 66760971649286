import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import Button from '@mui/material/Button'
import Icon from '@mui/material/Icon'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'

import { Trans } from 'react-i18next'

import utils from '../../../srp_modules/utils'

import LoginPrefillFormBanner from '../../common/login-prefill-form-banner'
import BudgetBillingAccountDetails from './budget-billing-acct-details'
import BudgetBillingHomeDetails from './budget-billing-home-details'
import BudgetBillingAnonConfirmation from './budget-billing-anon-confirmation'

import CallAnytime from '../../myaccount_header/call-anytime'

import {
    setActiveStep,
    setAccountDetails,
    setSquareFootage,
    setElectricType,
    selectPool,
    selectEvapCooler,
    submitBudgetBilling
} from '../../../actions/auth/budget_billing/budget-billing-actions'
import { budgetBillingConstants } from '../../../constants/budget-billing-constants'
import { withRouter } from '../../../srp_modules/with-router'
import * as loadingStatus from '../../../constants/loading-status-constants'

class BudgetBillingAnonContainer extends React.Component {
    constructor(props) {
        super(props)

        this.createAndSubmitBudgetBillingForm = this.createAndSubmitBudgetBillingForm.bind(this)
    }

    createAndSubmitBudgetBillingForm(accountDetails, electricType, squareFoot, hasEvapCooler, hasPool) {
        let validElectricType = 2
        switch (electricType) {
            case "Y":
            case budgetBillingConstants.ELECTRIC_TYPE.ALL_ELECTRIC:
                validElectricType = 0
                break
            case "N":
            case budgetBillingConstants.ELECTRIC_TYPE.DUAL:
                validElectricType = 1
                break
            case budgetBillingConstants.ELECTRIC_TYPE.UNKNOWN:
            default:
                validElectricType = 2
        }

        let budgetBillingForm = {
            isResidential: true,
            accountNumber: accountDetails.billAccount,
            customerInfoModel: {
                firstName: accountDetails.firstName,
                middleName: accountDetails.middleInitial,
                lastName: accountDetails.lastName,
                emailAddress: accountDetails.email,
                phoneNumber: accountDetails.phone
            },
            serviceAddress: {
                streetAddress: accountDetails.serviceAddressLineOne,
                streetAddressLineTwo: accountDetails.serviceAddressLineTwo,
                state: "AZ",
                city: accountDetails.city ,
                zipCode: accountDetails.zipCode
            },
            language: "en", //language does not matter for budget billing
            electricType: validElectricType,
            sqaureFootage: squareFoot,
            hasEvaporatingCooling: hasEvapCooler,
            hasSwimmingPool: hasPool
        }

        this.props.submitBudgetBillingForm(budgetBillingForm)
    }

    render() {
        const t = this.props.t
        const i18n = this.props.i18n
    
        const isSpanish = (i18n.language === "es")
        const isCommercial = false
        const supportNumber = utils.GetCustomerServicePhoneNumber(isCommercial, isSpanish)
        const supportNumberFmt = utils.GetCustomerServicePhoneNumberFmt(isCommercial, isSpanish)

        if (this.props.submitBudgetBillingStatus === loadingStatus.LOADING_STATUS_INIT
            || this.props.submitBudgetBillingStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS) {

            return (
                <div>
                    <div className="d-block d-md-none">
                        <Button
                            variant="contained"
                            fullWidth
                            className="justify-content-start nav-button-responsive"
                            onClick={() => this.props.router.navigate(-1)}
                            disableRipple>
                            <Icon className="material-icons mr-2" style={{ color: 'rgb(33,108,224' }}>keyboard_backspace</Icon>
                            {t("Previous page")}
                        </Button>
                    </div>

                    <div className="container py-3">
                        <CallAnytime />
                        <h3 className="d-lg-block d-none mt-3 mb-4 pb-4">{t("Sign up for Budget Billing")}</h3>
                        <h3 className="d-lg-none d-block mt-3 mb-3">{t("Sign up for Budget Billing")}</h3>

                        <div className="d-none d-md-block">
                            <h5 className="d-flex justify-content-start align-items-center">
                                <button className="displayAsLink" onClick={() => this.props.router.navigate(-1)}>{t("Previous page")}</button>
                                <i className="material-icons" style={{ color: 'rgba(72, 72, 74, 0.376)' }} >keyboard_arrow_right</i>
                                <span style={{ color: '#707070' }}>{t("Budget Billing signup")}</span>
                            </h5>
                        </div>

                        <div className="row">
                            <div className="col-lg-6 col-12 mt-3">
                                <div className="srp-card-header">{t(budgetBillingConstants.BUDGET_BILLING_STEPS[this.props.activeStep].title)}</div>
                                <div className="srp-card-body">
                                    <div className="srp-card-details">
                                        <div className="d-lg-block d-none">
                                            {!this.props.isLoggedIn && this.props.activeStep === 0
                                                ? <LoginPrefillFormBanner />
                                                : null}
                                            <div className="col-8 p-0 mb-4">
                                                <Stepper activeStep={this.props.activeStep} className="p-0">
                                                    {budgetBillingConstants.BUDGET_BILLING_STEPS.map(item => {
                                                        return (
                                                            <Step key={item.step}>
                                                                <StepLabel>{t(item.label)}</StepLabel>
                                                            </Step>
                                                        )
                                                    })}
                                                </Stepper>
                                            </div>
                                        </div>
                                        <div className="d-lg-none d-block">
                                            {!this.props.isLoggedIn && this.props.activeStep === 0
                                                ? <LoginPrefillFormBanner />
                                                : null}
                                            <div className="p-0 mb-4">
                                                <Stepper activeStep={this.props.activeStep} alternativeLabel className="p-0">
                                                    {budgetBillingConstants.BUDGET_BILLING_STEPS.map(item => {
                                                        return (
                                                            <Step key={item.step}>
                                                                <StepLabel>{t(item.label)}</StepLabel>
                                                            </Step>
                                                        )
                                                    })}
                                                </Stepper>
                                            </div>
                                        </div>
                                        {this.props.activeStep === 0
                                            ? <BudgetBillingAccountDetails
                                                activeStep={this.props.activeStep}
                                                setActiveStepOnClick={this.props.setActiveStepOnClick}
                                                t={t} i18n={i18n}
                                                accountDetails={this.props.accountDetailsForAnonymous}
                                                setAccountDetailsOnSubmit={this.props.setAccountDetailsOnSubmit} />
                                            : <BudgetBillingHomeDetails
                                                activeStep={this.props.activeStep}
                                                setActiveStepOnClick={this.props.setActiveStepOnClick}
                                                accountDetails={this.props.accountDetailsForAnonymous}
                                                squareFootage={this.props.squareFootage}
                                                setSquareFootage={this.props.setSquareFootage}
                                                electricType={this.props.electricType}
                                                setElectricType={this.props.setElectricType}
                                                hasPool={this.props.hasPool}
                                                selectPoolOnClick={this.props.selectPoolOnClick}
                                                hasEvapCooler={this.props.hasEvapCooler}
                                                selectEvapCoolerOnClick={this.props.selectEvapCoolerOnClick}
                                                t={t} i18n={i18n}
                                                createAndSubmitBudgetBillingForm={this.createAndSubmitBudgetBillingForm}
                                                submitBudgetBillingStatus={this.props.submitBudgetBillingStatus} />
                                        }
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-6 col-12 mt-3">
                                <div className="srp-card-header">{t("Need help?")}</div>
                                <div className="srp-card-body" style={{ backgroundColor: '#f7dfd0' }}>
                                    <div className="srp-card-details">
                                        <p className="d-lg-block d-none mb-0">
                                            {t("Should_you_have_questions_residential_desktop", {supportNumber: supportNumberFmt})}
                                        </p>
                                        <p className="d-lg-none d-block mb-0">
                                            <Trans i18nKey="Should_you_have_questions_residential_mobile" t={t}>
                                                For questions please contact SRP Residential Customer Services at
                                                <a href={"tel:" + supportNumber}>
                                                    {{supportNumber: supportNumberFmt}}
                                                </a>
                                                , available to serve you 24 hours a day, seven days a week.
                                            </Trans>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            )
        }
        else {
            return (<BudgetBillingAnonConfirmation
                t={t} i18n={i18n}
                submitBudgetBillingStatus={this.props.submitBudgetBillingStatus}
                email={this.props.accountDetailsForAnonymous.email} />)
        }
    }
}

const mapStateToProps = state => {
    return { ...state.budgetBilling }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setActiveStepOnClick: (activeStep) => {
            dispatch(setActiveStep(activeStep))
        },
        setAccountDetailsOnSubmit: (accountDetails) => {
            dispatch(setAccountDetails(accountDetails))
        },
        setSquareFootage: (squareFootage) => {
            dispatch(setSquareFootage(squareFootage))
        },
        setElectricType: (electricType) => {
            dispatch(setElectricType(electricType))
        },
        selectPoolOnClick: (hasPool) => {
            dispatch(selectPool(hasPool))
        },
        selectEvapCoolerOnClick: (hasEvapCooler) => {
            dispatch(selectEvapCooler(hasEvapCooler))
        },
        submitBudgetBillingForm: (form) => {
            dispatch(submitBudgetBilling(form))
        }
    }
}

BudgetBillingAnonContainer.propTypes = {
    isLoggedIn: PropTypes.bool,
    router: PropTypes.shape({
        navigate: PropTypes.func
    }),

    activeStep: PropTypes.number,
    setActiveStepOnClick: PropTypes.func,

    accountDetailsForAnonymous: PropTypes.object,
    setAccountDetailsOnSubmit: PropTypes.func,

    squareFootage: PropTypes.string,
    setSquareFootage: PropTypes.func,

    electricType: PropTypes.string,
    setElectricType: PropTypes.func,

    hasPool: PropTypes.bool,
    selectPoolOnClick: PropTypes.func,

    hasEvapCooler: PropTypes.bool,
    selectEvapCoolerOnClick: PropTypes.func,

    submitBudgetBillingStatus: PropTypes.string,
    submitBudgetBillingForm: PropTypes.func,

    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired,
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BudgetBillingAnonContainer))