import React from 'react'
import { withTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

const FetchErrorCardContentPure = ({dataDescription, fetchAgainCallback, t}) => (
    <div>
        <div className="mb-1 text-center srp-100-red"><i className="material-icons srp-3x-font-size">error_outline</i></div>
        <div className="mb-1 text-center srp-100-red"><strong>{t("Something went wrong")}</strong></div>

        <div className="mb-4 text-center">{t("We_were_unable_to_retrieve", {dataDescription:dataDescription})}</div>

        <div className="d-flex justify-content-end">
            <button type="button" onClick={()=>fetchAgainCallback()} className="btn srp-btn btn-lightblue">{t("Try again")}</button>
        </div> 
    </div>
)

FetchErrorCardContentPure.propTypes = {
    dataDescription: PropTypes.string.isRequired,
    fetchAgainCallback: PropTypes.func.isRequired,

    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired
}

const FetchErrorCardContent = withTranslation("singlePurchasePage")(FetchErrorCardContentPure)
export { FetchErrorCardContent as default, FetchErrorCardContentPure }