export const myAccountConstants = {

    DISMISS_MESSAGE_SUCCESS: 'DISMISS_MESSAGE_SUCCESS',

    GET_DASHBOARD_MESSAGES_REQUEST: 'GET_DASHBOARD_MESSAGES_REQUEST',
    GET_DASHBOARD_MESSAGES_SUCCESS: 'GET_DASHBOARD_MESSAGES_SUCCESS',
    GET_DASHBOARD_MESSAGES_FAILURE: 'GET_DASHBOARD_MESSAGES_FAILURE',

    SAVE_PREFERRED_LANGUAGE: 'SAVE_PREFERRED_LANGUAGE',

    ALLOW_LOCALIZATION: 'ALLOW_LOCALIZATION',
    PREVENT_LOCALIZATION: 'PREVENT_LOCALIZATION',

    DO_NOT_INCLUDE_API_BASE_PATH: false,
    NO_PARAMETERS: {},
    MINIMUM_DATE: '0001-01-01T00:00:00',

    MAX_EXTENSION_TYPE: 2,

    GIFT_PAYMENT_BANK_CARD_TO_SHOW: {
        SELECT_BANK: 1,
        ADD_BANK: 2
    },

    RESIDENTIAL_CUSTOMER_SERVICE_PHONE_NUMBER: '6022368888',
    RESIDENTIAL_CUSTOMER_SERVICE_PHONE_NUMBER_FMTTD: '(602) 236-8888',
    RESIDENTIAL_CUSTOMER_SERVICE_SOLAR_PHONE_NUMBER: '6022364448',
    RESIDENTIAL_CUSTOMER_SERVICE_SOLAR_PHONE_NUMBER_FMTTD: '(602) 236-4448',
    COMMERCIAL_CUSTOMER_SERVICE_PHONE_NUMBER: '6022368833',
    COMMERCIAL_CUSTOMER_SERVICE_PHONE_NUMBER_FMTTD: '(602) 236-8833',
    SPANISH_CUSTOMER_SERVICE_PHONE_NUMBER: '6022361111',
    SPANISH_CUSTOMER_SERVICE_PHONE_NUMBER_FMTTD: '(602) 236-1111',

    MPOWER_TYPE: {
        NOTONMPOWER: 0,
        TOKEN: 1,
        TOKENLESS: 2
    },
    ERROR_LEVEL: {
        NONE: 0,
        WARNING: 1,
        ERROR: 2
    },

    ADD_SNACKBAR_MESSAGE: "ADD_SNACKBAR_MESSAGE",
    DISMISS_SNACKBAR_MESSAGE: "DISMISS_SNACKBAR_MESSAGE",
    SNACK_BAR_DURATION: 5000,
    SNACK_BAR_MULTI_MESSAGE_DELAY: 500,

    MAX_CONTACT_COUNT: 50,

    CUSTOMER_ACCOUNT_DATA_STATUS: {
        UNKNOWN: 0,
        SUCCESS: 1,
        FAILURE: 2,
        ACCOUNTNOTFOUND: 3,
        CONTACTNOTFOUND: 4,
        DUPLICATECONTACT: 5,
        MAXCONTACTLIMITREACHED: 6,
        INVALIDFORMAT: 7,
        MISSINGCONTACTS: 8,
        VALUEINVALID: 9,
        NOADDITIONALNOTIFICATIONPOINTS: 10,
        DUPLICATESUBSCRIPTION: 11,
        SUBSCRIPTIONDOESNOTEXIST: 12,
        INVALIDSUBSCRIPTIONTYPE: 13,
        INVALIDEMAILADDRESS: 14,
        NOCONTACT: 15,
        CONTACTMISSING: 16,
        MUSTINCLUDEEBILLFLAG: 17,
        MAXIMUMBILLACCOUNTSEXCEEDED: 18,
        INVALIDBUSINESSFUNCTION: 19,
        DATABASEERROR: 20,
        INVALIDCONTACTTYPE: 21,
        INVALIDENTITYTYPE: 22,
        BILLACCOUNTREQUIRED: 23,
        PHOENIXUNHANDLEDWORKERROR: 24,
        BILLPROJECTIONUNAVAILABLE: 25
    },

    ACTION_TYPE: {
        UNKNOWN: 0,
        ADD: 1,
        UPDATE: 2,
        DELETE: 3
    },

    SINGLE_DAY_HOURS: 24,

    TRANSACTION_SOURCE: {
        WEB: 1,
        PPD: 2,
        MAIL: 3,
        FAX: 4,
        RTO: 5
    }
}