export const multiPaymentNgpConstants = {
    VERIFY_MULTIPLE_NGP_REQUEST: 'VERIFY_MULTIPLE_NGP_REQUEST',
    VERIFY_MULTIPLE_NGP_SUCCESS: 'VERIFY_MULTIPLE_NGP_SUCCESS',
    VERIFY_MULTIPLE_NGP_FAILURE: 'VERIFY_MULTIPLE_NGP_FAILURE',

    SUBMIT_MULTIPLE_NGP_REQUEST: 'SUBMIT_MULTIPLE_NGP_REQUEST',
    SUBMIT_MULTIPLE_NGP_SUCCESS: 'SUBMIT_MULTIPLE_NGP_SUCCESS',
    SUBMIT_MULTIPLE_NGP_FAILURE: 'SUBMIT_MULTIPLE_NGP_FAILURE',

    SELECT_ALL_MPOWER_ACCOUNTS: 'SELECT_ALL_MPOWER_ACCOUNTS',
    SELECT_FIRST_100_MPOWER_ACCOUNTS: 'SELECT_FIRST_100_MPOWER_ACCOUNT',

    CLEAR_MULTIPLE_NGP_LIST: 'CLEAR_MULTIPLE_NGP_LIST',

    RESET_MULTIPLE_NGP_INFO: 'RESET_MULTIPLE_NGP_INFO',

    GET_SELECTED_BANK_ACCOUNT_MULTI_NGP: 'GET_SELECTED_BANK_ACCOUNT_MULTI_NGP',
    
    SELECT_MPOWER_ACCOUNT: 'SELECT_MPOWER_ACCOUNT',

    GET_MULTIPLE_PURCHASE_AMOUNT: 'GET_MULTIPLE_PURCHASE_AMOUNT',

    GET_PAY_AMOUNT_OWED_TO_SRP: 'GET_PAY_AMOUNT_OWED_TO_SRP',

    FILTER_ACCOUNT_TEXT_NGP: 'FILTER_ACCOUNT_TEXT_NGP',

    MAX_MPOWER_PAYMENT: 999.99,

    SET_FORM_START_EVENT_EMITTED: 'SET_MULTI_PURCHASE_FORM_START_EVENT_EMITTED',
    CLEAR_FORM_START_EVENT_EMITTED: 'CLEAR_MULTI_PURCHASE_FORM_START_EVENT_EMITTED',

    CLEAR_SUBMIT_PURCHASE_ERROR: 'CLEAR_SUBMIT_PURCHASE_ERROR',

    PAYMENT_AMOUNT_ERRORS: {
        NO_ERROR: 0,
        REQUIRED: 1,
        INVALID_AMOUNT: 2,
        PAYMENT_LIMIT: 3,
        LESS_THAN_TOTAL: 4,
        MORE_THAN_TOTAL: 5
    }
}