import React from 'react'
import { connect } from 'react-redux'

import format from '../../../../srp_modules/format'

import { addBankAccount, storeBankInfoCollected, setBankIdForGiftPayment, storePaymentList, verifyPaymentList, setBankFormOpen, clearBankFormOpen } from '../../../../actions/auth/payment/gift-payment-actions'
import { addSnackbarMessage, dismissSnackbarMessage } from '../../../../actions/common/snackbar'

import { getEnrolledProgramsDetails } from '../../../../actions/auth/payment/payment-actions'
import { getCustomerNames, getPhoneNumber } from '../../../../actions/auth/bill_account/bill-account-actions'

import MultiplePaymentInputCardWithRouter from './multiple-payment-input-card'

const mapStateToProps = state => {
    return {
        ...state.giftPaymentBankInfo, ...state.giftPaymentList, bankAccountList: state.bankAccounts.bankAccountList,
        selectedBillAccount: state.accountInfo.billAccount.selectedBillAccount,
        enrolledProgramsDetailsStatus: state.accountInfo.payment.enrolledProgramsDetailsStatus,
        customerNamesStatus: state.accountInfo.billAccount.customerNamesStatus,
        phoneNumberStatus: state.accountInfo.billAccount.phoneNumberStatus,
        isCashOnly: state.accountInfo.payment.isCashOnly
    }
}

const mapDispatchToProps = (dispatch) => (
    {
        storeBankInfo: (bankValues) => {
            dispatch(storeBankInfoCollected(bankValues))
        },
        storeSelectedBankId: (selectedBankId) => {
            dispatch(setBankIdForGiftPayment({selectedBankId}))
        },
        addBank: async (handleAddBankErrors, bankValues) => {
            let response = await dispatch(addBankAccount())
            if (response.error === true) {
                handleAddBankErrors({networkError: true}, bankValues)
                return false
            }

            if (response.payload.isSuccess === false) {
                const eChexAddBankAccountErrors = response.payload.saveBankAccountErrors
                handleAddBankErrors(eChexAddBankAccountErrors, bankValues)
                return false
            }

            const savedBankAccount = response.payload.savedBankAccount
            if (savedBankAccount.bankAccountId === 0) {
                handleAddBankErrors({unexpectedError: true}, bankValues)
                return false
            }

            dispatch(setBankIdForGiftPayment({selectedBankId: savedBankAccount.bankAccountId}))
            return true
        },
        syncPaymentList: (paymentList) => {
            dispatch(storePaymentList(paymentList))
        },
        validatePaymentList: async (paymentList, handleValidatePaymentListErrors, navigate) => {
            dispatch(dismissSnackbarMessage())
            dispatch(storePaymentList(paymentList))

            const response = await dispatch(verifyPaymentList())

            if (response.error === true) {
                handleValidatePaymentListErrors({networkError: true})
            } else {
                const referenceNumber = response.payload.referenceNumberWhenNoError
                const eChexValidationErrors = response.payload.eChexValidationErrors
                if (referenceNumber !== 0 && (Object.keys(eChexValidationErrors).length === 0 && eChexValidationErrors.constructor === Object))
                    navigate('/myaccount/payment/gift/confirmation')
                else
                    handleValidatePaymentListErrors(eChexValidationErrors)
            }
        },
        showUndoRemovePaymentSnackbar: (deletedPayment, addPaymentIfNotOnList, t) => {
            const deletedPaymentAmountDisplay = format.formatUsdAmount(deletedPayment.paymentAmount)
            dispatch(addSnackbarMessage(
                <span>{t("Removed_x_payment", { amount: deletedPaymentAmountDisplay }) }</span>,
                <button className="displayAsBlueText" onClick={() => {
                    dispatch(dismissSnackbarMessage())
                    if (addPaymentIfNotOnList(deletedPayment) === false)
                        dispatch(addSnackbarMessage(<span>{t("Cant_restore_deleted_payment", { deletedPayment: deletedPaymentAmountDisplay })}</span>))
            }}>{t("Undo")}</button>))
        },
        setBankFormOpen: () => {
            dispatch(setBankFormOpen())
        },
        clearBankFormOpen: () => {
            dispatch(clearBankFormOpen())
        },
        getEnrolledProgramsDetails: (billAccount) => dispatch(getEnrolledProgramsDetails(billAccount)),
        getCustomerName: (billAccount) => dispatch(getCustomerNames(billAccount)),
        getPhoneNumber: (billAccount) => dispatch(getPhoneNumber(billAccount)),
    }
)

const MultiplePaymentInputCardContainer = connect(mapStateToProps, mapDispatchToProps)(MultiplePaymentInputCardWithRouter)
export default MultiplePaymentInputCardContainer