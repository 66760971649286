import { singlePaymentConstants } from '../../../constants/single-payment/single-payment-constants'
import { billAccountConstants } from '../../../constants/bill-account-constants'

const initialState = {
    ignoreScheduledAndRecentPayments: false,
    formStartEventEmitted: false,
}

export default function singlePaymentReducer(state = initialState, action) {
    switch (action.type) {
        case singlePaymentConstants.IGNORE_SCHEDULED_AND_RECENT_PAYMENTS:
            return { ...state, ignoreScheduledAndRecentPayments: true }
        case billAccountConstants.SELECT_BILL_ACCOUNT_SUCCESS:
        case singlePaymentConstants.SHOW_SCHEDULED_AND_RECENT_PAYMENTS:
            return { ...state, ignoreScheduledAndRecentPayments: false }
        case singlePaymentConstants.SET_FORM_START_EVENT_EMITTED:
            return { ...state, formStartEventEmitted: true }
        case singlePaymentConstants.CLEAR_FORM_START_EVENT_EMITTED:
            return { ...state, formStartEventEmitted: false }
        default:
            return state
    }
}