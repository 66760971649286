import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Select from '@mui/material/Select'
import Chip from '@mui/material/Chip'
import CircularProgress from '@mui/material/CircularProgress'
import Icon from '@mui/material/Icon'
import '../../../../styles/dashboard-page.css'
import styled from '@emotion/styled'

import { notificationConstants } from '../../../../constants/notification-constants'
import alertsModule from '../../../../srp_modules/alerts-module'

let StyledChip = styled(Chip)`
    & .MuiChip-label {
        font-size: 1rem;
        color: #757575;
        font-family: futura-pt;
    }

    & .MuiIcon-root, &:hover .MuiIcon-root {
        color: #757575;
    }
`

class EmailAlert extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            addEmailAlertLoading: false,
            deleteEmailAlertLoading: false,
            chipToDelete: {},
            subscribedEmailContacts: alertsModule.normalizeEnrolledContacts(this.props.emailContacts, this.props.alert.email)
        }

        this.addEmailChip = this.addEmailChip.bind(this)
        this.deleteEmailChip = this.deleteEmailChip.bind(this)
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.alert.email !== this.props.alert.email || nextProps.contacts !== this.props.contacts) {
            this.setState({
                subscribedEmailContacts: alertsModule.normalizeEnrolledContacts(nextProps.emailContacts, nextProps.alert.email)
            })
        }
    }

    async addEmailChip(billAccount, notificationInfo, notificationData, contact) {
        this.setState({
            addEmailAlertLoading: true
        })

        if (notificationInfo.notificationType === notificationConstants.NOTIFICATION_TYPE.MPOWER_ALERT) {
            await this.props.addNotification(billAccount, notificationInfo, this.props.allAlerts.mPowerPurchaseAlert, contact)
        }
        else {
            await this.props.addNotification(billAccount, notificationInfo, notificationData, contact)
        }

        this.setState({
            addEmailAlertLoading: false
        })
    }

    async deleteEmailChip(billAccount, notificationInfo, notificationData, contact) {
        this.setState({
            deleteEmailAlertLoading: true,
            chipToDelete: contact
        })

        if (notificationInfo.notificationType === notificationConstants.NOTIFICATION_TYPE.MPOWER_ALERT) {
            await this.props.deleteNotification(billAccount, notificationInfo, this.props.allAlerts.mPowerPurchaseAlert, contact)
        }
        else {
            await this.props.deleteNotification(billAccount, notificationInfo, notificationData, contact)
        }

        this.setState({
            deleteEmailAlertLoading: false
        })
    }

    render() {
        return (
            <div>
                <div className="d-lg-block d-none">
                    <div className="text-muted pl-3">{this.props.t("Email addresses subscribed")}</div>
                    <div className="d-flex flex-wrap pl-3">
                        <span className="mr-2">
                            {this.state.subscribedEmailContacts.map((chip) => (<StyledChip
                                key={chip.contactAddress}
                                onDelete={() => this.deleteEmailChip(this.props.selectedBillAccount, this.props.alert.notificationInfo, this.props.notificationData, chip)}
                                deleteIcon={this.state.deleteEmailAlertLoading && this.state.chipToDelete === chip
                                    ? <CircularProgress size={20} thickness={5} />
                                    : <Icon>cancel</Icon>}
                                sx={{ marginTop: 0.5, marginBottom: 0.5, marginRight: 0.5, backgroundColor:'#E0E7F0' }}
                                label={alertsModule.getContactLabel(chip)}
                            />
                            ))}
                        </span>
                        <span>
                            <Select
                                value={0}
                                onChange={event => this.addEmailChip(this.props.selectedBillAccount, this.props.alert.notificationInfo, this.props.notificationData, event.target.value)}
                                className="d-flex align-bottom"
                                disabled={this.state.addEmailAlertLoading}
                                renderValue={()=>this.state.addEmailAlertLoading ? <CircularProgress size={20} thickness={5} /> : this.props.t("Add email")}
                            >
                                {alertsModule.getContactMenuItems(this.props.emailContacts, this.state.subscribedEmailContacts)}
                            </Select>
                        </span>
                    </div>
                </div>
                <div className="d-lg-none d-block">
                    <div className="text-muted">{this.props.t("Email addresses enrolled")}</div>
                    <div className="d-flex flex-wrap mb-2">
                        {this.state.subscribedEmailContacts.map((chip) => (<StyledChip
                            key={chip.contactAddress}
                            onDelete={() => this.deleteEmailChip(this.props.selectedBillAccount, this.props.alert.notificationInfo, this.props.notificationData, chip)}
                            deleteIcon={this.state.deleteEmailAlertLoading && this.state.chipToDelete === chip
                                ? <CircularProgress size={20} thickness={5} />
                                : <Icon>cancel</Icon>}
                            sx={{ marginTop: 0.5, marginBottom: 0.5, marginRight: 0.5, backgroundColor:'#E0E7F0' }}
                            label={alertsModule.getContactLabel(chip)}
                        />
                        ))}
                    </div>
                    <div>
                        <Select
                            value={0}
                            onChange={event => this.addEmailChip(this.props.selectedBillAccount, this.props.alert.notificationInfo, this.props.notificationData, event.target.value)}
                            disabled={this.state.addEmailAlertLoading}
                            renderValue={()=>this.state.addEmailAlertLoading ? <CircularProgress size={20} thickness={5} /> : this.props.t("Add email")}
                        >
                            {alertsModule.getContactMenuItems(this.props.emailContacts, this.state.subscribedEmailContacts)}
                        </Select>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return { ...state.accountInfo.billAccount, ...state.accountInfo.notification, ...state.accountInfo.contact }
}

EmailAlert.propTypes = {
    selectedBillAccount: PropTypes.number,
    emailContacts: PropTypes.arrayOf(PropTypes.object),
    allAlerts: PropTypes.object,
    alert: PropTypes.object,
    notificationData: PropTypes.string,
    contacts: PropTypes.arrayOf(PropTypes.object),

    addNotification: PropTypes.func,
    deleteNotification: PropTypes.func,
    t: PropTypes.func
}

export default connect(mapStateToProps)(EmailAlert)