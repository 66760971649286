import React from 'react'
import PropTypes from 'prop-types'
import TextField from '@mui/material/TextField'
import { PatternFormat } from 'react-number-format'

const SRPAccountNumberField = ({t, ...props}) => {

    let isError = props.touched['srpAccountNumber']
        && Object.prototype.hasOwnProperty.call(props.errors, 'srpAccountNumber')
    let errMsg = isError ? props.errors['srpAccountNumber'] : ''

    const getLocalizedErrMsg = errMsg.getLocalizedErrMsg
    if (typeof getLocalizedErrMsg === 'function')
        errMsg = getLocalizedErrMsg()
    else if (errMsg !== "")
        errMsg = t(errMsg)

    let meta = {
        id: 'srpAccountNumber',
        name: 'srpAccountNumber',
        label: t("SRP account number"),
        helperText: errMsg,
        error: isError
    }

    return (
        <PatternFormat
            {...meta}
            {...props}
            valueIsNumericString={false}
            format="###-###-###"
            type="tel"
            customInput={TextField}
        />
    )
}

SRPAccountNumberField.propTypes = {
    touched: PropTypes.shape({
        srpAccountNumber: PropTypes.bool
    }).isRequired,
    errors: PropTypes.shape({
        srpAccountNumber: PropTypes.oneOfType([
            PropTypes.string.isRequired,
            PropTypes.shape({
                getLocalizedErrMsg: PropTypes.func.isRequired,
            }),
        ]),
    }).isRequired,
    t: PropTypes.func.isRequired
}

export default SRPAccountNumberField