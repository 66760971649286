import React from 'react'
import utils from '../../../srp_modules/utils'
import PropTypes from 'prop-types'

const DocumentFailure = ({t}) => {
    return(
        <div className="mt-4">
                <div className="srp-alert-error" style={{marginTop:'-20px', marginBottom: '20px'}}>
                    <strong>{t("FAILURE")}</strong> {t("cannot_complete", {phoneNumber: utils.getFormattedCSPhoneNumber(false)})}
                </div>
        </div>
    )
}

DocumentFailure.propTypes = {
    t: PropTypes.func
}

export default DocumentFailure