import * as React from 'react'
import PropTypes from 'prop-types'
import CircularProgress from '@mui/material/CircularProgress'
import { LOADING_STATUS_SUCCESS } from '../../../constants/loading-status-constants'

const ServiceAddress = ({serviceAddress, serviceAddressStatus, myAccountType, t}) => {
    if (myAccountType === 'water' || serviceAddress === null)
        return null

    if (serviceAddressStatus !== LOADING_STATUS_SUCCESS)
        return (
            <div>
                <label className="mb-0 font-weight-bold">{t("Service address")}</label>
                <div><CircularProgress size={20} thickness={5} /></div>
            </div>
        )

    return (
        <div>
            <label className="mb-0 font-weight-bold">{t("Service address")}</label>
            {serviceAddress.streetAddress !== "" && serviceAddress.streetAddress !== "0"
                ? <p className="form-control-static pt-0 text-muted">{serviceAddress.streetAddress}<br/>{serviceAddress.cityStateZip}</p>
                : <p className="form-control-static pt-0 text-muted">{serviceAddress.cityStateZip}</p>
            }
            
        </div>
    )
}

ServiceAddress.propTypes = {
    serviceAddress: PropTypes.object.isRequired,
    serviceAddressStatus: PropTypes.string,
    myAccountType: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired
}

export default ServiceAddress