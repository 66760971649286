import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import ProfileMenu from './profile-menu'
import ProfileAndSettingsPage from './profile-and-settings-page'
import MyAccountMainContainer from '../../containers/my-account-main-container'

const ProfileAndSettingsPageContainer = (props) => {
    return(
        <MyAccountMainContainer pageTitle="My profile & settings">
            <ProfileMenu />
            {
                <ProfileAndSettingsPage isPendingTurnOn={props.isPendingTurnOn} />
            }
        </MyAccountMainContainer>
    )
}

const mapStateToProps = state => {
    return {
        isPendingTurnOn: state.accountInfo.billAccount.selectedBillAccountDetails.isPendingTurnOn
     }
}

ProfileAndSettingsPageContainer.propTypes = {
    isPendingTurnOn: PropTypes.bool.isRequired
}

export default connect(mapStateToProps)(ProfileAndSettingsPageContainer)