import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Formik } from 'formik'

import { withTranslation } from 'react-i18next'

import CircularProgress from '@mui/material/CircularProgress'
import Button from '@mui/material/Button'
import Icon from '@mui/material/Icon'

import AccountInfoBarContainer from '../myaccount_header/info_bar/account-info-bar-container'
import AnalyticsFormTracking from '../common_formik/analytics-form-tracking'
import CallAnytime from '../myaccount_header/call-anytime'
import ReportOutagePage from './report-outage-page'
import UnableToReportOutage from './unable-to-report-outage'
import * as loadingStatus from '../../constants/loading-status-constants'
import { contactConstants } from '../../constants/contact-constants'
import { rateMetaDataConstants } from '../../constants/rate-meta-data-constants'

import FetchErrorCardContent from './fetch-error-card-content'

import {
    togglePowerRestoredCallback, submitReportOutageForm, getMedicalSupportOption,
    getIsIneligibleToReport, getLastReportedOutage
} from '../../actions/auth/outage/report-outage-actions'
import { getIsInOutageArea, getOutageHistory } from '../../actions/auth/outage/outage-actions'
import { getNotifications, refreshNotification, addNotification } from '../../actions/auth/notification/notification-actions'
import { getContacts, refreshContacts, addContacts } from '../../actions/auth/contact/contact-actions'
import { getPhoneNumber } from '../../actions/auth/bill_account/bill-account-actions'
import { getRateMetaData } from '../../actions/auth/usage/rate-meta-data-actions'
import { addSnackbarMessage } from '../../actions/common/snackbar'
import outageRestoration from '../../srp_modules/outage-restoration'
import { withRouter } from '../../srp_modules/with-router'

const OUTAGE_NOTIFICATION = 41

let style = {
    marginTop: "10%",
    marginBottom: "20%"
}

class ReportOutagePageContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showAffectedAddressModal: false,
            outageSubscriptionEmailError: false,
            outageSubscriptionPhoneError: false,
            newSubscriptionEmailDialog: { display: 'none' },
            newSubscriptionPhoneDialog: { display: 'none' },
            outageNotificationToAdd: {},
            callbackError: false,
            callbackErrorMessage: "",
            breakerCheck: false,
            generalError: false,
            initialChangedState: false,
        }
        this.loadDataIfNeeded = this.loadDataIfNeeded.bind(this)
        this.tryAgainToLoadData = this.tryAgainToLoadData.bind(this)
        this.closeAffectedAddressModalOnClick = this.closeAffectedAddressModalOnClick.bind(this)
        this.openAffectedAddressModalOnClick = this.openAffectedAddressModalOnClick.bind(this)
        this.validateCallbackPhone = this.validateCallbackPhone.bind(this)
        this.validateOutageSubscriptionEmail = this.validateOutageSubscriptionEmail.bind(this)
        this.validateOutageSubscriptionPhone = this.validateOutageSubscriptionPhone.bind(this)
        this.setGeneralError = this.setGeneralError.bind(this)
        this.onStateChanged = this.onStateChanged.bind(this)
    }

    componentDidMount() {
        this.loadDataIfNeeded()
    }

    componentDidUpdate(prevProps) {
        if (prevProps.selectedBillAccount === this.props.selectedBillAccount)
            return

        if (this.props.notificationsStatus === loadingStatus.LOADING_STATUS_INIT) {
            this.setState({ //eslint-disable-line react/no-did-update-set-state
                showAffectedAddressModal: false,
                outageSubscriptionEmailError: false,
                outageSubscriptionPhoneError: false,
                newSubscriptionEmailDialog: { display: 'none' },
                newSubscriptionPhoneDialog: { display: 'none' },
                outageNotificationToAdd: {}
            })
        }

        this.loadDataIfNeeded()
    }

    loadDataIfNeeded() {
        if (this.props.selectedBillAccount === 0)
            return

        if (this.props.notificationsStatus === loadingStatus.LOADING_STATUS_INIT) {
            this.props.getOutageNotifications(this.props.selectedBillAccount)
        }

        if (this.props.hasMedicalSupportOptionStatus === loadingStatus.LOADING_STATUS_INIT) {
            this.props.getMedicalSupportOptionInfo(this.props.selectedBillAccount)
        }

        if (this.props.isIneligibleToReportStatus === loadingStatus.LOADING_STATUS_INIT) {
            this.props.getIsIneligibleToReportInfo(this.props.selectedBillAccount)
        }

        if (this.props.contactsStatus === loadingStatus.LOADING_STATUS_INIT) {
            this.props.getContacts(this.props.selectedBillAccount)
        }

        if (this.props.phoneNumberStatus === loadingStatus.LOADING_STATUS_INIT) {
            this.props.getBestPhone(this.props.selectedBillAccount)
        }

        if (this.props.rateMetaDataStatus === loadingStatus.LOADING_STATUS_INIT) {
            this.props.getRateMetaDataInfo(this.props.selectedBillAccount)
        }

        if (this.props.lastReportedOutageStatus === loadingStatus.LOADING_STATUS_INIT) {
            this.props.getLastReportedOutageInfo(this.props.selectedBillAccount)
        }

        if (this.props.customerInOutageInfoStatus === loadingStatus.LOADING_STATUS_INIT) {
            this.props.getCustomerInOutageAreaInfo(this.props.selectedBillAccount)
        }

        if (this.props.outageHistoryStatus === loadingStatus.LOADING_STATUS_INIT) {
            this.props.getOutageHistoryInfo(this.props.selectedBillAccount)
        }

        if (this.props.selectedBillAccountDetails.isCommercial) {
            this.props.togglePowerRestoredCallbackOnClick(true)
        }
        else {
            this.props.togglePowerRestoredCallbackOnClick(false)
        }
    }

    tryAgainToLoadData() {
        if (this.props.selectedBillAccount === 0)
            return

        if (this.props.notificationsStatus === loadingStatus.LOADING_STATUS_FAILURE) {
            this.props.getOutageNotifications(this.props.selectedBillAccount)
        }

        if (this.props.hasMedicalSupportOptionStatus === loadingStatus.LOADING_STATUS_FAILURE) {
            this.props.getMedicalSupportOptionInfo(this.props.selectedBillAccount)
        }

        if (this.props.isIneligibleToReportStatus === loadingStatus.LOADING_STATUS_FAILURE) {
            this.props.getIsIneligibleToReportInfo(this.props.selectedBillAccount)
        }

        if (this.props.contactsStatus === loadingStatus.LOADING_STATUS_FAILURE) {
            this.props.getContacts(this.props.selectedBillAccount)
        }

        if (this.props.phoneNumberStatus === loadingStatus.LOADING_STATUS_FAILURE) {
            this.props.getBestPhone(this.props.selectedBillAccount)
        }

        if (this.props.rateMetaDataStatus === loadingStatus.LOADING_STATUS_FAILURE) {
            this.props.getRateMetaDataInfo(this.props.selectedBillAccount)
        }

        if (this.props.lastReportedOutageStatus === loadingStatus.LOADING_STATUS_FAILURE) {
            this.props.getLastReportedOutageInfo(this.props.selectedBillAccount)
        }

        if (this.props.customerInOutageInfoStatus === loadingStatus.LOADING_STATUS_FAILURE) {
            this.props.getCustomerInOutageAreaInfo(this.props.selectedBillAccount)
        }

        if (this.props.outageHistoryStatus === loadingStatus.LOADING_STATUS_FAILURE) {
            this.props.getOutageHistoryInfo(this.props.selectedBillAccount)
        }
    }

    closeAffectedAddressModalOnClick() {
        this.setState({
            showAffectedAddressModal: false
        })
    }

    openAffectedAddressModalOnClick() {
        this.setState({
            showAffectedAddressModal: true
        })
    }

    validateCallbackPhone(error, message) {
        this.setState({
            callbackError: error,
            callbackErrorMessage: message
        })
    }

    validateOutageSubscriptionEmail(error) {
        if (error) {
            this.setState({
                outageSubscriptionEmailError: true
            })
        }
        else {
            this.setState({
                outageSubscriptionEmailError: false
            })
        }
    }

    validateOutageSubscriptionPhone(error) {
        if (error) {
            this.setState({
                outageSubscriptionPhoneError: true
            })
        }
        else {
            this.setState({
                outageSubscriptionPhoneError: false
            })
        }
    }

    setGeneralError(value) {
        this.setState({
            generalError: value
        })
    }

    onStateChanged() {
        this.setState({
            initialChangedState: true
        })
    }

    getCardToRender() {
        const t = this.props.t
        const i18n = this.props.i18n

        if (this.props.notificationsStatus === loadingStatus.LOADING_STATUS_INIT || this.props.notificationsStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS
            || this.props.hasMedicalSupportOptionStatus === loadingStatus.LOADING_STATUS_INIT || this.props.hasMedicalSupportOptionStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS
            || this.props.isIneligibleToReportStatus === loadingStatus.LOADING_STATUS_INIT || this.props.isIneligibleToReportStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS
            || this.props.billAccountListStatus === loadingStatus.LOADING_STATUS_INIT || this.props.billAccountListStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS
            || this.props.phoneNumberStatus === loadingStatus.LOADING_STATUS_INIT || this.props.phoneNumberStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS
            || this.props.rateMetaDataStatus === loadingStatus.LOADING_STATUS_INIT || this.props.rateMetaDataStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS
            || this.props.lastReportedOutageStatus === loadingStatus.LOADING_STATUS_INIT || this.props.lastReportedOutageStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS
            || this.props.customerInOutageInfoStatus === loadingStatus.LOADING_STATUS_INIT || this.props.customerInOutageInfoStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS
            || this.props.outageHistoryStatus === loadingStatus.LOADING_STATUS_INIT || this.props.outageHistoryStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS) {
            return <div className="d-flex justify-content-center"><CircularProgress size={80} thickness={5} style={style} /></div>
        }

        const allDataLoaded = (this.props.notificationsStatus === loadingStatus.LOADING_STATUS_SUCCESS && this.props.hasMedicalSupportOptionStatus === loadingStatus.LOADING_STATUS_SUCCESS
            && this.props.isIneligibleToReportStatus === loadingStatus.LOADING_STATUS_SUCCESS && this.props.billAccountListStatus === loadingStatus.LOADING_STATUS_SUCCESS
            && this.props.rateMetaDataStatus === loadingStatus.LOADING_STATUS_SUCCESS && this.props.customerInOutageInfoStatus === loadingStatus.LOADING_STATUS_SUCCESS
            && this.props.outageHistoryStatus === loadingStatus.LOADING_STATUS_SUCCESS)
        if (!allDataLoaded) {
            return <FetchErrorCardContent dataDescription={t("report outage")} fetchAgainCallback={this.tryAgainToLoadData} t={t} />
        }

        const ineligibleToReportOutage = (this.props.hasMedicalSupportOption || this.props.isIneligibleToReport
            || this.props.selectedBillAccountDetails.isPrePay
            || this.props.customerInOutageInfo.isInOutageArea
            || (this.props.lastReportedOutage.hasReported
                    && !outageRestoration.isReportedOutageClosed(this.props.lastReportedOutage, this.props.outageHistoryList))
            || (this.props.rateMetaData.summaryBilling === rateMetaDataConstants.SUMMARY_BILLING_PARENT))

        const initValues = {
            selectedBillAccount: this.props.selectedBillAccount,
            outageSubscriptionEmail: this.props.outageSubscriptionEmail,
            outageSubscriptionPhone: this.props.outageSubscriptionPhone,
            requestCallback: this.props.requestCallback,
            phoneForCallback: this.props.phoneForCallback,
            breakerCheck: this.state.breakerCheck,
        }

        return (
            <Formik initialValues={initValues} onSubmit={this.props.submitReportOutageFormOnClick}>
                {({values, setFieldValue, handleSubmit, status}) => {

                return (<React.Fragment>
                    <AnalyticsFormTracking name="Report Outage" formStep="outage details" meta={{}}
                        onStateChanged={this.onStateChanged} initialChangedState={this.state.initialChangedState} />
                    {(ineligibleToReportOutage)
                    ? <UnableToReportOutage t={t} />
                    : <ReportOutagePage
                        selectedBillAccount={values.selectedBillAccount}
                        isCommercial={this.props.selectedBillAccountDetails.isCommercial}
                        serviceAddress={this.props.serviceAddress.fullStreetAddress}
                        requestCallback={values.requestCallback}
                        phoneForCallback={values.phoneForCallback}
                        getPhoneForCallback={phone => {setFieldValue('phoneForCallback', phone)}}
                        togglePowerRestoredCallbackOnClick={wantsCallback => {setFieldValue('requestCallback', wantsCallback); this.props.togglePowerRestoredCallbackOnClick()}}
                        submitReportOutageFormOnClick={handleSubmit}
                        isReportingOutage={this.props.isReportingOutage}
                        showAffectedAddressModal={this.state.showAffectedAddressModal}
                        closeAffectedAddressModalOnClick={this.closeAffectedAddressModalOnClick}
                        openAffectedAddressModalOnClick={this.openAffectedAddressModalOnClick}
                        outageSubscription={this.props.outageSubscription}
                        availableOutageNotification={this.props.availableOutageNotification}
                        outageSubscriptionEmail={values.outageSubscriptionEmail}
                        outageSubscriptionEmailError={this.state.outageSubscriptionEmailError}
                        validateOutageSubscriptionEmail={this.validateOutageSubscriptionEmail}
                        outageSubscriptionPhone={values.outageSubscriptionPhone}
                        outageSubscriptionPhoneError={this.state.outageSubscriptionPhoneError}
                        validateOutageSubscriptionPhone={this.validateOutageSubscriptionPhone}
                        getOutageSubscriptionEmail={value => {setFieldValue('outageSubscriptionEmail', value); this.setState({ newSubscriptionEmailDialog: { display: 'block' } })}}
                        getOutageSubscriptionPhone={value => {setFieldValue('outageSubscriptionPhone', value); this.setState({ newSubscriptionPhoneDialog: { display: 'block' } })}}
                        newSubscriptionEmailDialog={this.state.newSubscriptionEmailDialog}
                        newSubscriptionPhoneDialog={this.state.newSubscriptionPhoneDialog}
                        contacts={this.props.contacts}
                        addAndSubscribeContact={this.props.addAndSubscribeContact}
                        validateCallbackPhone={this.validateCallbackPhone}
                        callbackError={this.state.callbackError}
                        callbackErrorMessage={this.state.callbackErrorMessage}
                        breakerCheck={values.breakerCheck}
                        generalError={this.state.generalError}
                        onBreakerCheckClicked={() => { setFieldValue('breakerCheck', !values.breakerCheck); if (!values.breakerCheck) this.setGeneralError(false) }}
                        setGeneralError={this.setGeneralError}
                        errorCallingReportOutageAPI={status?.submitError ?? false}
                        t={t}
                        i18n={i18n}
                    />}
                </React.Fragment>)
                }}
            </Formik>
        )
    }

    render() {
        const t = this.props.t
        const i18n = this.props.i18n

        let renderedCard = this.getCardToRender()

        return (
            <div>
                <div className="d-block d-lg-none">
                    <Button
                        variant="contained"
                        fullWidth
                        className="justify-content-start nav-button-responsive"
                        onClick={() => this.props.router.navigate(-1)}
                        disableRipple>
                        <Icon className="material-icons srp-blue-color mr-2">keyboard_backspace</Icon>
                        {t("Previous page")}
                    </Button>
                </div>

                <div className="container py-3">
                    <CallAnytime />
                    <h3 className="mt-3">{t("Report an outage")}</h3>
                    <h5 className="text-muted">{t("Provide details for the loss in power")}</h5>

                    <AccountInfoBarContainer disableSelector={false} />

                    <div className="d-none d-lg-block">
                        <h5 className="d-flex justify-content-start align-items-center">
                            <button className="displayAsLink" onClick={() => this.props.router.navigate(-1)}>{t("Previous page")}</button>
                            <i className="material-icons srp-dark-gray-color" >keyboard_arrow_right</i>
                            <span className="srp-gray-color">{t("Report Outage")}</span>
                        </h5>
                    </div>

                    {renderedCard}

                </div>
            </div>
        )
    }
}

function getOutageSubscription(notifications) {
    let outageSubscriptionDetails = {}
    let availableNotification = {}
    let contactEmail = ""
    let contactPhone = ""

    for (let i = 0; i < notifications.subscribed.length; i++) {
        if (notifications.subscribed[i].notificationType === OUTAGE_NOTIFICATION) {

            for (let j = 0; j < notifications.subscribed[i].contacts.length; j++) {
                if (contactEmail === "") {
                    contactEmail = notifications.subscribed[i].contacts[j].contactType === contactConstants.CONTACT_TYPE.EMAIL ? notifications.subscribed[i].contacts[j].contactAddress : ""
                }
                if (contactPhone === "") {
                    contactPhone = notifications.subscribed[i].contacts[j].contactType === contactConstants.CONTACT_TYPE.PHONE ? notifications.subscribed[i].contacts[j].contactAddress : ""
                }
            }

            outageSubscriptionDetails = notifications.subscribed[i]
        }
    }

    for (let i = 0; i < notifications.available.length; i++) {
        if (notifications.available[i].notificationType === OUTAGE_NOTIFICATION) {
            availableNotification = notifications.available[i]
        }
    }

    return {
        outageSubscription: outageSubscriptionDetails,
        outageSubscriptionEmail: contactEmail,
        outageSubscriptionPhone: contactPhone,
        availableOutageNotification: availableNotification
    }
}

const mapStateToProps = state => {
    const outageSubscriptionInfo = getOutageSubscription(state.accountInfo.notification.notifications)

    return {
        ...state.accountInfo.reportOutage,
        ...state.accountInfo.outage,
        ...state.accountInfo.billAccount,
        phoneForCallback: state.accountInfo.billAccount.phoneNumber?.bestPhone ?? "",
        ...outageSubscriptionInfo,
        notificationsStatus: state.accountInfo.notification.notificationsStatus,
        ...state.accountInfo.contact,
        ...state.login,
        ...state.rateMetaData }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        togglePowerRestoredCallbackOnClick: (isRequestCallback) => {
            dispatch(togglePowerRestoredCallback(isRequestCallback))
        },
        submitReportOutageFormOnClick: async ({ selectedBillAccount, phoneForCallback, requestCallback }, actions) => {
            const res = await dispatch(submitReportOutageForm(selectedBillAccount, phoneForCallback, requestCallback))

            const reportedSuccessfully = (res.payload?.lastReportedOutage?.hasReported === true)
            actions.setStatus({submitError: !reportedSuccessfully})

            actions.setSubmitting(false)

            if (reportedSuccessfully) {
                ownProps.router.navigate('/myaccount/outages')
            }
        },
        getOutageNotifications: (billAccount) => {
            dispatch(getNotifications(billAccount))
        },
        getMedicalSupportOptionInfo: (billAccount) => {
            dispatch(getMedicalSupportOption(billAccount))
        },
        getIsIneligibleToReportInfo: (billAccount) => {
            dispatch(getIsIneligibleToReport(billAccount))
        },
        getContacts: (billAccount) => {
            dispatch(getContacts(billAccount))
        },
        addAndSubscribeContact: async (billAccount, contactsToAdd, notificationToAdd) => {
            let responseArray = []
            let contactError = false

            for (let i = 0; i < contactsToAdd.length; i++) {
                let response = await dispatch(addContacts(billAccount, contactsToAdd[i]))
                if (response.error || (response.payload !== 1 && response.payload !== 12))
                    responseArray.push(response)
            }

            for (let i = 0; i < responseArray.length; i++) {
                if (responseArray[i].error || (responseArray[i].payload !== 1 && responseArray[i].payload !== 12))
                    contactError = true
            }

            if (contactError) {
                dispatch(addSnackbarMessage(<span>Unable to add alert</span>))
            }
            else {
                let notificationResponseArray = []
                let notificationResponse
                let notificationError = false
                let contactList = notificationToAdd.notificationToAdd.contacts
                for (let i = 0; i < contactList.length; i++) {
                    let notification = {
                        billAccount: notificationToAdd.billAccount,
                        notificationToAdd: {
                            ...notificationToAdd.notificationToAdd,
                            contacts: [contactList[i]]
                        }
                    }
                    notificationResponse = await dispatch(addNotification(billAccount, notification))
                    if (notificationResponse.error || (notificationResponse.payload !== 1 && notificationResponse.payload !== 12))
                        notificationResponseArray.push(notificationResponse)

                    for (let i = 0; i < notificationResponseArray.length; i++) {
                        if (notificationResponseArray[i].error || (notificationResponseArray[i].payload !== 1 && notificationResponseArray[i].payload !== 12))
                            notificationError = true
                    }

                    if(notificationError)
                        dispatch(addSnackbarMessage(<span>Unable to add alert</span>))

                    dispatch(refreshContacts(billAccount))
                    dispatch(refreshNotification(billAccount))
                }

            }
        },
        getBestPhone: (billAccount) => {
            dispatch(getPhoneNumber(billAccount))
        },
        getRateMetaDataInfo: (billAccount) => {
            dispatch(getRateMetaData(billAccount))
        },
        getLastReportedOutageInfo: (billAccount) => {
            dispatch(getLastReportedOutage(billAccount))
        },
        getCustomerInOutageAreaInfo: (billAccount) => {
            dispatch(getIsInOutageArea(billAccount))
        },
        getOutageHistoryInfo: (billAccount) => {
            dispatch(getOutageHistory(billAccount))
        }
    }
}

ReportOutagePageContainer.propTypes = {
    selectedBillAccount: PropTypes.number.isRequired,
    selectedBillAccountDetails: PropTypes.object.isRequired,
    billAccountListStatus: PropTypes.string.isRequired,
    serviceAddress: PropTypes.object.isRequired,
    requestCallback: PropTypes.bool,
    togglePowerRestoredCallbackOnClick: PropTypes.func.isRequired,
    submitReportOutageFormOnClick: PropTypes.func.isRequired,
    isReportingOutage: PropTypes.bool.isRequired,
    router: PropTypes.shape({
        navigate: PropTypes.func
    }),

    getOutageNotifications: PropTypes.func.isRequired,
    notificationsStatus: PropTypes.string.isRequired,
    outageSubscription: PropTypes.object.isRequired,
    outageSubscriptionEmail: PropTypes.string.isRequired,
    outageSubscriptionPhone: PropTypes.string.isRequired,
    availableOutageNotification: PropTypes.object.isRequired,

    getMedicalSupportOptionInfo: PropTypes.func.isRequired,
    hasMedicalSupportOptionStatus: PropTypes.string.isRequired,
    hasMedicalSupportOption: PropTypes.bool.isRequired,
    getIsIneligibleToReportInfo: PropTypes.func.isRequired,
    isIneligibleToReportStatus: PropTypes.string.isRequired,
    isIneligibleToReport: PropTypes.bool.isRequired,

    contactsStatus: PropTypes.string.isRequired,
    contacts: PropTypes.arrayOf(PropTypes.object).isRequired,
    getContacts: PropTypes.func.isRequired,
    addAndSubscribeContact: PropTypes.func.isRequired,

    phoneNumberStatus: PropTypes.string.isRequired,
    getBestPhone: PropTypes.func.isRequired,
    phoneForCallback: PropTypes.string.isRequired,

    rateMetaData: PropTypes.object.isRequired,
    rateMetaDataStatus: PropTypes.string.isRequired,
    getRateMetaDataInfo: PropTypes.func.isRequired,

    lastReportedOutage: PropTypes.object.isRequired,
    lastReportedOutageStatus: PropTypes.string.isRequired,
    getLastReportedOutageInfo: PropTypes.func.isRequired,

    customerInOutageInfo: PropTypes.object.isRequired,
    customerInOutageInfoStatus: PropTypes.string.isRequired,
    getCustomerInOutageAreaInfo: PropTypes.func.isRequired,

    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired,

    outageHistoryList: PropTypes.arrayOf(PropTypes.object).isRequired,
    outageHistoryStatus: PropTypes.string.isRequired,
    getOutageHistoryInfo: PropTypes.func.isRequired
}

export default withTranslation("reportOutage")(withRouter(connect(mapStateToProps, mapDispatchToProps)(ReportOutagePageContainer)))