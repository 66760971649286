import React from 'react'
import PropTypes from 'prop-types'

import format from '../../../srp_modules/format'

import { DateTime } from 'luxon'

const PaymentSubmittedDetailsTable = ({paymentList, bankName, truncatedBankAccountNumber, referenceNumber, t }) => (
    <div>
        <div className="text-muted srp-olive-green pl-3 pb-3">
            <h5 className="d-lg-none">{t("Reference number:")} {referenceNumber}</h5>
            <h4 className="d-none d-lg-block">{t("Reference number:")} {referenceNumber}</h4>
        </div>

        <table className="table">
            <tbody>
                <tr>
                    <td className="border-top-0 pl-3 srp-fit">{t("SRP account")}</td>
                    <td className="border-top-0">{format.formatBillAccountNumber(paymentList[0].srpAccountNumber)}</td>
                </tr>
                <tr>
                    <td className="pl-3 srp-fit">{t("Bank account")}</td>
                    <td>{`${bankName} (****${truncatedBankAccountNumber})`}</td>
                </tr>
                <tr>
                    <td className="pl-3 srp-fit">{t("Payment amount")}</td>
                    <td>{'$'+paymentList[0].paymentAmount.toFixed(2)}</td>
                </tr>
                {(DateTime.now().startOf('day').equals(paymentList[0].paymentDate) == false) &&
                <tr>
                    <td className="pl-3 srp-fit">{t("Today's date")}</td>
                    <td>{DateTime.now().toFormat('M/d/yyyy')}</td>
                </tr>
                }
                <tr>
                    <td className="border-bottom pl-3 srp-fit">{t("Payment date")}</td>
                    <td className="border-bottom">{DateTime.fromISO(paymentList[0].paymentDate).toFormat('M/d/yyyy')}</td>
                </tr>
            </tbody>
        </table>
    </div>
)

PaymentSubmittedDetailsTable.propTypes = {
    paymentList: PropTypes.arrayOf(PropTypes.shape({
        srpAccountNumber: PropTypes.number.isRequired,
        paymentAmount: PropTypes.number.isRequired,
        paymentDate: PropTypes.string.isRequired
    })),
    bankName: PropTypes.string.isRequired,
    truncatedBankAccountNumber: PropTypes.string.isRequired,
    referenceNumber: PropTypes.number.isRequired,
    t: PropTypes.func.isRequired
}

export default PaymentSubmittedDetailsTable