import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import CompareBillCard from './compare-bill-card'
import BillPageLoading from '../bill-page-loading'
import BillPageError from '../bill-page-error'

import { getEnrolledProgramsDetails } from '../../../actions/auth/payment/payment-actions'
import { addBillToCompare, removeBillFromCompare } from '../../../actions/auth/bill_page/compare-bill-actions'
import { submitBudgetBilling } from '../../../actions/auth/budget_billing/budget-billing-actions'
import { rateMetaDataConstants } from '../../../constants/rate-meta-data-constants'
import { withRouter } from '../../../srp_modules/with-router'
import * as loadingStatus from '../../../constants/loading-status-constants'

class CompareBillCardContainer extends React.Component{
    constructor(props) {
        super(props);

        this.refreshCompareMyBillCard = this.refreshCompareMyBillCard.bind(this)
        this.createAndSubmitBudgetBillingForm = this.createAndSubmitBudgetBillingForm.bind(this)
    }

    componentDidMount() {
        if(this.props.selectedBillAccount === 0)
            return

        if(this.props.enrolledProgramsDetailsStatus === loadingStatus.LOADING_STATUS_INIT) {
            this.props.getEnrolledProgramsDetails(this.props.selectedBillAccount)
        }

    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        // TODO: For now, only update outage information on bill account change
        if(this.props.selectedBillAccount === nextProps.selectedBillAccount || nextProps.selectedBillAccount === 0)
            return

        if(nextProps.enrolledProgramsDetailsStatus !== loadingStatus.LOADING_STATUS_IN_PROGRESS) {
            this.props.getEnrolledProgramsDetails(nextProps.selectedBillAccount)
        }
    }

    refreshCompareMyBillCard() {
        if(this.props.accountHistoryStatus === loadingStatus.LOADING_STATUS_FAILURE)
            this.props.getAccountHistory(this.props.selectedBillAccount)

        if(this.props.enrolledProgramsDetailsStatus === loadingStatus.LOADING_STATUS_FAILURE)
            this.props.getEnrolledProgramsDetails(this.props.selectedBillAccount)
    }

    createAndSubmitBudgetBillingForm() {
        let budgetBillingForm = {
            isResidential: !this.props.selectedBillAccountDetails.isCommercial,
            accountNumber: this.props.selectedBillAccount,
            customerInfoModel: {
                firstName: this.props.customerNames.firstName,
                lastName: this.props.customerNames.lastName,
                emailAddress: this.props.primaryEmail,
                phoneNumber: this.props.primaryPhone
            },
            businessInfoModel: {
                businessName: this.props.customerNames.displayName,
                emailAddress: this.props.primaryEmail,
                phoneNumber: this.props.primaryPhone
            },
            serviceAddress: {
                streetAddress: this.props.serviceAddress.streetAddress,
                state: this.props.serviceAddress.state,
                city: this.props.serviceAddress.cityName,
                zipCode: this.props.serviceAddress.zipCode,
                addressType: this.props.serviceAddress.serviceAddressTypeCode
            },
            language: "en", //language does not matter for budget billing
            electricType: 2,
            squareFootage: "",
            hasEvaporatingCooling: false,
            hasSwimmingPool: false
        }

        this.props.submitBudgetBillingForm(budgetBillingForm)
    }

    render() {
        let renderedCard = {}
        if((this.props.accountHistoryStatus === loadingStatus.LOADING_STATUS_INIT || this.props.accountHistoryStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS)
            || (this.props.enrolledProgramsDetailsStatus === loadingStatus.LOADING_STATUS_INIT || this.props.enrolledProgramsDetailsStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS))
        {
            renderedCard = <BillPageLoading/>
        }
        else if(this.props.accountHistoryStatus === loadingStatus.LOADING_STATUS_SUCCESS
            && this.props.enrolledProgramsDetailsStatus === loadingStatus.LOADING_STATUS_SUCCESS)
        {
            renderedCard = (<CompareBillCard
                                accountHistoryList={this.props.accountHistoryList}
                                billChipInfo={this.props.billChipInfo}
                                billToCompare={this.props.billToCompare}
                                addBillToCompareOnClick={this.props.addBillToCompareOnClick}
                                removeBillFromCompareOnClick={this.props.removeBillFromCompareOnClick}
                                budgetBillingEstimate={this.props.budgetBillingEstimate}
                                submitBudgetBillingStatus={this.props.submitBudgetBillingStatus}
                                createAndSubmitBudgetBillingForm={this.createAndSubmitBudgetBillingForm}
                                isCommercial={this.props.selectedBillAccountDetails.isCommercial}
                                isBudgetBillingEligible={!this.props.isBudgetBilling
                                    && !this.props.selectedBillAccountDetails.isCommercial
                                    && this.props.rateMetaData.summaryBilling === rateMetaDataConstants.SUMMARY_BILLING_NONE
                                }
                                hasEmail={this.props.primaryEmail.length > 0}
                                t={this.props.t}
                            />)
        }
        else
        {
            renderedCard = <BillPageError dataThatFailed="Account history" refreshData={this.refreshCompareMyBillCard}/>
        }
        return renderedCard
    }
}

const mapStateToProps = state => {
    return {
        ...state.accountHistory,
        ...state.compareBill,
        ...state.accountInfo.billAccount,
        ...state.accountInfo.payment,
        ...state.budgetBilling,
        ...state.accountInfo.contact,
        ...state.rateMetaData }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        addBillToCompareOnClick: (bill, chip) => {
            dispatch(addBillToCompare(bill, chip))
        },
        removeBillFromCompareOnClick: (bill) => {
            dispatch(removeBillFromCompare(bill))
        },
        getEnrolledProgramsDetails: (billAccount) => {
            dispatch(getEnrolledProgramsDetails(billAccount))
        },
        submitBudgetBillingForm: async (form) => {
            let response = await dispatch(submitBudgetBilling(form))
            if(response){
                ownProps.router.navigate('/budgetbilling')
            }
        }
    }
}

CompareBillCardContainer.propTypes = {
    selectedBillAccountDetails: PropTypes.object,
    selectedBillAccount: PropTypes.number,
    customerNames: PropTypes.object,
    primaryEmail: PropTypes.string,
    primaryPhone: PropTypes.string,
    serviceAddress: PropTypes.object,

    billChipInfo: PropTypes.arrayOf(PropTypes.object),
    billToCompare: PropTypes.number,
    addBillToCompareOnClick: PropTypes.func,
    removeBillFromCompareOnClick: PropTypes.func,

    accountHistoryList: PropTypes.arrayOf(PropTypes.object),
    accountHistoryStatus: PropTypes.string,
    getAccountHistory: PropTypes.func,

    isBudgetBilling: PropTypes.bool,
    enrolledPrograms: PropTypes.arrayOf(PropTypes.object),
    enrolledProgramsDetailsStatus: PropTypes.string,
    getEnrolledProgramsDetails: PropTypes.func,

    budgetBillingEstimate: PropTypes.number,
    submitBudgetBillingStatus: PropTypes.string,
    submitBudgetBillingForm: PropTypes.func,

    rateMetaData: PropTypes.object,
    router: PropTypes.shape({
        navigate: PropTypes.func
    }),

    t: PropTypes.func.isRequired
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CompareBillCardContainer))