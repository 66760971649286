import { myAccountConstants } from '../../constants/myaccount-constants'

export const addSnackbarMessage = (message, actions) => {
    return {
        type: myAccountConstants.ADD_SNACKBAR_MESSAGE,
        snackbarMessage: { message, actions }
    }}

export const dismissSnackbarMessage = () => ({
    type: myAccountConstants.DISMISS_SNACKBAR_MESSAGE
})