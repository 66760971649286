import React from 'react'
import { Link } from 'react-router-dom'

import AccountInfoBarContainer from '../myaccount_header/info_bar/account-info-bar-container'
import CallAnytime from '../myaccount_header/call-anytime'

import SuccessCheck from '../../images/common/SuccessCheck.png'

const EppFormConfirmation = () => {
    return (
        <div>
            <div className="container py-3">
                <CallAnytime />
                <h3 className="mt-3">Reapply for the Economy Price Plan</h3>

                <AccountInfoBarContainer disableSelector={true} />

                <div className="row">
                    <div className="col-lg-7 col-12">
                        <div className="srp-card-header">DocuSign application submitted</div>
                        <div className="srp-card-body">
                            <div className="srp-card-details text-muted">
                                <div className="d-lg-block d-none">
                                    <div className="mb-3 srp-olive-green d-flex align-items-center">
                                        <img className="img-fluid mr-3" src={SuccessCheck} style={{width: '50px'}} />
                                        <span className="font-28 font-weight-bold">You're all set!</span>
                                    </div>
                                </div>
                                <div className="d-lg-none d-block">
                                    <div className="mb-3 srp-olive-green text-center">
                                        <img className="img-fluid mr-3" src={SuccessCheck} style={{width: '50px'}}/>
                                        <div className="font-28 font-weight-bold">You're all set!</div>
                                    </div>
                                </div>

                                <div className="mb-4">
                                    {`Your Application was received and is being processed. Please allow time for processing 
                                    before checking your status. Processing may take approximately 4 business days. 
                                    A communication will be mailed letting you know of your application status once processed. 
                                    You can also check the Price Plan and Programs section for status updates.`}
                                </div>

                                <div className="d-flex flex-wrap justify-content-end">
                                    <Link to="/myaccount/dashboard" className="btn srp-btn btn-lightblue">Go to dashboard</Link>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-5 col-12">
                        <div className="srp-card-header">Need help?</div>
                        <div className="srp-card-body">
                            <div className="srp-card-details srp-orange-background">
                                {`If you have any questions, please call SRP’s resource 
                                counselors at (602) 236-3000, 
                                Monday – Friday 8:00 a.m. – 5:00 p.m.`}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EppFormConfirmation