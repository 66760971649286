import React from 'react'
import PropTypes from 'prop-types'

import format from '../../../srp_modules/format'

class PaymentListDetailsDesktop extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {
        const {paymentDate, paymentList, referenceNumber, t} = this.props

        let rows = paymentList.map((p, paymentIndex) => (
            <tr key={paymentIndex}>
                {paymentIndex===0 ?
                <td className="border-top-0 border-bottom pl-3">{paymentDate}</td> :
                <td className="border-top-0 pl-3" />
                }
                <td className="border-top-0 border-bottom">{format.formatBillAccountNumber(p.srpAccountNumber)}</td>
                <td className={"border-top-0 border-bottom text-right" + ((referenceNumber || referenceNumber===0) ? '' : ' pr-3')}>
                    {'$'+p.paymentAmount.toFixed(2)}
                </td>
                {(referenceNumber || referenceNumber===0) &&
                <td className="border-top-0 border-bottom text-right pr-3">{referenceNumber}</td>
                }
            </tr>
        ))

        return (
            <table className="table">
                <thead>
                    <tr>
                        <th className="border-0 font-weight-normal pl-3">{t("Payment date")}</th>
                        <th className="border-0 font-weight-normal">{t("Account number")}</th>
                        <th className={"border-0 font-weight-normal text-right" + ((referenceNumber || referenceNumber===0) ? '' : ' pr-3')}>
                            {t("Payment amount")}
                        </th>
                        {(referenceNumber || referenceNumber===0) &&
                        <th className="border-0 font-weight-normal text-right pr-3">{t("Reference number")}</th>
                        }
                    </tr>
                </thead>
                <tbody>{rows}</tbody>
            </table>
        )
    }
}

PaymentListDetailsDesktop.propTypes = {
    paymentDate: PropTypes.string.isRequired,
    paymentList: PropTypes.arrayOf(PropTypes.shape({
        srpAccountNumber: PropTypes.string.isRequired,
        paymentAmount: PropTypes.number.isRequired,
    })),
    referenceNumber: PropTypes.number,
    t: PropTypes.func.isRequired,
}

export default PaymentListDetailsDesktop