import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import UsagePageLoading from './usage-page-loading'
import BillProjectionUnavailable from './bill-projection-unavailable'
import BillProjectionError from './bill-projection-error'
import { getBillProjection } from '../../actions/auth/payment/bill-projection-actions'
import { getDailyUsage } from '../../actions/auth/usage/daily-usage-actions'
import * as loadingStatus from '../../constants/loading-status-constants'
import { DateTime } from 'luxon'

let calculateProjectionThroughDate = (dailyMaxDate) => {
    let yesterday = DateTime.now().setZone('MST').startOf('day').minus({ days: 1 })
    let maxDate = DateTime.fromISO(dailyMaxDate).setZone('MST').startOf('day')

    // Don't return a projection through date greater than yesterday
    return DateTime.min(yesterday, maxDate).toFormat('M/d/yy')
}

class BillProjectionCard extends React.Component {
    constructor(props) {
        super(props)

        this.refreshBillProjData = this.refreshBillProjData.bind(this)
    }

    componentDidMount() {
        if (this.props.selectedBillAccount === 0)
            return

        if (this.props.billProjectionStatus === loadingStatus.LOADING_STATUS_INIT) {
            this.props.getBillProjectionInfo(this.props.selectedBillAccount)
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.selectedBillAccount === nextProps.selectedBillAccount || nextProps.selectedBillAccount === 0)
            return

        if (nextProps.billProjectionStatus !== loadingStatus.LOADING_STATUS_IN_PROGRESS) {
            this.props.getBillProjectionInfo(nextProps.selectedBillAccount)
        }
    }

    refreshBillProjData() {
        if (this.props.billProjectionStatus === loadingStatus.LOADING_STATUS_FAILURE)
            this.props.getBillProjectionInfo(this.props.selectedBillAccount)

        if (this.props.dailyUsageStatus === loadingStatus.LOADING_STATUS_FAILURE)
            this.props.getDailyUsageForAccount(this.props.selectedBillAccount)
    }

    render() {
        let renderedCard = {}

        if (this.props.isSummaryMaster) {
            return null
        }

        if (this.props.billProjectionStatus === loadingStatus.LOADING_STATUS_INIT || this.props.billProjectionStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS) {
            renderedCard =
                (<div className="srp-card-body">
                    <div className="srp-card-details">
                        <UsagePageLoading />
                    </div>
                </div>)
        }
        else if (this.props.billProjectionStatus === loadingStatus.LOADING_STATUS_SUCCESS) {
            if (!this.props.billProjection.hasProjection) {
                renderedCard = <BillProjectionUnavailable t={this.props.t}/>
            }
            else {
                if (this.props.dailyUsageStatus === loadingStatus.LOADING_STATUS_INIT || this.props.dailyUsageStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS) {
                    renderedCard =
                        (<div className="srp-card-body">
                            <div className="srp-card-details">
                                <UsagePageLoading />
                            </div>
                        </div>)
                }
                else if (this.props.dailyUsageStatus === loadingStatus.LOADING_STATUS_SUCCESS) {
                    renderedCard =
                        (<div className="srp-card-body">
                            <div className="srp-card-summary d-flex justify-content-around">
                                <div className="d-flex flex-column align-items-center">
                                    <div className="h4 text-white">${((this.props.billProjection.amount).toFixed(0)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
                                    <span>{this.props.t("projected bill amount")}</span>
                                </div>
                            </div>

                            <div className="srp-card-details">
                                <div className="text-muted mt-2">
                                    {this.props.t("Projection_amount", {month : this.props.billProjection.month, date: calculateProjectionThroughDate(this.props.dailyMaxDate)})}
                                    {/* {`Your ${this.props.billProjection.month} bill projection is based on your usage through ${calculateProjectionThroughDate(this.props.dailyMaxDate)}.`} */}
                                </div>
                            </div>
                        </div>)
                }
                else {
                    renderedCard = <BillProjectionError refreshData={this.refreshBillProjData} />
                }
            }
        }
        else {
            renderedCard = <BillProjectionError refreshData={this.refreshBillProjData} />
        }
        return (
            <div>
                <div className="srp-card-header mt-4">{this.props.t("Bill projection")}</div>
                {renderedCard}
            </div>
        )
    }
}

const mapStateToProps = state => {
    return { ...state.accountInfo.billAccount, ...state.billProjection, ...state.accountInfo.usagePageUI, ...state.dailyUsage }
}


const mapDispatchToProps = dispatch => {
    return {
        getBillProjectionInfo: (billAccount) => {
            dispatch(getBillProjection(billAccount))
        },
        getDailyUsageForAccount: (billAccount) => {
            dispatch(getDailyUsage(billAccount))
        }
    }
}

BillProjectionCard.propTypes = {
    selectedBillAccount: PropTypes.number,
    isSummaryMaster: PropTypes.bool,

    billProjection: PropTypes.object,
    billProjectionStatus: PropTypes.string,
    getBillProjectionInfo: PropTypes.func,

    dailyUsageStatus: PropTypes.string,
    getDailyUsageForAccount: PropTypes.func,

    dailyMaxDate: PropTypes.object,
    t: PropTypes.func
}

export default connect(mapStateToProps, mapDispatchToProps)(BillProjectionCard)