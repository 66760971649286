import { DateTime } from 'luxon'
import { eppConstants } from '../constants/epp-constants'
import { billAccountConstants } from '../constants/bill-account-constants'
import config from 'my-account-config'

const eppStatusModule = {
    getEppStatusMessageInfo,
    getEppApplyEligibility,
    displayEppOnProgramPage,
    displayEppStatusOnProgramPage
}

function getEppStatusMessageInfo(eppAuditInfo) {
    if (eppAuditInfo.isPendingReview) {
        return {
            showMessage: true,
            title: "PENDING REVIEW",
            description: "Processing may take approximately 4 business days.",
            bannerType: "srp-alert-notice"
        }
    }

    let eppStatusExpirationDays = config.eppStatusExpirationDays || 30

    let noMessage = {
        showMessage: false,
        title: "",
        description: "",
        bannerType: ""
    }

    let removalMessage = {
        showMessage: true,
        title: "removal notice",
        description: "Your account was removed from the Economy Price Plan- we did not receive the required documents.",
        bannerType: "srp-alert-error"
    }

    switch (eppAuditInfo.statusId) {
        case eppConstants.AUDIT_STATUS.NONE: {
            if (eppAuditInfo.removedWithinOneYear) {
                return removalMessage
            } else {
                return noMessage
            }
        }
        case eppConstants.AUDIT_STATUS.INITIAL:
            return {
                showMessage: true,
                title: "time to reapply",
                description: "It’s time to reapply for the Economy Price Plan discount.",
                bannerType: "srp-alert-notice"
            }
        case eppConstants.AUDIT_STATUS.MISSING_INFO:
        case eppConstants.AUDIT_STATUS.MISSING_PREV_AUDIT:
            return {
                showMessage: true,
                title: "missing information",
                description: "We received part of your Economy Price Plan reapplication paperwork for your account, but are missing some information before we can determine if you're still eligible.",
                bannerType: "srp-alert-warn"
            }
        case eppConstants.AUDIT_STATUS.LAST_CHANCE:
            return {
                showMessage: true,
                title: "second reminder",
                description: "It’s time to reapply for the Economy Price Plan discount.",
                bannerType: "srp-alert-warn"
            }
        case eppConstants.AUDIT_STATUS.REMOVED_NO_RESPONSE:
        case eppConstants.AUDIT_STATUS.REMOVED_MISSING_DNR:
        case eppConstants.AUDIT_STATUS.REMOVAL_LETTER:{
            return removalMessage
            }
        case eppConstants.AUDIT_STATUS.APPROVED: {
            let thirtyDaysAfterStatus = DateTime.fromISO(eppAuditInfo.statusDate).plus({ days: eppStatusExpirationDays })
            if (DateTime.now().setZone('MST') > thirtyDaysAfterStatus) {
                return noMessage
            }
            else {
                return {
                    showMessage: true,
                    title: "approved",
                    description: "Thank you for reapplying for the Economy Price Plan monthly discount. Good news! Your application has been approved.",
                    bannerType: "srp-alert-success"
                }
            }
        }

        default:
            return noMessage
    }
}

function getEppApplyEligibility(selectedBillAccountDetails, economicPricePlan, customerNames) {
    let eligible = true

    if (selectedBillAccountDetails.isCommercial) {
        eligible = false
    } else if (selectedBillAccountDetails.closedState !== billAccountConstants.CLOSED_STATE.OPEN) {
        eligible = false
    } else if (economicPricePlan.isEnrolled) {
        eligible = false
    } else if (economicPricePlan.statusId !== eppConstants.AUDIT_STATUS.NONE) {
        eligible = false
    } else if (!customerNames.firstName) {
        eligible = false
    } else if (economicPricePlan.removedWithinOneYear) {
        eligible = false
    }

    return eligible
}

function displayEppOnProgramPage(status) {
    switch (status) {
        case eppConstants.AUDIT_STATUS.INITIAL:
        case eppConstants.AUDIT_STATUS.MISSING_INFO:
        case eppConstants.AUDIT_STATUS.MISSING_PREV_AUDIT:
        case eppConstants.AUDIT_STATUS.LAST_CHANCE:
        case eppConstants.AUDIT_STATUS.REMOVAL_LETTER:
        case eppConstants.AUDIT_STATUS.APPROVED:
        case eppConstants.AUDIT_STATUS.APPROVED_ASSISTANCE:
        case eppConstants.AUDIT_STATUS.RECEIVED:
        case eppConstants.AUDIT_STATUS.DEFER:
        case eppConstants.AUDIT_STATUS.NONE:
            return true
        default:
            return false
    }
}
function displayEppStatusOnProgramPage(pendingReview, status, discount, t) {
    if (pendingReview) {
        return {
            statusText: t("Processing"),
            showReapplyLink: false,
        }
    }

    switch (status) {
        case eppConstants.AUDIT_STATUS.INITIAL:
        case eppConstants.AUDIT_STATUS.MISSING_INFO:
        case eppConstants.AUDIT_STATUS.MISSING_PREV_AUDIT:
        case eppConstants.AUDIT_STATUS.LAST_CHANCE:
        case eppConstants.AUDIT_STATUS.REMOVAL_LETTER:
            return {
                statusText: t("Processing"),
                showReapplyLink: true
            }
        case eppConstants.AUDIT_STATUS.APPROVED:
        case eppConstants.AUDIT_STATUS.APPROVED_ASSISTANCE:
        case eppConstants.AUDIT_STATUS.RECEIVED:
        case eppConstants.AUDIT_STATUS.DEFER:
        case eppConstants.AUDIT_STATUS.NONE:
            return {
                statusText: t("x_monthly_discount", {discount}),
                showReapplyLink: false
            }
        default:
            return {
                statusText: "",
                showReapplyLink: false
            }
    }
}

export default eppStatusModule