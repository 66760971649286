import { billAccountConstants } from '../../../constants/bill-account-constants'
import { contactConstants } from '../../../constants/contact-constants'
import { rateMetaDataConstants } from '../../../constants/rate-meta-data-constants'
import { shareConstants } from '../../../constants/share-constants'
import * as loadingStatus from '../../../constants/loading-status-constants'

const initialState = {
    activeStep: 0,

    accountDetails: {
        accountType: shareConstants.ACOUNT_TYPE.UNKNOWN,
        billAccount: '',
        firstName: '',
        middleInitial: '',
        lastName: '',
        businessName: '',
        dbaName: '',
        contactName: '',
        contactPosition: '',
        serviceAddressOne: '',
        serviceAddressTwo: '',
        city: '',
        zipCode: '',
        emailAddress: '',
        phoneNumber: '',
    },

    donationType: shareConstants.DONATION_TYPE.MONTHLY,
    donationAmount: shareConstants.DONATION_AMOUNT.ONE_DOLLAR,
    otherDonationAmount: "0.00",

    submitShareFormStatus: loadingStatus.LOADING_STATUS_INIT
}

export default function shareReducer(state = initialState, action) {
    switch (action.type) {
        case shareConstants.SET_ACTIVE_STEP:
            return { ...state, activeStep: action.activeStep }
        case rateMetaDataConstants.GET_RATE_META_DATA_SUCCESS:
            return {
                ...state,
                accountDetails: {
                    ...state.accountDetails,
                    firstName: action.payload.isBusinessOnResidentialPlan
                                ? action.payload.displayName
                                : action.payload.firstName,
                    middleInitial: action.payload.middleName,
                    lastName: action.payload.lastName,
                    businessName: action.payload.isCommercial || action.payload.isBusinessOnResidentialPlan
                                    ? action.payload.displayName
                                    : ""
                }
            }
        case billAccountConstants.GET_PHONE_SUCCESS:
            return {
                ...state,
                accountDetails: {
                    ...state.accountDetails,
                    phoneNumber: action.payload.bestPhone
                }
            }
        case contactConstants.GET_CONTACTS_SUCCESS:
            return {
                ...state,
                accountDetails: {
                    ...state.accountDetails,
                    emailAddress: getPrimaryEmail(action.payload),
                }
            }
        case billAccountConstants.GET_ADDRESS_SUCCESS:
            return {
                ...state,
                accountDetails: {
                    ...state.accountDetails,
                    serviceAddressOne: action.payload.streetAddress,
                    city: action.payload.cityName,
                    zipCode: action.payload.zipCode,
                }
            }

        case shareConstants.SET_ACCOUNT_TYPE:
            return {
                ...state,
                accountDetails: {
                    ...state.accountDetails,
                    accountType: action.accountType
                }
            }
        case shareConstants.SET_ACCOUNT_DETAILS:
            return { ...state, accountDetails: action.accountDetails }
        case shareConstants.SET_DONATION_TYPE: {
            let defaultDonationAmount = state.donationAmount
            switch (action.donationType) {
                case shareConstants.DONATION_TYPE.MONTHLY:
                    defaultDonationAmount = shareConstants.DONATION_AMOUNT.ONE_DOLLAR
                    break
                case shareConstants.DONATION_TYPE.ONE_TIME:
                    defaultDonationAmount = shareConstants.DONATION_AMOUNT.FIVE_DOLLAR
                    break
                case shareConstants.DONATION_TYPE.DISCONTINUE:
                    defaultDonationAmount = shareConstants.DONATION_AMOUNT.OTHER
                    break
                default:
                    break
            }
            return {
                ...state,
                donationType: action.donationType,
                donationAmount: defaultDonationAmount
            }
        }
        case shareConstants.SET_DONATION_AMOUNT:
            return { ...state, donationAmount: action.donationAmount }
        case shareConstants.SET_OTHER_DONATION_AMOUNT:
            return { ...state, otherDonationAmount: action.donationAmount }

        case shareConstants.SUBMIT_SHARE_REQUEST:
            return { ...state, submitShareFormStatus: loadingStatus.LOADING_STATUS_IN_PROGRESS }
        case shareConstants.SUBMIT_SHARE_SUCCESS:
            return (action.payload === true)
                ? { ...state, submitShareFormStatus: loadingStatus.LOADING_STATUS_SUCCESS }
                : { ...state, submitShareFormStatus: loadingStatus.LOADING_STATUS_FAILURE }
        case shareConstants.SUBMIT_SHARE_FAILURE:
            return { ...state, submitShareFormStatus: loadingStatus.LOADING_STATUS_FAILURE }

        case billAccountConstants.SELECT_BILL_ACCOUNT_SUCCESS:
            return { ...initialState }
        default:
            return state
    }
}

function getPrimaryEmail(contacts) {
    let primaryEmail = ""
    for (let i = 0; i < contacts.length; i++) {
        if (contacts[i].contactType === contactConstants.CONTACT_TYPE.EMAIL && contacts[i].displaySequenceNumber === 1) {
            primaryEmail = contacts[i].contactAddress
        }
    }

    return primaryEmail
}