import React from "react"
import PropTypes from 'prop-types'

import TextSignUpAlertMessage from './text-signup-alert-message'
import CommonAlert from "./common-alert"
import { centralPrepayConstants } from '../../../../constants/central-prepay-constants'
const MPowerAlerts = ({ allAlerts, phoneContacts, emailContacts, mPowerAcctDetails, addNotificationOnClick, deleteNotificationOnClick, deleteAllNotificationsOnClick, t }) => {
    return (
        <div className="srp-card-details">
            <TextSignUpAlertMessage t={t}/>

            <div className="d-lg-block d-none">
                <div className="row mb-3">
                    <div className="col-lg-8" />
                    <div className="col-lg-2 text-muted">{t("Email")}</div>
                    <div className="col-lg-2 text-muted">{t("Text")}</div>
                </div>
            </div>
            {allAlerts.mPowerLowBalanceAlert && allAlerts.mPowerLowBalanceAlert.isAvailable
             && mPowerAcctDetails.centralPrepayStatus == centralPrepayConstants.PREPAY_CENTRAL
                ? <CommonAlert
                    alert={allAlerts.mPowerLowBalanceAlert}
                    alertTitle={t(allAlerts.mPowerLowBalanceAlert.notificationInfo.displayTitle)}
                    alertDescription={t("SRP will alert you daily when you have three days of power or less remaining on your account.")}
                    phoneContacts={phoneContacts}
                    emailContacts={emailContacts}
                    addNotification={addNotificationOnClick}
                    deleteNotification={deleteNotificationOnClick}
                    deleteAllNotificationsOnClick={deleteAllNotificationsOnClick}
                    allowEmailSubscription={true}
                    allowTextSubscription={true}
                    t={t}
                />
                : null}
            
            {allAlerts.mPowerPurchaseAlert.isAvailable
                ? <CommonAlert
                    alert={allAlerts.mPowerPurchaseAlert}
                    alertTitle={t(allAlerts.mPowerPurchaseAlert.notificationInfo.displayTitle)}
                    alertDescription={t("Tell me when my meter balance has been updated by a recent purchase or credit.")}
                    phoneContacts={phoneContacts}
                    emailContacts={emailContacts}
                    addNotification={addNotificationOnClick}
                    deleteNotification={deleteNotificationOnClick}
                    deleteAllNotificationsOnClick={deleteAllNotificationsOnClick}
                    allowEmailSubscription={true}
                    allowTextSubscription={true}
                    t={t}
                />
                : null}

            {allAlerts.heatMoratoriumAlert.isAvailable
                ? <CommonAlert
                    alert={allAlerts.heatMoratoriumAlert}
                    alertTitle={t(allAlerts.heatMoratoriumAlert.notificationInfo.displayTitle)}
                    alertDescription={t("SRP keeps power on for all M-Power customers when the National Weather Service issues an Excessive Heat Warning, even if you run out of credit. Alert me when an Excessive Heat Warning starts and ends.")}
                    phoneContacts={phoneContacts}
                    emailContacts={emailContacts}
                    addNotification={addNotificationOnClick}
                    deleteNotification={deleteNotificationOnClick}
                    deleteAllNotificationsOnClick={deleteAllNotificationsOnClick}
                    allowEmailSubscription={true}
                    allowTextSubscription={true}
                    t={t}
                />
                : null}

            {allAlerts.heatMoratoriumAccumulatedDebtAlert.isAvailable
                ? <CommonAlert
                    alert={allAlerts.heatMoratoriumAccumulatedDebtAlert}
                    alertTitle={t(allAlerts.heatMoratoriumAccumulatedDebtAlert.notificationInfo.displayTitle)}
                    alertDescription={t("You will be alerted when you have accumulated debt on your meter during an excessive heat warning. If you do not make a purchase greater than your accumulated debt amount, your power will disconnect when the Excessive Heat Warning ends.")}
                    phoneContacts={phoneContacts}
                    emailContacts={emailContacts}
                    addNotification={addNotificationOnClick}
                    deleteNotification={deleteNotificationOnClick}
                    deleteAllNotificationsOnClick={deleteAllNotificationsOnClick}
                    allowEmailSubscription={true}
                    allowTextSubscription={true}
                    t={t}
                />
                : null}
                
            {allAlerts.heatMoratoriumEndWithAccumulatedDebtAlert && allAlerts.heatMoratoriumEndWithAccumulatedDebtAlert.isAvailable
            ? <CommonAlert
                alert={allAlerts.heatMoratoriumEndWithAccumulatedDebtAlert}
                alertTitle={t(allAlerts.heatMoratoriumEndWithAccumulatedDebtAlert.notificationInfo.displayTitle)}
                alertDescription={t("You will be alerted when an excessive heat warning ends and your account has Accumulated Debt or a Low Balance. If you do not make a purchase greater than your accumulated debt amount, your power will disconnect when the Excessive Heat Warning ends.")}
                phoneContacts={phoneContacts}
                emailContacts={emailContacts}
                addNotification={addNotificationOnClick}
                deleteNotification={deleteNotificationOnClick}
                deleteAllNotificationsOnClick={deleteAllNotificationsOnClick}
                allowEmailSubscription={true}
                allowTextSubscription={true}
                t={t}
            />
            : null}
        </div>
    )
}

MPowerAlerts.propTypes = {
    allAlerts: PropTypes.object,
    phoneContacts: PropTypes.arrayOf(PropTypes.object),
    emailContacts: PropTypes.arrayOf(PropTypes.object),
    mPowerAcctDetails: PropTypes.object,
    addNotificationOnClick: PropTypes.func,
    deleteNotificationOnClick: PropTypes.func,
    deleteAllNotificationsOnClick: PropTypes.func,
    t: PropTypes.func
}

export default MPowerAlerts