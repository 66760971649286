import { multiPaymentNgpConstants } from '../../../constants/payment/multi-payment-ngp-constants'
import { RSAA } from 'redux-api-middleware'
import utils from '../../../srp_modules/utils'

export const verifyPurchases = () => (dispatch, getState) => {
    let state = getState()
    let purchaseList = state.multiPaymentNgp.purchaseList.map(purchase => {
         return {
            billAccountNumber: purchase.billAccount,
            purchaseAmount: purchase.purchaseAmount,
            payAmountOwedToSRP: purchase.payAmountOwedToSRP
            }
        })

    const bankAccountId = state.multiPaymentNgp.selectedBankAccountId
    const bankAccount = state.bankAccounts.bankAccountList.find(ba => ba.bankId === bankAccountId)
    const bankAccountIdToken = (bankAccount === undefined) ? "" : bankAccount.bankIdToken

    let purchaseInfo = {
        bankAccountIdToken: bankAccountIdToken,
        purchaseList: purchaseList
    }

    return dispatch({
        [RSAA]: {
            endpoint: utils.createUrl('payments/verifypurchaselist'),
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include',
            body: JSON.stringify(purchaseInfo),
            types: [
                multiPaymentNgpConstants.VERIFY_MULTIPLE_NGP_REQUEST,
                multiPaymentNgpConstants.VERIFY_MULTIPLE_NGP_SUCCESS,
                multiPaymentNgpConstants.VERIFY_MULTIPLE_NGP_FAILURE
            ]
        },
        meta: {
            debounce: {
                leading: false,
                trailing: false
            }
        }
    })
}

export const submitPurchases = () => (dispatch, getState) => {
    let state = getState()

    let bankAccount = state.bankAccounts.bankAccountList.find(ba => ba.bankId === state.multiPaymentNgp.selectedBankAccountId)
    let bankAccountIdToken = (bankAccount === undefined) ? "" : bankAccount.bankIdToken

    let purchaseList = state.multiPaymentNgp.purchaseList.map(purchase => {
        return {
           billAccountNumber: purchase.billAccount,
           purchaseAmount: purchase.purchaseAmount,
           payAmountOwedToSRP: purchase.payAmountOwedToSRP
           }
       })

    let billAccountList = state.accountInfo.billAccount.billAccountList
    let allCommercial = false
    if (purchaseList && billAccountList && purchaseList.length > 0) {
        allCommercial = purchaseList.every(p => billAccountList.find(b => b.account === p.billAccountNumber)?.isCommercial ?? false)
    }

    let purchaseInfo = {
        bankAccountIdToken: bankAccountIdToken,
        purchaseList: purchaseList,
        isReconnectAuthorized: "true", //always send yes for now until echex can change on their end to remove from endpoint US #84438
        sendCommercialConfirmationEmail: allCommercial,
        bankSignerFirstNameForConfirmationEmail: bankAccount.firstName,
        bankSignerLastNameForConfirmationEmail: bankAccount.lastName,
        bankSignerEmailAddressForConfirmationEmail: bankAccount.email,
        bankAccountNumberTruncatedForConfirmationEmail: bankAccount.accountNumber,
        referenceNumberFromValidatePurchase: state.multiPaymentNgp.verifyMultiPurchaseResult.referenceNumberWhenNoError
    }
    
    return dispatch({
        [RSAA]: {
            endpoint: utils.createUrl('payments/submitpurchaselist_emailconfirmation'),
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include',
            body: JSON.stringify(purchaseInfo),
            types: [
                multiPaymentNgpConstants.SUBMIT_MULTIPLE_NGP_REQUEST,
                multiPaymentNgpConstants.SUBMIT_MULTIPLE_NGP_SUCCESS,
                multiPaymentNgpConstants.SUBMIT_MULTIPLE_NGP_FAILURE
            ]
        }
    })
}

export const filterAccountsText = (enabled, filterText) => ({
    type: multiPaymentNgpConstants.FILTER_ACCOUNT_TEXT_NGP,
    enabled,
    filterText
})

export const resetMultipleMpowerInfo = () => ({
    type: multiPaymentNgpConstants.RESET_MULTIPLE_NGP_INFO
})

export const selectAllAccounts = () => ({
    type: multiPaymentNgpConstants.SELECT_ALL_MPOWER_ACCOUNTS
})

export const selectFirst100Accounts = () => ({
    type: multiPaymentNgpConstants.SELECT_FIRST_100_MPOWER_ACCOUNTS
})

export const clearPurchaseList = () => ({
    type: multiPaymentNgpConstants.CLEAR_MULTIPLE_NGP_LIST
})

export const getSelectedBankAccountForMultiPayment = (bankAccountId) => ({
    type: multiPaymentNgpConstants.GET_SELECTED_BANK_ACCOUNT_MULTI_NGP,
    bankAccountId
})

export const getSelectedMpowerAccount = (billAccount, paymentInfo) => ({
    type: multiPaymentNgpConstants.SELECT_MPOWER_ACCOUNT,
    billAccount, paymentInfo
})

export const getPurchaseAmount = (billAccount, purchaseAmount) => ({
    type: multiPaymentNgpConstants.GET_MULTIPLE_PURCHASE_AMOUNT,
    billAccount, purchaseAmount
})

export const getPayAmountOwedToSrp = (billAccount) => ({
    type: multiPaymentNgpConstants.GET_PAY_AMOUNT_OWED_TO_SRP,
    billAccount
})

export const setFormStartEventEmitted = () => ({
    type: multiPaymentNgpConstants.SET_FORM_START_EVENT_EMITTED
})

export const clearFormStartEventEmitted = () => ({
    type: multiPaymentNgpConstants.CLEAR_FORM_START_EVENT_EMITTED
})

export const clearSubmitPurchaseError = () => ({
    type: multiPaymentNgpConstants.CLEAR_SUBMIT_PURCHASE_ERROR
})