import React from 'react'
import PropTypes from 'prop-types'
import TextField from '@mui/material/TextField'
import MenuItem from '@mui/material/MenuItem'
import Radio from '@mui/material/Radio'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Divider from '@mui/material/Divider'

import BillingHistoryTable from './billing-history-table'
import UsageHistoryTable from './usage-history-table'

import { billPageConstants } from '../../../constants/bill-page-constants'

const AccountHistoryCard = ({numOfBills, numOfBillsDisplayed, getNumOfBillsToDisplayOnChange, historyType, getAccountHistoryTypeOnChange, showDetailedTransactionList,
    billTableStyle, usageTableStyle, toggleDetailedTransactionList, getPrevAccountHistoryOnClick, getNextAccountHistoryOnClick, accountHistoryIndex, accountHistoryList,
    eBillDocumentList, selectedBillAccount, billingAndPaymentHistoryList, isResidentialIDR, t}) => {

    return(
        <div className="srp-card-body">
            <div className="srp-card-details pb-0">
                <div className="d-none d-lg-block">                        
                    <div className="form-inline">
                        {/* <label className="mr-2 text-muted" style={{verticalAlign: 'middle'}}>Number of bills displayed</label> */}
                        <TextField
                            select
                            label={t("Number of bills displayed")}
                            value={numOfBills} 
                            onChange={event=>getNumOfBillsToDisplayOnChange(event.target.value, accountHistoryList.length)}
                            style={{verticalAlign: 'middle', margin: "0px", width: "20%"}}
                        >
                            <MenuItem value={3}>3</MenuItem>
                            <MenuItem value={6}>6</MenuItem>
                            <MenuItem value={12}>12</MenuItem>
                            <MenuItem value={24}>24</MenuItem>
                            <MenuItem value={36}>36</MenuItem>
                        </TextField>
                                                    
                        <label className="ml-4 mr-3 text-muted" style={{verticalAlign: 'middle'}}>{t("History type")}</label>
                        <FormGroup row>
                            <FormControlLabel
                                control={<Radio 
                                    checked={historyType === billPageConstants.HISTORY_TYPE_BILLING}
                                    onChange={event=>getAccountHistoryTypeOnChange(event.target.value)}
                                    value={billPageConstants.HISTORY_TYPE_BILLING}
                                    aria-label="Billing/Payment"
                                    color="primary"
                                />}
                                label={t("Billing/Payment")}
                            />
                            <FormControlLabel
                                control={<Radio 
                                    checked={historyType === billPageConstants.HISTORY_TYPE_USAGE}
                                    onChange={event=>getAccountHistoryTypeOnChange(event.target.value)}
                                    value={billPageConstants.HISTORY_TYPE_USAGE}
                                    aria-label="Usage"
                                    color="primary"
                                />}
                                label={t("Usage")}
                            />
                        </FormGroup>
                        
                        <label className="ml-4 mr-2 text-muted" style={historyType === billPageConstants.HISTORY_TYPE_USAGE ? {display: 'none'} : {verticalAlign: 'middle'}} >{t("Detailed transaction list")}</label>
                        <label className="switch" style={historyType === billPageConstants.HISTORY_TYPE_USAGE ? {display: 'none'} : {display: 'block'}}>
                            <input type="checkbox" checked={showDetailedTransactionList} onChange={()=>toggleDetailedTransactionList()} />
                            <span className="slider"/>
                        </label>
                    </div>
                </div>

                <div className="d-block d-lg-none">
                    <div className="row mb-3">
                        {/* <div className="col-4 pr-1 mt-1 text-right">
                            <label className="mr-2 text-muted">History type</label>
                        </div> */}
                        <div className="col-6 pl-1">
                            <TextField
                                select
                                value={historyType} 
                                onChange={event=>getAccountHistoryTypeOnChange(event.target.value)}
                                fullWidth
                                label={t("History type")}
                            >
                                <MenuItem value={billPageConstants.HISTORY_TYPE_BILLING}>{t("Billing/payment")}</MenuItem>
                                <MenuItem value={billPageConstants.HISTORY_TYPE_USAGE}>{t("Usage")}</MenuItem>
                            </TextField>
                        </div>
                    </div>
                    <div className="form-inline d-flex justify-content-end mb-3">
                        <label className="mr-2 text-muted" style={historyType === billPageConstants.HISTORY_TYPE_USAGE ? {display: 'none'} : {verticalAlign: 'middle'}} >{t("Detailed transaction list")}</label>
                        <label className="switch" style={historyType === billPageConstants.HISTORY_TYPE_USAGE ? {display: 'none'} : {display: 'block'}}>
                            <input type="checkbox" checked={showDetailedTransactionList} onChange={()=>toggleDetailedTransactionList()} />
                            <span className="slider"/>
                        </label>
                    </div>
                </div>
            </div>
            
            <div className="d-block d-lg-none">
                <Divider/>
            </div>

            <div className="srp-card-details">
                <div>
                    <div style={billTableStyle}>
                        <BillingHistoryTable
                            numOfBillsDisplayed={numOfBillsDisplayed}
                            accountHistoryList={accountHistoryList}
                            getPrevBillingHistoryOnClick={getPrevAccountHistoryOnClick}
                            getNextBillingHistoryOnClick={getNextAccountHistoryOnClick}
                            billingHistoryIndex={accountHistoryIndex}
                            eBillDocumentList={eBillDocumentList}
                            selectedBillAccount={selectedBillAccount}
                            billingAndPaymentHistoryList={billingAndPaymentHistoryList}
                            showDetailedTransactionList={showDetailedTransactionList} 
                            t={t}
                        />
                    </div>

                    <div style={usageTableStyle}>
                        <UsageHistoryTable
                            numOfBillsDisplayed={numOfBillsDisplayed}
                            accountHistoryList={accountHistoryList}
                            getPrevUsageHistoryOnClick={getPrevAccountHistoryOnClick}
                            getNextUsageHistoryOnClick={getNextAccountHistoryOnClick}
                            usageHistoryIndex={accountHistoryIndex}
                            eBillDocumentList={eBillDocumentList}
                            selectedBillAccount={selectedBillAccount}
                            isResidentialIDR={isResidentialIDR} 
                            t={t}
                        />
                    </div>
                </div>
            </div>
        </div>

    )

}

AccountHistoryCard.propTypes = {    
    selectedBillAccount: PropTypes.number,
    numOfBills: PropTypes.number,
    numOfBillsDisplayed: PropTypes.number,
    getNumOfBillsToDisplayOnChange: PropTypes.func,
    historyType: PropTypes.string,
    getAccountHistoryTypeOnChange: PropTypes.func,
    showDetailedTransactionList: PropTypes.bool,
    toggleDetailedTransactionList: PropTypes.func,
    billTableStyle: PropTypes.object,
    usageTableStyle: PropTypes.object,
    getPrevAccountHistoryOnClick: PropTypes.func,
    getNextAccountHistoryOnClick: PropTypes.func,
    accountHistoryIndex: PropTypes.number,

    accountHistoryList: PropTypes.arrayOf(PropTypes.object),
    eBillDocumentList: PropTypes.arrayOf(PropTypes.object),
    billingAndPaymentHistoryList: PropTypes.arrayOf(PropTypes.object),

    isResidentialIDR: PropTypes.bool,
    t: PropTypes.func.isRequired
}

export default AccountHistoryCard