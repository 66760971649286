import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import '../../../styles/dashboard-page.css'
import format from '../../../srp_modules/format'
import utils from '../../../srp_modules/utils'
import { withRouter } from '../../../srp_modules/with-router'
import { getBankAccountToEdit } from '../../../actions/auth/payment/bank-account-actions'
import SubmittedMultiPaymentNgpPage from './submitted-multi-payment-ngp-page'

let getSelectedBank = (bankAccountList, selectedBankAccountId) => {
    const selectedBank = bankAccountList.find((bankAccount) => bankAccount.bankId === selectedBankAccountId)
    return selectedBank
}

let getTotalPurchase = (purchaseList) => {
    let total = 0
    for (let i = 0; i < purchaseList.length; i++) {
        total += utils.paymentToNumber(purchaseList[i].purchaseAmount)
    }
    return total
}

class SubmittedMultiPaymentNgpPageContainer extends React.Component {
    constructor(props) {
        super(props)

        if (this.isStateEmpty())
            this.props.router.navigate('/myaccount/payment/multi/mpower', { replace: true })
    }

    isStateEmpty() {
        return this.props.submitMultiPurchaseResult.referenceNumber === undefined
            || this.props.submitMultiPurchaseResult.referenceNumber === 0
    }

    render() {
        return (
            !this.isStateEmpty() &&
            <div>
                <SubmittedMultiPaymentNgpPage
                    submitMultiPurchaseResult={this.props.submitMultiPurchaseResult}
                    selectedBankAccountDescription={this.props.selectedBankAccountDescription}
                    totalPurchase={getTotalPurchase(this.props.purchaseList)}
                    purchaseList={this.props.purchaseList}
                    paymentInfoList={this.props.paymentInfoList}
                    selectedBank={this.props.selectedBank}
                    updateBankEmailAddressOnClick={this.props.updateBankEmailAddressOnClick}
                 />
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const selectedBank = getSelectedBank(state.bankAccounts.bankAccountList, state.multiPaymentNgp.selectedBankAccountId)

    const purchaseList = state.multiPaymentNgp.purchaseList
    const paymentInfoList = state.multiPaymentNgp.mpowerPaymentInfoList

    return {
        purchaseList,
        paymentInfoList,
        submitMultiPurchaseResult: state.multiPaymentNgp.submitMultiPurchaseResult,
        selectedBankAccountDescription: selectedBank === undefined ? '' : format.bankAccount(selectedBank),
        selectedBank
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        updateBankEmailAddressOnClick: (selectedBank) => {
            if (selectedBank === undefined) {
                ownProps.router.navigate('/myaccount/profile/banks')
            } else {
                dispatch(getBankAccountToEdit(selectedBank))
                ownProps.router.navigate('/myaccount/profile/banks/editbankaccount')
            }
        }
    }
}

SubmittedMultiPaymentNgpPageContainer.propTypes = {
    purchaseList: PropTypes.arrayOf(PropTypes.object),
    paymentInfoList: PropTypes.arrayOf(PropTypes.object),
    submitMultiPurchaseResult: PropTypes.object,
    selectedBankAccountDescription: PropTypes.string.isRequired,
    router: PropTypes.shape({
        navigate: PropTypes.func
    }),
    selectedBank: PropTypes.object,
    updateBankEmailAddressOnClick: PropTypes.func.isRequired
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SubmittedMultiPaymentNgpPageContainer))