import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import 'react-widgets/styles.css'

import AccountManagerContainer from './account-manager-container'
import AccountSelectorContainer from './account-selector-container'
import MyAccountPageHeaderResponsiveContainer from '../myaccount-page-header-responsive-container'
import ServiceAddressContainer from './service-address-container'
import PricePlanContainer from './price-plan-container'
import BarCodeContainer from './bar-code-container'
const AccountInfoBar = ({disableSelector, displayLandlordLink, t, i18n}) => {
    return (
        <div className="row mt-4">
            {displayLandlordLink
                    ? <div className="col-12 mb-2">
                        <span className="font-weight-bold mr-2">{t("Landlord account")}&nbsp;</span>
                        <Link to="/myaccount/landlord" className="text-primary-ada text-nowrap">{t("View landlord summary")}&nbsp;»</Link>
                    </div>
                    : null
            }
            <div className="col-12 col-lg-3 mr-lg-0 pr-lg-0">
                <AccountSelectorContainer
                    disableSelector={disableSelector}
                    t={t}
                />
            </div>

            <div className="col-12 col-lg-9 ml-lg-0 pl-lg-0 d-none d-lg-flex">
              <AccountManagerContainer />

                <div className="form-group ml-5">
                    <ServiceAddressContainer
                        t={t}
                     />
                </div>

                <div className="form-group ml-5">
                    <PricePlanContainer
                        t={t} i18n={i18n}
                    />
                </div>

                <div className="form-group ml-5">
                    <BarCodeContainer
                        t={t}
                     />
                </div>
            </div>

            <div className="col-12 mb-4 mb-lg-0">
                <MyAccountPageHeaderResponsiveContainer t={t} i18n={i18n}/>
            </div>
        </div>
    )
}

AccountInfoBar.propTypes = {
    disableSelector: PropTypes.bool,
    displayLandlordLink: PropTypes.bool,
    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired,
}

export default AccountInfoBar