import React from "react"
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import { withTranslation } from 'react-i18next'

import cashIconGrey from '../../../images/payment/cash-grey.svg'
import cashIconWhite from '../../../images/payment/cash-white.svg'

const iconSize = {
    height: "32px", width: "32px"
}

const mobileIconSize = {
    height: "24px", width: "24px"
}

const PayByCashCardLinkPure = ({ navPath, t }) => (
    <div>
        <div className="d-none d-lg-block">
            <NavLink to={navPath} className="nav-link flex-column align-items-center">
                <img src={cashIconGrey} style={iconSize} className="icon" />
                <img src={cashIconWhite} style={iconSize} className="iconSelected" />
                <div>{t("Cash")}</div>
            </NavLink>
        </div>
        <div className="d-block d-lg-none">
            <NavLink
                to={navPath}
                className="nav-link nav-link-mobile d-flex justify-content-start align-items-center p-1">
                <img src={cashIconGrey} style={mobileIconSize} className="icon ml-1 mr-2" />
                <img src={cashIconWhite} style={mobileIconSize} className="iconSelected ml-1 mr-2" />
                <span>{t("Cash")}</span>
            </NavLink>
        </div>
    </div>
)

PayByCashCardLinkPure.propTypes = {
    t: PropTypes.func.isRequired,
    navPath: PropTypes.string
}

const PayByCashCardLink = withTranslation('cashPaymentCard')(PayByCashCardLinkPure)
export { PayByCashCardLink as default, PayByCashCardLinkPure }