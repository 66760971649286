import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

import NavMenuButtonResponsive from './nav-menu-button-responsive'
import CallAnytime from '../../myaccount_header/call-anytime'

import { openResponsiveNav } from '../../../actions/auth/navigation/navigation-actions'

class NavMenuButtonResponsiveContainer extends React.Component {
    render() {
        return (
            <div>
                <NavMenuButtonResponsive
                    openResponsiveNavOnClick={this.props.openResponsiveNavOnClick}
                    pageTitle={this.props.pageTitle}
                    t={this.props.t}
                />
                <div className="container py-3">
                    <CallAnytime/>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return { ...state.pageHeader, ...state.accountInfo.billAccount }
}

const mapDispatchToProps = (dispatch) => {
    return {
        openResponsiveNavOnClick: () => {
            dispatch(openResponsiveNav())
        }
    }
}

NavMenuButtonResponsiveContainer.propTypes = {
    openResponsiveNavOnClick: PropTypes.func,
    pageTitle: PropTypes.string,
    t: PropTypes.func
}

export default withTranslation("navigationBar")(connect(mapStateToProps, mapDispatchToProps)(NavMenuButtonResponsiveContainer))