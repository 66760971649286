export default function drawHorizontalBars(d,data,x,y,minValue,maxValue,
    yOffset,barHeight,yAxisKey){
    let radius = 4
    
    if (d[1] - d[0] == 0) {
        return
    }
    
    if ((handleFloatEquality(d[0], minValue) && d[1] >= 0) && handleFloatEquality(d[1], maxValue)) {//positive empty bar
       return "M" + (x(d[1]) - radius) + "," + ((y(d.data[yAxisKey]) + yOffset)) +
       "h" + ((x(d[0]) - x(d[1] + d[0])) + (radius * 2)) +
       "a" + radius + "," + -radius + " 1 0 0 " + -radius + "," + radius +
       "v" + (barHeight - (2 * radius)) +
       "a" + radius + "," + -radius + " 1 0 0 " + radius + "," + radius +
       "h" + -((x(d[0]) - x(d[1] + d[0])) + (radius *2)) +
       "a" + radius + "," + radius + " 1 0 0 " + radius + "," + -radius +
       "v" + -(barHeight - (2 * radius)) +
       "a" + radius + "," + radius + " 1 0 0 " + -radius + "," + -radius +
       "z"
    }
    if (handleFloatEquality(d[0], 0) && handleFloatEquality(d[1], minValue)){//negative empty bar
        return "M" + (x(d[1])) + "," + ((y(d.data[yAxisKey]) + yOffset)) +
        "h" + ((x(d[0]) - x(d[1] + d[0])) ) +
        "v" + barHeight  +
        "h" + -((x(d[0]) - x(d[1] + d[0]))) +
        "a" + radius + "," + -radius + " 1 0 1 " + -radius + "," + -radius +
        "v" + -(barHeight - (2 * radius)) +
        "a" + radius + "," + radius + " 1 0 1 " + radius + "," + -radius +
        "z"
     }
     if (handleFloatEquality(d[0], 0) && d[1] < 0) {
        return "M" + (x(d[1]) + radius) + "," + ((y(d.data[yAxisKey]) + yOffset)) +
        "h" + ((x(d[0]) - x(d[1] + d[0])) - radius ) +
        "a" + radius + "," + -radius + " 1 0 1 " + radius + "," + radius +
        "v" + (barHeight - (2 * radius)) +
        "a" + radius + "," + -radius + " 1 0 1 " + -radius + "," + radius +
        "h" + -((x(d[0]) - x(d[1] + d[0])) - radius) +
        "v" + -barHeight +
        "z"
     }
     if (handleFloatEquality(d[1], minValue) && d[0] <= 0){
        return "M" + (x(d[1]) + (2*radius)) + "," + ((y(d.data[yAxisKey]) + yOffset)) +
        "h" + (x(d[0]) - x(d[1]) - (radius)) +
        "v" + barHeight +
        "h" + -(x(d[0]) - x(d[1]) + (radius)) +
        "a" + radius + "," + -radius + " 1 0 1 " + -radius + "," + -radius +
        "v" + -(barHeight - (2 * radius)) +
        "a" + radius + "," + radius + " 1 0 1 " + radius + "," + -radius +
        "z"
     }
    if ((handleFloatEquality(d[0], minValue) && d[1] >= 0)) {//bottom with no negative values
        return "M" + (x(d[1] - d[0])) + "," +  ((y(d.data[yAxisKey]) + yOffset)) +
            "h" + ((x(d[0]) - x(d[1] + d[0])) + radius) +
            "a" + radius + "," + -radius + " 1 0 0 " + -radius + "," + radius +
            "v" + (barHeight - (2 * radius)) +
            "a" + radius + "," + -radius + " 1 0 0 " + radius + "," + radius +
            "h" + -((x(d[0]) - x(d[1] + d[0])) + radius) +
            "v" + (-barHeight) +
            "z"
    }

    if ((handleFloatEquality(d[1], minValue) && d[0] <= 0)) {
        return "M" + (x(d[0])) + "," +  ((y(d.data[yAxisKey]) + yOffset)) +
        "h" + (-( x(d[1]) + x(d[0]))) +
        "a" + radius + "," + -radius + " 1 0 0 " + -radius + "," + radius +
        "v" + (barHeight - (2*radius)) +
        "a" + radius + "," + -radius + " 1 0 0 " + radius + "," + radius +
        "h" + ( x(d[1]) + x(d[0])) +
        "v" + -(barHeight) +
        "z"
    }

    if (d[0] < 0 && handleFloatEquality(d[0], minValue)) {
         return "M" + (x(d[1] + d[0])) + "," +  ((y(d.data[yAxisKey]) + yOffset)) +
            "h" + ((x(d[0]) - x(d[1] + d[0]))) +
            "a" + radius + "," + -radius + " 1 0 0 " + -radius + "," + radius +
            "v" + (barHeight - (radius)) +
            "a" + radius + "," + -radius + " 1 0 0 " + radius + "," + radius +
            "h" + (( x(d[1] - d[0])) - x(d[0])) +
            "v" + (barHeight) +
            "z"
    }

    if (handleFloatEquality(d[1], maxValue)) {
        return "M" + (x(d[0])) + "," +  ((y(d.data[yAxisKey]) + yOffset)) +
        "h" + ((x(d[1]) - x(d[0])) -radius) +
        "a" + radius + "," + radius + " 0 0 1 " + radius + "," + radius +
        "v" + (barHeight - (2 * radius )) +
        "a" + radius + "," + -radius + " 1 0 1 " + -radius + "," + radius +
        "h" + -((x(d[1]) - x(d[0])) - radius) +
        "v" + (-barHeight) +
        "z"
    }
    if(d[1] < 0){
        return "M" + (x(d[0]) + radius) + "," +  ((y(d.data[yAxisKey]) + yOffset)) +
        "h" + ((x(d[1]) - x(d[0]))) +
        "v" + (barHeight) +
        "h" + -((x(d[1]) - x(d[0]))) +
        "v" + (-barHeight) +
        "z"
    }

    return "M" + (x(d[0])) + "," +  ((y(d.data[yAxisKey]) + yOffset)) +
        "h" + ((x(d[1]) - x(d[0]))) +
        "v" + (barHeight) +
        "h" + -((x(d[1]) - x(d[0]))) +
        "v" + (-barHeight) +
        "z"
}

function handleFloatEquality(a, b) {
    return decimalRound(a) == decimalRound(b)
}
function decimalRound(d) {
    let num = d * 100
    return Math.round(num)/100
    
}