import React from "react"
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import PaymentHistory from './payment-history'

import { ignoreScheduledAndRecentPayments } from '../../../../actions/auth/payment/single-payment-actions'
import { myAccountConstants } from '../../../../constants/myaccount-constants'

export const PaymentHistoryContainer = ({
    scheduledPaymentsByAcct, pendingPaymentsByAcct, postedPaymentsByAcct,
    bankAccountList, username, loginEmail, isResidential,
    onMakeAnotherPaymentClicked, t, i18n
}) => (
    <PaymentHistory
        bankAccountList={bankAccountList}
        scheduledPaymentList={scheduledPaymentsByAcct}
        recentPaymentsList={pendingPaymentsByAcct.concat(postedPaymentsByAcct)}
        makeAnotherPayment={onMakeAnotherPaymentClicked}
        scheduledBy={username.trim().length == 0 ? loginEmail.trim() : username.trim()}
        supportPhoneNumber={isResidential ?
            myAccountConstants.RESIDENTIAL_CUSTOMER_SERVICE_PHONE_NUMBER_FMTTD :
            myAccountConstants.COMMERCIAL_CUSTOMER_SERVICE_PHONE_NUMBER_FMTTD}
        t={t}
        i18n={i18n}
    />
)

PaymentHistoryContainer.propTypes = {
    scheduledPaymentsByAcct: PropTypes.arrayOf(PropTypes.object).isRequired,
    pendingPaymentsByAcct: PropTypes.arrayOf(PropTypes.object).isRequired,
    postedPaymentsByAcct: PropTypes.arrayOf(PropTypes.object).isRequired,
    bankAccountList: PropTypes.arrayOf(PropTypes.object).isRequired,
    username: PropTypes.string,
    loginEmail: PropTypes.string.isRequired,
    isResidential: PropTypes.bool.isRequired,
    onMakeAnotherPaymentClicked: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    i18n: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
    username: state.login.username,
    loginEmail: state.login.loginEmail
})

const mapDispatchToProps = (dispatch) => ({
    onMakeAnotherPaymentClicked: () => {
        dispatch(ignoreScheduledAndRecentPayments())
    }
})

const PaymentHistoryContainerWithRouter = connect(mapStateToProps, mapDispatchToProps)(PaymentHistoryContainer)
export default PaymentHistoryContainerWithRouter