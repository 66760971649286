import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import CallAnytime from '../../myaccount_header/call-anytime'

const PasswordResetEmailConfirmationPage = ({emailToSendReset, t}) => {
    return (
        <div>
            <div className="container py-3">
                <CallAnytime />
                <h3 className="mt-3">{t("Forgot your password?")}</h3>
                <h5 className="text-muted">{t("Retrieve password reset instructions by email")}</h5>

                <div className="row">
                    <div className="col-lg-6 col-12 mt-4">
                        <div className="srp-card-header">
                            {t("Your password has been reset")}
                        </div>

                        <div className="srp-card-body ">
                            <div className="srp-card-details">
                                <div className="text-muted mb-2">{t("We've just sent a verification email to")} <strong>{emailToSendReset}</strong>. {t("Follow the instructions and you'll be all set!")}</div>
                                <div className="d-flex justify-content-end">
                                    <Link to="/login" className="btn srp-btn btn-lightblue">{t("Back to Login")}</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

    )
}

PasswordResetEmailConfirmationPage.propTypes = {
    emailToSendReset: PropTypes.string,
    t: PropTypes.func
}

export default withTranslation('forgotPassword')(PasswordResetEmailConfirmationPage)
