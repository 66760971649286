import React from 'react'
import PropTypes from 'prop-types'
import config from 'my-account-config'
import { withTranslation } from 'react-i18next'

const TerritoryCard = ({t}) => {
  return (
    <div>
       <div className="srp-card-header mt-4">
        {t("Where does SRP serve?")}
      </div>

      <div className="srp-card-body">
          <div className="srp-card-details">
            {t("Check out our service territory map to learn more.")}&nbsp;<a target="_blank" href={`${config.srpnetBaseUrl}about/service-area-territory`}>{t("View map »")}</a>
          </div>
      </div>
    </div>
  )
}

TerritoryCard.propTypes = {
    t: PropTypes.func.isRequired
}

export default withTranslation("startStopTransfer")(TerritoryCard)