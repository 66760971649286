import { singlePaymentBankInfoConstants } from '../../../constants/single-payment/single-payment-bank-info-constants'

const initialState = {
    bankFormOpen: false,

    isDefaultBankAccount: false,
    forUseWithIVR: false
}

export default function singlePaymentBankInfoReducer(state = initialState, action) {
    switch (action.type) {
        case singlePaymentBankInfoConstants.SINGLE_PAYMENT_STORE_BANK_INFO_COLLECTED:
            return { ...state,
                isDefaultBankAccount: action.isDefaultBankAccount,
                forUseWithIVR: action.forUseWithIVR
            }
        case singlePaymentBankInfoConstants.SINGLE_PAYMENT_CLEAR_BANK_INFO:
            return { ...state, ...initialState }

        case singlePaymentBankInfoConstants.SINGLE_PAYMENT_SET_BANK_FORM_OPEN:
            return { ...state, bankFormOpen: true }
        case singlePaymentBankInfoConstants.SINGLE_PAYMENT_CLEAR_BANK_FORM_OPEN:
            return { ...state, bankFormOpen: false }

        default:
            return state
    }
}