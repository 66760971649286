import { connect } from 'react-redux'


import SinglePaymentFormik from './single-payment-formik'

import { myAccountConstants } from '../../../constants/myaccount-constants'
import { storeBankInfoCollected, addPaymentToList, clearPaymentList, verifyPaymentList, enableMultiplePayments } from '../../../actions/guest/payment/guest-payment-actions'
import { withRouter } from '../../../srp_modules/with-router'

const mapStateToProps = state => {
    return { ...state.guestPaymentBankInfo, ...state.guestPaymentList }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
    switchToMultiplePaymentForm: () => {
        dispatch(enableMultiplePayments())
    },
    storeBankInfoAndPayment: (paymentAndBankValues) => {
        dispatch(storeBankInfoCollected(paymentAndBankValues))
        dispatch(clearPaymentList())
        dispatch(addPaymentToList(paymentAndBankValues))
    },
    validatePayment: async (goodies, validationSchema, paymentAndBankValues) => {
        const t = ownProps.t
        const i18n = ownProps.i18n
        const language = i18n.language
        const response = await dispatch(verifyPaymentList(language))
        goodies.setSubmitting(false)

        if (response.error === true)
            goodies.setStatus({ hasUnhandledeChexValidationError: true })
        else {
            if (response.payload.isSuccess)
                ownProps.router.navigate('/myaccount/payment/guest/confirmation')
            else {
                const eChexValidationErrors = response.payload.eChexValidationErrors
                const bankInputValues = {
                    bankFirstName: paymentAndBankValues.bankFirstName,
                    bankLastName: paymentAndBankValues.bankLastName,
                    bankRoutingNumber: paymentAndBankValues.bankRoutingNumber,
                    bankAccountNumber: paymentAndBankValues.bankAccountNumberLeadingZeroesAreSignificant
                }
                let numErrorsHandled = createValidationRulesFromeChexBankInfoValidationErrors(validationSchema.fields, eChexValidationErrors, bankInputValues)
                numErrorsHandled += createValidationRulesFromeChexSRPAccountNumberAndPaymentAmountValidationErrors(validationSchema.fields, eChexValidationErrors, t, i18n)
                const hasUnhandledeChexValidationError = (Object.keys(eChexValidationErrors).length - numErrorsHandled) > 0 && eChexValidationErrors.constructor === Object
                goodies.setStatus({ hasUnhandledeChexValidationError })
                goodies.validateForm()

                if (eChexValidationErrors.errorBankAccountSetupLocked)
                    ownProps.router.navigate('/myaccount/payment/guest/locked')
            }
        }
    }
})

// export for unit testing and multi-account guest payment form
export function createValidationRulesFromeChexBankInfoValidationErrors(validationRules, eChexValidationErrors, bankInputValues) {
    let numErrorsHandled = 0

    for (let errorCode in eChexValidationErrors)
        switch (errorCode) {
            case 'errorInvalidCharacters': {
                const badBankFirstName = bankInputValues.bankFirstName
                const badBankLastName = bankInputValues.bankLastName

                validationRules.bankFirstName = validationRules.bankFirstName.test(errorCode,
                    'earlyWarning::'+errorCode,
                    function (bankFirstName) {
                        const { bankLastName } = this.parent
                        return bankFirstName !== badBankFirstName
                            || bankLastName !== badBankLastName
                    })

                validationRules.bankLastName = validationRules.bankLastName.test(errorCode,
                    'earlyWarning::'+errorCode,
                    function (bankLastName) {
                        const { bankFirstName } = this.parent
                        return bankFirstName !== badBankFirstName
                            || bankLastName !== badBankLastName
                    })

                ++numErrorsHandled
                }
                break
            case 'errorBankAccountInfoMismatch': {
                const badBankFirstName = bankInputValues.bankFirstName
                const badBankLastName = bankInputValues.bankLastName
                const pairedBankRoutingNumber = bankInputValues.bankRoutingNumber
                const pairedBankAccountNumber = bankInputValues.bankAccountNumber

                validationRules.bankFirstName = validationRules.bankFirstName.test(errorCode,
                    'earlyWarning::'+errorCode,
                    function (bankFirstName) {
                        const { bankLastName, bankRoutingNumber, bankAccountNumberLeadingZeroesAreSignificant } = this.parent
                        return bankFirstName !== badBankFirstName
                            || bankLastName !== badBankLastName
                            || bankRoutingNumber !== pairedBankRoutingNumber
                            || bankAccountNumberLeadingZeroesAreSignificant !== pairedBankAccountNumber
                    })

                validationRules.bankLastName = validationRules.bankLastName.test(errorCode,
                    'earlyWarning::'+errorCode,
                    function (bankLastName) {
                        const { bankFirstName, bankRoutingNumber, bankAccountNumberLeadingZeroesAreSignificant } = this.parent
                        return bankFirstName !== badBankFirstName
                            || bankLastName !== badBankLastName
                            || bankRoutingNumber !== pairedBankRoutingNumber
                            || bankAccountNumberLeadingZeroesAreSignificant !== pairedBankAccountNumber
                    })

                ++numErrorsHandled
            }
                break
            case 'errorRoutingNumberInvalid': {
                const badBankRoutingNumber = bankInputValues.bankRoutingNumber
                validationRules.bankRoutingNumber = validationRules.bankRoutingNumber.test(errorCode,
                    'earlyWarning::'+errorCode,
                    bankRoutingNumber => bankRoutingNumber !== badBankRoutingNumber)
                ++numErrorsHandled
                }
                break
            case 'errorPaymentTypeInvalid':
            case 'errorBankAccountInfoInvalid': {
                const badBankAccountNumber = bankInputValues.bankAccountNumber
                const pairedBankRoutingNumber = bankInputValues.bankRoutingNumber
                validationRules.bankAccountNumberLeadingZeroesAreSignificant = validationRules.bankAccountNumberLeadingZeroesAreSignificant.test(errorCode,
                    'earlyWarning::'+errorCode,
                    function (bankAccountNumber) {
                        const { bankRoutingNumber } = this.parent
                        return bankAccountNumber !== badBankAccountNumber
                            || bankRoutingNumber !== pairedBankRoutingNumber
                    })
                ++numErrorsHandled
                }
                break
        }

    return numErrorsHandled
}

// export for unit testing and multi-account guest payment form
export function createValidationRulesFromeChexSRPAccountNumberAndPaymentAmountValidationErrors(validationRules, eChexValidationErrors, t, i18n) {
    let numErrorsHandled = 0

    for (let errorCode in eChexValidationErrors)
        switch (errorCode) {
            case 'errorIneligibleBillAccount':
                validationRules.srpAccountNumber = validationRules.srpAccountNumber.test(errorCode, {
                        getLocalizedErrMsg: () => (t("Online_payments_cannot_be_made_for_this_account",
                            {phone: i18n.language === 'es'
                                ? myAccountConstants.SPANISH_CUSTOMER_SERVICE_PHONE_NUMBER_FMTTD
                                : myAccountConstants.RESIDENTIAL_CUSTOMER_SERVICE_PHONE_NUMBER_FMTTD}))
                    },
                    srpAccountNumber => srpAccountNumber !== eChexValidationErrors[errorCode].srpAccountNumber)
                ++numErrorsHandled
                break
            case 'errorExceedHardLimit': {
                const errorInfo = eChexValidationErrors[errorCode]
                validationRules.paymentAmount = validationRules.paymentAmount.test(errorCode, {
                        getLocalizedErrMsg: () => (t("Payments_of_over_x_are_not_allowed", {amount: errorInfo.maxPaymentAmountAllowed}))
                    },
                    function (paymentAmount) {
                        const { srpAccountNumber } = this.parent
                        if (typeof paymentAmount === "string")
                            paymentAmount = parseFloat(paymentAmount.replace(/[$,]/g, ''))
                        return paymentAmount <= errorInfo.maxPaymentAmountAllowed || srpAccountNumber !== errorInfo.srpAccountNumber
                    })
                }
                ++numErrorsHandled
                break
        }

    return numErrorsHandled
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SinglePaymentFormik))