import React from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

import HereToHelpAd from './here-to-help-ad'
import HomeEnergyProfileAd from './home-energy-profile-ad'
import SolarEstimatorAd from './solar-estimator-ad'
import MarketplaceAd from './marketplace-ad'

import solarEstimatorImg from '../../images/ads/interestedInSolar.jpg'
import solarEstimatorImgEs from '../../images/ads/interestedInSolarEs.jpg'

const PromotionalBanner = ({ activeResidential, hashedAccountForOpower, singleBillAccount, displaySolarEstimatorAd, t, i18n }) => {
    let adColumnFormat = getAdColumnFormat(activeResidential, displaySolarEstimatorAd)
    const isSpanish = (i18n.language === "es")

    return (
        <div className="d-print-none">
            <div className="row">
                <div className={`${adColumnFormat} col-12 mt-3`}>
                    <HereToHelpAd t={t} isSpanish={isSpanish} />
                </div>
                {activeResidential &&
                <div className={`${adColumnFormat} col-12 mt-3`}>
                    <HomeEnergyProfileAd hashedAccountForOpower={hashedAccountForOpower} singleBillAccount={singleBillAccount} t={t} isSpanish={isSpanish} />
                </div>
                }
                <div className={displaySolarEstimatorAd ? `d-lg-block d-none ${adColumnFormat} mt-3` : "d-none"}>
                    <SolarEstimatorAd>
                        <img alt={t("Solar Estimate tool")} className="img-fluid w-100" src={isSpanish ? solarEstimatorImgEs : solarEstimatorImg} />
                    </SolarEstimatorAd>
                </div>
                <div className={`${adColumnFormat} col-12 mt-3`}>
                    <MarketplaceAd t={t} isSpanish={isSpanish} />
                </div>
            </div>
        </div>
    )
}

PromotionalBanner.propTypes = {
    activeResidential: PropTypes.bool.isRequired,
    hashedAccountForOpower: PropTypes.string.isRequired,
    singleBillAccount: PropTypes.bool.isRequired,
    displaySolarEstimatorAd: PropTypes.bool,
    i18n: PropTypes.shape({
        language: PropTypes.string
    }),
    t: PropTypes.func
}

export default withTranslation("promotionBanner")(PromotionalBanner)

function getAdColumnFormat(activeResidential, solarEstimator){
    if (activeResidential && solarEstimator)
        return "col-lg-3"; // show 4 ads, 3 columns each

    if (activeResidential || solarEstimator)
        return "col-lg-4"; // show 3 ads, 4 columns each


    return "col-lg-6"; // show 2 ads, 6 columns each
}