import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import '../../styles/cash-payment-page.css'
import { getBillAccounts, getServiceAddress} from '../../actions/auth/bill_account/bill-account-actions'
import { LOADING_STATUS_INIT, LOADING_STATUS_SUCCESS, LOADING_STATUS_FAILURE, LOADING_STATUS_IN_PROGRESS } from '../../constants/loading-status-constants'
import { getBarCode } from '../../actions/auth/bar_code/bar-code-actions'
import { withRouter } from '../../srp_modules/with-router'
import InitialLoadingPage from '../../components/initial-loading-page'
import CashCardError from '../../components/cash_card_page/cash-card-error'
import srpImage from '../../images/common/srp-logo-no-tag-k-min.png'
import iccImage from '../../images/common/Cone-Black.png'

function getAccountData(props,state) {
    let billAccountToUse = state.passedInBillAccount ? state.passedInBillAccount : props.selectedBillAccount
    billAccountToUse = parseInt(billAccountToUse,10)
    if (props.billAccountListStatus === LOADING_STATUS_INIT || props.billAccountListStatus === LOADING_STATUS_FAILURE)
        props.updateBillAccountList()
    if (props.billAccountListStatus !== LOADING_STATUS_SUCCESS || billAccountToUse === 0)
        return
    let billAccountToUseDetails = props.billAccountList.filter(o => o.account === billAccountToUse)
    state.billAccountDetails = billAccountToUseDetails[0]
    if (!state.billAccountDetails.isBarcodeEligible)
        return
    if (props.serviceAddressStatus === LOADING_STATUS_INIT)
        props.updateServiceAddress(billAccountToUse)
    if (props.barCodeStatus === LOADING_STATUS_INIT)
        props.updateBarCode(billAccountToUse)

}

class CashPaymentCard extends React.Component {
    constructor(props) {
        super(props)
        let shouldPrint = new URLSearchParams(document.location.search).get("print")
        let passedInBillAccount = new URLSearchParams(document.location.search).get("billAccount")
        this.setImageLoaded = this.setImageLoaded.bind(this)
        this.state = {
          imageLoadedCount: 0,
          shouldPrint: shouldPrint,
          passedInBillAccount: passedInBillAccount,
          billAccountDetails : null
        }
    }
    componentDidMount() {
        getAccountData(this.props, this.state)
    }
    shouldComponentUpdate(nextProps, nextState){
        if (nextState.imageLoadedCount !== this.state.imageLoadedCount){
            if(nextState.imageLoadedCount > 1 && this.state.shouldPrint){
                window.print()
            }
            return false
        }
        if(nextState.billAccountDetails !== this.state.billAccountDetails){
            return false
        }
        return true
    }
    componentDidUpdate(prevProps) {
        if (prevProps.billAccountListStatus !== this.props.billAccountListStatus
            && this.props.billAccountListStatus === LOADING_STATUS_FAILURE) {
            this.props.router.navigate('/error', { replace: true })
            return
        }

        getAccountData(this.props, this.state)

    }
    setImageLoaded(){
        if(this.state.shouldPrint){
            this.setState({imageLoadedCount :
                this.state.imageLoadedCount + 1})
        }
    }
    render() {
        let paymentsOrPurchases = this.props.selectedBillAccountDetails.isPrePay ? this.props.t("purchases") : this.props.t("payments")
        let display = false
        let url = ""
        if(this.props.billAccountListStatus === LOADING_STATUS_SUCCESS && !this.props.selectedBillAccountDetails.isBarcodeEligible)
        {
            return(
                <CashCardError/>
            )
        }
        if(this.props.barCodeStatus !== LOADING_STATUS_INIT && this.props.barCodeStatus !== LOADING_STATUS_IN_PROGRESS){
            display = true
            if(this.props.barCodeStatus === LOADING_STATUS_SUCCESS){
                url =  URL.createObjectURL(this.props.barCodeImage)
            }
        }
        else
        {
            return(<InitialLoadingPage/>)
        }

        return (
            <div>
            {display &&
            <div className="container d-flex full-page-div d-print-none d-none">
                <div className="row justify-content-center h-100 p-2">
                    <div id="cash-payment-card" className="col-lg-7 col-12 align-self-center p-lg-4 p-0">
                        <div className="container">
                            <div className="row pl-1">
                                <div className="col pl-0">
                                    <img id="cash-card-logo" className="img-fluid" src={srpImage}/>
                                </div>
                            </div>
                            <div className="row pl-2 pr-2 cc-text">
                                <div className="col mb-2">
                                    <div id="go-to-a-participating">{this.props.t("Go_to_participating_retail_location")}</div>
                                </div>
                            </div>
                            <div className="row pl-2 pr-2 cc-text">
                                <div className="col">
                                    <div id="for-payments-guest">
                                        {`${this.props.t("For")} ${paymentsOrPurchases} ${this.props.t("at guest services")}:`}
                                    </div>
                                </div>
                            </div>
                            <div className="row pl-2 pr-2 pb-4 font-weight-bold">
                                <div className="col-lg-6 col-12">
                                    <span>{this.props.t("Account Number")}</span>
                                    <span className="pl-2">{this.state.billAccountDetails.accountText}</span>
                                </div>

                                <div className="col-lg-6 col-12 text-lg-right">
                                    <span>{this.props.t("House Number")}</span>
                                    <span className="pl-2">{this.props.serviceAddress.houseNumber}</span>
                                </div>

                            </div>
                            {this.props.barCodeStatus === LOADING_STATUS_SUCCESS &&
                            <div>
                                <div id="for-payments-co" className="cc-text row pl-2 pr-2">
                                    <div className="col">{`${this.props.t("For")} ${paymentsOrPurchases} ${this.props.t("at checkout")}:`}</div>
                                </div>
                                <div className="row pt-4 pb-3">
                                    <div className="col-3 pl-2 pr-4 pb-2 ">
                                        <img id="icc-img" className="img-fluid mx-auto d-block float-right" src={iccImage} />
                                    </div>
                                    <div className="col-8 pl-0 pr-2">
                                        <img id="bar-code" className="img-fluid mx-auto d-block" src={url} />
                                    </div>
                                </div>
                                <div className="row pr-2 pl-2 cc-text">
                                    <div className="col">
                                        {`${this.props.t("By using barcode to make cash")} ${paymentsOrPurchases} ${this.props.t("(limit $1-$999), you agree to full terms at")} `}
                                        <a href={this.props.t("vanilla_direct_terms_link")} target="window" className="cc-a">{this.props.t("vanilla_direct_terms_text")}</a>
                                        {`; ${this.props.t("access e-receipt at")} `}
                                        <a href={this.props.t("vanilla_direct_ereceipt_link")} className="cc-a" target="window">{this.props.t("vanilla_direct_ereceipt_text")}</a>.
                                    </div>
                                </div>
                            </div>}
                        </div>
                    </div>
                </div>
            </div>
        }
        <div className="container d-print-flex ml-1 d-none">
                <div className="row justify-content-center h-100 p-2">
                    <div id="cash-payment-card" className="col-6 align-self-center ml-4 pl-4">
                        <div className="container pl-1">
                            <div className="row pl-1">
                                <div className="col pl-0">
                                    <img id="cash-card-logo" className="img-fluid" src={srpImage}/>
                                </div>
                            </div>
                            <div className="row pl-2 pr-2 cc-text">
                                <div className="col-6 mb-2">
                                    <div id="go-to-a-participating">{this.props.t("Go_to_participating_retail_location")}</div>
                                </div>
                            </div>
                            <div className="row pl-2 pr-2 cc-text">
                                <div className="col">
                                    <div id="for-payments-guest">
                                        {`${this.props.t("For")} ${paymentsOrPurchases} ${this.props.t("at guest services")}:`}
                                    </div>
                                </div>
                            </div>
                            <div className="row pl-2 pr-2 pb-4 font-weight-bold">
                                <div className="col-4">
                                    <span>{this.props.t("Account Number")}</span>
                                    <span className="pl-2">{this.state.billAccountDetails.accountText}</span>
                                </div>

                                <div className="col-5 ">
                                    <span>{this.props.t("House Number")}</span>
                                    <span className="pl-2 mr-2">{this.props.serviceAddress.houseNumber}</span>
                                </div>

                            </div>
                            {this.props.barCodeStatus === LOADING_STATUS_SUCCESS &&
                            <div>
                                <div id="for-payments-co" className="cc-text row pl-2 pr-2">
                                    <div className="col">{`${this.props.t("For")} ${paymentsOrPurchases} ${this.props.t("at checkout")}:`}</div>
                                </div>
                                <div className="row pt-4 pb-3">
                                    <div className="col-2 pl-2 pb-2 float-right">
                                        <img id="icc-img" className="img-fluid mx-auto d-inline float-right" src={iccImage} onLoad={this.setImageLoaded}/>
                                    </div>
                                    <div className="col-3 pl-0 pr-2">
                                        <img id="bar-code" className="img-fluid mx-auto d-block" src={url} onLoad={this.setImageLoaded}/>
                                    </div>
                                </div>
                                <div className="row pr-2 pl-2 cc-text col-12 mb-2">
                                    <div className="col-6">
                                        {`${this.props.t("By using barcode to make cash")} ${paymentsOrPurchases} ${this.props.t("(limit $1-$999), you agree to full terms at")} `}
                                        <a href={this.props.t("vanilla_direct_terms_link")} target="window" className="cc-a">{this.props.t("vanilla_direct_terms_text")}</a>
                                        {`; ${this.props.t("access e-receipt at")} `}
                                        <a href={this.props.t("vanilla_direct_ereceipt_link")} className="cc-a" target="window">{this.props.t("vanilla_direct_ereceipt_text")}</a>.
                                    </div>
                                </div>
                            </div>}
                        </div>
                    </div>
                </div>
            </div>
            </div>
        )
    }


}
const mapStateToProps = (state) => {
    return {...state.accountInfo.billAccount, ...state.barCode}
}

const mapDispatchToProps = dispatch => {
    return {
        updateServiceAddress: (billAccount) => {
            return dispatch(getServiceAddress(billAccount))
        },
        updateBillAccountList: () => {
            return dispatch(getBillAccounts())
        },
        updateBarCode: (billAccount) => {
            return dispatch(getBarCode(billAccount))
        }
    }
}

CashPaymentCard.propTypes = {
    selectedBillAccount: PropTypes.number.isRequired,
    selectedBillAccountDetails: PropTypes.object.isRequired,
    updateServiceAddress: PropTypes.func.isRequired,
    updateBillAccountList: PropTypes.func.isRequired,
    barCodeStatus: PropTypes.string.isRequired,
    billAccountListStatus: PropTypes.string.isRequired,
    serviceAddress: PropTypes.object.isRequired,
    barCodeImage: PropTypes.string,
    router: PropTypes.shape({
        navigate: PropTypes.func
    }),
    t: PropTypes.func.isRequired
}
export default withTranslation('cashPaymentCard')(withRouter(connect(mapStateToProps, mapDispatchToProps)(CashPaymentCard)))