import React from 'react'
import PropTypes from 'prop-types'

import { DateTime } from 'luxon'

import Icon from '@mui/material/Icon'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'

import PurchaseBreakdown from './purchase-breakdown'

import format from '../../../srp_modules/format'
import { PurchaseDetailsStyledDiv, classes } from './purchase-details-styles'

const PurchaseDetailsPanel = ({ srpAccountNumber, purchaseAmount, purchaseDate, applyWholePurchaseTowardsArrears, amountOwedToSRP, payDownFraction, defaultExpanded }) => {
    const billAccountDisplay = format.formatBillAccountNumber(srpAccountNumber)
    const purchaseAmountDisplay = purchaseAmount.toLocaleString('EN-US',{ style: 'currency', currency: 'USD' })
    const purchaseDateDisplay = DateTime.fromJSDate(purchaseDate).toFormat('M/d/yy')

    return (
    <PurchaseDetailsStyledDiv>
    <Accordion defaultExpanded={defaultExpanded} elevation={0} className="mt-2 mui-collapsed-panel-no-line purchase-details">
        <AccordionSummary expandIcon={<Icon color="primary">expand_more</Icon>} className="px-0">
            <h6 className={`${classes.column} ${classes.secondaryText} mb-0 align-self-center d-none d-lg-block`}>{billAccountDisplay}</h6>
            <h4 className={`${classes.column} ${classes.amount} mb-0 align-self-center d-none d-lg-block`}>{purchaseAmountDisplay}</h4>
            <h6 className={`${classes.column} ${classes.secondaryText} mb-0 align-self-center d-none d-lg-block`}>{purchaseDateDisplay}</h6>

            <div className="d-lg-none d-flex flex-column pr-0 w-100">
            <div className="d-flex">
            <h6 className={`${classes.secondaryText} mb-0 w-50`}>{billAccountDisplay}</h6>
            <h6 className={`${classes.secondaryText} mb-0`}>{purchaseDateDisplay}</h6>
            </div>
            <h4 className={`${classes.amount} mb-0`}>{purchaseAmountDisplay}</h4>
            </div>
        </AccordionSummary>
        <AccordionDetails className="p-0">
            <PurchaseBreakdown purchaseAmount={purchaseAmount} applyWholePurchaseTowardsArrears={applyWholePurchaseTowardsArrears} amountOwedToSRP={amountOwedToSRP} payDownFraction={payDownFraction} />
        </AccordionDetails>
    </Accordion>
    </PurchaseDetailsStyledDiv>
    )
}

PurchaseDetailsPanel.propTypes = {
    srpAccountNumber: PropTypes.string.isRequired,
    purchaseAmount: PropTypes.number.isRequired,
    purchaseDate: PropTypes.instanceOf(Date).isRequired,
    applyWholePurchaseTowardsArrears: PropTypes.bool.isRequired,
    amountOwedToSRP: PropTypes.number.isRequired,
    payDownFraction: PropTypes.number.isRequired,
    defaultExpanded: PropTypes.bool.isRequired
}

export default PurchaseDetailsPanel