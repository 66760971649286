const validate = {
    accountNumber: validateAccountNumber,
    accountNumberEmptyOk: validateAccountNumberEmptyOk,
    phoneNumber: validatePhoneNumber,
    emailAddress: validateEmailAddress,
    password: validatePassword,
    passwordLength: validatePasswordLength,
    passwordLetter: validatePasswordLetter,
    passwordNumber: validatePasswordNumber,
    passwordMatch: validatePasswordMatch,
    text: validateText,
    routingNumber: validateRoutingNumber,
    bankAccountNumber: validateBankAccountNumber,
    paymentAmount: validatePaymentAmount,
    paymentAmountEmptyOk: validatePaymentAmountEmptyOk
}

function validateAccountNumber(accountNumber) {
    let isValid = true
    if (isNaN(accountNumber)) {
        isValid = false
    } else if (accountNumber.length < 9) {
        isValid = false
    }

    return isValid
}

function validateAccountNumberEmptyOk(accountNumber) {
    let isValid = false

    if (accountNumber === undefined) {
        isValid = true
    } else if (typeof accountNumber === "string" && accountNumber.length === 0) {
        isValid = true
    } else {
        isValid = validateAccountNumber(accountNumber)
    }

    return isValid
}

function validatePhoneNumber(phoneNumber) {
    let isValid = true
    if (isNaN(phoneNumber)) {
        isValid = false
    } else if (phoneNumber.length != 10) {
        isValid = false
    }

    return isValid
}

function validateEmailAddress(emailAddress) {
    let isValid = true
    let emailFormat = /^(([-!#-'*+/-9=?A-Z^-~]+(?:\.[-!#-'*+/-9=?A-Z^-~]+)*)|("[^"]+"))@((?!..--)(?:[A-Za-z0-9](?:[-A-Z-a-z0-9]*[A-Za-z0-9])?\.)+[A-Za-z]{2,})$/
    if(!emailAddress.match(emailFormat)) {
        isValid = false
    }
    
    return isValid
}

function validatePasswordLength(password) {
    return password.length > 7
}

function validatePasswordLetter(password) {
    return /[a-z]/i.test(password)
}

function validatePasswordNumber(password) {
    return /[0-9]/.test(password)
}

function validatePassword(password) {
    return validatePasswordLength(password)
        && validatePasswordLetter(password)
        && validatePasswordNumber(password)
}

function validatePasswordMatch(password, passwordMatch)
{
    return password === passwordMatch
}

function validateText(text) {
    let isValid = true
    if (!text) {
        isValid = false
    }

    return isValid
}

function validateRoutingNumber(routingNumber) {
    let isValid = true
    if (isNaN(routingNumber)) {
        isValid = false
    } else if (routingNumber.length != 9) {
        isValid = false
    }

    return isValid
}

function validateBankAccountNumber(bankAccountNumber) {    
    let isValid = true
    if (isNaN(bankAccountNumber) || bankAccountNumber === "") {
        isValid = false
    } else if (bankAccountNumber.length < 5 || bankAccountNumber.length > 17) {
        isValid = false
    }

    return isValid
}

function validatePaymentAmount(amount) {
    let isValid = true
    if (isNaN(amount)) {
        isValid = false
    } else if (!(Number(amount) > 0.00)) {
        isValid = false
    }

    return isValid
}

function validatePaymentAmountEmptyOk(amount) {
    let isValid = false

    if (amount === undefined) {
        isValid = true
    } else if (typeof amount === "string" && amount.length === 0) {
        isValid = true
    } else {
        isValid = validatePaymentAmount(amount)
    }

    return isValid
}
export default validate