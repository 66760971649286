import {dailyUsageChartTypeConstants} from "../../constants/daily-usage-chart-type-constants"
import i18n from '../../i18n'

export function convertMonthlyDataForCharting(data, currentChartType){
    if(data != undefined && data.length > 0 ){
        const t = i18n.t
        let monthlyData = []
        if(currentChartType === dailyUsageChartTypeConstants.COST){
            data.forEach(function(e){ 
                monthlyData.push({
                    day: e.billEndDate,
                    date: e.billEndDate,
                    billStartDate: e.billStartDate,
                    billEndDate: e.billEndDate,
                    superOffPeak: 0,
                    offPeak: 0,
                    shoulder: 0,
                    onPeak: 0,
                    total: e.totalUsageCharges,
                    totalCost: e.totalUsageCharges,
                    totalKwh: 0,
                    onPeakCost: 0,
                    offPeakCost: 0,
                    shoulderCost: 0,
                    superOffPeakCost: 0,
                    showSuperOffPeakTip: e.superOffPeakKwh !== 0,
                    showOffPeakTip: e.offPeakKwh !== 0,
                    showShoulderTip: e.shoulderKwh !== 0,
                    showOnPeakTip: e.onPeakKwh !== 0,
                    showTotalTip: e.totalKwh !== 0,
                    showTotalCostTip: true,
                    })
            })
        }
        if(currentChartType === dailyUsageChartTypeConstants.USAGE
            || currentChartType === dailyUsageChartTypeConstants.NET_ENERGY){
            data.forEach(function(e){ 
                monthlyData.push({
                    day: e.billEndDate,
                    date: e.billEndDate,
                    billStartDate : e.billStartDate,
                    billEndDate: e.billEndDate,
                    superOffPeak: e.meteredSuperOffPeakUsageAmount,
                    offPeak: e.meteredOffPeakUsageAmount,
                    shoulder: e.meteredShoulderUsageAmount,
                    onPeak: e.meteredOnPeakUsageAmount,
                    totalCost: e.totalUsageCharges,
                    total: e.meteredUsageAmount,
                    showSuperOffPeakTip: e.meteredSuperOffPeakUsageAmount !== 0,
                    showOffPeakTip: e.meteredOffPeakUsageAmount !== 0,
                    showShoulderTip: e.meteredShoulderUsageAmount !== 0,
                    showOnPeakTip: e.meteredOnPeakUsageAmount !== 0,
                    showTotalTip: e.meteredUsageAmount !== 0,
                    tooltipSuperOffPeak: t("usagePage::Super off-peak") + " kWh: " + Math.round(e.meteredSuperOffPeakUsageAmount).toLocaleString('US-EN'),
                    tooltipOffPeak: t("usagePage::Off-peak") + " kWh: " + Math.round(e.meteredOffPeakUsageAmount).toLocaleString('US-EN'),
                    tooltipShoulder: t("usagePage::Shoulder") + " kWh: " + Math.round(e.meteredShoulderUsageAmount).toLocaleString('US-EN'),
                    tooltipOnPeak: t("usagePage::On-peak") + " kWh: " + Math.round(e.meteredOnPeakUsageAmount).toLocaleString('US-EN'),
                    tooltipTotal: t("usagePage::Total") + " kWh: " + Math.round(e.meteredUsageAmount).toLocaleString('US-EN')
                })
            })
        }
        if(currentChartType === dailyUsageChartTypeConstants.GENERATION){
            data.forEach(function(e){ 
                let total = setTotalGeneration(e)
                monthlyData.push({
                    day: e.billEndDate,
                    date: e.billEndDate,
                    billStartDate : e.billStartDate,
                    billEndDate: e.billEndDate,
                    superOffPeak: e.superOffPeakGeneration,
                    offPeak: e.offPeakGeneration,
                    shoulder: e.shoulderGeneration,
                    onPeak: e.onPeakGeneration,
                    totalCost: e.totalUsageCharges,
                    total: total,
                    showSuperOffPeakTip: e.superOffPeakGeneration !== 0,
                    showOffPeakTip: e.offPeakGeneration !== 0,
                    showShoulderTip: e.shoulderGeneration !== 0,
                    showOnPeakTip: e.onPeakGeneration !== 0,
                    showTotalTip: total !== 0,
                    tooltipSuperOffPeak: t("usagePage::Super off-peak") + " kWh: " + e.superOffPeakGeneration.toLocaleString('US-EN'),
                    tooltipOffPeak: t("usagePage::Off-peak") + " kWh: " + e.offPeakGeneration.toLocaleString('US-EN'),
                    tooltipShoulder: t("usagePage::Shoulder") + " kWh: " +e.shoulderGeneration.toLocaleString('US-EN'),
                    tooltipOnPeak: t("usagePage::On-peak") + " kWh: " + e.onPeakGeneration.toLocaleString('US-EN'),
                    tooltipTotal: t("usagePage::Total") + " kWh: " + total.toLocaleString('US-EN')
                })
            })
        }
        if(currentChartType === dailyUsageChartTypeConstants.EXPORT){
            data.forEach(function(e){ 
                monthlyData.push({
                    day: e.billEndDate,
                    date: e.billEndDate,
                    billStartDate : e.billStartDate,
                    billEndDate: e.billEndDate,
                    superOffPeak: 0,
                    offPeak: 0,
                    shoulder: 0,
                    onPeak: 0,
                    totalCost: 0,
                    total: e.totalReceived,
                    showTotalTip: e.totalReceived !== 0,
                    tooltipTotal: t("usagePage::Total") + " kWh: " + e.totalReceived.toLocaleString('US-EN')
                })
            })
        }
        if(currentChartType === dailyUsageChartTypeConstants.CONSUMPTION){
            data.forEach(function(e){ 
                let total = setTotalConsumption(e)
                monthlyData.push({
                    day: e.billEndDate,
                    date: e.billEndDate,
                    billStartDate : e.billStartDate,
                    billEndDate: e.billEndDate,
                    superOffPeak: e.superOffPeakConsumption,
                    offPeak: e.offPeakConsumption,
                    shoulder: e.shoulderConsumption,
                    onPeak: e.onPeakConsumption,
                    totalCost: e.totalUsageCharges,
                    total: total,
                    showOffPeakTip: e.offPeakConsumption !== 0,
                    showShoulderTip: e.shoulderConsumption !== 0,
                    showOnPeakTip: e.onPeakConsumption !== 0,
                    showSuperOffPeakTip: e.superOffPeakConsumption !== 0,
                    showTotalTip: total !== 0,
                    tooltipSuperOffPeak: t("usagePage::Super off-peak") + " kWh: " + Math.round(e.superOffPeakConsumption).toLocaleString('US-EN'),
                    tooltipOffPeak: t("usagePage::Off-peak") + " kWh: " + Math.round(e.offPeakConsumption).toLocaleString('US-EN'),
                    tooltipShoulder: t("usagePage::Shoulder") + " kWh: " + Math.round(e.shoulderConsumption).toLocaleString('US-EN'),
                    tooltipOnPeak: t("usagePage::On-peak") + " kWh: " + Math.round(e.onPeakConsumption).toLocaleString('US-EN'),
                    tooltipTotal: t("usagePage::Total") + " kWh: " + Math.round(total).toLocaleString('US-EN')
                })
            })
        }
        if(currentChartType === dailyUsageChartTypeConstants.DEMAND){
            data.forEach(function(e){ 
                let total = e.onPeakDemand > 0 ? 0 : e.demand
                monthlyData.push({
                    day: e.billEndDate,
                    date: e.billEndDate,
                    billStartDate : e.billStartDate,
                    billEndDate: e.billEndDate,
                    superOffPeak: 0,
                    offPeak: 0,
                    shoulder: 0,
                    onPeak: e.onPeakDemand,
                    totalCost: 0,
                    total: total,
                    showSuperOffPeakTip: false,
                    showOffPeakTip: false,
                    showShoulderTip: false,
                    showOnPeakTip: e.onPeakDemand !== 0,
                    showTotalTip: total !== 0,
                    showTotalCostTip: false,
                    tooltipOffPeak: t("usagePage::Off-peak demand") + " kW: " + e.offPeakDemand.toLocaleString('US-EN'),
                    tooltipShoulder: t("usagePage::Shoulder demand") + " kW: " +e.shoulderDemand.toLocaleString('US-EN'),
                    tooltipOnPeak: t("usagePage::On-peak demand") + " kW: " + e.onPeakDemand.toLocaleString('US-EN'),
                    tooltipTotal: t("usagePage::Total demand") + " kW: " + total.toLocaleString('US-EN')
                })
            })
        }

        return monthlyData
    }
    return []
}



function setTotalGeneration(billData){
    if(billData.superOffPeakGeneration === 0
        && billData.offPeakGeneration === 0
        && billData.shoulderGeneration === 0
        && billData.onPeakGeneration === 0)
             return billData.totalGeneration
    
    return 0 
}

function setTotalConsumption(billData){
    if(billData.superOffPeakConsumption === 0
        && billData.offPeakConsumption === 0
        && billData.shoulderConsumption === 0
        && billData.onPeakConsumption === 0)
             return billData.totalConsumption
    
    return 0 
}