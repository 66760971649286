import React from 'react'
import PropTypes from 'prop-types'

const SafetyStatement = ({t}) => (
    <div className="credit-advance-popup-text">
        {t("risk_of_fire_notice")}
    </div>
)

SafetyStatement.propTypes = {
    t: PropTypes.func.isRequired,
}

export default SafetyStatement