import React from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import config from 'my-account-config'

const LearnAboutMPowerCosts = ({t, i18n}) => {
  const mpowerInfoPage = i18n.language==='es'
    ? `${config.srpnetBaseUrlEs}planes-de-precio/residencial-electrico/m-power/compras-creditos-cargos#2`
    : `${config.srpnetBaseUrl}price-plans/residential-electric/m-power/purchases-credits-fees#2`

  return (
    <div>
      {t("Learn about")}
      {' '}
      <a className="displayAsLink" href={mpowerInfoPage} target="_blank">
        {t("SRP M-Power pricing and startup costs")}
      </a>
      .
    </div>
  )
}
LearnAboutMPowerCosts.propTypes = {
  i18n: PropTypes.shape({
    language: PropTypes.string.isRequired,
  }).isRequired,
  t: PropTypes.func
}

export default withTranslation('transferService')(LearnAboutMPowerCosts)