import React from "react"
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import CallAnytime from '../../myaccount_header/call-anytime'

const ConfirmSetupPage = ({t}) => {
    return (
        <div>
            <div className="container py-3">
                <CallAnytime />
                <h3 className="mt-3">{t("You're all set!")}</h3>
                <h5 className="text-muted">{t("Thank you for signing up")}</h5>

                <div className="row">
                    <div className="col-lg-6 col-12 mt-4">
                        <div className="srp-card-header">
                            {t("Verification required")}
                        </div>

                        <div className="srp-card-body">
                            <div className="srp-card-details">
                                <div className="text-muted" >
                                    {t("sent_email_verification")}
                                </div>
                                <div className="text-muted mt-2" >
                                    {t("Thank you for signing up for My Account.")}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
};

ConfirmSetupPage.propTypes = {
    t: PropTypes.func
}

export default withTranslation('accountSignUp')(ConfirmSetupPage)