import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import Divider from '@mui/material/Divider'

import { Trans } from 'react-i18next'

import utils from '../../../srp_modules/utils'

const CardBillNoBill = ({ isMPower, isCommercial, t, i18n }) => {
    const isSpanish = i18n.language==="es"
    const csPhoneNumber = isSpanish ? utils.getCSSpanishPhoneNumber() : utils.getCSPhoneNumber(isCommercial)
    const formattedCSPhoneNumber = isSpanish ? utils.getFormattedCSSpanishPhoneNumber() : utils.getFormattedCSPhoneNumber(isCommercial)

    return (
    <div className="srp-card">
        <div className="srp-card-header">
            {t(isMPower ? "My M-Power" : "My bill")}
        </div>
        <div className="srp-card-body">
            <div className="srp-card-summary d-flex justify-content-around" style={{ backgroundColor: '#747474' }}>
                <div className="d-flex flex-column align-items-center text-white">
                    <div className="h4">{t("Stay tuned")}</div>
                    {t(isMPower ? "Credit remaining info coming" : "for your first bill")}
                </div>
            </div>
            <div className="srp-card-details d-flex flex-column" style={{ color: '#707070' }}>
                <div className="d-lg-block d-none">
                    <div className="d-flex flex-wrap justify-content-end">
                        <Link to="/myaccount/payment" className="btn srp-btn btn-lightblue mb-3">{t("Make payment")}</Link>
                    </div>
                </div>
                {isMPower
                    ? <div>
                        <div className="d-lg-block d-none">
                            {t("The_credit_remaining_info_desktop", {phone: formattedCSPhoneNumber})}
                        </div>
                        <div className="d-block d-lg-none">
                            <Trans i18nKey="The_credit_remaining_info_mobile" t={t}>
                            The credit remaining information for your account should become available in 24-48 hours. Please try again later, or call us at <a href={"tel:" + csPhoneNumber}>{{phone: formattedCSPhoneNumber}}</a>.
                            </Trans>
                        </div>
                    </div>
                    : t("Your_account_has_not_yet_billed")
                }
            </div>
            <div className="d-lg-none d-block">
                <Divider />
                <div className="srp-card-details">
                    <div className="d-flex flex-wrap justify-content-end">
                        <Link to="/myaccount/payment" className="btn srp-btn btn-lightblue">{t("Make payment")}</Link>
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
}

CardBillNoBill.propTypes = {
    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired,

    isMPower: PropTypes.bool,
    isCommercial: PropTypes.bool
}

export default CardBillNoBill