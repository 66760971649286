import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

import BudgetBillingContainer from './budget_billing_auth/budget-billing-container'
import BudgetBillingAnonContainer from './budget_billing_anon/budget-billing-anon-container'

class BudgetBillingLandingPage extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {
        const t = this.props.t
        const i18n = this.props.i18n
    
        if(this.props.isLoggedIn){
            return <BudgetBillingContainer t={t} i18n={i18n} />
        }
        else{
            return <BudgetBillingAnonContainer t={t} i18n={i18n} />
        }
    }
}

const mapStateToProps = state => {
    return { ...state.login }
}

BudgetBillingLandingPage.propTypes = {
    isLoggedIn: PropTypes.bool.isRequired,
    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired,
}

export default withTranslation("budgetBilling")(connect(mapStateToProps)(BudgetBillingLandingPage))