import React from 'react'
import PropTypes from 'prop-types'

const BillProjectionError = ({refreshData}) => {

    return(
        <div className="srp-card-body">
            <div className="srp-card-details">
                <div className="text-center">
                    <div className="mb-1" style={{color: "#9E2A2B"}}><i className="material-icons" style={{fontSize:"50px"}}>error_outline</i></div>
                    <div className="mb-1" style={{color: "#9E2A2B"}}>Something went wrong</div>
                    <div className="mb-4">We were unable to retrieve bill projection data.</div>
                </div>
                <div className="d-flex justify-content-end">
                    <a onClick={()=>refreshData()} className="btn srp-btn btn-lightblue text-white mr-2">Try again</a>
                </div> 
            </div>
        </div>
    )
}

BillProjectionError.propTypes = {
    refreshData: PropTypes.func
}

export default BillProjectionError