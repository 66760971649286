import React from 'react'
import PropTypes from 'prop-types'
import { Formik } from 'formik'

import * as Yup from 'yup'

import BankInfoForm from './bank-info-form'

class BankInfoFormik extends React.Component {
    constructor(props) {
        super(props)

        this.storeBankInfoAndSelectedBankId = this.storeBankInfoAndSelectedBankId.bind(this)

        this.addBankAccountButtonClicked = this.addBankAccountButtonClicked.bind(this)
        this.addBankCancelButtonClicked = this.addBankCancelButtonClicked.bind(this)

        this.bankValidationSchema = Yup.object().shape({
            bankFormOpen: Yup.boolean(),
            bankAccountIdSelected: Yup.number()
                .when('bankFormOpen', {
                    is: false,
                    then: (schema) => schema
                        .moreThan(0, 'Invalid bank account ID.')
                }),
            bankFirstName: Yup.string()
                .when('bankFormOpen', {
                    is: true,
                    then: (schema) => schema.ensure().trim()
                        .required('First name is required.')
                }),
            bankLastName: Yup.string()
                .when('bankFormOpen', {
                    is: true,
                    then: (schema) => schema.ensure().trim()
                        .required('Last name is required.')
                        .test('not all spaces', 'Last name is required.', bankLastName => typeof bankLastName === "string" && bankLastName.trim().length > 0)
                        .test('at least 2 characters', 'Last name must have at least two characters.', bankLastName => typeof bankLastName === "string" && bankLastName.trim().length > 1)
                }),
            bankPhoneNumber: Yup.string()
                .when('bankFormOpen', {
                    is: true,
                    then: (schema) => schema.ensure().trim()
                        .transform(function (bankPhoneNumber) {
                            return this.isType(bankPhoneNumber) && bankPhoneNumber !== null ? bankPhoneNumber.replace(/[() -]/g, '') : bankPhoneNumber
                        })
                        .required('Phone number is required.')
                        .test('must have 10 digits', 'Phone number must have 10 digits.', bankPhoneNumber => /^\d{10}$/.test(bankPhoneNumber))
                }),
            bankEmailAddress: Yup.string()
                .when('bankFormOpen', {
                    is: true,
                    then: (schema) => schema.ensure().trim()
                        .required('Email address is required.')
                        .email('Invalid email address.')
                }),
            bankRoutingNumber: Yup.string()
                .when('bankFormOpen', {
                    is: true,
                    then: (schema) => schema.ensure().trim()
                        .required('Routing number is required.')
                        .test('must have 9 digits', 'Enter a valid 9-digit routing number.', bankRoutingNumber => /^\d{9}$/.test(bankRoutingNumber))
                }),
            bankAccountNumberLeadingZeroesAreSignificant: Yup.string()
                .when('bankFormOpen', {
                    is: true,
                    then: (schema) => schema.ensure().trim()
                        .required('Bank account number is required.')
                        .test('17 digits max', 'Your account number must be 1-17 digits.', bankAccountNumber => /^\d{1,17}$/.test(bankAccountNumber))
                })
        })
    }

    addBankAccountButtonClicked(setFieldValue) {
        this.props.setBankFormOpen()
        setFieldValue('bankFormOpen', true)
    }
    addBankCancelButtonClicked(setFieldValue) {
        this.props.clearBankFormOpen()
        setFieldValue('bankFormOpen', false)
    }

    storeBankInfoAndSelectedBankId(bankValues) {
        const _bankValues = this.transformValues(bankValues)
        this.props.storeBankInfo(_bankValues)
        this.props.storeSelectedBankId(_bankValues.bankAccountIdSelected)
        return _bankValues
    }

    transformValues(values) {
        return {
            ...values,
            bankAccountIdSelected: values.bankAccountIdSelected,
            bankFirstName: values.bankFirstName.trim(),
            bankLastName: values.bankLastName.trim(),
            bankPhoneNumber: values.bankPhoneNumber.replace(/[() -]/g, ''),
            bankEmailAddress: values.bankEmailAddress.trim(),
            bankRoutingNumber: values.bankRoutingNumber.trim(),
            bankAccountNumberLeadingZeroesAreSignificant: values.bankAccountNumberLeadingZeroesAreSignificant.trim(),
            bankNickname: values.bankNickname.trim()
        }
    }

    render() {
        let bankAccountIdSelected = this.props.selectedBankId
        if (bankAccountIdSelected === 0 && this.props.bankAccountList.length > 0) {
            const defaultBankAccount = this.props.bankAccountList.find((bankAccount) => bankAccount.isDefault === true)
            bankAccountIdSelected = (defaultBankAccount !== undefined) ?
                defaultBankAccount.bankId :
                this.props.bankAccountList[0].bankId
        }

        return (
            <Formik
                initialValues={{
                    bankFormOpen: this.props.bankFormOpen,
                    bankAccountIdSelected: bankAccountIdSelected,
                    bankFirstName: this.props.bankFirstName,
                    bankLastName: this.props.bankLastName,
                    bankPhoneNumber: this.props.bankPhoneNumber,
                    bankEmailAddress: this.props.bankEmailAddress,
                    bankRoutingNumber: this.props.bankRoutingNumber,
                    bankAccountNumberLeadingZeroesAreSignificant: this.props.bankAccountNumberLeadingZeroesAreSignificant,
                    bankNickname: this.props.bankNickname
                }}
                validationSchema={this.bankValidationSchema}
                onSubmit={(bankValues) => {
                    this.storeBankInfoAndSelectedBankId(bankValues)
                }}>
                {formikProps => (<BankInfoForm {...formikProps} {...this.props} {...this.state}
                    addBankAccountButtonClicked={this.addBankAccountButtonClicked}
                    addBankCancelButtonClicked={this.addBankCancelButtonClicked}
                    bankValidationSchema={this.bankValidationSchema}
                    storeBankInfoAndSelectedBankId={this.storeBankInfoAndSelectedBankId}
                />)}
            </Formik>
        )
    }
}

BankInfoFormik.propTypes = {
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired,
    t: PropTypes.func.isRequired,

    selectedBankId: PropTypes.number.isRequired,
    bankAccountList: PropTypes.arrayOf(PropTypes.shape({
        bankId: PropTypes.number.isRequired,
        bankName: PropTypes.string.isRequired,
        nickName: PropTypes.string.isRequired,
        accountNumber: PropTypes.string.isRequired
    })).isRequired,

    bankFirstName: PropTypes.string.isRequired,
    bankLastName: PropTypes.string.isRequired,
    bankPhoneNumber: PropTypes.string.isRequired,
    bankEmailAddress: PropTypes.string.isRequired,
    bankRoutingNumber: PropTypes.string.isRequired,
    bankAccountNumberLeadingZeroesAreSignificant: PropTypes.string.isRequired,
    bankNickname: PropTypes.string.isRequired,

    storeBankInfo: PropTypes.func.isRequired,
    storeSelectedBankId: PropTypes.func.isRequired,

    verifyingPaymentList: PropTypes.bool.isRequired,

    bankFormOpen: PropTypes.bool.isRequired,
    setBankFormOpen: PropTypes.func.isRequired,
    clearBankFormOpen: PropTypes.func.isRequired
}

export default BankInfoFormik