import React from 'react'
import PropTypes from 'prop-types'
import { DateTime } from 'luxon'

const RetailLocationDays = ({ days, t }) => {
    return (
        <table className="border-0">
            <tbody>
                {days.length === 0
                    ? <tr key={0}>
                        <td className="pr-2">{t("No data available.")}</td>
                    </tr>
                    : days.map(day => {
                        return (
                            <tr key={day.dayId}>
                                <td className="pr-2">{mapDayOfWeek(day.dayId, t)}</td>
                                <td>{displayHoursOfOperation(day.openTime, day.closeTime)}</td>
                            </tr>
                        )
                    })
                }
            </tbody>
        </table>
    )
}

RetailLocationDays.propTypes = {
    days: PropTypes.array
}

export default RetailLocationDays

function mapDayOfWeek(dayId, t) {
    switch (dayId) {
        case 0: return t("Sunday")
        case 1: return t("Monday")
        case 2: return t("Tuesday")
        case 3: return t("Wednesday")
        case 4: return t("Thursday")
        case 5: return t("Friday")
        case 6: return t("Saturday")
    }
}

function displayHoursOfOperation(openTime, closeTime) {
    if (openTime === "00:00" && closeTime === "00:00") {
        return "Closed"
    }
    else if(openTime === "00:01" && closeTime === "23:59") {
        return "Open 24 hours"
    }
    else {
        return `${DateTime.fromISO(openTime, "HH:mm").toLocaleString(DateTime.TIME_SIMPLE)} - ${DateTime.fromISO(closeTime, "HH:mm:ss").toLocaleString(DateTime.TIME_SIMPLE)}`
    }
}