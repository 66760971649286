import React from 'react'
import PropTypes from 'prop-types'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { connect } from 'react-redux'
import { DateTime } from 'luxon'

import SinglePaymentSelectBankForm from './single-payment-select-bank-form'

import { makePaymentInfoChange, verifySinglePayment } from '../../actions/auth/payment/payment-actions'
import { startCIXFlow } from '../../actions/auth/payment/cix-actions'
import { withRouter } from '../../srp_modules/with-router'
import paymentUtils from '../common_payment/payment-utils'

class SinglePaymentSelectBankFormik extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            dateFieldProps: paymentUtils.getDateFieldProps(props.hasScheduledPayment),
            hasSurePayWarning: this.props.payment.isSurePay && (paymentUtils.getPaymentAmount(this.props.billAccount, this.props.payment, this.props.makePaymentInfo) > 0),
            showPaymentDateWithinTwoBusinessDaysOfSurePayReleaseWarning: false,
            hasPaymentOverAmountWarning: paymentUtils.isPaymentOverAmountDue(paymentUtils.getPaymentAmount(this.props.billAccount, this.props.payment, this.props.makePaymentInfo), this.props.payment.amountDueWithShare),
            hasPaymentUnderAmountWarning: paymentUtils.isPaymentUnderAmountDue(paymentUtils.getPaymentAmount(this.props.billAccount, this.props.payment, this.props.makePaymentInfo), this.props.payment.amountDueWithShare),
            hasPaymentDateWarning: paymentUtils.isPaymentDateAfterDueDate(
                paymentUtils.getPaymentDate(this.props.billAccount, DateTime.now().toISODate(), this.props.makePaymentInfo),
                this.props.payment.dueDate) && this.props.payment.amountDueWithShare > 0
        }

        this.validationSchema = Yup.object().shape({
            shareAmount: Yup.number(),
            bankAccountId: Yup.string(),
            paymentAmount: Yup.string().ensure().trim()
                .required("A payment amount is required.")
                .test('payment amount is valid',
                    "Invalid payment amount.",
                    paymentAmount => /^(\$?\d+(,\d{3})*\.?[0-9]?[0-9]?|\$?\.\d{1,2})$/.test(paymentAmount)) // regex from https://stackoverflow.com/questions/8829765/regular-expression-for-dollar-amount-in-javascript
                .test('payment amount is a penny or more',
                    "Invalid payment amount.",
                    paymentAmount => parseFloat(paymentAmount.replace(/[$,]/g, '')) >= 0.01),
            paymentDate: Yup.date()
                .required(props.t("A payment date is required."))
                .min(this.state.dateFieldProps.minDate, this.state.dateFieldProps.minDateMessage)
                .max(this.state.dateFieldProps.maxDate, this.state.dateFieldProps.maxDateMessage)
        })

        this.isPaymentDateWithinTwoBusinessDaysOfSurePayRelease = this.isPaymentDateWithinTwoBusinessDaysOfSurePayRelease.bind(this)
        this.getPaymentAmountWarning = this.getPaymentAmountWarning.bind(this)
        this.getPaymentDateWarning = this.getPaymentDateWarning.bind(this)
        this.getPaymentWarningText = this.getPaymentWarningText.bind(this)
        this.transformValues = this.transformValues.bind(this)
    }

    componentDidMount() {
        const initialPaymentDate = paymentUtils.getPaymentDate(this.props.billAccount, this.state.dateFieldProps.minDate, this.props.makePaymentInfo)
        this.isPaymentDateWithinTwoBusinessDaysOfSurePayRelease(initialPaymentDate)
    }

    isPaymentDateWithinTwoBusinessDaysOfSurePayRelease(paymentDate) {
        const paymentDateWithinTwoBusinessDaysOfSurePayRelease = this.state.hasSurePayWarning &&
            DateTime.fromISO(this.props.payment.twoBusinessDaysBeforeSurePayReleaseDate) <= DateTime.fromISO(paymentDate)
            && DateTime.fromISO(paymentDate) <= DateTime.fromISO(this.props.payment.surePayDate)

        this.setState({
            showPaymentDateWithinTwoBusinessDaysOfSurePayReleaseWarning: paymentDateWithinTwoBusinessDaysOfSurePayRelease
        })
    }

    getPaymentAmountWarning(paymentAmount) {
        this.setState({
            hasSurePayWarning: this.props.payment.isSurePay && paymentAmount > 0,
            hasPaymentOverAmountWarning: paymentUtils.isPaymentOverAmountDue(paymentAmount, this.props.payment.amountDueWithShare),
            hasPaymentUnderAmountWarning: paymentUtils.isPaymentUnderAmountDue(paymentAmount, this.props.payment.amountDueWithShare)
        })
    }

    getPaymentDateWarning(date) {
        let dueDate = DateTime.fromISO(this.props.payment.dueDate).startOf('day').toISODate()
        let paymentDate = DateTime.fromISO(date).startOf('day').toISODate()

        this.setState({
            hasPaymentDateWarning: paymentUtils.isPaymentDateAfterDueDate(paymentDate, dueDate) && this.props.payment.amountDueWithShare > 0
        })
    }

    getPaymentWarningText() {
        let attentionText = ""

        if (this.state.hasSurePayWarning)
            attentionText = this.props.t("Surepay_may_be_canceled")
        else if (this.state.hasPaymentDateWarning)
            attentionText = this.props.t("Paying_after_due_date")
        else if (this.state.hasPaymentUnderAmountWarning)
            attentionText = this.props.t("Paying_less_warning")
        else if (this.state.hasPaymentOverAmountWarning)
            attentionText = this.props.t("You are making a payment that is more than the amount due.")

        return attentionText
    }

    transformValues(values) {
        return {
            ...values,
            paymentAmount: parseFloat(values.paymentAmount.replace(/[$,]/g, ''))
        }
    }

    render() {
        let dueDate = DateTime.fromISO(this.props.payment.dueDate).startOf('day').toISODate()
        return (
            <Formik
                initialValues={{
                    srpAccountNumber: this.props.billAccount,
                    paymentAmount: paymentUtils.getPaymentAmount(this.props.billAccount, this.props.payment, this.props.makePaymentInfo),
                    paymentDate: paymentUtils.getPaymentDate(this.props.billAccount, this.state.dateFieldProps.minDate, this.props.makePaymentInfo),
                    bankAccountId: this.props.bankAccountId,
                    shareAmount: paymentUtils.getShareAmount(this.props.makePaymentInfo)
                }}
                validationSchema={this.validationSchema}
                onSubmit={(values, formikProps) => {
                    let _values = this.transformValues(values)

                    this.getPaymentAmountWarning(Number(_values.paymentAmount))
                    this.getPaymentDateWarning(DateTime.fromISO(_values.paymentDate).startOf('day').toISODate())

                    let paymentInfo = {
                        accountNumber: _values.srpAccountNumber,
                        amount: (Number(_values.paymentAmount) + Number(_values.shareAmount)).toFixed(2),
                        bankAccountId: this.props.bankAccountId,
                        billAccount: _values.srpAccountNumber,
                        paymentAmount: (Number(_values.paymentAmount) + Number(_values.shareAmount)).toFixed(2),
                        paymentDate: _values.paymentDate,
                        paymentAmountWarningText: this.props.payment.isSurePay ?
                            this.props.t("Surepay_may_be_canceled") :
                            ((Number(_values.paymentAmount) + Number(_values.shareAmount)) < this.props.payment.amountDueWithShare
                                ? this.props.t("Paying_less_warning")
                                : Number(_values.paymentAmount) > this.props.payment.amountDueWithShare
                                    ? this.props.t("You are making a payment that is more than the amount due.")
                                    : ""),
                        paymentDateWarningText: DateTime.fromISO(_values.paymentDate) > DateTime.fromISO(dueDate) && this.props.payment.amountDueWithShare > 0
                            ? this.props.t("Paying_after_due_date") : "",
                        shareAmount: Number(_values.shareAmount).toFixed(2)
                    }

                    this.props.actions.submitPayment(this.props.bankAccountId, paymentInfo, this.validationSchema, formikProps)
                }}>
                {formikProps => (
                <SinglePaymentSelectBankForm
                    formikProps={formikProps}
                    isResidential={this.props.isResidential}
                    supportPhoneNumbers={this.props.supportPhoneNumbers}
                    onAddBankAccountClick={this.props.onAddBankAccountClick}
                    paymentDetails={this.props.payment}
                    isPaymentInfoValid={this.props.isPaymentInfoValid}
                    creditExtensionInfo={this.props.creditExtension}
                    startCIXFlowOnClick={this.props.actions.startCIXFlowOnClick}
                    dateFieldProps={this.state.dateFieldProps}
                    hasPaymentOverAmountWarning={this.state.hasPaymentOverAmountWarning}
                    hasPaymentUnderAmountWarning={this.state.hasPaymentUnderAmountWarning}
                    hasPaymentDateWarning={this.state.hasPaymentDateWarning}
                    hasSurePayWarning={this.state.hasSurePayWarning}
                    showPaymentDateWithinTwoBusinessDaysOfSurePayReleaseWarning={this.state.showPaymentDateWithinTwoBusinessDaysOfSurePayReleaseWarning}
                    dueDate={dueDate}
                    summaryBillingStatus={this.props.summaryBillingStatus}
                    formDataChanged={this.props.formDataChanged}
                    getPaymentDateWarning={this.getPaymentDateWarning}
                    getPaymentAmountWarning={this.getPaymentAmountWarning}
                    isPaymentDateWithinTwoBusinessDaysOfSurePayRelease={this.isPaymentDateWithinTwoBusinessDaysOfSurePayRelease}
                    getPaymentWarningText={this.getPaymentWarningText}
                    t={this.props.t}
                    i18n={this.props.i18n}/>)}
            </Formik>
        )
    }
}

SinglePaymentSelectBankFormik.propTypes = {
    eChexValidationErrors: PropTypes.shape({
        ErrOnSurePay: PropTypes.bool,
        ErrCashOnly: PropTypes.bool,
        ErrBankruptcy: PropTypes.bool,
        ErrClosedSrpAcct: PropTypes.bool,
        ErrInvalidBillStatus: PropTypes.bool,
        ErrInvalidCustomerAcctLen: PropTypes.bool,
        ErrInvalidSrpAcct: PropTypes.bool,
        ErrMissingSrpAccount: PropTypes.bool,
        ErrNoSrpAcct: PropTypes.bool,
        ErrNoBank: PropTypes.bool,
        ErrLockedBank: PropTypes.bool,
        ErrInvalidBankAcctLen: PropTypes.bool,
        ErrLockedBankAcct: PropTypes.bool,
        ErrExceedHardLimit: PropTypes.number,
        ErrMYAInternational: PropTypes.bool,
        ErrMYANonInternational: PropTypes.bool // ErrMYA*
    }).isRequired,
    supportPhoneNumbers: PropTypes.shape({
        residentialCustomerServicePhoneNumber: PropTypes.string.isRequired,
        residentialCustomerServicePhoneNumberSpanish: PropTypes.string.isRequired,
        commercialCustomerServicePhoneNumber: PropTypes.string.isRequired
    }).isRequired,
    showSpanish: PropTypes.bool.isRequired,
    billAccount: PropTypes.number.isRequired,
    payment: PropTypes.object.isRequired,
    isPaymentInfoValid: PropTypes.bool.isRequired,
    bankAccountId: PropTypes.number.isRequired,
    onAddBankAccountClick: PropTypes.func.isRequired,
    hasScheduledPayment: PropTypes.bool.isRequired,
    actions: PropTypes.object,
    makePaymentInfo: PropTypes.object.isRequired,
    isResidential: PropTypes.bool.isRequired,
    creditExtension: PropTypes.object.isRequired,
    summaryBillingStatus: PropTypes.number.isRequired,
    formDataChanged: PropTypes.func.isRequired,
    router: PropTypes.shape({
        navigate: PropTypes.func
    }).isRequired,
    t: PropTypes.func.isRequired,
    i18n: PropTypes.object.isRequired
}

const mapStateToProps = (state) => {
    return {
        bankAccountId: state.bankAccounts.selectedBankAccountId,
        isResidential: !state.accountInfo.billAccount.selectedBillAccountDetails.isCommercial,
        summaryBillingStatus: state.accountInfo.billAccount.selectedBillAccountDetails.summaryBillingStatus
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        actions: {
            submitPayment: async (bankAccountId, paymentInfo, validationSchema, formikProps) => {
                await dispatch(makePaymentInfoChange(paymentInfo))
                let verifyPaymentResult = await dispatch(verifySinglePayment(bankAccountId))
                formikProps.setSubmitting(false)

                if (verifyPaymentResult.error) {
                    formikProps.setStatus({ hasUnandledPaymentError: true })
                    document.getElementById("unhandledPaymentError").scrollIntoView()
                    return false
                }
                else {
                    let referenceNumber = verifyPaymentResult.payload.referenceNumberWhenNoError
                    let eChexValidationErrors = verifyPaymentResult.payload.eChexValidationErrors
                    if (referenceNumber !== 0 && (Object.keys(eChexValidationErrors).length === 0 && eChexValidationErrors.constructor === Object))
                        ownProps.router.navigate('/myaccount/payment/single/verify')
                    else {
                        let numErrorsHandled = createValidationRulesFromeChexPaymentAmountValidationErrors(validationSchema.fields, eChexValidationErrors, ownProps.t)
                        const hasUnhandledeChexValidationError = (Object.keys(eChexValidationErrors).length - numErrorsHandled) > 0
                        formikProps.setStatus({ hasUnandledPaymentError: hasUnhandledeChexValidationError })
                        formikProps.validateForm()
                    }
                }
            },
            startCIXFlowOnClick: async () => {
                await dispatch(startCIXFlow())
            }
        }
    }
}

export function createValidationRulesFromeChexPaymentAmountValidationErrors(validationRules, eChexValidationErrors, t)
{
    let numErrorsHandled = 0

    for (let errorCode in eChexValidationErrors)
        switch(errorCode)
        {
            case 'errExceedHardLimit': {
                const errorInfo = eChexValidationErrors[errorCode]
                validationRules.paymentAmount = validationRules.paymentAmount.test(
                    errorCode,
                    t("Payments of over $") + errorInfo.maxPaymentAmountAllowed + ` ${t("are not allowed.")}`,
                    function(paymentAmount) {
                        const maxPaymentAmount = errorInfo.maxPaymentAmountAllowed || Number.MAX_SAFE_INTEGER
                        return typeof paymentAmount === "string" && parseFloat(paymentAmount.trim().replace(/[$,]/g, '')) <= maxPaymentAmount
                    })
                }
                ++numErrorsHandled
                break
        }

    return numErrorsHandled
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SinglePaymentSelectBankFormik))
