import React from 'react'
import PropTypes from 'prop-types'
import SrpModal from '../../../common_modal/srp-modal'
import CardError from '../../../common_error/card-error'
import format from '../../../../srp_modules/format'

import ReconnectAdvanceDetails from './reconnect-advance-details'
import CreditAdvanceTerms from './credit-advance-terms'
import SafetyStatement from './safety-statement'

import { Trans } from 'react-i18next'

import CircularProgress from '@mui/material/CircularProgress'
import IconButton from '@mui/material/IconButton'
import Checkbox from '@mui/material/Checkbox'
class CreditAdvanceRequest extends React.Component  {
    constructor(props) {
        super(props)

        this.state = {
            displayReconnectAdvanceDetailsDialog: false,
            displayTerms: false,
            displaySafetyStatement: false,
            displayAcknowledgeError: false,
            acknowledgementChecked: false,
            isSubmitting: false
        }

        this.displayReconnectAdvanceDetailsDialog = this.displayReconnectAdvanceDetailsDialog.bind(this)
        this.toggleTerms = this.toggleTerms.bind(this)
        this.toggleSafetyStatement = this.toggleSafetyStatement.bind(this)
        this.toggleAcknowledgement = this.toggleAcknowledgement.bind(this)
        this.submitCreditAdvanceRequest = this.submitCreditAdvanceRequest.bind(this)
    }

    displayReconnectAdvanceDetailsDialog(display) {
        this.setState({displayReconnectAdvanceDetailsDialog: display})
    }

    toggleTerms(display) {
        this.setState({displayTerms: display})
    }

    toggleSafetyStatement(display) {
        this.setState({displaySafetyStatement: display})
    }

    toggleAcknowledgement() {
        let nextAcknowledgementChecked = !this.state.acknowledgementChecked
        let nextDisplayAcknowledgeError = nextAcknowledgementChecked ? false: true
    
        this.setState({acknowledgementChecked: nextAcknowledgementChecked, displayAcknowledgeError: nextDisplayAcknowledgeError})
    }

    submitCreditAdvanceRequest() {
        if (!this.state.acknowledgementChecked) {
            this.setState({ displayAcknowledgeError: true})
        } else {
            this.setState({ isSubmitting: true})
            this.props.submitMpowerSelfServiceAdvanceRequest(this.props.billAccount, this.props.mPowerSelfServiceAdvance.advanceDataToken)
        }
    }

    render() {
        const t = this.props.t

        let amountOwed =  format.formatDollarAmount(this.props.payment.amountDue + this.props.mPowerSelfServiceAdvance.advanceAmount)
        let payDownPercent = this.props.payment.payDownPercent * 100
        let requestNumber = this.props.mPowerSelfServiceAdvance.advanceTaken + 1

        const isReconnectAdvance = this.props.mPowerSelfServiceAdvance.advanceEligibility === 'ReconnectAdvance'
        const maxAdvancesAllowed = this.props.mPowerSelfServiceAdvance.advanceAllowed

        return (
            <div className="srp-card">
                        <div className="srp-card-header">
                            {t("My M-Power")}
                        </div>
                        <div className="srp-card-body">
                            <div className="srp-card-details">
                                <CardError 
                                    open={this.state.displayAcknowledgeError}
                                    message={t("Please acknowledge the safety statement")}
                                />
                                <div className="credit-advance-header" style={{ marginBottom: "5px"}}>{t("You're eligible for an advance")}</div>
                                {/* <span className="text-primary-ada">&lsaquo;&nbsp;</span> */}
                                <div className="d-flex align-items-center mb-3">
                                    <i className="material-icons text-primary-ada">keyboard_arrow_left</i>
                                    <button className="displayAsLink text-primary-ada credit-advance-back" onClick={() => this.props.toggleSelfServiceAdvance(false)}>{t("Back to Home")}</button>
                                </div>
                                {isReconnectAdvance &&
                                <div className="srp-alert-notice p-2 mb-3" style={{fontSize: "smaller"}}>
                                    {t("Your account is eligible for an additional advance.")}
                                    <button className="displayAsLink mt-2" onClick={() => this.displayReconnectAdvanceDetailsDialog(true)}><strong>{t("Why is this being offered to me?")}</strong></button>
                                </div>
                                }

                                <div className="credit-advance-text">{t("advance_to_your_meter")}</div>
                                <div className="credit-advance-dollar">${this.props.mPowerSelfServiceAdvance.advanceAmount}</div>
                                <div className="credit-advance-header">{t("Advance Details")}
                                    <IconButton
                                        style={{outline: "none", zIndex: 1}}
                                        onClick={() => this.toggleTerms(true)}
                                        size="large">
                                        <i className="material-icons md-20 srp-icon-color">help_outline</i>
                                    </IconButton></div>
                                <div style={{ border: "1px solid rgb(228,228,228)", borderRadius: "5px", paddingLeft: "15px", paddingTop: "5px", paddingBottom: "5px"}}>
                                    {!(isReconnectAdvance && requestNumber>maxAdvancesAllowed) &&
                                    <div className="mb-3">
                                        <div className="credit-advance-details-section-header">{t("Request number")}</div>
                                        <div className="credit-advance-details-section-text">{t("x_of_y_advances", {requestNumber, maxAdvancesAllowed})}</div>
                                    </div>
                                    }
                                    <div className="mb-3">
                                        <div className="credit-advance-details-section-header">{t("Pay back percentage")}</div>
                                        <div className="credit-advance-details-section-text">{payDownPercent}%</div>
                                    </div>
                                    <div>
                                        <div className="credit-advance-details-section-header">{t("New amount owed to SRP")}</div>
                                        <div className="credit-advance-details-section-text">{amountOwed}</div>
                                    </div>
                                </div>
                                <div className="clearfix" style={{ position: "relative", marginTop: "15px"}}>
                                    <div style={{ float: "left", width: "15%"}}>
                                        <Checkbox
                                            checked={this.state.acknowledgementChecked}
                                            onChange={() => this.toggleAcknowledgement()}
                                            color="primary"
                                            id="acknowledgementCheckbox"
                                        />
                                    </div>
                                    <div style={{ float: "right", width: "85%"}} className="credit-advance-details-acknowledgement">
                                        <label htmlFor="acknowledgementCheckbox">
                                        <Trans i18nKey="acknowledge_statement" t={t}>
                                        I acknowledge my amount owed to SRP will increase to {amountOwed}, and that credit will be <span style={{ fontWeight: "800"}}>immediately</span> sent to my meter. I understand that if my power is currently disconnected, it will be reconnected. <button className="displayAsLink credit-advance-safety-statement" onClick={() => this.toggleSafetyStatement(true)}>See safety statement&nbsp;&raquo;</button>
                                        </Trans>
                                        </label>
                                    </div>
                                </div>
                                <div style={{textAlign: "right", marginTop: "10px"}}>
                                    <button 
                                        className="btn srp-btn btn-green align-self-end" 
                                        style={{ width: "125px"}}
                                        type="button"
                                        disabled={this.state.isSubmitting}
                                        onClick={() => this.submitCreditAdvanceRequest()}>
                                        {this.state.isSubmitting ? 
                                            <CircularProgress size={20} thickness={5} style={{ color: "white"}} /> : 
                                            <span>{t("Submit request")}</span>
                                        }
                                    </button>
                                </div>
                            </div>
                        </div>
                        <SrpModal
                            open={this.state.displayReconnectAdvanceDetailsDialog}
                            title={t("Reconnect advance details")}
                            content={<ReconnectAdvanceDetails t={t} />}
                            okClick={() => this.displayReconnectAdvanceDetailsDialog(false)}
                            closeClick={() => this.displayReconnectAdvanceDetailsDialog(false)}
                        />
                        <SrpModal
                            open={this.state.displayTerms}
                            title={t("Advance details information")}
                            content={<CreditAdvanceTerms t={t} />}
                            okClick={() => this.toggleTerms(false)}
                            closeClick={() => this.toggleTerms(false)}
                        />
                        <SrpModal
                            open={this.state.displaySafetyStatement}
                            title={t("Safety statement")}
                            content={<SafetyStatement t={t} />}
                            okClick={() => this.toggleSafetyStatement(false)}
                            closeClick={() => this.toggleSafetyStatement(false)}
                        />
                    </div>
        )
    }
}

CreditAdvanceRequest.propTypes = {
    t: PropTypes.func.isRequired,
    billAccount: PropTypes.number.isRequired,
    mPowerSelfServiceAdvance: PropTypes.shape({
        advanceAmount: PropTypes.number.isRequired,
        advanceDataToken: PropTypes.string.isRequired,
        advanceAllowed: PropTypes.number.isRequired,
        advanceTaken: PropTypes.number.isRequired,
        advanceEligibility: PropTypes.string.isRequired,
    }).isRequired,
    payment: PropTypes.object.isRequired,
    submitMpowerSelfServiceAdvanceRequest: PropTypes.func.isRequired,
    toggleSelfServiceAdvance: PropTypes.func.isRequired
}

export default CreditAdvanceRequest