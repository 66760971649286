import React from 'react'
import PropTypes from 'prop-types'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableHead from '@mui/material/TableHead'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import IconButton from '@mui/material/IconButton'
import Icon from '@mui/material/Icon'
import Dialog from '@mui/material/Dialog'
import CircularProgress from '@mui/material/CircularProgress'
import { PatternFormat } from 'react-number-format'
import { Trans } from 'react-i18next'

import '../../../styles/tables.css'

import format from '../../../srp_modules/format'
import * as loadingStatus from '../../../constants/loading-status-constants'
import { myAccountConstants } from '../../../constants/myaccount-constants'

const AutopayTable = ({ autopayInfoList, showCancelAutopayModule, showCancelAutopayModuleOnClick, closeCancelAutopayModuleOnClick, autopayToCancel,
    cancelAutopayStatus, cancelAutopayOnClick, numOfAutopayToShowStart, numOfAutopayToShowEnd, getPrevAutopayOnClick, getNextAutopayOnClick,
    enrollAutopayOnClick, editAutopayOnClick, t }) => {

    let hasPendingEnrollment = autopayInfoList.find(e => e.hasPendingEnrollment) !== undefined
    return (
        <div>
            <div className="d-lg-block d-none" style={{ overflow: 'auto' }}>
                {hasPendingEnrollment
                    ? <div className="srp-alert-notice font-14 mb-3">
                            {t("SurePay enrollment pending")}
                    </div>
                    : <span />
                }
                <Table className="mb-4" id="bank-info-table">
                    <TableHead className="srp-table-head">
                        <TableRow>
                            <TableCell className="border-0" padding="none">{t("SRP account")}</TableCell>
                            <TableCell className="border-0" padding="none" style={{ width: '1px', whiteSpace: 'nowrap' }}>{t("Bank name")}</TableCell>
                            <TableCell className="border-0 text-left" >{t("Account number")}</TableCell>
                            <TableCell className="border-0" style={{ maxWidth: '41px' }} />
                            <TableCell className="border-0" style={{ width: '41px' }} />
                        </TableRow>
                    </TableHead>
                    <TableBody className="srp-table-body">
                        {autopayInfoList.slice(numOfAutopayToShowStart, numOfAutopayToShowEnd).map((row, index) => {
                            return row.isEnrolled
                                ? <TableRow key={index} className="border-0">
                                    <TableCell padding="none">
                                        {format.formatBillAccountNumber(row.billAccount)}
                                        {row.hasPendingChange && !row.hasPendingEnrollment && <span className="text-nowrap surepay-pending-chip">
                                            <span style={{marginLeft: 'auto', marginRight: 'auto'}}>{t("Pending")}</span>
                                        </span>}
                                    </TableCell>
                                    <TableCell padding="none" style={{ width: '1px', whiteSpace: 'nowrap' }}>
                                        <button className="displayAsLink align-middle" onClick={() => editAutopayOnClick(row.billAccount)}>{(row.bankAccount.institutionName)}</button>
                                    </TableCell>
                                    <TableCell className="text-left">
                                        {"****" + (row.bankAccount.bankAccountNumber)}
                                    </TableCell>
                                    <TableCell className="text-right" padding="none" style={{ width: '41px' }}>
                                        <IconButton
                                            color="primary"
                                            className="button-focus srp-table-button"
                                            style={{ textDecoration: 'none' }}
                                            onClick={() => editAutopayOnClick(row.billAccount)}
                                            size="large">
                                            <Icon>edit</Icon>
                                        </IconButton>
                                    </TableCell>
                                    <TableCell padding="none" style={{ width: '41px' }}>
                                        <IconButton
                                            color="primary"
                                            className="button-focus srp-table-button"
                                            style={{ textDecoration: 'none' }}
                                            onClick={() => showCancelAutopayModuleOnClick(row)}
                                            size="large">
                                            <Icon>delete</Icon>
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                                : row.isEligibleToEnroll
                                    ? <TableRow key={index} className="border-0">
                                        <TableCell padding="none">{format.formatBillAccountNumber(row.billAccount)}</TableCell>
                                        <TableCell padding="none" style={{ width: '1px', whiteSpace: 'nowrap' }}>- -</TableCell>
                                        <TableCell className="text-left">- -</TableCell>
                                        <TableCell className="text-right" padding="none" style={{ width: '41px' }} />
                                        <TableCell padding="none" style={{ width: '41px' }}>
                                            <IconButton
                                                color="primary"
                                                className="button-focus srp-table-button"
                                                style={{ textDecoration: 'none' }}
                                                onClick={() => enrollAutopayOnClick(row.billAccount)}
                                                size="large">
                                                <Icon>add</Icon>
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                    : <TableRow key={index} className="border-0">
                                        <TableCell padding="none">{format.formatBillAccountNumber(row.billAccount)}</TableCell>
                                        <TableCell padding="none" style={{ width: '1px', whiteSpace: 'nowrap' }}>
                                            <div className="d-flex align-items-center srp-red-text">
                                                <Icon className="mr-1">info_outlined</Icon>
                                                <span>{t("Account is not eligible for SurePay")}</span>
                                            </div>
                                        </TableCell>
                                        <TableCell className="text-left" />
                                        <TableCell className="text-right" padding="none" style={{ width: '41px' }} />
                                        <TableCell padding="none" style={{ width: '41px' }} />
                                    </TableRow>
                        })}
                    </TableBody>
                </Table>
                <div className="d-flex justify-content-start align-items-center">
                    <div className="mr-4 text-muted">{`Viewing accounts ${numOfAutopayToShowStart + 1}-${numOfAutopayToShowEnd}`}</div>
                    <button
                        onClick={() => getPrevAutopayOnClick()} disabled={numOfAutopayToShowStart === 0}
                        className="btn srp-btn btn-lightblue d-flex align-items-center mr-1">
                        {t("Previous")}
                        </button>
                    <button
                        onClick={() => getNextAutopayOnClick(autopayInfoList.length)} disabled={numOfAutopayToShowEnd === autopayInfoList.length}
                        className="btn srp-btn btn-lightblue d-flex align-items-center">
                        {t("Next")}
                        </button>
                </div>
            </div>

            <div className="d-lg-none d-block">
                {autopayInfoList.slice(numOfAutopayToShowStart, numOfAutopayToShowEnd).map((row, index) => {
                    return (
                        <div key={"autopayTableMobile" + index}>
                            <div className="row d-flex align-items-center">
                                <div className="col text-muted align-middle">{t("SRP account")}</div>
                                <div className="col align-middle text-right">{format.formatBillAccountNumber(row.billAccount)}</div>
                            </div>
                            <hr className="mt-2" />
                            <div className="row d-flex align-items-center">
                                <div className="col text-muted align-middle">{t("Bank name")}</div>
                                <div className="col align-middle text-right">
                                    {row.isEnrolled
                                        ? <button className="displayAsLink align-middle text-right" onClick={() => editAutopayOnClick(row.billAccount)}>{row.bankAccount.institutionName}</button>
                                        : row.isEligibleToEnroll
                                            ? "- -"
                                            : <div className="srp-red-text text-right d-flex justify-content-end align-items-center">
                                                <Icon className="mr-1">info_outlined</Icon>
                                                <span>{t("Account is not eligible for SurePay")}</span>
                                            </div>
                                    }
                                </div>
                            </div>
                            <hr className="mt-2" />
                            <div className="row d-flex align-items-center">
                                <div className="col text-muted align-middle">{t("Account number")}</div>
                                <div className="col align-middle text-right">
                                    {row.isEnrolled
                                        ? "****" + row.bankAccount.bankAccountNumber
                                        : row.isEligibleToEnroll
                                            ? "- -"
                                            : null
                                    }
                                </div>
                            </div>
                            <hr className="mt-2 mb-0" />
                            <div className="row d-flex align-items-center">
                                {row.isEnrolled
                                    ? <div className="col pr-0 text-right">
                                        <IconButton
                                            color="primary"
                                            className="button-focus srp-table-button"
                                            style={{ textDecoration: 'none' }}
                                            onClick={() => editAutopayOnClick(row.billAccount)}
                                            size="large">
                                            <Icon>edit</Icon>
                                        </IconButton>
                                        <IconButton
                                            color="primary"
                                            className="button-focus srp-table-button"
                                            style={{ textDecoration: 'none' }}
                                            onClick={() => showCancelAutopayModuleOnClick(row)}
                                            size="large">
                                            <Icon>delete</Icon>
                                        </IconButton>
                                    </div>
                                    : row.isEligibleToEnroll
                                        ? <div className="col pr-0 text-right">
                                            <IconButton
                                                color="primary"
                                                className="button-focus srp-table-button"
                                                style={{ textDecoration: 'none' }}
                                                onClick={() => enrollAutopayOnClick(row.billAccount)}
                                                size="large">
                                                <Icon>add</Icon>
                                            </IconButton>
                                        </div>
                                        : null
                                }
                            </div>
                            <hr className="mt-0" style={{ border: '2px solid lightgray' }} />
                        </div>
                    )
                })}
                <div className="d-flex justify-content-between align-items-center">
                    <button
                        onClick={() => getPrevAutopayOnClick()} disabled={numOfAutopayToShowStart === 0}
                        className="btn srp-btn btn-lightblue d-flex align-items-center mr-1">
                        <i className="material-icons">{'keyboard_arrow_left'}</i>
                    </button>
                    <div className="mr-4 text-muted">{`Viewing accounts ${numOfAutopayToShowStart + 1}-${numOfAutopayToShowEnd}`}</div>
                    <button
                        onClick={() => getNextAutopayOnClick(autopayInfoList.length)} disabled={numOfAutopayToShowEnd === autopayInfoList.length}
                        className="btn srp-btn btn-lightblue d-flex align-items-center">
                        <i className="material-icons">{'keyboard_arrow_right'}</i>
                    </button>
                </div>
            </div>

            <Dialog
                modal="true"
                open={showCancelAutopayModule}
                PaperProps={{ className: "srp-modal" }}
            >
                <div className="srp-modal-body">
                    <div className="srp-modal-close" onClick={() => closeCancelAutopayModuleOnClick()} />
                    <div>
                        <div className="srp-modal-header">
                            <h4 className="srp-modal-title">{t("Cancel SurePay")}</h4>
                        </div>
                        <div>
                            {autopayToCancel.isPendingRelease ?
                                <div className="srp-alert-warn">
                                    <Trans i18nKey="attention_within_2_days" t={t}>
                                        <span style={{ fontWeight: "bold" }}>ATTENTION&nbsp;&nbsp;</span>
                                        It is within 2 days of your scheduled SurePay payment date.  Changes
                                        to your SurePay enrollment will go into effect with your next bill. For questions please contact SRP
                                        {{isResidential : autopayToCancel.isResidential ? "Residential" : "Commercial"}} Customer Services at
                                        {{phoneNumber : autopayToCancel.isResidential ?
                                            myAccountConstants.RESIDENTIAL_CUSTOMER_SERVICE_PHONE_NUMBER_FMTTD :
                                            myAccountConstants.COMMERCIAL_CUSTOMER_SERVICE_PHONE_NUMBER_FMTTD}},
                                            available to serve you 24 hours a day, seven days a week.
                                    </Trans>

                                </div> :
                                <span />
                            }
                            <div className="mt-3 mb-3">{t("The following SurePay information will be cancelled.")}</div>
                            <div>{t("SRP account") + ": " + format.formatBillAccountNumber(autopayToCancel.billAccount)}</div>
                            <div>
                                {autopayToCancel.isResidential
                                    ? t("Name") + ": " + autopayToCancel.customerInfo.firstName + " " + autopayToCancel.customerInfo.lastName
                                    : t("Name") + ": " + autopayToCancel.businessInfo.businessName
                                }
                            </div>
                            <div>{t("Bank name") + ": " + autopayToCancel.bankAccount.institutionName}</div>
                            <div>{t("Routing number") + ": " + autopayToCancel.bankAccount.routingNumber}</div>
                            <div>{t("Bank account number") + ": " + "****" + autopayToCancel.bankAccount.bankAccountNumber}</div>
                            <div>{t("Email address") + ": " + autopayToCancel.emailAddress}</div>
                            <div>{t("Phone number") +": "}<PatternFormat value={autopayToCancel.phoneNumber} valueIsNumericString={true} displayType={'text'} format="(###) ###-####" /></div>
                            <div className="srp-modal-footer">
                                <button className="btn srp-btn btn-lightblue mr-2" onClick={() => closeCancelAutopayModuleOnClick()}>{t("Go back")}</button>
                                <button className="btn srp-btn btn-green" disabled={cancelAutopayStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS} onClick={() => cancelAutopayOnClick(autopayToCancel)}>
                                    {cancelAutopayStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS
                                        ? <CircularProgress size={20} thickness={5} style={{ color: 'white' }} />
                                        : t("Submit")
                                    }
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>
        </div>
    )
}

AutopayTable.propTypes = {
    autopayInfoList: PropTypes.arrayOf(PropTypes.object),
    showCancelAutopayModule: PropTypes.bool,
    showCancelAutopayModuleOnClick: PropTypes.func,
    closeCancelAutopayModuleOnClick: PropTypes.func,
    autopayToCancel: PropTypes.object,
    cancelAutopayStatus: PropTypes.string,
    cancelAutopayOnClick: PropTypes.func,
    numOfAutopayToShowStart: PropTypes.number,
    numOfAutopayToShowEnd: PropTypes.number,
    getPrevAutopayOnClick: PropTypes.func,
    getNextAutopayOnClick: PropTypes.func,
    enrollAutopayOnClick: PropTypes.func,
    editAutopayOnClick: PropTypes.func,
    t: PropTypes.func
}

export default AutopayTable
