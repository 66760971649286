import React from 'react'
import { withTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import TextField from '@mui/material/TextField'
import { PatternFormat } from 'react-number-format'

const BankRoutingNumberFieldPure = props => {
    let isError = props.touched['bankRoutingNumber']
        && Object.prototype.hasOwnProperty.call(props.errors, 'bankRoutingNumber')
    let errMsg = isError ? props.errors['bankRoutingNumber'] : ''
    let meta = {
        id: 'bankRoutingNumber',
        name: 'bankRoutingNumber',
        label: props.t("9 digit routing number"),
        helperText: errMsg==="" ? "" : props.t(errMsg),
        error: isError
    }

    let textFieldProps = {
        value: !props.value ? '' : props.value,
        onChange: props.onChange,
        onBlur: props.onBlur,
        errors: props.errors,
        touched: props.touched,
        fullWidth: props.fullWidth
    }

    return (
        <PatternFormat
            {...meta}
            {...textFieldProps}
            valueIsNumericString={true}
            format="#########"
            type="tel"
            customInput={TextField}
            InputProps={{ endAdornment: props.endAdornment }}
        />
    )
}

BankRoutingNumberFieldPure.propTypes = {
    errors: PropTypes.shape({
        bankRoutingNumber: PropTypes.string
    }).isRequired,
    fullWidth: PropTypes.bool,
    endAdornment: PropTypes.object.isRequired,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired,
    onBlur: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    touched: PropTypes.shape({
        bankRoutingNumber: PropTypes.bool
    }).isRequired,
    value: PropTypes.string.isRequired
}

const BankRoutingNumberField = withTranslation("singlePurchasePage")(BankRoutingNumberFieldPure)
export { BankRoutingNumberField as default, BankRoutingNumberFieldPure }