export const safetyNetConstants = {
    SUBMIT_SAFETYNET_FORM_REQUEST: "SUBMIT_SAFETYNET_FORM_REQUEST",
    SUBMIT_SAFETYNET_FORM_SUCCESS: "SUBMIT_SAFETYNET_FORM_SUCCESS",
    SUBMIT_SAFETYNET_FORM_FAILURE: "SUBMIT_SAFETYNET_FORM_FAILURE",

    UPDATE_SAFETYNET_REQUEST: "UPDATE_SAFETYNET_REQUEST",
    UPDATE_SAFETYNET_SUCCESS: "UPDATE_SAFETYNET_SUCCESS",
    UPDATE_SAFETYNET_FAILURE: "UPDATE_SAFETYNET_FAILURE",

    DELETE_SAFETYNET_REQUEST: "DELETE_SAFETYNET_REQUEST",
    DELETE_SAFETYNET_SUCCESS: "DELETE_SAFETYNET_SUCCESS",
    DELETE_SAFETYNET_FAILURE: "DELETE_SAFETYNET_FAILURE",

    UNDO_SAFETYNET_FORM_REQUEST: "UNDO_SAFETYNET_FORM_REQUEST",
    UNDO_SAFETYNET_FORM_SUCCESS: "UNDO_SAFETYNET_FORM_SUCCESS",
    UNDO_SAFETYNET_FORM_FAILURE: "UNDO_SAFETYNET_FORM_FAILURE",

    RESET_SAFETYNET_FORM: "RESET_SAFETYNET_FORM",

    SAFETYNET_ACTION: {
        SIGNUP: 0,
        CHANGE: 1,
        UNENROLL: 2
    }
}