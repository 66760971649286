import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'

import Greeting from './greeting'

import { getRateMetaData } from '../../actions/auth/usage/rate-meta-data-actions'
import * as loadingStatus from '../../constants/loading-status-constants'

class GreetingContainer extends React.Component {
    componentDidMount() {
        if (this.props.selectedBillAccount === 0)
            return

        if (this.props.rateMetaDataStatus === loadingStatus.LOADING_STATUS_INIT) {
            this.props.getRateMetaDataInfo(this.props.selectedBillAccount)
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.selectedBillAccount === nextProps.selectedBillAccount || nextProps.selectedBillAccount === 0)
            return

        if (nextProps.rateMetaDataStatus !== loadingStatus.LOADING_STATUS_IN_PROGRESS) {
            this.props.getRateMetaDataInfo(nextProps.selectedBillAccount)
        }
    }

    render() {
        return(
            <Greeting
                rateMetaData={this.props.rateMetaData} 
                t={this.props.t}/>
        );
    }
}

const mapStateToProps = (state) => {
    return { ...state.accountInfo.billAccount, ...state.rateMetaData }
}

const mapDispatchToProps = dispatch => {
    return {
        getRateMetaDataInfo: (billAccount) => {
            dispatch(getRateMetaData(billAccount))
        }
    }
}

GreetingContainer.propTypes = {
    rateMetaData: PropTypes.object,
    rateMetaDataStatus: PropTypes.string,
    getRateMetaDataInfo: PropTypes.func,
    selectedBillAccount: PropTypes.number,
    t: PropTypes.func
}

export default withTranslation('greetings')(connect(mapStateToProps, mapDispatchToProps)(GreetingContainer));