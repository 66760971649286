import React from "react"
import { withTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import CircularProgress from '@mui/material/CircularProgress'

import Dialog from '@mui/material/Dialog'
import Icon from '@mui/material/Icon'

import creditCardIconGrey from '../../../images/payment/credit-card-grey.png'
import creditCardIconWhite from '../../../images/payment/credit-card-white.png'

import outgoingLinkIcon from '../../../images/nav/glyphicons-390-new-window-alt.png'

const iconSize = {
    height: "32px", width: "32px"
}

const mobileIconSize = {
    height: "24px", width: "24px"
}

const PayByCreditCardLinkPure = ({ creditCardPurchaseUrl, isMpowerPurchaseAckModalOpen, mpowerPurchaseAckModalOnClick, displaySafetyStatement, toggleSafetyStatement, t }) => {
    const disableLink = (creditCardPurchaseUrl === '' || creditCardPurchaseUrl === "#")
    const target = disableLink ? "_self" : "_blank"

    return (
        <div>
            <div className="d-none d-md-block">
                <a
                    onClick={() => mpowerPurchaseAckModalOnClick(true)}
                    target={target}
                    className="nav-link d-flex flex-column align-items-center"
                    disabled={disableLink}>
                    <img src={creditCardIconGrey} style={iconSize} className="icon" />
                    <img src={creditCardIconWhite} style={iconSize} className="iconSelected" />
                    <div>
                        {t("Credit/debit card")}<br />
                        <div className="clearfix">
                            <small className="mr-1">{t("(additional fee)")}</small>
                            <img src={outgoingLinkIcon} style={{ height: "8px", width: "8xp" }} className="pull-right" />
                        </div>
                    </div>
                </a>
            </div>
            <div className="d-block d-md-none">
                <a
                    onClick={() => mpowerPurchaseAckModalOnClick(true)}
                    target={target}
                    className="nav-link d-flex justify-content-start align-items-center p-1"
                    disabled={disableLink}>
                    <img src={creditCardIconGrey} style={mobileIconSize} className="icon ml-1 mr-2" />
                    <img src={creditCardIconWhite} style={mobileIconSize} className="iconSelected ml-1 mr-2" />
                    <div>
                        <span>{`${t("Credit/debit")} `}</span>
                        <span className="clearfix">
                            <span className="mr-1">{t("(add. fee)")}</span>
                            <img src={outgoingLinkIcon} style={{ height: "8px", width: "8xp" }} className="pull-right" />
                        </span>
                    </div>
                </a>
            </div>
            <Dialog
                modal="true"
                open={isMpowerPurchaseAckModalOpen}
                PaperProps={{ className: "srp-modal" }}
            >
                <div className="srp-modal-body" id="purchaseAckModal">
                    <div className="srp-modal-close" onClick={() => mpowerPurchaseAckModalOnClick(false)} />
                    <div>
                        <div className="srp-modal-header">
                            <h4 className="srp-modal-title">{t("Proceed with Credit/Debit Purchase?")}</h4>
                        </div>
                        <div>
                            <div>
                                {t("This_purchase_will_immediately_add_credit")}
                                <button
                                    className="displayAsLink font-weight-bold"
                                    onClick={() => toggleSafetyStatement(!displaySafetyStatement)}>
                                    <span className="d-flex align-items-center">
                                        {t("safety statement.")}
                                        <Icon>
                                            {displaySafetyStatement ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
                                        </Icon>
                                    </span>
                                </button>
                            </div>
                            {displaySafetyStatement
                                ? <div className="mt-2">
                                    {t("The_Safety_Statement")}
                                </div>
                                : null
                            }
                        </div>
                        <div className="srp-modal-footer mb-0 pt-3">
                            <button className="btn srp-btn btn-lightblue mr-2" onClick={() => mpowerPurchaseAckModalOnClick(false)}>{t("Cancel")}</button>
                            <a href={disableLink ? null : creditCardPurchaseUrl} onClick={() => mpowerPurchaseAckModalOnClick(false)} className="btn srp-btn btn-blue" target={target}>
                                {disableLink ? <CircularProgress size={20} thickness={5} style={{ color: 'white' }} /> : t("OK")}
                            </a>
                        </div>
                    </div>
                </div>
            </Dialog>
        </div>
    )
}

PayByCreditCardLinkPure.propTypes = {
    creditCardPurchaseUrl: PropTypes.string.isRequired,
    isMpowerPurchaseAckModalOpen: PropTypes.bool,
    mpowerPurchaseAckModalOnClick: PropTypes.func,
    displaySafetyStatement: PropTypes.bool,
    toggleSafetyStatement: PropTypes.func,

    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired
}

const PayByCreditCardLink = withTranslation("singlePurchasePage")(PayByCreditCardLinkPure)
export { PayByCreditCardLink as default, PayByCreditCardLinkPure }