import utils from '../../../srp_modules/utils'
import { eBillConstants } from '../../../constants/ebill-constants'
import { RSAA } from 'redux-api-middleware'

export const getEBillList = (billAccount) => dispatch => {
    return dispatch({
        [RSAA]: {
            endpoint: utils.createUrl('documents/eBillList', { billAccount: billAccount }),
            method: 'GET',
            credentials: 'include',
            types: [
                eBillConstants.GET_E_BILL_LIST_REQUEST,
                eBillConstants.GET_E_BILL_LIST_SUCCESS,
                eBillConstants.GET_E_BILL_LIST_FAILURE
            ]
        }
    })
}