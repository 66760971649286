import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { withTranslation } from 'react-i18next'

import AccountInfoBarContainer from '../../myaccount_header/info_bar/account-info-bar-container'
import CallAnytime from '../../myaccount_header/call-anytime'

import SuccessCheck from '../../../images/common/SuccessCheck.png'

import { myAccountConstants } from '../../../constants/myaccount-constants'
import * as loadingStatus from '../../../constants/loading-status-constants'

const TransferServiceConfirmation = ({ submitTransferServiceStatus, t }) => {
    return (
        <div>
            <div className="container py-3">
                <CallAnytime />
                <h3 className="d-lg-block d-none mt-3 mb-4 pb-4">{t("Transfer my electric service")}</h3>
                <h3 className="d-lg-none d-block mt-3 mb-3">{t("Transfer my electric service")}</h3>

                <AccountInfoBarContainer disableSelector={true} />

                {submitTransferServiceStatus !== loadingStatus.LOADING_STATUS_FAILURE
                    ? <div className="row" id="topOfConfirmation">
                        <div className="col-lg-7 col-12 mt-4">
                            <div className="srp-card-header">{t("Transfer of service request submitted")}</div>
                            <div className="srp-card-body text-muted">
                                <div className="srp-card-details">
                                    <div className="d-lg-block d-none">
                                        <div className="mb-3 srp-olive-green d-flex align-items-center">
                                            <img className="img-fluid mr-3" src={SuccessCheck} style={{ width: '50px' }} />
                                            <span style={{ fontSize: '1.75rem', fontWeight: '500' }}>{t("You're all set!")}</span>
                                        </div>
                                    </div>
                                    <div className="d-lg-none d-block">
                                        <div className="mb-3 srp-olive-green text-center">
                                            <img className="img-fluid mr-3" src={SuccessCheck} style={{ width: '50px' }} />
                                            <div style={{ fontSize: '1.75rem', fontWeight: '500' }}>{t("You're all set!")}</div>
                                        </div>
                                    </div>

                                    <div className="mb-4">
                                        {t("thank_you_for_submitting_request")}
                                    </div>

                                    <div className="d-flex flex-wrap justify-content-end">
                                        <Link to="/myaccount/dashboard" className="btn srp-btn btn-lightblue">{t("Go to dashboard")}</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    : <div className="row" id="topOfConfirmation">
                        <div className="col-lg-7 col-12">
                            <div className="srp-card-header">{t("Unable to submit your request")}</div>
                            <div className="srp-card-body text-muted">
                                <div className="srp-card-details">
                                    <div className="mb-3">
                                        {t("unable_to_submit_request_description")}
                                    </div>
                                    <p className="d-lg-block d-none mb-0">
                                        {`${t("please_try_again_later")} 
                                        ${myAccountConstants.RESIDENTIAL_CUSTOMER_SERVICE_PHONE_NUMBER_FMTTD}, 
                                        ${t("available_24_hours")}.`}
                                    </p>
                                    <p className="d-lg-none d-block mb-0">
                                        {`${t("please_try_again_later")} `}
                                        <a href={"tel:" + myAccountConstants.RESIDENTIAL_CUSTOMER_SERVICE_PHONE_NUMBER}>
                                            {myAccountConstants.RESIDENTIAL_CUSTOMER_SERVICE_PHONE_NUMBER_FMTTD}
                                        </a>
                                        {`, ${t("available_24_hours")}.`}
                                    </p>

                                    <div className="d-flex flex-wrap justify-content-end">
                                        <Link to="/myaccount/dashboard" className="btn srp-btn btn-lightblue">{t("Go to dashboard")}</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    )

}

TransferServiceConfirmation.propTypes = {
    submitTransferServiceStatus: PropTypes.string,
    t: PropTypes.func
}

export default withTranslation('transferService')(TransferServiceConfirmation)