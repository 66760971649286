import drawHorizontalBars from './draw-horizontal-bars'
import {dailyUsageChartTypeConstants} from '../../constants/daily-usage-chart-type-constants'
import * as d3 from 'd3'
import { DateTime } from 'luxon'
import {displaySizeConstants} from '../../constants/display-size-constants'
import {getDisplaySize} from '../../srp_modules/display-size'
import {convertDailyDataForCharting} from '../../srp_modules/charting/daily-data-dto'
import NetCompliantChartData from './net-compliant-chart-data'
const standardChartKeys = ["superOffPeak", "offsetSuperOff", "offPeak", "offsetOff", "shoulder", "offsetShoulder", "onPeak", "offsetOn", "total", "offsetTotal", "min", "offsetMin", "max"]
const margin = {
    top: 20,
    right: 20,
    bottom: 80,
    left: 100
}

export default function drawHorizontalChart(data,weatherData,highDemandInfoList,usageChartType,hasOnOffShoulder,barClickCallback,isMPower, t) {
    window.daily_usage_selected_date =  ""
    let highDemandDate = highDemandInfoList.length > 0
        ? highDemandInfoList[0].isCurrentBill
            ? highDemandInfoList[0].onPeakHighDemand > 0
                ? highDemandInfoList[0].onPeakHighDemandDate
                : highDemandInfoList[0].highDemand > 0
                    ? highDemandInfoList[0].highDemandDate
                    : undefined
            : undefined
        :undefined
    let isCost = usageChartType === dailyUsageChartTypeConstants.COST
    let isDemand = usageChartType === dailyUsageChartTypeConstants.DEMAND
    let isConsumption = usageChartType === dailyUsageChartTypeConstants.CONSUMPTION
    let isGeneration = usageChartType === dailyUsageChartTypeConstants.GENERATION
    let dtoData = convertDailyDataForCharting(data, usageChartType)
    let netCompliantChartData = new NetCompliantChartData(dtoData,isCost,isDemand,isConsumption,isGeneration)
    let adjustedData = addTooltipInfoToData(netCompliantChartData.adjustedData,weatherData)
    let maxValue = netCompliantChartData.maxY
    let minValue = netCompliantChartData.minY
    let legendDisplayStates = getLegendDisplayStates(adjustedData, isCost)
    let chart = d3.select("#usagePageChartContainer")
    d3.selectAll("#usagePageChart").remove()
    let x
    let y
    let barHeight = 20;
    let yOffset = 5;
    let displaySize = getDisplaySize(window.innerWidth)
    let chartSize = getChartSvgSize(displaySize, data.length, barHeight,margin)
    let  svg = chart
    .insert('svg','div')
    .attr('width', chartSize.width)
    .attr('height', chartSize.height)
    .attr('id', 'usagePageChart')
    .attr('class','viz-chart')

    let g = svg.append("g")
        .attr("transform", "translate(" + margin.left + "," + margin.top + ")")

    y = d3.scaleBand()
    .range([0, chartSize.height - margin.top - margin.bottom])
    .align(0.1)
    .padding(0.1)
    let xTemp = d3.scaleLinear().range([chartSize.width - margin.left - margin.right - pageXOffset, 0])
    x = d3.scaleLinear()
        .rangeRound([0, chartSize.width - margin.left - margin.right])

    addTooltipInfoToData(adjustedData,weatherData)

    y.domain(adjustedData.map(function (d) {
        return d.Date
    }))
    x.domain([minValue, maxValue])
    xTemp.domain([d3.max(weatherData, function (d) {
		return Math.max(d.high);
    }),0]).nice()

    let initialTt = chart.select(".viz-tooltip-arrow-main")
    initialTt.remove()
    let tooltip = addTooltipToChart(chart)

    g.append("g").attr("id","usagePageChartMainGroup")
        .selectAll("g")
        .data(d3.stack().keys(standardChartKeys)(adjustedData))
        .enter().append("g")
        .attr("class", function (d,i) {
            return getCssClass(standardChartKeys[i],isGeneration) + " viz-bar"
        })
        .selectAll("path")
        .data(function (d) {
            return d
        })
        .enter().append("g")
        .classed("viz-high-demand-bar", function (d) {
            let date = DateTime.fromISO(d.data.date).setZone('MST')
            return highDemandDate !== undefined && isDemand
                && date.hasSame(DateTime.fromISO(highDemandDate).setZone('MST'), 'day')
        })
        .classed("viz-on-off-shoulder", function (d) {
            return d.data.onPeak === 0 && hasOnOffShoulder && isDemand})
        .append("path")
        .on("mouseover", function (d) {
            handleMouseOver(d, this, tooltip, barHeight, yOffset, maxValue, x, y, isCost, t)
        })
        .on("touchstart", function (d) {
            handleMouseOver(d, this, tooltip, barHeight, yOffset, maxValue, x, y, isCost, t)
            d3.event.stopPropagation()
        }, { passive: true })
        .on("mouseout", function (d) { handleMouseOut(d, this, tooltip) })
        .on("mousedown", function(d) {
            if('ontouchstart' in document.documentElement !== true) {
                return true
            }
            handleMouseOver(d, this, tooltip, barHeight, yOffset, maxValue, x, y, isCost, t)

            let date = DateTime.fromISO(d.data.date).setZone('MST')
            if(window.daily_usage_selected_date !== undefined
                && window.daily_usage_selected_date !== null
                && window.daily_usage_selected_date !== "")
            {
                if (window.daily_usage_selected_date.equals(date)) {
                    barClickCallback(date, isMPower ? dailyUsageChartTypeConstants.USAGE : usageChartType)
                }
            }

            d3.event.stopPropagation()
            window.daily_usage_selected_date = date
        })
        .on("click", function(d){
            if('ontouchstart' in document.documentElement){
                return true
            }
            barClickCallback(DateTime.fromISO(d.data.date).setZone('MST'), isMPower ? dailyUsageChartTypeConstants.USAGE : usageChartType)
        })
        .attr("height", 0)
        .attr("d", function (d) {
            return drawHorizontalBars(d,data,x,y,minValue,maxValue,yOffset,barHeight,"Date")
        })

    drawAxisTicks(g, chartSize, isCost, x, y, xTemp)

    const xLineHigh = d3.line()
        .y(function (d) {
            return y(DateTime.fromISO(d.weatherDate)) + yOffset + (barHeight/2)
        })
        .x(function (d) {
            return xTemp(d.high)
        })

    g.append("path")
        .attr("class", "viz-high-temp")
        .attr("d", xLineHigh(weatherData))
        .style("fill", "none")
        .style("stroke-width", "2px");

    const xLineLow = d3.line()
        .y(function (d) {
            return y(DateTime.fromISO(d.weatherDate)) + yOffset + (barHeight/2)
        })
        .x(function (d) {
            return xTemp(d.low);
        })

    g.append("path")
        .attr("class", "viz-low-temp")
        .attr("d", xLineLow(weatherData))
        .style("fill", "none")
        .style("stroke-width", "2px");

    svg.append("text")
        .attr("y", chartSize.height - margin.bottom/2)
        .attr("x", chartSize.width/2 )
        .attr("dy", "1em")
        .style("text-anchor", "middle")
        .attr("class","chart-axis-label")
        .text(t("High / low temperature") + " (°F)")

    drawLegend(legendDisplayStates, isGeneration)
}

function drawAxisTicks(g, chartSize, isCost, x, y, xTemp) {
    if(isCost){
        g.append("g")
        .attr("class", "axis")
        .call(d3.axisTop(x).ticks(4, "s")
            .tickFormat(d3.format(["$", ""])))
    }
    else{
        g.append("g")
            .attr("class", "axis")
            .call(d3.axisTop(x).ticks(4, "s"))
    }
    g.append("g")
        .attr("class", "axis")
        .attr("transform", "translate(-10, 2)")
        .call(d3.axisLeft(y).tickValues(y.domain().filter(function(d,i){
            return !(i % 7)
        }))
        .tickFormat(d3.timeFormat("%-a, %b %-e"))
        )
    g.append("g")
        .attr("transform", "translate(0," + (chartSize.height  - margin.top - margin.bottom) + ")")
        .attr("class", "axis")
        .call(d3.axisBottom(xTemp).ticks(5, "s"))
        .append("text")
        .attr("x", 2)
        .attr("y", x(xTemp.ticks().pop()) + 0.5)
        .attr("fill", "#000")
        .attr("font-weight", "bold")
        .attr("text-anchor", "start")
}

function getChartSvgSize(displaySize, numberOfBars, barHeight, margin) {
    let chartHeight = numberOfBars * (barHeight + 10) + margin.top + margin.bottom
    switch(displaySize){
        case displaySizeConstants.MEDIUM:
        return {
                height: chartHeight,
                width :640
            }
        case displaySizeConstants.SMALL:
            return {
                height: chartHeight,
                width :480
            }
        case displaySizeConstants.EXTRA_SMALL:
            return {
                height: chartHeight,
                width :400
            }
        case displaySizeConstants.MOBILE_LARGE:
            return {
                height: chartHeight,
                width :340
            }
        case displaySizeConstants.MOBILE:
            return {
                height: chartHeight,
                width :310
            }
        case displaySizeConstants.MOBILE_SMALL:
            return {
                height: chartHeight,
                width :260
            }
        default:
            return {
                height: chartHeight,
                width :640
            }
        }

}
function getCssClass(text,isGeneration) {
    if (text.includes("offset")) {
        return "viz-invisible"
    }
    return isGeneration
        ? "viz-" + text + "-generation"
        : "viz-" + text
}
function handleMouseOver(d, element, tooltip, barHeight, yOffset, maxValue, x, y, isCost, t) {
    $(element).addClass("current-hover")
    createTooltip(d, tooltip, barHeight, yOffset, maxValue, x, y, isCost, t)
    return true
}

function handleMouseOut(d, element, tooltip) {
    $(element).removeClass("current-hover")
    tooltip.style("display", "none")
}

function addTooltipToChart(chart) {
    let tooltip = chart
        .append("div")
        .attr("class", "viz-tooltip-arrow-main")
    let tooltipArrow =
        tooltip
            .append("div")
            .attr("class", "viz-tooltip-arrow")

    tooltipArrow.append("div")
        .attr("class", "viz-tooltip-main")
        .append("div")
        .attr("class", "viz-tooltip-inner")
        .attr("id", "viz-tooltipText")
    return tooltip
}
function getTootipArrowposition(xPosition,barMaxX){
    let relativeXPosition = xPosition/barMaxX
    if(relativeXPosition >= 0.8){
        return 80;
    }
    if(relativeXPosition <= 0.2){
        return 20
    }
    return relativeXPosition * 100
}
function createTooltip(d, tooltip, barHeight,
    yOffset, maxValue, x, y,isCost, t) {
    let yPosition = y(d.data.Date) - (barHeight/2) + yOffset + margin.top

    let xPosition = margin.left + (x(maxValue) - x(d.data.max))
    let formatTime = d3.timeFormat("%a, %b %e")

    tooltip
        .style("left", xPosition + "px")
        .style("top", yPosition + "px")
        .style("display", "block")
    let tooltipInner = tooltip.select(".viz-tooltip-inner")
    tooltipInner.selectAll("*").remove()
    tooltipInner.append("div").text(formatTime(d.data.Date))
    tooltipInner.style("right", getTootipArrowposition(xPosition,x(maxValue) + margin.left) + "%")
    if(isCost){
        if (d.data.showTotalCostTip) {
            tooltipInner.append("div")
                .attr("class", "viz-tooltip-text-line")
                .text(t("Cost") + ": $" + d.data.dailyCost.toFixed(2))
        }
        if (d.data.showOffPeakCostTip) {
            tooltipInner.append("div")
                .attr("class", "viz-tooltip-text-line")
                .text(d.data.tooltipOffPeakCost)
        }
        if (d.data.showOnPeakCostTip) {
            tooltipInner.append("div")
                .attr("class", "viz-tooltip-text-line")
                .text(d.data.tooltipOnPeakCost)
        }
    }
    else{
        if (d.data.showOffPeakTip) {
            tooltipInner.append("div")
                .attr("class", "viz-tooltip-text-line")
                .text(d.data.tooltipOffPeak)
        }
        if (d.data.showShoulderTip) {
            tooltipInner.append("div")
                .attr("class", "viz-tooltip-text-line")
                .text(d.data.tooltipShoulder)
        }
        if (d.data.showOnPeakTip) {
            tooltipInner.append("div")
                .attr("class", "viz-tooltip-text-line")
                .text(d.data.tooltipOnPeak)
        }
        if (d.data.showSuperOffPeakTip) {
            tooltipInner.append("div")
                .attr("class", "viz-tooltip-text-line")
                .text(d.data.tooltipSuperOffPeak)
        }
        if (d.data.showTotalTip) {
            tooltipInner.append("div")
                .attr("class", "viz-tooltip-text-line")
                .text(d.data.tooltipTotal)
        }
    }
    if (d.data.lowTemp != 0 && d.data.highTemp !=0) {
        tooltipInner.append("div")
            .attr("class", "viz-tooltip-text-line")
            .text(t("High/low temp") + ": " + Math.round(d.data.highTemp)
                + "°/" + Math.round(d.data.lowTemp) + "°F" )
    }
}

function drawLegend(legendDisplayStates, isGeneration) {

    if (legendDisplayStates.hasSuperOff) {
        let superOffItem = d3.select('#superOffLegendItem')
        cssForLegnedItem(superOffItem, isGeneration)
    }
    else{
        d3.select('#superOffLegendItem')
            .attr("class", "chart-legend-item-hidden")
    }
    if (legendDisplayStates.hasOffPeak) {
        let offItem = d3.select('#offPeakLegendItem')
        cssForLegnedItem(offItem, isGeneration)
    }
    else{
        d3.select('#offPeakLegendItem')
            .attr("class", "chart-legend-item-hidden")
    }
    if (legendDisplayStates.hasShoulder) {
        let shoulderItem = d3.select('#shoulderLegendItem')
        cssForLegnedItem(shoulderItem, isGeneration)
    }
    else{
        d3.select('#shoulderLegendItem')
            .attr("class", "chart-legend-item-hidden")
    }
    if (legendDisplayStates.hasOnPeak) {
        let onItem = d3.select('#onPeakLegendItem')
        cssForLegnedItem(onItem, isGeneration)
    }
    else{
        d3.select('#onPeakLegendItem')
            .attr("class", "chart-legend-item-hidden")
    }
    if (legendDisplayStates.hasTotal && legendDisplayStates.isCost) {
        let totalItem = d3.select('#totalLegendItemCost')
        cssForLegnedItem(totalItem, isGeneration)
    }
    else{
        d3.select('#totalLegendItemCost')
            .attr("class", "chart-legend-item-hidden")
    }
    if (legendDisplayStates.hasTotal && !legendDisplayStates.isCost) {
        let total = d3.select('#totalLegendItem')
            .attr("class", "usage-chart-legend-item-show")
            cssForLegnedItem(total, isGeneration)
    }
    else{
        d3.select('#totalLegendItem')
            .attr("class", "chart-legend-item-hidden")
       }
}
function cssForLegnedItem(legendItem, isGeneration){
    legendItem.attr("class", "usage-chart-legend-item-show")
    if(isGeneration){
        legendItem.select(".usage-chart-legend-circle").classed("generation", true)
    }
    else{
        legendItem.select(".usage-chart-legend-circle").classed("generation", false)
    }
}
function addTooltipInfoToData(adjustedData,weatherData){
    let hasWeatherData = adjustedData !== undefined
        && (adjustedData.length === weatherData.length)
        adjustedData.forEach(function (element,i) {
            // TODO: Should we set timezone?
            element.day = DateTime.fromISO(element.day).toFormat('yyyy-MM-dd')
            element.Date = DateTime.fromISO(element.day)
            element.highTemp = hasWeatherData ? weatherData[i].high : 0,
            element.lowTemp = hasWeatherData ? weatherData[i].low : 0
        })
    return adjustedData
}

function getLegendDisplayStates(adjustedData,isCost){
    let hasOnPeak = false,
    hasSuperOff = false,
    hasOffPeak = false,
    hasShoulder = false,
    hasTotal = false

    let hasWeatherData = adjustedData !== undefined
    adjustedData.forEach(function (element) {
        hasOnPeak = element.onPeak != 0 ? true : hasOnPeak
        hasOffPeak = element.offPeak != 0 ? true : hasOffPeak
        hasShoulder = element.shoulder != 0 ? true : hasShoulder
        hasSuperOff = element.superOffPeak != 0 ? true : hasSuperOff
        hasTotal = element.total != 0 ? true : hasTotal
    })
    return {hasOnPeak: hasOnPeak,
        hasSuperOff: hasSuperOff,
        hasOffPeak: hasOffPeak ,
        hasShoulder: hasShoulder,
        hasTotal: hasTotal,
        hasWeather: hasWeatherData,
        isCost: isCost
    }
}