import React from 'react'
import PropTypes from 'prop-types'

import Dialog from '@mui/material/Dialog'

const PaperlessBillingDialog = ({eBillEmailAddrPhoneNo, showDialog, closeDialogOnClick, t}) => (
    <Dialog modal="true" open={showDialog} PaperProps={{ className: "srp-modal" }}>
        <div className="srp-modal-body">
            <div className="srp-modal-close" onClick={() => closeDialogOnClick()} />
            <div>
                <div className="srp-modal-header">
                    <h4 className="srp-modal-title">{t("eBill paperless billing")}</h4>
                </div>
                <p>
                    {`${t("turned_on_paperless_billing_1")} ${eBillEmailAddrPhoneNo}. ${t("turned_on_paperless_billing_2")}`}
                </p>
                <div className="srp-modal-footer mb-0">
                    <button
                        className="btn srp-btn btn-lightblue"
                        onClick={() => closeDialogOnClick()}>
                        {t("Close")}
                    </button>
                </div>
            </div>
        </div>
    </Dialog>
)

PaperlessBillingDialog.propTypes = {
    eBillEmailAddrPhoneNo: PropTypes.string.isRequired,
    showDialog: PropTypes.bool.isRequired,
    closeDialogOnClick: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired
}

export default PaperlessBillingDialog