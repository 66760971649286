export const shareConstants = {
    SET_ACTIVE_STEP: 'SET_ACTIVE_STEP',

    ACOUNT_TYPE: {
        RESIDENTIAL: 0,
        COMMERCIAL: 1,
        UNKNOWN: 2
    },
    SET_ACCOUNT_TYPE: 'SET_ACCOUNT_TYPE',
    SET_ACCOUNT_DETAILS: 'SET_ACCOUNT_DETAILS',

    DONATION_TYPE: {
        MONTHLY: 0,
        ONE_TIME: 1,
        DISCONTINUE: 2
    },
    SET_DONATION_TYPE: 'SET_DONATION_TYPE',
    DONATION_AMOUNT: {
        OTHER: 0,
        ONE_DOLLAR: 1,
        TWO_DOLLAR: 2,
        FIVE_DOLLAR: 5,
        TEN_DOLLAR: 10,
        TWENTY_DOLLAR: 20
    },
    SET_DONATION_AMOUNT: 'SET_DONATION_AMOUNT',
    SET_OTHER_DONATION_AMOUNT: 'SET_OTHER_DONATION_AMOUNT',
    FORMAT_OTHER_DONATION_AMOUNT: 'FORMAT_OTHER_DONATION_AMOUNT',

    ADOBE_ANALYTICS_FORM_NAME: 'SHARE',
    getStepName: (activeStep) => {
        const step = shareConstants.SHARE_STEPS.find(s => s.step === activeStep)
        const stepName = (typeof step !== "undefined" ? step.label : "step " + activeStep)
        return stepName
    },

    SUBMIT_SHARE_REQUEST: 'SUBMIT_SHARE_REQUEST',
    SUBMIT_SHARE_SUCCESS: 'SUBMIT_SHARE_SUCCESS',
    SUBMIT_SHARE_FAILURE: 'SUBMIT_SHARE_FAILURE',

    SHARE_STEPS: [
        {
            step: 0,
            label: "Account details",
            title: "Tell us about yourself"
        },
        {
            step: 1,
            label: "Select donation",
            title: "Donation type and amount"
        },
        {
            step: 2,
            label: "Review",
            title: "Dontation type and amount"
        }
    ]
}