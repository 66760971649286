import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import OutageHistory from './outage-history'
import OutagePageLoading from './outage-page-loading'
import OutagePageError from './outage-page-error'

import { getNextOutageHistory, getPrevOutageHistory } from '../../actions/auth/outage/outage-history-actions'
import { rateMetaDataConstants } from '../../constants/rate-meta-data-constants'
import * as loadingStatus from '../../constants/loading-status-constants'

class OutageHistoryContainer extends React.Component {
    render() {
        const t = this.props.t
        const i18n = this.props.i18n

        let renderedCard = {}
        if (this.props.rateMetaDataStatus === loadingStatus.LOADING_STATUS_SUCCESS || this.props.rateMetaDataStatus === loadingStatus.LOADING_STATUS_FAILURE) {
            if (this.props.rateMetaData.summaryBilling === rateMetaDataConstants.SUMMARY_BILLING_PARENT) {
                return null
            }
            else {
                if (this.props.outageHistoryStatus === loadingStatus.LOADING_STATUS_INIT || this.props.outageHistoryStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS) {
                    renderedCard = <OutagePageLoading />
                }
                else if (this.props.outageHistoryStatus === loadingStatus.LOADING_STATUS_SUCCESS) {
                    renderedCard = (<OutageHistory
                        t={t} i18n={i18n}
                        serviceReliability={this.props.serviceReliability}
                        outageHistoryList={this.props.outageHistoryList}
                        outageHistoryCount={this.props.outageHistoryCount}
                        totalOutageHistoryDuration={this.props.totalOutageHistoryDuration}
                        outageHistoryTimeSpan={this.props.outageHistoryTimeSpan}
                        getNextOutageHistoryOnClick={this.props.getNextOutageHistoryOnClick}
                        getPrevOutageHistoryOnClick={this.props.getPrevOutageHistoryOnClick}
                        outageHistoryIndex={this.props.outageHistoryIndex} />)
                }
                else {
                    renderedCard = <OutagePageError dataThatFailed={t("Outage history")} refreshData={this.props.refreshOutageHistoryCard} t={t} />
                }
            }
        }
        else {
            return null
        }
        return (
            <div>
                <div className="srp-card-header">{t("Outage history")}</div>
                <div className="srp-card-body">
                    {renderedCard}
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return { ...state.accountInfo.outage, ...state.rateMetaData }
}

const mapDispatchToProps = dispatch => {
    return {
        getNextOutageHistoryOnClick: () => {
            dispatch(getNextOutageHistory())
        },
        getPrevOutageHistoryOnClick: () => {
            dispatch(getPrevOutageHistory())
        },
    }
}

OutageHistoryContainer.propTypes = {
    serviceReliability: PropTypes.number,
    outageHistoryList: PropTypes.arrayOf(PropTypes.object),
    outageHistoryCount: PropTypes.string,
    totalOutageHistoryDuration: PropTypes.string,
    outageHistoryTimeSpan: PropTypes.string,
    outageHistoryStatus: PropTypes.string,
    getNextOutageHistoryOnClick: PropTypes.func,
    getPrevOutageHistoryOnClick: PropTypes.func,
    outageHistoryIndex: PropTypes.number,

    rateMetaData: PropTypes.object,
    rateMetaDataStatus: PropTypes.string,

    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired,

    refreshOutageHistoryCard: PropTypes.func
}

export default connect(mapStateToProps, mapDispatchToProps)(OutageHistoryContainer)