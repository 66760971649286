import { paymentConstants } from '../../../constants/payment-constants'

export const startCIXFlow = () => ({
    type: paymentConstants.START_CIX_FLOW
})

export const endCIXFlow = () => ({
    type: paymentConstants.END_CIX_FLOW
})

export const showCIXCard = (cardToShow) => ({
    type: paymentConstants.SHOW_CIX_CARD_ON_CHANGE,
    cardToShow
})

export const sendCIXConfirmationEmail = (checked) => ({
    type: paymentConstants.SEND_CIX_CONFIRMATION_EMAIL,
    checked
})