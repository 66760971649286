import React from 'react'
import { connect } from 'react-redux'
import { DocumentTitle } from '../../../srp_modules/document-title'
import { Formik } from 'formik'
import PropTypes from 'prop-types'
import AnalyticsFormTracking from '../../common_formik/analytics-form-tracking'

import Card from '@mui/material/Card'
import FormControl from '@mui/material/FormControl'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import CircularProgress from '@mui/material/CircularProgress'

import SolarChoiceAccountInfo from './solar-choice-account-info'
import SolarChoiceEstimator from './solar-choice-estimator'
import SolarChoiceSignupConfirmation from './solar-choice-signup-confirmation'
import { submitSolarChoiceForm } from '../../../actions/auth/forms/solar-choice-actions'

import validate from '../../../srp_modules/validate'
import { withRouter } from '../../../srp_modules/with-router'
import { withTranslation } from 'react-i18next'

class SolarChoiceSignupContainer extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            accountDetails: null,
            wasSubmitted: false,
            initialChangedState: false
        }

        this.setAccountInfo = this.setAccountInfo.bind(this)
        this.accountInfoIsLoaded = this.accountInfoIsLoaded.bind(this)
        this.cardIsLoaded = this.cardIsLoaded.bind(this)
        this.onStateChanged = this.onStateChanged.bind(this)
    }

    setAccountInfo(accountInfo) {
        this.setState({ accountDetails: accountInfo })
    }

    accountInfoIsLoaded() {
        return this.state.accountDetails !== null
    }

    cardIsLoaded() {
        let isLoaded = this.accountInfoIsLoaded()

        return isLoaded
    }

    onStateChanged() {
        this.setState({
            initialChangedState: true
        })
    }

    render() {
        const t = this.props.t
        const i18n = this.props.i18n

        return (
            <Formik
                initialValues={{
                    solarChoiceData: {
                        accountNumber: 0,
                        solarChoicePlan: 'None',
                        customerName: '',
                        serviceAddress: null,
                        customerInfoModel: null,
                        businessInfoModel: null,
                        isResidential: true,
                        solarChoiceAction: 'SolarChoiceSignup'
                    },
                    emailAddress: '',
                    emailAddressFromFormik: ""
                }}
                validate={(values) => validateForm(values)}
                onSubmit={this.props.submitSolarChoiceChange()}>
                {({ values, status, errors, touched, handleSubmit, isSubmitting, handleChange, handleBlur }) => {
                    let emailError = touched.emailAddressFromFormik && errors.email !== undefined
                    let invalidEmailError = touched.emailAddressFromFormik && errors.invalidEmail !== undefined

                    let isSolarChoiceDisabled = !this.cardIsLoaded()
                    if (this.accountInfoIsLoaded() === true) {
                        values.solarChoiceData.accountNumber = this.props.selectedBillAccount
                        values.solarChoiceData.customerName = this.state.accountDetails.customerName
                        values.solarChoiceData.serviceAddress = this.state.accountDetails.serviceAddress
                        values.solarChoiceData.customerInfoModel = this.state.accountDetails.customerInfoModel
                        values.solarChoiceData.businessInfoModel = this.state.accountDetails.businessInfoModel
                        values.solarChoiceData.isResidential = this.state.accountDetails.isResidential
                        values.emailAddress = this.state.accountDetails.emailAddress
                    }

                    let planIsSelected = values.solarChoiceData.solarChoicePlan !== 'None'

                    let changeStatus = status || {}

                    let displayedContent = (
                        <React.Fragment>
                            <AnalyticsFormTracking
                                name="SRP Solar Choice Sign-Up"
                                formStep="Select preferred amount of solar energy"
                                meta={{ solarChoicePlan: values.solarChoiceData.solarChoicePlan }}
                                onStateChanged={this.onStateChanged}
                                initialChangedState={this.state.initialChangedState} />
                            {(changeStatus.wasSubmitted && changeStatus.wasSubmitted === true)
                                ? <SolarChoiceSignupConfirmation emailAddress={values.emailAddress.length > 0 ? values.emailAddress : values.emailAddressFromFormik} t={t} />
                                : <div>
                                    <h2 className="pb-2">{t("SRP Solar Choice")}</h2>
                                    <div className="srp-card-header">
                                        <div>{t("SRP Solar Choice Sign-Up")}</div>
                                    </div>
                                    <Card>
                                        <div className="container">
                                            <div className="srp-card-details">
                                                {changeStatus.submitError
                                                    ? <div className="srp-alert-error mb-2" style={{ marginTop: '20px' }}>
                                                        {t("We're sorry, something went wrong. Please try again later.")}
                                                    </div>
                                                    : null
                                                }
                                                <div className="pt-3">
                                                    <SolarChoiceAccountInfo
                                                        setAccountInfo={this.setAccountInfo}
                                                        emailAddressFromFormik={values.emailAddressFromFormik}
                                                        emailError={emailError}
                                                        invalidEmailError={invalidEmailError}
                                                        errors={errors}
                                                        handleChange={handleChange}
                                                        handleBlur={handleBlur}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <hr className="w-100" />
                                        <div className="container">
                                            <div className="srp-card-details">
                                                <SolarChoiceEstimator />
                                            </div>
                                        </div>
                                        <hr className="w-100" />
                                        <div className="container">
                                            <form onSubmit={handleSubmit}>
                                                <div className="srp-card-details">
                                                    <div>{t("Select your preferred amount of solar energy")}</div>
                                                    <div className="text-muted font-14 pl-1 pt-2">{t("Select a program amount")}</div>
                                                    <FormControl className="pl-2">
                                                        <RadioGroup
                                                            id="solarChoiceData.solarChoicePlan"
                                                            name="solarChoiceData.solarChoicePlan"
                                                            value={values.solarChoiceData.solarChoicePlan}
                                                            onChange={handleChange}
                                                        >
                                                            <FormControlLabel
                                                                value="SolarChoice100"
                                                                control={
                                                                    <Radio
                                                                        color="primary"
                                                                        disabled={isSolarChoiceDisabled}
                                                                    />
                                                                }
                                                                style={{ marginBottom: '0' }}
                                                                label={<span className="checkmark-label-blue">{t("SRP Solar Choice Program (100%)")}</span>}
                                                            />
                                                            <FormControlLabel
                                                                value="SolarChoice50"
                                                                control={
                                                                    <Radio
                                                                        color="primary"
                                                                        disabled={isSolarChoiceDisabled}
                                                                    />
                                                                }
                                                                style={{ marginBottom: '0' }}
                                                                label={<span className="checkmark-label-blue">{t("SRP Solar Choice Program (50%)")}</span>}
                                                            />
                                                        </RadioGroup>
                                                    </FormControl>
                                                    <div className="d-flex flex-wrap justify-content-end">
                                                        <button className="btn srp-btn btn-lightblue" type="button" onClick={() => this.props.router.navigate(-1)}>{t("Previous")}</button>
                                                        <button
                                                            className="btn srp-btn btn-green ml-2"
                                                            type="submit"
                                                            disabled={!planIsSelected || emailError || invalidEmailError}>
                                                            {isSubmitting
                                                                ? <CircularProgress size={20} thickness={5} style={{ color: 'white' }} />
                                                                : t("Submit")
                                                            }
                                                        </button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </Card>
                                </div>
                            }
                        </React.Fragment >
                    )

                    return (
                        <div className="container py-3" >
                            <div className="clearfix pt-3 pt-md-2 row">
                                <div className="col-md-6 col-12">
                                    <DocumentTitle title={t("Solar Choice Sign-Up Form | SRP Power")}>
                                        <div>
                                            {displayedContent}
                                        </div>
                                    </DocumentTitle >
                                </div>
                            </div>
                        </div>
                    )
                }}
            </Formik >
        )
    }
}

SolarChoiceSignupContainer.propTypes = {
    selectedBillAccount: PropTypes.number,
    enrolledProgramsDetailsStatus: PropTypes.string,
    submitSolarChoiceChange: PropTypes.func,
    router: PropTypes.shape({
        navigate: PropTypes.func
    }),
    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired,
}

const mapStateToProps = state => {
    return {
        enrolledProgramsDetailsStatus: state.accountInfo.payment.enrolledProgramsDetailsStatus,
        selectedBillAccount: state.accountInfo.billAccount.selectedBillAccount,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        submitSolarChoiceChange: () => async (values, goodies) => {

            let solarChoiceSubmitData = values.solarChoiceData
            if (values.emailAddressFromFormik.length > 0) {
                solarChoiceSubmitData = {
                    ...solarChoiceSubmitData,
                    customerInfoModel: {
                        ...solarChoiceSubmitData.customerInfoModel,
                        emailAddress: values.emailAddressFromFormik
                    },
                    businessInfoModel: {
                        ...solarChoiceSubmitData.businessInfoModel,
                        emailAddress: values.emailAddressFromFormik
                    }
                }
            }

            let result = await dispatch(submitSolarChoiceForm(solarChoiceSubmitData))

            if (result.error === true || result.payload.success === false) {
                goodies.setStatus({ submitError: true, wasSubmitted: false })
                goodies.setSubmitting(false)
            }
            else {
                goodies.setStatus({ submitError: false, wasSubmitted: true })
                goodies.setSubmitting(false)
            }
        }
    }
}

export default withTranslation('solarChoice')(withRouter(connect(mapStateToProps, mapDispatchToProps)(SolarChoiceSignupContainer)))

function validateForm(values) {
    let errors = {}

    if (values.emailAddress.length === 0) {
        if (values.emailAddressFromFormik === undefined || values.emailAddressFromFormik.length === 0)
            errors.email = 'Email address is required'
        if (!validate.emailAddress(values.emailAddressFromFormik))
            errors.invalidEmail = 'Please enter a valid email address'
    }

    return errors
}