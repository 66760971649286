import React from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import {displayRateCode} from './../../../srp_modules/forms/display-mapper'

const EstimatedCost = ({selectedPlan, t}) => {
  return (
    <div>
       <div className="srp-card-header">
            {t("estimated_startup_costs")}
        </div>

        <div className="srp-card-body ">
          <div className="srp-card-details srp-orange-background">
            {t("selected_rate_plan").replace('{rate plan}', displayRateCode(selectedPlan, t))}          
            <br/>
            • {t("deposit_290")}<br/>
            • {t("service_establishment_fee")}
          </div>
        </div>
    </div>
  )
}

EstimatedCost.propTypes = {
  selectedPlan: PropTypes.string,
  t: PropTypes.func
}

export default withTranslation('startService')(EstimatedCost)