import React from 'react'
import { withTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import { PurchaseDetailsStyledDiv, classes } from './purchase-details-styles'

const BankDetailsSectionPure = ({selectedBankAccountDescription, t}) => {
    return (<PurchaseDetailsStyledDiv>
        <div className="px-3 pt-3">
            <h4 className="text-secondary">{t("Bank details")}</h4>
            <div className="d-none d-lg-block">
            <div className="d-flex flex-row align-items-center mt-4">
                <h6 className={`${classes.column} ${classes.secondaryText} mb-0`}>{t("Bank account")}</h6>
                <h6 className={`${classes.twoColumns} mb-0`}>{selectedBankAccountDescription}</h6>
            </div>
            </div>
            <div className="d-lg-none d-flex mt-4">
                <h6 className={`${classes.secondaryText} mb-0 w-50 align-self-center`}>{t("Bank account")}</h6>
                <h6 className="mb-0 align-self-center">{selectedBankAccountDescription}</h6>
            </div>
        </div>
        <hr />
    </PurchaseDetailsStyledDiv>)
}

BankDetailsSectionPure.propTypes = {
    selectedBankAccountDescription: PropTypes.string.isRequired,

    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired
}

const BankDetailsSection = withTranslation("multiPurchasePage")(BankDetailsSectionPure)
export { BankDetailsSection as default, BankDetailsSectionPure }