export const singlePurchaseApiConstants = {
    VERIFY_SINGLE_PURCHASE_LIST_REQUEST: 'VERIFY_SINGLE_PURCHASE_LIST_REQUEST',
    VERIFY_SINGLE_PURCHASE_LIST_SUCCESS: 'VERIFY_SINGLE_PURCHASE_LIST_SUCCESS',
    VERIFY_SINGLE_PURCHASE_LIST_FAILURE: 'VERIFY_SINGLE_PURCHASE_LIST_FAILURE',

    SUBMIT_SINGLE_PURCHASE_LIST_REQUEST: 'SUBMIT_SINGLE_PURCHASE_LIST_REQUEST',
    SUBMIT_SINGLE_PURCHASE_LIST_SUCCESS: 'SUBMIT_SINGLE_PURCHASE_LIST_SUCCESS',
    SUBMIT_SINGLE_PURCHASE_LIST_FAILURE: 'SUBMIT_SINGLE_PURCHASE_LIST_FAILURE',

    SINGLE_PURCHASE_ADD_BANK_ACCOUNT_REQUEST: 'SINGLE_PURCHASE_ADD_BANK_ACCOUNT_REQUEST',
    SINGLE_PURCHASE_ADD_BANK_ACCOUNT_SUCCESS: 'SINGLE_PURCHASE_ADD_BANK_ACCOUNT_SUCCESS',
    SINGLE_PURCHASE_ADD_BANK_ACCOUNT_FAILURE: 'SINGLE_PURCHASE_ADD_BANK_ACCOUNT_FAILURE',

    CLEAR_PURCHASE_SUBMITTED: 'CLEAR_PURCHASE_SUBMITTED'
}