import React from 'react'
import PropTypes from 'prop-types'

import BankInfoFormik from './bank-info-formik'

const BankInfoSection = (props) => (
    <div>
        <label className="h4 text-muted">{props.t('Bank information')}</label>
        {props.bankFormOpen &&
        <p className="text-muted">{props.t('Enter_bank_info_to_save')}</p>
        }
        <BankInfoFormik {...props} />
    </div>
)

BankInfoSection.propTypes = {
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired,
    t: PropTypes.func.isRequired,

    bankFormOpen: PropTypes.bool.isRequired
}

export default BankInfoSection