import React from 'react'
import PropTypes from 'prop-types'
import { Formik } from 'formik'
import { PatternFormat } from 'react-number-format'
import CircularProgress from '@mui/material/CircularProgress'
import TextField from '@mui/material/TextField'

import validate from '../../srp_modules/validate'

const NoBillAccountsAddAccountForm = ({ addNewBillAccount }) => {
  return (

    <Formik
      initialValues={{
        accountNumber: '',
        phoneNumber: '',
        nickname: ''
      }}
      validate={validateAddAccountForm}
      onSubmit={(values, goodies) => addNewBillAccount(values, goodies)}>
      {({ values, status, errors, dirty, touched, setFieldValue, handleBlur, handleChange, handleSubmit, submitCount, isSubmitting }) => {

        let formStatus = status || {}
        let isAccountError = (dirty || submitCount > 0) && touched.accountNumber && errors.accountNumber !== undefined
        let isPhoneError = (dirty || submitCount > 0) && touched.phoneNumber && errors.phoneNumber !== undefined
        let isNicknameError = (dirty || submitCount > 0) && touched.nickname && errors.nickname !== undefined

        return (
          <div>
            <form method="POST" onSubmit={handleSubmit}>
              <div className="text-muted pb-3">
                It looks like there are no active accounts associated to your login.
                Enter your account information below to get access to My Account.
                </div>
              {formStatus.error ? <div className="srp-alert-error">{formStatus.error}</div> : null}
              <div className="pt-3">
                {/* <InputLabel htmlFor="accountNumber">SRP account number</InputLabel> */}
                <PatternFormat
                  id="accountNumber"
                  name="accountNumber"
                  className="mb-3"
                  value={values.accountNumber}
                  valueIsNumericString={true}
                  format="#########"
                  type="tel"
                  customInput={TextField}
                  fullWidth={true}
                  onValueChange={v => setFieldValue('accountNumber', v.value)}
                  onBlur={handleBlur}
                  error={isAccountError}
                  helperText={isAccountError ? errors.accountNumber : ''} 
                  label="SRP account number"
                />

                {/* <InputLabel htmlFor="phoneNumber">Account phone number</InputLabel> */}
                <PatternFormat
                  id="phoneNumber"
                  name="phoneNumber"
                  className="mb-3"
                  value={values.phoneNumber}
                  valueIsNumericString={true}
                  format="(###) ###-####"
                  type="tel"
                  customInput={TextField}
                  fullWidth={true}
                  onValueChange={v => setFieldValue('phoneNumber', v.value)}
                  onBlur={handleBlur}
                  error={isPhoneError}
                  helperText={isPhoneError ? errors.phoneNumber : ''} 
                  label="Account phone number"  
                />

                {/* <InputLabel htmlFor="nickname">Nickname (optional)</InputLabel> */}
                <TextField
                  id="nickname"
                  name="nickname"
                  value={values.nickname}
                  fullWidth={true}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  error={isNicknameError}
                  helperText={isNicknameError ? errors.nickname : ''} 
                  label="Nickname (optional)"
                />
              </div>
              <div className="d-flex flex-wrap justify-content-end mt-4">
                <button className="btn srp-btn btn-green ml-2" type="submit" disabled={isSubmitting}>
                  {isSubmitting ? <CircularProgress size={20} thickness={5} color="secondary" /> : 'Add account'}
                </button>
              </div>
            </form>
          </div>
        )
      }}
    </Formik>
  )

}

NoBillAccountsAddAccountForm.propTypes = {
  addNewBillAccount: PropTypes.func
}

export default NoBillAccountsAddAccountForm

function validateAddAccountForm(values) {
  let errors = {}

  if (values.accountNumber.length === 0) {
    errors.accountNumber = 'SRP account number is required'
  }

  if (values.phoneNumber.length === 0) {
    errors.phoneNumber = 'Account phone number is required'
  }
  else if (!validate.phoneNumber(values.phoneNumber)) {
    errors.phoneNumber = "You must enter a valid phone number"
  }

  return errors
}