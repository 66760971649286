import React from 'react'
import PropTypes from 'prop-types'

import SRPAccountNumberField from '../input-fields/srp-account-number-field'
import PaymentAmountField from '../input-fields/payment-amount-field'

import PaymentListDesktop from './payment-list-desktop'
import PaymentListMobile from './payment-list-mobile'

const SRPAccountNumberPaymentAmountForm = ({ paymentList, paymentListErrors, values,
    errors, touched, handleChange, handleBlur, handleSubmit, handleRemovePayment,
    bindSrpAccountNumberField, verifyingPaymentList, formDataChanged,
    setFieldValue, setFieldTouched, t }) => {
    let commonFormikProps = {
        onChange: (e) => { handleChange(e); formDataChanged(e); },
        onBlur: handleBlur,
        errors,
        touched
    }
    let commonTextFieldProps = {
        fullWidth: true
    }

    return (
        <form method="POST" onSubmit={handleSubmit}>
            <label className="h4 text-muted">{t("Power account and payment amount")}</label>
    
            <div className="mt-1"><SRPAccountNumberField inputRef={e1 => bindSrpAccountNumberField(e1)} autoFocus={true} t={t} value={values.srpAccountNumber} {...commonFormikProps} {...commonTextFieldProps} /></div>
            <div className="mt-4"><PaymentAmountField t={t} value={values.paymentAmount} {...commonFormikProps} {...commonTextFieldProps} setFieldValue={setFieldValue} setFieldTouched={setFieldTouched} /></div>

            <div className="d-flex justify-content-end mt-4 flex-wrap">
                <button className="btn srp-btn btn-lightblue mb-4" type="submit" disabled={verifyingPaymentList}>{t("Add")}</button>
            </div>

            <div className="d-none d-md-block">
            <PaymentListDesktop paymentList={paymentList} paymentListErrors={paymentListErrors} removePayment={handleRemovePayment} verifyingPaymentList={verifyingPaymentList} t={t} />
            </div>
            <div className="d-md-none">
            <PaymentListMobile paymentList={paymentList} paymentListErrors={paymentListErrors} removePayment={handleRemovePayment} verifyingPaymentList={verifyingPaymentList} t={t} />
            </div>
        </form>
    )
}

SRPAccountNumberPaymentAmountForm.propTypes = {
    paymentList: PropTypes.arrayOf(PropTypes.shape({
        srpAccountNumber: PropTypes.string.isRequired,
        paymentAmount: PropTypes.number.isRequired
    })).isRequired,
    paymentListErrors: PropTypes.arrayOf(PropTypes.shape({
        srpAccountNumber: PropTypes.oneOfType([
            PropTypes.string.isRequired,
            PropTypes.shape({
                getLocalizedErrMsg: PropTypes.func.isRequired,
            }),
        ]),
        paymentAmount: PropTypes.oneOfType([
            PropTypes.string.isRequired,
            PropTypes.shape({
                getLocalizedErrMsg: PropTypes.func.isRequired,
            }),
        ]),
    })),
    values: PropTypes.shape({
        srpAccountNumber: PropTypes.string.isRequired,
        paymentAmount: PropTypes.string.isRequired
    }).isRequired,
    errors: PropTypes.shape({
        srpAccountNumber: PropTypes.string,
        paymentAmount: PropTypes.string
    }).isRequired,
    touched: PropTypes.shape({
        srpAccountNumber: PropTypes.bool,
        paymentAmount: PropTypes.bool
    }).isRequired,
    handleChange: PropTypes.func.isRequired,
    handleBlur: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    handleRemovePayment: PropTypes.func.isRequired,
    bindSrpAccountNumberField: PropTypes.func.isRequired,
    verifyingPaymentList: PropTypes.bool.isRequired,
    t:  PropTypes.func.isRequired,
    formDataChanged: PropTypes.func.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    setFieldTouched: PropTypes.func.isRequired
}

export default SRPAccountNumberPaymentAmountForm