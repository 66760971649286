import { economyPricePlanConstants } from '../../../constants/economy-price-plan-constants'
import { RSAA } from 'redux-api-middleware'

import utils from '../../../srp_modules/utils'

export const submitEPPSignupForm = (eppData) => {
    let url = utils.createUrl('forms/submiteppform')

    let stringData = JSON.stringify(eppData)

    return dispatch => {
        return dispatch({
            [RSAA]: {
                endpoint: url,
                body: stringData,
                method: 'POST',
                credentials: 'include',
                headers: { 'Content-Type': 'application/json' },
                types: [
                    economyPricePlanConstants.SUBMIT_EPP_FORM_REQUEST,
                    economyPricePlanConstants.SUBMIT_EPP_FORM_SUCCESS,
                    economyPricePlanConstants.SUBMIT_EPP_FORM_FAILURE,
                ]
            },
            meta: {
                debounce: {
                    leading: false,
                    trailing: false
                }
            }
        })
    }
}

export const generateEppApplicationUrl = (billAccount, newEnvelopeLanguage, returnUrl) => {
    let url = utils.createUrl('forms/generate-epp-application-url', { billAccount, newEnvelopeLanguage, returnUrl })

    return dispatch => {
        return dispatch({
            [RSAA]: {
                endpoint: url,
                method: 'GET',
                credentials: 'include',
                types: [
                    economyPricePlanConstants.GENERATE_EPP_APP_URL_REQUEST,
                    economyPricePlanConstants.GENERATE_EPP_APP_URL_SUCCESS,
                    economyPricePlanConstants.GENERATE_EPP_APP_URL_FAILURE,
                ]
            }
        })
    }
}