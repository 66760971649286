import { singlePurchaseListConstants } from '../../../constants/single-purchase/single-purchase-list-constants'

const initialState = {
    purchaseList: [],
    formStartEventEmitted: false
}

export default function singlePurchaseListReducer(state = initialState, action) {
    switch (action.type) {
        case singlePurchaseListConstants.ADD_SINGLE_PURCHASE_TO_LIST:
            return { ...state,
                purchaseList: [...state.purchaseList, {srpAccountNumber: action.srpAccountNumber, purchaseAmount: action.purchaseAmount, payAmountOwedToSRP: action.payAmountOwedToSRP}]
            }
        case singlePurchaseListConstants.CLEAR_SINGLE_PURCHASE_LIST:
            return { ...state,
                purchaseList: []
            }
        case singlePurchaseListConstants.SET_FORM_START_EVENT_EMITTED:
            return { ...state,
                formStartEventEmitted: true
            }
        case singlePurchaseListConstants.CLEAR_FORM_START_EVENT_EMITTED:
            return { ...state,
                formStartEventEmitted: false
            }
        default:
            return state
    }
}