import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { sendPasswordResetEmail } from '../../../actions/auth/login/login-actions'
import { withRouter } from '../../../srp_modules/with-router'
import PasswordResetPage from './password-reset-page'

class PasswordResetPageContainer extends React.Component {
    constructor(props) {
        super(props)
    }

    render(){
        return(
            <PasswordResetPage
                emailToSendReset={this.props.emailToSendReset}
                sendPasswordResetEmailOnClick={this.props.sendPasswordResetEmailOnClick}
                t={this.props.t} />
        )
    }
}

const mapStateToProps = state => {
    return { ...state.login }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        sendPasswordResetEmailOnClick: async (values, goodies) => {
            let response = await dispatch(sendPasswordResetEmail(values.email))
            goodies.setSubmitting(false)

            if (response.error === true) {
                goodies.setStatus({ resetError: true, resetErrorText: this.props.t("something_went_wrong") })
                return
            }

            if (response.payload.success === false) {
                goodies.setStatus({ resetError: true, resetErrorText: response.payload.error })
                goodies.setErrors({ email: '' })
                return
            }

            ownProps.router.navigate('/passwordResetEmailConfirmation')
        }
    }
}

PasswordResetPageContainer.propTypes = {
    emailToSendReset: PropTypes.string,
    emailSendResetOnChange: PropTypes.string,
    isSendingResetEmail: PropTypes.bool,
    sendPasswordResetEmailOnClick: PropTypes.func.isRequired,
    passwordResetEmailSentError: PropTypes.bool,
    passwordResetEmailSentErrorText: PropTypes.string,
    router: PropTypes.shape({
        navigate: PropTypes.func
    }),
    t: PropTypes.func
}

export default withTranslation('forgotPassword')(withRouter(connect(mapStateToProps, mapDispatchToProps)(PasswordResetPageContainer)))

