import React from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

import { Link } from 'react-router-dom'

import { DateTime } from 'luxon'

import CheckImage from '../../../images/common/SuccessCheck.png'

const YouAreAllSet = ({selectedDate, isMpower, isOnCentralPrepay, shouldSendPostage, t}) => (
    <div>
        <div style={{ color: "#737B4C" }} className="row align-items-start mb-1">
            <div className="col-md-auto py-1"><img src={CheckImage} /></div>
            <div className="col-md-auto py-3"><h4>{t("You're all set!")}</h4></div>
        </div>
        <div className="text-muted mb-2">
            <div className="pb-2">{t("thank_you_for_submitting")}</div>
            <div className="pb-2">{t("we_will_process_request")}</div>
            <div>{`${t("please_note_power_turn_off")} ${DateTime.fromJSDate(selectedDate).toFormat('EEEE,  MMMM d, yyyy')}.`}</div>
        </div>

        {isMpower && !isOnCentralPrepay &&
        <div className="pt-4 text-muted">
            <div className="font-weight-bold mb-2">{t("return_home_display_unit")}</div>

            {shouldSendPostage &&
                <div className="mb-3">{t("srp_send_postage_paid_envelope")}</div>
            }

            <div className="mb-2">{t("your_deposit_will_be_sent")}</div>
        </div>
        }


        <div className="d-flex justify-content-end pt-2">
        <Link to="/myaccount/dashboard" className="btn srp-btn btn-lightblue mr-3">
            {t("Go to dashboard")}
        </Link>
        </div>
    </div>
)

YouAreAllSet.propTypes = {
    selectedDate: PropTypes.instanceOf(Date).isRequired,
    isMpower: PropTypes.bool.isRequired,
    isOnCentralPrepay: PropTypes.bool.isRequired,
    shouldSendPostage: PropTypes.bool.isRequired,
    t: PropTypes.func
}

export default withTranslation('stopService')(YouAreAllSet)