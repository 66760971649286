import { outageConstants } from '../../../constants/outage-constants'
import { billAccountConstants } from '../../../constants/bill-account-constants'
import * as loadingStatus from '../../../constants/loading-status-constants'
import { DateTime } from 'luxon'

const initialState = {
    requestCallback: false,
    isReportingOutage: false,
    lastReportedOutage: {
        hasReported: false,
        dateReported: DateTime.fromISO("0001-01-01T00:00:00"),
        callBackPhoneNumber: "",
        requestCallback: false
    },
    lastReportedOutageStatus: loadingStatus.LOADING_STATUS_INIT,
    hasMedicalSupportOption: false,
    hasMedicalSupportOptionStatus: loadingStatus.LOADING_STATUS_INIT,
    isIneligibleToReport: false,
    isIneligibleToReportStatus: loadingStatus.LOADING_STATUS_INIT
}

export default function reportOutageReducer(state = initialState, action) {
    switch (action.type) {
        case outageConstants.TOGGLE_POWER_RESTORED_CALLBACK:
            return { ...state, requestCallback: action.isRequestCallback }
        case outageConstants.REPORT_OUTAGE_REQUEST:
            return { ...state, isReportingOutage: true}
        case outageConstants.REPORT_OUTAGE_SUCCESS:
            return { ...state, isReportingOutage: false, requestCallback: false,  lastReportedOutage: action.payload.lastReportedOutage}
        case outageConstants.REPORT_OUTAGE_FAILURE:
            return { ...state, isReportingOutage: false }
        case outageConstants.GET_MEDICAL_SUPPORT_OPTION_REQUEST:
            return { ...state, hasMedicalSupportOptionStatus: loadingStatus.LOADING_STATUS_IN_PROGRESS }
        case outageConstants.GET_MEDICAL_SUPPORT_OPTION_SUCCESS:
            return { ...state, hasMedicalSupportOption: action.payload, hasMedicalSupportOptionStatus: loadingStatus.LOADING_STATUS_SUCCESS }
        case outageConstants.GET_MEDICAL_SUPPORT_OPTION_FAILURE:
            return { ...state, hasMedicalSupportOption: { ...initialState.hasMedicalSupportOption }, hasMedicalSupportOptionStatus: loadingStatus.LOADING_STATUS_FAILURE }
        case outageConstants.GET_INELIGIBLE_TO_REPORT_REQUEST:
            return { ...state, isIneligibleToReportStatus: loadingStatus.LOADING_STATUS_IN_PROGRESS }
        case outageConstants.GET_INELIGIBLE_TO_REPORT_SUCCESS:
            return { ...state, isIneligibleToReport: action.payload, isIneligibleToReportStatus: loadingStatus.LOADING_STATUS_SUCCESS }
        case outageConstants.GET_INELIGIBLE_TO_REPORT_FAILURE:
            return { ...state, isIneligibleToReport: { ...initialState.isIneligibleToReport }, isIneligibleToReportStatus: loadingStatus.LOADING_STATUS_FAILURE }
        case outageConstants.GET_LAST_REPORTED_OUTAGE_REQUEST:
            return { ...state, lastReportedOutageStatus: loadingStatus.LOADING_STATUS_IN_PROGRESS }
        case outageConstants.GET_LAST_REPORTED_OUTAGE_SUCCESS:
            return { ...state, lastReportedOutage: {...action.payload, dateReported: DateTime.fromISO(action.payload.dateReported)}, lastReportedOutageStatus: loadingStatus.LOADING_STATUS_SUCCESS }
        case outageConstants.GET_LAST_REPORTED_OUTAGE_FAILURE:
            return { ...state, lastReportedOutage: initialState.lastReportedOutage, lastReportedOutageStatus: loadingStatus.LOADING_STATUS_FAILURE }
        case billAccountConstants.SELECT_BILL_ACCOUNT_SUCCESS:
            return initialState
        default:
            return state
    }
}