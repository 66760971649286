import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { DocumentTitle } from '../../srp_modules/document-title'
import { Card, CircularProgress } from '@mui/material'

import { withTranslation } from 'react-i18next'

import AddLandlordCard from './add-landlord-card'
import LandlordCard from './landlord-card'

import { getLandlordAccounts } from '../../actions/auth/landlord/landlord-actions'
import { LOADING_STATUS_INIT, LOADING_STATUS_IN_PROGRESS, LOADING_STATUS_FAILURE } from '../../constants/loading-status-constants'

class LandlordPageContainer extends React.Component {
    componentDidMount() {
        if (this.props.landlordAccountsStatus === LOADING_STATUS_INIT
            || this.props.landlordAccountsStatus === LOADING_STATUS_IN_PROGRESS) {
            this.props.getLandlordAccounts()
        }
    }

    render() {
        const t = this.props.t
        const i18n = this.props.i18n

        let landlordContent
        if (this.props.landlordAccountsStatus === LOADING_STATUS_INIT
            || this.props.landlordAccountsStatus === LOADING_STATUS_IN_PROGRESS) {
            landlordContent = (<React.Fragment>
                                   <div className="col-12 col-lg-9">
                                       <Card className="d-flex srp-card-details justify-content-center align-items-center" style={{ height: '15rem' }}>
                                           <CircularProgress thickness={5} />
                                       </Card>
                                   </div>
                               </React.Fragment>)
        }
        else if (this.props.landlordAgreementList.length === 0 && this.props.landlordAccountsStatus !== LOADING_STATUS_FAILURE) {
            landlordContent = <AddLandlordCard t={t} i18n={i18n} />
        }
        else {
            landlordContent = <LandlordCard getLandlordAccounts={this.props.getLandlordAccounts} t={t} i18n={i18n} />
        }

        return (
            <DocumentTitle title={t("Landlord Summary | SRP Power")}>
                <div className="container py-3 ">
                    <h3 className="mt-2 mb-2">{t("Landlord summary")}</h3>
                    <h5 className="mt-2 align-middle">
                        <span className="align-middle"><Link to="/myaccount/dashboard">{t("My Account Dashboard")}</Link></span>
                        <i className="material-icons align-middle text-muted">keyboard_arrow_right</i>
                        <span className="align-middle">{t("Landlord summary")}</span>
                    </h5>
                    <div className="row">
                        {landlordContent}
                    </div>
                </div>
            </DocumentTitle>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        ...state.landlord
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getLandlordAccounts: () => {
            dispatch(getLandlordAccounts())
        }
    }
}

LandlordPageContainer.propTypes = {
    landlordAgreementList: PropTypes.array.isRequired,
    landlordAccountsStatus: PropTypes.string.isRequired,

    getLandlordAccounts: PropTypes.func.isRequired,

    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired,
}

export default withTranslation('landlordSummary')(connect(mapStateToProps, mapDispatchToProps)(LandlordPageContainer))