import React from 'react'
import PropTypes from 'prop-types'
import TextField from '@mui/material/TextField'
import addressAutocompleteModule from '../srp_modules/address-autocomplete'

class AddressWithAutoComplete extends React.Component {
    constructor(props) {
        super(props)
    }

    componentDidUpdate() {
        addressAutocompleteModule.initializeGoogleAutocomplete(
            this.props.fieldName, this.props.fieldValue, this.props.updateAddressCallback, this.props.isDomesticAddress)
    }

    componentWillUnmount(){
        addressAutocompleteModule.removeAutocompleteListener(this.props.fieldName)
    }

    render() {
        return (
            <TextField
                fullWidth
                className="mb-2"
                id={this.props.fieldName}
                name={this.props.fieldName}
                inputProps={{ maxLength: 39 }}
                value={this.props.fieldValue}
                onChange={e => this.props.handleAddressChange(e)}
                onBlur={e => this.props.handleAddressBlur(e)}
                helperText={this.props.errorText}
                error={this.props.hasError}
                label={this.props.label}
            />
        )
    }
}

AddressWithAutoComplete.propTypes = {
    fieldName: PropTypes.string,
    fieldValue: PropTypes.string,
    handleAddressChange: PropTypes.func,
    handleAddressBlur: PropTypes.func,
    errorText: PropTypes.string,
    hasError: PropTypes.bool,
    label: PropTypes.string,
    updateAddressCallback: PropTypes.func,
    isDomesticAddress: PropTypes.bool
}


export default AddressWithAutoComplete