import React from 'react'
import PropTypes from 'prop-types'

import '../../styles/outages-page.css'

import CallAnytime from '../myaccount_header/call-anytime'
import PublicOutageMap from './outages-map-public'

const PublicOutagePage = ({refreshOutageMapCard, t, i18n}) => {
    return (
        <div>
            <div className="container py-3 ">
                <CallAnytime />
                <h3 className="mt-3">{t("View outages")}</h3>
                <h5 className="text-muted mb-4">{t("Get outage details and restoration times")}</h5>
                
                <div className="row">
                    <div className="col-12 mt-4">
                        <div className="srp-card-header">
                            {t("Outage map")}
                        </div>
                        <div className="srp-card-body">
                            <PublicOutageMap refreshOutageMapCard={refreshOutageMapCard} t={t} i18n={i18n} />
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    )
}

PublicOutagePage.propTypes = {
    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired,
    refreshOutageMapCard: PropTypes.func
}

export default PublicOutagePage