export const paymentLocationConstants = {
    
    GET_PAY_LOCATION_MAP: 'GET_PAY_LOCATION_MAP',

    FIND_PAYMENT_LOCATIONS: 'FIND_PAYMENT_LOCATIONS',
    CLEAR_CLOSEST_PAY_LOCATIONS: 'CLEAR_CLOSEST_PAY_LOCATIONS',
    SET_FILTERED_PAY_LOCATION_LIST: 'SET_FILTERED_PAY_LOCATION_LIST',

    GET_CURRENT_LOCATION: 'GET_CURRENT_LOCATION',
    GET_END_ADDRESS_FOR_DIRECTIONS: 'GET_END_ADDRESS_FOR_DIRECTIONS',
    GET_START_ADDRESS_FOR_DIRECTIONS: 'GET_START_ADDRESS_FOR_DIRECTIONS',
    GET_SEARCH_ADDRESS_COORDS: 'GET_SEARCH_ADDRESS_COORDS',
    
    HIDE_PAY_LOCATION_DETAILS: 'HIDE_PAY_LOCATION_DETAILS',
    SHOW_PAY_LOCATION_DETAILS: 'SHOW_PAY_LOCATION_DETAILS',
    SHOW_START_ADDR_DETAILS: 'SHOW_START_ADDR_DETAILS',
    HIDE_START_ADDR_DETAILS: 'HIDE_START_ADDR_DETAILS',

    GET_ALL_PAY_LOCATIONS_REQUEST: 'GET_ALL_PAY_LOCATIONS_REQUEST',
    GET_ALL_PAY_LOCATIONS_SUCCESS: 'GET_ALL_PAY_LOCATIONS_SUCCESS',
    GET_ALL_PAY_LOCATIONS_FAILURE: 'GET_ALL_PAY_LOCATIONS_FAILURE',

    PAY_LOCATION_TYPE: {
        PAY_CENTER: 0,
        RETAIL: 1,
        ALL: 2
    }
}