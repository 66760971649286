import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import HelpCard from './help'
import BusinessStartCard from './business-card'
import TerritoryCard from './territory-card'
import ServiceOptionCard from './service-option-card'
import Dialog from '@mui/material/Dialog'
import CircularProgress from '@mui/material/CircularProgress'
import AccountInfoBarContainer from '../myaccount_header/info_bar/account-info-bar-container'
import { isAccountBlockedFromTurnOnOff } from './../../actions/auth/bill_account/bill-account-actions'
import { getRateMetaData } from '../../actions/auth/usage/rate-meta-data-actions'
import * as loadingStatus from '../../constants/loading-status-constants'
import { myAccountConstants } from '../../constants/myaccount-constants'
import { withRouter } from '../../srp_modules/with-router'


let progressCirclestyle = {
  marginTop: "10%",
  marginBottom: "20%"
}

class StartStopTransferPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isDisplayMessageShown: false,
    }

    this.setModalMessage = this.setModalMessage.bind(this)
    this.toggleMessagePopup = this.toggleMessagePopup.bind(this)
    this.checkBlocked = this.checkBlocked.bind(this)
    this.getPageDetails = this.getPageDetails.bind(this)
  }

  componentDidMount() {
    if (this.props.billAccount === 0)
      return

    if (this.props.blockFromTurnOffOnStatus === loadingStatus.LOADING_STATUS_INIT) {
      this.props.checkIfAccountIsBlocked(this.props.billAccount, this.props.isLoggedIn)
    }

    if (this.props.rateMetaDataStatus === loadingStatus.LOADING_STATUS_INIT) {
      this.props.getRateMetaDataInfo(this.props.billAccount)
    }


  }

  componentDidUpdate(prevProps) {
    if (this.props.billAccount === prevProps.billAccount || this.props.billAccount === 0)
      return

    if (this.props.blockFromTurnOffOnStatus !== loadingStatus.LOADING_STATUS_IN_PROGRESS) {
      this.props.checkIfAccountIsBlocked(this.props.billAccount, this.props.isLoggedIn)
    }

    if (this.props.rateMetaDataStatus !== loadingStatus.LOADING_STATUS_IN_PROGRESS) {
      this.props.getRateMetaDataInfo(this.props.billAccount)
    }
  }

  setModalMessage() {

    let residentialCustomerServiceNumber = myAccountConstants.RESIDENTIAL_CUSTOMER_SERVICE_PHONE_NUMBER_FMTTD

    if (this.props.i18n.language === 'es')
    {
        residentialCustomerServiceNumber = myAccountConstants.SPANISH_CUSTOMER_SERVICE_PHONE_NUMBER_FMTTD
    }

    if (!this.props.isLoggedIn) return ""

    if (this.props.rateMetaData.isSolar || this.props.rateMetaData.shortDesription === "E-27") {
      return `${this.props.t("Please contact us at")} (602) 236-4448 ${this.props.t("to speak with a representative about your request.  We're available to serve you weekdays from 7 a.m. - 7 p.m.")}`
    }
    else if (this.props.isCommercial || this.props.rateMetaData.isBusinessOnResidentialPlan) {
      return `${this.props.t("Please contact us at")} (602) 236-8833 ${this.props.t("to speak with a representative about your request.  We're available to serve you weekdays from 7 a.m. - 5 p.m., excluding holidays.")}`
    }
    else {
      return `${this.props.t("Please contact us at")} ${residentialCustomerServiceNumber} ${this.props.t("to speak with a representative about your request. We're available to serve you 24 hours a day, seven days a week.")}`
    }
  }

  toggleMessagePopup(event) {
    event.preventDefault()
    this.setState({ isDisplayMessageShown: !this.state.isDisplayMessageShown })
  }

  checkBlocked(event, isBlocked) {
    if (isBlocked) {
      this.toggleMessagePopup(event)
    }
  }

  getPageDetails() {

    if (this.props.isLoggedIn
      && (this.props.blockFromTurnOffOnStatus === loadingStatus.LOADING_STATUS_INIT
      || this.props.blockFromTurnOffOnStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS
      || this.props.rateMetaDataStatus === loadingStatus.LOADING_STATUS_INIT
      || this.props.rateMetaDataStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS)) {
      return <div className="d-flex justify-content-center"><CircularProgress size={80} thickness={5} style={progressCirclestyle} /></div>
    }
    else {
      return (
        <div className="d-md-block">
          <div className="row">
            <div className="col-md-6 mt-4">
              <ServiceOptionCard
                props={this.props}
                blockCheckFunc={this.checkBlocked}
                blockForSolar={this.props.rateMetaData.isSolar || this.props.rateMetaData.shortDesription === "E-27"}
              />
              <TerritoryCard />
            </div>

            <div className="col-md-6 mt-4">
              <HelpCard isCommercial={this.props.isCommercial || this.props.rateMetaData.isBusinessOnResidentialPlan} />
              <BusinessStartCard />
            </div>
          </div>
        </div>
      )
    }
  }

  render() {
    return (
      <div>
        <div className="container py-3">
          <h3 className="mt-3">{this.props.t("Start, stop or transfer Electric Service")}</h3>
          <div className="py-3">
            {this.props.isLoggedIn &&
              <AccountInfoBarContainer disableSelector={true} />
            }
          </div>
          {this.getPageDetails()}
        </div>
        <Dialog
          modal="true"
          open={this.state.isDisplayMessageShown}
          PaperProps={{ className: "srp-modal" }}>
          <div className="srp-modal-body">
            <div className="srp-modal-close" onClick={(e) => this.toggleMessagePopup(e)} />
            <div>
              <div>
                {this.setModalMessage()}
                <div className="srp-modal-footer mb-0 pt-3">
                  <button className="btn srp-btn btn-blue" onClick={(e) => this.toggleMessagePopup(e)}>OK</button>
                </div>
              </div>
            </div>
          </div>
        </Dialog>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    isLoggedIn: state.login.isLoggedIn,
    blockedFromStartStopTransfer: state.accountInfo.billAccount.selectedBlockFromTurnOffOn,
    blockFromTurnOffOnStatus: state.accountInfo.billAccount.blockFromTurnOffOnStatus,
    isCommercial: state.accountInfo.billAccount.selectedBillAccountDetails.isCommercial,
    billAccount: state.accountInfo.billAccount.selectedBillAccount,
    ...state.rateMetaData
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    checkIfAccountIsBlocked: (billAccount, isLoggedIn) => dispatch(isAccountBlockedFromTurnOnOff(billAccount, isLoggedIn)),
    getRateMetaDataInfo: (billAccount) => {
      dispatch(getRateMetaData(billAccount))
    }
  }
}

StartStopTransferPage.propTypes = {
  billAccount: PropTypes.number,
  checkIfAccountIsBlocked: PropTypes.func,
  isLoggedIn: PropTypes.bool,
  isCommercial: PropTypes.bool,
  blockedFromStartStopTransfer: PropTypes.object,
  blockFromTurnOffOnStatus: PropTypes.string,
  router: PropTypes.shape({
    location: PropTypes.object,
    navigate: PropTypes.func
  }),
  rateMetaDataStatus: PropTypes.string,
  getRateMetaDataInfo: PropTypes.func,
  rateMetaData: PropTypes.object,
  t: PropTypes.func.isRequired,
  i18n: PropTypes.object.isRequired
}

export default withTranslation("startStopTransfer")(withRouter(connect(mapStateToProps, mapDispatchToProps)(StartStopTransferPage)))