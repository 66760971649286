import React from "react"
import PropTypes from "prop-types"

import config from 'my-account-config'

import { Trans } from 'react-i18next'

const MPowerNegativeBalanceExplanation = ({cssClass, i18n, t}) => {
    const accumulatedDebtInfoPage = i18n.language==='es'
        ? `${config.srpnetBaseUrlEs}planes-de-precio/residencial-electrico/m-power/creditos-adelantos-deuda-acumulada`
        : `${config.srpnetBaseUrl}price-plans/residential-electric/m-power/credit-advances-accumulated-debt`

    return (
      <>
      <div className={`srp-alert-notice ${cssClass}`}>
        <div className="font-weight-bold">
          {t("Why do I have accumulated debt?")}
        </div>
        <div className="mt-2">
          <Trans i18nKey="negative_balance_explanation" t={t}>
          Electricity used after your meter runs out of credit appears as <a href={accumulatedDebtInfoPage} target="_blank">Accumulated Debt</a>.
          </Trans>
        </div>
      </div>
      </>
    )
  }

  MPowerNegativeBalanceExplanation.propTypes = {
    cssClass: PropTypes.string.isRequired,
    i18n: PropTypes.shape({
      language: PropTypes.string.isRequired,
    }).isRequired,
    t: PropTypes.func.isRequired,
  }

  export default MPowerNegativeBalanceExplanation