import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import '../../../styles/dashboard-page.css'
import format from '../../../srp_modules/format'
import { submitPurchases, clearSubmitPurchaseError } from '../../../actions/auth/payment/multi-payment-ngp-actions'
import VerifyMultiPaymentNgpPage from './verify-multi-payment-ngp-page'
import utils from '../../../srp_modules/utils'
import { withRouter } from '../../../srp_modules/with-router'

import { formCompleteEvent, formErrorEvent } from '../../../srp_modules/analytics-events'
import { purchaseConstants } from '../../common_payment/purchase-constants'

let getSelectedBankAccountDescription = (bankAccountList, selectedBankAccountId) => {
    if (selectedBankAccountId > 0) {
        let selectedBank = bankAccountList.find((bankAccount) => bankAccount.bankId === selectedBankAccountId)
        return format.bankAccount(selectedBank)
    } else {
        return ''
    }
}

let getTotalPurchase = (purchaseList) => {
    let total = 0
    purchaseList.forEach(purchase => {
        total += utils.paymentToNumber(purchase.purchaseAmount)
    })

    return total
}

class VerifyMultiPaymentNgpPageContainer extends React.Component {
    constructor(props) {
        super(props)

        if (this.isStateEmpty())
            this.props.router.navigate('/myaccount/payment/multi/mpower', { replace: true })

        this.onSubmitClick = this.onSubmitClick.bind(this)
    }

    isStateEmpty() { return this.props.purchaseList === undefined || this.props.purchaseList.length === 0 }

    onSubmitClick() {
        this.setState({ ...this.state })

        const purchaseInfo = {
            purchaseList: this.props.purchaseList.map(purchase => {
                const purchaseAmount = utils.paymentToNumber(purchase.purchaseAmount)
                const applyWholePurchaseTowardsArrears = purchase.payAmountOwedToSRP

                const paymentInfo = this.props.paymentInfoList.find(pi => pi.billAccount === purchase.billAccount)
                const amountOwedToSRP = paymentInfo.paymentInfo.amountDue
                const payDownFraction = paymentInfo.paymentInfo.payDownFraction

                let amountToSRP = 0
                let amountToMeter = purchaseAmount

                if (amountOwedToSRP > 0)
                    ({ amountToMeter, amountToSRP } = utils.calcAmountToMeterAndToSRP({ purchaseAmount, amountOwedToSRP, payDownFraction, applyWholePurchaseTowardsArrears }))

                return { amountToSRP, amountToMeter }
            }),
            sendCreditToMeter: true,
        }

        this.props.onSubmitClick(purchaseInfo)
    }

    render() {
        return (
            !this.isStateEmpty() &&
            <div>
                <VerifyMultiPaymentNgpPage
                    purchaseList={this.props.purchaseList}
                    paymentInfoList={this.props.paymentInfoList}
                    totalPurchase={getTotalPurchase(this.props.purchaseList)}
                    submitMultiPurchaseResultStatus={this.props.submitMultiPurchaseResultStatus}
                    onSubmitClick={this.onSubmitClick}
                    selectedBankAccountDescription={this.props.selectedBankAccountDescription}
                    showSubmitError={this.props.showSubmitError}
                    clearSubmitPurchaseError={this.props.clearSubmitPurchaseError}
                />
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const purchaseList = state.multiPaymentNgp.purchaseList
    const paymentInfoList = state.multiPaymentNgp.mpowerPaymentInfoList

    return {
        bankAccountList: state.bankAccounts.bankAccountList,
        bankAccountListStatus: state.bankAccounts.bankAccountListStatus,
        purchaseList,
        paymentInfoList,
        submitMultiPurchaseResultStatus: state.multiPaymentNgp.submitMultiPurchaseResultStatus,
        selectedBankAccountDescription: getSelectedBankAccountDescription(state.bankAccounts.bankAccountList, state.multiPaymentNgp.selectedBankAccountId),
        showSubmitError: state.multiPaymentNgp.errorSubmittingPurchases,
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onSubmitClick: async ({ purchaseList, sendCreditToMeter }) => {
            const formName = purchaseConstants.ADOBE_ANALYTICS_FORM_NAME

            let submitPurchaseResult = await dispatch(submitPurchases())

            if (submitPurchaseResult.error === true) {
                formErrorEvent(formName)
            } else if (submitPurchaseResult.payload.referenceNumber > 0) {
                const totalAmountToSRP = purchaseList.reduce((prevAmountToSRP, curr) => prevAmountToSRP + curr.amountToSRP, 0)
                const totalAmountToMeter = purchaseList.reduce((prevAmountToMeter, curr) => prevAmountToMeter + curr.amountToMeter, 0)

                const meta = {
                    purchaseCount: purchaseList.length,
                    purchases: purchaseList,
                    total: {
                        amountToSRP: totalAmountToSRP,
                        amountToMeter: totalAmountToMeter,
                    },
                    sendCreditToMeter
                }
                formCompleteEvent(formName, meta)

                ownProps.router.navigate('/myaccount/payment/multi/mpower/submitted', { replace: true })
            }
        },
        clearSubmitPurchaseError: () => {
            dispatch(clearSubmitPurchaseError())
        }
    }
}

VerifyMultiPaymentNgpPageContainer.propTypes = {
    bankAccountList: PropTypes.arrayOf(PropTypes.object),
    bankAccountListStatus: PropTypes.string.isRequired,
    purchaseList: PropTypes.arrayOf(PropTypes.object).isRequired,
    paymentInfoList: PropTypes.arrayOf(PropTypes.object).isRequired,
    submitMultiPurchaseResultStatus: PropTypes.string.isRequired,
    selectedBankAccountDescription: PropTypes.string.isRequired,
    onSubmitClick: PropTypes.func.isRequired,
    showSubmitError: PropTypes.bool.isRequired,
    clearSubmitPurchaseError: PropTypes.func.isRequired,
    router: PropTypes.shape({
        navigate: PropTypes.func
    })
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(VerifyMultiPaymentNgpPageContainer))