import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import SuccessCheck from '../../../images/common/SuccessCheck.png'
import { solarNonprofitFormConstants } from '../../../constants/solar-nonprofit-constants'

const SolarNonprofitConfirmation = ({ formType, t }) => {

    return (
        <div className="srp-card-body text-muted">
            <div className="srp-card-details">
                <div className="d-lg-block d-none">
                    <div className="mb-3 srp-olive-green d-flex align-items-center">
                        <img className="img-fluid mr-3" src={SuccessCheck} style={{ width: '50px' }} />
                        <span className="font-28 fw-500">{t("You're all set!")}</span>
                    </div>
                </div>
                <div className="d-lg-none d-block">
                    <div className="mb-3 srp-olive-green text-center">
                        <img className="img-fluid mr-3" src={SuccessCheck} style={{ width: '50px' }} />
                        <div className="font-28 fw-500">{t("You're all set!")}</div>
                    </div>
                </div>

                <div className="text-muted font-28 fw-500">{t("We've received your request")}</div>
                <div className="text-muted mb-4">
                    {formType === solarNonprofitFormConstants.SOLAR_NONPROFIT_SIGNUP
                        ? <div>
                            <div className="mb-2">{t("Thank you for enrolling in Solar for Nonprofits")}</div>
                            <div className="mb-2">{t("Your contributions will be used to build solar energy projects for local nonprofits.")}</div>
                            <div>{t("You should be receiving a confirmation email from us shortly.")}</div>
                        </div>
                        : t("You should be receiving a confirmation email from us shortly.")
                    }
                </div>

                <div className="d-flex flex-wrap justify-content-end">
                    <Link to="/myaccount/dashboard" className="btn srp-btn btn-lightblue ml-2 mb-2">{t("Go to dashboard")}</Link>
                </div>
            </div>
        </div>
    )

}

SolarNonprofitConfirmation.propTypes = {
    formType: PropTypes.number,
    t: PropTypes.func.isRequired,
}

export default SolarNonprofitConfirmation