import React from 'react'
import PropTypes from 'prop-types'

import '../../styles/outages-page.css'

import MyAccountMainContainer from '../../containers/my-account-main-container'
import OutagesMapContainer from './outages-map-container'
import OutageHistoryContainer from './outage-history-container'
import OutageAlert from './outage-alert'
import ReportOutageSubmitted from './report-outage-submitted-alert'
import PendingTurnOnCard from '../common_pending_turn_on/pending-turn-on-card'

import * as loadingStatus from '../../constants/loading-status-constants'

const OutagesPage = ({ customerInOutageInfo, lastReportedOutage, lastReportedOutageStatus, outageHistoryList, outageHistoryStatus,
    refreshOutageHistoryCard, refreshOutageMapCard, isPendingTurnOn, navLocation, t, i18n }) => {
    return (
        <MyAccountMainContainer pageTitleEn="Outages" pageTitle={t("Outages")}>
            <OutageAlert customerInOutageInfo={customerInOutageInfo} t={t} />
            {lastReportedOutageStatus === loadingStatus.LOADING_STATUS_SUCCESS
                && outageHistoryStatus === loadingStatus.LOADING_STATUS_SUCCESS
                && <ReportOutageSubmitted lastReportedOutage={lastReportedOutage} outageHistoryList={outageHistoryList} t={t} />
            }
            {isPendingTurnOn
                ? <PendingTurnOnCard navLocation={navLocation} />
                : <div className="row">
                        <div className="col-lg-8 col-12 mt-4">
                            <div className="srp-card-header">
                                {t("Outage map")}
                            </div>
                            <div className="srp-card-body">
                                <OutagesMapContainer refreshOutageMapCard={refreshOutageMapCard} t={t} i18n={i18n} />
                            </div>
                        </div>

                        <div className="col-lg-4 col-12 mt-4" >
                            <OutageHistoryContainer refreshOutageHistoryCard={refreshOutageHistoryCard} t={t} i18n={i18n} />
                        </div>
                    </div>
            }
        </MyAccountMainContainer>
    )
}

OutagesPage.propTypes = {
    navLocation: PropTypes.string,
    customerInOutageInfo: PropTypes.object,
    lastReportedOutage: PropTypes.object,
    lastReportedOutageStatus: PropTypes.string,
    outageHistoryList: PropTypes.arrayOf(PropTypes.object),
    outageHistoryStatus: PropTypes.string,
    refreshOutageHistoryCard: PropTypes.func,
    refreshOutageMapCard: PropTypes.func,
    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired,
    isPendingTurnOn: PropTypes.bool
}

export default OutagesPage;