/* eslint react/no-danger: 0 */
import React from 'react'
import PropTypes from 'prop-types';

const Footer = ({footerHtml}) => {
    return footerHtml
        ? (
        <div className="d-print-none">
            <div id="footer" dangerouslySetInnerHTML={{ __html: footerHtml }} />
        </div>
    ) : null
}

Footer.propTypes = {
    footerHtml: PropTypes.string
}

export default Footer