import React from 'react'
import PropTypes from 'prop-types'

const CreditAdvanceTerms = ({t}) => (
    <div>
        <div className="credit-advance-popup-text d-none d-lg-block">
            <span className="credit-advance-popup-section-header">{t("Advance")}</span><br />
            {t("Advance_definition")}<br /><br />
            <span className="credit-advance-popup-section-header">{t("Request amount")}</span><br />
            {t("Request_amount_definition")}<br /><br />
            <span className="credit-advance-popup-section-header">{t("Request number")}</span><br />
            {t("Request_number_definition")}<br /><br />
            <span className="credit-advance-popup-section-header">{t("Payback percentage")}</span><br />
            {t("Payback_percentage_definition")}<br /><br />
            <span className="credit-advance-popup-section-header">{t("New amount owed to SRP")}</span><br />
            {t("New_amount_owed_to_SRP_definition")}<br /><br />
        </div>
        <div className="credit-advance-popup-text-responsive d-block d-lg-none">
            <span className="credit-advance-popup-section-header">{t("Advance")}</span><br />
            {t("Advance_definition")}<br /><br />
            <span className="credit-advance-popup-section-header">{t("Request amount")}</span><br />
            {t("Request_amount_definition")}<br /><br />
            <span className="credit-advance-popup-section-header">{t("Request number")}</span><br />
            {t("Request_number_definition")}<br /><br />
            <span className="credit-advance-popup-section-header">{t("Payback percentage")}</span><br />
            {t("Payback_percentage_definition")}<br /><br />
            <span className="credit-advance-popup-section-header">{t("New amount owed to SRP")}</span><br />
            {t("New_amount_owed_to_SRP_definition")}<br /><br />
        </div>
    </div>
)

CreditAdvanceTerms.propTypes = {
    t: PropTypes.func.isRequired,
}

export default CreditAdvanceTerms