import { billAccountConstants } from '../../../constants/bill-account-constants'
import { contactConstants } from '../../../constants/contact-constants'
import { notificationConstants } from '../../../constants/notification-constants'
import { myAccountConstants } from '../../../constants/myaccount-constants'
import * as loadingStatus from '../../../constants/loading-status-constants'

const initialState = {
    contacts: [],
    contactsStatus: loadingStatus.LOADING_STATUS_INIT,
    refreshContactsStatus: loadingStatus.LOADING_STATUS_INIT,
    primaryEmail: '',
    primaryPhone: '',
    primaryEmailStatus: loadingStatus.LOADING_STATUS_INIT,
    addContactsStatus: loadingStatus.LOADING_STATUS_INIT,
    addContactsResult: myAccountConstants.CUSTOMER_ACCOUNT_DATA_STATUS.UNKNOWN,
    deleteContactsStatus: loadingStatus.LOADING_STATUS_INIT,
    deleteContactsResult: myAccountConstants.CUSTOMER_ACCOUNT_DATA_STATUS.UNKNOWN,
    moveSubscriptionsAndDeleteContactStatus: loadingStatus.LOADING_STATUS_INIT,
    moveSubscriptionsAndDeleteContactResult: myAccountConstants.CUSTOMER_ACCOUNT_DATA_STATUS.UNKNOWN,
    reorderContactsStatus: loadingStatus.LOADING_STATUS_INIT,
    reorderContactsResult: myAccountConstants.CUSTOMER_ACCOUNT_DATA_STATUS.UNKNOWN,
    updateContactsStatus: loadingStatus.LOADING_STATUS_INIT,
    updateContactsResult: myAccountConstants.CUSTOMER_ACCOUNT_DATA_STATUS.UNKNOWN,
    subscribedContacts: {
        emails: {},
        phones: {}
    }
}

export function getSubscribedContacts(notifications) {
    let subscribed = {
        emails: {},
        phones: {}
    }

    notifications.subscribed.forEach(notification => {
        notification.contacts.forEach(contact => {
            if (contact.contactType === contactConstants.CONTACT_TYPE.EMAIL) {
                if (!Object.prototype.hasOwnProperty.call(subscribed.emails, contact.contactAddress)) {
                    let subscribedNotification = JSON.parse(JSON.stringify(notification))
                    subscribedNotification.contacts = [contact]
                    subscribedNotification.description = ''
                    subscribed.emails[contact.contactAddress] = {
                        contact,
                        subscribedNotifications: [subscribedNotification]
                    }
                } else {
                    subscribed.emails[contact.contactAddress].subscribedNotifications.push(notification)
                }
            }
            if (contact.contactType === contactConstants.CONTACT_TYPE.PHONE) {
                if (!Object.prototype.hasOwnProperty.call(subscribed.phones, contact.contactAddress)) {
                    let subscribedNotification = JSON.parse(JSON.stringify(notification))
                    subscribedNotification.contacts = [contact]
                    subscribedNotification.description = ''
                    subscribed.phones[contact.contactAddress] = {
                        contact,
                        subscribedNotifications: [subscribedNotification]
                    }
                } else {
                    subscribed.phones[contact.contactAddress].subscribedNotifications.push(notification)
                }
            }
        })
    })

    return subscribed
}

function getPrimaryEmail(contacts) {
    let primaryEmail = ''
    let i = 0
    do {
        if (contacts[i].contactType === contactConstants.CONTACT_TYPE.EMAIL && contacts[i].displaySequenceNumber === 1) {
            primaryEmail = contacts[i].contactAddress
        }
        i++
    } while (primaryEmail === '' && i < contacts.length)

    return primaryEmail
}

function getPrimaryPhone(contacts) {
    let primaryPhone = ''
    let i = 0
    do {
        if (contacts[i].contactType === contactConstants.CONTACT_TYPE.PHONE && contacts[i].displaySequenceNumber === 1) {
            primaryPhone = contacts[i].contactAddress
        }
        i++
    } while (primaryPhone === '' && i < contacts.length)

    return primaryPhone
}


function filterContacts(contacts) {
    // filter out push contacts since the website won't be used to manage push notifications
    let filteredContactList = []

    contacts.forEach(contact => {
        if (contact.contactType !== contactConstants.CONTACT_TYPE.PUSH) {
            filteredContactList.push(contact)
        }
    })

    return filteredContactList
}

export default function contactReducer (state = initialState, action) {
    switch (action.type) {
        case contactConstants.GET_CONTACTS_REQUEST:
            return { ...state, contactsStatus: loadingStatus.LOADING_STATUS_IN_PROGRESS, primaryEmailStatus: loadingStatus.LOADING_STATUS_IN_PROGRESS }
        case contactConstants.GET_CONTACTS_SUCCESS:
            return { ...state, contacts: filterContacts(action.payload), contactsStatus: loadingStatus.LOADING_STATUS_SUCCESS,
                primaryPhone: getPrimaryPhone(action.payload), primaryEmail: getPrimaryEmail(action.payload), primaryEmailStatus: loadingStatus.LOADING_STATUS_SUCCESS }
        case contactConstants.GET_CONTACTS_FAILURE:
            return { ...state, contacts: initialState.contacts, contactsStatus: loadingStatus.LOADING_STATUS_FAILURE, primaryEmail: initialState.primaryEmail, primaryEmailStatus: loadingStatus.LOADING_STATUS_FAILURE }

        case contactConstants.REFRESH_CONTACTS_REQUEST:
            return { ...state, refreshContactsStatus: loadingStatus.LOADING_STATUS_IN_PROGRESS, primaryEmailStatus: loadingStatus.LOADING_STATUS_IN_PROGRESS }
        case contactConstants.REFRESH_CONTACTS_SUCCESS:
            return { ...state, contacts: filterContacts(action.payload), refreshContactsStatus: loadingStatus.LOADING_STATUS_SUCCESS,
                primaryPhone: getPrimaryPhone(action.payload), primaryEmail: getPrimaryEmail(action.payload), primaryEmailStatus: loadingStatus.LOADING_STATUS_SUCCESS }
        case contactConstants.REFRESH_CONTACTS_FAILURE:
            return { ...state, contacts: initialState.contacts, refreshContactsStatus: loadingStatus.LOADING_STATUS_FAILURE, primaryEmail: initialState.primaryEmail, primaryEmailStatus: loadingStatus.LOADING_STATUS_FAILURE }

        case contactConstants.ADD_CONTACTS_REQUEST:
            return { ...state, addContactsStatus: loadingStatus.LOADING_STATUS_IN_PROGRESS }
        case contactConstants.ADD_CONTACTS_SUCCESS:
            return { ...state, addContactsStatus: loadingStatus.LOADING_STATUS_SUCCESS, addContactsResult: action.payload }
        case contactConstants.ADD_CONTACTS_FAILURE:
            return { ...state, addContactsStatus: loadingStatus.LOADING_STATUS_FAILURE, addContactsResult: initialState.addContactsResult}

        case contactConstants.DELETE_CONTACTS_REQUEST:
            return { ...state, deleteContactsStatus: loadingStatus.LOADING_STATUS_IN_PROGRESS }
        case contactConstants.DELETE_CONTACTS_SUCCESS:
            return { ...state, deleteContactsStatus: loadingStatus.LOADING_STATUS_SUCCESS, deleteContactsResult: action.payload }
        case contactConstants.DELETE_CONTACTS_FAILURE:
            return { ...state, deleteContactsStatus: loadingStatus.LOADING_STATUS_FAILURE, deleteContactsResult: initialState.deleteContactsResult }

        case contactConstants.MOVE_SUBSCRIPTIONS_AND_DELETE_CONTACT_REQUEST:
            return { ...state, moveSubscriptionsAndDeleteContactStatus: loadingStatus.LOADING_STATUS_IN_PROGRESS }
        case contactConstants.MOVE_SUBSCRIPTIONS_AND_DELETE_CONTACT_SUCCESS:
            return { ...state, moveSubscriptionsAndDeleteContactStatus: loadingStatus.LOADING_STATUS_SUCCESS, moveSubscriptionsAndDeleteContactResult: action.payload }
        case contactConstants.MOVE_SUBSCRIPTIONS_AND_DELETE_CONTACT_FAILURE:
            return { ...state, moveSubscriptionsAndDeleteContactStatus: loadingStatus.LOADING_STATUS_FAILURE, moveSubscriptionsAndDeleteContactResult: initialState.moveSubscriptionsAndDeleteContactResult}

        case contactConstants.REORDER_CONTACTS_REQUEST:
            return { ...state, reorderContactsStatus: loadingStatus.LOADING_STATUS_IN_PROGRESS }
        case contactConstants.REORDER_CONTACTS_SUCCESS:
            return { ...state, reorderContactsStatus: loadingStatus.LOADING_STATUS_SUCCESS, reorderContactsResult: action.payload }
        case contactConstants.REORDER_CONTACTS_FAILURE:
            return { ...state, reorderContactsStatus: loadingStatus.LOADING_STATUS_FAILURE, reorderContactsResult: initialState.reorderContactsResult }

        case contactConstants.UPDATE_CONTACTS_REQUEST:
            return { ...state, updateContactsStatus: loadingStatus.LOADING_STATUS_IN_PROGRESS }
        case contactConstants.UPDATE_CONTACTS_SUCCESS:
            return { ...state, updateContactsStatus: loadingStatus.LOADING_STATUS_SUCCESS, updateContactsResult: action.payload }
        case contactConstants.UPDATE_CONTACTS_FAILURE:
            return { ...state, updateContactsStatus: loadingStatus.LOADING_STATUS_FAILURE, updateContactsResult: initialState.updateContactsResult }

        case notificationConstants.GET_NOTIFICATIONS_REQUEST:
        case notificationConstants.REFRESH_NOTIFICATIONS_REQUEST:
            return { ...state, subscribedContacts: initialState.subscribedContacts }
        case notificationConstants.GET_NOTIFICATIONS_SUCCESS:
        case notificationConstants.REFRESH_NOTIFICATIONS_SUCCESS:
            return { ...state, subscribedContacts: getSubscribedContacts(action.payload) }
        case notificationConstants.GET_NOTIFICATIONS_FAILURE:
        case notificationConstants.REFRESH_NOTIFICATIONS_FAILURE:
            return { ...state, subscribedContacts: initialState.subscribedContacts }

        case billAccountConstants.SELECT_BILL_ACCOUNT_SUCCESS:
            return { ...initialState }
        default:
            return { ...state }
    }
}