export const centralPrepayConstants = {

    PREPAY_CENTRAL: "PrepayCentral",
    IN_CONVERSION_TO_CENTRAL: "InConversionToCentral",
    PREPAY_EDGE: "PrepayEdge",
    FORMER_PREPAY_CENTRAL: "FormerPrepayCentral",
    FORMER_PREPAY_EDGE: "FormerPrepayEdge",
    NEVER_PREPAY: "NeverPrepay"
}

export const mpowerMeterStatus = {
    CPP_CONNECTION_PENDING_AUTH: "CPP_ConnectionPendingAuth",
    CPP_CONNECTION_IN_PROGRESS: "CPP_ConnectionInProgress",
    CPP_NO_CONNECTION: "CPP_NoConnection",
    TBD: "TBD",
    GOOD: "Good",
    WARNING: "Warning",
    DANGER: "Danger",
}