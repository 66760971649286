import React from 'react'
import PropTypes from 'prop-types'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'

const AutopayStep = ({activeStep, t}) => {
    let currentStep = activeStep - 1
    return (
        <div className="mt-3">
            <div className="d-lg-block d-none">
                <div className="p-0 mb-4">
                    <Stepper activeStep={currentStep} className="p-0">
                        <Step key={0}>
                            <StepLabel>{t("Account & bank information")}</StepLabel>
                        </Step>
                        <Step key={1}>
                            <StepLabel>{t("Review")}</StepLabel>
                        </Step>
                        <Step key={2}>
                            <StepLabel>{t("Confirmation")}</StepLabel>
                        </Step>
                    </Stepper>
                </div>
            </div>
            <div className="d-lg-none d-block">
                <div className="p-0 mb-4">
                    <Stepper activeStep={currentStep} alternativeLabel className="p-0">
                        <Step key={0}>
                            <StepLabel>{t("Account & bank information")}</StepLabel>
                        </Step>
                        <Step key={1}>
                            <StepLabel>{t("Review")}</StepLabel>
                        </Step>
                        <Step key={2}>
                            <StepLabel>{t("Confirmation")}</StepLabel>
                        </Step>
                    </Stepper>
                </div>
            </div>
        </div>
    )
}

AutopayStep.propTypes = {
    t: PropTypes.func.isRequired,
    activeStep: PropTypes.number.isRequired
}

export default AutopayStep
