import { DateTime } from 'luxon'
import { eBillConstants } from '../constants/ebill-constants'
import config from 'my-account-config'

const accountHistoryModule = {
    getBill,
    exportToExcel
}

function getBill(billList, billDate) {
    const billDateTime = DateTime.fromISO(billDate)
    const billDateThreshold = billDateTime.plus({ days: 5 }) 
    let bill = ""
    for (let i=0; i < billList.length; i++) {
        if (billList[i].documentType === eBillConstants.DOCUMENT_TYPE_BILL) {
            if (DateTime.fromISO(billList[i].documentDate) >= billDateTime && DateTime.fromISO(billList[i].documentDate) <= billDateThreshold) {
                let date = DateTime.fromISO(billList[i].documentDate).toFormat('MMMMyyyy')
                bill = config.apiBaseUrl+"api/documents/eBillDocument?billAccount="+billList[i].billingID+"&documentId="+encodeURIComponent(billList[i].documentID)+"&date="+date+"&inline=true"
            }
        }
    }

    return bill
}

function exportToExcel(tableID, startDate, endDate, e) {
    e.preventDefault()
    let downloadLink
    let dataType = 'application/vnd.ms-excel'
    let tableSelect = document.getElementById(tableID)
    let newTable = document.createElement("table")
    newTable.innerHTML = tableSelect.innerHTML.replace(/ /g, '%20')
    $('.account-history-col-view', newTable).remove()
    
    $('td:hidden,th:hidden', newTable).remove()
    let tableHTML = "<table>"+newTable.innerHTML+"</table>"

    let start = DateTime.fromISO(startDate).toFormat('M_d_yyyy')
    let end = DateTime.fromISO(endDate).toFormat('M_d_yyyy')
    let filename
    if(start !== end) {
        filename = 'AccountHistory'+start+'_to_'+end+'.xls'
    }
    else {
        filename = 'AccountHistory'+start+'.xls'
    }

    downloadLink = document.createElement("a")
    
    document.body.appendChild(downloadLink)
    
    if(navigator.msSaveOrOpenBlob) {
        let msTableHTML = tableHTML
        let attachment = new Blob(['\ufeff', msTableHTML], {
            type: dataType
        })
        navigator.msSaveOrOpenBlob( attachment, filename)
    }
    else {
        downloadLink.href = 'data:' + dataType + ', ' + tableHTML
        downloadLink.download = filename
        downloadLink.click()
    }
}

export default accountHistoryModule