import React from 'react'
import PropTypes from 'prop-types'
import Icon from '@mui/material/Icon'

import utils from '../../../srp_modules/utils'
import DocumentCenterDocError from './document-center-doc-error'
import * as loadingStatus from '../../../constants/loading-status-constants'



const CreditHistoryDocs = ({ creditHistoryLink, creditHistoryLinkResultStatus, refreshFailedLinks, isCommercial, t}) => {
    let descriptiveText = t("Provides information regarding length of service and credit history.")
    let linkName = t("Credit History")
    let creditHistory = null
    if (creditHistoryLinkResultStatus === loadingStatus.LOADING_STATUS_SUCCESS) {
        creditHistory = 
        (<div style={{ paddingTop: '5px' }}>
            <a href={utils.createUrl(creditHistoryLink.url)}><h3>{linkName}</h3></a>
            <p className="text-muted">{descriptiveText}</p>
        </div>)
    }
    else if (creditHistoryLinkResultStatus === loadingStatus.LOADING_STATUS_FAILURE) {
        creditHistory =
            (<div style={{ paddingTop: '5px' }}>
                <div>
                    <h3 className="text-muted" style={{ display: 'inline' }}>{linkName}</h3>
                    <button className="displayAsText" style={{ marginLeft: '1em', verticalAlign: 'sub' }}
                        onClick={() => { refreshFailedLinks() }}
                    >
                        <Icon style={{ color: '#087ed8' }}>refresh</Icon>
                    </button>
                </div>
                <p className="text-muted">{descriptiveText}</p>
                <DocumentCenterDocError isCommercial={isCommercial} t={t}/>
            </div>)
    }

    return (
        <div>
            {creditHistory}
        </div>
    )
}

CreditHistoryDocs.propTypes = {
    isCommercial: PropTypes.bool.isRequired,

    creditHistoryLink: PropTypes.object.isRequired,
    creditHistoryLinkResultStatus: PropTypes.string.isRequired,

    refreshFailedLinks: PropTypes.func,
    t: PropTypes.func
}

export default CreditHistoryDocs