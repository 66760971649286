import {billProjectionConstants} from '../../../constants/payment/bill-projection-constants'
import {RSAA} from 'redux-api-middleware'
import config from 'my-account-config'

export const getBillProjection = (billAccount) =>{
    return dispatch => {
        return dispatch({
            [RSAA]: {
                endpoint: config.apiBaseUrl +'api/payments/billprojection?billaccount=' +
                    encodeURIComponent(billAccount),
                method: 'GET',
                credentials: 'include',
                types:[
                    billProjectionConstants.GET_PROJECTION_REQUEST,
                    billProjectionConstants.GET_PROJECTION_SUCCESS,
                    billProjectionConstants.GET_PROJECTION_FAILURE
                ]
                
            }
        })
    }
}