import React from 'react'
import PropTypes from 'prop-types'
import { PatternFormat } from 'react-number-format'
import format from '../../srp_modules/format'

const AutopaySrpAcctInfo = ({billAccount, customerName, serviceAddress, t}) => {
    return (
        <div>
            <label className="h4 text-muted mt-4">{t("SRP account information")}</label>
            <div className="row srp-row">
                <div className="col d-block d-lg-none srp-label">{t("SRP account")}</div>
                <div className="col d-block d-lg-none" style={{textAlign: "right"}}>
                    <PatternFormat
                        value={format.srpAccountNumber(billAccount)}
                        valueIsNumericString={true}
                        format="###-###-###"
                        displayType="text"
                        style={{width: '100%'}}
                    />
                </div>
                <div className="col d-lg-block d-none">
                    <span className="srp-label">{t("SRP account")}&nbsp;&nbsp;</span>
                    <span className="pull-right">
                        <PatternFormat
                            value={format.srpAccountNumber(billAccount)}
                            valueIsNumericString={true}
                            format="###-###-###"
                            displayType="text"
                            style={{width: '100%'}}
                        />
                    </span>
                </div>
            </div>
            <div className="row srp-row">
                <div className="col srp-label d-block d-lg-none">{t("Name")}</div>
                <div className="col d-block d-lg-none" style={{textAlign:"right"}}>{customerName}</div>
                <div className="col d-lg-block d-none">
                    <span className="srp-label">{t("Name")}&nbsp;&nbsp;</span>
                    <span className="pull-right">{customerName}</span>
                </div>
            </div>
            <div className="row srp-row">
                <div className="col srp-label d-block d-lg-none">{t("Service address")}</div>
                <div className="col d-block d-lg-none" style={{textAlign:"right"}}>{serviceAddress.streetAddress}<br />{serviceAddress.cityStateZip}</div>
                <div className="col d-lg-block d-none">
                    <span className="srp-label">{t("Service address")}&nbsp;&nbsp;</span>
                    <span className="pull-right">
                        {serviceAddress.streetAddress + ", " + serviceAddress.cityStateZip}
                    </span>
                </div>
            </div>
        </div>
    )
}

AutopaySrpAcctInfo.propTypes = {
    t: PropTypes.func.isRequired,
    billAccount: PropTypes.number.isRequired,
    customerName: PropTypes.string.isRequired,
    serviceAddress: PropTypes.shape({
        streetAddress: PropTypes.string.isRequired,
        cityStateZip: PropTypes.string.isRequired
    }).isRequired
}

export default AutopaySrpAcctInfo
