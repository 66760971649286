import {displaySizeConstants} from '../constants/display-size-constants'

export function getDisplaySize(windowInnerWidth){
    if (windowInnerWidth >= 1200) {
        return displaySizeConstants.EXTRA_LARGE
    }
    if (windowInnerWidth < 1200 && windowInnerWidth >= 992) {
        return displaySizeConstants.LARGE
    }
    if (windowInnerWidth < 992 && windowInnerWidth >= 768) {
        return displaySizeConstants.MEDIUM
    }
    if (windowInnerWidth < 768 && windowInnerWidth >= 576) {
        return displaySizeConstants.SMALL
    }
    if (windowInnerWidth < 576 && windowInnerWidth >= 400) {
        return displaySizeConstants.MOBILE_LARGE
    }
    if (windowInnerWidth < 400 && windowInnerWidth >= 360) {
        return displaySizeConstants.MOBILE
    }
    if(windowInnerWidth < 360){
        return displaySizeConstants.MOBILE_SMALL
    }
}