import React from "react"
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import TextField from '@mui/material/TextField'
import { NumericFormat } from 'react-number-format'

import CircularProgress from '@mui/material/CircularProgress'

import TextSignUpAlertMessage from './text-signup-alert-message'
import TextAlert from './text-alert'
import EmailAlert from './email-alert'
import CommonAlert from './common-alert'

import { toggleEbill, refreshNotification } from '../../../../actions/auth/notification/notification-actions'
import { addSnackbarMessage } from '../../../../actions/common/snackbar'
import alertsModule from '../../../../srp_modules/alerts-module'
import * as loadingStatus from '../../../../constants/loading-status-constants'

class BillingAlerts extends React.Component {
    constructor(props) {
        super(props)

        this.monthlyProjAmountInput = React.createRef()
        this.monthlyProjAmountInputMobile = React.createRef()

        this.state = {
            isSubscribedBillingAlertEmail: this.props.allAlerts.billingAlert.subscribedToEmail,
            isSubscribedBillingAlertText: this.props.allAlerts.billingAlert.subscribedToText,
            disbledBillingAlertEmailToggle: false,
            disbledBillingAlertTextToggle: false,

            isSubscribedEbillAlert: this.props.eBillState,
            disabledEbillToggle: false,

            isSubscribedBillProjAlertEmail: this.props.allAlerts.billProjectionAlert.subscribedToEmail,
            isSubscribedBillProjAlertText: this.props.allAlerts.billProjectionAlert.subscribedToText,
            disbledBillProjAlertEmailToggle: false,
            disbledBillProjAlertTextToggle: false,

            isSubscribedMonthlyProjAlertEmail: this.props.allAlerts.monthlyProjectionAlert.subscribedToEmail,
            isSubscribedMonthlyProjAlertText: this.props.allAlerts.monthlyProjectionAlert.subscribedToText,
            monthlyProjectionAmount: Number(this.props.allAlerts.monthlyProjectionAlert.notificationInfo.notificationData).toFixed(2),
            disbledMonthlyProjAlertEmailToggle: false,
            disbledMonthlyProjAlertTextToggle: false,
            monthlyProjectionError: false,

            isSubscribedLandlordAlertEmail: this.props.allAlerts.landlordAlert.subscribedToEmail,
            isSubscribedLandlordAlertText: this.props.allAlerts.landlordAlert.subscribedToText,
            disabledLandlordAlertEmailToggle: false,
            disabledLandlordAlertTextToggle: false
        }

        this.toggleBillingAlertEmail = this.toggleBillingAlertEmail.bind(this)
        this.toggleBillingAlertText = this.toggleBillingAlertText.bind(this)
        this.toggleBillProjAlertEmail = this.toggleBillProjAlertEmail.bind(this)
        this.toggleBillProjAlertText = this.toggleBillProjAlertText.bind(this)
        this.toggleMonthlyProjAlertEmail = this.toggleMonthlyProjAlertEmail.bind(this)
        this.toggleMonthlyProjAlertText = this.toggleMonthlyProjAlertText.bind(this)
        this.toggleLandlordAlertEmail = this.toggleLandlordAlertEmail.bind(this)
        this.toggleLandlordAlertText = this.toggleLandlordAlertText.bind(this)
        this.getMonthlyProjectionAmountOnBlur = this.getMonthlyProjectionAmountOnBlur.bind(this)
        this.getEbillToggle = this.getEbillToggle.bind(this)
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.refreshNotificationStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS
            && nextProps.refreshNotificationStatus === loadingStatus.LOADING_STATUS_SUCCESS) {
            this.setState({
                isSubscribedBillingAlertEmail: nextProps.allAlerts.billingAlert.subscribedToEmail,
                isSubscribedBillingAlertText: nextProps.allAlerts.billingAlert.subscribedToText,
                disbledBillingAlertEmailToggle: false,
                disbledBillingAlertTextToggle: false,

                isSubscribedBillProjAlertEmail: nextProps.allAlerts.billProjectionAlert.subscribedToEmail,
                isSubscribedBillProjAlertText: nextProps.allAlerts.billProjectionAlert.subscribedToText,
                disbledBillProjAlertEmailToggle: false,
                disbledBillProjAlertTextToggle: false,

                isSubscribedMonthlyProjAlertEmail: nextProps.allAlerts.monthlyProjectionAlert.subscribedToEmail,
                isSubscribedMonthlyProjAlertText: nextProps.allAlerts.monthlyProjectionAlert.subscribedToText,
                monthlyProjectionAmount: Number(nextProps.allAlerts.monthlyProjectionAlert.notificationInfo.notificationData).toFixed(2),
                disbledMonthlyProjAlertEmailToggle: false,
                disbledMonthlyProjAlertTextToggle: false,
                monthlyProjectionError: false,

                isSubscribedLandlordAlertEmail: nextProps.allAlerts.landlordAlert.subscribedToEmail,
                isSubscribedLandlordAlertText: nextProps.allAlerts.landlordAlert.subscribedToText,
                disabledLandlordAlertEmailToggle: false,
                disabledLandlordAlertTextToggle: false
            })
        }
    }

    toggleBillingAlertEmail(isSubscribed, notification) {
        this.setState({
            isSubscribedBillingAlertEmail: !isSubscribed
        })

        if (this.props.allAlerts.billingAlert.subscribedToEmail && isSubscribed) {
            this.props.deleteAllNotificationsOnClick(this.props.selectedBillAccount, notification.notificationInfo, notification.notificationInfo.notificationData, notification.email)
            this.setState({
                disbledBillingAlertEmailToggle: true
            })
        }
    }

    toggleBillingAlertText(isSubscribed, notification) {
        this.setState({
            isSubscribedBillingAlertText: !isSubscribed
        })

        if (this.props.allAlerts.billingAlert.subscribedToText && isSubscribed) {
            this.props.deleteAllNotificationsOnClick(this.props.selectedBillAccount, notification.notificationInfo, notification.notificationInfo.notificationData, notification.phone)
            this.setState({
                disbledBillingAlertTextToggle: true
            })
        }
    }

    toggleBillProjAlertEmail(isSubscribed, notification) {
        this.setState({
            isSubscribedBillProjAlertEmail: !isSubscribed
        })

        if (this.props.allAlerts.billProjectionAlert.subscribedToEmail && isSubscribed) {
            this.props.deleteAllNotificationsOnClick(this.props.selectedBillAccount, notification.notificationInfo, notification.notificationInfo.notificationData, notification.email)
            this.setState({
                disbledBillProjAlertEmailToggle: true
            })
        }
    }

    toggleBillProjAlertText(isSubscribed, notification) {
        this.setState({
            isSubscribedBillProjAlertText: !isSubscribed
        })

        if (this.props.allAlerts.billProjectionAlert.subscribedToText && isSubscribed) {
            this.props.deleteAllNotificationsOnClick(this.props.selectedBillAccount, notification.notificationInfo, notification.notificationInfo.notificationData, notification.phone)
            this.setState({
                disbledBillProjAlertTextToggle: true
            })
        }
    }

    toggleMonthlyProjAlertEmail(isSubscribed, notification) {
        this.setState({
            isSubscribedMonthlyProjAlertEmail: !isSubscribed
        })

        if (this.props.allAlerts.monthlyProjectionAlert.subscribedToEmail && isSubscribed) {
            this.props.deleteAllNotificationsOnClick(this.props.selectedBillAccount, notification.notificationInfo, notification.notificationInfo.notificationData, notification.email)
            this.setState({
                disbledMonthlyProjAlertEmailToggle: true
            })
        }
    }

    toggleMonthlyProjAlertText(isSubscribed, notification) {
        this.setState({
            isSubscribedMonthlyProjAlertText: !isSubscribed
        })

        if (this.props.allAlerts.monthlyProjectionAlert.subscribedToText && isSubscribed) {
            this.props.deleteAllNotificationsOnClick(this.props.selectedBillAccount, notification.notificationInfo, notification.notificationInfo.notificationData, notification.phone)
            this.setState({
                disbledMonthlyProjAlertTextToggle: true
            })
        }
    }

    toggleLandlordAlertEmail(isSubscribed, notification) {
        this.setState({
            isSubscribedLandlordAlertEmail: !isSubscribed
        })

        if (this.props.allAlerts.landlordAlert.subscribedToEmail && isSubscribed) {
            this.props.deleteAllNotificationsOnClick(this.props.selectedBillAccount, notification.notificationInfo, notification.notificationInfo.notificationData, notification.email)
            this.setState({
                disabledLandlordAlertEmailToggle: true
            })
        }
    }

    toggleLandlordAlertText(isSubscribed, notification) {
        this.setState({
            isSubscribedLandlordAlertText: !isSubscribed
        })

        if (this.props.allAlerts.landlordAlert.subscribedToText && isSubscribed) {
            this.props.deleteAllNotificationsOnClick(this.props.selectedBillAccount, notification.notificationInfo, notification.notificationInfo.notificationData, notification.phone)
            this.setState({
                disabledLandlordAlertTextToggle: true
            })
        }
    }

    getMonthlyProjectionAmountOnBlur(amount) {
        let formattedAmount = amount === "" ? "0.00" : alertsModule.formatDollarAmount(Number(amount))
        this.setState({
            monthlyProjectionAmount: formattedAmount
        })

        if(Number(amount) < -9999999.99 ||  Number(amount) > 99999999.99){
            this.setState({
                monthlyProjectionError: true
            })
            return
        }
        else {
            this.setState({
                monthlyProjectionError: false
            })
        }

        if (!this.state.isSubscribedMonthlyProjAlertEmail && !this.state.isSubscribedMonthlyProjAlertText)
            return

        this.props.updateNotificationOnBlur(this.props.selectedBillAccount, this.props.allAlerts.monthlyProjectionAlert.notificationInfo, formattedAmount)
    }

    async getEbillToggle(isSubscribing, enrollingText) {
        let preferPhone = enrollingText

        this.setState({
            disabledEbillToggle: true
        })

        let success = await this.props.updateEbillEnrollment(isSubscribing, preferPhone, this.props.selectedBillAccount)

        this.setState({
            disabledEbillToggle: false
        })

        if (success) {
            this.setState({
                isSubscribedEbillAlert: isSubscribing,
            })
        }
    }

    render() {
        let billingAlertDisplayTitle = this.props.t(this.props.allAlerts.billingAlert.notificationInfo.displayTitle)
        let disconnectAlertDisplayTitle = this.props.t(this.props.allAlerts.disconnectAlert.notificationInfo.displayTitle)
        let billProjectionAlertDisplayTitle = this.props.t(this.props.allAlerts.billProjectionAlert.notificationInfo.displayTitle)
        let monthlyProjectionAlertDisplayTitle = this.props.t(this.props.allAlerts.monthlyProjectionAlert.notificationInfo.displayTitle)
        let landlordAlertDisplayTitle = this.props.t(this.props.allAlerts.landlordAlert.notificationInfo.displayTitle)

        return (
            <div className="srp-card-details">
                <TextSignUpAlertMessage t={this.props.t}/>

                <div className="d-lg-block d-none">
                    <div className="row mb-3">
                        <div className="col-lg-8" />
                        <div className="col-lg-2 text-muted">{this.props.t("Email")}</div>
                        <div className="col-lg-2 text-muted">{this.props.t("Text")}</div>
                    </div>
                </div>
                <div style={this.props.allAlerts.billingAlert.isAvailable ? { display: 'block' } : { display: 'none' }}>
                    <div className="row d-flex align-items-center">
                        <div className="col-lg-8 col-12">
                            <h4 className="d-lg-block d-none" style={{ color: 'rgb(103, 135, 183)' }}>{billingAlertDisplayTitle}</h4>
                            <h5 className="d-lg-none d-block" style={{ color: 'rgb(103, 135, 183)' }}>{billingAlertDisplayTitle}</h5>
                            <div className="d-lg-none d-block">
                                <p className="text-muted">
                                {this.props.t("Tell me when my bill is available and when my payment is received. Also receive alerts if payment is not received three days prior to the due date and again if not received by the due date.")}
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-2 col-4">
                            <div className="d-lg-none d-block text-muted">{this.props.t("Email")}</div>
                            <label className="switch align-middle mb-0">
                                <input
                                    type="checkbox"
                                    checked={this.state.isSubscribedBillingAlertEmail}
                                    disabled={this.state.disbledBillingAlertEmailToggle ? this.props.t("disabled") : ""}
                                    onChange={() => this.toggleBillingAlertEmail(this.state.isSubscribedBillingAlertEmail, this.props.allAlerts.billingAlert)}
                                />
                                <span className="slider" />
                            </label>
                        </div>
                        <div className="col-lg-2 col-4">
                            <div className="d-lg-none d-block text-muted">{this.props.t("Text")}</div>
                            <label className="switch align-middle mb-0">
                                <input
                                    type="checkbox"
                                    checked={this.state.isSubscribedBillingAlertText}
                                    disabled={this.state.disbledBillingAlertTextToggle ? this.props.t("disabled") : ""}
                                    onChange={() => this.toggleBillingAlertText(this.state.isSubscribedBillingAlertText, this.props.allAlerts.billingAlert)}
                                />
                                <span className="slider" />
                            </label>
                        </div>
                    </div>
                    <span className="d-lg-none d-block mb-3" />
                    <div className="d-lg-block d-none">
                        <div className="row">
                            <div className="col-lg-8">
                                <p className="text-muted mb-3">
                                {this.props.t("Tell me when my bill is available and when my payment is received. Also receive alerts if payment is not received three days prior to the due date and again if not received by the due date.")}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="mb-3" style={this.state.isSubscribedBillingAlertEmail ? { display: 'block' } : { display: 'none' }}>
                        <EmailAlert
                            alert={this.props.allAlerts.billingAlert}
                            notificationData={this.props.allAlerts.billingAlert.notificationInfo.notificationData}
                            emailContacts={this.props.emailContacts}
                            addNotification={this.props.addNotificationOnClick}
                            deleteNotification={this.props.deleteNotificationOnClick} 
                            t={this.props.t}/>
                    </div>

                    <div className="mb-3" style={this.state.isSubscribedBillingAlertText ? { display: 'block' } : { display: 'none' }}>
                        <TextAlert
                            alert={this.props.allAlerts.billingAlert}
                            notificationData={this.props.allAlerts.billingAlert.notificationInfo.notificationData}
                            phoneContacts={this.props.phoneContacts}
                            addNotification={this.props.addNotificationOnClick}
                            deleteNotification={this.props.deleteNotificationOnClick} 
                            t={this.props.t}/>
                    </div>
                    {!this.props.isSummaryMaster
                        ? <div style={this.state.isSubscribedBillingAlertEmail || this.state.isSubscribedBillingAlertText ? { display: 'block' } : { display: 'none' }}>
                            <div className="d-lg-block d-none pl-3 mt-4">
                                <div className="col-lg-8 col-12 mb-3" style={this.state.isSubscribedEbillAlert
                                        ? { border: '1px solid rgb(204, 219, 231)', borderRadius: '4px' }
                                        : { border: '2px solid rgb(204, 138, 0)', borderRadius: '4px' }
                                    }>
                                    <div className="d-flex align-items-center justify-content-start mt-3 mb-2">
                                        <div className="text-muted font-weight-bold align-middle mr-3">{this.props.t("Paperless billing")}</div>
                                        <label className="switch align-middle mb-0">
                                            {this.state.disabledEbillToggle ?
                                            <CircularProgress size={20} thickness={5} />
                                            :
                                            <div>
                                            <input
                                                type="checkbox"
                                                checked={this.state.isSubscribedEbillAlert}
                                                onChange={() => this.getEbillToggle(!this.state.isSubscribedEbillAlert, this.state.isSubscribedBillingAlertText)} />
                                            <span className="slider" />
                                            </div>
                                            }
                                        </label>
                                    </div>
                                    <p className="mb-3" style={{ color: 'rgba(112, 112, 112, 0.537)' }}>
                                    {this.props.t("I wish to receive my SRP electric bill electronically. I may choose to resume paper bills at any time. By going paperless, I will receive Billing and Payment eNotes.")}
                                </p>
                                </div>
                            </div>

                            <div className="d-lg-none d-block mt-4">
                                <div className="col-lg-8 col-12 mb-3" style={this.state.isSubscribedEbillAlert
                                        ? { border: '1px solid rgb(204, 219, 231)', borderRadius: '4px' }
                                        : { border: '2px solid rgb(204, 138, 0)', borderRadius: '4px' }
                                    }>
                                    <div className="d-flex align-items-center justify-content-start mt-3 mb-2">
                                        <div className="text-muted font-weight-bold align-middle mr-3">{this.props.t("Paperless billing")}</div>
                                        <label className="switch align-middle mb-0">
                                            {this.state.disabledEbillToggle ?
                                            <CircularProgress size={20} thickness={5} />
                                            :
                                            <div>
                                            <input
                                                type="checkbox"
                                                checked={this.state.isSubscribedEbillAlert}
                                                onChange={() => this.getEbillToggle(!this.state.isSubscribedEbillAlert, this.state.isSubscribedBillingAlertText)} />
                                            <span className="slider" />
                                            </div>
                                            }
                                        </label>
                                    </div>
                                    <p className="mb-3" style={{ color: 'rgba(112, 112, 112, 0.537)' }}>
                                    {this.props.t("I wish to receive my SRP electric bill electronically. I may choose to resume paper bills at any time. By going paperless, I will receive Billing and Payment eNotes.")}
                                </p>
                                </div>
                            </div>
                        </div>
                        : null
                    }
                </div>

                {this.props.allAlerts.disconnectAlert.isAvailable
                ? <CommonAlert
                    alert={this.props.allAlerts.disconnectAlert}
                    alertTitle={disconnectAlertDisplayTitle}
                    alertDescription={this.props.t("Notify me when my account is eligible for disconnection because of a past due balance.")}
                    phoneContacts={this.props.phoneContacts}
                    emailContacts={this.props.emailContacts}
                    addNotification={this.props.addNotificationOnClick}
                    deleteNotification={this.props.deleteNotificationOnClick}
                    deleteAllNotificationsOnClick={this.props.deleteAllNotificationsOnClick}
                    allowEmailSubscription={true}
                    allowTextSubscription={true}
                    t={this.props.t}
                />
                : null}

                <div style={this.props.allAlerts.billProjectionAlert.isAvailable ? { display: 'block' } : { display: 'none' }}>
                    <div className="row d-flex align-items-center">
                        <div className="col-lg-8 col-12">
                            <h4 className="d-lg-block d-none" style={{ color: 'rgb(103, 135, 183)' }}>{billProjectionAlertDisplayTitle}</h4>
                            <h5 className="d-lg-none d-block" style={{ color: 'rgb(103, 135, 183)' }}>{billProjectionAlertDisplayTitle}</h5>
                            <div className="d-lg-none d-block">
                                <p className="text-muted">{this.props.allAlerts.billProjectionAlert.notificationInfo.description}</p>
                            </div>
                        </div>
                        <div className="col-lg-2 col-4">
                            <div className="d-lg-none d-block text-muted">{this.props.t("Email")}</div>
                            <label className="switch align-middle mb-0">
                                <input
                                    type="checkbox"
                                    checked={this.state.isSubscribedBillProjAlertEmail}
                                    disabled={this.state.disbledBillProjAlertEmailToggle ? "disabled" : ""}
                                    onChange={() => this.toggleBillProjAlertEmail(this.state.isSubscribedBillProjAlertEmail, this.props.allAlerts.billProjectionAlert)}
                                />
                                <span className="slider" />
                            </label>
                        </div>
                        <div className="col-lg-2 col-4">
                            <div className="d-lg-none d-block text-muted">{this.props.t("Text")}</div>
                            <label className="switch align-middle mb-0">
                                <input
                                    type="checkbox"
                                    checked={this.state.isSubscribedBillProjAlertText}
                                    disabled={this.state.disbledBillProjAlertTextToggle ? "disabled" : ""}
                                    onChange={() => this.toggleBillProjAlertText(this.state.isSubscribedBillProjAlertText, this.props.allAlerts.billProjectionAlert)}
                                />
                                <span className="slider" />
                            </label>
                        </div>
                    </div>
                    <span className="d-lg-none d-block mb-3" />
                    <div className="d-lg-block d-none">
                        <div className="row">
                            <div className="col-lg-8">
                                <p className="text-muted mb-3">{this.props.allAlerts.billProjectionAlert.notificationInfo.description}</p>
                            </div>
                        </div>
                    </div>
                    <div className="mb-3" style={this.state.isSubscribedBillProjAlertEmail ? { display: 'block' } : { display: 'none' }}>
                        <EmailAlert
                            alert={this.props.allAlerts.billProjectionAlert}
                            notificationData={this.props.allAlerts.billProjectionAlert.notificationInfo.notificationData}
                            emailContacts={this.props.emailContacts}
                            addNotification={this.props.addNotificationOnClick}
                            deleteNotification={this.props.deleteNotificationOnClick} 
                            t={this.props.t}/>
                    </div>

                    <div className="mb-4" style={this.state.isSubscribedBillProjAlertText ? { display: 'block' } : { display: 'none' }}>
                        <TextAlert
                            alert={this.props.allAlerts.billProjectionAlert}
                            notificationData={this.props.allAlerts.billProjectionAlert.notificationInfo.notificationData}
                            phoneContacts={this.props.phoneContacts}
                            addNotification={this.props.addNotificationOnClick}
                            deleteNotification={this.props.deleteNotificationOnClick} 
                            t={this.props.t}/>
                    </div>
                </div>

                <div style={this.props.allAlerts.monthlyProjectionAlert.isAvailable ? { display: 'block' } : { display: 'none' }}>
                    <div className="row d-flex align-items-center">
                        <div className="col-lg-8 col-12">
                            <h4 className="d-lg-block d-none" style={{ color: 'rgb(103, 135, 183)' }}>{monthlyProjectionAlertDisplayTitle}</h4>
                            <h5 className="d-lg-none d-block" style={{ color: 'rgb(103, 135, 183)' }}>{monthlyProjectionAlertDisplayTitle}</h5>
                            <div className="d-lg-none d-block mb-3">
                                <div className="text-muted my-2">{this.props.allAlerts.monthlyProjectionAlert.notificationInfo.description}</div>
                                <NumericFormat
                                    getInputRef={this.monthlyProjAmountInput}
                                    value={this.state.monthlyProjectionAmount}
                                    displayType="input"
                                    style={{ width: '120px' }}
                                    onValueChange={(values) => {
                                        this.setState({...this.state, monthlyProjectionAmount: values.value})
                                    }}
                                    onBlur={() => this.getMonthlyProjectionAmountOnBlur(this.state.monthlyProjectionAmount)}
                                    valueIsNumericString={true}
                                    thousandSeparator={true}
                                    prefix={"$"}
                                    allowNegative={true}
                                    customInput={TextField}
                                    type="text"
                                    error={this.state.monthlyProjectionError}
                                    helperText={this.state.monthlyProjectionError ? this.props.t("Invalid amount") : ''}
                                    disabled={(this.props.allAlerts.monthlyProjectionAlert.email).length === 0
                                           && (this.props.allAlerts.monthlyProjectionAlert.phone).length === 0}
                                />
                            </div>
                        </div>
                        <div className="col-lg-2 col-4">
                            <div className="d-lg-none d-block text-muted">{this.props.t("Email")}</div>
                            <label className="switch align-middle mb-0">
                                <input
                                    type="checkbox"
                                    checked={this.state.isSubscribedMonthlyProjAlertEmail}
                                    disabled={this.state.disbledMonthlyProjAlertEmailToggle ? "disabled" : ""}
                                    onChange={() => this.toggleMonthlyProjAlertEmail(this.state.isSubscribedMonthlyProjAlertEmail, this.props.allAlerts.monthlyProjectionAlert)}
                                />
                                <span className="slider" />
                            </label>
                        </div>
                        <div className="col-lg-2 col-4">
                            <div className="d-lg-none d-block text-muted">{this.props.t("Text")}</div>
                            <label className="switch align-middle mb-0">
                                <input
                                    type="checkbox"
                                    checked={this.state.isSubscribedMonthlyProjAlertText}
                                    disabled={this.state.disbledMonthlyProjAlertTextToggle ? this.props.t("disabled") : ""}
                                    onChange={() => this.toggleMonthlyProjAlertText(this.state.isSubscribedMonthlyProjAlertText, this.props.allAlerts.monthlyProjectionAlert)}
                                />
                                <span className="slider" />
                            </label>
                        </div>
                    </div>
                    <span className="d-lg-none d-block mb-3" />
                    <div className="d-lg-block d-none">
                        <div className="row">
                            <div className="col-8 d-flex justify-content-start align-items-center mb-3">
                                <div className="text-muted mr-2">{this.props.allAlerts.monthlyProjectionAlert.notificationInfo.description}</div>
                                <div>
                                    <NumericFormat
                                        getInputRef={this.monthlyProjAmountInputMobile}
                                        value={this.state.monthlyProjectionAmount}
                                        displayType="input"
                                        style={{ width: '120px' }}
                                        onValueChange={(values) => {
                                            this.setState({...this.state, monthlyProjectionAmount: values.value})
                                        }}
                                        onBlur={() => this.getMonthlyProjectionAmountOnBlur(this.state.monthlyProjectionAmount)}
                                        valueIsNumericString={true}
                                        thousandSeparator={true}
                                        prefix={"$"}
                                        allowNegative={true}
                                        customInput={TextField}
                                        type="text"
                                        error={this.state.monthlyProjectionError}
                                        helperText={this.state.monthlyProjectionError ? "Invalid amount" : ''}
                                        disabled={(this.props.allAlerts.monthlyProjectionAlert.email).length === 0
                                               && (this.props.allAlerts.monthlyProjectionAlert.phone).length === 0}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mb-3" style={this.state.isSubscribedMonthlyProjAlertEmail ? { display: 'block' } : { display: 'none' }}>
                        <EmailAlert
                            alert={this.props.allAlerts.monthlyProjectionAlert}
                            notificationData={this.props.allAlerts.monthlyProjectionAlert.notificationInfo.notificationData}
                            emailContacts={this.props.emailContacts}
                            addNotification={this.props.addNotificationOnClick}
                            deleteNotification={this.props.deleteNotificationOnClick} 
                            t={this.props.t}/>
                    </div>

                    <div style={this.state.isSubscribedMonthlyProjAlertText ? { display: 'block' } : { display: 'none' }}>
                        <TextAlert
                            alert={this.props.allAlerts.monthlyProjectionAlert}
                            notificationData={this.props.allAlerts.monthlyProjectionAlert.notificationInfo.notificationData}
                            phoneContacts={this.props.phoneContacts}
                            addNotification={this.props.addNotificationOnClick}
                            deleteNotification={this.props.deleteNotificationOnClick} 
                            t={this.props.t}/>
                    </div>
                </div>

                <div style={this.props.allAlerts.landlordAlert.isAvailable ? { display: 'block' } : { display: 'none' }}>
                    <div className="row d-flex align-items-center">
                        <div className="col-lg-8 col-12">
                            <h4 className="d-lg-block d-none" style={{ color: 'rgb(103, 135, 183)' }}>{landlordAlertDisplayTitle}</h4>
                            <h5 className="d-lg-none d-block" style={{ color: 'rgb(103, 135, 183)' }}>{landlordAlertDisplayTitle}</h5>
                            <div className="d-lg-none d-block">
                                <p className="text-muted">
                                    {this.props.t("Notify me when a location is turned on or off in the Landlord's name.")}
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-2 col-4">
                            <div className="d-lg-none d-block text-muted">{this.props.t("Email")}</div>
                            <label className="switch align-middle mb-0">
                                <input
                                    type="checkbox"
                                    checked={this.state.isSubscribedLandlordAlertEmail}
                                    disabled={this.state.disabledLandlordAlertEmailToggle ? "disabled" : ""}
                                    onChange={() => this.toggleLandlordAlertEmail(this.state.isSubscribedLandlordAlertEmail, this.props.allAlerts.landlordAlert)}
                                />
                                <span className="slider" />
                            </label>
                        </div>
                        <div className="col-lg-2 col-4">
                            <div className="d-lg-none d-block text-muted">{this.props.t("Text")}</div>
                            <label className="switch align-middle mb-0">
                                <input
                                    type="checkbox"
                                    checked={this.state.isSubscribedLandlordAlertText}
                                    disabled={this.state.disabledLandlordAlertTextToggle ? this.props.t("disabled") : ""}
                                    onChange={() => this.toggleLandlordAlertText(this.state.isSubscribedLandlordAlertText, this.props.allAlerts.landlordAlert)}
                                />
                                <span className="slider" />
                            </label>
                        </div>
                    </div>
                    <span className="d-lg-none d-block mb-3" />
                    <div className="d-lg-block d-none">
                        <div className="row">
                            <div className="col-lg-8">
                                <p className="text-muted mb-3">
                                {this.props.t("Notify me when a location is turned on or off in the Landlord's name.")}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="mb-3" style={this.state.isSubscribedLandlordAlertEmail ? { display: 'block' } : { display: 'none' }}>
                        <EmailAlert
                            alert={this.props.allAlerts.landlordAlert}
                            notificationData={this.props.allAlerts.landlordAlert.notificationInfo.notificationData}
                            emailContacts={this.props.emailContacts}
                            addNotification={this.props.addNotificationOnClick}
                            deleteNotification={this.props.deleteNotificationOnClick} 
                            t={this.props.t}/>
                    </div>

                    <div className="mb-4" style={this.state.isSubscribedLandlordAlertText ? { display: 'block' } : { display: 'none' }}>
                        <TextAlert
                            alert={this.props.allAlerts.landlordAlert}
                            notificationData={this.props.allAlerts.landlordAlert.notificationInfo.notificationData}
                            phoneContacts={this.props.phoneContacts}
                            addNotification={this.props.addNotificationOnClick}
                            deleteNotification={this.props.deleteNotificationOnClick} 
                            t={this.props.t}/>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return { ...state.accountInfo.billAccount, ...state.accountInfo.notification }
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateEbillEnrollment: async (isEnrolling, preferPhone, billAccount) => {
            let response = await dispatch(toggleEbill(isEnrolling, preferPhone))
            const success = !response.error && response.payload
            if (success) {
                dispatch(refreshNotification(billAccount))
            } else {
                dispatch(addSnackbarMessage(<span>Unable to {isEnrolling ? "enroll in" : "unenroll from"} paperless billing. Please try again later.</span>))
            }
            return success
        }
    }
}

BillingAlerts.propTypes = {
    selectedBillAccount: PropTypes.number,
    allAlerts: PropTypes.object,
    phoneContacts: PropTypes.arrayOf(PropTypes.object),
    emailContacts: PropTypes.arrayOf(PropTypes.object),

    addNotificationOnClick: PropTypes.func,
    updateNotificationOnBlur: PropTypes.func,
    deleteNotificationOnClick: PropTypes.func,
    deleteAllNotificationsOnClick: PropTypes.func,

    eBillState: PropTypes.bool,
    eBillStateStatus: PropTypes.string,
    updateEbillEnrollment: PropTypes.func,

    refreshNotificationStatus: PropTypes.string,

    isSummaryMaster: PropTypes.bool,

    t: PropTypes.func
}

export default connect(mapStateToProps, mapDispatchToProps)(BillingAlerts)