import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { withTranslation } from 'react-i18next'

import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import TextField from '@mui/material/TextField'
import { NumericFormat } from 'react-number-format'

class EconomyPricePlanEligibilityPure extends React.Component {
    constructor(props) {
        super(props)

        let thresholdsCopy = [...props.eppIncomeThresholds]
        let sortedThresholds = thresholdsCopy.sort((a, b) => { return a.numberInHousehold.toString().localeCompare(b.numberInHousehold, 'en', { numeric: true, sensitivity: 'accent' }) })

        this.state = {
            sortedThresholds: sortedThresholds
        }

        this.onCheckEligibilityClicked = this.onCheckEligibilityClicked.bind(this)
    }

    onCheckEligibilityClicked(event) {
        if (!this.props.formikValues.householdIncome) {
            event.preventDefault()
            return
        }
        let comparable = parseInt(this.props.formikValues.householdIncome.replace(/,/g, ''))
        if (comparable < 1) {
            event.preventDefault()
            return
        }

        let threshold = this.state.sortedThresholds.find(element => element.numberInHousehold === this.props.formikValues.peopleInHousehold)

        let isEligible = threshold.eligibleIncome >= comparable

        this.props.setFieldValue('eligibilityChecked', true)
        this.props.setFieldValue('passesEligibilityCheck', isEligible)
    }

    render() {
        let applyIneligible = null
        if (this.props.canApply === false) {
            applyIneligible = (
                <React.Fragment>
                    <div className="srp-alert-error mb-3">
                        <p>
                            <strong>{this.props.t("NOT_ELIGIBLE")} </strong> {this.props.t("NOT_ELIGIBLE_Explanation")}
                        </p>
                        <Link to="/myaccount/dashboard" className="display-as-link ml-2 mb-2">{this.props.t("Go_to_dashboard")}</Link>
                    </div>
                </React.Fragment>
            )
        }

        let checkEligibleButton = null

        if (this.props.hideEligibilityButton === false) {
            checkEligibleButton =
                (<div className="d-flex flex-wrap justify-content-end">
                    <Link
                        className="btn srp-btn btn-lightblue"
                        type="button"
                        to="/myaccount/programs">
                        {this.props.t("EPP_Go_Back")}
                    </Link>
                    <button
                        className="btn srp-btn btn-green ml-2"
                        disabled={
                            this.props.canApply === false
                        }
                        onClick={(event) => this.onCheckEligibilityClicked(event)}>
                        {this.props.t("Check_eligibility")}
                    </button>
                </div>)
        }

        let householdSelection = null

        if (this.state.sortedThresholds) {

            householdSelection = (
                <Select
                    id="peopleInHousehold"
                    name="peopleInHousehold"
                    value={this.props.formikValues.peopleInHousehold}
                    onChange={this.props.handleChange}
                    disabled={this.props.hasCheckedEligibility === true || this.props.canApply === false}
                >
                    {this.state.sortedThresholds.map((person, index) =>
                        <MenuItem value={person.numberInHousehold} key={index}>{person.numberInHousehold} </MenuItem>
                    )}
                </Select>
            )
        }

        return (
            <React.Fragment>
                <div className="srp-card-details">
                    <div>
                        {applyIneligible}
                    </div>
                    <div>
                        <p>
                            {this.props.t("Initial_Enrollment_Statement")}
                        </p>

                        <div className="pb-3">
                            <div>{this.props.t("Number_In_Household")}</div>
                            <div>{householdSelection}</div>
                        </div>

                        <div className="">
                            {this.props.t("Monthly_Income_Preface")} {this.props.t("Monthly_Income_Conclusion")}
                            <br />
                            <NumericFormat
                                id="householdIncome"
                                name="householdIncome"
                                value={this.props.formikValues.householdIncome}
                                valueIsNumericString={true}
                                onChange={this.props.handleChange}
                                decimalScale={0}
                                thousandSeparator={true}
                                fixedDecimalScale={true}
                                allowNegative={false}
                                customInput={TextField}
                                type="text"
                                disabled={this.props.hasCheckedEligibility === true || this.props.canApply === false}
                                InputProps={{ startAdornment: <span className="text-muted mr-1">$</span> }}
                            />
                        </div>
                    </div>
                    <div className="mt-3">
                        {checkEligibleButton}
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

EconomyPricePlanEligibilityPure.propTypes = {
    formikValues: PropTypes.object.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    handleChange: PropTypes.func,
    canApply: PropTypes.bool,
    hasCheckedEligibility: PropTypes.bool,
    hideEligibilityButton: PropTypes.bool.isRequired,
    eppIncomeThresholds: PropTypes.array.isRequired,

    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired
}

const EconomyPricePlanEligibility = withTranslation("economyPricePlanSignupForm")(EconomyPricePlanEligibilityPure)
export { EconomyPricePlanEligibility as default, EconomyPricePlanEligibilityPure }