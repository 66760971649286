import { DateTime } from 'luxon'
import { RSAA } from 'redux-api-middleware'
import { billPageConstants } from '../../../constants/bill-page-constants'
import { eBillConstants } from '../../../constants/ebill-constants'

import config from 'my-account-config'

function getDocumentPDF(document) {
    let date = DateTime.fromISO(document.documentDate).toFormat('MMMMyyyy')
    if(document.documentType === eBillConstants.DOCUMENT_TYPE_BILL) {
        return `${config.apiBaseUrl}api/documents/eBillDocument.pdf?billAccount=${document.billingID}&documentId=${encodeURIComponent(document.documentID)}&date=${date}`
    }
    else {
        return `${config.apiBaseUrl}api/documents/eBillReminder.pdf?billAccount=${document.billingID}&documentId=${encodeURIComponent(document.documentID)}&date=${date}`
    }
}

export const getSelectedBill = (billId, billDoc) => async (dispatch) => {
    let billName = `SRPbill${DateTime.fromISO(billDoc.documentDate).toFormat('MMMMyyyy')}.pdf`

    let baseUrl = getDocumentPDF(billDoc)
    let documentUrl = `${baseUrl}&inline=true`
    let downloadUrl = `${baseUrl}&inline=false`

    let apiEndpoint = `${config.apiBaseUrl}api/login/profile`

    let response = await dispatch({
        [RSAA]: {
            endpoint: apiEndpoint,
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include',
            types: [
                billPageConstants.GET_SELECTED_BILL_REQUEST,
                {
                    type: billPageConstants.GET_SELECTED_BILL_SUCCESS,
                    meta: { billId, billName },
                    payload: () => {
                        return {
                            documentPdf: documentUrl,
                            downloadPdf: downloadUrl,
                            viewBillBlobUrl: documentUrl
                        }
                    }
                },
                billPageConstants.GET_SELECTED_BILL_FAILURE
            ]
        }
    })

    return response
}

export const getViewBillTab = (evt, tab) => ({
    type: billPageConstants.GET_VIEW_BILL_TAB,
    tab
})