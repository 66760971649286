import React from 'react'
import PropTypes from 'prop-types'
import { formConstants } from '../../../constants/form-constants'
import { withTranslation } from 'react-i18next'

import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'

const SafetyStatement = ({ 
    hasAgreedToSafety, agreeToSafety, validateSafety, agreementError, selectedPlan, t }) => (
    <div>
        <div className="section-header">{t("Safety Statement")}</div>
        <div className="mt-2 mb-3">
            {t("safety_statement")}
        </div>
        {selectedPlan === formConstants.MPOWER
            ? <div className="mb-3">{t("mpower_purchase_acknowledgement")}</div>
            : null}
        <div className="row mx-2">
            <FormControlLabel
                control={
                    <Checkbox
                        checked={hasAgreedToSafety}
                        onChange={e => {
                            agreeToSafety(e.target.checked)
                            validateSafety(e.target.checked)
                        }}
                        onBlur={e => {
                            agreeToSafety(e.target.checked)
                            validateSafety(e.target.checked)
                        }}
                        color="primary"
                    />
                }
                label={
                    <span className={agreementError ? "srp-red-text" : ""}>
                        {t("agree_safety_statement")} {agreementError ? `*${t("required")}` : ""}
                    </span>
                }
            />
        </div>
    </div>
)

SafetyStatement.propTypes = {
    hasAgreedToSafety: PropTypes.bool.isRequired,
    agreementError: PropTypes.bool.isRequired,
    agreeToSafety: PropTypes.func.isRequired,
    validateSafety: PropTypes.func.isRequired,
    selectedPlan: PropTypes.string.isRequired,

    t: PropTypes.func
}

export default withTranslation('startService')(SafetyStatement)