import React from 'react'
import PropTypes from 'prop-types'

import Icon from '@mui/material/Icon'
import IconButton from '@mui/material/IconButton'

import SrpTooltip from '../../../common_tooltip/srp-tooltip' 

import format from '../../../../srp_modules/format'

const PaymentListDesktop = ({ paymentList, paymentListErrors, removePayment, verifyingPaymentList, t }) => (
    <div>
        <label className="h4 text-muted">{t("Payment list")}</label>
        <table className="table mb-0">
            <thead>
                <tr>
                    <th className="border-0 srp-fit text-muted" style={{ fontWeight: 'normal' }}>{t("Account number")}</th>
                    <th className="border-0 srp-fit" />
                    <th className="border-0 text-muted text-right" style={{ fontWeight: 'normal' }}>{t("Payment amount")}</th>
                    <th className="border-0 srp-fit" />
                    <th className="border-0 srp-fit" style={{ fontWeight: 'normal' }} />
                </tr>
            </thead>
            <tbody>
                {paymentList.map((p, paymentIndex) => (
                    <tr key={paymentIndex}>
                        <td className="py-0 border-top-0 border-bottom align-middle">{format.formatBillAccountNumber(p.srpAccountNumber)}</td>
                        <td className="p-0 border-top-0 border-bottom align-middle">
                            {paymentListErrors && paymentIndex < paymentListErrors.length && paymentListErrors[paymentIndex].srpAccountNumber &&
                                <SrpTooltip content={
                                    <span>
                                    {
                                    typeof paymentListErrors[paymentIndex].srpAccountNumber.getLocalizedErrMsg === 'function'
                                        ? paymentListErrors[paymentIndex].srpAccountNumber.getLocalizedErrMsg()
                                        : (paymentListErrors[paymentIndex].srpAccountNumber !== "" ? t(paymentListErrors[paymentIndex].srpAccountNumber) : "")
                                    }
                                    </span>
                                }>
                                    <Icon className="align-middle">
                                        <i className="material-icons srp-icon-red-color">error_outline</i>
                                    </Icon>
                                </SrpTooltip>
                            }
                        </td>
                        <td className="text-right py-0 border-top-0 border-bottom align-middle">{format.formatDollarAmount(p.paymentAmount)}</td>
                        <td className="p-0 border-top-0 border-bottom align-middle">
                            {paymentListErrors && paymentIndex < paymentListErrors.length && paymentListErrors[paymentIndex].paymentAmount &&
                                <SrpTooltip content={
                                    <span>
                                    {
                                    typeof paymentListErrors[paymentIndex].paymentAmount.getLocalizedErrMsg === 'function'
                                        ? paymentListErrors[paymentIndex].paymentAmount.getLocalizedErrMsg()
                                        : (paymentListErrors[paymentIndex].paymentAmount !== "" ? t(paymentListErrors[paymentIndex].paymentAmount) : "")
                                    }
                                    </span>
                                }>
                                    <Icon className="align-middle">
                                        <i className="material-icons srp-icon-red-color">error_outline</i>
                                    </Icon>
                                </SrpTooltip>
                            }
                        </td>
                        <td className="p-0 border-top-0 border-bottom">
                            <IconButton
                                style={{ outline: "none" }}
                                type="button"
                                disabled={verifyingPaymentList}
                                onClick={() => removePayment(paymentIndex)}
                                size="large">
                                <i className="material-icons srp-icon-color">delete</i>
                            </IconButton>
                        </td>
                    </tr>
                ))}
            </tbody>
            <tfoot>
                <tr>
                    <td className="srp-fit font-weight-bold">{t("Total payment")}</td>
                    <td />
                    <td className="font-weight-bold text-right">{format.formatDollarAmount(paymentList.reduce((total, p) => total + p.paymentAmount, 0))}</td>
                    <td />
                    <td />
                </tr>
            </tfoot>
        </table>
    </div>
)

PaymentListDesktop.propTypes = {
    t: PropTypes.func.isRequired,
    paymentList: PropTypes.arrayOf(PropTypes.shape({
        srpAccountNumber: PropTypes.string.isRequired,
        paymentAmount: PropTypes.number.isRequired
    })),
    paymentListErrors: PropTypes.arrayOf(PropTypes.shape({
        srpAccountNumber: PropTypes.oneOfType([
            PropTypes.string.isRequired,
            PropTypes.shape({
                getLocalizedErrMsg: PropTypes.func.isRequired,
            }),
        ]),
        paymentAmount: PropTypes.oneOfType([
            PropTypes.string.isRequired,
            PropTypes.shape({
                getLocalizedErrMsg: PropTypes.func.isRequired,
            }),
        ]),
    })),
    removePayment: PropTypes.func.isRequired,
    verifyingPaymentList: PropTypes.bool.isRequired
}

export default PaymentListDesktop