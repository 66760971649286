import React from 'react'
import { withTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import format from '../../../srp_modules/format'
import utils from '../../../srp_modules/utils'

import Card from '@mui/material/Card'
import '../../../styles/dashboard-page.css'

import BankDetailsSection from './bank-details-section'
import TotalPurchaseLine from './total-purchase-line'

import PurchaseDetailsLine from '../../single_purchase_page/common/purchase-details-line'
import PurchaseDetailsPanel from '../../single_purchase_page/common/purchase-details-panel'

const SubmittedMultiPaymentNgpPagePure = ({ submitMultiPurchaseResult, purchaseList, paymentInfoList, totalPurchase, selectedBankAccountDescription, selectedBank, updateBankEmailAddressOnClick, t }) => {
    return (
        <div className="container py-3 hidden-sm-down">
            <h3 className="mt-3">{t("Make a purchase")}</h3>
            <br />
            <div className="row" id="section-to-print-payment-submitted">
                <div className="col-lg-6 col-12">
                    <label className="h5">{t("Your purchase has been submitted")}</label>
                    <Card>
                        <div className={`srp-olive-green p-3`}>
                            <div className="d-none d-lg-block" style={{ fontSize: "1.5rem" }}>
                                {t("Reference Number")} {submitMultiPurchaseResult.referenceNumber}
                            </div>
                            <div className="d-lg-none" style={{ fontSize: "1rem" }}>
                                {t("Reference Number")} {submitMultiPurchaseResult.referenceNumber}
                            </div>
                        </div>

                        <BankDetailsSection selectedBankAccountDescription={selectedBankAccountDescription} />
                        <TotalPurchaseLine totalPurchase={totalPurchase} />

                        <h4 className="text-secondary px-3 my-4">{t("Purchase details")}</h4>

                        {purchaseList.map((purchase, index) => {
                            const srpAccountNumber = format.formatBillAccountNumber(purchase.billAccount)
                            const purchaseAmount = utils.paymentToNumber(purchase.purchaseAmount)

                            const paymentInfo = paymentInfoList.find(pi => pi.billAccount === purchase.billAccount)
                            const amountOwedToSRP = paymentInfo.paymentInfo.amountDue
                            const payDownFraction = paymentInfo.paymentInfo.payDownFraction

                            return (
                                <div key={index}>
                                    <div className="px-3">
                                        {amountOwedToSRP <= 0 ?
                                            <PurchaseDetailsLine srpAccountNumber={srpAccountNumber} purchaseAmount={purchaseAmount} purchaseDate={new Date()} /> :
                                            <PurchaseDetailsPanel
                                                srpAccountNumber={srpAccountNumber}
                                                purchaseAmount={purchaseAmount}
                                                applyWholePurchaseTowardsArrears={purchase.payAmountOwedToSRP}
                                                purchaseDate={new Date()}
                                                amountOwedToSRP={amountOwedToSRP}
                                                payDownFraction={payDownFraction}
                                                defaultExpanded={false}
                                            />
                                        }
                                    </div>
                                    <hr />
                                </div>
                            )
                        })}

                        <div className="pt-2 px-3">
                            {submitMultiPurchaseResult.confirmationEmailSent &&
                                <p style={{ fontSize: "14px", color: "rgb(112,112,112)" }}>
                                    {t("A confirmation email has been sent to")} <strong>{submitMultiPurchaseResult.confirmationEmailAddress}</strong> {t("for this purchase.")}&nbsp;
                                    <button className="displayAsLink d-print-none" onClick={() => updateBankEmailAddressOnClick(selectedBank)}>{t("Update your email address")} &raquo;</button>
                                    <br />
                                </p>}
                            {!submitMultiPurchaseResult.confirmationEmailSent &&
                                <p style={{ fontSize: "14px", color: "rgb(112,112,112)" }}>
                                    {t("Unable to send a confirmation email to")} <strong>{submitMultiPurchaseResult.confirmationEmailAddress}</strong> {t("for this purchase.")}&nbsp;
                                    <button className="displayAsLink d-print-none" onClick={() => updateBankEmailAddressOnClick(selectedBank)}>{t("Update your email address")} &raquo;</button>
                                    <br />
                                </p>}

                            <div className="d-print-none mb-3" style={{ float: "right" }}>
                                <button className="btn srp-btn btn-lightblue" onClick={() => { window.print() }}>{t("Print")}</button><Link to="/myaccount/dashboard" className="btn srp-btn btn-blue ml-2">{t("Go to dashboard")}</Link>
                            </div>
                        </div>
                    </Card>
                </div>
                <div className="col" />
            </div>
        </div>
    )
}

SubmittedMultiPaymentNgpPagePure.propTypes = {
    totalPurchase: PropTypes.number.isRequired,
    submitMultiPurchaseResult: PropTypes.object.isRequired,
    purchaseList: PropTypes.arrayOf(PropTypes.object),
    paymentInfoList: PropTypes.arrayOf(PropTypes.object),
    selectedBankAccountDescription: PropTypes.string.isRequired,
    selectedBank: PropTypes.object,
    updateBankEmailAddressOnClick: PropTypes.func.isRequired,

    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired
}

const SubmittedMultiPaymentNgpPage = withTranslation("multiPurchasePage")(SubmittedMultiPaymentNgpPagePure)
export { SubmittedMultiPaymentNgpPage as default, SubmittedMultiPaymentNgpPagePure }