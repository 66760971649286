import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Formik } from 'formik'
import { PatternFormat } from 'react-number-format'
import CircularProgress from '@mui/material/CircularProgress'
import TextField from '@mui/material/TextField'

import { addBillAccount, getBillAccounts, selectBillAccountOnChange } from '../../../../actions/auth/bill_account/bill-account-actions'
import { addSnackbarMessage } from '../../../../actions/common/snackbar'
import validate from '../../../../srp_modules/validate'

class AddNewAccountForm extends React.Component {

    render() {
        return (
        <Formik
            initialValues={{
                accountNumber: '',
                phoneNumber: '',
                nickname: ''
            }}
            validate={(values) => validateAddAccountForm(values, this.props.t)}
            onSubmit={this.props.addNewBillAccount(this.props.hideForm)}>
            {({values, status, errors, dirty, touched, setFieldValue, handleBlur, handleChange, handleSubmit, submitCount, isSubmitting}) => {

                let formStatus = status || { }
                let isAccountError = (dirty || submitCount > 0) && touched.accountNumber && errors.accountNumber !== undefined
                let isPhoneError = (dirty || submitCount > 0) && touched.phoneNumber && errors.phoneNumber !== undefined
                let isNicknameError = (dirty || submitCount > 0) && touched.nickname && errors.nickname !== undefined

                return (
                    <React.Fragment>
                    {formStatus.error ? <div className="srp-alert-error">{formStatus.error}</div> : null}
                    <form method="POST" onSubmit={handleSubmit}>
                        <div className="row align-items-end">
                            <div className="col-lg-6 col-12">
                                {/* <InputLabel htmlFor="accountNumber" error={isAccountError} className="pt-2">SRP account number</InputLabel> */}
                                <PatternFormat
                                    name="accountNumber"
                                    value={values.accountNumber}
                                    valueIsNumericString={true}
                                    format="#########"
                                    type="tel"
                                    customInput={TextField}
                                    fullWidth={true}
                                    onValueChange={v => setFieldValue('accountNumber', v.value)}
                                    onBlur={handleBlur}
                                    error={isAccountError}
                                    helperText={isAccountError ? errors.accountNumber : ''} 
                                    label={this.props.t("SRP account number")}
                                />
                                {/* <InputLabel htmlFor="phoneNumber" error={isPhoneError} className="pt-2">Account phone number</InputLabel> */}
                                <PatternFormat
                                    id="phoneNumber"
                                    name="phoneNumber"
                                    value={values.phoneNumber}
                                    valueIsNumericString={true}
                                    format="(###) ###-####"
                                    type="tel"
                                    customInput={TextField}
                                    fullWidth={true}
                                    onValueChange={v => setFieldValue('phoneNumber', v.value)}
                                    onBlur={handleBlur}
                                    error={isPhoneError}
                                    helperText={isPhoneError ? errors.phoneNumber : ''} 
                                    label={this.props.t("Account phone number")}
                                />
                                {/* <InputLabel htmlFor="nickname" error={isNicknameError} className="pt-2">Nickname (optional)</InputLabel> */}
                                <TextField
                                    name="nickname"
                                    value={values.nickname}
                                    fullWidth={true}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    error={isNicknameError}
                                    helperText={isNicknameError ? errors.nickname : ''} 
                                    label={this.props.t("Nickname (optional)" )}   
                                />
                            </div>
                            <div className="col-lg-6 col-12 text-right">
                                <button type="button" className="btn srp-btn btn-lightblue mt-2"
                                        disabled={isSubmitting} onClick={this.props.hideForm}>{this.props.t("Cancel")}</button>
                                <button type="submit" className="btn srp-btn btn-green mt-2 ml-2" disabled={isSubmitting}>
                                    {isSubmitting ? <CircularProgress size={20} thickness={5} color="secondary" /> : this.props.t("Add account")}
                                </button>
                            </div>
                        </div>
                    </form>
                    </React.Fragment>
                )
            }}
        </Formik>
    )}
}

const mapStateToProps = () => {
    return {
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        addNewBillAccount: (hideForm) => async (values, goodies) => {
            let response = await dispatch(addBillAccount(values.accountNumber, values.phoneNumber, values.nickname))

            if (response.error === true) {
                goodies.setStatus({ error: 'We\'re sorry, something went wrong. Please try again later.' })
                goodies.setSubmitting(false)
                return
            }

            if (response.payload.success !== true && response.payload.error) {
                if (response.payload.errorNumber === 2) {
                    goodies.setStatus({ error: 'This SRP account is already active in your list of accounts.' })
                    goodies.setSubmitting(false)
                    return
                }

                goodies.setStatus({ error: response.payload.error })
                goodies.setSubmitting(false)
                return
            }

            let accountListResponse = await dispatch(getBillAccounts())
            if (accountListResponse.error === true) {
                goodies.setStatus({ error: 'We\'re sorry, something went wrong. Please try again later.' })
                goodies.setSubmitting(false)
                return
            }

            let selectResponse = await dispatch(selectBillAccountOnChange(values.accountNumber))
            if (selectResponse.error === true) {
                goodies.setStatus({ error: 'We\'re sorry, something went wrong. Please try again later.' })
                goodies.setSubmitting(false)
                return
            }

            dispatch(addSnackbarMessage(<div>Account added</div>))
            hideForm()
        }
    }
}

AddNewAccountForm.propTypes = {
    hideForm: PropTypes.func,
    addNewBillAccount: PropTypes.func
}

export default connect(mapStateToProps, mapDispatchToProps)(AddNewAccountForm)

function validateAddAccountForm(values, t) {
    let errors = {}

    if (values.accountNumber.length === 0) {
        errors.accountNumber = t("SRP account number is required")
    }

    if (values.phoneNumber.length === 0) {
        errors.phoneNumber = t("Account phone number is required")
    }
    else if (!validate.phoneNumber(values.phoneNumber)) {
        errors.phoneNumber = t("You must enter a valid phone number")
    }

    return errors
}