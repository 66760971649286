import React from 'react'
import PropTypes from 'prop-types'

import { withTranslation } from 'react-i18next'

import '../../../styles/dashboard-page.css'

import CallAnytime from '../../myaccount_header/call-anytime'

import GiftPaymentSubmittedCardContainer from './gift-payment-submitted-card-container'

const GiftPaymentSubmittedPagePure = ({t, i18n}) => (
    <div className="container py-3">
        <CallAnytime />

        <h3 className="mt-3 mb-5">{t("Make a gift payment")}</h3>

        <div className="row">
            <div className="col-lg-6">
                <GiftPaymentSubmittedCardContainer t={t} i18n={i18n} />
            </div>
        </div>
    </div>
)

GiftPaymentSubmittedPagePure.propTypes = {
    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired,
}

const GiftPaymentSubmittedPage = withTranslation("giftPayment")(GiftPaymentSubmittedPagePure)
export { GiftPaymentSubmittedPage as default, GiftPaymentSubmittedPagePure }