import React from "react"
import { withTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import { NavLink } from 'react-router-dom'

import bankIconGrey from '../../../images/payment/bank-grey.png'
import bankIconWhite from '../../../images/payment/bank-white.png'

const iconSize = {
    height: "32px", width: "32px"
}

const mobileIconSize = {
    height: "24px", width: "24px"
}

const NgpPayUsingBankAccountLinkPure = ({t}) => (
    <div>
        <div className="d-none d-lg-block">
            <NavLink end to="/myaccount/payment/multi/mpower" className="nav-link flex-column align-items-center">
                <img src={bankIconGrey} style={iconSize} className="icon" />
                <img src={bankIconWhite} style={iconSize} className="iconSelected" />
                <div>{t("Bank account")}</div>
            </NavLink>
        </div>
        <div className="d-block d-lg-none">
            <NavLink
                end to="/myaccount/payment/multi/mpower"
                className="nav-link nav-link-mobile d-flex justify-content-start align-items-center p-1">
                <img src={bankIconGrey} style={mobileIconSize} className="icon ml-1 mr-2" />
                <img src={bankIconWhite} style={mobileIconSize} className="iconSelected ml-1 mr-2" />
                <span>{t("Bank account")}</span>
            </NavLink>
        </div>
    </div>
)

NgpPayUsingBankAccountLinkPure.propTypes = {
    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired
}

const NgpPayUsingBankAccountLink = withTranslation("multiPurchasePage")(NgpPayUsingBankAccountLinkPure)
export { NgpPayUsingBankAccountLink as default, NgpPayUsingBankAccountLinkPure }