import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Formik } from 'formik'
import PropTypes from 'prop-types'

import CircularProgress from '@mui/material/CircularProgress'
import TextField from '@mui/material/TextField'

import validate from '../../../srp_modules/validate'
import PasswordValidation from '../../login_page/password-validation'
import { changePassword, refreshPasswordReset } from '../../../actions/auth/login/login-actions'

import { Trans } from 'react-i18next'

class ChangePasswordForm extends React.Component {

    constructor(props) {
        super(props)

        this.validatePasswordChangeForm = this.validatePasswordChangeForm.bind(this)
    }

    validatePasswordChangeForm(values) {
        let errors = {}

        if (values.currentPassword === undefined || values.currentPassword.trim().length === 0) {
            errors.currentPassword = 'Current password is required'
        }

        if (values.password === undefined || values.password.trim().length === 0) {
            errors.password = 'Password is required'
        }
        else if (validate.password(values.password) !== true) {
            errors.password = ''
        }

        if (values.confirmPassword === undefined || values.confirmPassword.trim().length === 0) {
            errors.confirmPassword = 'Password confirmation is required'
        }
        else if (!validate.passwordMatch(values.password, values.confirmPassword)) {
            errors.confirmPassword = 'Password does not match'
        }

        return errors
    }

    render() {
        return (
            <Formik
                initialValues={{
                    currentPassword: '',
                    password: '',
                    confirmPassword: '',
                    showPassword: false,
                    showCurrentPassword: false,
                    attempts: 0
                }}
                validate={this.validatePasswordChangeForm}
                onSubmit={this.props.submitChangePassword(this.props.setDisplayChangePasswordSuccess)}>
                {({ values, errors, status, touched, dirty, handleChange, handleBlur, handleSubmit, setValues, submitCount, isSubmitting }) => {

                    let isPasswordError = (dirty || submitCount > 0) && touched.password === true && errors.password !== undefined
                    let isConfirmPasswordError = (dirty || submitCount > 0) && touched.confirmPassword === true && errors.confirmPassword !== undefined

                    let changeStatus = status || {}

                    let isCurrentPasswordError = changeStatus.submitError !== undefined && changeStatus.submitError !== ''

                    return (
                        <form onSubmit={handleSubmit}>
                            {changeStatus.submitError}
                            <div className="w-100">
                                <div>
                                    {/* <InputLabel error={isCurrentPasswordError} className="text-muted mt-4" htmlFor="currentPassword">Current password</InputLabel> */}
                                    <TextField
                                        fullWidth={true}
                                        type={values.showCurrentPassword ? 'text' : 'password'}
                                        value={values.currentPassword}
                                        error={isCurrentPasswordError}
                                        helperText=""
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        id="currentPassword"
                                        name="currentPassword" 
                                        label={this.props.t("Current password")}
                                    />

                                    <PasswordValidation
                                        togglePasswordVisibilityOnClick={() => setValues({ ...values, showPassword: !values.showPassword })}
                                        showPassword={values.showPassword}
                                        passwordLabel={this.props.t("New password")}
                                        password={values.password}
                                        passwordError={isPasswordError}
                                        passwordErrorText={isPasswordError ? errors.password : ''}
                                        passwordOnChange={handleChange}
                                        passwordOnBlur={handleBlur}
                                        confirmPassword={values.confirmPassword}
                                        confirmPasswordError={isConfirmPasswordError}
                                        confirmPasswordErrorText={isConfirmPasswordError ? errors.confirmPassword : ''}
                                        confirmPasswordOnChange={handleChange}
                                        confirmPasswordOnBlur={handleBlur} />
                                </div>
                                <div className="justify-content-end">
                                    <div className="col-lg text-right">
                                        <button type="button" disabled={isSubmitting}
                                                className="btn srp-btn btn-lightblue ml-2 mb-2"
                                                onClick={this.props.setDisplayChangePassword(false)}>
                                            {this.props.t("Cancel")}
                                        </button>
                                        <button type="submit" disabled={isSubmitting} className="btn srp-btn btn-green ml-2 mb-2">
                                            {isSubmitting ? <CircularProgress size={20} thickness={5} style={{ color: 'white' }} /> : 'Update'}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    )
                }}
            </Formik>
        )
    }
}

ChangePasswordForm.propTypes = {
    submitChangePassword: PropTypes.func.isRequired,
    setDisplayChangePassword: PropTypes.func.isRequired,
    setDisplayChangePasswordSuccess: PropTypes.func.isRequired
}

const mapDispatchToProps = dispatch => {
    return {
        submitChangePassword: (setDisplayChangePasswordSuccess) => async (values, goodies) => {

            let result = await dispatch(changePassword(values.currentPassword, values.password))

            if (result.error === true) {

                let errorMessage = <div className="srp-alert-error mb-2" style={{ marginTop: '20px' }}>We're sorry, something went wrong. Please try again later.</div>

                goodies.setStatus({ submitError: errorMessage })
                goodies.setSubmitting(false)
            }
            else {
                if (result.payload.success === false) {

                    let attempts = values.attempts + 1
                    let newValues = { ...values, attempts }

                    let errorMessage = newValues.attempts > 2
                        ? (<div className="srp-alert-error mb-2" style={{ marginTop: '20px' }}>
                            <Trans i18nKey="account_lock_message" t={this.props.t}>
                                Your account may be locked after further failed attempts. Have you <Link to="/passwordReset" onClick={() => refreshPasswordReset(values.username)}>forgotten your password</Link>?
                            </Trans>
                            </div>)
                        : <div className="srp-alert-error mb-2" style={{ marginTop: '20px' }}>{this.props.t("The password provided is incorrect")}</div>

                    goodies.setValues(newValues)
                    goodies.setStatus({ submitError: errorMessage })
                    goodies.setSubmitting(false)
                    return
                }
            }

            goodies.setStatus({ submitError: '' })
            goodies.setSubmitting(false)
            setDisplayChangePasswordSuccess()
        }
    }
}

export default connect(null, mapDispatchToProps)(ChangePasswordForm)
