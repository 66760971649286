import NetCompliantChartData from './net-compliant-chart-data'
import drawVerticalBars from './draw-vertical-bars'
import * as d3 from 'd3'
import { DateTime } from 'luxon'
import {displaySizeConstants} from '../../constants/display-size-constants'
import {getDisplaySize} from '../../srp_modules/display-size'
import {dailyUsageChartTypeConstants} from '../../constants/daily-usage-chart-type-constants'

const margin = {
    top: 20,
    right: 70,
    bottom: 10,
    left: 70
}
const yOffset = 40
const standardChartKeys = ["superOffPeak", "offsetSuperOff", "offPeak", "offsetOff", "shoulder", "offsetShoulder", "onPeak", "offsetOn", "total", "offsetTotal", "min", "offsetMin", "max"]



export default function drawVerticalChart(data, usageChartType, highDemandInfoList, 
    hasOnOffShoulder, barClickCallback, hasCost, isSolar, t) {
    window.monthly_usage_selected_date = ""
    let x
    let y

    let isCost = usageChartType === dailyUsageChartTypeConstants.COST
    let isDemand = usageChartType === dailyUsageChartTypeConstants.DEMAND
    let isConsumption = usageChartType === dailyUsageChartTypeConstants.CONSUMPTION
    let isGeneration = usageChartType === dailyUsageChartTypeConstants.GENERATION
    let netCompliantChartData = new NetCompliantChartData(data, isCost, isDemand, isConsumption, isGeneration)
    let adjustedData = addTooltipInfoToData(netCompliantChartData.adjustedData)
    let maxY = netCompliantChartData.maxY
    let minY = netCompliantChartData.minY
    let legendDisplayStates = getLegendDisplayStates(adjustedData, isCost)
    let chart = d3.select("#usagePageChartContainer")
    d3.selectAll("#usagePageChart").remove()
    let displaySize = getDisplaySize(window.innerWidth)
    let chartSize = getChartSvgSize(displaySize)
    let svg = chart
        .insert('svg', 'div')
        .attr('width', chartSize.width)
        .attr('height', chartSize.height)
        .attr('id', 'usagePageChart')
        .attr('class', 'viz-chart')
    let width = +svg.attr("width") - margin.left - margin.right
    let height = +svg.attr("height") - margin.top - margin.bottom
    let g = svg.append("g").attr("transform", "translate(" + margin.left + "," + margin.top + ")")

    x = d3.scaleBand()
        .range([0, width])
        .align(0.1)

    y = d3.scaleLinear()
        .rangeRound([height - yOffset, 0])

    x.domain(adjustedData.map(function (d) {
        return d.date
    }))
    y.domain([minY, maxY])

    //add line at zero if the chart has negative data
    if (minY < 0) {
        g.append("line")
            .attr("y1", y(0))
            .attr("y2", y(0))
            .attr("x1", 20)
            .attr("x2", width - 20)
            .style("stroke-width", "0.01em")
            .style("stroke", "#1F1D1D")
    }

    const barWidth = (width / data.length / 1.5)
    const xOffset = (width / data.length - barWidth) / 2
    let initialTt = chart.select(".viz-tooltip-arrow-main")
    initialTt.remove()
    let tooltip = addTooltipToChart(chart)

    g.append("g").attr("id", "dailyChartMainGroup")
        .selectAll("g")
        .data(d3.stack().keys(standardChartKeys)(adjustedData))
        .enter().append("g")
        .attr("class", function (d, i) {
            return getCssClass(standardChartKeys[i],isGeneration,hasOnOffShoulder,d[i]) + " viz-bar"
        })
        .selectAll("path")
        .data(function (d) {
            return d
        })
        .enter().append("g")
        .classed("viz-on-off-shoulder", function (d) {
            return d.data.onPeak === 0 && hasOnOffShoulder && isDemand})
        .append("path")
        .on("mouseover", function (d) {
            handleMouseOver(d, this, tooltip, barWidth, xOffset, maxY, svg, x, y, isCost, t)
        })
        .on("mousedown", function(d){
            if('ontouchstart' in document.documentElement !== true) {
                return true
            }
            handleMouseOver(d, this, tooltip, barWidth, xOffset, maxY, svg, x, y, isCost, t)

            let billStartDate = DateTime.fromISO(d.data.billStartDate).setZone('MST')
            if(window.monthly_usage_selected_date !== undefined
                && window.monthly_usage_selected_date !== null
                && window.monthly_usage_selected_date !== "")
            {
                if (window.monthly_usage_selected_date.equals(billStartDate)) {
                    barClickCallback(billStartDate,
                        DateTime.fromISO(d.data.date).setZone('MST'),
                        usageChartType,
                        hasCost,
                        isSolar)
                }
            }
            d3.event.stopPropagation()
            window.monthly_usage_selected_date = DateTime.fromISO(d.data.billStartDate)
        })
        .on("mouseout", function (d) { handleMouseOut(d, this, tooltip) })
        .on("click", function(d){ 
            if('ontouchstart' in document.documentElement) {
                return true
            }
            barClickCallback(DateTime.fromISO(d.data.billStartDate).setZone('MST'),
                DateTime.fromISO(d.data.date).setZone('MST'),
                usageChartType,
                hasCost,
                isSolar)
        })
        .attr("height", 0)
        .attr("d", function (d) {
            return drawVerticalBars(d, data, x, y, minY, maxY, xOffset, barWidth, "date")
        })

    drawAxisTicks(data, g, height, yOffset, width, isCost, x, y, displaySize)
    drawAxisLabels(svg, chartSize.height, chartSize.width,usageChartType, t)
    drawLegend(width, legendDisplayStates, isGeneration)
}

function drawAxisTicks(data, g, height, yOffset, width, isCost, x, y, displaySize) {
    g.append("g")
        .attr("class", "axis")
        .attr("class", "usage-date-axis")
        .attr("transform", "translate(0," + (height - yOffset) + ")")
        .call(d3.axisBottom(x).tickValues(x.domain().filter(function (d, i) {
            return calculateAxisTickStep(data.length, i, displaySize)
        }))
        .tickPadding(8)
        .tickFormat(function(x) {return DateTime.fromISO(x).toFormat('MMM d')}))
        //.tickFormat(i18n.language === 'es' ? es.format("%b %e") : en.format("%b %e")))
        d3.selectAll("g.tick line")
        .attr("y2", 10)
        .style("stroke-width", "1px")
        .style('shape-rendering','crispEdges')

    // TODO: Getting "Unexpected value NaN parsing y attribute" error below
    if (isCost) {
        g.append("g")
            .attr("class", "axis")
            .call(d3.axisLeft(y).ticks(4, "s")
                .tickFormat(d3.format('$,.2s')))
            .append("text")
            .attr("x", 2)
            .attr("y", y(y.ticks().pop()) + 0.5)
            .attr("fill", "#000")
            .attr("font-weight", "bold")
            .attr("text-anchor", "start")
    }
    else {
        g.append("g")
            .attr("class", "axis")
            .call(d3.axisLeft(y).ticks(4, "s"))
            .append("text")
            .attr("x", 2)
            .attr("y", y(y.ticks().pop()) + 0.5)
            .attr("fill", "#000")
            .attr("font-weight", "bold")
            .attr("text-anchor", "start")
    }
}

function drawAxisLabels(svg,height,width,usageChartType, t){
    let leftLabel = ""
    switch(usageChartType){
        case dailyUsageChartTypeConstants.COST:
            leftLabel = t("Energy cost")
            break
        case dailyUsageChartTypeConstants.DEMAND:
            leftLabel = t("Peak Demand")
            break
        case dailyUsageChartTypeConstants.CONSUMPTION:
            leftLabel = t("Usage (kWh)")
            break
        case dailyUsageChartTypeConstants.USAGE:
            leftLabel = t("Usage (kWh)")
            break
        case dailyUsageChartTypeConstants.NET_ENERGY:
            leftLabel = t("Usage (kWh)")
            break
        case dailyUsageChartTypeConstants.GENERATION:
            leftLabel = t("Generation (kWh)")
    }
    
    svg.append("text")
        .attr("transform", "rotate(-90)")
        .attr("y", 0 )
        .attr("x",0 - (height/2))
        .attr("dy", "1em")
        .style("text-anchor", "middle")
        .attr("class","chart-axis-label")
        .text(leftLabel)
  
}
function drawLegend(width, legendDisplayStates, isGeneration) {
    let legendContainer = d3.select('#dailyUsageLegendContainer')
    legendContainer
        .style("left", margin.left + "px")
        .style("width", width + "px")
    if (legendDisplayStates.hasSuperOff) {
        let superOffItem = d3.select('#superOffLegendItem')
        cssForLegnedItem(superOffItem, isGeneration)    
    }
    else{
        d3.select('#superOffLegendItem')
            .attr("class", "chart-legend-item-hidden")
    }
    if (legendDisplayStates.hasOffPeak) {
        let offItem = d3.select('#offPeakLegendItem')
        cssForLegnedItem(offItem, isGeneration)
    }
    else{
        d3.select('#offPeakLegendItem')
            .attr("class", "chart-legend-item-hidden")
    }
    if (legendDisplayStates.hasShoulder) {
        let shoulderItem = d3.select('#shoulderLegendItem')
        cssForLegnedItem(shoulderItem, isGeneration)
    }
    else{
        d3.select('#shoulderLegendItem')
            .attr("class", "chart-legend-item-hidden")
    }
    if (legendDisplayStates.hasOnPeak) {
        let onItem = d3.select('#onPeakLegendItem')
        cssForLegnedItem(onItem, isGeneration)
    }
    else{
        d3.select('#onPeakLegendItem')
            .attr("class", "chart-legend-item-hidden")
    }
    if (legendDisplayStates.hasTotal && legendDisplayStates.isCost) {
        let totalItem = d3.select('#totalLegendItemCost')
        cssForLegnedItem(totalItem, isGeneration)
    }
    else{
        d3.select('#totalLegendItemCost')
            .attr("class", "chart-legend-item-hidden")
    }
    if (legendDisplayStates.hasTotal && !legendDisplayStates.isCost) {
        d3.select('#totalLegendItem')
            .attr("class", "usage-chart-legend-item-show")
    }
    else{
        d3.select('#totalLegendItem')
            .attr("class", "chart-legend-item-hidden")
       }
}
function getChartSvgSize(displaySize) {
    switch(displaySize){

    case displaySizeConstants.EXTRA_LARGE:
        return { height: 300, width: 780 }
    
    case displaySizeConstants.LARGE:
        return { height: 300, width: 650 }
    
    case displaySizeConstants.MEDIUM:
        return { height: 300, width: 480 }
    
    case displaySizeConstants.SMALL:
        return { height: 175, width: 480 }
    
    case displaySizeConstants.EXTRA_SMALL:
        return { height: 200, width: 420 }
    }
}
function cssForLegnedItem(legendItem, isGeneration){
    legendItem.attr("class", "usage-chart-legend-item-show")
    if(isGeneration){
        legendItem.select(".usage-chart-legend-circle").classed("generation", true)
    }
    else{
        legendItem.select(".usage-chart-legend-circle").classed("generation", false)
    }
}
function calculateOffsetLeft(svg) {
    let offsetLeft = svg.node().offsetLeft
    if (typeof offsetLeft != "undefined") {
        return offsetLeft
    }
    let svgElement = document.getElementById(svg.node().id)
    let svgParent = svgElement.parentNode
    let left = svgElement.getBoundingClientRect().left -
        svgParent.getBoundingClientRect().left

    return left;
}
function getCssClass(text,isGeneration) {
    if (text.includes("offset")) {
        return "viz-invisible"
    }
    return isGeneration 
        ? "viz-" + text + "-generation" 
        : "viz-" + text
}
function addTooltipToChart(chart) {
    let tooltip = chart
        .append("div")
        .attr("class", "viz-tooltip-arrow-main")
    let tooltipArrow =
        tooltip
            .append("div")
            .attr("class", "viz-tooltip-arrow")

    tooltipArrow.append("div")
        .attr("class", "viz-tooltip-main")
        .append("div")
        .attr("class", "viz-tooltip-inner")
        .attr("id", "viz-tooltipText")
    return tooltip
}

function handleMouseOut(d, element, tooltip) {
    $(element).removeClass("current-hover")
    tooltip.style("display", "none")
}

function handleMouseOver(d, element, tooltip, barWidth, xOffset,
    maxY, svg, x, y, isCost, t) {
    $(element).addClass("current-hover")
    createTooltip(d, tooltip, barWidth, xOffset,
        maxY, svg, x, y, isCost, t)
    return true
}

function createTooltip(d, tooltip, barWidth,
    xOffset, maxY, svg, x, y, isCost, t) {
    let barTop = maxY - d.data.max

    let xPosition = x(d.data.date) + xOffset + margin.left +
        (barWidth - 20) / 2 + calculateOffsetLeft(svg)
    let yPosition = barTop === 0 
        ? y(d.data.max)
        : y(barTop)
    
    tooltip
        .style("left", xPosition + "px")
        .style("top", yPosition + "px")
        .style("display", "block")
    let tooltipInner = tooltip.select(".viz-tooltip-inner")
    tooltipInner.selectAll("*").remove()
    tooltipInner.append("div").text(
        DateTime.fromISO(d.data.billStartDate).toFormat('MMM d')
        + ' - ' +
        DateTime.fromISO(d.data.date).toFormat('MMM d'))
    if(isCost){
        if (d.data.showTotalCostTip) {
            tooltipInner.append("div")
                .attr("class", "viz-tooltip-text-line")
                .text(t("Cost") + ": " + Number(d.data.totalCost.toFixed(2)).toLocaleString('EN-US',{ style: 'currency', currency: 'USD' }))
        }
    }
    else{
        if (d.data.showOffPeakTip) {
            tooltipInner.append("div")
                .attr("class", "viz-tooltip-text-line")
                .text(d.data.tooltipOffPeak)
        }
        if (d.data.showShoulderTip) {
            tooltipInner.append("div")
                .attr("class", "viz-tooltip-text-line")
                .text(d.data.tooltipShoulder)
        }
        if (d.data.showOnPeakTip) {
            tooltipInner.append("div")
                .attr("class", "viz-tooltip-text-line")
                .text(d.data.tooltipOnPeak)
        }
        if (d.data.showSuperOffPeakTip) {
            tooltipInner.append("div")
                .attr("class", "viz-tooltip-text-line")
                .text(d.data.tooltipSuperOffPeak)
        }
        if (d.data.showTotalTip) {
            tooltipInner.append("div")
                .attr("class", "viz-tooltip-text-line")
                .text(d.data.tooltipTotal)
        }
    }
  
}

function calculateAxisTickStep(dataLength,i){
    return i % Math.round(dataLength / 5) === 0  
}

function addTooltipInfoToData(adjustedData){
        adjustedData.forEach(function (element) {
            element.date = DateTime.fromISO(element.date).setZone('MST')
            element.day = DateTime.fromISO(element.day).toFormat('yyyy-MM-dd')
           
        })
    return adjustedData
}

function getLegendDisplayStates(adjustedData,isCost){
    let hasOnPeak = false,
    hasSuperOff = false,
    hasOffPeak = false,
    hasShoulder = false,
    hasTotal = false

    adjustedData.forEach(function (element) {
        hasOnPeak = element.onPeak != 0 ? true : hasOnPeak
        hasOffPeak = element.offPeak != 0 ? true : hasOffPeak
        hasShoulder = element.shoulder != 0 ? true : hasShoulder
        hasSuperOff = element.superOffPeak != 0 ? true : hasSuperOff
        hasTotal = element.total != 0 ? true : hasTotal
    })
    return {hasOnPeak: hasOnPeak,
        hasSuperOff: hasSuperOff,
        hasOffPeak: hasOffPeak ,
        hasShoulder: hasShoulder,
        hasTotal: hasTotal,
        isCost: isCost
    }
}