import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import config from 'my-account-config'
import Dialog from '@mui/material/Dialog'
import { withTranslation } from 'react-i18next'

import { sendUsageData } from '../../actions/auth/forms/clean-power-actions'
import utils from '../../srp_modules/utils'

class SolarEstimatorAd extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            showAcknowledgement: false
        }

        this.openAcknowledgement = this.openAcknowledgement.bind(this)
        this.closeAcknowledgement = this.closeAcknowledgement.bind(this)
    }

    openAcknowledgement() {
        this.setState({
            showAcknowledgement: true
        })
    }

    closeAcknowledgement() {
        this.setState({
            showAcknowledgement: false
        })
    }

    render() {

        return (
            <div>
                <button aria-label="Interested In Solar?" className="displayAsLink" onClick={() => this.openAcknowledgement()}>
                    {this.props.children}
                </button>
                {this.props.hasEnoughHistoryInfo.success
                    ? this.props.hasEnoughHistoryInfo.hasEnoughHistory
                        ? <Dialog
                            modal="true"
                            open={this.state.showAcknowledgement}
                            PaperProps={{ className: "srp-modal" }}
                        >
                            <div className="srp-modal-body">
                                <div className="srp-modal-close" onClick={this.closeAcknowledgement} />
                                <div>
                                    <div className="srp-modal-header">
                                        <h4 className="srp-modal-title">{this.props.t("Customer Acknowledgement")}</h4>
                                    </div>
                                    <div>
                                        <div className="mb-2">
                                            <div className="mb-2">{this.props.t('By clicking "Agree" below, you acknowledge your agreement with the following')}:</div>
                                            <ul>
                                                <li>
                                                    {this.props.t("The solar estimator can only compare prices based on current prices and conditions. In the future, prices and price structures could change significantly. SRP cautions that its calculations should not be used to predict future conditions or future price differentials.")}
                                                </li>
                                            </ul>
                                            <div>{this.props.t("You acknowledge that you have read and understood this Customer Acknowledgement.")}</div>
                                        </div>
                                        <div className="srp-modal-footer mb-0">
                                            <div className="d-flex justify-content-end">
                                                <button
                                                    className="btn srp-btn btn-lightblue ml-2"
                                                    onClick={this.closeAcknowledgement}>
                                                    {this.props.t("Cancel")}
                                                </button>
                                                <button
                                                    className="btn srp-btn btn-blue ml-2"
                                                    type="button"
                                                    onClick={this.props.sendUsageDataToSolarEstimator(this.closeAcknowledgement, this.props.hasEnoughHistoryInfo.solarEstimatorURL, this.props.selectedBillAccount)}>
                                                    {this.props.t("Agree")}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Dialog>
                        : <Dialog
                            modal="true"
                            open={this.state.showAcknowledgement}
                            PaperProps={{ className: "srp-modal" }}
                        >
                            <div className="srp-modal-body">
                                <div className="srp-modal-close" onClick={this.closeAcknowledgement} />
                                <div>
                                    <div className="srp-modal-header">
                                        <h4 className="srp-modal-title">{this.props.t("Not enough data")}</h4>
                                    </div>
                                    <div>
                                        <div className="mb-2">
                                            {this.props.t("12monthNotice", {month: getMonthsOfDataDisplay(this.props.hasEnoughHistoryInfo.numMonthsOfData)})}
                                        </div>
                                        <div className="srp-modal-footer mb-0">
                                            <button className="btn srp-btn btn-blue ml-2" onClick={this.closeAcknowledgement}>{this.props.t("Close")}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Dialog>
                    : <Dialog
                        modal="true"
                        open={this.state.showAcknowledgement}
                        PaperProps={{ className: "srp-modal" }}
                    >
                        <div className="srp-modal-body">
                            <div className="srp-modal-close" onClick={this.closeAcknowledgement} />
                            <div>
                                <div className="srp-modal-header">
                                    <h4 className="srp-modal-title">{this.props.t("We're sorry")}</h4>
                                </div>
                                <div>
                                    <div className="mb-2">
                                        {this.props.t("cannotSetup", {phoneNumber : utils.getFormattedCSPhoneNumber(this.props.selectedBillAccountDetails.isCommercial)})}
                                    </div>
                                    <div className="srp-modal-footer mb-0">
                                        <button className="btn srp-btn btn-blue ml-2" onClick={this.closeAcknowledgement}>{this.props.t("Close")}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Dialog>
                }
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        ...state.accountInfo.billAccount,
        ...state.cleanPower
    }
}
const mapDispatchToProps = dispatch => {
    return {
        sendUsageDataToSolarEstimator: (closeModal, actionURL, billAccount) => async () => {

            let appPathWithTrailingSlash = config.appPath === '/' ? config.appPath : `${config.appPath}/`
            let redirect = window.location.origin + appPathWithTrailingSlash + 'solarestimatorredirect'
            let newWindow = window.open(redirect, 'SolarEstimator')

            let wattPlanInfo = {
                wattPlanType: "SolarEstimator",
                billAccount: billAccount
            }

            let result = await dispatch(sendUsageData(wattPlanInfo))
            if (!result || result.error === true || result.payload.success === false) {
                newWindow.location = window.location.origin + appPathWithTrailingSlash + 'error'
                closeModal()
                return
            }

            let form = newWindow.document.createElement('form')
            form.setAttribute('method', 'POST')
            form.setAttribute('action', actionURL)

            let input1 = newWindow.document.createElement('input')
            input1.setAttribute('type', 'hidden')
            input1.setAttribute('name', 'customerid')
            input1.setAttribute('value', result.payload.cachedIntervalDataInfo.customerId)

            let input2 = newWindow.document.createElement('input')
            input2.setAttribute('type', 'hidden')
            input2.setAttribute('name', 'sessionid')
            input2.setAttribute('value', result.payload.cachedIntervalDataInfo.sessionId)

            let input3 = newWindow.document.createElement('input')
            input3.setAttribute('type', 'hidden')
            input3.setAttribute('name', 'uuid')
            input3.setAttribute('value', result.payload.cachedIntervalDataInfo.uuid)

            form.appendChild(input1)
            form.appendChild(input2)
            form.appendChild(input3)

            let newBody = newWindow.document.getElementsByTagName('body')[0]
            newBody.appendChild(form)

            form.submit()

            closeModal()
        }
    }
}

SolarEstimatorAd.propTypes = {
    selectedBillAccount: PropTypes.number,
    selectedBillAccountDetails: PropTypes.object,
    hasEnoughHistoryInfo: PropTypes.object,
    sendUsageDataToSolarEstimator: PropTypes.func,
    children: PropTypes.element,
    t: PropTypes.func.isRequired
}

export default withTranslation("promotionBanner")(connect(mapStateToProps, mapDispatchToProps)(SolarEstimatorAd))

function getMonthsOfDataDisplay(numOfMonths) {
    let display = ""
    if (numOfMonths === 1) {
        display = "1 month"
        return display
    }
    else {
        display = `${numOfMonths} months`
        return display
    }
}
