import React from 'react'
import PropTypes from 'prop-types'
import { DateTime } from 'luxon'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import CircularProgress from '@mui/material/CircularProgress'

import CIXCalendar from './cix-calendar'
import { paymentConstants } from '../../../../constants/index';

import utils from '../../../../srp_modules/utils'

const CreditExtensionCard = ({ selectedBillAccount, showCIXCardOnChange, shouldSendCIXConfirmationEmail, sendCIXConfirmationEmailOnChange, emailToSendCIXConfirm,
    maxExtensionDate, maxExtensionAmount, submittingCreditExtension, submitCreditExtensionOnClick, endCIXFlow, t, i18n }) => {

    const isSpanish = i18n.language==="es"
    const _maxExtensionDate = DateTime.fromISO(maxExtensionDate)
    const spanishMonth = utils.getSpanishMonth(_maxExtensionDate.month)
    const maxExtensionDateMonthNameDay = _maxExtensionDate.toFormat(isSpanish ? `d [${spanishMonth}]`: 'MMMM d')
    const maxExtensionDateMonthYear = _maxExtensionDate.toFormat(isSpanish ? `[${spanishMonth}] yyyy`: 'MMMM yyyy')
    const maxExtensionDateMonthDay = _maxExtensionDate.toFormat(isSpanish ? 'd/M' : 'M/d')

    let checkBoxLabel = (<div>{t("Send a confirmation email to")} <strong>{(emailToSendCIXConfirm).toLowerCase()}</strong></div>)
    let emailForCIX = ""
    if (shouldSendCIXConfirmationEmail) {
        emailForCIX = emailToSendCIXConfirm
    }

    return (
        <div className="srp-card">
            <div className="srp-card-header">
                {t("My bill")}
            </div>
            <div className="srp-card-body">
                <div className="srp-card-details">
                    <h5><strong>{t("Extend my due date")}</strong></h5>
                    <h5 className="d-flex justify-content-start align-items-center mb-4">
                        <i className="material-icons text-primary-ada md-18">keyboard_arrow_left</i>
                        <button className="displayAsLink text-primary-ada" onClick={() => endCIXFlow()}>{t("Back to Home")}</button>
                    </h5>
                    <div className="text-muted mb-3">{t("We_can_extend_the_due_date_up_to", {maxExtensionAmount, maxExtensionDate: maxExtensionDateMonthNameDay})}</div>
                    <div className="text-muted mb-3">{t("A late payment fee will be assessed. You cannot exceed 6 extensions per 12 month period.")}</div>
                    <div className="mb-2 d-flex justify-content-center">{maxExtensionDateMonthYear.toUpperCase()}</div>
                    <div className="mb-3"><CIXCalendar maxExtensionDate={maxExtensionDate} isSpanish={isSpanish} /></div>
                    <div className="mb-2">
                        <FormGroup row>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={shouldSendCIXConfirmationEmail}
                                        onChange={event => sendCIXConfirmationEmailOnChange(event.target.checked)}
                                        value="cixEmail"
                                        color="primary"
                                    />
                                }
                                label={checkBoxLabel}
                            />
                        </FormGroup>
                    </div>
                    <div className="d-flex flex-wrap justify-content-end">
                        <button className="btn srp-btn btn-lightblue text-white mb-2" onClick={() => showCIXCardOnChange(paymentConstants.CIX_ASSISTANCE_CARD)}>{t("Need more time?")}</button>
                        <button
                            onClick={() => submitCreditExtensionOnClick(selectedBillAccount, emailForCIX)}
                            disabled={submittingCreditExtension}
                            className="btn srp-btn btn-green text-white ml-2 mb-2">
                            {submittingCreditExtension
                                ? <CircularProgress size={20} thickness={5} style={{ color: 'white' }} aria-label="submitting credit extension request" alt="progress icon" />
                                : t("Pay_by_date", {date: maxExtensionDateMonthDay})
                            }
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

CreditExtensionCard.propTypes = {
    selectedBillAccount: PropTypes.number,
    showCIXCardOnChange: PropTypes.func,
    shouldSendCIXConfirmationEmail: PropTypes.bool,
    sendCIXConfirmationEmailOnChange: PropTypes.func,
    emailToSendCIXConfirm: PropTypes.string,
    maxExtensionDate: PropTypes.string,
    maxExtensionAmount: PropTypes.string,
    submittingCreditExtension: PropTypes.bool,
    submitCreditExtensionOnClick: PropTypes.func,
    endCIXFlow: PropTypes.func,

    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired,
}

export default CreditExtensionCard