import React from 'react'
import { DocumentTitle } from '../../../srp_modules/document-title'

import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

import AccountInfoBarContainer from '../../myaccount_header/info_bar/account-info-bar-container'
import CallAnytime from '../../myaccount_header/call-anytime'
import AddBankFormik from './add-bank-formik'

import CheckDiagram from '../../../images/payment/check-diagram.png'
import CheckDiagramSpanish from '../../../images/payment/check-diagram-es.png'


const AddBankInfoContainer = ({t, i18n}) => {
    const checkDiagramToShow = (i18n.language==='es' ? CheckDiagramSpanish : CheckDiagram)

    return (
        <DocumentTitle title={t("Add bank account | SRP Power")}>
            <div className="container py-3">
                <CallAnytime />
                <h3 className="mt-3">{t("Add bank account")}</h3>

                <AccountInfoBarContainer disableSelector={false} />

                <div className="row">
                    <div className="col-lg-6 col-12 mt-4">
                        <div className="srp-card-header">{t("Enter your bank information")}</div>
                        <div className="srp-card-body">
                            <div className="srp-card-details">
                                <AddBankFormik t={t} i18n={i18n} />
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6 mt-4 d-lg-block d-none">
                        <div className="srp-card-header">{t("Check diagram")}</div>
                        <div className="srp-card-body">
                            <div className="srp-card-details">
                                <img className="img-fluid center-block d-lg-block d-none" src={checkDiagramToShow} />
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </DocumentTitle>
    )

}

AddBankInfoContainer.propTypes = {
    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired,
}

export default withTranslation("addUpdateBank")(AddBankInfoContainer)