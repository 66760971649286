export const economyPricePlanConstants = {
    SUBMIT_EPP_FORM_REQUEST: "SUBMIT_EPP_FORM_REQUEST",
    SUBMIT_EPP_FORM_SUCCESS: "SUBMIT_EPP_FORM_SUCCESS",
    SUBMIT_EPP_FORM_FAILURE: "SUBMIT_EPP_FORM_FAILURE",

    GENERATE_EPP_APP_URL_REQUEST: "GENERATE_EPP_APP_URL_REQUEST",
    GENERATE_EPP_APP_URL_SUCCESS: "GENERATE_EPP_APP_URL_SUCCESS",
    GENERATE_EPP_APP_URL_FAILURE: "GENERATE_EPP_APP_URL_FAILURE",

    ECONOMY_PRICE_PLAN_SIGNUP: 0,
}