import { billPageConstants } from '../../../constants/bill-page-constants'
import { accountHistoryConstants } from '../../../constants/account-history-constants'
import { billAccountConstants } from '../../../constants/bill-account-constants'
import { DateTime } from 'luxon'

const initialState = {
    billToCompare: 0,
    billChipInfo: []
}

export default function compareBillReducer (state = initialState, action) {
    switch (action.type) {
        case billPageConstants.ADD_BILL_TO_COMPARE: {
            let billChips = Array.from(state.billChipInfo)
            const chipToAdd = action.chip
            return {
                ...state,
                billToCompare: 0,
                billChipInfo: billChips.indexOf(chipToAdd) === -1 ? billChips.concat(chipToAdd) : Array.from(state.billChipInfo)
            }
        }
        case billPageConstants.REMOVE_BILL_FROM_COMPARE: {
            let newBillChips = Array.from(state.billChipInfo)
            if(newBillChips.indexOf(action.billToDelete) === 0) {
                return { ...state, billChipInfo: newBillChips }
            }
            const chipToDelete = newBillChips.indexOf(action.billToDelete)
            newBillChips.splice(chipToDelete, 1)
            return { ...state, billChipInfo: newBillChips }
        }
        case accountHistoryConstants.GET_ACCOUNT_HISTORY_SUCCESS:
            return {
                ...state,
                billChipInfo: Object.keys(action.payload.accountHistoryList).length === 0
                    ? Array.from(initialState.billChipInfo)
                    : getDefaultBillsToCompare(action.payload.accountHistoryList)
            }
        case billAccountConstants.SELECT_BILL_ACCOUNT_SUCCESS:
            return { ...initialState }
        default:
            return state
    }
}

function getDefaultBillsToCompare(accountHistory) {
    let bills = [accountHistory[0]]
    let currentMonth = DateTime.fromISO(accountHistory[0].billGeneratedDate).month
    let currentYear = DateTime.fromISO(accountHistory[0].billGeneratedDate).year

    if(Object.keys(accountHistory).length > 1) {
        bills.push(accountHistory[1])
    }

    for(let i=0; i < accountHistory.length; i++) {
        let pastMonth = DateTime.fromISO(accountHistory[i].billGeneratedDate).month
        let pastYear = DateTime.fromISO(accountHistory[i].billGeneratedDate).year
        if((currentMonth === pastMonth) && (currentYear-1 === pastYear)) {
            return bills.concat(accountHistory[i])
        }
    }

    return bills
}