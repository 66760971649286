import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import Dialog from '@mui/material/Dialog'
import CircularProgress from '@mui/material/CircularProgress'

import { withTranslation } from 'react-i18next'

import { removeSafetyNet } from '../../../actions/auth/forms/safety-net-actions'

import * as loadingStatus from '../../../constants/loading-status-constants'

class DeleteSafetyNetDialog extends React.Component {
    constructor(props) {
        super(props)
    }

    componentDidUpdate(prevProps) {
        if(prevProps.deleteSafetyNetStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS) {
            this.props.handleDialog(false)
        }
    }

    render() {
        const t = this.props.t

        return (
            <Dialog
                modal="true"
                open={this.props.showDialog}
                PaperProps={{ className: "srp-modal" }}
            >
                <div className="srp-modal-body">
                    <div className="srp-modal-close" onClick={() => this.props.handleDialog(false)} />
                    <div>
                        <div className="srp-modal-header">
                            <h4 className="srp-modal-title">{t("Remove Safety Net partner?")}</h4>
                        </div>
                        <div>{t("This individual will no longer be notified when your bill becomes past due.")}</div>
                        <div className="srp-modal-footer mb-0">
                            <div className="d-flex flex-wrap justify-content-end">
                                <button className="btn srp-btn btn-lightblue mb-2" onClick={() => this.props.handleDialog(false)}>{t("No")}</button>
                                <button
                                    className="btn srp-btn btn-blue ml-2 mb-2"
                                    disabled={this.props.deleteSafetyNetStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS}
                                    onClick={() => {this.props.removeSafetyNetPartner(this.props.billAccount, this.props.safetyNetDetails, this.props.customerNames, this.props.emailAddress)}}>
                                    {this.props.deleteSafetyNetStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS
                                        ? <CircularProgress size={20} thickness={5} style={{ color: 'white' }} />
                                        : t("Yes, remove partner")
                                    }
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>
        )
    }

}

DeleteSafetyNetDialog.propTypes = {
    showDialog: PropTypes.bool,
    handleDialog: PropTypes.func,
    billAccount: PropTypes.number,
    customerNames: PropTypes.object,
    emailAddress: PropTypes.string,
    safetyNetDetails: PropTypes.object,
    removeSafetyNetPartner: PropTypes.func,
    deleteSafetyNetStatus: PropTypes.string,

    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired,
}

const mapStateToProps = state => {
    return {
        billAccount: state.accountInfo.billAccount.selectedBillAccount,
        customerNames: state.accountInfo.billAccount.customerNames,
        emailAddress: state.accountInfo.contact.primaryEmail,
        safetyNetDetails: state.accountInfo.payment.enrolledProgramsDetails.safetyNet,
        ...state.safetyNet
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        removeSafetyNetPartner: (billAccount, safetyNetDetails, customerNames, emailAddress) => {
            let removeSafetyNetDetails = {
                billAccount: billAccount,
                emailAddress: emailAddress,
                firstName: customerNames.firstName,
                safetyNetAddress: {
                    streetAddress: safetyNetDetails.streetAddress,
                    state: safetyNetDetails.state,
                    city: safetyNetDetails.city,
                    zipCode: safetyNetDetails.zipCode,
                    mailNameLineOne: `${customerNames.firstName} ${customerNames.lastName}`,
                    mailNameLineTwo: safetyNetDetails.safetyNetsName
                },
                preferredLanguage: "en"
            }
            dispatch(removeSafetyNet(removeSafetyNetDetails))
        }
    }
}

export default withTranslation('safetyNet')(connect(mapStateToProps, mapDispatchToProps)(DeleteSafetyNetDialog))