import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

const PurchaseHistoryDocs = ({t}) => {
    let descriptiveText = t("Print out your account purchases.")
    let linkName = t("Purchase History")
    return (
        <div style={{ paddingTop: '5px' }}>
            <Link to="/myaccount/bill"><h3>{linkName}</h3></Link>
            <p className="text-muted">{descriptiveText}</p>
        </div>
    )
}

PurchaseHistoryDocs.propTypes = {
    t: PropTypes.func
}

export default PurchaseHistoryDocs