import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Dialog from '@mui/material/Dialog'
import format from '../../srp_modules/format'
import CircularProgress from '@mui/material/CircularProgress'
import { withTranslation } from 'react-i18next'

import { addSnackbarMessage } from '../../actions/common/snackbar'
import * as loadingStatus  from '../../constants/loading-status-constants'
import { getTransferredAccountStatus, attachTransferredAccount, getBillAccounts, selectBillAccountOnChange, dismissTransferredAccountPrompt } from '../../actions/auth/bill_account/bill-account-actions'

class TransferredAccount extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            displayModal: this.getDisplayStatus(),
            isSubmitting: false
        }

        this.onCloseClick = this.onCloseClick.bind(this)
        this.attachTransferredAccount = this.attachTransferredAccount.bind(this)
        this.getDisplayOpenStatus = this.getDisplayStatus.bind(this)
    }

    componentDidMount() {
        if (this.props.billAccountListStatus === loadingStatus.LOADING_STATUS_SUCCESS && this.props.transferredAccountStatusStatus === loadingStatus.LOADING_STATUS_INIT && this.props.selectedBillAccount !== 0) {
            this.props.getTransferredAccountStatus(this.props.selectedBillAccount)
        }
    }
    componentDidUpdate(prevProps) {
        if (this.props.selectedBillAccount !== prevProps.selectedBillAccount && this.props.selectedBillAccount !== 0) {
            this.props.getTransferredAccountStatus(this.props.selectedBillAccount)
        }

        if (this.props.transferredAccountStatus.isTransferredAccount !== prevProps.transferredAccountStatus.isTransferredAccount ||
            this.props.transferredAccountAlreadyAdded !== prevProps.transferredAccountAlreadyAdded) {
                // eslint-disable-next-line react/no-did-update-set-state
                this.setState({ displayModal: this.getDisplayStatus() })
            }
    }

    getDisplayStatus() {
        return this.props.transferredAccountStatus.isTransferredAccount && !this.props.transferredAccountAlreadyAdded && !this.props.transferredAccountSupressDisplay
    }

    onCloseClick () {
        this.props.dismissTransferredAccountPrompt()
        this.setState({displayModal: false})
    }

    async attachTransferredAccount() {
        this.setState({isSubmitting: true})
        await this.props.attachTransferredAccount(this.props.transferredAccountStatus.newBillAccount)
        this.setState({displayModal: false, isSubmitting: false})
    }

    render () {
        let welcomeText = this.props.t('Welcome back')
        let formattedAccountNumber = format.formatBillAccountNumber(this.props.transferredAccountStatus.newBillAccount)
        let streetAddress = this.props.transferredAccountStatus.newServiceAddress ? this.props.transferredAccountStatus.newServiceAddress.streetAddress : ""
        let cityStateZip = this.props.transferredAccountStatus.newServiceAddress ? this.props.transferredAccountStatus.newServiceAddress.cityStateZip : ""

        if (this.props.firstName) {
            welcomeText += `, ${this.props.firstName}`
        }
        return (
            <Dialog
                modal="true"
                open={this.state.displayModal}
                PaperProps={{className:"srp-modal"}}
            >
                <div className="srp-modal-body">
                    <div>
                        <div className="srp-modal-header">
                            <h4 className="srp-modal-title">{welcomeText}</h4>
                        </div>
                        <div>
                            {this.props.t('found_newer_account')}<br />
                            <div className="mt-2"><b>{`${this.props.t('Bill account')}:`}</b></div>
                            <div className="mb-2">{formattedAccountNumber}</div>
                            <div><b>{`${this.props.t('Service address')}:`}</b></div>
                            <div>{streetAddress}</div>
                            <div>{cityStateZip}</div>
                            <div className="srp-modal-footer">
                                <button aria-label="not now"
                                    disabled={this.state.isSubmitting}
                                    className="btn srp-btn btn-lightblue ml-2 mb-1"
                                    onClick={this.onCloseClick}
                                >
                                        {this.props.t('Not now')}
                                </button>
                                <button aria-label="add account"
                                    disabled={this.state.isSubmitting}
                                    className="btn srp-btn btn-lightblue ml-2 mb-1"
                                    onClick={this.attachTransferredAccount}
                                    style={{width: "120px"}}
                                >
                                        {this.state.isSubmitting ? <CircularProgress size={20} thickness={5}/> : this.props.t('Add account')}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

            </Dialog>
        )
    }
}
const mapStateToProps = state => {
    return {
        billAccountListStatus: state.accountInfo.billAccount.billAccountListStatus,
        selectedBillAccount: state.accountInfo.billAccount.selectedBillAccount,
        transferredAccountStatus: state.accountInfo.billAccount.transferredAccountStatus,
        transferredAccountAlreadyAdded: state.accountInfo.billAccount.transferredAccountAlreadyAdded,
        transferredAccountStatusStatus: state.accountInfo.billAccount.transferredAccountStatusStatus,
        transferredAccountSupressDisplay: state.accountInfo.billAccount.transferredAccountSupressDisplay,
        firstName: state.rateMetaData.rateMetaData.firstName }
}

const mapDispatchToProps = dispatch => {
    return {
        getTransferredAccountStatus: (billAccount) => {
            dispatch(getTransferredAccountStatus(billAccount))
        },
        dismissTransferredAccountPrompt: () => {
            dispatch(dismissTransferredAccountPrompt())
        },
        attachTransferredAccount: async (billAccount) => {
            let addAccountModel = [{
                billAccount: billAccount,
                nickName: ""
            }]

            let result = await dispatch(attachTransferredAccount(addAccountModel))
            if (result.error) {
            dispatch(addSnackbarMessage(<span>Add account failed</span>))
            } else if (result.payload.success) {
            await dispatch(getBillAccounts())
            dispatch(selectBillAccountOnChange(billAccount))
            } else {
            dispatch(addSnackbarMessage(<span>Add account failed</span>))
            }
        }
    }
}

TransferredAccount.propTypes = {
    getTransferredAccountStatus: PropTypes.func,
    dismissTransferredAccountPrompt: PropTypes.func,
    attachTransferredAccount: PropTypes.func,
    billAccountListStatus: PropTypes.string,
    selectedBillAccount: PropTypes.number,
    transferredAccountStatus: PropTypes.object,
    transferredAccountAlreadyAdded: PropTypes.bool,
    transferredAccountStatusStatus: PropTypes.string,
    transferredAccountSupressDisplay: PropTypes.bool,
    firstName: PropTypes.string,
    t: PropTypes.func.isRequired
}

export default withTranslation('transferServiceModal')(connect(mapStateToProps, mapDispatchToProps)(TransferredAccount))
