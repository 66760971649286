import React from "react"
import PropTypes from 'prop-types'

import bankIconGrey from '../../../images/payment/bank-grey.png'
import bankIconWhite from '../../../images/payment/bank-white.png'

const iconSize = {
    height: "32px", width:"32px"
}

const mobileIconSize = {
    height: "24px", width:"24px"
}

const PayUsingBankAccountDiv = ({t}) => (
    <div>
        <div className="d-none d-lg-block">
            <div className="nav-link flex-column align-items-center active">
                <img src={bankIconGrey} style={iconSize} className="icon" />
                <img src={bankIconWhite} style={iconSize} className="iconSelected" />
                <div>{t("Bank account")}</div>
            </div>
        </div>
        <div className="d-block d-lg-none">
            <div
                className="nav-link nav-link-mobile d-flex justify-content-start align-items-center p-1 active">
                <img src={bankIconGrey} style={mobileIconSize} className="icon ml-1 mr-2" />
                <img src={bankIconWhite} style={mobileIconSize} className="iconSelected ml-1 mr-2" />
                <span>{t("Bank account")}</span>
            </div>
        </div>
    </div>
)

PayUsingBankAccountDiv.propTypes = {
    t:  PropTypes.func.isRequired,
}

export default PayUsingBankAccountDiv