import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'

import { DateTime } from 'luxon'

import HelpCard from '../help'
import { formConstants } from '../../../constants/form-constants'
import {
  changeRatePlan, changeSolarChoice, paperlessBillingChange, isMailingAddressChange, carMakeChange,
  startDateChange, dueDateChange, carModelChange, carYearChange, vehicleOwnershipChange,
  address1Change, changeSupplyEquipment, changeInputVoltage, changeCurrentSetting, changeFirstName,
  changeMiddleName, changeLastName, changeIDType, changeIDNumber, changePreferredLang, changeEmail,
  changePhoneNumber, changeAuthSpouse, changeSpouseFirstName, changeSpouseLastname, changeAddress2, runRequiredValidationCheck,
  cityChange, changeAgreementToSafety, submitTurnOn,
  changeMailingAddress1, changeMailingAddress2, changeMailingCity, changeMailingState, changeMailingCountry,
  changeMailingZipcode, getHolidays, refreshStartForm
} from '../../../actions/guest/form/turn-on-actions'
import { getContacts } from '../../../actions/auth/contact/contact-actions'
import { getCustomerNames } from '../../../actions/auth/bill_account/bill-account-actions'
import { savePreferredLanguage } from '../../../actions/auth/myaccount/myaccount-actions'

import { Formik } from 'formik'
import AnalyticsFormTracking from '../../common_formik/analytics-form-tracking'

import AboutYou from './about-you'
import Safety from './safety'
import EstimatedCost from './estimated-cost'
import Dialog from '@mui/material/Dialog'
import AccountInfoBarContainer from '../../myaccount_header/info_bar/account-info-bar-container'
import CircularProgress from '@mui/material/CircularProgress'
import validate from '../../../srp_modules/validate'
import * as loadingStatus from '../../../constants/loading-status-constants'

import transferModule from '../../../srp_modules/forms/transfer-module'

import {
  getPlanDescription, getSolarChoiceDescription, getCustomDueDate, getLanguageDescription,
  getOwnershipType, mapChargingSupplyType, mapChargingVoltage, mapChargingAmps
} from '../../../srp_modules/forms/turnon-mapper'

import SelectDatePlan from './select-date-plan'
import ServiceDetails from './service-details'
import YouAreAllSet from './you-are-all-set'

let progressCirclestyle = {
  marginTop: "10%",
  marginBottom: "10%"
}

class StartServicePage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isConfirmationShown: false,
      isServiceDatesShown: true,
      isAboutYouShown: false,
      isSolarPopupShown: false,
      isSafetyShown: false,
      selectedPlan: formConstants.TIME_OF_USE,
      confirmPhone: '',
      confirmEmail: '',
      currentStep: 0,
      agreementError: false,
      errorMessage: '',
      formLanguage: this.props.i18n.language,
    }

    this.displayCardBody = this.displayCardBody.bind(this)
    this.cardBodyWithFormik = this.cardBodyWithFormik.bind(this)

    this.validateSafety = this.validateSafety.bind(this)

    this.setSelectedPlan = this.setSelectedPlan.bind(this)
    this.goToServiceDetails = this.goToServiceDetails.bind(this)
    this.toggleSolarPopup = this.toggleSolarPopup.bind(this)

    this.goBackToServiceDates = this.goBackToServiceDates.bind(this)
    this.goToAboutYou = this.goToAboutYou.bind(this)
    this.submitStartServiceForm = this.submitStartServiceForm.bind(this)

    this.getCurrentStep = this.getCurrentStep.bind(this)
    this.getMetaData = this.getMetaData.bind(this)
  }

  componentDidMount() {
    this.props.getHolidays()

    if (!this.props.isLoggedIn) {
      let language = this.props.myAccountPreferredLanguage
      if (language === "es") {
        this.props.preferredLanguageChange(formConstants.SPANISH)
      }
    }

    if (this.props.selectedRawBillAccount === 0)
      return

    if (this.props.contactsStatus === loadingStatus.LOADING_STATUS_INIT
      || this.props.contactsStatus === loadingStatus.LOADING_STATUS_FAILURE) {
      this.props.loadContactInfo(this.props.selectedRawBillAccount)
    }
    if (this.props.customerNamesStatus === loadingStatus.LOADING_STATUS_INIT
      || this.props.customerNamesStatus === loadingStatus.LOADING_STATUS_FAILURE) {
      this.props.loadCustomerName(this.props.selectedRawBillAccount)
    }
  }

  UNSAFE_componentWillUpdate(nextProps) {
    if (this.props.myAccountPreferredLanguage !== nextProps.myAccountPreferredLanguage) {
      this.setState({ formLanguage: nextProps.myAccountPreferredLanguage })
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.selectedRawBillAccount === prevProps.selectedRawBillAccount || this.props.selectedRawBillAccount === 0)
      return

    if (this.props.contactsStatus !== loadingStatus.LOADING_STATUS_IN_PROGRESS) {
      this.props.loadContactInfo(this.props.selectedRawBillAccount)
    }

    if (this.props.customerNamesStatus !== loadingStatus.LOADING_STATUS_IN_PROGRESS) {
      this.props.loadCustomerName(this.props.selectedRawBillAccount)
    }
  }

  updateConfirmEmail(email) {
    this.setState({ confirmEmail: email })
  }

  updateConfirmPhone(phone) {
    this.setState({ confirmPhone: phone })
  }

  setSelectedPlan(newPlan) {
    if (newPlan === formConstants.MPOWER) {
      this.props.selectSolarChoiceFunc(formConstants.SOLARCHOICE_NONE)
    }
    this.setState({ selectedPlan: newPlan })
    // TODO: Reset plan change with only the redux state
    this.props.planChange(newPlan)
  }

  toggleSolarPopup(event) {
    event.preventDefault()
    this.setState({ isSolarPopupShown: !this.state.isSolarPopupShown })
  }

  goToServiceDetails(currentStep) {
    this.setState({ isServiceDatesShown: false })
    this.setState({ isServiceDetailsShown: true })
    this.setState({ isAboutYouShown: false })
    this.setState({ isSafetyShown: false })
    this.setState({ isConfirmationShown: false })
    this.setState({ currentStep: currentStep })
    backToTop("form-heading")
  }

  goBackToServiceDates() {
    this.setState({ isServiceDatesShown: true })
    this.setState({ isServiceDetailsShown: false })
    this.setState({ isAboutYouShown: false })
    this.setState({ isSafetyShown: false })
    this.setState({ isConfirmationShown: false })
    this.setState({ currentStep: 0 })
  }

  validateAddress() {
    let valid = true

    if (this.props.selectedPlan !== formConstants.MPOWER) {
      if (this.props.dayOfMonth === 0) {
        this.props.runDetailsValidation(true)
        valid = false
      }
    }

    if (this.props.address1.length === 0
      || this.props.city.length === 0) {
      this.props.runDetailsValidation(true)
      valid = false
    }

    if (!this.props.isMailingAddressSame) {
      if (this.props.mailingAddress1.length === 0
        || this.props.mailingCity.length === 0
        || this.props.mailingState.length === 0
        || this.props.mailingZipCode.length === 0) {
        this.props.runDetailsValidation(true)
        valid = false
      }
    }

    return valid
  }

  validateEV() {
    if (this.props.selectedPlan === formConstants.ELECTRIC_VEHICLE) {
      if (((this.props.carYear).length > 0 && (this.props.carYear).length < 4))
        return false
    }

    return true
  }

  validateAboutYou() {
    if (this.props.firstName.length === 0 ||
      this.props.lastName.length === 0 ||
      this.props.identificationNumber.length === 0 ||
      !this.isValidSsnItin() ||
      this.props.email.length === 0 ||
      !validate.emailAddress(this.props.email) ||
      this.props.phoneNumber.length === 0 ||
      this.state.confirmPhone.length === 0 ||
      this.state.confirmEmail.length === 0) {
      this.props.runDetailsValidation(true)
      return false
    }

    return true
  }

  isValidSsnItin() {
    if (this.props.identificationType === formConstants.SOCIAL || this.props.identificationType === formConstants.ITIN) {
      if (this.props.identificationNumber < 9) {
        return false
      }
    }

    return true
  }

  validateSafety(hasAgreedToSafety) {
    let safetyValid = (hasAgreedToSafety === true)
    this.setState({
      agreementError: !safetyValid
    })
    return safetyValid
  }

  setPreferredLanguage(preferredLanguage) {
    this.props.preferredLanguageChange(preferredLanguage)
    let newLang = preferredLanguage === formConstants.SPANISH ? "es" : "en"

    this.props.savePreferredLanguage(newLang)
    this.setState({
      formLanguage: newLang,
    })
  }

  goToAboutYou(currentStep) {
    if (!this.validateAddress() || !this.validateEV()) return
    this.setState({ isServiceDatesShown: false })
    this.setState({ isServiceDetailsShown: false })
    this.setState({ isAboutYouShown: true })
    this.setState({ isSafetyShown: false })
    this.setState({ isConfirmationShown: false })
    this.setState({ currentStep: currentStep })
    backToTop("form-heading")
  }

  goToSafety(currentStep) {
    if (!this.validateAboutYou()) return
    this.setState({ isServiceDatesShown: false })
    this.setState({ isServiceDetailsShown: false })
    this.setState({ isAboutYouShown: false })
    this.setState({ isSafetyShown: true })
    this.setState({ isConfirmationShown: false })
    this.setState({ currentStep: currentStep })
    backToTop("form-heading")
  }

  goToConfirmation() {
    this.setState({ isServiceDatesShown: false })
    this.setState({ isServiceDetailsShown: false })
    this.setState({ isAboutYouShown: false })
    this.setState({ isSafetyShown: false })
    this.setState({ isConfirmationShown: true })
    backToTop("form-heading")
  }

  async submitStartServiceForm(_, formikActions) {
    const result = await this.props.submitTurnonDetails()

    const submittedSuccessfully = (result.payload?.success === true)

    formikActions.setStatus({ submitError: !submittedSuccessfully })
    formikActions.setSubmitting(false)

    if (submittedSuccessfully) {
      this.goToConfirmation()
    }
    else {
      if (result.payload?.message === 'Turn on date is not valid') {
        this.setState({ errorMessage: 'something_went_wrong_invalid_date' })
        this.goBackToServiceDates()
      }
      else {
        backToTop("form-heading")
      }
    }
  }

  displayCardBody(currentStepName, values, setFieldValue, handleSubmit) {
    if (currentStepName === "select dates/plan") {
      return (<SelectDatePlan
        startDate={this.props.startDate}
        errorMessage={this.state.errorMessage}
        holidayList={this.props.holidayList}
        planDateChange={(newStartDate) => { setFieldValue('startDate', newStartDate); this.props.planDateChange(newStartDate.toJSDate()) }}
        onDateInitialized={this.props.planDateChange}
        setSelectedPlan={(newPlan) => { setFieldValue('selectedPlan', newPlan); this.setSelectedPlan(newPlan) }}
        selectedPlan={this.state.selectedPlan}
        currentStep={this.state.currentStep}
        toggleSolarPopup={this.toggleSolarPopup}
        goToServiceDetails={this.goToServiceDetails}

        isLoggedIn={this.props.isLoggedIn}
        selectSolarChoiceFunc={(solarChoice) => {
          setFieldValue('solarChoice', solarChoice === this.props.selectedSolarChoicePlan
            ? formConstants.SOLARCHOICE_NONE
            : solarChoice)
          this.props.selectSolarChoiceFunc(solarChoice)
        }}
        selectedSolarChoicePlan={this.props.selectedSolarChoicePlan}
        isSolarChoiceDisabled={this.state.selectedPlan === formConstants.MPOWER}
      />)
    }

    if (currentStepName === "turn on details") {
      return (<ServiceDetails
        selectedPlan={this.state.selectedPlan}
        currentStep={this.state.currentStep}
        goBackToServiceDates={this.goBackToServiceDates}
        goToAboutYou={this.goToAboutYou}
        {...this.props}
      />)
    }

    if (currentStepName === "about you") {
      return (<div>
        <AboutYou
          {...this.props}
          goToServiceDetails={() => this.goToServiceDetails(this.state.currentStep - 1)}
          goToSafety={() => this.goToSafety(this.state.currentStep + 1)}
          confirmPhone={this.state.confirmPhone}
          confirmEmail={this.state.confirmEmail}
          updateConfirmEmail={this.updateConfirmEmail.bind(this)}
          updateConfirmPhone={this.updateConfirmPhone.bind(this)}
          currentStep={this.state.currentStep}
        />
      </div>)
    }

    if (currentStepName === "safety") {
      return (<div>
        {this.props.showSubmitError &&
          <div className="srp-alert-error mb-4">{this.props.t("error_text")}</div>
        }
        <Safety
          {...this.props}
          agreementError={this.state.agreementError}
          currentStep={this.state.currentStep}
          validateSafety={this.validateSafety}
          goToAboutYou={this.goToAboutYou}
          submitStartServiceForm={handleSubmit}
        />
      </div>)
    }

    if (currentStepName === "you're all set") {
      return (<YouAreAllSet isLoggedIn={this.props.isLoggedIn}
        refreshForm={this.props.refreshForm} />)
    }

    return null
  }

  getCurrentStep() {
    if (this.state.isServiceDatesShown)
      return "select dates/plan"

    if (this.state.isServiceDetailsShown)
      return "turn on details"

    if (this.state.isAboutYouShown)
      return "about you"

    if (this.state.isSafetyShown && !this.props.submitSucceeded)
      return "safety"

    if (this.props.submitSucceeded)
      return "you're all set"

    return "unknown step"
  }

  getMetaData() {
    return {
      loggedIn: this.props.isLoggedIn,
      serviceStartDate: DateTime.fromISO(this.props.startDate).toFormat('yyyy-MM-dd'),
      serviceStartDay: DateTime.fromISO(this.props.startDate).toFormat('EEEE'),
      ratePlan: getPlanDescription(this.props.selectedPlan),
      solarChoice: getSolarChoiceDescription(this.props.selectedSolarChoicePlan),
      serviceAndMailingAddressSame: this.props.isMailingAddressSame,
      paperlessBilling: this.props.isPaperlessBilling,
      customDueDate: getCustomDueDate(this.props.dayOfMonth, this.props.selectedPlan),
      evDetails: (this.props.selectedPlan === formConstants.ELECTRIC_VEHICLE)
        ?
        {
          make: this.props.carMake,
          model: this.props.carModel,
          year: this.props.carYear,
          ownershipType: getOwnershipType(this.props.ownershipType),
          supplyEquipmentType: mapChargingSupplyType(this.props.supplyEquipment),
          inputVoltage: mapChargingVoltage(this.props.inputVoltage),
          inputCurrent: mapChargingAmps(this.props.inputCurrent),
        }
        :
        {},
      idType: transferModule.getIDTypeDisplay(this.props.identificationType),
      preferredLanguage: getLanguageDescription(this.props.preferredLanguage),
      spouseAuthorized: this.props.authorizeSpouse,
    }
  }

  cardBodyWithFormik() {
    const initValues = {
      startDate: this.props.startDate,
      selectedPlan: this.state.selectedPlan,
      solarChoice: this.props.selectedSolarChoicePlan,
    }

    return (
      <Formik initialValues={initValues} onSubmit={this.submitStartServiceForm}>
        {({ values, setFieldValue, handleSubmit }) => {

          const currentStepName = this.getCurrentStep()
          const meta = (currentStepName === "you're all set") ? this.getMetaData() : {}

          return (
            <React.Fragment>
              <AnalyticsFormTracking name="Web Turnon" formStep={currentStepName} meta={meta} />
              {this.displayCardBody(currentStepName, values, setFieldValue, handleSubmit)}
            </React.Fragment>
          )
        }}
      </Formik>
    )
  }

  render() {
    if (this.props.isLoggedIn
      && (this.props.contactsStatus === loadingStatus.LOADING_STATUS_INIT || this.props.contactsStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS
        || this.props.customerNamesStatus === loadingStatus.LOADING_STATUS_INIT || this.props.customerNamesStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS)) {
      return (
        <div>
          <div className="container py-3">
            <h3 className="mt-3">{this.props.t("start_service_header")}</h3>
            <div className="py-3">
              {this.props.isLoggedIn &&
                <AccountInfoBarContainer disableSelector={true} />
              }
            </div>
            <div className="d-flex justify-content-center"><CircularProgress size={80} thickness={5} style={progressCirclestyle} /></div>
          </div>
        </div>
      )
    }
    return (
      <div>
        <div className="container py-3">
          <h3 className="mt-3">{this.props.t("start_service_header")}</h3>
          <div className="py-3">
            {this.props.isLoggedIn &&
              <AccountInfoBarContainer disableSelector={true} />
            }
          </div>
          <div className="d-lg-block">
            <div className="row">
              <div className="col-lg-7 mt-4">
                <div className="srp-card-header" id="form-heading">
                  <span style={this.state.isServiceDatesShown ? {} : { display: 'none' }}>{this.props.t("provide_account_details")}</span>
                  <span style={this.state.isServiceDetailsShown ? {} : { display: 'none' }}>{this.props.t("where_power")}</span>
                  <span style={this.state.isAboutYouShown ? {} : { display: 'none' }}>{this.props.t("tell_us_about_yourself")}</span>
                  <span style={this.state.isSafetyShown ? {} : { display: 'none' }}>{this.props.t("make_sure_right")}</span>
                  <span style={this.state.isConfirmationShown ? {} : { display: 'none' }}>{this.props.t("request_submitted")}</span>
                </div>
                <div className="srp-card-body">
                  <div className="srp-card-details">
                    {this.cardBodyWithFormik()}
                  </div>
                </div>
              </div>
              <div className="col-lg-5 mt-4">
                {!this.state.isSafetyShown && !this.state.isConfirmationShown &&
                  (<React.Fragment>
                    <HelpCard
                      displaySpanish={true}
                    />
                  </React.Fragment>)}

                {this.state.isSafetyShown && this.props.selectedPlan !== formConstants.MPOWER && !this.state.isConfirmationShown &&
                  <EstimatedCost
                    {...this.props}
                  />}
              </div>
            </div>
          </div>
        </div>
        <Dialog
          modal="true"
          open={this.state.isSolarPopupShown}
          PaperProps={{ className: "srp-modal" }}>
          <div className="srp-modal-body">
            <div className="srp-modal-close" onClick={(e) => this.toggleSolarPopup(e)} />
            <div>
              <div className="srp-modal-header">
                <h4 className="srp-modal-title">{this.props.t("customer_generation")}</h4>
              </div>
              <div>
                {this.props.t("customer_generation_contact")}
                <div className="srp-modal-footer">
                  <button className="btn srp-btn btn-blue" onClick={(e) => this.toggleSolarPopup(e)}>{this.props.t("OK")}</button>
                </div>
              </div>
            </div>
          </div>
        </Dialog>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    // NOTE: ...state.turnOn includes a preferredLanguage variable,
    //this is NOT related to the i18n or the myAccountPreferredLanguage
    ...state.turnOn,
    isLoggedIn: state.login.isLoggedIn,
    contactsStatus: state.accountInfo.contact.contactsStatus,
    customerNamesStatus: state.accountInfo.billAccount.customerNamesStatus,
    selectedRawBillAccount: state.accountInfo.billAccount.selectedBillAccount,
    // NOTE #2: intentionally mapping this to displayedLanguage so this page uses the i18n language
    myAccountPreferredLanguage: state.myAccount.displayedLanguage
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    planChange: (newPlan) => dispatch(changeRatePlan(newPlan)),
    selectSolarChoiceFunc: (solarChoice) => dispatch(changeSolarChoice(solarChoice)),
    planDateChange: (newStartDate) => dispatch(startDateChange(newStartDate)),
    address1Change: (newAddress) => dispatch(address1Change(newAddress)),
    address2Change: (newAddress) => dispatch(changeAddress2(newAddress)),
    cityChange: (newCity) => dispatch(cityChange(newCity)),
    paperlessBillingChange: (isPaperless) => dispatch(paperlessBillingChange(isPaperless)),
    mailingAddressAsServiceChange: (newSetting) => dispatch(isMailingAddressChange(newSetting)),
    setCustomDueDate: (dayOfMonth) => dispatch(dueDateChange(dayOfMonth)),
    carMakeChange: (newCarMake) => dispatch(carMakeChange(newCarMake)),
    carModelChange: (newCarModel) => dispatch(carModelChange(newCarModel)),
    carYearChange: (newCarYear) => dispatch(carYearChange(newCarYear)),
    vehicleOwnershipChange: (newOwnership) => dispatch(vehicleOwnershipChange(newOwnership)),
    supplyEquipmentChange: (newEquipment) => dispatch(changeSupplyEquipment(newEquipment)),
    inputVoltageChange: (newVoltage) => dispatch(changeInputVoltage(newVoltage)),
    inputCurrentChange: (newInputCurrent) => dispatch(changeCurrentSetting(newInputCurrent)),

    firstNameChange: (newName) => dispatch(changeFirstName(newName)),
    middleNameChange: (newMiddleName) => dispatch(changeMiddleName(newMiddleName)),
    lastNameChange: (newLastName) => dispatch(changeLastName(newLastName)),
    idTypeChange: (newIdType) => dispatch(changeIDType(newIdType)),
    idNumberChange: (newIDNumber) => dispatch(changeIDNumber(newIDNumber)),
    preferredLanguageChange: (newLang) => dispatch(changePreferredLang(newLang)),
    savePreferredLanguage: (preferredLanguage) => dispatch(savePreferredLanguage(preferredLanguage)),
    emailChange: (newEmail) => dispatch(changeEmail(newEmail)),
    phoneNumberChange: (newPhone) => dispatch(changePhoneNumber(newPhone)),
    authorizeSpouseChange: (newSetting) => dispatch(changeAuthSpouse(newSetting)),
    spouseFirstNameChange: (newName) => dispatch(changeSpouseFirstName(newName)),
    spouseLastNameChange: (newName) => dispatch(changeSpouseLastname(newName)),

    runDetailsValidation: (shouldRun) => dispatch(runRequiredValidationCheck(shouldRun)),
    agreeToSafety: (hasAgreed) => dispatch(changeAgreementToSafety(hasAgreed)),
    submitTurnonDetails: () => dispatch(submitTurnOn()),

    mailingAddress1Change: (address) => dispatch(changeMailingAddress1(address)),
    mailingAddress2Change: (address) => dispatch(changeMailingAddress2(address)),
    mailingCityChange: (city) => dispatch(changeMailingCity(city)),
    mailingStateChange: (state) => dispatch(changeMailingState(state)),
    mailingCountryChange: (country) => dispatch(changeMailingCountry(country)),
    mailingZipChange: (zip) => dispatch(changeMailingZipcode(zip)),

    getHolidays: () => dispatch(getHolidays()),
    loadCustomerName: (billAccount) => dispatch(getCustomerNames(billAccount)),
    loadContactInfo: (billAccount) => dispatch(getContacts(billAccount)),
    refreshForm: () => dispatch(refreshStartForm())
  }
}

StartServicePage.propTypes = {
  address1: PropTypes.string,
  authorizeSpouse: PropTypes.bool.isRequired,
  carMake: PropTypes.string.isRequired,
  carModel: PropTypes.string.isRequired,
  carYear: PropTypes.string,
  city: PropTypes.string,
  contactsStatus: PropTypes.string,
  customerNamesStatus: PropTypes.string,
  dayOfMonth: PropTypes.number,
  displayAboutYou: PropTypes.func,
  displayConfirmation: PropTypes.func,
  displaySafety: PropTypes.func,
  displayServiceDates: PropTypes.func,
  displayServiceDetails: PropTypes.func,
  selectSolarChoiceFunc: PropTypes.func,
  email: PropTypes.string,
  firstName: PropTypes.string,
  getHolidays: PropTypes.func,
  hasAgreedToSafety: PropTypes.bool,
  hasSubmitted: PropTypes.bool,
  holidayList: PropTypes.arrayOf(PropTypes.string),
  identificationType: PropTypes.string,
  identificationNumber: PropTypes.string,
  inputCurrent: PropTypes.string.isRequired,
  inputVoltage: PropTypes.string.isRequired,
  isAboutYouShown: PropTypes.bool,
  isConfirmationShown: PropTypes.bool,
  isLoggedIn: PropTypes.bool,
  isMailingAddressSame: PropTypes.bool,
  isPaperlessBilling: PropTypes.bool.isRequired,
  isSafetyShown: PropTypes.bool,
  isServiceDatesShown: PropTypes.bool,
  isServiceDetailsShown: PropTypes.bool,
  lastName: PropTypes.string,
  loadContactInfo: PropTypes.func,
  loadCustomerName: PropTypes.func,
  mailingAddress1: PropTypes.string,
  mailingCity: PropTypes.string,
  mailingCountry: PropTypes.string,
  mailingState: PropTypes.string,
  mailingZipCode: PropTypes.string,
  middleName: PropTypes.string,
  myAccountPreferredLanguage: PropTypes.string,
  ownershipType: PropTypes.string.isRequired,
  phoneNumber: PropTypes.string,
  planChange: PropTypes.func,
  planDateChange: PropTypes.func,
  preferredLanguage: PropTypes.string.isRequired,
  preferredLanguageChange: PropTypes.func,
  props: PropTypes.object,
  refreshForm: PropTypes.func,
  runDetailsValidation: PropTypes.func,
  savePreferredLanguage: PropTypes.func,
  selectedSolarChoicePlan: PropTypes.string,
  selectedPlan: PropTypes.string,
  selectedRawBillAccount: PropTypes.number,
  showSubmitError: PropTypes.bool,
  startDate: PropTypes.object,
  submitSucceeded: PropTypes.bool,
  submitTurnonDetails: PropTypes.func,
  supplyEquipment: PropTypes.string.isRequired,
  t: PropTypes.func,
  i18n: PropTypes.object,
}

export default withTranslation('startService')(connect(mapStateToProps, mapDispatchToProps)(StartServicePage))

function backToTop(elementId) {
  document.getElementById(elementId).scrollIntoView()
}