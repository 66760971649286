import React from 'react'
import CircularProgress from '@mui/material/CircularProgress'

import SrpLogo from '../images/nav/srp-logo.svg'

const InitialLoadingPage = () => {
    return (

        <div className="loading-page-h">
            <div className="d-flex justify-content-center align-items-center flex-column h-100">
                <div className="mb-4">
                    <img
                        className="srp-logo-loading-w"
                        src={SrpLogo} />
                </div>
                <div className="md-24 fw-700 mt-4 mb-4">Loading...</div>
                <CircularProgress size={60} thickness={4} />
            </div>
        </div>
    )
}

export default InitialLoadingPage