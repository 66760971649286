import React from 'react'
import PropTypes from 'prop-types'
import { Link, NavLink, useLocation } from 'react-router-dom'
import Divider from '@mui/material/Divider'
import Fade from '@mui/material/Fade'

import ActiveDashboardIcon from '../../../images/nav/nav_icons/black_icons/dashboard-black.svg'
import ActiveMyBillIcon from '../../../images/nav/nav_icons/black_icons/payment-black.svg'
import ActiveUsageIcon from '../../../images/nav/nav_icons/black_icons/usage-black.svg'
import ActiveOutageIcon from '../../../images/nav/nav_icons/black_icons/outages-black.svg'
import ActivePricePlansProgramsIcon from '../../../images/nav/nav_icons/black_icons/price-plan-programs-black.svg'
import ActiveProfileIcon from '../../../images/nav/nav_icons/black_icons/profile-black.svg'

import DashboardIcon from '../../../images/nav/nav_icons/blue_icons/dashboard-blue.svg'
import MyBillIcon from '../../../images/nav/nav_icons/blue_icons/payment-blue.svg'
import UsageIcon from '../../../images/nav/nav_icons/blue_icons/usage-blue.svg'
import OutageIcon from '../../../images/nav/nav_icons/blue_icons/outages-blue.svg'
import PricePlansProgramsIcon from '../../../images/nav/nav_icons/blue_icons/price-plan-programs-blue.svg'
import ProfileIcon from '../../../images/nav/nav_icons/blue_icons/profile-blue.svg'

import DashboardIconMobile from '../../../images/nav/nav_icons/white_icons/dashboard-white.svg'
import MyBillIconMobile from '../../../images/nav/nav_icons/white_icons/payment-white.svg'
import UsageIconMobile from '../../../images/nav/nav_icons/white_icons/usage-white.svg'
import OutageIconMobile from '../../../images/nav/nav_icons/white_icons/outages-white.svg'
import PricePlansProgramsIconMobile from '../../../images/nav/nav_icons/white_icons/price-plan-programs-white.svg'
import ProfileIconMobile from '../../../images/nav/nav_icons/white_icons/profile-white.svg'
import LogoutIconMobile from '../../../images/nav/nav_icons/white_icons/logout-white.svg'

const NavigationMenu = ({ selectedBillAccountDetails, responsiveNavStyle, responsiveNavOpen, closeResponsiveNavOnClick, t }) => {
    let locationHook = useLocation()

    let getMPowerNavItem = () => {
        if (selectedBillAccountDetails.isPrePay) {
            return ["My purchases", "View purchases, balances"]
        }
        else {
            return ["My bill", "View bills, account history"]
        }
    }

    let dashboard = ActiveDashboardIcon
    let bill = MyBillIcon
    let usage = UsageIcon
    let outage = OutageIcon
    let pricePlanPrograms = PricePlansProgramsIcon
    let profile = ProfileIcon

    let myAccountPageRegex = /^\/?myaccount\/([^/]+)/i
    let myAccountMatch = myAccountPageRegex.exec(locationHook.pathname)
    let pageName = (myAccountMatch !== null) ? myAccountMatch[1] : ''

    switch (pageName.toLowerCase()) {
        case 'dashboard':
            dashboard = ActiveDashboardIcon
            bill = MyBillIcon
            usage = UsageIcon
            outage = OutageIcon
            pricePlanPrograms = PricePlansProgramsIcon
            profile = ProfileIcon
            break;
        case 'bill':
            dashboard = DashboardIcon
            bill = ActiveMyBillIcon
            usage = UsageIcon
            outage = OutageIcon
            pricePlanPrograms = PricePlansProgramsIcon
            profile = ProfileIcon
            break;
        case 'usage':
            dashboard = DashboardIcon
            bill = MyBillIcon
            usage = ActiveUsageIcon
            outage = OutageIcon
            pricePlanPrograms = PricePlansProgramsIcon
            profile = ProfileIcon
            break;
        case 'outages':
            dashboard = DashboardIcon
            bill = MyBillIcon
            usage = UsageIcon
            outage = ActiveOutageIcon
            pricePlanPrograms = PricePlansProgramsIcon
            profile = ProfileIcon
            break;
        case 'programs':
            dashboard = DashboardIcon
            bill = MyBillIcon
            usage = UsageIcon
            outage = OutageIcon
            pricePlanPrograms = ActivePricePlansProgramsIcon
            profile = ProfileIcon
            break;
        case 'profile':
            dashboard = DashboardIcon
            bill = MyBillIcon
            usage = UsageIcon
            outage = OutageIcon
            pricePlanPrograms = PricePlansProgramsIcon
            profile = ActiveProfileIcon
            break;
        default:
            break;
    }

    let dashboardClass = ({isActive}) => {
        let baseClass = "nav-link d-flex flex-column align-items-center"
        return (isActive || locationHook.pathname === "/myaccount")? `${baseClass} active` : baseClass
    }

    return (
        <div className="d-print-none">
            <div className="d-none d-lg-block">
                <div className="row">
                    <ul className="myaccount-nav nav nav-filled align-items-end w-100 ">  
                        <li className="nav-item col-lg-2 pt-0 pb-0 pr-0" >
                            <NavLink
                                to="/myaccount/dashboard"
                                className={dashboardClass}>
                                <img alt="dashboard" className="nav-img" src={dashboard} />
                                <div className="nav-title">{t("Dashboard")}</div>
                                <div className="nav-description">{t("A quick look at your account")}</div>
                            </NavLink>
                        </li>
                    
                        <li className="nav-item col-lg-2 p-0" >
                            <NavLink to="/myaccount/bill" className="nav-link d-flex flex-column align-items-center">
                                <img alt="bill" className="nav-img" src={bill} />
                                <div className="nav-title">{t(getMPowerNavItem()[0])}</div>
                                <div className="nav-description">{t(getMPowerNavItem()[1])}</div>
                            </NavLink>
                        </li>
                    
                        <li className="nav-item col-lg-2 p-0" >
                            <NavLink to="/myaccount/usage" className="nav-link d-flex flex-column align-items-center">
                                <img alt="usage" className="nav-img" src={usage} />
                                <div className="nav-title">{t("Usage")}</div>
                                <div className="nav-description">{t("See your energy usage")}</div>
                            </NavLink>
                        </li>
                    
                        <li className="nav-item col-lg-2 p-0" >
                            <NavLink to="/myaccount/outages" className="nav-link d-flex flex-column align-items-center">
                                <img alt="outage" className="nav-img" src={outage} />
                                <div className="nav-title">{t("Outages")}</div>
                                <div className="nav-description">{t("View, report losses in power")}</div>
                            </NavLink>
                        </li>
                    
                        <li className="nav-item col-lg-2 p-0" >
                            <NavLink to="/myaccount/programs" className="nav-link d-flex flex-column align-items-center">
                                <img alt="price plan & programs" className="nav-img" src={pricePlanPrograms} />
                                <div className="nav-title">{t("Price plan & programs")}</div>
                                <div className="nav-description">{t("See your plan, ways to save")}</div>
                            </NavLink>
                        </li>
                    
                        <li className="nav-item col-lg-2 pt-0 pb-0 pl-0" >
                            <NavLink to="/myaccount/profile" className="nav-link d-flex flex-column align-items-center">
                                <img alt="my profile" className="nav-img" src={profile} />
                                <div className="nav-title">{t("My profile & settings")}</div>
                                <div className="nav-description">{t("Alerts, bank info, documents")}</div>
                            </NavLink>
                        </li>
                    </ul>
                </div>
            </div>

            <div className="row d-none d-md-block d-lg-none">
                <ul className="myaccount-nav nav list-inline nav-filled align-items-end w-100 d-flex flex-row">
                    <li className="nav-item col-md-2 pt-0 pb-0 pr-0" >
                        <NavLink to="/myaccount/dashboard" className="nav-link d-flex flex-column align-items-center">
                            <img alt = "dashboard" className="nav-img" src={dashboard}/>
                            <div className="nav-title">{t("Dashboard")}</div>
                        </NavLink>
                    </li>

                    <li className="nav-item col-md-2 p-0" >
                        <NavLink to="/myaccount/bill" className="nav-link d-flex flex-column align-items-center">
                            <img alt="bill" className="nav-img" src={bill} />
                            <div className="nav-title">{t(getMPowerNavItem()[0])}</div>
                        </NavLink>
                    </li>
                
                    <li className="nav-item col-md-2 p-0" >
                        <NavLink to="/myaccount/usage" className="nav-link d-flex flex-column align-items-center">
                            <img alt="usage" className="nav-img" src={usage} />
                            <div className="nav-title">{t("Usage")}</div>
                        </NavLink>
                    </li>
                
                    <li className="nav-item col-md-2 p-0" >
                        <NavLink to="/myaccount/outages" className="nav-link d-flex flex-column align-items-center">
                            <img alt="outage" className="nav-img" src={outage} />
                            <div className="nav-title">{t("Outages")}</div>
                        </NavLink>
                    </li>
                
                    <li className="nav-item col-md-2 p-0" >
                        <NavLink to="/myaccount/programs" className="nav-link d-flex flex-column align-items-center">
                            <img alt="price plan & programs" className="nav-img" src={pricePlanPrograms} />
                            <div className="nav-title">{t("Price plan & programs")}</div>
                        </NavLink>
                    </li>
                
                    <li className="nav-item col-md-2 w-100 pt-0 pb-0 pl-0">
                        <NavLink to="/myaccount/profile" className="nav-link d-flex flex-column align-items-center">
                            <img alt = "profile & settings" className="nav-img" src={profile} />
                            <div className="nav-title">
                                <div>
                                    <div>{t("My profile")}</div>
                                    <div>& {t("settings")}</div>
                                </div>
                            </div>
                        </NavLink>
                    </li>  
                </ul>
            </div>

            <div className="d-block d-md-none sticky-top">
                <Fade in={responsiveNavOpen}>
                    <div className="overlay pointer-btns" style={responsiveNavStyle}>
                        <a onClick={closeResponsiveNavOnClick} className="closebtn text-white d-flex align-items-center">
                            <i className="material-icons mr-2" >clear</i>
                            {t("Close")}
                        </a>
                        <div className="overlay-content text-white">
                            <div>
                                <Link to="/myaccount/dashboard" onClick={closeResponsiveNavOnClick} className="overlay-link" style={{ textDecoration: 'none' }}>
                                    <div className="d-flex row">
                                        <img alt="dashboard" className="nav-icon" src={DashboardIconMobile} />
                                        <div>
                                            <div className="nav-title">{t("Dashboard")}</div>
                                            <div className="nav-description">{t("A quick look at your account")}</div>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <Divider style={{ backgroundColor: 'rgb(0, 102, 165)' }} />
                            <div>
                                <Link to="/myaccount/bill" onClick={closeResponsiveNavOnClick} className="overlay-link" style={{ textDecoration: 'none' }}>
                                    <div className="d-flex row">
                                        <img alt="bill" className="nav-icon" src={MyBillIconMobile} />
                                        <div>
                                            <div className="nav-title">{t(getMPowerNavItem()[0])}</div>
                                            <div className="nav-description">{t(getMPowerNavItem()[1])}</div>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <Divider style={{ backgroundColor: 'rgb(0, 102, 165)' }} />
                            <div>
                                <Link to="/myaccount/usage" onClick={closeResponsiveNavOnClick} className="overlay-link" style={{ textDecoration: 'none' }}>
                                    <div className="d-flex row">
                                        <img alt="usage" className="nav-icon" src={UsageIconMobile} />
                                        <div>
                                            <div className="nav-title">{t("Usage")}</div>
                                            <div className="nav-description">{t("See your energy usage")}</div>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <Divider style={{ backgroundColor: 'rgb(0, 102, 165)' }} />
                            <div>
                                <Link to="/myaccount/outages" onClick={closeResponsiveNavOnClick} className="overlay-link" style={{ textDecoration: 'none' }}>
                                    <div className="d-flex row">
                                        <img alt="outage" className="nav-icon" src={OutageIconMobile} />
                                        <div>
                                            <div className="nav-title">{t("Outages")}</div>
                                            <div className="nav-description">{t("View, report losses in power")}</div>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <Divider style={{ backgroundColor: 'rgb(0, 102, 165)' }} />
                            <div>
                                <Link to="/myaccount/programs" onClick={closeResponsiveNavOnClick} className="overlay-link" style={{ textDecoration: 'none' }}>
                                    <div className="d-flex row">
                                        <img alt="price plan & programs" className="nav-icon" src={PricePlansProgramsIconMobile} />
                                        <div>
                                            <div className="nav-title">{t("Price plan & programs")}</div>
                                            <div className="nav-description">{t("See your plan, ways to save")}</div>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <Divider style={{ backgroundColor: 'rgb(0, 102, 165)' }} />
                            <div>
                                <Link to="/myaccount/profile" onClick={closeResponsiveNavOnClick} className="overlay-link" style={{ textDecoration: 'none' }}>
                                    <div className="d-flex row">
                                        <img alt = "profile & settings" className="nav-icon" src={ProfileIconMobile} />
                                        <div>
                                            <div className="nav-title">{t("My profile & settings")}</div>
                                            <div className="nav-description">{t("Alerts, bank info, documents")}</div>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <Divider style={{ backgroundColor: 'rgb(0, 102, 165)' }} />
                            <div>
                                <Link to="/logout" onClick={closeResponsiveNavOnClick} className="overlay-link" style={{ textDecoration: 'none' }}>
                                    <div className="d-flex row">
                                        <img className="nav-icon" src={LogoutIconMobile} />
                                        <div>
                                            <div className="nav-title">{t("Log out")}</div>
                                            <div className="nav-description">{t("Log out of your account")}</div>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <Divider style={{ backgroundColor: 'rgb(0, 102, 165)' }} />
                        </div>
                    </div>
                </Fade>
            </div>

        </div>
    )
}

NavigationMenu.propTypes = {
    navLocation: PropTypes.string,
    selectedBillAccountDetails: PropTypes.object.isRequired,
    responsiveNavStyle: PropTypes.object.isRequired,
    responsiveNavOpen: PropTypes.bool.isRequired,
    closeResponsiveNavOnClick: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired
}

export default NavigationMenu