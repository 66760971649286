import React from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

import Dialog from '@mui/material/Dialog'
import CheckDiagramMobile from '../../images/payment/check-diagram-mobile.png'
import CheckDiagramMobileEs from '../../images/payment/check-diagram-mobile-es.png'

const CheckDiagramDialog = props => (
    <Dialog
        modal="true"
        open={props.showDialog}
        PaperProps={{ className: "srp-modal d-lg-none d-block" }}
    >
        <div className="srp-modal-body">
            <div className="srp-modal-close" onClick={props.handleClose} />
            <div>
                <div className="srp-modal-header">
                    <h4 className="srp-modal-title" style={{ color: 'rgba(72, 72, 74, 0.867)' }}>{props.t("Check diagram")}</h4>
                </div>
                <img src={props.i18n.language==="es" ? CheckDiagramMobileEs : CheckDiagramMobile} className="mx-auto d-block img-fluid" />
                {props.showRoutingNumberDescription && <div className="mt-0">{props.t("routing_number")}</div>}
                {props.showBankAccountNumberDescription && <div className="mt-0">{props.t("bank_account_number")}</div>}
            </div>
        </div>
    </Dialog>  
)

CheckDiagramDialog.propTypes = {
    showDialog: PropTypes.bool.isRequired,
    showRoutingNumberDescription: PropTypes.bool.isRequired,
    showBankAccountNumberDescription: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        language: PropTypes.string,
    }).isRequired,
}

export default withTranslation("checkDiagramCard")(CheckDiagramDialog)
