import React from 'react'
import { withTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import { Link } from 'react-router-dom'

import Card from '@mui/material/Card'

import SinglePurchaseDetailsTableContainer from './single-purchase-details-table-container'

const SinglePurchaseSubmittedCardPure = ({referenceNumber, confirmationEmailSent, bankAccountUsed, updateBankEmailAddressOnClick, t}) => {
    
    let confirmationEmailAddress = bankAccountUsed !== undefined ? bankAccountUsed.email : t("no_email")
    
    return (<div>
        <label className="h5">{t("Your purchase has been submitted")}</label>

        <Card>
            <div>
                <SinglePurchaseDetailsTableContainer referenceNumber={referenceNumber} />

                <h6 className="text-muted px-3">
                    {confirmationEmailSent ?
                    <div>
                        {t("A confirmation email has been sent to")} <span className="font-weight-bold">{confirmationEmailAddress}</span> {t("for this purchase.")}&nbsp;
                        <button className="displayAsLink d-print-none" onClick={() => updateBankEmailAddressOnClick(bankAccountUsed)}>{t("Update your email address")} &raquo;</button>
                    </div>
                    :
                    <div>
                        {t("Unable to send a confirmation email to")} <span className="font-weight-bold">{confirmationEmailAddress}</span> {t("for this purchase.")}&nbsp;
                        <button className="displayAsLink d-print-none" onClick={() => updateBankEmailAddressOnClick(bankAccountUsed)}>{t("Update your email address")} &raquo;</button>
                    </div>
                    }
                </h6>
            </div>

            <div className="d-flex justify-content-end mt-4 px-3 pb-3 d-print-none">
                <button className="btn srp-btn btn-lightblue mr-2" onClick={() => { window.print() }}>{t("Print")}</button>
                <Link to="/myaccount/dashboard" className="btn srp-btn btn-blue">{t("Go to dashboard")}</Link>
            </div>
        </Card>
    </div>)
}

SinglePurchaseSubmittedCardPure.propTypes = {
    referenceNumber: PropTypes.number.isRequired,
    confirmationEmailSent: PropTypes.bool.isRequired,
    bankAccountUsed: PropTypes.object,
    updateBankEmailAddressOnClick: PropTypes.func.isRequired,

    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired
}

const SinglePurchaseSubmittedCard = withTranslation("singlePurchasePage")(SinglePurchaseSubmittedCardPure)
export { SinglePurchaseSubmittedCard as default, SinglePurchaseSubmittedCardPure }