import React from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'

import SinglePurchaseDetailsTable from './single-purchase-details-table'

class SinglePurchaseDetailsTableContainer extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <SinglePurchaseDetailsTable
                bankName={this.props.bankName}
                truncatedBankAccountNumber={this.props.truncatedBankAccountNumber}
                purchaseList={this.props.purchaseList}
                amountOwedToSRP={this.props.amountOwedToSRP}
                payDownFraction={this.props.payDownFraction}
            />
        )
    }
}

const mapStateToProps = state => {
    const selectedBankId = state.singlePurchaseBankInfo.selectedBankId
    const selectedBank = state.bankAccounts.bankAccountList.find(ba => ba.bankId === selectedBankId)

    return {
        bankName: selectedBank ? (selectedBank.nickName || selectedBank.bankName) : '',
        truncatedBankAccountNumber: selectedBank ? selectedBank.accountNumber : '',
        purchaseList: state.singlePurchaseList.purchaseList,
        amountOwedToSRP: state.accountInfo.payment.payment.amountDue,
        payDownFraction: state.accountInfo.payment.payment.payDownPercent,
    }
}

SinglePurchaseDetailsTableContainer.propTypes = {
    bankName: PropTypes.string.isRequired,
    truncatedBankAccountNumber: PropTypes.string.isRequired,
    purchaseList: PropTypes.arrayOf(PropTypes.shape({
        srpAccountNumber: PropTypes.string.isRequired,
        purchaseAmount: PropTypes.number.isRequired,
        payAmountOwedToSRP: PropTypes.bool.isRequired
    })),
    amountOwedToSRP: PropTypes.number.isRequired,
    payDownFraction: PropTypes.number.isRequired,
}

export default connect(mapStateToProps)(SinglePurchaseDetailsTableContainer)