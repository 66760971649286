import React from "react"
import PropTypes from 'prop-types'

const JustGonePaperlessBanner = ({t}) => (
    <div className="srp-alert-sucess p-3">
        <h6>{t("Paperless billing is")} <b>{t("ON")}</b></h6>

        <small>{t("Thank_you_enrolling_paperless")}</small>
    </div>
)

JustGonePaperlessBanner.propTypes = {
    t: PropTypes.func.isRequired
}

export default JustGonePaperlessBanner