import React from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

import format from '../../../srp_modules/format'

import transferModule from '../../../srp_modules/forms/transfer-module'

import { formConstants } from '../../../constants/form-constants'

const AboutYouDesktop = ({
    isLoggedIn, preferredLanguage, email, phoneNumber,
    identificationType, identificationNumber,
    lastName, middleName, firstName,
    authorizeSpouse, spouseLastName, spouseFirstName,
    displayIdentificationType, t
    }) => (
    <div className="d-lg-block d-none mb-4">
        <div>
        {middleName.length !== 0
            ? `${firstName} ${middleName} ${lastName}`
            : `${firstName} ${lastName}`
        }
        </div>
        <div>
            <span className="font-weight-bold mr-2">{t("id_type")}</span>
            <span>{displayIdentificationType(identificationType, t)}</span>
        </div>
        <div>
            <span className="font-weight-bold mr-2">{t("id")}</span>
            <span>{transferModule.formatIDNumber(identificationNumber)}</span>
        </div>
        {!isLoggedIn &&
        <div>
            <span className="font-weight-bold mr-2">{t("preferred_language")}</span>
            <span>
            {preferredLanguage == formConstants.ENGLISH
                ? t("english")
                : t("spanish")}
            </span>
        </div>
        }
        <div>
            <span className="font-weight-bold mr-2">{t("email_address")}</span>
            <span>{email}</span>
        </div>
        <div>
            <span className="font-weight-bold mr-2">{t("phone")}</span>
            <span>{format.formatPhoneNumber(phoneNumber)}</span>
        </div>
        {authorizeSpouse &&
        <div>
            <span className="font-weight-bold mr-2">{t("spouse")}</span>
            <span>
            {spouseLastName.length !== 0
                ? `${spouseFirstName} ${spouseLastName}`
                : `${spouseFirstName}`
            }
            </span>
        </div>
        }
    </div>
)

AboutYouDesktop.propTypes = {
    isLoggedIn: PropTypes.bool.isRequired,
    preferredLanguage: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    phoneNumber: PropTypes.string.isRequired,

    identificationType: PropTypes.string.isRequired,
    identificationNumber: PropTypes.string.isRequired,

    lastName: PropTypes.string.isRequired,
    middleName: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,

    authorizeSpouse: PropTypes.bool.isRequired,
    spouseLastName: PropTypes.string.isRequired,
    spouseFirstName: PropTypes.string.isRequired,

    displayIdentificationType: PropTypes.func.isRequired,

    t: PropTypes.func.isRequired,
}

export default withTranslation('startService')(AboutYouDesktop)