import React from "react"
import PropTypes from 'prop-types'

import CircularProgress from '@mui/material/CircularProgress'

const GoPaperlessBanner = ({turnOnPaperlessBilling, isSubmitting, disabled, t}) => (
    <div className="d-flex flex-column srp-alert-notice p-3">
        <h4 className="srp-dark-blue-color"><b>{t("Go paperless today")}</b></h4>

        <small>{t("paperless_billing_benefits")}</small>

        <button className="btn srp-btn btn-blue align-self-end mt-3" type="button" onClick={() => turnOnPaperlessBilling()} disabled={disabled}>
            {isSubmitting ? <CircularProgress size={20} thickness={5} style={{color:'white'}} aria-label="turning on paperless billing" alt="progress icon" /> : <small><b>{t("Turn on paperless billing")}</b></small>}
        </button>
    </div>
)

GoPaperlessBanner.propTypes = {
    turnOnPaperlessBilling: PropTypes.func.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
    disabled: PropTypes.bool,
    t: PropTypes.func.isRequired,
}

export default GoPaperlessBanner