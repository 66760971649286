import { dashboardConstants  } from '../../../constants/dashboard-constants'

const initialState = {    
    defaultViewStyle: {display:'block'},
    outageDetailViewStyle: {display:'none'}
}

export default function cardOutageReducer(state = initialState, action) {
    switch (action.type) {
        case dashboardConstants.GET_DEFAULT_VIEW:
            return { ...state, defaultViewStyle: {display:'block'}, outageDetailViewStyle: {display:'none'} }
        case dashboardConstants.GET_OUTAGE_DETAIL_VIEW:
            return { ...state, defaultViewStyle: {display:'none'}, outageDetailViewStyle: {display:'block'} }
        default:
            return state
    }
}