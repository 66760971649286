import React from 'react'
import PropTypes from 'prop-types'

import Card from '@mui/material/Card'

const FetchErrorCardContent = ({dataDescription, fetchAgainCallback, t}) => (
    <Card className="p-4">
        <div className="mb-1 text-center srp-100-red"><i className="material-icons srp-3x-font-size">error_outline</i></div>
        <div className="mb-1 text-center srp-100-red"><strong>{t("Something went wrong")}</strong></div>
        <div className="mb-4 text-center">{t("unable_to_retrieve_data", {dataDescription})}</div>

        <div className="d-flex justify-content-end">
            <button type="button" onClick={()=>fetchAgainCallback()} className="btn srp-btn btn-lightblue">{t("Try again")}</button>
        </div> 
    </Card>
)

FetchErrorCardContent.propTypes = {
    t: PropTypes.func.isRequired,
    dataDescription: PropTypes.string.isRequired,
    fetchAgainCallback: PropTypes.func.isRequired
}

export default FetchErrorCardContent