import React from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

import format from '../../../srp_modules/format'

import transferModule from '../../../srp_modules/forms/transfer-module'

import { formConstants } from '../../../constants/form-constants'

const AboutYouMobile = ({
    isLoggedIn, preferredLanguage, email, phoneNumber,
    identificationType, identificationNumber,
    lastName, middleName, firstName,
    authorizeSpouse, spouseLastName, spouseFirstName,
    displayIdentificationType, t
    }) => (
    <div className="d-lg-none d-block mb-4">
        <div className="row d-flex align-items-center">
            <div className="col text-right">
            {middleName.length !== 0
                ? `${firstName} ${middleName} ${lastName}`
                : `${firstName} ${lastName}`
            }
            </div>
        </div>
        <hr className="mt-1 mb-1" />
        <div className="row d-flex align-items-center">
            <div className="col-5 text-muted font-weight-bold">{t("id_type")}</div>
            <div className="col-7 text-right">{displayIdentificationType(identificationType, t)}</div>
        </div>
        <hr className="mt-1 mb-1" />
        <div className="row d-flex align-items-center">
            <div className="col-5 text-muted font-weight-bold">{t("id")}</div>
            <div className="col-7 text-right">{transferModule.formatIDNumber(identificationNumber)}</div>
        </div>
        <hr className="mt-1 mb-1" />
        {!isLoggedIn &&
        <div>
        <div className="row d-flex align-items-center">
            <div className="col-5 text-muted font-weight-bold">{t("preferred_language")}</div>
            <div className="col-7 text-right">
            {preferredLanguage == formConstants.ENGLISH
                ? t("english")
                : t("spanish")}
            </div>
        </div>
        <hr className="mt-1 mb-1" />
        </div>
        }
        <div className="row d-flex align-items-center">
            <div className="col-5 text-muted font-weight-bold">{t("email_address")}</div>
            <div className="col-7 text-right">{email}</div>
        </div>
        <hr className="mt-1 mb-1" />
        <div className="row d-flex align-items-center">
            <div className="col-5 text-muted font-weight-bold">{t("phone")}</div>
            <div className="col-7 text-right">{format.formatPhoneNumber(phoneNumber)}</div>
        </div>
        <hr className="mt-1" />
        {authorizeSpouse &&
        <div>
        <div className="row d-flex align-items-center">
            <div className="col-5 text-muted font-weight-bold">{t("spouse")}</div>
            <div className="col-7 text-right">
            {spouseLastName.length !== 0
                ? `${spouseFirstName} ${spouseLastName}`
                : `${spouseFirstName}`
            }
            </div>
        </div>
        <hr className="mt-1" />
        </div>
        }
    </div>
)

AboutYouMobile.propTypes = {
    isLoggedIn: PropTypes.bool.isRequired,
    preferredLanguage: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    phoneNumber: PropTypes.string.isRequired,

    identificationType: PropTypes.string.isRequired,
    identificationNumber: PropTypes.string.isRequired,

    lastName: PropTypes.string.isRequired,
    middleName: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,

    authorizeSpouse: PropTypes.bool.isRequired,
    spouseLastName: PropTypes.string.isRequired,
    spouseFirstName: PropTypes.string.isRequired,

    displayIdentificationType: PropTypes.func.isRequired,

    t: PropTypes.func
}

export default withTranslation('startService')(AboutYouMobile)