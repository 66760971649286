import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import AccountInfoBarContainer from './myaccount_header/info_bar/account-info-bar-container'
import { withRouter } from '../srp_modules/with-router'

import config from 'my-account-config'

class WaterPlaceholder extends React.Component {
    componentDidMount() {
        if (this.props.selectedBillAccount > 0) {
            this.props.returnToDashboard()
        }
    }

    componentDidUpdate() {
        if (this.props.selectedBillAccount > 0) {
            this.props.returnToDashboard()
        }
    }

    render() {
        return (
        <div>
            <div className="container py-3">
                <h3 className="mt-3">You've selected a water account!</h3>

                <AccountInfoBarContainer disableSelector={false} />

                <div className="mt-4">
                    <div className="srp-card-body ">
                        <div className="srp-card-details">
                            <div className="text-muted">
                                We seem to be having trouble directing you to your irrigation profile page.
                                Try visiting the <a href={`${config.waterBaseUrl}dashboard`}>water dashboard</a> directly, or
                                select a power account above to return to the power dashboard.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>)
    }
}

const mapStateToProps = state => {
    return {
        selectedBillAccount: state.accountInfo.billAccount.selectedBillAccount
    }
}

const mapDispatchToProps = (_, ownProps) => {
    return {
        returnToDashboard: () => {
            ownProps.router.navigate('/myaccount/dashboard', { replace: true })
        }
    }
}

WaterPlaceholder.propTypes = {
    selectedBillAccount: PropTypes.number.isRequired,
    returnToDashboard: PropTypes.func.isRequired,
    router: PropTypes.shape({
        location: PropTypes.object,
        navigate: PropTypes.func
    }).isRequired
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WaterPlaceholder));