import React from 'react'
import PropTypes from 'prop-types'
import Tooltip from '@mui/material/Tooltip'

class SrpTooltip extends React.Component {
    render() {
        return (
            <Tooltip
                placement={this.props.placement || 'top'}
                disableHoverListener={this.props.disableHoverListener || false}
                enterTouchDelay={100}
                title={this.props.content}
                arrow
            >
                {this.props.children}
            </Tooltip>
        )
    }
}

SrpTooltip.propTypes = {
    content: PropTypes.element.isRequired,
    placement: PropTypes.string,
    children: PropTypes.element,
    disableHoverListener: PropTypes.bool
}

export default SrpTooltip