import React from 'react'
import PropTypes from 'prop-types'

import { DateTime } from 'luxon'

import format from '../../../srp_modules/format'

import PaymentListDetailsDesktop from './payment-list-details-desktop'
import PaymentListDetailsMobile from './payment-list-details-mobile'

const MultiplePaymentDetailsTable = ({paymentList, bankAccountNumber, referenceNumber, t}) => (
    <div>
    <table className="table my-3">
        <tbody>
            <tr>
                <td className="border-top-0 pl-3 srp-fit">{t("Bank account")}</td>
                <td className="border-top-0 pr-3">{format.maskBankAccountNumber(bankAccountNumber)}</td>
            </tr>
            <tr>
                <td className="border-bottom pl-3 srp-fit">{t("Total payments")}</td>
                <td className="border-bottom pr-3">{format.formatDollarAmount(paymentList.reduce((total, p) => total+p.paymentAmount, 0))}</td>
            </tr>
        </tbody>
    </table>

    <div className="d-none d-lg-block">
    <PaymentListDetailsDesktop paymentDate={DateTime.now().toFormat('M/d/yyyy')} paymentList={paymentList} referenceNumber={referenceNumber} t={t} />
    </div>
    <div className="d-lg-none d-block">
    <PaymentListDetailsMobile paymentDate={DateTime.now().toFormat('M/d/yy')} paymentList={paymentList} referenceNumber={referenceNumber} t={t} />
    </div>
    </div>
)

MultiplePaymentDetailsTable.propTypes = {
    paymentList: PropTypes.arrayOf(PropTypes.shape({
        srpAccountNumber: PropTypes.string.isRequired,
        paymentAmount: PropTypes.number.isRequired,
    })),
    referenceNumber: PropTypes.number,
    bankAccountNumber: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired,
}

export default MultiplePaymentDetailsTable