import { dailyUsageChartTypeConstants } from "../../constants/daily-usage-chart-type-constants"
export function convertDailyExportDataForCharting(data, currentChartType) {
    if (data != undefined && data.length > 0) {
        let dailyData = []
        if (currentChartType === dailyUsageChartTypeConstants.COST) {
            data.forEach(function (e) {
                dailyData.push({
                    day: e.day,
                    date: e.date,
                    meterReadDate: e.meterReadDate,
                    billStartDate: e.billStartDate,
                    billEndDate: e.date,
                    superOffPeak: e.superOffPeakCost,
                    offPeak: e.offPeakCost,
                    shoulder: e.shoulderCost,
                    onPeak: e.onPeakCost,
                    total: e.totalCost,
                    dailyCost: e.dailyCost,
                    totalCost: e.totalCost,
                    totalKwh: e.totalKwh,
                    onPeakCost: e.onPeakCost,
                    offPeakCost: e.offPeakCost,
                    shoulderCost: e.shoulderCost,
                    superOffPeakCost: e.superOffPeakCost,
                    showSuperOffPeakTip: e.superOffPeakKwh !== 0,
                    showOffPeakTip: e.offPeakKwh !== 0,
                    showShoulderTip: e.shoulderKwh !== 0,
                    showOnPeakTip: e.onPeakKwh !== 0,
                    showTotalTip: e.totalKwh !== 0,
                    showTotalCostTip: true,
                    tooltipSuperOffPeak: "Super off-peak kWh: " + e.superOffPeakKwh,
                    tooltipOffPeak: "Off-peak kWh: " + e.offPeakKwh,
                    tooltipShoulder: "Shoulder kWh: " + e.shoulderKwh,
                    tooltipOnPeak: "On-peak kWh: " + e.onPeakKwh,
                    tooltipTotal: "Total kWh: " + e.totalKwh
                })
            })
        }

        if (currentChartType === dailyUsageChartTypeConstants.USAGE
            || currentChartType === dailyUsageChartTypeConstants.NET_ENERGY) {
            data.forEach(function (e) {
                dailyData.push({
                    day: e.day,
                    date: e.date,
                    meterReadDate: e.meterReadDate,
                    billStartDate: e.billStartDate,
                    billEndDate: e.date,
                    dailyCost: e.dailyCost,
                    superOffPeak: e.superOffPeakKwh,
                    offPeak: e.offPeakKwh,
                    shoulder: e.shoulderKwh,
                    onPeak: e.onPeakKwh,
                    total: e.totalKwh,
                    totalKwh: e.totalKwh,
                    showSuperOffPeakTip: e.superOffPeakKwh !== 0,
                    showOffPeakTip: e.offPeakKwh !== 0,
                    showShoulderTip: e.shoulderKwh !== 0,
                    showOnPeakTip: e.onPeakKwh !== 0,
                    showTotalTip: e.totalKwh !== 0,
                    totalCost: e.totalCost,
                    tooltipSuperOffPeak: "Super off-peak kWh: " + e.superOffPeakKwh,
                    tooltipOffPeak: "Off-peak kWh: " + e.offPeakKwh,
                    tooltipShoulder: "Shoulder kWh: " + e.shoulderKwh,
                    tooltipOnPeak: "On-peak kWh: " + e.onPeakKwh,
                    tooltipTotal: "Total kWh: " + e.totalKwh
                })
            })
        }
        if (currentChartType === dailyUsageChartTypeConstants.DEMAND) {
            data.forEach(function (e) {
                dailyData.push({
                    day: e.day,
                    date: e.date,
                    meterReadDate: e.meterReadDate,
                    billStartDate: e.billStartDate,
                    billEndDate: e.date,
                    dailyCost: 0,
                    superOffPeak: e.superOffPeakDemand,
                    offPeak: e.offPeakDemand,
                    shoulder: e.shoulderDemand,
                    onPeak: e.onPeakDemand,
                    total: e.totalDemand,
                    intervalTimeInMinutes: e.intervalTimeInMinutes || 0,
                    showSuperOffPeakTip: e.superOffPeakDemand !== 0,
                    showOffPeakTip: e.offPeakDemand !== 0,
                    showShoulderTip: e.shoulderDemand !== 0,
                    showOnPeakTip: e.onPeakDemand !== 0,
                    showTotalTip: e.totalDemand !== 0,
                    showTotalCostTip: false,
                    tooltipSuperOffPeak: "Super off-peak demand kW:" + e.superOffPeakDemand,
                    tooltipOffPeak: "Off-peak demand kW: " + e.offPeakDemand,
                    tooltipShoulder: "Shoulder demand kW: " + e.shoulderDemand,
                    tooltipOnPeak: "On-peak demand kW: " + e.onPeakDemand,
                    tooltipTotal: "Total demand kW: " + e.totalDemand
                })
            })
        }
        if (currentChartType === dailyUsageChartTypeConstants.CONSUMPTION ||
            currentChartType === dailyUsageChartTypeConstants.GENERATION
            || currentChartType === dailyUsageChartTypeConstants.EXPORT) {
            data.forEach(function (e) {
                dailyData.push({
                    day: e.day,
                    date: e.date,
                    meterReadDate: e.meterReadDate,
                    billStartDate: e.billStartDate,
                    billEndDate: e.date,
                    dailyCost: 0,
                    superOffPeak: e.superOffPeak,
                    offPeak: e.offPeak,
                    shoulder: e.shoulder,
                    onPeak: e.onPeak,
                    total: e.total,
                    totalKwh: e.total,
                    showSuperOffPeakTip: e.superOffPeak !== 0,
                    showOffPeakTip: e.offPeak !== 0,
                    showShoulderTip: e.shoulder !== 0,
                    showOnPeakTip: e.onPeak !== 0,
                    showTotalTip: e.total !== 0,
                    showTotalCostTip: false,
                    tooltipSuperOffPeak: "Super off-peak kWh: " + e.superOffPeak,
                    tooltipOffPeak: "Off-peak kWh: " + e.offPeak,
                    tooltipShoulder: "Shoulder kWh: " + e.shoulder,
                    tooltipOnPeak: "On-peak kWh: " + e.onPeak,
                    tooltipTotal: "Total kWh: " + e.total
                })
            })
        }
        return dailyData
    }
    return []
}
