import {formStopConstants} from '../../../constants/form-stop-constants'
import utils from '../../../srp_modules/utils'
import {RSAA} from 'redux-api-middleware'
import {mapToServerStruct} from '../../../srp_modules/forms/turnoff-mapper'

export const changeStopDate = (selectedDate) => ({ type: formStopConstants.CHANGE_STOP_DATE, selectedDate })
export const changeAddress1 = (newAddress) => ({ type: formStopConstants.CHANGE_ADDRESS1, newAddress})
export const changeAddress2 = (newAddress) => ({ type: formStopConstants.CHANGE_ADDRESS2, newAddress})
export const changeZip = (zip) => ({type: formStopConstants.CHANGE_ZIPCODE, zip})
export const changeCity = (city) => ({type: formStopConstants.CHANGE_CITY, city})
export const changeState = (state) => ({type: formStopConstants.CHANGE_STATE, state})
export const changeCountry = (country) => ({type: formStopConstants.CHANGE_COUNTRY, country})
export const changePhone = (phone) => ({type: formStopConstants.CHANGE_PHONENUMBER, phone})
export const changeEmail = (email) => ({type: formStopConstants.CHANGE_EMAIL, email})

export const changeConfirmEmail = (email) => ({type: formStopConstants.CHANGE_CONFIRM_EMAIL, email})
export const changeConfirmPhone = (phone) => ({type: formStopConstants.CHANGE_CONFIRM_PHONE, phone})

export const goToReview = (formState, isMpower) => ({type: formStopConstants.SHOW_REVIEW, formState, isMpower })
export const goToDetails = () => ({type: formStopConstants.SHOW_DETAIL_FORM})

export const shouldRunValidationCheck = (shouldRun) => ({type: formStopConstants.RUN_VALIDATION_CHECK, shouldRun})
export const sendPostage = (shouldSend) => ({type: formStopConstants.SEND_ENVELOPE, shouldSend})

export const submitStopRequest = () => {
  return (dispatch, getState) => {
    const currentState = getState()
    const serverFormValues = mapToServerStruct( currentState.turnOff, 
                                                currentState.accountInfo.billAccount.selectedBillAccount,
                                                currentState.accountInfo.billAccount.selectedBillAccountDetails.isCommercial,
                                                currentState.accountInfo.billAccount.mPowerAcctDetails.centralPrepayStatus) 
    
    return dispatch({
      [RSAA]: {
        endpoint: utils.createUrl('forms/submitwebturnoff'),
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(serverFormValues),
        types: [
          formStopConstants.SUBMIT_TURNOFF_REQUEST,
          formStopConstants.SUBMIT_TURNOFF_SUCCESS,
          formStopConstants.SUBMIT_TURNOFF_FAILURE
        ]
      }  
    })
  }
}

export const getHolidays = () => dispatch => {
  return dispatch({
    [RSAA]: 
      { endpoint: utils.createUrl('forms/getblockeddates')
      , method: 'GET'
      , headers: {'Content-Type': 'application/json'}
      , types: 
          [ formStopConstants.HOLIDAY_REQUEST  
          , formStopConstants.HOLIDAY_SUCCESS
          , formStopConstants.HOLIDAY_FAILURE
          ]
      } 
  })
}