import React from 'react'
import PropTypes from 'prop-types'

import { Link } from 'react-router-dom'

const ExportAndPaymentButtons = ({exportCallback, t, i18n}) => (
    <div>
    <div className="d-none d-lg-block mt-3">
        <div className="d-flex justify-content-end">
            <button
                className="btn srp-btn btn-lightblue"
                onClick={exportCallback}>
                {t("Export to Excel")}
            </button>
            <Link to="/myaccount/payment" className="btn srp-btn btn-lightblue mb-2 ml-2">{t("Make payment")}</Link>
        </div>
    </div>

    <div className="d-lg-none d-flex mt-3">
        <button
            className="btn srp-btn btn-lightblue mr-2"
            onClick={exportCallback}>
            {t("Export")}
        </button>
        <Link to="/myaccount/payment" className="btn srp-btn btn-lightblue">{t("Make payment")}</Link>
    </div>
    </div>
)

ExportAndPaymentButtons.propTypes = {
    exportCallback: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired,
}

export default ExportAndPaymentButtons