import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Divider from "@mui/material/Divider"
import '../../styles/usage.css'
import HourlyUsageChartContainer from './hourly-usage-chart-container'
import HourlyTableView from './hourly-table-view'
import { dailyUsageChartTypeConstants } from '../../constants/daily-usage-chart-type-constants'
import { getUsageHourlyChartType, getHourlyDataTable } from '../../actions/auth/usage/usage-actions'
import exportUsageModule from '../../srp_modules/charting/export'
import NoHourlyUsageMessage from './no-hourly-usage-message'
import UsagePageLoading from './usage-page-loading'
import * as loadingStatus from '../../constants/loading-status-constants'


class HourlyUsageContainer extends React.Component {
    constructor(props) {
        super(props)

        this.exportHourlyToExcel = this.exportHourlyToExcel.bind(this)
        this.getUsageDataType = this.getUsageDataType.bind(this)
    }

    exportHourlyToExcel(dataType, chartType) {
        let dataToExport = this.getUsageDataType(dataType)
        exportUsageModule.getUsageChartExport(dataToExport, dataType, chartType)
    }

    getUsageDataType(dataType) {
        let usageData
        switch (dataType) {
            case dailyUsageChartTypeConstants.DEMAND:
                usageData = this.props.hourlyUsage.hourlyDemandList
                break
            case dailyUsageChartTypeConstants.USAGE:
            case dailyUsageChartTypeConstants.COST:
                usageData = this.props.hourlyUsage.hourlyUsageList
                break
            case dailyUsageChartTypeConstants.EXPORT:
                usageData = this.props.hourlyUsage.hourlyReceivedList
                break
            case dailyUsageChartTypeConstants.GENERATION:
                usageData = this.props.hourlyUsage.hourlyGenerationList
                break
            case dailyUsageChartTypeConstants.CONSUMPTION:
                usageData = this.props.hourlyUsage.hourlyConsumptionList
                break
        }

        return usageData
    }

    render() {
        if (this.props.rateMetaData.rateMetaDataStatus === loadingStatus.LOADING_STATUS_INIT
            || this.props.rateMetaData.rateMetaDataStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS
            || this.props.dailyUsage.dailyUsageStatus === loadingStatus.LOADING_STATUS_INIT
            || this.props.dailyUsage.dailyUsageStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS
            || this.props.hourlyUsage.hourlyUsageStatus === loadingStatus.LOADING_STATUS_INIT
            || this.props.hourlyUsage.hourlyUsageStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS
        )
            return (
                <div>
                    <UsagePageLoading />
                </div>
            )
        if (showNoDataCard(this.props.currentHourlyChart, this.props.hourlyUsage)) {
            return (<NoHourlyUsageMessage t={this.props.t}/>)
        }
        let hasCost = this.props.rateMetaData.rateMetaData.hasCost && !this.props.rateMetaData.rateMetaData.isMPower
        let showDemand = this.props.rateMetaData.rateMetaData.isDemand && ! this.props.rateMetaData.rateMetaData.hasMonthlyDemandOnly
        return (
            <div className="ml-4 mr-4 pb-2">
                <div className="d-none d-lg-block mt-4">
                    {(hasCost
                        || showDemand
                        || this.props.rateMetaData.rateMetaData.isIDRRate)
                        && <div className="d-none d-lg-block chart-btn-container">
                            <span className="mr-2" style={{ color: 'black' }}>{this.props.t("View usage in")}</span>
                            {hasCost &&
                                <button className={this.props.hourlyButtonClassNames.costBtnClassName} onClick={() => this.props.getUsageHourlyChartTypeOnClick(dailyUsageChartTypeConstants.COST)}>{this.props.t("Cost ($)")}</button>
                            }
                            <button className={this.props.hourlyButtonClassNames.usageBtnClassName} onClick={() => this.props.getUsageHourlyChartTypeOnClick(dailyUsageChartTypeConstants.USAGE)}>
                                {this.props.rateMetaData.rateMetaData.isIDRRate &&
                                    <span>{this.props.t("Billed Energy")}</span>
                                }
                                {!this.props.rateMetaData.rateMetaData.isIDRRate &&
                                    <span>{this.props.t("Usage (kWh)")}</span>
                                }
                            </button>
                            {this.props.rateMetaData.rateMetaData.isIDRRate &&
                                <button className={this.props.hourlyButtonClassNames.exportBtnClassName} onClick={() => this.props.getUsageHourlyChartTypeOnClick(dailyUsageChartTypeConstants.EXPORT)}>{this.props.t("Export (kWh)")}</button>
                            }
                            {this.props.rateMetaData.rateMetaData.isIDRRate &&
                                <button className={this.props.hourlyButtonClassNames.generationBtnClassName} onClick={() => this.props.getUsageHourlyChartTypeOnClick(dailyUsageChartTypeConstants.GENERATION)}>{this.props.t("Generation")}</button>
                            }
                            {this.props.rateMetaData.rateMetaData.isIDRRate &&
                                <button className={this.props.hourlyButtonClassNames.consumptionBtnClassName} onClick={() => this.props.getUsageHourlyChartTypeOnClick(dailyUsageChartTypeConstants.CONSUMPTION)}>{this.props.t("Usage")}</button>
                            }
                            {showDemand &&
                                <button className={this.props.hourlyButtonClassNames.demandBtnClassName} onClick={() => this.props.getUsageHourlyChartTypeOnClick(dailyUsageChartTypeConstants.DEMAND)}>{this.props.t("Demand")}</button>
                            }
                        </div>}
                </div>

                <div className="d-block d-lg-none">
                    <div className="d-flex justify-content-end">
                        <button
                            className="btn srp-btn btn-lightblue text-white mr-2"
                            onClick={() => this.props.getHourlyDataTableOnClick(!this.props.showHourlyDataTable)}>
                            {this.props.showHourlyDataTable ? this.props.t("View chart") : this.props.t("View data table")}
                        </button>
                        <button
                            className="btn srp-btn btn-lightblue text-white"
                            onClick={() => this.exportHourlyToExcel(this.props.currentHourlyChart, this.props.selectedUsagePageChartTab)}>
                            {this.props.t("Export")}
                        </button>
                    </div>
                </div>
                <Divider className="mt-4 mb-4 d-lg-none" id="top-of-hourly-chart" />
                {(hasCost
                        || showDemand
                        || this.props.rateMetaData.rateMetaData.isIDRRate) &&
                    <div className="d-block d-lg-none mb-4 ">
                        <div className="mb-2 mobile-chart-label">{this.props.t("View usage in")}</div>
                        <div>
                            {hasCost &&
                                <button className={this.props.hourlyButtonClassNames.costBtnClassName} onClick={() => this.props.getUsageHourlyChartTypeOnClick(dailyUsageChartTypeConstants.COST)}>{this.props.t("Cost ($)")}</button>
                            }
                            <button className={this.props.hourlyButtonClassNames.usageBtnClassName} onClick={() => this.props.getUsageHourlyChartTypeOnClick(dailyUsageChartTypeConstants.USAGE)}>
                                {this.props.rateMetaData.rateMetaData.isIDRRate &&
                                    <span>{this.props.t("Billed Energy")}</span>
                                }
                                {!this.props.rateMetaData.rateMetaData.isIDRRate &&
                                    <span>{this.props.t("Usage (kWh)")}</span>
                                }
                            </button>
                            {this.props.rateMetaData.rateMetaData.isIDRRate &&
                                <button className={this.props.hourlyButtonClassNames.exportBtnClassName} onClick={() => this.props.getUsageHourlyChartTypeOnClick(dailyUsageChartTypeConstants.EXPORT)}>{this.props.t("Export (kWh)")}</button>
                            }
                            {this.props.rateMetaData.rateMetaData.isIDRRate &&
                                <button className={this.props.hourlyButtonClassNames.generationBtnClassName} onClick={() => this.props.getUsageHourlyChartTypeOnClick(dailyUsageChartTypeConstants.GENERATION)}>{this.props.t("Generation")}</button>
                            }
                            {this.props.rateMetaData.rateMetaData.isIDRRate &&
                                <button className={this.props.hourlyButtonClassNames.consumptionBtnClassName} onClick={() => this.props.getUsageHourlyChartTypeOnClick(dailyUsageChartTypeConstants.CONSUMPTION)}>{this.props.t("Usage")}</button>
                            }
                            {showDemand &&
                                <button className={this.props.hourlyButtonClassNames.demandBtnClassName} onClick={() => this.props.getUsageHourlyChartTypeOnClick(dailyUsageChartTypeConstants.DEMAND)}>{this.props.t("Demand")}</button>
                            }
                        </div>
                    </div>}

                {this.props.showHourlyDataTable
                    ? null
                    : <div className="d-block d-lg-none mb-4 center-text">
                        <div className={this.props.currentHourlyChart === dailyUsageChartTypeConstants.COST ? "block-element" : "hidden-element"}>{this.props.t("Energy cost per hour")}</div>
                        <div className={this.props.currentHourlyChart === dailyUsageChartTypeConstants.USAGE || dailyUsageChartTypeConstants === dailyUsageChartTypeConstants.CONSUMPTION ? "block-element" : "hidden-element"}>{this.props.t("Usage (kWh)")}</div>
                        <div className={this.props.currentHourlyChart === dailyUsageChartTypeConstants.DEMAND ? "block-element" : "hidden-element"}>{this.props.t("Peak Demand")}</div>

                    </div>
                }

                {this.props.showHourlyDataTable
                    ? <HourlyTableView t={this.props.t}
                        usageData={this.getUsageDataType(this.props.currentHourlyChart)}
                        currentUsageType={this.props.currentHourlyChart} />
                    : <HourlyUsageChartContainer t={this.props.t}/>}

                <div className="d-none d-lg-block mt-4">
                    <div className="d-flex justify-content-end mb-1">
                        <button
                            className="btn srp-btn btn-lightblue text-white mr-2"
                            onClick={() => this.props.getHourlyDataTableOnClick(!this.props.showHourlyDataTable)}>
                            {this.props.showHourlyDataTable ? this.props.t("View as chart") : this.props.t("View as data table")}
                        </button>
                        <button
                            className="btn srp-btn btn-lightblue text-white"
                            onClick={() => this.exportHourlyToExcel(this.props.currentHourlyChart, this.props.selectedUsagePageChartTab)}>
                            {this.props.t("Export to Excel")}
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}
HourlyUsageContainer.propTypes = {
    rateMetaData: PropTypes.object,
    hourlyButtonClassNames: PropTypes.object,
    getUsageHourlyChartTypeOnClick: PropTypes.func,
    currentHourlyChart: PropTypes.string,
    hourlyUsageList: PropTypes.arrayOf(PropTypes.object),
    hourlyDemandList: PropTypes.arrayOf(PropTypes.object),
    selectedUsagePageChartTab: PropTypes.number,
    getHourlyDataTableOnClick: PropTypes.func,
    showHourlyDataTable: PropTypes.bool,
    hourlyUsage: PropTypes.object,
    dailyUsage: PropTypes.object,
    t: PropTypes.func
}
const mapStateToProps = state => {
    return {
        ...state.accountInfo.usage,
        ...state.accountInfo.usagePageUI,
        hourlyUsage: state.hourlyUsage,
        rateMetaData: state.rateMetaData,
        dailyUsage: state.dailyUsage
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getUsageHourlyChartTypeOnClick: (type) => {
            dispatch(getUsageHourlyChartType(type))
        },
        getHourlyDataTableOnClick: (showTable) => {
            dispatch(getHourlyDataTable(showTable))
        }
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(HourlyUsageContainer);

function showNoDataCard(chartType, hourlyUsage) {
    switch (chartType) {
        case dailyUsageChartTypeConstants.COST:
            return hourlyUsage.hourlyUsageList === undefined
                || hourlyUsage.hourlyUsageList.length < 1
        case dailyUsageChartTypeConstants.USAGE:
            return hourlyUsage.hourlyUsageList === undefined
                || hourlyUsage.hourlyUsageList.length < 1
        case dailyUsageChartTypeConstants.CONSUMPTION:
            return hourlyUsage.hourlyConsumptionList === undefined
                || hourlyUsage.hourlyConsumptionList.length < 1
        case dailyUsageChartTypeConstants.GENERATION:
            return hourlyUsage.hourlyGenerationList === undefined
                || hourlyUsage.hourlyGenerationList.length < 1
        case dailyUsageChartTypeConstants.DEMAND:
            return hourlyUsage.hourlyDemandList === undefined
                || hourlyUsage.hourlyDemandList.length < 1
    }
    return false
}