import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { DateTime } from 'luxon'
import config from 'my-account-config'
import Divider from '@mui/material/Divider'
import { formatDayWithOrdinal } from '../../../srp_modules/utils'

const borderStyle = {
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)"
}

const SummaryMasterPrograms = ({ programs, numOfEnrolledPrograms, autopayInfo, budgetBillingAmount, isBudgetBillingActive, t }) => {

    let budgetBillingHasLabelEnrolled = !programs.autopay.isEnrolled || (programs.autopay.isEnrolled && !isBudgetBillingActive)
    let autopayHasBorderEnrolled = programs.budgetBilling.isEnrolled && isBudgetBillingActive

    return (
        <div className="srp-card-body">
            <div className="d-lg-block d-none">
                <div className="srp-card-details pt-2 pb-0">
                    <div className="row" style={programs.autopay.isEnrolled ? { display: 'flex' } : { display: 'none' }}>
                        <div className="col-3 text-muted pt-2 pb-2">{t("Enrolled Programs")}</div>
                        <div className="col-3 pt-2 pb-2" style={autopayHasBorderEnrolled ? borderStyle : { borderBottom: 'none' }}>
                            <a
                                href={`${config.srpnetBaseUrl}account/electric/manage-bill/pay-electric-bill`}
                                target="_blank">
                                {t("SurePay")}
                            </a>
                        </div>
                        <div className="col-3 pt-2 pb-2" style={autopayHasBorderEnrolled ? borderStyle : { borderBottom: 'none' }}>
                            {autopayInfo.surePayDate !== "0001-01-01T00:00:00" && autopayInfo.surePayDate !== null
                                ? t("surepay_date", {surePayDate: formatDayWithOrdinal(DateTime.fromISO(autopayInfo.surePayDate).day)})
                                : ""
                            }
                        </div>
                        <div className="col-3 pt-2 pb-2 text-right" style={autopayHasBorderEnrolled ? borderStyle : { borderBottom: 'none' }}>
                            <Link to="/myaccount/payment/autopay/edit">{t("Change date")}</Link>
                        </div>
                    </div>
                    <div className="row" style={programs.budgetBilling.isEnrolled && isBudgetBillingActive ? { display: 'flex' } : { display: 'none' }}>
                        <div className="col-3 text-muted pt-2 pb-2">{budgetBillingHasLabelEnrolled ? t("Enrolled Programs") : ""}</div>
                        <div className="col-3 pt-2 pb-2">
                            <a
                                href={`${config.srpnetBaseUrl}account/electric/manage-bill/budget-billing-residential`}
                                target="_blank">
                                {t("Budget Billing")}
                            </a>
                        </div>
                        <div className="col-3 pt-2 pb-2">
                            {t("Amount_dollars", {amount: budgetBillingAmount.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")})}
                        </div>
                        <div className="col-3 pt-2 pb-2 text-right" />
                    </div>
                    <div className="row" style={numOfEnrolledPrograms === 0 ? { display: 'flex' } : { display: 'none' }}>
                        <div className="col-3 text-muted pt-2 pb-2">{numOfEnrolledPrograms === 0 ? t("Enrolled Programs") : ""}</div>
                        <div className="col-9 pt-2 pb-2">{t("youre_not_enrolled_in_any_programs")}</div>
                    </div>
                </div>
                <Divider />
                <div className="srp-card-details pt-2 pb-0">
                    <div className="row" style={programs.autopay.isAvailable && autopayInfo.isEligibleToEnroll ? { display: 'flex' } : { display: 'none' }}>
                        <div className="col-3 pt-2 pb-2" style={{ color: 'rgb(128, 34, 95)' }}>{t("Available Programs")}</div>
                        <div className="col-3 pt-2 pb-2">
                            <a
                                href={`${config.srpnetBaseUrl}account/electric/manage-bill/pay-electric-bill`}
                                target="_blank">
                                {t("SurePay")}
                            </a>
                        </div>
                        <div className="col-3 pt-2 pb-2">{t("Automatic bill payments")}</div>
                        <div className="col-3 pt-2 pb-2 text-right">
                            <Link to="/myaccount/payment/autopay">{t("Enroll")}</Link>
                        </div>
                    </div>
                    <div className="row" style={!programs.autopay.isAvailable ? { display: 'flex' } : { display: 'none' }}>
                        <div className="col-3 pt-2 pb-2" style={{ color: 'rgb(128, 34, 95)' }}>{t("Available Programs")}</div>
                        <div className="col-9 pt-2 pb-2">{t("youre_enrolled_in_all_programs")}</div>
                    </div>
                </div>
                <Divider />
                <div className="srp-card-details pb-0" />
            </div>

            <div className="d-lg-none d-block">
                <div className="srp-card-details text-muted font-weight-bold pb-0">{t("Enrolled Programs")}</div>
                <div className="srp-card-details pt-2 pb-2 row" style={programs.autopay.isEnrolled ? { alignItems: 'center' } : { display: 'none' }}>
                    <div className="col align-middle ml-3">
                        <div className="font-weight-bold">{t("SurePay")}</div>
                        <div>
                            {autopayInfo.surePayDate !== "0001-01-01T00:00:00" && autopayInfo.surePayDate !== null
                                ? t("surepay_date", {surePayDate: formatDayWithOrdinal(DateTime.fromISO(autopayInfo.surePayDate).day)})
                                : ""
                            }
                        </div>
                    </div>
                    <div className="col text-right">
                        <Link to="/myaccount/payment/autopay/edit">{t("Change date")}</Link>
                    </div>
                </div>
                <div style={programs.autopay.isEnrolled && autopayHasBorderEnrolled ? { display: 'block' } : { display: 'none' }}><Divider /></div>

                <div className="srp-card-details pt-2 pb-2 row" style={programs.budgetBilling.isEnrolled && isBudgetBillingActive ? { alignItems: 'center' } : { display: 'none' }}>
                    <div className="col align-middle ml-3">
                        <div className="font-weight-bold">
                            <a
                                href={`${config.srpnetBaseUrl}account/electric/manage-bill/budget-billing-residential`}
                                target="_blank">
                                {t("Budget Billing")}
                            </a>
                        </div>
                        <div>{t("Amount_dollars", {amount: budgetBillingAmount.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")})}</div>
                    </div>
                    <div className="col text-right" />
                </div>

                <div className="srp-card-details pt-2 pb-2 row" style={numOfEnrolledPrograms === 0 ? { alignItems: 'center' } : { display: 'none' }}>
                    <div className="col align-middle ml-3">{t("youre_not_enrolled_in_any_programs")}</div>
                </div>

                <Divider />

                <div className="srp-card-details font-weight-bold pb-0" style={{ color: 'rgb(128, 34, 95)' }}>{t("Available Programs")}</div>

                <div className="srp-card-details pt-2 pb-2 row" style={programs.autopay.isAvailable && autopayInfo.isEligibleToEnroll ? { alignItems: 'center' } : { display: 'none' }}>
                    <div className="col align-middle ml-3">
                        <div className="font-weight-bold">
                            <a
                                href={`${config.srpnetBaseUrl}account/electric/manage-bill/pay-electric-bill`}
                                target="_blank">
                                {t("SurePay")}
                            </a>
                        </div>
                        <div>{t("Automatic bill payments")}</div>
                    </div>
                    <div className="col text-right">
                        <Link to="/myaccount/payment/autopay">{t("Enroll")}</Link>
                    </div>
                </div>

                <div className="srp-card-details pt-2 pb-2 row" style={!programs.autopay.isAvailable ? { alignItems: 'center' } : { display: 'none' }}>
                    <div className="col align-middle ml-3">{t("youre_enrolled_in_all_programs")}</div>
                </div>

                <Divider />
                <div className="srp-card-details pb-0" />
            </div>
        </div>
    )
}

SummaryMasterPrograms.propTypes = {
    programs: PropTypes.object,
    numOfEnrolledPrograms: PropTypes.number,
    autopayInfo: PropTypes.object,
    budgetBillingAmount: PropTypes.number,
    isBudgetBillingActive: PropTypes.bool,
    isCommercial: PropTypes.bool,
    t: PropTypes.func.isRequired,
}

export default SummaryMasterPrograms