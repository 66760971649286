import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import SearchFieldWithAutocomplete from './search-field-with-autocomplete'

import {
    findClosestPayLocations,
    clearClosestPayLocations,
    getStartAddressForDirections,
    getSearchAddressCoords } from '../../actions/guest/pay-location-actions'
import * as loadingStatus from '../../constants/loading-status-constants'

let google
class PayLocationSearch extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            searchAddress: this.props.startAddress,
            searchAddressError: false,
            searchAddressErrorMessage: ""
        }

        this.determineclosestPayLocations = this.determineclosestPayLocations.bind(this)
    }

    componentDidMount() {
        if (this.props.paymentLocationStatus === loadingStatus.LOADING_STATUS_SUCCESS
            && Object.keys(this.props.currentLocation).length !== 0) {
            this.determineclosestPayLocations(this.props.currentLocation, this.props.filteredPayLocationList, false)
        }
    }

    componentDidUpdate(prevProps) {
        if ((prevProps.paymentLocationStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS
                && this.props.paymentLocationStatus === loadingStatus.LOADING_STATUS_SUCCESS
                && Object.keys(this.props.currentLocation).length !== 0)
            || (Object.keys(prevProps.currentLocation).length === 0
                && Object.keys(this.props.currentLocation).length !== 0
                && this.props.paymentLocationStatus === loadingStatus.LOADING_STATUS_SUCCESS)) {
            this.determineclosestPayLocations(this.props.currentLocation, this.props.filteredPayLocationList, false)
        }
    }

    determineclosestPayLocations(coords, paymentLocations, isFromSearch) {
        if(paymentLocations.length === 0)
            return

        google = window.google
        let sortedAddress = []
        let topFivePayLocations = []

        if (Object.keys(coords).length !== 0) {
            for (let i = 0; i < paymentLocations.length; i++) {
                sortedAddress.push({
                    name: paymentLocations[i].retailer,
                    street: paymentLocations[i].street,
                    city: paymentLocations[i].city,
                    stateZip: `${paymentLocations[i].state} ${paymentLocations[i].zip}`,
                    distance: google.maps.geometry.spherical.computeDistanceBetween(coords, new google.maps.LatLng(paymentLocations[i].latitude, paymentLocations[i].longitude)),
                    type: paymentLocations[i].paylocationType,
                    typeName: paymentLocations[i].paylocationType === 0 ? "SRP PayCenter®" : "Retail location"
                })
            }

            sortedAddress.sort((a, b) => {
                return a.distance - b.distance
            })

            for (let i = 0; i < 5; i++) {
                topFivePayLocations.push({
                    name: sortedAddress[i].name,
                    street: sortedAddress[i].street,
                    city: sortedAddress[i].city,
                    stateZip: sortedAddress[i].stateZip,
                    address: `${sortedAddress[i].street} ${sortedAddress[i].city}, ${sortedAddress[i].stateZip}`,
                    distanceInMiles: (sortedAddress[i].distance * 0.000621371).toFixed(1),
                    type: sortedAddress[i].type,
                    typeName: sortedAddress[i].typeName,
                    startAddress: coords
                })
            }
        }

        this.props.findClosestPayLocationsOnSubmit(topFivePayLocations, isFromSearch)
    }

    getCoords(event, closestPayLocationsFunc, list, address, t) {
        event.preventDefault()
        if (address.length > 0) {
            this.setState({
                searchAddress: address,
                searchAddressError: false,
                searchAddressErrorMessage: ""
            })
        }
        else {
            this.setState({
                searchAddress: address,
                searchAddressError: true,
                searchAddressErrorMessage: t("Address or zip code required")
            })

            this.props.clearClosestPayLocationsOnClick()

            return
        }

        google = window.google
        let thisGeocoder = this
        let geocoder = new google.maps.Geocoder()
        geocoder.geocode({ 'address': address }, function (results, status) {
            let coords = []
            if (status === google.maps.GeocoderStatus.OK) {
                let arizonaAddress = thisGeocoder.props.restrictToArizona(results)
                if (Object.keys(arizonaAddress).length !== 0) {
                    thisGeocoder.setState({
                        searchAddress: arizonaAddress.formatted_address,
                    })
                    coords = arizonaAddress.geometry.location
                    closestPayLocationsFunc(coords, list, true)
                    thisGeocoder.props.getSearchAddressCoords(coords.lat(), coords.lng())
                }
                else {
                    thisGeocoder.setState({
                        searchAddressError: true,
                        searchAddressErrorMessage: t("Valid AZ address is required")
                    })
                    thisGeocoder.props.clearClosestPayLocationsOnClick()
                }
            }
            else {
                thisGeocoder.setState({
                    searchAddressError: true,
                    searchAddressErrorMessage: t("Valid AZ address is required")
                })
            }
        })
    }

    onSearchChange(value){
        this.setState({
            searchAddress: value
        })
        this.props.getStartAddress(value)
    }

    render() {
        return (
            <div>
                <div className="srp-card-details">
                    <form onSubmit={(event) => this.getCoords(event, this.determineclosestPayLocations, this.props.filteredPayLocationList, this.props.startAddress, this.props.t)}>
                        <div className="d-flex flex-lg-nowrap flex-wrap">
                            <div className="d-flex mr-2 flex-grow-1">
                                <SearchFieldWithAutocomplete
                                    id="searchAddress"
                                    searchValue={this.state.searchAddress}
                                    label={this.props.t("Find a payment location")}
                                    placeholderText={this.props.t("Enter address or zip code")}
                                    hasError={this.state.searchAddressError}
                                    errorText={this.state.searchAddressError ? this.state.searchAddressErrorMessage : ""}
                                    handleChange={this.onSearchChange.bind(this)} />
                            </div>
                            <div className="d-flex align-items-center mr-md-5 pr-md-5">
                                <div><button type="submit" className="btn srp-btn btn-green">{this.props.t("Submit")}</button></div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return { ...state.paymentLocation }
}

const mapDispatchToProps = dispatch => {
    return {
        findClosestPayLocationsOnSubmit: (list, isFromSearch) => {
            dispatch(findClosestPayLocations(list, isFromSearch))
        },
        clearClosestPayLocationsOnClick: () => {
            dispatch(clearClosestPayLocations())
        },
        getStartAddress: (address) => {
            dispatch(getStartAddressForDirections(address))
        },
        getSearchAddressCoords: (lat, lng) => {
            dispatch(getSearchAddressCoords(lat, lng))
        }
    }
}

PayLocationSearch.propTypes = {
    paymentLocationStatus: PropTypes.string,
    currentLocation: PropTypes.object,
    findClosestPayLocationsOnSubmit: PropTypes.func,
    clearClosestPayLocationsOnClick: PropTypes.func,
    filteredPayLocationList: PropTypes.array,
    startAddress: PropTypes.string,
    getStartAddress: PropTypes.func
}

export default connect(mapStateToProps, mapDispatchToProps)(PayLocationSearch)