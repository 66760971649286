import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Formik } from 'formik'
import * as Yup from 'yup'

import EditBankForm from './edit-bank-form'

import AnalyticsFormTracking from '../../common_formik/analytics-form-tracking'

import { createValidationRulesFromeChexBankInfoValidationErrors } from '../../gift_payment_page/input_page/single-payment-formik-container'

import { updateBankAccount, deleteBankAccount, showDeleteBankModule, closeDeleteBankModule, closeDeleteBankErrorModule, storeUpdatedBankAccount } from '../../../actions/auth/payment/bank-account-actions'
import { addSnackbarMessage } from '../../../actions/common/snackbar'
import format from '../../../srp_modules/format'
import { withRouter } from '../../../srp_modules/with-router'

class EditBankFormik extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            initialChangedState: false,
        }
        this.onStateChanged = this.onStateChanged.bind(this)

        this.validationSchema = Yup.object().shape({
            isDefaultOnMyAcct: Yup.boolean(),
            isDefaultWithIVR: Yup.boolean(),
            shareAmount: Yup.number(),
            bankFirstName: Yup.string().ensure().trim()
                .required('First name is required.')
                .test('not all spaces', 'First name is required.', bankFirstName => typeof bankFirstName === "string" && bankFirstName.trim().length > 0),
            bankLastName: Yup.string().ensure().trim()
                .required('Last name is required.')
                .test('not all spaces',
                    'Last name is required.',
                    bankLastName => typeof bankLastName === "string" && bankLastName.trim().length > 0)
                .test('at least 2 characters',
                    'Last name must have at least two characters.',
                    bankLastName => typeof bankLastName === "string" && bankLastName.trim().length > 1),
            bankPhoneNumber: Yup.string().ensure().trim()
                .required('Phone number is required.')
                .test('not all spaces', 'Phone number is required.',
                    bankPhoneNumber => typeof bankPhoneNumber === "string" && bankPhoneNumber.trim().length > 0)
                .test('must have 10 digits',
                    'Phone number must have 10 digits.',
                    bankPhoneNumber => typeof bankPhoneNumber === "string" && /^\d{10}$/.test(bankPhoneNumber.replace(/[() -]/g, ''))),
            bankEmailAddress: Yup.string().ensure().trim()
                .required('Email address is required.')
                .test('not all spaces',
                    'Email address is required.',
                    bankEmailAddress => typeof bankEmailAddress === "string" && bankEmailAddress.trim().length > 0)
                .email('Invalid email address.'),
            bankRoutingNumber: Yup.string().ensure().trim()
                .required('Routing number is required.')
                .test('not all spaces',
                    'Routing number is required.',
                    bankRoutingNumber => typeof bankRoutingNumber === "string" && bankRoutingNumber.trim().length > 0)
                .test('must have 9 digits',
                    'Enter a valid 9-digit routing number.',
                    bankRoutingNumber => typeof bankRoutingNumber === "string" && /^\d{9}$/.test(bankRoutingNumber.trim())),
            bankAccountNumberLeadingZeroesAreSignificant: Yup.string().ensure().trim()
                .required('Bank account number is required.')
                .test('not all spaces',
                    'Bank account number is required.',
                    bankAccountNumber => typeof bankAccountNumber === "string" && bankAccountNumber.trim().length > 0)
                .test('17 digits max',
                    'Your account number must be 1-17 digits.',
                    bankAccountNumber => typeof bankAccountNumber === "string" && /^\d{1,17}$/.test(bankAccountNumber.trim())),
            bankAccountNickname: Yup.string().ensure().trim()
                .test('35 characters max',
                    'Your bank account nickname cannot have more than 35 characters.',
                    nickname => nickname === undefined || (typeof nickname === "string" && nickname.trim().length <= 35)),
        })
        this.initialValues = {}
    }

    onStateChanged() {
        this.setState({
            initialChangedState: true
        })
    }

    transformValues(values) {
        return {
            ...values,
            bankFirstName: values.bankFirstName.trim(),
            bankLastName: values.bankLastName.trim(),
            bankPhoneNumber: values.bankPhoneNumber.replace(/[() -]/g, ''),
            bankEmailAddress: values.bankEmailAddress.trim(),
            bankRoutingNumber: values.bankRoutingNumber.trim(),
            bankAccountNumberLeadingZeroesAreSignificant: values.bankAccountNumberLeadingZeroesAreSignificant.trim(),
            bankAccountNickname: values.bankAccountNickname.trim(),
        }
    }

    render() {
        return (
            <Formik
                initialValues={{
                    bankFirstName: this.props.bankAccountToEdit.firstName,
                    bankLastName: this.props.bankAccountToEdit.lastName,
                    bankPhoneNumber: this.props.bankAccountToEdit.phoneNumber,
                    bankEmailAddress: this.props.bankAccountToEdit.email,
                    bankRoutingNumber: format.formatRoutingNumber(this.props.bankAccountToEdit.routingNumber).toString(),
                    bankAccountNumberLeadingZeroesAreSignificant: this.props.bankAccountToEdit.bankAccountNumber,
                    bankAccountNickname: this.props.bankAccountToEdit.nickName,
                    isDefaultOnMyAcct: this.props.bankAccountToEdit.isDefault,
                    isDefaultWithIVR: this.props.bankAccountToEdit.isPhoneEligible,
                }}
                validationSchema={this.validationSchema}
                onSubmit={(values, formikProps) => {
                    let _values = this.transformValues(values)

                    let bankAccountInfo = {
                        bankAccountNumber: _values.bankAccountNumberLeadingZeroesAreSignificant,
                        bankId: this.props.bankAccountToEdit.bankId,
                        bankIdToken: this.props.bankAccountToEdit.bankIdToken,
                        nickName: _values.bankAccountNickname,
                        routingNumber: _values.bankRoutingNumber,
                        firstName: _values.bankFirstName,
                        lastName: _values.bankLastName,
                        phoneNumber: _values.bankPhoneNumber,
                        isDefaultAccount: _values.isDefaultOnMyAcct,
                        emailAddress: _values.bankEmailAddress,
                        isDefaultWithIVR: _values.isDefaultWithIVR,
                    }

                    this.props.submitEditBankForm(bankAccountInfo, this.props.selectedBillAccount,
                        this.props.allBillAccountsAreCommercial, formikProps, this.validationSchema)

                }}>
                {formikProps => (<React.Fragment>
                    <AnalyticsFormTracking name="Edit Bank" formStep="bank details" meta={{}}
                        onStateChanged={this.onStateChanged} initialChangedState={this.state.initialChangedState} />
                    <EditBankForm
                    {...formikProps}
                    {...this.props}
                    {...this.state}
                    deleteBankAccountOnClick={this.props.deleteBankAccountOnClick}
                    cancelOnClick={this.props.cancelOnClick}
                    showDeleteBankModuleOnClick={this.props.showDeleteBankModuleOnClick}
                    closeDeleteBankModuleOnClick={this.props.closeDeleteBankModuleOnClick}
                    closeDeleteBankErrorModuleOnClick={this.props.closeDeleteBankErrorModuleOnClick}
                    />
                </React.Fragment>)}
            </Formik>
        )
    }
}

const mapStateToProps = state => {
    return {
        ...state.accountInfo.billAccount,
        ...state.bankAccounts,
        allBillAccountsAreCommercial: state.accountInfo.billAccount.billAccountList?.every(b => b.isCommercial) ?? false
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        submitEditBankForm: async (bankInfo, billAccount, allBillAccountsAreCommercial, formikProps, validationSchema) => {
            let response = await dispatch(updateBankAccount(bankInfo, billAccount, allBillAccountsAreCommercial))
            if (response.error) {
                formikProps.setStatus({hasUnhandledError: true, submitError: true})
                formikProps.setSubmitting(false)
                return false
            }

            if (!response.payload.isSuccess) {
                const bankInputValues = {
                    bankFirstName: bankInfo.firstName,
                    bankLastName: bankInfo.lastName,
                    bankRoutingNumber: bankInfo.routingNumber,
                    bankAccountNumber: bankInfo.bankAccountNumber
                }

                const eChexAddBankAccountErrors = response.payload.saveBankAccountErrors
                const numErrorsHandled = createValidationRulesFromeChexBankInfoValidationErrors(validationSchema.fields, eChexAddBankAccountErrors, bankInputValues)
                const hasUnhandledeChexValidationError = (Object.keys(eChexAddBankAccountErrors).length - numErrorsHandled) > 0
                formikProps.setStatus({hasUnhandledError: hasUnhandledeChexValidationError, submitError: true})
                formikProps.validateForm()

                formikProps.setSubmitting(false)

                if (eChexAddBankAccountErrors.errorBankAccountSetupLocked)
                    ownProps.router.navigate('/myaccount/profile/banks/locked')

                return false
            }
            else {
                dispatch(storeUpdatedBankAccount(bankInfo))
                formikProps.setStatus({submitError: false})
                formikProps.setSubmitting(false)
                ownProps.router.navigate(-1)
            }
        },
        cancelOnClick: () => {
            ownProps.router.navigate(-1)
        },
        deleteBankAccountOnClick: async (bankToDelete, allBillAccountsAreCommercial, t) => {
            let response = await dispatch(deleteBankAccount(bankToDelete.bankIdToken, allBillAccountsAreCommercial))
            if (response.error || !response.payload.isSuccess)
                return

            let snackbarMessage = bankToDelete.nickName.length > 0 ? t("bank_nickname_removed", {bankToDeleteNickName: bankToDelete.nickName}) : t("Bank account removed")

            ownProps.router.navigate(-1)
            dispatch(addSnackbarMessage(<span>{snackbarMessage}</span>))
        },
        showDeleteBankModuleOnClick: (bankAccountInfo) => {
            dispatch(showDeleteBankModule(bankAccountInfo))
        },
        closeDeleteBankModuleOnClick: () => {
            dispatch(closeDeleteBankModule());
        },
        closeDeleteBankErrorModuleOnClick: () => {
            dispatch(closeDeleteBankErrorModule())
        }
    }
}

EditBankFormik.propTypes = {
    selectedBillAccount: PropTypes.number,
    selectedBillAccountDetails: PropTypes.object,
    isCommercial: PropTypes.bool,
    allBillAccountsAreCommercial: PropTypes.bool.isRequired,

    bankAccountToEdit: PropTypes.object,
    editBankAccountStatus: PropTypes.string,
    updateBankAccount: PropTypes.func,
    submitEditBankForm: PropTypes.func,

    storeUpdatedBankAccount: PropTypes.func,

    bankAccountToDelete: PropTypes.object,
    deleteBankAccountStatus: PropTypes.string,
    deleteBankAccountOnClick: PropTypes.func,

    showDeleteBankModule: PropTypes.bool,
    showDeleteBankModuleOnClick: PropTypes.func,
    cancelOnClick: PropTypes.func.isRequired,
    closeDeleteBankModuleOnClick: PropTypes.func,
    showDeleteBankErrorModule: PropTypes.bool,
    closeDeleteBankErrorModuleOnClick: PropTypes.func,

    router: PropTypes.shape({
        navigate: PropTypes.func
    }),
    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired,
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EditBankFormik))