import * as loadingStatus from '../../../../constants/loading-status-constants'
import { giftPaymentMultiConstants } from '../../../../constants/payment/gift-payment-multi-constants'

const initialState = {
    bankAccountId: 0,
    paymentList: [],
    addBankInfo: { useAddBankCard: false },
    verifyMultiPaymentResult: {},
    verifyMultiPaymentResultStatus: loadingStatus.LOADING_STATUS_INIT,
    submitMultiPaymentResult: {},
    submitMultiPaymentResultStatus: loadingStatus.LOADING_STATUS_INIT
}

export default function giftPaymentMultiReducer(state = initialState, action) {
    switch(action.type) {
        case giftPaymentMultiConstants.GIFT_PAYMENT_MULTI_POPULATE_PAYMENTS:
            return { ...state, bankAccountId: action.paymentInfo.bankAccountId, paymentList: action.paymentInfo.payments }

        case giftPaymentMultiConstants.GIFT_PAYMENT_MULTI_POPULATE_PAYMENT: {
            let newPaymentList = state.paymentList.slice()
            newPaymentList.push({
                accountNumber: action.billAccount,
                paymentAmount: action.paymentAmount
            })

            return { ...state, paymentList: newPaymentList }
        }

        case giftPaymentMultiConstants.GIFT_PAYMENT_MULTI_ADD_BANK_INFO: {
            let newAddBankInfo = { 
                useAddBankCard: true,
                bankAccountNumber: action.values.bankAccountNumber,
                bankNickname: action.values.bankNickname,
                emailAddress: action.values.emailAddress,
                firstName: action.values.firstName,
                lastName: action.values.lastName,
                phoneNumber: action.values.phoneNumber,
                routingNumber: action.values.routingNumber
            }

            return { ...state, addBankInfo: newAddBankInfo }
        }

        // verify paymnet
        case giftPaymentMultiConstants.VERIFY_GIFT_MULTIPLE_REQUEST:
            return { ...state, verifyMultiPaymentResultStatus: loadingStatus.LOADING_STATUS_IN_PROGRESS }
        case giftPaymentMultiConstants.VERIFY_GIFT_MULTIPLE_SUCCESS:
            return { ...state, verifyMultiPaymentResult: action.payload, verifyMultiPaymentResultStatus: loadingStatus.LOADING_STATUS_SUCCESS }
        case giftPaymentMultiConstants.VERIFY_GIFT_MULTIPLE_FAILURE:
            return { ...state, verifyMultiPaymentResult: initialState.verifyMultiPaymentResult, verifyMultiPaymentResultStatus: loadingStatus.LOADING_STATUS_FAILURE }

        // submit payment
        case giftPaymentMultiConstants.SUBMIT_GIFT_MULTIPLE_REQUEST:
            return { ...state, submitMultiPaymentResultStatus: loadingStatus.LOADING_STATUS_IN_PROGRESS }
        case giftPaymentMultiConstants.SUBMIT_GIFT_MULTIPLE_SUCCESS:
            return { ...state, submitMultiPaymentResult: action.payload, submitMultiPaymentResultStatus: loadingStatus.LOADING_STATUS_SUCCESS }
        case giftPaymentMultiConstants.SUBMIT_GIFT_MULTIPLE_FAILURE:
            return { ...state, submitMultiPaymentResult: initialState.submitMultiPaymentResult, submitMultiPaymentResultStatus: loadingStatus.LOADING_STATUS_FAILURE}
          
        case giftPaymentMultiConstants.GIFT_PAYMENT_MULTI_CLEAR_PAYMENTS:
            return { ...state, 
                paymentList: initialState.paymentList, 
                verifyMultiPaymentResult: initialState.verifyMultiPaymentResult, 
                verifyMultiPaymentResultStatus: initialState.verifyMultiPaymentResultStatus,
                submitMultiPaymentResultStatus: initialState.submitMultiPaymentResultStatus,
                addBankInfo: initialState.addBankInfo
            }
        default:
            return state
    }

}