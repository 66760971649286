import React from 'react'
import PropTypes from 'prop-types'

const DocumentSuccess = ({primaryEmail, t}) => {
    return (
        <div className="mt-4">
            <div className="srp-alert-success" style={{marginTop:'-20px', marginBottom: '20px'}}>
                <strong>{t("SUCCESS")}</strong> {t("request_submitted", {primaryEmail : primaryEmail})}
            </div>
        </div>
    )
}

DocumentSuccess.propTypes = {
    primaryEmail: PropTypes.string,
    t: PropTypes.func
}

export default DocumentSuccess
