import React from "react"
import PropTypes from 'prop-types'
import { DateTime } from 'luxon'

const PaymentDetails = props => {
    let bankUsedList = props.bankAccountList.filter(ba => ba.bankId === props.fundingSourceId)
    let maskedBankAccountNumber = 'unknown'
    let routingNumber = 'unknown'
    let t = props.t

    if (bankUsedList.length === 1)
    {
        maskedBankAccountNumber = '****' + bankUsedList[0].accountNumber
        routingNumber = bankUsedList[0].routingNumber.toString()
    }

    return (
    <table className="table">
        <tbody>
            <tr>
                <td className="pl-0 srp-fit border-top-0">{t("Payment amount")}</td>
                <td className="border-top-0">{'$'+props.paymentAmount.toFixed(2)}</td>
            </tr>
            <tr>
                <td className="pl-0 srp-fit">{t("Payment date")}</td>
                <td>{DateTime.fromISO(props.paymentDate).startOf('day').toFormat('MM/dd/yyyy')}</td>
            </tr>
            <tr>
                <td className="pl-0 srp-fit">{t("Bank routing number")}</td>
                <td>{routingNumber}</td>
            </tr>
            <tr>
                <td className="pl-0 srp-fit">{t("Bank account number")}</td>
                <td>{maskedBankAccountNumber}</td>
            </tr>
            <tr>
                <td className="pl-0 srp-fit">{t("Scheduled by")}</td>
                <td>{props.scheduledBy}</td>
            </tr>
            <tr>
                <td className="pl-0 srp-fit">{t("Scheduled via")}</td>
                <td>{props.scheduledVia}</td>
            </tr>
            <tr>
                <td className="pl-0 srp-fit border-bottom">{t("Reference number")}</td>
                <td className="border-bottom">{props.referenceNumber}</td>
            </tr>
        </tbody>
    </table>
    )
}

PaymentDetails.propTypes = {
    paymentAmount: PropTypes.number.isRequired,
    paymentDate: PropTypes.string.isRequired,
    scheduledVia: PropTypes.string.isRequired,
    referenceNumber: PropTypes.number.isRequired,
    fundingSourceId: PropTypes.number.isRequired,
    bankAccountList: PropTypes.arrayOf(PropTypes.shape({
        accountNumber: PropTypes.string.isRequired,
        routingNumber: PropTypes.number.isRequired
    })).isRequired,
    scheduledBy: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired,
    i18n: PropTypes.object.isRequired
}

export default PaymentDetails
