import { mapScriptConstants } from '../../constants/map-script-constants'

export const getMapScriptRequest = () => ({
    type: mapScriptConstants.MAP_SCRIPT_REQUEST
})

export const getMapScriptSuccess = () => ({
    type: mapScriptConstants.MAP_SCRIPT_SUCCESS
})

export const getMapScriptFailure = () => ({
    type: mapScriptConstants.MAP_SCRIPT_FAILURE
})