/* eslint react/no-multi-comp: "off" */

import React from 'react'
import PropTypes from 'prop-types'

import Checkbox from '@mui/material/Checkbox'
import { PatternFormat } from 'react-number-format'
import format from '../../../srp_modules/format'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'


function getFullStreetAddress(address) {
    let unitNum = ""
    if (address.unitNumber) {
        unitNum = "#" + address.unitNumber
    }

    let fullStreetAddress = address.houseNumber + " " + address.addressDirection + " " + address.streetName + " " + unitNum

    if (fullStreetAddress.length > 24) {
        fullStreetAddress = fullStreetAddress.substring(0, 11) + "..." + fullStreetAddress.substring(fullStreetAddress.length - 10)
    }

    return fullStreetAddress
}

function getRowTitle(subAccount, viewBillClick) {
    if (subAccount.accountName && subAccount.accountName.trim() !== '') {
        return (
            <div className="clearfix" style={{position: 'relative'}}>
                <div style={{fontSize: '18px', fontWeight: '800', float: 'left'}}>
                    <div>
                        <button
                            className="displayAsLink text-truncate"
                            type="button"
                            style={{ maxWidth: '200px'}}
                            onClick={() => viewBillClick(subAccount.billAccount)}
                        >
                            {subAccount.accountName}
                        </button>
                    </div>
                    <div style={{color: '#333333', fontWeight: '500'}}>
                        <PatternFormat
                            value={format.srpAccountNumber(subAccount.billAccount)}
                            valueIsNumericString={true}
                            format="###-###-###"
                            displayType="text"
                        />
                    </div>
                </div>
            </div>
        )
    } else {
        return (
            <div className="clearfix" style={{position: 'relative'}}>
                <div style={{float: 'left'}}>
                    <button
                        className="displayAsLink"
                        style={{fontSize: '18px', fontWeight: '800'}}
                        type="button"
                        onClick={() => viewBillClick(subAccount.billAccount)}
                    >
                        <PatternFormat
                            value={format.srpAccountNumber(subAccount.billAccount)}
                            valueIsNumericString={true}
                            format="###-###-###"
                            displayType="text"
                        />
                    </button>
                </div>
            </div>
        )
    }
}
const SummaryBillingSubAccountResponsive = ({subAccount, viewBillClick, showSubAccounts}) => {
    let className = showSubAccounts ? "pat-tr-separator-visible" : "pat-tr-separator"

    return (
        <React.Fragment>
            <TableRow
                    hover
                    role="checkbox"
                    aria-checked={false}
                    tabIndex={-1}
                    selected={false}
                    className="open-close"
                >
                    <TableCell className={className} padding="none" style={{ verticalAlign: "top", paddingTop: "10px", width: "45px"}}>
                        <Checkbox
                            onChange={() => {}}
                            disabled={true}
                            color="primary"
                            checked={false}
                        />
                    </TableCell>
                    <TableCell className={className} style={{ paddingBottom: "20px"}}>
                        {getRowTitle(subAccount, viewBillClick)}
                        <div className="clearfix" style={{ position: "relative" }}>
                            <div>
                                {getFullStreetAddress(subAccount.serviceAddress)}
                            </div>
                            <div className="clearfix" style={{position: 'relative'}}>
                                <div style={{float: 'left', color: 'rgba(33, 33, 33, 0.537)'}}>
                                    Amount due:
                                </div>
                                <div style={{float: 'right', fontWeight: '800'}}>
                                    - -
                                </div>
                            </div>
                        </div>
                    </TableCell>
                </TableRow>
        </React.Fragment>
    )
}

SummaryBillingSubAccountResponsive.propTypes = {
    subAccount: PropTypes.object.isRequired,
    viewBillClick: PropTypes.func.isRequired,
    showSubAccounts: PropTypes.bool.isRequired
}

export default SummaryBillingSubAccountResponsive