import { connect } from 'react-redux'
import { getBankAccountToEdit } from '../../../actions/auth/payment/bank-account-actions'
import { withRouter } from '../../../srp_modules/with-router'

import GiftPaymentSubmittedCard from './gift-payment-submitted-card'

const mapStateToProps = state => {
    const selectedBankId = state.giftPaymentBankInfo.selectedBankId
    const bankAccount = state.bankAccounts.bankAccountList.find(ba => ba.bankId === selectedBankId)
    const confirmationEmailAddress = bankAccount !== undefined ? bankAccount.email : '<no email>'

    const confirmationEmailSent = state.giftPaymentApiResponse.confirmationEmailSent
    const referenceNumber = state.giftPaymentApiResponse.referenceNumber

    return { referenceNumber, confirmationEmailAddress, confirmationEmailSent, bankAccountUsed: bankAccount }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
    updateBankEmailAddressOnClick: (bankAccountUsed) => {
        if (bankAccountUsed === undefined) {
            ownProps.router.navigate('/myaccount/profile/banks')
        } else {
            dispatch(getBankAccountToEdit(bankAccountUsed))
            ownProps.router.navigate('/myaccount/profile/banks/editbankaccount')
        }
    }
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GiftPaymentSubmittedCard))