import { usageConstants } from '../../../constants/usage-constants'

const initialState = {
    //usage card dashboard
    hourlyUsage: [],
    hourlyRangeUsage: [],
    yesterdayUsage: [],
    dailyUsage: [],
    monthlyUsage: [],
    usageChartTab: 0,
}

export default function usageReducer(state = initialState, action) {
    switch (action.type) {
        case usageConstants.GET_HOURLY_SUCCESS:
            return { ...state, hourlyUsage: action.hourlyUsage }
        case usageConstants.GET_HOURLY_RANGE_SUCCESS:
            return { ...state, hourlyRangeUsage: action.hourlyRangeUsage }
        case usageConstants.GET_YESTERDAY_SUCCESS:
            return { ...state, yesterdayUsage: action.yesterdayUsage }
        
        case usageConstants.GET_MONTHLY_SUCCESS:
            return { ...state, monthlyUsage: action.monthlyUsage }
        case usageConstants.GET_USAGE_CHART_TAB:
            return { ...state, usageChartTab: action.value}
        default:
            return state;
    }
}