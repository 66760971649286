import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { dailyUsageChartTypeConstants } from '../../constants/daily-usage-chart-type-constants'
import DailyNetChartContainer from './daily-net-chart-container'
import DailyUsageChartContainer from './daily-usage-chart-container'
import DailyTableView from './daily-table-view'
import Divider from "@mui/material/Divider"
import '../../styles/usage.css'

import { getUsageDailyChartType, getDailyDataTable  } from '../../actions/auth/usage/usage-actions'
import exportUsageModule from '../../srp_modules/charting/export'

class DailyNetContainer extends React.Component {
    constructor(props) {
        super(props)

        this.exportDailyNetToExcel = this.exportDailyNetToExcel.bind(this)
        this.getUsageDataType = this.getUsageDataType.bind(this)
    }

    exportDailyNetToExcel(dataType, chartType) {
        let dataToExport = this.getUsageDataType(dataType)
        exportUsageModule.getUsageChartExport(dataToExport, dataType, chartType, this.props.dailyWeatherDataList)
    }

    getUsageDataType(dataType) {
        let usageData
        switch (dataType) {
            case dailyUsageChartTypeConstants.DEMAND:
                usageData = this.props.dailyDemandList
                break
            case dailyUsageChartTypeConstants.CONSUMPTION:
                usageData = this.props.dailyConsumptionList
                break
            case dailyUsageChartTypeConstants.GENERATION:
                usageData = this.props.dailyGenerationList
                break
            case dailyUsageChartTypeConstants.NET_ENERGY:
            case dailyUsageChartTypeConstants.USAGE:
            case dailyUsageChartTypeConstants.COST:
                usageData = this.props.dailyUsageList
                break
        }

        let dataWithDateRange = exportUsageModule.getUsageDataWithDateRange(usageData, this.props.dailyStartDate, this.props.dailyEndDate)
        return dataWithDateRange
    }

    render() {
        let isCommercialSolar = this.props.rateMetaData.rateMetaData.isCommercial && this.props.rateMetaData.rateMetaData.isSolar
        let NetOrDeliveredChipVerbiage = isCommercialSolar ? this.props.t("Delivered") : this.props.t("Net energy")
        let clickVerbiage = ""
        switch (this.props.currentDailyChart) {
            case dailyUsageChartTypeConstants.USAGE:
                clickVerbiage = this.props.rateMetaData.rateMetaData.isIDRRate
                    ? this.props.t("Click a bar to see hourly billed energy")
                    : this.props.t("Click a bar to see hourly usage")
                break
            case dailyUsageChartTypeConstants.COST:
                clickVerbiage = this.props.t("Click a bar to see hourly cost")
                break
            case dailyUsageChartTypeConstants.NET_ENERGY:
                clickVerbiage = isCommercialSolar
                ? this.props.t("Click a bar to see hourly delivered energy")
                : this.props.t("Click a bar to see hourly net energy")
                break
            case dailyUsageChartTypeConstants.GENERATION:
                clickVerbiage = this.props.t("Click a bar to see hourly generation")
                break
            case dailyUsageChartTypeConstants.DEMAND:
                clickVerbiage = this.props.t("Click a bar to see hourly demand")
                break
            case dailyUsageChartTypeConstants.EXPORT:
                clickVerbiage = this.props.t("Click a bar to see hourly export")
                break
            case dailyUsageChartTypeConstants.CONSUMPTION:
                clickVerbiage = this.props.t("Click a bar to see hourly usage")
                break
        }
        return (
            <div className="ml-4 mr-4 pb-2">

                {this.props.showDailyDataTable
                    ? <div className="d-none d-lg-block mb-4"/>
                    : <div className="d-none d-lg-block mb-4">
                        <div >{clickVerbiage}</div>
                    </div>
                }

                <div className="d-none d-lg-block chart-btn-container">
                    <span className="mr-2">{this.props.t("View my")}</span>
                    <button className={this.props.dailyButtonClassNames.netBtnClassName} onClick={() => this.props.getUsageDailyChartTypeOnClick(dailyUsageChartTypeConstants.NET_ENERGY)}>{NetOrDeliveredChipVerbiage}</button>
                    <button className={this.props.dailyButtonClassNames.generationBtnClassName} onClick={() => this.props.getUsageDailyChartTypeOnClick(dailyUsageChartTypeConstants.GENERATION)}>{this.props.t("Generation")}</button>
                    <button className={this.props.dailyButtonClassNames.consumptionBtnClassName} onClick={() => this.props.getUsageDailyChartTypeOnClick(dailyUsageChartTypeConstants.CONSUMPTION)}>{this.props.t("Usage")}</button>
                    {this.props.rateMetaData.rateMetaData.isDemand &&
                        <button className={this.props.dailyButtonClassNames.demandBtnClassName} onClick={() => this.props.getUsageDailyChartTypeOnClick(dailyUsageChartTypeConstants.DEMAND)}>{this.props.t("Demand")}</button>
                    }
                </div>
                <div className="d-block d-lg-none">
                    <div className="d-flex justify-content-end">
                        <button
                            className="btn srp-btn btn-lightblue mr-2"
                            onClick={() => this.props.getDailyDataTableOnClick(!this.props.showDailyDataTable)}>
                            {this.props.showDailyDataTable ? this.props.t("View chart") : this.props.t("View data table")}
                        </button>
                        <button
                            className="btn srp-btn btn-lightblue text-white"
                            onClick={() => this.exportDailyNetToExcel(this.props.currentDailyChart, this.props.selectedUsagePageChartTab)}>
                            {this.props.t("Export")}
                        </button>
                    </div>
                </div>
                <Divider className="mt-4 mb-4 d-lg-none" id="top-of-daily-chart"/>
                <div className="d-lg-none mt-2 mb-4">
                    <div className="mr-2 mb-2" >{this.props.t("View my")}</div>
                    <button className={this.props.dailyButtonClassNames.netBtnClassName} onClick={() => this.props.getUsageDailyChartTypeOnClick(dailyUsageChartTypeConstants.NET_ENERGY)}>{NetOrDeliveredChipVerbiage}</button>
                    <button className={this.props.dailyButtonClassNames.generationBtnClassName} onClick={() => this.props.getUsageDailyChartTypeOnClick(dailyUsageChartTypeConstants.GENERATION)}>{this.props.t("Generation")}</button>
                    <button className={this.props.dailyButtonClassNames.consumptionBtnClassName} onClick={() => this.props.getUsageDailyChartTypeOnClick(dailyUsageChartTypeConstants.CONSUMPTION)}>{this.props.t("Usage")}</button>
                    {this.props.rateMetaData.rateMetaData.isDemand &&
                        <button className={this.props.dailyButtonClassNames.demandBtnClassName} onClick={() => this.props.getUsageDailyChartTypeOnClick(dailyUsageChartTypeConstants.DEMAND)}>{this.props.t("Demand")}</button>
                    }
                </div>

                {this.props.showDailyDataTable
                    ? null
                    : <div className="d-block d-lg-none mb-4">
                        <div >{clickVerbiage}</div>
                     </div>
                }

                {this.props.showDailyDataTable
                    ? null
                    : <div className="d-block d-lg-none mb-4 center-text">
                        <div className={(this.props.currentDailyChart === dailyUsageChartTypeConstants.NET_ENERGY
                            || this.props.currentDailyChart === dailyUsageChartTypeConstants.USAGE
                            || this.props.currentDailyChart === dailyUsageChartTypeConstants.CONSUMPTION)
                            ? "block-element"
                            : "hidden-element"}>{this.props.t("Usage (kWh)")}
                        </div>
                        <div className={this.props.currentDailyChart === dailyUsageChartTypeConstants.GENERATION ? "block-element" : "hidden-element"}>{this.props.t("Generation (kWh)")}</div>
                        <div className={this.props.currentDailyChart === dailyUsageChartTypeConstants.DEMAND ? "block-element" : "hidden-element"}>{this.props.t("Peak Demand")}</div>
                    </div>
                }

                {this.props.showDailyDataTable
                    ? <DailyTableView
                        usageData={this.getUsageDataType(this.props.currentDailyChart)}
                        currentUsageType={this.props.currentDailyChart}
                        weather={this.props.dailyWeatherDataList} 
                        t={this.props.t}/>
                    : this.props.currentDailyChart === dailyUsageChartTypeConstants.NET_ENERGY
                        ? <DailyNetChartContainer t={this.props.t}/>
                        : (this.props.currentDailyChart === dailyUsageChartTypeConstants.DEMAND
                            || this.props.currentDailyChart === dailyUsageChartTypeConstants.USAGE
                            || this.props.currentDailyChart === dailyUsageChartTypeConstants.CONSUMPTION
                            || this.props.currentDailyChart === dailyUsageChartTypeConstants.GENERATION
                        )
                            ? <DailyUsageChartContainer t={this.props.t}/>
                            : null
                }

                <div className="d-none d-lg-block mt-4">
                    <div className="d-flex justify-content-end mb-1">
                        <button
                            className="btn srp-btn btn-lightblue mr-2"
                            onClick={() => this.props.getDailyDataTableOnClick(!this.props.showDailyDataTable)}>
                            {this.props.showDailyDataTable ? this.props.t("View as chart") : this.props.t("View as data table")}
                        </button>
                        <button
                            className="btn srp-btn btn-lightblue text-white"
                            onClick={() => this.exportDailyNetToExcel(this.props.currentDailyChart, this.props.selectedUsagePageChartTab)}>
                            {this.props.t("Export to Excel")}
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        ...state.accountInfo.usage,
        ...state.accountInfo.usagePageUI,
        ...state.dailyUsage,
        ...state.weatherData,
        rateMetaData: state.rateMetaData,

    }
}

const mapDispatchToProps = dispatch => {
    return {
        getUsageDailyChartTypeOnClick: (type) => {
            dispatch(getUsageDailyChartType(type))
        },
        getDailyDataTableOnClick: (showTable) => {
            dispatch(getDailyDataTable(showTable))
        }
    }
}

DailyNetContainer.propTypes = {
    showDailyCostVerbiage: PropTypes.object,
    showDailyUsageVerbiage: PropTypes.object,
    showDailyCostChart: PropTypes.bool,
    showDailyNetEnergyChart: PropTypes.bool,
    dailyButtonClassNames: PropTypes.object,
    getUsageDailyChartTypeOnClick: PropTypes.func,
    rateMetaData: PropTypes.object,
    currentDailyChart: PropTypes.string,
    dailyUsageList: PropTypes.arrayOf(PropTypes.object),
    dailyDemandList: PropTypes.arrayOf(PropTypes.object),
    dailyConsumptionList: PropTypes.arrayOf(PropTypes.object),
    dailyGenerationList: PropTypes.arrayOf(PropTypes.object),
    dailyStartDate: PropTypes.object,
    dailyEndDate: PropTypes.object,
    selectedUsagePageChartTab: PropTypes.number,
    dailyWeatherDataList: PropTypes.arrayOf(PropTypes.object),
    getDailyDataTableOnClick: PropTypes.func,
    showDailyDataTable: PropTypes.bool,
    t: PropTypes.func
}

export default connect(mapStateToProps, mapDispatchToProps)(DailyNetContainer);