import React from 'react'
import PropTypes from 'prop-types'

import ResidentialIconBlue from '../../../images/forms/home-blue.svg'
import ResidentialIconGrey from '../../../images/forms/home-grey.svg'
import CommercialIconBlue from '../../../images/forms/business-blue.svg'
import CommercialIconGrey from '../../../images/forms/business-grey.svg'


const accountTypeBtn = {
    borderRadius: '10px',
    border: '2px solid #DADADB',
    backgroundColor: 'white',
    color: '#087ED8',
    fontWeight: '400',
    width: '100%'
}

const accountTypeBtnSelected = {
    borderRadius: '10px',
    border: '3px solid #087ED8',
    backgroundColor: 'white',
    color: '#087ED8',
    fontWeight: '700',
    width: '100%'
}

const iconStyle = {
    width: '40px',
    height: '100%'
}

const CustomDueDateCommercialToggle = ({ showCommercial, setShowCommercial, t }) => {
    return (
        <div>
            <div>
                {t("What type of account is this?")}
            </div>
            <div className="row">
                <div className="col-6">
                    <button
                        className="button-focus pointer-btns"
                        type="button"
                        name="accountType"
                        onClick={() => setShowCommercial(false)}
                        style={showCommercial ? accountTypeBtn : accountTypeBtnSelected}>
                        <img
                            className="mt-2 mb-1 img-fluid"
                            src={showCommercial ? ResidentialIconGrey : ResidentialIconBlue}
                            style={iconStyle} />
                        <div className="mb-2">{t("Residential")}</div>
                    </button>
                </div>
                <div className="col-6">
                    <button
                        className="button-focus pointer-btns"
                        type="button"
                        name="accountType"
                        onClick={() => setShowCommercial(true)}
                        style={showCommercial ? accountTypeBtnSelected : accountTypeBtn}>
                        <img
                            className="mt-2 mb-1 img-fluid"
                            src={showCommercial ? CommercialIconBlue : CommercialIconGrey}
                            style={iconStyle} />
                        <div className="mb-2">{t("Business")}</div>
                    </button>
                </div>
            </div>
        </div>
    )
}

CustomDueDateCommercialToggle.propTypes = {
    showCommercial: PropTypes.bool,
    setShowCommercial: PropTypes.func,
    t: PropTypes.func.isRequired,
}

export default CustomDueDateCommercialToggle