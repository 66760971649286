import React from 'react'
import { Link } from 'react-router-dom'

export const bankAccountConstants = {
    GET_BANK_ACCOUNT_LIST_REQUEST: 'GET_BANK_ACCOUNT_LIST_REQUEST',
    GET_BANK_ACCOUNT_LIST_SUCCESS: 'GET_BANK_ACCOUNT_LIST_SUCCESS',
    GET_BANK_ACCOUNT_LIST_FAILURE: 'GET_BANK_ACCOUNT_LIST_FAILURE',

    POPULATE_ADD_BANK_ACCOUNT: 'POPULATE_ADD_BANK_ACCOUNT',

    ADD_BANK_ACCOUNT_VALIDATION_REQUEST: 'ADD_BANK_ACCOUNT_VALIDATION_REQUEST',
    ADD_BANK_ACCOUNT_VALIDATION_SUCCESS: 'ADD_BANK_ACCOUNT_VALIDATION_SUCCESS',
    ADD_BANK_ACCOUNT_VALIDATION_FAILURE: 'ADD_BANK_ACCOUNT_VALIDATION_FAILURE',    

    CHANGE_SELECTED_BANK_ACCOUNT_SUCCESS: 'CHANGE_SELECTED_BANK_ACCOUNT_SUCCESS',

    // add bank account constants
    GET_FIRST_NAME: 'GET_FIRST_NAME',
    GET_LAST_NAME: 'GET_LAST_NAME',
    GET_PHONE_NUMBER: 'GET_PHONE_NUMBER',
    GET_EMAIL_ADDRESS: 'GET_EMAIL_ADDRESS',
    GET_ROUTING_NUMBER: 'GET_ROUTING_NUMBER',
    GET_BANK_ACCOUNT_NUMBER: 'GET_BANK_ACCOUNT_NUMBER',
    GET_NICKNAME: 'GET_NICKNAME',
    
    ADD_BANK_ACCOUNT_INFO_CHANGE: 'ADD_BANK_ACCOUNT_INFO_CHANGE',
    ADD_BANK_EARLY_WARNING_REQUEST: 'ADD_BANK_EARLY_WARNING_REQUEST',
    ADD_BANK_EARLY_WARNING_SUCCESS: 'ADD_BANK_EARLY_WARNING_SUCCESS',
    ADD_BANK_EARLY_WARNING_FAILURE: 'ADD_BANK_EARLY_WARNING_FAILURE',

    SHOW_DELETE_BANK_MODULE: 'SHOW_DELETE_BANK_MODULE',
    CLOSE_DELETE_BANK_MODULE: 'CLOSE_DELETE_BANK_MODULE',
    CLOSE_DELETE_BANK_ERROR_MODULE: 'CLOSE_DELETE_BANK_ERROR_MODULE',
    
    DELETE_BANK_ACCOUNT_REQUEST: 'DELETE_BANK_ACCOUNT_REQUEST',
    DELETE_BANK_ACCOUNT_SUCCESS: 'DELETE_BANK_ACCOUNT_SUCCESS',
    DELETE_BANK_ACCOUNT_FAILURE: 'DELETE_BANK_ACCOUNT_FAILURE',

    STORE_UPDATED_BANK_ACCOUNT: 'STORE_UPDATED_BANK_ACCOUNT',
    GET_BANK_TO_EDIT: 'GET_BANK_TO_EDIT',
    EDIT_BANK_ACCOUNT_REQUEST: 'EDIT_BANK_ACCOUNT_REQUEST',
    EDIT_BANK_ACCOUNT_SUCCESS: 'EDIT_BANK_ACCOUNT_SUCCESS',
    EDIT_BANK_ACCOUNT_FAILURE: 'EDIT_BANK_ACCOUNT_FAILURE',

    HAS_SCHEDULED_PMT_MSG: <span>Bank account cannot be deleted while a payment is scheduled. Go to <Link style={{color: 'white', textDecoration: 'underline'}} to="/myaccount/payment">your payments</Link> to remove the scheduled payment.</span>,
    HAS_PENDING_PMT_COMM: "Bank account cannot be deleted as pending payment(s) are being processed. For more information, please call 602-236-8833, Monday through Friday. 7 a.m.-5 p.m.",
    HAS_PENDING_PMT_RES: "Bank account cannot be deleted as pending payments(s) are being processed. For more information, please call 602-236-8888.",
}