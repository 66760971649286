import { billPageConstants } from '../../../constants/bill-page-constants'
import { eBillConstants } from '../../../constants/ebill-constants'
import { billAccountConstants } from '../../../constants/bill-account-constants'
import * as loadingConstants from '../../../constants/loading-status-constants'
import config from 'my-account-config'
import { DateTime } from 'luxon'

const initialState = {
    selectedBill: 0,
    selectedBillName: '',
    documentPdf: '',
    downloadPdf: '',
    viewBillTab: 0,
    viewBillBlobUrl: '',
    viewBillStatus: loadingConstants.LOADING_STATUS_INIT
}

export default function viewBillReducer (state = initialState, action) {
    switch (action.type) {
        case eBillConstants.GET_E_BILL_LIST_SUCCESS: {
            return { ...state,
                downloadPdf: action.payload.documentList.length !== 0 ? getDocumentPDF(action.payload.documentList[0], false) : ""
            }
        }

        case billPageConstants.GET_SELECTED_BILL_REQUEST: {
            return { ...state,
                viewBillStatus: loadingConstants.LOADING_STATUS_IN_PROGRESS
            }
        }
        case billPageConstants.GET_SELECTED_BILL_SUCCESS: {
            return { ...state,
                selectedBill: action.meta.billId,
                selectedBillName: action.meta.billName,
                documentPdf: action.payload.documentPdf,
                downloadPdf: action.payload.downloadPdf,
                viewBillBlobUrl: action.payload.viewBillBlobUrl,
                viewBillStatus: loadingConstants.LOADING_STATUS_SUCCESS
            }
        }
        case billPageConstants.GET_SELECTED_BILL_FAILURE: {
            return { ...state,
                viewBillStatus: loadingConstants.LOADING_STATUS_FAILURE
            }
        }
        case billPageConstants.GET_VIEW_BILL_TAB: {
            return { ...state, viewBillTab: action.tab }
        }
        case billAccountConstants.SELECT_BILL_ACCOUNT_SUCCESS:
            return { ...initialState }
        default:
            return state
    }
}

function getDocumentPDF(document, isInline) {
    let date = DateTime.fromISO(document.documentDate).toFormat('MMMMyyyy')
    if(document.documentType === eBillConstants.DOCUMENT_TYPE_BILL) {
        return config.apiBaseUrl+"api/documents/eBillDocument?billAccount="+document.billingID+"&documentId="+encodeURIComponent(document.documentID)+"&date="+date+"&inline="+isInline
    }
    else {
        return config.apiBaseUrl+"api/documents/eBillReminder?billAccount="+document.billingID+"&documentId="+encodeURIComponent(document.documentID)+"&date="+date+"&inline="+isInline
    }
}