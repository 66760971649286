import React from 'react'
import PropTypes from 'prop-types'
import FormControl from '@mui/material/FormControl'
import MenuItem from '@mui/material/MenuItem'
import TextField from '@mui/material/TextField'

import PurchaseHistoryTable from './purchase-history-table'
import { DateTime, Settings} from 'luxon'


const monthYearDropdownStyle = {
    minWidth: '19%',
    marginRight: '1.5rem',
}

const PurchaseHistoryCard = ({ purchasesHistoryByMonth, grandTotals, amountDue, numOfMonthsDisplayedStart,
    numOfMonthsDisplayedEnd, getStartMonthOnChange, getEndMonthOnChange, startMonth, endMonth, listOfStartMonths, listOfEndMonths,
    getPurchaseHistoryByDateOnClick, isShowPurchaseHistoryDetails, showPurchaseDetails, purchasHistoryIndex, getPrevPurchaseHistoryOnClick,
    getNextPurchaseHistoryOnClick, selectedBillAccount, serviceAddress, customerName, flattenedPurchaseHistoryDetails, t }) => {

    const isEmptyPurchaseHistory = (flattenedPurchaseHistoryDetails.length === 0)

    return (
        <div className="srp-card-body">
            {!isEmptyPurchaseHistory && <div className="srp-card-details d-print-none d-flex flex-wrap">
                <div>
                    <FormControl style={monthYearDropdownStyle} disabled={isEmptyPurchaseHistory}>
                        <TextField
                            select
                            value={startMonth}
                            label={t("Start month")}
                            onChange={event => getStartMonthOnChange(event.target.value)}
                            className="mt-0"
                        >
                            {getmonthSelectorList(listOfStartMonths)}
                        </TextField>
                    </FormControl>
                    <FormControl style={monthYearDropdownStyle} disabled={isEmptyPurchaseHistory}>
                        <TextField
                            select
                            value={endMonth}
                            label={t("End month")}
                            onChange={event => getEndMonthOnChange(event.target.value)}
                            className="mt-0"
                        >
                            {getmonthSelectorList(listOfEndMonths)}
                        </TextField>
                    </FormControl>
                </div>
                <div>
                    <button className="btn srp-btn btn-blue-purchase-history text-nowrap mt-2" disabled={isEmptyPurchaseHistory} onClick={() => getPurchaseHistoryByDateOnClick(startMonth, endMonth)}>{t("View history")}</button>
                </div>
            </div>}

            <PurchaseHistoryTable
                purchasesHistoryByMonth={purchasesHistoryByMonth}
                grandTotals={grandTotals}
                isShowPurchaseHistoryDetails={isShowPurchaseHistoryDetails}
                showPurchaseDetails={showPurchaseDetails}
                numOfMonthsDisplayedStart={numOfMonthsDisplayedStart}
                numOfMonthsDisplayedEnd={numOfMonthsDisplayedEnd}
                purchasHistoryIndex={purchasHistoryIndex}
                getPrevPurchaseHistoryOnClick={getPrevPurchaseHistoryOnClick}
                getNextPurchaseHistoryOnClick={getNextPurchaseHistoryOnClick}
                startDate={startMonth}
                endDate={endMonth}
                selectedBillAccount={selectedBillAccount}
                serviceAddress={serviceAddress}
                customerName={customerName}
                flattenedPurchaseHistoryDetails={flattenedPurchaseHistoryDetails}
                amountDueToSRP={amountDue} />

        </div>

    )

}

PurchaseHistoryCard.propTypes = {
    purchasesHistoryByMonth: PropTypes.arrayOf(PropTypes.object),
    flattenedPurchaseHistoryDetails: PropTypes.arrayOf(PropTypes.object),
    grandTotals: PropTypes.object,
    amountDue: PropTypes.number,

    numOfMonthsDisplayedStart: PropTypes.number,
    numOfMonthsDisplayedEnd: PropTypes.number,

    getStartMonthOnChange: PropTypes.func,
    getEndMonthOnChange: PropTypes.func,
    startMonth: PropTypes.string,
    endMonth: PropTypes.string,
    listOfStartMonths: PropTypes.array,
    listOfEndMonths: PropTypes.array,
    getPurchaseHistoryByDateOnClick: PropTypes.func,

    isShowPurchaseHistoryDetails: PropTypes.arrayOf(PropTypes.bool),
    showPurchaseDetails: PropTypes.func,

    purchasHistoryIndex: PropTypes.number,
    getPrevPurchaseHistoryOnClick: PropTypes.func,
    getNextPurchaseHistoryOnClick: PropTypes.func,

    selectedBillAccount: PropTypes.number,
    serviceAddress: PropTypes.string,
    customerName: PropTypes.string,
    t: PropTypes.func.isRequired
}

export default PurchaseHistoryCard

function getmonthSelectorList(months) {
    let items = [];
    for (let i = 0; i < months.length; i++) {
        let item = months[i]
        if(Settings.defaultLocale == "es"){
            item = DateTime.fromFormat(months[i], "MMMM yyyy", {locale : 'en'}).setLocale('es').toFormat("MMMM yyyy")
        }
        items.push(<MenuItem
            key={months[i]}
            value={months[i]}
        >{item}</MenuItem>)
    }
    return items
}