import React from 'react'
import PropTypes from 'prop-types'

import CircularProgress from '@mui/material/CircularProgress'

import { myAccountConstants } from '../../../constants/myaccount-constants'

import SRPAccountNumberField from './input-fields/srp-account-number-field'

import SelectABankAccount from './input-fields/select-a-bank-account'

import AddBankAccountForm from '../common/add-bank-account-form'

import PaymentAmountField from './input-fields/payment-amount-field'

const SinglePaymentFormFunc = ({
    t, i18n,
    bankAccountList, bankFormOpen, isSubmitting, status, 
    setPayMultipleAccountsButtonClicked, clearPayMultipleAccountsButtonClicked, addBankAccountButtonClicked, addBankCancelButtonClicked,
    values, errors, touched, handleChange, handleBlur, handleSubmit,
    formDataChanged, setFieldValue, setFieldTouched, submitForm
}) => {
    let commonFormikProps = {
        onChange: (e) => {handleChange(e); formDataChanged(e);},
        onBlur: handleBlur,
        errors,
        touched
    }
    let commonTextFieldProps = {
        fullWidth: true
    }

    const verifyStatus = status || {}

    const showCancelButton = bankAccountList.length > 0

    return (
        <div>
        {verifyStatus.hasUnhandledeChexValidationError ? (
            <div className="srp-alert-error mb-3">
                {t("We_are_unable_to_complete", {phone: i18n.language==='es' ? myAccountConstants.SPANISH_CUSTOMER_SERVICE_PHONE_NUMBER_FMTTD : myAccountConstants.RESIDENTIAL_CUSTOMER_SERVICE_PHONE_NUMBER_FMTTD})}
            </div>
        ) : null}

        <form method="POST" onSubmit={handleSubmit}>
            <label className="h4 text-muted">{t('Bank information')}</label>

            {bankFormOpen &&
            <p className="text-muted">{t('Enter_bank_info_to_save')}</p>
            }

            {bankFormOpen === false ?
            <SelectABankAccount
                {...commonFormikProps}
                bankAccountIdSelected={values.bankAccountIdSelected}
                bankAccountList={bankAccountList}
                addBankAccountButtonClicked={addBankAccountButtonClicked}
                setFieldValue={setFieldValue}
                isSubmitting={isSubmitting}
                t={t}
            />
            :
            <AddBankAccountForm
                values={values}
                errors={errors}
                touched={touched}
                handleChange={commonFormikProps.onChange}
                handleBlur={handleBlur}
                showCancelButton={showCancelButton}
                handleBankFormCancel={() => addBankCancelButtonClicked(setFieldValue)}
                isSubmitting={isSubmitting}
                t={t}
                i18n={i18n}
            />
            }

            <label className="h4 text-muted mt-4">{t('Payment details')}</label>

            <div className="mt-1"><SRPAccountNumberField label="SRP account number to pay" autoFocus={true} {...commonFormikProps} {...commonTextFieldProps} value={values.srpAccountNumber} t={t} /></div>

            <div className="mt-3"><PaymentAmountField {...commonFormikProps} {...commonTextFieldProps} setFieldValue={setFieldValue} setFieldTouched={setFieldTouched} value={values.paymentAmount} t={t} /></div>

            <div className="d-flex justify-content-end mt-4 flex-wrap">
                <button className="btn srp-btn btn-lightblue mb-2" type="button" disabled={isSubmitting} onClick={() => {setPayMultipleAccountsButtonClicked(); verifyStatus.hasUnhandledeChexValidationError = false; submitForm()}}>{t('Pay multiple accounts')}</button>
                <button className="btn srp-btn btn-blue mb-2 ml-2" type="submit" disabled={isSubmitting} onClick={() => {clearPayMultipleAccountsButtonClicked(); verifyStatus.hasUnhandledeChexValidationError = false}}>
                    {isSubmitting ? <CircularProgress size={20} thickness={5} style={{color:'white'}} /> : t('Confirm payment')}
                </button>
            </div>
        </form>
        </div>
    )
}

SinglePaymentFormFunc.propTypes = {
    t:  PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired,
    values: PropTypes.shape({
        srpAccountNumber: PropTypes.string.isRequired,
        bankAccountIdSelected: PropTypes.number.isRequired,
        bankFirstName: PropTypes.string.isRequired,
        bankLastName: PropTypes.string.isRequired,
        bankPhoneNumber: PropTypes.string.isRequired,
        bankEmailAddress: PropTypes.string.isRequired,
        bankRoutingNumber: PropTypes.string.isRequired,
        bankAccountNumberLeadingZeroesAreSignificant: PropTypes.string.isRequired,
        bankNickname: PropTypes.string.isRequired,
        paymentAmount: PropTypes.string.isRequired
    }).isRequired,
    errors: PropTypes.shape({
        srpAccountNumber: PropTypes.oneOfType([
            PropTypes.string.isRequired,
            PropTypes.shape({
                getLocalizedErrMsg: PropTypes.func.isRequired,
            }),
        ]),
        bankAccountIdSelected: PropTypes.oneOfType([
            PropTypes.string.isRequired,
            PropTypes.shape({
                getLocalizedErrMsg: PropTypes.func.isRequired,
            }),
        ]),
        bankFirstName: PropTypes.string,
        bankLastName: PropTypes.string,
        bankPhoneNumber: PropTypes.string,
        bankEmailAddress: PropTypes.string,
        bankRoutingNumber: PropTypes.string,
        bankAccountNumberLeadingZeroesAreSignificant: PropTypes.string,
        bankNickname: PropTypes.string,
        paymentAmount: PropTypes.oneOfType([
            PropTypes.string.isRequired,
            PropTypes.shape({
                getLocalizedErrMsg: PropTypes.func.isRequired,
            }),
        ]),
    }).isRequired,
    touched: PropTypes.shape({
        srpAccountNumber: PropTypes.bool,
        bankAccountIdSelected: PropTypes.bool,
        bankFirstName: PropTypes.bool,
        bankLastName: PropTypes.bool,
        bankPhoneNumber: PropTypes.bool,
        bankEmailAddress: PropTypes.bool,
        bankRoutingNumber: PropTypes.bool,
        bankAccountNumberLeadingZeroesAreSignificant: PropTypes.bool,
        bankNickname: PropTypes.bool,
        paymentAmount: PropTypes.bool
    }).isRequired,
    handleChange: PropTypes.func.isRequired,
    handleBlur: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
    bankFormOpen: PropTypes.bool.isRequired,
    addBankAccountButtonClicked: PropTypes.func.isRequired,
    addBankCancelButtonClicked: PropTypes.func.isRequired,
    status: PropTypes.shape({
        hasUnhandledeChexValidationError: PropTypes.bool
    }),
    setPayMultipleAccountsButtonClicked: PropTypes.func.isRequired,
    clearPayMultipleAccountsButtonClicked: PropTypes.func.isRequired,
    submitForm: PropTypes.func.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    setFieldTouched: PropTypes.func.isRequired,
    bankAccountList: PropTypes.arrayOf(PropTypes.shape({
        bankId: PropTypes.number.isRequired,
        bankName: PropTypes.string.isRequired,
        nickName: PropTypes.string.isRequired,
        accountNumber: PropTypes.string.isRequired
    })).isRequired,

    formDataChanged: PropTypes.func.isRequired,
}

export default SinglePaymentFormFunc