import { DateTime } from 'luxon'

const paymentUtils = {
    getDateFieldProps,
    getPaymentAmount,
    getPaymentDate,
    isPaymentOverAmountDue,
    isPaymentUnderAmountDue,
    isPaymentDateAfterDueDate,
    getBankAccounteChexValidationErrors,
    getBankAccounteChexValidationErrors2,
    getShareAmount
}

function getDateFieldProps(hasScheduledPayment) {
    let dateFieldProps = {
        minDate: DateTime.now().startOf('day').toFormat('yyyy-MM-dd'),
        maxDate: hasScheduledPayment ?
            DateTime.now().startOf('day').toFormat('yyyy-MM-dd') :
            DateTime.now().plus({ days: 59 }).startOf('day').toFormat('yyyy-MM-dd'),
        minDateMessage: "Payment date cannot be earlier than today's date.",
        maxDateMessage: hasScheduledPayment ?
            "You already scheduled a payment. You can make an additional payment for today only." :
            "You cannot schedule a payment more than 60 days in advance.",
        invalidDateMessage: "Invalid payment date. Please enter a date in MM/DD/YY format.",
        disablePast: true,
        disableFuture: hasScheduledPayment,
        views: [ 'day' ] // limits month/year dropdown selection on calendar picker
    }

    return dateFieldProps
}

function getPaymentAmount(billAccount, payment, makePaymentInfo) {
    let amount = (makePaymentInfo && makePaymentInfo.accountNumber === billAccount ? makePaymentInfo.amount : payment.amountDueWithShare).toString()
    let shareAmount = (makePaymentInfo && makePaymentInfo.accountNumber === billAccount ? makePaymentInfo.shareAmount : '0').toString()
    amount = parseFloat(amount.trim().replace(/[$,]/g, '')) - parseFloat(shareAmount.trim().replace(/[$,]/g, ''))
    amount = amount < 0 ? 0 : amount
    return amount.toFixed(2)
}

function getPaymentDate(billAccount, defaultDate, makePaymentInfo) {
    let date = makePaymentInfo && makePaymentInfo.accountNumber === billAccount ? makePaymentInfo.paymentDate : defaultDate
    return DateTime.fromISO(date).startOf('day').toFormat('yyyy-MM-dd')
}

function isPaymentOverAmountDue(amount, amountDue) {
    return Number(amount) > 0 && Number(amount) > Number(amountDue)
}

function isPaymentUnderAmountDue(amount, amountDue) {
    return Number(amount) < Number(amountDue)
}

function isPaymentDateAfterDueDate(paymentDate, dueDate) {
    return DateTime.fromISO(paymentDate).startOf('day') > (DateTime.fromISO(dueDate).startOf('day'))
}

function getBankAccounteChexValidationErrors(eChexValidationErrors) {

    // BankRoutingNumberValidationSchema
    let ErrNoBank = false
    let ErrLockedBank = false
    let ErrMYAInternational = false
    // BankAccountNumberLeadingZeroesAreSignificantValidationSchema
    let ErrInvalidBankAcctLen = false
    let ErrLockedBankAcct = false
    let ErrMYANonInternational = false
    // SRPAccountNumberValidationSchema
    let ErrOnSurePay = false
    let ErrCashOnly = false
    let ErrBankruptcy = false
    let ErrClosedSrpAcct = false
    let ErrInvalidBillStatus = false
    let ErrInvalidCustomerAcctLen = false
    let ErrInvalidSrpAcct = false
    let ErrMissingSrpAccount = false
    let ErrNoSrpAcct = false

    let hasUnhandledBankAccountValidationError = false
    let hasUnhandledPaymentValidationError = false

    if (eChexValidationErrors.bankAccountError !== "") {
        switch (eChexValidationErrors.bankAccountError) {
            case 'errInvalidBankAcctLen':
            case 'InvalidLength':
                ErrInvalidBankAcctLen = true
                break
            case 'errLockedBankAcct':
            case 'errMYAC03':
            case 'errMYACLS':
            case 'errMYACO1':
            case 'errMYACO2':
            case 'errMYACUS':
            case 'errMYADUP':
            case 'errMYAFRD':
            case 'errMYAFRZ':
            case 'errMYAINV':
            case 'errMYALOC':
            case 'errMYALST':
            case 'errMYANAU':
            case 'errMYANON':
            case 'errMYANTA':
            case 'errMYAOTHER':
            case 'errMYAUNK':
            case 'Locked':
                ErrLockedBankAcct = true
                break
            default:
                hasUnhandledBankAccountValidationError = true
                break
        }
    }

    if (eChexValidationErrors.routingNumberError !== "") {
        switch (eChexValidationErrors.routingNumberError) {
            case 'errNoBank':
            case 'Invalid':
                ErrNoBank = true
                break
            case 'errLockedBank':
                ErrLockedBank = true
                break
            case 'errMYAInternational':
            case 'InvalidInternational':
                ErrMYAInternational = true
                break
            default:
                hasUnhandledBankAccountValidationError = true
                break
        }
    }

    let eChexErrors = {
        ErrNoBank,
        ErrLockedBank,
        ErrMYAInternational,
        ErrInvalidBankAcctLen,
        ErrLockedBankAcct,
        ErrMYANonInternational,
        ErrOnSurePay,
        ErrCashOnly,
        ErrBankruptcy,
        ErrClosedSrpAcct,
        ErrInvalidBillStatus,
        ErrInvalidCustomerAcctLen,
        ErrInvalidSrpAcct,
        ErrMissingSrpAccount,
        ErrNoSrpAcct,
        hasUnhandledBankAccountValidationError,
        hasUnhandledPaymentValidationError
    }

    return eChexErrors
}

function getBankAccounteChexValidationErrors2(eChexValidationErrors) {
    let eChexErrors = {
        ErrNoBank: false,
        ErrLockedBank: false,
        ErrMYAInternational: false,
        ErrInvalidBankAcctLen: false,
        ErrLockedBankAcct: false,
        ErrMYANonInternational: false,
        ErrOnSurePay: false,
        ErrCashOnly: false,
        ErrBankruptcy: false,
        ErrClosedSrpAcct: false,
        ErrInvalidBillStatus: false,
        ErrInvalidCustomerAcctLen: false,
        ErrInvalidSrpAcct: false,
        ErrMissingSrpAccount: false,
        ErrNoSrpAcct: false,
        hasUnhandledBankAccountValidationError: false,
        hasUnhandledPaymentValidationError: false
    }

    for (let errorCode in eChexValidationErrors)
        switch(errorCode)
        {
            case 'errNoBank':
                eChexErrors.ErrNoBank = true
                break
            case 'errLockedBank':
                eChexErrors.ErrLockedBank = true
                break
            case 'errMYAInternational':
                eChexErrors.ErrMYAInternational = true
                break
            case 'errInvalidBankAcctLen':
                eChexErrors.ErrInvalidBankAcctLen = true
                break
            case 'errLockedBankAcct':
            case 'errMYAC03':
            case 'errMYACLS':
            case 'errMYACO1':
            case 'errMYACO2':
            case 'errMYACUS':
            case 'errMYADUP':
            case 'errMYAFRD':
            case 'errMYAFRZ':
            case 'errMYAINV':
            case 'errMYALOC':
            case 'errMYALST':
            case 'errMYANAU':
            case 'errMYANON':
            case 'errMYANTA':
            case 'errMYAOTHER':
            case 'errMYAUNK':
                eChexErrors.ErrLockedBankAcct = true
                break
        }

    return eChexErrors
}

function getShareAmount(makePaymentInfo) {
    let shareAmount = (makePaymentInfo ? makePaymentInfo.shareAmount : '0').toString()
    shareAmount = parseFloat(shareAmount.trim().replace(/[$,]/g, ''))
    shareAmount = shareAmount < 0 ? 0 : shareAmount
    return shareAmount
}

export default paymentUtils
