import React from 'react'
import PropTypes from 'prop-types'

let getErrorMessageStyle = (open) => {
    return {
        color: "#424242",
        backgroundColor: "rgba(244, 231, 204, 1)",
        borderRadius: "4px",
        fontSize: "14px",
        padding: "5px 10px 5px 10px",
        display: open ? "block" : "none"
    }
}

const CardInformation = ({open, message}) => (
    <div style={getErrorMessageStyle(open)}>
        {message}
    </div>
)

CardInformation.propTypes = {
    open: PropTypes.bool.isRequired,
    message: PropTypes.string.isRequired
}

export default CardInformation