import React from 'react'
import PropTypes from 'prop-types'
import ReactDOM from 'react-dom'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Divider from '@mui/material/Divider'
import Dialog from '@mui/material/Dialog'
import { ThemeProvider, StyledEngineProvider, createTheme } from '@mui/material/styles'

import ClosestPaymentLocations from './closest-payment-locations'
import SearchFieldWithAutocomplete from './search-field-with-autocomplete'
import RetailLocationDays from './retail-location-days'

import '../../styles/map.css'

import PayCenterIcon from '../../images/payment_location/paycenter-pin.svg'
import RetailIcon from '../../images/payment_location/retail-pin.svg'
import SelectedPaymentLocIcon from '../../images/payment_location/selected-pin.svg'
import CashIcon from '../../images/payment_location/cash.svg'

import {
    hidePayLocationDetails, showPayLocationDetails, getCurrentLocation,
    getEndAddressForDirections, getPayLocationMap, showStartAddrDetails, hideStartAddrDetails, clearClosestPayLocations,
    setFilteredPayLocationList
} from '../../actions/guest/pay-location-actions'
import * as loadingStatus from '../../constants/loading-status-constants'
import { printByIdInNewWindow } from '../../srp_modules/print'
import utils from '../../srp_modules/utils'
import format from '../../srp_modules/format'
import { paymentLocationConstants } from '../../constants/payment-location-constants'

let google
let paymentLocationMarkers = []
window.paymentLocationMap = {}

const overrideInputFontSize = {
    MuiInputBase: {
        styleOverrides: {
            root: {
                fontSize: '16px'
            }
        }
    }
}

const muiTextFieldThemeModal = createTheme({
    components: overrideInputFontSize
})

const muiTextFieldTheme = createTheme({
    palette: { mode: 'dark' },
    components: overrideInputFontSize
})

class PayLocationMapContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            lastValidCenter: {},
            directionsDisplay: {},
            directionsService: {},
            showLegendModal: false,
            showPaymentLocationDetailModal: false,
            showStartAddrModal: false,
            showDirectionsPanel: { display: 'none' },

            startAddress: "",
            startAddressError: false,
            startAddressErrorMessage: "",

            filterMenuAnchorElement: null
        }

        this.toggleLegendModal = this.toggleLegendModal.bind(this)
        this.togglePaymentLocationDetailModal = this.togglePaymentLocationDetailModal.bind(this)
        this.openStartAddressModalOnClick = this.openStartAddressModalOnClick.bind(this)
        this.closeStartAddressModalOnClick = this.closeStartAddressModalOnClick.bind(this)
        this.refreshPaymentLocationMap = this.refreshPaymentLocationMap.bind(this)
        this.openFilterMenu = this.openFilterMenu.bind(this)
        this.selectPayLocationTypeToFilter = this.selectPayLocationTypeToFilter.bind(this)
        this.setPaymentLocationMapCenter = this.setPaymentLocationMapCenter.bind(this)
    }

    componentDidMount() {
        if (this.props.mapScriptLoadedStatus !== loadingStatus.LOADING_STATUS_SUCCESS) {
            window.paymentLocationMap = {}
            return
        }

        this.setPayLocationMap()
    }

    componentDidUpdate(prevProps) {
        if (this.props.mapScriptLoadedStatus !== loadingStatus.LOADING_STATUS_SUCCESS) {
            window.paymentLocationMap = {}
            return
        }

        if(prevProps.currentLocation !== this.props.currentLocation) {
            this.setPayLocationMap()
        }

        if (prevProps.paymentLocationList !== this.props.paymentLocationList
            || prevProps.mapScriptLoadedStatus !== this.props.mapScriptLoadedStatus
            || prevProps.filteredPayLocationList !== this.props.filteredPayLocationList)
            this.plotPayLocations(window.paymentLocationMap, this.props.filteredPayLocationList, this.props.t)

        if(prevProps.searchAddressCoords !== this.props.searchAddressCoords
            && Object.keys(this.props.searchAddressCoords).length > 0) {
                this.setPaymentLocationMapCenter(this.props.searchAddressCoords)
            }
    }

    initMap() {
        google = window.google
        let mapCenter = new google.maps.LatLng(33.4473093599, -111.95285216)

        window.paymentLocationMap = new google.maps.Map(ReactDOM.findDOMNode(this.refs.hiddenPaymentLocationMap), {
            center: mapCenter,
            zoom: 15,
            minZoom: 8,
            mapTypeControl: false,
            streetViewControl: false,
            fullscreenControl: false,
            styles: [{
                featureType: 'poi',
                stylers: [{ visibility: 'off' }]
            },
            {
                featureType: 'poi.park',
                stylers: [{ visibility: 'on' }]
            }]
        })

        this.setState({
            lastValidCenter: mapCenter
        })
    }

    setPayLocationMap() {
        if (this.props.paymentLocationStatus !== loadingStatus.LOADING_STATUS_SUCCESS
            || this.props.mapScriptLoadedStatus !== loadingStatus.LOADING_STATUS_SUCCESS) {
            return
        }

        if (Object.keys(window.paymentLocationMap).length === 0) {
            this.initMap()
        }

        ReactDOM.findDOMNode(this.refs.paymentLocationMap).appendChild(window.paymentLocationMap.getDiv())
        google.maps.event.trigger(window.paymentLocationMap, 'resize')

        let dDisplay = new google.maps.DirectionsRenderer
        let dService = new google.maps.DirectionsService

        dDisplay.setMap(window.paymentLocationMap)
        dDisplay.setPanel(ReactDOM.findDOMNode(this.refs.directionsPanel))

        this.setState({
            directionsDisplay: dDisplay,
            directionsService: dService
        })

        this.setPaymentLocationMapCenter(this.props.currentLocation)

        window.paymentLocationMap.controls[google.maps.ControlPosition.LEFT].clear()
        window.paymentLocationMap.controls[google.maps.ControlPosition.LEFT_BOTTOM].clear()

        let payLocationDetail = ReactDOM.findDOMNode(this.refs.pcDetail)
        window.paymentLocationMap.controls[google.maps.ControlPosition.LEFT].push(payLocationDetail)

        let startAddrDetail = ReactDOM.findDOMNode(this.refs.pcGetStartAddr)
        window.paymentLocationMap.controls[google.maps.ControlPosition.LEFT].push(startAddrDetail)

        this.plotPayLocations(window.paymentLocationMap, this.props.filteredPayLocationList, this.props.t)
    }

    handleLocationError(plMap) {
        plMap.setCenter(new google.maps.LatLng(33.4473093599, -111.95285216))
        this.setState({
            lastValidCenter: new google.maps.LatLng(33.4473093599, -111.95285216)
        })

        plMap.setZoom(15)
    }

    setPaymentLocationMapCenter(coords){
        if (Object.keys(coords).length > 0) {
            let currentLatLng = new google.maps.LatLng(coords.lat, coords.lng)
            window.paymentLocationMap.setCenter(currentLatLng)
            window.paymentLocationMap.panTo(currentLatLng)
            window.paymentLocationMap.setZoom(15)

            this.setState({
                lastValidCenter: currentLatLng
            })
        }
        else {
            this.handleLocationError(window.paymentLocationMap)
        }
    }

    plotPayLocations(map, paymentLocationList, t) {
        google = window.google
        let paymentLocationPosition = {};
        let paymentLocationIcon = "";

        if (paymentLocationMarkers.length != 0) {
            for (let i = 0; i < paymentLocationMarkers.length; i++) {
                paymentLocationMarkers[i].setMap(null)
            }
        }

        if (paymentLocationList.length !== 0) {

            for (let i = 0; i < paymentLocationList.length; i++) {
                let latitude = parseFloat(paymentLocationList[i].latitude)
                let longitude = parseFloat(paymentLocationList[i].longitude)
                paymentLocationPosition = { lat: latitude, lng: longitude }

                if (paymentLocationList[i].paylocationType === 0) {
                    paymentLocationIcon = PayCenterIcon;
                }
                else {
                    paymentLocationIcon = RetailIcon;
                }

                let paymentLocationMarkerInfo = {
                    locationName: paymentLocationList[i].retailer,
                    type: paymentLocationList[i].paylocationType,
                    street: paymentLocationList[i].street,
                    city: paymentLocationList[i].city,
                    stateZip: `${paymentLocationList[i].state} ${paymentLocationList[i].zip}`,
                    phone: paymentLocationList[i].phoneNumber,
                    hours: paymentLocationList[i].hours,
                    notes: paymentLocationList[i].paylocationType === 0
                        ? paymentLocationList[i].directions
                        : paymentLocationList[i].isAccountLookup
                            ? t("Pay at guest services with your account/house number")
                            : t("Pay at checkout with your barcode (service not available at self-checkout)"),
                    paycenterAvailable: paymentLocationList[i].numberAvailable,
                    paycenterUnavailable: paymentLocationList[i].numberUnavailable,
                    days: paymentLocationList[i].days !== null ? paymentLocationList[i].days : []
                }

                let markerIcon = {
                    url: paymentLocationIcon,
                    scaledSize: new google.maps.Size(20, 31),
                    origin: new google.maps.Point(0, 0),
                    anchor: new google.maps.Point(10, 31)
                }

                let markerIconClicked = {
                    url: SelectedPaymentLocIcon,
                    scaledSize: new google.maps.Size(32, 49),
                    origin: new google.maps.Point(0, 0),
                    anchor: new google.maps.Point(16, 49)
                }

                let marker = new google.maps.Marker({
                    icon: markerIcon,
                    position: paymentLocationPosition,
                    map: map,
                    data: paymentLocationMarkerInfo,
                    optimized: false
                })

                paymentLocationMarkers.push(marker)

                let markerThis = this.props
                google.maps.event.addListener(marker, 'click', () => {
                    if (window.innerWidth > 991) {
                        marker.setIcon(markerIconClicked)
                        marker.setAnimation(4)

                        for (let i = 0; i < paymentLocationMarkers.length; i++) {
                            if (marker !== paymentLocationMarkers[i]) {
                                paymentLocationMarkers[i].setIcon({
                                    url: paymentLocationMarkers[i].data.type === 0 ? PayCenterIcon : RetailIcon,
                                    scaledSize: new google.maps.Size(20, 31),
                                    origin: new google.maps.Point(0, 0),
                                    anchor: new google.maps.Point(10, 31)
                                })
                            }
                        }
                    }

                    let markerFullAddress = `${marker.data.street} ${marker.data.city}, ${marker.data.stateZip}`
                    markerThis.showPayLocationDetailsOnClick(marker.data)
                    markerThis.getEndAddressForDirectionsOnClick(markerFullAddress)

                    //mobile
                    if (window.innerWidth <= 991) {
                        this.togglePaymentLocationDetailModal(true)
                    }

                    ReactDOM.findDOMNode(this.refs.hidePcDetails).addEventListener('click', () => {
                        marker.setIcon(markerIcon)
                    })
                })

                google.maps.event.addListener(map, 'zoom_changed', function () {
                    marker.setIcon(markerIcon);
                })
            }
        }

        return map
    }

    getDirectionsFromDetails(event, display, service, start, end, t) {
        event.preventDefault()
        if (start.length > 0) {
            google = window.google
            let thisGeocoder = this
            let geocoder = new google.maps.Geocoder()
            geocoder.geocode({ 'address': start }, function (results, status) {
                if (status === google.maps.GeocoderStatus.OK) {
                    let arizonaAddress = thisGeocoder.props.restrictToArizona(results)
                    if (Object.keys(arizonaAddress).length !== 0) {
                        thisGeocoder.getDirections(display, service, arizonaAddress.formatted_address, end, false, thisGeocoder, t)
                    }
                    else {
                        thisGeocoder.setState({
                            startAddressError: true,
                            startAddressErrorMessage: t("Valid AZ address is required"),
                            showDirectionsPanel: { display: 'none' }
                        })
                    }
                }
                else {
                    thisGeocoder.setState({
                        startAddressError: true,
                        startAddressErrorMessage: t("Valid AZ address is required"),
                        showDirectionsPanel: { display: 'none' }
                    })
                }
            })
        }
        else {
            this.setState({
                startAddressError: true,
                startAddressErrorMessage: t("Address or zip code required"),
                showDirectionsPanel: { display: 'none' }
            })
        }
    }

    getDirections(display, service, start, end, fromTop5, thisMap, t) {
        if (fromTop5) {
            for (let i = 0; i < paymentLocationMarkers.length; i++) {
                paymentLocationMarkers[i].icon.scaledSize = new google.maps.Size(25, 36)
                paymentLocationMarkers[i].icon.anchor = new google.maps.Point(12.5, 36)
            }
        }

        service.route({
            origin: start,
            destination: end,
            travelMode: 'DRIVING'
        }, function (response, status) {
            if (status === 'OK') {
                thisMap.setState({
                    showStartAddrModal: false,
                    startAddressError: false,
                    showDirectionsPanel: { display: 'block' }
                })
                thisMap.props.hideStartAddrDetailsOnClick(false)
                display.setDirections(response)
            }
            else {
                thisMap.setState({
                    startAddressError: true,
                    startAddressErrorMessage: t("Invalid address"),
                    showDirectionsPanel: { display: 'none' }
                })
            }
        })
    }

    toggleLegendModal(display) {
        this.setState({
            showLegendModal: display
        })
    }

    togglePaymentLocationDetailModal(display) {
        this.setState({
            showPaymentLocationDetailModal: display
        })

        if (!display)
            this.props.hidePayLocationDetailsOnClick()
    }

    openStartAddressModalOnClick() {
        this.setState({
            showPaymentLocationDetailModal: false,
            showStartAddrModal: true
        })

        this.props.hidePayLocationDetailsOnClick()
    }

    closeStartAddressModalOnClick() {
        this.setState({
            showStartAddrModal: false
        })
    }

    refreshPaymentLocationMap() {
        this.props.getAllPayLocationsInfo()

        window.paymentLocationMap.setZoom(12)
        window.paymentLocationMap.setCenter(this.state.lastValidCenter)
        this.state.directionsDisplay.setMap(null)
        this.props.hidePayLocationDetailsOnClick()
        this.setState({
            showDirectionsPanel: { display: 'none' }
        })
    }

    onSearchChange(value) {
        this.setState({
            startAddress: value,
        })
    }

    openFilterMenu(event) {
        this.setState({
            filterMenuAnchorElement: event.currentTarget
        })
    }

    selectPayLocationTypeToFilter(type) {
        let filteredType = this.props.filterType === type ? paymentLocationConstants.PAY_LOCATION_TYPE.ALL : type
        let newFilteredList = []

        switch (filteredType) {
            case paymentLocationConstants.PAY_LOCATION_TYPE.ALL: {
                this.setState({ filterMenuAnchorElement: null })
                this.props.setFilteredPayLocationList(this.props.paymentLocationList, filteredType)
                return
            }
            case paymentLocationConstants.PAY_LOCATION_TYPE.PAY_CENTER:
            case paymentLocationConstants.PAY_LOCATION_TYPE.RETAIL: {
                this.props.paymentLocationList.forEach((location) => {
                    if (location.paylocationType === filteredType)
                        newFilteredList.push(location)
                })

                this.setState({ filterMenuAnchorElement: null })
                this.props.setFilteredPayLocationList(newFilteredList, filteredType)
                return
            }
        }
    }

    render() {
        return (
            <div>
                <div className="srp-card-details">
                    {this.props.displayClosestPaymentLocations
                        ? <ClosestPaymentLocations
                            topFiveList={this.props.topFivePayLocations}
                            isClosestPayLocFromSearch={this.props.isClosestPayLocFromSearch || this.props.startAddress.length > 0}
                            currentLocationLoading={this.props.currentLocationLoading}
                            getDirections={this.getDirections}
                            display={this.state.directionsDisplay}
                            service={this.state.directionsService}
                            clearClosestPayLocationsOnClick={this.props.clearClosestPayLocationsOnClick}
                            thisMap={this}
                            t={this.props.t}
                        />
                        : null
                    }

                    <div className="d-none">
                        <div className="pay-location-map" id="hiddenPaymentLocationMap" ref="hiddenPaymentLocationMap" />
                    </div>
                    <div className="pay-location-map" id="paymentLocationMap" ref="paymentLocationMap" />

                    <div className="h-0">
                        {/* Payment Locations Details & Mobile Details */}
                        <div className="pay-center-detail d-none d-lg-block" id="pcDetail" ref="pcDetail" style={this.props.payLocationDetailStyle}>
                            <div className="legend text-white">
                                <div className="legend-title mb-3">
                                    {this.props.payLocationMarkerInfo.type === paymentLocationConstants.PAY_LOCATION_TYPE.PAY_CENTER
                                        ? this.props.t("PayCenter details")
                                        : this.props.t("Location details")}
                                </div>
                                <div className="legend-header mb-1">{this.props.payLocationMarkerInfo.locationName}</div>
                                {this.props.payLocationMarkerInfo.type === paymentLocationConstants.PAY_LOCATION_TYPE.PAY_CENTER
                                    ? <div className="mb-3">
                                        <div>{this.props.t("PayCenters available") + ": " + this.props.payLocationMarkerInfo.paycenterAvailable}</div>
                                        <div>{this.props.t("PayCenters unavailable") + ": " + this.props.payLocationMarkerInfo.paycenterUnavailable}</div>
                                    </div>
                                    : null
                                }

                                {this.props.payLocationMarkerInfo.type === paymentLocationConstants.PAY_LOCATION_TYPE.PAY_CENTER
                                    ? <div className="legend-header mb-1"><strong>{this.props.t("Address")}</strong></div>
                                    : null
                                }
                                <div>{this.props.payLocationMarkerInfo.street}</div>
                                <div>
                                    <span>{this.props.payLocationMarkerInfo.city}</span>
                                    {`, ${this.props.payLocationMarkerInfo.stateZip}`}
                                </div>
                                <div className="mb-3">{format.formatPhoneNumber(this.props.payLocationMarkerInfo.phone)}</div>
                                <div className="legend-header mb-1"><strong>{this.props.t("Hours")}</strong></div>
                                <div className="mb-3">
                                    {this.props.payLocationMarkerInfo.type === paymentLocationConstants.PAY_LOCATION_TYPE.PAY_CENTER
                                        ? this.props.payLocationMarkerInfo.hours
                                        : <RetailLocationDays days={this.props.payLocationMarkerInfo.days} t={this.props.t}/>}
                                </div>
                                <div className="legend-header mb-1"><strong>{this.props.t("Notes")}</strong></div>
                                <div className="mb-3">{this.props.payLocationMarkerInfo.notes}</div>

                                <div className="mt-3 legend-details-btn-container-position">
                                    {this.props.payLocationMarkerInfo.type === paymentLocationConstants.PAY_LOCATION_TYPE.RETAIL
                                        ? <div className="legend-details-btn-container mb-3">
                                            <img className="legend-icons mr-2" src={CashIcon} />
                                            <Link
                                                to="/myaccount/cashpaymentcard"
                                                target="_blank"
                                                className="legend-details-btn text-white button-focus">
                                                {this.props.t("View my cash payment card")}
                                            </Link>
                                        </div>
                                        : null}
                                    <div className="legend-details-btn-container mb-3">
                                        <i className="material-icons">directions</i>
                                        <button
                                            onClick={() => this.props.showStartAddrDetailsOnClick()}
                                            className="legend-details-btn text-white button-focus">
                                            {this.props.t("Get directions")}
                                        </button>
                                    </div>
                                    <div className="legend-details-btn-container">
                                        <i className="material-icons">keyboard_arrow_left</i>
                                        <button
                                            onClick={() => this.props.hidePayLocationDetailsOnClick()}
                                            className="legend-details-btn text-white button-focus"
                                            id="hide-pc-details"
                                            ref="hidePcDetails">
                                            {this.props.t("Hide details")}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <Dialog
                            modal="true"
                            open={this.state.showPaymentLocationDetailModal}
                            PaperProps={{ className: "srp-modal d-lg-none d-block" }}
                        >
                            <div className="srp-modal-body" id="pc-detail-modal">
                                <div className="srp-modal-close" id="hideDetailsResp" ref="hideDetailsResp" onClick={() => this.togglePaymentLocationDetailModal(false)} />
                                <div className="text-muted">
                                    <div className="srp-modal-header">
                                        <h4 className="srp-modal-title font-weight-bold">
                                            {this.props.payLocationMarkerInfo.type === paymentLocationConstants.PAY_LOCATION_TYPE.PAY_CENTER
                                                ? this.props.t("PayCenter details")
                                                : this.props.t("Location details")
                                            }
                                        </h4>
                                    </div>
                                    <div>
                                        <div className="legend-header mb-1">{this.props.payLocationMarkerInfo.locationName}</div>
                                        {this.props.payLocationMarkerInfo.type === paymentLocationConstants.PAY_LOCATION_TYPE.PAY_CENTER
                                            ? <div className="mb-3">
                                                <div>{this.props.t("PayCenters available") + ": " + this.props.payLocationMarkerInfo.paycenterAvailable}</div>
                                                <div>{this.props.t("PayCenters unavailable") + ": " + this.props.payLocationMarkerInfo.paycenterUnavailable}</div>
                                            </div>
                                            : null
                                        }

                                        {this.props.payLocationMarkerInfo.type === paymentLocationConstants.PAY_LOCATION_TYPE.PAY_CENTER
                                            ? <div className="legend-header mb-1"><strong>{this.props.t("Address")}</strong></div>
                                            : null
                                        }
                                        <div>{this.props.payLocationMarkerInfo.street}</div>
                                        <div className="mb-3">
                                            <span>{this.props.payLocationMarkerInfo.city}</span>
                                            {`, ${this.props.payLocationMarkerInfo.stateZip}`}
                                        </div>
                                        <div className="mb-3">
                                            <a href={`tel:${this.props.payLocationMarkerInfo.phone}`}>{format.formatPhoneNumber(this.props.payLocationMarkerInfo.phone)}</a>
                                        </div>
                                        <div className="legend-header mb-1"><strong>{this.props.t("Hours")}</strong></div>
                                        <div className="mb-3">
                                            {this.props.payLocationMarkerInfo.type === paymentLocationConstants.PAY_LOCATION_TYPE.PAY_CENTER
                                                ? this.props.payLocationMarkerInfo.hours
                                                : <RetailLocationDays days={this.props.payLocationMarkerInfo.days} t={this.props.t}/>}
                                        </div>
                                        <div className="legend-header mb-1"><strong>{this.props.t("Notes")}</strong></div>
                                        <div className="mb-3">{this.props.payLocationMarkerInfo.notes}</div>
                                        <div className="srp-modal-footer">
                                            {this.props.payLocationMarkerInfo.type === paymentLocationConstants.PAY_LOCATION_TYPE.RETAIL
                                                ? <Link
                                                    to="/myaccount/cashpaymentcard"
                                                    target="_blank"
                                                    className="btn srp-btn btn-lightblue mr-2">
                                                    {this.props.t("View cash payment card")}
                                                </Link>
                                                : null
                                            }
                                            <button className="btn srp-btn btn-lightblue" id="ok-btn-pc-details" ref="okBtnPcDetails" onClick={() => this.openStartAddressModalOnClick()}>{this.props.t("Get directions")}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Dialog>

                        {/* Start address "legend" & mobile modal */}
                        <div className="pay-center-detail d-none d-lg-block" id="pc-get-start-addr" ref="pcGetStartAddr" style={this.props.startAddrDetailStyle}>
                            <div className="legend text-white">
                                <div className="legend-title"><strong>{this.props.t("Where will you be driving from?")}</strong></div>
                                <br />
                                <form onSubmit={(event) => this.getDirectionsFromDetails(event, this.state.directionsDisplay, this.state.directionsService, this.state.startAddress, this.props.endAddress, this.props.t)}>
                                    <StyledEngineProvider injectFirst>
                                        <ThemeProvider theme={muiTextFieldTheme}>
                                            <SearchFieldWithAutocomplete
                                                id="searchAddressDetails"
                                                label={this.props.t("Starting address")}
                                                searchValue={this.state.startAddress}
                                                placeholderText={this.props.t("Enter an address")}
                                                hasError={this.state.startAddressError}
                                                errorText={this.state.startAddressError ? this.state.startAddressErrorMessage : ""}
                                                handleChange={this.onSearchChange.bind(this)}
                                                useStyle={false}
                                                hasFullWidth={true} />
                                        </ThemeProvider>
                                    </StyledEngineProvider>
                                    <button type="submit" className="btn srp-btn btn-green mt-4" id="submit-start-addr-btn">{this.props.t("Submit")}</button>
                                </form>
                                <div className="legend-details-btn-container-position legend-details-btn-container">
                                    <i className="material-icons">keyboard_arrow_left</i>
                                    <button
                                        onClick={() => this.props.hideStartAddrDetailsOnClick(true)}
                                        className="legend-details-btn text-white button-focus"
                                        id="hide-pc-details">
                                        {this.props.t("Hide")}
                                    </button>
                                </div>
                            </div>
                        </div>

                        <Dialog
                            modal="true"
                            open={this.state.showStartAddrModal}
                            PaperProps={{ className: "srp-modal d-lg-none d-block" }}
                            style={{ zIndex: '20' }}
                        >
                            <div className="srp-modal-body" id="pc-get-start-addr-modal">
                                <div className="srp-modal-close" id="hide-submit-start-addr-Resp" ref="hideSubmitStartAddrResp" onClick={() => this.closeStartAddressModalOnClick()} />
                                <div>
                                    <div className="srp-modal-header">
                                        <h4 className="srp-modal-title" style={{ color: 'rgba(72, 72, 74, 0.867)' }}>{this.props.t("Where will you be driving from?")}</h4>
                                    </div>
                                    <form onSubmit={(event) => this.getDirectionsFromDetails(event, this.state.directionsDisplay, this.state.directionsService, this.state.startAddress, this.props.endAddress, this.props.t)}>
                                        <StyledEngineProvider injectFirst>
                                            <ThemeProvider theme={muiTextFieldThemeModal}>
                                                <SearchFieldWithAutocomplete
                                                    id="searchAddressDetailsMobile"
                                                    label={this.props.t("Starting address")}
                                                    searchValue={this.state.startAddress}
                                                    placeholderText={this.props.t("Enter an address")}
                                                    hasError={this.state.startAddressError}
                                                    errorText={this.state.startAddressError ? this.state.startAddressErrorMessage : ""}
                                                    handleChange={this.onSearchChange.bind(this)}
                                                    useStyle={false}
                                                    hasFullWidth={true} />
                                            </ThemeProvider>
                                        </StyledEngineProvider>
                                        <div className="d-flex justify-content-end">
                                            <button type="submit" className="btn srp-btn btn-green mt-4" id="submit-start-addr-btn-modal">{this.props.t("Submit")}</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </Dialog>
                    </div>

                    <div className="d-flex justify-content-end flex-wrap mt-2">
                        <Link
                            to="/myaccount/cashpaymentcard"
                            target="_blank"
                            className="btn srp-btn btn-lightblue mt-2">
                            {this.props.t("View my cash payment card")}
                        </Link>

                        <a onClick={() => this.refreshPaymentLocationMap()} className="btn srp-btn btn-lightblue mt-2 ml-2">{this.props.t("Refresh map")}</a>
                    </div>
                </div>

                {/* Directions panel */}
                <div className="srp-card-details pt-0" style={this.state.showDirectionsPanel}>
                    <Divider className="mb-3" />
                    <div className="mb-3" id="directionsPanel" ref="directionsPanel" />
                    <Divider className="mb-3" />
                    <div className="d-lg-block d-none">
                        <div className="d-flex justify-content-end">
                            {utils.isLocalStorageAvailable() && <button
                                className="btn srp-btn btn-lightblue text-white mr-2"
                                onClick={() => printByIdInNewWindow('directionsPanel', 'PayLocationDirections')}>
                                {this.props.t("Print directions")}
                            </button>}
                        </div>
                    </div>
                </div>
            </div >
        )
    }
}

PayLocationMapContainer.propTypes = {
    payLocationLegendStyle: PropTypes.object,
    payLocationLegendBtnStyle: PropTypes.object,
    payLocationDetailStyle: PropTypes.object,
    hidePayLocationDetailsOnClick: PropTypes.func,
    showPayLocationDetailsOnClick: PropTypes.func,
    payLocationMarkerInfo: PropTypes.object,
    getCurrentLocationInfo: PropTypes.func,
    currentLocation: PropTypes.object,
    showStartAddrDetailsOnClick: PropTypes.func,
    endAddress: PropTypes.string,
    searchAddress: PropTypes.string,
    searchAddressResponsive: PropTypes.string,
    getEndAddressForDirectionsOnClick: PropTypes.func,
    hideStartAddrDetailsOnClick: PropTypes.func,
    startAddrDetailStyle: PropTypes.object,
    clearClosestPayLocationsOnClick: PropTypes.func,
    topFivePayLocations: PropTypes.arrayOf(PropTypes.object),
    clearClosestStyle: PropTypes.object,
    mapScriptLoadedStatus: PropTypes.string,
    paymentLocationStatus: PropTypes.string,
    serviceAddress: PropTypes.object,
    isLoggedIn: PropTypes.bool,
    getAllPayLocationsInfo: PropTypes.func,
    displayClosestPaymentLocations: PropTypes.bool,
    isClosestPayLocFromSearch: PropTypes.bool,
    currentLocationLoading: PropTypes.bool,
    filteredPayLocationList: PropTypes.array,
    paymentLocationList: PropTypes.array,
    setFilteredPayLocationList: PropTypes.func,
    filterType: PropTypes.number,
    startAddress: PropTypes.string,
    searchAddressCoords: PropTypes.object
}

const mapStateToProps = state => {
    return {
        ...state.paymentLocation,
        ...state.login,
        ...state.accountInfo.billAccount
    }
}

const mapDispatchToProps = dispatch => {
    return {
        hidePayLocationDetailsOnClick: () => {
            dispatch(hidePayLocationDetails())
        },
        showPayLocationDetailsOnClick: (details) => {
            dispatch(showPayLocationDetails(details))
        },
        getCurrentLocationInfo: (location) => {
            dispatch(getCurrentLocation(location))
        },
        getEndAddressForDirectionsOnClick: (address) => {
            dispatch(getEndAddressForDirections(address))
        },
        getPayLocationMapOnLoad: (map) => {
            dispatch(getPayLocationMap(map))
        },
        showStartAddrDetailsOnClick: () => {
            dispatch(showStartAddrDetails())
        },
        hideStartAddrDetailsOnClick: (showDetails) => {
            dispatch(hideStartAddrDetails(showDetails))
        },
        clearClosestPayLocationsOnClick: () => {
            dispatch(clearClosestPayLocations())
        },
        setFilteredPayLocationList: (list, type) => {
            dispatch(setFilteredPayLocationList(list, type))
        }
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(PayLocationMapContainer)