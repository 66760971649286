import React from 'react'
import PropTypes from 'prop-types'
import TextField from '@mui/material/TextField'
import { NumericFormat } from 'react-number-format'

class PaymentAmountField extends React.Component {
    render() {
        let isError = this.props.touched['paymentAmount']
            && Object.prototype.hasOwnProperty.call(this.props.errors, 'paymentAmount')
        let errMsg = isError ? this.props.errors['paymentAmount'] : ''

        const getLocalizedErrMsg = errMsg.getLocalizedErrMsg
        if (typeof getLocalizedErrMsg === 'function')
            errMsg = getLocalizedErrMsg()
        else if (errMsg !== "")
            errMsg = this.props.t(errMsg)

        let meta = {
            id: 'paymentAmount',
            name: 'paymentAmount',
            label: this.props.t("Payment amount"),
            helperText: errMsg,
            error: isError
        }

        let numberFormatProps = {
            ...this.props,
            value: this.props.value.replace(/[$,]/g, '') // Formik always passes in the formatted value, we want the unformatted string value
        }
        delete numberFormatProps.setFieldValue
        delete numberFormatProps.setFieldTouched
        delete numberFormatProps.t

        return (
            <NumericFormat
                {...meta}
                {...numberFormatProps}
                valueIsNumericString={true}
                thousandSeparator={true}
                prefix={'$'}
                decimalScale={2}
                fixedDecimalScale={false}
                allowNegative={false}
                customInput={TextField}
                InputProps={{
                    inputProps: { inputMode: 'decimal' }
                }}
                onBlur={() => {
                    let fixedPaymentAmount = Number(numberFormatProps.value).toFixed(2)
                    this.props.setFieldValue('paymentAmount', fixedPaymentAmount, false)
                    this.props.setFieldTouched(meta.id, true)
                }}
                isAllowed={field => field.floatValue === undefined || field.floatValue < 1000000000.00}
                type="text"
            />
        )
    }
}

PaymentAmountField.propTypes = {
    touched: PropTypes.shape({
        paymentAmount: PropTypes.bool
    }).isRequired,
    errors: PropTypes.shape({
        paymentAmount: PropTypes.oneOfType([
            PropTypes.string.isRequired,
            PropTypes.shape({
                getLocalizedErrMsg: PropTypes.func.isRequired,
            }),
        ]),
    }).isRequired,
    t: PropTypes.func.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    setFieldTouched: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired
}

export default PaymentAmountField