import React from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

const DontForget = ({t}) => {
  return (<div className="mt-4">
    <div className="srp-card-header">{t("Don't forget")}</div>
    <div className="srp-card-body">
      <div className="srp-card-details" style={{background: "#E0E7F0"}}>
        {t("update_forwarding_address")}
      </div>      
    </div>    
  </div>)
}

DontForget.propTypes = {
  t: PropTypes.func
}


export default withTranslation('stopService')(DontForget)