import { billProjectionConstants } from '../../../constants/payment/bill-projection-constants'
import { billAccountConstants } from '../../../constants/bill-account-constants'
import * as loadingStatus from '../../../constants/loading-status-constants'

const initialState ={
    amount: 0,
    billProjection: {
        amount: 0,
        month: "",
        message: ""
    },
    billProjectionStatus: loadingStatus.LOADING_STATUS_INIT
}

export default function billProjectionReducer(state = initialState, action){
    switch(action.type){
        case billProjectionConstants.GET_PROJECTION_REQUEST:
            return {
                    ...state,
                    billProjectionStatus: loadingStatus.LOADING_STATUS_IN_PROGRESS
                }
        case billProjectionConstants.GET_PROJECTION_SUCCESS:
            return {
                ...state,
                amount: action.payload.amount,
                billProjection: action.payload,
                billProjectionStatus: loadingStatus.LOADING_STATUS_SUCCESS
            }
        case billProjectionConstants.GET_PROJECTION_FAILURE:
            return {
                ...state,
                amount: 0,
                billProjection: initialState.billProjection,
                billProjectionStatus: loadingStatus.LOADING_STATUS_FAILURE
            }
        case billAccountConstants.SELECT_BILL_ACCOUNT_SUCCESS:
            return initialState
        default:
            return state
    }
}