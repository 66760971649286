import React from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

import CircularProgress from '@mui/material/CircularProgress'

import ReviewDetails from './review-details'

const ReviewForm = ({props, currentStep, showErrorMessage, csPhoneNumberFormatted,  submitting, submitForm, goToDetails, isOnCentralPrepay, t}) => (
    <div>

    {showErrorMessage &&
    <div>
        <div className="d-lg-block d-none srp-alert-error mb-4">
        {`${t("unable_to_complete_request")} ${csPhoneNumberFormatted}.`}
        </div>
        <div className="d-block d-lg-none srp-alert-error mb-4">
            {`${t("unable_to_complete_request")}
            ${<a href={"tel:" + csPhoneNumberFormatted.replace(/[() -]/g, '')}>
                {csPhoneNumberFormatted}
            </a>}
            .`}
        </div>
    </div>
    }

    <ReviewDetails {...props} isOnCentralPrepay={isOnCentralPrepay} currentStep={currentStep} />

    <div className="d-flex justify-content-end pt-2">
        <button className="btn srp-btn btn-lightblue mr-3" onClick={() => goToDetails(currentStep - 1)}>
            {t("Previous")}
        </button>
        <button type="submit" className="btn srp-btn btn-green" onClick={submitForm}>
            { submitting
              ? <CircularProgress size={20} thickness={5} style={{ color: 'white' }} />
              : t("Submit")}
        </button>
    </div>

    </div>
)

ReviewForm.propTypes = {
    props: PropTypes.object.isRequired,
    currentStep: PropTypes.number.isRequired,
    showErrorMessage: PropTypes.bool.isRequired,
    csPhoneNumberFormatted: PropTypes.string.isRequired,
    submitting: PropTypes.bool.isRequired,
    submitForm: PropTypes.func.isRequired,
    goToDetails: PropTypes.func.isRequired,
    isOnCentralPrepay: PropTypes.bool.isRequired,
    t: PropTypes.func
}

export default withTranslation('stopService')(ReviewForm)