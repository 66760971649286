import React from 'react'
import PropTypes from 'prop-types'

import { Trans } from 'react-i18next'

import { Link } from 'react-router-dom'

import Card from '@mui/material/Card'

import PaymentDetailsTableContainer from '../common/payment-details-table-container'

const GuestPaymentSubmittedCard = ({referenceNumber, confirmationEmailAddress, confirmationEmailSent, t, i18n}) => (
    <div>
        <label className="h5">{t("Your payment has been submitted")}</label>

        <Card>
            <div>
                <PaymentDetailsTableContainer referenceNumber={referenceNumber} t={t} i18n={i18n} />

                <h6 className="text-muted px-3">
                    {confirmationEmailSent ?
                    <Trans i18nKey="confirmation_email_has_been_sent_to" t={t}>
                        A confirmation email has been sent to <span className="font-weight-bold">{{confirmationEmailAddress}}</span> for this payment.
                    </Trans>
                    :
                    <Trans i18nKey="unable_to_send_a_confirmation_email" t={t}>
                        Unable to send a confirmation email to <span className="font-weight-bold">{{confirmationEmailAddress}}</span> for this payment.
                    </Trans>
                    }
                </h6>
            </div>

            <div className="d-flex justify-content-end mt-4 px-3 pb-3 d-print-none">
                <button className="btn srp-btn btn-lightblue mr-2" onClick={() => { window.print() }}>{t("Print")}</button>
                <Link to="/accountSetup" className="btn srp-btn btn-blue">{t("Sign up for My Account")}</Link>
            </div>
        </Card>
    </div>
)

GuestPaymentSubmittedCard.propTypes = {
    referenceNumber: PropTypes.number.isRequired,
    confirmationEmailAddress: PropTypes.string.isRequired,
    confirmationEmailSent: PropTypes.bool.isRequired,
    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired,
}

export default GuestPaymentSubmittedCard