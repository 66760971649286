import React from 'react'
import PropTypes from 'prop-types'

import BankRoutingNumberandAccountNumber from './bank-routing-number-and-account-number'
import BankInfoFormik from './bank-info-formik'

const BankInfoSection = (props) => (
    <div>
    <BankRoutingNumberandAccountNumber bankRoutingNumber={props.bankRoutingNumber} bankAccountNumber={props.bankAccountNumberLeadingZeroesAreSignificant} t={props.t} />

    {props.showBankForm ?
        <BankInfoFormik {...props} /> :
        <div className="d-flex justify-content-end">
            <button className="btn srp-btn btn-lightblue mb-2" type="button" onClick={props.openBankForm} disabled={props.verifyingPaymentList}>{props.t("Edit")}</button>
        </div>
    }
    </div>
)

BankInfoSection.propTypes = {
    showBankForm: PropTypes.bool.isRequired,
    openBankForm: PropTypes.func.isRequired,
    handleBankFormClose: PropTypes.func.isRequired,

    storeBankInfo: PropTypes.func.isRequired,

    bankFirstName: PropTypes.string.isRequired,
    bankLastName: PropTypes.string.isRequired,
    bankPhoneNumber: PropTypes.string.isRequired,
    bankEmailAddress: PropTypes.string.isRequired,
    bankRoutingNumber: PropTypes.string.isRequired,
    bankAccountNumberLeadingZeroesAreSignificant: PropTypes.string.isRequired,

    bankValidationSchema: PropTypes.object.isRequired,

    verifyingPaymentList: PropTypes.bool.isRequired,

    formDataChanged: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired,
}

export default BankInfoSection