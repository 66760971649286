import React from 'react'
import PropTypes from 'prop-types'
import { Formik } from 'formik'
import { connect } from 'react-redux'
import CircularProgress from '@mui/material/CircularProgress'
import TextField from '@mui/material/TextField'

import { requestLoginEmailUpdate } from '../../../actions/auth/login/login-actions'
import validate from '../../../srp_modules/validate'
import { Trans } from 'react-i18next'

class ChangeLoginEmailForm extends React.Component {

    render() {
        return (
            <Formik
                initialValues={{
                    newEmail: '',
                    password: ''
                }}
                validate={(values) => validateChangeEmailRequest(values, this.props.t)}
                onSubmit={this.props.requestLoginEmailUpdate}>
                {({values, status, errors, dirty, touched, handleChange, handleBlur, handleSubmit, submitCount, isSubmitting}) => {
                    let formStatus = status || { }
                    let emailError = (dirty || submitCount > 0) && touched.newEmail && errors.newEmail !== undefined
                    let passwordError = (dirty || submitCount > 0) && touched.password && errors.password !== undefined

                    return (formStatus.showSuccess ?
                        <div className="srp-alert-success mt-2 mb-2 p-2 d-flex">
                            <div className="d-flex">
                                {this.props.t("We have sent an email verification to your email address. Further instructions will be in the email.")}
                            </div>
                            <div className="d-flex text-right align-middle">
                                <button className="displayAsText h-100 align-middle" onClick={this.props.setDisplayChangeEmailForm(false)}>
                                    <i className="material-icons align-middle">close</i>
                                </button>
                            </div>
                        </div>
                        :
                        <form method="POST" onSubmit={handleSubmit}>
                            <div className="w-100">
                                {formStatus.error ?
                                    (<div className="srp-alert-error">
                                        {formStatus.error}
                                    </div>) : null}
                                <div className="mb-2">
                                    <TextField name="newEmail"
                                        type="email"
                                        value={values.newEmail}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={emailError}
                                        helperText={emailError ? errors.newEmail : null}
                                        fullWidth={true} 
                                        label={this.props.t("New email address")}
                                    />
                                    <TextField name="password"
                                        type={values.showPassword ? "text" : "password"}
                                        value={values.password}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={passwordError}
                                        helperText={passwordError ? errors.password : null}
                                        fullWidth={true} 
                                        label={this.props.t("Verify password")}
                                    />
                                </div>
                                <div className="srp-alert-notice mb-3">
                                    <span>
                                        <Trans i18nKey="remember_to_update" t={this.props.t}>
                                            <strong className="text-uppercase">Important</strong> Remember to update your email address in <strong>Contact info & alerts</strong> to receive account alerts at your new email address.
                                        </Trans>
                                    </span>
                                </div>
                                <div className="row justify-content-end">
                                    <div className="col-lg text-right">
                                        <button type="button" className="btn srp-btn btn-lightblue ml-2 mb-2" onClick={this.props.setDisplayChangeEmailForm(false)}>{this.props.t("Cancel")}</button>
                                        <button type="submit" disabled={isSubmitting} className="btn srp-btn btn-green ml-2 mb-2">
                                            {isSubmitting
                                            ? <CircularProgress size={20} thickness={5} color="secondary" />
                                            : this.props.t("Update")}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    )
                }}
            </Formik>
        )
    }
}

const mapStateToProps = () => {
    return {
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        requestLoginEmailUpdate: async (values, goodies) => {
            let response = await dispatch(requestLoginEmailUpdate(values.newEmail, values.password))
            goodies.setSubmitting(false)

            if (response.error === true) {
                goodies.setStatus({ error: 'There was an unknown error' })
                return
            }

            if (response.payload.success !== true && response.payload.error !== undefined && response.payload.error.length > 0) {
                goodies.setStatus({ error: response.payload.error })
                return
            }

            goodies.setStatus({ showSuccess: true })
        }
    }
}

ChangeLoginEmailForm.propTypes = {
    requestLoginEmailUpdate: PropTypes.func.isRequired,
    setDisplayChangeEmailForm: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangeLoginEmailForm)

function validateChangeEmailRequest(values, t) {
    let errors = {}

    if (values.password.length === 0) {
        errors.password = t("Password is required")
    }

    if (values.newEmail.length === 0) {
        errors.newEmail = t("New email address is required")
    }
    else if (!validate.emailAddress(values.newEmail)) {
        errors.newEmail = t("You must enter a valid new email address")
    }

    return errors
}