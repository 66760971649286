import React from 'react'
import PropTypes from 'prop-types'
import { DateTime } from 'luxon'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import StopServiceStepper from './stop-service-stepper'
import format from '../../../srp_modules/format'
import '../../../styles/forms.css'

const ReviewDetails = (props) => {
  return (<div>
    <StopServiceStepper
      currentStep={props.currentStep}
    />
    <div className="text-muted">
      <div className="mb-2 section-header">{props.t("Customer information")}</div>
      <div className="d-lg-block d-none mb-4">
        <div>
          <span className="font-weight-bold mr-2">{props.t("Name")}</span>
          <span>
            {props.isCommercial
              ? props.businessName
              : `${props.firstName} ${props.middleName} ${props.lastName}`
            }
          </span>
        </div>
        {props.dba.length > 0
          ? <div>
            <span className="font-weight-bold mr-2">{props.t("DBA")}</span>
            <span>{props.dba}</span>
          </div>
          : null
        }
        {props.isCommercial
          ? <div>
            <div>
              <span className="font-weight-bold mr-2">{props.t("Requestor's name")}</span>
              <span>{props.requestorsName}</span>
            </div>
            <div>
              <span className="font-weight-bold mr-2">{props.t("Requestor's association with business")}</span>
              <span>{props.requestorsAssociaton}</span>
            </div>
          </div>
          : null
        }
        <div>
          <span className="font-weight-bold mr-2">{props.t("SRP account number")}</span>
          <span>{props.selectedBillAccount}</span>
        </div>
        <div className="row-fluid d-inline-flex align-content-start w-100">
          <span className="font-weight-bold mr-2 text-nowrap">{props.t("Email address")}</span>
          <span className="d-block text-truncate">{props.primaryEmail}</span>
        </div>
        <div>
          <span className="font-weight-bold mr-2">{props.t("Phone number")}</span>
          <span>{format.formatPhoneNumber(props.primaryPhone)}</span>
        </div>
      </div>
      <div className="d-lg-none d-block mb-4">
        <div className="row d-flex align-items-center">
          <div className="col-5 text-muted font-weight-bold">{props.t("Name")}</div>
          <div className="col-7 text-right">
            {props.isCommercial
              ? props.businessName
              : `${props.firstName} ${props.middleName} ${props.lastName}`
            }
          </div>
        </div>
        <hr className="mt-1 mb-1" />
        {props.dba.length > 0
          ? <div>
            <div className="row d-flex align-items-center">
              <div className="col-5 text-muted font-weight-bold">{props.t("DBA")}</div>
              <div className="col-7 text-right">{props.dba}</div>
            </div>
            <hr className="mt-1 mb-1" />
          </div>
          : null
        }
        {props.isCommercial
          ? <div>
            <div className="row d-flex align-items-center">
              <div className="col-5 text-muted font-weight-bold">{props.t("Requestor's name")}</div>
              <div className="col-7 text-right">{props.requestorsName}</div>
            </div>
            <hr className="mt-1 mb-1" />
            <div className="row d-flex align-items-center">
              <div className="col-6 text-muted font-weight-bold">{props.t("Requestor's association with business")}</div>
              <div className="col-6 text-right">{props.requestorsAssociaton}</div>
            </div>
            <hr className="mt-1 mb-1" />
          </div>
          : null
        }
        <div className="row d-flex align-items-center">
          <div className="col-5 text-muted font-weight-bold">{props.t("SRP account number")}</div>
          <div className="col-7 text-right">{props.selectedBillAccount}</div>
        </div>
        <hr className="mt-1 mb-1" />
        <div className="row d-flex align-items-center">
          <div className="col-5 text-muted font-weight-bold">{props.t("Email address")}</div>
          <div className="col-7 text-right text-truncate">{props.primaryEmail}</div>
        </div>
        <hr className="mt-1 mb-1" />
        <div className="row d-flex align-items-center">
          <div className="col-5 text-muted font-weight-bold">{props.t("Phone number")}</div>
          <div className="col-7 text-right">{format.formatPhoneNumber(props.primaryPhone)}</div>
        </div>
        <hr className="mt-1 mb-1" />
      </div>

      <div className="mb-2 section-header">{props.t("Turn off information")}</div>
      <div className="d-lg-block d-none mb-4">
        <div>
          <span className="font-weight-bold mr-2">{props.t("Turn off date")}</span>
          <span>{DateTime.fromJSDate(props.selectedDate).toFormat('EEEE,  MMMM d, yyyy')}</span>
        </div>
        <div className="d-flex">
          <div>
            <span className="font-weight-bold">{props.t("Turn off address")}</span>
          </div>
          <div className="pl-2">
            <div>{props.serviceAddress.streetAddress}</div>
            <div>{props.serviceAddress.cityStateZip}</div>
          </div>
        </div>
        <div className="d-flex">
          <div>
            <span className="font-weight-bold">{props.t("Mailing address")}</span>
          </div>
          <div className="pl-2 text-uppercase">
            <div>
              {props.address2.length === 0
                ? props.address1
                : `${props.address1} ${props.address2}`
              }
            </div>
            <div>{props.city}, {props.state} {props.zip}</div>
          </div>
        </div>
        {props.sendFinalBillAsEbill
          ? <div className="d-flex">
            <div>
              <span className="font-weight-bold">{props.t("Final bill email address")}</span>
            </div>
            <div className="pl-2">
              {props.primaryEmail}
            </div>
          </div>
          : null}
      </div>
      <div className="d-lg-none d-block mb-4">
        <div className="row d-flex align-items-center">
          <div className="col-5 text-muted font-weight-bold">{props.t("Turn off date")}</div>
          <div className="col-7 text-right">{DateTime.fromISO(props.selectedDate).toFormat('EEEE,  MMMM d, yyyy')}</div>
        </div>
        <hr className="mt-1 mb-1" />
        <div className="row d-flex align-items-center">
          <div className="col-5 text-muted font-weight-bold">{props.t("Turn off address")}</div>
          <div className="col-7 text-right">
            <div>{props.serviceAddress.streetAddress}</div>
            <div>{props.serviceAddress.cityStateZip}</div>
          </div>
        </div>
        <hr className="mt-1 mb-1" />
        <div className="row d-flex align-items-center">
          <div className="col-5 text-muted font-weight-bold">{props.t("Mailing address")}</div>
          <div className="col-7 text-right text-uppercase">
            <div>
              {props.address2.length === 0
                ? props.address1
                : `${props.address1} ${props.address2}`
              }
            </div>
            <div>{props.city}, {props.state} {props.zip}</div>
          </div>
        </div>
        {props.sendFinalBillAsEbill
          ?
          <div>
            <hr className="mt-1 mb-1" />
            <div className="row d-flex align-items-center">
              <div className="col-5 text-muted font-weight-bold">{props.t("Final bill email address")}</div>
              <div className="col-7 text-right">{props.primaryEmail}</div>
            </div>
          </div>
          : null}
        <hr className="mt-1 mb-1" />
      </div>

      {props.isMpower && !props.isOnCentralPrepay &&
        <div>
          <div className="mb-2 section-header">{props.t("Return in-home display unit")}</div>
          <div className="mb-3">{props.t("account_will_be_credited")}</div>
          <p className="mb-3">{props.t("after_power_has_been_turned_off")}</p>
          <div>{props.t("Unable to find your original packaging?")}</div>
          <FormControlLabel
            control={
              <Checkbox
                checked={props.shouldSendPostage && "sendPostage"}
                onChange={event => props.updatePostage(event.target.checked)}
                value="sendPostage"
                color="primary" />}
            label={<span className="font-weight-bold text-muted">{props.t("Please send me a postage-paid envelope")}</span>}
          />
        </div>
      }

      {!props.isMpower &&
        <div>
          <div className="mb-2 section-header">{props.t("Refund of deposit")}</div>
          <p>{props.t("once_completed_service_turn_off")}</p>
        </div>
      }
    </div>
  </div>)
}

ReviewDetails.propTypes = {
  address1: PropTypes.string,
  address2: PropTypes.string,
  businessName: PropTypes.string,
  city: PropTypes.string,
  currentStep: PropTypes.number,
  dba: PropTypes.string,
  firstName: PropTypes.string,
  isCommercial: PropTypes.bool,
  isMpower: PropTypes.bool,
  isOnCentralPrepay: PropTypes.bool.isRequired,
  lastName: PropTypes.string,
  middleName: PropTypes.string,
  primaryEmail: PropTypes.string,
  primaryPhone: PropTypes.string,
  requestorsAssociaton: PropTypes.string,
  requestorsName: PropTypes.string,
  selectedBillAccount: PropTypes.string,
  selectedDate: PropTypes.object,
  sendFinalBillAsEbill: PropTypes.bool,
  sendPostage: PropTypes.bool,
  serviceAddress: PropTypes.object,
  shouldSendPostage: PropTypes.bool,
  state: PropTypes.string,
  updatePostage: PropTypes.func,
  zip: PropTypes.string,
  props: PropTypes.object,
  t: PropTypes.func
}

export default ReviewDetails