import React from 'react'
import { withTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import AccountInfoBarContainer from '../../myaccount_header/info_bar/account-info-bar-container'
import OtherPaymentOptions from '../../common_payment/other-payment-options'
import Breadcrumb from '../../common_payment/breadcrumb'
import NgpMakePaymentByCard from './ngp-make-payment-by-card'
import NgpPayByBank from './ngp-pay-by-bank'
import PayByCash from '../multi_payment/pay-by-cash'

const MultiPaymentNgpPagePure = ({ isCash, billAccountList, t }) => {
    let pageComp = isCash ? <PayByCash billAccountList={billAccountList} isMpower={true} /> : <NgpPayByBank />
    return (
        <div>
            <div className="container py-3 ">
                <h3 className="mt-2 mb-0">{t("Make a purchase")}</h3>
                <AccountInfoBarContainer disableSelector={false} />
                <Breadcrumb pageTitle={t("Make a purchase")} />
                <div className="row">
                    <div className="col-lg-6 col-12 mt-lg-4 mb-4">
                        <NgpMakePaymentByCard />
                    </div>

                    <div className="col-md-6 col-12 mt-4 d-none d-md-block d-lg-block">
                        <OtherPaymentOptions
                            showAutoPay={false}
                            showCreditDebit={true}
                            showGiftPayment={true}
                            showPayCenterLink={true}
                            multiPayment={true}
                        />
                    </div>
                </div>
                <div className="">
                    {pageComp}
                </div>
                <br />
                <div className="d-sm-block d-md-none">
                    <OtherPaymentOptions
                        showAutoPay={false}
                        showCreditDebit={true}
                        showGiftPayment={true}
                        showPayCenterLink={true}
                        multiPayment={true}
                    />
                </div>
            </div>

        </div>
    )
}

MultiPaymentNgpPagePure.propTypes = {
    billAccountList: PropTypes.array,
    isCash: PropTypes.bool,

    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired
}

const MultiPaymentNgpPage = withTranslation("multiPurchasePage")(MultiPaymentNgpPagePure)
export { MultiPaymentNgpPage as default, MultiPaymentNgpPagePure }