export const giftPaymentApiConstants = {
    VERIFY_GIFT_PAYMENT_LIST_REQUEST: 'VERIFY_GIFT_PAYMENT_LIST_REQUEST',
    VERIFY_GIFT_PAYMENT_LIST_SUCCESS: 'VERIFY_GIFT_PAYMENT_LIST_SUCCESS',
    VERIFY_GIFT_PAYMENT_LIST_FAILURE: 'VERIFY_GIFT_PAYMENT_LIST_FAILURE',

    SUBMIT_GIFT_PAYMENT_LIST_REQUEST: 'SUBMIT_GIFT_PAYMENT_LIST_REQUEST',
    SUBMIT_GIFT_PAYMENT_LIST_SUCCESS: 'SUBMIT_GIFT_PAYMENT_LIST_SUCCESS',
    SUBMIT_GIFT_PAYMENT_LIST_FAILURE: 'SUBMIT_GIFT_PAYMENT_LIST_FAILURE',

    GIFT_PAGE_ADD_BANK_ACCOUNT_REQUEST: 'GIFT_PAGE_ADD_BANK_ACCOUNT_REQUEST',
    GIFT_PAGE_ADD_BANK_ACCOUNT_SUCCESS: 'GIFT_PAGE_ADD_BANK_ACCOUNT_SUCCESS',
    GIFT_PAGE_ADD_BANK_ACCOUNT_FAILURE: 'GIFT_PAGE_ADD_BANK_ACCOUNT_FAILURE',

    CLEAR_GIFT_PAYMENT_SUBMITTED: 'CLEAR_GIFT_PAYMENT_SUBMITTED'
}