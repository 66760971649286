import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import Divider from '@mui/material/Divider'

import { programConstants } from '../../../constants/program-constants'

const MyProgramDetailsResponsive = ({ last, program, enrolled, t }) => {
    if (program.program === programConstants.ECONOMIC_PRICE_PLAN && enrolled) {
        return (
            <div>
                <div className="srp-card-details pt-2 pb-2 row" style={{ alignItems: 'center' }}>
                    <div className="col align-middle ml-3">
                        <div className="font-weight-bold">
                            <a
                                href={program.programLink}
                                target="_blank">
                                {program.programText}
                            </a>
                        </div>
                        <div>
                            {program.descriptionText}
                        </div>
                    </div>
                    <div className="col text-right">
                        {program.docusign.showReapplyLink &&
                        <Link to="/myaccount/epp/application" target="_blank" className="displayAsLink srp-blue-color">{t("Reapply")}</Link>
                        }
                    </div>
                </div>
                {
                    !last ?
                        <div style={{ display: 'block' }}>
                            <Divider />
                        </div>
                        : null
                }
            </div>
        )
    } else {
        return (
            <div>
                <div className="srp-card-details pt-2 pb-2 row" style={{ alignItems: 'center' }}>
                    <div className="col align-middle ml-3">
                        <div className="font-weight-bold">
                            <a
                                href={program.programLink}
                                target="_blank">
                                {program.programText}
                            </a>
                        </div>
                        <div>
                            {program.descriptionText}
                        </div>
                    </div>
                    <div className="col text-right">
                        {program.actionText2.length === 0
                            ? program.actionLink.startsWith('http')
                                ? <a href={program.actionLink} target="_blank">{program.actionText}</a>
                                : <Link to={program.actionLink}>{program.actionText}</Link>
                            : <div className="row">
                                <div className="col">
                                    {program.actionLink2.startsWith('http')
                                        ? <a href={program.actionLink2} target="_blank">{program.actionText2}</a>
                                        : <Link to={program.actionLink2}>{program.actionText2}</Link>
                                    }
                                </div>
                                <div className="col">
                                    {program.program === programConstants.SAFETY_NET
                                        ? <button
                                            className="displayAsLink"
                                            onClick={() => program.actionLink(true)}>{program.actionText}</button>
                                        : program.actionLink.startsWith('http')
                                            ? <a href={program.actionLink} target="_blank">{program.actionText}</a>
                                            : <Link to={program.actionLink}>{program.actionText}</Link>
                                    }
                                </div>
                            </div>
                        }
                    </div>
                </div>
                {
                    !last ?
                        <div style={{ display: 'block' }}>
                            <Divider />
                        </div>
                        : null
                }
            </div>
        )
    }
}

MyProgramDetailsResponsive.propTypes = {
    enrolled: PropTypes.bool.isRequired,
    last: PropTypes.bool.isRequired,
    program: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
}

export default MyProgramDetailsResponsive