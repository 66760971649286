import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import config from 'my-account-config'

import ledLightBulbs from '../../images/energy_savings_tips/led-light-bulbs.jpg'

const EnergySavingsTips = ({isCommercial, t, i18n}) => {
    const isSpanish = i18n.language==="es"
    let rebatesUrl = ""
    if (isCommercial) {
        rebatesUrl = isSpanish
            ? `${config.srpnetBaseUrlEs}reembolsos-ahorros-de-energia/negocios/herramientas-de-ahorro`
            : `${config.srpnetBaseUrl}energy-savings-rebates/business/savings-tools`
    } else {
        rebatesUrl = isSpanish
            ? `${config.srpnetBaseUrlEs}reembolsos-ahorros-de-energia/hogar/reembolsos/reembolsos-residencial`
            : `${config.srpnetBaseUrl}energy-savings-rebates/home/rebates/residential-rebates`
    }

    return (
    <div className="srp-card-body">
        <div className="srp-card-details">
            <img className="img-fluid mb-3" src={ledLightBulbs}/>
            <h4 className="mb-2" style={{ color: 'rgba(51, 51, 51, 0.537)' }}>{t("Switch to LED light bulbs")}</h4>
            <p className="mb-4 text-muted">
                {t("light_bulb_types")}
            </p>
            <div className="d-flex flex-wrap justify-content-end">
                <a
                    className="btn srp-btn btn-lightblue"
                    href={rebatesUrl}
                    target="_blank">
                    {t("Learn more")}
                </a>
            </div>
        </div>
    </div>
    )
}

const mapStateToProps = state => {
    return { ...state.accountInfo.billAccount }
}


EnergySavingsTips.propTypes = {
    isCommercial: PropTypes.bool.isRequired,
    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired,
}

export default connect(mapStateToProps)(EnergySavingsTips)