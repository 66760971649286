import React from 'react'
import PropTypes from 'prop-types'
import IconButton from '@mui/material/IconButton'

let navButtonClasses = {
    root: "associated-account-pagination-buttons"
}

const AssociatedAccountRowsPaginationResponsive = ({ totalAccounts, pageSize, currentPage, changeCurrentPage }) => {
    let totalPages = Math.ceil(totalAccounts / pageSize)
    let displayedPageNumbers = []

    if (totalPages <= 3) {
        for (let i = 1; i <= totalPages; i++) {
            displayedPageNumbers.push(i)
        }
    } else if (currentPage === 1) {
        displayedPageNumbers.push(1)
        displayedPageNumbers.push(2)
        displayedPageNumbers.push(3)
    } else if (currentPage === totalPages) {
        displayedPageNumbers.push(currentPage - 2)
        displayedPageNumbers.push(currentPage - 1)
        displayedPageNumbers.push(currentPage)
    } else {
        displayedPageNumbers.push(currentPage - 1)
        displayedPageNumbers.push(currentPage)
        displayedPageNumbers.push(currentPage + 1)
    }

    return (
        <div className="pb-4 associated-account-pagination">
            <div className="align-self-center" style={{ textAlign: 'center'}}>
                <IconButton 
                    classes={navButtonClasses}
                    color="primary" 
                    size="small"
                    onClick={changeCurrentPage(1)}
                    disabled={currentPage === 1}>
                    <i className="material-icons">first_page</i>
                </IconButton>
                <IconButton 
                    classes={navButtonClasses}
                    color="primary" 
                    size="small"
                    onClick={changeCurrentPage(currentPage - 1)}
                    disabled={currentPage === 1}>
                    <i className="material-icons">chevron_left</i>
                </IconButton>
                {
                    displayedPageNumbers.map(page => {
                        return (
                            <IconButton 
                                classes={{ root: "associated-account-pagination-page-button"}}
                                key={page} 
                                color="primary" 
                                size="small"
                                onClick={changeCurrentPage(page)}
                                disabled={currentPage === page}>
                                <span>{page}</span>
                            </IconButton>
                        )
                    })
                }
                <IconButton 
                    classes={navButtonClasses}
                    color="primary" 
                    size="small"
                    onClick={changeCurrentPage(currentPage + 1)}
                    disabled={currentPage === totalPages}>
                    <i className="material-icons">chevron_right</i>
                </IconButton>
                <IconButton 
                    classes={navButtonClasses}
                    color="primary" 
                    size="small"
                    onClick={changeCurrentPage(totalPages)}
                    disabled={currentPage === totalPages}>
                    <i className="material-icons">last_page</i>
                </IconButton>
            </div>
        </div>
    )
}

AssociatedAccountRowsPaginationResponsive.propTypes = {
    totalAccounts: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired,
    currentPage: PropTypes.number.isRequired,
    changeCurrentPage: PropTypes.func.isRequired,
}

export default AssociatedAccountRowsPaginationResponsive
