
import { OPEN_RESONSIVE_NAV, CLOSE_RESONSIVE_NAV } from '../../../actions/auth/navigation/navigation-actions'
import { GET_NAV_MENU_SUCCESS, GET_FOOTER_SUCCESS } from '../../../actions/guest/header-footer-actions'

const initialState = {
    responsiveNavStyle: {display: 'none'},
    responsiveNavOpen: false,
    navMenuHtml: '',
    footerHtml: ''
};

export default function navigationReducer(state = initialState, action) {
    switch (action.type) {
        case OPEN_RESONSIVE_NAV:
            return { ...state, responsiveNavOpen: true, responsiveNavStyle: {display: 'block'}}
        case CLOSE_RESONSIVE_NAV:
            return { ...state, responsiveNavOpen: false, responsiveNavStyle: {display: 'none'} }
        case GET_NAV_MENU_SUCCESS:
            return { ...state, navMenuHtml: action.payload }
        case GET_FOOTER_SUCCESS:
            return { ...state, footerHtml: action.payload }
        default:
            return state;
    }
}