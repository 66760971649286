import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import AssistanceCIXCard from './assistance-cix-card'
import OutOfExtensionsCard from './out-of-extensions-card'
import ConfirmCIXCard from './confirm-cix-card'
import CreditExtensionCard from './credit-extension-card'
import ErrorOnCIXSubmitCard from './error-on-cix-submit-card'

import { withTranslation } from 'react-i18next'

import { endCIXFlow, showCIXCard, sendCIXConfirmationEmail } from '../../../../actions/auth/payment/cix-actions'
import { submitCreditExtension } from '../../../../actions/auth/payment/payment-actions'

import { paymentConstants } from '../../../../constants/payment-constants'

class CIXCardContainer extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {
        if (this.props.creditExtension.isEligible) {
            switch (this.props.cixCardToShow) {
                case paymentConstants.CIX_CARD:
                    return (
                        <CreditExtensionCard
                            t={this.props.t} i18n={this.props.i18n}
                            selectedBillAccount={this.props.selectedBillAccount}
                            showCIXCardOnChange={this.props.showCIXCardOnChange}
                            shouldSendCIXConfirmationEmail={this.props.shouldSendCIXConfirmationEmail}
                            sendCIXConfirmationEmailOnChange={this.props.sendCIXConfirmationEmailOnChange}
                            emailToSendCIXConfirm={this.props.primaryEmail}
                            maxExtensionDate={this.props.creditExtension.maxExtensionDate}
                            maxExtensionAmount={((this.props.creditExtension.maxExtensionAmount).toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            submittingCreditExtension={this.props.submittingCreditExtension}
                            submitCreditExtensionOnClick={this.props.submitCreditExtensionOnClick}
                            endCIXFlow={this.props.endCIXFlow} />
                    )
                case paymentConstants.OUT_OF_EXTENSIONS_CARD:
                    return <OutOfExtensionsCard extensionNotGrantedReasonCodes={this.props.extensionNotGrantedReasonCodes} endCIXFlow={this.props.endCIXFlow} t={this.props.t} i18n={this.props.i18n} />
                case paymentConstants.CIX_ASSISTANCE_CARD:
                    return <AssistanceCIXCard endCIXFlow={this.props.endCIXFlow} t={this.props.t} i18n={this.props.i18n} />
                case paymentConstants.CIX_CONFIRMATION_CARD:
                    return (
                        <ConfirmCIXCard
                            t={this.props.t} i18n={this.props.i18n}
                            endCIXFlow={this.props.endCIXFlow}
                            shouldSendCIXConfirmationEmail={this.props.shouldSendCIXConfirmationEmail}
                            emailToSendCIXConfirm={this.props.primaryEmail}
                            maxExtensionDate={this.props.creditExtension.maxExtensionDate}
                            maxExtensionAmount={((this.props.creditExtension.maxExtensionAmount).toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            totalAmountDue={((this.props.creditExtension.totalAmountDue).toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            isCommercial={this.props.selectedBillAccountDetails.isCommercial} />
                    )
                case paymentConstants.CIX_ERROR_ON_SUBMIT_CARD:
                    return (<ErrorOnCIXSubmitCard
                        t={this.props.t} i18n={this.props.i18n}
                        endCIXFlow={this.props.endCIXFlow}
                        isCommercial={this.props.selectedBillAccountDetails.isCommercial}/>)
                default:
                    break
            }
        }
        else {
            return (<AssistanceCIXCard
                t={this.props.t} i18n={this.props.i18n} 
                endCIXFlow={this.props.endCIXFlow}
                isCommercial={this.props.selectedBillAccountDetails.isCommercial} />)
        }
    }
}

CIXCardContainer.propTypes = {
    selectedBillAccount: PropTypes.number,
    selectedBillAccountDetails: PropTypes.object,
    primaryEmail: PropTypes.string,
    currentBillInfo: PropTypes.object,

    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired,

    creditExtension: PropTypes.object,
    cixCardToShow: PropTypes.number,
    showCIXCardOnChange: PropTypes.func,
    shouldSendCIXConfirmationEmail: PropTypes.bool,
    sendCIXConfirmationEmailOnChange: PropTypes.func,
    submittingCreditExtension: PropTypes.bool,
    submitCreditExtensionOnClick: PropTypes.func,
    extensionNotGrantedReasonCodes: PropTypes.arrayOf(PropTypes.string),
    endCIXFlow: PropTypes.func
}

const mapStateToProps = state => {
    return { ...state.accountInfo.payment, ...state.login, ...state.accountInfo.billAccount, ...state.accountInfo.contact }
}

const mapDispatchToProps = dispatch => {
    return {
        showCIXCardOnChange: (card) => {
            dispatch(showCIXCard(card))
        },
        sendCIXConfirmationEmailOnChange: (checked) => {
            dispatch(sendCIXConfirmationEmail(checked))
        },
        submitCreditExtensionOnClick: (billAccount, emailAddress) => {
            dispatch(submitCreditExtension(billAccount, emailAddress))
        },
        endCIXFlow: () => {
            dispatch(endCIXFlow())
        }
    }
}

export default withTranslation('dashboardMyBillCIXCard')(connect(mapStateToProps, mapDispatchToProps)(CIXCardContainer))