import React from 'react'
import { withTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

const HelpCard = ({displaySpanish = false, isCommercial, t, i18n}) => {
    const residentialCommercialVerbiage = isCommercial ?
        t("commercial_help_text"):
        t("residential_help_text")

    let nextLanguage = "es" 
    
    if (i18n.language === "es") {
        nextLanguage = "en"
    }

    return (
        <React.Fragment>
            <div className="srp-card-header">
                {t("Need help?")}
            </div>

            <div className="srp-card-body ">
                <div className="srp-card-details srp-orange-background">
                    {residentialCommercialVerbiage}
                    <br/>
                    {displaySpanish &&
                        <div>
                            {t("This form is also available in Spanish.")}&nbsp;<button className="displayAsLink" onClick={() => { i18n.changeLanguage(nextLanguage) }}>{t("English version »")}</button>
                        </div>
                    }
                </div>
            </div>
        </React.Fragment>
    )
}

HelpCard.propTypes = {
    displaySpanish: PropTypes.bool,
    isCommercial: PropTypes.bool,
    t: PropTypes.func.isRequired,
    i18n: PropTypes.object.isRequired
}

export default withTranslation("startStopTransfer")(HelpCard)