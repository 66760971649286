import React from "react"
import PropTypes from 'prop-types'
import EmailItem from './email-item'
import EmailAdd from './email-add'
import { contactConstants } from '../../../../constants/contact-constants'
import { myAccountConstants } from '../../../../constants/myaccount-constants'

class EmailCard extends React.Component {
    constructor(props) {
        super(props)
    }
    getStatusMessage(alertInfo, t) {
        let statusWord = t("SUCCESS")
        let actionWord = alertInfo.actionType === myAccountConstants.ACTION_TYPE.ADD ? "added" : "updated"
        // let statusMessage = `Your email address has been ${actionWord}`
        let statusMessage = t("email_address_added_or_updated", {actionWord: actionWord})

        switch(alertInfo.status) {
            case myAccountConstants.CUSTOMER_ACCOUNT_DATA_STATUS.SUCCESS:
                break
            case myAccountConstants.CUSTOMER_ACCOUNT_DATA_STATUS.FAILURE:
                statusWord = t("FAILED")
                // statusMessage = `Your email address has not been ${actionWord}`
                statusMessage = t("email_address_not_added_updated", {actionWord: actionWord})
                break
            case myAccountConstants.CUSTOMER_ACCOUNT_DATA_STATUS.DUPLICATECONTACT:
                statusWord = t("FAILED")
                statusMessage = t("That email address is already used")
                break
            case myAccountConstants.CUSTOMER_ACCOUNT_DATA_STATUS.MAXCONTACTLIMITREACHED:
                statusWord = ""
                // statusMessage = `You have reached the maximum number of emails (${myAccountConstants.MAX_CONTACT_COUNT}). Please modify or delete existing emails to proceed with changes`
                statusMessage = t("max_number_of_emails", {count: myAccountConstants.MAX_CONTACT_COUNT})
                break
            default:
                statusWord = t("FAILED")
                statusMessage = t("Your request was not completed")
        }

        if (alertInfo.status === myAccountConstants.CUSTOMER_ACCOUNT_DATA_STATUS.MAXCONTACTLIMITREACHED){
            return <div>{statusMessage}</div>
        } else {
            return <div><span style={{fontWeight:"700"}}>{statusWord}</span>&nbsp;{statusMessage}</div>
        }
    }

    render() {
        return (
            <div>
                <label className="d-none d-lg-block h4 text-muted">{this.props.t("Email")}</label>
                {this.props.alertInfo.show && this.props.alertInfo.contactType === contactConstants.CONTACT_TYPE.EMAIL &&
                <div className={this.props.alertInfo.status === myAccountConstants.CUSTOMER_ACCOUNT_DATA_STATUS.SUCCESS ? "srp-alert-success" : "srp-alert-error"}>
                {this.getStatusMessage(this.props.alertInfo, this.props.t)}</div>}
                {this.props.emails.length === 0 &&
                    <div style={{paddingTop: "10px"}}>
                        {this.props.t("There are no email addresses being used for contact and alerts at this time.")}
                    </div>
                }
                {this.props.emails.map((email, index) => {
                    return (
                            <div key={`${email.contactAddress}_${index}`}>
                                <EmailItem
                                    billAccount={this.props.billAccount}
                                    email={email}
                                    updateContactClick={this.props.updateContactClick}
                                    makePrimaryClick={this.props.makePrimaryClick}
                                    updateContactAndMakePrimaryClick={this.props.updateContactAndMakePrimaryClick}
                                    deleteContactClick={this.props.deleteContactClick}
                                    hideAlert={this.props.hideAlert}
                                    openItem={this.props.openItem}
                                    setOpenItem={this.props.setOpenItem}
                                    t={this.props.t}
                                />
                            </div>
                        )
                    })
                }
                <div>
                    <EmailAdd
                        subscribedContacts={this.props.subscribedContacts}
                        addContactsClick={this.props.addContactsClick}
                        addContactsAndCopySubscriptionsClick={this.props.addContactsAndCopySubscriptionsClick}
                        hideAlert={this.props.hideAlert}
                        openItem={this.props.openItem}
                        setOpenItem={this.props.setOpenItem}
                        alertInfo={this.props.alertInfo}
                        t={this.props.t}
                     />
                </div>

            </div>
        )
    }
}

EmailCard.propTypes = {
    billAccount: PropTypes.number.isRequired,
    emails: PropTypes.arrayOf(PropTypes.object).isRequired,
    updateContactClick: PropTypes.func.isRequired,
    makePrimaryClick: PropTypes.func.isRequired,
    updateContactAndMakePrimaryClick: PropTypes.func.isRequired,
    addContactsClick: PropTypes.func.isRequired,
    addContactsAndCopySubscriptionsClick: PropTypes.func.isRequired,
    deleteContactClick: PropTypes.func.isRequired,
    subscribedContacts: PropTypes.object.isRequired,
    alertInfo: PropTypes.object.isRequired,
    hideAlert: PropTypes.func.isRequired,
    openItem: PropTypes.string,
    setOpenItem: PropTypes.func.isRequired,
    t:  PropTypes.func
}

export default EmailCard