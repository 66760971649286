import React from 'react'
import PropTypes from 'prop-types'
import IconButton from '@mui/material/IconButton'
import ChangeBillingAddressForm from '../../account_info/change-billing-address-form'

const initialState = {
    displayUpdateMailingAddress: false,
    displayUpdateMailingAddressSuccess: false
}

class MailingAddressCard extends React.Component {

    constructor(props) {
        super(props)

        this.state = initialState

        this.setDisplayUpdateMailingAddressForm = this.setDisplayUpdateMailingAddressForm.bind(this)
        this.setDisplayUpdateMailingAddressSuccess = this.setDisplayUpdateMailingAddressSuccess.bind(this)
    }

    setDisplayUpdateMailingAddressSuccess() {
        this.setState({ ...initialState, displayUpdateMailingAddressSuccess: true })
    }

    setDisplayUpdateMailingAddressForm(displayUpdateMailingAddress) {
        return () => {
            this.setState({ ...initialState, displayUpdateMailingAddress })
        }
    }

    formattedStreetAddress(mailingAddress) {
        let formattedAddress = `${mailingAddress.streetAddress}`
        return formattedAddress
    }

    formattedCityStateZip(mailingAddress) {
        let formattedAddress = `${mailingAddress.city}, ${mailingAddress.state} ${this.formattedZipcode(mailingAddress.zipcode)}`
        return formattedAddress !== ',  ' ? formattedAddress : ''
    }

    formattedZipcode(zipcode) {
        switch (zipcode.length) {
            case 6:
                return `${zipcode.substring(0, 3)} ${zipcode.substring(3, 6)}`
            case 9:
                return `${zipcode.substring(0, 5)}-${zipcode.substring(5, 9)}`
            default:
                return zipcode
        }
    }

    render() {

        let mailingAddressComponent = (
            <div className="d-flex justify-content-between pl-3 pr-0 pt-3 pb-1" style={{color: "rgba(0, 0, 0, 0.38)"}}>
                {this.formattedStreetAddress(this.props.mailingAddress)}<br />
                {this.formattedCityStateZip(this.props.mailingAddress)}
                <IconButton
                    className="align-self-center"
                    color="primary"
                    onClick={this.setDisplayUpdateMailingAddressForm(true)}
                    size="large"><i className="material-icons">create</i></IconButton>
            </div>
        )

        if (this.state.displayUpdateMailingAddress === true)
            mailingAddressComponent = (<ChangeBillingAddressForm
                setDisplayChangeBillingAddressForm={this.setDisplayUpdateMailingAddressForm}
                setDisplayChangePasswordSuccess={this.setDisplayUpdateMailingAddressSuccess} 
                t={this.props.t}/>)
    
        return (
            <div>
                <label className="d-none d-lg-block h4 text-muted">{this.props.t("Mailing address")}</label>
                {this.state.displayUpdateMailingAddressSuccess &&
                <div className="srp-alert-success">{this.props.t("You’ve successfully updated your mailing address.")}</div>
                }

                {mailingAddressComponent}
             </div>
        )
    }
}

MailingAddressCard.propTypes = {
    mailingAddress: PropTypes.shape({
        streetAddress: PropTypes.string.isRequired,
        city: PropTypes.string.isRequired,
        state: PropTypes.string.isRequired,
        zipcode: PropTypes.string.isRequired,
    }).isRequired,
    t: PropTypes.func
}

export default MailingAddressCard