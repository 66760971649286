import React from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

import '../../../styles/dashboard-page.css'

import MakePurchaseByCard from './make-purchase-by-card'

import OtherPaymentOptions from '../../common_payment/other-payment-options'

import CallAnytime from '../../myaccount_header/call-anytime'

import AccountInfoBarContainer from '../../myaccount_header/info_bar/account-info-bar-container'

import Breadcrumb from '../../common_payment/breadcrumb'

import CashOnlyPaymentCard from '../../common_payment/cash-only-payment-card'
import ConnectedSinglePurchaseInputCard from './single-purchase-input-card'
import CheckDiagramCard from '../common/check-diagram-card'
import HelpFindPayLocationCard from '../../cash_card_page/common_payment_page/help-find-pay-location-card'
import HowToCashPayCardContainer from '../../cash_card_page/common_payment_page/how-to-cash-pay-card-container'

const SinglePurchasePagePure = ({ bankFormOpen, isCashPayTab, isBarcodeEligible, isMpower, isCashOnly, hasLoaded, t }) => (
    <div className="container py-3">
        <CallAnytime />

        <h3 className="mt-3 mb-4">{t("Make a purchase")}</h3>

        <AccountInfoBarContainer />

        <Breadcrumb pageTitle={t("Make a purchase")} />

        <div className="row">
            <div className="col-lg-6 mt-3">
                <MakePurchaseByCard />
            </div>
            <div className="col-lg-6 mt-3 d-none d-lg-block">
                <OtherPaymentOptions
                    showAutoPay={!isCashOnly}
                    showPayCenterLink={true}
                    showGiftPayment={!isCashOnly}
                />
            </div>
           
        </div>
        {isCashOnly && isCashPayTab && hasLoaded &&
        <div className="row mt-3">
            <div className="col-lg-6">
                <CashOnlyPaymentCard/>
            </div>
        </div>}
        {isCashPayTab && hasLoaded
            ?<div> 
            <div className="row">
                <div className="col-lg-6 mt-3">
                    <HowToCashPayCardContainer isBarcodeEligible={isBarcodeEligible} isMpower={isMpower} />
                </div>
                {!isCashOnly &&
                <div className="mt-3 ml-3">
                    <HelpFindPayLocationCard />
                </div>}
            </div>
            </div>
            : <div>
                <div className="row">
                    <div className="col-lg-6 mt-3">
                        <ConnectedSinglePurchaseInputCard />
                    </div>
                    {bankFormOpen &&
                        <div className="d-none d-lg-block mt-3">
                            <CheckDiagramCard />
                        </div>

                    }
                </div>
                {isCashOnly && hasLoaded &&
                <div className="row">
                    <div className="col-lg-6 mt-3">
                    <HowToCashPayCardContainer isBarcodeEligible={isBarcodeEligible} isMpower={isMpower} />
                    </div>
                </div>}
            </div>
        }

        <div className="row">
            <div className="col-12 mt-3 d-lg-none">
                <OtherPaymentOptions
                     showAutoPay={!isCashOnly}
                     showPayCenterLink={true}
                     showGiftPayment={!isCashOnly}
                />
            </div>
        </div>
    </div>
)

SinglePurchasePagePure.propTypes = {
    bankFormOpen: PropTypes.bool.isRequired,
    isCashPayTab: PropTypes.bool.isRequired,
    isBarcodeEligible: PropTypes.bool.isRequired,
    isCashOnly: PropTypes.bool.isRequired,
    isMpower: PropTypes.bool.isRequired,
    hasLoaded: PropTypes.bool.isRequired,

    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired
}

const SinglePurchasePage = withTranslation("singlePurchasePage")(SinglePurchasePagePure)
export { SinglePurchasePage as default, SinglePurchasePagePure }
