/* eslint react/no-multi-comp: "off" */

import React from 'react'
import PropTypes from 'prop-types'

import { multiPaymentConstants } from '../../../constants/payment/multi-payment-constants'
import { myAccountConstants } from '../../../constants/myaccount-constants'
import { billAccountConstants } from '../../../constants/bill-account-constants'

import Icon from '@mui/material/Icon'
import Checkbox from '@mui/material/Checkbox'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import TextField from '@mui/material/TextField'

import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { NumericFormat, PatternFormat } from 'react-number-format'
import { DateTime } from 'luxon'
import { CustomLuxonDateAdapter } from '../../../srp_modules/custom-luxon-date-adapter'
import format from '../../../srp_modules/format'
import utils from '../../../srp_modules/utils'

import SrpTooltip from '../../common_tooltip/srp-tooltip'

let dateFieldProps = {
    minDate: DateTime.now().startOf('day'),
    maxDate: DateTime.now().startOf('day').plus({ days: 59 }),
    minDateMessage: "",
    maxDateMessage: "",
    invalidDateMessage: "",
    disablePast: true
}

function getWarningStyle(errorLevel) {
    let color = "rgb(158,42,43)"

    if (errorLevel === myAccountConstants.ERROR_LEVEL.WARNING) {
        color = "rgb(204,138,0)"
    }

    return { color: color }
}

function getFullStreetAddress(address) {
    let unitNum = ""
    if (address.unitNumber) {
        unitNum = "#" + address.unitNumber
    }

    let fullStreetAddress = address.houseNumber + " " + address.addressDirection + " " + address.streetName + " " + unitNum

    if (fullStreetAddress.length > 24) {
        fullStreetAddress = fullStreetAddress.substring(0, 11).trim() + "..." + fullStreetAddress.substring(fullStreetAddress.length - 10).trim()
    }

    return fullStreetAddress
}

function getDeletePaymentElement(deletePaymentClick, scheduledPayment = {}) {
    return (<Icon onClick={() => { deletePaymentClick(scheduledPayment) }} style={{ cursor: "pointer", color: "rgb(8, 126, 216)", verticalAlign: "middle", fontSize: "20px", marginBottom: "2px" }}>delete</Icon>)
}

function getStatusText(text, color) {
    return (
        <span style={{marginLeft: 'auto', marginRight: 'auto', color: color}}>{text}</span>
    )
}

function getPaymentStatus(paymentInfo, deletePaymentClick, scheduledPayment = {}) {
    if (paymentInfo.isAdditionalPayment && paymentInfo.paymentInfo.isSurePay) {
        return "One-time payment"
    }

    if (paymentInfo.isAdditionalPayment) {
        return "One-time payment"
    }

    if (paymentInfo.paymentInfo.isSurePay) {
        return (
            getStatusText('SurePay', 'rgb(115,123,76)')
        )
    }

    if (paymentInfo.isCashOnly) {
        return "Cash only"
    }

    if (paymentInfo.paymentInfo.isBankrupt === true) {
        return <span>Bankruptcy account&nbsp;&nbsp;&nbsp;&nbsp;{getBankruptcyElement()}</span>
    }

    if (paymentInfo.hasPendingPayment) {
        return (
            <span>
                {getStatusText('Scheduled', 'rgb(115,123,76)')}&nbsp;&nbsp;
                {getDeletePaymentElement(deletePaymentClick, scheduledPayment)}
            </span>
        )
    }

    if (paymentInfo.paymentInfo.amountDue <= 0) {
        if (paymentInfo.paymentInfo.isClosedAccountWithZeroBalance) {
            return "Closed account"
        }

        if (paymentInfo.hoursSinceLastPayment > myAccountConstants.SINGLE_DAY_HOURS) {
            return <span style={{ color: "rgb(115, 123, 76)" }}>No payment due</span>
        } else {
            return <span style={{ color: "rgb(115, 123, 76)" }}>Paid in full</span>
        }
    }

    // There's an amount due at this point.

    if (DateTime.fromISO(paymentInfo.paymentInfo.dueDate) === DateTime.fromISO(myAccountConstants.MINIMUM_DATE)) {
        // No due date but there's an amount due. This is possible
        // if the account is on MPower or it's write-off.
        return "Payment due"
    }

    // There's a payment due date from this point on.

    if (paymentInfo.dueYesterday) {
        return getStatusText('Due yesterday', '#9E2A2B')
    }

    if (paymentInfo.dueToday) {
        return getStatusText('Due today', 'rgb(0,75,135)')
    }

    if (paymentInfo.dueTomorrow) {
        return getStatusText('Due tomorrow', 'rgb(0,75,135)')
    }

    if (paymentInfo.paymentInfo.pastDue > 0) {
        let text = 'Past due (' + DateTime.fromISO(paymentInfo.paymentInfo.dueDate).toFormat("M/d/yy") + ')'
        return getStatusText(text, '#9E2A2B')
    }

    return getStatusText("Due " + DateTime.fromISO(paymentInfo.paymentInfo.dueDate).toFormat("M/d/yy"), 'rgb(0,75,135)')
}

function getBankruptcyElement() {
    return (<SrpTooltip content={<span>This account is associated with a bankruptcy. For questions, please contact our phone center at (602) 236-8888 (for residential accounts) or (602) 236-8833 (for business accounts).</span>}>
        <Icon style={{ color: "rgb(204,138,0)", verticalAlign: "middle", fontSize: "20px" }}>error_outline</Icon>
    </SrpTooltip>)
}

function getAmountDueDisplay(amountDue) {
    const displayAmountDue = Math.abs(amountDue).toLocaleString("en-US", { style: "currency", currency: "USD" })

    return amountDue >= 0 ?
        displayAmountDue :
        displayAmountDue + " CR"
}

function getAmountDue(paymentInfo) {
    if (paymentInfo.isAdditionalPayment)
        return "- -"

    const amountDue = paymentInfo.paymentInfo.amountDue

    const displayAmountDue = getAmountDueDisplay(amountDue)

    if (amountDue >= 0)
        return displayAmountDue

    return <span style={{ color: "green" }}>{displayAmountDue}</span>
}

function getPaymentAmountErrorText(paymentAmountError, billAccount, cixClick) {
    const errorType = paymentAmountError.errorType
    let text = null
    switch (errorType) {
        case multiPaymentConstants.PAYMENT_AMOUNT_ERRORS.INVALID_AMOUNT:
            text = <span>Invalid payment amount</span>
            break
        case multiPaymentConstants.PAYMENT_AMOUNT_ERRORS.LESS_THAN_TOTAL:
            text = <span>Paying less than the total amount due may negatively affect your account, including disconnection.</span>
            break
        case multiPaymentConstants.PAYMENT_AMOUNT_ERRORS.LESS_THAN_TOTAL_CIX_ELIGIBLE:
            text = (
                <span>
                    Paying less than the total amount due may negatively affect your account, including disconnection. Need <span className="myaccount-pointer" style={{ textDecoration: "underline" }} onClick={() => cixClick(billAccount)}>more time to pay</span>? We may be able to assist you.
                </span>
            )
            break
        case multiPaymentConstants.PAYMENT_AMOUNT_ERRORS.MORE_THAN_TOTAL:
            text = <span>You are making a payment that is more than the amount due.</span>
            break
        case multiPaymentConstants.PAYMENT_AMOUNT_ERRORS.PAYMENT_LIMIT:
            {
                let maxPaymentAmountDisplay = utils.delimitNumbers(paymentAmountError.paymentHardLimit)
                text = <span>{"Payments of over " + maxPaymentAmountDisplay + " are not allowed"}</span>
            }
            break
        case multiPaymentConstants.PAYMENT_AMOUNT_ERRORS.REQUIRED:
            text = <span>This field is required</span>
            break
    }

    return text
}

function getPaymentDateErrorText(errorType, billAccount, cixClick) {
    let text = null
    switch (errorType) {
        case multiPaymentConstants.PAYMENT_DATE_ERRORS.REQUIRED:
            text = <span>This field is required</span>
            break
        case multiPaymentConstants.PAYMENT_DATE_ERRORS.INVALID_DATE:
            text = <span>Invalid payment date: Please enter a date in MM/DD/YY format</span>
            break
        case multiPaymentConstants.PAYMENT_DATE_ERRORS.DATE_AFTER_DUE_DATE_CIX_ELIGIBLE:
        case multiPaymentConstants.PAYMENT_DATE_ERRORS.DATE_AFTER_DUE_DATE_PAYMENT_LESS_THAN_TOTAL_CIX_ELIGIBLE:
            text = (
                <span>
                    Paying after the due date may negatively affect your account, including disconnection. Need <span className="myaccount-pointer" style={{ textDecoration: "underline" }} onClick={() => cixClick(billAccount)}>more time to pay</span>? We may be able to assist you.
                </span>
            )
            break
        case multiPaymentConstants.PAYMENT_DATE_ERRORS.DATE_AFTER_DUE_DATE:
        case multiPaymentConstants.PAYMENT_DATE_ERRORS.DATE_AFTER_DUE_DATE_PAYMENT_LESS_THAN_TOTAL:
            text = <span>Paying after the due date may negatively affect your account, including disconnection.</span>
            break
        case multiPaymentConstants.PAYMENT_DATE_ERRORS.DATE_BEFORE_TODAY:
            text = <span>Payment date cannot be earlier than today's date</span>
            break
        case multiPaymentConstants.PAYMENT_DATE_ERRORS.DATE_MORE_THAN_60:
            text = <span>You cannot schedule a payment more than 60 days in advance</span>
            break
        case multiPaymentConstants.PAYMENT_DATE_ERRORS.PAYMENT_ALREADY_SCHEDULED:
            text = <span>You already scheduled a payment. You can make an additional payment for today only</span>
            break
    }

    return text
}

function getInitialPaymentDateValue(paymentInfo, selectedInfo) {
    let initialDate = DateTime.now().startOf('day').toISODate()
    let surePayDate = DateTime.fromISO(paymentInfo.paymentInfo.surePayDate)
    let minimumDate = DateTime.fromISO(myAccountConstants.MINIMUM_DATE)
    if (paymentInfo.hasPendingPayment && !paymentInfo.isAdditionalPayment) {
        if (paymentInfo.paymentInfo.isSurePay) {
            if (surePayDate.toMillis() !== minimumDate.toMillis()) {
                initialDate = paymentInfo.paymentInfo.surePayDate
            }
        } else {
            initialDate = paymentInfo.scheduledPayments[0].paymentDate
        }
    } else if (selectedInfo.isSelected) {
        initialDate = selectedInfo.paymentDate
    }

    return initialDate
}

function getInitialPaymentValue(paymentInfo, selectedInfo) {
    let initialPayment = ''
    let surePayDate = DateTime.fromISO(paymentInfo.paymentInfo.surePayDate)
    let minimumDate = DateTime.fromISO(myAccountConstants.MINIMUM_DATE)
    if (paymentInfo.hasPendingPayment && !paymentInfo.isAdditionalPayment) {
        if (paymentInfo.paymentInfo.isSurePay && surePayDate !== minimumDate) {
            initialPayment = paymentInfo.paymentInfo.amountDue.toFixed(2)
        } else {
            initialPayment = paymentInfo.scheduledPayments[0].paymentAmount.toFixed(2)
        }
    } else if (paymentInfo.paymentInfo.isSurePay && !paymentInfo.isAdditionalPayment) {
        // do nothing. If there is no SurePay payment scheduled, leave blank
    } else if (selectedInfo.isSelected) {
        initialPayment = selectedInfo.paymentAmount
    }

    return initialPayment
}

function getSummaryMasterIcon(summaryBillingStatus) {
    if (summaryBillingStatus === billAccountConstants.SUMMARY_BILLING_STATUS.PARENT) {
        return (
            <div className="text-nowrap summary-account-parent-chip" style={{paddingTop: '2px', paddingBottom: '2px', paddingLeft: '8px', paddingRight: '8px'}}>
                <span style={{marginLeft: 'auto', marginRight: 'auto'}}>Master</span>
            </div>
        )
    } else {
        return null
    }
}

function getRowTitle(paymentInfo, viewBillClick) {
    if (paymentInfo.accountName && paymentInfo.accountName.trim() !== '') {
        return (
            <div className="clearfix" style={{position: 'relative'}}>
                <div style={{fontSize: '18px', fontWeight: '800', float: 'left'}}>
                    <div>
                        <button
                            className="displayAsLink text-truncate"
                            type="button"
                            style={{maxWidth: '200px'}}
                            onClick={() => viewBillClick(paymentInfo.billAccount)}
                        >
                            {paymentInfo.accountName}
                        </button>
                    </div>
                    <div style={{color: '#333333', fontWeight: '500'}}>
                        <PatternFormat
                            value={format.srpAccountNumber(paymentInfo.billAccount)}
                            valueIsNumericString={true}
                            format="###-###-###"
                            displayType="text"
                        />
                    </div>
                </div>
                <div style={{paddingTop: '5px', float: 'right'}}>
                    {getSummaryMasterIcon(paymentInfo.summaryBillingStatus)}
                </div>
            </div>
        )
    } else {
        return (
            <div className="clearfix" style={{position: 'relative'}}>
                <div style={{float: 'left'}}>
                    <button
                        className="displayAsLink"
                        style={{fontSize: '18px', fontWeight: '800', maxWidth: '200px'}}
                        type="button"
                        onClick={() => viewBillClick(paymentInfo.billAccount)}
                    >
                        <PatternFormat
                            value={format.srpAccountNumber(paymentInfo.billAccount)}
                            valueIsNumericString={true}
                            format="###-###-###"
                            displayType="text"
                        />
                    </button>
                </div>
                <div style={{paddingTop: '5px', float: 'right'}}>
                    {getSummaryMasterIcon(paymentInfo.summaryBillingStatus)}
                </div>
            </div>
        )
    }
}

const PayableAccountRowOneTimePaymentResponsive = (props) => {

    let paymentInfo = props.paymentInfo
    let selectedInfo = props.selectedInfo
    let disabled = props.disabled
    let isSelected = disabled ? false : selectedInfo.isSelected
    let scheduledPayment = props.paymentInfo.scheduledPayments.length > 0 ? props.paymentInfo.scheduledPayments[0] : {}
    let multiPaymentAmountInputMobile = React.useRef()

    return (
        <>
            <TableRow
                hover
                role="checkbox"
                aria-checked={false}
                tabIndex={-1}
                selected={false}
                className="open-close"
            >
                <TableCell className="pat-tr-disabled pat-scheduled-payment-top-left" padding="none" style={{ verticalAlign: "top", paddingTop: "10px", width: "45px" }}>
                    <Checkbox
                        onChange={() => props.getSelectedPayableAccountsOnClick(paymentInfo.billAccount, paymentInfo.paymentInfo)}
                        disabled={true}
                        color="primary"
                        checked={false}
                    />
                </TableCell>
                <TableCell className="pat-scheduled-payment-top-right" style={{borderBottom: "1px solid rgb(179,195,219)", paddingBottom: "20px"}}>
                    {getRowTitle(paymentInfo, props.viewBillClick)}
                    <div className="clearfix" style={{ position: "relative" }}>
                        <div style={{maxWidth: "200px"}}>
                            {paymentInfo.summaryBillingStatus !== billAccountConstants.SUMMARY_BILLING_STATUS.PARENT && getFullStreetAddress(paymentInfo.serviceAddress)}
                        </div>
                        <div style={{paddingTop: '3px', paddingBottom: '3px'}}>
                            {getPaymentStatus(paymentInfo.scheduledPaymentInfo, props.deletePaymentClick, scheduledPayment)}
                        </div>
                        <div className="clearfix" style={{position: 'relative'}}>
                            <div style={{float: 'left', color: 'rgba(33, 33, 33, 0.537)'}}>
                                Amount due:
                            </div>
                            <div style={{float: 'right', fontWeight: '800'}}>
                                {getAmountDue(paymentInfo.scheduledPaymentInfo)}
                            </div>
                        </div>
                        <div className="clearfix" style={{position: 'relative'}}>
                            <div style={{float: 'left', color: 'rgba(33, 33, 33, 0.537)'}}>
                                Payment amount:
                            </div>
                            <div style={{float: 'right', fontWeight: '800'}}>
                                {getAmountDueDisplay(getInitialPaymentValue(paymentInfo.scheduledPaymentInfo, selectedInfo))}
                            </div>
                        </div>
                        <div className="clearfix" style={{position: 'relative'}}>
                            <div style={{float: 'left', color: 'rgba(33, 33, 33, 0.537)'}}>
                                Payment date:
                            </div>
                            <div style={{float: 'right', fontWeight: '800'}}>
                                {DateTime.fromISO(getInitialPaymentDateValue(paymentInfo.scheduledPaymentInfo, selectedInfo)).toFormat("M/d/yy")}
                            </div>
                        </div>
                    </div>
                </TableCell>
            </TableRow>
            <TableRow
                hover
                role="checkbox"
                aria-checked={isSelected}
                tabIndex={-1}
                selected={isSelected}
                className="open-close"
            >
                <TableCell className="pat-scheduled-payment-bottom-left" padding="none" style={{ verticalAlign: "top", paddingTop: "12px", width: "45px" }}>
                    <Checkbox
                        onChange={() => props.getSelectedPayableAccountsOnClick(paymentInfo.billAccount, paymentInfo.paymentInfo)}
                        disabled={disabled}
                        color="primary"
                        checked={isSelected}
                    />
                </TableCell>
                <TableCell className="pat-scheduled-payment-bottom-right" style={{paddingTop: "20px"}}>
                    <div style={{fontSize: "18px", color: "black", fontWeight: "800"}}>One time payment</div>
                    <div className="clearfix" style={{ position: "relative" }}>
                        <div className="clearfix" style={{position: 'relative'}}>
                            <div style={{float: 'left', color: 'rgba(33, 33, 33, 0.537)'}}>
                                Amount due:
                            </div>
                            <div style={{float: 'right', fontWeight: '800'}}>
                                {getAmountDue(paymentInfo)}
                            </div>
                        </div>
                    </div>
                    {/* collapsible input  */}
                    <div style={isSelected && (paymentInfo.hasPendingPayment || !disabled) ? { display: 'block'} : { display: 'none'}}>
                        <div className="clearfix" style={{ paddingTop: "15px", position: "relative" }}>
                            <div style={{width: "85%", float: "left"}}>
                                <NumericFormat
                                    id={`paymentAmount${props.index}m`}
                                    getInputRef={multiPaymentAmountInputMobile}
                                    placeholder="Enter amount"
                                    disabled={disabled}
                                    value={getInitialPaymentValue(paymentInfo, selectedInfo)}
                                    valueIsNumericString={true}
                                    onBlur={(values) => {
                                        const paymentAmount = values.target.value
                                        if (paymentAmount === "") {
                                            if (selectedInfo.isSelected)
                                                props.getSelectedPayableAccountsOnClick(paymentInfo.billAccount, paymentInfo.paymentInfo)
                                        }
                                        else {
                                            let fixedPaymentAmount = Number(paymentAmount.replace(/[$,]/g, '')).toFixed(2)
                                            props.getPaymentAmountOnChange(paymentInfo.billAccount, fixedPaymentAmount)
                                        }
                                    }}
                                    decimalScale={2}
                                    thousandSeparator={true}
                                    prefix={'$'}
                                    fixedDecimalScale={false}
                                    allowNegative={false}
                                    customInput={TextField}
                                    type="text"
                                    fullWidth={true}
                                    className="pat-input-box-responsive"
                                    label="Payment amount"
                                />
                            </div>
                            <div style={{width: "15%", paddingTop: "30px", paddingLeft: "10px", float: "left"}}>
                                {isSelected && (selectedInfo.paymentAmountError.hasError) &&
                                    <SrpTooltip
                                        content={<span>{getPaymentAmountErrorText(selectedInfo.paymentAmountError, props.paymentInfo.billAccount, props.cixClick)}</span>}>
                                        <Icon style={{ ...getWarningStyle(selectedInfo.paymentAmountError.errorLevel), verticalAlign: "middle", fontSize: "20px" }}>error_outline</Icon>
                                    </SrpTooltip>
                                }
                            </div>
                        </div>
                        <div className="clearfix" style={{ paddingTop: "10px", paddingBottom: "15px", position: "relative" }}>
                            <div style={{ width: "85%", float: "left"}}>
                                <LocalizationProvider dateAdapter={CustomLuxonDateAdapter} adapterLocale="en-US">
                                    <DatePicker
                                        {...dateFieldProps}
                                        keyboard
                                        clearable
                                        disableOpenOnEnter
                                        disablePast
                                        disabled={disabled || paymentInfo.isAdditionalPayment}
                                        className="multi-payment-date-picker-mobile pat-input-box-responsive"
                                        value={DateTime.fromISO(getInitialPaymentDateValue(paymentInfo, selectedInfo))}
                                        format="M/d/yyyy"
                                        onChange={(selectedDate) => props.getPaymentDateOnChange(paymentInfo.billAccount, selectedDate, selectedInfo.paymentAmount)}
                                        label="Payment date"
                                        slotProps={{ textField: { fullWidth: true } }}
                                    />
                                </LocalizationProvider>
                            </div>
                            <div style={{width: "15%", paddingTop: "30px", paddingLeft: "10px", float: "left"}}>
                                {isSelected && (selectedInfo.paymentDateError.hasError) &&
                                    <SrpTooltip
                                        content={<span>{getPaymentDateErrorText(selectedInfo.paymentDateError.errorType, props.paymentInfo.billAccount, props.cixClick)}</span>}>
                                        <Icon style={{ ...getWarningStyle(selectedInfo.paymentDateError.errorLevel), verticalAlign: "middle", fontSize: "20px" }}>error_outline</Icon>
                                    </SrpTooltip>
                                }
                            </div>
                        </div>
                    </div>
                </TableCell>
            </TableRow>
            {paymentInfo.summaryBillingStatus !== billAccountConstants.SUMMARY_BILLING_STATUS.PARENT &&
            <TableRow style={{height: "10px", maxWidth: "300px"}} />}
        </>
    )
}

PayableAccountRowOneTimePaymentResponsive.propTypes = {
    paymentInfo: PropTypes.object.isRequired,
    selectedInfo: PropTypes.object.isRequired,
    disabled: PropTypes.bool.isRequired,
    index: PropTypes.number.isRequired,
    getSelectedPayableAccountsOnClick: PropTypes.func.isRequired,
    viewBillClick: PropTypes.func.isRequired,
    getPaymentAmountOnChange: PropTypes.func.isRequired,
    getPaymentDateOnChange: PropTypes.func.isRequired,
    deletePaymentClick: PropTypes.func.isRequired,
    cixClick: PropTypes.func.isRequired
}

export default PayableAccountRowOneTimePaymentResponsive