import { outageConstants } from '../../../constants/outage-constants'

export const getNextOutageHistory = () => ({
    type: outageConstants.GET_NEXT_OUTAGE_HISTORY
})

export const getPrevOutageHistory = () => ({
    type: outageConstants.GET_PREV_OUTAGE_HISTORY
})

