import { safetyNetConstants } from '../../../constants/safety-net-constants'
import { billAccountConstants } from '../../../constants/bill-account-constants'
import * as loadingStatus from '../../../constants/loading-status-constants'

const initialState = {
    submitSafetyNetFormResult: {
        success: false,
        errorMessage: ""
    },
    submitSafetyNetFormStatus: loadingStatus.LOADING_STATUS_INIT,
    deleteSafetyNetStatus: loadingStatus.LOADING_STATUS_INIT
}

export default function safetyNetReducer(state = initialState, action) {
    switch (action.type) {
        case safetyNetConstants.SUBMIT_SAFETYNET_FORM_REQUEST:
        case safetyNetConstants.UPDATE_SAFETYNET_REQUEST:
            return { ...state, submitSafetyNetFormStatus: loadingStatus.LOADING_STATUS_IN_PROGRESS }
        case safetyNetConstants.SUBMIT_SAFETYNET_FORM_SUCCESS:
        case safetyNetConstants.UPDATE_SAFETYNET_SUCCESS:
            return {
                ...state,
                submitSafetyNetFormResult: action.payload,
                submitSafetyNetFormStatus: action.payload.success ? loadingStatus.LOADING_STATUS_SUCCESS : loadingStatus.LOADING_STATUS_FAILURE
            }
        case safetyNetConstants.SUBMIT_SAFETYNET_FORM_FAILURE:
        case safetyNetConstants.UPDATE_SAFETYNET_FAILURE:
            return {
                ...state,
                submitSafetyNetFormResult: initialState.submitSafetyNetFormResult,
                submitSafetyNetFormStatus: loadingStatus.LOADING_STATUS_FAILURE
            }
        case safetyNetConstants.DELETE_SAFETYNET_REQUEST:
            return { ...state, deleteSafetyNetStatus: loadingStatus.LOADING_STATUS_IN_PROGRESS }
        case safetyNetConstants.DELETE_SAFETYNET_SUCCESS:
            return { ...state, deleteSafetyNetStatus: action.payload.success ? loadingStatus.LOADING_STATUS_SUCCESS : loadingStatus.LOADING_STATUS_FAILURE }
        case safetyNetConstants.DELETE_SAFETYNET_FAILURE:
            return { ...state, deleteSafetyNetStatus: loadingStatus.LOADING_STATUS_FAILURE }
        case safetyNetConstants.RESET_SAFETYNET_FORM:
            return { ...state, submitSafetyNetFormStatus: loadingStatus.LOADING_STATUS_INIT }
        case billAccountConstants.SELECT_BILL_ACCOUNT_SUCCESS:
            return { ...initialState }
        default:
            return state
    }
}