import React from 'react'
import PropTypes from 'prop-types'

import TextField from '@mui/material/TextField'
import MenuItem from '@mui/material/MenuItem'
import FormHelperText from '@mui/material/FormHelperText'

import format from '../../../../srp_modules/format'

const BankAccountSelector = ({bankAccountList, t, ...props}) => {
    const isError = props.touched['bankAccountIdSelected']
        && Object.prototype.hasOwnProperty.call(props.errors, 'bankAccountIdSelected')
    let errMsg = isError ? props.errors['bankAccountIdSelected'] : ''

    const getLocalizedErrMsg = errMsg.getLocalizedErrMsg
    if (typeof getLocalizedErrMsg === 'function')
        errMsg = getLocalizedErrMsg()
    else if (errMsg !== "")
        errMsg = t(errMsg)

    const id = 'bankAccountIdSelected'
    const name = 'bankAccountIdSelected'
    const meta = {
        id: id,
        name: name,
        error: isError,
        fullWidth: true,
        SelectProps: { SelectDisplayProps: { id, name } },
    }

    return (
        <div>
            <TextField select {...props} {...meta} label={t("Select a bank account")}>
                {bankAccountList.map((bank) =>
                    <MenuItem key={bank.bankId} value={bank.bankId}>{format.bankAccount(bank)}</MenuItem>
                )}
            </TextField>
            <FormHelperText error={isError} style={!isError ? {display: 'none'} : {}}>{errMsg}</FormHelperText>
        </div>
    )
}

BankAccountSelector.propTypes = {
    t: PropTypes.func.isRequired,
    bankAccountList: PropTypes.arrayOf(PropTypes.shape({
        bankId: PropTypes.number.isRequired,
        bankName: PropTypes.string.isRequired,
        nickName: PropTypes.string.isRequired,
        accountNumber: PropTypes.string.isRequired
    })).isRequired,
    touched: PropTypes.shape({
        bankAccountIdSelected: PropTypes.bool
    }).isRequired,
    errors: PropTypes.shape({
        bankAccountIdSelected: PropTypes.oneOfType([
            PropTypes.string.isRequired,
            PropTypes.shape({
                getLocalizedErrMsg: PropTypes.func.isRequired,
            }),
        ]),
    }).isRequired
}

export default BankAccountSelector