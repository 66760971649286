import { replace } from '@lagunovsky/redux-react-router'
import { beginLogout, setSiteOffline } from '../../actions/auth/login/login-actions'
import { loginConstants } from '../../constants/index'

export const authRedirectMiddleware = ({ dispatch, getState }) => next => action => {
    if (typeof action === 'function')
        return next(action)

    let nextAction = next(action)

    if (action.error === true && action.payload !== undefined) {
        switch (action.payload.status) {
            case 401:
                if (action.type === loginConstants.LOGIN_PROFILE_FAILURE) {
                    return nextAction
                }
                if (getState().login.isLoggedIn) {
                    dispatch(beginLogout())
                }
                break
            case 403: {
                //window.location.reload()
                break
            }
            case 503: {
                if (action.payload && action.payload.response) {
                    dispatch(setSiteOffline(action.payload.response))
                }
                else {
                    replace('/error')
                }
            }
        }
    }

    return nextAction
}