// eslint is detecting the extra functions as multiple React components
/* eslint react/no-multi-comp: "off" */
import React from 'react'
import PropTypes from 'prop-types'
import { DateTime } from 'luxon'

import { withTranslation } from 'react-i18next'

import '../../styles/dashboard-page.css'
import MessageContainer from './message-container'
import EppStatusMessage from '../epp-status/epp-status-message'
import Cards from './cards'
import PendingTurnOnCard from '../common_pending_turn_on/pending-turn-on-card'

let getPasswordResetSuccessMessage = (success, closeAlert, t) => {
    if(success === true)
    {
        return(
            <div className="srp-card-body srp-alert-success d-flex justify-content-between mt-3 align-items-center">
                <div>{t("You've successfully updated your password.")}</div>
                <button className="mui-button p-1" aria-label="close banner" onClick={()=>{closeAlert()}}>
                    <i className="material-icons align-middle">close</i>
                </button>
            </div>
        )
    }
}

let getClosedAccountWarningMessage = (isClosed, closedDate, t, i18n) => {
    if(closedDate || isClosed) {
        if(closedDate && DateTime.fromISO(closedDate) < DateTime.now().startOf('day'))
        {
            const dateFormat = i18n.language === "es" ? "d/M/yyyy" : "M/d/yyyy"
            return(
                <div className="srp-card-body srp-alert-error d-flex justify-content-between mt-3">
                    <div>{t("This_account_was_closed_on", {closedDate: DateTime.fromISO(closedDate).toFormat(dateFormat)})}</div>
                </div>
            )
        }
        else {
            return(
                <div className="srp-card-body srp-alert-error d-flex justify-content-between mt-3">
                    <div>{t('This account is closed.')}</div>
                </div>
            )
        }
    }
}

const DashboardPage = ({passwordResetComplete, closePasswordResetSuccessMessageOnClick, accountClosedDate, isPendingTurnOn, navLocation, isClosed, t, i18n}) => {
    return (
      <div>
            <EppStatusMessage />
            <MessageContainer />
            {getPasswordResetSuccessMessage(passwordResetComplete, closePasswordResetSuccessMessageOnClick, t)}
            {getClosedAccountWarningMessage(isClosed, accountClosedDate, t, i18n)}
            {isPendingTurnOn
                ? <PendingTurnOnCard navLocation={navLocation} />
                : <Cards  />
            }
      </div>
    )
}

DashboardPage.propTypes = {
    passwordResetComplete: PropTypes.bool,
    closePasswordResetSuccessMessageOnClick: PropTypes.func,
    accountClosedDate: PropTypes.string,
    isPendingTurnOn: PropTypes.bool,
    navLocation: PropTypes.string,
    isClosed: PropTypes.bool,
    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired,
}

export default withTranslation('dashboardPage')(DashboardPage)