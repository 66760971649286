import React from "react";
import PropTypes from 'prop-types'
import TextField from '@mui/material/TextField'
import IconButton from '@mui/material/IconButton'
import Icon from '@mui/material/Icon'

const PasswordField = ({password, passwordOnChange, passwordOnBlur, idSuffix, showPassword, unmaskPasswordIconClick,
    isLoginError, loginErrorText, autoFocus, t}) => (
    <TextField
        fullWidth
        type={showPassword ? 'text' : 'password'}
        id={"password"+idSuffix}
        name="password"
        label={t("Password")}
        value={password}
        error={isLoginError}
        helperText={isLoginError ? loginErrorText : ''}
        onChange={passwordOnChange}
        onBlur={passwordOnBlur}
        autoFocus={autoFocus}
        InputProps={{
            endAdornment:
                <IconButton
                    /* If showPassword is true, password is showing, so we want the button label to read hide password. */
                    aria-label={showPassword ? 'hide password' : 'show password'}
                    alt={showPassword ? 'hide password button' : 'show password button'}
                    color="primary"
                    className="button-focus"
                    onClick={() => unmaskPasswordIconClick()}
                    size="large">
                    <Icon>{showPassword ? 'visibility_off' : 'visibility'}</Icon>
                </IconButton>
        }} />
)

PasswordField.propTypes = {
    password: PropTypes.string.isRequired,
    passwordOnChange: PropTypes.func.isRequired,
    passwordOnBlur: PropTypes.func.isRequired,
    idSuffix: PropTypes.string.isRequired,
    unmaskPasswordIconClick: PropTypes.func.isRequired,
    showPassword: PropTypes.bool.isRequired,
    isLoginError: PropTypes.bool,
    loginErrorText: PropTypes.string,
    autoFocus: PropTypes.bool,
    t: PropTypes.func
}

export default PasswordField