export const formConstants = {
  EZ_3: "EZ_3",
  EZ_34: "EZ_34",
  TIME_OF_USE: "TIME_OF_USE",
  ELECTRIC_VEHICLE: "ELECTRIC_VEHICLE",
  BASIC: "BASIC",
  CUSTOMER_GENERATION: "CUSTOMER_GENERATION",
  MPOWER: "MPOWER",

  SOLARCHOICE_100: "SolarChoice100",
  SOLARCHOICE_50: "SolarChoice50",
  SOLARCHOICE_NONE: "None",
  SOLARCHOICE_UNENROLL: "UnEnroll",

  PREFER_ENGLISH: "PREFERRED_ENGLISH",
  PREFER_SPANISH: "PREFERRED_SPANISH",

  SHOW_SERVICE_DATES: "SHOW_SERVICE_DATE",
  SHOW_SERVICE_DETAILS: "SHOW_SERVICE_DETAIL",
  SHOW_ABOUT_YOU: "SHOW_ABOUT_YOU",
  SHOW_SAFETY: "SHOW_SAFETY",
  SHOW_CONFIRMATION: "SHOW_CONFIRMATION",

  // Identification types
  SOCIAL: "SOCIAL",
  DRIVERS_LICENSE: "DRIVERS_LICENSE",
  STATE_ID: "STATE_ID",
  GOVERNMENT_ID: "GOVERNMENT_ID",
  MILITARY_ID: "MILITARY_ID",
  RESIDENCY_ID: "RESIDENCY_ID",
  NATURALIZATION: "NATURALIZATION",
  PASSPORT: "PASSPORT",
  MEXICAN_VOTER: "MEXICAN_VOTER",
  TEMP_WORK_AUTH: "TEMP_WORK_AUTH",
  ITIN: "ITIN",

  // EV
  VEHICLE_OWN: "VEHICLE_OWN",
  VEHICLE_LEASE: "VEHICLE_LEASE",
  SUPPLY_TYPE_1: "SUPPLY_TYPE_1",
  SUPPLY_TYPE_2: "SUPPLY_TYPE_2",
  SUPPLY_DONT_KNOW: "SUPPLY_DONT_KNOW",
  
  INPUT_VOLTAGE_120: "INPUT_VOLTAGE_120",
  INPUT_VOLTAGE_220: "INPUT_VOLTAGE_220",
  VOLTAGE_DONT_KNOW: "VOLTAGE_DONT_KNOW",

  AMPS_15: "AMPS_15",
  AMPS_20: "AMPS_20",
  AMPS_DONT_KNOW: "AMPS_DONT_KNOW",

  // Preferred language
  ENGLISH: "ENGLISH",
  SPANISH: "SPANISH",

  // Name events
  FIRST_NAME_CHANGE: "FIRST_NAME_CHANGE",
  MIDDLE_NAME_CHANGE: "MIDDLE_NAME_CHANGE",
  LAST_NAME_CHANGE: "LAST_NAME_CHANGE",

  // About you fields
  FIRST_NAME: "FIRST_NAME",
  MIDDLE_INITIAL: "MIDDLE_INITIAL",
  LAST_NAME: "LAST_NAME",
  IDENTIFICATION_TYPE: "IDENTIFICATION_TYPE",
  IDENTIFICATION_NUMBER: "IDENTIFICATION_NUMBER",
  PREFERRED_LANGUAGE: "PREFERRED_LANGUAGE",
  EMAIL_ADDRESS: "EMAIL_ADDRESS",
  PHONE_NUMBER: "PHONE_NUMBER",
  AUTHORIZE_SPOUSE: "AUTHORIZE_SPOUSE",
  SPOUSE_FIRST_NAME: "SPOUSE_FIRST_NAME",
  SPOUSE_LAST_NAME: "SPOUSE_LAST_NAME",
  CONFIRM_EMAIL: "CONFIRM_EMAIL",
  CONFIRM_PHONE: "CONFIRM_PHONE",

  // Actions 
  TURN_ON_DETAIL_CHANGE: "TURN_ON_DETAIL_CHANGE",
  CHANGE_RATE_PLAN: "CHANGE_RATE_PLAN",
  CHANGE_SOLARCHOICE: "CHANGE_SOLARCHOICE",
  CHANGE_START_DATE: "CHANGE_START_DATE",
  CHANGE_ADDRESS1: "CHANGE_ADDRESS1",
  CHANGE_ADDRESS2: "CHANGE_ADDRESS2",
  CHANGE_CITY: "CHANGE_CITY",
  CHANGE_MAILING_ADDRESS: "CHANGE_MAILING_ADDRESS",
  CHANGE_PAPERLESS_BILLING: "CHANGE_PAPERLESS_BILLING",
  CHANG_CUSTOM_DUE_DATE: "CHANG_CUSTOM_DUE_DATE",
  CHANGE_CAR_MAKE: "CHANGE_CAR_MAKE",
  CHANGE_CAR_MODEL: "CHANGE_CAR_MODEL",
  CHANGE_CAR_YEAR: "CHANGE_CAR_YEAR",
  CHANGE_VEHICLE_OWNERSHIP: "CHANGE_VEHICLE_OWNERSHIP",
  CHANGE_SUPPLY_EQUIP: "CHANGE_SUPPLY_EQUIP",
  CHANGE_INPUT_VOLTAGE: "CHANGE_INPUT_VOLTAGE",
  CHANGE_CURRENT: "CHANGE_CURRENT",
  CHANGE_FIRST_NAME: "CHANGE_FIRST_NAME",
  CHANGE_MIDDLE_NAME: "CHANGE_MIDDLE_NAME",
  CHANGE_LAST_NAME: "CHANGE_LAST_NAME",
  CHANGE_ID_TYPE: "CHANGE_ID_TYPE",
  CHANGE_ID_NUMBER: "CHANGE_ID_NUMBER",
  CHANGE_PREFERRED_LANGUAGE: "CHANGE_PREFERRED_LANGUAGE",
  CHANGE_EMAIL: "CHANGE_EMAIL",
  CHANGE_PHONE_NUMBER: "CHANGE_PHONE_NUMBER",
  CHANGE_AUTH_SPOUSE: "CHANGE_AUTH_SPOUSE",
  CHANGE_SPOUSE_FIRST_NAME: "CHANGE_SPOUSE_FIRST_NAME",
  CHANGE_SPOUSE_LAST_NAME: "CHANGE_SPOUSE_LAST_NAME",
  CHANGE_SAFETY_AGREEMENT: "CHANGE_SAFETY_AGREEMENT",

  CHANGE_MAILING_ADDRESS1: "CHANGE_MAILING_ADDRESS1",
  CHANGE_MAILING_ADDRESS2: "CHANGE_MAILING_ADDRESS2",
  CHANGE_MAILING_CITY: "CHANGE_MAILING_CITY",
  CHANGE_MAILING_STATE: "CHANGE_MAILING_STATE",
  CHANGE_MAILING_COUNTRY: "CHANGE_MAILING_COUNTRY",
  CHANGE_MAILING_ZIPCODE: "CHANGE_MAILING_ZIPCODE",

  // Validation
  RUN_VALIDATION_CHECK: "RUN_VALIDATION_CHECK",

  START_SERVICE: 0,
  ADDITIONAL_SERVICE: 3,

  SUBMIT_TURNON_SUCCESS: "SUBMIT_TURNON_SUCCESS",
  SUBMIT_TURNON_REQUEST: "SUBMIT_TURNON_REQUEST",
  SUBMIT_TURNON_FAILURE: "SUBMIT_TURNON_FAILURE",

  REFRESH_START_FORM: "REFRESH_START_FORM",

  SUCCESSFUL: "SUCCESSFUL",
  FAILED: "FAILED",

  AM: "AM",
  PM: "PM",
  
  HOLIDAY_SUCCESS: "HOLIDAY_SUCCESS",
  HOLIDAY_FAILURE: "HOLIDAY_FAILURE",
  HOLIDAY_REQUEST: "HOLIDAY_REQUEST"
}
