// error condition card

import React from 'react'
import PropTypes from 'prop-types'
import CircularProgress from '@mui/material/CircularProgress'

let style = {
    marginTop: "20%",
    marginBottom: "20%"
}

const CardLoading = ({title}) => (
    <div className="srp-card">
        <div className="srp-card-header">
            {title}
        </div>
        <div className="srp-card-body d-flex justify-content-center">
            <CircularProgress size={80} thickness={5} style={style} />
        </div>
    </div>
)

CardLoading.propTypes = {
    title: PropTypes.string
}

export default CardLoading