import React from 'react'
import { withTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import Card from '@mui/material/Card'
import CircularProgress from '@mui/material/CircularProgress'

import { myAccountConstants } from '../../../constants/myaccount-constants'
import utils from '../../../srp_modules/utils'

import SinglePurchaseDetailsTableContainer from './single-purchase-details-table-container'
import MpowerPurchaseAcknowledgement from './mpower-purchase-acknowledgement'

import { formEvent } from '../../../srp_modules/analytics-events'
import { purchaseConstants } from '../../common_payment/purchase-constants'
import { withRouter } from '../../../srp_modules/with-router'

const ConfirmPurchaseDetailsCardPure = ({ isSubmitting, submitPurchase, goBackToEditPurchaseDetails, errorSubmittingPurchase,
    purchase, amountOwedToSRP, payDownFraction, router, t, i18n }) => {

    const [confirmPurchaseFormEventEmitted, setConfirmPurchaseFormEventEmitted] = React.useState(false)

    if (typeof purchase === 'undefined') {
        router.navigate('/myaccount/payment/single/mpower', { replace: true })
        return null
    }

    const formName = purchaseConstants.ADOBE_ANALYTICS_FORM_NAME

    if (confirmPurchaseFormEventEmitted === false) {
        const stepName = purchaseConstants.PURCHASE_STEPS[1]
        formEvent(formName, stepName)

        setConfirmPurchaseFormEventEmitted(true)
    }

    const goBackToEditPurchaseDetailsAndTrack = () => {
        const stepName = purchaseConstants.PURCHASE_STEPS[0]
        formEvent(formName, stepName)

        goBackToEditPurchaseDetails()
    }

    const submitPurchaseAndTrack = () => {
        const purchaseAmount = purchase.purchaseAmount
        const applyWholePurchaseTowardsArrears = purchase.payAmountOwedToSRP

        let amountToSRP = 0
        let amountToMeter = purchaseAmount

        if (amountOwedToSRP > 0)
            ({ amountToMeter, amountToSRP } = utils.calcAmountToMeterAndToSRP({ purchaseAmount, amountOwedToSRP, payDownFraction, applyWholePurchaseTowardsArrears }))

        submitPurchase(amountToSRP, amountToMeter)
    }

    return (
        <div>
            <label className="h5">{t("Confirm purchase information")}</label>
            <Card>
                {errorSubmittingPurchase &&
                    <div className="srp-alert-error mx-3 mt-3">
                        {`${t("We_are_unable_to_complete_your_purchase")} ${i18n.language === 'es' ? myAccountConstants.SPANISH_CUSTOMER_SERVICE_PHONE_NUMBER_FMTTD : myAccountConstants.RESIDENTIAL_CUSTOMER_SERVICE_PHONE_NUMBER_FMTTD}.`}
                    </div>
                }

                <SinglePurchaseDetailsTableContainer />

                <MpowerPurchaseAcknowledgement />

                <div className="px-3 pb-3">
                    <h6 className="font-14 text-muted">{t("By_clicking_the_Submit_purchase_button")}</h6>

                    <div className="d-flex justify-content-start mt-4 flex-row-reverse flex-wrap-reverse">
                        <button className="btn srp-btn btn-green mt-2" type="button" onClick={submitPurchaseAndTrack} disabled={isSubmitting}>
                            {isSubmitting ? <CircularProgress size={20} thickness={5} style={{ color: 'white' }} /> : t("Submit purchase")}
                        </button>
                        <button className={"btn srp-btn btn-lightblue mx-2"} disabled={isSubmitting} type="button" onClick={goBackToEditPurchaseDetailsAndTrack} {...isSubmitting && { 'aria-disabled': true, tabIndex: -1 }}>
                            <span className="d-lg-none">{t("Edit")}</span>
                            <span className="d-none d-lg-block">{t("Edit purchase details")}</span>
                        </button>
                    </div>
                </div>
            </Card>
        </div>
    )
}

ConfirmPurchaseDetailsCardPure.propTypes = {
    isSubmitting: PropTypes.bool.isRequired,
    submitPurchase: PropTypes.func.isRequired,
    goBackToEditPurchaseDetails: PropTypes.func.isRequired,
    errorSubmittingPurchase: PropTypes.bool.isRequired,

    purchase: PropTypes.shape({
        srpAccountNumber: PropTypes.string.isRequired,
        purchaseAmount: PropTypes.number.isRequired,
        payAmountOwedToSRP: PropTypes.bool.isRequired
    }),
    amountOwedToSRP: PropTypes.number.isRequired,
    payDownFraction: PropTypes.number.isRequired,

    router: PropTypes.shape({
        navigate: PropTypes.func
    }).isRequired,

    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired
}

const ConfirmPurchaseDetailsCard = withTranslation("singlePurchasePage")(withRouter(ConfirmPurchaseDetailsCardPure))
export { ConfirmPurchaseDetailsCard as default, ConfirmPurchaseDetailsCardPure }