import { isRSAA, RSAA } from 'redux-api-middleware'

// NOTE: This does not perform the actual debouncing,
// it only adds the FSA object to our API calls,
// in order to signal to the redux-debounced middleware.
const apiDebounceOptionsMiddleware = ({ getState }) => next => action => {
    if (!isRSAA(action))
        return next(action)

    if (action.meta && action.meta.debounce)
        return next(action)

    let rsaa = action[RSAA]
    let rsaaRequestType = rsaa.types[0]
    let requestTypeString = (typeof rsaaRequestType === 'string') ? rsaa.types[0] : rsaa.types[0].type

    let state = getState()
    let billAccountFromState = state.accountInfo.billAccount.selectedWaterAccount > 0
                             ? state.accountInfo.billAccount.selectedWaterAccount
                             : state.accountInfo.billAccount.selectedBillAccount

    // NOTE: If the API request includes the bill account in its meta data,
    // include that in the debounce key, otherwise use the redux store selected account
    // This will debounce requests per bill account, allowing us to increase the time
    let billAccount = rsaa.meta && rsaa.meta.billAccount
                    ? rsaa.meta.billAccount
                    : rsaaRequestType.meta && rsaaRequestType.meta.billAccount
                        ? rsaaRequestType.meta.billAccount
                        : billAccountFromState

    let debounceKey = `${requestTypeString}_${billAccount}`

    let nextAction = {
        ...action,
        meta: {
            ...action.meta,
            debounce: {
                key: debounceKey,
                time: 2000,
                leading: true,
                trailing: false
            }
        }
    }

    return next(nextAction)
}

export { apiDebounceOptionsMiddleware }