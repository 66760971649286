import React from 'react'
import PropTypes from 'prop-types'
import { Formik } from 'formik'
import * as Yup from 'yup'

import SinglePaymentForm from './single-payment-form'

class SinglePaymentFormik extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            payMultipleAccountsButtonClicked: false,
            initialBankFormOpen: props.bankFormOpen
        }

        if (props.bankAccountList.length === 0 && props.bankFormOpen === false) {
            props.setBankFormOpen()
            this.state.initialBankFormOpen = true
        }

        this.setPayMultipleAccountsButtonClicked = this.setPayMultipleAccountsButtonClicked.bind(this)
        this.clearPayMultipleAccountsButtonClicked = this.clearPayMultipleAccountsButtonClicked.bind(this)

        this.addBankAccountButtonClicked = this.addBankAccountButtonClicked.bind(this)
        this.addBankCancelButtonClicked = this.addBankCancelButtonClicked.bind(this)

        this.validationSchema = Yup.object().shape({
            srpAccountNumber: Yup.string().ensure()
                .transform(function (srpAccountNumber) {
                    return this.isType(srpAccountNumber) && srpAccountNumber !== null ? srpAccountNumber.replace(/[- ]/g, '') : srpAccountNumber
                })
                .required('SRP account number is required.')
                .test('nnn-nnn-nnn format', 'Invalid SRP account number.', srpAccountNumber => /^\d{9}$/.test(srpAccountNumber)),
            bankFormOpen: Yup.boolean(),
            bankAccountIdSelected: Yup.number()
                .when('bankFormOpen', {
                    is: false,
                    then: (schema) => schema
                        .moreThan(0, 'Invalid bank account ID.')
                }),
            bankFirstName: Yup.string()
                .when('bankFormOpen', {
                    is: true,
                    then: (schema) => schema.ensure().trim()
                        .required('First name is required.')
                }),
            bankLastName: Yup.string()
                .when('bankFormOpen', {
                    is: true,
                    then: (schema) => schema.ensure().trim()
                        .required('Last name is required.')
                        .test('not all spaces', 'Last name is required.', bankLastName => typeof bankLastName === "string" && bankLastName.trim().length > 0)
                        .test('at least 2 characters', 'Last name must have at least two characters.', bankLastName => typeof bankLastName === "string" && bankLastName.trim().length > 1)
                }),
            bankPhoneNumber: Yup.string()
                .when('bankFormOpen', {
                    is: true,
                    then: (schema) => schema.ensure().trim()
                        .transform(function (bankPhoneNumber) {
                            return this.isType(bankPhoneNumber) && bankPhoneNumber !== null ? bankPhoneNumber.replace(/[() -]/g, '') : bankPhoneNumber
                        })
                        .required('Phone number is required.')
                        .test('must have 10 digits', 'Phone number must have 10 digits.', bankPhoneNumber => /^\d{10}$/.test(bankPhoneNumber))
                }),
            bankEmailAddress: Yup.string()
                .when('bankFormOpen', {
                    is: true,
                    then: (schema) => schema.ensure().trim()
                        .required('Email address is required.')
                        .email('Invalid email address.')
                }),
            bankRoutingNumber: Yup.string()
                .when('bankFormOpen', {
                    is: true,
                    then: (schema) => schema.ensure().trim()
                        .required('Routing number is required.')
                        .test('must have 9 digits', 'Enter a valid 9-digit routing number.', bankRoutingNumber => /^\d{9}$/.test(bankRoutingNumber))
                }),
            bankAccountNumberLeadingZeroesAreSignificant: Yup.string()
                .when('bankFormOpen', {
                    is: true,
                    then: (schema) => schema.ensure().trim()
                        .required('Bank account number is required.')
                        .test('17 digits max', 'Your account number must be 1-17 digits.', bankAccountNumber => /^\d{1,17}$/.test(bankAccountNumber))
                }),
            paymentAmount: Yup.string().ensure().trim()
                .required('A payment amount is required.')
                .test('payment amount is valid', 'Invalid payment amount.', paymentAmount => /^(\$?\d+(,\d{3})*\.?[0-9]?[0-9]?|\$?\.\d{1,2})$/.test(paymentAmount)) // regex from https://stackoverflow.com/questions/8829765/regular-expression-for-dollar-amount-in-javascript
                .test('payment amount is a penny or more', 'Invalid payment amount.', paymentAmount => parseFloat(paymentAmount.replace(/[$,]/g, '')) >= 0.01)
        })
    }

    setPayMultipleAccountsButtonClicked() {
        this.setState({ ...this.state, payMultipleAccountsButtonClicked: true })
    }
    clearPayMultipleAccountsButtonClicked() {
        this.setState({ ...this.state, payMultipleAccountsButtonClicked: false })
    }

    addBankAccountButtonClicked(setFieldValue) {
        this.props.setBankFormOpen()
        setFieldValue('bankFormOpen', true)
    }
    addBankCancelButtonClicked(setFieldValue) {
        this.props.clearBankFormOpen()
        setFieldValue('bankFormOpen', false)
    }

    transformValues(values) {
        return {
            ...values,
            srpAccountNumber: values.srpAccountNumber.replace(/[- ]/g, ''),
            bankAccountIdSelected: values.bankAccountIdSelected,
            bankFirstName: values.bankFirstName.trim(),
            bankLastName: values.bankLastName.trim(),
            bankPhoneNumber: values.bankPhoneNumber.replace(/[() -]/g, ''),
            bankEmailAddress: values.bankEmailAddress.trim(),
            bankRoutingNumber: values.bankRoutingNumber.trim(),
            bankAccountNumberLeadingZeroesAreSignificant: values.bankAccountNumberLeadingZeroesAreSignificant.trim(),
            bankNickname: values.bankNickname.trim(),
            paymentAmount: parseFloat(values.paymentAmount.trim().replace(/[$,]/g, ''))
        }
    }

    render() {
        let bankAccountIdSelected = this.props.selectedBankId
        if (bankAccountIdSelected === 0 && this.props.bankAccountList.length > 0) {
            const defaultBankAccount = this.props.bankAccountList.find((bankAccount) => bankAccount.isDefault === true)
            bankAccountIdSelected = (defaultBankAccount !== undefined) ?
                defaultBankAccount.bankId :
                this.props.bankAccountList[0].bankId
        }

        return (
            <Formik
                initialValues={{
                    srpAccountNumber: this.props.paymentList.length > 0 ? this.props.paymentList[0].srpAccountNumber : '',
                    bankFormOpen: this.state.initialBankFormOpen,
                    bankAccountIdSelected: bankAccountIdSelected,
                    bankFirstName: this.props.bankFirstName,
                    bankLastName: this.props.bankLastName,
                    bankPhoneNumber: this.props.bankPhoneNumber,
                    bankEmailAddress: this.props.bankEmailAddress,
                    bankRoutingNumber: this.props.bankRoutingNumber,
                    bankAccountNumberLeadingZeroesAreSignificant: this.props.bankAccountNumberLeadingZeroesAreSignificant,
                    bankNickname: this.props.bankNickname,
                    paymentAmount: this.props.paymentList.length > 0 ? String(this.props.paymentList[0].paymentAmount) : ''
                }}
                validationSchema={this.validationSchema}
                onSubmit={async (paymentAndBankValues, goodies) => {
                    const _paymentAndBankValues = this.transformValues(paymentAndBankValues)
                    this.props.storeBankInfoAndPayment(_paymentAndBankValues)
                    if (this.state.payMultipleAccountsButtonClicked)
                        this.props.switchToMultiplePaymentForm()
                    else {
                        if (_paymentAndBankValues.bankFormOpen) {
                            const bankAdded = await this.props.addBank(goodies, this.validationSchema, _paymentAndBankValues)
                            if (bankAdded === false)
                                return
                        }
                        this.props.validatePayment(goodies, this.validationSchema, _paymentAndBankValues)
                    }
                }}>
                {formikProps => (<SinglePaymentForm {...formikProps} {...this.props} {...this.state}
                    setPayMultipleAccountsButtonClicked={this.setPayMultipleAccountsButtonClicked}
                    clearPayMultipleAccountsButtonClicked={this.clearPayMultipleAccountsButtonClicked}
                    addBankAccountButtonClicked={this.addBankAccountButtonClicked}
                    addBankCancelButtonClicked={this.addBankCancelButtonClicked}
                />)}
            </Formik>
        )
    }
}

SinglePaymentFormik.propTypes = {
    paymentList: PropTypes.arrayOf(PropTypes.shape({
        srpAccountNumber: PropTypes.string.isRequired,
        paymentAmount: PropTypes.number.isRequired,
    })),
    bankAccountList: PropTypes.arrayOf(PropTypes.shape({
        bankId: PropTypes.number.isRequired,
        bankName: PropTypes.string.isRequired,
        nickName: PropTypes.string.isRequired,
        accountNumber: PropTypes.string.isRequired
    })).isRequired,
    selectedBankId: PropTypes.number.isRequired,
    bankFirstName: PropTypes.string.isRequired,
    bankLastName: PropTypes.string.isRequired,
    bankPhoneNumber: PropTypes.string.isRequired,
    bankEmailAddress: PropTypes.string.isRequired,
    bankRoutingNumber: PropTypes.string.isRequired,
    bankAccountNumberLeadingZeroesAreSignificant: PropTypes.string.isRequired,
    bankNickname: PropTypes.string.isRequired,

    formDataChanged: PropTypes.func.isRequired,

    addBank: PropTypes.func.isRequired,
    validatePayment: PropTypes.func.isRequired,
    switchToMultiplePaymentForm: PropTypes.func.isRequired,
    storeBankInfoAndPayment: PropTypes.func.isRequired,

    t:  PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired,

    bankFormOpen: PropTypes.bool.isRequired,
    setBankFormOpen: PropTypes.func.isRequired,
    clearBankFormOpen: PropTypes.func.isRequired
}

export default SinglePaymentFormik