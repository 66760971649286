import * as Yup from 'yup'
import validate from './validate'
import i18n from '../i18n'

const t = i18n.t

let firstNameRequired = Yup.string().required('First name is required.').
    test('not all spaces', 'First name is required.', firstName => typeof firstName === "string" && firstName.trim().length > 0)

let lastNameRequired = Yup.string().required('Last name is required.').
    test('not all spaces', 'Last name is required.', lastName => typeof lastName === "string" && lastName.trim().length > 0)

let phoneNumberRequired = Yup.string().required(t("contactInfoAndAlert::Phone number is required.")).
    test('not all spaces', t("contactInfoAndAlert::Phone number is required."), phoneNumber => typeof phoneNumber === "string" && phoneNumber.trim().length > 0).
    test('must have 10 digits', t("contactInfoAndAlert::Phone number must have 10 digits."), phoneNumber => validate.phoneNumber(phoneNumber))

let emailAddressRequired = Yup.string().required(t("contactInfoAndAlert::Email address is required.")).test('not all spaces', t("contactInfoAndAlert::Email address is required."), emailAddress => typeof emailAddress === "string" && emailAddress.trim().length > 0).
    email(t("contactInfoAndAlert::Invalid email address."))

let srpAccountNumberEmptyOk = Yup.string().test('valid length', 'SRP account number is required', srpAccountNumber => validate.accountNumberEmptyOk(srpAccountNumber))

let srpAccountNumber = Yup.string().
    test('not all spaces', 'SRP account number is required.', srpAccountNumber => typeof srpAccountNumber === "string" && srpAccountNumber.trim().length > 0).
    test('nnn-nnn-nnn format', 'Invalid SRP account number.', srpAccountNumber => typeof srpAccountNumber === "string" && /^\d{9}$/.test(srpAccountNumber.replace(/[- ]/g, '')))

let srpAccountNumberRequired = Yup.string().required('SRP account number is required').
    test('not all spaces', 'SRP account number is required.', srpAccountNumber => typeof srpAccountNumber === "string" && srpAccountNumber.trim().length > 0).
    test('nnn-nnn-nnn format', 'Invalid SRP account number.', srpAccountNumber => typeof srpAccountNumber === "string" && /^\d{9}$/.test(srpAccountNumber.replace(/[- ]/g, '')))

let bankRoutingNumberRequired = Yup.string().required('Routing number is required.').
    test('not all spaces', 'Routing number is required.', bankRoutingNumber => typeof bankRoutingNumber === "string" && bankRoutingNumber.trim().length > 0).
    test('must have 9 digits', 'This is a 9 digit number located at the bottom left corner of your check.', bankRoutingNumber => typeof bankRoutingNumber === "string" && /^\d{9}$/.test(bankRoutingNumber.trim()))

let bankAccountNumberRequired = Yup.string().required('Bank account number is required.').
    test('not all spaces', 'Bank account number is required.', bankAccountNumber => typeof bankAccountNumber === "string" && bankAccountNumber.trim().length > 0).
    test('17 digits max', 'Your bank account number must be 1-17 digits long.', bankAccountNumber => typeof bankAccountNumber === "string" && /^\d{1,17}$/.test(bankAccountNumber.trim()))

let paymentAmountEmptyOk = Yup.string().test('payment amount is valid, empty is ok', 'Invalid payment amount', paymentAmount => validate.paymentAmountEmptyOk(paymentAmount))

let paymentAmount = Yup.string().test('payment amount is valid', 'Invalid payment amount', paymentAmount => validate.paymentAmountEmptyOk(paymentAmount))

let paymentAmountRequired = paymentAmount.required('A payment amount is required')

let bankNickNameMaxCharacter = Yup.string().test('35 characters max', 'Your bank account nickname cannot have more than 35 characters.', nickname => nickname === undefined || (typeof nickname === "string" && nickname.trim().length <= 35))

let nickNameMaxCharacter = Yup.string().test('20 characters max', t("contactInfoAndAlert::Your nickname cannot have more than 20 characters."), nickname => nickname === undefined || (typeof nickname === "string" && nickname.trim().length <= 20))

const validateFormik = {
    firstNameRequired: firstNameRequired,
    lastNameRequired: lastNameRequired,
    phoneNumberRequired: phoneNumberRequired,
    emailAddressRequired: emailAddressRequired,
    srpAccountNumberEmptyOk: srpAccountNumberEmptyOk,
    srpAccountNumber: srpAccountNumber,
    srpAccountNumberRequired: srpAccountNumberRequired,
    bankRoutingNumberRequired: bankRoutingNumberRequired,
    bankAccountNumberRequired: bankAccountNumberRequired,
    paymentAmountEmptyOk: paymentAmountEmptyOk,
    paymentAmount: paymentAmount,
    paymentAmountRequired: paymentAmountRequired,
    bankNickNameMaxCharacter: bankNickNameMaxCharacter,
    nickNameMaxCharacter: nickNameMaxCharacter
}

export default validateFormik