import { createStore, compose, applyMiddleware } from 'redux'
import { apiMiddleware } from 'redux-api-middleware'
import { createRouterMiddleware } from '@lagunovsky/redux-react-router'
import createDebounce from 'redux-debounced'
import reduxImmutableStateInvariant from 'redux-immutable-state-invariant'
import thunk from 'redux-thunk'

import rootReducer from '../reducers'
import { authRedirectMiddleware } from '../srp_modules/middleware/auth-redirect-middleware'
import { billAccountMiddleware } from '../srp_modules/middleware/bill-account-middleware'
import { myAccountHeaderMiddleware } from '../srp_modules/middleware/my-account-header-middleware'
import { xsrfMiddleware } from '../srp_modules/middleware/xsrf-middleware'
import { apiDebounceOptionsMiddleware } from '../srp_modules/middleware/api-debounce-options-middleware'


function configureStoreProd(initialState, history) {
  const middlewares = [
    createRouterMiddleware(history),
    // Add other middleware on this line...
    xsrfMiddleware,
    myAccountHeaderMiddleware,
    apiDebounceOptionsMiddleware,
    createDebounce(),
    billAccountMiddleware(apiMiddleware),
    authRedirectMiddleware,

    // thunk middleware can also accept an extra argument to be passed to each thunk action
    // https://github.com/gaearon/redux-thunk#injecting-a-custom-argument
    thunk,
  ];

  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose; // add support for Redux dev tools
  return createStore(rootReducer(history), initialState, composeEnhancers(
    applyMiddleware(...middlewares)
    )
  );
}

function configureStoreDev(initialState, history) {
  const middlewares = [
    createRouterMiddleware(history),
    // Add other middleware on this line...
    xsrfMiddleware,
    myAccountHeaderMiddleware,
    apiDebounceOptionsMiddleware,
    createDebounce(),
    billAccountMiddleware(apiMiddleware),
    authRedirectMiddleware,

    // Redux middleware that spits an error on you when you try to mutate your state either inside a dispatch or between dispatches.
    reduxImmutableStateInvariant(),

    // thunk middleware can also accept an extra argument to be passed to each thunk action
    // https://github.com/gaearon/redux-thunk#injecting-a-custom-argument
    thunk,
  ];

  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose; // add support for Redux dev tools
  const store = createStore(rootReducer(history), initialState, composeEnhancers(
    applyMiddleware(...middlewares)
    )
  );

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('../reducers', () => {
      const nextReducer = require('../reducers').default; // eslint-disable-line global-require
      store.replaceReducer(nextReducer);
    });
  }

  return store;
}

const configureStore = process.env.NODE_ENV === 'production' ? configureStoreProd : configureStoreDev;

export default configureStore;