import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { NumericFormat } from 'react-number-format'

import { DateTime } from 'luxon'
import Divider from '@mui/material/Divider'

import { Trans } from 'react-i18next'

const MPowerPendingConnectBillCard = ({ mPowerAcctDetails, t }) => {
    const _lastReadDate = DateTime.fromISO(mPowerAcctDetails.lastEstimatedReadDate)
    const isMinDate = _lastReadDate.year===1 && _lastReadDate.month===1 && _lastReadDate.day===1
    const lastReadDate = isMinDate ? "- -" : _lastReadDate.toFormat("h:mm a, MMM d")
    const balanceRemaining = <NumericFormat value={mPowerAcctDetails.balanceRemaining} valueIsNumericString={true} prefix={'$'} thousandSeparator={true} fixedDecimalScale={true} decimalScale={2} displayType={'text'} />

    return (
        <div className="srp-card">
            <div className="srp-card-header">
                {t("My M-Power")}
            </div>

            <div className="srp-card-body">
                <div className={"srp-card-summary d-flex justify-content-around srp-gray"}>
                    <div className="d-flex flex-column align-items-center text-white">
                        <div className="h4">{t("Disconnected")}</div>
                        <span>{balanceRemaining} {t("meter_balance_as_of", {lastReadDate})}</span>
                    </div>
                </div>

                <div className="srp-card-details">
                    <div className="d-none d-lg-block mb-2">
                        <div className="d-flex flex-wrap justify-content-end">
                            <button className="btn srp-btn btn-lightblue mb-2" style={{ fontSize: '95%' }} disabled={true}>{t("Purchase power")}</button>
                            <button
                                className="btn srp-btn btn-green ml-2 mb-2"
                                style={{ fontSize: '95%' }}
                                disabled={true}>
                                {t("Pending reconnect")}
                            </button>
                        </div>
                    </div>

                    <div style={{ fontSize: '14px' }}>
                        <div className="srp-alert-warn">
                            <Trans i18nKey="pending_reconnect_fire_risk_notice" t={t}>
                                <b>We are experiencing a short delay in reconnecting your meter.</b>
                                Please be aware of the risk of fire if any appliances or other electric devices,
                                such as an electric stove, are turned on at the time power is delivered.
                                Please ensure all of the appliances and electric devices at the premises are turned off.
                            </Trans>
                        </div>
                    </div>
                </div>

                <div className="d-block d-lg-none">
                    <Divider />
                    <div className="srp-card-details">
                        <div className="d-flex flex-wrap justify-content-end">
                            <button to="/myaccount/payment" disabled={true} className="btn srp-btn btn-lightblue my-1">{t("Purchase power")}</button>
                            <button
                                className="btn srp-btn btn-green ml-2 my-1"
                                disabled={true}>
                                {t("Pending reconnect")}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

MPowerPendingConnectBillCard.propTypes = {
    mPowerAcctDetails: PropTypes.shape({
        balanceRemaining: PropTypes.number.isRequired,
        lastEstimatedReadDate: PropTypes.string.isRequired,
    }).isRequired,
    t: PropTypes.func.isRequired,
}

export default MPowerPendingConnectBillCard