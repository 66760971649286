import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import CircularProgress from '@mui/material/CircularProgress'
import Button from '@mui/material/Button'
import Icon from '@mui/material/Icon'

import BudgetBillingForm from './budget-billing-form'
import BudgetBillingConfirmation from './budget-billing-confirmation'
import BudgetBillingError from './budget-billing-error'
import BudgetBillingIneligible from './budget-billing-ineligible'
import AccountInfoBarContainer from '../../myaccount_header/info_bar/account-info-bar-container'
import CallAnytime from '../../myaccount_header/call-anytime'

import { getContacts } from '../../../actions/auth/contact/contact-actions'
import { getServiceAddress, getCountyAssessorInfo } from '../../../actions/auth/bill_account/bill-account-actions'
import {
    setSquareFootage,
    setElectricType,
    selectPool,
    selectEvapCooler,
    submitBudgetBilling
} from '../../../actions/auth/budget_billing/budget-billing-actions'
import { getAccountHistory } from '../../../actions/auth/payment/account-history-actions'
import { getRateMetaData } from '../../../actions/auth/usage/rate-meta-data-actions'
import { budgetBillingConstants } from '../../../constants/budget-billing-constants'
import { rateMetaDataConstants } from '../../../constants/rate-meta-data-constants'
import { withRouter } from '../../../srp_modules/with-router'
import * as loadingStatus from '../../../constants/loading-status-constants'

let progressCirclestyle = {
    marginTop: "10%",
    marginBottom: "20%"
}

class BudgetBillingContainer extends React.Component {
    constructor(props) {
        super(props)

        this.createAndSubmitBudgetBillingForm = this.createAndSubmitBudgetBillingForm.bind(this)
        this.refreshBudgetBilling = this.refreshBudgetBilling.bind(this)
    }

    componentDidMount() {
        if (this.props.selectedBillAccount === 0)
            return

        if (this.props.rateMetaDataStatus === loadingStatus.LOADING_STATUS_INIT
            || this.props.rateMetaDataStatus === loadingStatus.LOADING_STATUS_FAILURE) {
            this.props.getRateMetaDataInfo(this.props.selectedBillAccount)
        }
        if (this.props.contactsStatus === loadingStatus.LOADING_STATUS_INIT
            || this.props.contactsStatus === loadingStatus.LOADING_STATUS_FAILURE) {
            this.props.getContactInfo(this.props.selectedBillAccount)
        }
        if (this.props.serviceAddressStatus === loadingStatus.LOADING_STATUS_INIT
            || this.props.serviceAddressStatus === loadingStatus.LOADING_STATUS_FAILURE) {
            this.props.getServiceAddressInfo(this.props.selectedBillAccount)
        }
        if (this.props.countyAssessorStatus === loadingStatus.LOADING_STATUS_INIT
            || this.props.countyAssessorStatus === loadingStatus.LOADING_STATUS_FAILURE) {
            this.props.getCountyAssessorInfo(this.props.selectedBillAccount)
        }

        if (this.props.accountHistoryStatus === loadingStatus.LOADING_STATUS_INIT
            || this.props.accountHistoryStatus === loadingStatus.LOADING_STATUS_FAILURE) {
            this.props.getAccountHistoryInfo(this.props.selectedBillAccount)
        }

    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.selectedBillAccount === nextProps.selectedBillAccount || nextProps.selectedBillAccount === 0)
            return

        if (nextProps.rateMetaDataStatus !== loadingStatus.LOADING_STATUS_IN_PROGRESS) {
            this.props.getRateMetaDataInfo(nextProps.selectedBillAccount)
        }
        if (nextProps.contactsStatus !== loadingStatus.LOADING_STATUS_IN_PROGRESS) {
            this.props.getContactInfo(nextProps.selectedBillAccount)
        }
        if (nextProps.serviceAddressStatus !== loadingStatus.LOADING_STATUS_IN_PROGRESS) {
            this.props.getServiceAddressInfo(nextProps.selectedBillAccount)
        }
        if (nextProps.countyAssessorStatus !== loadingStatus.LOADING_STATUS_IN_PROGRESS) {
            this.props.getCountyAssessorInfo(nextProps.selectedBillAccount)
        }
        if (nextProps.accountHistoryStatus !== loadingStatus.LOADING_STATUS_IN_PROGRESS) {
            this.props.getAccountHistoryInfo(nextProps.selectedBillAccount)
        }
    }

    createAndSubmitBudgetBillingForm(electricType, squareFoot, hasEvapCooler, hasPool, emailAddressIfCustDoesntHaveOne) {
        let validElectricType = 2
        switch (electricType) {
            case "Y":
            case budgetBillingConstants.ELECTRIC_TYPE.ALL_ELECTRIC:
                validElectricType = 0
                break
            case "N":
            case budgetBillingConstants.ELECTRIC_TYPE.DUAL:
                validElectricType = 1
                break
            case budgetBillingConstants.ELECTRIC_TYPE.UNKNOWN:
            default:
                validElectricType = 2
        }

        let budgetBillingForm = {
            isResidential: !this.props.selectedBillAccountDetails.isCommercial,
            accountNumber: this.props.selectedBillAccount,
            customerInfoModel: {
                firstName: this.props.rateMetaData.firstName,
                middleName: this.props.rateMetaData.middleName,
                lastName: this.props.rateMetaData.lastName,
                emailAddress: this.props.primaryEmail.length > 0
                    ? this.props.primaryEmail
                    : emailAddressIfCustDoesntHaveOne,
                phoneNumber: this.props.primaryPhone
            },
            businessInfoModel: {
                businessName: this.props.rateMetaData.displayName,
                emailAddress: this.props.primaryEmail.length > 0
                    ? this.props.primaryEmail
                    : emailAddressIfCustDoesntHaveOne,
                phoneNumber: this.props.primaryPhone
            },
            serviceAddress: {
                streetAddress: this.props.serviceAddress.streetAddress,
                state: this.props.serviceAddress.state,
                city: this.props.serviceAddress.cityName,
                zipCode: this.props.serviceAddress.zipCode,
                addressType: this.props.serviceAddress.serviceAddressTypeCode
            },
            language: "en", //language does not matter for budget billing
            electricType: validElectricType,
            squareFootage: squareFoot,
            hasEvaporatingCooling: hasEvapCooler,
            hasSwimmingPool: hasPool
        }

        this.props.submitBudgetBillingForm(budgetBillingForm)
    }

    refreshBudgetBilling() {
        if (this.props.rateMetaDataStatus === loadingStatus.LOADING_STATUS_FAILURE) {
            this.props.getRateMetaDataInfo(this.props.selectedBillAccount)
        }
        if (this.props.contactsStatus === loadingStatus.LOADING_STATUS_FAILURE) {
            this.props.getContactInfo(this.props.selectedBillAccount)
        }
        if (this.props.serviceAddressStatus === loadingStatus.LOADING_STATUS_FAILURE) {
            this.props.getServiceAddressInfo(this.props.selectedBillAccount)
        }
        if (this.props.countyAssessorStatus === loadingStatus.LOADING_STATUS_FAILURE) {
            this.props.getCountyAssessorInfo(this.props.selectedBillAccount)
        }
    }

    render() {
        const t = this.props.t
        const i18n = this.props.i18n
    
        let renderedCard = null
        if (this.props.submitBudgetBillingStatus === loadingStatus.LOADING_STATUS_INIT || this.props.submitBudgetBillingStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS) {
            if (this.props.rateMetaDataStatus === loadingStatus.LOADING_STATUS_INIT || this.props.rateMetaDataStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS
                || this.props.contactsStatus === loadingStatus.LOADING_STATUS_INIT || this.props.contactsStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS
                || this.props.serviceAddressStatus === loadingStatus.LOADING_STATUS_INIT || this.props.serviceAddressStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS
                || this.props.countyAssessorStatus === loadingStatus.LOADING_STATUS_INIT || this.props.countyAssessorStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS
                || this.props.accountHistoryStatus === loadingStatus.LOADING_STATUS_INIT || this.props.accountHistoryStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS
                || this.props.rateMetaDataStatus === loadingStatus.LOADING_STATUS_INIT || this.props.rateMetaDataStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS) {
                renderedCard = <div className="d-flex justify-content-center"><CircularProgress size={80} thickness={5} style={progressCirclestyle} /></div>
            }
            else if (this.props.rateMetaDataStatus === loadingStatus.LOADING_STATUS_SUCCESS && this.props.contactsStatus === loadingStatus.LOADING_STATUS_SUCCESS
                && this.props.serviceAddressStatus === loadingStatus.LOADING_STATUS_SUCCESS && this.props.countyAssessorStatus === loadingStatus.LOADING_STATUS_SUCCESS) {
                if (this.props.selectedBillAccountDetails.isCommercial
                    || this.props.selectedBillAccountDetails.isPrePay
                    || this.props.rateMetaData.summaryBilling !== rateMetaDataConstants.SUMMARY_BILLING_NONE) {
                    renderedCard = (<BudgetBillingIneligible isCommercial={this.props.selectedBillAccountDetails.isCommercial} t={t} i18n={i18n} />)
                }
                else {
                    renderedCard =
                        (<BudgetBillingForm
                            isCommercial={this.props.selectedBillAccountDetails.isCommercial}
                            customerName={this.props.rateMetaData.displayName}
                            primaryEmail={this.props.primaryEmail}
                            serviceAddress={this.props.serviceAddress}
                            countyAssessorInfo={this.props.countyAssessorInfo}
                            budgetBillingEstimate={this.props.budgetBillingEstimate}
                            squareFootage={this.props.squareFootage}
                            getSquareFootage={this.props.selectSquareFootage}
                            electricType={this.props.electricType}
                            geElectricType={this.props.selectElectricType}
                            selectedPool={this.props.hasPool}
                            selectPoolOnClick={this.props.selectPoolOnClick}
                            selectedEvapCooler={this.props.hasEvapCooler}
                            selectEvapCoolerOnClick={this.props.selectEvapCoolerOnClick}
                            createAndSubmitBudgetBillingForm={this.createAndSubmitBudgetBillingForm}
                            t={t} i18n={i18n}
                            submitBudgetBillingStatus={this.props.submitBudgetBillingStatus} />)
                }
            }
            else {
                renderedCard = <BudgetBillingError refreshData={this.refreshBudgetBilling()} t={t} />
            }
            return (
                <div>
                    <div className="d-block d-md-none">
                        <Button
                            variant="contained"
                            fullWidth
                            className="justify-content-start nav-button-responsive"
                            onClick={() => this.props.router.navigate(-1)}
                            disableRipple>
                            <Icon className="material-icons mr-2" style={{ color: 'rgb(33,108,224' }}>keyboard_backspace</Icon>
                            {t("Previous page")}
                        </Button>
                    </div>

                    <div className="container py-3">
                        <CallAnytime />
                        <h3 className="d-lg-block d-none mt-3 mb-4 pb-4">{t("Sign up for Budget Billing")}</h3>
                        <h3 className="d-lg-none d-block mt-3 mb-3">{t("Sign up for Budget Billing")}</h3>

                        <AccountInfoBarContainer disableSelector={false} />

                        <div className="d-none d-md-block">
                            <h5 className="d-flex justify-content-start align-items-center">
                                <button className="displayAsLink" onClick={() => this.props.router.navigate(-1)}>{t("Previous page")} </button>
                                <i className="material-icons" style={{ color: 'rgba(72, 72, 74, 0.376)' }} >keyboard_arrow_right</i>
                                <span style={{ color: '#707070' }}>{t("Budget Billing signup")}</span>
                            </h5>
                        </div>

                        {renderedCard}

                    </div>
                </div>
            )
        }
        else {
            return (<BudgetBillingConfirmation
                submitBudgetBillingStatus={this.props.submitBudgetBillingStatus}
                primaryEmail={this.props.primaryEmail}
                t={t} i18n={i18n}
                isCommercial={this.props.selectedBillAccountDetails.isCommercial} />)
        }
    }
}

const mapStateToProps = state => {
    return {
        ...state.login,
        ...state.accountInfo.billAccount,
        ...state.accountInfo.contact,
        ...state.budgetBilling,
        ...state.accountHistory,
        ...state.rateMetaData
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getContactInfo: (billAccount) => {
            dispatch(getContacts(billAccount))
        },
        getRateMetaDataInfo: (billAccount) => {
            dispatch(getRateMetaData(billAccount))
        },
        getServiceAddressInfo: (billAccount) => {
            dispatch(getServiceAddress(billAccount))
        },
        getCountyAssessorInfo: (billAccount) => {
            dispatch(getCountyAssessorInfo(billAccount))
        },
        getAccountHistoryInfo: (billAccount) => {
            dispatch(getAccountHistory(billAccount))
        },
        selectSquareFootage: (squareFootage) => {
            dispatch(setSquareFootage(squareFootage))
        },
        selectElectricType: (electricType) => {
            dispatch(setElectricType(electricType))
        },
        selectPoolOnClick: (hasPool) => {
            dispatch(selectPool(hasPool))
        },
        selectEvapCoolerOnClick: (hasEvapCooler) => {
            dispatch(selectEvapCooler(hasEvapCooler))
        },
        submitBudgetBillingForm: (form) => {
            dispatch(submitBudgetBilling(form))
        }
    }
}

BudgetBillingContainer.propTypes = {
    router: PropTypes.shape({
        navigate: PropTypes.func
    }),
    selectedBillAccount: PropTypes.number,
    selectedBillAccountDetails: PropTypes.object,

    primaryEmail: PropTypes.string,
    primaryPhone: PropTypes.string,
    contactsStatus: PropTypes.string,
    getContactInfo: PropTypes.func,

    serviceAddress: PropTypes.object,
    serviceAddressStatus: PropTypes.string,
    getServiceAddressInfo: PropTypes.func,

    countyAssessorInfo: PropTypes.object,
    countyAssessorStatus: PropTypes.string,
    getCountyAssessorInfo: PropTypes.func,

    budgetBillingEstimate: PropTypes.number,
    accountHistoryList: PropTypes.array,
    accountHistoryStatus: PropTypes.string,
    getAccountHistoryInfo: PropTypes.func,

    rateMetaData: PropTypes.object,
    rateMetaDataStatus: PropTypes.string,
    getRateMetaDataInfo: PropTypes.func,

    squareFootage: PropTypes.string,
    selectSquareFootage: PropTypes.func,

    electricType: PropTypes.string,
    selectElectricType: PropTypes.func,

    hasPool: PropTypes.bool,
    selectPoolOnClick: PropTypes.func,

    hasEvapCooler: PropTypes.bool,
    selectEvapCoolerOnClick: PropTypes.func,

    submitBudgetBillingStatus: PropTypes.string,
    submitBudgetBillingForm: PropTypes.func,

    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired,
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BudgetBillingContainer))