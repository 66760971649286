import { bankAccountConstants } from '../../../constants/bank-account-constants'
import { RSAA } from 'redux-api-middleware'
import utils from '../../../srp_modules/utils'

export const validateAddBankAccount = () => (dispatch, getState) => {
    dispatch({type: bankAccountConstants.ADD_BANK_ACCOUNT_VALIDATION_REQUEST})

    let validationType = bankAccountConstants.ADD_BANK_ACCOUNT_VALIDATION_SUCCESS
    let isValid = true
    let info = getState().bankAccounts.addBankAccountInfo

    let formError =  (info.firstNameError ||
        info.lastNameError ||
        info.phoneNumberError ||
        info.emailAddressError ||
        info.routingNumberError ||
        info.bankAccountNumberError)

    let valueError = (info.firstName === '' ||
        info.lastName === '' ||
        info.phoneNumber === '' ||
        info.emailAddress === '' ||
        info.routingNumber === '' ||
        info.bankAccountNumber === '')

    if (formError || valueError) {
        isValid = false
        validationType = bankAccountConstants.ADD_BANK_ACCOUNT_VALIDATION_FAILURE
    }

    dispatch({
        type: validationType,
        isValid
    })

    return new Promise(function(resolve){
        resolve(isValid)
    })
}

export const populateAddBankAccount = (firstName, lastName, phoneNumber, emailAddress, routingNumber, bankAccountNumber, nickName) => (dispatch) => {
    dispatch({
        type: bankAccountConstants.POPULATE_ADD_BANK_ACCOUNT,
        addBankInfo: {
            firstName: firstName,
            lastName: lastName,
            phoneNumber: phoneNumber,
            emailAddress: emailAddress,
            routingNumber: routingNumber,
            bankAccountNumber: bankAccountNumber,
            nickName: nickName}
    })

    return new Promise(function(resolve){
        resolve(true)
    })
}

export const getBankAccountList = () => dispatch => {
    return dispatch({
        [RSAA]: {
            endpoint: utils.createUrl('bankaccount/getlist'),
            method: 'GET',
            credentials: 'include',
            types: [
                bankAccountConstants.GET_BANK_ACCOUNT_LIST_REQUEST,
                bankAccountConstants.GET_BANK_ACCOUNT_LIST_SUCCESS,
                bankAccountConstants.GET_BANK_ACCOUNT_LIST_FAILURE
            ]
        }
    })
}

export const changeSelectedBankAccountId = (bankAccountId) => {
    return (dispatch) => {
        dispatch(changeSelectedBankAccountIdSuccess(bankAccountId))
    }
}

export const changeSelectedBankAccountIdSuccess = (bankAccountId) => ({
    type: bankAccountConstants.CHANGE_SELECTED_BANK_ACCOUNT_SUCCESS,
    bankAccountId
})

export const getFirstName = (firstName) => ({
    type: bankAccountConstants.GET_FIRST_NAME,
    firstName
})

export const getLastName = (lastName) => ({
    type: bankAccountConstants.GET_LAST_NAME,
    lastName
})

export const getPhoneNumber = (phoneNumber) => ({
    type: bankAccountConstants.GET_PHONE_NUMBER,
    phoneNumber
})

export const getEmailAddress = (emailAddress) => ({
    type: bankAccountConstants.GET_EMAIL_ADDRESS,
    emailAddress
})

export const getRoutingNumber = (routingNumber) => ({
    type: bankAccountConstants.GET_ROUTING_NUMBER,
    routingNumber
})

export const getBankAccountNumber = (bankAccountNumber) => ({
    type: bankAccountConstants.GET_BANK_ACCOUNT_NUMBER,
    bankAccountNumber
})

export const getNickName = (nickName) => ({
    type: bankAccountConstants.GET_NICKNAME,
    nickName
})

export const addBankAccountInfoChange = (addBankAccountInfo) => ({
    type: bankAccountConstants.ADD_BANK_ACCOUNT_INFO_CHANGE,
    addBankAccountInfo
})

export const addBankAccountFullDetails = () => (dispatch, getState) => {
    let billAccount = getState().accountInfo.billAccount.selectedBillAccount
    let bankAccount = getState().bankAccounts.addBankAccountInfo

    let billAccountList = getState().accountInfo.billAccount.billAccountList
    let allCommercial = billAccountList?.every(b => b.isCommercial) ?? false

    let bankInfo = {
        firstNameOnBankAccount: bankAccount.firstName,
        lastNameOnBankAccount: bankAccount.lastName,
        signerPhoneNumberTenDigits: bankAccount.phoneNumber,
        signerEmailAddress: bankAccount.emailAddress,
        bankRoutingNumber: Number(bankAccount.routingNumber),
        bankAccountNumberLeadingZeroesAreSignificant: bankAccount.bankAccountNumber,
        nicknameForBankAccount: bankAccount.nickName,
        makeDefaultBankAccount: bankAccount.isDefaultAccount,
        canBeUsedWithIntegratedVoiceResponse: bankAccount.isDefaultWithIVR,

        billAccountNumberForWhichBankAccountCanBeUsedAtPayCenterAndPhonePayments: billAccount,
        sendCommercialConfirmationEmail: allCommercial
    }

    let jsonBody = JSON.stringify(bankInfo)

    return dispatch({
        [RSAA] : {
            endpoint: utils.createUrl('bankaccount/addwithdefaults_emailconfirmation'),
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include',
            body: jsonBody,
            types: [
                bankAccountConstants.ADD_BANK_EARLY_WARNING_REQUEST,
                bankAccountConstants.ADD_BANK_EARLY_WARNING_SUCCESS,
                bankAccountConstants.ADD_BANK_EARLY_WARNING_FAILURE
            ]
        },
        meta: {
            debounce: {
                leading: false,
                trailing: false
            }
        }
    })
}


//profile & settings bank info actions
export const showDeleteBankModule = (bankToDelete) => ({
    type: bankAccountConstants.SHOW_DELETE_BANK_MODULE,
    bankToDelete
})

export const closeDeleteBankModule = () => ({
    type: bankAccountConstants.CLOSE_DELETE_BANK_MODULE
})

export const closeDeleteBankErrorModule = () => ({
    type: bankAccountConstants.CLOSE_DELETE_BANK_ERROR_MODULE
})

export const deleteBankAccount = (bankAccountIdToken, allBillAccountsAreCommercial) => dispatch => {
    let parameters = {
        bankAccountIdToken,
        sendCommercialConfirmationEmail: allBillAccountsAreCommercial,
    }

    return dispatch({
        [RSAA]: {
            endpoint: utils.createUrl('bankaccount/deleteAndEmailConfirmation'),
            method: 'POST',
            body: JSON.stringify(parameters),
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include',
            types: [
                bankAccountConstants.DELETE_BANK_ACCOUNT_REQUEST,
                bankAccountConstants.DELETE_BANK_ACCOUNT_SUCCESS,
                bankAccountConstants.DELETE_BANK_ACCOUNT_FAILURE
            ]
        }
    })
}

export const getBankAccountToEdit = (bankAccount, canDelete = true) => ({
    type: bankAccountConstants.GET_BANK_TO_EDIT,
    bankAccount, canDelete
})

export const storeUpdatedBankAccount = (updatedBankAccount) => ({
    type: bankAccountConstants.STORE_UPDATED_BANK_ACCOUNT,
    updatedBankAccount
})

export const updateBankAccount = (bankAccountInfo, billAccount, allBillAccountsAreCommercial) => (dispatch) => {
    let bankInfo = {
        bankAccountIdToken: bankAccountInfo.bankIdToken,
        firstNameOnBankAccount: bankAccountInfo.firstName,
        lastNameOnBankAccount: bankAccountInfo.lastName,
        signerPhoneNumberTenDigits: bankAccountInfo.phoneNumber,
        signerEmailAddress: bankAccountInfo.emailAddress,
        nicknameForBankAccount: bankAccountInfo.nickName,
        bankRoutingNumber: Number(bankAccountInfo.routingNumber),
        bankAccountNumberLeadingZeroesAreSignificant: bankAccountInfo.bankAccountNumber,
        makeDefaultBankAccount: bankAccountInfo.isDefaultAccount,
        canBeUsedWithIntegratedVoiceResponse: bankAccountInfo.isDefaultWithIVR,
        billAccountNumberForWhichBankAccountCanBeUsedAtPayCenterAndPhonePayments: billAccount,
        sendCommercialConfirmationEmail: allBillAccountsAreCommercial
    }

    let jsonBody = JSON.stringify(bankInfo)

    return dispatch({
        [RSAA] : {
            endpoint: utils.createUrl('bankaccount/updatewithdefaults_emailconfirmation'),
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include',
            body: jsonBody,
            types: [
                bankAccountConstants.EDIT_BANK_ACCOUNT_REQUEST,
                bankAccountConstants.EDIT_BANK_ACCOUNT_SUCCESS,
                bankAccountConstants.EDIT_BANK_ACCOUNT_FAILURE
            ]
        }
    })
}
