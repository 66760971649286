import React from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import Collapse from '@mui/material/Collapse'
import IconButton from '@mui/material/IconButton'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import SummaryBillingSubAccount from './summary-billing-subaccount'

class SummaryBillingSubAccounts extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            showSubAccounts: false
        }
    }

    render() {
        return (
            <React.Fragment>
                <TableRow>
                    <TableCell colSpan="9">
                        <Collapse
                            in={this.state.showSubAccounts}
                            collapsedSize="49px"
                            classes={{
                                root: "row"
                            }}
                        >
                            {!this.state.showSubAccounts &&
                                <div className="summary-account-expand-multipayment">
                                    <IconButton
                                        color="primary"
                                        onClick={() => this.setState({ ...this.state, showSubAccounts: !this.state.showSubAccounts})}
                                        size="large">
                                            <i className="material-icons">keyboard_arrow_down</i>
                                    </IconButton>
                                    <span className="align-self-center">{this.props.t("Show sub accounts")}&nbsp;({this.props.subAccounts.length})</span>
                                </div>
                            }
                            
                            <Table style={{ minWidth: '1070px', fontSize: '15px', tableLayout: 'fixed' }}>
                                <colgroup>
                                    <col width="7%" />
                                    <col width="32%" />
                                    <col width="15%" />
                                    <col width="8%" />
                                    <col width="4%" />
                                    <col width="14%" />
                                    <col width="3%" />
                                    <col width="14%" />
                                    <col width="3%" />
                                </colgroup>
                                <TableBody>
                                    {this.props.subAccounts.map((subAccount, index) => {
                                        return (
                                            <SummaryBillingSubAccount 
                                                subAccount={subAccount} 
                                                viewBillClick={this.props.viewBillClick}
                                                key={`${subAccount.billAccount}_${index}`} 
                                            />
                                        )
                                    })}
                                </TableBody>
                            </Table>
                            
                            {this.state.showSubAccounts &&
                                <div className="summary-account-expand-multipayment">
                                    <IconButton
                                        color="primary"
                                        onClick={() => this.setState({ ...this.state, showSubAccounts: !this.state.showSubAccounts})}
                                        size="large">
                                            <i className="material-icons">keyboard_arrow_up</i>
                                    </IconButton>
                                    <span className="align-self-center">{this.props.t("Hide sub accounts")}&nbsp;({this.props.subAccounts.length})</span>
                                </div>
                            }
                        </Collapse>
                    </TableCell>
                </TableRow>
            </React.Fragment>
        )
    }
}

SummaryBillingSubAccounts.propTypes = {
    subAccounts: PropTypes.arrayOf(PropTypes.object),
    viewBillClick: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    i18n: PropTypes.object.isRequired
}

export default withTranslation("multiPayment")(SummaryBillingSubAccounts)