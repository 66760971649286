import React from 'react'
import { pricePlanConstants } from '../../constants/price-plan-constants'


const changePricePlanModule = {
    mapRateSavingsMessageForResidential,
    mapRateSavingsMessageForCommercial,
    getPricePlanTitle
}

function mapRateSavingsMessageForResidential(selectedPricePlan, t) {
    let savingsMessage = null
    let boldVerbiage = ""
    let verbiage = ""
    switch (selectedPricePlan) {
        case pricePlanConstants.PRICE_PLAN_RES_SERVER_MAP.EZ3:
            boldVerbiage = t("use_less_energy_3_to_6")
            verbiage = t("You’ll get lower prices all other hours.")
            savingsMessage = createSavingsMessage(boldVerbiage, verbiage)
            break
        case pricePlanConstants.PRICE_PLAN_RES_SERVER_MAP.EZ3_4_7:
            boldVerbiage = t("use_less_energy_4_to_7")
            verbiage = t("You’ll get lower prices all other hours.")
            savingsMessage = createSavingsMessage(boldVerbiage, verbiage)
            break
        case pricePlanConstants.PRICE_PLAN_RES_SERVER_MAP.TOU:
            boldVerbiage = t("use_less_energy_during_peak_hrs")
            verbiage = t("peak_hours_are_5_to_9")
            savingsMessage = createSavingsMessage(boldVerbiage, verbiage)
            break
        case pricePlanConstants.PRICE_PLAN_RES_SERVER_MAP.EV:
            savingsMessage = (<div>
                <span className="font-weight-bold">{`${t("use_less_energy_during_peak_hrs")} `}</span>
                <span>{t("peak_hours_are_5_to_9")}</span>
                <p className="pt-3">{t("save_more_charging_ev_11pm_to_5am")}</p>
            </div>)
            break
        case pricePlanConstants.PRICE_PLAN_RES_SERVER_MAP.BASIC:
            savingsMessage = <p>{t("price_of_energy_is_same")}</p>
            break
        case pricePlanConstants.PRICE_PLAN_RES_SERVER_MAP.TOU_DEMAND:
            boldVerbiage = t("spread_use_of_major_appliances")
            verbiage = t("peak_hours_are_5_to_9")
            savingsMessage = createSavingsMessage(boldVerbiage, verbiage)
            break
        case pricePlanConstants.PRICE_PLAN_RES_SERVER_MAP.TOU_EXPORT:
        case pricePlanConstants.PRICE_PLAN_RES_SERVER_MAP.EV_EXPORT:
            savingsMessage = (<div>
                <div className="font-weight-bold">{t("when_using_energy_supplied_by_srp")}</div>
                <p className="pt-3">{t("youll_only_pay_for_energy_you_use")}</p>
            </div>)
            break
        case pricePlanConstants.PRICE_PLAN_RES_SERVER_MAP.AVERAGE_DEMAND:
            savingsMessage = (<div>
                {t("This net-metering price plan works best for customers whose")}
                <span className="font-weight-bold">{` ${t("demand levels vary significantly")} `}</span>
                {t("throughout the month")}.
                <p className="pt-3">{t("onpeak_demand_averaged_over_bill_month")}</p>
            </div>)
            break
        case pricePlanConstants.PRICE_PLAN_RES_SERVER_MAP.GENERATION:
            savingsMessage = (<div>
                {t("best_for_customers_who_manage_onpeak_demand")}
                <span className="font-weight-bold">{` ${t("the per kW charge is tiered")}.`}</span>
                <p className="pt-3">{t("bill_calculated_on_max_demand")}</p>
            </div>)
            break
        default:
            break
    }

    return savingsMessage
}

function mapRateSavingsMessageForCommercial(selectedPricePlan, currentPlanIsTouOrSolar, t){
let savingsMessage = null
    switch (selectedPricePlan) {
        case pricePlanConstants.PRICE_PLAN_COMM_SERVER_MAP.TOU:
            currentPlanIsTouOrSolar
                ? savingsMessage = null
                : savingsMessage = (<p>{t("if_plan_doesnt_create_savings")}</p>)
            break
        case pricePlanConstants.PRICE_PLAN_COMM_SERVER_MAP.GENERAL:
            savingsMessage = null
            break
        default:
            break
    }

    return savingsMessage
}

function createSavingsMessage(boldVerbiage, verbiage){
    let savingsMessage = (<div>
        <p>
            <span className="font-weight-bold">{`${boldVerbiage} `}</span>
            <span>{` ${verbiage}`}</span>
        </p>
    </div>)

    return savingsMessage
}

function getPricePlanTitle(isCommercial, pricePlan) {
    let pricePlanTitle = ""
    if (isCommercial) {
        switch (pricePlan) {
            case pricePlanConstants.PRICE_PLAN_COMM_SERVER_MAP.TOU:
                pricePlanTitle = pricePlanConstants.PRICE_PLAN_NAME.EN.TOU_COMM
                break
            case pricePlanConstants.PRICE_PLAN_COMM_SERVER_MAP.GENERAL:
                pricePlanTitle = pricePlanConstants.PRICE_PLAN_NAME.EN.GENERAL_COMM
                break
            default:
                break
        }
    }
    else {
        switch (pricePlan) {
            case pricePlanConstants.PRICE_PLAN_RES_SERVER_MAP.EZ3:
                pricePlanTitle = pricePlanConstants.PRICE_PLAN_NAME.EN.EZ3_RES
                break
            case pricePlanConstants.PRICE_PLAN_RES_SERVER_MAP.EZ3_4_7:
                pricePlanTitle = pricePlanConstants.PRICE_PLAN_NAME.EN.EZ3_4_7
                break
            case pricePlanConstants.PRICE_PLAN_RES_SERVER_MAP.TOU:
                pricePlanTitle = pricePlanConstants.PRICE_PLAN_NAME.EN.TOU_RES
                break
            case pricePlanConstants.PRICE_PLAN_RES_SERVER_MAP.EV:
                pricePlanTitle = pricePlanConstants.PRICE_PLAN_NAME.EN.EV_RES
                break
            case pricePlanConstants.PRICE_PLAN_RES_SERVER_MAP.BASIC:
                pricePlanTitle = pricePlanConstants.PRICE_PLAN_NAME.EN.BASIC_RES
                break
            case pricePlanConstants.PRICE_PLAN_RES_SERVER_MAP.MPOWER:
                pricePlanTitle = pricePlanConstants.PRICE_PLAN_NAME.EN.MPOWER_RES
                break
            case pricePlanConstants.PRICE_PLAN_RES_SERVER_MAP.GENERATION:
                pricePlanTitle = pricePlanConstants.PRICE_PLAN_NAME.EN.GENERATION_RES
                break
            case pricePlanConstants.PRICE_PLAN_RES_SERVER_MAP.TOU_DEMAND:
                pricePlanTitle = pricePlanConstants.PRICE_PLAN_NAME.EN.TOU_DEMAND
                break
            case pricePlanConstants.PRICE_PLAN_RES_SERVER_MAP.TOU_EXPORT:
                pricePlanTitle = pricePlanConstants.PRICE_PLAN_NAME.EN.TOU_EXPORT
                break
            case pricePlanConstants.PRICE_PLAN_RES_SERVER_MAP.EV_EXPORT:
                pricePlanTitle = pricePlanConstants.PRICE_PLAN_NAME.EN.EV_EXPORT
                break
            case pricePlanConstants.PRICE_PLAN_RES_SERVER_MAP.AVERAGE_DEMAND:
                pricePlanTitle = pricePlanConstants.PRICE_PLAN_NAME.EN.AVERAGE_DEMAND
                break
            default:
                pricePlanTitle = pricePlanConstants.PRICE_PLAN_NAME.EN.UNKNOWN
                break
        }
    }

    return pricePlanTitle
}

export default changePricePlanModule