import React from 'react'
import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'

const StyledTooltip = styled(({ className, ...props}) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: 'white',
        borderRadius: '5px',
        border: '1px solid #888',
        padding: '5px'
    },

    [`& .${tooltipClasses.arrow}`]: {
        '&::before': {
            backgroundColor: 'white',
            border: '1px solid #888'
        }
    }
}))

class SrpPopupMenu extends React.Component {
    render() {
        return (
            <StyledTooltip
                placement={this.props.placement || 'top'}
                disableFocusListener
                enterTouchDelay={100}
                title={
                    <React.Fragment>
                        {this.props.content}
                    </React.Fragment>
                }
                arrow
            >
                {this.props.children}
            </StyledTooltip>
        )
    }
}

SrpPopupMenu.propTypes = {
    content: PropTypes.element.isRequired,
    placement: PropTypes.string,
    children: PropTypes.element
}

export default SrpPopupMenu