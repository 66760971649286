import React from 'react'
import PropTypes from 'prop-types'
import { useFormikContext } from 'formik'

import { formErrorEvent, formEvent, autoFormStartEvent, formStartEvent, formCompleteEvent } from '../../srp_modules/analytics-events'

const AnalyticsFormTracking = ({ name, formStep, meta, onStateChanged, initialChangedState = false }) => {
    const formikContext = useFormikContext()
    const { initialValues, values, isSubmitting, status } = formikContext

    const formStepRef = React.useRef(formStep)
    const isSubmittingRef = React.useRef(isSubmitting)

    const [ hasChanged, setHasChanged ] = React.useState(initialChangedState)

    // 1. Watch for form start event
    React.useEffect(() => {
        if (hasChanged)
            return

        if (initialValues !== values) {
            formStartEvent(name, formStep)
            setHasChanged(true)
            if (onStateChanged)
                onStateChanged()
        }
    }, [ formStep, hasChanged, initialValues, name, values, onStateChanged ])

    // 2. Watch for step change events
    React.useEffect(() => {
        if (formStepRef.current === formStep)
            return

        if (!hasChanged) {
            autoFormStartEvent(name, formStepRef.current)
            setHasChanged(true)
        }

        formEvent(name, formStep)
        formStepRef.current = formStep
    }, [ formStep, name, hasChanged ])

    // 3. Watch for form submit event
    React.useEffect(() => {
        if (isSubmittingRef.current && !isSubmitting) {
            if (!hasChanged) {
                autoFormStartEvent(name, formStep)
                setHasChanged(true)
            }

            if (status && status.submitError === true) {
                formErrorEvent(name)
            }
            else {
                formCompleteEvent(name, meta)
            }
        }
        isSubmittingRef.current = isSubmitting
    }, [ isSubmitting, hasChanged, meta, formStep, name, status ])

    // Note: intentionally not rendering anything, this is just watching for changes to existing forms
    return null
}

AnalyticsFormTracking.propTypes = {
    formStep: PropTypes.string,
    initialChangedState: PropTypes.bool,
    meta: PropTypes.object,
    name: PropTypes.string.isRequired,
    onStateChanged: PropTypes.func
}

export default AnalyticsFormTracking