import React from 'react'
import CircularProgress from '@mui/material/CircularProgress'

let style = {
    marginTop: "10%",
    marginBottom: "10%"
}

const BankInfoLoadingCard = () => {
    return (
        <div className="d-flex justify-content-center">
            <CircularProgress size={80} thickness={5} style={style} />
        </div>
    )
}

export default BankInfoLoadingCard