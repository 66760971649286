import {RSAA} from 'redux-api-middleware'
import {formConstants} from '../../../constants/form-constants'
import utils from '../../../srp_modules/utils'
import {mapToServerStruct} from "../../../srp_modules/forms/turnon-mapper"

export const updatesTurnOnDetails = (turnOnDetails) => ({
  type: formConstants.TURN_ON_DETAIL_CHANGE, 
  turnOnDetails
})

export const changeRatePlan = (newPlan) => ({
  type: formConstants.CHANGE_RATE_PLAN,
  newPlan
})

export const changeSolarChoice = (solarChoice) => ({
  type: formConstants.CHANGE_SOLARCHOICE,
  solarChoice
})

export const startDateChange = (newStartDate) => ({
  type: formConstants.CHANGE_START_DATE,
  newStartDate
})

export const address1Change = (newAddress1) => ({
  type: formConstants.CHANGE_ADDRESS1,
  newAddress1
})

export const changeAddress2 = (newAddress2) => ({
  type: formConstants.CHANGE_ADDRESS2,
  newAddress2
})

export const cityChange = (newCity) => ({
  type: formConstants.CHANGE_CITY,
  newCity
})

export const paperlessBillingChange = (newSetting) => ({
  type: formConstants.CHANGE_PAPERLESS_BILLING,
  newSetting
})

export const isMailingAddressChange = (newSetting) => ({
  type: formConstants.CHANGE_MAILING_ADDRESS,
  newSetting
})

export const dueDateChange = (dateChange) => ({
  type: formConstants.CHANG_CUSTOM_DUE_DATE,
  dateChange
})

export const carMakeChange = (newMake) => ({
  type: formConstants.CHANGE_CAR_MAKE,
  newMake
})

export const carModelChange = (newModel) => ({
  type: formConstants.CHANGE_CAR_MODEL,
  newModel
})

export const carYearChange = (newYear) => ({
  type: formConstants.CHANGE_CAR_YEAR,
  newYear
})

export const vehicleOwnershipChange = (newOwnership) => ({
  type: formConstants.CHANGE_VEHICLE_OWNERSHIP,
  newOwnership
})

export const changeSupplyEquipment = (newEquipment) => ({
  type: formConstants.CHANGE_SUPPLY_EQUIP,
  newEquipment
})

export const changeInputVoltage = (newVoltage) => ({
  type: formConstants.CHANGE_INPUT_VOLTAGE,
  newVoltage
})

export const changeCurrentSetting = (newSetting) => ({
  type: formConstants.CHANGE_CURRENT,
  newSetting
})

export const changeFirstName = (newFirstName) => ({
  type: formConstants.CHANGE_FIRST_NAME,
  newFirstName
})

export const changeMiddleName = (newMiddleName) => ({
  type: formConstants.CHANGE_MIDDLE_NAME,
  newMiddleName
})

export const changeLastName = (newLastName) => ({
  type: formConstants.CHANGE_LAST_NAME,
  newLastName
})

export const changeIDType = (newIDType) => ({
  type: formConstants.CHANGE_ID_TYPE,
  newIDType
})

export const changeIDNumber = (newIDNumber) => ({
  type: formConstants.CHANGE_ID_NUMBER,
  newIDNumber
})

export const changePreferredLang = (newLang) => ({
  type: formConstants.CHANGE_PREFERRED_LANGUAGE,
  newLang
})

export const changeEmail = (newEmail) => ({
  type: formConstants.CHANGE_EMAIL,
  newEmail
})

export const changePhoneNumber = (newNumber) => ({
  type: formConstants.CHANGE_PHONE_NUMBER,
  newNumber
})

export const changeAuthSpouse = (newSetting) => ({
  type: formConstants.CHANGE_AUTH_SPOUSE,
  newSetting
})

export const changeSpouseFirstName = (newName) => ({
  type: formConstants.CHANGE_SPOUSE_FIRST_NAME,
  newName 
})

export const changeSpouseLastname = (newLastName) => ({
  type: formConstants.CHANGE_SPOUSE_LAST_NAME,
  newLastName
})

export const runRequiredValidationCheck = (shouldCheck) => ({
  type: formConstants.RUN_VALIDATION_CHECK,
  shouldCheck
})

export const changeAgreementToSafety = (hasAgreed) => ({
  type: formConstants.CHANGE_SAFETY_AGREEMENT,
  hasAgreed
})

export const refreshStartForm = () => ({
  type: formConstants.REFRESH_START_FORM
})

export const changeMailingAddress1 = (address) => ({ type: formConstants.CHANGE_MAILING_ADDRESS1, address})
export const changeMailingAddress2 = (address) => ({ type: formConstants.CHANGE_MAILING_ADDRESS2, address})
export const changeMailingCity     = (city) => ({ type: formConstants.CHANGE_MAILING_CITY, city })
export const changeMailingState    = (state) => ({ type: formConstants.CHANGE_MAILING_STATE, state })
export const changeMailingCountry  = (country) => ({ type: formConstants.CHANGE_MAILING_COUNTRY, country })
export const changeMailingZipcode  = (zip) => ({ type: formConstants.CHANGE_MAILING_ZIPCODE, zip })

export const successfulResponse = () => ({ type: formConstants.SUCCESSFUL })
export const failedResponse = () => ({ type: formConstants.FAILED })

export const submitTurnOn = () => {
  return (dispatch, getState) => {
    const serverFormValues = mapToServerStruct(getState().turnOn, getState().login.isLoggedIn, getState().accountInfo.billAccount) 
    
    return dispatch({
        [RSAA]: {
          endpoint: utils.createUrl('forms/submitwebturnon'),
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(serverFormValues),
          types: [
            formConstants.SUBMIT_TURNON_REQUEST,
            formConstants.SUBMIT_TURNON_SUCCESS,
            formConstants.SUBMIT_TURNON_FAILURE
          ] 
      } 
    })
  }
}

export const getHolidays = () => dispatch => {
    return dispatch({
      [RSAA]: 
        { endpoint: utils.createUrl('forms/getblockeddates')
        , method: 'GET'
        , headers: {'Content-Type': 'application/json'}
        , types: 
            [ formConstants.HOLIDAY_REQUEST  
            , formConstants.HOLIDAY_SUCCESS
            , formConstants.HOLIDAY_FAILURE
            ]
        } 
    })
}
