import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import { DocumentTitle } from '../../../srp_modules/document-title'
import Card from '@mui/material/Card'

const CustomDueDateIneligible = ({ getFullStreetAddress, getDisplayName, navigateBack, t }) => {

    return (
        <DocumentTitle title={t("Custom Due Date Form | SRP Power")}>
            <div>
                <div className="srp-card-header">
                    <div>{t("Unable to change due date")}</div>
                </div>
                <Card>
                    <div className="container">
                        <div className="srp-card-details">
                            <h3 className="text-muted">{t("SRP account information")}</h3>
                            <div style={{ paddingTop: "10px" }}><span className="font-weight-bold" style={{ paddingRight: '.8em' }}>{t("Name")}</span>{getDisplayName()}</div>
                            <div><span className="font-weight-bold" style={{ paddingRight: '.8em' }}>{t("Service Address")}</span>{getFullStreetAddress()}</div>
                            <div style={{ paddingTop: "20px" }}>
                                {t("not_eligible_to_change_due_dates")}
                            </div>
                            <div className="justify-content-end" style={{ paddingTop: "15px" }}>
                                <div className="col-md text-right">
                                    <Link to="/MyAccount/dashboard/"
                                        className="btn srp-btn btn-lightblue ml-2 mb-2"
                                        onClick={() => navigateBack()}>
                                        {t("Back to previous page")}
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </Card>
            </div>
        </DocumentTitle>
    )
}

CustomDueDateIneligible.propTypes = {
    getFullStreetAddress: PropTypes.func,
    getDisplayName: PropTypes.func,
    navigateBack: PropTypes.func,
    t: PropTypes.func.isRequired,
}

export default CustomDueDateIneligible