/* eslint react/no-danger: 0 */
import React from 'react'

class EmptyPrint extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            printContent: localStorage.getItem('printContent')
        }
        localStorage.removeItem('printContent')

        this.addEventListeners = this.addEventListeners.bind(this)
    }

    componentDidMount() {
        window.print()

        // HACK: This is so IE doesn't immediately close the window before print has even started.
        // Unfortunately, using this hack in Safari causes weird browser issues (page goes blank),
        // which means Safari users will need to manually close the print tab themselves.
        if (window.navigator.userAgent.indexOf('Trident') > 0) {
            setTimeout(this.addEventListeners, 500)
            return
        }
        else if (window.navigator.userAgent.indexOf('Safari') > 0) {
            return
        }

        window.close()
    }

    addEventListeners() {
        window.onfocus = window.close
    }

    render() {
        return (
            this.state.printContent
                ? <div className="m-2" id="printContent" dangerouslySetInnerHTML={{ __html: this.state.printContent }} />
                : null
        )
    }
}

export default EmptyPrint