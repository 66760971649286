import React from 'react'
import PropTypes from 'prop-types'
import List from '@mui/material/List'
import UnlinkedBillAccountRow from './unlinked-bill-account-row'

import utils from '../../../../srp_modules/utils'

class UnlinkedBillAccountRows extends React.PureComponent {
    constructor(props) {
        super(props)
    }

    render() {
        let billAccountList = [...this.props.unlinkedBillAccountList].sort((a, b) => { return utils.serviceAddressSort(a.serviceAddress, b.serviceAddress) })

        return (
            <List style={{ maxHeight: 300, overflowY: 'auto', border: '1px solid #ccc', borderRadius: '3px' }}>
                {
                    billAccountList.map(item => <UnlinkedBillAccountRow key={`unlinked_${item.account}`} billAccountItem={item} />)
                }
            </List>
        )
    }
}

UnlinkedBillAccountRows.propTypes = {
    unlinkedBillAccountList: PropTypes.array.isRequired
}

export default UnlinkedBillAccountRows