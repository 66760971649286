import React from 'react'
import PropTypes from 'prop-types'

import AccountInfoBarContainer from '../myaccount_header/info_bar/account-info-bar-container'
import CallAnytime from '../myaccount_header/call-anytime'
import CashOnlyPaymentCard from '../common_payment/cash-only-payment-card'
import OtherPaymentOptions from '../common_payment/other-payment-options'
import CardLoading from '../../components/dashboard_page/card-loading'
import * as loadingStatus from '../../constants/loading-status-constants'

const AutopayCashOnlyPage = ({billAccount, autopayInfoStatus, selectBillAccountStatus, t}) => {
    return (
        <div>
            <div className="container py-3 hidden-sm-down">
                <CallAnytime />

                <h3 className="mt-3">{t("Sign up for SurePay")}</h3>

                <AccountInfoBarContainer disableSelector={false} billAccount={billAccount} />

                <div className="row">
                    <div className="col-lg-6 mt-3">
                    { autopayInfoStatus !== loadingStatus.LOADING_STATUS_SUCCESS
                        && autopayInfoStatus !== loadingStatus.LOADING_STATUS_FAILURE
                        && selectBillAccountStatus !== loadingStatus.LOADING_STATUS_SUCCESS
                        && selectBillAccountStatus !== loadingStatus.LOADING_STATUS_FAILURE
                        ? (<CardLoading title={t("SurePay")} />) : ( <CashOnlyPaymentCard /> )
                    }
                    </div>
                    <div className="col-lg-6 mt-3">
                        <OtherPaymentOptions />
                    </div>
                </div>
            </div>
        </div>
    )
}

AutopayCashOnlyPage.propTypes = {
    billAccount: PropTypes.number,
    autopayInfoStatus: PropTypes.string,
    selectBillAccountStatus: PropTypes.string,
    t: PropTypes.func.isRequired,
}

export default AutopayCashOnlyPage
