const streetAddressRegex = "^[ A-Za-z0-9#/'-]*$"
const alphaNumericRegex = "^[A-Za-z0-9]*$"
const alphaRegex = "^[A-Za-z]*$"

function filterInput(value, fieldname, setFieldValue, regex) {
    if (value.match(regex)) {
        setFieldValue(fieldname, value.replace(/ {1,}/g,' '))
    }
}

function filterStreetAddress(value, fieldName, setFieldValue) {
    filterInput(value, fieldName, setFieldValue, streetAddressRegex)
}

function filterAlpha(value, fieldName, setFieldValue) {
    filterInput(value, fieldName, setFieldValue, alphaRegex)
}

const formikInputFilter = {
    filterStreetAddress: filterStreetAddress,
    filterAlpha: filterAlpha,
    regex: {
        streetAddressRegex: streetAddressRegex,
        alphaNumericRegex: alphaNumericRegex,
        alphaRegex: alphaRegex
    }
}

export default formikInputFilter