import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'

import { Formik } from 'formik'
import AnalyticsFormTracking from '../../common_formik/analytics-form-tracking'

import ReviewForm from './review-form'
import YouAreAllSet from './you-are-all-set'

import AccountInfoBarContainer from '../../myaccount_header/info_bar/account-info-bar-container'
import HelpCard from '../help'
import DetailsForm from './stop-details'
import DontForget from './dont-forget'
import {
  changeStopDate, goToDetails, goToReview, changeAddress1, changeAddress2
  , changeCity, changeZip, changePhone, changeEmail, changeCountry, shouldRunValidationCheck
  , changeConfirmEmail, changeConfirmPhone, submitStopRequest, getHolidays, sendPostage
} from '../../../actions/guest/form/turn-off-actions'
import { getContacts } from '../../../actions/auth/contact/contact-actions'
import { getBillingAddress, getMPowerAccountDetails } from '../../../actions/auth/bill_account/bill-account-actions'
import { getRateMetaData } from '../../../actions/auth/usage/rate-meta-data-actions'
import { getNotifications } from '../../../actions/auth/notification/notification-actions'

import CircularProgress from '@mui/material/CircularProgress'
import { myAccountConstants } from '../../../constants/myaccount-constants'
import * as loadingStatus from '../../../constants/loading-status-constants'

let progressCirclestyle = {
  marginTop: "10%",
  marginBottom: "10%"
}

class StopServicePage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      address1: '',
      address2: '',
      city: '',
      state: '',
      country: '',
      zip: '',
      phoneNumber: props.primaryPhone,
      confirmPhoneNumber: '',
      email: props.primaryEmail,
      confirmEmail: '',
      sendFinalBillAsEbill: false,

      dba: "",
      requestorsName: "",
      requestorsAssociaton: "",
      currentStep: 0,
    }

    this.goToDetails = this.goToDetails.bind(this)
    this.submitForm = this.submitForm.bind(this)

    this.updateField.bind(this)
    this.updateCheckbox.bind(this)
  }

  componentDidMount() {
    this.props.getHolidays()

    if (this.props.selectedRawBillAccount === 0)
      return

    if (this.props.contactsStatus === loadingStatus.LOADING_STATUS_INIT
      || this.props.contactsStatus === loadingStatus.LOADING_STATUS_FAILURE) {
      this.props.loadContactInfo(this.props.selectedRawBillAccount)
    }
    if (this.props.rateMetaDataStatus === loadingStatus.LOADING_STATUS_INIT
      || this.props.rateMetaDataStatus === loadingStatus.LOADING_STATUS_FAILURE) {
      this.props.loadRateMetaData(this.props.selectedRawBillAccount)
    }
    if (this.props.billingAddressStatus === loadingStatus.LOADING_STATUS_INIT
      || this.props.billingAddressStatus === loadingStatus.LOADING_STATUS_FAILURE) {
      this.props.loadBillingAddress(this.props.selectedRawBillAccount)
    }

    if (this.props.notificationsStatus === loadingStatus.LOADING_STATUS_INIT
      || this.props.notificationsStatus === loadingStatus.LOADING_STATUS_FAILURE) {
      this.props.getNotificationsInfo(this.props.selectedRawBillAccount)
    }

    if (this.props.isMpower &&
      (this.props.mPowerAcctDetailsStatus === loadingStatus.LOADING_STATUS_INIT
        || this.props.mPowerAcctDetailsStatus === loadingStatus.LOADING_STATUS_FAILURE)) {
      this.props.loadMPowerAccountDetails(this.props.selectedRawBillAccount)
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.contactsStatus === loadingStatus.LOADING_STATUS_SUCCESS
      && prevProps.contactsStatus !== loadingStatus.LOADING_STATUS_SUCCESS) {
      this.updateField('email', this.props.primaryEmail)
      this.updateField('phoneNumber', this.props.primaryPhone)
    }

    if ((this.props.selectedBillAccount === prevProps.selectedBillAccount || this.props.selectedBillAccount === 0)
      && !(this.props.rateMetaDataStatus !== prevProps.rateMetaDataStatus && this.props.rateMetaDataStatus === loadingStatus.LOADING_STATUS_SUCCESS))
      return

    if (this.props.contactsStatus === loadingStatus.LOADING_STATUS_INIT) {
      this.props.loadContactInfo(this.props.selectedRawBillAccount)
    }

    if (this.props.rateMetaDataStatus === loadingStatus.LOADING_STATUS_INIT) {
      this.props.loadRateMetaData(this.props.selectedRawBillAccount)
    }

    if (this.props.billingAddressStatus === loadingStatus.LOADING_STATUS_INIT) {
      this.props.loadBillingAddress(this.props.selectedRawBillAccount)
    }

    if (this.props.notificationsStatus === loadingStatus.LOADING_STATUS_INIT) {
      this.props.getNotificationsInfo(this.props.selectedRawBillAccount)
    }

    if (this.props.isMpower &&
      this.props.mPowerAcctDetailsStatus === loadingStatus.LOADING_STATUS_INIT) {
      this.props.loadMPowerAccountDetails(this.props.selectedRawBillAccount)
    }
  }

  updateField(fieldName, fieldValue) {
    this.setState({ [fieldName]: fieldValue.replace(/ {1,}/g,' ') })
  }

  updateCheckbox(fieldName, fieldValue) {
    this.setState({ [fieldName]: fieldValue })
  }

  goToDetails(currentStep) {
    this.setState({ currentStep: currentStep })
    this.props.goToDetailsStep()
  }

  goToReview(currentStep) {
    if (this.state.address1.length === 0
      || this.state.city.length === 0
      || this.state.state.length === 0
      || this.state.zip.length === 0
      || this.state.phoneNumber.length === 0
      || this.state.email.length === 0) {
      this.props.runValidation(true)
      return false
    }
    this.props.goToReviewStep(this.state, this.props.isMpower)
    this.setState({ currentStep: currentStep })
    backToTop("form-header")
  }

  async submitForm(_, formikActions) {
    if (Object.keys(this.props.serviceAddress).length === 0) {
      formikActions.setStatus({submitError: true})
      backToTop("form-header")
    }
    else {
      const res = await this.props.submit()
      const submittedSuccessfully = (res.payload?.success === true)

      // for AnalyticsFormTracking: emit an error event if
      // there's an error calling the API
      formikActions.setStatus({submitError: !submittedSuccessfully})
    }
    formikActions.setSubmitting(false)
  }

  displayCardBody() {
    if (this.props.contactsStatus === loadingStatus.LOADING_STATUS_INIT || this.props.contactsStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS
      || this.props.rateMetaDataStatus === loadingStatus.LOADING_STATUS_INIT || this.props.rateMetaDataStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS
      || this.props.billingAddressStatus === loadingStatus.LOADING_STATUS_INIT || this.props.billingAddressStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS
      || this.props.serviceAddressStatus === loadingStatus.LOADING_STATUS_INIT || this.props.serviceAddressStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS
      || (this.props.isMpower && (this.props.mPowerAcctDetailsStatus === loadingStatus.LOADING_STATUS_INIT || this.props.mPowerAcctDetailsStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS))
      || this.props.notificationsStatus === loadingStatus.LOADING_STATUS_INIT || this.props.notificationsStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS) {
      return <div className="d-flex justify-content-center"><CircularProgress size={80} thickness={5} style={progressCirclestyle} /></div>
    }

    const initValues = {
      address1: this.state.address1,
      address2: this.state.address2,
      city: this.state.city,
      state: this.state.state,
      country: this.state.country,
      zip: this.state.zip,
      email: this.state.email,
      confirmEmail: this.state.confirmEmail,
      sendFinalBillAsEbill: this.state.sendFinalBillAsEbill, // used if not on M-Power
      phoneNumber: this.state.phoneNumber,
      confirmPhoneNumber: this.state.confirmPhoneNumber,
      dba: this.state.dba,
      requestorsName: this.state.requestorsName, // used if bill account is commercial
      requestorsAssociaton: this.state.requestorsAssociaton, // used if bill account is commercial
    }

    const currentStep = this.state.currentStep
    const csPhoneNumberFormatted = this.props.isCommercial
      ? myAccountConstants.COMMERCIAL_CUSTOMER_SERVICE_PHONE_NUMBER_FMTTD
      : myAccountConstants.RESIDENTIAL_CUSTOMER_SERVICE_PHONE_NUMBER_FMTTD
    const submitting = (this.props.submitTurnOffStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS)
    const submitted = (this.props.submitTurnOffStatus === loadingStatus.LOADING_STATUS_SUCCESS)

    if (submitted)
      backToTop("form-header")

    return (
      <Formik initialValues={initValues} onSubmit={this.submitForm}>
        {({values, setFieldValue, handleSubmit, status}) => {

        let meta = {
          accountType: this.props.isCommercial ? "commercial" : "residential"
        }

        const showErrorMessage = (status?.submitError === true ?? false)

        return (<React.Fragment>
          <AnalyticsFormTracking name="Web Turnoff" formStep={currentStep===0 ? "account details" : "review"} meta={meta} />
          {(this.props.isAccountDetailsShown) ?
          <div>
            <DetailsForm
              pageState={values}
              {...this.props}
              updateField={(fieldName, fieldValue) => {setFieldValue(fieldName, fieldValue); this.updateField(fieldName, fieldValue)}}
              updateCheckbox={(fieldName, fieldValue) => {setFieldValue(fieldName, fieldValue); this.updateCheckbox(fieldName, fieldValue)}}
              currentStep={currentStep}
              goToReview={() => this.goToReview(currentStep + 1)} />
          </div> :
          (this.props.isReviewShown) ?
          <ReviewForm showErrorMessage={showErrorMessage} csPhoneNumberFormatted={csPhoneNumberFormatted}
            submitting={submitting} submitForm={handleSubmit} currentStep={currentStep}
            goToDetails={this.goToDetails} props={this.props} isOnCentralPrepay={this.props.centralPrepayStatus==='PrepayCentral'}
          /> :
          (submitted) ?
          <YouAreAllSet selectedDate={this.props.selectedDate} isMpower={this.props.isMpower} isOnCentralPrepay={this.props.centralPrepayStatus==='PrepayCentral'} shouldSendPostage={this.props.shouldSendPostage} />
          : null}
          </React.Fragment>)
        }}
      </Formik>
  )}

  render() {
    return (<div>
      <div className="container py-3">
        <h3 className="mt-3">{this.props.t("Stop my electric service")}</h3>
        <div className="py-3">
          {this.props.isLoggedIn &&
            <AccountInfoBarContainer disableSelector={true} />
          }
        </div>
        <div className="d-lg-block">
          <div className="row">
            <div className="col-lg-6 mt-4">
              <div className="srp-card-header" id="form-header">
                <span style={this.props.isAccountDetailsShown ? {} : { display: 'none' }}>{this.props.t("We just need a few details")}</span>
                <span style={this.props.isReviewShown ? {} : { display: 'none' }}>{this.props.t("Let's make sure everything is right")}</span>
                <span style={this.props.submitSucceeded ? {} : { display: 'none' }}>{this.props.t("Turn off request submitted")}</span>
              </div>
              <div className="srp-card-body">
                <div className="srp-card-details">
                  {this.displayCardBody()}
                </div>
              </div>
            </div>
            <div className="col-lg-6 mt-4">
              <HelpCard isCommercial={this.props.isCommercial} />
              {this.props.submitSucceeded &&
                <DontForget />
              }
            </div>
          </div>
        </div>
      </div>
    </div>)
  }
}

const mapStateToProps = state => {
  return {
    ...state.turnOff,
    isLoggedIn: state.login.isLoggedIn,
    selectedBillAccount: state.accountInfo.billAccount.selectedBillAccountDetails.accountText,
    selectedRawBillAccount: state.accountInfo.billAccount.selectedBillAccount,
    displayName: state.accountInfo.billAccount.customerNames.displayName,
    isMpower: state.accountInfo.billAccount.currentRateCode.isPrepay, // This component depends on AccountInfoBarContainer to dispatch getRateCode.
    centralPrepayStatus: state.accountInfo.billAccount.mPowerAcctDetails.centralPrepayStatus ?? '',
    mPowerAcctDetailsStatus: state.accountInfo.billAccount.mPowerAcctDetailsStatus,
    isCommercial: state.accountInfo.billAccount.selectedBillAccountDetails.isCommercial,
    loggedInPhoneNumber: state.accountInfo.billAccount.phoneNumber.bestPhone,
    loggedInEmail: state.login.loginEmail,
    contactsStatus: state.accountInfo.contact.contactsStatus,
    billingAddressStatus: state.accountInfo.billAccount.billingAddressStatus,
    serviceAddressStatus: state.accountInfo.billAccount.serviceAddressStatus,
    rateMetaDataStatus: state.rateMetaData.rateMetaDataStatus,
    isBusinessOnResidentialPlan: state.rateMetaData.rateMetaData.isBusinessOnResidentialPlan,
    ...state.accountInfo.notification,
  }
}
// TODO: Add ismpower flag to update global state
const mapDispatchToProps = (dispatch) => {
  return {
    stopDateChange: (newDate) => dispatch(changeStopDate(newDate)),
    goToDetailsStep: () => dispatch(goToDetails()),
    goToReviewStep: (formState, isMpower) => dispatch(goToReview(formState, isMpower)),
    loadContactInfo: (billAccount) => dispatch(getContacts(billAccount)),
    loadRateMetaData: (billAccount) => dispatch(getRateMetaData(billAccount)), // getRateMetaData, GET_RATE_META_DATA_SUCCESS, rateMetaData.rateMetaData.isMPower
    loadMPowerAccountDetails: (billAccount) => dispatch(getMPowerAccountDetails(billAccount)),
    loadBillingAddress: (billAccount) => dispatch(getBillingAddress(billAccount)),
    selectTurnOffDate: (newDate) => dispatch(changeStopDate(newDate)),
    updateAddress1: (address) => dispatch(changeAddress1(address)),
    updateAddress2: (address) => dispatch(changeAddress2(address)),
    updateCity: (city) => dispatch(changeCity(city)),
    updateZip: (zip) => dispatch(changeZip(zip)),
    updateEmail: (email) => dispatch(changeEmail(email)),
    updatePhone: (phone) => dispatch(changePhone(phone)),
    updateCountry: (country) => dispatch(changeCountry(country)),
    updateConfirmEmail: (email) => dispatch(changeConfirmEmail(email)),
    updateConfirmPhone: (phone) => dispatch(changeConfirmPhone(phone)),
    runValidation: (shouldRun) => dispatch(shouldRunValidationCheck(shouldRun)),
    updatePostage: (shouldSendPostage) => dispatch(sendPostage(shouldSendPostage)),
    getHolidays: () => dispatch(getHolidays()),
    submit: () => dispatch(submitStopRequest()),
    getNotificationsInfo: (billAccount) => dispatch(getNotifications(billAccount))
  }
}

StopServicePage.propTypes = {
  billingAddressStatus: PropTypes.string,
  centralPrepayStatus: PropTypes.string.isRequired,
  contactsStatus: PropTypes.string,
  eBillState: PropTypes.bool,
  getHolidays: PropTypes.func,
  loadMPowerAccountDetails: PropTypes.func.isRequired,
  getNotificationsInfo: PropTypes.func,
  goToDetailsStep: PropTypes.func,
  goToReviewStep: PropTypes.func,
  isAccountDetailsShown: PropTypes.bool,
  isBusinessOnResidentialPlan: PropTypes.bool,
  isCommercial: PropTypes.bool,
  isLoggedIn: PropTypes.bool,
  isMpower: PropTypes.bool,
  isReviewShown: PropTypes.bool,
  loadBillingAddress: PropTypes.func,
  loadContactInfo: PropTypes.func,
  loadRateMetaData: PropTypes.func,
  mPowerAcctDetailsStatus: PropTypes.string.isRequired,
  notificationsStatus: PropTypes.string,
  primaryEmail: PropTypes.string,
  primaryPhone: PropTypes.string,
  rateMetaDataStatus: PropTypes.string,
  redirectToLogin: PropTypes.func,
  runValidation: PropTypes.func,
  selectedDate: PropTypes.object,
  selectedBillAccount: PropTypes.string,
  selectedRawBillAccount: PropTypes.number,
  serviceAddress: PropTypes.object,
  serviceAddressStatus: PropTypes.string,
  shouldSendPostage: PropTypes.bool,
  submit: PropTypes.func,
  submitSucceeded: PropTypes.bool,
  submitTurnOffStatus: PropTypes.string,
  t: PropTypes.func
}

export default withTranslation('stopService')(connect(mapStateToProps, mapDispatchToProps)(StopServicePage))

function backToTop(elementId) {
  document.getElementById(elementId).scrollIntoView()
}