export const paymentConstants = {
    POPULATE_PAYMENT: 'POPULATE_PAYMENT',

    GET_PAYMENT_REQUEST: 'GET_PAYMENT_REQUEST',
    GET_PAYMENT_SUCCESS: 'GET_PAYMENT_SUCCESS',
    GET_PAYMENT_FAILURE: 'GET_PAYMENT_FAILURE',

    GET_LAST_PAYMENT_REQUEST: 'GET_LAST_PAYMENT_REQUEST',
    GET_LAST_PAYMENT_SUCCESS: 'GET_LAST_PAYMENT_SUCCESS',
    GET_LATE_PAYMENT_FAILURE: 'GET_LATE_PAYMENT_FAILURE',

    GET_ENROLLED_PROGRAMS_DETAILS_REQUEST: 'GET_ENROLLED_PROGRAMS_DETAILS_REQUEST',
    GET_ENROLLED_PROGRAMS_DETAILS_SUCCESS: 'GET_ENROLLED_PROGRAMS_DETAILS_SUCCESS',
    GET_ENROLLED_PROGRAMS_DETAILS_FAILURE: 'GET_ENROLLED_PROGRAMS_DETAILS_FAILURE',

    GET_PAYMENT_HISTORY_REQUEST: 'GET_PAYMENT_HISTORY_REQUEST',
    GET_PAYMENT_HISTORY_SUCCESS: 'GET_PAYMENT_HISTORY_SUCCESS',
    GET_PAYMENT_HISTORY_FAILURE: 'GET_PAYMENT_HISTORY_FAILURE',

    TRANSACTION_TYPE_UNKNOWN: 0,
    TRANSACTION_TYPE_PAYMENT: 1,
    TRANSACTION_TYPE_FEE: 2,
    TRANSACTION_TYPE_AGENCY_GUARANTEE: 3,
    TRANSACTION_TYPE_ADJUSTMENT: 4,

    GET_SCHEDULED_PAYMENTS_REQUEST: 'GET_SCHEDULED_PAYMENTS_REQUEST',
    GET_SCHEDULED_PAYMENTS_SUCCESS: 'GET_SCHEDULED_PAYMENTS_SUCCESS',
    GET_SCHEDULED_PAYMENTS_FAILURE: 'GET_SCHEDULED_PAYMENTS_FAILURE',

    GET_PENDING_PAYMENTS_REQUEST: 'GET_PENDING_PAYMENTS_REQUEST',
    GET_PENDING_PAYMENTS_SUCCESS: 'GET_PENDING_PAYMENTS_SUCCESS',
    GET_PENDING_PAYMENTS_FAILURE: 'GET_PENDING_PAYMENTS_FAILURE',

    GET_CREDIT_CARD_PAYMENT_URL_REQUEST: 'GET_CREDIT_CARD_PAYMENT_URL_REQUEST',
    GET_CREDIT_CARD_PAYMENT_URL_SUCCESS: 'GET_CREDIT_CARD_PAYMENT_URL_SUCCESS',
    GET_CREDIT_CARD_PAYMENT_URL_FAILURE: 'GET_CREDIT_CARD_PAYMENT_URL_FAILURE',

    GET_CREDIT_EXTENSION_REQUEST: 'GET_CREDIT_EXTENSION_REQUEST',
    GET_CREDIT_EXTENSION_SUCCESS: 'GET_CREDIT_EXTENSION_SUCCESS',
    GET_CREDIT_EXTENSION_FAILURE: 'GET_CREDIT_EXTENSION_FAILURE',

    SHOW_CIX_CARD_ON_CHANGE: 'SHOW_CIX_CARD_ON_CHANGE',
    SEND_CIX_CONFIRMATION_EMAIL: 'SEND_CIX_CONFIRMATION_EMAIL',
    START_CIX_FLOW: 'START_CIX_FLOW',
    END_CIX_FLOW: 'END_CIX_FLOW',

    SUBMIT_CREDIT_EXTENSION_REQUEST: 'SUBMIT_CREDIT_EXTENSION_REQUEST',
    SUBMIT_CREDIT_EXTENSION_SUCCESS: 'SUBMIT_CREDIT_EXTENSION_SUCCESS',
    SUBMIT_CREDIT_EXTENSION_FAILURE: 'SUBMIT_CREDIT_EXTENSION_FAILURE',

    CIX_CARD: 1, //'CreditExtensionCard'
    OUT_OF_EXTENSIONS_CARD: 2, //OutOfExtensionsCard
    CIX_ASSISTANCE_CARD: 3, //AssistanceCIXCard
    CIX_CONFIRMATION_CARD: 4, //ConfirmCIXCard
    CIX_ERROR_ON_SUBMIT_CARD: 5, //ErrorOnCIXSubmitCard

    SELECT_BILL_ACCOUNT_ON_CHANGE: 'SELECT_BILL_ACCOUNT_ON_CHANGE_FOR_PAYMENT',

    // make payment
    GET_PAYMENT_ACCOUNT_NUMBER: 'GET_PAYMENT_ACCOUNT_NUMBER',
    GET_PAYMENT_AMOUNT: 'GET_PAYMENT_AMOUNT',
    GET_PAYMENT_DATE: 'GET_PAYMENT_DATE',

    // submit payment
    SUBMIT_PAYMENT_REQUEST: 'SUBMIT_PAYMENT_REQUEST',
    SUBMIT_PAYMENT_SUCCESS: 'SUBMIT_PAYMENT_SUCCESS',
    SUBMIT_PAYMENT_FAILURE: 'SUBMIT_PAYMENT_FAILURE',

    // clear payment information
    CLEAR_PAYMENT_INFO: 'CLEAR_PAYMENT_INFO',

    CLEAR_MAKE_PAYMENT_RESULT: 'CLEAR_MAKE_PAYMENT_RESULT',

    // validate payment before submitting to verify
    VERIFY_PAYMENT_VALIDATION_REQUEST: 'VERIFY_PAYMENT_VALIDATION_REQUEST',
    VERIFY_PAYMENT_VALIDATION_SUCCESS: 'VERIFY_PAYMENT_VALIDATION_SUCCESS',
    VERIFY_PAYMENT_VALIDATION_FAILURE: 'VERIFY_PAYMENT_VALIDATION_FAILURE',

    // verify payment
    VERIFY_PAYMENT_REQUEST: 'VERIFY_PAYMENT_REQUEST',
    VERIFY_PAYMENT_SUCCESS: 'VERIFY_PAYMENT_SUCCESS',
    VERIFY_PAYMENT_FAILURE: 'VERIFY_PAYMENT_FAILURE',

    // verify payment
    VERIFY_SINGLE_PAYMENT_REQUEST: 'VERIFY_SINGLE_PAYMENT_REQUEST',
    VERIFY_SINGLE_PAYMENT_SUCCESS: 'VERIFY_SINGLE_PAYMENT_SUCCESS',
    VERIFY_SINGLE_PAYMENT_FAILURE: 'VERIFY_SINGLE_PAYMENT_FAILURE',
    
    MAKE_PAYMENT_INFO_CHANGE: 'MAKE_PAYMENT_INFO_CHANGE',

    DELETE_PAYMENT_REQUEST: 'DELETE_PAYMENT_REQUEST',
    DELETE_PAYMENT_SUCCESS: 'DELETE_PAYMENT_SUCCESS',
    DELETE_PAYMENT_FAILURE: 'DELETE_PAYMENT_FAILURE',

    GET_SCHEDULED_PAYMENTS_BY_ACCT_REQUEST: 'GET_SCHEDULED_PAYMENTS_BY_ACCT_REQUEST',
    GET_SCHEDULED_PAYMENTS_BY_ACCT_SUCCESS: 'GET_SCHEDULED_PAYMENTS_BY_ACCT_SUCCESS',
    GET_SCHEDULED_PAYMENTS_BY_ACCT_FAILURE: 'GET_SCHEDULED_PAYMENTS_BY_ACCT_FAILURE',

    GET_PENDING_PAYMENTS_BY_ACCT_REQUEST: 'GET_PENDING_PAYMENTS_BY_ACCT_REQUEST',
    GET_PENDING_PAYMENTS_BY_ACCT_SUCCESS: 'GET_PENDING_PAYMENTS_BY_ACCT_SUCCESS',
    GET_PENDING_PAYMENTS_BY_ACCT_FAILURE: 'GET_PENDING_PAYMENTS_BY_ACCT_FAILURE',

    GET_POSTED_PAYMENTS_BY_ACCT_REQUEST: 'GET_POSTED_PAYMENTS_BY_ACCT_REQUEST',
    GET_POSTED_PAYMENTS_BY_ACCT_SUCCESS: 'GET_POSTED_PAYMENTS_BY_ACCT_SUCCESS',
    GET_POSTED_PAYMENTS_BY_ACCT_FAILURE: 'GET_POSTED_PAYMENTS_BY_ACCT_FAILURE',

    GET_PAYMENTS_BY_ACCT_REQUEST: 'GET_PAYMENTS_BY_ACCT_REQUEST',
    GET_PAYMENTS_BY_ACCT_SUCCESS: 'GET_PAYMENTS_BY_ACCT_SUCCESS',
    GET_PAYMENTS_BY_ACCT_FAILURE: 'GET_PAYMENTS_BY_ACCT_FAILURE',

    GET_CUSTOM_DUE_DATE_ELIGIBLE_REQUEST: "GET_CUSTOM_DUE_DATE_ELIGIBLE_REQUEST",
    GET_CUSTOM_DUE_DATE_ELIGIBLE_SUCCESS: "GET_CUSTOM_DUE_DATE_ELIGIBLE_SUCCESS",
    GET_CUSTOM_DUE_DATE_ELIGIBLE_FAILURE: "GET_CUSTOM_DUE_DATE_ELIGIBLE_FAILURE",

    SET_CUSTOM_DUE_DATE_REQUEST: "SET_CUSTOM_DUE_DATE_REQUEST",
    SET_CUSTOM_DUE_DATE_SUCCESS: "SET_CUSTOM_DUE_DATE_SUCCESS",
    SET_CUSTOM_DUE_DATE_FAILURE: "SET_CUSTOM_DUE_DATE_FAILURE",

    AUTHORIZE_TOKENLESS_RECONNECT_REQUEST: 'AUTHORIZE_TOKENLESS_RECONNECT_REQUEST',
    AUTHORIZE_TOKENLESS_RECONNECT_SUCCESS: 'AUTHORIZE_TOKENLESS_RECONNECT_SUCCESS',
    AUTHORIZE_TOKENLESS_RECONNECT_FAILURE: 'AUTHORIZE_TOKENLESS_RECONNECT_FAILURE',

    PAYMENT_PLAN_CODE: {
        MPP_DELETE: "C",
        PMT_ARNG_DELETE: "E",
        BUDGET_INACTIVE: "0",
        BUDGET_ACTIVE: "1",
        BUDGET_UNAVAILABLE: "2",
        NONE: ""
    }
}