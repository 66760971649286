import React from 'react'
import PropTypes from 'prop-types'

const NoPurchasesCopyDesktop = ({t}) => (
    <div className="text-center mt-4 mb-3">
        <div style={{ color: "#004B87" }}>
            <i className="material-icons" style={{ fontSize: "3rem" }}>attach_money</i>
        </div>
        <div className="mb-1" style={{ color: "#326E9F", fontSize: "1.125rem" }}>
            <strong>{t("You have yet to make any purchases")}</strong>
        </div>
        <div className="text-muted">
            <small>{t("SRP is delighted to have you as a customer, we look forward to serving you.")}</small>
        </div>
    </div>
)

NoPurchasesCopyDesktop.propTypes = {
    t: PropTypes.func.isRequired
}

export default NoPurchasesCopyDesktop