import React from 'react'
import CircularProgress from '@mui/material/CircularProgress'

let style = {
    marginTop: "10%",
    marginBottom: "10%"
}

const BillPageLoading = () => {

    return(
        <div className="srp-card-body">
            <div className="srp-card-details d-flex justify-content-center">
                <CircularProgress size={80} thickness={5} style={style} />
            </div>
        </div>
    )
}

export default BillPageLoading