import React from 'react'
import { Formik } from 'formik'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import CircularProgress from '@mui/material/CircularProgress'
import TextField from '@mui/material/TextField'
import AddressWithAutoComplete from '../../address-with-autocomplete'
import formikInputFilter from '../../../srp_modules/formik-input-filter'

import { updateBillingAddress } from '../../../actions/auth/bill_account/bill-account-actions'

class ChangeBillingAddressForm extends React.Component {

    constructor(props) {
        super(props)
    }

    render() {
        return (
            <Formik
                initialValues={{
                    billAccount: this.props.selectedBillAccount,
                    streetAddress: '',
                    city: '',
                    state: '',
                    zipcode: ''
                }}
                validate={(values)=>validateBillingAddressChangeForm(values, this.props.t)}
                onSubmit={this.props.submitChangeBillingAddress(this.props.setDisplayChangePasswordSuccess)}>
                {({ values, errors, status, touched, dirty, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => {
                    let isStreetAddressError = dirty && touched.streetAddress === true && errors.streetAddress !== undefined
                    let isStateError = dirty && touched.state === true && errors.state !== undefined
                    let isCityError = dirty && touched.city === true && errors.city !== undefined
                    let isZipCodeError = dirty && touched.zipcode === true && errors.zipcode !== undefined

                    let changeStatus = status || {}

                    let setAddressComponents = (components) => {
                        formikInputFilter.filterStreetAddress(`${components.streetNumber} ${components.street}`, 'streetAddress', setFieldValue)
                        formikInputFilter.filterStreetAddress(components.city, 'city', setFieldValue)
                        formikInputFilter.filterAlpha(components.state, 'state', setFieldValue)
                        formikInputFilter.filterStreetAddress(components.zipCode, 'zipcode', setFieldValue)
                    }

                    return (
                        <form onSubmit={handleSubmit}>
                            <div className="w-100">
                                {changeStatus.submitError}
                                <div>
                                    <div className="mb-2" style={{ position: 'relative' }}>
                                        <AddressWithAutoComplete
                                            fieldName="streetAddress"
                                            fieldValue={values.streetAddress}
                                            handleAddressChange={(e) => formikInputFilter.filterStreetAddress(e.target.value, 'streetAddress', setFieldValue)}
                                            handleAddressBlur={handleBlur}
                                            errorText={isStreetAddressError ? errors.streetAddress : ''}
                                            hasError={isStreetAddressError}
                                            updateAddressCallback={setAddressComponents}
                                            isDomesticAddress={true} 
                                            label={this.props.t("Street address")}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <div className="mb-2" style={{ position: 'relative' }}>
                                        <TextField
                                            fullWidth={true}
                                            type={'text'}
                                            value={values.city}
                                            error={isCityError}
                                            helperText={isCityError ? errors.city : ''}
                                            onChange={(e) => formikInputFilter.filterStreetAddress(e.target.value, 'city', setFieldValue)}
                                            onBlur={handleBlur}
                                            id="city"
                                            name="city" 
                                            label={this.props.t("City")}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <div className="mb-2" style={{ position: 'relative' }}>
                                        <TextField
                                            fullWidth={true}
                                            type={'text'}
                                            value={values.state}
                                            error={isStateError}
                                            helperText={isStateError ? errors.state : ''}
                                            inputProps={{ maxLength: 2 }}
                                            onChange={(e) => formikInputFilter.filterAlpha(e.target.value, 'state', setFieldValue)}
                                            onBlur={handleBlur}
                                            id="state"
                                            name="state" 
                                            label={this.props.t("State")}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <div className="mb-2" style={{ position: 'relative' }}>
                                        <TextField
                                            fullWidth={true}
                                            type={'text'}
                                            value={values.zipcode}
                                            error={isZipCodeError}
                                            helperText={isZipCodeError ? errors.zipcode : ''}
                                            onChange={(e) => formikInputFilter.filterStreetAddress(e.target.value, 'zipcode', setFieldValue)}
                                            onBlur={handleBlur}
                                            id="zipcode"
                                            name="zipcode" 
                                            label={this.props.t("Zip code")}
                                        />
                                    </div>
                                </div>
                                <div className="justify-content-end">
                                    <div className="col-lg text-right mt-3">
                                        <button type="button" disabled={isSubmitting}
                                            className="btn srp-btn btn-lightblue ml-2 mb-2"
                                            onClick={this.props.setDisplayChangeBillingAddressForm(false)}>
                                            {this.props.t("Cancel")}
                                        </button>
                                        <button type="submit" disabled={isSubmitting} className="btn srp-btn btn-green ml-2 mb-2">
                                            {isSubmitting ? <CircularProgress size={20} thickness={5} style={{ color: 'white' }} /> : this.props.t("Update")}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    )
                }}
            </Formik>
        )
    }
}

ChangeBillingAddressForm.propTypes = {
    selectedBillAccount: PropTypes.number.isRequired,
    submitChangeBillingAddress: PropTypes.func.isRequired,
    setDisplayChangePasswordSuccess: PropTypes.func.isRequired,
    setDisplayChangeBillingAddressForm: PropTypes.func.isRequired,
    t: PropTypes.func
}

const mapDispatchToProps = dispatch => {
    return {
        submitChangeBillingAddress: (setDisplayChangePasswordSuccess) => async (values, goodies) => {

            let newBillingAddress = {
                StreetAddress: values.streetAddress,
                StreetAddressLineTwo: '',
                State: values.state.trim(),
                City: values.city,
                Zipcode: values.zipcode,
                AddressType: 'Billing',
                Country: ''
            }

            let result = await dispatch(updateBillingAddress(values.billAccount, newBillingAddress))

            if (result.error === true || result.payload === undefined) {

                let errorMessage = <div className="srp-alert-error mb-2" style={{ marginTop: '20px' }}>We're sorry, something went wrong. Please try again later.</div>

                goodies.setStatus({ submitError: errorMessage })
                goodies.setSubmitting(false)
                return
            }
            else {
                if (result.payload.success === false) {

                    let errorMessage = <div className="srp-alert-error mb-2" style={{ marginTop: '20px' }}>{result.payload.errorMessage}</div>

                    goodies.setStatus({ submitError: errorMessage })
                    goodies.setSubmitting(false)
                    return
                }
            }

            goodies.setStatus({ submitError: '' })
            goodies.setSubmitting(false)
            setDisplayChangePasswordSuccess(true)
        }
    }
}

const mapStateToProps = state => {
    return {
        selectedBillAccount: state.accountInfo.billAccount.selectedBillAccount,
        isMPower: state.accountInfo.billAccount.selectedBillAccountDetails.isPrePay,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangeBillingAddressForm)

function validateBillingAddressChangeForm(values, t){
    let errors = {}

    if (values.streetAddress === undefined || values.streetAddress.trim().length === 0) {
        errors.streetAddress = t('Street address is required')
    }

    if (values.city === undefined || values.city.trim().length === 0) {
        errors.city = t("City is required")
    }

    if (values.state === undefined || values.state.trim().length === 0) {
        errors.state = t("State is required")
    }
    else if (values.state.trim().length !== 2) {
        errors.state = t("State must be a postal abbreviation only 2 characters in length")
    }

    if (values.zipcode === undefined || values.zipcode.trim().length === 0) {
        errors.zipcode = t("Zip is required")
    }
    else if (values.zipcode.trim().length < 5) {
        errors.zipcode = t("Must provide a valid 5 digit zip code")
    }

    return errors
}

