import React from 'react'
import { withTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import CircularProgress from '@mui/material/CircularProgress'

import { myAccountConstants } from '../../../constants/myaccount-constants'

import SelectABankAccount from './input-fields/select-a-bank-account'

import AddBankAccountForm from '../common/add-bank-account-form'

import PurchaseBreakdown from '../common/purchase-breakdown'

import * as loadingStatus from '../../../constants/loading-status-constants'

import PurchaseAmountField from './input-fields/purchase-amount-field'
import PayAmountOwedToSRPCheckbox from './input-fields/pay-amount-owed-to-SRP-checkbox'

import { formStartEvent } from '../../../srp_modules/analytics-events'
import { purchaseConstants } from '../../common_payment/purchase-constants'

class SinglePurchaseFormPure extends React.Component {
    constructor(props) {
        super(props)

        this.formDataChanged = this.formDataChanged.bind(this)
    }

    componentDidUpdate(prevProps) {
        if (this.props.primaryEmailStatus === loadingStatus.LOADING_STATUS_SUCCESS &&
            prevProps.primaryEmailStatus !== loadingStatus.LOADING_STATUS_SUCCESS) {
            this.props.setFieldValue('bankEmailAddress', this.props.primaryEmail, false)
        }
    }

    formDataChanged() {
        if (this.props.formStartEventEmitted === false) {
            const formName = purchaseConstants.ADOBE_ANALYTICS_FORM_NAME
            const stepName = purchaseConstants.PURCHASE_STEPS[0]
            formStartEvent(formName, stepName)

            this.props.setFormStartEventEmitted()
        }
    }

    getCsPhoneNumber() {
        let number = myAccountConstants.RESIDENTIAL_CUSTOMER_SERVICE_PHONE_NUMBER_FMTTD

        if (this.props.i18n.language === 'es')
            number = myAccountConstants.SPANISH_CUSTOMER_SERVICE_PHONE_NUMBER_FMTTD

        return number
    }

    render() {
        let {
            amountOwedToSRP, payDownFraction,
            bankAccountList, bankFormOpen, isSubmitting, status,
            addBankAccountButtonClicked, addBankCancelButtonClicked,
            values, errors, touched, handleChange, handleBlur, handleSubmit,
            setFieldValue, setFieldTouched
        } = this.props

        let commonFormikProps = {
            onChange: (e) => { handleChange(e); this.formDataChanged(e) },
            onBlur: handleBlur,
            errors,
            touched
        }
        let commonTextFieldProps = {
            fullWidth: true
        }

        const verifyStatus = status || {}

        const showCancelButton = bankAccountList.length > 0

        const purchaseAmount = parseFloat(values.purchaseAmount.trim().replace(/[$,]/g, ''))

        return (
            <div>
                {verifyStatus.hasUnhandledeChexValidationError ? (
                    <div className="srp-alert-error mb-3">
                        {`${this.props.t("We_are_unable_to_complete_your_purchase")} ${this.getCsPhoneNumber()}.`}
                    </div>
                ) : null}

                <form onSubmit={handleSubmit}>
                    <label className="h4 text-muted">{this.props.t("Bank information")}</label>

                    {bankFormOpen &&
                        <p className="text-muted">{this.props.t("Enter_your_bank_information_below_to_make_a_purchase")}</p>
                    }

                    {bankFormOpen === false ?
                        <SelectABankAccount
                            bankAccountIdSelected={values.bankAccountIdSelected}
                            bankAccountList={bankAccountList}
                            addBankAccountButtonClicked={addBankAccountButtonClicked}
                            setFieldValue={setFieldValue}
                            isSubmitting={isSubmitting}
                            {...commonFormikProps}
                        />
                        :
                        <AddBankAccountForm
                            values={values}
                            errors={errors}
                            touched={touched}
                            handleChange={commonFormikProps.onChange}
                            handleBlur={handleBlur}
                            showCancelButton={showCancelButton}
                            handleBankFormCancel={() => addBankCancelButtonClicked(setFieldValue)}
                            isSubmitting={isSubmitting}
                        />
                    }

                    <label className="h4 text-muted mt-4">{this.props.t("Purchase details")}</label>

                    <div className="mt-1"><PurchaseAmountField value={values.purchaseAmount} {...commonFormikProps} {...commonTextFieldProps} setFieldValue={setFieldValue} setFieldTouched={setFieldTouched} /></div>

                    <div className="my-3"><PayAmountOwedToSRPCheckbox checked={values.payAmountOwedToSRP} onChange={commonFormikProps.onChange} amountOwedToSRP={amountOwedToSRP} /></div>
                    {amountOwedToSRP > 0 &&
                        <PurchaseBreakdown
                            purchaseAmount={purchaseAmount}
                            applyWholePurchaseTowardsArrears={values.payAmountOwedToSRP}
                            amountOwedToSRP={amountOwedToSRP}
                            payDownFraction={payDownFraction}
                        />
                    }

                    <div className="d-flex justify-content-end mt-4 flex-wrap">
                        <button className="btn srp-btn btn-blue mb-2 ml-2" type="submit" disabled={isSubmitting} onClick={() => { verifyStatus.hasUnhandledeChexValidationError = false }}>
                            {isSubmitting ? <CircularProgress size={20} thickness={5} style={{ color: 'white' }} /> : this.props.t("Confirm purchase")}
                        </button>
                    </div>
                </form>
            </div>
        )
    }
}

SinglePurchaseFormPure.propTypes = {
    values: PropTypes.shape({
        srpAccountNumber: PropTypes.string.isRequired,
        bankAccountIdSelected: PropTypes.number.isRequired,
        bankFirstName: PropTypes.string.isRequired,
        bankLastName: PropTypes.string.isRequired,
        bankPhoneNumber: PropTypes.string.isRequired,
        bankEmailAddress: PropTypes.string.isRequired,
        bankRoutingNumber: PropTypes.string.isRequired,
        bankAccountNumberLeadingZeroesAreSignificant: PropTypes.string.isRequired,
        bankNickname: PropTypes.string.isRequired,
        purchaseAmount: PropTypes.string.isRequired,
        payAmountOwedToSRP: PropTypes.bool.isRequired
    }).isRequired,
    errors: PropTypes.shape({
        srpAccountNumber: PropTypes.string,
        bankAccountIdSelected: PropTypes.oneOfType([
            PropTypes.shape({
                getLocalizedErrMsg: PropTypes.func.isRequired,
            }),
            PropTypes.string.isRequired,
        ]),
        bankFirstName: PropTypes.string,
        bankLastName: PropTypes.string,
        bankPhoneNumber: PropTypes.string,
        bankEmailAddress: PropTypes.string,
        bankRoutingNumber: PropTypes.string,
        bankAccountNumberLeadingZeroesAreSignificant: PropTypes.string,
        bankNickname: PropTypes.string,
        purchaseAmount: PropTypes.oneOfType([
            PropTypes.shape({
                getLocalizedErrMsg: PropTypes.func.isRequired,
            }),
            PropTypes.string.isRequired,
        ]),
        payAmountOwedToSRP: PropTypes.bool
    }).isRequired,
    touched: PropTypes.shape({
        srpAccountNumber: PropTypes.bool,
        bankAccountIdSelected: PropTypes.bool,
        bankFirstName: PropTypes.bool,
        bankLastName: PropTypes.bool,
        bankPhoneNumber: PropTypes.bool,
        bankEmailAddress: PropTypes.bool,
        bankRoutingNumber: PropTypes.bool,
        bankAccountNumberLeadingZeroesAreSignificant: PropTypes.bool,
        bankNickname: PropTypes.bool,
        purchaseAmount: PropTypes.bool,
        payAmountOwedToSRP: PropTypes.bool
    }).isRequired,
    handleChange: PropTypes.func.isRequired,
    handleBlur: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
    bankFormOpen: PropTypes.bool.isRequired,
    addBankAccountButtonClicked: PropTypes.func.isRequired,
    addBankCancelButtonClicked: PropTypes.func.isRequired,
    status: PropTypes.shape({
        hasUnhandledeChexValidationError: PropTypes.bool
    }),
    setFieldValue: PropTypes.func.isRequired,
    setFieldTouched: PropTypes.func.isRequired,
    bankAccountList: PropTypes.arrayOf(PropTypes.shape({
        bankId: PropTypes.number.isRequired,
        bankName: PropTypes.string.isRequired,
        nickName: PropTypes.string.isRequired,
        accountNumber: PropTypes.string.isRequired
    })).isRequired,
    payDownFraction: PropTypes.number.isRequired,
    amountOwedToSRP: PropTypes.number.isRequired,

    formStartEventEmitted: PropTypes.bool.isRequired,
    setFormStartEventEmitted: PropTypes.func.isRequired,

    primaryEmailStatus: PropTypes.string.isRequired,
    primaryEmail: PropTypes.string.isRequired,

    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired
}

const SinglePurchaseForm = withTranslation("singlePurchasePage")(SinglePurchaseFormPure)
export { SinglePurchaseForm as default, SinglePurchaseFormPure }