import React from 'react'
import PropTypes from 'prop-types'
import { DateTime } from 'luxon'

const Greeting = ({rateMetaData, t}) => {

    let getCustomerName = (rateMetaData) => {
        if(!rateMetaData.isCommercial && !rateMetaData.isBusinessOnResidentialPlam)
        {
            return ", " + rateMetaData.firstName
        }

        return ","
    }
    let getGreeting = () => {
        let currentTime = parseFloat(DateTime.now().toFormat('HH'))
        let afternoon = 12
        let evening = 17

        if(currentTime >= afternoon && currentTime < evening)
        {
            return "afternoon"
        }
        else if(currentTime >= evening)
        {
            return "evening"
        }
        else
        {
            return "morning"
        }
    }

    return(
        <div>
            <h3 className="mt-0 mb-0">{t("Good " + getGreeting())}{getCustomerName(rateMetaData)}</h3>
            <h5 className="text-muted">{t("Welcome to My Account")}</h5>
        </div>
    )
}

Greeting.propTypes = {
    rateMetaData: PropTypes.object,
    t: PropTypes.func.isRequired
}

export default Greeting