import React from 'react'
import { withTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import format from '../../../srp_modules/format'

import { PurchaseDetailsStyledDiv, classes } from './purchase-details-styles'

const TotalPurchaseLinePure = ({ totalPurchase, t }) => {
    const totalDisplay = format.formatDollarAmount(totalPurchase)

    return (
        <PurchaseDetailsStyledDiv>
            <div className="px-3">
                <div className="d-none d-lg-block">
                    <div className="d-flex flex-row align-items-center">
                        <h6 className={`${classes.column} ${classes.secondaryText} mb-0`}>{t("Total purchase")}</h6>
                        <h6 className={`${classes.twoColumns} mb-0`}>{totalDisplay}</h6>
                    </div>
                </div>
                <div className="d-lg-none d-flex">
                    <h6 className={`${classes.secondaryText} mb-0 w-50 align-self-center`}>{t("Total purchase")}</h6>
                    <h6 className="mb-0 align-self-center">{totalDisplay}</h6>
                </div>
            </div>
            <hr />
        </PurchaseDetailsStyledDiv>
    )
}

TotalPurchaseLinePure.propTypes = {
    totalPurchase: PropTypes.number.isRequired,

    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired
}

const TotalPurchaseLine = withTranslation("multiPurchasePage")(TotalPurchaseLinePure)
export { TotalPurchaseLine as default, TotalPurchaseLinePure }