import React from "react"
import PropTypes from "prop-types"
import Icon from '@mui/material/Icon'
import IconButton from '@mui/material/IconButton'

const Collapse = ({title, collapsed, children }) => {
    const [isCollapsed, setIsCollapsed] = React.useState(collapsed)
    const containerStyle={
        display: "flex",
        color: "#087ED8"
        
      }
    let iconStyle = {
        marginLeft: "auto",
        order: "2",
        color: "#087ED8"
    }
    
    return (
      <div className={`p-2 ${isCollapsed ? 'collapsedBorderStyle' : 'expandedBorderStyle'}`}>
        <div className={`d-flex align-items-center`}>
          <span style={containerStyle} className="font-weight-bold">{title}</span>
          <IconButton
            onClick={() => setIsCollapsed(!isCollapsed)}
            className="button-focus"
            style={iconStyle}
            size="large">
              <Icon>{!isCollapsed ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}</Icon>
          </IconButton>
        </div>
        <div
          className={`collapse-content ${isCollapsed ? 'collapsed' : 'expanded'}`}
          aria-expanded={isCollapsed}
        >
          {children}
        </div>
      </div>
    )
  }

  Collapse.propTypes = {
      title: PropTypes.string.isRequired,
      collapsed: PropTypes.bool.isRequired,
      children: PropTypes.element
  }

  export default Collapse