import React from 'react'
import PropTypes from 'prop-types'
import { myAccountConstants } from '../../constants/myaccount-constants'

const UnmeteredMessage = ({ isCommercial, t }) => {
    return (
        <div className="usage-chart-error">
            <div className="text-center">
                <div><i className="material-icons">error_outline</i></div>
                <div className="usage-chart-error-title">{t("Usage data unavailable")}</div>
                <div style={{ marginRight: "25%", marginLeft: "25%", textAlign: "center" }}>
                    {t("Unable_to_display_usage_data_for_unmetered_accounts", 
                        {phoneNumber : 
                            isCommercial 
                            ? myAccountConstants.COMMERCIAL_CUSTOMER_SERVICE_PHONE_NUMBER_FMTTD 
                            : myAccountConstants.RESIDENTIAL_CUSTOMER_SERVICE_PHONE_NUMBER_FMTTD}
                        )
                    }
                </div>
            </div>
        </div>
    )
}

UnmeteredMessage.propTypes = {
    isCommercial: PropTypes.bool,
    t:PropTypes.func
}

export default UnmeteredMessage