import React from 'react'
import PropTypes from 'prop-types'
import Icon from '@mui/material/Icon'
import InputLabel from '@mui/material/InputLabel'
import CircularProgress from '@mui/material/CircularProgress'
import { NumericFormat, PatternFormat } from 'react-number-format'
import { DateTime } from 'luxon'
import { withTranslation } from 'react-i18next'

import format from '../../../srp_modules/format'

import * as loadingStatus from '../../../constants/loading-status-constants'
import CardInformation from '../../common_error/card-information'

import Card from '@mui/material/Card'
import '../../../styles/dashboard-page.css'
import { multiPaymentConstants } from '../../../constants/payment/multi-payment-constants'
import SrpTooltip from '../../common_tooltip/srp-tooltip'

import { formEvent } from '../../../srp_modules/analytics-events'
import { paymentConstants } from '../../common_payment/payment-constants'
import { withRouter } from '../../../srp_modules/with-router'

import { myAccountConstants } from '../../../constants/myaccount-constants'

let rowStyle = {
    borderBottom: "1px solid #E5E5E5",
    borderRadius: "0"
}

let paymentStyle = {
    borderBottom: "1px solid rgba(204,204,204,1)",
    paddingBottom: "14px",
    paddingTop: "15px"
}
class VerifyMultiPaymentPage extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            confirmPaymentFormEventEmitted: false
        }

        this.getWarningStyleAndToolTip = this.getWarningStyleAndToolTip.bind(this)
        this.goBack = this.goBack.bind(this)
        this.emitFormStepEvent = this.emitFormStepEvent.bind(this)
    }

    componentDidMount() {
        this.emitFormStepEvent()
    }

    componentDidUpdate() {
        this.emitFormStepEvent()
    }

    getWarningStyleAndToolTip(payment) {
        if (payment.paymentAmountError.hasError) {
            let toolTipText = "Paying less than the total amount due may negatively affect your account, including disconnection."

            if (payment.paymentAmountError.errorType === multiPaymentConstants.PAYMENT_AMOUNT_ERRORS.MORE_THAN_TOTAL) {
                toolTipText = "You are making a payment that is more than the amount due."
            }

            return (
                <span>
                    <SrpTooltip content={<span>{toolTipText}</span>}>
                        <Icon style={{color: "rgb(204,138,0)", verticalAlign: "middle", fontSize: "20px", marginBottom: "4px"}}>info_outline</Icon>
                    </SrpTooltip>
                </span>
                )
        }

        return null
    }

    goBack() {
        const formName = paymentConstants.ADOBE_ANALYTICS_FORM_NAME
        const stepName = paymentConstants.PAYMENT_STEPS[0]
        formEvent(formName, stepName)

        this.props.clearSubmitPaymentError()

        this.props.router.navigate(-1)
    }

    emitFormStepEvent() {
        if (this.state.confirmPaymentFormEventEmitted === false) {
            const formName = paymentConstants.ADOBE_ANALYTICS_FORM_NAME
            const stepName = paymentConstants.PAYMENT_STEPS[1]
            formEvent(formName, stepName)

            this.setState({...this.state, confirmPaymentFormEventEmitted: true})
        }
    }

    render() {
        let t = this.props.t
        let dateWarningText = t("payment_after_due_date")

        return (
            <div className="container py-3 hidden-sm-down">
                <h3 className="mt-3">{t("Make a payment")}</h3>
                <br />
                <div className="row">
                    <div className="col-lg-6 col-12">
                        <label className="h5">{t("Confirm payment details")}</label>
                        <Card className="p-4" style={rowStyle}>
                            {this.props.showSubmitError &&
                            <div className="srp-alert-error mb-3">
                                {`${t("unable_complete_payment")} ${myAccountConstants.RESIDENTIAL_CUSTOMER_SERVICE_PHONE_NUMBER_FMTTD}.`}
                            </div>
                            }
                            <div className="row srp-row">
                                <div className="col-4 srp-label">
                                    <div>{t("Bank account")}</div>
                                </div>
                                <div className="col">
                                    <div>{this.props.selectedBankAccountDescription}</div>
                                </div>
                            </div>
                        </Card>
                        <Card className="p-4" style={rowStyle}>
                            <div className="row srp-row">
                                <div className="col-4 srp-label">
                                    <div>{t("Total payments")}</div>
                                </div>
                                <div className="col">
                                    <div>
                                        <NumericFormat
                                            value={this.props.totalPayment}
                                            valueIsNumericString={true}
                                            thousandSeparator={true}
                                            prefix={'$'}
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                            displayType="text"
                                            style={{width: '100%'}}
                                        />
                                    </div>
                                </div>
                            </div>
                        </Card>
                        <Card className="p-4" style={{ ...rowStyle, paddingTop: "20px" }}>
                            <div style={{marginBottom: "10px", display: this.props.displayDateWarning ? "block" : "none"}}>
                                <CardInformation open={this.props.displayDateWarning} message={dateWarningText} />
                            </div>
                            <div className="row" style={{ paddingLeft: "5px"}}>
                                <div className="col-4">
                                    <InputLabel className="overflow-visible text-wrap" style={{ fontSize: "14px"}}>{t("Payment date")}</InputLabel>
                                </div>
                                <div className="col-4">
                                    <InputLabel className="overflow-visible text-wrap" style={{ fontSize: "14px" }}>{t("Account number")}</InputLabel>
                                </div>
                                <div className="col-3" style={{ textAlign: "right" }}>
                                    <InputLabel className="overflow-visible text-wrap" style={{ fontSize: "14px"}}>{t("Payment amount")}</InputLabel>
                                </div>
                            </div>
                            {Object.keys(this.props.sortedPaymentList).map((key, index) => {
                                let paymentObject = this.props.sortedPaymentList[key]

                                return (<div key={index}>
                                    {paymentObject.paymentList.map((payment, index) => {
                                    return (
                                        <div className="row" key={index} style={{ paddingLeft: "5px", fontSize: "14px"}}>
                                            <div className="col-4" style={index === 0 ? paymentStyle : {}}>
                                                {index === 0 ? DateTime.fromISO(key).toFormat('M/d/yy') : ""}
                                            </div>
                                            <div className="col-4 text-nowrap" style={paymentStyle}>
                                                <PatternFormat
                                                    value={format.srpAccountNumber(payment.billAccount)}
                                                    valueIsNumericString={true}
                                                    format="###-###-###"
                                                    displayType="text"
                                                    style={{width: '100%'}}
                                                />
                                            </div>
                                            <div className="col-3" style={{ ...paymentStyle, textAlign: "right" }}>
                                                <NumericFormat
                                                    value={payment.paymentAmount}
                                                    valueIsNumericString={true}
                                                    thousandSeparator={true}
                                                    prefix={'$'}
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    displayType="text"
                                                    style={{width: '100%'}}
                                                />
                                            </div>
                                            <div className="col-1" style={{ ...paymentStyle, paddingLeft: "0px", paddingRight: "0px"}}>
                                                {this.getWarningStyleAndToolTip(payment)}
                                            </div>
                                        </div>
                                    )}
                                )}
                                <div className="row" style={{ paddingLeft: "5px", paddingBottom: "20px", fontSize: "14px" }}>
                                    <div className="col-4" />
                                    <div className="col-1" style={{ ...paymentStyle, paddingBottom: "20px" }} />
                                    <div className="col-6" style={{ ...paymentStyle, textAlign: "right", fontWeight: "bold" }}>
                                        {t("Subtotal")}&nbsp;&nbsp;
                                        <NumericFormat
                                            value={paymentObject.subTotal}
                                            valueIsNumericString={true}
                                            thousandSeparator={true}
                                            prefix={'$'}
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                            displayType="text"
                                            style={{width: '100%'}}
                                        />
                                    </div>
                                    <div className="col-1 d-none d-md-block" style={{ ...paymentStyle, paddingLeft: "0px", paddingRight: "0px"}} />
                                </div>
                                </div>)}
                            )}

                            <div>
                                <p style={{fontSize: "14px", color: "rgb(112,112,112)"}}>
                                    {t("submit_payment_agreement")}
                                </p>
                            </div>
                            <div style={{float: "right"}}>
                                <button
                                    disabled={this.props.submitMultiPaymentResultStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS}
                                    className="btn srp-btn btn-lightblue d-inline d-md-none"
                                    onClick={this.goBack}>
                                        {t("Edit")}
                                </button>
                                <button
                                    disabled={this.props.submitMultiPaymentResultStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS}
                                    className="btn srp-btn btn-lightblue d-none d-md-inline"
                                    onClick={this.goBack}>
                                        {t("Edit payment details")}
                                </button>
                                <button
                                    disabled={this.props.submitMultiPaymentResultStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS}
                                    className="btn srp-btn btn-green ml-2"
                                    onClick={this.props.onSubmitClick}>
                                        {this.props.submitMultiPaymentResultStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS ? <CircularProgress size={20} thickness={5} style={{color:'white'}} /> : t("Submit payment")}
                                </button>
                            </div>
                        </Card>
                    </div>
                <div className="col" />
            </div>
        </div>)
    }
}

VerifyMultiPaymentPage.propTypes = {
    sortedPaymentList: PropTypes.object.isRequired,
    totalPayment: PropTypes.number.isRequired,
    submitMultiPaymentResultStatus: PropTypes.string.isRequired,
    selectedBankAccountDescription: PropTypes.string.isRequired,
    onSubmitClick: PropTypes.func.isRequired,
    displayDateWarning: PropTypes.bool.isRequired,
    clearSubmitPaymentError: PropTypes.func.isRequired,
    showSubmitError: PropTypes.bool.isRequired,
    router: PropTypes.shape({
        navigate: PropTypes.func
    }),
    t: PropTypes.func.isRequired,
    i18n: PropTypes.object.isRequired
}

export default withTranslation("multiPayment")(withRouter(VerifyMultiPaymentPage))