export const transferServiceConstants = {
    SET_ACTIVE_STEP: 'SET_ACTIVE_STEP',

    SET_END_SERVICE_DATE: 'SET_END_SERVICE_DATE',
    SET_START_SERVICE_DATE: 'SET_START_SERVICE_DATE',
    SET_END_SERVICE_DATE_TOUCHED: 'SET_END_SERVICE_DATE_TOUCHED',
    SET_START_SERVICE_DATE_TOUCHED: 'SET_START_SERVICE_DATE_TOUCHED',
    SELECT_SOLAR_CHOICE: 'SELECT_SOLAR_CHOICE',
    SELECT_PRICE_PLAN: 'SELECT_PRICE_PLAN',

    SET_EV_MAKE: 'SET_EV_MAKE',
    SET_EV_MODEL: 'SET_EV_MODEL',
    SET_EV_YEAR: 'SET_EV_YEAR',
    VALIDATE_EV_YEAR: 'VALIDATE_EV_YEAR',
    SELECT_EV_OWNERSHIP: 'SELECT_EV_OWNERSHIP',
    SELECT_EV_EQUIPMENT_TYPE: 'SELECT_EV_EQUIPMENT_TYPE',
    SELECT_EV_VOLTAGE: 'SELECT_EV_VOLTAGE',
    SELECT_EV_CURRENT: 'SELECT_EV_CURRENT',

    SET_SERVICE_AND_BILL_INFO: 'SET_SERVICE_AND_BILL_INFO',
    SET_CUSTOMER_INFO: 'SET_CUSTOMER_INFO',

    SET_SAFETY_STMT_AGREE: 'SET_SAFETY_STMT_AGREE',
    SEND_MPOWER_BOX: 'SEND_MPOWER_BOX',

    SUBMIT_TRANSFER_SERVICE_REQUEST: 'SUBMIT_TRANSFER_SERVICE_REQUEST',
    SUBMIT_TRANSFER_SERVICE_SUCCESS: 'SUBMIT_TRANSFER_SERVICE_SUCCESS',
    SUBMIT_TRANSFER_SERVICE_FAILURE: 'SUBMIT_TRANSFER_SERVICE_FAILURE',

    ADOBE_ANALYTICS_FORM_NAME: 'Web Transfer',
    getStepName: (activeStep) => {
        const step = transferServiceConstants.TRANSFER_SERVICE_STEPS.find(s => s.step === activeStep)
        const stepName = (typeof step !== "undefined" ? step.labelMobileEn : "step " + activeStep)
        return stepName
    },

    TRANSFER_SERVICE_STEPS: [
        {
            step: 0,
            labelEn: "Select dates/plan",
            labelEs: "Select dates/plan",
            labelMobileEn: "Dates & plan",
            labelMobileEs: "Dates & plan",
            titleEn: "Let's choose your price plan",
            titleEs: "Let's choose your price plan"
        },
        {
            step: 1,
            labelEn: "Service/billing info",
            labelEs: "Service/billing info",
            labelMobileEn: "Service & billing info",
            labelMobileEs: "Service & billing info",
            titleEn: "Provide some billing and moving details",
            titleEs: "Provide some billing and moving details"
        },
        {
            step: 2,
            labelEn: "About you",
            labelEs: "About you",
            labelMobileEn: "About you",
            labelMobileEs: "About you",
            titleEn: "Tell us about yourself",
            titleEs: "Tell us about yourself"
        },
        {
            step: 3,
            labelEn: "Review",
            labelEs: "Review",
            labelMobileEn: "Review",
            labelMobileEs: "Review",
            titleEn: "Let's make sure everything is right",
            titleEs: "Let's make sure everything is right"
        }
    ]
}