const landlordConstants = {
    GET_LANDLORD_AGREEMENTS_REQUEST: 'GET_LANDLORD_AGREEMENTS_REQUEST',
    GET_LANDLORD_AGREEMENTS_SUCCESS: 'GET_LANDLORD_AGREEMENTS_SUCCESS',
    GET_LANDLORD_AGREEMENTS_FAILURE: 'GET_LANDLORD_AGREEMENTS_FAILURE',

    ADD_LANDLORD_AGREEMENT_REQUEST: 'ADD_LANDLORD_AGREEMENT_REQUEST',
    ADD_LANDLORD_AGREEMENT_SUCCESS: 'ADD_LANDLORD_AGREEMENT_SUCCESS',
    ADD_LANDLORD_AGREEMENT_FAILURE: 'ADD_LANDLORD_AGREEMENT_FAILURE',

    DELETE_LANDLORD_AGREEMENT_REQUEST: 'DELETE_LANDLORD_AGREEMENT_REQUEST',
    DELETE_LANDLORD_AGREEMENT_SUCCESS: 'DELETE_LANDLORD_AGREEMENT_SUCCESS',
    DELETE_LANDLORD_AGREEMENT_FAILURE: 'DELETE_LANDLORD_AGREEMENT_FAILURE',

    GET_LANDLORD_ACCOUNT_LIST_REQUEST: 'GET_LANDLORD_ACCOUNT_LIST_REQUEST',
    GET_LANDLORD_ACCOUNT_LIST_SUCCESS: 'GET_LANDLORD_ACCOUNT_LIST_SUCCESS',
    GET_LANDLORD_ACCOUNT_LIST_FAILURE: 'GET_LANDLORD_ACCOUNT_LIST_FAILURE',

    CHANGE_SELECTED_LANDLORD_AGREEMENT: 'CHANGE_SELECTED_LANDLORD_AGREEMENT',

    GET_LANDLORD_BILL_INFO_REQUEST: 'GET_LANDLORD_BILL_INFO_REQUEST',
    GET_LANDLORD_BILL_INFO_SUCCESS: 'GET_LANDLORD_BILL_INFO_SUCCESS',
    GET_LANDLORD_BILL_INFO_FAILURE: 'GET_LANDLORD_BILL_INFO_FAILURE',

    GET_LANDLORD_MOST_RECENT_PAYMENTS_REQUEST: 'GET_LANDLORD_MOST_RECENT_PAYMENTS_REQUEST',
    GET_LANDLORD_MOST_RECENT_PAYMENTS_SUCCESS: 'GET_LANDLORD_MOST_RECENT_PAYMENTS_SUCCESS',
    GET_LANDLORD_MOST_RECENT_PAYMENTS_FAILURE: 'GET_LANDLORD_MOST_RECENT_PAYMENTS_FAILURE',
}

export default landlordConstants