import React from 'react'
import PropTypes from 'prop-types'

import CircularProgress from '@mui/material/CircularProgress'

import PowerWaterTabs from './power-water-tabs'

import { myAccountConstants } from '../../../constants/myaccount-constants'

import SRPAccountNumberField from './input-fields/srp-account-number-field'
import BankFirstNameField from './input-fields/bank-first-name-field'
import BankLastNameField from './input-fields/bank-last-name-field'
import BankPhoneNumberField from './input-fields/bank-phone-number-field'
import BankEmailAddressField from './input-fields/bank-email-address-field'
import BankRoutingNumberField from './input-fields/bank-routing-number-field'
import BankAccountNumberField from './input-fields/bank-account-number-field'
import PaymentAmountField from './input-fields/payment-amount-field'
import PaymentDateField from './input-fields/payment-date-field'

import BankRoutingNumberAccountNumberInfoIcon from '../../common_payment/bank-routing-number-account-number-info-icon'

const SinglePaymentForm = ({ values, errors, touched, handleChange, handleBlur, handleSubmit,
    isSubmitting, status, formDataChanged, submitForm, setPayMultipleAccountsButtonClicked,
    clearPayMultipleAccountsButtonClicked, setFieldValue, setFieldTouched, t, i18n }) => {
    let commonFormikProps = {
        onChange: (e) => { handleChange(e); formDataChanged(e); },
        onBlur: handleBlur,
        errors,
        touched
    }
    let commonTextFieldProps = {
        fullWidth: true
    }

    const verifyStatus = status || {}

    return (
        <div>
        <label className="h4 text-muted">{t("Account type & number")}</label>

        {verifyStatus.hasUnhandledeChexValidationError ? (
            <div className="srp-alert-error mb-3">
                {t("unable_to_complete",
                    {phone: i18n.language==='es' ? myAccountConstants.SPANISH_CUSTOMER_SERVICE_PHONE_NUMBER_FMTTD : myAccountConstants.RESIDENTIAL_CUSTOMER_SERVICE_PHONE_NUMBER_FMTTD})}
            </div>
        ) : null}

        <div className="my-3">
            <PowerWaterTabs t={t} i18n={i18n} />
        </div>

        <form method="POST" onSubmit={handleSubmit}>
            <div className="mt-1"><SRPAccountNumberField autoFocus={true} t={t} value={values.srpAccountNumber} {...commonFormikProps} {...commonTextFieldProps} /></div>

            <label className="h4 text-muted mt-4">{t("Bank information")}</label>

            <div className="mt-1"><BankFirstNameField t={t} value={values.bankFirstName} {...commonFormikProps} {...commonTextFieldProps} /></div>
            <div className="mt-3"><BankLastNameField t={t} value={values.bankLastName} {...commonFormikProps} {...commonTextFieldProps} /></div>
            <div className="mt-3"><BankPhoneNumberField t={t} value={values.bankPhoneNumber} {...commonFormikProps} {...commonTextFieldProps} /></div>
            <div className="mt-3"><BankEmailAddressField t={t} value={values.bankEmailAddress} {...commonFormikProps} {...commonTextFieldProps} /></div>
            
            <div className="mt-3 position-relative">
                <BankRoutingNumberField t={t} value={values.bankRoutingNumber} {...commonFormikProps} {...commonTextFieldProps}
                    endAdornment={
                        <BankRoutingNumberAccountNumberInfoIcon
                            showRoutingNumberDescription={true}
                            showBankAccountNumberDescription={false}
                            className="d-lg-none" />
                    }
                />
            </div>

            <div className="mt-3 position-relative">
                <BankAccountNumberField t={t} value={values.bankAccountNumberLeadingZeroesAreSignificant} {...commonFormikProps} {...commonTextFieldProps}
                    endAdornment={
                        <BankRoutingNumberAccountNumberInfoIcon
                            showRoutingNumberDescription={false}
                            showBankAccountNumberDescription={true}
                            className="d-lg-none" />
                    }
                />
            </div>

            <label className="h4 text-muted mt-4">{t("Payment amount & date")}</label>

            <div className="mt-1"><PaymentAmountField t={t} value={values.paymentAmount} {...commonFormikProps} {...commonTextFieldProps} setFieldValue={setFieldValue} setFieldTouched={setFieldTouched} /></div>
            <div className="mt-3"><PaymentDateField t={t} value={values.paymentDate} {...commonFormikProps} {...commonTextFieldProps} /></div>

            <div className="d-flex justify-content-end mt-4 flex-wrap">
                <button className="btn srp-btn btn-lightblue mt-2" type="button" disabled={isSubmitting}
                    onClick={() => {setPayMultipleAccountsButtonClicked(); verifyStatus.hasUnhandledeChexValidationError = false; submitForm()}}>{t("Pay multiple accounts")}
                </button>
                <button className="btn srp-btn btn-blue mt-2 ml-2" type="submit" disabled={isSubmitting} onClick={() => {clearPayMultipleAccountsButtonClicked(); verifyStatus.hasUnhandledeChexValidationError = false}}>
                    {isSubmitting ? <CircularProgress size={20} thickness={5} style={{color:'white'}} /> : t("Confirm payment")}
                </button>
            </div>
        </form>
        </div>
    )
}

SinglePaymentForm.propTypes = {
    values: PropTypes.shape({
        srpAccountNumber: PropTypes.string.isRequired,
        bankFirstName: PropTypes.string.isRequired,
        bankLastName: PropTypes.string.isRequired,
        bankPhoneNumber: PropTypes.string.isRequired,
        bankEmailAddress: PropTypes.string.isRequired,
        bankRoutingNumber: PropTypes.string.isRequired,
        bankAccountNumberLeadingZeroesAreSignificant: PropTypes.string.isRequired,
        paymentAmount: PropTypes.string.isRequired,
        paymentDate: PropTypes.string.isRequired
    }).isRequired,
    errors: PropTypes.shape({
        srpAccountNumber: PropTypes.oneOfType([
            PropTypes.string.isRequired,
            PropTypes.shape({
                getLocalizedErrMsg: PropTypes.func.isRequired,
            }),
        ]),
        bankFirstName: PropTypes.string,
        bankLastName: PropTypes.string,
        bankPhoneNumber: PropTypes.string,
        bankEmailAddress: PropTypes.string,
        bankRoutingNumber: PropTypes.string,
        bankAccountNumberLeadingZeroesAreSignificant: PropTypes.string,
        paymentAmount: PropTypes.oneOfType([
            PropTypes.string.isRequired,
            PropTypes.shape({
                getLocalizedErrMsg: PropTypes.func.isRequired,
            }),
        ]),
        paymentDate: PropTypes.string
    }).isRequired,
    touched: PropTypes.shape({
        srpAccountNumber: PropTypes.bool,
        bankFirstName: PropTypes.bool,
        bankLastName: PropTypes.bool,
        bankPhoneNumber: PropTypes.bool,
        bankEmailAddress: PropTypes.bool,
        bankRoutingNumber: PropTypes.bool,
        bankAccountNumberLeadingZeroesAreSignificant: PropTypes.bool,
        paymentAmount: PropTypes.bool,
        paymentDate: PropTypes.bool
    }).isRequired,
    handleChange: PropTypes.func.isRequired,
    handleBlur: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
    status: PropTypes.shape({
        hasUnhandledeChexValidationError: PropTypes.bool
    }),
    t:  PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired,
    formDataChanged: PropTypes.func.isRequired,
    setPayMultipleAccountsButtonClicked: PropTypes.func.isRequired,
    clearPayMultipleAccountsButtonClicked: PropTypes.func.isRequired,
    submitForm: PropTypes.func.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    setFieldTouched: PropTypes.func.isRequired
}

export default SinglePaymentForm