import React from 'react'
import PropTypes from 'prop-types'

import { DateTime } from 'luxon'

import format from '../../../srp_modules/format'

import { PurchaseDetailsStyledDiv, classes } from './purchase-details-styles'

const PurchaseDetailsLine = ({ srpAccountNumber, purchaseAmount, purchaseDate }) => {
    const billAccountDisplay = format.formatBillAccountNumber(srpAccountNumber)
    const purchaseAmountDisplay = purchaseAmount.toLocaleString('EN-US',{ style: 'currency', currency: 'USD' })
    const purchaseDateDisplay = DateTime.fromJSDate(purchaseDate).toFormat('M/d/yy')

return (
<PurchaseDetailsStyledDiv>
    <div className="d-none d-lg-block">
    <div className="d-flex flex-row align-items-center mt-4">
        <h6 className={`${classes.column} ${classes.secondaryText} align-self-center mb-0`}>{billAccountDisplay}</h6>
        <h4 className={`${classes.column} ${classes.amount} align-self-center mb-0`}>{purchaseAmountDisplay}</h4>
        <h6 className={`${classes.column} ${classes.secondaryText} align-self-center mb-0`}>{purchaseDateDisplay}</h6>
    </div>
    </div>

    <div className="d-lg-none">
    <div className="d-flex mt-4">
        <h6 className={`${classes.secondaryText} mb-0 w-50`}>{billAccountDisplay}</h6>
        <h6 className={`${classes.secondaryText} mb-0`}>{purchaseDateDisplay}</h6>
    </div>
    <h4 className={`${classes.amount} mb-0`}>{purchaseAmountDisplay}</h4>
    </div>
</PurchaseDetailsStyledDiv>
)
}

PurchaseDetailsLine.propTypes = {
    srpAccountNumber: PropTypes.string.isRequired,
    purchaseAmount: PropTypes.number.isRequired,
    purchaseDate: PropTypes.instanceOf(Date).isRequired
}

export default PurchaseDetailsLine