import React from 'react'
import PropTypes from 'prop-types'

import utils from '../../../srp_modules/utils'


const CommunitySolarDocs = ({ communitySolarLink }) => {

    let commSolarHTML = null

    if (communitySolarLink.url && communitySolarLink.url.length > 0) {
        commSolarHTML =
            (<div style={{ paddingTop: '5px' }}>
                <a href={utils.createUrl(communitySolarLink.url)}><h3>{communitySolarLink.description}</h3></a>
            </div>)
    }

    return (
        <div>
            {commSolarHTML}
        </div>
    )
}

CommunitySolarDocs.propTypes = {
    communitySolarLink: PropTypes.object.isRequired,

    refreshFailedLinks: PropTypes.func
}

export default CommunitySolarDocs