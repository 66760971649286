import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { CircularProgress } from '@mui/material'
import { addMultipleBillAccounts, getBillAccounts } from '../../../../actions/auth/bill_account/bill-account-actions'
import { addSnackbarMessage } from '../../../../actions/common/snackbar'

const RestoreHiddenAccountsButtons = ({selectedUnlinkedBillAccounts, addUnlinkedBillAccounts, hideForm, t}) => {
    let isAccountSelected = false
    for (let key in selectedUnlinkedBillAccounts) {
        isAccountSelected = selectedUnlinkedBillAccounts[key] !== undefined
        break
    }

    const [isSubmitting, setSubmitting] = React.useState(false);

    let handleSubmit = async (event) => {
        event.persist()
        event.preventDefault()
        await setSubmitting(true)
        await addUnlinkedBillAccounts(selectedUnlinkedBillAccounts, hideForm)
    }

    return (
        <form onSubmit={handleSubmit}>
            <button type="button" disabled={isSubmitting} className="btn srp-btn btn-lightblue mt-2 ml-0" onClick={hideForm}>{t("Cancel")}</button>
            <button type="submit" disabled={isSubmitting || !isAccountSelected} className="btn srp-btn btn-green mt-2 ml-2">
                {isSubmitting ? <CircularProgress size={20} thickness={5} color="secondary" /> : t("Add accounts")}
            </button>
        </form>
    )
}

const mapStateToProps = state => {
    return {
        selectedUnlinkedBillAccounts: state.accountInfo.billAccount.selectedUnlinkedBillAccounts
    }
}

const mapDispatchToProps = dispatch => {
    return {
        addUnlinkedBillAccounts: async (selectedUnlinkedBillAccounts, hideForm) => {
            let accountsToAdd = []
            for (let key in selectedUnlinkedBillAccounts) {
                let selectedAccount = selectedUnlinkedBillAccounts[key]
                accountsToAdd.push({ billAccount: selectedAccount.account, nickname: selectedAccount.accountName })
            }

            await dispatch(addMultipleBillAccounts(accountsToAdd))
            dispatch(getBillAccounts())
            hideForm()

            dispatch(addSnackbarMessage(<span>Accounts added</span>))
        }
    }
}

RestoreHiddenAccountsButtons.propTypes = {
    addUnlinkedBillAccounts: PropTypes.func.isRequired,
    hideForm: PropTypes.func.isRequired,
    selectedUnlinkedBillAccounts: PropTypes.object.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(RestoreHiddenAccountsButtons)