import {rateMetaDataConstants} from '../../../constants/rate-meta-data-constants'
import { RSAA } from 'redux-api-middleware'
import config from 'my-account-config'

export const getRateMetaData = (billAccount) =>{
    return dispatch => {
        return dispatch({
            [RSAA]: {
                endpoint: config.apiBaseUrl + 'api/accounts/getratemetadata?billAccount=' + encodeURIComponent(billAccount),
                method: 'GET',
                credentials: 'include',
                types: [
                    rateMetaDataConstants.GET_RATE_META_DATA_REQUEST,
                    rateMetaDataConstants.GET_RATE_META_DATA_SUCCESS,
                    rateMetaDataConstants.GET_RATE_META_DATA_FAILURE
                ]
            }
        })
    }
}

