/* eslint react/no-danger: 0 */
import React from 'react'
import cookies from 'js-cookie'

function exitMaintenanceTest() {
    cookies.remove('maintenancetest')
    location = location.origin + location.pathname    
}
const MaintenanceTestBanner = () => {
    return (
        <div className="d-print-none">
            <div className="srp-alert-error ml-2 mr-2 mt-2">
                <strong>You are in maintenance testing mode. Not all features may function properly.</strong> To exit maintenance testing mode, close all browser windows or click <a href="#" onClick={exitMaintenanceTest}>here.</a>
            </div>
        </div>
    )
}

export default MaintenanceTestBanner