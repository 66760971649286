import { RSAA } from 'redux-api-middleware'
import { budgetBillingConstants } from '../../../constants/budget-billing-constants'
import utils from '../../../srp_modules/utils'

export const setActiveStep = (activeStep) => ({
    type: budgetBillingConstants.SET_ACTIVE_STEP,
    activeStep
})

export const setAccountDetails = (accountDetails) => ({
    type: budgetBillingConstants.SET_ACCOUNT_DETAILS,
    accountDetails
})

export const setSquareFootage = (squareFootage) => ({
    type: budgetBillingConstants.SET_SQUARE_FOOTAGE,
    squareFootage
})

export const setElectricType = (electricType) => ({
    type: budgetBillingConstants.SET_ELECTRIC_TYPE,
    electricType
})

export const selectPool = (hasPool) => ({
    type: budgetBillingConstants.SELECT_POOL,
    hasPool
})

export const selectEvapCooler = (hasEvapCooler) => ({
    type: budgetBillingConstants.SELECT_EVAP_COOLER,
    hasEvapCooler
})

export const submitBudgetBilling = budgetBillingForm => dispatch => {
    return dispatch({
        [RSAA]: {
            endpoint: utils.createUrl('accounts/submitbudgetbilling'),
            body: JSON.stringify(budgetBillingForm),
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            types: [
                budgetBillingConstants.SUBMIT_BUDGET_BILLING_REQUEST,
                budgetBillingConstants.SUBMIT_BUDGET_BILLING_SUCCESS,
                budgetBillingConstants.SUBMIT_BUDGET_BILLING_FAILURE
            ]
        }
    })
}