import React from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import HowToCashPaymentCard from './how-to-cash-payment'
import CircularProgress from '@mui/material/CircularProgress'
import config from 'my-account-config'

import HelpFindPayLocationCard from '../../cash_card_page/common_payment_page/help-find-pay-location-card'

const loadingStyle = {
    marginLeft: "45%",
    marginTop: "20%",
    marginBottom: "20%"
}

const loadingStyleResp = {
    marginLeft: "40%",
    marginTop: "20%",
    marginBottom: "20%"
}

const PayByCash = ({ billAccountList, isMpower, t }) => {
    const [disable, setDisable] = React.useState(true);
    const selectOnChange = (event) => {
        event.target.value === 0 
            ? setDisable(true)
            : setDisable(false)
      };
    let eligibleAccounts = billAccountList ? billAccountList.filter((billAccount) => { return billAccount.isBarcodeEligible === true }) : []
    let ineligibleAccounts = billAccountList ? billAccountList.filter((billAccount) => { return billAccount.isBarcodeEligible === false }) : []
    if (!billAccountList || billAccountList.length < 1) {
        return getLoadingCard()
    }
    return (
        <div>

            <div className="container pl-0 pt-0 mt-4">

                <div className="row">
                    <div className="col-lg-6 col-12 pr-lg-1">
                        <div>
                            <label className="h5">{t("My cash payment")}</label>
                        </div>
                        <div className="srp-card-body">
                            <div className="srp-card-details">
                                <FormControl>
                                <InputLabel id="selectAccountLabel">{t("Select account*")}</InputLabel>
                                <Select native
                                        label={t('Select account*')} labelId="selectAccountLabel"
                                        defaultValue="" id="grouped-native-select"
                                        onChange={selectOnChange} className="mt-0 pr-2"
                                >
                                    <option disabled value="" />
                                    <optgroup label={t("Eligible accounts")}>
                                        {eligibleAccounts && eligibleAccounts.length > 0 && eligibleAccounts.map((e) => {
                                            return <option key={e.account} value={e.account}>{formatName(e.accountName, e.accountText)}</option>

                                        })}
                                    </optgroup>
                                    <optgroup />
                                    <optgroup label={t("Ineligible accounts")}>
                                        {ineligibleAccounts && ineligibleAccounts.length > 0 && ineligibleAccounts.map((e) => {
                                            return <option key={e.account} value={e.account} disabled>{formatName(e.accountName, e.accountText)}</option>

                                        })}
                                    </optgroup>
                                </Select>
                                </FormControl>

                                <div className="d-flex flex-wrap justify-content-start mb-2">
                                    <div className="mt-2"><button disabled={disable} onClick={() => { openCashCard("/myaccount/cashpaymentcard", false) }} className="btn srp-btn btn-blue mr-1" >{t("View my cash payment card")}</button></div>
                                    <div className="mt-2"><button disabled={disable} onClick={() => { openCashCard("/myaccount/cashpaymentcard", true) }} className="btn srp-btn btn-lightblue">{t("Print my cash payment card")}</button></div>
                                </div>
                                <div className="text-muted mb-5">{t("some_ineligible_accounts")}</div>
                                
                                <HowToCashPaymentCard isMpower={isMpower}/>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-12 pl-lg-4 pl-3 pr-0">
                        <HelpFindPayLocationCard />
                    </div>
                </div>

            </div>
        </div>
    )
    function getLoadingCard() {
        return (
            <div>
                <label className="h5 mt-4">{t("My cash payment")}</label>
                <div className="col-lg-6 col-12 pl-0">
                    <div className="srp-card-body">
                        <div className="srp-card-details pr-lg-1">
                            <div className="d-none d-lg-block">
                                <CircularProgress size={80} thickness={5} style={loadingStyle} />
                            </div>
                            <div className="d-block d-lg-none">
                                <CircularProgress size={80} thickness={5} style={loadingStyleResp} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    function formatName(accountName, accountNumber) {
        return accountName ? accountName + ", " + accountNumber : accountNumber
    }
    function buildUrl(path, billAccount, shouldPrint) {
        let cashCardUrl = config.appPath !== '/' ? '..' + config.appPath + path : path
        return shouldPrint ? cashCardUrl + '?billAccount=' + billAccount + "&print=true" : cashCardUrl + '?billAccount=' + billAccount
    }
    function getSelectedOption(id) {
        let e = document.getElementById(id)
        return e.value
    }
    function openCashCard(path, shouldPrint) {
        window.open(buildUrl(path, getSelectedOption("grouped-native-select"), shouldPrint))
    }

}
PayByCash.propTypes = {
    billAccountList: PropTypes.array,
    t: PropTypes.func.isRequired,
    i18n: PropTypes.object.isRequired,
    isMpower: PropTypes.bool
}
export default withTranslation("multiPayment")(PayByCash)