import React from 'react'
import PropTypes from 'prop-types'

import Card from "@mui/material/Card"
import { NavLink } from 'react-router-dom'

import '../../styles/autopay-page.css'
import AccountInfoBarContainer from '../myaccount_header/info_bar/account-info-bar-container'
import AutopayStep from '../common_autopay/autopay-step'
import AutopaySrpAcctInfo from '../common_autopay/autopay-srp-acct-info'
import CallAnytime from '../myaccount_header/call-anytime'
import CardLoading from '../../components/dashboard_page/card-loading'
import * as loadingStatus from '../../constants/loading-status-constants'

import { myAccountConstants } from '../../constants/myaccount-constants'

const AutopayIneligiblePage = ({billAccount, customerName, serviceAddress, autopayInfoStatus, selectBillAccountStatus, isResidential, t, i18n}) => {
    const lobName = t(isResidential ? "Residential" : "Commercial")

    const showSpanish = i18n.language === "es"
    const supportPhoneNumber = isResidential ?
        (showSpanish ?
            myAccountConstants.SPANISH_CUSTOMER_SERVICE_PHONE_NUMBER_FMTTD :
            myAccountConstants.RESIDENTIAL_CUSTOMER_SERVICE_PHONE_NUMBER_FMTTD) :
        myAccountConstants.COMMERCIAL_CUSTOMER_SERVICE_PHONE_NUMBER_FMTTD

    return (
        <div>
            <div className="container py-3 hidden-sm-down">
                <CallAnytime />

                <h3 className="mt-3">{t("Sign up for SurePay")}</h3>

                <AccountInfoBarContainer disableSelector={false} billAccount={billAccount} />

                <div className="row">
                    <div className="col-lg-6 mt-3">
                        <div>
                            <label className="h5">{t("We'll need some information first")}</label>
                            { autopayInfoStatus !== loadingStatus.LOADING_STATUS_SUCCESS
                                && autopayInfoStatus !== loadingStatus.LOADING_STATUS_FAILURE
                                && selectBillAccountStatus !== loadingStatus.LOADING_STATUS_SUCCESS
                                && selectBillAccountStatus !== loadingStatus.LOADING_STATUS_FAILURE
                                ? (<CardLoading title={t("SurePay")} />)
                                : (<Card className="p-4">
                                    <AutopayStep activeStep={1} t={t} />

                                    <AutopaySrpAcctInfo
                                        billAccount={billAccount}
                                        customerName={customerName}
                                        serviceAddress={serviceAddress}
                                        t={t}
                                    />

                                    <div className="srp-alert-error">
                                        {t("This_account_is_not_eligible_for_SurePay", {
                                            lobName, supportPhoneNumber
                                        })}
                                    </div>
                                    <div className="d-flex flex-row-reverse mt-4">
                                        <NavLink exact to="/myaccount/dashboard">
                                            <div className="btn srp-btn btn-lightblue ml-2">{t("Back to dashboard")}</div>
                                        </NavLink>
                                    </div>
                                </Card>)
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

AutopayIneligiblePage.propTypes = {
    billAccount: PropTypes.number.isRequired,
    customerName: PropTypes.string.isRequired,
    serviceAddress: PropTypes.shape({
        streetAddress: PropTypes.string.isRequired,
        cityStateZip: PropTypes.string.isRequired
    }).isRequired,
    autopayInfoStatus: PropTypes.string.isRequired,
    selectBillAccountStatus: PropTypes.string.isRequired,
    isResidential: PropTypes.bool.isRequired,
    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired,
}

export default AutopayIneligiblePage
