import React from 'react'
import PropTypes from 'prop-types'

import SelectABankAccount from '../input-fields/select-a-bank-account'

import BankFirstNameField from '../input-fields/bank-first-name-field'
import BankLastNameField from '../input-fields/bank-last-name-field'
import BankPhoneNumberField from '../input-fields/bank-phone-number-field'
import BankEmailAddressField from '../input-fields/bank-email-address-field'
import BankRoutingNumberField from '../input-fields/bank-routing-number-field'
import BankAccountNumberField from '../input-fields/bank-account-number-field'
import BankNicknameField from '../input-fields/bank-nickname-field'

import BankRoutingNumberAccountNumberInfoIcon from '../../../common_payment/bank-routing-number-account-number-info-icon'

class BankInfoForm extends React.Component {
    constructor (props) {
        super(props)

        this.props.bankInfoFormRefs(this.validateBankInfoForm.bind(this), this.props.bankValidationSchema, this.storeBankInfoAndSelectedBankIdInRedux.bind(this))
    }

    async validateBankInfoForm() {
        const errors = await this.props.validateForm()
        const valid = (Object.keys(errors).length === 0 || errors.constructor !== Object)
        if (!valid)
            this.props.submitForm() // show validation errors
        return valid
    }

    storeBankInfoAndSelectedBankIdInRedux() {
        return this.props.storeBankInfoAndSelectedBankId(this.props.values)
    }

    render() {
        let { values, errors, touched, handleChange, handleBlur,
                handleSubmit, verifyingPaymentList, bankAccountList,
                addBankAccountButtonClicked, addBankCancelButtonClicked,
                setFieldValue, bankFormOpen, formDataChanged,
                t, i18n } = this.props
        let commonFormikProps = {
            onChange: (e) => {handleChange(e); formDataChanged(e);},
            onBlur: handleBlur,
            errors,
            touched
        }

        let commonTextFieldProps = {
            fullWidth: true
        }

        return (
            <form method="POST" onSubmit={handleSubmit}>
            {bankFormOpen === false ?
            <SelectABankAccount
                bankAccountIdSelected={values.bankAccountIdSelected}
                bankAccountList={bankAccountList}
                addBankAccountButtonClicked={addBankAccountButtonClicked}
                setFieldValue={setFieldValue}
                isSubmitting={verifyingPaymentList}
                t={t}
                {...commonFormikProps}
            />
            :
            <div className="mb-4">
                <div className="mt-1"><BankFirstNameField value={values.bankFirstName} {...commonFormikProps} {...commonTextFieldProps} t={t} /></div>
                <div className="mt-3"><BankLastNameField value={values.bankLastName} {...commonFormikProps} {...commonTextFieldProps} t={t} /></div>
                <div className="mt-3"><BankPhoneNumberField value={values.bankPhoneNumber} {...commonFormikProps} {...commonTextFieldProps} t={t} /></div>
                <div className="mt-3"><BankEmailAddressField value={values.bankEmailAddress} {...commonFormikProps} {...commonTextFieldProps} t={t} /></div>
                
                <div className="mt-3 position-relative">
                    <BankRoutingNumberField value={values.bankRoutingNumber} {...commonFormikProps} {...commonTextFieldProps} t={t}
                        endAdornment={
                            <BankRoutingNumberAccountNumberInfoIcon
                                showRoutingNumberDescription={true}
                                showBankAccountNumberDescription={false}
                                className="d-lg-none" />
                        }
                    />
                </div>

                <div className="mt-3 position-relative">
                    <BankAccountNumberField value={values.bankAccountNumberLeadingZeroesAreSignificant} {...commonFormikProps} {...commonTextFieldProps} t={t}
                        endAdornment={
                            <BankRoutingNumberAccountNumberInfoIcon
                                showRoutingNumberDescription={false}
                                showBankAccountNumberDescription={true}
                                className="d-lg-none" />
                        }
                    />
                </div>

                <div className="mt-3"><BankNicknameField value={values.bankNickname} {...commonFormikProps} {...commonTextFieldProps} t={t} /></div>

                <div className="d-flex justify-content-end flex-wrap mt-4">
                    <button className="btn srp-btn btn-lightblue mr-2" type="button" onClick={() => addBankCancelButtonClicked(setFieldValue)} disabled={verifyingPaymentList}>{t("Cancel")}</button>
                </div>
            </div>
            }
            </form>
        )
    }
}

BankInfoForm.propTypes = {
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired,
    t: PropTypes.func.isRequired,

    values: PropTypes.shape({
        bankAccountIdSelected: PropTypes.number.isRequired,
        bankFirstName: PropTypes.string.isRequired,
        bankLastName: PropTypes.string.isRequired,
        bankPhoneNumber: PropTypes.string.isRequired,
        bankEmailAddress: PropTypes.string.isRequired,
        bankRoutingNumber: PropTypes.string.isRequired,
        bankAccountNumberLeadingZeroesAreSignificant: PropTypes.string.isRequired,
        bankNickname: PropTypes.string.isRequired
    }).isRequired,
    errors: PropTypes.shape({
        bankAccountIdSelected: PropTypes.string,
        bankFirstName: PropTypes.string,
        bankLastName: PropTypes.string,
        bankPhoneNumber: PropTypes.string,
        bankEmailAddress: PropTypes.string,
        bankRoutingNumber: PropTypes.string,
        bankAccountNumberLeadingZeroesAreSignificant: PropTypes.string,
        bankNickname: PropTypes.string
    }).isRequired,
    touched: PropTypes.shape({
        bankAccountIdSelected: PropTypes.bool,
        bankFirstName: PropTypes.bool,
        bankLastName: PropTypes.bool,
        bankPhoneNumber: PropTypes.bool,
        bankEmailAddress: PropTypes.bool,
        bankRoutingNumber: PropTypes.bool,
        bankAccountNumberLeadingZeroesAreSignificant: PropTypes.bool,
        bankNickname: PropTypes.bool
    }).isRequired,
    handleChange: PropTypes.func.isRequired,
    handleBlur: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    validateForm: PropTypes.func.isRequired,
    submitForm: PropTypes.func.isRequired,
    verifyingPaymentList: PropTypes.bool.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    addBankAccountButtonClicked: PropTypes.func.isRequired,
    addBankCancelButtonClicked: PropTypes.func.isRequired,
    bankFormOpen: PropTypes.bool.isRequired,
    bankAccountList: PropTypes.arrayOf(PropTypes.shape({
        bankId: PropTypes.number.isRequired,
        bankName: PropTypes.string.isRequired,
        nickName: PropTypes.string.isRequired,
        accountNumber: PropTypes.string.isRequired
    })).isRequired,
    bankInfoFormRefs: PropTypes.func.isRequired,
    bankValidationSchema: PropTypes.object.isRequired,
    storeBankInfoAndSelectedBankId: PropTypes.func.isRequired,
    formDataChanged: PropTypes.func.isRequired,
}

export default BankInfoForm