export const giftPaymentMultiConstants = {           
    VERIFY_GIFT_MULTIPLE_REQUEST: 'VERIFY_GIFT_MULTIPLE_REQUEST',
    VERIFY_GIFT_MULTIPLE_SUCCESS: 'VERIFY_GIFT_MULTIPLE_SUCCESS',
    VERIFY_GIFT_MULTIPLE_FAILURE: 'VERIFY_GIFT_MULTIPLE_FAILURE',

    SUBMIT_GIFT_MULTIPLE_REQUEST: 'SUBMIT_GIFT_MULTIPLE_REQUEST',
    SUBMIT_GIFT_MULTIPLE_SUCCESS: 'SUBMIT_GIFT_MULTIPLE_SUCCESS',
    SUBMIT_GIFT_MULTIPLE_FAILURE: 'SUBMIT_GIFT_MULTIPLE_FAILURE',

    GIFT_PAYMENT_MULTI_POPULATE_PAYMENT: 'GIFT_PAYMENT_MULTI_POPULATE_PAYMENT',
    GIFT_PAYMENT_MULTI_POPULATE_PAYMENTS: 'GIFT_PAYMENT_MULTI_POPULATE_PAYMENTS',
    GIFT_PAYMENT_MULTI_CLEAR_PAYMENTS: 'GIFT_PAYMENT_MULTI_CLEAR_PAYMENTS',

    GIFT_PAYMENT_MULTI_ADD_BANK_INFO: 'GIFT_PAYMENT_MULTI_ADD_BANK_INFO'
    
}