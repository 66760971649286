import React from 'react'

import CircularProgress from '@mui/material/CircularProgress'

const SinglePaymentLoadingProgress = () => (
    <div className="d-flex justify-content-center">
        <CircularProgress size={80} thickness={5} />
    </div>
)

export default SinglePaymentLoadingProgress