import { RSAA } from 'redux-api-middleware'
import { SKIP_BILL_ACCOUNT_CHECK } from '../../../srp_modules/middleware/bill-account-middleware'
import landlordConstants from '../../../constants/landlord-constants'
import utils from '../../../srp_modules/utils'

export const getLandlordAgreements = () => dispatch => {
    return dispatch({
        [RSAA]: {
            endpoint: utils.createUrl('landlord/list'),
            method: 'GET',
            credentials: 'include',
            types: [
                landlordConstants.GET_LANDLORD_AGREEMENTS_REQUEST,
                landlordConstants.GET_LANDLORD_AGREEMENTS_SUCCESS,
                landlordConstants.GET_LANDLORD_AGREEMENTS_FAILURE
            ]
        },
        [SKIP_BILL_ACCOUNT_CHECK]: true
    })
}

export const addLandlordAgreement = (landlordAgreement, phoneNumber) => dispatch => {
    return dispatch({
        [RSAA]: {
            endpoint: utils.createUrl('landlord/add', { landlordAgreement, phoneNumber }),
            method: 'POST',
            credentials: 'include',
            types: [
                landlordConstants.ADD_LANDLORD_AGREEMENT_REQUEST,
                landlordConstants.ADD_LANDLORD_AGREEMENT_SUCCESS,
                landlordConstants.ADD_LANDLORD_AGREEMENT_FAILURE
            ]
        },
        [SKIP_BILL_ACCOUNT_CHECK]: true,
        meta: {
            debounce: {
                leading: false,
                trailing: false
            }
        }
    })
}

export const changeSelectedLandlordAgreement = landlordAgreement => {
    return {
        type: landlordConstants.CHANGE_SELECTED_LANDLORD_AGREEMENT,
        selectedLandlordAgreement: landlordAgreement
    }
}

export const deleteLandlordAgreement = (landlordAgreement) => dispatch => {
    return dispatch({
        [RSAA]: {
            endpoint: utils.createUrl('landlord/delete', { landlordAgreement }),
            method: 'DELETE',
            credentials: 'include',
            types: [
                landlordConstants.DELETE_LANDLORD_AGREEMENT_REQUEST,
                landlordConstants.DELETE_LANDLORD_AGREEMENT_SUCCESS,
                landlordConstants.DELETE_LANDLORD_AGREEMENT_FAILURE
            ]
        },
        [SKIP_BILL_ACCOUNT_CHECK]: true
    })
}

export const getLandlordAccounts = () => dispatch => {
    return dispatch({
        [RSAA]: {
            endpoint: utils.createUrl('landlord/accounts/detailedlist'),
            method: 'GET',
            credentials: 'include',
            types: [
                landlordConstants.GET_LANDLORD_ACCOUNT_LIST_REQUEST,
                landlordConstants.GET_LANDLORD_ACCOUNT_LIST_SUCCESS,
                landlordConstants.GET_LANDLORD_ACCOUNT_LIST_FAILURE
            ]
        },
        [SKIP_BILL_ACCOUNT_CHECK]: true
    })
}

export const getLandlordBillInfo = (selectedLandlordAgreement) => (dispatch, getState) => {
    const landlordState = getState().landlord

    let billAccountList = []

    let selectedLandlordDetails = landlordState.landlordAccounts[selectedLandlordAgreement]
    selectedLandlordDetails.landlordProperties.forEach(landlordProperty => {
        const billAccount = landlordProperty.billAccount
        if (billAccount === null)
            return

        let propertyBillInfo = landlordState.landlordBillInfo[billAccount]
        if (propertyBillInfo === undefined) {
            billAccountList.push(billAccount)
        }
    })

    return dispatch({
        [RSAA]: {
            endpoint: utils.createUrl('landlord/accounts/billinfo'),
            method: 'POST',
            body: JSON.stringify(billAccountList),
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include',
            types: [
                landlordConstants.GET_LANDLORD_BILL_INFO_REQUEST,
                landlordConstants.GET_LANDLORD_BILL_INFO_SUCCESS,
                landlordConstants.GET_LANDLORD_BILL_INFO_FAILURE
            ]
        }
    })
}

export const getLandlordMostRecentPayments = (selectedLandlordAgreement) => (dispatch, getState) => {
    const landlordState = getState().landlord

    let billAccountList = []

    let selectedLandlordDetails = landlordState.landlordAccounts[selectedLandlordAgreement]
    selectedLandlordDetails.landlordProperties.forEach(landlordProperty => {
        const billAccount = landlordProperty.billAccount
        if (billAccount === null)
            return

        let propertyMostRecentPayment = landlordState.landlordMostRecentPayments[billAccount]
        if (propertyMostRecentPayment === undefined) {
            billAccountList.push({billAccountKey: billAccount, billAccounts: landlordProperty.billAccounts})
        }
    })

    return dispatch({
        [RSAA]: {
            endpoint: utils.createUrl('landlord/accounts/mostrecentpayments'),
            method: 'POST',
            body: JSON.stringify(billAccountList),
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include',
            types: [
                landlordConstants.GET_LANDLORD_MOST_RECENT_PAYMENTS_REQUEST,
                landlordConstants.GET_LANDLORD_MOST_RECENT_PAYMENTS_SUCCESS,
                landlordConstants.GET_LANDLORD_MOST_RECENT_PAYMENTS_FAILURE
            ]
        }
    })
}