import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import SuccessCheck from '../../../images/common/SuccessCheck.png'

const SolarChoiceSignupConfirmation = ({t}) => {
  return (
    <React.Fragment>
      <div className="srp-card-header">{t("Solar Choice change request submitted")}</div>
      <div className="srp-card-body text-muted">
        <div className="srp-card-details">
          <div className="d-lg-block d-none">
            <div className="mb-3 srp-olive-green d-flex align-items-center">
              <img className="img-fluid mr-3" src={SuccessCheck} style={{ width: '50px' }} />
              <span style={{ fontSize: '1.75rem', fontWeight: '500' }}>{t("You're all set!")}</span>
            </div>
          </div>
          <div className="d-lg-none d-block">
            <div className="mb-3 srp-olive-green text-center">
              <img className="img-fluid mr-3" src={SuccessCheck} style={{ width: '50px' }} />
              <div style={{ fontSize: '1.75rem', fontWeight: '500' }}>{t("You're all set!")}</div>
            </div>
          </div>
          <div className="mb-1">
            <h4>{t("We've received your request")}</h4>
          </div>
          <div className="mb-4">
            {t("Thanks for enrolling in SRP Solar Choice! You should be receiving a confirmation email from us shortly.")}
          </div>
          <div className="d-flex flex-wrap justify-content-end">
            <Link to="/myaccount/dashboard" className="btn srp-btn btn-lightblue ml-2">{t("Go to dashboard")}</Link>
          </div>
        </div>
      </div>
    </React.Fragment >
  )
}

SolarChoiceSignupConfirmation.propTypes = {
  t: PropTypes.func.isRequired,
}

export default SolarChoiceSignupConfirmation