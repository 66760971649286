import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet-async'

export const DocumentTitle = (props) => {
    return (
        <>
            <Helmet>
                <title>{props.title}</title>
            </Helmet>
            {props.children}
        </>
    )
}

DocumentTitle.propTypes = {
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    title: PropTypes.string.isRequired
}