import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { withTranslation } from 'react-i18next'

import MyPricingContainer from './my_pricing/my-pricing-container'
import MyProgramsContainer from './my_programs/my-programs-container'
import EnergySavingsTips from './energy-savings-tips'
import MyAccountMainContainer from '../../containers/my-account-main-container'
import PendingTurnOnCard from '../common_pending_turn_on/pending-turn-on-card'
import ErapStatusCard from '../era_program/erap-status-card'
import { billAccountConstants } from '../../constants/bill-account-constants'
import * as loadingStatus from '../../constants/loading-status-constants'

class PricingAndProgramsContainer extends React.Component {
    render() {
        const t = this.props.t
        const i18n = this.props.i18n
        const isCommercial = this.props.isCommercial

        return (
            <MyAccountMainContainer pageTitle="Price plan & programs">
                {
                    this.props.isPendingTurnOn ?
                        <div className="row">
                            <div className="col-lg-9 col-12">
                                <PendingTurnOnCard colStyle="col-lg-8 col-12" />
                            </div>
                            <div className="col-lg-3 col-12">
                                <div className="srp-card-header mt-2">{t("Energy savings tips")}</div>
                                <EnergySavingsTips isCommercial={isCommercial} t={t} i18n={i18n} />
                            </div>
                        </div> :
                        <div className="row">
                            <div className="col-lg-9 col-12">
                                <div className="col-12 mt-3 pl-0 pr-0">
                                    <div className="srp-card-header">{t("My price plan")}</div>
                                    <MyPricingContainer />
                                </div>
                                <div className="col-12 mt-3 pl-0 pr-0">
                                    <div className="srp-card-header">{t("My programs")}</div>
                                    <MyProgramsContainer />
                                </div>
                                {this.props.isEraProgramEligible
                                && this.props.enrolledProgramsDetailsStatus === loadingStatus.LOADING_STATUS_SUCCESS
                                && (this.props.closedAcctState !== billAccountConstants.CLOSED_STATE.CLOSED
                                    || this.props.azDesPaymentList.length > 0)
                                    ? <div className="col-12 mt-3 pl-0 pr-0">
                                        <ErapStatusCard />
                                    </div>
                                    : null
                                }
                            </div>
                            <div className="col-lg-3 col-12 mt-3">
                                <div className="srp-card-header">{t("Energy savings tips")}</div>
                                <EnergySavingsTips isCommercial={isCommercial} t={t} i18n={i18n} />
                            </div>
                        </div>
                }
            </MyAccountMainContainer>
        )
    }
}

const mapStateToProps = state => {
    return {
        isCommercial:  state.accountInfo.billAccount.selectedBillAccountDetails.isCommercial,
        isPendingTurnOn: state.accountInfo.billAccount.selectedBillAccountDetails.isPendingTurnOn,
        closedAcctState: state.accountInfo.billAccount.selectedBillAccountDetails.closedState,
        isEraProgramEligible: state.accountInfo.payment.enrolledProgramsDetails.eraProgram.isEligible,
        azDesPaymentList: state.accountInfo.payment.enrolledProgramsDetails.eraProgram.azDesPaymentList,
        enrolledProgramsDetailsStatus: state.accountInfo.payment.enrolledProgramsDetailsStatus
    }
}

PricingAndProgramsContainer.propTypes = {
    isCommercial: PropTypes.bool.isRequired,
    isPendingTurnOn: PropTypes.bool.isRequired,
    closedAcctState: PropTypes.number,
    isEraProgramEligible: PropTypes.bool,
    azDesPaymentList: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    enrolledProgramsDetailsStatus: PropTypes.string,

    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired,
}

export default withTranslation('pricePlanAndPrograms')(connect(mapStateToProps)(PricingAndProgramsContainer))