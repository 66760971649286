// eslint mistakenly sees the helper method as a React component
/* eslint react/no-multi-comp: "off" */

import React from 'react'
import PropTypes from 'prop-types'
import { LOADING_STATUS_SUCCESS, LOADING_STATUS_FAILURE } from '../../constants/loading-status-constants'
import { myAccountConstants } from '../../constants/myaccount-constants'
import config from 'my-account-config'
import FooterContainer from '../common_footer/footer-container'

let cardStyle = {
    border: "none",
    backgroundColor: "white",
    boxShadow: "0px 3px 6px rgba(0,0,0,0.2)",
    borderRadius: "2px",
    padding: "10px"
}

function GetDisplayText(error) {
    let defaultError = <div>{`An error occured verifying your email. Please call us at ${myAccountConstants.RESIDENTIAL_CUSTOMER_SERVICE_PHONE_NUMBER_FMTTD}`}</div>

    switch (error) {
       case "userDoesNotExist":
            return <div>This user is not found in our system with this email address.</div>
        case "tokenExpired":
            return <div>The security token in this email has expired.</div>
        case "emailAlreadyConfirmed":
            return <div>This email has already been confirmed. You may log into the mobile app with your credentials.</div>
        case "userAccountDoesNotExist":
        case "invalidBillAccount":
        case "accountPhoneNumberMatch":
        case "waterError":
            return defaultError
        default:
            return defaultError
    }
}

const MobileVerificationPage = ({completeCreateAccountStatus, completeCreateAccountResult}) => (
    <div>
        <div style={{width: "100%", padding: "20px 10px 20px 10px", borderBottom: "solid 5px #004b87", backgroundColor: "white"}}>
            <img 
                src={`${config.srpnetBaseUrl}assets/srpnet/components/SRP-Logo.svg`} 
                style={{ width: "130px", height: "70px"}}
                alt="SRP Logo"
            />
        </div>
        <div style={{backgroundColor: "#ccc", padding: "20px 16px 20px 16px"}}>
            <div style={cardStyle}>
                <div className="srp-card-details" style={{ backgroundColor: "#f1f1ed", fontSize: "16px"}}>
                    {completeCreateAccountStatus === LOADING_STATUS_SUCCESS &&
                    <div>
                        You successfully confirmed your email address.<br />
                        Please log in to your account using your mobile app.
                    </div>}
                    {completeCreateAccountStatus === LOADING_STATUS_FAILURE &&
                        GetDisplayText(completeCreateAccountResult.error)}
                </div>
            </div>
        </div>
        <div id="footer">
            <FooterContainer />
        </div>
    </div>
)

MobileVerificationPage.propTypes = {
    completeCreateAccountStatus: PropTypes.string.isRequired,
    completeCreateAccountResult: PropTypes.object.isRequired
}

export default MobileVerificationPage