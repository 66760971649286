import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import config from 'my-account-config'
import * as loadingConstants from '../constants/loading-status-constants'
import { useLocation, useNavigate } from 'react-router-dom'

const LoginRequiredContainer = (props) => {
    let location = useLocation()
    let navigate = useNavigate()

    React.useEffect(() => {
        if (props.isLoggedIn && props.isIrrigator) {
            window.location = config.waterBaseUrl + 'dashboard'
            return
        }
    })

    React.useEffect(() => {
        if (props.isLoggedIn)
            return

        if (props.loginSubmitStatus !== loadingConstants.LOADING_STATUS_SUCCESS
            && props.loginSubmitStatus !== loadingConstants.LOADING_STATUS_FAILURE) {
            return
        }

        // Per Garrett, redirect unauthenticated /myaccount/payment requests to the payment login form.
        // Use the regular login form to authenticate /myaccount/payment/gift because the gift payment
        // page doesn't look like the payment login form.
        let pathname = location.pathname.toLowerCase()
        let loginPage = pathname.startsWith('/myaccount/payment') && !pathname.startsWith('/myaccount/payment/gift')
                      ? '/myaccount/payment/login' : '/login'

        // No need to redirect if already on login page
        if (loginPage === pathname)
            return

        navigate(loginPage,
            {
                state: {
                    from: location
                },
                replace: true // unless the user manually navigates to the login page, we don't need it in the browser history
            }
        )
    })

    let renderMe = props.loginSubmitStatus === loadingConstants.LOADING_STATUS_SUCCESS
                   && props.isLoggedIn && !props.isIrrigator
    return renderMe ? props.children : null
}

const mapStateToProps = (state) => {
    return {
        isLoggedIn: state.login.isLoggedIn,
        isIrrigator: state.login.isIrrigator,
        loginSubmitStatus: state.login.loginSubmitStatus
    }
}

LoginRequiredContainer.propTypes = {
    isLoggedIn: PropTypes.bool.isRequired,
    isIrrigator: PropTypes.bool.isRequired,
    loginSubmitStatus: PropTypes.string.isRequired,
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
}

export default connect(mapStateToProps)(LoginRequiredContainer)