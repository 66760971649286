import { barCodeConstants } from '../../../constants/bar-code-constants'
import * as loadingStatus from '../../../constants/loading-status-constants'

const initialState ={
    barCodeImage: '',
    barCodeStatus: loadingStatus.LOADING_STATUS_INIT
}

export default function barCodeReducer(state = initialState, action){
    switch(action.type){
        case barCodeConstants.GET_BAR_CODE_REQUEST:
            return{
                ...state,
                barCodeStatus: loadingStatus.LOADING_STATUS_IN_PROGRESS
            }
        case barCodeConstants.GET_BAR_CODE_SUCCESS:
            return {
                ...state,
                barCodeImage: action.payload,
                barCodeStatus: loadingStatus.LOADING_STATUS_SUCCESS
            }
        case barCodeConstants.GET_BAR_CODE_FAILURE:
            return {
                ...state,
                barCodeImage: '',
                barCodeStatus: loadingStatus.LOADING_STATUS_FAILURE
            }
        default:
            return state
    }
}
