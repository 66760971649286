/*eslint no-unused-vars: "warn"*/
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import AutopaySubmittedPage from './autopay-submitted-page'

class AutopaySubmittedPageContainer extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {

        return (
            <AutopaySubmittedPage
                billAccount={this.props.autopayInfoSubmitted.billAccount}
                customerName={this.props.autopayFormData.firstName + ' ' + this.props.autopayFormData.lastName}
                bankName={this.props.bankData.institutionName}
                routingNumber={this.props.autopayFormData.routingNumber}
                bankAccountNumber={this.props.autopayFormData.bankAccountNumber}
                emailAddress={this.props.autopayFormData.emailAddress}
                phoneNumber={this.props.autopayFormData.phoneNumber}
                shareAmount={this.props.autopayFormData.shareAmount}
                isEdit={this.props.updateAutopaySuccess}
                isPendingRelease={this.props.autopayInfoSubmitted.isPendingRelease}
                isResidential={this.props.autopayInfoSubmitted.isResidential}
                showSpanish={this.props.showSpanish}
                autopayInfoStatus={this.props.autopayInfoStatus}
                selectBillAccountStatus={this.props.selectBillAccountStatus}
            />
        )
    }
}

AutopaySubmittedPageContainer.propTypes = {
    autopayInfoSubmitted: PropTypes.shape({
        billAccount: PropTypes.number.isRequired,
        isPendingRelease: PropTypes.bool.isRequired,
        isResidential: PropTypes.bool.isRequired,
    }).isRequired,

    autopayFormData: PropTypes.object.isRequired,

    bankData: PropTypes.object.isRequired,

    enrollAutopaySuccess: PropTypes.bool.isRequired,

    updateAutopaySuccess: PropTypes.bool.isRequired,

    showSpanish: PropTypes.bool.isRequired,

    autopayInfoStatus: PropTypes.string.isRequired,

    selectBillAccountStatus: PropTypes.string.isRequired
}

const mapStateToProps = (state) => {
    return {
        autopayInfoSubmitted: state.accountInfo.autopay.autopayInfoSubmitted,

        autopayFormData: state.accountInfo.autopay.autopayFormData,

        bankData: state.accountInfo.autopay.bankData,

        enrollAutopaySuccess: state.accountInfo.autopay.enrollAutopaySuccess,

        updateAutopaySuccess: state.accountInfo.autopay.updateAutopaySuccess,

        showSpanish: false,

        selectBillAccountStatus: state.accountInfo.billAccount.selectBillAccountStatus,

        autopayInfoStatus: state.accountInfo.autopay.autopayInfoStatus
    }
}

export default connect(mapStateToProps)(AutopaySubmittedPageContainer)
