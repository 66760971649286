import React from 'react'
import PropTypes from 'prop-types'
import UnmeteredCard from './unmetered-card'
import SummaryMasterCard from './summary-master-card'
import UsageChartDailyContainer from './usage-chart-daily-container'
import UsageChartMonthlyContainer from './usage-chart-monthly-container'
import NoMonthlyCard from './no-monthly-card'
import { myAccountConstants } from '../../../constants/myaccount-constants'
import format from '../../../srp_modules/format'
import AppBar from '@mui/material/AppBar'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import { dailyUsageChartTypeConstants } from '../../../constants/daily-usage-chart-type-constants'
import { withTranslation } from 'react-i18next'

const CardUsage = (
    {
        billAccount,
        billProjection,
        isMetered,
        lastUsed,
        isCost,
        getUsageChartTabOnClick,
        wasYesterday,
        isDemand,
        hasDaily,
        hasMonthly,
        demandAmount,
        hasOnPeakDemand,
        hasOnOffShoulder,
        highDemandDate,
        isMPower,
        isTokenlessPrepay,
        lastPurchaseDate,
        mostRecentUsageDate,
        selectedUsageChartTab,
        isCommercial,
        isSummaryMaster,
        setUsageChartType,
        isNetBilled,
        isAverageDemandRate,
        averageDemandAmount,
        hasMonthlyDemandOnly,
        isNonSmartMeterRate,
        t,
        i18n
    }) => {

    return isMetered
        ?
        (
        <div className="srp-card d-inline tooltip-overflow">
            <div className="srp-card-header">
                {t("My usage")}
            </div>
            <div className="srp-card-body">
                <div className="srp-card-summary d-flex justify-content-around">
                    <div className="d-flex flex-column align-items-center">
                        {lastUsed === "--" ?
                        <div className="h4 text-white">
                            {lastUsed}
                        </div>:

                        <div className="h4 text-white">
                            {
                                (isMPower && !isTokenlessPrepay)
                                ? lastUsed > 0
                                        ? <span>{format.formatDollarAmount(parseFloat(lastUsed))}, {lastPurchaseDate}</span>
                                        : format.formatNumber(parseFloat(lastUsed))
                                 : isCost
                                    ? format.formatDollarAmount(parseFloat(lastUsed))
                                    : <span>{format.formatNumber(parseFloat(lastUsed))} kWh</span>
                            }
                            </div>
                        }
                        {isMPower && !isTokenlessPrepay
                            ? t("most recent purchase")
                            : wasYesterday
                                ? t("used yesterday")
                                : t("used") + " " + mostRecentUsageDate}
                    </div>
                    {(!isMPower && (billProjection !== 0)) &&
                    <div className="d-flex flex-column align-items-center">
                        <div className="h4 text-white">
                            {format.formatDollarAmount(billProjection, 0)}
                        </div>
                        {t("projected bill amount")}
                    </div>
                    }
                </div>
                {isDemand && hasDaily && !hasMonthlyDemandOnly &&
                <div className={'high-demand-box'}>
                    <div>{isAverageDemandRate
                        ? <span>{t("Average on-peak demand", {averageDemandAmount : averageDemandAmount})}</span>
                        : hasOnOffShoulder || hasOnPeakDemand
                            ?<span>{t("On-peak demand", { demandAmount: demandAmount, highDemandDate: highDemandDate })}</span>
                            :<span>{t("High demand", { demandAmount: demandAmount, highDemandDate: highDemandDate })}</span>}
                    </div>
                </div>}
                <div className="usage-card-buttons">
                    <div className="d-none d-lg-block ">
                        <div className="d-flex justify-content-end" style={{display: 'flex', flexWrap: 'wrap'}}>
                            {isDemand && !hasMonthlyDemandOnly &&
                               <button className="btn srp-btn btn-lightblue mb-2 " onClick={() => setUsageChartType(dailyUsageChartTypeConstants.DEMAND,1)}>{t("View demand")}</button>
                            }
                            <button className="btn srp-btn btn-lightblue ml-2 mb-2 " onClick={() => viewUsage(isCost,isNetBilled,setUsageChartType,isNonSmartMeterRate)}>{t("View usage")}</button>
                        </div>
                    </div>
                </div>
                {(isMPower && !isTokenlessPrepay)
                ? <div>{hasMonthly
                    ? <UsageChartMonthlyContainer t={t}/>
                    :<div className={"srp-card-details"}>
                        <div className="srp-alert-warn">
                            {t("unable to display", {phoneNumber : myAccountConstants.RESIDENTIAL_CUSTOMER_SERVICE_PHONE_NUMBER_FMTTD})}
                        </div>
                    </div>
                    }</div> :
                <div>

                    <AppBar position="static" style={{ backgroundColor: 'white', borderBottom: "1px solid lightgray" }} elevation={0}>
                        <Tabs
                            value={selectedUsageChartTab}
                            onChange={(evt, value) => getUsageChartTabOnClick(evt, value)}
                            indicatorColor="primary"
                            textColor="primary"
                            style={{ color: 'black' }}
                            variant="fullWidth"
                        >
                            <Tab className="mui_tab" label={t("7 day")} style={{ outline: 'none', boxShadow: 'none', textTransform: 'lowercase' }} />
                            <Tab className="mui_tab" label={t("Monthly")} style={{ outline: 'none', boxShadow: 'none', textTransform: 'capitalize' }} />
                        </Tabs>
                    </AppBar>

                    {selectedUsageChartTab === 0
                        && <div className="srp-card-details">
                            {(hasDaily && !isNonSmartMeterRate)
                            ? <UsageChartDailyContainer isCost={isCost} t={t} i18n={i18n}/>
                            : <div className="srp-alert-warn">
                                {t("unable to display", {phoneNumber : myAccountConstants.RESIDENTIAL_CUSTOMER_SERVICE_PHONE_NUMBER_FMTTD})}
                              </div>}
                            </div>
                    }
                    {selectedUsageChartTab === 1
                        && <div className="srp-card-details">
                            {hasMonthly
                            ? <UsageChartMonthlyContainer billAccount={billAccount} targetId="monthlyChart" t={t}/>
                            : <NoMonthlyCard t={t}/>}
                        </div>
                    }
                </div>
                }
                <div className="d-sm-block d-lg-none srp-card-details" style={{ borderTop: "2px solid #E5E5E5"}}>
                    <div>
                        <div className="d-flex justify-content-end" style={{ display: 'flex', flexWrap: 'wrap' }}>
                        {isDemand && !hasMonthlyDemandOnly &&
                               <button className="btn srp-btn btn-lightblue mb-2 " onClick={() => setUsageChartType(dailyUsageChartTypeConstants.DEMAND, 1)}>{t("View demand")}</button>
                            }
                            <button className="btn srp-btn btn-lightblue ml-2 mb-2 " onClick={() => viewUsage(isCost,isNetBilled,setUsageChartType,isNonSmartMeterRate)}>{t("View usage")}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        )
        : isSummaryMaster
            ? <SummaryMasterCard isCommercial={isCommercial} t={t}/>
            :
            (
            <div>
                <div className="srp-card">
                    <div className="srp-card-header">
                        {t("My usage")}
                    </div>
                    <div className="srp-card-body">
                        <div className="srp-card-details">
                            <UnmeteredCard isCommercial={isCommercial} t={t}/>
                        </div>
                    </div>
                </div>
            </div>
            )
}

CardUsage.propTypes = {
    billAccount: PropTypes.number,
    billProjection: PropTypes.number.isRequired,
    isMetered: PropTypes.bool,
    lastUsed: PropTypes.string,
    isCost: PropTypes.bool,
    getUsageChartTabOnClick: PropTypes.func,
    selectedUsageChartTab: PropTypes.number,
    wasYesterday: PropTypes.bool,
    isDemand: PropTypes.bool,
    hasDaily: PropTypes.bool,
    hasMonthly: PropTypes.bool,
    hasOnOffShoulder: PropTypes.bool,
    demandAmount: PropTypes.number,
    isMPower: PropTypes.bool,
    highDemandDate: PropTypes.string,
    isTokenlessPrepay: PropTypes.bool,
    mostRecentUsageDate: PropTypes.string,
    hasOnPeakDemand: PropTypes.bool,
    lastPurchaseDate: PropTypes.string,
    isCommercial: PropTypes.bool,
    isSummaryMaster: PropTypes.bool,
    setUsageChartType: PropTypes.func,
    isNetBilled: PropTypes.bool,
    isAverageDemandRate: PropTypes.bool,
    averageDemandAmount: PropTypes.number,
    hasMonthlyDemandOnly: PropTypes.bool,
    isNonSmartMeterRate: PropTypes.bool,
    t: PropTypes.func.isRequired,
    i18n: PropTypes.object.isRequired
}

function viewUsage(isCost, isNetBilled, setUsageChartType, isNonSmartMeterRate){
    let beginningTab = isNonSmartMeterRate ? 2 : 1
    isCost
        ? setUsageChartType(dailyUsageChartTypeConstants.COST, beginningTab)
        : isNetBilled
            ? setUsageChartType(dailyUsageChartTypeConstants.NET_ENERGY,beginningTab)
            : setUsageChartType(dailyUsageChartTypeConstants.USAGE,beginningTab)
}

export default withTranslation("usageCard")(CardUsage)