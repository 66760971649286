import i18n, { changeLanguage } from 'i18next'
import { initReactI18next } from 'react-i18next'

import Cookies from 'js-cookie'

// import Backend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'

import config from 'my-account-config'

import { LANG_COOKIE_KEY, LANG_COOKIE_EXPIRATION_DAYS } from './constants/cookie-constants'

/* eslint-disable import/extensions */
// Suppress ESLint warning about unexpected use of file extension .json for translation files.
import commonEn from './locales/en/common.json'
import commonEs from './locales/es/common.json'

import dashboardPageEn from './locales/en/dashboardPage.json'
import dashboardPageEs from './locales/es/dashboardPage.json'

import dashboardMyBillCardEn from './locales/en/dashboardMyBillCard.json'
import dashboardMyBillCardEs from './locales/es/dashboardMyBillCard.json'
import dashboardMyBillCIXCardEn from './locales/en/dashboardMyBillCIXCard.json'
import dashboardMyBillCIXCardEs from './locales/es/dashboardMyBillCIXCard.json'
import dashboardOutageCardsEn from './locales/en/dashboardOutageCards.json'
import dashboardOutageCardsEs from './locales/es/dashboardOutageCards.json'

import earlyWarningEn from './locales/en/earlyWarning.json'
import earlyWarningEs from './locales/es/earlyWarning.json'
import giftPaymentEn from './locales/en/giftPayment.json'
import giftPaymentEs from './locales/es/giftPayment.json'
import guestPaymentEn from './locales/en/guestPayment.json'
import guestPaymentEs from './locales/es/guestPayment.json'
import eraProgramFormEn from './locales/en/eraProgramForm.json'
import eraProgramFormEs from './locales/es/eraProgramForm.json'
import economyPricePlanSignupFormEn from './locales/en/economyPricePlanSignupForm.json'
import economyPricePlanSignupFormEs from './locales/es/economyPricePlanSignupForm.json'
import evCommunitySignupFormEn from './locales/en/evCommunitySignupForm.json'
import evCommunitySignupFormEs from './locales/es/evCommunitySignupForm.json'
import startServiceEn from './locales/en/startService.json'
import startServiceEs from './locales/es/startService.json'
import transferServiceEn from './locales/en/transferService.json'
import transferServiceEs from './locales/es/transferService.json'
import safetyNetSignupFormEn from './locales/en/safetyNetSignupForm.json'
import safetyNetSignupFormEs from './locales/es/safetyNetSignupForm.json'
import singlePurchasePageEn from './locales/en/singlePurchasePage.json'
import singlePurchasePageEs from './locales/es/singlePurchasePage.json'
import cashPaymentCardEn from './locales/en/cashPaymentCard.json'
import cashPaymentCardEs from './locales/es/cashPaymentCard.json'
import singlePaymentEn from './locales/en/singlePayment.json'
import singlePaymentEs from './locales/es/singlePayment.json'
import multiPurchasePageEn from './locales/en/multiPurchasePage.json'
import multiPurchasePageEs from './locales/es/multiPurchasePage.json'
import accountInfoBarEn from './locales/en/accountInfoBar.json'
import accountInfoBarEs from './locales/es/accountInfoBar.json'
import bankAccountSelectFieldEn from './locales/en/bankAccountSelectField.json'
import bankAccountSelectFieldEs from './locales/es/bankAccountSelectField.json'
import breadCrumbEn from './locales/en/breadCrumb.json'
import breadCrumbEs from './locales/es/breadCrumb.json'
import cashOnlyPaymentEn from './locales/en/cashOnlyPayment.json'
import cashOnlyPaymentEs from './locales/es/cashOnlyPayment.json'
import checkDiagramCardEn from './locales/en/checkDiagramCard.json'
import checkDiagramCardEs from './locales/es/checkDiagramCard.json'
import otherPaymentOptionsEn from './locales/en/otherPaymentOptions.json'
import otherPaymentOptionsEs from './locales/es/otherPaymentOptions.json'
import makeACreditCardPaymentLinkEn from './locales/en/makeACreditCardPaymentLink.json'
import makeACreditCardPaymentLinkEs from './locales/es/makeACreditCardPaymentLink.json'
import multiPaymentEn from './locales/en/multiPayment.json'
import multiPaymentEs from './locales/es/multiPayment.json'
import stopServiceEn from './locales/en/stopService.json'
import stopServiceEs from './locales/es/stopService.json'
import changePricePlanEn from './locales/en/changePricePlan.json'
import changePricePlanEs from './locales/es/changePricePlan.json'
import loginEn from './locales/en/login.json'
import loginEs from './locales/es/login.json'
import forgotPasswordEn from './locales/en/forgotPassword.json'
import forgotPasswordEs from './locales/es/forgotPassword.json'
import accountSignUpEn from './locales/en/accountSignUp.json'
import accountSignUpEs from './locales/es/accountSignUp.json'
import startStopTransferEn from './locales/en/startStopTransfer.json'
import startStopTransferEs from './locales/es/startStopTransfer.json'
import authorizedPartyEn from './locales/en/authorizedParty.json'
import authorizedPartyEs from './locales/es/authorizedParty.json'
import transferServiceModalEn from './locales/en/transferServiceModal.json'
import transferServiceModalEs from './locales/es/transferServiceModal.json'

import reportOutageEn from './locales/en/reportOutage.json'
import reportOutageEs from './locales/es/reportOutage.json'

import shareProgramEn from './locales/en/shareProgram.json'
import shareProgramEs from './locales/es/shareProgram.json'

import addUpdateBankEn from './locales/en/addUpdateBank.json'
import addUpdateBankEs from './locales/es/addUpdateBank.json'

import addUpdateSurePayEn from './locales/en/addUpdateSurePay.json'
import addUpdateSurePayEs from './locales/es/addUpdateSurePay.json'

import bankLockedEn from './locales/en/bankLocked.json'
import bankLockedEs from './locales/es/bankLocked.json'

import greetingsEn from './locales/en/greetings.json'
import greetingsEs from './locales/es/greetings.json'
import navigationBarEN from './locales/en/navigationBar.json'
import navigationBarES from './locales/es/navigationBar.json'
import callAnyTimeEN from './locales/en/callAnyTime.json'
import callAnyTimeES from './locales/es/callAnyTime.json'
import eppStatusEN from './locales/en/eppStatus'
import eppStatusES from './locales/es/eppStatus'
import pendingTurnOnCardEN from "./locales/en/pendingTurnOnCard.json"
import pendingTurnOnCardES from "./locales/es/pendingTurnOnCard.json"
import mainLayoutEN from './locales/en/mainLayout.json'
import mainLayoutES from './locales/es/mainLayout.json'
import promotionBannerEN from './locales/en/promotionBanner.json'
import promotionBannerES from './locales/es/promotionBanner.json'
import emptyStateEN from './locales/en/emptyState.json'
import emptyStateES from './locales/es/emptyState.json'
import usageCardEN from './locales/en/usageCard.json'
import usageCardES from './locales/es/usageCard.json'
import myPurchasePageEN from './locales/en/myPurchasePage.json'
import myPurchasePageES from './locales/es/myPurchasePage.json'
import purchaseHistoryTableEN from './locales/en/purchaseHistoryTable.json'
import purchaseHistoryTableES from './locales/es/purchaseHistoryTable.json'
import billPageEN from './locales/en/billPage.json'
import billPageES from './locales/es/billPage.json'
import myBillPageEN from './locales/en/myBillPage.json'
import myBillPageES from './locales/es/myBillPage.json'

import budgetBillingEn from './locales/en/budgetBilling.json'
import budgetBillingEs from './locales/es/budgetBilling.json'
import customDueDateEn from './locales/en/customDueDate.json'
import customDueDateEs from './locales/es/customDueDate.json'
import outageMapEn from './locales/en/outageMap.json'
import outageMapEs from './locales/es/outageMap.json'

import landlordSummaryEn from './locales/en/landlordSummary.json'
import landlordSummaryEs from './locales/es/landlordSummary.json'

import healthyForestEn from './locales/en/healthyForest.json'
import healthyForestEs from './locales/es/healthyForest.json'
import solarNonProfitEn from './locales/en/solarNonProfit.json'
import solarNonProfitEs from './locales/es/solarNonProfit.json'

import usagePageEN from './locales/en/usagePage.json'
import usagePageES from './locales/es/usagePage.json'
import usageFormsEN from './locales/en/usageForms.json'
import usageFormsES from './locales/es/usageForms.json'

import pricePlanAndProgramsEn from './locales/en/pricePlanAndPrograms.json'
import pricePlanAndProgramsEs from './locales/es/pricePlanAndPrograms.json'

import safetyNetEn from './locales/en/safetyNet.json'
import safetyNetEs from './locales/es/safetyNet.json'

import solarChoiceEn from './locales/en/solarChoice.json'
import solarChoiceEs from './locales/es/solarChoice.json'

import erapEn from './locales/en/erap.json'
import erapEs from './locales/es/erap.json'

import paymentLocationEN from './locales/en/paymentLocation.json'
import paymentLocationES from './locales/es/paymentLocation.json'

import profileSettingsEN from './locales/en/profileSettings.json'
import profileSettingsES from './locales/es/profileSettings.json'

import contactInfoAndAlertEN from './locales/en/contactInfoAndAlert.json'
import contactInfoAndAlertES from './locales/es/contactInfoAndAlert.json'

import bankInformationEN from './locales/en/bankInformation.json'
import bankInformationES from './locales/es/bankInformation.json'

import documentCenterEN from './locales/en/documentCenter.json'
import documentCenterES from './locales/es/documentCenter.json'

import primaryContactInfoReminderEN from './locales/en/primaryContactInfoReminder.json'
import primaryContactInfoReminderES from './locales/es/primaryContactInfoReminder.json'

/* eslint-enable import/extensions */

const detectionOptions = {
    lookupQuerystring: 'lang',
    // ?lang=LANGUAGE
    // cookie i18next=LANGUAGE
    // navigator.language
    order: ['querystring', 'cookie', 'navigator'],
}

i18n
    .on('languageChanged', (lng) => {document.documentElement.setAttribute('lang', lng);})
    // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
    // learn more: https://github.com/i18next/i18next-http-backend
    // .use(Backend)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        resources: {
            en: {
                common: commonEn,
                dashboardPage: dashboardPageEn,
                dashboardMyBillCard: dashboardMyBillCardEn,
                dashboardMyBillCIXCard: dashboardMyBillCIXCardEn,
                dashboardOutageCards: dashboardOutageCardsEn,
                earlyWarning: earlyWarningEn,
                giftPayment: giftPaymentEn,
                guestPayment: guestPaymentEn,
                economyPricePlanSignupForm: economyPricePlanSignupFormEn,
                evCommunitySignupForm: evCommunitySignupFormEn,
                eraProgramForm: eraProgramFormEn,
                startService: startServiceEn,
                transferService: transferServiceEn,
                safetyNetSignupForm: safetyNetSignupFormEn,
                singlePurchasePage: singlePurchasePageEn,
                cashPaymentCard: cashPaymentCardEn,
                singlePayment: singlePaymentEn,
                multiPurchasePage: multiPurchasePageEn,
                accountInfoBar: accountInfoBarEn,
                bankAccountSelectField: bankAccountSelectFieldEn,
                breadCrumb: breadCrumbEn,
                cashOnlyPayment: cashOnlyPaymentEn,
                checkDiagramCard: checkDiagramCardEn,
                otherPaymentOptions: otherPaymentOptionsEn,
                makeACreditCardPaymentLink: makeACreditCardPaymentLinkEn,
                multiPayment: multiPaymentEn,
                stopService: stopServiceEn,
                changePricePlan: changePricePlanEn,
                login: loginEn,
                forgotPassword: forgotPasswordEn,
                accountSignUp: accountSignUpEn,
                startStopTransfer: startStopTransferEn,
                authorizedParty: authorizedPartyEn,
                transferServiceModal: transferServiceModalEn,
                reportOutage: reportOutageEn,
                shareProgram: shareProgramEn,
                addUpdateBank: addUpdateBankEn,
                addUpdateSurePay: addUpdateSurePayEn,
                bankLocked: bankLockedEn,
                greetings:greetingsEn,
                navigationBar:navigationBarEN,
                eppStatus:eppStatusEN,
                callAnyTime: callAnyTimeEN,
                pendingTurnOnCard: pendingTurnOnCardEN,
                mainLayout: mainLayoutEN,
                promotionBanner : promotionBannerEN,
                emptyState: emptyStateEN,
                usageCard: usageCardEN,
                customDueDate: customDueDateEn,
                outageMap: outageMapEn,
                budgetBilling: budgetBillingEn,
                landlordSummary: landlordSummaryEn,
                healthyForest: healthyForestEn,
                solarNonProfit: solarNonProfitEn,
                pricePlanAndPrograms: pricePlanAndProgramsEn,
                safetyNet: safetyNetEn,
                solarChoice: solarChoiceEn,
                erap: erapEn,
                myPurchasePage : myPurchasePageEN,
                purchaseHistoryTable : purchaseHistoryTableEN,
                billPage: billPageEN,
                myBillPage : myBillPageEN,
                usagePage : usagePageEN,
                usageForms : usageFormsEN,
                paymentLocation: paymentLocationEN,
                profileSettings: profileSettingsEN,
                contactInfoAndAlert: contactInfoAndAlertEN,
                bankInformation : bankInformationEN,
                documentCenter : documentCenterEN,
                primaryContactInfoReminder : primaryContactInfoReminderEN
            },
            es: {
                common: commonEs,
                dashboardPage: dashboardPageEs,
                dashboardMyBillCard: dashboardMyBillCardEs,
                dashboardMyBillCIXCard: dashboardMyBillCIXCardEs,
                dashboardOutageCards: dashboardOutageCardsEs,
                earlyWarning: earlyWarningEs,
                giftPayment: giftPaymentEs,
                guestPayment: guestPaymentEs,
                economyPricePlanSignupForm: economyPricePlanSignupFormEs,
                eraProgramForm: eraProgramFormEs,
                evCommunitySignupForm: evCommunitySignupFormEs,
                startService: startServiceEs,
                transferService: transferServiceEs,
                safetyNetSignupForm: safetyNetSignupFormEs,
                singlePurchasePage: singlePurchasePageEs,
                cashPaymentCard: cashPaymentCardEs,
                singlePayment: singlePaymentEs,
                multiPurchasePage: multiPurchasePageEs,
                accountInfoBar: accountInfoBarEs,
                bankAccountSelectField: bankAccountSelectFieldEs,
                breadCrumb: breadCrumbEs,
                cashOnlyPayment: cashOnlyPaymentEs,
                checkDiagramCard: checkDiagramCardEs,
                otherPaymentOptions: otherPaymentOptionsEs,
                makeACreditCardPaymentLink: makeACreditCardPaymentLinkEs,
                multiPayment: multiPaymentEs,
                stopService: stopServiceEs,
                changePricePlan: changePricePlanEs,
                login: loginEs,
                forgotPassword: forgotPasswordEs,
                accountSignUp: accountSignUpEs,
                startStopTransfer: startStopTransferEs,
                authorizedParty: authorizedPartyEs,
                transferServiceModal: transferServiceModalEs,
                reportOutage: reportOutageEs,
                shareProgram: shareProgramEs,
                addUpdateBank: addUpdateBankEs,
                addUpdateSurePay: addUpdateSurePayEs,
                bankLocked: bankLockedEs,
                greetings: greetingsEs,
                navigationBar: navigationBarES,
                eppStatus: eppStatusES,
                callAnyTime: callAnyTimeES,
                pendingTurnOnCard: pendingTurnOnCardES,
                mainLayout: mainLayoutES,
                promotionBanner : promotionBannerES,
                emptyState: emptyStateES,
                usageCard: usageCardES,
                customDueDate: customDueDateEs,
                outageMap: outageMapEs,
                budgetBilling: budgetBillingEs,
                landlordSummary: landlordSummaryEs,
                healthyForest: healthyForestEs,
                solarNonProfit: solarNonProfitEs,
                pricePlanAndPrograms: pricePlanAndProgramsEs,
                safetyNet: safetyNetEs,
                solarChoice: solarChoiceEs,
                erap: erapEs,
                myPurchasePage : myPurchasePageES,
                purchaseHistoryTable : purchaseHistoryTableES,
                billPage : billPageES,
                myBillPage : myBillPageES,
                usagePage : usagePageES,
                usageForms : usageFormsES,
                paymentLocation : paymentLocationES,
                profileSettings : profileSettingsES,
                contactInfoAndAlert : contactInfoAndAlertES,
                bankInformation : bankInformationES,
                documentCenter : documentCenterES,
                primaryContactInfoReminder : primaryContactInfoReminderES
            },
        },
        detection: detectionOptions,
        fallbackLng: 'en',
        debug: !config.isProduction,
        keySeparator: false,
        nsSeparator: '::',

        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
    })

let lang = i18n.language
if (lang === 'es' || (lang?.startsWith('es-')===true)) {
    lang = 'es'
} else {
    lang = 'en'
}

changeLanguage(lang)
Cookies.set(LANG_COOKIE_KEY, lang, { expires: LANG_COOKIE_EXPIRATION_DAYS })

export default i18n