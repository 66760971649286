export default function drawVerticalBars(d,data,x,y,minY,maxY,
    xOffset,barWidth,xAxisKey){
    let radius = getRadius(data.length)
    
                if (d[1] - d[0] == 0) {
                    return
                }
                
                if ((handleFloatEquality(d[0], minY) && d[1] >= 0) && handleFloatEquality(d[1], maxY)) {//positive empty bar
                    
                    return "M" + ((x(d.data[xAxisKey]) + xOffset) + radius) + "," + y(maxY) +
                    "h" + (barWidth - (radius * 2)) +
                    "a" + radius + "," + radius + " 0 0 1 " + radius + "," + radius +
                    "v" + ((y(d[0]) - y(d[1] + d[0])) - (2*radius)) +
                    "a" + radius + "," + -radius + " 0 0 1 " + -radius + "," + radius +
                    "h" + (radius * 2 - barWidth) +
                    "a" + radius + "," + radius + " 0 0 1 " + -radius + "," + -radius +
                    "v" + (0 - (y(d[0]) - y(d[1]) - (2*radius))) +
                    "a" + radius + "," + radius + " 0 0 1 " + radius + "," + -radius +
                    "z"
                  
                }
                if (handleFloatEquality(d[0], 0) && handleFloatEquality(d[1], minY)  && handleFloatEquality(maxY, 0)) {//negative empty bar
                    
                    return "M" + ((x(d.data[xAxisKey]) + xOffset) + radius) + "," + y(0) +
                    "h" + (barWidth - (radius * 2)) +
                    "a" + radius + "," + radius + " 0 0 1 " + radius + "," + radius +
                    "v" + (y(minY) - (2*radius)) +
                    "a" + radius + "," + -radius + " 0 0 1 " + -radius + "," + radius +
                    "h" + (radius * 2 - barWidth) +
                    "a" + radius + "," + radius + " 0 0 1 " + -radius + "," + -radius +
                    "v" + -((y(minY) - (2*radius))) +
                    "a" + radius + "," + radius + " 0 0 1 " + radius + "," + -radius +
                    "z"
                  
                }

                if ((handleFloatEquality(d[0], minY) && d[1] >= 0)) {//bottom with no negative values
                    return "M" + ((x(d.data[xAxisKey]) + xOffset) + radius) + "," + y(d[1] + d[0]) +
                        "h" + (barWidth - (radius)) +
                        "v" + ((y(d[0]) - y(d[1] + d[0])) - radius) +
                        "a" + radius + "," + -radius + " 0 0 1 " + -radius + "," + radius +
                        "h" + (radius * 2 - barWidth) +
                        "a" + radius + "," + radius + " 0 0 1 " + -radius + "," + -radius +
                        "v" + (radius - ((y(d[0]) - y(d[1] + d[0])))) +
                        "z"
                }
                if ((handleFloatEquality(d[0], maxY) && d[1] <= 0)) {//top with no positive values
                    return "M" + ((x(d.data[xAxisKey]) + xOffset) + radius) + "," + y(d[0]) +
                        "h" + (barWidth - (2 * radius)) +
                        "a" + radius + "," + radius + " 0 0 1 " + radius + "," + radius +
                        "v" + -((y(d[0]) - y(d[1] + d[0])) + radius) +
                        "h" + -barWidth +
                       
                        "v" + ((y(d[0]) - y(d[1] + d[0])) + radius) +
                        "a" + radius + "," + radius + " 0 0 1 " + radius + "," + -radius +
                        "z"
                }
                if ((handleFloatEquality(d[1], minY) && d[0] <= 0)) {
                    return "M" + ((x(d.data[xAxisKey]) + xOffset) + radius) + "," + y(d[0]) +
                        "h" + (barWidth - (radius)) +
                        "v" + -((y(d[0]) - y(d[1])) + radius) +
                        "a" + radius + "," + -radius + " 0 0 1 " + -radius + "," + radius +
                        "h" + (radius * 2 - barWidth) +
                        "a" + radius + "," + radius + " 0 0 1 " + -radius + "," + -radius +
                        "v" + ((y(d[0]) - y(d[1])) + radius) +
                        "z"
                }
    
                if (d[0] < 0 && handleFloatEquality(d[0], minY)) {
                    return "M" + ((x(d.data[xAxisKey]) + xOffset) + radius) + "," + y(maxY) +
                        "h" + (barWidth - (radius * 2)) +
                        "a" + radius + "," + radius + " 0 0 1 " + radius + "," + radius +
                        "v" + ((y(0) - y(maxY)) - radius) +
                        "h" + (0 - barWidth) +
                        "v" + (0 - (y(0) - y(maxY) - radius)) +
                        "a" + radius + "," + radius + " 0 0 1 " + radius + "," + -radius +
                        "z"
                }
    
                if (handleFloatEquality(d[1], maxY)) {
                    return "M" + ((x(d.data[xAxisKey]) + xOffset) + radius) + "," + y(maxY) +
                        "h" + (barWidth - (radius * 2)) +
                        "a" + radius + "," + radius + " 0 0 1 " + radius + "," + radius +
                        "v" + ((y(d[0]) - y(d[1])) - radius) +
                        "h" + (0 - barWidth) +
                        "v" + (0 - (y(d[0]) - y(d[1]) - radius)) +
                        "a" + radius + "," + radius + " 0 0 1 " + radius + "," + -radius +
                        "z"
                }
                
                let heightOrMinHeight = Math.abs(((y(d[0]) - y(d[1])))) > 0 ? ((y(d[0]) - y(d[1]))) : 1
                return "M" + (x(d.data[xAxisKey]) + xOffset) + "," + y(d[1]) +
                    "h" + (barWidth) +
                    "v" + heightOrMinHeight +
                    "h" + (0 - barWidth) +
                    "v" + (0 - ((y(d[0]) - y(d[1] + d[0])))) +
                    "z"
}


function getRadius(dataCount) {
    if(dataCount <= 4){
        return 10/dataCount
    }
    if(dataCount <= 14 && dataCount > 4){
        return 25/dataCount
    }
    return 100/dataCount
    
}

function handleFloatEquality(a, b) {
    return decimalRound(a) == decimalRound(b)
}
function decimalRound(d) {
    let num = d * 100
    return Math.round(num)/100
    
}