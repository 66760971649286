import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import TextField from '@mui/material/TextField'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import MailingAddress from './mailing-address'
import StartServiceStepper from './start-stepper'
import formikInputFilter from '../../../srp_modules/formik-input-filter'
import AddressWithAutoComplete from '../../address-with-autocomplete'

class Address extends Component {
    constructor(props) {
        super(props)
        this.state = {
            hasAddressError: false,
            addressError: "",
            hasCityError: false,
            cityError: ''
        }
        this.addressRequiredField = this.addressRequiredField.bind(this)
        this.addressRequiredMessage = "address_is_required"
        this.cityRequiredMessage = "city_required"
        this.autocompleteServiceAddress = this.autocompleteServiceAddress.bind(this)
    }

    componentDidUpdate(prevProps) {
        if (this.props.shouldRunValidation !== prevProps.shouldRunValidation) {
            this.addressRequiredField(this.props.address1)
            this.cityRequiredField(this.props.city)
        }
    }

    addressRequiredField(fieldValue) {
        if (fieldValue.length === 0) {
            this.setState({ hasAddressError: true })
            this.setState({ addressError: "address_is_required" })
        } else {
            this.setState({ hasAddressError: false })
            this.setState({ addressError: "" })
        }
        this.props.runDetailsValidation(false)
    }

    cityRequiredField(fieldValue) {
        if (fieldValue.length === 0) {
            this.setState({ hasCityError: true })
            this.setState({ cityError: this.cityRequiredMessage })
        } else {
            this.setState({ hasCityError: false })
            this.setState({ cityError: "" })
        }
        this.props.runDetailsValidation(false)
    }

    calloutAddressRequiredField() {
        this.setState({ hasAddressError: true })
        this.setState({ addressError: this.addressRequiredMessage })
    }

    calloutCityRequiredField() {
        this.setState({ hasCityError: true })
        this.setState({ cityError: this.cityRequiredMessage })
    }

    autocompleteServiceAddress(components){
        let streetAddress = `${components.streetNumber} ${components.street}`
        this.props.address1Change(streetAddress)
        this.props.cityChange(components.city)
    }

    mailingInputValid(newValue) {
        if (newValue.match(formikInputFilter.regex.streetAddressRegex)) {
            return true
        }

        return false
    }

    render() {
        return (
            <div>
                <div>
                    <StartServiceStepper currentStep={this.props.currentStep} />
                    <div className="">
                        <AddressWithAutoComplete
                            fieldName="Address1"
                            fieldValue={this.props.address1}
                            handleAddressChange={e => {
                                if (this.mailingInputValid(e.target.value)) {
                                    this.props.address1Change(e.target.value.replace(/ {1,}/g,' '))
                                }
                            }}
                            handleAddressBlur={e => this.addressRequiredField(e.target.value)}
                            errorText={this.state.addressError==='' ? '' : this.props.t(this.state.addressError)}
                            hasError={this.state.hasAddressError}
                            updateAddressCallback={this.autocompleteServiceAddress}
                            isDomesticAddress={true} 
                            label={this.props.t("Street address")}
                        />
                        <TextField id="Address2"
                            fullWidth={true}
                            value={this.props.address2}
                            inputProps={{ maxLength: 39 }}
                            className="mb-3"
                            onChange={e => {
                                if (this.mailingInputValid(e.target.value)) {
                                    this.props.address2Change(e.target.value)
                                }
                            }}
                            label={this.props.t("Apt, suite, etc (optional)")}
                        />
                    </div>
                    <div>
                        <TextField id="city"
                            fullWidth={true}
                            value={this.props.city}
                            error={this.state.hasCityError}
                            helperText={this.state.cityError==='' ? '' : this.props.t(this.state.cityError)}
                            inputProps={{ maxLength: 30 }}
                            className="mb-3"
                            onBlur={e => this.cityRequiredField(e.target.value)}
                            onChange={e => {
                                if (this.mailingInputValid(e.target.value)) {
                                    this.props.cityChange(e.target.value)
                                }
                            }}
                            label={this.props.t("city")}
                        />
                    </div>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={this.props.isMailingAddressSame}
                                onChange={event => this.props.mailingAddressAsServiceChange(event.target.checked)}
                                color="primary" />
                        }
                        label={this.props.t("service_is_mailing_address")}
                    />
                </div>
                <MailingAddress {...this.props} />
            </div>
        )
    }
}

Address.propTypes = {
    address1: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
    runDetailsValidation: PropTypes.func.isRequired,
    shouldRunValidation: PropTypes.bool.isRequired,
    address2Change: PropTypes.func.isRequired,
    cityChange: PropTypes.func.isRequired,
    isMailingAddressSame: PropTypes.bool.isRequired,
    mailingAddressAsServiceChange: PropTypes.func.isRequired,
    address1Change: PropTypes.func.isRequired,
    address2: PropTypes.string.isRequired,
    currentStep: PropTypes.number.isRequired,
    t: PropTypes.func
}

export default withTranslation('startService')(Address)