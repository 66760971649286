import * as React from 'react'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import * as loadingStatus from '../../constants/loading-status-constants'
import drawHorizontalChart from  '../../srp_modules/charting/hourly-usage-horizontal'
import drawVerticalChart from  '../../srp_modules/charting/hourly-usage-vertical'
import {dailyUsageChartTypeConstants} from '../../constants/daily-usage-chart-type-constants'

let initializeChart = (hourlyUsageList,
    currentChart,
    hasOnOffShoulder,
    t
    ) => {

    if(hourlyUsageList.length > 0){

        window.innerWidth < 1024
            ? drawHorizontalChart(hourlyUsageList, [], currentChart, hasOnOffShoulder, t)
            : drawVerticalChart(hourlyUsageList, currentChart, hasOnOffShoulder, t)
    }
}

class HourlyUsageChartContainer extends React.Component{
    constructor(props) {
        super(props)
      this.state = {
          resizeListener: this.updateChart.bind(this)
      }
    }
    componentDidMount() {
        window.addEventListener("resize", this.state.resizeListener)
        if (this.props.hourlyUsageStatus === loadingStatus.LOADING_STATUS_SUCCESS)
            this.updateChart()
    }

    shouldComponentUpdate(nextProps) {
        return (this.props.hourlyUsageStatus !== nextProps.hourlyUsageStatus
                || this.props.rateMetaData.rateMetaDataStatus !== nextProps.rateMetaData.rateMetaDataStatus
                || this.props.hourlyStartDate !== nextProps.hourlyStartDate
                || this.props.currentHourlyChart !== nextProps.currentHourlyChart
                || this.props.t !== nextProps.t
            )
        }

    componentDidUpdate() {
        if (this.props.hourlyUsageStatus === loadingStatus.LOADING_STATUS_SUCCESS
            && this.props.rateMetaData.rateMetaDataStatus === loadingStatus.LOADING_STATUS_SUCCESS
        )
            this.updateChart()
    }

    componentWillUnmount(){
        window.removeEventListener("resize", this.state.resizeListener)
    }

    updateChart() {
        let usageData = [];
        switch(this.props.currentHourlyChart){
            case dailyUsageChartTypeConstants.NET_ENERGY:
                usageData = this.props.hourlyUsageList;
                break
            case dailyUsageChartTypeConstants.GENERATION:
                usageData = this.props.hourlyGenerationList;
                break
            case dailyUsageChartTypeConstants.CONSUMPTION:
                usageData = this.props.hourlyConsumptionList;
                break
            case dailyUsageChartTypeConstants.COST:
                usageData = this.props.hourlyUsageList
                break
            case dailyUsageChartTypeConstants.USAGE:
                usageData = this.props.hourlyUsageList
                break
            case dailyUsageChartTypeConstants.DEMAND:
                usageData = this.props.hourlyDemandList
                break
            case dailyUsageChartTypeConstants.EXPORT:
                usageData = this.props.hourlyReceivedList
                break

        }

        initializeChart(
            usageData,
            this.props.currentHourlyChart,
            this.props.rateMetaData.rateMetaData.hasOnOffShoulder,
            this.props.t
        )
    }

    render() {
        return(
            <div>
                <div id="usagePageChartContainer">

                    <div id="usageLegendContainer" className="chart-legend-container">
                        <div id="superOffLegendItem" className="chart-legend-item-hidden">
                            <span>{this.props.t("Super off-peak")}</span>
                            <div className="usage-chart-legend-circle viz-SuperOffPeak" />
                        </div>
                        <div id="offPeakLegendItem" className="chart-legend-item-hidden">
                            <span>{this.props.t("Off-peak")}</span>
                            <div className="usage-chart-legend-circle viz-OffPeak" />
                        </div>
                        <div id="shoulderLegendItem" className="chart-legend-item-hidden">
                            <span>{this.props.t("Shoulder")}</span>
                            <div className="usage-chart-legend-circle viz-Shoulder" />
                        </div>
                        <div id="onPeakLegendItem" className="chart-legend-item-hidden">
                            <span>{this.props.t("On-peak")}</span>
                            <div className="usage-chart-legend-circle viz-OnPeak" />
                        </div>
                        <div id="totalLegendItem" className="chart-legend-item-hidden">
                            <span>{this.props.t("Total")}</span>
                            <div className="usage-chart-legend-circle viz-Total" />
                        </div>
                        <div id="totalLegendItemCost" className="chart-legend-item-hidden">
                            <span>{this.props.t("Cost")}</span>
                            <div className="usage-chart-legend-circle viz-Total" />
                        </div>
                      </div>
                    <div className=".viz-tooltip-arrow-main" />
                </div>
            </div>
        )
    }

}

HourlyUsageChartContainer.propTypes = {
    highDemandInfoList: PropTypes.array,
    selectedBillAccount: PropTypes.number,
    selectedUsagePageChartTab: PropTypes.number,
    rateMetaData: PropTypes.object,
    getRateMetaDataForAccount: PropTypes.func,
    hourlyUsageStatus: PropTypes.string,
    hourlyStartDate: PropTypes.object,
    hourlyUsageList: PropTypes.array,
    currentHourlyChart: PropTypes.string,
    hourlyGenerationList: PropTypes.array,
    hourlyDemandList: PropTypes.array,
    hourlyConsumptionList: PropTypes.array,
    hourlyReceivedList: PropTypes.array,
    t: PropTypes.func
}

const mapStateToProps = (state) => {
    return {
        ...state.hourlyUsage,
        selectedBillAccount: state.accountInfo.billAccount.selectedBillAccount,
        isCost: PropTypes.bool,
        selectedUsagePageChartTab: state.accountInfo.usagePageUI.selectedUsagePageChartTab,
        rateMetaData: state.rateMetaData,
        ...state.accountInfo.usagePageUI,

    }
}



export default connect(mapStateToProps)(HourlyUsageChartContainer)

