import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { withTranslation } from 'react-i18next'

import SuccessCheck from '../../images/common/SuccessCheck.png'

import { myAccountConstants } from '../../constants/myaccount-constants'
import * as loadingStatus from '../../constants/loading-status-constants'

const ChangePricePlanConfirmation = ({ isLoggedIn, isCommercial, changePricePlanStatus, t }) => {

    return (changePricePlanStatus !== loadingStatus.LOADING_STATUS_FAILURE
            ? <div className="row">
                <div className="col-lg-7 col-12 mt-4">
                    <div className="srp-card-header">{t("Your request has been submitted")}</div>
                    <div className="srp-card-body text-muted">
                        <div className="srp-card-details">
                            <div className="d-lg-block d-none">
                                <div className="mb-3 srp-olive-green d-flex align-items-center">
                                    <img className="img-fluid mr-3" src={SuccessCheck} style={{ width: '50px' }} />
                                    <span style={{ fontSize: '1.75rem', fontWeight: '500' }}>{t("You're all set!")}</span>
                                </div>
                            </div>
                            <div className="d-lg-none d-block">
                                <div className="mb-3 srp-olive-green text-center">
                                    <img className="img-fluid mr-3" src={SuccessCheck} style={{ width: '50px' }} />
                                    <div style={{ fontSize: '1.75rem', fontWeight: '500' }}>{t("You're all set!")}</div>
                                </div>
                            </div>

                            <div className="mb-4">{t("thank_you_for_changing_srp_service")}</div>

                            <div className="d-flex flex-wrap justify-content-end">
                                {isLoggedIn
                                    ? <Link to="/myaccount/dashboard" className="btn srp-btn btn-lightblue">{t("Go to dashboard")}</Link>
                                    : <a className="btn srp-btn btn-lightblue" href={t("srpnet_link")}>{t("Go to SRPnet.com")}</a>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            : <div className="row">
                <div className="col-lg-7 col-12">
                    <div className="srp-card-header">{t("Unable to change your Price Plan")}</div>
                    <div className="srp-card-body text-muted">
                        <div className="srp-card-details">
                            <div className="mb-3">{t("unable_to_submit_price_pln_change")}</div>
                            <p className="d-lg-block d-none mb-0">
                                {`${t("Please try again later or call SRP Customer Service at")}
                                ${isCommercial ? myAccountConstants.COMMERCIAL_CUSTOMER_SERVICE_PHONE_NUMBER_FMTTD : myAccountConstants.RESIDENTIAL_CUSTOMER_SERVICE_PHONE_NUMBER_FMTTD},
                                ${t("available_24_hrs_7_days")}.`}
                            </p>
                            <p className="d-lg-none d-block mb-0">
                                {`${t("Please try again later or call SRP Customer Service at")} `}
                                <a href={isCommercial ? "tel:" + myAccountConstants.COMMERCIAL_CUSTOMER_SERVICE_PHONE_NUMBER : "tel:" + myAccountConstants.RESIDENTIAL_CUSTOMER_SERVICE_PHONE_NUMBER}>{isCommercial ? myAccountConstants.COMMERCIAL_CUSTOMER_SERVICE_PHONE_NUMBER_FMTTD : myAccountConstants.RESIDENTIAL_CUSTOMER_SERVICE_PHONE_NUMBER_FMTTD}</a>
                                {`, ${t("available_24_hrs_7_days")}.`}
                            </p>

                            <div className="d-flex flex-wrap justify-content-end">
                                {isLoggedIn
                                    ? <Link to="/myaccount/dashboard" className="btn srp-btn btn-lightblue">{t("Go to dashboard")}</Link>
                                    : <a className="btn srp-btn btn-lightblue" href={t("srpnet_link")}>{t("Go to SRPnet.com")}</a>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
}

ChangePricePlanConfirmation.propTypes = {
    isLoggedIn: PropTypes.bool,
    isCommercial: PropTypes.bool,
    changePricePlanStatus: PropTypes.string,
    t: PropTypes.func
}

export default withTranslation('changePricePlan')(ChangePricePlanConfirmation)