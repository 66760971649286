import React from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import BankAccountSelectorCardContainer from './bank-account-selector-card-container'
import PayableAccountsTableContainer from './payable-accounts-table-container'

const PayByBank = ({t}) => {
    return (
        <div>
            <div className="  pl-0">
                <div className="col-12 col-lg-6 pl-0">
                    <div className="srp-card-header">
                        {t("Bank account")}
                    </div>
                    <div className="srp-card-body">
                        <div className="srp-card-details text-muted">
                            <BankAccountSelectorCardContainer />
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-lg-12 col-12 mt-4 pl-0">
                <div className="srp-card-header" id="payable-accounts-table-header">
                    {/* Payable accounts */}
                </div>
                <PayableAccountsTableContainer />
            </div>
            <br />
        </div>
    )
}

PayByBank.propTypes = {
    t: PropTypes.func.isRequired,
    i18n: PropTypes.object.isRequired
}

export default withTranslation("multiPayment")(PayByBank)